import React from "react";
import StarRating from "./StarRating/StarRating";
import Time from "../DateTime/Time";
import classnames from "classnames";
import "./Reviews.scss";
import styles from "./Review.module.scss";
import { Badge } from "reactstrap";

const { reviewDateClass } = styles;

const ReviewHeader = ({
  review: { author, rating, type, formattedStarRating, reviewDate },
  showType,
  spaced = true
}) => (
  <>
    {!!type && showType && (
      <Badge color="dark" className="mb-3">
        {type}
      </Badge>
    )}
    <div className="review-header">{author}</div>
    <div className="d-flex justify-content-between align-items-center">
      {type === "REVIEW" && (
        <StarRating
          className="d-inline-flex"
          rating={rating}
          formattedStarRating={formattedStarRating}
          spaced={spaced}
        />
      )}
      {reviewDate && (
        <Time
          className={classnames("text-truncate", reviewDateClass)}
          withIcon={false}
          format="dateSlash"
          date={reviewDate}
        />
      )}
    </div>
  </>
);

export default ReviewHeader;
