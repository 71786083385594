import { TableCard, TableCardHeader, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import { bySource } from "data/sites";
import { CardBody, CardTitle } from "components/Card";

import { useReportFilterQuery } from "components/HOCs/ReportWithFilters";

export const withInviteReportFilterQuery = (Component, config = {}) => {
  const ComponentWithData = () => {
    const filterQuery = useReportFilterQuery({
      url: "invitereport",
      additionalParams: { frequency: "DAILY" },
      ...config
    });

    return <Component {...filterQuery} {...config} />;
  };
  return ComponentWithData;
};

export const RequestsBySource = withInviteReportFilterQuery(({ data = {} }) => {
  return (
    <TableCard>
      <TableCardHeader>
        <CardTitle>Review Requests by Source</CardTitle>
      </TableCardHeader>
      <CardBody className="mt-3">
        <WWTable id="Review-Requests-by-Source">
          <WWTHead>
            <WWTR>
              <WWTH></WWTH>
              <WWTH>Automated</WWTH>
              <WWTH>Manual</WWTH>
            </WWTR>
          </WWTHead>
          <WWTBody>
            <WWTR>
              <WWTD>Sent by Source</WWTD>
              <WWTD>{data?.bySource?.Automatic || 0}</WWTD>
              <WWTD>{data?.bySource?.Manual || 0}</WWTD>
            </WWTR>
            <WWTR>
              <WWTD>Opened by Source</WWTD>
              <WWTD>{data?.openedBySource?.Automatic || 0}</WWTD>
              <WWTD>{data?.openedBySource?.Manual || 0}</WWTD>
            </WWTR>
          </WWTBody>
        </WWTable>
      </CardBody>
    </TableCard>
  );
});

export const RequestsByDestination = withInviteReportFilterQuery(({ data = {} }) => {
  return (
    <TableCard>
      <TableCardHeader>
        <CardTitle>Review Requests by Destination</CardTitle>
      </TableCardHeader>
      <CardBody className="mt-3">
        <WWTable id="by-destination">
          <WWTHead>
            <WWTR>
              <WWTH></WWTH>
              <WWTH>SMS</WWTH>
              <WWTH>Email</WWTH>
            </WWTR>
          </WWTHead>
          <WWTBody>
            <WWTR>
              <WWTD>Sent by Destination</WWTD>
              <WWTD>{data?.byDestination?.SMS || 0}</WWTD>
              <WWTD>{data?.byDestination?.EMAIL || 0}</WWTD>
            </WWTR>
            <WWTR>
              <WWTD>Opened by Destination</WWTD>
              <WWTD>{data?.openedByDestination?.SMS || 0}</WWTD>
              <WWTD>{data?.openedByDestination?.EMAIL || 0}</WWTD>
            </WWTR>
          </WWTBody>
        </WWTable>
      </CardBody>
    </TableCard>
  );
});

export const LandingPagePerformance = withInviteReportFilterQuery(({ data = {} }) => {
  return (
    <TableCard>
      <TableCardHeader>
        <CardTitle>Review Landing Page Performance</CardTitle>
      </TableCardHeader>
      <CardBody>
        <WWTable id="review-landing-performance">
          <WWTHead>
            <WWTR>
              <WWTH>Site</WWTH>
              <WWTH>CTR</WWTH>
              <WWTH>Clicks</WWTH>
            </WWTR>
          </WWTHead>
          <WWTBody>
            {data?.landingPagePerformance?.map(([site, ctr, clicks]) => (
              <WWTR key={site}>
                <WWTD>{bySource(site).label || site}</WWTD>
                <WWTD>{Math.round(ctr * 100)}%</WWTD>
                <WWTD>{clicks}</WWTD>
              </WWTR>
            ))}
          </WWTBody>
        </WWTable>
      </CardBody>
    </TableCard>
  );
});

export const CtrByPosition = withInviteReportFilterQuery(({ data = {} }) => {
  return (
    <TableCard>
      <TableCardHeader>
        <CardTitle>CTR by Position</CardTitle>
      </TableCardHeader>
      <CardBody>
        <strong className="ps-4">Primary Section</strong>
        <WWTable id="CRT-PrimarySection">
          <WWTHead>
            <WWTR>
              <WWTH>Position</WWTH>
              <WWTH>CTR</WWTH>
            </WWTR>
          </WWTHead>
          <WWTBody>
            {data?.ctrByPosition?.primary?.map(([position, ctr]) => (
              <WWTR key={position}>
                <WWTD>{position + 1}</WWTD>
                <WWTD>{Math.round(ctr * 100)}%</WWTD>
              </WWTR>
            ))}
          </WWTBody>
        </WWTable>
        <div className="ps-4">
          <strong>Secondary Section</strong>
        </div>
        <WWTable id="CRT-secondary-section">
          <WWTHead>
            <WWTR>
              <WWTH>Position</WWTH>
              <WWTH>CTR</WWTH>
            </WWTR>
          </WWTHead>
          <WWTBody>
            {data?.ctrByPosition?.secondary?.map(([position, ctr]) => (
              <WWTR key={position}>
                <WWTD>{position + 1}</WWTD>
                <WWTD>{Math.round(ctr * 100)}%</WWTD>
              </WWTR>
            ))}
          </WWTBody>
        </WWTable>
      </CardBody>
    </TableCard>
  );
});

export default RequestsBySource;
