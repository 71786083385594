import React, { useState, useEffect, useCallback } from "react";
import { isString, uniqueId } from "lodash";
import { not } from "ramda";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "reactstrap";

/**
 * A clickable item which will copy text on to the clipboard.
 * If no children are supplied a copy icon will render.
 *
 * @param {string} text - The text to put on the clipboard
 */
const PROMPT_TEXT = "Click to copy";
const COPIED_TEXT = "Copied!";

export default function CopyButton({ text, type, color = "primary", children, className }) {
  const [id] = useState(uniqueId("cb"));
  const [tipOpen, setTipOpen] = useState(false);
  const [tipText, setTipText] = useState(PROMPT_TEXT);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied && tipOpen) {
      setTipText(COPIED_TEXT);
    } else {
      setTipText(PROMPT_TEXT);
      setCopied(false);
    }
  }, [copied, tipOpen]);

  let label;
  let copyText = text;
  if (children && type !== "button") {
    if (isString(children)) {
      label = <code className="p-0">{children}</code>;
      copyText = text || children;
    } else {
      label = children;
    }
  } else if (type === "button") {
    //using WWButton prevents CopyToClipboard from doing its thing
    label = (
      <div className={`btn btn-${color}`}>
        <i className={"fa fa-copy me-2 " + className} />
        {children}
      </div>
    );
  } else {
    label = <i className={"fa fa-copy link " + className} />;
  }

  const onClickHandler = useCallback(e => e.stopPropagation(), []);

  const onCopyHandler = useCallback(() => setCopied(true), []);

  const tooltipToggle = useCallback(() => setTipOpen(not), []);

  return (
    <div onClick={onClickHandler}>
      <CopyToClipboard id={id} text={copyText} onCopy={onCopyHandler}>
        {label}
      </CopyToClipboard>
      <Tooltip target={id} isOpen={tipOpen} toggle={tooltipToggle}>
        {tipText}
      </Tooltip>
    </div>
  );
}
