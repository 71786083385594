import qs from "qs";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { encodeForURLQuery } from "../../util/functionUtils";

export default function useURLQuerySetter(replace = false, encoder = encodeForURLQuery) {
  const history = useHistory();
  return useCallback(
    params => {
      const normalized = encoder(params);
      const stringified = qs.stringify(normalized, { indices: false });
      if (stringified !== history.location.search.slice(1)) {
        const update = { ...history.location, search: stringified };
        replace ? history.replace(update) : history.push(update);
      }
    },
    [replace, history]
  );
}
