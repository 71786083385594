import { round } from "lodash";

const RATING_GOOD_THRESHOLD = 4.6;
const RATING_WARN_THRESHOLD = 4.0;

export function reportMapStateToProps(state) {
  return {
    startDate: state.report.startDate,
    endDate: state.report.endDate,
    customerId: state.report.customerId,
    contactId: state.report.contactId
  };
}

export function rating_classes(value) {
  if (value >= RATING_GOOD_THRESHOLD) {
    return "perf-good";
  } else if (value >= RATING_WARN_THRESHOLD) {
    return "perf-warn";
  }
  return "perf-bad";
}

export function rating_colors(value) {
  if (value >= RATING_GOOD_THRESHOLD) {
    return "success";
  } else if (value >= RATING_WARN_THRESHOLD) {
    return "warning";
  }
  return "danger";
}

export function neg_approval_time_colors(value) {
  if (value <= 1) {
    return "success";
  } else if (value <= 2) {
    return "warning";
  }
  return "danger";
}

export function neg_approval_time_classes(rawValue) {
  const value = round(rawValue);

  if (typeof rawValue === "undefined" || isNaN(value)) return "";

  if (value === 0.0) {
    return "perf-good";
  } else if (value <= 1) {
    return "perf-good";
  } else if (value <= 2) {
    return "perf-warn";
  }
  return "perf-bad";
}

export function negpercent_classes(value) {
  if (value >= 20) {
    return "perf-bad";
  } else if (value >= 11) {
    return "perf-warn";
  }
  return "perf-good";
}

export function tag_colors(tag) {
  switch (tag) {
    case "Sales":
      return "#4dbd74";
    case "Service":
      return "#63c2de";
    case "Finance":
      return "#8411ff";
    default:
      return "#20a8d8";
  }
}

export function site_colors(tag) {
  switch (tag) {
    case "Facebook":
      return "#3C5A99";
    case "Google":
      return "#f2bd42";
    case "Dealerrater":
      return "#ea7b34";
    case "Cars.com":
      return "#69339e";
    case "Yelp":
      return "#c3362e";
    case "CarGurus":
      return "#3e99f7";
    default:
      return "#20a8d8";
  }
}

export function format_percent(n, d) {
  if (!d || d === 0) return "0%";
  return Math.ceil((n / d) * 100) + "%";
}

export const defaultPrevented = cb => e => (e.preventDefault(), cb(e));
