import React from "react";
import WWButton from "../Buttons/WWButton";

export default function AddButton({ onClick, size = "md", children, ...props }) {
  return (
    <WWButton iconClass={`fa fa-${size} fa-plus`} color="primary" size={size} onClick={onClick} {...props}>
      {children}
    </WWButton>
  );
}
