import BiaxialLinesChart from "components/Charts/BiaxialLineChart";
import get from "lodash/get";
import moment from "moment";
import colors from "constants/colors";
import WWButton from "components/Buttons/WWButton";
import WidgetCard from "../CommonComponents/WidgetCard";
import { ExampleLine } from "components/Charts/ExampleLine";
import { isEmpty, isNil } from "lodash";
import Skeleton from "react-loading-skeleton";

import styles from "./BiaxialLineChartWidget.module.scss";
const { noDataMessage } = styles;

const SKELETON_HEIGHT = 300;

const BiaxialLineChartWidget = ({
  heading,
  isLoading,
  headingInfo = "Last 30 Days",
  data = [],
  noDataText = "You have no new reviews in the last 30 days",
  chartProps = {},
  children
}) => {
  return (
    <WidgetCard heading={heading} headingInfo={headingInfo}>
      {isLoading ? (
        <Skeleton height={SKELETON_HEIGHT} />
      ) : isEmpty(data) ? (
        <div className={noDataMessage}>{noDataText}</div>
      ) : (
        <>
          {!isEmpty(data) && (
            <BiaxialLinesChart
              data={data}
              options={chartProps}
            />
          )}
        </>
      )}
      {children}
    </WidgetCard>
  );
};

export const defaultBiaxialLineTooltipFormatter =
  ({ leftLabel, leftValue, rightLabel, rightValue }) =>
  ({ active, payload }) =>
    active && payload && payload.length > 0 ? (
      <BiaxialLineTooltip
        data={payload[0].payload}
        leftLabel={leftLabel}
        leftValue={leftValue}
        rightLabel={rightLabel}
        rightValue={rightValue}
      />
    ) : null;

export const BiaxialLineTooltip = ({
  leftLabel,
  rightLabel,
  leftValue,
  rightValue,
  data,
  format = "ddd, MMM Do",
  valueRenderer = defaultTooltipValueRenderer
}) => (
  <div className="btn btn-light">
    <div className={`d-flex flex-column align-items-start flex-grow-1`}>
      {leftLabel && (
        <span className="mb-2">
          <strong>{leftLabel}</strong>
        </span>
      )}
      <span className="d-flex flex-nowrap align-items-center">
        <ExampleLine />
        {!isNil(get(data, `${leftValue}.value`))
          ? `${moment(get(data, `${leftValue}.timestamp`)).format(format)}: ${valueRenderer(
              get(data, `${leftValue}.value`)
            )}`
          : "No Data"}
      </span>
      {rightLabel && (
        <span className="mb-2">
          <strong>{rightLabel}</strong>
        </span>
      )}
      <span className="d-flex flex-nowrap align-items-center">
        <ExampleLine color={colors.chartStrokeNavy} />
        {!isNil(get(data, `${rightValue}.value`))
          ? `${moment(get(data, `${rightValue}.timestamp`)).format(format)}: ${valueRenderer(
              get(data, `${rightValue}.value`)
            )}`
          : "No Data"}
      </span>
    </div>
  </div>
);

export const defaultTooltipValueRenderer = v => (v === null ? "n/a" : v);

export default BiaxialLineChartWidget;
