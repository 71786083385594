import * as client from "./apiClient";

const baseUri = "tagSets";

export function loadTags() {
  return client.get(`${baseUri}/tags`);
}

export function loadAgencyTags(agencyId) {
  return client.get(`${baseUri}/agency/${agencyId}`);
}

export function loadDistinctAudienceTags() {
  return client.get(`${baseUri}/contacts/audience`);
}
