import { isNil, zip } from "lodash";
import moment from "moment";

/**
 * @param {number[]} stat
 * @param {number[]} comparison
 * @param {string[]} bucketsStat
 * @param {string[]} bucketsComparison
 */

export const convertDataToComparisonLinesChartFormat = (stat, comparison, bucketsStat, bucketsComparison) => {
  return zip(stat, comparison).map(([s, c], i) => {
    let ret = {};

    ret.stat = !isNil(s)
      ? {
          value: s,
          timestamp: moment(bucketsStat[i]).unix()
        }
      : null;

    ret.comparison = !isNil(c)
      ? {
          value: c,
          timestamp: moment(bucketsComparison[i]).unix()
        }
      : null;

    return ret;
  });
};
