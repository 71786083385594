// uncomment this line if you have something to import from your scss module
import { useContext } from "react";
import styles from "./Page.module.scss";
import { CSSTransition } from "react-transition-group";
import { useReduxAt } from "../../../../hooks/stateHooks";
import { ContainerContext } from "../NavigableModalContainer/NavigableModalContainer";

const { focused, zoomedout, transparent, opaque, transitioning, container, duration } = styles;

const classNames = {
  enterActive: `${focused} ${opaque} ${transitioning}`,
  enterDone: `${focused} ${opaque}`,
  exit: `${zoomedout} ${transparent}`
};

export default function Page({ id, children }) {
  const context = useContext(ContainerContext);
  const section = useReduxAt(`${context.reduxRoot}/section`, undefined);
  return (
    <CSSTransition
      in={section === id}
      timeout={parseInt(duration, 10)}
      mountOnEnter
      unmountOnExit
      appear
      classNames={classNames}
    >
      <div className={`${container}`}>{children}</div>
    </CSSTransition>
  );
}
