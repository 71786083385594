import isUrl from "is-url";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Tooltip,
  UncontrolledDropdown
} from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import * as api from "../../api/inviteConfigurationApi";
import { AuthorizationRequiredToRender } from "../../components/Auth/Authorization";
import { permissions } from "../../components/Auth/permissions";
import { Card, CardBody, CardHeader, CardHeaderAction, CardHeaderActions, CardTitle } from "../../components/Card";
import CustomerFilter from "../../components/Customer/CustomerFilter";
import Switch from "../../components/Form/Switch";
import TextEditor from "../../components/Form/TextEditor";
import withConfirmation from "../../components/Form/withConfirmation";
import { errorCaughtNotifier, useLocalNotifications } from "../../components/Notifications/notification";
import { WWTabs } from "../../components/WWTabs";
import {
  useCustomerInviteConfiguration,
  useCustomersWithProductLoader,
  useLoggedInUserInfo,
  useProductCustomers,
  useUpdateCustomerInviteConfiguration
} from "../../hooks/customerHooks";
import { isCurrentUserInGroup } from "../../util/userUtils";
import { AutoreplyMessaging, defaultAutoreplyMessageText } from "./Configuration/AutoreplyMessaging";
import InviteDeliveryRuleConfiguration from "./InviteDeliveryRuleConfiguration";
import { PrimarySiteButton, SecondarySiteButton } from "./ReviewSiteButton";
import { EditableList, useDragStart } from "../../components/EditableList/EditableList";
import { bySource } from "data/sites";
import { inviteCustomerProductsKeys } from "data/products";
import { staffNotificationPlaceholders, noStaffInviteNotificationPlaceholders } from "data/editorPlaceholders";

import styles from "./Configuration/InvitePageSiteOrder.module.scss";
import { getCustomSiteError } from "../../util/validators";

const { dragButton } = styles;
const initialNewSitePayload = { name: "", url: "" };

export const ConfigurationPage = () => {
  const loggedInUserInfo = useLoggedInUserInfo();
  const notifier = useLocalNotifications();

  const [customers, setProduct] = useProductCustomers(
    isCurrentUserInGroup(permissions.REVIEW_INVITE_MANAGE) ? "REVIEW_INVITE" : "INVITE_VIDEO"
  );

  const [selectedCustomer, setSelectedCustomer] = useState(customers[0] || {});
  useEffect(() => {
    setSelectedCustomer(customers[0] || {});
  }, [customers]);

  const updateInviteConfiguration = useUpdateCustomerInviteConfiguration(selectedCustomer.id);
  const [configuration, setConfigurationCustomer, reignite] = useCustomerInviteConfiguration(selectedCustomer.id);
  useEffect(() => {
    setConfigurationCustomer(selectedCustomer.id);
  }, [selectedCustomer]);

  const [customs, setCustoms] = useState(configuration.config.customSites || []);
  const [options, setOptions] = useState(configuration.sites);
  const [primaries, setPrimaries] = useState(configuration.config.primarySiteOrder);
  const [secondaries, setSecondaries] = useState(configuration.config.secondarySiteOrder || []);
  const [repEmailText, setRepEmailText] = useState(configuration.config.emailMessage || "");
  const [repSmsText, setRepSmsText] = useState(configuration.config.smsMessage || "");
  const [noRepEmailText, setNoRepEmailText] = useState(configuration.config.noRepEmailMessage || "");
  const [noRepSmsText, setNoRepSmsText] = useState(configuration.config.noRepSmsMessage || "");
  const [localLandingPageText, setLocalLandingPageText] = useState(configuration.config.landingPageText || "");
  const [localNoRepLandingPageText, setLocalNoRepLandingPageText] = useState(
    configuration.config.noRepLandingPageText || ""
  );
  const [localVideoLandingPageText, setLocalVideoLandingPageText] = useState(
    configuration.config.videoLandingPageText || ""
  );

  const [autoreplySmsText, setAutoreplySmsText] = useState(
    configuration.config.inboundAutoreplyMessage || defaultAutoreplyMessageText
  );
  const [inboundAutoreplyMessageEnabled, setInboundAutoreplyMessageEnabled] = useState(
    configuration.config.inboundAutoreplyEnabled ?? false
  );
  const [invitationTextSelected, setInvitationTextSelected] = useState("sms");
  const [landingPageTextSelected, setLandingPageTextSelected] = useState(
    isCurrentUserInGroup(permissions.REVIEW_INVITE_MANAGE) ? "review" : "video"
  );
  const [delayConfig, setDelayConfig] = useState(configuration.config.delayConfigurations);
  const [videoInvitePercentage, setVideoInvitePercentage] = useState(configuration.config.videoInvitePercentage || 0);
  const [videoFiveStarFollowup, setVideoFiveStarFollowup] = useState(
    configuration.config.videoFiveStarFollowup || false
  );

  useEffect(() => {
    const {
      config: {
        primarySiteOrder,
        customSites = [],
        secondarySiteOrder = [],
        emailMessage = "",
        smsMessage = "",
        noRepEmailMessage = "",
        noRepSmsMessage = "",
        landingPageText = "",
        noRepLandingPageText = "",
        videoLandingPageText = "",
        inboundAutoreplyMessage = "",
        inboundAutoreplyEnabled = true,
        delayConfigurations,
        videoInvitePercentage,
        videoFiveStarFollowup
      },
      sites
    } = configuration;
    setCustoms(prev => (isEqual(prev, customSites) ? prev : customSites));
    setPrimaries(prev => (isEqual(prev, primarySiteOrder) ? prev : primarySiteOrder));
    setSecondaries(prev => (isEqual(secondarySiteOrder, prev) ? prev : secondarySiteOrder));
    setRepEmailText(emailMessage);
    setRepSmsText(smsMessage);
    setNoRepEmailText(noRepEmailMessage);
    setNoRepSmsText(noRepSmsMessage);
    setLocalLandingPageText(landingPageText);
    setLocalNoRepLandingPageText(noRepLandingPageText);
    setLocalVideoLandingPageText(videoLandingPageText);
    setAutoreplySmsText(inboundAutoreplyMessage);
    setInboundAutoreplyMessageEnabled(inboundAutoreplyEnabled);
    setDelayConfig(delayConfigurations);
    setVideoInvitePercentage(videoInvitePercentage);
    setVideoFiveStarFollowup(videoFiveStarFollowup);
  }, [configuration]);

  useEffect(() => {
    setOptions(prev => {
      const newopts = [...configuration.sites.filter(v => !v.custom), ...customs];
      return isEqual(prev, newopts) ? prev : newopts;
    });
  }, [customs, configuration]);

  const [primariesOptions, setPrimariesOptions] = useState([]);
  useEffect(() => {
    setPrimariesOptions(prev => {
      const newopts = options.filter(({ name }) => !primaries.includes(name));
      return isEqual(prev, newopts) ? prev : newopts;
    });
  }, [primaries, options]);

  const [secondariesOptions, setSecondariesOptions] = useState([]);
  useEffect(() => {
    setSecondariesOptions(prev => {
      const newopts = options.filter(
        ({ name }) => !secondaries.includes(name) && !(primaries.length === 1 && name === primaries[0])
      );
      return isEqual(prev, newopts) ? prev : newopts;
    });
  }, [primaries, options, secondaries]);

  const [newSite, setNewSite] = useState(initialNewSitePayload);
  const onNewItemChange = useCallback(patch => setNewSite(prev => ({ ...prev, ...patch })), []);
  const onNewItemAdd = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setNewSite(initialNewSitePayload);
      setCustoms(prev => [...prev, newSite]);
    },
    [newSite]
  );

  const validationError = useMemo(() => getCustomSiteError(options, newSite.url, newSite.name), [options, newSite]);

  const [tooltipActive, setTooltipActive] = useState(undefined);
  const activateTooltipOn = useCallback(
    i => active => (active ? setTooltipActive(i) : setTooltipActive(undefined)),
    []
  );

  const onExistingItemRemove = useCallback(
    item => () => {
      setCustoms(data => data.filter(v => v !== item));
      setPrimaries(data => data.filter(v => v !== item.name));
      setSecondaries(data => data.filter(v => v !== item.name));
    },
    []
  );

  const onExistingItemUpdate = useCallback(
    current => update => {
      setCustoms(data => data.map(v => (v === current ? update : v)));
      setPrimaries(data => data.map(v => (v === current.name ? update.name : v)));
      setSecondaries(data => data.map(v => (v === current.name ? update.name : v)));
    },
    []
  );

  const binder = useDragStart();
  const routerHistory = useHistory();
  const [repEmailTextValid, setRepEmailTextValid] = useState(true);
  const [repSmsTextValid, setRepSmsTextValid] = useState(true);
  const [noRepEmailTextValid, setNoRepEmailTextValid] = useState(true);
  const [noRepSmsTextValid, setNoRepSmsTextValid] = useState(true);
  const [landingPageTextValid, setLandingPageTextValid] = useState(true);
  const [noRepLandingPageTextValid, setNoRepLandingPageTextValid] = useState(true);
  const [videoLandingPageTextValid, setVideoLandingPageTextValid] = useState(true);

  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    const {
      config: {
        primarySiteOrder,
        customSites,
        secondarySiteOrder,
        emailMessage,
        smsMessage,
        noRepEmailMessage,
        noRepSmsMessage,
        landingPageText,
        noRepLandingPageText,
        videoLandingPageText,
        inboundAutoreplyMessage,
        inboundAutoreplyEnabled,
        delayConfigurations
      }
    } = configuration;
    setDirty(
      false ||
        !isEqual(primaries, primarySiteOrder) ||
        !isEqual(secondaries, secondarySiteOrder) ||
        !isEqual(customSites, customs) ||
        smsMessage !== repSmsText ||
        emailMessage !== repEmailText ||
        noRepEmailMessage !== noRepEmailText ||
        noRepSmsMessage !== noRepSmsText ||
        delayConfigurations !== delayConfig ||
        inboundAutoreplyMessage !== autoreplySmsText ||
        landingPageText !== localLandingPageText ||
        noRepLandingPageText !== localNoRepLandingPageText ||
        videoLandingPageText !== localVideoLandingPageText ||
        inboundAutoreplyEnabled !== inboundAutoreplyMessageEnabled ||
        videoInvitePercentage !== configuration.config?.videoInvitePercentage ||
        videoFiveStarFollowup !== configuration.config?.videoFiveStarFollowup
    );
  }, [
    primaries,
    secondaries,
    customs,
    repSmsText,
    repEmailText,
    noRepEmailText,
    noRepSmsText,
    localLandingPageText,
    localNoRepLandingPageText,
    localVideoLandingPageText,
    autoreplySmsText,
    inboundAutoreplyMessageEnabled,
    configuration,
    delayConfig,
    videoInvitePercentage,
    videoFiveStarFollowup
  ]);

  const revertConfig = useCallback(() => {
    api
      .removeCustomConfig(selectedCustomer.id)
      .then(() => reignite())
      .catch(errorCaughtNotifier(notifier));
  }, [reignite, selectedCustomer]);

  const [configurationAllowedForSaving, setConfigurationAllowingForSaving] = useState(false);
  useEffect(() => {
    setConfigurationAllowingForSaving(
      dirty &&
        repSmsTextValid &&
        repEmailTextValid &&
        noRepEmailTextValid &&
        noRepSmsTextValid &&
        landingPageTextValid &&
        videoLandingPageTextValid &&
        noRepLandingPageTextValid &&
        !!selectedCustomer?.id
    );
  }, [
    dirty,
    repSmsTextValid,
    repEmailTextValid,
    noRepEmailTextValid,
    noRepSmsTextValid,
    landingPageTextValid,
    videoLandingPageTextValid,
    noRepLandingPageTextValid,
    selectedCustomer
  ]);

  const onSave = useCallback(
    () =>
      updateInviteConfiguration({
        primarySiteOrder: primaries,
        secondarySiteOrder: secondaries,
        customSites: customs,
        smsMessage: repSmsText,
        emailMessage: repEmailText,
        noRepSmsMessage: noRepSmsText,
        noRepEmailMessage: noRepEmailText,
        landingPageText: localLandingPageText,
        noRepLandingPageText: localNoRepLandingPageText,
        videoLandingPageText: localVideoLandingPageText,
        inboundAutoreplyMessage: autoreplySmsText,
        inboundAutoreplyEnabled: inboundAutoreplyMessageEnabled,
        delayConfigurations: delayConfig,
        videoInvitePercentage,
        videoFiveStarFollowup
      })
        .then(() => reignite())
        .catch(errorCaughtNotifier(notifier)),

    [
      primaries,
      secondaries,
      customs,
      repSmsText,
      repEmailText,
      reignite,
      noRepEmailText,
      noRepSmsText,
      localLandingPageText,
      localNoRepLandingPageText,
      localVideoLandingPageText,
      delayConfig,
      autoreplySmsText,
      inboundAutoreplyMessageEnabled,
      videoInvitePercentage,
      videoFiveStarFollowup
    ]
  );

  const RevertButton = useMemo(
    () =>
      withConfirmation(
        <CardHeaderAction color="warning" onClick={revertConfig}>
          Revert
        </CardHeaderAction>,
        {},
        true,
        "This will revert your configuration back to default. Any other locations using this configuration will also go back to default.",
        "Revert to Default?"
      ),
    [revertConfig]
  );

  const customerFilterLoader = useCustomersWithProductLoader(inviteCustomerProductsKeys); //TODO: needs to pull in all locations with invite_video or review_invite

  const noRepMessageValidator = text => {
    let validationErrors = [];
    const matches = text.match(
      /(\[EMPLOYEE FIRST NAME\]|\[EMPLOYEE LAST NAME\]|\[STAFF FIRST NAME\]|\[STAFF LAST NAME\])/g
    );
    if (matches) {
      validationErrors = matches.map(match => `${match} cannot be used in alternative messaging.`);
    }
    return validationErrors;
  };

  const invitationTabsOptions = useMemo(
    () => [
      {
        label: "SMS",
        active: invitationTextSelected === "sms",
        onClick: () => setInvitationTextSelected("sms")
      },
      {
        label: "Email",
        active: invitationTextSelected === "email",
        onClick: () => setInvitationTextSelected("email")
      }
    ],
    [invitationTextSelected]
  );
  const landingPageTabsOptions = useMemo(
    () => [
      {
        label: "Review",
        active: landingPageTextSelected === "review",
        onClick: () => setLandingPageTextSelected("review"),
        hide: !isCurrentUserInGroup([permissions.REVIEW_INVITE_MANAGE])
      },
      {
        label: "Video",
        active: landingPageTextSelected === "video",
        onClick: () => setLandingPageTextSelected("video"),
        hide: !isCurrentUserInGroup([permissions.VIDEO_MANAGE])
      }
    ],
    [landingPageTextSelected]
  );

  return (
    <>
      <div className="py-4 px-md-4 container-fluid">
        <Row>
          <Col className="d-flex">
            <Card className="me-auto">
              <InputGroup>
                <InputGroupText className="fa fa-users d-none d-sm-block" />
                <CustomerFilter
                  name="customer"
                  clearButton={false}
                  value={[selectedCustomer.id]}
                  onChange={v => {
                    const value = v.target.value[0] || {};
                    setSelectedCustomer(value);
                  }}
                  loader={customerFilterLoader}
                />
              </InputGroup>
            </Card>
          </Col>
          <Col className="d-flex">
            <Card className="ms-auto">
              <CardHeaderActions>
                <CardHeaderAction disabled={!configurationAllowedForSaving} color="primary" onClick={onSave}>
                  Save
                </CardHeaderAction>
                {!!selectedCustomer.id && get(configuration.config.ownedBy, "id") === selectedCustomer.id && (
                  <RevertButton />
                )}
                <CardHeaderAction
                  disabled={!configuration.config.id || !selectedCustomer?.id}
                  color="secondary"
                  onClick={() => routerHistory.push("/invite/configure/" + configuration.config.id + "/history")}
                  className="ms-2"
                >
                  History
                </CardHeaderAction>
              </CardHeaderActions>
              {repSmsTextValid &&
              repEmailTextValid &&
              noRepEmailTextValid &&
              noRepSmsTextValid &&
              landingPageTextValid &&
              videoLandingPageTextValid &&
              noRepLandingPageTextValid ? null : (
                <p className="text-danger">*please check for errors on page</p>
              )}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <AuthorizationRequiredToRender roles={permissions.REVIEW_INVITE_MANAGE}>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <CardTitle>Invite Page Configuration</CardTitle>
                    </CardHeader>
                    <CardBody>
                      {!selectedCustomer.id ? (
                        <div className="d-flex flex-column align-items-center pt-4">Please, select customer</div>
                      ) : (
                        <Row>
                          <Col className="d-flex flex-column align-items-center pt-4">
                            <p className="fw-bold text-center">Hi, [Customer Name]</p>
                            <p className="fw-bold text-center">
                              How was your experience with {loggedInUserInfo.name.split(/\s+/gi)[0]} at{" "}
                              <span className="text-nowrap">{selectedCustomer.companyName}</span>?
                            </p>
                            <div className="d-flex flex-column mx-auto align-items-stretch">
                              <EditableList
                                data={primaries}
                                onChange={newPrimaries => setPrimaries(newPrimaries)}
                                keyer={x => x}
                              >
                                {(onDragStart, item, _, list) => (
                                  <div className="d-flex align-items-stretch flex-row w-100">
                                    <Button
                                      className={
                                        "flex-grow me-2 border-0 bg-transparent" +
                                        (list.length <= 1 ? " invisible " : " ") +
                                        dragButton
                                      }
                                      disabled={list.length <= 1}
                                      {...binder(list.length > 1 ? onDragStart : noop)}
                                    >
                                      <span className="text-muted h3">≡</span>
                                    </Button>
                                    <PrimarySiteButton site={item.toUpperCase()} />
                                    <Button
                                      className={
                                        "fa fa-trash-o flex-grow ms-2 border-0 bg-transparent" +
                                        (list.length <= 1 ? " invisible" : "")
                                      }
                                      color="warning"
                                      disabled={list.length <= 1}
                                      onClick={() => setPrimaries(prev => prev.filter(prim => prim !== item))}
                                    />
                                  </div>
                                )}
                              </EditableList>
                              <UncontrolledDropdown className="w-100 mb-3">
                                <DropdownToggle
                                  disabled={primariesOptions.length === 0}
                                  className="w-100 my-1"
                                  color="light"
                                >
                                  + Add Site
                                </DropdownToggle>
                                <DropdownMenu>
                                  {primariesOptions.map(v => (
                                    <DropdownItem
                                      key={v.name}
                                      onClick={() => {
                                        setPrimaries(prev => [...prev, v.name]);
                                        setSecondaries(prev => prev.filter(item => item !== v.name));
                                      }}
                                    >
                                      {bySource(v.name).label || v.name}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              {secondaries.length > 0 ? (
                                <>
                                  <p className="text-center font-italic fw-bold text-muted">other options</p>
                                  <EditableList data={secondaries} onChange={updated => setSecondaries(updated)}>
                                    {(onDragStart, item, _, list) => (
                                      <div className="d-flex align-items-stretch flex-row w-100">
                                        <Button
                                          className={
                                            "flex-grow me-2 border-0 bg-transparent d-flex align-items-center" +
                                            (list.length <= 1 ? " invisible " : " ") +
                                            dragButton
                                          }
                                          disabled={list.length <= 1}
                                          {...binder(list.length > 1 ? onDragStart : noop)}
                                        >
                                          <span className="text-muted h3">≡</span>
                                        </Button>
                                        <div className="w-100 my-auto">
                                          <SecondarySiteButton site={item.toUpperCase()} />
                                        </div>
                                        <Button
                                          className="fa fa-trash-o flex-grow ms-2 border-0 bg-transparent"
                                          color="warning"
                                          onClick={() => setSecondaries(prev => prev.filter(prim => prim !== item))}
                                        />
                                      </div>
                                    )}
                                  </EditableList>
                                </>
                              ) : (
                                <p className="text-muted d-flex justify-content-center">No secondary links</p>
                              )}
                              <UncontrolledDropdown className="w-100">
                                <DropdownToggle
                                  disabled={
                                    secondariesOptions.length === 0 || (primaries.length <= 1 && options.length <= 1)
                                  }
                                  className="w-100 my-1"
                                  color="light"
                                >
                                  + Add Site
                                </DropdownToggle>
                                <DropdownMenu>
                                  {secondariesOptions.map(v => (
                                    <DropdownItem
                                      key={v.name}
                                      onClick={() => {
                                        setSecondaries(prev => [...prev, v.name]);
                                        setPrimaries(prev => prev.filter(item => item !== v.name));
                                      }}
                                    >
                                      {bySource(v.name).label || v.name}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AuthorizationRequiredToRender>
            <AuthorizationRequiredToRender roles={permissions.REVIEW_INVITE_MANAGE}>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <CardTitle>Delivery Rules</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <InviteDeliveryRuleConfiguration
                        customerId={selectedCustomer.id}
                        rules={delayConfig}
                        onChange={config => setDelayConfig(config)}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AuthorizationRequiredToRender>
            <AuthorizationRequiredToRender roles={permissions.VIDEO_MANAGE}>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <CardTitle>Video Invite BETA</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup
                        disabled
                        className="flex-grow-1 bg-light border py-2 my-1 d-flex flex-row flex-nowrap align-items-center"
                      >
                        <Switch
                          onChange={() => setVideoFiveStarFollowup(prev => !prev)}
                          checked={videoFiveStarFollowup}
                          className="align-self-center m-0 mt-2"
                        />
                        <FormText className="m-2">
                          5-star Follow-up {videoFiveStarFollowup ? <span className="text-success">on</span> : "off"}
                        </FormText>
                      </FormGroup>
                      <FormGroup className="flex-grow-1 bg-light border py-2 my-1 d-flex flex-column align-items-start p-3">
                        <FormText>Percentage of video invites to be sent</FormText>
                        <InputGroup className="d-flex flex-row flex-nowrap align-items-center">
                          <Input
                            type="range"
                            value={videoInvitePercentage}
                            step={1}
                            min={0}
                            max={100}
                            className="w-75"
                            onChange={v => setVideoInvitePercentage(v.target.value)}
                          />
                          <Input
                            type="number"
                            value={videoInvitePercentage}
                            step={1}
                            min={0}
                            max={100}
                            onChange={v => setVideoInvitePercentage(v.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AuthorizationRequiredToRender>
          </Col>
          <Col>
            <AuthorizationRequiredToRender roles={permissions.REVIEW_INVITE_MANAGE}>
              <Card>
                <CardHeader>
                  <CardTitle>Custom sites</CardTitle>
                </CardHeader>
                <CardBody>
                  {!selectedCustomer.id ? (
                    <div className="d-flex flex-column align-items-center pt-4">Please, select customer</div>
                  ) : (
                    <>
                      {customs.length > 0 ? (
                        customs.map((v, i) => (
                          <div className="mb-2 d-flex flex-row flex-nowrap" key={i}>
                            <CustomSiteEntry data={v} onChange={onExistingItemUpdate(v)} />
                            <Tooltip
                              target={`removeExistingItem-${i}`}
                              toggle={primaries.length === 1 && primaries[0] === v.name ? activateTooltipOn(i) : noop()}
                              isOpen={i === tooltipActive}
                              autohide={true}
                            >
                              You can not remove site, that is the last in primaries section. Primaries section must not
                              be empty.
                            </Tooltip>
                            <div id={`removeExistingItem-${i}`}>
                              <Button
                                disabled={primaries.length === 1 && primaries[0] === v.name}
                                className="fa fa-trash ms-2"
                                color="warning"
                                onClick={onExistingItemRemove(v)}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="text-muted d-flex justify-content-center">No custom sites defined</p>
                      )}
                      <Form className="mt-2" onSubmitCapture={onNewItemAdd}>
                        <FormGroup>
                          <div className="d-flex flex-nowrap align-items-stretch">
                            <CustomSiteEntry data={newSite} onChange={onNewItemChange} />
                            <Button
                              type="submit"
                              className="fa fa-plus ms-2"
                              color="primary"
                              disabled={!!validationError}
                            />
                          </div>
                          <Input type="hidden" invalid={newSite !== initialNewSitePayload && !!validationError} />
                          {validationError ? <FormFeedback>{validationError}</FormFeedback> : null}
                        </FormGroup>
                      </Form>
                    </>
                  )}
                </CardBody>
              </Card>
            </AuthorizationRequiredToRender>
            <AuthorizationRequiredToRender roles={permissions.REVIEW_INVITE_MANAGE}>
              <Card>
                <CardHeader>
                  <CardTitle>Invitation Text</CardTitle>
                </CardHeader>
                <CardBody>
                  {!selectedCustomer.id ? (
                    <div className="d-flex flex-column align-items-center pt-4">Please, select a customer</div>
                  ) : (
                    <>
                      <WWTabs tabs={invitationTabsOptions} align="left" className="tabbed_navigation" />
                      <Card className="bg-light border-top-0">
                        {(invitationTextSelected === "sms" && (
                          <div>
                            <Alert color="light" fade={false}>
                              <small>
                                The following templates will apply to all invitations. "Reply STOP to opt-out" will be
                                added to all SMS messages automatically.
                              </small>
                            </Alert>
                            <h5 className="text-dark">
                              Recommended Messaging{" "}
                              <i id="recommended-messging-info" className="fa fa-info-circle ms-2" />
                              <UncontrolledTooltip target="recommended-messging-info">
                                Personalizing content for your customers can increase engagement. Using "Placeholders",
                                you can input personalized tokens into SMS review requests. This information will
                                automatically be pulled from the CRM be Widewail. This is the Widewail recommended
                                template. Edit the text here to update communications for all future customers.
                              </UncontrolledTooltip>
                            </h5>
                            <TextEditor
                              value={repSmsText}
                              onChange={setRepSmsText}
                              onValidChange={setRepSmsTextValid}
                              placeholders={staffNotificationPlaceholders}
                            />
                            <h5 className="text-dark mt-4">
                              Alternative Messaging{" "}
                              <i id="alternative-messging-info" className="fa fa-info-circle ms-2" />
                              <UncontrolledTooltip target="alternative-messging-info">
                                If no employee is attached to the transaction in your CRM, Widewail will automatically
                                display the message below.
                              </UncontrolledTooltip>
                            </h5>
                            <TextEditor
                              value={noRepSmsText}
                              onChange={setNoRepSmsText}
                              onValidChange={setNoRepSmsTextValid}
                              validator={noRepMessageValidator}
                              placeholders={noStaffInviteNotificationPlaceholders}
                            />
                            <AutoreplyMessaging
                              messageText={autoreplySmsText}
                              onTextChange={setAutoreplySmsText}
                              enabled={inboundAutoreplyMessageEnabled}
                              onToggled={setInboundAutoreplyMessageEnabled}
                            />
                          </div>
                        )) ||
                          (invitationTextSelected === "email" && (
                            <div>
                              <h5 className="text-dark">
                                Recommended Messaging{" "}
                                <i id="recommended-messging-info" className="fa fa-info-circle ms-2" />
                                <UncontrolledTooltip target="recommended-messging-info">
                                  Personalizing content for your customers can increase engagement. Using
                                  "Placeholders", you can input personalized tokens into Email review requests. This
                                  information will automatically be pulled from the CRM be Widewail. This is the
                                  Widewail recommended template. Edit the text here to update communications for all
                                  future customers.
                                </UncontrolledTooltip>
                              </h5>
                              <TextEditor
                                value={repEmailText}
                                onChange={setRepEmailText}
                                onValidChange={setRepEmailTextValid}
                                placeholders={staffNotificationPlaceholders}
                              />
                              <h5 className="text-dark mt-4">
                                Alternative Messaging{" "}
                                <i id="alternative-messging-info" className="fa fa-info-circle ms-2" />
                                <UncontrolledTooltip target="alternative-messging-info">
                                  If no employee is attached to the transaction in your CRM, Widewail will automatically
                                  display the message below.
                                </UncontrolledTooltip>
                              </h5>
                              <TextEditor
                                value={noRepEmailText}
                                onChange={setNoRepEmailText}
                                onValidChange={setNoRepEmailTextValid}
                                validator={noRepMessageValidator}
                                placeholders={noStaffInviteNotificationPlaceholders}
                              />
                            </div>
                          ))}
                      </Card>
                    </>
                  )}
                </CardBody>
              </Card>
            </AuthorizationRequiredToRender>
            <AuthorizationRequiredToRender roles={[permissions.REVIEW_INVITE_MANAGE, permissions.VIDEO_MANAGE]}>
              <Card>
                <CardHeader>
                  <CardTitle>Landing Page Text</CardTitle>
                </CardHeader>
                <CardBody>
                  {!selectedCustomer.id ? (
                    <div className="d-flex flex-column align-items-center pt-4">Please, select a customer</div>
                  ) : (
                    <>
                      <WWTabs tabs={landingPageTabsOptions} align="left" className="tabbed_navigation" />
                      <Card className="bg-light border-top-0">
                        <Alert color="light" fade={false}>
                          <small>Modifying the Landing Page text will override translations</small>
                        </Alert>
                        {(landingPageTextSelected === "review" && (
                          <div>
                            <h5 className="text-dark">
                              Recommended Messaging{" "}
                              <i id="recommended-messging-info" className="fa fa-info-circle ms-2" />
                              <UncontrolledTooltip target="recommended-messging-info">
                                Personalizing content for your customers can increase engagement. Using "Placeholders",
                                you can input personalized tokens into the Invite. This information will automatically
                                be pulled from the CRM to Widewail. This is the Widewail recommended template, if left
                                blank Widewail will use this template. Add text here to update communications for all
                                future customers.
                              </UncontrolledTooltip>
                            </h5>
                            <TextEditor
                              value={localLandingPageText}
                              onChange={setLocalLandingPageText}
                              onValidChange={setLandingPageTextValid}
                              placeholders={staffNotificationPlaceholders}
                              placeholder="Hi [CUSTOMER FIRST NAME] How was your experience with [STAFF FIRST NAME] at [LOCATION]"
                            />
                            <h5 className="text-dark mt-4">
                              Alternative Messaging{" "}
                              <i id="alternative-messging-info" className="fa fa-info-circle ms-2" />
                              <UncontrolledTooltip target="alternative-messging-info">
                                If no employee is attached to the transaction in your CRM, Widewail will automatically
                                display the message below.
                              </UncontrolledTooltip>
                            </h5>
                            <TextEditor
                              value={localNoRepLandingPageText}
                              onChange={setLocalNoRepLandingPageText}
                              onValidChange={setNoRepLandingPageTextValid}
                              validator={noRepMessageValidator}
                              placeholders={noStaffInviteNotificationPlaceholders}
                              placeholder="Hi [CUSTOMER FIRST NAME] How was your experience at [LOCATION]"
                            />
                          </div>
                        )) ||
                          (landingPageTextSelected === "video" && (
                            <div>
                              <h5 className="text-dark">
                                Recommended Messaging{" "}
                                <i id="recommended-messging-info" className="fa fa-info-circle ms-2" />
                                <UncontrolledTooltip target="recommended-messging-info">
                                  Personalizing content for your customers can increase engagement. Using
                                  "Placeholders", you can input personalized tokens into the Invite. This information
                                  will automatically be pulled from the CRM be Widewail. This is the Widewail
                                  recommended template, if left blank Widewail will use this template. Add text here to
                                  update communications for all future customers.
                                </UncontrolledTooltip>
                              </h5>
                              <TextEditor
                                value={localVideoLandingPageText}
                                onChange={setLocalVideoLandingPageText}
                                onValidChange={setVideoLandingPageTextValid}
                                placeholders={staffNotificationPlaceholders}
                                placeholder="We want to hear from you about your experience with [LOCATION]"
                              />
                            </div>
                          ))}
                      </Card>
                    </>
                  )}
                </CardBody>
              </Card>
            </AuthorizationRequiredToRender>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const CustomSiteEntry = ({ data = { name: "", url: "" }, onChange = noop }) => {
  const onNameChange = useCallback(({ target: { value } }) => onChange({ ...data, name: value }), [data, onChange]);
  const onURLChange = useCallback(
    ({ target: { value } }) =>
      onChange({
        url: value,
        name: !data.name && isUrl(value) ? new URL(value).hostname.split(".").slice(0, -1).join(" ") : data.name
      }),
    [data, onChange]
  );

  return (
    <InputGroup>
      <InputGroupText className="fa fa-globe" />
      <Input type="url" name="url" placeholder="Site URL" value={data.url} onChange={onURLChange} />
      <Input type="text" name="name" placeholder="Site Name" value={data.name} onChange={onNameChange} />
    </InputGroup>
  );
};
