import classNames from "classnames";
import DeleteButton from "components/Buttons/DeleteButton";
import Time from "components/DateTime/Time";
import StatusInfo from "components/Table/StatusInfo/StatusInfo";
import { WWTD, WWTR } from "components/Table/WWTable";
import { startsWith } from "lodash";
import { useMemo } from "react";
import { Alert } from "reactstrap";
import { createSiteLogoUrl } from "util/siteUtils";
import styles from "../SiteRowRenderers.module.scss";

const SquareSiteRowRenderer = ({ index, customer, rowClicked, site, onRemove }) => {
  const onRowClick = useMemo(() => (site.userLinking ? () => rowClicked(site.source) : undefined), [site]);
  return (
    <>
      <WWTR noHover>
        <WWTD className="ps-0 pt-0">
          <div className={classNames(" d-flex flex-nowrap align-items-center mb-2", { "mt-5": index !== 0 })}>
            <img className={styles.logo} alt={`Square Logo`} src={createSiteLogoUrl(site.source)} />
            <h4 className={"m-0 ms-2 " + styles.siteLabel}>Square</h4>
          </div>
        </WWTD>
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
      </WWTR>
      {site.siteInfo?.map(info =>
        info.locations?.length > 0 ? (
          info.locations?.map(location => (
            <WWTR key={location.id} onClick={onRowClick}>
              <WWTD>
                <span className="d-flex align-items-center">{location.name}</span>
              </WWTD>
              <WWTD>
                <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                  <span className="font-xs">LOCATION ID</span> {location.id}
                </div>
              </WWTD>
              <WWTD>
                <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                  <span className="font-xs">ACCESS TOKEN</span>{" "}
                  <span className="text-capitalize">{info.tokenStatus.toLowerCase()}</span>
                </div>
              </WWTD>
              <WWTD></WWTD>
              <WWTD>
                {info?.importStatus && (
                  <>
                    <StatusInfo
                      status={startsWith(info.importStatus, "ERROR")}
                      statusColorMapper={{ true: "danger", false: "success" }}
                    >
                      {info.importStatus}
                    </StatusInfo>
                    <Time
                      className="font-xs text-uppercase"
                      withIcon={false}
                      format="ago"
                      date={info.lastImportSuccess}
                    />
                    <span className="font-xs"> AGO</span>
                  </>
                )}
              </WWTD>
              <WWTD></WWTD>
            </WWTR>
          ))
        ) : (
          <WWTR onClick={onRowClick}>
            <WWTD>
              <Alert color={"danger"} className="ms-3 mb-0">
                To finish your linking please add a location
              </Alert>
            </WWTD>
            <WWTD>
              <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                <span className="font-xs">ACCESS TOKEN</span>{" "}
                <span className="text-capitalize">{info.tokenStatus.toLowerCase()}</span>
              </div>
            </WWTD>
            <WWTD className="pt-0" />
            <WWTD className="pt-0" />
            <WWTD className="pt-0" />
            <WWTD>
              <DeleteButton
                confirmationPrompt="Widewail will no longer import data from this location. Existing reviews will be marked read-only."
                type="button"
                size="sm"
                tooltip="Remove Square"
                squareButton
                iconClass="fa fa-chain-broken"
                stopPropagation
                onClick={e => {
                  e.stopPropagation();
                  onRemove(site.customerField, info.id);
                }}
              >
                {null}
              </DeleteButton>
            </WWTD>
          </WWTR>
        )
      )}
    </>
  );
};

export default SquareSiteRowRenderer;
