import React, { useContext, useMemo, useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Label, Input } from "reactstrap";
import classNames from "classnames";
import { FormContext } from "./Form";

import Skeleton from "components/Skeleton";

import styles from "./Switch.module.scss";

const { disabledToggleClass, loadingSkeletonClass, notTransitionSwitchHandlerClass } = styles;

const defaultSwitchClassName = "switch switch-text switch-pill switch-lg";

const ToggleSwitch = ({
  label = "",
  icon = null,
  name,
  id = name,
  onChange,
  value,
  onLabel = "On",
  offLabel = "Off",
  labelWidth = 2,
  inputWidth = 10,
  minWidth = 60,
  className = "mb-3",
  labelClass = "text-muted",
  type = "primary",
  disabled = false,
  loading = false,
  children,
  checked,
  ...props
}) => {
  const context = useContext(FormContext);
  useEffect(() => {
    context && context.registerField(name);
  }, [name]);

  const [touched, setTouched] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState();

  useEffect(() => {
    setCheckboxValue(checked || !context ? checked : get(context.state, context.parseFieldName(name)));
  }, [checked, context, name]);

  const changeHandler = useCallback(
    event => {
      if (!touched) {
        setTouched(true);
      }
      if (onChange) {
        return onChange(event);
      } else if (context) {
        return context.setCheckboxField({ target: { name, ...event.target } });
      }
    },
    [onChange, name, touched]
  );

  return (
    <div className={classNames("d-flex align-items-center flex-wrap-reverse", className)}>
      <div xs="12" md={inputWidth} className="me-2" style={{ minWidth: `${minWidth}px` }}>
        {loading ? (
          <Skeleton className={loadingSkeletonClass} />
        ) : (
          <Label
            className={classNames("m-0", defaultSwitchClassName, `switch-${type}`, {
              [disabledToggleClass]: disabled
            })}
          >
            <Input
              {...props}
              disabled={disabled || loading}
              type="checkbox"
              className="switch-input"
              value={value}
              name={name}
              id={id}
              checked={!!checkboxValue}
              onChange={changeHandler}
            />

            <span className="switch-label" data-on={onLabel} data-off={offLabel} />
            {/* "key" uses here to guarantee re-rendering and instant class change applying */}
            {!loading && (
              <span
                className={classNames("switch-handle", { [notTransitionSwitchHandlerClass]: !touched })}
                key={`${touched ? "transition" : "no-transition"}`}
              />
            )}

            {icon && <i className={`switch-icon fa fa-fw ${icon}`} />}
          </Label>
        )}
        {children}
      </div>
      {label && labelWidth !== 0 && (
        <Label className={"fw-normal py-0 m-0 " + labelClass} role="button" htmlFor={name}>
          {label}
        </Label>
      )}
    </div>
  );
};

ToggleSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  inputWidth: PropTypes.number,
  labelWidth: PropTypes.number,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  className: PropTypes.string
};

export default React.memo(ToggleSwitch);
