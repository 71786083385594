import React, { memo } from "react";
import classNames from "classnames";
import { DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { RESPONDER_FLAG_VALUES } from "data/options";

import styles from "./FlagSelectDropdown.module.scss";

const {
  clearItemClass,
  flagBlueClass,
  flagGreenClass,
  flagPurpleClass,
  flagBrownClass,
  flagYellowClass,
  flagTealClass,
  flagDropdownToggleClass
} = styles;

const addFlagIconClass = colorClass => classNames("fa fa-flag", colorClass);

export const RESPONDER_FLAG_OPTIONS = [
  { iconClass: addFlagIconClass(flagBlueClass), value: RESPONDER_FLAG_VALUES.followUp, label: "Blue" },
  { iconClass: addFlagIconClass(flagGreenClass), value: RESPONDER_FLAG_VALUES.green, label: "Green" },
  { iconClass: addFlagIconClass(flagTealClass), value: RESPONDER_FLAG_VALUES.teal, label: "Teal" },
  { iconClass: addFlagIconClass(flagPurpleClass), value: RESPONDER_FLAG_VALUES.purple, label: "Purple" },
  { iconClass: addFlagIconClass(flagBrownClass), value: RESPONDER_FLAG_VALUES.brown, label: "Brown" },
  { iconClass: addFlagIconClass(flagYellowClass), value: RESPONDER_FLAG_VALUES.yellow, label: "Yellow" }
];

export const FlagSelectDropdown = ({ onSelectFlag, selectedFlag = null }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="div" role="button" className={flagDropdownToggleClass}>
        <i
          id="responder-flag-toggle"
          className={
            RESPONDER_FLAG_OPTIONS.find(({ value }) => value === selectedFlag)?.iconClass ||
            addFlagIconClass("text-muted")
          }
        />
        <UncontrolledTooltip target="responder-flag-toggle">Set responder flag</UncontrolledTooltip>
      </DropdownToggle>
      <DropdownMenu>
        {RESPONDER_FLAG_OPTIONS.map(({ iconClass, value, label }) => (
          <DropdownItem onClick={() => onSelectFlag(value)} key={value}>
            <i className={iconClass} />
            {label}
          </DropdownItem>
        ))}
        {!!selectedFlag && selectedFlag !== RESPONDER_FLAG_VALUES.doNotRespond && (
          <DropdownItem onClick={() => onSelectFlag(null)}>
            <div className={clearItemClass}>Clear</div>
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default memo(FlagSelectDropdown);
