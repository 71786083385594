import SimpleBar from "simplebar-react";
import { Card } from "components/Card";
import { Slab } from "components/Charts/Slab";
import { withReportFilterQuery } from "components/HOCs/ReportWithFilters";

export const prioritySortingOrder = [
    "Volume",
    "Overall Sentiment",
    "Negative Sentiment",
    "Tour Sentiment",
    "Move in Sentiment",
    "Maintenance Sentiment",
    "Renewal Sentiment",
    "Move out Sentiment",
    "General Sentiment"
  ],
  summaryDataMapper = ({ id, pctChange, value }) => ({
    id,
    pctChange,
    total: value
  }),
  summaryDataSorter = (a, b) => {
    const aIndex = prioritySortingOrder.indexOf(a.id);
    const bIndex = prioritySortingOrder.indexOf(b.id);
    return aIndex >= 0
      ? bIndex >= 0
        ? aIndex - bIndex
        : -1
      : bIndex >= 0
      ? 1
      : a.total === null
      ? b.total === null
        ? 0
        : 1
      : b.total === null
      ? -1
      : b.total - a.total;
  },
  summaryLabelFn = (entry, key) => {
    if (key === "pctChange" || (key === "total" && entry.id === "Negative Sentiment")) {
      return `${Math.round(entry[key] * 100)}%`;
    }

    return entry[key];
  },
  summaryWidgetRenderer = mapper => key => {
    if (key === "id") return entry => <span className="text-dark text-nowrap">{mapper(entry, key)}</span>;

    if (key === "total") return entry => <h3>{entry.total === null ? "N/A" : mapper(entry, key)}</h3>;

    return entry => {
      const value = mapper(entry, key);

      if (entry.pctChange !== null && entry.pctChange > 0) {
        if (entry.id === "Negative Sentiment") {
          return <span className="text-danger">{value}</span>;
        } else {
          return <span className="text-success">{value}</span>;
        }
      } else {
        if (entry.pctChange !== null && entry.pctChange <= 0) {
          if (entry.id === "Negative Sentiment") {
            return <span className="text-success">{value}</span>;
          } else {
            return <span className="text-danger">{value}</span>;
          }
        }
      }

      return <span className="text-dark">N/A</span>;
    };
  };

export function SummaryWidget({ data }) {
  const { summary } = data;

  return (
    <SimpleBar className="simpleBar-print">
      <div className=" screen-flex-nowrap d-flex flex-row justify-content-start">
        {summary?.map(v => (
          <Card className="mx-2 flex-shrink-0 flex-grow-1" key={v.id}>
            <div className="px-2">
              <Slab
                id={v.id}
                pctChange={v.pctChange}
                total={v.total}
                renderer={summaryWidgetRenderer(summaryLabelFn)}
              />
            </div>
          </Card>
        ))}
      </div>
    </SimpleBar>
  );
}

export default withReportFilterQuery({
  url: `/report/sentimentWidget`,
  normalizeResponse: (summary = []) => ({ summary: summary.map(summaryDataMapper).sort(summaryDataSorter) })
})(SummaryWidget);
