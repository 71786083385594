import store from "../redux/store/configureStore";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import some from "lodash/some";
import indexOf from "lodash/indexOf";
import { USE_NEW_REVIEW_FEED_FEATURE_NAME, NewReviewFeedFeatureValues } from "../data/optInFeatures";

export function currentUser() {
  return store.getState().cognito.user;
}

export function currentUserFullName() {
  // use name off cognito user if available, otherwise use current contact
  return store.getState().cognito?.user?.name || currentContactName();
}

export function currentContactName() {
  return store.getState().currentContact.name;
}
export function currentContact() {
  return store.getState().currentContact;
}

export function currentUserEmail() {
  return store.getState().cognito.user.email;
}

export function isUserInGroup(user, groups) {
  if (isEmpty(groups)) return true;
  if (!user) return false;
  if (isString(groups)) return indexOf(user["cognito:groups"], groups) >= 0;
  if (isArray(groups)) return some(user["cognito:groups"], v => groups.indexOf(v) >= 0);
  return false;
}

export function isCurrentUserInGroup(groups) {
  return isUserInGroup(store.getState().cognito.user, groups);
}

export function currentUserHasFeature(feature) {
  const features = store.getState().currentContact?.features;
  return !!features && String(features[feature]) === "true";
}

export function currentUserFeatureValue(feature) {
  const features = store.getState().currentContact?.features;
  return !!features && features[feature];
}

export function shouldRedirectToReviewFeed() {
  return currentUserFeatureValue(USE_NEW_REVIEW_FEED_FEATURE_NAME) === NewReviewFeedFeatureValues.feed;
}

/**
 * Checks to see if the user isn't an agency admin, or has set a customer, brand, or contact.
 * @param {string|null} customer - customer id from customer selector
 * @param {string|null} brand - brand from customer selector
 * @param {string|null} contact - contact from customer selector
 * @returns {boolean} false if the user is an agency admin and the customer, brand, and contact are all missing;
 * true otherwise.
 */
export const hasAgencyAdminSelectedALocation = (customer, brand, contact) => {
  return !isCurrentUserInGroup("AGENCY_ADMIN") || !!customer || !!brand || !!contact;
};

/**
 * Checks to see if the user isn't a Widewail admin, or has set a customer, brand, or contact.
 * @param {string|null} customer - customer id from customer selector
 * @param {string|null} brand - brand from customer selector
 * @param {string|null} contact - contact from customer selector
 * @returns {boolean} false if the user is a Widewail admin and the customer, brand, and contact are all missing;
 * true otherwise.
 */
export const hasWidewailAdminSelectedALocation = (customer, brand, contact) => {
  return !isCurrentUserInGroup("WIDEWAIL_ADMIN") || !!customer || !!brand || !!contact;
};

export const isWidewailAdmin = () => {
  return isCurrentUserInGroup("WIDEWAIL_ADMIN");
};

export const isAgencyAdmin = () => {
  return isCurrentUserInGroup("AGENCY_ADMIN");
};

export function isDdc() {
  return get(store.getState(), "currentAgency.name") === "Dealer.com";
}

export function isOhMD() {
  return get(store.getState(), "currentAgency.name") === "OhMD";
}

export function isWidewailTest() {
  return get(store.getState(), "currentAgency.name") === "Widewail Test";
}
