import React from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend, Tooltip } from "recharts";
import map from "lodash/map";

import colors from "../../constants/colors";
import { AxisTypes, LineTypes, Intervals, STROKE_WIDTH, TICK_SIZE_DEFAULT, TICK_SIZE_SM } from "../../constants/chart";

/**
 * @param {{
 *   data?: Array<{
 *     timestamp: number;
 *     <key1>: number;
 *     <key2>: number;
 *     ...
 *     <keyN>: number;
 *   }>;
 *   options?: {
 *     xAxis?: import("recharts").XAxisProps;
 *     yAxis?: import("recharts").YAxisProps;
 *     line?: import("recharts").LineProps;
 *     grid: import("recharts").CartesianGridProps;
 *   };
 *   keys?: Array<string>
 * }} param0
 */
const OverlappingAreaChart = ({ chartData = { keys: [], data: [] }, options }) => {
  return (
    // <Collapse isOpen={chartData?.data?.length > 0}>
    <ResponsiveContainer width="100%" aspect={2.0}>
      <AreaChart data={chartData?.data || []}>
        <Legend verticalAlign="top" iconSize={15} {...(options.legend || {})} />
        <CartesianGrid vertical={false} {...(options.grid || {})} />
        <XAxis
          dataKey="date"
          scale="time"
          type={AxisTypes.number}
          // domain={["dataMin", "dataMax"]}
          tickSize={TICK_SIZE_DEFAULT}
          stroke={colors.chartStrokeGray}
          interval={1}
          {...(options.xAxis || {})}
        />
        <YAxis
          tickSize={TICK_SIZE_SM}
          stroke={colors.chartStrokeGray}
          interval={Intervals.preserveStartEnd}
          {...(options.yAxis || {})}
        />
        <Tooltip {...(options.tooltip || {})} />
        {map(chartData?.keys || [], ({ key, color }, i) => (
          <Area
            type={LineTypes.monotone}
            key={key}
            dot={true}
            dataKey={key}
            stroke={color || colors.chartBlack}
            fill={color || colors.chartBlack}
            fillOpacity="20%"
            legendType="square"
            strokeWidth={STROKE_WIDTH}
            {...(options.line || {})}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
    // </Collapse>
  );
};

export default OverlappingAreaChart;
