import { get, doDelete } from "./apiClient";

const BASE_PATH = "inviteConfigurations";

export function loadConfigurationHistory(configurationId) {
  return get([BASE_PATH, configurationId, "history"]);
}

export function removeCustomConfig(customerId) {
  return doDelete([BASE_PATH, "customer", customerId]);
}
