import { sortBy, uniq } from "lodash";
import { useMemo } from "react";
import { useCurrentAgency } from "../hooks/agencyHooks";

const AUTO_TOPICS = [
  "New Sales",
  "Leasing",
  "Used Sales",
  "Trade-in",
  "Warranty",
  "Illegal",
  "Bait and Switch",
  "Online",
  "Delivery",
  "Remote/remotely",
  "Staff",
  "Pick-up/Drop-off",
  "Facilities",
  "COVID-19",
  "Sales Lead",
  "Service Lead"
];
const PROPERTY_TOPICS = [
  "Common Areas",
  "COVID-19",
  "Fees",
  "Evictions",
  "Fair Housing/Discrimination",
  "In-Unit Condition",
  "Leasing",
  "Maintenance",
  "Parking",
  "Pests",
  "Renewal Rate",
  "Staff",
  "Smart Home Technology"
];
export const DEFAULT_TOPICS = sortBy(uniq([...AUTO_TOPICS, ...PROPERTY_TOPICS]));
// agency specific topic overrides
const AGENCY_TOPICS = {
  "Dealer.com": [
    "At home / from home",
    "Bait and Switch",
    "COVID-19",
    "Delivery",
    "Facilities",
    "False advertising",
    "Illegal",
    "Leasing",
    "New Sales",
    "Online",
    "Pick-up / Drop-off",
    "Remote / remotely",
    "Staff",
    "Sales Lead",
    "Service Lead",
    "Trade-in",
    "Unethical",
    "Used Sales",
    "Video",
    "Warranty",
    "Website"
  ],
  "BH Management": PROPERTY_TOPICS,
  "Garber Automotive": AUTO_TOPICS
};

export const useTopics = () => {
  const agency = useCurrentAgency();
  return useMemo(() => AGENCY_TOPICS[agency?.name] || DEFAULT_TOPICS, [agency]);
};

export const topicsForAgency = agency => AGENCY_TOPICS[agency?.name] || DEFAULT_TOPICS;
