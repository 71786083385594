import { isArray } from "lodash";
import memoize from "memoizee";

export const toggleValueInArray = (array = [], value) => {
  let newArray = [...array, value];
  if (array.includes(value)) {
    return array.filter(v => v !== value);
  } else {
    return newArray;
  }
};

export const arrayOfOneOrLess = array => isArray(array) && array.length <= 1;

export const sortDatasets = (datasets, sortingOrder = undefined) => {
  const LEAST_INDEX = -2;
  const NOT_IN_ARRAY_INDEX = -1;
  const getIndex = value => (sortingOrder ? sortingOrder.indexOf(value) : NOT_IN_ARRAY_INDEX);
  return datasets.sort((a, b) => {
    const aIndex = a.id === "TOTAL" ? LEAST_INDEX : getIndex(a.id);
    const bIndex = b.id === "TOTAL" ? LEAST_INDEX : getIndex(b.id);
    return aIndex - bIndex;
  });
};

export const sortDatasetsForPropertyManagement = datasets =>
  sortDatasets(datasets, ["Tour", "Move in", "Maintenance", "Renewal", "Move out", "General"]);

export const getEntityById = memoize((entities, entityId) => entities.find(({ id }) => entityId === id));
