import uniqueId from "lodash/uniqueId";
import React, { useCallback, useContext } from "react";

export const ModalsContext = React.createContext();

/**
 * @param {React.Dispatch<React.SetStateAction<{id: string; open: boolean; factory: () => JSX.Element}[]>>} setter
 * @returns {<T = any>(factory: (resolve: (v: T) => void) => JSX.Element) => Promise<[T, () => void]>}
 */
export const useModalsContextValue = setter =>
  useCallback(
    factory =>
      new Promise(resolve => {
        const id = uniqueId("modal");
        const close = () => setter(prev => prev.map(v => (v.id === id ? { ...v, open: false } : v)));
        const resolutionCallback = resolution => resolve([close, resolution]);
        setter(prev => [
          ...prev,
          {
            id,
            open: true,
            factory: () => factory(resolutionCallback)
          }
        ]);
      }),
    [setter]
  );

/**
 * @type {() => <T = any>(factory: (resolve: (v: T) => void) => JSX.Element) => Promise<[T, () => void]>}
 */
export const useModal = () => useContext(ModalsContext);

export const withModals = BaseComponent => ({ ...props }) => {
  return <ModalsContext.Consumer>{riser => <BaseComponent {...props} modal={riser} />}</ModalsContext.Consumer>;
};

/**
 * @param {Promise<[T, () => void]>} riser
 * @returns {Promise<T>}
 */
export const autoclose = promise => promise.then(([close, resolution]) => (close(), resolution));
