import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { usePost } from "../dataHooks";

/**
 * Generates function that will send request for video review removal
 * to backend on invocation
 *
 * @returns {[
 *   (id: string, reason: string) => Promise<any>,
 *   import("react-query").UseMutationResult
 * ]}
 */
export const useVideoTestimonialRemover = () => {
  const post = usePost();
  const client = useQueryClient();
  const mutation = useMutation(
    ({ id, reason }) => post(`/media/${id}`, { data: { status: "REMOVED", statusReason: reason } }),
    {
      onSuccess: () => client.invalidateQueries("/interactions/media")
    }
  );

  return useMemo(() => [(id, reason) => mutation.mutateAsync({ id, reason }, reason), mutation], [mutation]);
};

export default useVideoTestimonialRemover;
