import { stubFalse } from "lodash";
import noop from "lodash/noop";
import uniqueId from "lodash/uniqueId";
import moment from "moment";
import PropTypes from "prop-types";
import { always, until } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CalendarDay, DateRangePicker, DayPickerSingleDateController } from "react-dates";
import { sameDate } from "../../util/dateUtils";
import HtmlSelectField from "../Form/HtmlSelectField";
import { datePickerOptions, datePickerOptionsKeys } from "data/dateRangeOptions";
import FormField from "components/Form/FormField";
import { DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, UncontrolledDropdown } from "reactstrap";
import InnerDateRangeInput from "components/FacetedSearch/Filters/DateRangeFilter/InnerDateRangeInput";

/**
 *
 * @param {Moment} start start date
 * @param {Moment} end end date
 * @param {String} initialFocusedInput START_DATE, END_DATE or null (default null)
 * @param {Function} onChange Fired when a new start and end date pair is selected, (start,end) => .. for ranged, (date) => ... for single
 * @param {Function} onStartChange Fired when a new start date is selected
 * @param {Function} onEndChange Fired when a new end date is selected
 * @param {Function} isOutsideRange Optionally determine if a date is outside of the acceptable range
 * @param {Boolean} clearEndOnStartChange Force a new end date selection when a new start date is selected
 * @param {Boolean} range - True = date range picker, false = single date picker
 * @param {Boolean} quickSelect - True = quick Select dropdown
 */

const DatePicker = ({
  start = moment().startOf("month"),
  end = moment(),
  muted = null,
  initialFocusedInput = null,
  onChange = noop,
  onStartChange = noop,
  onEndChange = noop,
  isOutsideRange = stubFalse,
  clearEndOnStartChange = true,
  range = true,
  numberOfMonths = 1,
  keepOpenOnDateSelect = false,
  quickSelect = false,
  children,
  ...props
}) => {
  const [focusedInput, setFocusedInput] = useState(initialFocusedInput);
  const [localStart, setLocalStart] = useState(start);
  const [localEnd, setLocalEnd] = useState(end);

  const startDateId = useMemo(() => uniqueId("datepicker"), []);
  const endDateId = useMemo(() => uniqueId("datepicker"), []);
  const orientation = useMemo(() => (window.matchMedia("(max-width: 720px)").matches ? "vertical" : "horizontal"), []);
  const dayRenderer = useCallback(
    props => {
      const isMuted = !!muted && moment(props.day).isBetween(muted[0], muted[1], "day", "[]");
      return (
        <CalendarDay
          {...props}
          renderDayContents={day =>
            isMuted ? (
              <div className="bg-light text-dark w-100 h-100 d-flex align-items-center justify-content-center">
                {day.format("D")}
              </div>
            ) : (
              day.format("D")
            )
          }
        />
      );
    },
    [muted]
  );

  const onDateRangeChange = useCallback(
    ({ startDate, endDate }) => {
      setLocalStart(startDate);
      onStartChange(startDate);
      if (clearEndOnStartChange && !sameDate(startDate)(localStart)) {
        setLocalEnd(null);
        onEndChange(null);
      } else {
        setLocalEnd(endDate);
        onEndChange(endDate);
        onChange(startDate, endDate);
      }
    },
    [clearEndOnStartChange, onChange, onStartChange, onEndChange, localStart]
  );

  const onSingleDateChange = useCallback(
    date => {
      setLocalStart(date);
      onStartChange(date);
      onChange(date);
    },
    [onChange, onStartChange]
  );

  const onQuickSelectDateChange = useCallback(
    event => {
      const value = event.target.value;
      setLocalStart(datePickerOptions[value].startDate);
      onStartChange(datePickerOptions[value].startDate);
      setLocalEnd(datePickerOptions[value].endDate);
      onEndChange(datePickerOptions[value].endDate);
      onChange(datePickerOptions[value].startDate, datePickerOptions[value].endDate);
      setFocusedInput(null);
    },
    [onChange, onStartChange, onEndChange]
  );

  useEffect(() => {
    setLocalStart(newDateSetter(start));
  }, [start]);
  useEffect(() => {
    setLocalEnd(newDateSetter(end));
  }, [end]);

  const calendarInfo = useCallback(() => {
    return (
      <div className="pb-3 d-flex align-items-start">
        {(quickSelect && (
          <div className="ms-3 w-100">
            <p className="d-inline">Date Range: </p>
            <HtmlSelectField
              name="quickSelector"
              className="col-4 ms-3"
              inline={true}
              options={datePickerOptionsKeys}
              onChange={onQuickSelectDateChange}
            />
          </div>
        )) ||
          null}
        {children}
      </div>
    );
  }, [quickSelect, children]);

  if (range) {
    return (
      <DateRangePicker
        startDate={localStart}
        startDateId={startDateId}
        endDate={localEnd}
        endDateId={endDateId}
        minimumNights={0}
        onDatesChange={onDateRangeChange}
        focusedInput={focusedInput}
        onFocusChange={v => setFocusedInput(v)}
        isOutsideRange={isOutsideRange}
        keepOpenOnDateSelect={keepOpenOnDateSelect}
        renderCalendarInfo={calendarInfo}
        renderCalendarDay={dayRenderer}
        numberOfMonths={numberOfMonths}
        orientation={orientation}
        {...props}
      />
    );
  } else {
    return (
      <DayPickerSingleDateController
        date={localStart}
        onDateChange={onSingleDateChange}
        focused={focusedInput}
        onFocusChange={v => setFocusedInput(v)}
        numberOfMonths={numberOfMonths}
        isOutsideRange={isOutsideRange}
        {...props}
      />
    );
  }
};

DatePicker.propTypes = {
  start: PropTypes.object,
  end: PropTypes.object,
  initialFocusedInput: PropTypes.string,
  onChange: PropTypes.func,
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func,
  isOutsideRange: PropTypes.func,
  clearEndOnStartChange: PropTypes.bool,
  quickSelect: PropTypes.bool
};

export default DatePicker;

export const useComparisonRange = (range, mode) => {
  const [source, setSource] = useState(range);
  useEffect(() => {
    setSource(prev => (range[0]?.isSame(prev[0]) && range[1]?.isSame(prev[1]) && prev) || range);
  }, [range]);
  return useMemo(
    () =>
      mode === "RELATIVE"
        ? [
            source[0].clone().subtract(source[1]?.clone(0).diff(source[0], "day") + 1, "day"),
            source[0].clone().subtract(1, "day")
          ]
        : mode === "PREVIOUS_MONTH"
          ? [source[0].clone().subtract(1, "month"), source[1].clone().subtract(1, "month")]
          : [source[0].clone().subtract(1, "year"), source[1].clone().subtract(1, "year")],
    [source, mode]
  );
};

export const SelectedDayLegend = ({ day = 12, className }) => (
  <div className={`d-flex flex-row flex-nowrap align-items-center ${className}`}>
    <div
      className="CalendarDay__selected_span d-flex align-items-center justify-content-center"
      style={{ width: 20, height: 20 }}
    >
      <small>{day}</small>
    </div>
    <div className="ps-2 text-muted text-nowrap">
      <small>Selected</small>
    </div>
  </div>
);

export const ComparisonDayLegend = ({ day = 12, className }) => (
  <div className={`d-flex flex-row flex-nowrap align-items-center ${className}`}>
    <div
      className="border bg-light text-dark d-flex align-items-center justify-content-center"
      style={{ width: 20, height: 20 }}
    >
      <small>{day}</small>
    </div>
    <div className="ps-2 text-muted text-nowrap">
      <small>Comparison</small>
    </div>
  </div>
);

const newDateSetter = v => until(sameDate(v), always(v));

export const DatePickerDropdownField = ({
  value,
  onChange,
  label = "Date",
  placeholder = "Select date",
  isOutsideRange
}) => {
  return (
    <FormField className="w-50" name="date" label={label} inline={false}>
      <div className="d-flex content-stretch flex-nowrap flex-grow">
        <UncontrolledDropdown className="flex-grow-1">
          <DropdownToggle tag="div">
            <InputGroup>
              <InputGroupText className="fa fa-calendar" />
              <Input
                type="text"
                value={value ? moment(value).format("L") : ""}
                setDate={noop}
                placeholder={placeholder}
              />
            </InputGroup>
          </DropdownToggle>
          <DropdownMenu className="p-0 bg-transparent border-0 overflow-visible" tag="div">
            <DatePicker start={value} onStartChange={onChange} range={false} isOutsideRange={isOutsideRange} />
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </FormField>
  );
};
