import { updateSyncMode } from "api/listingsApi";
import { permissions } from "components/Auth/permissions";
import Time from "components/DateTime/Time";
import ToggleSwitch from "components/Form/Switch";
import { errorCaughtNotifier, useLocalNotifications } from "components/Notifications/notification";
import { WWTD, WWTR } from "components/Table/WWTable";
import LastSyncedInfo from "components/Listings/LastSyncedInfo";
import { isEmpty, groupBy, noop } from "lodash";
import { not } from "ramda";
import { useMemo, useState } from "react";
import { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { createSiteLogoUrl } from "util/siteUtils";
import { apiFieldNameForDisplay } from "util/stringUtils";
import { isCurrentUserInGroup } from "util/userUtils";

const ICON_WIDTH = 18;

const ListingsTableRow = ({ listing, refresh = noop }) => {
  const history = useHistory();
  const [listingSync, setListingSync] = useState(listing.syncMode === "READ_WRITE");

  const listingDetailsPath = useMemo(() => `/listings/${listing.customerId}/${listing.id}`, [listing]);

  const toDetailsPage = useCallback(() => {
    history.push(listingDetailsPath);
  }, [listingDetailsPath, history]);

  const notify = useLocalNotifications();

  const onSyncToggle = useCallback(
    event => {
      event.stopPropagation();
      updateSyncMode(listing.customerId, listing.id, event.target.checked ? "READ_WRITE" : "OFF")
        .then(() => {
          setListingSync(not);
          refresh();
        })
        .catch(errorCaughtNotifier(notify));
    },
    [listing, notify, refresh]
  );
  const mostRecentChanges = useMemo(() => Object.entries(groupBy(listing.changes.fieldChanges, "revisionDate"))[0], [
    listing
  ]);

  return (
    <WWTR onClick={toDetailsPage}>
      <WWTD>
        <div>{listing.businessName}</div>
        <span className="fs-1 text-uppercase text-muted">{listing?.companyName}</span>
      </WWTD>
      <WWTD>
        <LastSyncedInfo listing={listing} isSyncModeOn={listingSync} />
      </WWTD>
      <WWTD>
        {!isEmpty(mostRecentChanges) && (
          <>
            <div className={`d-flex justify-content-start align-items-center`}>
              <img
                src={createSiteLogoUrl(mostRecentChanges?.[1]?.[0].source)}
                alt={mostRecentChanges?.[1]?.[0].source}
                width={ICON_WIDTH}
              />
              <span className="ms-1">{apiFieldNameForDisplay(mostRecentChanges?.[1]?.[0]?.fieldName)}</span>
              {mostRecentChanges?.[1]?.length > 1 && (
                <span className="ms-1 fs-1 text-muted">+{mostRecentChanges?.[1]?.length - 1} others</span>
              )}
            </div>
            <Time
              className="fs-1 text-uppercase text-muted"
              withIcon={false}
              date={mostRecentChanges?.[0]}
              format="ago"
            />{" "}
            <span className="fs-1 text-uppercase text-muted">ago</span>
          </>
        )}
      </WWTD>
      <WWTD onClick={e => e.stopPropagation()}>
        <ToggleSwitch
          name={`sync_mode_${listing.id}`}
          className="m-0"
          onChange={onSyncToggle}
          onClick={e => e.stopPropagation()}
          checked={listingSync}
          disabled={!isCurrentUserInGroup(permissions.LISTINGS_MANAGE)}
        />
      </WWTD>
      <WWTD className="text-end">
        <Link className="btn btn-secondary text-uppercase btn-outline-secondary">Details</Link>
      </WWTD>
    </WWTR>
  );
};

export default ListingsTableRow;
