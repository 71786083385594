import { last } from "lodash";
import isEmpty from "lodash/isEmpty";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { saveReply } from "../../../api/reviewApi";
import { useLocalNotifications } from "../../../components/Notifications/notification";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../../redux/actions/ajaxStatusActions";
import { QUICK_NOTIFICATION_TIMEOUT } from "../../../util/constants";
import { currentUserFullName } from "../../../util/userUtils";

export default () => {
  const notify = useLocalNotifications();
  const dispatch = useDispatch();
  const author = useMemo(() => currentUserFullName(), []);

  return useCallback(
    (review, content, thirdpartyParentId, publish) =>
      new Promise((resolve, reject) => {
        if (publish && isEmpty(review.tags)) {
          reject("Review category is required");
        } else {
          dispatch(beginAjaxCall());
          saveReply(
            {
              author,
              content,
              createdOn: new Date().toISOString(),
              source: "WIDEWAIL",
              review: { id: review.id },
              thirdpartyParentId
            },
            publish
          )
            .then(({ data }) => {
              dispatch(endAjaxCall());
              notify({
                title: publish ? "Published" : "Saved",
                icon: "success",
                timeout: QUICK_NOTIFICATION_TIMEOUT
              });
              resolve(data);
            })
            .catch(err => {
              dispatch(ajaxCallError());
              reject(err);
            });
        }
      }),
    [author]
  );
};
