import classNames from "classnames";
import WWButton from "components/Buttons/WWButton";
import { Card } from "components/Card";
import CircularIcon from "components/Icons/CircularIcon/CircularIcon";
import { useState } from "react";
import { Alert, Modal } from "reactstrap";
import styles from "./Hipaa.module.scss";
const { modal, container, listContainer, listItem } = styles;

export default function HipaaModal({ onGoBack, isHipaa }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Modal className={modal} fade={false} isOpen={isHipaa && isOpen}>
      <div className="p-5">
        <h4 className="text-center">HIPAA Compliance Required</h4>
        <p className="text-muted text-center mb-5">
          HIPAA requires that we do not disclose any patient information even if the review mentions it directly.
        </p>
        <div className={classNames(container, "mb-5")}>
          <div className={listContainer}>
            <div className={listItem}>
              <CircularIcon icon="close" /> <span>Do not use the reviewer’s name</span>
            </div>
            <div className={listItem}>
              <CircularIcon icon="close" />
              <span>Do not use the word “patient”</span>
            </div>
            <div className={listItem}>
              <CircularIcon icon="close" />
              <span>Do not mention repeat or future visits</span>
            </div>
          </div>
          <div className={listContainer}>
            <div className={listItem}>
              <CircularIcon icon="close" />
              <span>Do not use “you” or “your”</span>
            </div>
            <div className={listItem}>
              <CircularIcon icon="close" />
              <span>Do not acknowledge/confirm/deny any facts about the person’s visit</span>
            </div>
            <div className={listItem}>
              <CircularIcon icon="close" />
              <span>Do not mention specifics</span>
            </div>
          </div>
        </div>
        <div className={container}>
          <Card className="p-2" color="light">
            <Alert color="danger">
              <div className={listItem}>
                <CircularIcon icon="close" color="light" iconColor="danger" />
                <span>Sorry you had to wait.</span>
              </div>
            </Alert>
            <Alert className="mb-0">
              <div className={listItem}>
                <CircularIcon icon="check" color="light" iconColor="success" />
                <span>We strive to provide the shortest wait times possible.</span>
              </div>
            </Alert>
          </Card>
          <Card className="p-2" color="light">
            <Alert color="danger">
              <div className={listItem}>
                <CircularIcon icon="close" color="light" iconColor="danger" />
                <span>We apologize that you had this stressful experience.</span>
              </div>
            </Alert>
            <Alert className="mb-0">
              <div className={listItem}>
                <CircularIcon icon="check" color="light" iconColor="success" />
                <span>Our staff aims to make all visits as stress free as possible.</span>
              </div>
            </Alert>
          </Card>
        </div>
        <div className="d-flex justify-content-end">
          <WWButton iconClass="fa fa-long-arrow-left" color="link" onClick={onGoBack}>
            Go Back
          </WWButton>
          <WWButton color="primary" onClick={() => setIsOpen(false)}>
            Continue
          </WWButton>
        </div>
      </div>
    </Modal>
  );
}
