import * as types from "../actions/actionTypes";

export default function currentAgency(state = {}, action) {
  if (state === undefined) {
    return {};
  } else {
    switch (action.type) {
      // case types.USER_LOAD_CURRENT:
      //     return Object.assign({}, state, { startDate: action.date });

      case types.AGENCY_SET:
        return Object.assign({}, state, { ...action.agency });

      case types.LOGOUT_AGENCY:
        let newState = {};
        return newState;

      default:
        return state;
    }
  }
}
