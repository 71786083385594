import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";
import { FormGroup, Input, UncontrolledTooltip } from "reactstrap";
import { brandSearch } from "../../../../api/servicesApi";
import { useCampaignMessageFlow, useCustomerSmsOrderSubmit } from "../../../../hooks/customerHooks";
import HorizontalSelectField from "../../../Form/HorizontalSelectField";
import { ConfirmationButton } from "../../../Form/withConfirmation";
import { useErrorNotification } from "../../../Notifications/notification";
import FormField from "../../../Form/FormField";
import PhoneNumberField from "../../../Form/PhoneNumberField";
import HostedNumberEditor from "../HostedNumberEditor/HostedNumberEditor";
import { PhoneBrandFlows } from "../../../../data/options";
import { removeUSCodeFromPhone } from "../../../../util/phoneNumberUtils";
import CheckboxLabeled from "../../../Checkbox/Labeled/CheckboxLabeled";
import CampaignEditor, { getEmptyCampaign } from "../CampaignEditor/CampaignEditor";
import { get } from "lodash";

import { useAreaCodeFields } from "../useAreaCodeFields";

const phonePrefix = number => {
  if (number) {
    return number.substring(0, 3);
  }
  return undefined;
};

const VERIFIED_STATUSES = { VERIFIED: "VERIFIED", VETTED_VERIFIED: "VETTED_VERIFIED" };

const BrandSelector = ({ brand, setBrand, searchBrands }) => {
  const STATUS_VALUES = {
    [VERIFIED_STATUSES.VERIFIED]: "Verified",
    [VERIFIED_STATUSES.VETTED_VERIFIED]: "Verified",
    null: "Verification Pending"
  };
  const STATUS_EMOJIS = {
    [VERIFIED_STATUSES.VERIFIED]: "✅️",
    [VERIFIED_STATUSES.VETTED_VERIFIED]: "✅️",
    null: "⏳️"
  };
  const getStyles = (previous, { data }) => ({
    ...previous,
    alignItems: "baseline",
    display: "flex",
    ":before": {
      content: `"${!data?.website ? "❌️" : get(STATUS_EMOJIS, data?.identityStatus, "❌️")} "`,
      display: "block",
      marginRight: 16,
      height: 10,
      width: 10
    },
    ":after": {
      fontStyle: "italic",
      fontSize: "70%",
      textTransform: "uppercase",
      color: "#999",
      content: `"${get(STATUS_VALUES, data?.identityStatus, "Verification Failed")}${
        !data?.website ? " - Website Missing" : ""
      }"`,
      marginLeft: "0.5em",
      flex: 1
    }
  });

  return (
    <>
      <HorizontalSelectField
        async
        defaultOptions
        formFieldClass="flex-grow-1"
        name="existingBrand"
        label="Existing Brand"
        loadOptions={searchBrands}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ brandId }) => brandId}
        isMulti={false}
        creatable={false}
        onChange={event => setBrand(event.target.value)}
        inline={false}
        styleOptions={{
          singleValue: getStyles,
          option: getStyles
        }}
      />
      <small>
        <em>
          The statuses shown here are fetched directly from the TCR. Only verified brands with websites will work. To
          add a website, edit the brand in the <a href="https://csp.campaignregistry.com/brands">Campaign Registry</a>.
        </em>
      </small>
    </>
  );
};

export default function ExistingPhoneBrand({ customer }) {
  const errorNotification = useErrorNotification();

  const [flow, setFlow] = useState("NEW");
  const [phoneNumber, setPhoneNumber] = useState();
  const [loa, setLoa] = useState();
  const [brand, setBrand] = useState();

  const [showCampaignEditor, setShowCampaignEditor] = useState(false);
  const [newCampaign, setNewCampaign] = useState(getEmptyCampaign(customer, null));
  const { data: { messageFlow, messageFlowNeedsReview } = {} } = useCampaignMessageFlow(customer.id);

  const searchBrands = debounce((input, callback) => {
    brandSearch(input).then(callback).catch(errorNotification);
  }, 250);

  const { desiredAreaCode, areaCodefields, fallbackAreaCodes, fallbackCity, fallbackState } = useAreaCodeFields({
    customer
  });

  const valid =
    (flow === PhoneBrandFlows.NEW ? desiredAreaCode : phoneNumber) &&
    brand &&
    Object.keys(VERIFIED_STATUSES).includes(brand.identityStatus) && !!brand.website;

  const submit = useCustomerSmsOrderSubmit(customer.id);

  const submitHandler = () => {
    submit({
      customerId: customer.id,
      desiredAreaCode: flow === PhoneBrandFlows.NEW ? desiredAreaCode : null,
      phoneNumber: flow === PhoneBrandFlows.EXISTING ? phoneNumber : null,
      hosted: !!loa && flow === PhoneBrandFlows.EXISTING,
      brand: { brandId: brand?.brandId, letterOfAuthorization: loa },
      campaign: showCampaignEditor ? newCampaign : null,
      fallbackCity,
      fallbackState,
      fallbackAreaCodes
    });
  };

  // set default message flow
  useEffect(() => {
    setNewCampaign(Object.assign({}, newCampaign, { messageFlow, messageFlowNeedsReview }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNewCampaign, messageFlow, messageFlowNeedsReview]);

  return (
    <>
      <div className="d-flex justify-content-around">
        <FormGroup>
          <Input
            type="radio"
            name="flow"
            onChange={() => setFlow(PhoneBrandFlows.NEW)}
            checked={flow === PhoneBrandFlows.NEW}
          />
          <span>New Number</span>
        </FormGroup>
        <FormGroup>
          <Input
            type="radio"
            name="flow"
            onChange={() => setFlow(PhoneBrandFlows.EXISTING)}
            checked={flow === PhoneBrandFlows.EXISTING}
          />
          <span>Existing Number</span>
        </FormGroup>
      </div>

      {flow === PhoneBrandFlows.EXISTING && <HostedNumberEditor customer={customer} onChange={setLoa} />}

      {flow === PhoneBrandFlows.NEW ? (
        areaCodefields
      ) : (
        <>
          <FormField inline={false} name="phoneNumber" label="Existing Number">
            <PhoneNumberField
              onValueChange={values => setPhoneNumber(values.value)}
              value={removeUSCodeFromPhone(phoneNumber)}
            />
          </FormField>
        </>
      )}
      <hr />
      <BrandSelector brand={brand} setBrand={setBrand} searchBrands={searchBrands} />
      <br />
      <br />

      <CheckboxLabeled
        label="Create new campaign for this brand?"
        checked={showCampaignEditor}
        onToggle={setShowCampaignEditor}
      />
      {showCampaignEditor && (
        <CampaignEditor
          campaign={newCampaign}
          onChange={setNewCampaign}
          messageFlow={messageFlow}
          messageFlowNeedsReview={messageFlowNeedsReview}
        />
      )}
      <div className="d-flex justify-content-center">
        <ConfirmationButton disabled={!valid} onClick={submitHandler}>
          Request Phone Number
        </ConfirmationButton>
      </div>
    </>
  );
}
