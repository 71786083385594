import React from "react";
import PropTypes from "prop-types";
import "./ButtonArea.scss";
import map from "lodash/map";
import isArray from "lodash/isArray";

/**
 * Container that holds a set of buttons that we don't necessarily want in a ButtonGroup.
 */
export default function ButtonArea({ className = "", size = "md", children }) {
  if (!children) {
    return <></>;
  }

  const wrapElement = (elm, key) =>
    elm.type && elm.type.name.endsWith("Button") ? React.cloneElement(elm, { size, key }) : elm;
  return (
    <span className={`button-area ${className}`}>
      {isArray(children) ? map(children, (c, i) => c && wrapElement(c, i)) : wrapElement(children)}
    </span>
  );
}

ButtonArea.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string
};
