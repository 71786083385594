import { isUndefined, isFunction, get, noop, debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Input as _Input } from "reactstrap";
import { FormContext } from "./Form";
import { DEBOUNCE_DELAY_DEFAULT } from "constants/delays";

const isTextField = type => type === "input" || type === "textarea" || type === "text";
const isCheckField = type => type === "checkbox" || type === "radio";

/**
 * Input field which supports debouncing input by default and updating
 * state within a managed form context.
 *
 * Explicitly provided value/onChange will take prescedence.
 */
function Input({
  type = "input",
  value,
  checked,
  onChange,
  name,
  maxTextLength,
  dataLpIgnore = "true",
  autocomplete = "off",
  normalizer,
  debounceDelay = DEBOUNCE_DELAY_DEFAULT,
  ...props
}) {
  const context = useContext(FormContext);
  const changeHandler = useMemo(() => {
    if (onChange) {
      return isFunction(normalizer)
        ? ({ target: { value } }) => {
          onChange({ target: { value: normalizer(value) } });
        }
        : onChange;
    } else if (context) {
      const setter = isFunction(normalizer)
        ? ({ target: { value } }) => context.setTextField({ target: { value: normalizer(value), name } })
        : context.setTextField;
      return isCheckField(type) ? context.setCheckboxField : setter;
    }
    return noop;
  }, [normalizer, onChange, type, context]);
  const inputValue = useMemo(() => {
    // is the value explicitly provided or is a context unavailable?
    if (value || checked || !name || !context) {
      return value || checked;
    } else {
      return get(context.state, context.parseFieldName(name));
    }
  }, [value, checked, context?.state, name]);

  const [_input, setInput] = useState(inputValue || "");

  const debounceCallback = useCallback(
    debounce(event => changeHandler(event), debounceDelay),
    [changeHandler]
  );

  const _inputOnChange = useCallback(
    event => {
      if (isTextField(type) && !isFunction(normalizer)) {
        // reflect typing in the field right away even though we want to
        // fire onChange later
        const value = isUndefined(maxTextLength) ? event.target.value : event.target.value?.slice(0, maxTextLength);
        setInput(value);
        debounceCallback(event);
      } else {
        changeHandler(event);
      }
    },
    [setInput, debounceCallback, changeHandler, normalizer]
  );

  useEffect(() => {
    setInput(inputValue);
  }, [inputValue]);

  useEffect(() => {
    context && context.registerField(name);
  }, [name]);

  return (
    <_Input
      {...props}
      data-lpignore={dataLpIgnore}
      autoComplete={autocomplete}
      checked={isCheckField(type) ? inputValue : false}
      name={name}
      aria-label={name}
      value={type === "file" ? undefined : _input || ""}
      type={type}
      onChange={_inputOnChange}
    />
  );
}

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Input;
