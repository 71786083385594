import { noop } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import { loadCustomer } from "../../api/customerApi";
import { addInteraction } from "../../api/leadsApi";
import DatePicker from "../../components/DateTime/DatePicker";
import StatusMessages from "../../components/Misc/StatusMessages";
import { useLoggedInUserInfo } from "../../hooks/customerHooks";
import { Staff } from "./Staff";

export const AddInteractionWizard = ({
  onInteractionAdded = noop,
  lead: {
    id: leadId,
    customer: { id: customerId }
  }
}) => {
  const [stage, setStage] = useState("form");
  const [errors, setErrors] = useState([]);

  return (
    <>
      {stage === "form" ? (
        <>
          <h3>Add interaction</h3>
          <AddInteractionForm
            customerId={customerId}
            onInteractionAboutToAdd={e =>
              addInteraction({
                invitations: [],
                ...(e.note ? { notes: e.note } : {}),
                representatives: e.representatives.map(([firstName, lastName]) => ({ firstName, lastName })),
                reviews: [],
                tags: [e.department],
                visitTime: e.visitTime
              })(leadId)
                .then(v => onInteractionAdded(v))
                .catch(({ error }) => {
                  setErrors([error]);
                  setStage("failure");
                })
            }
          />
        </>
      ) : stage === "failure" ? (
        <>
          <h3>Something went wrong...</h3>
          <StatusMessages errors={errors} />
        </>
      ) : null}
    </>
  );
};

export const AddInteractionForm = ({ onInteractionAboutToAdd = noop, customerId }) => {
  const userinfo = useLoggedInUserInfo();
  const [department, setDepartment] = useState("Sales");
  const [representatives, setRepresentatives] = useState([userinfo.name.split(/\s+/)]);
  const [note, setNote] = useState("");
  const [visitTime, setVisitTime] = useState(moment());
  const [availableDepartments, setAvailableDepartments] = useState([]);

  useEffect(() => {
    loadCustomer(customerId).then(v => setAvailableDepartments(v.reviewTagSet.tags));
  }, [customerId]);

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        onInteractionAboutToAdd({
          department,
          representatives,
          visitTime: visitTime.toDate(),
          note
        });
      }}
    >
      <FormGroup className="text-center text-sm-start mb-4">
        <Label>Visit Time</Label>
        <Row>
          <Col className="d-flex flex-column align-items-stretch">
            <UncontrolledDropdown className="flex-grow-1">
              <DropdownToggle tag="div">
                <InputGroup>
                  <InputGroupText className="fa fa-calendar" />
                  <Input
                    type="text"
                    value={visitTime.format("L")}
                    placeholder="select schedule date and time"
                    onChange={noop}
                  />
                </InputGroup>
              </DropdownToggle>
              <DropdownMenu className="p-2 my-2 overflow-visible" tag="div">
                <DatePicker range={false} start={visitTime} onStartChange={setVisitTime} />
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className="text-center text-sm-start mb-4">
        <Label>Department</Label>
        <Row>
          <Col className="d-flex flex-column align-items-stretch">
            <ButtonGroup className="flex-grow-1 mt-2">
              {availableDepartments.map(v => (
                <Button key={v} color={department === v ? "primary" : "light"} onClick={() => setDepartment(v)}>
                  {v}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className="text-center text-sm-start mb-4">
        <Label>Staff</Label>
        <Staff staff={representatives} onChange={v => setRepresentatives(v)} />
        <Input type="hidden" invalid={representatives.length === 0} />
        <FormFeedback>At least one representative must be specified</FormFeedback>
      </FormGroup>
      <FormGroup className="text-center text-sm-start mb-4">
        <Label>Notes</Label>
        <Row className="mb-2">
          <Col className="d-flex align-items-center">
            <Input
              type="textarea"
              placeholder="Enter some notes here"
              value={note}
              onChange={e => setNote(e.target.value)}
              className="flex-grow-1"
            />
          </Col>
        </Row>
      </FormGroup>
      <Button disabled={representatives.length === 0} type="submit" color="primary">
        Add
      </Button>
    </Form>
  );
};
