import React, { useCallback } from "react";
import moment from "moment";
import DropdownDateRangePicker from "./DropdownDateRangePicker";
import SidebarDateRangePicker from "./SidebarDateRangePicker";
import WWChip from "components/WWChips/WWChip/WWChip";
import { useFilterValueState } from "hooks/filteringHooks";

const DateRangeFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <DateRangeFilterBar key={props.name} className={className} {...props} />
  );

  const filterComponent = ({ ...props }, className) => (
    <DateRangeFilterSidebar key={props.name} className={className} {...props} />
  );

  const chip = ({ ...props }, className) => <DateRangeChip key={props.name} {...props} className={className} />;

  return {
    barComponent,
    chip,
    filterComponent
  };
};

export const DateRangeFilterBar = ({ name, type, queryParams, className, ...props }) => {
  const [value, setter] = useFilterValueState(name, type, queryParams);
  return (
    <div className={className}>
      <DropdownDateRangePicker onChange={setter} dateRange={value} {...props} />
    </div>
  );
};

export const DateRangeFilterSidebar = ({ name, type, queryParams, className, ...props }) => {
  const [value, setter] = useFilterValueState(name, type, queryParams);
  const momentValues = [value?.[0] && moment(value?.[0]), value?.[1] && moment(value?.[1])];
  return (
    <div className={className}>
      <SidebarDateRangePicker onChange={setter} value={momentValues} {...props} />
    </div>
  );
};

export const DateRangeChip = ({ name, type, queryParams, className, iconClass, defaultValue, ...props }) => {
  const [value, setter] = useFilterValueState(name, type, queryParams);
  return value?.[0] && value?.[1] ? (
    <WWChip
      iconClass={iconClass}
      key={name}
      className="overflow-visible"
      onClick={() => setter(defaultValue)}
      {...props}
    >
      {moment(value?.[0]).format("L")}
      {" ― "}
      {moment(value?.[1]).format("L")}
    </WWChip>
  ) : null;
};

export default DateRangeFactory;
