import React, { createContext, useCallback, useEffect, useState, memo } from "react";
import NavigableModalContainer from "components/Modals/NavigableModal/NavigableModalContainer/NavigableModalContainer";
import Pages from "components/Modals/NavigableModal/Pages/Pages";
import Page from "components/Modals/NavigableModal/Page/Page";
import { useFormState } from "hooks/stateHooks";
import Form from "components/Form/Form";
import { isEqual } from "lodash";
import { autoclose } from "components/Modals";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import { useErrorNotification } from "components/Notifications/notification";
import StatusBar from "views/Settings/StatusBar/StatusBar";
import FieldsRenderer from "./BulkFieldsRenderer/BulkFieldsRenderer";
import SingletonButtonDropdown from "components/Dropdowns/SingletonButtonDropdown/SingletonButtonDropdown";
import useBulkUpdates from "hooks/bulkUpdatesHooks";

const DEFAULT_BULK_UPLOAD_VALUE = {};

export const BulkUpdateContext = createContext(null);

const BulkUpdatesModal = ({
  options = [],
  bulkUpdateCallback,
  selectedIds,
  filters,
  numberOfIds,
  products,
  label = "locations",
  type = "location"
}) => {
  const [bulkFormFields, setBulkFormFields] = useState([]);
  const [bulkUpdateValues, setBulkUpdateValues, setBulkUpdateField] = useFormState(DEFAULT_BULK_UPLOAD_VALUE);

  const confirm = useConfirmationDialog();

  const { setBulkUpdatesOpened } = useBulkUpdates();

  const errorNotify = useErrorNotification();

  const onFieldAdd = useCallback(field => {
    setBulkFormFields(prev => [...prev, field]);
  }, []);

  const onSubmit = useCallback(
    () =>
      autoclose(
        confirm({
          title: `Update ${numberOfIds} ${label}`,
          body: (
            <p>
              Are you sure you want to update all <strong className="text-danger">{numberOfIds}</strong> {label}
            </p>
          )
        })
      ).then(confirmed => {
        if (confirmed) {
          bulkUpdateCallback(selectedIds, filters, bulkUpdateValues)
            .then(res => {
              setStatus("success");
              setBulkUpdatesOpened(false);
              setBulkFormFields([]);
              setBulkUpdateValues(DEFAULT_BULK_UPLOAD_VALUE);
            })
            .catch(error => {
              setStatus("failure");
              errorNotify(error);
            });
        } else {
          setStatus("dirty");
        }
      }),
    [selectedIds, filters, bulkUpdateValues, numberOfIds]
  );

  // Status bar integration...
  const [status, setStatus] = useState("clean");
  useEffect(() => {
    if (!!bulkUpdateValues) {
      if (status === "clean" && !isEqual(bulkUpdateValues, DEFAULT_BULK_UPLOAD_VALUE)) {
        setStatus("dirty");
      } else if (status === "dirty" && isEqual(bulkUpdateValues, DEFAULT_BULK_UPLOAD_VALUE)) {
        setStatus("clean");
      } else if (status === "savingRequested") {
        setStatus("saving");
        onSubmit();
      } else if (status === "cleanRequested") {
        setBulkUpdateValues(DEFAULT_BULK_UPLOAD_VALUE);
        setBulkFormFields([]);
        setStatus("clean");
      } else if (status === "success") {
        const timeoutid = setTimeout(() => setStatus("clean"), 1000);
        return () => clearTimeout(timeoutid);
      }
    }
  }, [status, bulkUpdateValues, onSubmit]);
  // ...Status bar integration

  return (
    <BulkUpdateContext.Provider value={{ type, bulkUpdateValues, setBulkUpdateValues, setBulkFormFields }}>
      <NavigableModalContainer
        onClosed={() => {
          setBulkUpdateValues(DEFAULT_BULK_UPLOAD_VALUE);
          setBulkFormFields([]);
          setStatus("clean");
        }}
        title={`Update ${numberOfIds} ${label}`}
        reduxRoot="bulkUpdates"
      >
        <Pages>
          <Page id="bulkUpdates" section="main">
            <div className="p-4">
              <div>
                <Form state={bulkUpdateValues} setState={setBulkUpdateValues}>
                  {bulkFormFields?.map(field => (
                    <FieldsRenderer
                      key={field.value}
                      field={field.value}
                      label={field.label}
                      placeholder={field.placeholder}
                      bulkUpdateValues={bulkUpdateValues}
                      products={products}
                      setBulkUpdateValues={setBulkUpdateValues}
                      setBulkFormFields={setBulkFormFields}
                    />
                  ))}
                </Form>
              </div>
              <div className="d-flex justify-content-center">
                <SingletonButtonDropdown
                  label="Add Field"
                  options={options}
                  selected={bulkFormFields}
                  onSelected={onFieldAdd}
                />
              </div>
            </div>
            <StatusBar status={status} onStatusChange={setStatus} saveButtonText="Bulk Update" />
          </Page>
        </Pages>
      </NavigableModalContainer>
    </BulkUpdateContext.Provider>
  );
};

export default memo(BulkUpdatesModal);
