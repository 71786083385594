export const contactCustomerTemplates = [
  {
    title: "Allegations",
    text: "Hi team, this review has allegations of [XXX]. We recommend addressing it at your earliest convenience."
  },
  {
    title: "Sensitive content",
    text:
      "Hi team - This review has some sensitive content. We recommend getting in touch with this customer directly. Thank you!"
  },
  {
    title: "Non-English or Spanish",
    text:
      "Hi team - At present, Widewail only responds to reviews in English and Spanish, so we kindly ask that you respond to this as you see fit. Thank you."
  },
  {
    title: "Not Recommended Yelp",
    text:
      "Hi team, this is a \"Not Recommended\" review on Yelp, meaning that it won't be shown to most users and won't factor into your overall rating. Usually, this happens when the Yelp algorithm detects suspicious activity. You may respond as normal."
  },
  {
    title: "Can't overwrite Yelp",
    text:
      "Hi team, due to Yelp's rules and regulations, we can’t overwrite a review response posted from a different account. That means your team will need to update the response to this Yelp review from the original responder's account. Let us know if you have any questions."
  },
  {
    title: "Response posted multiple times modified for Yelp",
    text:
      "Hi team, this customer posted this review several times. We are using the same response for consistency, but have modified it slightly here to fit Yelp's style."
  },
  {
    title: "Facebook glitch",
    text:
      "Hi team, due to a Facebook glitch, we are unable to respond to this review from our account. If you approve of this reply, please log in to your dealership’s business page on Facebook and publish it directly. Thank you!"
  },
  {
    title: "Lowered star rating",
    text:
      "Hi team, this customer lowered their rating by a star. You don't need to engage further here, but we wanted to notify you in case you wanted to try to reach them directly."
  },
  {
    title: "HIPAA response",
    text:
      "Hi team - We noticed you already responded to this review – thanks for getting involved in the conversation! We wanted to notify you that it looks like your reply doesn’t comply with HIPAA conventions. We generally try to avoid the use of the words “you”/“your”, “patient”, or the customer’s name, and we try not to reference any specific details about the patient’s visit. Let us know if you need any assistance crafting an alternate reply! Thank you."
  },
  {
    title: "Question",
    text: "Hi team - Please see the question from [XXX] and let us know what information we can pass along. Thank you."
  },
  {
    title: "Legal action",
    text:
      "Hi team - This reviewer is threatening legal action. We recommend being in touch with them directly if possible. Thank you."
  }
];
