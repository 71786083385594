import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDelete, useGet, usePost, usePut } from "hooks/dataHooks";
import { convertPagedResourcesResponse } from "hooks/dataHooks";
import { customerKeys } from "hooks/customerHooks";
import { contactsKeys } from "./useContact";
import { LOCATION_DATA_PROCS } from "data/customers";

const BASE_URL = "/accounts";

export const accountKeys = {
  all: [BASE_URL],
  accounts: params => [...accountKeys.all, params],
  account: (id, projection) => [...accountKeys.all, id, projection],
  create: () => accountKeys.all,
  update: id => [...accountPaths.all, id],
  whitespace: id => [...accountKeys.all, id, "report", "whitespace"],
  contact: contactId => [...accountKeys.all, "contacts", contactId]
};

const accountPaths = {
  all: accountKeys.all,
  accounts: () => [...accountKeys.all],
  account: id => [...accountPaths.all, id],
  create: () => accountPaths.all,
  update: id => accountKeys.account(id),
  whitespace: id => accountKeys.whitespace(id),
  addCustomer: ({ accountId, customerId }) => [...accountKeys.all, accountId, "customers", customerId],
  removeCustomer: ({ accountId, customerId }) => accountPaths.addCustomer({ accountId, customerId }),
  contact: contactId => [...accountKeys.all, "contacts", contactId],
  addContact: ({ accountId, contactId }) => [...accountKeys.all, accountId, "contacts", contactId],
  removeContact: ({ accountId, contactId }) => accountPaths.addContact({ accountId, contactId })
};

export const useGetAccounts = params => {
  const [get] = useGet(accountPaths.accounts(), { params });
  return get;
};

/**
 * @param {{
 *  params: object;
 *  enabled: boolean;
 * }}
 * @returns {import("react-query").UseQueryResult<Account>
 */
export const useAccounts = ({ params, enabled = true }) => {
  const queryKey = accountKeys.accounts(params);
  const get = useGetAccounts(params);
  return useQuery(queryKey, get, { enabled });
};

export const useAccountSearch = params => {
  const queryKey = accountKeys.accounts(params);
  const get = useGetAccounts(params);
  const queryRun = () => get().then(LOCATION_DATA_PROCS.account);

  return useQuery(queryKey, queryRun);
};

/**
 * @param {{
 *  accountId: number;
 *  params: object;
 * }}
 * @returns {import("react-query").UseQueryResult<Account>
 */
export const useAccount = ({ accountId, projection, enabled = true }) => {
  const queryKey = accountKeys.account(accountId, projection);
  const [get] = useGet(accountPaths.account(accountId), { params: { projection } });
  return useQuery(queryKey, get, { enabled });
};

/**
 * @param {{
 *  accountId: number;
 * }}
 * @returns {import("react-query").UseQueryResult<AccountProductsData>
 */
export const useAccountWhitespaceReport = ({ accountId }) => {
  const queryKey = accountKeys.whitespace(accountId);
  const [get] = useGet(accountPaths.whitespace(accountId));
  return useQuery(queryKey, get);
};

export const useAccountCreate = () => {
  const post = usePost();
  const mutation = useMutation(data => post(accountPaths.create(), { data }));
  return useCallback(mutation.mutateAsync, [mutation]);
};

export const useAccountUpdate = ({ accountId }) => {
  const put = usePut();
  const queryClient = useQueryClient();
  const mutation = useMutation(data => put(accountPaths.update(accountId), { data }), {
    onSuccess: () => queryClient.invalidateQueries(accountKeys.update(accountId))
  });
  return useCallback(mutation.mutateAsync, [mutation]);
};

export const useAccountAddCustomer = () => {
  const post = usePost();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ accountId, customerId }) =>
      post(accountPaths.addCustomer({ accountId, customerId })).then(() =>
        queryClient.invalidateQueries(customerKeys.customer(customerId))
      ),
    {}
  );
  return useCallback(mutation.mutateAsync, [mutation]);
};

export const useAccountRemoveCustomer = () => {
  const del = useDelete();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ accountId, customerId }) =>
      del(accountPaths.removeCustomer({ accountId, customerId })).then(() =>
        queryClient.invalidateQueries(customerKeys.customer(customerId))
      ),
    {}
  );
  return useCallback(mutation.mutateAsync, [mutation]);
};

export const useAccountAddContact = () => {
  const post = usePost();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ accountId, contactId, contact }) =>
      post(accountPaths.addContact({ accountId, contactId }), { data: contact }).then(() =>
        queryClient.invalidateQueries(contactsKeys.detail(contactId))
      ),
    {}
  );
  return useCallback(mutation.mutateAsync, [mutation]);
};

export const useAccountRemoveContact = () => {
  const del = useDelete();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ accountId, contactId }) =>
      del(accountPaths.removeContact({ accountId, contactId })).then(() =>
        queryClient.invalidateQueries(contactsKeys.detail(contactId))
      ),
    {}
  );
  return useCallback(mutation.mutateAsync, [mutation]);
};
