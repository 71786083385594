import React, { useContext, useState, useCallback, useEffect } from "react";
import { DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, UncontrolledDropdown } from "reactstrap";
import { noop } from "lodash";
import moment from "moment";
import { FormContext } from "./Form";
import DatePicker from "../DateTime/DatePicker";
import FormField from "./FormField";
import { ISO_FORMAT } from "util/dateUtils";
import get from "lodash/get";

const DatePickerField = ({ name, label, className }) => {
  const context = useContext(FormContext);

  const onPickDate = useCallback(date => {
    const formattedDate = date.format(ISO_FORMAT);
    context.setTextField({ target: { name, type: "text", value: formattedDate } });
  }, []);

  useEffect(() => {
    context && context.registerField(name);
  }, [name]);

  return (
    <FormField name="activeDate" label={label || "Date"} inline={false}>
      <div className="d-flex content-stretch flex-nowrap flex-grow">
        <UncontrolledDropdown className="flex-grow-1">
          <DropdownToggle tag="div">
            <InputGroup>
              <InputGroupText className="fa fa-calendar" />
              <Input
                className={className}
                onChange={noop}
                value={get(context.state, context.parseFieldName(name))}
                name={name}
                placeholder=""
              />
            </InputGroup>
          </DropdownToggle>
          <DropdownMenu className="p-0 bg-transparent border-0 overflow-visible" tag="div">
            <DatePicker start={moment().startOf("year")} onStartChange={onPickDate} range={false} />
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </FormField>
  );
};

export default DatePickerField;
