import { noop } from "lodash";
import { path } from "ramda";
import React, { useCallback } from "react";
import { Button } from "reactstrap";
import InputField from "../../../components/Form/InputField";
import { useStateThroughPipe } from "../../../hooks/utilHooks";

export default class ServiceTitan {
  field() {
    return "serviceTitanInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info }) => {
  return (
    <>
      <InputField
        type="text"
        label="Service Titan API key"
        placeholder="API key"
        onChange={noop}
        value={info.apiKey}
        readOnly={true}
      />
    </>
  );
};

const Add = ({ onSave }) => {
  const [apiKey, setApiKey] = useStateThroughPipe("", path(["target", "value"]));

  const onLink = useCallback(
    () =>
      onSave({
        apiKey,
        source: "SERVICE_TITAN"
      }),
    [apiKey, onSave]
  );

  return (
    <>
      <InputField type="text" label="Service Titan API key" placeholder="API key" onChange={setApiKey} value={apiKey} />
      <Button color="primary" disabled={!apiKey} onClick={onLink}>
        Link
      </Button>
    </>
  );
};
