import { Col, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import VideoSummaryWidgetContent from "./VideoSummaryWidgetContent";
import { withReportFilterQuery } from "components/HOCs/ReportWithFilters";
import { DEFAULT_REQUEST_FIELDS, getPrepareFilters } from "util/reportUtils/videoReportUtils";

export const VideoSummary = ({ data }) => {
  if (!data) {
    return null;
  }
  return (
    <Row className="my-1 no-pagebreak-inside">
      <Col>
        <SimpleBar>
          <VideoSummaryWidgetContent data={data} />
        </SimpleBar>
      </Col>
    </Row>
  );
};

export default withReportFilterQuery({
  url: "/inviteVideoReport/stats",
  prepareFilters: getPrepareFilters(DEFAULT_REQUEST_FIELDS)
})(VideoSummary);
