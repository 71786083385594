import { useMemo } from "react";
import { useURLHashSetter, useURLHashValue } from "./urlHooks";

export const useTabProps = defaultActiveTab => {
  const setUrlHash = useURLHashSetter(true);
  const currentHash = useURLHashValue();
  const activeTab = decodeURIComponent(currentHash) || defaultActiveTab;
  const getTabProps = useMemo(
    () => tab => ({
      active: activeTab === tab,
      onClick: () => {
        setUrlHash(tab);
      },
      label: tab
    }),
    [currentHash, activeTab, setUrlHash]
  );
  return [activeTab, getTabProps, setUrlHash];
};
