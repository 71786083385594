import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { deleteReply } from "../../../api/reviewApi";
import { errorCaughtNotifier, useLocalNotifications } from "../../../components/Notifications/notification";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../../redux/actions/ajaxStatusActions";
import { QUICK_NOTIFICATION_TIMEOUT } from "../../../util/constants";

export default () => {
  const notify = useLocalNotifications();
  const dispatch = useDispatch();
  return useCallback(replyid => {
    dispatch(beginAjaxCall());
    return deleteReply(replyid)
      .then(({ data }) => {
        notify({ timeout: QUICK_NOTIFICATION_TIMEOUT, title: "Reply removed" });
        dispatch(endAjaxCall());
        return data;
      })
      .catch(e => {
        dispatch(ajaxCallError());
        return Promise.reject(e);
      });
  });
};
