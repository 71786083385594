import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { groupBy, isEmpty, isEqual, isNull, omit, pick, uniqBy } from "lodash";
import { pipe } from "ramda";
import { categoriesSearchById, publishListing } from "api/listingsApi";
import { WWTabContent, WWTabContentItem, WWTabs } from "components/WWTabs";
import Form from "components/Form/Form";
import { Card } from "components/Card";
import ToggleSwitch from "components/Form/Switch";
import SaveBar from "components/Form/SaveBar";
import { useErrorNotification, useNotification } from "components/Notifications/notification";
import AboutForm from "./Forms/AboutForm";
import ContactForm from "./Forms/ContactForm";
import LocationForm from "./Forms/LocationForm";
import HoursForm from "./Forms/HoursForm/HoursForm";
import MoreForm from "./Forms/MoreForm";
import MediaForm from "./Forms/MediaForm";
import ConnectDirectories from "./ConnectDirectories";
import UpdateLog from "./UpdateLog";
import { getDayOfWeekById } from "./Forms/HoursForm/DaysOfWeekForm";
import useGoogleAttributes from "hooks/data/useGoogleAttributes";
import { useTabProps } from "hooks/tabsHooks";
import { useListing, useListingPatch, useServiceItems } from "hooks/data/listingsDataHooks";
import { cleanUpRequestParams } from "util/functionUtils";
import {
  getAttributes,
  getHoursEntity,
  getMoreHours,
  getRegularHours,
  getServiceItems,
  getSpecialHours
} from "util/dataUtils/listingsDataUtils";
import { removeUSCodeFromPhone } from "util/phoneNumberUtils";
import { currentUserHasFeature } from "util/userUtils";
import { OPEN_CLOSED_STATUSES } from "data/listings";

import styles from "./ListingsEdit.module.scss";
import WWButton from "components/Buttons/WWButton";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import { autoclose } from "components/Modals";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import { LISTING_MEDIA } from "data/featureFlags";

const { mainFormContainerClass, tabsClass, tabsContentClass } = styles;

const ON_SAVE_NOTIFY_TIMEOUT = 5000;

const TABS = ["about", "contact", "location", "hours", "more", "media"];
const defaultTab = "about";

export const ListingsEdit = ({ history, listingId, customerId }) => {
  const [sites, setSites] = useState();
  const [listing, setListing] = useState({});
  const [transformedCategories, setTransformedCategories] = useState(null);

  const [activeTab, getTabProps] = useTabProps(defaultTab);

  const notify = useNotification();
  const errorNotification = useErrorNotification();
  const confirm = useConfirmationDialog();

  const attributesQuery = useGoogleAttributes(listing?.categories?.primaryCategory?.value, customerId);
  const attributesData = attributesQuery?.data;
  const serviceItemsQuery = useServiceItems(listing?.categories?.primaryCategory?.value);
  const save = useListingPatch({ listingId, customerId });

  const onListingLoadSuccess = useCallback(async data => {
    try {
      let primaryCategory, secondaryCategories;
      if (data?.categories?.primaryCategory) {
        primaryCategory = data.categories.primaryCategory;
      }
      if (!isEmpty(data?.categories?.secondaryCategories)) {
        secondaryCategories = data.categories.secondaryCategories;
      }
      setTransformedCategories(prev => ({
        ...prev,
        primaryCategory: primaryCategory
          ? {
              value: primaryCategory.name,
              label: primaryCategory.displayName,
              moreHoursTypes: primaryCategory.moreHoursTypes,
              facebookLabel: primaryCategory.displayNames?.FACEBOOK,
              aggregatorLabel: primaryCategory.displayNames?.NEUSTAR
            }
          : undefined,
        secondaryCategories: secondaryCategories
          ? secondaryCategories.map(secondaryCategory => ({
              value: secondaryCategory?.name,
              label: secondaryCategory?.displayName,
              moreHoursTypes: secondaryCategory?.moreHoursTypes,
              facebookLabel: secondaryCategory.displayNames?.FACEBOOK,
              aggregatorLabel: secondaryCategory.displayNames?.NEUSTAR
            }))
          : []
      }));
    } catch (e) {
      errorNotification(e);
    }
  }, []);

  const publishListingQA = useCallback(
    () =>
      autoclose(
        confirm({
          title: "Publish this listing?",
          body: (
            <p>
              Are you sure you want to publish this listing? This is intended for testing purposes but will release
              these changes live.
            </p>
          )
        })
      ).then(
        confirmed =>
          confirmed &&
          publishListing(customerId, listingId)
            .then(() => {
              notify({
                timeout: ON_SAVE_NOTIFY_TIMEOUT,
                body: "Listing published successfully!"
              });
            })
            .catch(errorNotification)
      ),
    [customerId, listingId]
  );

  const listingDataQuery = useListing({ listingId, customerId }, { onSuccess: onListingLoadSuccess });
  const listingData = useMemo(() => listingDataQuery.data, [listingDataQuery]);
  useEffect(() => {
    setSites({
      GOOGLE: listingData?.gmbInfo,
      FACEBOOK: listingData?.facebookInfo,
      NEUSTAR: listingData?.neustarInfo
    });
  }, [listingData]);

  const categoryIds = useMemo(() => {
    let primary = listing?.categories?.primaryCategory?.value;
    let secondary = listing?.categories?.secondaryCategories?.map(c => c?.value) || [];
    return [primary, ...secondary]
      .filter(i => i)
      .sort()
      .join(",");
  }, [listing]);

  const [moreHoursTypes, setMoreHoursTypes] = useState([]);
  useEffect(() => {
    categoriesSearchById(categoryIds).then(({ data }) => {
      let types = uniqBy(
        data.reduce((acc, next) => (next.moreHoursTypes ? acc.concat(next.moreHoursTypes) : acc), []),
        "hoursTypeId"
      );
      setMoreHoursTypes(types);
    });
  }, [categoryIds]);

  const transformCategory = category => {
    if (category && category.value && category.label) {
      return {
        name: category.value,
        displayName: category.label,
        facebookLabel: category.label,
        aggregatorLabel: category.label
      };
    } else {
      return null;
    }
  };

  const transformFormValues = useCallback(
    formValues => {
      const { categories, additionalPhoneNumbers, emails, syncMode, addressStatus } = formValues;
      const [email, ...additionalEmails] = emails?.filter(email => !isNull(email)) || [];
      const updatedCategories = pipe(cleanUpRequestParams)({
        primaryCategory: transformCategory(categories.primaryCategory),
        secondaryCategories: categories?.secondaryCategories?.map(transformCategory) || []
      });
      const isOpenWithHours = formValues?.openClosedStatus === OPEN_CLOSED_STATUSES.open;
      const regularHours = isOpenWithHours ? getRegularHours(formValues) : [];
      const specialHours = isOpenWithHours ? getSpecialHours(formValues) : [];
      const moreHours = isOpenWithHours ? getMoreHours(formValues, moreHoursTypes) : [];
      const attributes = getAttributes(formValues, attributesData);
      const serviceItems = getServiceItems(formValues, categories?.primaryCategory?.value);
      const res = pipe(cleanUpRequestParams)({
        ...pick(formValues, [
          "addressLine1",
          "addressLine2",
          "websiteUri",
          "facebookUrlParameters",
          "googleUrlParameters",
          "listingsAggregatorUrlParameters",
          "serviceAreas",
          "businessName",
          "categories",
          "description",
          "about",
          "openClosedStatus",
          "primaryPhone",
          "googlePhone",
          "facebookPhone",
          "listingAggregatorPhone",
          "countryCode",
          "addressStatus",
          "locality",
          "administrativeArea",
          "openClosedStatus",
          "openingDate",
          "postalCode"
        ]),
        categories: isEmpty(updatedCategories) ? undefined : updatedCategories,
        additionalPhoneNumbers: additionalPhoneNumbers
          ?.filter(phoneNumber => !isNull(phoneNumber))
          .map(phoneNumber => ({ type: null, phoneNumber })),
        email: email || "",
        additionalEmails: additionalEmails.map(email => ({ email })),
        syncMode: syncMode === true ? "READ_WRITE" : "OFF",
        addressStatus: addressStatus === true ? "ACTIVE" : "HIDDEN",
        regularHours,
        specialHours,
        moreHours,
        attributes,
        serviceItems
      });
      return { ...res, openingDate: res.openingDate || {} };
    },
    [moreHoursTypes, attributesData]
  );

  const attributes4InitialData = useMemo(() => {
    const result = {};
    listingData?.attributes?.forEach(({ name, values }) => {
      result[name] = values.includes("true");
    });
    return result;
  }, [listingData]);

  const moreHours4InitialData = useMemo(() => {
    let result = {};
    if (listingData?.moreHours) {
      const groupedByType = groupBy(listingData?.moreHours, "hoursTypeId");
      Object.keys(groupedByType).forEach(typeKey => {
        if (!result[typeKey]) {
          result[typeKey] = {};
        }
        const groupedByDay = groupBy(groupedByType[typeKey], "openDay");
        Object.keys(groupedByDay).forEach(dayKey => {
          const dayValue = getDayOfWeekById(dayKey);
          if (!result[typeKey][dayValue]) {
            result[typeKey][dayValue] = {
              hasOpenHours: true,
              hours: []
            };
          }
          groupedByDay[dayKey].forEach(
            ({ openDay, openHour, closeHour, openMin, closeMin, hoursTypeId, hoursTypeName }) => {
              result[typeKey][dayValue].hours.push({
                hoursTypeId,
                hoursTypeName,
                openDay,
                ...getHoursEntity({ openHour, closeHour, openMin, closeMin })
              });
            }
          );
        });
      });
    }
    return result;
  }, [listingData]);

  const regularHours4InitialData = useMemo(() => {
    const regularHours = {};
    const hoursCount = {};
    listingData?.regularHours.map(({ openDay, openHour, closeHour, openMin, closeMin }) => {
      const dayField = getDayOfWeekById(openDay);
      if (!regularHours[dayField]) {
        regularHours[dayField] = {};
        hoursCount[dayField] = 0;
        regularHours[dayField].hasOpenHours = true;
      } else {
        hoursCount[dayField]++;
      }
      if (!regularHours[dayField].hours) {
        regularHours[dayField].hours = [];
      }
      regularHours[dayField].hours[hoursCount[dayField]] = getHoursEntity({
        openHour,
        closeHour,
        openMin,
        closeMin
      });
    });
    return regularHours;
  }, [listingData]);

  const specialHours4InitialData = useMemo(() => {
    const result = [];
    const hours4Date = {};
    listingData?.specialHours.forEach(({ closed, startDate, endDate, openHour, openMin, closeHour, closeMin }) => {
      const baseEntity = {
        closed,
        startDate,
        endDate
      };
      if (closed) {
        result.push(baseEntity);
      } else {
        if (!hours4Date[startDate]) {
          hours4Date[startDate] = [];
        }
        hours4Date[startDate].push({ ...getHoursEntity({ openHour, closeHour, openMin, closeMin }) });
      }
    });
    return result.concat(
      Object.keys(hours4Date).map(key => ({
        startDate: key,
        endDate: key,
        hours: hours4Date[key],
        // opened uses only for proper UI rendering
        opened: hours4Date[key].length > 0
      }))
    );
  }, [listingData]);

  const transformedInitialData = useMemo(
    () =>
      listingData
        ? {
            ...listingData,
            syncMode: listingData.syncMode !== "OFF",
            addressStatus: listingData.addressStatus === "ACTIVE",
            primaryPhone: removeUSCodeFromPhone(listingData.primaryPhone),
            googlePhone: removeUSCodeFromPhone(listingData.googlePhone),
            facebookPhone: removeUSCodeFromPhone(listingData.facebookPhone),
            listingAggregatorPhone: removeUSCodeFromPhone(listingData.listingAggregatorPhone),
            additionalPhoneNumbers:
              listingData.additionalPhoneNumbers?.map(({ phoneNumber }) => removeUSCodeFromPhone(phoneNumber)) || [],
            emails: [
              ...(listingData.email ? [listingData.email] : []),
              ...(listingData.additionalEmails?.map(({ email }) => email) || [])
            ],
            categories: transformedCategories,
            serviceItems:
              listingData.serviceItems?.map(serviceItem => {
                return {
                  ...serviceItem,
                  googleServiceItemId: serviceItem?.googleServiceItemId
                    ? { serviceTypeId: serviceItem?.googleServiceItemId }
                    : {
                        label: serviceItem?.name,
                        value: serviceItem?.name
                      }
                };
              }) || [],
            regularHours: regularHours4InitialData,
            specialHours: specialHours4InitialData,
            moreHours: moreHours4InitialData,
            more: attributes4InitialData
          }
        : {},
    [listingData, regularHours4InitialData, specialHours4InitialData, attributes4InitialData, transformedCategories]
  );

  const onSave = useCallback(
    async closeAfterSave => {
      try {
        await save({
          ...transformFormValues(listing)
        });
        notify({
          timeout: ON_SAVE_NOTIFY_TIMEOUT,
          body: "Listing Updated successfully!"
        });
        setShowSetBar(false);
        closeAfterSave && history.goBack();
      } catch (e) {
        throw e;
      }
    },
    [save, listing]
  );

  const [showSaveBar, setShowSetBar] = useState(false);
  const disableSave = useMemo(
    () =>
      !listing.addressLine1 ||
      !listing.administrativeArea ||
      !listing.postalCode ||
      !listing.locality ||
      !listing.countryCode,
    [listing]
  );

  useEffect(() => {
    if (
      !isEmpty(listing) &&
      listing.categories &&
      !isEqual(omit(listing, "media"), omit(transformedInitialData, "media"))
    ) {
      setShowSetBar(true);
    }
  }, [listing]);

  const tabContentProps = { className: tabsContentClass, activeTab };
  return (
    <Form state={listing} setState={setListing} initialValues={transformedInitialData}>
      <Row>
        <Col sm={12} md={8}>
          <Card className={mainFormContainerClass}>
            <div className="d-flex justify-content-between mb-4 w-100">
              <h4 className="mb-0">{listingData?.customer?.companyName || null}</h4>
              <ToggleSwitch
                label="Syncing"
                inputWidth={6}
                className="mb-0"
                name="syncMode"
                loading={listingDataQuery.isLoading}
              />
            </div>
            <WWTabs className={tabsClass} tabs={TABS.map(tab => getTabProps(tab))} responsiveBreakpoint="lg" />
            {!!listingData && (
              <>
                <WWTabContent {...tabContentProps}>
                  <WWTabContentItem tabId="about">
                    <AboutForm
                      initialValues={transformedInitialData}
                      values={listing}
                      loaded={!isNull(transformedCategories)}
                    />
                  </WWTabContentItem>
                </WWTabContent>

                <WWTabContent {...tabContentProps}>
                  <WWTabContentItem tabId="contact">
                    <ContactForm initialValues={transformedInitialData} />
                  </WWTabContentItem>
                </WWTabContent>

                <WWTabContent {...tabContentProps}>
                  <WWTabContentItem tabId="location">
                    <LocationForm
                      initialValues={{ ...transformedInitialData }}
                      serviceItemsOptions={serviceItemsQuery?.data || []}
                      openingDate={listing.openingDate}
                    />
                  </WWTabContentItem>
                </WWTabContent>

                <WWTabContent {...tabContentProps}>
                  <WWTabContentItem tabId="hours">
                    <HoursForm
                      initialValues={transformedInitialData}
                      values={listing}
                      moreHoursTypes={moreHoursTypes}
                    />
                  </WWTabContentItem>
                </WWTabContent>

                <WWTabContent {...tabContentProps}>
                  <WWTabContentItem tabId="more">
                    <MoreForm attributes={attributesData} />
                  </WWTabContentItem>
                </WWTabContent>

                <WWTabContent {...tabContentProps}>
                  <WWTabContentItem tabId="media">
                    <MediaForm values={listing} listingId={listingId} setListing={setListing} />
                  </WWTabContentItem>
                </WWTabContent>
              </>
            )}
          </Card>
          {showSaveBar && (
            <SaveBar
              prompt={disableSave ? "Cannot save changes without address information" : "Save changes?"}
              confirmationPrompt="Save changes?"
              disableSave={disableSave}
              onSave={onSave}
              onCancel={() => {
                setShowSetBar(false);
                history.goBack();
              }}
            />
          )}
        </Col>
        <Col sm={12} md={4}>
          <div className="d-flex flex-column">
            <UpdateLog listingId={listingId} customerId={customerId} />
            <AuthorizationRequiredToRender roles={[permissions.WIDEWAIL_ADMIN]}>
              <WWButton onClick={publishListingQA}>release changes for qa</WWButton>
            </AuthorizationRequiredToRender>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ListingsEdit;
