import { path, pathOr } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, FormGroup, Collapse, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { cloneDeep, get } from "lodash";
import HorizontalSelectField from "../../../components/Form/HorizontalSelectField";
import InputField from "../../../components/Form/InputField";
import FormField from "../../../components/Form/FormField";
import CopyButton from "../../../components/Buttons/CopyButton";
import { bySource } from "../../../data/sites";
import { useStateThroughPipe } from "../../../hooks/utilHooks";
import { updateYardiLink } from "../../../api/customerApi";
import Labeled from "../../../components/Checkbox/Labeled/CheckboxLabeled";
import SharedCredentialsSelector from "components/SharedCredentials/SharedCredentialsSelector";
import { useValidatePropertySitesCheckboxes } from "hooks/validationHooks";

export default class YardiAdminLinking {
  field() {
    return "yardiInfo";
  }

  add() {
    return YardiLinkAdd;
  }

  edit() {
    const EditYardi = ({ info, customer }) => {
      const [sharedCredentials, setSharedCredentials] = useState(info.sharedCredentials);
      const [propertyId, setPropertyId] = useStateThroughPipe(info.propertyId, nativeInputEventPipe);
      const [importMoveIn, setImportMoveIn] = useStateThroughPipe(info.importMoveIn);
      const [importMoveOut, setImportMoveOut] = useStateThroughPipe(info.importMoveOut);
      const [importRenewal, setImportRenewal] = useStateThroughPipe(info.importRenewal);
      const [importTour, setImportTour] = useStateThroughPipe(info.importTour);
      const [initialInfo, setInitialInfo] = useState([]);
      const [dirty, setDirty] = useState(false);

      const importOptions = useMemo(
        () => [
          { name: "importMoveIn", label: "Move in", value: importMoveIn, setter: setImportMoveIn },
          { name: "importMoveOut", label: "Move out", value: importMoveOut, setter: setImportMoveOut },
          { name: "importRenewal", label: "Renewal", value: importRenewal, setter: setImportRenewal },
          { name: "importTour", label: "Tour", value: importTour, setter: setImportTour }
        ],
        [importMoveIn, importMoveOut, importRenewal, importTour]
      );

      const vaidateActions = useValidatePropertySitesCheckboxes(importOptions);

      const updateYardi = useCallback(() => {
        vaidateActions().then(validationResult => {
          if (validationResult) {
            return updateYardiLink(customer.id, info.id, {
              sharedCredentials,
              propertyId,
              importMoveIn,
              importMoveOut,
              importTour,
              importRenewal
            }).then(data => {
              setInitialInfo(data);
            });
          }
        });
      }, [importMoveIn, importMoveOut, importRenewal, importTour, propertyId, sharedCredentials, customer, info]);

      useEffect(() => {
        setInitialInfo(cloneDeep(info));
      }, [info]);

      useEffect(() => {
        setDirty(
          propertyId !== initialInfo.propertyId ||
            importOptions.some(opt => get(initialInfo, opt.name) !== opt.value) ||
            sharedCredentials?.id !== initialInfo?.sharedCredentials?.id
        );
      }, [initialInfo, importMoveIn, importMoveOut, importRenewal, importTour, propertyId, sharedCredentials]);
      return (
        <>
          <InputField name="propertyId" label="Property ID" value={info.propertyId} onChange={setPropertyId} />
          <CopyButton className="ms-1" text={info.propertyId} />
          <SharedCredentialsSelector
            label="Shared credentials"
            source="YARDI"
            id={sharedCredentials?.id}
            onChange={setSharedCredentials}
          />
          {!sharedCredentials && (
            <>
              <InputField inline={false} disabled={true} label="Username" value={info.username} />
              <InputField inline={false} disabled={true} label="Database name" value={info.databaseName} />
              <InputField inline={false} disabled={true} label="Server name" value={info.serverName} />
              <InputField inline={false} disabled={true} label="Service URL" value={info.serviceUrl} />
            </>
          )}
          {importOptions.map(({ name, label, value, setter }) => (
            <FormGroup check key={name} style={{ flexBasis: "165px" }} className="d-flex m-2">
              <Labeled name={name} checked={value} onToggle={setter} label={label} />
            </FormGroup>
          ))}
          <Collapse isOpen={dirty}>
            <FormField label=" ">
              <Button color="primary" onClick={updateYardi} disabled={!dirty}>
                Update
              </Button>
            </FormField>
          </Collapse>
        </>
      );
    };
    return EditYardi;
  }
}

const nativeInputEventPipe = path(["target", "value"]);
const { source } = bySource("YARDI");

const YardiLinkAdd = ({ onSave }) => {
  const [sharedCredentials, setSharedCredentials] = useState();
  const [username, setUsername] = useStateThroughPipe("", nativeInputEventPipe);
  const [password, setPassword] = useStateThroughPipe("", nativeInputEventPipe);
  const [databaseName, setDatabaseName] = useStateThroughPipe("", nativeInputEventPipe);
  const [serverName, setServerName] = useStateThroughPipe("", nativeInputEventPipe);
  const [propertyId, setPropertyId] = useStateThroughPipe("", nativeInputEventPipe);
  const [serviceUrl, setServiceUrl] = useStateThroughPipe("", nativeInputEventPipe);
  const [importMoveIn, setImportMoveIn] = useStateThroughPipe(false);
  const [importMoveOut, setImportMoveOut] = useStateThroughPipe(false);
  const [importRenewal, setImportRenewal] = useStateThroughPipe(false);
  const [importTour, setImportTour] = useStateThroughPipe(false);

  const importOptions = useMemo(
    () => [
      { name: "importMoveIn", label: "Move in", value: importMoveIn, setter: setImportMoveIn },
      { name: "importMoveOut", label: "Move out", value: importMoveOut, setter: setImportMoveOut },
      { name: "importRenewal", label: "Renewal", value: importRenewal, setter: setImportRenewal },
      { name: "importTour", label: "Tour", value: importTour, setter: setImportTour }
    ],
    [importMoveIn, importMoveOut, importRenewal, importTour]
  );

  const vaidateActions = useValidatePropertySitesCheckboxes(importOptions);

  const [platform, setPlatform] = useStateThroughPipe(
    { value: "SQL_SERVER", label: "SQL Server" },
    pathOr({ value: "SQL_SERVER", label: "SQL Server" }, ["target", "value"])
  );
  const allFilled = useMemo(() => {
    if (sharedCredentials) {
      return propertyId;
    } else {
      return propertyId && username && password && databaseName && serverName && serviceUrl && platform;
    }
  }, [sharedCredentials, username, password, databaseName, serverName, propertyId, serviceUrl, platform]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      vaidateActions().then(validationResult => {
        if (validationResult) {
          onSave({
            sharedCredentials,
            username,
            password,
            databaseName,
            serverName,
            propertyId,
            serviceUrl,
            platform: platform.value,
            source,
            importMoveIn,
            importMoveOut,
            importRenewal,
            importTour
          });
        }
      });
    },
    [
      onSave,
      sharedCredentials,
      username,
      password,
      databaseName,
      serverName,
      propertyId,
      serviceUrl,
      platform,
      importMoveIn,
      importMoveOut,
      importRenewal,
      importTour
    ]
  );

  return (
    <Form onSubmit={onSubmit}>
      <InputField inline={false} label="Property ID" value={propertyId} onChange={setPropertyId} />
      <SharedCredentialsSelector label="Shared credentials" source="YARDI" onChange={setSharedCredentials} />
      {!sharedCredentials && (
        <>
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Username"
            value={username}
            onChange={setUsername}
          />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Password"
            value={password}
            onChange={setPassword}
          />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Database name"
            value={databaseName}
            onChange={setDatabaseName}
          />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Server name"
            value={serverName}
            onChange={setServerName}
          />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Service URL"
            value={serviceUrl}
            onChange={setServiceUrl}
          />
          <HorizontalSelectField
            name="platform"
            inline={false}
            isDisabled={sharedCredentials}
            label="Platform"
            value={platform}
            simpleValue={false}
            isMulti={false}
            options={[
              { value: "SQL_SERVER", label: "SQL Server" },
              { value: "ORACLE", label: "Oracle" }
            ]}
            onChange={setPlatform}
          />
        </>
      )}
      {importOptions.map(({ name, label, value, setter }) => (
        <Labeled key={name} name={name} checked={value} onToggle={setter} label={label} />
      ))}
      <Button type="submit" color="primary" disabled={!allFilled}>
        Link
      </Button>
    </Form>
  );
};
