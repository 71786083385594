import * as types from "../actions/actionTypes";
import moment from "moment";

export const initialReportState = {
  startDate: moment().startOf("month"),
  endDate: moment().endOf("day")
};

export default function report(state, action) {
  if (state === undefined) {
    return initialReportState;
  } else {
    switch (action.type) {
      case types.REPORT_SET_START_DATE:
        return Object.assign({}, state, { startDate: action.date });

      case types.REPORT_SET_END_DATE:
        return Object.assign({}, state, { endDate: action.date });

      case types.REPORT_SET_CUSTOMER:
        return Object.assign({}, state, { customerId: action.customerId });

      case types.REPORT_SET_CONTACT:
        return Object.assign({}, state, { contactId: action.contactId });

      case types.REPORT_CLEAR_CUSTOMER:
        let newState = Object.assign({}, state);
        delete newState.customerId;
        delete newState.contactId;
        return newState;

      default:
        return state;
    }
  }
}
