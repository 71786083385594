import axios from "axios";
import config from "../config";

export function listVenues(listVenuesRequest, filter = "") {
  return axios.post(config.api + "/sevenrooms/groups/venues", listVenuesRequest, {
    params: {
      filter
    }
  });
}
