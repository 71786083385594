import WWChip from "components/WWChips/WWChip/WWChip";
import { useFilterValueState } from "hooks/filteringHooks";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { valueOf } from "util/functionUtils";
import classNames from "classnames";
import { getLabelFromValue } from "data/options";

import styles from "./ButtonDropdownFilter.module.scss";

const ButtonDropdownFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <ButtonDropdownFilterBar key={props.name} className={className} {...props} />
  );
  const chip = ({ ...props }, className = undefined) => (
    <ButtonDropdownChip key={props.name} {...props} className={className} />
  );
  const filterComponent = ({ ...props }, className) => (
    <ButtonDropdownFilterSidebar key={props.name} {...props} className={className} />
  );

  return {
    barComponent,
    chip,
    filterComponent
  };
};

const DropdownFilterItemContent = ({ transformOptionToNode, option, value }) =>
  typeof transformOptionToNode === "function" ? (
    transformOptionToNode(option)
  ) : (
    <span className={classNames("text-capitalize", { "text-muted": option.value !== value })}>{option.label}</span>
  );

export const ButtonDropdownFilterBar = ({
  className,
  icon = "fa-sort",
  clearable = true,
  color = "primary",
  name,
  label,
  options,
  placeholder,
  showLabelInMenu,
  transformOptionToNode = undefined,
  outerLabel
}) => {
  const [value, setter] = useFilterValueState(name);
  return (
    <UncontrolledDropdown className={classNames(styles.container, className, "d-inline-flex")}>
      {outerLabel && <span className={styles.outerLabel}>{outerLabel}</span>}
      <DropdownToggle color={color} className="d-flex text-nowrap flex-nowrap">
        {!value ? placeholder : getLabelFromValue(value, options)}
        {icon && <i className={classNames("fa ms-3 text-muted", { [icon]: icon })} />}
      </DropdownToggle>
      <DropdownMenu container="body">
        {showLabelInMenu && (
          <small className="text-uppercase text-muted px-3 pb-2 mb-2 border-bottom d-block">{label}</small>
        )}
        {options.map(v => (
          <DropdownItem toggle={true} key={v.value} onClick={() => setter(v.value)}>
            <DropdownFilterItemContent option={v} value={value} transformOptionToNode={transformOptionToNode} />
          </DropdownItem>
        ))}
        {clearable && (
          <DropdownItem className="text-muted" toggle={true} key="clear" onClick={() => setter("")}>
            Clear
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export const ButtonDropdownChip = ({ name, className, options, defaultValue, iconClass, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return value ? (
    <WWChip
      iconClass={iconClass}
      key={name}
      className="overflow-visible"
      onClick={() => setter(valueOf(defaultValue))}
      {...props}
    >
      {getLabelFromValue(value, options)}
    </WWChip>
  ) : null;
};

export const ButtonDropdownFilterSidebar = ({
  icon = "fa-sort",
  clearable = true,
  color = "primary",
  name,
  options,
  placeholder,
  transformOptionToNode = undefined
}) => {
  const [value, setter] = useFilterValueState(name);
  return (
    <UncontrolledDropdown className="d-inline-flex">
      <DropdownToggle color={color} className="d-flex text-nowrap flex-nowrap">
        {!value ? placeholder : getLabelFromValue(value, options)}
        {icon && <i className={classNames("fa ms-3 text-muted", { [icon]: icon })} />}
      </DropdownToggle>
      <DropdownMenu>
        {options.map(v => (
          <DropdownItem toggle={true} key={v.value} onClick={() => setter(v.value)}>
            <DropdownFilterItemContent option={v} value={value} transformOptionToNode={transformOptionToNode} />
          </DropdownItem>
        ))}
        {clearable && (
          <DropdownItem className="text-muted" toggle={true} key="clear" onClick={() => setter("")}>
            Clear
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ButtonDropdownFactory;
