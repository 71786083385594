import WWButton from "components/Buttons/WWButton";
import React, { useCallback, useState } from "react";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";

export const Staff = ({ staff, onChange }) => {
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");

  const remove = useCallback(index => () => onChange(staff.filter((_, i) => index !== i)), [staff]);

  const addStaff = useCallback(
    fresh => () => {
      setNewFirstName("");
      setNewLastName("");
      onChange([...staff, fresh]);
    },
    [staff]
  );

  const addIfEnter = fresh => e => {
    if (e.key === "Enter" && !!fresh[0] && !!fresh[1]) {
      e.preventDefault();
      addStaff(fresh);
    }
  };

  return (
    <>
      {!(staff?.length > 0) && <small className="text-muted d-block w-100 text-center mb-3 mt-2">No staff</small>}
      {staff?.map(([fn, ln], i) => (
        <Row key={i} className="mb-2">
          <Col className="d-flex align-items-center">
            <InputGroup className="flex-grow-1 me-2">
              <InputGroupText className="fa fa-user px-2 py-0" />
              <Input readOnly={true} value={fn} />
              <Input readOnly={true} value={ln} />
            </InputGroup>
            <WWButton iconClass="fa fa-trash" color="warning" onClick={remove(i)} />
          </Col>
        </Row>
      ))}
      <Row>
        <Col className="d-flex align-items-center">
          <InputGroup className="flex-grow-1 me-2">
            <InputGroupText className="fa fa-user-plus px-2 py-0" />
            <Input
              placeholder="first name"
              value={newFirstName}
              onChange={({ target }) => setNewFirstName(target.value)}
              onKeyDown={addIfEnter([newFirstName, newLastName])}
            />
            <Input
              placeholder="last name"
              value={newLastName}
              onChange={({ target }) => setNewLastName(target.value)}
              onKeyDown={addIfEnter([newFirstName, newLastName])}
            />
          </InputGroup>
          <WWButton iconClass="fa fa-plus" disabled={!newFirstName} onClick={addStaff([newFirstName, newLastName])} />
        </Col>
      </Row>
    </>
  );
};
