import { isNull } from "lodash";
import { Col, Row } from "reactstrap";
import { defaultTooltipValueRenderer, TrendingChartTooltip } from "components/Charts/Tooltips/TrendingChartTooltip";
import TrendingChartWithLegend from "components/Charts/TrendingChartWithLegend";
import Ring from "components/Misc/Ring";
import { DashboardWidget } from "components/Widgets/DashboardWidget";
import { withReportFilterQuery } from "components/HOCs/ReportWithFilters";
import { sortDatasetsForPropertyManagement } from "util/arrayUtils";
import { neg_approval_time_colors } from "views/Reports/util";

const legendMapper = (entry, key) => {
    const value = entry[key];
    return key === "id" ? (value === "TOTAL" ? "Total" : value) : `${Math.round(value * 100)}%`;
  },
  legendRenderer = key => entry => {
    const value = legendMapper(entry, key);
    return key === "id" ? (
      <span className="text-dark text-nowrap">{value}</span>
    ) : key === "total" ? (
      <h2>{entry[key] === null ? "N/A" : value}</h2>
    ) : !isNull(entry[key]) && entry[key] <= 0 ? (
      <span className="text-success">{value}</span>
    ) : !isNull(entry[key]) && entry[key] > 0 ? (
      <span className="text-danger">{value}</span>
    ) : (
      <span className="text-dark">{"N/A"}</span>
    );
  },
  tooltipFormatter = ({ active, payload }) => {
    return active && payload && payload.length > 0 ? (
      <TrendingChartTooltip
        data={payload[0].payload}
        valueRenderer={v => (v !== null ? `${v}%` : defaultTooltipValueRenderer(v))}
      >
        Volume
      </TrendingChartTooltip>
    ) : null;
  };

const NegativeReviewsWidget = ({ data, actions, isLoading }) => {
  let { averageResponseTime } = data;

  return (
    <>
      <Row>
        <Col>
          <DashboardWidget title="Negative Reviews (3 stars or less)" actions={actions}>
            <TrendingChartWithLegend
              dataSource={data}
              legendRenderer={legendRenderer}
              chartProps={{
                yAxis: {
                  axisLine: false,
                  tickFormatter: value => `${value}%`,
                  domain: [v => v * 0.5, "dataMax"]
                },
                tooltip: {
                  content: tooltipFormatter
                }
              }}
            />
          </DashboardWidget>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }} className="ring-wrap">
          <DashboardWidget title="Average response time (days)" isLoading={isLoading}>
            <div className="m-auto">
              <Ring
                color={isNull(data.averageResponseTime) ? "secondary" : neg_approval_time_colors(averageResponseTime)}
                text={
                  isNull(averageResponseTime)
                    ? "N/A"
                    : averageResponseTime < 1
                    ? "< 1"
                    : Math.round(averageResponseTime)
                }
              />
            </div>
          </DashboardWidget>
        </Col>
      </Row>
    </>
  );
};

export default withReportFilterQuery({
  url: "/report/negativeReviews",
  normalizeResponse: resp => {
    return {
      ...resp,
      datasets: sortDatasetsForPropertyManagement(resp.datasets).map(({ trends, ...rest }) => ({
        ...rest,
        trends: {
          stat: trends?.stat?.map(v => v && Math.round(100 * v)),
          comparison: trends?.comparison?.map(v => v && Math.round(100 * v))
        }
      }))
    };
  }
})(NegativeReviewsWidget);
