import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import uniqueId from "lodash/uniqueId";
import { UncontrolledTooltip as Tooltip } from "reactstrap";
import moment from "moment";

const DATETIME_FORMAT = "MMMM DD YYYY h:mm a";

function parseFormat(format) {
  switch (format) {
    case "date":
      return ["MMMM DD YYYY", "fa-calendar"];

    case "dateshort":
      return ["MMM DD", "fa-calendar"];

    case "time":
      return ["h:mm a", "fa-clock-o"];

    case "datetime":
      return [DATETIME_FORMAT, "fa-calendar"];

    case "dateSlash":
      return ["M/D/YYYY", "fa-calendar"];

    case "dateSlashTime":
      return ["M/D/YYYY h:mma", "fa-calendar"];

    case "time":
      return ["h:mma", "fa-calendar"];

    default:
      return [format, "fa-calendar"];
  }
}

/**
 * Render a <time/> w/ a specified or pre-defined format
 * @param {date} Date
 */
function Time({ date, format = "date", withIcon = true, emptyDateText = "Unknown", tooltipContainer, ...props }) {
  const [initialFormat, initialIcon] = parseFormat(format);
  const [id] = useState(uniqueId("time"));
  const [icon, setIcon] = useState(initialIcon);
  const [finalFormat, setFinalFormat] = useState(initialFormat === "ago" ? undefined : initialFormat);
  const [ago, setAgo] = useState(initialFormat === "ago");

  useEffect(() => {
    const [f, i] = parseFormat(format);
    setIcon(i);
    if (f === "ago") {
      setAgo(true);
      setFinalFormat(undefined);
    } else {
      setAgo(false);
      setFinalFormat(f);
    }
  }, [format]);

  if (!date) {
    return <span {...props}>{emptyDateText}</span>;
  }

  return (
    <span {...props}>
      {withIcon && <i className={`fa ${icon} me-2`} />}
      <Moment id={id} format={finalFormat} ago={ago} fromNow={ago}>
        {date}
      </Moment>
      <Tooltip target={id} container={tooltipContainer} placement="top">
        {moment(date).format(DATETIME_FORMAT)}
      </Tooltip>
    </span>
  );
}

Time.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Time;
