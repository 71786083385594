import React from "react";
import classnames from "classnames";
import CardButton from "../../../../CardButton/CardButton";

import styles from "./LogoCardButton.module.scss";

const { logoCardButton, logoClass, selectedClass, disabledClass } = styles;

const LogoCardButton = ({ className, selected, logoName, disabled, ...props }) => {
  const logoClassName = styles[logoName?.toLowerCase()] || "";
  return (
    <CardButton
      withoutTitle
      selected={selected}
      disabled={disabled}
      className={classnames(logoCardButton, className, { [selectedClass]: selected, [disabledClass]: disabled })}
      {...props}
    >
      <div className={classnames(logoClass, logoClassName)} />
    </CardButton>
  );
};

export default LogoCardButton;
