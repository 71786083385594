import * as api from "./apiClient";

const baseUri = "email";

export function sendSmsTest(contactId) {
  return api.post(`${baseUri}/test/contact/${contactId}/sms`);
}

export function sendEmailTest(contactId) {
  return api.post(`${baseUri}/test/contact/${contactId}/email`);
}
