import { useMemo } from "react";
import { useGet } from "../dataHooks";
import { useQuery } from "react-query";

export default function useGoogleAttributes(category, customerId) {
  const queryKey = useMemo(() => ["googleAttributes", category], [category]);
  const [get] = useGet("/google/attributes", { params: { category, customerId } });
  return useQuery(queryKey, get, {
    enabled: !!category && !!customerId
  });
}
