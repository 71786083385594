import React from "react";
import SelectField from "../../components/Form/SelectField";
import FormField from "./FormField";

const HorizontalSelectField = ({
  name,
  label,
  labelWidth,
  inputWidth,
  inline,
  valid,
  feedback,
  noMargin,
  tip,
  help,
  children,
  selectKey,
  formFieldClass,
  ...props
}) => {
  return (
    <FormField
      label={label}
      name={name}
      valid={valid}
      tip={tip}
      noMargin={noMargin}
      feedback={feedback}
      help={help}
      inline={inline}
      className={formFieldClass}
    >
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="flex-fill">
          <SelectField {...props} id={name} name={name} key={selectKey} />
        </div>
        {children && <div className="mb-3">{children}</div>}
      </div>
    </FormField>
  );
};

HorizontalSelectField.propTypes = {
  ...FormField.propTypes
};

export default HorizontalSelectField;
