import classNames from "classnames";
import { Card } from "components/Card";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import { Alert, Col, Row } from "reactstrap";
import styles from "./Hipaa.module.scss";
const { container, listContainer, ul } = styles;

export default function HipaaReminder({ setHipaaAcknowledged, hipaaAcknowledged }) {
  return (
    <Row>
      <Col>
        <Card className="mt-2 mb-0 rounded-0 rounded-top p-3" color="light">
          <h6>HIPAA Compliance Required</h6>
          <div className={container}>
            <ul className={classNames(listContainer, ul, "text-muted")}>
              <li>Do not use “you” or “your”</li>
              <li>Do not acknowledge/confirm/deny any facts about the person’s visit</li>
              <li>Do not mention repeat or future visits</li>
            </ul>
            <ul className={classNames(listContainer, ul, "text-muted")}>
              <li>Keep responses as generic as possible</li>
              <li>Do not use the word “patient”</li>
              <li>Do not use the reviewer’s name</li>
            </ul>
          </div>
        </Card>
        <Alert className="py-1 mb-1 rounded-0 rounded-bottom" color="primary">
          <Labeled
            value={hipaaAcknowledged}
            onToggle={setHipaaAcknowledged}
            label="This response follows HIPAA guidelines"
          />
        </Alert>
      </Col>
    </Row>
  );
}
