import styles from "./Pages.module.scss";

const { container, pages } = styles;

export default function Pages({ children }) {
  return (
    <div className={container}>
      <div className={`${pages} bg-white`}>{children}</div>
    </div>
  );
}
