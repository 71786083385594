import React, { useState, useMemo, useEffect } from "react";
import classnames from "classnames";
import { isNil, noop } from "lodash";
import SelectSiteIntegrationStep from "./Steps/SelectSiteIntegrationStep";
import GoogleLinking from "views/Customer/GoogleLinking";
import WWButton from "components/Buttons/WWButton";
import styles from "./AddIntegrationWizard.module.scss";
import MindbodyLinking from "views/Customer/MindbodyLinking";
import CarsDealerraterLinking from "views/Customer/CarsDealerraterLinking";
import CarGurusLinking from "views/Customer/CarGurusLinking";
import CarfaxLinking from "views/Customer/CarfaxLinking";
import YelpLinking from "views/Customer/YelpLinking";
import EdmundsLinking from "views/Customer/EdmundsLinking";
import FacebookLinking from "views/Customer/FacebookLinking";
import KeapLinking from "views/Customer/KeapLinking";
import HubspotLinking from "views/Customer/HubspotLinking";
import SquareLinking from "views/Customer/SquareLinking";
import { useLocationQueryParamState } from "hooks/stateHooks";

const { wizardWrapperClass, stepsWrapperClass, hiddenStepClass, buttonsContainerClass } = styles;

const SELECT_SITE_INTEGRATION_STEP_INDEX = 0;
const LINK_SITE_STEP = 1;

const AddIntegrationWizard = ({ customer, currentAgency, updateCustomerField, onFinish = noop, site }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [stepsData, setStepsData] = useState({});
  const [urlSite, setUrlSite] = useLocationQueryParamState("site");
  const steps = useMemo(() => {
    const steps = [
      <SelectSiteIntegrationStep
        setStepData={stepData => setStepsData({ ...stepsData, [SELECT_SITE_INTEGRATION_STEP_INDEX]: stepData })}
        preselectedSource="GOOGLE"
      />
    ];
    switch (stepsData[SELECT_SITE_INTEGRATION_STEP_INDEX]) {
      case "GOOGLE":
        steps.push(
          <GoogleLinking
            customer={customer}
            setStepData={stepData => setStepsData({ ...stepsData, [LINK_SITE_STEP]: stepData })}
          />
        );
        break;
      case "FACEBOOK":
        steps.push(<FacebookLinking customer={customer} />);
        break;
      case "HUBSPOT":
        steps.push(<HubspotLinking />);
        break;
      case "MINDBODY":
        steps.push(<MindbodyLinking customer={customer} />);
        break;
      case "DEALERRATER":
      case "CARS_DOT_COM":
        steps.push(<CarsDealerraterLinking customer={customer} />);
        break;
      case "CAR_GURUS":
        steps.push(<CarGurusLinking customer={customer} widewailAccount={currentAgency.carGurusEmail} />);
        break;
      case "CARFAX":
        steps.push(<CarfaxLinking customer={customer} />);
        break;
      case "EDMUNDS":
        steps.push(
          <EdmundsLinking customer={customer} widewailAccount={currentAgency.edmundsEmail} showInstructions={true} />
        );
        break;
      case "YELP":
        steps.push(
          <YelpLinking
            customer={customer}
            onChange={infos => updateCustomerField("yelpInfo", infos)}
            widewailAccount={currentAgency.yelpEmail}
          />
        );
        break;
      case "KEAP":
        steps.push(<KeapLinking customer={customer} />);
        break;
      case "SQUARE":
        steps.push(<SquareLinking customer={customer} />);
        break;

      // using Admin linking instead of oath flow
      // case "SERVICE_FUSION":
      //   steps.push(<ServiceFusionLinking customer={customer} />);
      //   break;
      default:
        break;
    }
    return steps;
  }, [setStepsData, stepsData, customer, currentAgency]);

  useEffect(() => {
    if (site) {
      setStepIndex(1);
      setStepsData({ 0: site });
    }
  }, [site]);

  useEffect(() => {
    if (stepIndex === 1 && stepsData[SELECT_SITE_INTEGRATION_STEP_INDEX]) {
      setUrlSite(stepsData[SELECT_SITE_INTEGRATION_STEP_INDEX]);
    } else {
      setUrlSite(null);
    }
  }, [stepIndex, stepsData]);

  const isLastStep = stepIndex === steps.length - 1 && stepIndex !== SELECT_SITE_INTEGRATION_STEP_INDEX;
  const incrementStep = () => {
    if (isLastStep) {
      onFinish();
    } else {
      setStepIndex(stepIndex + 1);
    }
  };
  const decrementStep = () => stepIndex > 0 && setStepIndex(stepIndex - 1);

  return (
    <div className={wizardWrapperClass}>
      <div className={stepsWrapperClass}>
        {steps.slice(0, stepIndex + 1).map((step, index) => (
          <div key={index} className={classnames({ [hiddenStepClass]: index !== stepIndex })}>
            {step}
          </div>
        ))}
      </div>
      <div className={buttonsContainerClass}>
        <WWButton color="primary" onClick={incrementStep}>
          {isLastStep ? "Close" : "Next"}
        </WWButton>
        {stepIndex > 0 && (
          <WWButton color="primary" onClick={decrementStep}>
            Back
          </WWButton>
        )}
      </div>
    </div>
  );
};

export default AddIntegrationWizard;
