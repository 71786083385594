import React from "react";
import { Button, InputGroup } from "reactstrap";
import FormField from "../../../components/Form/FormField";

export default class DatashakeAdminLinking {
  field() {
    return "datashakeInfo";
  }

  add() {
    const Add = ({ onSave }) => {
      return (
        <InputGroup>
          <Button
            color="primary"
            onClick={() =>
              onSave({
                source: "DATASHAKE"
              })
            }
          >
            Link
          </Button>
        </InputGroup>
      );
    };

    return Add;
  }

  edit() {
    const Edit = () => (
      <>
        <FormField label="Active">
          <div className="text-nowrap">
            <i class="fa fa-check" aria-hidden="true"></i>
          </div>
        </FormField>
      </>
    );
    return Edit;
  }
}
