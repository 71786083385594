import React from "react";
import { isFunction, get, debounce, isEmpty } from "lodash";
import { Table } from "reactstrap";
import Pager from "../Paging/Pager";

import styles from "./ListField.module.scss";
import SelectField from "./SelectField";
import { DEBOUNCE_DELAY_DEFAULT } from "constants/delays";
import WWButton from "components/Buttons/WWButton";

const { closeButtonCellClass } = styles;

const getFieldFactory = field => option => get(option, field);
const defaultGetLabel = getFieldFactory("label");
const defaultGetValue = getFieldFactory("value");

// actions can be a map of action name => call back function
const ListField = ({
  label,
  members,
  getMemberLabel = defaultGetLabel,
  getMemberValue = defaultGetValue,
  onAdd = () => { },
  onRemove,
  onRemoveAll,
  renderActions,
  page,
  onPageChange,
  type,
  placeholder,
  getOptionLabel = defaultGetLabel,
  loadOptions,
  options,
  async = true,
  cacheOptions = true,
  children,
  readOnly,
  ...props
}) => {
  return (
    <div>
      {children}
      {(loadOptions || options) && !readOnly && (
        <SelectField
          name="contact"
          async={async}
          cacheOptions={cacheOptions}
          defaultOptions
          placeholder={placeholder}
          loadOptions={loadOptions && debounce(loadOptions, DEBOUNCE_DELAY_DEFAULT)}
          getOptionLabel={getOptionLabel || getMemberLabel}
          getOptionValue={getMemberValue}
          value={null} //causes the input to clear when a value is selected
          onChange={event => {
            onAdd(event.target.value);
          }}
          options={options}
          {...props}
        />
      )}
      <Table responsive>
        {label && (
          <thead>
            <tr>
              <th>{label}</th>
            </tr>
          </thead>
        )}
        <tbody>
          {members?.map(m => (
            <tr key={getMemberValue(m)}>
              <td>
                <div className="d-flex align-items-center justify-content-between">
                  {getMemberLabel(m)}
                  {isFunction(renderActions) && renderActions(m)}
                </div>
              </td>
              {!readOnly && (
                <td className={closeButtonCellClass}>
                  <i className="icon-close" onClick={() => onRemove(m)} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {!readOnly && !isEmpty(members) && !!onRemoveAll && (
        <div className="d-flex justify-content-center">
          <WWButton color="danger" onClick={onRemoveAll}>
            Remove All {label}
          </WWButton>
        </div>
      )}
      {page && <Pager pageInfo={page} onPageChange={onPageChange} />}
    </div>
  );
};

export default React.memo(ListField);
