import { useCallback, useMemo, useContext, useState, useEffect } from "react";
import moment from "moment";
import { either, fromPairs, isEmpty, isNil, last, toPairs, pipe, reject } from "ramda";
import { pick, omitBy, isUndefined, isArray, omit } from "lodash";
import { useCampaignNameParameterSetter } from "./data/campaignHooks";
import useURLQuerySetter from "./useURLQuerySetter/useURLQuerySetter";
import { useLocationQueryParamSetter, useReduxAt } from "./stateHooks";
import { datetime2iso, isISOString } from "util/dateUtils";
import { encodeForURLQuery, valueOf } from "util/functionUtils";
import qs from "qs";
import { useLocation } from "react-router-dom";
import { useReduxPathFromUrlPath } from "./urlHooks";
import { LOCATION_TYPES_SET, LOCATION_PARAMS, LOCATION_DEFAULT_URL_QUERY_FIELDS } from "data/customers";
import { DATA_PROVIDER_ACTIONS, DataProviderContext } from "containers/DataProvider";

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

export const queryDateFormatter = dateValue => dateValue?.format(DEFAULT_DATE_FORMAT) || null;

export const FILTER_HOOK_NAMES = Object.freeze({
  setCustomer: "setCustomer",
  setContact: "setContact",
  setBrand: "setBrand",
  setStartDate: "setStartDate",
  setEndDate: "setEndDate",
  setFrequency: "setFrequency",
  setComparisonMode: "setComparisonMode",
  setCampaign: "setCampaign"
});

export const useReportFilteringQueryParamsSetters = customFieldsSet => {
  const datePipe = useCallback(queryDateFormatter, []);
  const isFieldNeeded = useMemo(() => field => !customFieldsSet || customFieldsSet.includes(field), [customFieldsSet]);
  const hooksSetDescription = useMemo(
    () => [
      {
        field: FILTER_HOOK_NAMES.setCustomer,
        hookFunction: useLocationQueryParamSetter,
        hookFunctionArguments: ["customer"]
      },
      {
        field: FILTER_HOOK_NAMES.setContact,
        hookFunction: useLocationQueryParamSetter,
        hookFunctionArguments: ["contact"]
      },
      {
        field: FILTER_HOOK_NAMES.setBrand,
        hookFunction: useLocationQueryParamSetter,
        hookFunctionArguments: ["brand"]
      },
      {
        field: FILTER_HOOK_NAMES.setStartDate,
        hookFunction: useLocationQueryParamSetter,
        hookFunctionArguments: ["startDate", datePipe]
      },
      {
        field: FILTER_HOOK_NAMES.setEndDate,
        hookFunction: useLocationQueryParamSetter,
        hookFunctionArguments: ["endDate", datePipe]
      },
      {
        field: FILTER_HOOK_NAMES.setFrequency,
        hookFunction: useLocationQueryParamSetter,
        hookFunctionArguments: ["frequency"]
      },
      {
        field: FILTER_HOOK_NAMES.setComparisonMode,
        hookFunction: useLocationQueryParamSetter,
        hookFunctionArguments: ["mode"]
      },
      { field: FILTER_HOOK_NAMES.setCampaign, hookFunction: useCampaignNameParameterSetter, hookFunctionArguments: [] }
    ],
    []
  );

  const hooksSet = {};
  hooksSetDescription.forEach(({ field, hookFunction, hookFunctionArguments }) => {
    if (isFieldNeeded(field)) {
      hooksSet[field] = hookFunction(...hookFunctionArguments);
    }
  });

  return hooksSet;
};

export const useFilterReduxKey = () => {
  const location = useLocation();
  const reduxKey = useReduxPathFromUrlPath(location.pathname);
  return reduxKey;
};

export const useFilterSearch = reduxPath => {
  const relatedReduxValue = useReduxAt(reduxPath);
  const normalized = encodeForURLQuery(omit(relatedReduxValue, ["page", "urlLoaded"]));
  const reduxSearch = qs.stringify(normalized, { indices: false });
  return reduxSearch;
};

export const useFilterResetter = filters => {
  const [, setter] = useFilterState();

  const resetAllFilters = useCallback(() => {
    const filtersDefaultValues = Object.assign(
      {},
      ...filters
        .filter(filter => !isNil(filter.defaultValue))
        .map(filter => ({
          [filter.name]: valueOf(filter.defaultValue)
        }))
    );
    setter(omitBy(filtersDefaultValues, isUndefined));
  }, [filters]);

  return resetAllFilters;
};
export const useFilterState = () => {
  const key = useFilterReduxKey();
  const value = useReduxAt(key);
  const setter = useURLQuerySetter();

  return [value, setter];
};

/**
 * removes the urlLoaded status from filter values
 * @param filters filters you want to remove urlLoaded status
 */
export const filtersWithoutUrlStatus = filters => omit(filters, ["urlLoaded"]);

export const useFilterValueState = (name, type, queryParams) => {
  const key = useFilterReduxKey();
  const value = useReduxAt([key, name]);
  const fullValue = useReduxAt(key);
  const decodedValue = decoder(value, type, queryParams);
  const setter = useURLQuerySetter(true, pipe(toPairs, reject(pipe(last, either(isNil, isEmpty))), fromPairs));
  const set = useCallback(
    valueToSet => {
      const encoded = encoder(valueToSet, type, queryParams);
      const encodedEntries = { [name]: encoded };
      setter(filtersWithoutUrlStatus({ ...fullValue, ...encodedEntries }));
    },
    [name, type, queryParams, fullValue, value]
  );

  const useFilterArray = useMemo(() => [decodedValue, set, fullValue], [decodedValue, set, fullValue]);
  return useFilterArray;
};

const decoder = (value, type, queryParams) => {
  try {
    switch (type) {
      case "date":
        return isISOString(value) ? moment(value) : undefined;
      case "dates":
        return !isUndefined(value) && isArray(queryParams) && !queryParams.some(param => isNil(value[param]))
          ? queryParams.map(param => (isISOString(value[param]) ? moment(value[param]) : undefined))
          : [];
      case "number":
        const number = Number(value);
        return isNaN(number) ? undefined : number;
      default:
        return value;
    }
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const encoder = (value, type, queryParams) => {
  try {
    if (LOCATION_TYPES_SET.has(type)) {
      return pick(
        isArray(value) && !!value.length ? value[0] : value,
        LOCATION_PARAMS[type].urlQueryFields || LOCATION_DEFAULT_URL_QUERY_FIELDS
      );
    }
    switch (type) {
      case "date":
        return datetime2iso(value);
      case "dates":
        const valueHasDates = value && value?.length === 2 && value.every(date => !isNil(date));
        return fromPairs(
          queryParams?.map((param, index) => [[param], valueHasDates ? datetime2iso(value[index]) : undefined])
        );
      case "campaign":
        return (Array.isArray(value) ? value : [value]).map(item => pick(item, ["name", "id"]));
      default:
        return value;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const useToggleFilter = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const { dispatch } = useContext(DataProviderContext);
  const setFilterActive = useCallback(payload => dispatch({ type: DATA_PROVIDER_ACTIONS.setFilterActive, payload }), [
    dispatch
  ]);
  useEffect(() => () => isOpen && setFilterActive(false), []);
  const toggle = () => {
    setIsOpen(prev => {
      const next = !prev;
      setFilterActive(next);
      return next;
    });
  };
  return [isOpen, toggle];
};
