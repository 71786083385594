import React, { useCallback, useMemo, useState } from "react";
import { Collapse } from "reactstrap";
import FormField from "components/Form/FormField";
import InputField from "components/Form/InputField";
import { updateOpenTableInfo } from "api/customerApi";
import WWButton from "components/Buttons/WWButton";

export default class OpenTable {
  field() {
    return "openTableInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info, customer }) => {
  const [initialRestaurantId, setInitialRestaurantId] = useState(info.restaurantId);
  const [restaurantId, setRestaurantId] = useState(initialRestaurantId);

  const patchReady = useMemo(() => initialRestaurantId !== restaurantId, [initialRestaurantId, restaurantId]);
  const saveToggles = useCallback(() => {
    updateOpenTableInfo(customer.id, info.id, {
      restaurantId
    }).then(() => {
      setInitialRestaurantId(restaurantId);
    });
  }, [info, restaurantId, customer]);

  return (
    <>
      <InputField
        type="text"
        name="restaurantId"
        label="OpenTable Restaurant Id"
        placeholder="Id"
        onChange={e => setRestaurantId(e.target.value)}
        value={restaurantId}
      />
      <Collapse isOpen={patchReady}>
        <FormField label=" ">
          <WWButton color="primary" onClick={saveToggles} disabled={!patchReady}>
            Update
          </WWButton>
        </FormField>
      </Collapse>
    </>
  );
};

const Add = ({ onSave }) => {
  const [restaurantId, setRestaurantId] = useState();

  const onLink = useCallback(
    () =>
      onSave({
        restaurantId,
        source: "OPEN_TABLE"
      }),
    [restaurantId, onSave]
  );

  return (
    <>
      <InputField
        type="text"
        name="restaurantId"
        label="OpenTable Restaurant Id"
        placeholder="Id"
        onChange={e => setRestaurantId(e.target.value)}
        value={restaurantId}
      />
      <WWButton disabled={!restaurantId} color="primary" onClick={onLink}>
        Link
      </WWButton>
    </>
  );
};
