import last from "lodash/last";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import routes from "routes";

export const findRouteName = url =>
  routes[url.replace(/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/, ":id")];

const getPaths = pathname => {
  const paths = ["/"];

  if (pathname === "/") return paths;

  pathname.split("/").reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

/**
 * Get the names of the current breadcrumb path. ex: ["Home", "Review Performance"]
 *
 * @returns {string[]} - Friendly names of the current path
 */
export const useCurrentRouteNames = () => {
  const paths = useCurrentRoutePath();
  const names = useMemo(() => paths.map(findRouteName).filter(route => !!route), [paths]);
  return names;
};

/**
 * Returns the name of the current page. ex: "User Dashboard"
 *
 * @returns {string} - The name of the current page
 */
export const useCurrentRouteName = () => {
  const paths = useCurrentRouteNames();
  return last(paths);
};

/**
 * Gets the current path route. ex: ["/", "/report", "/report/customer"]
 * @returns {string[]} - The current url path heirarchy
 */
export const useCurrentRoutePath = () => {
  const location = useLocation();
  return useMemo(() => getPaths(location.pathname), [location.pathname]);
};
