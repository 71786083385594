import React, { useCallback, useState } from "react";
import Block from "../../components/Layout/Block";
import QueueImportButton from "../../views/Customer/QueueImportButton";
import AjaxLoader from "../Misc/AjaxLoader";
import StatusMessages from "../Misc/StatusMessages";
import { previewImport } from "../../api/customerApi";
import { Container } from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";

export default function FeedPreview({ customer, info, onClose }) {
  const [records, setRecords] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(undefined);

  const request = useCallback(
    siteSource => {
      setLoading(true);
      previewImport(customer.id, siteSource.source, siteSource.siteInfoId, siteSource.endDate)
        .then(data => setRecords(data))
        .catch(() => setErrors("Failed loading preview"))
        .finally(() => setLoading(false));
    },
    [customer]
  );

  return (
    <Container className="py-2">
      <div className="d-flex justify-content-between">
        <h4>Preview Import Feed</h4>
        <CloseButton onClick={onClose} />
      </div>
      <div className="text-center">
        <QueueImportButton label="Select start date" info={info} queueImport={request} source={info.source} />
        <StatusMessages errors={errors} />
        {loading && <AjaxLoader />}
      </div>
      {records && records.length === 0 && <p className="w-100 text-center">No results</p>}
      {records?.map(({ firstName, lastName, interactions, extra }) => (
        <Block>
          <div>
            <strong>{`${firstName} ${lastName}`}</strong>
            {" - "}
            {interactions?.map(({ tags }) => tags?.join(", "))}
          </div>
          {extra && (
            <div>
              {Object.keys(extra)
                .map(k => `${k}: ${extra[k]}`)
                .join(", ")}
            </div>
          )}
        </Block>
      ))}
    </Container>
  );
}
