export const productsSet = [
  {
    product: "REVIEW_IMPORT",
    label: "Review Import",
    category: "Engage"
  },
  {
    product: "REVIEW_RESPONSE",
    label: "Review Response",
    category: "Engage"
  },
  {
    product: "AUTORESPONSE",
    label: "Autoresponse",
    category: "Engage"
  },
  {
    label: "Facebook Posts",
    product: "FACEBOOK_POSTS",
    category: "Engage Plus"
  },
  {
    label: "Facebook Ads",
    product: "FACEBOOK_ADS",
    category: "Engage Plus"
  },
  {
    label: "Google Questions",
    product: "GOOGLE_QUESTIONS",
    category: "Engage Plus"
  },
  {
    label: "Instagram",
    product: "INSTAGRAM",
    category: "Engage Plus"
  },
  {
    label: "LinkedIn",
    product: "LINKEDIN",
    category: "Engage Plus"
  },
  {
    label: "Review Lead Import",
    product: "REVIEW_LEAD_IMPORT",
    category: "Invite"
  },
  {
    label: "Review Invitations",
    product: "REVIEW_INVITE",
    category: "Invite"
  },
  {
    label: "Video Testimonials",
    product: "INVITE_VIDEO",
    category: "Invite"
  },
  {
    label: "Create",
    product: "CREATE",
    category: "Create"
  },
  {
    label: "Share",
    product: "SHARE",
    category: "Create"
  },
  {
    label: "Website Widget",
    product: "WEBSITE_WIDGET",
    category: "Widgets"
  },
  {
    label: "Listings Management",
    product: "LISTINGS_MANAGEMENT",
    category: "Listings"
  },
  {
    label: "AI Response",
    product: "AI_RESPONSE",
    category: "Engage"
  },
  {
    label: "AI Assist",
    product: "AI_ASSIST",
    category: "Engage"
  },
  {
    label: "AI Analytics",
    product: "AI_ANALYTICS",
    category: "Engage"
  }
];

export const InviteProductsKeys = Object.freeze({
  reviewInvite: "REVIEW_INVITE",
  inviteVideo: "INVITE_VIDEO"
});

export const inviteCustomerProductsKeys = [InviteProductsKeys.reviewInvite, InviteProductsKeys.inviteVideo];

export const EngageProductKeys = Object.freeze({
  reviewResponse: "REVIEW_RESPONSE"
});

export const engageCustomerProductsKeys = Object.values(EngageProductKeys);
