import WWTableBlock from "components/Table/WWTableBlock";
import WidgetCard from "../CommonComponents/WidgetCard";
import LinkButton from "components/Routing/LinkButton";
import { useGet } from "hooks/dataHooks";
import { useQuery } from "react-query";

import styles from "./LocationPerformanceWidget.module.scss";
const { dualTableContainer } = styles;
const allLocationsSchema = [
  {
    label: <strong>Locations</strong>,
    valueKey: "visits",
    renderCell: ({ name }) => name,
    headerClassName: "text-dark"
  },
  {
    label: <strong>Rating</strong>,
    valueKey: "rating",
    type: WWTableBlock.cellTypes.number,
    headerClassName: "text-dark"
  }
];

const topPerformersSchema = [
  {
    label: <strong>Top Performers</strong>,
    valueKey: "visits",
    renderCell: ({ name }) => (
      <span className="truncate">{name?.length > 18 ? name?.substring(0, 18) + "..." : name}</span>
    ),
    headerClassName: "text-success"
  },
  {
    label: <strong>Rating</strong>,
    valueKey: "rating",
    type: WWTableBlock.cellTypes.number,
    headerClassName: "text-success"
  }
];
const bottomPerformersSchema = [
  {
    label: <strong>Bottom Performers</strong>,
    valueKey: "visits",
    renderCell: ({ name }) => (
      <span className="truncate">{name?.length > 18 ? name?.substring(0, 18) + "..." : name}</span>
    ),
    headerClassName: "text-danger"
  },
  {
    label: <strong>Rating</strong>,
    valueKey: "rating",
    type: WWTableBlock.cellTypes.number,
    headerClassName: "text-danger"
  }
];

const LocationPerformanceWidget = () => {
  const topPerformersQuery = useLocationPerformanceData();
  if (topPerformersQuery?.data?.topPerformers?.length <= 1 || topPerformersQuery.isLoading) {
    return null;
  } else {
    return (
      <WidgetCard heading={"Top/Bottom Performers"} headingInfo={"Last 30 days"}>
        {topPerformersQuery.data?.bottomPerformers?.length > 0 ? (
          <span className={dualTableContainer}>
            <WWTableBlock
              schema={topPerformersSchema}
              data={topPerformersQuery.data?.topPerformers?.slice(0, 5)}
              tableContainerClassnames="m-0 w-100"
              tableBodyClassnames="p-0 fs-2"
            />
            <WWTableBlock
              schema={bottomPerformersSchema}
              data={topPerformersQuery.data?.bottomPerformers?.slice(0, 5)}
              tableContainerClassnames="m-0"
              tableBodyClassnames="p-0 fs-2"
            />
          </span>
        ) : (
          <WWTableBlock
            tableContainerClassnames="m-0"
            tableBodyClassnames="p-0 fs-2"
            compact
            schema={allLocationsSchema}
            data={topPerformersQuery?.data?.topPerformers}
          />
        )}
        <div className="d-flex justify-content-center">
          <LinkButton trackingAction="Location Performance View full report" className="mt-0" color="primary" to="/report/customer">
            View Full Report
          </LinkButton>
        </div>
      </WidgetCard>
    );
  }
};

export const useLocationPerformanceData = () => {
  const path = "/dash/topBottomPerformance";
  const [get] = useGet(path);
  return useQuery([path], get, {});
};

export default LocationPerformanceWidget;
