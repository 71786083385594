import React, { useState, useEffect, useCallback, useMemo } from "react";
import { map, isEmpty, debounce, isUndefined } from "lodash";
import { useSelector } from "react-redux";
import memoize from "memoizee";
import * as facebookApi from "api/facebookApi";
import { Row, Col, Button, Collapse, Alert } from "reactstrap";
import StatusMessages from "components/Misc/StatusMessages";
import FacebookLoginButton from "components/Facebook/FacebookLoginButton";
import SelectField from "components/Form/SelectField";
import AjaxLoader, { AjaxLoaderSizes } from "components/Misc/AjaxLoader";
import Block from "components/Layout/Block";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import ProgressTracker from "components/ProgressTracker/ProgressTracker";
import { DefaultDuplicateWarning } from "./AdminLinking";
import DeleteButton from "components/Buttons/DeleteButton";
import WWAccordionSection from "components/WWAccordion/WWAccordionSection";
import StatusInfo from "components/Table/StatusInfo/StatusInfo";
import { isProductActiveForCustomer } from "util/customerUtils";
import { getLinkingErrorMessageObject } from "util/customerUtils";
import {
  useCurrentFacebookUser,
  useFacebookAdAccounts,
  useFacebookBusinesses,
  useFacebookBusinessPages
} from "hooks/data/facebookApiHooks";
import { useAddAndRemoveSite, useSiteInfo, useUpdateDefaultReviewTag } from "hooks/customerHooks";
import { autoclose } from "components/Modals";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import { useNotification } from "components/Notifications/notification";
import { includes } from "ramda";
import { SITE_SOURCE_NAMES, SOURCES } from "data/sites";

import styles from "./SelfServeLinking.module.scss";

const { linkedSitesAccordion, linkedSitesAccordionTitle } = styles;

const LINK_STATUS_LABELS = {
  INVITE_SENT: "Invited",
  BM_LINKED: "Linked",
  USER_LINKED: "Pending Agency Link"
};

export const FacebookIntegrationSteps = Object.freeze({
  logIn: "log in",
  selectAccount: "select pages",
  linkProfiles: "link pages"
});

const LOG_IN_STEP_INDEX = 0;
const SELECT_BUSINESS_STEP_INDEX = 1;
const LINK_SITE_STEP_INDEX = 2;
const FINISH_STEP_INDEX = 3;

const getPageUrl = memoize(pageId => `https://www.facebook.com/${pageId}/settings/?tab=admin_roles&ref=page_edit`);

export default function FacebookLinking({ customer }) {
  const [activeStepIndex, setActiveStepIndex] = useState(LOG_IN_STEP_INDEX);
  const facebookUser = useCurrentFacebookUser();
  const isFacebookLoggedIn = useMemo(() => !!facebookUser?.id);
  const currentAgency = useSelector(state => state.currentAgency);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState();
  const [errors, setErrors] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState();
  const [facebookInfos, setFacebookInfos] = useState([]);
  const [linkedAdAccounts, setLinkedAdAccounts] = useState([]);
  const [working, setWorking] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [unchangedFromLastLink, setUnchangedFromLastLink] = useState(true);
  const [adAccountSelectInput, setAdAccountSelectInput] = useState();
  const [tag, setTag] = useState();
  const [isDepartmentSpecific, setIsDepartmentSpecific] = useState(false);

  const siteLinkingParams = {
    customerId: customer.id,
    field: SOURCES[SITE_SOURCE_NAMES.facebook].customerField
  };

  const [addSite, removeSite] = useAddAndRemoveSite(siteLinkingParams);
  const updateDefaultReviewCategory = useUpdateDefaultReviewTag(siteLinkingParams);
  const siteInfo = useSiteInfo(siteLinkingParams);

  const notify = useNotification();

  const canLinkWithoutTag = useMemo(
    () => !(facebookInfos || []).filter(({ defaultReviewTag }) => isUndefined(defaultReviewTag)).length > 0,
    [facebookInfos]
  );

  const availableTags = useMemo(
    () =>
      customer?.reviewTagSet?.tags?.filter(
        value =>
          !includes(
            value,
            facebookInfos?.map(({ defaultReviewTag }) => defaultReviewTag).filter(value => value !== "General")
          )
      ),
    [customer?.reviewTagSet?.tags, facebookInfos]
  );

  const [pageSelectInput, _setPageSelectInput] = useState();
  const showAdAccounts = useMemo(() => isProductActiveForCustomer(customer, "FACEBOOK_ADS"), [customer]);
  const businessFetch = useFacebookBusinesses();
  const adAccountFetch = useFacebookAdAccounts(showAdAccounts ? selectedBusiness?.id : undefined, {
    filter: { name: adAccountSelectInput }
  });
  const pagesFetch = useFacebookBusinessPages(selectedBusiness?.id, { filter: { name: pageSelectInput } });
  const confirm = useConfirmationDialog();
  const setPageSelectInput = useCallback(
    debounce(input => _setPageSelectInput(input), 500),
    [_setPageSelectInput]
  );

  useEffect(() => {
    const { data, isSuccess, isLoading } = siteInfo;
    if (!isLoading && isSuccess) {
      setFacebookInfos(data?.facebookInfoes || []);
    }
  }, [siteInfo.isSuccess, siteInfo.isLoading, siteInfo.data]);

  const defaultGetOptionLabel = useCallback(({ name }) => name, []);
  const defaultGetOptionValue = useCallback(({ id }) => id, []);
  const isPageLinked = useCallback(({ id }) => !!facebookInfos?.find(({ pageId }) => id === pageId), [facebookInfos]);
  const isAdAccountLinked = useCallback(adAccount => !!linkedAdAccounts?.find(({ id }) => adAccount.id === id), [
    linkedAdAccounts
  ]);

  const loadLinkedAdAccounts = useCallback(() => {
    if (selectedBusiness && !!facebookInfos?.find(({ businessId }) => selectedBusiness.id === businessId)) {
      return facebookApi
        .getAdAccounts(selectedBusiness.id)
        .then(res => setLinkedAdAccounts(res))
        .catch(setErrors);
    }
  }, [selectedBusiness, facebookInfos]);

  const reset = useCallback(() => {
    setSelectedPages(undefined);
    setSelectedAdAccounts(undefined);
    setLinkedAdAccounts(undefined);
  }, []);

  useEffect(() => {
    loadLinkedAdAccounts();
    reset();
  }, [selectedBusiness, loadLinkedAdAccounts]);

  useEffect(() => {
    if (!facebookUser) {
      setSelectedBusiness(undefined);
      reset();
      setActiveStepIndex(LOG_IN_STEP_INDEX);
    }
  }, [facebookUser]);

  useEffect(() => {
    if (!!businessFetch.data && businessFetch.data.length > 0) {
      setActiveStepIndex(SELECT_BUSINESS_STEP_INDEX);
    }
  }, [businessFetch.data]);

  const onSelectPage = useCallback(
    ({ target: { value } }) => {
      if (facebookInfos?.length === 1) {
        autoclose(
          confirm({
            title: `Are you sure you want to add more than one Facebook page to ${customer.companyName}`,
            body:
              "You are attempting to link an additional Facebook page to this location. We recommend that each Widewail location be linked to a single Facebook page. Ensure you are not linking Facebook pages for multiple locations to a single location in Widewail. Would you like to continue?"
          })
        ).then(confirmation => {
          if (confirmation) {
            setSelectedPages(value);
            setUnchangedFromLastLink(false);
          }
        });
      } else {
        setSelectedPages(value);
        setUnchangedFromLastLink(false);
      }
    },
    [selectedPages, customer]
  );

  const linkPagesWithAccessToken = useCallback(async () => {
    const { id, name, access_token } = selectedPages;

    addSite({
      source: SITE_SOURCE_NAMES.facebook,
      pageId: id,
      userAccessToken: facebookUser.accessToken,
      businessId: selectedBusiness.id,
      pageName: name,
      pageAccessToken: access_token,
      defaultReviewTag: tag
    })
      .then(() => {
        setActiveStepIndex(FINISH_STEP_INDEX);
        reset();
        notify({ body: "successful page linking" });
      })
      .catch(linkingResult => {
        setDuplicates(linkingResult.response?.data.duplicateLinks);
        setErrors(getLinkingErrorMessageObject(linkingResult));
      });
  }, [selectedPages, facebookInfos, selectedBusiness, tag]);

  const linkAdAccountsWithAccessToken = useCallback(async () => {
    if (showAdAccounts && selectedAdAccounts) {
      facebookApi
        .linkAdAccounts(selectedBusiness.id, {
          adAccountId: selectedAdAccounts.id,
          userAccessToken: facebookUser.accessToken
        })
        .then(() => {
          setActiveStepIndex(FINISH_STEP_INDEX);
          reset();
          notify({ body: "successful Ad linking" });
        })
        .catch(error => {
          setErrors(getLinkingErrorMessageObject(error));
          reset();
        });
      // not need to wait this request result for more responsive rendering
      loadLinkedAdAccounts();
    }
  }, [selectedBusiness, showAdAccounts, selectedAdAccounts]);

  const onLink = useCallback(async () => {
    setWorking(true);
    if (selectedPages) {
      await linkPagesWithAccessToken();
    }
    if (selectedAdAccounts) {
      await linkAdAccountsWithAccessToken();
    }
    setUnchangedFromLastLink(true);
    setWorking(false);
  }, [linkPagesWithAccessToken, linkAdAccountsWithAccessToken, setWorking, setUnchangedFromLastLink]);

  const onSelectBusiness = useCallback(event => {
    const business = event.target.value;
    setActiveStepIndex(LINK_SITE_STEP_INDEX);
    setSelectedBusiness(business);
    reset();
  }, []);

  const onUnlink = useCallback(
    (event, info) => {
      event.stopPropagation();
      removeSite(info.id).then(() => window.open(getPageUrl(info.pageId), "_blank"));
    },
    [customer]
  );

  const onSelectAdAccount = useCallback(
    ({
      target: {
        value: { id, name }
      }
    }) => {
      setSelectedAdAccounts({ id, name });
      setUnchangedFromLastLink(false);
    },
    [selectedBusiness, selectedAdAccounts]
  );

  const onTagChange = useCallback(
    (event, info) => {
      updateDefaultReviewCategory({ siteId: info.id, defaultReviewTag: event.target.value })
        .then(res => setFacebookInfos(res.data))
        .catch(error => setErrors(error));
    },
    [customer]
  );

  const linkButtonText = useMemo(
    () =>
      !isEmpty(selectedPages) || (isEmpty(selectedPages) && isEmpty(selectedAdAccounts))
        ? `Link Pages${isEmpty(selectedAdAccounts) ? "" : " And Ad Accounts"}`
        : "Link Ad Accounts",
    [selectedPages, selectedAdAccounts]
  );

  const hasInfoForSelectedBusiness = useMemo(
    () => !isEmpty(facebookInfos?.find(({ businessId }) => businessId === selectedBusiness?.id)),
    [facebookInfos, selectedBusiness]
  );

  const linkButtonDisabled = useMemo(
    () =>
      unchangedFromLastLink ||
      (!hasInfoForSelectedBusiness && isEmpty(selectedPages) && (!showAdAccounts || isEmpty(selectedAdAccounts))),
    [selectedPages, selectedAdAccounts, unchangedFromLastLink, hasInfoForSelectedBusiness, showAdAccounts]
  );

  return (
    <div>
      <h4 className="text-center">{customer.companyName}</h4>
      <ProgressTracker
        steps={[
          FacebookIntegrationSteps.logIn,
          FacebookIntegrationSteps.selectAccount,
          FacebookIntegrationSteps.linkProfiles
        ]}
        activeStepIndex={activeStepIndex}
      />
      <Block>
        <StatusMessages errors={errors} />
        <DefaultDuplicateWarning duplicates={duplicates} agency={currentAgency} />
      </Block>
      <Block className="mt-4">
        <h4>Log In</h4>
        <p>
          In order to manage your recommendations Widewail must be added as an Agency to your page(s). Before beginning
          make sure you are a manager on your page and that you have access to Facebook Business Manager.
        </p>
      </Block>
      <FacebookLoginButton facebookAdsEnabled={showAdAccounts} />
      {isFacebookLoggedIn && (
        <>
          <Block className="mt-4">
            <h4>Select Business</h4>
            <SelectField
              name="business"
              isLoading={businessFetch.isFetching}
              options={businessFetch.data || []}
              getOptionLabel={defaultGetOptionLabel}
              getOptionValue={defaultGetOptionValue}
              onChange={onSelectBusiness}
              hideSelectedOptions
            />
          </Block>
          <Block className="my-4">
            {pagesFetch.isError && (
              <Alert color={"danger"}>There was an error fetching your Facebook Ad Accounts</Alert>
            )}
            <h4>Select Page</h4>
            <SelectField
              name="page"
              options={pagesFetch.data || []}
              isLoading={pagesFetch.isFetching}
              isDisabled={pagesFetch.isError || !selectedBusiness}
              isOptionDisabled={isPageLinked}
              getOptionLabel={defaultGetOptionLabel}
              getOptionValue={defaultGetOptionValue}
              value={selectedPages || undefined}
              onChange={onSelectPage}
              inputValue={pageSelectInput}
              onInputChange={(value, action) => {
                if (action?.action !== "input-blur" && action?.action !== "menu-close") {
                  setPageSelectInput(value);
                }
              }}
              hideSelectedOptions
            />
            <div className="mt-2">
              {canLinkWithoutTag ? (
                <>
                  <Labeled
                    label="Is this a department specific page?"
                    checked={isDepartmentSpecific}
                    onToggle={setIsDepartmentSpecific}
                  />
                  <Collapse isOpen={isDepartmentSpecific}>
                    <HorizontalSelectField
                      name="defaultReviewTag"
                      value={tag}
                      label="Default Review Tag"
                      simpleValue={true}
                      options={availableTags}
                      onChange={event => setTag(event.target.value)}
                      isClearable={true}
                    />
                  </Collapse>
                </>
              ) : (
                <HorizontalSelectField
                  name="defaultReviewTag"
                  value={tag}
                  label="Default Review Tag"
                  simpleValue={true}
                  options={availableTags}
                  onChange={event => setTag(event.target.value)}
                  isClearable={true}
                />
              )}
            </div>
          </Block>
        </>
      )}

      {showAdAccounts && hasInfoForSelectedBusiness && (
        <Block className="mt-1 mb-4">
          {adAccountFetch.isError && (
            <Alert color={"danger"}>There was an error fetching your Facebook Ad Accounts</Alert>
          )}
          <h4>Select Ad Accounts</h4>
          <SelectField
            name="ads"
            isLoading={adAccountFetch.isFetching}
            options={adAccountFetch.data || []}
            isOptionDisabled={isAdAccountLinked}
            getOptionLabel={defaultGetOptionLabel}
            getOptionValue={defaultGetOptionValue}
            onChange={onSelectAdAccount}
            value={selectedAdAccounts}
            placeholder="Search All Ad Accounts"
            inputValue={adAccountSelectInput}
            onInputChange={setAdAccountSelectInput}
            hideSelectedOptions
            isDisabled={adAccountFetch.isError || !selectedBusiness}
          />
        </Block>
      )}
      <Block xs="auto">
        <Collapse isOpen={!isEmpty(selectedPages) || !isEmpty(selectedAdAccounts)}>
          <Button
            color="primary"
            disabled={working || linkButtonDisabled || (!canLinkWithoutTag && !tag)}
            onClick={onLink}
          >
            {working ? <AjaxLoader size={AjaxLoaderSizes.XS} /> : linkButtonText}
          </Button>
        </Collapse>
      </Block>
      <Block className="my-4">
        <hr />
        {!isEmpty(facebookInfos) && <h4>Linked Pages</h4>}
        {map(facebookInfos, info => (
          <Block key={info.id}>
            <WWAccordionSection
              caption={
                <div className={linkedSitesAccordion}>
                  <div className={linkedSitesAccordionTitle}>
                    <span className="fs-3">{info.pageName}</span>
                    <div className={linkedSitesAccordion}>
                      {info.defaultReviewTag && <strong className="fs-3">{info.defaultReviewTag}</strong>}
                      <StatusInfo
                        className="fs-3"
                        status={LINK_STATUS_LABELS[info.status]}
                        statusColorMapper={{
                          Invited: "warning",
                          "Pending Agency Link": "warning",
                          Linked: "success"
                        }}
                      />
                    </div>
                  </div>
                  <DeleteButton
                    type="button"
                    size="sm"
                    tooltip="Remove Site"
                    squareButton
                    iconClass="fa fa-chain-broken"
                    confirmationPrompt="Widewail will no longer import data from this location. Existing reviews will be marked read-only."
                    onClick={event => onUnlink(event, info)}
                  >
                    {null}
                  </DeleteButton>
                </div>
              }
            >
              {!isEmpty(facebookInfos) && (
                <Row>
                  <Col>
                    <HorizontalSelectField
                      name="defaultReviewTag"
                      value={info.defaultReviewTag}
                      label="Default Review Tag"
                      simpleValue={true}
                      options={customer.reviewTagSet.tags}
                      onChange={event => onTagChange(event, info)}
                      isClearable={true}
                      menuPortalTarget=""
                    />
                  </Col>
                </Row>
              )}
            </WWAccordionSection>
          </Block>
        ))}
      </Block>
    </div>
  );
}
