import { useState, useMemo, useCallback, useEffect, memo } from "react";
import { isEmpty, isEqual, orderBy, omit } from "lodash";
import QueryString from "qs";
import { Card, CardBody, CardHeader, CardHeaderActions, CardTitle } from "components/Card";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import Skeleton from "components/Skeleton";
import Form from "components/Form/Form";
import InputField from "components/Form/InputField";
import SaveBar from "components/Form/SaveBar";
import FormField from "components/Form/FormField";
import { useNotification } from "components/Notifications/notification";
import PhoneNumberField from "components/Form/PhoneNumberField";
import WWButton from "components/Buttons/WWButton";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import StatusMessages from "components/Misc/StatusMessages";
import ImportFromHubspotAction from "../Customer/ImportFromHubspotAction";
import { useCurrentAgency } from "hooks/agencyHooks";
import { accountKeys, useGetAccounts, useAccountUpdate, useAccountCreate } from "hooks/data/accountsHooks";
import { useTagSetsByAgency } from "hooks/data/tagSetHooks";
import { getContactName } from "util/getContactName";

const ON_SAVE_NOTIFY_TIMEOUT = 5000;
const ACCOUNTS_LIST_PATH = "/accounts";
const CONTACT_TYPES = ["PRIMARY", "BILLING", "TECHNICAL"];

const composeContactFieldName = (index, field) => `pointsOfContact[${index}].${field}`;

const getPreparedValues = ({ accountState, agencyId, initialPointsOfContact }) => {
  const formPointsOfContact = accountState.pointsOfContact.filter(({ name }) => !!name);
  return {
    ...accountState,
    tagSet: { id: accountState?.tagSet?.id || null },
    pointsOfContact: formPointsOfContact,
    agency: { id: agencyId }
  };
};

export const EditAccountFormLayout = memo(
  ({
    name,
    id,
    isNew,
    tagsOptions,
    accountState,
    setAccountState,
    onSave,
    isLoading,
    isUpdated,
    initialValues,
    invalidFields,
    populateFromHubspotRecord,
    currentAgencyName
  }) => {
    const history = useHistory();
    const errors = Object.values(invalidFields).filter(Boolean);
    const search = QueryString.stringify({ account: { id, name } });
    return (
      <>
        <Row>
          <Col md={6}>
            <Card>
              <CardHeader>
                <CardTitle>{isLoading ? <Skeleton width={200} /> : isNew ? "Account Details" : name}</CardTitle>
                <CardHeaderActions>
                  <ImportFromHubspotAction
                    currentAgencyName={currentAgencyName}
                    onPickCustomer={populateFromHubspotRecord}
                    enabled={isNew}
                  />
                </CardHeaderActions>
              </CardHeader>
              <CardBody>
                {!isNew && <h5 className="me-4">Account Info</h5>}
                <Form
                  state={accountState}
                  setState={setAccountState}
                  initialValues={initialValues}
                  className="form-horizontal"
                >
                  {!isLoading && errors.length > 0 && <StatusMessages errors={errors} />}
                  <InputField name="name" label="Name" valid={invalidFields.name ? false : undefined} />
                  <HorizontalSelectField
                    name="tagSet"
                    value={{
                      label: accountState.tagSet?.name,
                      value: accountState.tagSet
                    }}
                    label="Tag Set"
                    options={tagsOptions}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        borderColor: invalidFields.tagSet ? "#d32f2f" : "#e0e0e0"
                      })
                    }}
                    valid={invalidFields.tagSet ? false : undefined}
                  />
                  <h5 className="me-4">Contact Info</h5>
                  {CONTACT_TYPES.map((contactType, index) => (
                    <div key={contactType}>
                      <div className="text-capitalize mb-2">
                        <strong>{contactType.toLowerCase()}</strong>
                      </div>
                      <InputField
                        name={`pointsOfContact[${index}].name`}
                        label="Name"
                        valid={invalidFields[composeContactFieldName(index, "name")] ? false : undefined}
                      />
                      <div className="d-sm-flex w-100">
                        <FormField
                          name={composeContactFieldName(index, "phoneNumber")}
                          label="Phone"
                          className="me-sm-2 w-50"
                        >
                          <PhoneNumberField name={composeContactFieldName(index, "phoneNumber")} />
                        </FormField>
                        <FormField
                          name={composeContactFieldName(index, "emailAddress")}
                          label="Email"
                          className="ms-sm-2 w-50"
                        >
                          <InputField name={composeContactFieldName(index, "emailAddress")} />
                        </FormField>
                      </div>
                    </div>
                  ))}
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="pb-0">
              <CardHeader>
                <CardTitle>Locations</CardTitle>
                <CardHeaderActions>
                  <WWButton
                    className="text-nowrap"
                    color="warning"
                    onClick={() => history.push({ pathname: "/customers", search: isNew ? undefined : search })}
                  >
                    View All Locations
                  </WWButton>
                  {!isNew && (
                    <WWButton
                      className="text-nowrap"
                      onClick={() => history.push({ pathname: "/customers/new", search })}
                    >
                      Add New Location
                    </WWButton>
                  )}
                </CardHeaderActions>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        {isUpdated && errors.length === 0 && (
          <SaveBar
            prompt="Save changes?"
            confirmationPrompt="Save changes?"
            onSave={onSave}
            onCancel={() => {
              setAccountState(initialValues);
            }}
          />
        )}
      </>
    );
  }
);

export const EditAccountForm = ({
  isNew,
  account: { name, id, hubspotId = null, agency, tagSet, pointsOfContact } = {},
  isLoading
}) => {
  const [accountState, setAccountState] = useState({ pointsOfContact: [], tagSet: {} });
  const [isUpdated, setIsUpdated] = useState(false);
  const [invalidFields, setInvalidFields] = useState({});

  const initialValues = useMemo(
    () => ({
      name,
      tagSet,
      hubspotId,
      pointsOfContact: CONTACT_TYPES.map(type => {
        const contact = (pointsOfContact || []).find(contact => contact.type === type) || { type: type };
        return {
          ...contact,
          phoneNumber: contact.phoneNumber ? contact.phoneNumber.replace("+", "") : contact.phoneNumber,
          name: getContactName(contact)
        };
      })
    }),
    [name, tagSet, pointsOfContact, hubspotId]
  );

  const similarNameRequestParams = { q: accountState.name };
  const getAccounts = useGetAccounts(similarNameRequestParams);
  const update = useAccountUpdate({ accountId: id });
  const create = useAccountCreate();
  const notify = useNotification();
  const queryClient = useQueryClient();
  const history = useHistory();
  const currentAgency = useCurrentAgency();
  const agencyTags = useTagSetsByAgency(agency?.id || currentAgency.id);

  const tagsOptions = useMemo(
    () =>
      orderBy(
        (agencyTags.data || []).map(tagSet => ({
          value: tagSet,
          label: tagSet.name,
          isDefault: tagSet.isDefault,
          tags: tagSet.tags
        })),
        ["isDefault"],
        ["desc"]
      ),
    [agencyTags.data]
  );

  const validateUniqueAccountName = async () => {
    const similarNameAccounts = await queryClient.fetchQuery(
      accountKeys.accounts(similarNameRequestParams),
      getAccounts
    );
    if (similarNameAccounts._embedded?.accounts?.some(({ name }) => name === accountState.name)) {
      setInvalidFields(prev => ({
        ...prev,
        name: "Account with this name is already exist. The name must be unique."
      }));
      return false;
    }
    return true;
  };

  const onSave = useCallback(
    async close => {
      try {
        if (isNew || initialValues.name !== accountState.name) {
          if (!(await validateUniqueAccountName())) {
            return;
          }
        }
        const result = await (isNew ? create : update)(
          getPreparedValues({
            accountState,
            agencyId: agency?.id || currentAgency.id,
            initialPointsOfContact: pointsOfContact
          })
        );
        notify({
          timeout: ON_SAVE_NOTIFY_TIMEOUT,
          body: "Account Updated successfully!"
        });
        setIsUpdated(false);
        if (close) {
          history.push(ACCOUNTS_LIST_PATH);
        } else if (isNew) {
          history.push(`${ACCOUNTS_LIST_PATH}/${result?.id}`);
        }
      } catch (e) {
        throw e;
      }
    },
    [
      id,
      accountState,
      create,
      update,
      hubspotId,
      agency,
      pointsOfContact,
      currentAgency.id,
      history,
      isNew,
      notify,
      validateUniqueAccountName
    ]
  );

  useEffect(() => {
    if (!isLoading && id === undefined && !isNew) {
      history.push(ACCOUNTS_LIST_PATH);
    }
  }, [isLoading, id, history, isNew]);

  useEffect(() => {
    setIsUpdated(
      !isEmpty(accountState) &&
        !(
          isEqual(omit(accountState, "tagSet"), omit(initialValues, "tagSet")) &&
          accountState.tagSet?.id === initialValues.tagSet?.id
        )
    );
  }, [initialValues, accountState]);

  useEffect(() => {
    const validate = (condition, field, message) => {
      setInvalidFields(prev => ({ ...prev, [field]: condition ? message : undefined }));
    };
    validate(!accountState.name, "name", "Account name can't be blank");
    validate(!accountState.tagSet?.id, "tagSet", "Tag set must be selected");
  }, [accountState]);

  const populateFromHubspotRecord = useCallback(record => {
    setAccountState(prev => ({
      ...prev,
      hubspotId: record.id,
      name: record.name,
      pointsOfContact: [
        {
          name: record.name,
          phoneNumber: record.phone,
          type: CONTACT_TYPES[0]
        },
        {
          type: CONTACT_TYPES[1]
        },
        {
          type: CONTACT_TYPES[2]
        }
      ]
    }));
  }, []);

  return (
    <EditAccountFormLayout
      id={id}
      name={name}
      isNew={isNew}
      tagsOptions={tagsOptions}
      isLoading={isLoading}
      isUpdated={isUpdated}
      accountState={accountState}
      setAccountState={setAccountState}
      onSave={onSave}
      initialValues={initialValues}
      invalidFields={invalidFields}
      populateFromHubspotRecord={populateFromHubspotRecord}
      currentAgencyName={currentAgency.name}
    />
  );
};

export default EditAccountForm;
