import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import uniqueId from "lodash/uniqueId";

function HelpIcon({ icon = "help", placement = "bottom", className = "", style, children }) {
  const [id] = useState(uniqueId("helpicon"));
  const iconClass = useMemo(() => (icon === "info" ? "fa-info-circle" : "fa-question-circle"));
  const iconStyle = {
    cursor: "pointer",
    ...style
  };

  return (
    <>
      <i style={iconStyle} className={`fa ${iconClass} ${className}`} aria-hidden="true" id={id}></i>
      <UncontrolledPopover trigger="click hover" placement={placement} target={id}>
        <PopoverBody>{children}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
}

HelpIcon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};

export default HelpIcon;
