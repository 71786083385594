import { useEffect, useState, useCallback, useMemo } from "react";
import { InputGroup } from "reactstrap";
import { useFormEventSetter } from "../../../../hooks/stateHooks";
import { BRAND_RELATIONSHIPS, ENTITY_TYPES, STOCK_EXCHANGE, VERTICALS } from "../../../../data/options";
import AddressInput from "../../../Form/AddressInput/AddressInput";
import HorizontalSelectField from "../../../Form/HorizontalSelectField";
import InputField from "../../../Form/InputField";

import HostedNumberEditor from "../HostedNumberEditor/HostedNumberEditor";
import { validPhoneNumber, validWebsite } from "util/validators";
import FormField from "components/Form/FormField";
import PhoneNumberField from "components/Form/PhoneNumberField";
import { removeUSCodeFromPhone } from "util/phoneNumberUtils";

function notBlank(value) {
  return value && value.trim() ? undefined : false;
}

function readAddress(brand) {
  // Bandwidth concatenates the house number and street name, and our address control renders them separately.
  // We'll just concatenate them back together later, so this is a close enough approximation for now.
  const streetMatch = (brand.street || "").match(/(\S*)\s*(.*)/);
  return {
    countryCode: brand.countryCode || "",
    streetNumber: streetMatch[1] || "",
    streetName: streetMatch[2] || "",
    address2: "",
    locality: brand.city || "",
    administrativeArea: brand.state || "",
    postalCode: brand.postalCode || ""
  };
}

export default function BrandEditor({ customer, contacts, brand, onChange, allowHostedNumber = true }) {
  const setBrandField = useCallback(
    event => {
      let update = {};
      update[event.target.name] = event.target.value;
      onChange(Object.assign(brand, update));
    },
    [onChange, brand]
  );

  const [address, setAddress] = useState(readAddress(brand));
  const setAddressField = useFormEventSetter(setAddress);
  useEffect(() => {
    setAddress(readAddress(brand));
  }, [brand, brand?.brandId]);
  useEffect(() => {
    onChange(
      Object.assign({}, brand, {
        street: address.address2
          ? `${address.streetNumber} ${address.streetName}, ${address.address2}`
          : `${address.streetNumber} ${address.streetName}`,
        city: address.locality,
        state: address.administrativeArea,
        countryCode: address.countryCode,
        postalCode: address.postalCode
      })
    );
  }, [
    address.streetNumber,
    address.streetName,
    address.address2,
    address.locality,
    address.administrativeArea,
    address.countryCode,
    address.postalCode,
    onChange
  ]);

  const setSupportContact = useCallback(
    contact => {
      const name = contact.name.split(" ");
      setBrandField({ target: { value: name[0], name: "supportContactFirstName" } });
      setBrandField({ target: { value: name[1], name: "supportContactLastName" } });
      setBrandField({ target: { value: contact.email, name: "supportContactEmail" } });
    },
    [setBrandField]
  );

  const isStockInfoValid = useMemo(() => {
    const symbol = !!brand.stockSymbol;
    const exchange = !!brand.stockExchange && brand.stockExchange !== "NONE";
    return symbol === exchange ? undefined : false;
  }, [brand.stockExchange, brand.stockSymbol]);

  const [letterOfAuthorization, setLetterOfAuthorization] = useState(brand.letterOfAuthorization);
  useEffect(() => {
    onChange(
      Object.assign({}, brand, {
        letterOfAuthorization: letterOfAuthorization
      })
    );
  }, [onChange, letterOfAuthorization]);

  useEffect(() => {
    if (!brand?.supportContactPhone)
      setBrandField({ target: { value: customer?.businessPhone, name: "supportContactPhone" } });
  }, [setBrandField, customer, brand]);

  return (
    <>
      {allowHostedNumber && <HostedNumberEditor customer={customer} onChange={setLetterOfAuthorization} />}
      <h4 className="pb-2">Brand Info</h4>
      <InputGroup>
        <InputField
          name="legalName"
          className="flex-grow-1"
          value={brand.legalName}
          label="Legal name"
          onChange={setBrandField}
          inline={false}
          valid={notBlank(brand.legalName)}
          tip="Must match the company name on the tax filing"
        />
        <InputField
          name="name"
          className="flex-grow-1"
          value={brand.name}
          label="Location Name"
          onChange={setBrandField}
          inline={false}
          disabled={true}
        />
      </InputGroup>
      <InputGroup>
        <InputField
          name="website"
          className="flex-grow-1"
          value={brand.website}
          label="Website"
          onChange={setBrandField}
          inline={false}
          valid={validWebsite(brand.website)}
          placeholder="https://example.com"
          tip="Ideally the customer's own website; a social media link is acceptable if there is no website. The business's phone and email should be listed on their website."
        />
        <FormField label="Business phone" invalid={!validPhoneNumber(brand.supportContactPhone)}>
          <PhoneNumberField
            onValueChange={({ value }) => setBrandField({ target: { name: "supportContactPhone", value } })}
            value={removeUSCodeFromPhone(brand?.supportContactPhone)}
          />
        </FormField>
      </InputGroup>
      <h5>
        <strong>Legal Address</strong>
        <br />
        <small>Note that the legal address may not be the same as the business address</small>
      </h5>
      <AddressInput fullAddress={address} onChange={setAddressField} />
      <InputGroup>
        <InputField
          name="ein"
          className="flex-grow-1"
          value={brand?.ein}
          label="Tax Number/EIN"
          onChange={setBrandField}
          inline={false}
          valid={brand?.countryCode === "US" ? (brand?.ein || "").match(/^[0-9]{2}-?[0-9]{7}$/) : notBlank(brand?.ein)}
        />
        <HorizontalSelectField
          name="vertical"
          formFieldClass="flex-grow-1"
          value={brand?.vertical}
          label="Vertical Type"
          onChange={setBrandField}
          options={VERTICALS}
          simpleValue={true}
          inline={false}
        />
      </InputGroup>
      <InputGroup>
        <HorizontalSelectField
          name="entityType"
          formFieldClass="flex-grow-1"
          value={brand?.entityType}
          label="Entity Type"
          simpleValue={true}
          options={ENTITY_TYPES}
          onChange={setBrandField}
          inline={false}
        />
        <HorizontalSelectField
          name="brandRelationship"
          formFieldClass="flex-grow-1"
          value={brand?.brandRelationship}
          label="Brand Relationship"
          simpleValue={true}
          options={BRAND_RELATIONSHIPS}
          onChange={setBrandField}
          inline={false}
        />
      </InputGroup>
      <InputGroup>
        <InputField
          name="stockSymbol"
          className="flex-grow-1"
          value={brand?.stockSymbol}
          label="Stock Symbol (optional)"
          onChange={setBrandField}
          disabled={false}
          inline={false}
          valid={isStockInfoValid}
          tip={isStockInfoValid === false && "If a stock symbol or exchange is given, both must be."}
        />
        <HorizontalSelectField
          name="stockExchange"
          className="flex-grow-1"
          formFieldClass="flex-grow-1"
          value={brand?.stockExchange}
          simpleValue={true}
          label="Stock Exchange (optional)"
          onChange={setBrandField}
          options={STOCK_EXCHANGE}
          inline={false}
        />
      </InputGroup>
      <h4 className="pb-2">Verification Contact Info</h4>
      {contacts && contacts.length > 0 && (
        <HorizontalSelectField
          name="supportContactSelector"
          label="Use one of the customer's contacts?"
          options={contacts}
          getOptionLabel={contact => contact.name}
          onChange={event => setSupportContact(event.target.value)}
          multi={false}
          value={[]}
        />
      )}
      <InputGroup>
        <InputField
          name="supportContactFirstName"
          className="flex-grow-1"
          value={brand?.supportContactFirstName}
          label="First Name"
          onChange={setBrandField}
          disabled={false}
          inline={false}
          valid={!!brand?.supportContactFirstName}
        />
        <InputField
          name="supportContactLastName"
          className="flex-grow-1"
          value={brand?.supportContactLastName}
          label="Last Name"
          onChange={setBrandField}
          disabled={false}
          inline={false}
          valid={!!brand?.supportContactLastName}
        />
      </InputGroup>
      <InputGroup>
        <InputField
          name="supportContactEmail"
          className="flex-grow-1"
          value={brand?.supportContactEmail}
          label="Email"
          onChange={setBrandField}
          disabled={false}
          inline={false}
          valid={(brand.supportContactEmail || "").match(/.@./)}
          tip="Should be on the same domain as the business"
        />
      </InputGroup>
    </>
  );
}
