import { createContext, useReducer } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { get } from "lodash";
import { useErrorNotification } from "components/Notifications/notification";
import { useOnce } from "hooks/utilHooks";

const QUERY_STALE_TIME = 10000;

/**
 * @type {import("react-query").DefaultOptions}
 */
const getQueryClientOptions = ({ onError } = {}) => ({
  queries: {
    staleTime: QUERY_STALE_TIME,
    suspense: false,
    retry: false,
    refetchOnWindowFocus: false,
    onError
  },
  mutations: { onError }
});

export const DATA_PROVIDER_ACTIONS = Object.freeze({
  setFilterActive: "setFilterActive"
});

/**
 * @type {import("react").Context<{<string>?: any}>}
 */
export const DataProviderContext = createContext({});

const INITIAL_DATA_PROVIDER_STATE = {
  openedFiltersCount: 0 // tracks if there are any filters in the children that being interacted with, if there are we should pause the queries until the user is done
};

/**
 * @param {{openedFiltersCount: number}} state
 * @param {type: "INCREMENT_OPENED_FILTERS" | "DECREMENT_OPENED_FILTERS" | undefined }} action
 */
const dataProviderReducer = (state, action) => {
  switch (action.type) {
    case DATA_PROVIDER_ACTIONS.setFilterActive:
      return { ...state, openedFiltersCount: state.openedFiltersCount + (action.payload !== false ? 1 : -1) };
    default:
      return state;
  }
};

const mapDataProviderState = state => ({
  allFiltersClosed: get(state, "openedFiltersCount", 0) === 0
});

export const queryClient = new QueryClient({ defaultOptions: getQueryClientOptions() });

/**
 * wrapper that include QueryClientProvider and context provider for storage of query global settings.
 * at the moment, it provides count of number opened filter dropdowns to prevent unnecessary requests
 */
const DataProvider = ({ children }) => {
  const onError = useErrorNotification();
  useOnce(() => queryClient.setDefaultOptions(getQueryClientOptions({ onError })));
  const [dataProviderState, dispatch] = useReducer(dataProviderReducer, INITIAL_DATA_PROVIDER_STATE);
  const dataProviderValue = {
    dispatch,
    ...mapDataProviderState(dataProviderState)
  };
  return (
    <QueryClientProvider client={queryClient}>
      <DataProviderContext.Provider value={dataProviderValue}>{children}</DataProviderContext.Provider>
    </QueryClientProvider>
  );
};

export default DataProvider;
