import { useCurrentAgency } from "hooks/agencyHooks";
import { useGet, usePatch } from "../dataHooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useMemo } from "react";
import { find } from "lodash";

const baseUri = "tagSets";

// Load tag sets by agency
export const useTagSetsByAgency = (agencyId, options = {}) => {
  const queryKey = `/tagSets/agency/${agencyId}`;
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { ...options });
};

// Load tag set by tagSetId
export const useTagSetById = (tagSetId, options = {}) => {
  const queryKey = `/${baseUri}/${tagSetId}`;
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { ...options });
};

// Load tag set by customer id
export const useTagSetByCustomerId = (customerId, options = {}) => {
  const queryKey = `${baseUri}/customers/${customerId}`;
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { ...options });
};

// Load tag set by account id
export const useTagSetByAccountId = (customerId, options = {}) => {
  const queryKey = `${baseUri}/accounts/${customerId}`;
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { ...options });
};

// Load tag set by Contact id
export const useTagSetByContactId = (contactId, options = {}) => {
  const queryKey = `tagSets/contact/${contactId}`;
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { ...options });
};

// Load topics for current user
export const useTopicsForCurrentUser = (options = {}) => {
  const queryKey = `${baseUri}/tags/topics`; //no tags in path?
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { ...options });
};

// Load tags (categories) for current user
export const useCategoriesForCurrentUser = (options = {}) => {
  const queryKey = `${baseUri}/tags`;
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { ...options });
};

// updates the Topics by tag id
export const usePatchTopics = tagsId => {
  const patch = usePatch();
  const queryClient = useQueryClient();
  return useMutation(
    data =>
      patch(`${baseUri}/${tagsId}`, {
        data
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(`/${baseUri}/${tagsId}`)
    }
  );
};

export const useCatagoriesByContactIdWithFallbacks = (contactId, customerId) => {
  const agency = useCurrentAgency();
  const tagSetQueryByContact = useTagSetByContactId(contactId, { enabled: !!contactId });
  const tagSetQueryByCustomer = useTagSetByCustomerId(customerId, { enabled: !!customerId && !contactId });
  const tagSetQueryByAgency = useTagSetsByAgency(agency?.id, {
    enabled: !!agency?.id && !customerId && !contactId
  });
  const tagSet = useMemo(() => {
    if (!!tagSetQueryByContact?.data) {
      return { data: tagSetQueryByContact?.data, query: tagSetQueryByContact };
    } else if (!!tagSetQueryByCustomer?.data) {
      return { data: [tagSetQueryByCustomer?.data], query: tagSetQueryByCustomer };
    } else if (!!tagSetQueryByAgency?.data) {
      return { data: [find(tagSetQueryByAgency?.data, "isDefault")], query: tagSetQueryByAgency };
    } else {
      return { data: [], query: null };
    }
  }, [tagSetQueryByContact, tagSetQueryByCustomer, tagSetQueryByAgency]);

  const catagories = tagSet.data
    ?.flatMap(tagSet => tagSet.tags)
    .filter((value, index, self) => self.indexOf(value) === index);

  return [catagories, tagSet.query];
};
