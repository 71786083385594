import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { DashboardWidget } from "../Widgets/DashboardWidget";
import OverlappingAreaChart from "./OverlappingAreaChart";
import uniq from "lodash/uniq";
import reduce from "lodash/reduce";
import values from "lodash/values";
import map from "lodash/map";
import filter from "lodash/filter";

const ConfigurableAreaChartWithLegend = ({
  title = "",
  dataSource = () => Promise.resolve({ buckets: [], datasets: [] }),
  chartProps = { colors: {} }
}) => {
  const [selected, setSelected] = useState([]);
  const [rawData, setRawData] = useState(null);
  const [chartData, setChartData] = useState({ keys: [], data: [] });

  const convert = useDataConverter();
  const formatTick = useTickFormatter();

  const toggleSelected = key => {
    const dataKey = key.dataKey;
    const update = selected.indexOf(dataKey) >= 0 ? filter(selected, k => k !== dataKey) : [...selected, dataKey];
    setSelected(update);
    setChartData(convert(rawData, chartProps.colors, update));
  };

  const legendFormatter = selected => value => (
    <span style={{ opacity: selected.indexOf(value) >= 0 ? 1.0 : 0.5 }}>{value}</span>
  );

  useEffect(() => {
    if (selected.length === 0) {
      //initial load
      dataSource().then(v => {
        setRawData(v);
        const d = convert(v, chartProps.colors);
        setChartData(d);
        setSelected(map(d.keys, k => k.key));
      });
    }
    // }
  }, [dataSource, convert]);

  return (
    <DashboardWidget title={title}>
      <OverlappingAreaChart
        tickFormatter={formatTick}
        chartData={chartData}
        options={{
          ...chartProps,
          xAxis: { tickFormatter: formatTick, ...chartProps.xAxis },
          legend: {
            onClick: toggleSelected,
            formatter: legendFormatter(selected),
            ...chartProps.legend
          }
        }}
      />
    </DashboardWidget>
  );
};

const useDataConverter = () =>
  useCallback((raw, colors = {}, selected = null) => {
    const keys = uniq(raw.map(({ type }) => type)).map(key => {
      return {
        key,
        color: colors[key] ? colors[key] : "rgba(128, 128, 128, .5)"
      };
    });
    const rawData = values(
      reduce(
        raw.filter(({ type }) => !selected || selected.indexOf(type) >= 0),
        (result, { type, date, hour, count }) => {
          const unixDate = moment.utc(date + "T" + (hour < 10 ? "0" + hour : hour)).unix();
          if (!result[unixDate]) {
            result[unixDate] = { date: unixDate };
          }
          result[unixDate][type] = count;
          return result;
        },
        {}
      )
    );
    return {
      keys,
      data: rawData
    };
  }, []);

const useTickFormatter = () =>
  useCallback(
    v =>
      moment
        .unix(v)
        .local()
        .format("ddd, hA"),
    []
  );

export default ConfigurableAreaChartWithLegend;
