import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Button, Alert } from "reactstrap";
import { truncate } from "lodash";
import InputField from "../../components/Form/InputField";
import { addSite, getMindbodyLink, removeSite } from "../../api/customerApi";
import StatusMessages from "../../components/Misc/StatusMessages";
import CopyButton from "../../components/Buttons/CopyButton";

const MindbodyLinking = ({ customer }) => {
  const [errors, setErrors] = useState([]);
  const [mindbodyInfo, setMindbodyInfo] = useState([]);
  const [url, setUrl] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [siteId, setSiteId] = useState("");

  useEffect(() => {
    if (customer?.mindbodyInfo?.length > 0) {
      setMindbodyInfo(customer.mindbodyInfo);
    }
  }, [customer]);

  const addSiteHandler = useCallback(() => {
    addSite(customer.id, "mindbodyInfo", { source: "MINDBODY", clientId: siteId })
      .then(addSiteRes => {
        getMindbodyLink(customer.id, siteId)
          .then(res => {
            setMindbodyInfo(addSiteRes.data);
            setUrl(res.data.ActivationLink);
            setActivationCode(res.data.ActivationCode);
          })
          .catch(error => {
            setErrors(error);
          });
      })
      .catch(error => {
        setErrors(error);
      });
  }, [customer, siteId]);

  const removeSiteHandler = useCallback(() => {
    removeSite(customer.id, "mindbodyInfo", mindbodyInfo[0].id).then(res => {
      setMindbodyInfo(res.data);
    });
  });

  return (
    <Container>
      <Row>
        <Col>
          <StatusMessages errors={errors} />
        </Col>
      </Row>
      {mindbodyInfo?.length > 0 ? (
        <>
          <Row>
            <Col>
              <p>To relink your account remove your studio Id and enter it again.</p>
            </Col>
          </Row>
          <div className="ms-3 mb-2">
            {activationCode && (
              <Alert color="dark">
                <div>
                  <p>
                    You'll need to log in to your MINDBODY site using the owner account, then click this link to
                    auto-fill the code. If successful, you'll land on page titled "Integrating with MINDBODY’s API" and
                    see a green checkmark on the right side. If the link didn't work copy the activation code and enter
                    it{" "}
                    <a
                      href="https://support.mindbodyonline.com/s/article/Setting-up-an-API-integration?language=en_US#2"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      manually on the Mindbody site.
                    </a>
                  </p>
                  <strong>Activation Link: </strong>
                  <a href={url} target="_blank" rel="noreferrer noopener">
                    {truncate(url, { length: 45 })}
                    <i className="ms-1 fa fa-external-link" />
                  </a>
                </div>
                <div className="pt-2">
                  <strong>Activation Code: </strong> {activationCode} <CopyButton text={activationCode} />
                </div>
              </Alert>
            )}
            <h5>
              <i className="me-1 fa fa-close" onClick={removeSiteHandler} />
              <strong>Studio ID: </strong> {mindbodyInfo[0].clientId}
            </h5>
          </div>
        </>
      ) : (
        <>
          <Row>
            <Col>
              <p>
                Your{" "}
                <a
                  href="https://support.mindbodyonline.com/s/article/206398178-How-do-I-find-my-Client-ID?language=en_US"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Studio ID
                </a>{" "}
                (may also be called Client or Site ID) is listed on the Mindbody subscription screen when logged in as
                Owner. Also found at the bottom right corner of your site. Copy and past the ID into the below field to
                activate your account.
              </p>
              <p>
                You'll need to log in to your MINDBODY site using the owner account, then click this link to auto-fill
                the code. If successful, you'll land on page titled "Integrating with MINDBODY’s API" and see a green
                checkmark on the right side.
              </p>
            </Col>
          </Row>
          <InputField inline={false} label="Studio Id" name="studioId" onChange={evt => setSiteId(evt.target.value)} />
          <Button disabled={!siteId} className="ms-3" color="primary" onClick={addSiteHandler}>
            Add Studio
          </Button>
        </>
      )}
    </Container>
  );
};

export default MindbodyLinking;
