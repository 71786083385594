import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { Button } from "reactstrap";
import { deleteKeapInfo } from "../../api/keapApi";
import StatusMessages from "../../components/Misc/StatusMessages";
import { LOCAL_STORAGE_KEYS } from "../../data/storageKeys";

const KeapLinking = ({ customer }) => {
  const [errors, setErrors] = useState([]);
  const [connected, setConnected] = useState();

  const match = useRouteMatch();
  const customerId = useMemo(() => match.params.id);

  const authURL = useMemo(
    () =>
      "https://accounts.infusionsoft.com/app/oauth/authorize" +
      `?client_id=${encodeURIComponent(process.env.REACT_APP_KEAP_CLIENTID)}` +
      `&response_type=code` +
      `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_COGNITO_REDIRECTURI}oauth/keap`)}`,
    []
  );

  const onConnectClick = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.AWAITED_REDIRECT_CUSTOMER, customerId);
    window.open(authURL);
  }, [authURL, customerId]);
  useEffect(() => {
    setConnected(customer.keapInfo?.length > 0);
  }, [customer]);

  const unlinkKeap = useCallback(() => {
    deleteKeapInfo(customerId, customer?.keapInfo[0].id)
      .then(() => {
        setConnected(false);
      })
      .catch(error => setErrors(error));
  }, [customerId, customer]);

  return (
    <>
      <StatusMessages errors={errors} />
      {/* {connected === undefined && <AjaxLoader />} */}
      {connected === true ? (
        <div>
          <h5>
            <strong>Connected: </strong> {customer.keapInfo[0].companyName}
          </h5>
          <Button color="primary" className="w-100" onClick={unlinkKeap}>
            Unlink Keap
          </Button>
        </div>
      ) : (
        <Button color="primary" className="w-100" onClick={onConnectClick}>
          Connect to Keap
        </Button>
      )}
    </>
  );
};

export default KeapLinking;
