export const NewReviewFeedFeatureValues = Object.freeze({
  approval: "approval",
  feed: "feed",
  optout: "optout"
});

export const NewDashboardFeatureValues = Object.freeze({
  oldDashboard: "old",
  newDashboard: "new",
  optout: "optout"
});

export const USE_NEW_DASHBOARD = "preference.useNewDashboard";

export const USE_NEW_REVIEW_FEED_FEATURE_NAME = "preference.useNewReviewFeed";

export const USE_NEW_REVIEW_FEED_FEATURE = {
  name: USE_NEW_REVIEW_FEED_FEATURE_NAME,
  defaultValue: NewReviewFeedFeatureValues.approval,
  optInValue: NewReviewFeedFeatureValues.feed,
  optOutValue: NewReviewFeedFeatureValues.optout
};

export const USE_NEW_DASHBOARD_FEATURE = {
  name: USE_NEW_DASHBOARD,
  defaultValue: undefined,
  optInValue: NewDashboardFeatureValues.newDashboard,
  optOutValue: NewDashboardFeatureValues.optout
};
