import { find, identity, noop } from "lodash";
import { useCallback, useEffect, useState } from "react";

/**
 * @param {[number, number]} to
 * @returns {(value: [number, number]) => [number, number]}
 */
export const relative = to => value => value.map((v, i) => v - to[i]);

export const useDragStart = () =>
  useCallback(
    handler => ({
      onPointerDownCapture: e => {
        e.stopPropagation();
        e.preventDefault();
        handler({ where: [e.clientX, e.clientY], how: "pointer" });
      },
      onTouchStartCapture: e => {
        e.stopPropagation();
        e.preventDefault();
        handler({
          where: [e.touches.item(0).clientX, e.touches.item(0).clientY],
          how: "touch"
        });
      }
    }),
    []
  );

export const EditableList = ({
  data = [],
  keyer = JSON.stringify,
  idMatcher,
  onChange = noop,
  className = "",
  children = noop
}) => {
  const [dragTarget, setDragTarget] = useState(undefined);
  const [dropTarget, setDropTarget] = useState(undefined);
  const [offset, setOffset] = useState([0, 0]);
  const [dragStart, setDragStart] = useState([0, 0]);
  const [dragMove, setDragMove] = useState([0, 0]);

  const [draggingMode, setDraggingMode] = useState(undefined);

  const onPointerEnter = useCallback(
    v => () => {
      if (!!dragTarget) setDropTarget(v);
    },
    [dragTarget]
  );
  const onDragStart = useCallback(v => ({ where, how }) => {
    setDragStart(where);
    setDragMove(where);
    setDraggingMode(how);
    setDragTarget(v);
  });

  const onPointerMove = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setDragMove([e.clientX, e.clientY]);
  }, []);

  const onPointerUpOrCancel = useCallback(() => {
    setDraggingMode(undefined);
  }, []);

  const onTouchMove = useCallback(
    e => {
      e.stopPropagation();
      const position = [e.touches.item(0).clientX, e.touches.item(0).clientY];
      setDragMove(position);
      const element = document.elementFromPoint(position[0], position[1]);
      if (!!element && element.getAttribute("datatype") === "dropzone") {
        onPointerEnter(idMatcher ? find(data, idMatcher(element.id)) : element.id)();
      }
    },
    [onPointerEnter, data]
  );

  const onTouchEndOrCancel = useCallback(() => {
    setDraggingMode(undefined);
  }, []);

  useEffect(() => {
    if (!draggingMode) {
      if (!!dropTarget && !!dragTarget) {
        if (dropTarget !== dragTarget) {
          onChange(
            data.reduce((acc, v, i, list) => {
              return keyer(v, i) === keyer(dragTarget)
                ? acc
                : keyer(v, i) === keyer(dropTarget) || keyer(v, i) === dropTarget
                  ? list.indexOf(dragTarget) < list.indexOf(dropTarget)
                    ? [...acc, v, dragTarget]
                    : [...acc, dragTarget, v]
                  : [...acc, v];
            }, [])
          );
        }
        setDropTarget(undefined);
        setDragTarget(undefined);
      }
      setDragStart([0, 0]);
      setDragMove([0, 0]);
    }
  }, [dropTarget, dragTarget, draggingMode]);

  useEffect(() => {
    if (!!draggingMode) {
      if (draggingMode === "pointer") {
        document.addEventListener("pointermove", onPointerMove, {
          capture: true,
          passive: false
        });
        document.addEventListener("pointercancel", onPointerUpOrCancel, {
          passive: true
        });
        document.addEventListener("pointerup", onPointerUpOrCancel, {
          passive: true
        });
      } else {
        document.addEventListener("touchmove", onTouchMove, {
          capture: true,
          passive: true
        });
        document.addEventListener("touchcancel", onTouchEndOrCancel, {
          passive: true
        });
        document.addEventListener("touchend", onTouchEndOrCancel, {
          passive: true
        });
      }
    }
    return () => {
      document.removeEventListener("pointermove", onPointerMove);
      document.removeEventListener("pointercancel", onPointerUpOrCancel);
      document.removeEventListener("pointerup", onPointerUpOrCancel);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchcancel", onTouchEndOrCancel);
      document.removeEventListener("touchend", onTouchEndOrCancel);
    };
  }, [onPointerMove, onPointerUpOrCancel, onTouchMove, onTouchEndOrCancel, draggingMode]);

  useEffect(() => {
    setOffset(relative(dragStart)(dragMove));
  }, [dragStart, dragMove]);

  const boxStyle = useCallback(
    (v, i, list) => {
      const dragTargetIndex = list.indexOf(dragTarget);
      const dropTargetIndex = list.indexOf(dropTarget);
      return v === dragTarget
        ? {
            transform: `translateY(${offset[1]}px)`,
            zIndex: 3,
            pointerEvents: "none"
          }
        : {
            ...(draggingMode && {
              transition: "all .3s",
              ...(i > dragTargetIndex && i <= dropTargetIndex
                ? { transform: `translateY(-100%)` }
                : i < dragTargetIndex && i >= dropTargetIndex && dropTargetIndex >= 0
                  ? { transform: `translateY(100%)` }
                  : {})
            })
          };
    },
    [offset[1], dragTarget, dropTarget, draggingMode]
  );

  const itemStyle = useCallback(
    v =>
      v === dragTarget
        ? {
            transform: `scale(1.05)`,
            filter: "drop-shadow(0 0 3px rgba(0, 0, 0, .2))"
          }
        : { transition: "all .3s" },
    [dragTarget]
  );

  const placeholderStyle = useCallback(
    () => ({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      ...(!!draggingMode ? { zIndex: 2 } : { zIndex: 0 })
    }),
    [draggingMode]
  );

  return (
    <div className="d-flex flex-column align-items-stretch flex-grow">
      {data.map((v, i, list) => (
        <div key={keyer(v)} className={"position-relative my-1 " + className}>
          <div
            className="position-absolute"
            style={placeholderStyle()}
            onPointerEnter={onPointerEnter(v)}
            id={keyer(v)}
            datatype={"dropzone"}
          />
          <div style={boxStyle(v, i, list)} className="position-relative">
            <div className="d-flex" style={itemStyle(v)}>
              {children(onDragStart(v), v, i, list)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
