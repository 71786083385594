import React from "react";
import { Button } from "reactstrap";

const LinkedInReactionSelector = ({ enabled = true, onChange, isReply = false }) => {
  return (
    <>
      <Button className="d-block" disabled={!enabled} color="link" onClick={() => onChange("LIKE")}>
        <img src="img/linkedin/like.svg" alt="like" />
      </Button>
      {!isReply && (
        <>
          <Button className="d-block" disabled={!enabled} color="link" onClick={() => onChange("EMPATHY")}>
            <img src="img/linkedin/empathy.svg" alt="empathy" />
          </Button>
          <Button className="d-block" disabled={!enabled} color="link" onClick={() => onChange("PRAISE")}>
            <img src="img/linkedin/praise.svg" alt="praise" />
          </Button>
          <Button className="d-block" disabled={!enabled} color="link" onClick={() => onChange("INTEREST")}>
            <img src="img/linkedin/interest.svg" alt="interest" />
          </Button>
          <Button className="d-block" disabled={!enabled} color="link" onClick={() => onChange("MAYBE")}>
            <img src="img/linkedin/maybe.svg" alt="maybe" />
          </Button>
        </>
      )}
    </>
  );
};

export default LinkedInReactionSelector;
