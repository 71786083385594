import axios from "axios";
import { isArray, omitBy, isUndefined } from "lodash";
import * as api from "./apiClient";

const BASE_URI = "/media";

/**
 *
 * @param {file object}
 * @param {{
 *  type?: string;
 *  title?: string;
 *  total
 *  description?: string;
 *  imagePrefix?: string;
 *  validationRuleName: "GOOGLE_IMAGE" | "GOOGLE_VIDEO" | "FACEBOOK_IMAGE";
 * }} options
 */
export function uploadMedia(file, options = {}) {
  const files = isArray(file) ? file : [file];
  return api
    .postEmpty(BASE_URI, { params: { type: options?.type || "IMAGE", num: files.length, format: options?.format } })
    .then(async ({ data }) => {
      try {
        await Promise.all(
          data.map((mediaItem, index) =>
            upload(files[index], mediaItem.uploadUrl, options.onPercentComplete).then(() =>
              validateMedia(mediaItem?.storageKey, options.validationRuleName)
            )
          )
        );
      } catch (e) {
        throw e;
      }
      return data;
    })
    .then(media =>
      media.map(m => {
        const retval = { ...m };
        delete retval.uploadUrl;
        return retval;
      })
    );
}

const validateMedia = (key, validationRuleName) => {
  return axios.post(process.env.REACT_APP_MEDIA_PROCESS_UPLOAD_URL, omitBy({ key, validationRuleName }, isUndefined()));
};

export function upload(file, target, onPercentComplete) {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = event => {
      const rawFile = event.target.result;
      return axios
        .put(target, rawFile, {
          headers: {
            "Content-Type": file.type?.split(";")[0] || "binary/octet-stream"
          },
          onUploadProgress: progressEvent => {
            if (onPercentComplete) {
              onPercentComplete(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
          }
        })
        .then(resolve)
        .catch(reject);
    };
  });
}

