import React from "react";
import isEmpty from "lodash/isEmpty";
import { TAG_ICONS } from "../../data/options";

export function TagLabel({ tags }) {
  if (isEmpty(tags)) {
    return <></>;
  }

  return (
    <span className="text-truncate">
      <i className={TAG_ICONS[tags] + " fa me-2"} />
      {tags}
    </span>
  );
}

export default TagLabel;
