import { useMemo, useCallback } from "react";
import { filter, find } from "lodash";
import { customerReviewEntities } from "api/customerApi";
import MemberList from "components/Form/ListField";
import SentimentSelector from "components/Form/SentimentSelector";

import styles from "./TagsEdit.module.scss";

const { sentimentSelectorClass, sentimentSelectorContainerClass, dividerClass } = styles;

export default function TagsEdit({ label, review, tags, type, onAddOrUpdate, onRemove, options = null }) {
  const filteredTags = useMemo(() => filter(tags, { type }), [tags, type]);
  const unset = value => !find(filteredTags, { value });
  const getMemberLabel = useCallback(({ value }) => <span>{value}</span>, []);
  return (
    <>
      <h4>{label}</h4>
      <MemberList
        //forces rerendering of options to prevent duplicate entities from being added
        key={JSON.stringify(filteredTags)}
        creatable={!options}
        placeholder={`Search ${label}...`}
        members={filteredTags}
        onAdd={value => onAddOrUpdate(type, value)}
        onRemove={onRemove}
        onCreateOption={value => onAddOrUpdate(type, value)}
        loadOptions={(inputValue, callback) => {
          if (options) {
            callback(
              options.filter(option => option.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0).filter(unset)
            );
          } else {
            customerReviewEntities(review.customer.id, type, inputValue).then(res => callback(res.data.filter(unset)));
          }
        }}
        renderActions={member => (
          <div className={sentimentSelectorContainerClass}>
            <SentimentSelector
              selectorStyle="tag"
              sentiment={member.sentiment}
              onChange={sentiment => {
                const { type, value } = member;
                return onAddOrUpdate(type, value, sentiment);
              }}
              className={sentimentSelectorClass}
            />
            <div className={dividerClass}></div>
          </div>
        )}
        getMemberLabel={getMemberLabel}
        simpleValue
      />
    </>
  );
}
