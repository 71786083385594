import classnames from "classnames";
import { map, round } from "lodash";
import moment from "moment";
import qs from "qs";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import InteractiveBarChart from "components/Charts/InteractiveBarChart";
import WWButton from "components/Buttons/WWButton";
import * as util from "./util";
import * as api from "api/reportApi";

function render_response_time(days) {
  if (days !== undefined) {
    if (days < 1) {
      return "< 1";
    }
    return round(days);
  }
  return "No data";
}

class ReviewSummaryTable extends PureComponent {
  constructor(props) {
    super(props);

    let expandedRows = this.props.summary.included ? [] : [this.props.summary.parent.customer.companyName];
    this.state = {
      expandedRows
    };

    this.render_row = this.render_row.bind(this);
    this.toggleGroup = this.toggleGroup.bind(this);
  }

  toggleGroup(id) {
    if (this.state.expandedRows.indexOf(id) >= 0) {
      this.setState({
        expandedRows: this.state.expandedRows.filter(e => e !== id)
      });
    } else {
      this.setState({ expandedRows: [...this.state.expandedRows, id] });
    }
  }

  render_row(summary, sites, lvl, tags) {
    const negPercent =
      summary.reviews.count > 0 ? Math.ceil((summary.reviews.negativeCount / summary.reviews.count) * 100) : 0;
    const { expandedRows } = this.state;
    const {
      reportFilterValues: { dateRange }
    } = this.props;
    return (
      <tr key={summary.customer.companyName}>
        <td style={{ paddingLeft: Math.max(5, 24 * lvl) }}>
          {lvl === 0 && (
            <i
              className={classnames(
                "fa",
                "link",
                expandedRows.indexOf(summary.customer.companyName) >= 0 ? "fa-minus-square-o" : "fa-plus-square-o"
              )}
              style={{ fontWeight: "normal", paddingRight: "4px" }}
              onClick={() => this.toggleGroup(summary.customer.companyName)}
            />
          )}
          <strong>
            {summary.customer.id ? (
              <Link
                className="link"
                to={{
                  pathname: "/report/customer",
                  search: qs.stringify({
                    location: { id: summary.customer.id, companyName: summary.customer.companyName, type: "CUSTOMER" },
                    dateRange
                  })
                }}
              >
                {summary.customer.companyName}
              </Link>
            ) : (
              <span>{summary.customer.companyName}</span>
            )}
          </strong>
        </td>
        <td className={util.rating_classes(summary.reviews.rating)}>{summary.reviews.rating}</td>
        {map(sites, k => {
          let value = summary.reviews.sites[k];
          if (value) {
            // let neg =
            //   value.count > 0
            //     ? Math.ceil((value.negativeCount / value.count) * 100)
            //     : 0;
            return (
              <td key={k} className={util.rating_classes(value.rating)}>
                {/* <Tooltip styles={{tooltip: {overflow: 'visible'}}} content={[
                                                                <div key="t1">{`Total Reviews: ${value.count}`}</div>,
                                                                <div key="t2">{`% Negative: ${neg}%`}</div>
                                                            ]}>{value.rating}</Tooltip> */}
                {value.rating.toFixed(1)}
              </td>
            );
          } else {
            return <td key={k}>No data</td>;
          }
        })}
        <td className={util.negpercent_classes(negPercent)}>{negPercent + "%"} </td>
        <td className={util.neg_approval_time_classes(summary.averageNegativeApproval)}>
          {render_response_time(summary.averageNegativeApproval)}
        </td>
        {map(tags, tag => {
          if (summary.reviews.tags[tag] && summary.reviews.tags[tag].rating > 0) {
            let r = summary.reviews.tags[tag].rating;
            return (
              <td key={tag} className={util.rating_classes(r)}>
                {r.toFixed(1)}
              </td>
            );
          } else {
            return <td key={tag}>No data</td>;
          }
        })}
        <td>{summary.reviews.count}</td>
      </tr>
    );
  }

  render() {
    let {
      summary,
      bordered = true,
      reportFilterValues: {
        brand,
        contact,
        dateRange: { startDate, endDate }
      },
      reportFilterValues
    } = this.props;
    let { expandedRows } = this.state;

    return (
      <div>
        <div>
          <Row>
            <Col>
              <h3 className="text-center text-sm-start">Review Performance Summary</h3>
            </Col>
            {!!reportFilterValues && (
              <Col sm={3}>
                <div className="float-end">
                  <WWButton
                    className="btn-outline-secondary m-2"
                    onClick={() => {
                      api
                        .exportGroupReport({
                          startDate,
                          endDate,
                          brand: brand?.brand,
                          contact: contact?.id
                        })
                        .then(({ data }) => {
                          window.open(data, "_blank");
                        });
                    }}
                  >
                    Export CSV
                  </WWButton>
                </div>
              </Col>
            )}
          </Row>

          <Row className="mt-3">
            <Col>
              <div className="float-end">
                <ReviewSummaryTableLegend />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="pe-5 pb-5">
              <h4>Total Reviews</h4>
              <InteractiveBarChart
                chartData={{
                  keys: [{ key: "Count", color: "#E9AC25" }],
                  data: map(summary.parent.reviews.reviewBuckets, b => ({
                    time: b.day
                      ? moment()
                          .year(b.year)
                          .month(b.month - 1)
                          .date(b.day)
                          .format("MM/DD/YYYY")
                      : moment()
                          .year(b.year)
                          .month(b.month - 1)
                          .format("MM/YYYY"),
                    Count: b.count
                  }))
                }}
                options={{
                  container: {
                    height: 200
                  },
                  legend: null,
                  xAxis: {
                    dataKey: "time"
                  }
                }}
              />
            </Col>
          </Row>
          <Row style={{ overflow: "visible" }}>
            <Col style={{ overflow: "visible" }}>
              <Table responsive bordered={bordered} hover style={{ overflow: "visible" }}>
                <thead>
                  <tr>
                    <th />
                    <th>Avg Rating</th>
                    {map(summary.sites, s => (
                      <th key={s}>{s}</th>
                    ))}
                    <th>% Negative</th>
                    <th>Response Time</th>
                    {map(summary.tags, t => (
                      <th key={t}>{t}</th>
                    ))}
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Benchmark</strong>
                    </td>
                    <td className="perf-good">4.6 - 5.0</td>
                    {map(summary.sites, (s, i) => (
                      <td key={i} className="perf-good">
                        4.6 - 5.0
                      </td>
                    ))}
                    <td className="perf-good">0% - 10%</td>
                    <td className="perf-good">1 day</td>
                    {Object.keys(summary.parent.reviews.tags).map((t, i) => (
                      <td key={i} className="perf-good">
                        4.6 - 5.0
                      </td>
                    ))}
                    <td>-</td>
                  </tr>
                  {this.render_row(summary.parent, summary.sites, summary.included ? 0 : -1, summary.tags)}
                  {summary.children &&
                    expandedRows.indexOf(summary.parent.customer.companyName) >= 0 &&
                    summary.children.map(c =>
                      this.render_row(c, summary.sites, summary.included ? 1 : -1, summary.tags)
                    )}
                  {summary.included &&
                    summary.included.map(include => {
                      let retval = [this.render_row(include.parent, summary.sites, 0, summary.tags)];
                      expandedRows.indexOf(include.parent.customer.companyName) >= 0 &&
                        retval.push(map(include.children, c => this.render_row(c, summary.sites, 1, summary.tags)));
                      return retval;
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const ReviewSummaryTableLegend = () => (
  <Table responsive size="sm" style={{ width: "130px", fontSize: ".75em", textAlign: "center" }}>
    <thead>
      <tr>
        <th>Rating</th>
        <th>% Negative</th>
      </tr>
    </thead>
    <tbody>
      <tr className="perf-good">
        <td>{">= 4.6"}</td>
        <td>{"0% - 10%"}</td>
      </tr>
      <tr className="perf-warn">
        <td>{"4.0 - 4.5"}</td>
        <td>{"11% - 19%"}</td>
      </tr>
      <tr className="perf-bad">
        <td>{"< 4.0"}</td>
        <td>{"> 20%"}</td>
      </tr>
    </tbody>
  </Table>
);

ReviewSummaryTable.propTypes = {
  summary: PropTypes.object.isRequired
};

export default connect(util.reportMapStateToProps)(ReviewSummaryTable);

export { ReviewSummaryTableLegend };
