import React, { useCallback, useEffect, useState } from "react";
import ReviewCountWidget from "./Widgets/ReviewCountWidget";
import IncomingReviews from "./Widgets/IncomingReviews";
import PublishedReplies from "./Widgets/PublishedReplies";
import PendingApprovals from "./Widgets/PendingApprovals";
import Activations from "./Widgets/Activations";
import ImportedReviews from "./Widgets/ImportedReviews";
import ImportFailures from "./Widgets/ImportFailures";
import AuthoredReplies from "./Widgets/AuthoredReplies";
import { Row, Col } from "reactstrap";

export default () => {
  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <Row>
        <Col xs={12}>
          <ReviewCountWidget></ReviewCountWidget>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <IncomingReviews />
        </Col>
        <Col xs={12} md={6}>
          <PublishedReplies />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <PendingApprovals />
        </Col>
        <Col xs={12} md={6}>
          <AuthoredReplies />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Activations />
        </Col>
        <Col xs={12} md={6}>
          <ImportedReviews />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <ImportFailures />
        </Col>
      </Row>
    </div>
  );
};
