import { useMutation } from "react-query";
import { usePost } from "../dataHooks";

/**
 * @param {{}} v
 * @returns {}
 */
const useTranslate = () => {
  const post = usePost();
  return useMutation(info =>
    post("reviews/translatedContent", {
      data: info.text,
      params: { sourceLanguage: info.sourceLanguage },
      headers: {
        "Content-type": "text/plain"
      }
    })
  );
};

export default useTranslate;
