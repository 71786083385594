import React, { useEffect } from "react";
import { isUndefined, pick } from "lodash";
import useURLQueryValue from "hooks/useURLQueryValue/useURLQueryValue";
import { useStateThroughRedux } from "hooks/stateHooks";
import { useToggleFilter } from "hooks/filteringHooks";
import ChipBar from "./ChipBar/ChipBar";
import FilterBar from "./FilterBar/FilterBar";
import FilterOffcanvas from "./FilterOffcanvas/FilterOffcanvas";
import { identity, isEmpty } from "ramda";
import useURLQuerySetter from "hooks/useURLQuerySetter/useURLQuerySetter";
import { valueOf } from "util/functionUtils";
import CheckboxFactory from "./Filters/CheckboxFilter/CheckboxFilter";
import ButtonDropdownFactory from "./Filters/ButtonDropdownFilter/ButtonDropdownFilter";
import ProductsFactory from "./Filters/ProductsFilter/ProductsFilter";
import FreeTextFactory from "./Filters/FreeTextFilter/FreeTextFilter";
import EntityFactory from "./Filters/EntityFilter/EntityFilter";
import DateRangeFactory from "./Filters/DateRangeFilter/DateRangeFilter";
import ButtonFactory from "./Filters/ButtonFilter/ButtonFilter";
import { FilterTableSortFactory } from "./Sort/FilterTableSort/FilterTableSort";
import SelectFactory from "./Filters/SelectFilter/SelectFilter";
import SlabFactory from "./Filters/SlabFilter/SlabFilter";
import MultiSelectFactory from "./Filters/MultiSelectFilter/MultiSelectFilter";
import { filtersWithoutUrlStatus, useFilterReduxKey } from "hooks/filteringHooks";

import style from "./FacetedSearch.module.scss";

import {
  LocationTypeaheadFactory as LocationFactory,
  CampaignTypeaheadFactory as CampaignFactory,
  ContactTypeaheadFactory as ContactFactory,
  AccountTypeaheadFactory as AccountFactory
} from "./Filters/TypeAheadFilter/TypeAheadFilter";
import LabelFilterFactory from "./Filters/LabelFilter/LabelFilter";
import { isAgencyAdmin } from "util/userUtils";

const { barContainer } = style;

/**
 * @param {[{name: string,
 * label: string,
 * type: string,
 * accordionFilter: { openOnInit: Boolean },
 * primaryBar: {position: "LEFT" | "RIGHT" | "NONE",
 * className: string},
 * secondaryBar: {className: string},
 * chip: {position: "NONE",
 * className: string},
 * defaultValue: *,
 * icon: string }]} filters
 *
 */

const FacetedSearch = ({ filters = [], children, showFiltersButton }) => {
  const [isOpen, toggle] = useToggleFilter();
  const setUrl = useURLQuerySetter(identity);
  const queryValue = useURLQueryValue(identity);
  const reduxKey = useFilterReduxKey();
  const [, setValues] = useStateThroughRedux(reduxKey);
  const userIsAgencyAdmin = isAgencyAdmin();

  useEffect(() => {
    // Include required filter always, and defaultValue filter only if the queryValue is empty
    const defaultValues = filters
      .filter(filter => !isUndefined(filter.defaultValue) && (filter.required || isEmpty(queryValue)))
      .filter(filter => isUndefined(queryValue[filter.name]))
      .reduce((acc, filter) => Object.assign(acc, { [filter.name]: valueOf(filter.defaultValue) }), {});
    setUrl(filtersWithoutUrlStatus({ ...defaultValues, ...queryValue }));
  }, [queryValue, setUrl]); // eslint-disable-line
  // filters intentionally left out

  useEffect(() => {
    filters.length > 0
      ? setValues({
          ...pick(
            queryValue,
            filters.map(filter => filter.name)
          ),
          urlLoaded: true
        })
      : setValues({ ...queryValue, urlLoaded: true });
  }, [queryValue, setValues]); // eslint-disable-line
  // filters intentionally left out

  const filtersToRender = filters.filter(f => !f.agencyAdmin || (userIsAgencyAdmin && f.agencyAdmin));

  return (
    <>
      <div className={barContainer}>
        <FilterBar toggle={toggle} filters={filtersToRender} showFiltersButton={showFiltersButton}>
          {children}
        </FilterBar>
        <ChipBar filters={filtersToRender} />
      </div>
      <FilterOffcanvas filters={filtersToRender} isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export function filterFactory(filterConfig) {
  let { type } = filterConfig;

  switch (type) {
    case "text":
      return FreeTextFactory();
    case "entity":
      return EntityFactory();
    case "account":
      return AccountFactory(filterConfig);
    case "allLocations":
      return LocationFactory(filterConfig);
    case "customerOrBrand":
      return LocationFactory(filterConfig);
    case "locationsWithInvite":
      return LocationFactory(filterConfig);
    case "customer":
      return LocationFactory(filterConfig);
    case "brand":
      return LocationFactory(filterConfig);
    case "location_label":
      return LocationFactory(filterConfig);
    case "campaign":
      return CampaignFactory(filterConfig);
    case "contact":
      return ContactFactory(filterConfig);
    case "locationLabel":
      return LabelFilterFactory(filterConfig);
    case "checkbox":
      return CheckboxFactory();
    case "multiSelect":
      return MultiSelectFactory();
    case "select":
      return SelectFactory();
    case "buttonDropdown":
      return ButtonDropdownFactory();
    case "products":
      return ProductsFactory();
    case "dates":
      return DateRangeFactory();
    case "button":
      return ButtonFactory();
    case "slab":
      return SlabFactory();
    case "sort":
      return FilterTableSortFactory();
    default:
      return FreeTextFactory();
  }
}

export default FacetedSearch;
