import * as types from "../actions/actionTypes";

export default function currentContact(state = {}, action) {
  if (state === undefined) {
    return {};
  } else {
    switch (action.type) {
      // case types.USER_LOAD_CURRENT:
      //     return Object.assign({}, state, { startDate: action.date });

      case types.CURRENT_CONTACT_RECEIVE:
        return Object.assign({}, state, { ...action.contact });

      case types.CURRENT_CONTACT_ACTIVE_PRODUCTS:
        return Object.assign({}, state, { activeProducts: action.activeProducts });

      case types.CURRENT_CONTACT_FEATURES:
        return Object.assign({}, state, { features: { ...state.features, ...action.feature } });

      default:
        return state;
    }
  }
}
