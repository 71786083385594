import { useTimeout } from "hooks/utilHooks";
import { every, noop } from "lodash";
import { useEffect } from "react";
import { Collapse, Toast, ToastBody, ToastHeader } from "reactstrap";
import styles from "./GroupedNotifications.module.scss";

/**
 * Grouped Notification component. Contains predefined visuals
 * and some specific behavior.
 *
 * @param {{
 *   timeout?: number;
 *   onClose?: () => void;
 *   title?: string;
 *   bannerColor?: string;
 *   notifications: Array;
 * }} param0
 */
const GroupedNotifications = ({
  timeout = Number.POSITIVE_INFINITY,
  onClose = noop,
  bannerColor = "secondary",
  notifications,
  title = "Notification"
}) => {
  const [pause, play, reset] = useTimeout(onClose, timeout);

  useEffect(() => {
    reset();
  }, [notifications]);

  return (
    <Collapse isOpen={every(notifications, "open") && notifications.length > 0} onExited={onClose}>
      <Toast
        className="notification-timeout-indicator-container"
        isOpen={true}
        fade={true}
        onPointerEnter={pause}
        onPointerLeave={play}
      >
        {timeout < Number.POSITIVE_INFINITY && (
          <div
            className="notification-timeout-indicator w-100 border-top border-bottom border-primary"
            style={{ animationDuration: `${timeout}ms` }}
          />
        )}
        <ToastHeader toggle={onClose} className={`bg-${bannerColor}`}>
          <span className="pe-5 text-capitalize text-white">
            {notifications.length > 1 ? `${notifications.length} ${title}s` : `1 ${title}`}
          </span>
        </ToastHeader>
        <ToastBody className={styles.toastBody}>
          {notifications.map((notification, index) => (
            <div
              className="m-1"
              data-test-id={notification.type === "danger" ? "error" : "notification"}
              key={notification.id}
            >
              {index !== 0 && <hr className="my-2" />}
              {notification.factory()}
            </div>
          ))}
        </ToastBody>
      </Toast>
    </Collapse>
  );
};

export default GroupedNotifications;
