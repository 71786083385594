import { DashboardWidget } from "components/Widgets/DashboardWidget";
import { useReportFilterQuery } from "components/HOCs/ReportWithFilters";
import { useState } from "react";
import WWButton from "components/Buttons/WWButton";
import { defaultPrepareFilters } from "hooks/data/useFilterQuery";
import { exportLifetimeTrend } from "api/reportApi";
import moment from "moment";
import { datetime2iso } from "util/dateUtils";
import WWTableBlock from "components/Table/WWTableBlock";

const schema = [
  {
    label: "Location",
    sortOptions: ["siteInfoRating.companyName,asc", "siteInfoRating.companyName,desc"],
    renderCell: site => (
      <>
        <div>{site.siteName || site.locationName}</div>
        <span className="fs-1 text-uppercase text-muted">{`${site.source}${
          !site.locationName ? "" : ` - ${site.locationName}`
        }`}</span>
      </>
    )
  },
  {
    label: "Rating",
    valueKey: "rating",
    type: WWTableBlock.cellTypes.diff,
    sortOptions: ["siteInfoRating.rating,asc", "siteInfoRating.rating,desc"]
  },
  {
    label: "Volume",
    valueKey: "volume",
    type: WWTableBlock.cellTypes.diff,
    sortOptions: ["siteInfoRating.volume,asc", "siteInfoRating.volume,desc"]
  }
];

export function LifetimeAverageTrend({
  data,
  sort,
  setSort,
  setPageNumber,
  actions,
  isLoading,
  pageNumber,
  pageSize,
  totalPages
}) {
  const tableProps = {
    schema,
    isLoading,
    pageNumber,
    setPageNumber,
    pageSize,
    totalPages,
    actions,
    sort,
    setSort,
    data,
    showPageNumbers: false
  };

  return (
    <DashboardWidget
      className="p-0"
      titleClassName="ms-sm-5 ms-2 mt-sm-5 mt-2 mb-0"
      actionsClassName="me-sm-5 me-2 mt-sm-5 mt-2 mb-0 ms-auto"
      title={"Lifetime Rating"}
      actions={actions}
    >
      <div className="ms-5">
        <small>
          Volume and rating based on daily snapshots from review sites. N/A means no snapshot data is available for the
          date.
        </small>
      </div>
      <div className="my-3">
        <WWTableBlock {...tableProps} pageSize={pageSize * 1.5} />
      </div>
    </DashboardWidget>
  );
}

const reduceFilterDateByOneDay = filterValues => {
  const newFilterValues = {
    ...filterValues,
    dateRange: {
      startDate: filterValues?.dateRange?.startDate,
      endDate: datetime2iso(moment(filterValues?.dateRange?.endDate).subtract(1, "days"))
    }
  };
  return newFilterValues;
};

// this is using the useReportFilterQuery hook directly because the mode
// selector, might be a smoother way to handle this via the source/freq menus
export default function LifetimeAverageTrendWithQuery() {
  const [source, setSource] = useState(),
    filterQuery = useReportFilterQuery({
      freqFilter: () => false,
      url: "/dash/getSiteTrendReport",
      sourceClearable: true,
      additionalParams: { source },
      defaultSort: "siteInfoRating.companyName,asc",
      pageSize: 5,
      prepareFilters: (filterValues, projection, filters) => {
        return defaultPrepareFilters(reduceFilterDateByOneDay(filterValues), projection, filters);
      },
      actions: ({ filterValues, filters, sort }) => (
        <>
          <WWButton
            onClick={() =>
              exportLifetimeTrend({
                ...defaultPrepareFilters(reduceFilterDateByOneDay(filterValues), null, filters),
                sort,
                source
              }).then(({ data }) => {
                window.open(data, "_blank");
              })
            }
          >
            CSV Export
          </WWButton>
        </>
      ),
      enabled: ({ hasLocationSelected }) => hasLocationSelected
    });

  return <LifetimeAverageTrend {...filterQuery} data={filterQuery.data?._embedded?.siteTrendDtoLines} />;
}
