import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import colors from "../../constants/colors";
import {
  AxisTypes,
  LineTypes,
  Intervals,
  STROKE_DASHARRAY,
  STROKE_WIDTH,
  TICK_SIZE_DEFAULT,
  TICK_TRUNCATE_VALUE_MIN
} from "../../constants/chart";
import { compactFormatter } from "util/numberUtils";

/**
 * @param {{
 *   data?: Array<{
 *     stat: {
 *       value: number;
 *       timestamp: number;
 *     };
 *     comparison: {
 *       value: number;
 *       timestamp: number;
 *     };
 *   }>;
 *   options?: {
 *     xAxis?: import("recharts").XAxisProps;
 *     yAxis?: import("recharts").YAxisProps;
 *     statLine?: import("recharts").LineProps;
 *     comparisonLine?: import("recharts").LineProps;
 *     grid: import("recharts").CartesianGridProps;
 *     tooltip: import("recharts").TooltipProps;
 *   }
 * }} param0
 */
export const ComparisonLinesChart = ({ data = [], options }) => (
  <ResponsiveContainer width="100%" height={300}>
    <LineChart width="100%" data={data}>
      <CartesianGrid width="100%" vertical={false} {...(options?.grid || {})} />
      <XAxis
        dataKey="stat.timestamp"
        type={AxisTypes.number}
        domain={["dataMin", "dataMax"]}
        tickSize={TICK_SIZE_DEFAULT}
        stroke={colors.chartStrokeGray}
        {...(options?.xAxis || {})}
      />
      <YAxis
        tickSize={TICK_SIZE_DEFAULT}
        stroke={colors.chartStrokeGray}
        interval={Intervals.preserveStartEnd}
        type={AxisTypes.number}
        tickFormatter={tick => (tick > TICK_TRUNCATE_VALUE_MIN ? compactFormatter.format(tick) : tick)}
        {...(options?.yAxis || {})}
      />
      <Tooltip {...(options?.tooltip || {})} />
      <Line
        type={LineTypes.monotone}
        dataKey="stat.value"
        stroke={colors.chartStokeYellow}
        strokeWidth={STROKE_WIDTH}
        dot={false}
        connectNulls={true}
        isAnimationActive={false}
        {...(options?.statLine || {})}
      />
      <Line
        type={LineTypes.monotone}
        dataKey="comparison.value"
        stroke={colors.chartStokeYellow}
        strokeDasharray={STROKE_DASHARRAY}
        dot={false}
        strokeWidth={STROKE_WIDTH}
        isAnimationActive={false}
        {...(options?.comparisonLine || {})}
      />
    </LineChart>
  </ResponsiveContainer>
);
