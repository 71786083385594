import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import { FormFeedback, Input } from "reactstrap";
import PasswordInput from "./PasswordInput";

const PasswordStrength = ({ password = "", onChange = noop, error, errorMessage }) => {
  const [minChars, setMinChars] = useState(false);
  const [capital, setCapital] = useState(false);
  const [lowercase, setLowercase] = useState(false);
  const [number, setNumber] = useState(false);
  const [strength, setStrength] = useState([true, false, false, false, false]);

  useEffect(() => {
    if (password.toLowerCase() != password) setCapital(true);
    else setCapital(false);

    if (password.toUpperCase() != password) setLowercase(true);
    else setLowercase(false);

    if (/\d/.test(password)) setNumber(true);
    else setNumber(false);

    if (password.length >= 8) setMinChars(true);
    else setMinChars(false);
  }, [password]);

  useEffect(() => {
    setStrength([true, minChars, capital, lowercase, number].sort().reverse());
  }, [minChars, capital, lowercase, number]);

  return (
    <div className="mt-4">
      <hr />
      <PasswordInput
        label="New Password"
        onChange={onChange}
        password={password}
        newPassword={true}
        name="newPassword"
      />
      <Input type="hidden" invalid={!error} />
      <FormFeedback className="col-md-12">{errorMessage}</FormFeedback>
      <div className="d-flex">
        <div className="d-inline-block">
          <h5 className="fw-bold mt-2">Password Strength</h5>
          <div>
            {strength.map((completed, index) => (
              <i
                className={completed ? "fa fa-star text-warning pe-1" : "fa fa-star-o text-warning pe-1"}
                key={index}
              />
            ))}
          </div>
          <div className="d-flex">
            <div className="pe-4">
              <p>
                <i className={lowercase ? "fa fa-star text-warning pe-1" : "fa fa-star-o text-warning pe-1"} />
                Lowercase Letter
              </p>

              <p className="mb-0">
                <i className={capital ? "fa fa-star text-warning pe-1" : "fa fa-star-o text-warning pe-1"} />
                Capital Letter
              </p>
            </div>
            <div>
              <p>
                <i className={number ? "fa fa-star text-warning pe-1" : "fa fa-star-o text-warning pe-1"} />
                Number
              </p>
              <p className="mb-0">
                <i className={minChars ? "fa fa-star text-warning pe-1" : "fa fa-star-o text-warning pe-1"} />8
                Characters
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default PasswordStrength;
