import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { get } from "lodash";
import Block from "components/Layout/Block";
import { useRemoveSite } from "hooks/customerHooks";
import { SITE_SOURCE_NAMES, SOURCES } from "data/sites";

export default function CdkFortellisLinking({ customer, updateSiteField }) {
  const [cdkFortellisInfo, setCdkFortellisInfo] = useState();

  const siteField = SOURCES[SITE_SOURCE_NAMES.cdkFortellis].customerField;

  useEffect(() => {
    setCdkFortellisInfo(get(customer, [siteField, 0]));
  }, [customer, siteField]);

  const removeSite = useRemoveSite({
    customerId: customer.id,
    field: siteField
  });

  const onRemoveSite = useCallback(() => {
    removeSite(cdkFortellisInfo.id).then(data => {
      updateSiteField(siteField, data);
      setCdkFortellisInfo(undefined);
    });
  }, [updateSiteField, cdkFortellisInfo, siteField]);

  return (
    <Container>
      {cdkFortellisInfo?.id ? (
        <>
          <Row>
            <Col>
              <strong>Linked Account</strong>
              <Button color="link" className="ms-1 p-0" onClick={onRemoveSite}>
                (unlink)
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <strong>{"Subscription ID: "}</strong>
              {cdkFortellisInfo.subscriptionId}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Block>
              <strong>Coming soon!</strong>
            </Block>
          </Row>
        </>
      )}
    </Container>
  );
}
