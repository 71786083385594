import styles from "./CollapsibleCard.module.scss";
import { Collapse } from "reactstrap";
import { useState } from "react";
import classNames from "classnames";

const { container, headerContainer, headerContainerCollapsed, headerActions, bodyContainer, title, toggleIcon } =
  styles;

const CollapsibleCard = ({ children, header, className }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className={classNames(container, className)}>
      <div className={collapsed ? headerContainerCollapsed : headerContainer}>
        <div className={title}>{header}</div>
        <div onClick={() => setCollapsed(prv => !prv)} className={toggleIcon}>
          <i data-testid="toggle-button" className={`fs-6 fa fa-angle-${collapsed ? "up" : "down"}`} />
        </div>
      </div>
      <Collapse data-testid="body-container" isOpen={!collapsed}>
        <div className={bodyContainer}>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsibleCard;
