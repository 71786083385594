import React, { useCallback, useEffect, useState } from "react";
import { DashboardWidget } from "../../../components/Widgets/DashboardWidget";
import { getImportFailures } from "../../../api/reportApi";
import { TBody, TR, TD, Table } from "../../../components/Table";
import { Tooltip } from "react-lightweight-tooltip";
import map from "lodash/map";

export default () => {
  const [importFailures, setImportFailures] = useState([]);
  const loadImportFailures = useImportFailuresLoader();

  useEffect(() => {
    loadImportFailures().then(({ importFailures }) => setImportFailures(importFailures));
  }, [loadImportFailures]);

  return (
    <DashboardWidget title="Import Failures">
      <Table responsive>
        <TBody>
          {map(importFailures, (item, i) => (
            <TR key={i} className="text-danger">
              <TD xs="sm">{new Date(item.startDate).toLocaleString()}</TD>
              <TD>{item.customer && item.customer.companyName}</TD>
              <TD>{item.source}</TD>
              <TD>
                <Tooltip content={item.errorMessage}>{item.errorMessage || ""}</Tooltip>
              </TD>
            </TR>
          ))}
        </TBody>
      </Table>
    </DashboardWidget>
  );
};

const useImportFailuresLoader = () => useCallback(getImportFailures, []);
