const routes = {
  "/": "Home",
  "/dashboard": "Dashboard",
  "/admindash": "Admin Dashboard",
  "/customers": "Locations",
  "/approve": "Approvals",
  "/report/customer": "Review Performance",
  "/report/customer/:customer": "Review Performance",
  "/report/invite": "Invites Report",
  "/report/invite/:target": "Invites Report",
  "/report/video": "Invite Video Report",
  "/report/group": "Group Comparison",
  "/report/engage": "Engage Report",
  "/report/tags": "Tags Report",
  "/report/enterprise": "Enterprise Report",
  "/posts": "Posts",
  "/posts/new": "New Post",
  "/posts/drafts": "Drafts",
  "/posts/posts": "Posts",
  "/posts/edit/:id": "Edit Post",
  "/posts/:id/share": "Ripple",
  "/user": "Edit Contact",
  "/customers/new": "Add Location",
  "/customers/:id": "View Location",
  "/customers/contacts": "Contacts",
  "/customers/contacts/new": "Add Contact",
  "/customers/contacts/:id": "Edit Contact",
  "/agency": "Edit Agency",
  "/reviews/list": "Responder Queue",
  "/reviews/:id": "View Review",
  "/reviews/templates": "Response Templates",
  "/reviews/list/edit/:id": "Compose Reply",
  "/reviews/responders": "Responder Report",
  "/reviews/creator": "Review Creator",
  "/reviews/feed": "Review Feed",
  "/log/import": "Import Log",
  "/log/inbound": "Inbound Emails",
  "/log/csvLinks": "CSV Links",
  "/invite/leads": "Review Leads",
  "/invite/leads/:id": "Edit Lead",
  "/invite/configure": "Configure Leads",
  "/invite/video": "Video",
  "/invite/automatedCampaigns": "Campaign Flow",
  "/invite/campaigns": "Campaigns",
  "/credentials": "Credentials",
  "/listings": "Listings",
  "/listings/:id": "Edit Listings",
  "/learning": "Learning Center",
  "/accounts": "Accounts",
  "/accounts/new": "Add Account",
  "/accounts/:id": "Edit Account",
};
export default routes;
