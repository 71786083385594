import { WWTabContent, WWTabContentItem, WWTabs } from "components/WWTabs";
import { useTabProps } from "hooks/tabsHooks";
import ListingsEdit from "../ListingsEdit/ListingsEdit";
import ListingsSites from "../ListingsSites/ListingsSites";
import { useParams } from "react-router-dom";
import { WWCollapse } from "components/WWCollapse/WWCollapse";
import styles from "./ListingsPage.module.scss";
import { useState } from "react";

const { tabContentClass } = styles;

export const ListingsPage = ({ history }) => {
  const [activeTab, setActiveTab] = useState("edit");
  const { listingId, customerId } = useParams();
  const tabContentProps = { className: tabContentClass, activeTab };

  return (
    <>
      <WWTabs
        className="bg-white pt-2 mb-5"
        tabs={[
          {
            label: "Listing Data",
            active: activeTab === "edit",
            onClick: () => setActiveTab("edit")
          },
          {
            label: "Sites",
            active: activeTab === "sites",
            onClick: () => setActiveTab("sites")
          }
        ]}
      />
      <div className="container-fluid">
        <WWTabContent {...tabContentProps}>
          <WWTabContentItem className="p-0" tabId="sites">
            <ListingsSites customerId={customerId} listingId={listingId} />
          </WWTabContentItem>
        </WWTabContent>
        <WWTabContent {...tabContentProps}>
          <WWTabContentItem className="p-0" tabId="edit">
            <ListingsEdit customerId={customerId} listingId={listingId} history={history} />
          </WWTabContentItem>
        </WWTabContent>
      </div>
    </>
  );
};
