import Time from "components/DateTime/Time";
import WWPagination from "components/Paging/WWPagination/WWPagination";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import StatusInfo from "components/Table/StatusInfo/StatusInfo";
import TableSortToggle from "components/Table/TableSortToggle/TableSortToggle";
import { TableCard, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import { WWTooltip } from "components/WWTooltip/WWTooltip";
import { useListingSites } from "hooks/data/listingsDataHooks";
import { isEmpty } from "lodash";
import { useState } from "react";

const ListingsSitesStatusMap = {
  SUCCESSFUL: "Successful",
  PENDING: "Pending",
  ACTION_REQUIRED: "Action Required",
  ERROR: "Error",
  UNKNOWN: "Unknown"
};
const ListingSitesStatusColorMap = {
  Pending: "warning",
  Successful: "success",
  "Action Required": "danger",
  Error: "danger"
};

const ListingsSites = ({ listingId, customerId }) => {
  const [sort, setSort] = useState("platformName,asc");
  const [page, setPage] = useState(0);
  const { data, isLoading } = useListingSites({ listingId, customerId, params: { sort, page } });

  return (
    <>
      <TableCard data-cy="tags-report-table" className="border-top-0 pt-0">
        <WWTable loaded={true}>
          <WWTHead>
            <WWTR>
              <WWTH>
                <TableSortToggle
                  name="Name"
                  value={sort}
                  toggleOptions={["platformName,asc", "platformName,desc"]}
                  sortSetter={setSort}
                />
              </WWTH>
              <WWTH>
                <TableSortToggle
                  name="Last Synced"
                  value={sort}
                  toggleOptions={["lastDeliveryTime,asc", "lastDeliveryTime,desc"]}
                  sortSetter={setSort}
                />
              </WWTH>
              <WWTH>
                <TableSortToggle
                  name="Status"
                  value={sort}
                  toggleOptions={["status,asc", "status,desc"]}
                  sortSetter={setSort}
                />
              </WWTH>
              <WWTH />
            </WWTR>
          </WWTHead>
          {isLoading ? (
            <TableBodySkeleton rows={8} cols={5} />
          ) : (
            <WWTBody>
              {isEmpty(data?._embedded?.widewailListingPublicationStateDtoes) ? (
                <WWTR>
                  <WWTD className="text-muted text-center p-3" colSpan={5}>
                    Waiting for first sync
                  </WWTD>
                </WWTR>
              ) : (
                data._embedded?.widewailListingPublicationStateDtoes.map(site => (
                  <WWTR key={site.id}>
                    <WWTD>{site.platformName}</WWTD>
                    <WWTD>
                      <Time date={site.lastDeliveryTime} emptyDateText="" />
                    </WWTD>
                    <WWTD>
                      <StatusInfo
                        id={`status${site.id}`}
                        status={ListingsSitesStatusMap[site.status]}
                        statusColorMapper={ListingSitesStatusColorMap}
                      />
                      {site.status === "PENDING" && (
                        <WWTooltip target={`status${site.id}`}>
                          Some sites can take up to 60 days to synchronize
                        </WWTooltip>
                      )}
                    </WWTD>
                    <WWTD>
                      {site.link && (
                        <a className="d-inline-block ms-1" href={site.link} target="_blank" rel="noreferrer noopener">
                          <i className="fa fa-external-link" />
                        </a>
                      )}
                    </WWTD>
                  </WWTR>
                ))
              )}
            </WWTBody>
          )}
        </WWTable>
      </TableCard>
      <WWPagination
        loading={isLoading}
        pageNumber={data?.page?.number + 1 || 1}
        pages={data?.page?.totalPages}
        setPageNumber={setPage}
      />
    </>
  );
};

export default ListingsSites;
