import React from "react";
import { defaultRenderer, Slab } from "./Slab";

/**
 * @param {{
 *   renderer?: (key: "id" | "total" | "pctChange") => (entry: {id: string; total: number; pctChange: number}) => JSX.Element;
 *   data?: {id: string; total: any; pctChange: any}[];
 * }} props
 */
export const SlabCloud = ({ renderer = defaultRenderer(), data = [] }) => (
  <div className="d-flex flex-row align-items-stretch justify-content-stretch flex-wrap">
    {data.map((entry, index) => (
      <div className="rounded bg-light m-1 py-2 px-3 flex-grow-1" key={entry.id || index}>
        <Slab {...entry} renderer={renderer} />
      </div>
    ))}
  </div>
);

// export const SlabCloudSkeleton = ({ amount = 3 }) => {
//   const range = useMemo(() => new Array(amount).fill(), [amount]);
//   return (
//     <div className="d-flex flex-row align-items-stretch justify-content-stretch flex-wrap">
//       {range.map((_, i) => (
//         <div className="rounded bg-light m-1 py-2 px-3 flex-grow-1" key={i}>
//           <SlabSkeleton />
//         </div>
//       ))}
//     </div>
//   );
// };
