import get from "lodash/get";
import React, { useEffect, useMemo, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Pager = ({ pageInfo, onPageChange }) => {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const [totalElements, totalPages, size, number] = useMemo(
    () => [
      get(pageInfo, "totalElements", 0),
      get(pageInfo, "totalPages", 0),
      get(pageInfo, "size", 0),
      get(pageInfo, "number", 0)
    ],
    [pageInfo]
  );

  useEffect(() => {
    if (totalElements <= size) {
      setStart(1);
      setEnd(totalElements);
    } else {
      setStart(number * size + 1);
      let pageMax = size * (number + 1);
      setEnd(pageMax - Math.max(0, pageMax - totalElements));
    }
  }, [totalElements, size, number, pageInfo]);

  useEffect(() => {
    if (totalPages != 0 && number >= totalPages) {
      onPageChange(0);
    }
  }, [number, totalPages, onPageChange, pageInfo]);
  return (
    <Pagination listClassName="my-0 mx-2 d-flex align-items-center flex-nowrap">
      {pageInfo && number > 0 && (
        <PaginationItem>
          <PaginationLink
            previous
            onClick={e => {
              e.preventDefault();
              onPageChange(number - 1);
            }}
          ></PaginationLink>
        </PaginationItem>
      )}
      {pageInfo && start && end > 0 && (
        <span className="mx-1 text-nowrap">{`${start}-${end} of ${totalElements}`}</span>
      )}
      {pageInfo && number < totalPages - 1 && (
        <PaginationItem>
          <PaginationLink
            next
            onClick={e => {
              e.preventDefault();
              onPageChange(number + 1);
            }}
          ></PaginationLink>
        </PaginationItem>
      )}
    </Pagination>
  );
};

export default Pager;
