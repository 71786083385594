import { useGet, usePost, usePut } from "hooks/dataHooks";
import { useMutation, useQuery } from "react-query";
import { useCallback } from "react";
import { isArray } from "lodash";

const BASE_URL = "/media";

const mediaPaths = {
  all: [BASE_URL],
  status: id => [...mediaPaths.all, id]
};

const mediaKeys = {
  all: mediaPaths.all,
  status: id => mediaPaths.status(id)
};

export const getMediaKeyAndPath = (requestName, ...args) => {
  return [mediaKeys[requestName](...args), mediaPaths[requestName](...args)];
};

export const useCheckMediaStatus = (mediaId, options) => {
  const [queryKey, path] = getMediaKeyAndPath("status", mediaId)
  const [get] = useGet(path);
  return useQuery(queryKey, get, options);
};

export const useValidateMedia = () => {
  const post = usePost();
  const mutation = useMutation(({ key, validationRuleName }) =>
    post(process.env.REACT_APP_MEDIA_PROCESS_UPLOAD_URL, {
      data: { key, validationRuleName }
    })
  );
  return mutation.mutateAsync;
};

const useUploadRawFile = () => {
  const put = usePut();
  const mutation = useMutation(({ uploadUrl, fileType, rawFile, onPercentComplete }) =>
    put(uploadUrl, {
      data: rawFile,
      headers: {
        "Content-Type": fileType?.split(";")[0] || "binary/octet-stream"
      },
      params: {
        onUploadProgress: progressEvent => {
          if (onPercentComplete) {
            onPercentComplete(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          }
        }
      }
    })
  );
  return mutation.mutateAsync;
};

export const useUploadFile = () => {
  const uploadRawFile = useUploadRawFile();
  const reader = new FileReader();
  return ({ file, uploadUrl, onPercentComplete }) => {
    reader.readAsArrayBuffer(file);
    return new Promise(async function(resolve, reject) {
      reader.onload = async event => {
        try {
          await uploadRawFile({
            uploadUrl,
            fileType: file.type,
            rawFile: event.target.result,
            onPercentComplete
          });
        } catch (e) {
          reject(e);
        }
        resolve();
      };
    });
  };
};

export function useUploadUrl() {
  const post = usePost();
  const mutation = useMutation(params => {
    return post(BASE_URL, { data: null, params });
  });
  return useCallback(mutation.mutateAsync, [mutation]);
}

export function useUploadMedia() {
  const requestUploadUrl = useUploadUrl();
  const uploadFile = useUploadFile();
  const validateMedia = useValidateMedia();

  return useCallback(async (file, options = {}) => {
    const files = isArray(file) ? file : [file];
    const data = await requestUploadUrl({
      type: options?.type || "IMAGE",
      num: files.length,
      format: options?.format
    });
    try {
      await Promise.all(
        data.map((mediaItem, index) =>
          uploadFile({
            file: files[index],
            uploadUrl: mediaItem.uploadUrl,
            onPercentComplete: options.onPercentComplete
          }).then(() => validateMedia({ key: mediaItem?.storageKey, validationRuleName: options.validationRuleName }))
        )
      );
      return data.map(m => {
        const retval = { ...m };
        delete retval.uploadUrl;
        return retval;
      });
    } catch (e) {
      throw e;
    }
  }, []);
}
