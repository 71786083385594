import React from "react";
import classnames from "classnames";

import styles from "./RemovableListItem.module.scss";

const { removableListItemClass } = styles;

export const RemovableListItem = ({ value, onRemove, className }) => (
  <div className={classnames(removableListItemClass, className)} key={value}>
    <span>{value}</span>
    <i className="icon-close" onClick={onRemove} />
  </div>
);

export default RemovableListItem;
