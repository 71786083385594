import React, { useCallback, useEffect, useState } from "react";
import { DashboardWidget } from "../../../components/Widgets/DashboardWidget";
import { Link } from "react-router-dom";
import { getReviewStatusCount } from "../../../api/reportApi";
import { useDispatch } from "react-redux";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../../redux/actions/ajaxStatusActions";
import { errorCaughtNotifier, useLocalNotifications } from "../../../components/Notifications/notification";

export default () => {
  const [reviewStatusCount, setReviewStatusCount] = useState({});
  const loadReviewStatusCount = useReviewStatusCountLoader();

  useEffect(() => {
    loadReviewStatusCount().then(map => {
      setReviewStatusCount(map);
    });
  }, [loadReviewStatusCount]);

  return (
    <DashboardWidget>
      <div className="d-flex flex-row justify-content-center flex-wrap">
        <SummaryWidget count={reviewStatusCount["UNREPLIED"]} status={"UNREPLIED"}>
          Unreplied Reviews
        </SummaryWidget>
        <span className="text-muted d-none d-lg-inline">|</span>
        <SummaryWidget count={reviewStatusCount["REVIEW"]} status={"REVIEW"}>
          Pending Reviews
        </SummaryWidget>
        <span className="text-muted d-none d-lg-inline">|</span>
        <SummaryWidget count={reviewStatusCount["APPROVAL"]} status={"APPROVAL"}>
          Approval Reviews
        </SummaryWidget>
        <span className="text-muted d-none d-lg-inline">|</span>
        <SummaryWidget count={reviewStatusCount["APPROVED"]} status={"APPROVED"}>
          Approved Reviews
        </SummaryWidget>
        <span className="text-muted d-none d-lg-inline">|</span>
        <SummaryWidget count={reviewStatusCount["CUSTOMER_RESPONDED"]} status={"CUSTOMER_RESPONDED"}>
          Customer Responded
        </SummaryWidget>
      </div>
    </DashboardWidget>
  );
};

/**
 * @param {{count: number; status: string; children: string;}} properties
 * @returns {JSX.Element}
 */
const SummaryWidget = ({ count, status, children }) => (
  <div className="mx-3 d-flex text-nowrap nowrap flex-nowrap">
    <Link className="d-flex flex-row text-nowrap text-dark" to={`/reviews/list?status=${status}`}>
      {children}
    </Link>
    <span className="ms-2">{count}</span>
  </div>
);

/**
 */
const useReviewStatusCountLoader = () => {
  const dispatch = useDispatch();
  const notify = useLocalNotifications();
  return useCallback(() => {
    dispatch(beginAjaxCall());
    return getReviewStatusCount()
      .then(v => {
        dispatch(endAjaxCall());
        return v;
      })
      .catch(e => {
        dispatch(ajaxCallError());
        errorCaughtNotifier(notify)(e);
        return {};
      });
  }, []);
};
