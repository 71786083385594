import { get, identity, isArray, isEqual, isFunction, isNil, isNull, isString, omit, toString } from "lodash";
import moment from "moment";
import qs from "querystring";
import { assocPath, pathOr, pipe, prepend, split, until } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { reportSetEndDate, reportSetStartDate } from "../redux/actions/reportActions";
import { updatePath, updateViewState } from "../redux/actions/viewStateActions";
import usePureMemo from "./usePureMemo/usePureMemo";
import { parseFormFieldName } from "util/stringUtils";
import { VIEW_STATE_STORAGE_KEYS } from "data/storageKeys";

/**
 * Live updating location query param value
 *
 * @param name query param name
 * @param pipe value processing function
 * @type {<T = string>(name: string, pipe?: (v: string) => T) => T}
 */
export const useLocationQueryParamValue = (name, pipe = identity) => {
  const history = useHistory();
  const [value, setValue] = useState(pipe(qs.parse(history.location.search.slice(1))[name]));
  useEffect(
    () =>
      history.listen(({ search }) => {
        const val = pipe(qs.parse(search.slice(1))[name]);
        setValue(prev => (JSON.stringify(prev) === JSON.stringify(val) ? prev : val));
      }),
    [history, name, pipe]
  );
  return value;
};

/**
 * Setter for updating location query param by name
 *
 * @param name query param name
 * @param pipe value processing function
 * @type {<T = string>(name: string, pipe: (v: T) => string) => (v: T) => void}
 */
export const useLocationQueryParamSetter = (name, pipe = defaultSetPipe) => {
  const history = useHistory();
  const setter = useCallback(
    value => {
      const query = qs.parse(history.location.search.slice(1));
      const actualValue = pipe(isFunction(value) ? value(query[name]) : value);
      history.push({
        ...history.location,
        search: qs.stringify(
          isNull(actualValue)
            ? omit(query, name)
            : {
                ...query,
                [name]: actualValue
              }
        )
      });
    },
    [history, pipe]
  );

  return setter;
};

/**
 * Use this hook in case, when you want to syncronize state
 * with the location query param.
 *
 * @param name url path parameter name
 * @param pipes pipes as codec for value passing through
 *
 * @type {<T = string>(name: string, pipes?: { read?: (v: string) => T; write?: (v: T) => string }) => [T, (v: T) => void]}
 */
export const useLocationQueryParamState = (
  name,
  pipes = {
    read: identity,
    write: defaultSetPipe
  }
) => {
  const value = useLocationQueryParamValue(name, pipes?.read || identity);
  const setter = useLocationQueryParamSetter(name, pipes?.write || toString);
  const cbSupportingSetter = useCallback(v => setter(isFunction(v) ? v(value) : v), [setter, value]);

  return [value, cbSupportingSetter];
};

/**
 * The same usage as useState, but actual state will circulate through redux
 * store and be persistant within the viewState under the specified key.
 * @param key key to persist state under
 * @param defaultState default value in case if no persistance found
 * @type {<T>(key: string, defaultState: T) => [T, React.Dispatch<React.SetStateAction<T>]}
 */
export const usePersistantViewState = (key, defaultState) => {
  const dispatch = useDispatch();
  const value = useSelector(s => get(s.viewState, [key, "persistent", "value"], defaultState));
  const updateValue = newval =>
    typeof newval === "function"
      ? dispatch(updateViewState(key, { persistent: { value: newval(value) } }))
      : dispatch(updateViewState(key, { persistent: { value: newval } }));
  return [value, updateValue];
};

/**
 * This hook can be used instead the pair of usePersistantViewState hook
 * to use for the aop sidebar state management.
 * @type {() => [boolean, boolean, React.Dispatch<React.SetStateAction<boolean>, React.Dispatch<React.SetStateAction<boolean>]}
 */
export const useSidebarPersistantViewState = () => {
  const [minimizedSidebar, setMinimizedSidebar] = usePersistantViewState(
    VIEW_STATE_STORAGE_KEYS.SIDEBAR_MINIMIZED,
    true
  );
  const [expandedForMobileSidebar, setExpandedForMobileSidebar] = usePersistantViewState(
    VIEW_STATE_STORAGE_KEYS.SIDEBAR_EXPANDED_FOR_MOBILE,
    false
  );
  return [minimizedSidebar, expandedForMobileSidebar, setMinimizedSidebar, setExpandedForMobileSidebar];
};

/**
 *
 * @param {string} key
 * Key to sync data to. Key will be the same for redux and for query
 * @param {any} fallback
 * Value that will be used when neither query nor redux value set for specified key
 * @param {(v: import("history").Location<{}>) => boolean} predicate
 * This param allows you to specify conditions when value should be syncronized.
 * By default predicate ensures that sync is happening within the same pathname.
 * This behavior is usually the expected one. No one wants to erase all the persistent
 * data on leaving the page. However, if you are know what you are doing,
 * you could override predicate to manually check conditions
 * @returns {[any, (v: any) => void]}
 */
export const usePersistentReduxQuerySyncedState = (key, fallback, predicate) => {
  const history = useHistory();
  const { push, replace } = history;
  const location = useLocation();
  const setter = useCallback(
    valueOrFunc => {
      const query = qs.parse(location.search.slice(1));
      const oldval = dequerialize(query[key] ?? null) || fallback;
      const newval = isFunction(valueOrFunc) ? valueOrFunc(oldval) : valueOrFunc;
      if (!isEqual(newval, oldval)) {
        push({
          search: qs.stringify({
            ...query,
            [key]: querialize(newval)
          })
        });
      }
    },
    [location, key, fallback]
  );

  const dispatch = useDispatch();
  const fallbackPredicate = useCallback(({ pathname }) => location.pathname === pathname, []); // no dependencies!
  const reduxSelector = useCallback(s => get(s, ["viewState", key, "persistent", "value"], fallback), [key, fallback]);
  const reduxValue = useSelector(reduxSelector, isEqual);
  const potentialValue = useMemo(
    () => (v => (v && dequerialize(v)) || reduxValue || fallback)(qs.parse(location.search.slice(1))[key]),
    [reduxValue, location, fallback]
  );

  // sync url once, because could be falling back
  useEffect(() => {
    const query = qs.parse(history.location.search.slice(1));
    replace({
      search: qs.stringify({
        ...query,
        [key]: querialize(potentialValue)
      })
    });
  }, []); // no dependencies!

  const [value, setValue] = useState(potentialValue);
  useEffect(
    () =>
      setValue(prev =>
        JSON.stringify(prev, null, 0) === JSON.stringify(potentialValue, null, 0) ? prev : potentialValue
      ),
    [potentialValue]
  );
  useEffect(() => {
    if ((predicate || fallbackPredicate)(location)) {
      const query = qs.parse(location.search.slice(1));
      const queryValue = query[key];
      const actualQueryValue = (queryValue && dequerialize(queryValue)) || null;
      if (!!actualQueryValue) {
        dispatch(
          updateViewState(key, {
            persistent: { value: actualQueryValue }
          })
        );
      }
    }
  }, [key, predicate, fallbackPredicate, location]);
  return [value, setter];
};

/**
 * @type {() => [ [import("moment").Moment, import("moment").Moment], (v: [import("moment").Moment, import("moment").Moment]) => void ]}
 */
export const useReportSharedTimerangeState = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getQuery = useCallback(() => qs.parse(history.location.search.slice(1)), [history.location]);
  const reduxStartDate = useSelector(v => v.report.startDate?.toISOString());
  const reduxEndDate = useSelector(v => v.report.endDate?.toISOString());
  const value = useMemo(() => {
    const query = getQuery();
    const rawStartDate = query.startDate;
    const rawEndDate = query.endDate;
    return [
      rawStartDate || reduxStartDate ? moment(rawStartDate || reduxStartDate) : moment().startOf("month"),
      rawEndDate || reduxEndDate ? moment(rawEndDate || reduxEndDate) : moment()
    ];
  }, [reduxStartDate, reduxEndDate, getQuery]);
  const setter = useCallback(
    ([start, end]) => {
      const restQueryParams = omit(getQuery(), ["startDate", "endDate"]);
      history.push({
        ...history.location,
        search: qs.stringify({
          ...restQueryParams,
          startDate: start?.format("YYYY-MM-DD") || null,
          endDate: end?.format("YYYY-MM-DD") || null
        })
      });
    },
    [history.location, getQuery]
  );

  useEffect(() => {
    dispatch(reportSetStartDate(value[0].startOf("day")));
    dispatch(reportSetEndDate(value[1].endOf("day")));
  }, [value]);

  return [value, setter];
};

// Unused Hooks//

/**
 * Live updating route param value listener.
 * You could specify pipe function for transforming route
 * param string value to data format you need
 *
 * @param name route param name
 * @param pipe value processing function
 * @type {<T = string>(name: string, pipe?: (v: string) => T) => T}
 */
export const useRouteParamValue = (name, pipe = identity) => {
  const match = useRouteMatch();
  const value = useMemo(() => pipe(match.params[name]), [match, name, pipe]);
  return value;
};

/**
 * Setter for updating route param by name
 *
 * @param name route param name
 * @param pipe value processing function
 * @param appendWhenNoParam if true and there is no placeholder with specified name setter will append value as route child
 * @type {<T = string>(name: string, pipe: (v: T) => string, appendWhenNoParam?: boolean) => (v: T) => void}
 */
export const useRouteParamSetter = (name, pipe = v => (v && toString(v)) || "", appendWhenNoParam = true) => {
  const history = useHistory();
  const match = useRouteMatch();
  const placeholder = useMemo(() => `:${name}`, [name]);
  return useCallback(
    v => {
      const pathname = match.path.replace(placeholder, pipe(v));
      void history.push({
        ...history.location,
        pathname: match.path === pathname && appendWhenNoParam ? `${pathname}/${pipe(v)}` : pathname
      });
    },
    [match, history, placeholder, pipe]
  );
};

/**
 * Use this hook in case, when you want to syncronize state
 * with the location, using route defined parameter.
 * Keep in mind, that you have to use this state hook inside
 * component being rendered under the route that have path
 * parameter you are about to use for sync. Otherwise, state
 * value will always be undefined.
 *
 * Also, as a temporary restriction - only single parameter pathes
 * are supported now. For example, `/a/:b/:c` is not supported due
 * to we have two params there: 'b' and 'c'.
 *
 * @param name url path parameter name
 * @param pipes pipes as codec for value passing through
 *
 * @example
 * // when you have route `/a/b/:c` defined
 * // you could use this hook, to shate some kind of string state
 * // via `c` path parameter in a way like:
 * const [param, setParam] = useRoutePathParamState("c");
 *
 * @todo support multiparam pathes
 *
 * @type {<T = string>(name: string, pipes?: { read?: (v: string) => T; write?: (v: T) => string }) => [T, (v: T) => void]}
 */
export const useRoutePathParamState = (
  name,
  pipes = {
    read: identity,
    write: toString
  }
) => {
  const value = useRouteParamValue(name, pipes?.read || identity);
  const setter = useRouteParamSetter(name, pipes?.write || toString);

  return [value, setter];
};

// End Unused Hooks //

export const useReduxAt = (path = [], fallback = undefined) => {
  const realFallback = useMemo(() => (isFunction(fallback) ? fallback() : fallback), [fallback]);
  const realPath = usePureMemo(pipe(until(isArray, split("/")), prepend("viewState")), path);
  const selector = useCallback(pathOr(realFallback, realPath), [realFallback, realPath]);
  return useSelector(selector);
};

/**
 * returns a function that can set the value for a given path in redux
 * @param newval value to set
 * @param path path to cycle state through
 */
export const useUpdateRedux = () => {
  const dispatch = useDispatch();
  const updateValue = useCallback((newval, path) => dispatch(updatePath(path, newval)), [dispatch]);
  return updateValue;
};

/**
 * The same usage as useState, but actual state will circulate through redux
 * store under the specified path.
 * @param path path to cycle state through
 * @param defaultState default value or value factory in case if no redux key found
 * @type {<T>(path?: string | string[], defaultState?: T | () => T) => [T, React.Dispatch<React.SetStateAction<T>]}
 */
export const useStateThroughRedux = (path = [], fallback = undefined) => {
  const dispatch = useDispatch();
  const normalizedPath = useMemo(() => until(isArray, split("/"))(path || []), [path]);
  const value = useReduxAt(normalizedPath, fallback);
  const updateValue = useCallback(newval => dispatch(updatePath(normalizedPath, newval)), [dispatch, normalizedPath]);
  return [value, updateValue];
};

const defaultSetPipe = v => (isNil(v) ? null : toString(v));
const querialize = v => (isString(v) ? v : JSON.stringify(v, null, 0));
const dequerialize = v => {
  try {
    return JSON.parse(v);
  } catch (_) {
    return v;
  }
};

export const eventTargetValue = evt =>
  evt?.target?.type === "number" ? parseInt(evt?.target?.value) : evt?.target?.value;
export const eventTargetChecked = evt => evt?.target?.checked;
export const eventTargetSelectValue = evt => evt?.target?.value?.value;
export const eventTargetSelectValueWithLabel = evt => ({
  value: evt?.target?.value?.value,
  label: evt?.target?.value?.label
});
export const eventTargetSelectMultiValue = evt => evt?.target?.value?.map(v => v.value);
/**
 * Returns a setter function suitable for use on form field onChange handlers.
 * Supports setting nested paths in an object. Useful when you have a form populating
 * fields on a larger object.
 *
 * @param {function} setter setter for the state value
 * @param {function} valueGetter function which takes a form event and extracts a value
 * @returns setter function
 */
export const useFormEventSetter = (setter, valueGetter = eventTargetValue) =>
  useCallback(
    event => {
      const {
        target: { name }
      } = event;
      const path = parseFormFieldName(name);
      return setter(assocPath(path, valueGetter(event)));
    },
    [setter]
  );

export const useFormState = (initialState, valueGetter = eventTargetValue) => {
  const [obj, setObj] = useState(initialState);
  const fieldSetter = useFormEventSetter(setObj, valueGetter);
  return [obj, setObj, fieldSetter];
};
