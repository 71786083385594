import React, { useCallback, useState, useMemo, useRef } from "react";
import { Col, Row } from "reactstrap";
import SelectField from "components/Form/SelectField";
import RemoveFieldControl from "components/Form/RemoveFieldControl";
import DaysOfWeekForm from "./DaysOfWeekForm";

const ADDITIONAL_HOURS_TYPE = "moreHours";

const AdditionalHoursForm = ({ initialValues, values, moreHoursTypes }) => {
  const selectRef = useRef();

  const hoursTypeOptions = useMemo(
    () =>
      moreHoursTypes.map(({ hoursTypeId, localizedDisplayName }) => ({
        value: hoursTypeId,
        label: localizedDisplayName
      })),
    [moreHoursTypes]
  );

  const [selectedMoreHoursTypes, setSelectedMoreHoursTypes] = useState(
    initialValues?.moreHours
      ? Object.keys(initialValues.moreHours).map(hoursTypeId =>
          hoursTypeOptions.find(({ value }) => value === hoursTypeId)
        )
      : []
  );

  const onSelectHourType = useCallback(
    e => {
      if (e.target.value) {
        setSelectedMoreHoursTypes(prev => [e.target.value, ...prev]);
        selectRef.current.clearValue();
      }
    },
    [selectRef.current, setSelectedMoreHoursTypes]
  );

  const filteredHoursTypeOptions = useMemo(
    () => hoursTypeOptions.filter(({ value }) => !selectedMoreHoursTypes.some(selected => selected.value === value)),

    [hoursTypeOptions, selectedMoreHoursTypes]
  );

  const removeHoursType = useCallback(typeToRemove => {
    setSelectedMoreHoursTypes(prev => prev.filter(hoursType => hoursType.value !== typeToRemove));
  }, []);

  return (
    <div className="mt-4">
      <h5 className="mb-1">Additional Hours</h5>
      <Row>
        <Col sm={10} md={6}>
          <SelectField
            name="moreHoursTypeSelect"
            options={filteredHoursTypeOptions}
            onChange={onSelectHourType}
            ref={selectRef}
          />
        </Col>
      </Row>
      {selectedMoreHoursTypes.map(hoursType => (
        <Row className="py-4" key={hoursType.value}>
          <Col sm={12}>
            <div className="d-flex align-items-center">
              <RemoveFieldControl
                className="pe-2"
                forField={`${ADDITIONAL_HOURS_TYPE}.${hoursType.value}`}
                onClick={() => removeHoursType(hoursType.value)}
              />
              <span className="text-uppercase">{hoursType.label}</span>
            </div>
          </Col>
          <DaysOfWeekForm
            initialValues={initialValues}
            values={values}
            field={`${ADDITIONAL_HOURS_TYPE}.${hoursType.value}`}
          />
        </Row>
      ))}
    </div>
  );
};

export default AdditionalHoursForm;
