import { useGet } from "hooks/dataHooks";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

const TAGS_PAGE_SIZE = 200;

/**
 *
 * @param {{[index: string]: string | number}} params
 * @param {boolean} enabled
 * @returns {import("react-query").UseQueryResult<[{
 *     averageRating: number,
 *     count: number,
 *     type: "PERSON",
 *     value: string
 * }]>}
 */
export default function useTagsReportData({ params, enabled = true }) {
  const fullParams = useMemo(() => ({ size: TAGS_PAGE_SIZE, ...params }), [params]);
  const queryKey = useMemo(() => ["/reviews/*/entityCounts", fullParams], [fullParams]);
  const [get] = useGet("/reviews/*/entityCounts", { params: fullParams });
  const queryRun = useCallback(() => get(), [get]);
  return useQuery(queryKey, queryRun, {
    enabled
  });
}
