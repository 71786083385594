import { noop, set } from "lodash";
import { useState } from "react";
import WWButton from "components/Buttons/WWButton";
import ResponderNoteField from "./ResponderNoteField";
import Skeleton from "../../Skeleton";
import { useResponderNotes } from "hooks/data/responderNotesHooks";
import { showSkeleton } from "util/showSkeleton";


const ResponderNotesEditor = ({ notes = [], onChange = noop, customerId, tags, tagSetId }) => {
  const [updatedNotes, setUpdatedNotes] = useState(notes);

  const updateNotes = data => {
    setUpdatedNotes(data);
    onChange(data);
  };

  const handleNotesUpdate = index => path => value => {
    const newArr = [...updatedNotes];
    set(newArr, [index, ...path], value);
    if (newArr[index].name === null && newArr[index].campaign.name) {
      newArr[index].name = newArr[index].campaign.name;
    }
    updateNotes(newArr);
  };

  const addNote = () => {
    const newNote = {
      title: "Responder Instruction",
      color: "YELLOW",
      requireConfirmation: false,
      tempId: Date.now()
    };
    updateNotes([...updatedNotes, newNote]);
  };

  const removeNote = index => {
    let newArr = [...updatedNotes];
    newArr.splice(index, 1);
    updateNotes(newArr);
  };

  return (
    <div className="w-100">
      {updatedNotes.map((note, index) => (
        <ResponderNoteField
          key={note.id || note.tempId}
          note={note}
          customerId={customerId}
          tags={tags}
          tagSetId={tagSetId}
          handleNotesUpdate={handleNotesUpdate(index)}
          removeNote={() => removeNote(index)}
        />
      ))}
      <div className="d-flex justify-content-center mt-3">
        <WWButton color="primary" onClick={addNote}>
          Add Responder Instruction
        </WWButton>
      </div>
    </div>
  );
};

const ConnectedResponderNotesEditor = ({ onChange = noop, customerId, accountId, tags, tagSetId, enabled = true }) => {
  const hasId = !!customerId || !!accountId;
  const { data, isFetched, isLoading } = useResponderNotes(
    {
      customerId,
      accountId
    },
    {
      enabled: hasId && enabled
    }
  );
  return hasId && showSkeleton({ isFetched, isLoading }) ? (
    <Skeleton height={400} />
  ) : (
    <ResponderNotesEditor notes={data} customerId={customerId} tags={tags} tagSetId={tagSetId} onChange={onChange} />
  );
};

export default ConnectedResponderNotesEditor;
