import { post, put } from "api/apiClient";
import axios from "axios";
import { useNotification, useErrorNotification } from "components/Notifications/notification";

export const useS3FileUploadWithPostProcess = ({ uploadPath, postProcessPath, successMessage }) => {
  const errorNotify = useErrorNotification(),
    notify = useNotification(),
    upload = e => {
      const file = e.target.files[0],
        postConfig = {
          headers: {
            "Content-Type": "text/csv"
          }
        };

      if (!file) return;

      post(uploadPath)
        .then(async ({ data }) => {
          const { storageKey, uploadUrl } = data;

          await axios.put(uploadUrl, file, postConfig);

          return storageKey;
        })
        .then(storageKey => post(postProcessPath, { storageKey }))
        .then(() => notify({ body: successMessage }))
        .catch(errorNotify);
    };

  return upload;
};
