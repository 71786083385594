import React from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import truncate from "lodash/truncate";
import { Container, Row, Col } from "reactstrap";

const Attachments = ({ attachments }) => {
  if (isEmpty(attachments)) {
    return <div className="d-none" />;
  }

  let components = map(attachments, (attachment, i) => {
    if (attachment.type === "PHOTO") {
      return <ImageAttachment key={i} attachment={attachment} />;
    } else {
      return <ShareAttachment key={i} attachment={attachment} />;
    }
  });

  return (
    <Container className="attachments">
      <Row>{components}</Row>
    </Container>
  );
};

export default Attachments;

const ImageAttachment = ({ attachment }) => {
  const style = {
    borderRadius: "10px",
    display: "inline-block",
    maxWidth: "100%"
  };

  return (
    <Col>
      <img className="m-1" style={style} src={attachment.target} alt={attachment.title} />
    </Col>
  );
};

const ShareAttachment = ({ attachment }) => {
  return (
    <Col>
      <div
        className="border-top border-bottom bg-light"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(attachment.target, "_blank")}
      >
        <p className="fw-bold">{attachment.title}</p>
        <p>{truncate(attachment.description)}</p>
      </div>
    </Col>
  );
};
