import { keys, pipe, map } from "ramda";
import memoize from "memoizee";
import { SITES } from "data/sites";
import { fromPairs } from "lodash";
import styles from "./options.module.scss";
import classNames from "classnames";
import { permissions } from "components/Auth/permissions";
const { grayClass } = styles;

const convertToLabelValuePair = (source, getLabel) =>
  source.map(value => {
    return { value: value, label: getLabel(value) };
  });

export const RESPONSE_TYPES = ["Casual", "Conservative"];
export const CONTACT_STATUS = ["INACTIVE", "ACTIVE"];
export const CUSTOMER_STATUSES = ["ACTIVE", "PENDING", "CANCELED", "REPORTING", "NEVER_LAUNCHED"];
export const CUSTOMER_STATUSES_LABEL_PAIRS = CUSTOMER_STATUSES.map(status => {
  return { label: status.toLowerCase().replace(/_/g, " "), value: status };
});

export const SITE_LABEL_PAIRS = SITES.map(site => ({ label: site.label, value: site.source }));
export const LIFETIME_TREND_SITES = fromPairs(
  SITES.filter(site => site.isLifetimeRatingSite).map(site => [site.source, site.label])
);
export const CONTACT_TYPES = ["Billing", "Escalation", "Report", "Other"];
export const SIGNATORY_TAG = "Signatory";
export const AUTORESPONSE_TAG = "Autoresponse";
export const ACTIVE_CONTACT = "ACTIVE";
export const DEFAULT_TAGS = ["General"];
export const ContactMethods = Object.freeze({
  CUSTOM: "CUSTOM",
  EMAIL: "EMAIL",
  PHONE: "PHONE"
});
export const PREFERRED_CONTACT_METHOD = [ContactMethods.PHONE, ContactMethods.EMAIL, ContactMethods.CUSTOM];
export const REPLY_STATUS = ["DRAFT", "PENDING_REVIEW", "APPROVED", "REJECTED", "PUBLISHED"];
export const REVIEW_TAGS = ["Sales", "Service", "Finance", "General"];
export const YESNO = ["Yes", "No"];

export const TAG_ICONS = {
  General: "fa-tags",
  Service: "fa-wrench",
  Sales: "fa-dollar",
  Finance: "fa-pencil"
};

export const MONTH_FORMAT = "MMM D YYYY";

const RatingToNameMap = {
  1: "1 Star",
  2: "2 Stars",
  3: "3 Stars",
  4: "4 Stars",
  5: "5 Stars"
};
// changed to string as update to URLValue results in everything being returned as a string. this will be replaced in filter 3.0
export const RATINGS = pipe(keys, map(String))(RatingToNameMap);
export const ratingName = v => RatingToNameMap[v] || v;
export const RATINGS_LABEL_PAIRS = convertToLabelValuePair(RATINGS, ratingName);

const ReviewStatusToReviewStatusNameMap = {
  UNREPLIED: "Unreplied",
  APPROVAL: "Approval",
  APPROVED: "Approved",
  REPLIED: "Replied",
  REMOVED: "Removed",
  IGNORED: "Ignored",
  REVIEW: "Review",
  NO_ACTION: "No action",
  QUEUED: "Queued",
  CUSTOMER_RESPONDED: "Customer Responded",
  CUSTOMER_UNREPLIED: "Customer Unreplied"
};
export const REVIEW_STATUS = Object.keys(ReviewStatusToReviewStatusNameMap);
export const reviewStatusName = source => ReviewStatusToReviewStatusNameMap[source] || source;
export const REVIEW_STATUS_LABEL_VALUE = convertToLabelValuePair(REVIEW_STATUS, reviewStatusName);

const InviteStatusToInviteStatusNameMap = {
  SKIPPED: "Skipped",
  FAILED: "Failed",
  QUEUED: "Queued",
  OPENED: "Opened",
  UNOPENED: "Unopened"
};
export const INVITE_STATUS = Object.keys(InviteStatusToInviteStatusNameMap);
export const inviteStatusName = source => InviteStatusToInviteStatusNameMap[source] || source;
export const INVITE_STATUS_LABEL_VALUE = convertToLabelValuePair(INVITE_STATUS, inviteStatusName);

const PostStatusToPostStatusNameMap = {
  DRAFT: "Draft",
  POSTED: "Posted",
  REMOVED: "Removed",
  QUEUED: "Queued",
  FAILED: "Failed",
  SCHEDULED: "Scheduled"
};
export const POST_STATUS = Object.keys(PostStatusToPostStatusNameMap);
export const postStatusName = source => PostStatusToPostStatusNameMap[source] || source;

const ReviewTypeToReviewTypeNameMap = {
  REVIEW: "Review",
  POST: "Post",
  QUESTION: "Question",
  AD: "Ad"
};
export const REVIEW_TYPE = Object.keys(ReviewTypeToReviewTypeNameMap);
export const reviewTypeName = source => ReviewTypeToReviewTypeNameMap[source] || source;
export const REVIEW_TYPE_LABEL_VALUE = convertToLabelValuePair(REVIEW_TYPE, reviewTypeName);

export const STATUS_LABEL_VALUES = (currentAgencyName = "Widewail") => [
  { value: "UNREPLIED", label: `With ${currentAgencyName} responders` },
  { value: "REPLIED", label: "Replied" },
  { value: "NO_ACTION", label: "No action needed" },
  { value: "APPROVAL", label: "Approval" },
  { value: "CUSTOMER_RESPONDED", label: `Sent back to ${currentAgencyName}` },
  { value: "CUSTOMER_UNREPLIED", label: `Unreplied` },
  { value: "REMOVED", label: "Removed" },
  { value: "IGNORED", label: "Spam/Not Tracking" }
];

const SentimentsValueNameMap = {
  NEUTRAL: "Neutral",
  POSITIVE: "Positive",
  NEGATIVE: "Negative"
};
export const SENTIMENTS = Object.keys(SentimentsValueNameMap);
export const sentimentValueName = source => SentimentsValueNameMap[source] || source;

const SupportedSalutationsValue2NameMap = {
  blank: "",
  mr: "Mr.",
  mrs: "Mrs.",
  miss: "Miss.",
  ms: "Ms.",
  mx: "Mx.",
  dr: "Dr."
};
export const SUPPORTED_SLUTATIONS = Object.keys(SupportedSalutationsValue2NameMap);
export const salutationName = val =>
  SupportedSalutationsValue2NameMap[val] === undefined ? val : SupportedSalutationsValue2NameMap[val];
export const salutationValue = n =>
  Object.keys(SupportedSalutationsValue2NameMap).find(key => SupportedSalutationsValue2NameMap[key] === n);
/* Post Options */

export const RESPONDER_FLAG_VALUES = Object.freeze({
  doNotRespond: "DO_NOT_RESPOND",
  followUp: "FOLLOW_UP",
  green: "GREEN",
  teal: "TEAL",
  purple: "PURPLE",
  brown: "BROWN",
  yellow: "YELLOW"
});

export const RESPONDER_NOTE_COLOR_VALUES = Object.freeze({
  yellow: "YELLOW",
  blue: "BLUE",
  red: "RED",
  green: "GREEN",
  gray: "GRAY"
});

const addCircleIconClass = colorClass => classNames("fa fa-circle", colorClass);

export const RESPONDER_NOTE_COLOR_OPTIONS = [
  { iconClass: addCircleIconClass("text-primary"), value: RESPONDER_NOTE_COLOR_VALUES.yellow, label: "Yellow" },
  { iconClass: addCircleIconClass("text-secondary"), value: RESPONDER_NOTE_COLOR_VALUES.blue, label: "Blue" },
  { iconClass: addCircleIconClass("text-danger"), value: RESPONDER_NOTE_COLOR_VALUES.red, label: "Red" },
  { iconClass: addCircleIconClass("text-success"), value: RESPONDER_NOTE_COLOR_VALUES.green, label: "Green" },
  { iconClass: addCircleIconClass(grayClass), value: RESPONDER_NOTE_COLOR_VALUES.gray, label: "Gray" }
];

// Subset for front end from api/src/main/java/com/widewail/entity/WidewailPost.java
export const POST_STATUSES = ["DRAFT", "REMOVED", "QUEUED"];
export const POST_TYPES = ["POST"];

//SMS form
export const ENTITY_TYPES = ["PRIVATE_PROFIT", "PUBLIC_PROFIT", "NON_PROFIT", "GOVERNMENT", "SOLE_PROPRIETOR"];
export const STOCK_EXCHANGE = [
  "NONE",
  "NASDAQ",
  "NYSE",
  "AMEX",
  "AMX",
  "ASX",
  "B3",
  "BME",
  "BSE",
  "FRA",
  "ICEX",
  "JPX",
  "JSE",
  "KRX",
  "LON",
  "NSE",
  "OMX",
  "SEHK",
  "SGX",
  "SSE",
  "STO",
  "SWX",
  "SZSE",
  "TSX",
  "TWSE",
  "VSE",
  "OTHER"
];
export const BRAND_RELATIONSHIPS = ["BASIC_ACCOUNT", "SMALL_ACCOUNT", "MEDIUM_ACCOUNT", "LARGE_ACCOUNT", "KEY_ACCOUNT"];
export const ALT_BUSINESS_ID_TYPE = ["NONE", "DUNS", "GIIN", "LEI"];
export const VERTICALS = [
  "AGRICULTURE",
  "COMMUNICATION",
  "CONSTRUCTION",
  "EDUCATION",
  "ENERGY",
  "ENTERTAINMENT",
  "FINANCIAL",
  "GAMBLING",
  "GOVERNMENT",
  "HEALTHCARE",
  "HOSPITALITY",
  "HUMAN_RESOURCES",
  "INSURANCE",
  "LEGAL",
  "MANUFACTURING",
  "NGO",
  "POLITICAL",
  "POSTAL",
  "PROFESSIONAL",
  "REAL_ESTATE",
  "RETAIL",
  "TECHNOLOGY",
  "TRANSPORTATION"
];
export const USECASES = [
  "AGENTS_FRANCHISES",
  "CARRIER_EXEMPT",
  "CHARITY",
  "CONVERSATIONAL",
  "EMERGENCY",
  "K12_EDUCATION",
  "LOW_VOLUME",
  "MIXED",
  "POLITICAL",
  "PROXY",
  "SOCIAL",
  "SOLE_PROPRIETOR",
  "SWEEPSTAKE",
  "TRIA"
];
export const SUB_USECASES = [
  "ACCOUNT_NOTIFICATION",
  "CUSTOMER_CARE",
  "DELIVERY_NOTIFICATION",
  "FRAUD_ALERT",
  "HIGHER_EDUCATION",
  "MARKETING",
  "POLLING_VOTING",
  "PUBLIC_SERVICE_ANNOUNCEMENT",
  "SECURITY_ALERT"
];
export const PhoneBrandFlows = Object.freeze({
  EXISTING: "EXISTING",
  NEW: "NEW"
});

export const replyStatuses = ["REMOVED", "PUBLISHED", "DRAFT"];

export const CUSTOMER_BULK_FIELD_OPTIONS = [
  { value: "status", label: "Status" },
  { value: "addResponderNotes", label: "Add Responder Instruction", permissions: [permissions.AGENCY_ADMIN] },
  {
    value: "removeResponderNotes",
    label: "Remove Responder Instruction by Name",
    placeholder: "Enter Note Title",
    permissions: [permissions.RESPONSE_MANAGER]
  },
  { value: "prependCustomerNotes", label: "Prepend Customer Notes" },
  { value: "replaceCustomerNotes", label: "Replace Customer Notes" },
  { value: "appendCustomerNotes", label: "Append Customer Notes" },
  { value: "addContactIds", label: "Add Contacts" },
  { value: "removeContactIds", label: "Remove Contacts" },
  { value: "addBrands", label: "Add Brands" },
  { value: "removeBrands", label: "Remove Brands" },
  { value: "addProducts", label: "Add Products" },
  { value: "removeProducts", label: "Remove Products" },
  { value: "updateHipaaCompliant", label: "Update HIPAA Status" },
  { value: "addLocationLabels", label: "Add Location Labels" },
  { value: "removeLocationLabels", label: "Remove Location Labels" },
  { value: "replaceTagSetId", label: "Update Tag Set" }
];

export const CONTACT_BULK_FIELD_OPTIONS = [
  { value: "status", label: "Status" },
  { value: "addCustomerIds", label: "Add Locations" },
  { value: "removeCustomerIds", label: "Remove Locations" }
];

export const REVIEW_BULK_FIELD_OPTIONS = [{ value: "status", label: "Status" }];

export const getLabelFromValue = memoize(
  (value, options) => options.find(option => option.value === value)?.label || ""
);

export const TAG_TYPES = {
  TOPIC: "Topic",
  PERSON: "Person"
};

export const YES_NO_OPTIONS = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];

export const reviewQueueDateSortOptions = [
  { value: "reviewDate,desc", label: "Review Date" },
  { value: "reviewDate,asc", label: "Review Date" },
  { value: "createdOn,desc", label: "Created On" },
  { value: "createdOn,asc", label: "Created On" },
  { value: "lastModifiedOn,desc", label: "Last Modified On" },
  { value: "lastModifiedOn,asc", label: "Last Modified On" }
];
