import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FormGroup, Input, Label } from "reactstrap";
import uniqueId from "lodash/uniqueId";
import isEmpty from "lodash/isEmpty";

// Select wrapper that formats events to look like standard html form events and
// adds some convenience around working with simple values
// simpleValue and multi are mutually exclusive
const HtmlSelectField = ({
  name,
  label,
  options,
  value,
  onChange,
  inline = false,
  className,
  groupClassName,
  ...props
}) => {
  const id = useMemo(() => `select${uniqueId()}`, []);
  const selectClassName = useMemo(() => (inline ? `d-inline ${className}` : className), [inline, className]);
  const _groupClassName = useMemo(() => (inline ? `d-inline ${groupClassName}` : groupClassName), [inline]);

  return (
    <FormGroup className={_groupClassName}>
      {label && <Label for={id}>{label}</Label>}
      <Input
        type="select"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={isEmpty(options)}
        className={selectClassName}
        {...props}
      >
        {options && options?.map(o => <option key={o}>{o}</option>)}
      </Input>
    </FormGroup>
  );
};

HtmlSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  multi: PropTypes.bool,
  inline: PropTypes.bool
};

export default HtmlSelectField;
