import styles from "./ResponderAiGeneratedResponse.module.scss";
import { useResponseBotReview } from "../../../hooks/data/responseBotHooks";
import classNames from "classnames";
import WWButton from "../../Buttons/WWButton";
import Paragraph from "../../Layout/Paragraph";
import React, { useCallback } from "react";
import TextSkeleton from "../../Skeleton/TextSkeleton/TextSkeleton";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import { useQueryClient } from "react-query";

export default function ResponderAiGeneratedResponse({ review, onUseReply = noop, ...props }) {
  const queryClient = useQueryClient();

  const { isFetching, data } = useResponseBotReview(review.id);
  const _onUseReply = useCallback(() => onUseReply(data), [data, onUseReply]);

  if (!isFetching && isEmpty(data)) {
    return (
      <div className={classNames(styles.container)} {...props}>
        <span className="d-flex align-items-center">
          <Paragraph className="mb-0" data-testid="error" content={"Suggestion not available"} />{" "}
          <WWButton
            size="sm"
            iconClass="fa fa-refresh"
            color="link"
            disabled={isFetching}
            onClick={() => queryClient.invalidateQueries(`/responseBot/review/${review.id}`)}
          />
        </span>
      </div>
    );
  }
  return (
    <div className={classNames(styles.container)} {...props}>
      <strong>Suggested Reply</strong>
      {isFetching ? (
        <p data-testid="skeleton" className={"w-100"}>
          <TextSkeleton rows={1} />
        </p>
      ) : (
        <Paragraph data-testid="suggestion" content={data} />
      )}
      <WWButton
        color="link"
        disabled={isFetching}
        className={styles.button}
        iconClass="fa fa-magic"
        onClick={_onUseReply}
      >
        {"Start with suggestion >"}
      </WWButton>
    </div>
  );
}
