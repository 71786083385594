import React, { useState } from "react";
import { Button } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import Select from "react-select";
import logger from "../../../util/logger";
import debounce from "lodash/debounce";
import InputField from "../../../components/Form/InputField";
import isEmpty from "lodash/isEmpty";
import truncate from "lodash/truncate";
import * as boulevardApi from "../../../api/boulevardApi";
import CopyButton from "../../../components/Buttons/CopyButton";

export default class Boulevard {
  field() {
    return "boulevardInfo";
  }

  add() {
    const AddBoulevard = ({ customer, onSave }) => {
      const [selectedLocation, setSelectedLocation] = useState();
      const [locations, setLocations] = useState();
      const [businessId, setBusinessId] = useState();

      const loadBoulevardLocationOptions = (boulevardInfo, filter = "") => {
        boulevardApi
          .listLocations(boulevardInfo, filter)
          .then(res => {
            setLocations(
              res.data.map(l => {
                return {
                  label: `${l.name + ", " + l.address}`,
                  value: { ...boulevardInfo, locationId: l.locationId, locationName: l.name }
                };
              })
            );
          })
          .catch(err => logger.error(err));
      };

      return (
        <>
          <InputField
            type="text"
            label="Business ID"
            placeholder="Business ID"
            onChange={e => setBusinessId(e.target.value)}
            value={businessId}
            inline={false}
          />
          <FormField label="Location">
            <Select
              className="w-100"
              isDisabled={isEmpty(businessId)}
              options={locations}
              placeholder="Type to see locations"
              onChange={l => setSelectedLocation(l)}
              onInputChange={debounce(input => {
                if (!isEmpty(businessId) && input !== "") {
                  loadBoulevardLocationOptions({ businessId }, input);
                }
              }, 250)}
              value={selectedLocation}
            />
          </FormField>
          <FormField label=" ">
            <Button
              disabled={!selectedLocation}
              color="primary"
              onClick={() => {
                onSave({
                  ...selectedLocation.value,
                  source: "BOULEVARD"
                });
              }}
            >
              Link
            </Button>
          </FormField>
        </>
      );
    };
    return AddBoulevard;
  }

  edit() {
    const EditBoulevard = ({ info, customer }) => {
      return (
        <>
          <FormField label="Business ID">
            <>
              <span className="text-truncate w-auto d-inline-block">{info.businessId}</span>
              <CopyButton className="ms-1" text={info.businessId} />
            </>
          </FormField>
          <FormField label="Location (Location ID)">
            <>
              <span className="text-truncate w-auto d-inline-block">
                {info.locationName + " (" + truncate(info.locationId) + ")"}
              </span>
              <CopyButton className="ms-1" text={info.locationId} />
            </>
          </FormField>
          <FormField label="Verified">
            <div className="text-nowrap">
              {info.verified && <i class="fa fa-check" aria-hidden="true"></i>}
              {!info.verified && <i class="fa fa-times" aria-hidden="true"></i>}
            </div>
          </FormField>
        </>
      );
    };
    return EditBoulevard;
  }
}
