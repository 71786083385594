import React from "react";
import { Button, InputGroup } from "reactstrap";
import FormField from "../../../components/Form/FormField";
/*
 * Site info admin panel for site info objects which just need a link button
 */
export default class SimpleLink {
  constructor(field, source) {
    this.fieldName = field;
    this.source = source;

    this.field = this.field.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
  }

  field() {
    return this.fieldName;
  }

  add() {
    const AddLink = ({ onSave }) => {
      return (
        <InputGroup>
          <Button
            color="primary"
            onClick={() =>
              onSave({
                source: this.source
              })
            }
          >
            Link
          </Button>
        </InputGroup>
      );
    };

    return AddLink;
  }

  edit() {
    const EditLink = () => (
      <>
        <FormField label="Active">
          <div className="text-nowrap">
            <i class="fa fa-check" aria-hidden="true"></i>
          </div>
        </FormField>
      </>
    );
    return EditLink;
  }
}
