import axios from "axios";
import config from "../config";

const baseUri = config.api + "/posts";

export function loadPosts(filter = null, page = 0, size = 25) {
  return axios.get(baseUri, {
    params: {
      size: size,
      page: page,
      sort: "lastModifiedOn,desc",
      ...filter
    }
  });
}

export function loadPost(postId) {
  return axios.get(baseUri + "/" + postId);
}

export function calculateReach(customerIds = [], shareTags, shareTargets) {
  const url = baseUri + "/calculateReach";
  return axios.post(url, shareTargets, {
    params: {
      customerIds: customerIds,
      shareTags: shareTags
    }
  });
}

export function primeAttachment(postId, type = "PHOTO") {
  return axios.post(baseUri + "/" + postId + "/attachments", {
    type
  });
}

export function putAttachment(postId, uploadUrl, type, file, progress) {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = event => {
      const rawImage = event.target.result;
      return axios
        .put(uploadUrl, rawImage, {
          headers: {
            "Content-Type": type
          },
          onUploadProgress: progress
        })
        .then(resolve);
    };
  });
}

export function deleteAttachment(postId, attachmentId) {
  return axios.delete(`${baseUri}/${postId}/attachments/${attachmentId}`);
}

export function savePost(post) {
  const entity = Object.assign({}, post);

  return post.id ? axios.patch(baseUri + "/" + post.id, entity) : axios.post(baseUri, entity);
}

export function deletePost(id) {
  return axios.delete(config.api + "/posts/" + id);
}

export function loadPostShares(postId) {
  return axios.get(`${baseUri}/${postId}/shares`);
}

export function loadPossibleDestinations(postId) {
  if (postId === null) {
    postId = "new";
  }
  return axios.get(`${baseUri}/${postId}/possibleDestinations`);
}

export function loadFailedPosts(postId) {
  return axios.get(`${baseUri}/${postId}/failedPosts`);
}

export function renderTemplate(customerId, templateString) {
  return axios.post(`${baseUri}/${customerId}/render`, templateString, {
    headers: {
      "Content-type": "text/plain"
    }
  });
}
