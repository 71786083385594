/**
 * A save prompt that handles catching when the user tries to navigate away without saving.
 */
import React, { useEffect, useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Toast, ToastBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useConfirmationDialog } from "../Modals/confirmation.modal";
import { autoclose } from "../Modals";
import { useNavigationPrompt } from "hooks/routerHooks";

import "./SaveBar.scss";

const UNSAVED_PROMPT = "Unsaved changes! Click Cancel to go back and save your changes.";

export default function SaveBar({
  withCancel = true,
  prompt = "Save changes?",
  onSave,
  withSaveAndClose = true,
  onCancel,
  confirmationPrompt,
  hideCondition,
  disableSave
}) {
  const [unsavedConfirmation, setUnsavedConfirmation] = useState(false);
  const confirm = useConfirmationDialog();
  const unloadHandler = event => {
    event.preventDefault();
    // Chrome requires returnValue to be set
    event.returnValue = UNSAVED_PROMPT;
  };
  const history = useHistory();
  const cancel = useMemo(() => (onCancel ? onCancel : () => history.goBack()), [onCancel]);

  const unload = useCallback((callback, shouldClose) => {
    setUnsavedConfirmation(true);
    window.removeEventListener("beforeunload", unloadHandler);
    if (callback) {
      callback(shouldClose);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      window.addEventListener("beforeunload", unloadHandler);
      return () => unload();
    }
  }, [unload]);

  useNavigationPrompt({
    when: !unsavedConfirmation && !hideCondition,
    message: UNSAVED_PROMPT
  });

  const getOnSaveHandler = useMemo(
    () => callbackParam => () =>
      confirmationPrompt
        ? autoclose(
            confirm({
              title: "Are you sure you want to save?",
              body: confirmationPrompt
            })
          ).then(resolution => resolution && unload(onSave, callbackParam))
        : unload(onSave, callbackParam),
    [onSave, unload, confirm, confirmationPrompt]
  );

  return (
    <Toast id="save-bar">
      <ToastBody className="d-flex justify-content-center flex-wrap">
        {onSave && (
          <Button disabled={disableSave} color="success" onClick={getOnSaveHandler(false)}>
            <strong>Save</strong>
          </Button>
        )}
        {withSaveAndClose && (
          <Button color="success" outline className="ms-2" disabled={disableSave} onClick={getOnSaveHandler(true)}>
            <strong>Save and close</strong>
          </Button>
        )}
        {withCancel && (
          <Button className="ms-2" color="danger" onClick={() => unload(cancel, true)}>
            Cancel
          </Button>
        )}
        <span className="ms-2 fw-bold align-self-center text-center">{prompt}</span>
      </ToastBody>
    </Toast>
  );
}

SaveBar.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  prompt: PropTypes.string,
  withCancel: PropTypes.bool,
  confirmationPrompt: PropTypes.string
};
