import _, { noop } from "lodash";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Progress,
  Row,
  UncontrolledButtonDropdown
} from "reactstrap";
import "../../../node_modules/video-react/dist/video-react.css";
import * as postApi from "../../api/postApi";
import * as shareApi from "../../api/shareApi";
import * as tagSetApi from "../../api/tagSetApi";
import withAuthorization from "../../components/Auth/Authorization";
import { permissions } from "../../components/Auth/permissions";
import { Card, CardBody, CardHeader, CardTitle } from "../../components/Card";
// import moment from 'moment'
import CustomerSiteTable from "../../components/Customer/CustomerSiteTable";
import DateTimeSelector from "../../components/DateTime/DateTimeSelector";
import { FileUploadButton } from "../../components/Form/FileUploadButton";
import HorizontalSelectField from "../../components/Form/HorizontalSelectField";
import Toggle from "../../components/Form/Toggle";
import withConfirmation from "../../components/Form/withConfirmation";
import WWButton from "../../components/Buttons/WWButton";
import AjaxLoader from "../../components/Misc/AjaxLoader";
import { EmojiPicker } from "../../components/Misc/EmojiPicker";
import HelpIcon from "../../components/Misc/HelpIcon";
import { Messages } from "../../components/Misc/StatusMessages";
import { errorCaughtNotifier, withLocalNotifications } from "../../components/Notifications/notification";
import PostAttachment from "../../components/Post/PostAttachment";
import DirectEmailPreview from "../../components/PostExamples/DirectEmailPreview";
import DirectSmsPreview from "../../components/PostExamples/DirectSmsPreview";
import FacebookLinkPreview from "../../components/PostExamples/FacebookLinkPreview";
import FacebookPostExample from "../../components/PostExamples/FacebookPostExample";
import GooglePostExample from "../../components/PostExamples/GooglePostExample";
import InstagramPostExample from "../../components/PostExamples/InstagramPostExample";
import LinkedInPostExample from "../../components/PostExamples/LinkedInPostExample";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../redux/actions/ajaxStatusActions";
import FailedPostsTable from "./FailedPostsTable";
import PostShares from "./PostShares";
import { DEBOUNCE_DELAY_DEFAULT } from "../../constants/delays";

const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

/**
 * Supported sites for this component.
 * @type {Array} collection of siteid and related source values.
 */
const allSites = [
  { siteInfo: "facebookInfo", source: "FACEBOOK" },
  { siteInfo: "instagramInfo", source: "INSTAGRAM" },
  { siteInfo: "gmbInfo", source: "GOOGLE" },
  { siteInfo: "linkedInInfo", source: "LINKEDIN" },
  { siteInfo: "directMessageInfo", source: "DIRECT_MESSAGE" }
];

class EditPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // General state
      isDirty: false,
      caret: 0,

      // validations warnings & disabled options
      invalidDestinations: false,
      invalidDestinationsNotificationCB: noop,
      disableCompose: true,
      disableSave: true,
      disablePublish: true,

      // Primary entities: customers for locations, the post object
      customers: [],
      post: {
        id: null,
        content: "",
        rendered: "",
        awaitingRendering: false,
        share: false,
        shareTags: [],
        audience: [],
        type: "POST",
        status: "DRAFT",
        destinations: [],
        attachments: [],
        scheduledPublishDate: null,
        shareTargets: []
      },

      uploadProgress: 0,
      reach: 0,
      displayPreviews: true,
      shareTagOptions: [],
      shareTargetOptions: [],
      failedPosts: []
    };

    this.savePost = this.savePost.bind(this);
    this.deletePostHandler = this.deletePostHandler.bind(this);
    this.updatePostField = this.updatePostField.bind(this);
    this.loadCustomers = this.loadCustomers.bind(this);
    this.primeAttachment = this.primeAttachment.bind(this);
    this.hasDestination = this.hasDestination.bind(this);
    this.initAllCustomerSites = this.initAllCustomerSites.bind(this);
    this.setSiteHandler = this.setSiteHandler.bind(this);
    this.setSite = this.setSite.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.validateDestinations = this.validateDestinations.bind(this);
    this.createCustomerDestinations = this.createCustomerDestinations.bind(this);
    this.redirectToList = this.redirectToList.bind(this);
    this.redirectToEditById = this.redirectToEditById.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.insertEmoji = this.insertEmoji.bind(this);
    this.updateAttachmentSources = this.updateAttachmentSources.bind(this);
    this.calculateReach = this.calculateReach.bind(this);
    this.calculateReach = _.debounce(this.calculateReach, DEBOUNCE_DELAY_DEFAULT);
    this.parseContent = this.parseContent.bind(this);
    this.calculateShareTagOptions = this.calculateShareTagOptions.bind(this);
    this.calculateShareTargetOptions = this.calculateShareTargetOptions.bind(this);
    this.calculateShareTargetOptions = _.debounce(this.calculateShareTargetOptions, DEBOUNCE_DELAY_DEFAULT);
    this.isScheduled = this.isScheduled.bind(this);
  }

  debouncedRenderRequest = _.debounce(
    (customerId, source) =>
      postApi
        .renderTemplate(customerId, source)
        .then(({ data }) =>
          this.setState(prev => ({
            ...prev,
            post: {
              ...prev.post,
              rendered: data,
              awaitingRendering: false
            }
          }))
        )
        .catch(({ response }) =>
          this.props.notify({
            title: "Preview error",
            icon: "danger",
            body: (
              <Messages
                messages={{
                  message: "Failed rendering previews. Check place holder variables.",
                  details: [...response?.data.errors]
                }}
              />
            )
          })
        ),
    1000
  );

  componentDidMount() {
    let postId = this.props.match.params.id;
    if (postId) {
      this.loadPost(postId).then(() => {
        this.loadCustomers();
      });
      this.loadFailedPosts(postId);
    } else {
      this.loadCustomers();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const post = this.state.post;
    const prevPost = prevState.post;
    if (post && prevPost) {
      if (post.content !== prevPost.content) {
        this.parseContent();
      }

      if (
        _.size(post.destinations) !== _.size(prevPost.destinations) ||
        _.size(post.shareTags) !== _.size(prevPost.shareTags) ||
        _.size(post.shareTargets) !== _.size(prevPost.shareTargets) ||
        post.share !== prevPost.share
      ) {
        this.calculateShareTagOptions();
        this.calculateShareTargetOptions();
        this.calculateReach();
      }
    }

    if (this.state.invalidDestinations !== prevState.invalidDestinations) {
      if (this.state.invalidDestinations) {
        this.props
          .notify({
            title: "Error",
            icon: "danger",
            body: "Enter at least one location and site",
            closable: false
          })
          .then(invalidDestinationsNotificationCB => this.setState({ invalidDestinationsNotificationCB }));
      } else {
        this.state.invalidDestinationsNotificationCB();
      }
    }
  }

  calculateShareTagOptions() {
    if (!this.state.post.share) {
      this.setState({ audienceOptions: [] });
    } else {
      tagSetApi.loadDistinctAudienceTags().then(res => {
        this.setState({ shareTagOptions: res.data });
      });
    }
  }

  calculateShareTargetOptions(input = "") {
    if (!this.state.post.share) {
      this.setState({ shareTargetOptions: [] });
    } else {
      shareApi.getShareTargetOptions(input, this.state.post.destinations).then(res => {
        this.setState({ shareTargetOptions: res.data });
      });
    }
  }

  calculateReach() {
    if (!this.state.post.share) {
      this.setState({ reach: 0 });
    } else {
      postApi
        .calculateReach(
          _.map(this.state.post.destinations, destination => {
            return destination.customerId;
          }),
          this.state.post.shareTags,
          this.state.post.shareTargets ? _.map(this.state.post.shareTargets, st => st.id) : []
        )
        .then(res => {
          this.setState({ reach: res.data });
        });
    }
  }

  /**
   * Restore post data from saved.
   * @param  {string} id Post Id
   * @return {Promise}   Promise returning post data.
   */
  loadPost(id) {
    this.props.dispatch(beginAjaxCall());
    return postApi
      .loadPost(id)
      .then(response => {
        let post = response.data;
        post.attachments = _.map(post.attachments, attachment => {
          if (attachment.sources) {
            let sources = attachment.sources.split(",");
            attachment.sources = sources;
            return attachment;
          } else {
            return attachment;
          }
        });
        this.setState({ post });

        this.props.dispatch(endAjaxCall());
      })
      .catch(error => {
        this.props.dispatch(ajaxCallError());
      });
  }

  /**
   * Determine if destination selections are valid, set state to trigger warnings.
   */
  validateDestinations() {
    const isValid = this.hasDestination();
    this.setState({
      disableCompose: !isValid,
      disableSave: !isValid,
      disablePublish: !isValid,
      invalidDestinations: !isValid
    });
  }

  /**
   * Put confirmation up before window navigation to prevent loss of work.
   * @param Event event
   */
  windowUnloadHandler(event) {
    event.preventDefault();
    // Chrome requires returnValue to be set
    event.returnValue = "";
  }

  /**
   * Trigger to indicate this post is in progress and changes have not been saved.
   */
  setDirty(isDirty) {
    // Don't trigger warnings if doing a hot reload update.
    if (!module.hot) {
      this.setState(
        {
          isDirty: true
        },
        () => {
          // Clean up listeners.
          window.removeEventListener("beforeunload", this.WindowUnloadHandler);
          // Add listener if set.
          if (isDirty) {
            window.addEventListener("beforeunload", this.WindowUnloadHandler);
          }
        }
      );
    }
  }

  /**
   * Load the locations that the user can associate with the post.
   * @return {array} collection of customers to populate select box
   */
  loadCustomers() {
    this.props.dispatch(beginAjaxCall());
    return postApi
      .loadPossibleDestinations(this.state.post.id)
      .then(response => {
        this.initAllCustomerSites(response.data);
        this.props.dispatch(endAjaxCall());
        return response.data;
      })
      .catch(error => {
        this.props.dispatch(ajaxCallError());
        return [];
      });
  }

  /**
   * Load the failed posts that were generated from this WWPost
   */
  loadFailedPosts(postId) {
    this.props.dispatch(beginAjaxCall());
    return postApi
      .loadFailedPosts(postId)
      .then(response => {
        this.setState({ failedPosts: response.data });
        this.props.dispatch(endAjaxCall());
        return response.data;
      })
      .catch(error => {
        this.props.dispatch(ajaxCallError());
        return [];
      });
  }

  /**
   * Set the attachments API temporary endpoint for uploads.
   * @return {Promise} promise with the primed attachment state values.
   *                           {type, target, uploadUrl}
   */
  primeAttachment(type = "PHOTO") {
    const {
      post: { id: postId }
    } = this.state;

    // Helper to save the returned attachment settings to component namespace.
    const saveAttachmentSettings = apiResponse => {
      const {
        data: { id, type, target, uploadUrl }
      } = apiResponse;
      return { id, type, target, uploadUrl };
    };

    if (postId) {
      return postApi
        .primeAttachment(postId, type)
        .then(response => saveAttachmentSettings(response))
        .catch(errorCaughtNotifier(this.props.notify));
    } else if (!postId) {
      // We will need to save post to get attachment configuration values.
      return this.savePost()
        .then(post => postApi.primeAttachment(post.id, type))
        .then(response => saveAttachmentSettings(response))
        .catch(errorCaughtNotifier(this.props.notify));
    }
  }

  /**
   * Utility to get global sites configuration.
   *
   * @return {array} sites from global value
   */
  getAllSites() {
    return allSites;
  }

  /**
   * Set up the customer sites UI state, which consists of siteInfo name with a true or false value.
   * If the value is missing then that toggle is not available.
   *
   * @param  {array} customers
   * @param  {array} selectedSites  Site selections.
   * @return {array}                Customers with initial sites property.
   */
  initAllCustomerSites(customers = this.state.customers, selectedSites = this.getAllSites()) {
    const { post } = this.state;
    let postDestinations = [];

    const updatedCustomers = customers.map(currCustomer => {
      const currentCustomerWithSites = currCustomer;

      const customerSites = selectedSites.reduce((sites, currentSite) => {
        if (currCustomer[currentSite.siteInfo].length > 0) {
          const customerDestinations = this.createCustomerDestinations(currCustomer, currentSite.siteInfo);

          if (!post) {
            // Build up new post destinations with all enabled.
            postDestinations = postDestinations.concat(customerDestinations);
            sites[currentSite.siteInfo] = true;
          } else {
            postDestinations = post.destinations;
            // Or if existing post, determine whether given source toggle should be enabled.
            sites[currentSite.siteInfo] = customerDestinations.reduce(
              (enabledSite, customerDestination) => {
                if (currentSite.source === customerDestination.source) {
                  return (
                    enabledSite ||
                    post.destinations.filter(destination => destination.id === customerDestination.id).length > 0
                  );
                }

                return enabledSite;
              },

              false
            );
          }
        }

        return sites;
      }, {});

      currentCustomerWithSites.sites = customerSites;

      return currentCustomerWithSites;
    });

    const updatedPost = Object.assign({}, this.state.post, {
      destinations: postDestinations
    });

    this.setState({ customers: updatedCustomers, post: updatedPost }, this.validateDestinations);
  }

  /**
   * Set the customer siteId selection state
   * @param {string}  customerId
   * @param {object}  selectedSite entry following the global allSites structure (siteInfo, source)
   * @param {boolean} enableSite
   */
  setSite(customerId, selectedSite, enableSite) {
    const { customers, post } = this.state;
    const { siteInfo, source } = selectedSite;
    const { destinations } = post;
    let addDestinations = [];
    let removeDestinations = [];
    let updatedDestinations = [];

    // Process customer site UI state and in the same loop the related destinations.
    const updatedCustomers = customers.map(customer => {
      const customerDestinations = this.createCustomerDestinations(customer, siteInfo);

      if (siteInfo in customer.sites) {
        // All customers for global site change, or just currently modified customer.
        if (!customerId || customerId === customer.id) {
          // Set enabled/disabled site setting for current customer.
          customer.sites[siteInfo] = enableSite;

          // Store the modified destinations.
          if (enableSite) {
            addDestinations = addDestinations.concat(customerDestinations);
          } else {
            removeDestinations = removeDestinations.concat(customerDestinations);
          }
        }
      }
      return customer;
    });

    // Process the post destinations based on what was detected above.
    if (removeDestinations.length > 0) {
      // Filter out the relevant post destinations, before adding back the enabled ones.
      updatedDestinations = destinations.filter(destination => {
        if (!customerId) {
          // Global selection case: simplified removal using just source.
          return destination.source !== source || enableSite;
        }

        // No destinations to remove.
        if (removeDestinations.length === 0) {
          return true;
        }

        // Otherwise remove destinations as identified in customer loop above.
        return !removeDestinations.find(removeDestination => removeDestination.id === destination.id);
      });
    }

    // Then add in the new destinations as identified in customer loop.
    if (addDestinations.length > 0) {
      updatedDestinations = destinations.concat(addDestinations);
    }

    const updatedPost = Object.assign({}, this.state.post, {
      destinations: updatedDestinations
    });

    this.setState({ customers: updatedCustomers, post: updatedPost }, () => {
      this.validateDestinations();
      this.setDirty(true);
    });
  }

  /**
   * Add an attachment to the post object state.
   * @param {object} newAttachment Attachment to add
   */
  addAttachment(newAttachment) {
    newAttachment.sources = ["FACEBOOK", "INSTAGRAM", "GOOGLE", "LINKEDIN"];
    const { post } = this.state;
    const { attachments } = post;
    const existing = _.filter(attachments, a => a.type !== "LINK");
    const updatedAttachments = [newAttachment, ...existing];

    const updatedPost = Object.assign({}, this.state.post, {
      attachments: updatedAttachments
    });

    this.setState({ post: updatedPost });
  }

  /**
   * Remove an attachment from the post object state.
   * @param {string} attachmentTarget attachment id to remove
   */
  removeAttachment(attachmentTarget) {
    const { post } = this.state;
    const attachments = [...post.attachments];
    const removedAttachment = _.remove(attachments, attachment => attachment.target === attachmentTarget)[0];
    const updatePost = () => {
      const updatedPost = Object.assign({}, this.state.post, {
        attachments
      });
      this.setState({ post: updatedPost });
    };

    if (removedAttachment.id) {
      postApi
        .deleteAttachment(post.id, removedAttachment.id)
        .then(updatePost)
        .catch(() => this.props.notify({ body: "Removing image failed.", icon: "danger" }));
    } else {
      updatePost();
    }
  }

  updateAttachmentSources(attachmentId, source, checked) {
    const attachments = [...this.state.post.attachments];

    for (let attachment of attachments) {
      if (attachment.id === attachmentId) {
        if (checked === false) {
          attachment.sources = _.remove(attachment.sources, s => {
            return s !== source;
          });
        } else {
          if (!_.isArray(attachment.sources)) {
            attachment.sources = [];
          }
          attachment.sources.push(source);
        }
      }
    }

    const post = Object.assign({}, this.state.post, { attachments });
    this.setState({ post });
  }

  /**
   * Handle location toggle event
   * @param {Event} event
   */
  setSiteHandler(event) {
    const eventSegments = event.target.name.split(":");
    const [source, customerId] = eventSegments;
    const selectedSite = {
      siteInfo: event.target.value,
      source
    };

    if ("All" === customerId) {
      this.setSite(null, selectedSite, event.target.checked);
    } else {
      this.setSite(customerId, selectedSite, event.target.checked);
    }
  }

  /**
   * Make the post destinations from customer's site info entries.
   * @param {object} customer
   * @param {string} siteInfo
   *
   * @return {array} collection of destinations (source + siteid)
   */
  createCustomerDestinations(customer, siteInfo) {
    return customer[siteInfo].map(site => this.buildNormalDestination(site, customer));
  }

  /**
   * Normalize to a thin minimal destination that the postApi expects.
   * @param  {object} site Full site from customer
   * @return {object}      Thin site object
   */
  buildNormalDestination(site, customer) {
    const { id, source } = site;
    return {
      id,
      source,
      companyName: customer.companyName,
      customerId: customer.id,
      timezone: customer.timezone
    };
  }

  /**
   * Update post object based on input field changes.
   * @param  {Event} event input event.
   */
  updatePostField(event) {
    const field = event.target.name;
    let post = Object.assign({}, this.state.post);
    let isValid = false;

    const setUploadProgress = function(progress) {
      let uploadProgress = parseInt(Math.round((progress.loaded / progress.total) * 100));
      this.setState({ uploadProgress });
    }.bind(this);
    // Updating attachments is a special case which must handle dynamic async server configuration.
    if ("PHOTO" === field || "VIDEO" === field) {
      let file = event.target.files[0];
      this.props.dispatch(beginAjaxCall());
      this.primeAttachment(field)
        .then(({ id, type, target, uploadUrl }) => {
          const { post } = this.state;
          return postApi
            .putAttachment(post.id, uploadUrl, type, file, setUploadProgress)
            .then(() => ({ id, type, target }));
        })
        .then(({ id, type, target }) => {
          const { post } = this.state;
          this.addAttachment({ id, type, target });
          this.props.dispatch(endAjaxCall());
          this.redirectToEditById(post.id);
        });
      return;
    } else {
      _.set(post, field, event.target.value);

      if ("share" === field && event.target.value === false) {
        _.set(post, "shareTags", []);
        _.set(post, "shareTargets", []);
      }
    }

    // What are the minimum values for a saved post? At least this...
    if (post.content || _.size(post.shareTags) > 0 || _.size(post.shareTargets) > 0 || _.size(post.attachments) > 0) {
      isValid = true;
    }

    this.setState(
      {
        post: {
          ...post,
          awaitingRendering: true,
          rendered: post.content
        },
        disableSave: !isValid,
        disablePublish: !isValid
      },
      () => {
        this.setDirty(true);
      }
    );

    if (isValid) {
      this.debouncedRenderRequest(this.state.customers[0] && this.state.customers[0].id, post.content);
    }
  }

  insertEmoji(emoji) {
    // TODO: Test on different devices. https://github.com/iamcal/js-emoji
    const updatedPost = Object.assign({}, this.state.post);

    updatedPost.content += emoji.native;
    this.setState({ post: updatedPost });
  }

  /**
   * Save Post
   * @return {Promise} postApi promise of saved post response from postApi.
   */
  savePost() {
    const { post } = this.state;
    if (_.size(post.attachments) > 0) {
      post.attachments = _.map(post.attachments, attachment => {
        if (attachment.sources) {
          let sources = attachment.sources.join(",");
          attachment.sources = sources;
          return attachment;
        } else {
          return attachment;
        }
      });
    }

    if (_.size(post.shareTargets) > 0) {
      post.shareContactIds = _.map(post.shareTargets, shareTarget => {
        return shareTarget.id;
      });
    }

    this.props.dispatch(beginAjaxCall());
    return postApi
      .savePost(post)
      .then(response => {
        this.props.dispatch(endAjaxCall());
        const post = response.data;
        this.setDirty(false);
        this.setState({ post: { ...this.state.post, ...post } });
        return post;
      })
      .catch(err => {
        this.props.dispatch(ajaxCallError());
        errorCaughtNotifier(this.props.notify)(err);
      });
  }

  setStatus(status, redirect = false, msg = null) {
    let { post } = this.state;
    post.status = status;

    this.setState({ post }, () => {
      this.savePost().then(p => {
        if (_.size(p.attachments) > 0) {
          p.attachments = _.map(p.attachments, attachment => {
            if (attachment.sources) {
              let sources = attachment.sources.split(",");
              attachment.sources = sources;
              return attachment;
            } else {
              return attachment;
            }
          });
        }

        if (msg) {
          this.props.notify({ body: msg, timeout: 4000 });
        }

        if (redirect) {
          this.redirectToList();
        } else {
          this.redirectToEditById(p.id);
        }

        let postId = this.props.match.params.id;
        if (postId) {
          this.loadPost(postId).then(() => {
            this.loadCustomers();
          });
        } else {
          this.loadCustomers();
        }
      });
    });
  }

  /**
   * Handle delete post button click. Redirect back to list.
   * @param  {Event} event from Publish button
   */
  deletePostHandler(event) {
    const { post } = this.state;

    if (post.id) {
      postApi.deletePost(post.id).then(this.redirectToList);
    }
  }

  /**
   * On Save/Publish/Cancel return to the Posts list.
   */
  redirectToList() {
    this.props.history.push("/posts");
  }

  /**
   * On incremental save from new, redirect to the Post Edit.
   * TODO: Temporarily disabled to help debugging.
   */
  redirectToEditById(id) {
    this.props.history.push(`/posts/edit/${id}`);
  }

  /**
   * Helper for valid destinations.
   * @return {Boolean} Does the post have at least one destination.
   */
  hasDestination() {
    const { post } = this.state;
    return _.size(post.destinations) > 0;
  }

  parseContent() {
    const { content, attachments } = this.state.post;
    let hasPhotos = false;
    if (_.size(attachments) > 0) {
      hasPhotos = _.findIndex(attachments, a => a.type === "PHOTO") >= 0;
    }
    if (!hasPhotos && content) {
      const match = content.match(URL_REGEX);
      if (match) {
        const link = { target: match[1], type: "LINK" };
        const existing = _.find(attachments, a => a.type === "LINK");
        if (existing) {
          if (existing.target !== link.target) {
            const filteredAttachments = _.filter(attachments, a => a.type !== "LINK");
            const update = Object.assign({}, this.state.post, {
              attachments: [...filteredAttachments, link]
            });
            this.setState({ post: update });
          }
        } else {
          this.addAttachment(link);
        }
      }
    }
  }

  isScheduled() {
    return _.isNil(this.state.post.scheduledPublishDate) === false && this.state.post.destinations.length > 0;
  }

  /**
   *
   * @param {number} caret
   * @returns {Promise<void>} promise on state updated
   */
  updatePostCaretPosition(caret) {
    return new Promise(resolve =>
      this.setState(
        state => ({
          ...state,
          caret
        }),
        resolve
      )
    );
  }

  /**
   *
   * @param {string} placeholderName
   * @returns {Promise<void>}
   */
  insertPlaceholder(placeholderName) {
    return new Promise(resolve =>
      this.setState(
        state => ({
          ...state,
          post: {
            ...state.post,
            content: `${state.post.content.slice(0, state.caret)}[($\{${placeholderName}})]${state.post.content.slice(
              state.caret
            )}`
          }
        }),
        resolve
      )
    );
  }

  getSchedulePublishDate() {
    return this.state.post.scheduledPublishDate
      ? moment(this.state.post.scheduledPublishDate)
      : moment().add(1, "hour");
  }

  render() {
    const { post, customers, disableCompose, invalidDestinations } = this.state;

    const images = _.filter(post.attachments, a => a.type === "PHOTO" || a.type === "VIDEO");
    const links = _.filter(post.attachments, a => a.type === "LINK");

    const ButtonWConfirm = withConfirmation(Button);

    const { loading } = this.props;

    const composeClassNames = `post-editor__section post-editor__compose     ${
      disableCompose ? "disable bg-light" : ""
    }`;
    const attachmentsClassNames = `post-editor__section post-editor__attachments ${
      disableCompose ? "disable bg-light" : ""
    }`;
    const locationsClassNames = `post-editor__section post-editor__locations   ${
      invalidDestinations ? "border-danger" : ""
    }`;
    const sharingClassNames = `post-editor__section post-editor__sharing     ${
      disableCompose ? "disable bg-light" : ""
    }`;

    return (
      <div className="post-editor ps-4 pt-4 pe-4">
        <Row>
          <Col xs={{ size: 12, order: 2 }} md={{ size: 8, order: 1 }}>
            <Row>
              <Col>
                <Card className={locationsClassNames}>
                  <CardHeader className="mt-3">
                    <CardTitle>
                      Locations
                      <br />
                      <small className="text-muted">Choose locations and networks for your post.</small>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <CustomerSiteTable
                          post={post}
                          disabled={this.state.post.status === "POSTED" ? true : false}
                          customers={customers}
                          onChange={this.setSiteHandler}
                        />
                        <div className="text-center">
                          <AjaxLoader loading={loading} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <div className={composeClassNames}>
                    <CardHeader className="mt-3">
                      <CardTitle>Compose</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs="12">
                          {loading && (
                            <div className="text-center">
                              <AjaxLoader loading={loading} />
                            </div>
                          )}
                          {/* TODO: Update to use TextEditor */}
                          {!loading && (
                            <Input
                              type="textarea"
                              id="compose-content"
                              name="content"
                              rows={5}
                              disabled={disableCompose}
                              placeholder="Enter Content..."
                              onChange={this.updatePostField}
                              onKeyUp={e => this.updatePostCaretPosition(e.target.selectionStart)}
                              onClick={e => this.updatePostCaretPosition(e.target.selectionStart)}
                              value={post.content}
                            />
                          )}
                        </Col>
                        <Col>
                          <EmojiPicker onPick={this.insertEmoji} />
                          <UncontrolledButtonDropdown className="mt-1 me-1 pull-right">
                            <DropdownToggle color="primary" size="sm" caret>
                              <i className="icon-plus" /> Variables
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  this.insertPlaceholder("location.companyName").then(() =>
                                    this.debouncedRenderRequest(
                                      this.state.customers[0] && this.state.customers[0].id,
                                      this.state.post.content
                                    )
                                  )
                                }
                              >
                                Company name
                              </DropdownItem>
                              {_.intersection(
                                ...this.state.customers
                                  .filter(
                                    ({ id }) =>
                                      _.uniq(this.state.post.destinations.map(({ customerId }) => customerId)).indexOf(
                                        id
                                      ) >= 0
                                  )
                                  .map(v => (v.customFields ? Object.keys(v.customFields) : []))
                              ).map(v => (
                                <DropdownItem
                                  key={v}
                                  onClick={() =>
                                    this.insertPlaceholder(`location.customFields['${v}']`).then(() =>
                                      this.debouncedRenderRequest(
                                        this.state.customers[0] && this.state.customers[0].id,
                                        this.state.post.content
                                      )
                                    )
                                  }
                                >
                                  {v}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                  <div className={attachmentsClassNames}>
                    <CardBody style={{ paddingTop: 0 }}>
                      {this.state.uploadProgress < 100 && this.state.uploadProgress > 0 && (
                        <Progress animated className="mb-3" color="warning" value={this.state.uploadProgress}>
                          {this.state.uploadProgress}%
                        </Progress>
                      )}
                      {_.map(links, link => (
                        <Row key={link}>
                          <Col>
                            <div style={{ maxWidth: "500px" }}>
                              <FacebookLinkPreview
                                url={link.target}
                                onRemove={() => this.removeAttachment(link.target)}
                              />
                            </div>
                          </Col>
                        </Row>
                      ))}
                      <Row style={{ paddingBottom: "20px" }}>
                        {_.size(images) > 1 && (
                          <Alert color="warning" style={{ width: "100%" }} className="text-center">
                            For Google Business Profile only the first image will be shown.
                          </Alert>
                        )}
                        {images.map(attachment => {
                          return (
                            <PostAttachment
                              key={attachment.target}
                              attachment={attachment}
                              updateAttachmentSources={this.updateAttachmentSources}
                              removeAttachment={this.removeAttachment}
                            ></PostAttachment>
                          );
                        })}
                      </Row>
                      <Row>
                        <Col className="text-center">
                          <FileUploadButton name="PHOTO" accept="image/*" onChange={this.updatePostField}>
                            <div style={{ fontSize: "1.25em" }}>
                              <i className="fa fa-image" />
                              <br />
                              {"Add Image"}
                            </div>
                          </FileUploadButton>
                        </Col>
                        <Col className="text-center">
                          <FileUploadButton name="VIDEO" accept="video/*" onChange={this.updatePostField}>
                            <div style={{ fontSize: "1.25em" }}>
                              <i className="fa fa-video-camera" />
                              <br />
                              {" Add Video"}
                            </div>
                          </FileUploadButton>
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                </Card>
              </Col>
            </Row>
            {/*<Row>*/}
            {/*  <Col>*/}
            {/*    <Card className={sharingClassNames}>*/}
            {/*      <CardHeader>*/}
            {/*        <CardTitle>*/}
            {/*          <Toggle*/}
            {/*            name="share"*/}
            {/*            label="Share Plus"*/}
            {/*            className="fw-bold"*/}
            {/*            value={!!this.state.post.share}*/}
            {/*            onClick={this.updatePostField}*/}
            {/*          />*/}
            {/*          <br />*/}
            {/*          <small className="text-muted">*/}
            {/*            This post will be shared to all advocates and contacts on the selected locations. Use the*/}
            {/*            filters below to limit the share audience by tag or specific individuals.*/}
            {/*          </small>*/}
            {/*        </CardTitle>*/}
            {/*        <HelpIcon className="ms-auto">*/}
            {/*          Selecting tags in the sharing drop down will associate contacts from your advocate list with this*/}
            {/*          post. When you decide to publish this post, these advocates will receive a text, inviting them to*/}
            {/*          share your post to their personal Facebook page.*/}
            {/*          <hr></hr>*/}
            {/*          To add advocates to your account, go to your store&quot;s [location page] and download the csv*/}
            {/*          format file in the advocates section, fill it out and upload back to Widewail.*/}
            {/*        </HelpIcon>*/}
            {/*      </CardHeader>*/}
            {/*      <Collapse isOpen={this.state.post.share}>*/}
            {/*        <CardBody>*/}
            {/*          <HorizontalSelectField*/}
            {/*            name="shareTags"*/}
            {/*            value={post.shareTags}*/}
            {/*            label="Tags"*/}
            {/*            placeholder="No tags selected..."*/}
            {/*            isMulti={true}*/}
            {/*            creatable={false}*/}
            {/*            cacheOptions={true}*/}
            {/*            defaultOptions={true}*/}
            {/*            searchable={false}*/}
            {/*            simpleValue={true}*/}
            {/*            options={this.state.shareTagOptions}*/}
            {/*            onChange={this.updatePostField}*/}
            {/*          />*/}

            {/*          <HorizontalSelectField*/}
            {/*            name="shareTargets"*/}
            {/*            value={post.shareTargets}*/}
            {/*            label="Share With"*/}
            {/*            placeholder="No one else..."*/}
            {/*            isMulti={true}*/}
            {/*            creatable={false}*/}
            {/*            cacheOptions={true}*/}
            {/*            getOptionLabel={({ name }) => name}*/}
            {/*            getOptionValue={({ id }) => id}*/}
            {/*            defaultOptions={true}*/}
            {/*            searchable={false}*/}
            {/*            options={this.state.shareTargetOptions}*/}
            {/*            onChange={this.updatePostField}*/}
            {/*          />*/}

            {/*          {post.id && post.shareTags && post.status === "POSTED" && <PostShares postId={post.id} />}*/}

            {/*          <big className="text-primary">Share Plus Audience: {this.state.reach}</big>*/}
            {/*        </CardBody>*/}
            {/*      </Collapse>*/}
            {/*    </Card>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Row>
              <Col>
                <Card>
                  <CardHeader className="mt-3">
                    <CardTitle>Summary</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {this.isScheduled() && (
                      <>
                        <Row>
                          <Col>
                            <h5>Post Times at your Locations:</h5>
                            <ul>
                              {_.map(this.state.post.destinations, dest => {
                                if (_.isNil(this.state.post.scheduledPublishDate) === false && !!dest.timezone) {
                                  let companyPostTime = moment(this.state.post.scheduledPublishDate)
                                    .tz(dest.timezone)
                                    .format("MMMM Do YYYY [at] h:mm a");
                                  return (
                                    <li key={dest.id + dest.source}>
                                      {dest.companyName} : {companyPostTime}{" "}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row className="mb-2">
                      {!this.props.isLoading && this.state.post.destinations.length > 0 && (
                        <Col xs="auto" className="text-start">
                          {/* If status is currenty draft show these buttons */}
                          {this.state.post.status === "DRAFT" && (
                            <>
                              <WWButton
                                iconClass="clock-o"
                                color="light"
                                size="sm"
                                className="me-1"
                                responsiveBreakpoint="sm"
                                onClick={() => {
                                  this.setStatus("SCHEDULED", false);
                                }}
                              >
                                Schedule
                              </WWButton>
                              <WWButton
                                iconClass="save"
                                color="primary"
                                size="sm"
                                className="me-1"
                                onClick={() => {
                                  this.setStatus("DRAFT", false, "The post has been saved as a draft.");
                                }}
                              >
                                Save Draft
                              </WWButton>
                              <WWButton
                                iconClass="fa fa-paper-plane"
                                color="primary"
                                size="sm"
                                className="me-1"
                                onClick={() => {
                                  this.setStatus("QUEUED", true, "This post will be published shortly.");
                                }}
                              >
                                Publish
                              </WWButton>
                            </>
                          )}
                          {/* If status is scheduled show these buttons */}
                          {this.state.post.status === "SCHEDULED" && (
                            <div className="d-flex">
                              <DateTimeSelector
                                date={this.getSchedulePublishDate().toDate()}
                                onChange={date =>
                                  this.setState({
                                    post: {
                                      ...this.state.post,
                                      scheduledPublishDate: date
                                    }
                                  })
                                }
                                clearable={false}
                              />
                              <ButtonGroup className="ms-2">
                                <WWButton
                                  color="secondary"
                                  iconClass="paper-plane"
                                  onClick={() => {
                                    this.setStatus("SCHEDULED", true, "This post is scheduled to be posted.");
                                  }}
                                >
                                  Schedule
                                </WWButton>
                                <WWButton
                                  color="warning"
                                  iconClass="fa fa-times"
                                  onClick={() => {
                                    this.setStatus("DRAFT", false, "Scheduling canceled. Post is now a draft.");
                                  }}
                                />
                              </ButtonGroup>
                            </div>
                          )}
                          {/* If status is posted or queued show these buttons */}
                          {(this.state.post.status === "POSTED" || this.state.post.status === "QUEUED") && (
                            <>
                              <ButtonWConfirm
                                color="danger"
                                size="sm"
                                className="me-1"
                                onClick={() => {
                                  this.deletePostHandler();
                                }}
                              >
                                <i className="fa fa-trash me-1" aria-hidden="true"></i>
                                Delete
                              </ButtonWConfirm>
                              {/* disabled could be tied to handler in the future, for now just check is the queued status is pending */}
                              <WWButton
                                iconClass="paper-plane"
                                color="primary"
                                size="sm"
                                className="me-1"
                                disabled={this.state.post.status === "QUEUED"}
                                onClick={() => {
                                  this.setStatus("QUEUED", false, "This post will be published shortly.");
                                }}
                              >
                                Update &amp; Publish
                              </WWButton>
                            </>
                          )}
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={{ size: 12, order: 2 }} md={{ size: 4, order: 2 }}>
            <Row>
              <Col>
                <div className="d-flex">
                  <h5 className="my-2">
                    <b>Previews</b>
                  </h5>
                  <HelpIcon className="ms-auto">
                    Social networks routinely update post layouts and formatting so your post may appear slightly
                    different when published. The company name will match the name at the destination when published.
                  </HelpIcon>
                </div>
                {_.map(this.state.post.destinations, dest => dest.source).includes("FACEBOOK") && (
                  <Card>
                    <CardHeader>
                      <CardTitle>Facebook</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FacebookPostExample
                        attachments={post.attachments}
                        posterName={_.get(post, "destinations[0].companyName")}
                        postText={!_.isEmpty(post.content) && post.rendered}
                      ></FacebookPostExample>
                    </CardBody>
                  </Card>
                )}
                {_.map(this.state.post.destinations, dest => dest.source).includes("INSTAGRAM") && (
                  <Card>
                    <CardHeader>
                      <CardTitle>Instagram</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <InstagramPostExample
                        attachments={post.attachments}
                        posterName={_.get(post, "destinations[0].companyName")}
                        postText={!_.isEmpty(post.content) && post.rendered}
                      ></InstagramPostExample>
                    </CardBody>
                  </Card>
                )}
                {_.map(this.state.post.destinations, dest => dest.source).includes("GOOGLE") && (
                  <Card>
                    <CardHeader>
                      <CardTitle>Google</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <GooglePostExample
                        attachments={post.attachments}
                        posterName={_.get(post, "destinations[0].companyName")}
                        postText={!_.isEmpty(post.content) && post.rendered}
                      ></GooglePostExample>
                    </CardBody>
                  </Card>
                )}
                {_.map(this.state.post.destinations, dest => dest.source).includes("LINKEDIN") && (
                  <Card>
                    <CardHeader>
                      <CardTitle>LinkedIn</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <LinkedInPostExample
                        attachments={post.attachments}
                        posterName={_.get(post, "destinations[0].companyName")}
                        postText={!_.isEmpty(post.content) && post.rendered}
                      ></LinkedInPostExample>
                    </CardBody>
                  </Card>
                )}
                {_.map(this.state.post.destinations, dest => dest.source).includes("DIRECT_MESSAGE") && (
                  <Card>
                    <CardHeader>
                      <CardTitle>Direct Email</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <DirectEmailPreview post={post}></DirectEmailPreview>
                    </CardBody>
                  </Card>
                )}
                {_.map(this.state.post.destinations, dest => dest.source).includes("DIRECT_MESSAGE") && (
                  <Card>
                    <CardHeader>
                      <CardTitle>Direct SMS</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <DirectSmsPreview post={post}></DirectSmsPreview>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
            {_.size(this.state.failedPosts) > 0 && (
              <Row>
                <Col>
                  <Card>
                    <CardHeader className="mt-3">
                      <CardTitle>Failed Postings</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FailedPostsTable failedPosts={this.state.failedPosts} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

EditPost.propTypes = {
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.cognito.user,
    loading: state.ajaxCallsInProgress > 0
  };
}

export default withAuthorization([permissions.CREATE_POST, permissions.SHARE_DIRECT])(
  withLocalNotifications(withRouter(connect(mapStateToProps)(EditPost)))
);
