import React from "react";
import { Col, Row } from "reactstrap";
import { useCurrentAgency } from "hooks/agencyHooks";
import InvitesBySender from "./InviteReporting/InvitesBySender";
import Summary from "./InviteReporting/Summary";

import { datetime2iso, endOfTheDay, startOfTheDay, now, daysBeforeNow } from "util/dateUtils";

import {
  CtrByPosition,
  LandingPagePerformance,
  RequestsByDestination,
  RequestsBySource
} from "./InviteReporting/RequestBy";

import ReportWithFilters, {
  getComparisonsOptions,
  COMPARISON_OPTIONS,
  useReportFilterQuery
} from "components/HOCs/ReportWithFilters";

import VolumeWidget, { VolumeWidget as SimpleVolumeWidget } from "views/Reports/VolumeWidget";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

const InvitePerformance = () => {
    const filterQuery = useReportFilterQuery({
      url: "/invitereport/invites-performance"
    });

    return <SimpleVolumeWidget {...filterQuery} title="Invite Performance" />;
  },
  InvitesSent = () => {
    const filterQuery = useReportFilterQuery({
      url: "/invitereport/invites-sent"
    });

    return <SimpleVolumeWidget {...filterQuery} title="Invites Sent " />;
  },
  filtersFn = ({ isAdmin, filterValues = {} }) => [
    getAccountFilterConfig(),
    {
      name: "location",
      label: "Location",
      type: "customerOrBrand",
      required: isAdmin && !filterValues?.account?.id,
      minLength: 0,
      primaryBar: {
        position: "LEFT",
        className: "d-md-flex auto-width d-none"
      },
      chip: {
        position: "NONE"
      }
    },
    {
      name: "campaignNames",
      label: "Campaign",
      type: "campaign",
      minLength: 0,
      primaryBar: {
        position: "LEFT",
        className: "d-md-flex auto-width d-none"
      },
      chip: {
        position: "NONE"
      },
      additionalArgs: { byName: true },
      additionalParams: {
        defaultSearchParams: {
          brand: filterValues?.location?.brand,
          customerId: filterValues?.location?.id
        }
      }
    },
    {
      name: "dateRange",
      label: "Date range",
      type: "dates",
      queryParams: ["startDate", "endDate"],
      required: true,
      defaultValue: {
        startDate: datetime2iso(startOfTheDay(daysBeforeNow(30))),
        endDate: datetime2iso(endOfTheDay(now()))
      },
      primaryBar: {
        position: "LEFT",
        className: "d-md-flex auto-width d-none"
      },
      chip: {
        position: "NONE"
      }
    },
    {
      name: "compareMode",
      label: "Compare to...",
      type: "buttonDropdown",
      required: true,
      showLabelInMenu: true,
      clearable: false,
      defaultValue: COMPARISON_OPTIONS.RELATIVE,
      options: getComparisonsOptions(filterValues?.dateRange),
      placeholder: "Compare to...",
      primaryBar: {
        position: "LEFT",
        className: "d-none d-md-flex comparison-selector"
      },
      chip: {
        position: "NONE"
      }
    }
  ];

export const InviteReporting = () => {
  const currentAgency = useCurrentAgency();

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <ReportWithFilters filtersFn={filtersFn}>
        <Summary />
        <Row className="my-1 no-pagebreak-inside">
          <Col>
            <VolumeWidget onlyAssignedLocations={true} showCampaignHelpText={true} />
          </Col>
        </Row>
        <Row className="my-1">
          <Col className="no-pagebreak-inside col-12">
            <RequestsBySource />
          </Col>
          <Col className="no-pagebreak-inside">
            <RequestsByDestination />
          </Col>
        </Row>
        {currentAgency?.name !== "OhMD" && (
          <>
            <Row className="my-1 no-pagebreak-inside">
              <Col>
                <InvitesSent />
              </Col>
            </Row>
            <Row className="my-1 no-pagebreak-inside">
              <Col>
                <InvitePerformance />
              </Col>
            </Row>
          </>
        )}
        <Row className="my-1 no-pagebreak-inside">
          <Col className="no-pagebreak-inside">
            <LandingPagePerformance />
          </Col>
          <Col className="no-pagebreak-inside">
            <CtrByPosition />
          </Col>
        </Row>
        <Row className="my-1 no-pagebreak-inside">
          <Col>
            <InvitesBySender />
          </Col>
        </Row>
      </ReportWithFilters>
    </div>
  );
};
