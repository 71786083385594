import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import isString from "lodash/isString";

class Logger {
  constructor() {
    datadogLogs.init({
      applicationId: process.env.REACT_APP_DATADOG_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      datacenter: "us",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      sessionReplaySampleRate: 100,
      allowedTracingUrls: [
        { match: "https://api.widewail.com", propagatorTypes: ["datadog"] },
        { match: "https://api-qa.widewail.com", propagatorTypes: ["datadog"] }
      ],
      env: process.env.REACT_APP_ENVIRONMENT || "undefined",
      service: process.env.REACT_APP_SERVICE || "undefined",
      version: process.env.REACT_APP_RELEASE_VERSION || "undefined",
      defaultPrivacyLevel: "mask-user-input"
    });

    datadogLogs.setGlobalContextProperty("logger_name", process.env.REACT_APP_SERVICE || "undefined");
    datadogLogs.setGlobalContextProperty("env", process.env.NODE_ENV);

    if (process.env.NODE_ENV === "production") {
      datadogRum.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        applicationId: process.env.REACT_APP_DATADOG_APP_ID,
        allowedTracingUrls: ["https://api.widewail.com", "https://api-qa.widewail.com"],
        allowedTracingUrls: [
          { match: "https://api.widewail.com", propagatorTypes: ["datadog"] },
          { match: "https://api-qa.widewail.com", propagatorTypes: ["datadog"] }
        ],
        datacenter: "us",
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        env: process.env.REACT_APP_ENVIRONMENT || "undefined",
        service: process.env.REACT_APP_SERVICE || "undefined",
        version: process.env.REACT_APP_RELEASE_VERSION || "undefined",
        defaultPrivacyLevel: 'mask-user-input'
      });

      datadogLogs.logger.setLevel("info");
      datadogLogs.logger.setHandler("http");
      if (console) {
        console.log = msg => this._log("info", msg);
      }
    } else {
      datadogLogs.logger.setLevel("debug");
      datadogLogs.logger.setHandler("console");
    }
  }

  _log(level, msg, context) {
    // const user = get(store.getState(), "cognito.user");
    // const currentAgencyId = get(store.getState(), "currentAgency.id");
    const logCtx = {
      // user: get(user, "email"),
      // auth: user,
      // agencyId: currentAgencyId,
      level: level.toUpperCase(),
      ...context
    };

    datadogLogs.logger.log(isString(msg) ? msg : JSON.stringify(msg), logCtx, level ? level.toLowerCase() : "info");
  }

  addGlobalContext(key, value) {
    datadogLogs.setGlobalContextProperty(key, value);
  }

  info(msg, context) {
    this._log("info", msg, context);
  }

  error(msg, context) {
    this._log("error", msg, context);
  }

  warn(msg, context) {
    this._log("warn", msg, context);
  }

  debug(msg, context) {
    this._log("debug", context);
  }

  action(name, context) {
    datadogRum.addAction(name, context);
  }
}

const logger = new Logger();
export default logger;
