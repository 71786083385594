module.exports = {
  BEGIN_AJAX_CALL: "BEGIN_AJAX_CALL",
  AJAX_CALL_ERROR: "AJAX_CALL_ERROR",
  AJAX_CALL_SUCCESS: "AJAX_CALL_SUCCESS",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  REPORT_SET_START_DATE: "REPORT_SET_START_DATE",
  REPORT_SET_END_DATE: "REPORT_SET_END_DATE",
  REPORT_SET_CUSTOMER: "REPORT_SET_CUSTOMER",
  REPORT_SET_CONTACT: "REPORT_SET_CONTACT",
  REPORT_CLEAR_CUSTOMER: "REPORT_CLEAR_CUSTOMER",

  CURRENT_CONTACT_LOAD: "CURRENT_CONTACT_LOAD",
  CURRENT_CONTACT_RECEIVE: "CURRENT_CONTACT_RECEIVE",
  CURRENT_CONTACT_ACTIVE_PRODUCTS: "CURRENT_CONTACT_ACTIVE_PRODUCTS",
  CURRENT_CONTACT_FEATURES: "CURRENT_CONTACT_FEATURES",

  AGENCY_SET: "AGENCY_SET",
  LOGOUT_AGENCY: "LOGOUT_AGENCY",

  APP_INIT_SUCCESS: "APP_INIT_SUCCESS",
  APP_SCRIPT_LOADED: "APP_SCRIPT_LOADED",
  APP_SET_VERSION: "APP_SET_VERSION",
  APP_ADD_MESSAGE: "APP_ADD_MESSAGE",
  APP_CLEAR_MESSAGES: "APP_CLEAR_MESSAGES",

  FACEBOOK_LOGIN: "FACEBOOK_LOGIN",
  FACEBOOK_LOGOUT: "FACEBOOK_LOGOUT",

  GOOGLE_LOGIN: "GOOGLE_LOGIN",
  GOOGLE_LOGOUT: "GOOGLE_LOGOUT",

  UPDATE_PATH: "UPDATE_PATH",
  UPDATE_VIEW_STATE: "UPDATE_VIEW_STATE",
  CLEAR_VIEW_STATE: "CLEAR_VIEW_STATE",

  USER_DASHBOARD_SET_CUSTOMERS: "USER_DASHBOARD_SET_CUSTOMERS"
};
