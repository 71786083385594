import React from "react";
import "./InteractionInfo.scss";
import Time from "../../components/DateTime/Time";
import WWButton from "../../components/Buttons/WWButton";
import join from "lodash/join";
import noop from "lodash/noop";
import { Link } from "react-router-dom";

export default function InteractionInfo({ interaction, onLink = noop, linkable = true, onUnlink = noop }) {
  return (
    <div className="interaction-info border-box p-2 border border-muted rounded-0 d-flex justify-content-between align-items-center">
      <div>
        <Link to={`/invite/leads/${interaction.reviewLead.id}`}>
          {interaction.reviewLead.firstName + " " + interaction.reviewLead.lastName}
        </Link>
        {" - " + join(interaction.tags, ", ") + " - "}
        <Time withIcon={false} format="date" date={interaction.visitTime} />
      </div>
      {linkable ? (
        <WWButton
          className="m-0 ms-1"
          iconClass="fa fa-chain"
          tooltip="Link"
          onClick={event => {
            onLink(interaction);
          }}
        />
      ) : (
        <WWButton className="m-0 ms-1" iconClass="fa fa-close" tooltip="Unlink" onClick={onUnlink} />
      )}
    </div>
  );
}
