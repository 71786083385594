import React from "react";
import FormField from "./FormField";
import { Input } from "reactstrap";

/**
 * TODO: ditch in favor of InputField
 * @param {} param0
 */
const CheckboxField = ({ name, label, inline, valid, feedback, tip, onChange, value, ...props }) => (
  <FormField label={label} name={name} valid={valid} tip={tip} feedback={feedback} inline={inline}>
    <div className="d-flex h-100 align-items-center">
      <Input
        {...props}
        type="checkbox"
        id={name}
        name={name}
        className="align-self-center"
        style={{ marginLeft: 0 }}
        onChange={event => {
          /* mutate the event to look like other input types to make upstream consumption easier */
          let syntheticEvent = {
            target: {
              name: event.target.name,
              value: event.target.checked
            }
          };
          onChange(syntheticEvent);
        }}
        //fix uncontrolled component errors when value is undefined
        checked={value || ""}
      />
    </div>
  </FormField>
);

CheckboxField.propTypes = {
  ...FormField.propTypes
};

export default CheckboxField;
