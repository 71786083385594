import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import moment from "moment";
import Time from "./Time";

const DAYS_FOR_SUCCESS = 1;
const DAYS_FOR_WARNING = 2;

/**
 * A color coded badge showing a duration of time from now
 *
 * @param {from} Date
 */
export function AgeBadge({ from, ...props }) {
  let color;
  if (
    moment()
      .subtract(DAYS_FOR_SUCCESS, "days")
      .isBefore(from)
  ) {
    color = "success";
  } else if (
    moment()
      .subtract(DAYS_FOR_WARNING, "days")
      .isBefore(from)
  ) {
    color = "warning";
  } else {
    color = "danger";
  }

  return (
    <Badge {...props} color={color}>
      <Time format="ago" date={from} />
    </Badge>
  );
}

AgeBadge.propTypes = {
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

export default AgeBadge;
