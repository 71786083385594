import Block from "components/Layout/Block";
import { useResponderNotesByReviewId } from "hooks/data/responderNotesHooks";
import { Alert } from "reactstrap";
import Skeleton from "components/Skeleton";
import classNames from "classnames";

import "react-quill/dist/quill.core.css";
import styles from "./ResponderNotes.module.scss";

const { responderNotes } = styles;

const COLOR_MAP = {
  YELLOW: "warning",
  GREEN: "success",
  RED: "danger",
  BLUE: "primary",
  GRAY: "secondary"
};

const ResponderNotes = ({ id }) => {
  const { data, isLoading } = useResponderNotesByReviewId(id);

  if (isLoading) {
    return (
      <Block>
        <Skeleton height={150} />
      </Block>
    );
  } else {
    return (
      <Block>
        {data?.map(note => (
          <Alert key={note.id} color={COLOR_MAP[note.color] || "warning"}>
            <h6 className="mb-0">
              <strong className="text-uppercase">{note.title || "Responder Instructions"}</strong>
            </h6>
            <hr />
            <div className={classNames("ql-editor", responderNotes)} dangerouslySetInnerHTML={{ __html: note.note }} />
          </Alert>
        ))}
      </Block>
    );
  }
};

export default ResponderNotes;
