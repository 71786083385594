import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const WWModal = ({ ...props }) => {
  return <Modal {...props} />;
};

export const WWModalHeader = ({ ...props }) => {
  return <ModalHeader {...props} />;
};

export const WWModalBody = ({ ...props }) => {
  return <ModalBody {...props} />;
};

export const WWModalFooter = ({ ...props }) => {
  return <ModalFooter {...props} />;
};
