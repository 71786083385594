import WWChip from "../../../WWChips/WWChip/WWChip";
import { useFilterValueState } from "hooks/filteringHooks";
import Input from "components/Form/Input";
import { FormGroup } from "reactstrap";
import { valueOf } from "util/functionUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import SelectField from "components/Form/SelectField";
import { getEntities } from "api/reviewApi";
import CheckboxLabeled from "../../../Checkbox/Labeled/CheckboxLabeled";

import styles from "./EntityFilter.module.scss";
import { isArray, uniqBy } from "lodash";

const EntityFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <EntityFilterBar key={props.name} className={className} {...props} />
  );
  const chip = ({ ...props }, className) => <EntityChip key={props.name} {...props} className={className} />;
  const filterComponent = ({ ...props }, className) => (
    <EntityFilterSidebar key={props.name} {...props} className={className} />
  );

  return {
    barComponent,
    chip,
    filterComponent
  };
};

export const EntityFilterBar = ({ className, name, label, icon, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return <></>;
};

const MODE_OFF = "OFF";
const MODE_ANY = "ANY";
const MODE_NONE = "NONE";
const MODE_MATCH = "MATCH";

const SENTIMENT_POSITIVE = "POSITIVE";
const SENTIMENT_NEUTRAL = "NEUTRAL";
const SENTIMENT_NEGATIVE = "NEGATIVE";
const SENTIMENT_UNTAGGED = "UNTAGGED";

export const EntityChip = ({ name, className, iconClass, options, label, defaultValue, ...props }) => {
  const [value, setter] = useFilterValueState(name);

  const chipText = useMemo(() => {
    const mode = value?.mode;
    if (mode === MODE_ANY) {
      return `any ${label}`;
    } else if (mode === MODE_NONE) {
      return `no ${label}`;
    } else {
      return value?.match;
    }
  }, [label, value]);

  const iconsClassSet = useMemo(() => {
    const iconsMap = {
      POSITIVE: "fa fa-thumbs-up text-muted",
      NEGATIVE: "fa fa-thumbs-down text-muted",
      NEUTRAL: "fa fa-circle text-muted"
    };
    if (!value?.sentiment) {
      return iconClass;
    }

    const iconsSet = iconClass ? [iconClass] : [];
    if (Array.isArray(value.sentiment)) {
      iconsSet.push(...value.sentiment.map(sentiment => iconsMap[sentiment]));
    } else {
      iconsSet.push(iconsMap[value.sentiment]);
    }

    return iconsSet;
  }, [value, iconClass]);

  return value ? (
    <WWChip
      iconClass={iconsClassSet}
      key={name}
      className="overflow-visible"
      onClick={() => setter(valueOf(defaultValue))}
      {...props}
    >
      {chipText}
    </WWChip>
  ) : null;
};

export const EntityFilterSidebar = ({ name, label, entityType, options, className, ...props }) => {
  const [value, setter, fullValue] = useFilterValueState(name);
  const [mode, setMode] = useState(value?.mode || MODE_OFF);
  const [match, setMatch] = useState(value?.match || "");
  const [sentiment, setSentiment] = useState(value?.sentiment || []);

  const getSentimentFlag = useCallback(flag => sentiment.includes(flag), [sentiment]);
  const setSentimentFlag = useCallback(
    (flag, value) => {
      const s = (isArray(sentiment) ? sentiment : [sentiment]).filter(f => f && f !== flag);
      if (value) {
        setSentiment([...s, flag]);
      } else {
        setSentiment(s);
      }
    },
    [sentiment, setSentiment]
  );
  const sentimentEnabled = useMemo(() => mode === MODE_ANY || mode === MODE_MATCH, [mode]);

  useEffect(() => {
    if (mode === MODE_OFF) {
      setter(null);
    } else if (mode === MODE_NONE) {
      setter({ mode });
    } else if (mode === MODE_ANY) {
      setter({ mode, sentiment });
    } else if (match) {
      // Logically, we must be in MODE_MATCH
      setter({ mode, match, sentiment });
    }
  }, [match, mode, sentiment, setter]);

  return (
    <>
      <FormGroup className="d-flex align-items-center flex-nowrap">
        <label className="d-flex align-items-center flex-nowrap fw-normal mb-1">
          <Input
            className="mt-0"
            type="radio"
            name={`${name}-off`}
            onChange={() => setMode(MODE_OFF)}
            checked={mode === MODE_OFF}
          />
          <span>All Reviews</span>
        </label>
      </FormGroup>
      <FormGroup className="d-flex align-items-center flex-nowrap">
        <label className="d-flex align-items-center flex-nowrap fw-normal">
          <Input
            className="mt-0"
            type="radio"
            name={`${name}-any`}
            onChange={() => setMode(MODE_ANY)}
            checked={mode === MODE_ANY}
          />
          <span>Reviews with {label} Tags</span>
        </label>
      </FormGroup>
      <FormGroup className="d-flex align-items-center flex-nowrap">
        <label className="d-flex align-items-center flex-nowrap fw-normal">
          <Input
            className="mt-0"
            type="radio"
            name={`${name}-none`}
            onChange={() => setMode(MODE_NONE)}
            checked={mode === MODE_NONE}
          />
          <span>Reviews without {label} Tags</span>
        </label>
      </FormGroup>
      <FormGroup className="d-flex align-items-center flex-nowrap">
        <label className="d-flex align-items-center flex-nowrap fw-normal">
          <Input
            className="mt-0"
            type="radio"
            name={`${name}-match`}
            onChange={() => setMode(MODE_MATCH)}
            checked={mode === MODE_MATCH}
          />
          <span>Reviews tagged with...</span>
        </label>
      </FormGroup>
      <SelectField
        name={name}
        isDisabled={mode !== MODE_MATCH}
        async={!options}
        key={`${name}_${fullValue}`}
        defaultOptions={!options}
        menuPosition="fixed"
        menuPortalTarget=""
        placeholder={`Search ${label}...`}
        onChange={event => setMatch(event.target.value.value)}
        options={options?.map(value => ({ value }))}
        loadOptions={
          options
            ? undefined
            : (inputValue, callback) => {
              getEntities("*", entityType, inputValue, fullValue).then(
                res => callback(uniqBy(res.data, d => d.value)) // entities with the same value but different sentiment come back on this request, this reduces the set to the distinct values
              );
            }
        }
        getOptionLabel={({ value }) => value}
        value={match ? { entityType, value: match } : ""}
      />
      <FormGroup className={`d-flex align-items-center flex-nowrap ${styles.sentimentCheckboxes}`}>
        <CheckboxLabeled
          label="Positive"
          checked={getSentimentFlag(SENTIMENT_POSITIVE)}
          onToggle={value => setSentimentFlag(SENTIMENT_POSITIVE, value)}
          className={styles.checkboxLabelClass}
          disabled={!sentimentEnabled}
        />
        <CheckboxLabeled
          label="Neutral"
          checked={getSentimentFlag(SENTIMENT_NEUTRAL)}
          onToggle={value => setSentimentFlag(SENTIMENT_NEUTRAL, value)}
          className={styles.checkboxLabelClass}
          disabled={!sentimentEnabled}
        />
        <CheckboxLabeled
          label="Negative"
          checked={getSentimentFlag(SENTIMENT_NEGATIVE)}
          onToggle={value => setSentimentFlag(SENTIMENT_NEGATIVE, value)}
          className={styles.checkboxLabelClass}
          disabled={!sentimentEnabled}
        />
        <CheckboxLabeled
          label="Untagged"
          checked={getSentimentFlag(SENTIMENT_UNTAGGED)}
          onToggle={value => setSentimentFlag(SENTIMENT_UNTAGGED, value)}
          className={styles.checkboxLabelClass}
          disabled={!sentimentEnabled}
        />
      </FormGroup>
    </>
  );
};

export default EntityFactory;
