import React, { useEffect, useState } from "react";
import "./PercentProgress.scss";
import colors from "../../constants/colors";

const PercentProgress = ({
  size = 50,
  progress = 0,
  strokeWidth = 10,
  fontSize = ".5rem",
  circleOneStroke = colors.grayDark,
  circleTwoStroke = colors.blue
}) => {
  const [offset, setOffset] = useState(0);

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, circumference, progress, offset]);

  return (
    <div className="svg-container">
      <svg className="svg" viewBox="0 0 51 51">
        <circle
          className="svg-circle-bg"
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="svg-circle"
          transform={`rotate(-90 ${center} ${center})`}
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        <text className="svg-circle-text" x={center} y={center} dominantBaseline="middle" fontSize={fontSize}>
          {progress || 0}%
        </text>
      </svg>
    </div>
  );
};

export default PercentProgress;
