import React, { useCallback, useMemo } from "react";
import "./ContactCard.scss";
import signature from "./ContactSignature";
import CopyButton from "../Buttons/CopyButton";
import noop from "lodash/noop";
import { ContactMethods } from "../../data/options";

const ContactCard = ({ contact, onClick = noop, disable = [] }) => {
  const contactInfo = useMemo(
    () =>
      ContactMethods.EMAIL === contact.preferredContactMethod
        ? contact.email
        : ContactMethods.PHONE === contact.preferredContactMethod
        ? contact.phone
        : contact.signatureLine,
    [contact]
  );

  const pick = useCallback(
    value => evt => {
      evt.stopPropagation();
      onClick(value);
    },
    [onClick]
  );

  return (
    <div
      className={
        (disable.includes("signature") ? "" : "clickable ") +
        "contact-card p-2 border border-muted rounded-0 d-flex flex-column align-items-start border-box h-100"
      }
      onClick={!disable.includes("signature") && pick(signature(contact))}
    >
      {contact.preferredContactMethod !== "CUSTOM" ? (
        <>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span>
              <span
                className={(disable.includes("name") ? "" : "clickable ") + "name fw-bold pe-2"}
                onClick={!disable.includes("name") && pick(contact.name)}
              >
                {contact.name}
              </span>
              {contact.pronouns ? `(${contact.pronouns})` : ""}
            </span>
            <small className="fs-2">
              <CopyButton text={signature(contact)} />
            </small>
          </div>
          <small
            className={disable.includes("title") ? "fs-2" : "fs-2 clickable "}
            onClick={!disable.includes("title") && pick(contact.title)}
          >
            {contact.title}
          </small>
          <small
            className={(disable.includes("contact") ? "" : "clickable ") + "fs-2 text-truncate"}
            onClick={!disable.includes("contact") && pick(contactInfo)}
          >
            {contactInfo}
          </small>
        </>
      ) : (
        <>
          <small className="fs-2">
            <CopyButton text={signature(contact)} className="float-end ms-2 mb-2" />
            {signature(contact)}
          </small>
        </>
      )}
    </div>
  );
};

ContactCard.propTypes = {};

export default ContactCard;
