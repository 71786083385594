import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import { useCurrentAgency } from "../agencyHooks";
import { useGet } from "../dataHooks";
import { defaultNormalizeResponse } from "./useReviews";

/**
 * @returns {import("react-query").UseQueryResult<[
 *  Array<{
 *    createdOn: import("moment").Moment,
 *    reviewDate: import("moment").Moment,
 *  }>,
 *  {
 *    size: number;
 *    totalElements: number;
 *    totalPages: number;
 *    number: number;
 *   }?
 * ]>}
 */
export const useDashboardReviews = ({ params, enabled = true }) => {
  const currentAgency = useCurrentAgency();
  const path = "/dash/approvalReviews";
  const queryKey = useMemo(() => [path, params, currentAgency.id], [params, currentAgency.id]);
  const [get] = useGet(path, { params });
  const queryRun = useCallback(() => get().then(defaultNormalizeResponse), [get]);
  return useQuery(queryKey, queryRun, {
    enabled
  });
};

/**
 * @returns {import("react-query").UseQueryResult<
 *  {
 *     totalReviews: number;
 *     approvals: number;
 *     replied: number;
 *     havingEntities: number;
 * }>}
 */
export const useDashboardReviewsCount = (enabled = true) => {
  const currentAgency = useCurrentAgency();
  const path = "/dash/reviewsCounter";
  const queryKey = useMemo(() => [path, currentAgency.id], [currentAgency.id]);
  const [get] = useGet(path);
  return useQuery(queryKey, get, {
    enabled
  });
};
