import { property } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { updateCdkDmsClosedSaleStatus } from "../../../api/customerApi";
import FormField from "../../../components/Form/FormField";
import HorizontalSelectField from "../../../components/Form/HorizontalSelectField";
import InputField from "../../../components/Form/InputField";
import CopyButton from "../../../components/Buttons/CopyButton";
import { errorCaughtNotifier, useLocalNotifications } from "../../../components/Notifications/notification";

export default class CdkDms {
  field() {
    return "cdkDmsInfo";
  }

  add() {
    return AddCdkDms;
  }

  edit() {
    return EditCdkDms;
  }
}

const EditCdkDms = ({ info, customer }) => {
  const [status, setStatus] = useState(info.closedSaleStatus);
  useEffect(() => {
    setStatus(info.closedSaleStatus);
  }, [info.closedSaleStatus]);
  const notify = useLocalNotifications();
  const onClosedSaleStatusChanged = useCallback(
    ({ target }) =>
      updateCdkDmsClosedSaleStatus(customer.id, "cdkDmsInfo", info.id, target.value)
        .then(property("closedSaleStatus"))
        .then(setStatus)
        .catch(errorCaughtNotifier(notify)),
    [info, customer]
  );
  return (
    <>
      <FormField label="Dealer ID">
        <div className="text-nowrap">
          {info.dealerId} <CopyButton className="ms-1" text={info.dealerId} />
        </div>
      </FormField>
      <HorizontalSelectField
        name="closed_sale_status"
        value={status}
        options={closedSaleStatusUpdateValuesSet}
        label="Closed Sale Status"
        isMulti={false}
        simpleValue={true}
        onChange={onClosedSaleStatusChanged}
        placeholder="BOOKED"
      />
    </>
  );
};

const AddCdkDms = ({ onSave }) => {
  const [dealerId, setDealerId] = useState();
  const [closedSaleStatus, setClosedSaleStatus] = useState("BOOKED");

  return (
    <>
      <InputField
        type="text"
        label="Dealer ID"
        placeholder="CDK Dealer ID"
        onChange={e => setDealerId(e.target.value)}
        value={dealerId}
      />

      <HorizontalSelectField
        name="closed_sale_status"
        value={closedSaleStatus}
        options={closedSaleStatusUpdateValuesSet}
        label="Closed Sale Status"
        isMulti={false}
        simpleValue={true}
        onChange={e => setClosedSaleStatus(e.target.value)}
        placeholder="BOOKED"
      />

      <Button
        color="primary"
        onClick={() =>
          onSave({
            dealerId,
            closedSaleStatus,
            source: "CDK_DMS"
          })
        }
      >
        Link
      </Button>
    </>
  );
};

const closedSaleStatusUpdateValuesSet = ["PROCESS", "CLEARED", "BOOKED", "FINALIZED", "POSTED", "UNWOUND", "DELIVERED"];
