import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import map from "lodash/map";
import { useSharedCredentials } from "hooks/data/credentialsHooks";
import { useLocationQueryParamState } from "hooks/stateHooks";
import { Card, CardBody, CardHeaderAction, CardHeaderActions, CardTitle } from "components/Card";
import Time from "components/DateTime/Time";
import Pager from "components/Paging/Pager";
import { TableCard, TableCardHeader, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import WWButton from "../../components/Buttons/WWButton";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";

import HorizontalSelectField from "components/Form/HorizontalSelectField";

import { CredentialsEditor, isSupported, NEW } from "./CredentialsEditor";
import { SITES } from "../../data/sites";
import SharedCredentialsSelector from "components/SharedCredentials/SharedCredentialsSelector";

const SOURCE_TO_LABEL = SITES.sort((a, b) => a.label.localeCompare(b.label))
  .map(site => {
    const result = [];
    result[site.source] = (
      <>
        <i className={`fa ${site.icon} me-2`} /> {site.label}
      </>
    );
    return result;
  })
  .reduce((a, b) => ({ ...a, ...b }));

const SOURCES = [
  {
    value: "",
    label: (
      <>
        <i className={`fa fa-circle-thin me-2`} /> Any
      </>
    )
  },
  ...SITES.filter(site => isSupported(site.source))
    .sort((a, b) => a.label.localeCompare(b.label))
    .map(site => ({
      value: site.source,
      label: (
        <>
          <i className={`fa ${site.icon} me-2`} /> {site.label}
        </>
      )
    }))
];

const CredentialsList = () => {
  const [pageNumber, setPageNumber] = useLocationQueryParamState("page");
  const [pageInfo, setPageInfo] = useState();
  const [source, setSource] = useState();
  const [name, setName] = useState();

  const [credentials, setCredentials] = useState();
  const { data } = useSharedCredentials({ page: pageNumber, source, name });

  const handleCredentialSelect = useCallback(data => {
    setName(data?.name);
  }, []);

  useEffect(() => {
    if (data) {
      setCredentials(data[0]);
      setPageInfo(data[1]);
    }
  }, [data, setCredentials, setPageInfo]);

  const [editingCredentialsId, setEditingCredentialsId] = useState();

  return (
    <div className="mt-4 container-fluid">
      <Row className="mt-1 no-pagebreak-inside">
        <Col xs={12} md={6} className="d-flex flex-row align-items-stretch justify-content-between">
          <Card className="me-auto flex-grow-1 justify-content-center no-pagebreak-inside">
            <HorizontalSelectField
              name="Source"
              label="Search by source"
              options={SOURCES}
              isMulti={false}
              onChange={event => setSource(event.target.value.value)}
            />
          </Card>
        </Col>
        <Col xs={12} md={6} className="d-flex flex-row align-items-stretch justify-content-between">
          <Card className="me-auto flex-grow-1 justify-content-center no-pagebreak-inside">
            <SharedCredentialsSelector
              label="Search by name"
              onChange={handleCredentialSelect}
              source={source}
              emptyLabel="Any"
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableCard>
            <TableCardHeader className="flex-wrap justify-content-between">
              <CardTitle>Shared Credentials</CardTitle>
              <Row className="d-flex align-items-center justify-content-between flex-wrap">
                <Col xs="auto" className="d-flex align-items-center ms-auto">
                  <CardHeaderActions>
                    <CardHeaderAction
                      className="d-md-inline mx-1"
                      color="primary"
                      onClick={() => {
                        setEditingCredentialsId(NEW);
                      }}
                    >
                      Create New Credentials
                    </CardHeaderAction>
                  </CardHeaderActions>
                </Col>
              </Row>
            </TableCardHeader>
            <CardBody>
              <WWTable>
                <WWTHead>
                  <WWTR>
                    <WWTH>Source</WWTH>
                    <WWTH>Name</WWTH>
                    <WWTH>Updated Date</WWTH>
                    <WWTH>Updated By</WWTH>
                    <WWTH>
                      <div className="d-flex justify-content-end">
                        <Pager pageInfo={pageInfo} onPageChange={setPageNumber} />
                      </div>
                    </WWTH>
                  </WWTR>
                </WWTHead>
                {!credentials && <TableBodySkeleton cols={4} rows={3} />}
                {credentials && (
                  <WWTBody>
                    {map(credentials, ({ id, source, name, modifiedOn, modifiedBy }) => (
                      <WWTR key={id}>
                        <WWTD>{SOURCE_TO_LABEL[source]}</WWTD>
                        <WWTD>{name}</WWTD>
                        <WWTD>
                          <div>
                            <Time withIcon={false} format="dateSlash" date={modifiedOn} />
                          </div>
                          <Time className="text-muted small" withIcon={false} format="time" date={modifiedOn} />
                        </WWTD>
                        <WWTD>{modifiedBy}</WWTD>
                        <WWTD>
                          <WWButton
                            onClick={() => {
                              setEditingCredentialsId(id);
                            }}
                          >
                            Edit
                          </WWButton>
                        </WWTD>
                      </WWTR>
                    ))}
                  </WWTBody>
                )}
              </WWTable>
            </CardBody>
          </TableCard>
          <div className="d-flex justify-content-end">
            <Pager pageInfo={pageInfo} onPageChange={setPageNumber} />
          </div>
        </Col>
      </Row>
      <CredentialsEditor
        id={editingCredentialsId}
        defaultSource={source}
        close={() => {
          setEditingCredentialsId(null);
        }}
      />
    </div>
  );
};

export default CredentialsList;
