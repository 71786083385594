import React, { Component } from "react";
import { Row, Col, Table } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import memoize from "memoizee";
import AgeBadge from "../../components/DateTime/AgeBadge";
import map from "lodash/map";
import get from "lodash/get";
import { getApprovalSearch } from "util/getApprovalSearch";

export default class PendingApprovalSummaryTable extends Component {
  constructor(props) {
    super(props);

    let expandedRows = this.props.summary.included ? [] : [this.props.summary.parent.customer.companyName];
    this.state = {
      expandedRows,
      summary: this.props.summary
    };

    this.sortChildren = memoize(children =>
      children?.filter(c => c.pendingApprovals).sort((a, b) => b.pendingApprovals - a.pendingApprovals)
    );

    this.render_row = this.render_row.bind(this);
    this.toggleGroup = this.toggleGroup.bind(this);
  }

  toggleGroup(id) {
    if (this.state.expandedRows.indexOf(id) >= 0) {
      this.setState({
        expandedRows: this.state.expandedRows.filter(e => e !== id)
      });
    } else {
      this.setState({ expandedRows: [...this.state.expandedRows, id] });
    }
  }

  render_row(summary, lvl) {
    const { expandedRows } = this.state;
    const approvePath = "/reviews/feed";
    const approveSearch = getApprovalSearch(
      summary.customer.id
        ? {
            location: { id: summary.customer.id, type: "CUSTOMER" }
          }
        : undefined
    );

    return (
      <tr key={summary.customer.companyName}>
        <td>
          {lvl === 0 && (
            <i
              className={classnames(
                "fa",
                "link",
                expandedRows.indexOf(summary.customer.companyName) >= 0 ? "fa-minus-square-o" : "fa-plus-square-o"
              )}
              style={{ fontWeight: "normal", paddingRight: "4px" }}
              onClick={() => this.toggleGroup(summary.customer.companyName)}
            />
          )}
          <strong style={{ paddingLeft: Math.max(0, 24 * lvl) }}>
            {summary.customer.id ? (
              <Link className="link" to={{ pathname: approvePath, search: `?${approveSearch}` }} target="_blank">
                {summary.customer.companyName}
              </Link>
            ) : (
              summary.customer.companyName
            )}
          </strong>
        </td>
        <td>{get(summary, "pendingApprovals", 0)}</td>
        <td>{summary.oldestApproval && <AgeBadge from={get(summary, "oldestApproval")} />}</td>
      </tr>
    );
  }

  render() {
    const { expandedRows } = this.state;
    const { summary } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <h3>Pending Approvals</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive bordered={true} hover style={{ overflow: "visible" }}>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Outstanding Approvals</th>
                  <th>Oldest Approval</th>
                </tr>
              </thead>
              <tbody>
                {get(summary.parent, "pendingApprovals", 0) > 0 &&
                  this.render_row(summary.parent, summary.included ? 0 : -1)}
                {expandedRows.indexOf(summary.parent.customer.companyName) >= 0 &&
                  map(this.sortChildren(summary.children), c => this.render_row(c, summary.included ? 1 : -1))}
                {summary.included &&
                  summary.included.map(include => {
                    let retval = [this.render_row(include.parent, 0)];
                    expandedRows.indexOf(include.parent.customer.companyName) >= 0 &&
                      retval.push(map(this.sortChildren(include.children), summary => this.render_row(summary, 1)));
                    return retval;
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}
