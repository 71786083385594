import React, { useMemo } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { isNull } from "lodash";
import { autoclose } from "components/Modals";
import { CARD_HEADER_ACTION_DROPDOWN_CLASS } from "components/Card";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import { getReportedAsLabel } from "data/reportReasons";

const ReportAsDropdown = ({ review, reportAs }) => {
  const reportAsOptions = useMemo(
    () => [
      { label: "Clear", value: null },
      ...Object.entries(review.canReportAs || [])
        .map(entry => ({ label: entry[1], value: entry[0] }))
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
    ],
    [review]
  );

  const confirm = useConfirmationDialog();
  const composeConfirmTitle = useMemo(
    () => value =>
      "Are you sure you want to " +
      (isNull(value)
        ? `clear the ${getReportedAsLabel(review).toLowerCase()} status for this review?`
        : `report this review as ${getReportedAsLabel(review, value).toLowerCase()}?`),
    [review]
  );

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle caret className={CARD_HEADER_ACTION_DROPDOWN_CLASS} data-cy="report-as-dropdown">
        {getReportedAsLabel(review) || "Report as"}
      </DropdownToggle>
      <DropdownMenu>
        {reportAsOptions
          .filter(({ value }) => (review.reportedAs ? value !== review.reportedAs : !!value))
          .map(({ label, value }) => (
            <DropdownItem
              onClick={() =>
                autoclose(
                  confirm({
                    title: composeConfirmTitle(value)
                  })
                ).then(v => v && reportAs(value))
              }
            >
              {label}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default ReportAsDropdown;
