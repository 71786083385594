import classNames from "classnames";
import { Card, CardHeader, CardBody } from "components/Card";
import styles from "./WidgetCard.module.scss";

const {
  widgetCardClass,
  widgetBodyClass,
  widgetHeadingClass,
  descriptionTextClass,
  largeCard,
  rigidCardClass
} = styles;

const WidgetCard = ({ children, heading, large, rigid = true, headingInfo, className, bodyClassName }) => (
  <Card className={classNames(widgetCardClass, className, { [largeCard]: large, [rigidCardClass]: rigid })}>
    {!!heading && (
      <CardHeader>
        <div className={widgetHeadingClass}>
          <h4>{heading}</h4>
          <div className={descriptionTextClass}>{headingInfo}</div>
        </div>
      </CardHeader>
    )}
    <CardBody className={classNames(widgetBodyClass, bodyClassName)}>{children}</CardBody>
  </Card>
);

export default WidgetCard;
