import React, { useCallback } from "react";
import DatePicker from "../../../components/DateTime/DatePicker";
import { useReportSharedTimerangeState } from "../../../hooks/stateHooks";

export default () => {
  const [timerange, setTimerange] = useReportSharedTimerangeState();
  const onChange = useCallback((s, e) => setTimerange([s, e]), []);

  return (
    <DatePicker start={timerange[0]} end={timerange[1]} quickSelect={true} onChange={onChange} numberOfMonths={2} />
  );
};
