import React, { useState, useCallback } from "react";
import { InputGroup, InputGroupText, Row } from "reactstrap";
import InputField from "components/Form/InputField";
import WWButton from "components/Buttons/WWButton";
import RemoveFieldControl from "components/Form/RemoveFieldControl";
import FormField from "components/Form/FormField";
import PhoneNumberField from "components/Form/PhoneNumberField";
import ListingFieldLabel from "./ListingFieldLabel";
import { LISTINGS_LINKS } from "constants/links";
import WWAccordionSection from "components/WWAccordion/WWAccordionSection";

import styles from "./Forms.module.scss";
import Input from "components/Form/Input";

const { inlineInputClass, addCategoryClass } = styles;

const ADDITIONAL_PHONE = "additionalPhoneNumbers";
const EMAIL = "emails";
const MAX_ADDITIONAL_PHONES_COUNT = 2;
const MAX_EMAILS_COUNT = 3;

const ContactForm = ({ initialValues }) => {
  const { additionalPhoneNumbers, emails } = initialValues;
  const [additionalPhoneNumberFields, setAdditionalPhoneNumberFields] = useState(
    additionalPhoneNumbers.length > 0
      ? additionalPhoneNumbers.map((phone, index) => `${ADDITIONAL_PHONE}[${index}]`)
      : []
  );
  const [emailFields, setEmailFields] = useState(
    emails.length > 0 ? emails.map((email, index) => `${EMAIL}[${index}]`) : []
  );
  const [additionalPhoneNumbersNextIndex, setAdditionalPhoneNumbersNextIndex] = useState(
    additionalPhoneNumberFields.length
  );
  const [emailsNextIndex, setEmailsNextIndex] = useState(emailFields.length);

  const addPhoneNumber = useCallback(() => {
    setAdditionalPhoneNumberFields(
      additionalPhoneNumberFields.concat([`${ADDITIONAL_PHONE}[${additionalPhoneNumbersNextIndex}]`])
    );
    setAdditionalPhoneNumbersNextIndex(prev => prev + 1);
  }, [additionalPhoneNumberFields, additionalPhoneNumbersNextIndex]);

  const removePhoneNumber = useCallback(
    field => {
      setAdditionalPhoneNumberFields(additionalPhoneNumberFields.filter(phoneField => phoneField !== field));
    },
    [additionalPhoneNumberFields]
  );

  const addEmail = useCallback(() => {
    setEmailFields(emailFields.concat([`${EMAIL}[${emailsNextIndex}]`]));
    setEmailsNextIndex(prev => prev + 1);
  }, [emailFields, emailsNextIndex]);

  const removeEmail = useCallback(
    field => {
      setEmailFields(emailFields.filter(emailField => emailField !== field));
    },
    [emailFields]
  );

  return (
    <>
      <Row>
        <h5 className="mb-1">Phone Number</h5>
        <ListingFieldLabel link={LISTINGS_LINKS.phoneNumber}>
          Provide a number that connects directly to your location.
        </ListingFieldLabel>
        <div className={inlineInputClass}>
          <FormField name="primaryPhone" label="Primary Phone" inline>
            <PhoneNumberField name="primaryPhone" label="Primary Phone" />
          </FormField>
        </div>
        {additionalPhoneNumberFields.map(field => (
          <div className={inlineInputClass} key={field}>
            <FormField
              name={field}
              label="Additional Phone"
              additionalControl={
                <RemoveFieldControl
                  className="ps-3 pe-2 mb-2"
                  forField={field}
                  onClick={() => removePhoneNumber(field)}
                />
              }
              inline
            >
              <PhoneNumberField name={field} label="Additional Phone" />
            </FormField>
          </div>
        ))}
        {additionalPhoneNumberFields.length < MAX_ADDITIONAL_PHONES_COUNT && (
          <div className={addCategoryClass}>
            <WWButton iconClass="fa fa-plus" color="link" onClick={addPhoneNumber} className="pe-0 mt-1">
              Add phone number
            </WWButton>
          </div>
        )}
        <WWAccordionSection
          borderless={true}
          caption="Tracking Phone Numbers (Advanced)"
          bodyClassName="py-0"
          openOnInit={initialValues.facebookPhone || initialValues.googlePhone || initialValues.listingAggregatorPhone}
          subCaption={
            <ListingFieldLabel>
              Tracking phone numbers allow you to send a different primary phone number to each directory, allowing you
              to track where your listing is being found.
            </ListingFieldLabel>
          }
        >
          <div className={inlineInputClass}>
            <FormField name="googlePhone" label="Google Tracking Phone">
              <PhoneNumberField name="googlePhone" label="Google Tracking Phone" />
            </FormField>
          </div>
          <div className={inlineInputClass}>
            <FormField name="facebookPhone" label="Facebook Tracking Phone">
              <PhoneNumberField name="facebookPhone" label="Facebook Tracking Phone" />
            </FormField>
          </div>
          <div className={inlineInputClass}>
            <FormField name="listingAggregatorPhone" label="Aggregator Tracking Phone">
              <PhoneNumberField name="listingAggregatorPhone" label="Aggregator Tracking Phone" />
            </FormField>
          </div>
        </WWAccordionSection>
      </Row>
      <Row className="my-4">
        <h5 className="mb-1">Email Addresses</h5>
        <ListingFieldLabel>
          Provide any email addresses you want to associate with your business. Not supported by Google.
        </ListingFieldLabel>
        {emailFields.map(field => (
          <div className={inlineInputClass} key={field}>
            <FormField
              name={field}
              label="Email address"
              additionalControl={
                <RemoveFieldControl className="ps-3 pe-2 mb-2" forField={field} onClick={() => removeEmail(field)} />
              }
              inline
            >
              <InputField name={field} />
            </FormField>
          </div>
        ))}
        {emailFields.length < MAX_EMAILS_COUNT && (
          <div className={addCategoryClass}>
            <WWButton iconClass="fa fa-plus" color="link" onClick={addEmail} className="pe-0 mt-1">
              Add email address
            </WWButton>
          </div>
        )}
      </Row>
      <Row>
        <h5 className="mb-1">Website</h5>
        <ListingFieldLabel link={LISTINGS_LINKS.website}>Add the link to your website.</ListingFieldLabel>
        <InputField name="websiteUri" />
      </Row>
      <Row>
        <WWAccordionSection
          borderless={true}
          caption="URL Parameters (Advanced)"
          bodyClassName="py-0"
          subCaption={
            <ListingFieldLabel>
              URL parameters are elements added onto a URL for click tracking. For example, if you want to track people
              visiting your website from Facebook, you could create Facebook Tracking Parameters.
            </ListingFieldLabel>
          }
        >
          <FormField className="mb-3" label="Facebook Tracking Parameters" name="facebookUrlParameters">
            <InputGroup>
              <InputGroupText>?</InputGroupText>
              <Input name="facebookUrlParameters" type="text" placeholder="Facebook Tracking Parameters" />
            </InputGroup>
          </FormField>
          <FormField className="mb-3" label="Google Tracking Parameters" name="googleUrlParameters">
            <InputGroup>
              <InputGroupText>?</InputGroupText>
              <Input name="googleUrlParameters" type="text" placeholder="Google Tracking Parameters" />
            </InputGroup>
          </FormField>
          <FormField label="Aggregator Tracking Parameters" name="listingsAggregatorUrlParameters">
            <InputGroup>
              <InputGroupText>?</InputGroupText>
              <Input name="listingsAggregatorUrlParameters" type="text" placeholder="Aggregator Tracking Parameters" />
            </InputGroup>
          </FormField>
        </WWAccordionSection>
      </Row>
    </>
  );
};

export default React.memo(ContactForm);
