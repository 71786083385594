import WWChip from "components/WWChips/WWChip/WWChip";
import { useFilterValueState } from "hooks/filteringHooks";
import { valueOf } from "util/functionUtils";
import WWButton from "components/Buttons/WWButton";

const ButtonFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <ButtonFilterBar key={props.name} className={className} {...props} />
  );
  const chip = ({ ...props }, className) => <ButtonChip key={props.name} {...props} className={className} />;
  const filterComponent = ({ ...props }, className) => (
    <ButtonFilterSidebar key={props.name} {...props} className={className} />
  );
  const actionButton = ({ ...props }, className) => (
    <ButtonFilterBar key={props.name} className={className} {...props} />
  );

  return {
    barComponent,
    chip,
    filterComponent,
    actionButton
  };
};

export const ButtonFilterBar = ({ className, offCanvas, name, label, icon, onClick, color = "link", ...props }) => {
  return (
    <WWButton
      badgeResponsiveBreakpoint={null}
      color={color}
      iconClass={icon}
      className={className}
      onClick={onClick}
      {...props}
    >
      {label}
    </WWButton>
  );
};

export const ButtonChip = ({ name, className, iconClass, defaultValue, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return value ? (
    <WWChip
      iconClass={iconClass}
      key={name}
      className="overflow-visible"
      onClick={() => setter(valueOf(defaultValue))}
      {...props}
    >
      {value}
    </WWChip>
  ) : null;
};

export const ButtonFilterSidebar = ({ className, offCanvas, name, label, icon, onClick, color = "link", ...props }) => {
  return (
    <WWButton color={color} iconClass={icon} className={className} onClick={onClick} {...props}>
      {label}
    </WWButton>
  );
};

export default ButtonFactory;
