import React from "react";
import map from "lodash/map";
import slice from "lodash/slice";
import filter from "lodash/filter";
import indexOf from "lodash/indexOf";
import isEmpty from "lodash/isEmpty";
import FacebookLinkPreview from "./FacebookLinkPreview";

export default function FacebookPostExample({
  postText = "Create a post",
  attachments = [],
  posterName = "Your Business",
  likes = 200,
  comments = 20,
  ...props
}) {
  let attachmentFragment;
  if (attachments) {
    const images = filter(
      attachments,
      a => a.type === "PHOTO" && (isEmpty(a.sources) || indexOf(a.sources, "FACEBOOK") >= 0)
    );
    if (!isEmpty(images)) {
      attachmentFragment = (
        <div className="facebook-example-images">
          <div className="top-images">
            <div
              key={images[0].target}
              className="facebook-example-image"
              alt="Post attachment"
              style={{ backgroundImage: "url(" + images[0].target + ")" }}
            ></div>
          </div>
          <div className="bottom-images">
            {map(slice(images, 1, 4), img => {
              return (
                <div
                  key={img.target}
                  className="facebook-example-image"
                  alt="Post attachment"
                  style={{ backgroundImage: "url(" + img.target + ")" }}
                ></div>
              );
            })}
          </div>
        </div>
      );
    } else {
      const link = attachments.find(a => a.type === "LINK");
      if (link) {
        attachmentFragment = <FacebookLinkPreview url={link.target} />;
      }
    }
  }

  return (
    <div className="facebook-example-post">
      <div className="facebook-example-header">
        <div className="facebook-image-circle text-center">
          <i className="fa fa-building" aria-hidden="true"></i>
        </div>
        <div>
          <div className="name-title">{posterName}</div>
          <div className="datetime-subtitle">Now</div>
        </div>
      </div>
      <div className="facebook-example-body">{postText}</div>
      {attachmentFragment}
      <div className="facebook-example-status">
        <div className="facebook-example-likes">
          <i className="fa fa-thumbs-up" aria-hidden="true"></i> {likes.toLocaleString()}
        </div>
        <div className="facebook-example-comments">{comments > 1 ? `${comments} Comments` : `${comments} Comment`}</div>
      </div>
      <div className="facebook-example-icons color-primary"></div>
    </div>
  );
}
