// Implementing current reducer behave like simple FIFO cache.
// This is important due to this reducers gonna process
// part of the state that responsible for a customers
// caching after fethcing them from backend. Hence, we
// need it to take managable amount of memory, instead of
// growing uncontrollable.

// Limit of how many customer items could be stored at max.
export const CACHE_LIMIT = 100;

// Redux state key where to put cached customers
export const CACHE_STATE_KEY = "cacheCustomers";

// Initial value for cache
export const CACHE_INITIAL_VALUE = [];

/**
 * @param {any[]} state
 * @param {{ type: "CACHE_MORE_CUSTOMERS" | "CACHE_CUSTOMERS_INVALIDATE", payload: any[] }} action
 */
export default (state, action) =>
  action.type === "CACHE_CUSTOMERS_MERGE"
    ? [
        ...action.payload.map(info => ({
          // merging fresh and cached data for cache not to be destructive
          ...(state.find(v => v.id === info.id) || {}),
          ...info
        })),
        ...state
          .filter(info => action.payload.findIndex(({ id }) => info.id === id) < 0) // preventing cache from storing diplicates
          .slice(0, Math.max(0, CACHE_LIMIT - action.payload.length)) // limiting cache by CACHE_LIMIT
      ]
    : action.type === "CACHE_CUSTOMERS_INVALIDATE"
    ? state.filter(({ id }) => action.payload.indexOf(id) < 0)
    : state || CACHE_INITIAL_VALUE;
