import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import CopyButton from "../../../components/Buttons/CopyButton";
import NewQuestion from "../NewQuestion";
import debounce from "lodash/debounce";
import isArray from "lodash/isArray";
import InputField from "../../../components/Form/InputField";
import { updateCompanyNameOverride } from "../../../api/customerApi";
import map from "lodash/map";
import { Link } from "react-router-dom";
import withConfirmation from "../../../components/Form/withConfirmation";
import SelectField from "components/Form/SelectField";
import { useGoogleAccounts, useGoogleLocations } from "hooks/data/googleApi";
import { DEBOUNCE_DELAY_500_MS } from "constants/delays";
import { join } from "../../../util/stringUtils";

export default class Google {
  field() {
    return "gmbInfo";
  }

  confirmationPrompt({ duplicates, agency }) {
    return (
      <>
        <span>{"Already linked to: "}</span>
        <ul className={`m-0 ${duplicates.length > 1 ? "" : "list-unstyled"}`}>
          {map(duplicates, (duplicate, idx) => (
            <>
              <Link to={`/customers/${duplicate.id}`}>{`${duplicate.companyName}`}</Link>

              {duplicate.agency.id !== agency.id && <>{` (${duplicate.agency.name})`}</>}
            </>
          ))}
        </ul>
      </>
    );
  }

  add() {
    const AddGoogle = ({ customer, onSave }) => {
      const [selectedLocation, setSelectedLocation] = useState();
      const [selectedAccount, setSelectedAccount] = useState();
      const [reviewUrl, setReviewUrl] = useState();
      const [filter, setFilter] = useState();
      const [inputValue, setInputValue] = useState();
      const { data: accounts, isFetching: isFetchingAccounts } = useGoogleAccounts(filter);
      const { data: locations, isFetching: isFetchingLocations } = useGoogleLocations(filter, selectedAccount?.name);
      const filterChangeHandler = useCallback(
        debounce(input => setFilter(input), DEBOUNCE_DELAY_500_MS),
        []
      );
      useEffect(() => {
        filterChangeHandler(inputValue);
      }, [inputValue]);

      return (
        <>
          <FormField label="Accounts">
            <SelectField
              name="location"
              className="w-100"
              isLoading={isFetchingAccounts}
              options={accounts || []}
              getOptionLabel={o => `${o.accountName} - ${o.accountNumber}`}
              getOptionValue={o => o.name}
              isSearchable={true}
              placeholder="Select an account"
              onChange={v => setSelectedAccount(v.target.value)}
              value={selectedAccount}
            />
          </FormField>
          <FormField label="Location">
            <SelectField
              name="location"
              className="w-100"
              isLoading={isFetchingLocations}
              isDisabled={!selectedAccount}
              options={locations || []}
              getOptionLabel={o =>
                o.duplicate ? "(DUPLICATE)" : "" + join([o.locationName, o.storeCode, o.address], " - ")
              }
              getOptionValue={o => o.location}
              isSearchable={true}
              inputValue={inputValue}
              onInputChange={setInputValue}
              placeholder="Type to search..."
              onChange={v => setSelectedLocation(v.target.value)}
              value={selectedLocation}
            />
          </FormField>
          <InputField label="Review Url" value={reviewUrl} onChange={e => setReviewUrl(e.target.value)} />
          <FormField label=" ">
            <Button
              disabled={!selectedLocation}
              color="primary"
              onClick={() => {
                if (reviewUrl) {
                  onSave({ source: "GOOGLE", reviewUrl });
                } else {
                  onSave({
                      location: selectedLocation.location,
                      account: selectedLocation.account,
                      businessName: selectedLocation.locationName,
                      source: "GOOGLE",
                      address: selectedLocation.address,
                      reviewUrl: selectedLocation.reviewUrl
                  });
                }
              }}
            >
              Link
            </Button>
          </FormField>
        </>
      );
    };
    return AddGoogle;
  }

  edit() {
    const EditGoogle = ({ info, customer, refreshSite }) => {
      const [newQuestion, setNewQuestion] = useState(false);
      const saveCompanyName = useCallback(
        name => updateCompanyNameOverride(customer.id, "gmbInfo", info.id, name),
        [info.id, customer.id]
      );

      return (
        <>
          {info.businessName && (
            <>
              <FormField label="Location">
                <>
                  <a
                    href={
                      "https://www.google.com/search?q=" +
                      info.businessName +
                      " " +
                      (info.address || customer.fullAddress)
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {info.businessName + "  "} <i className="fa fa-external-link" />
                  </a>
                  <Button size="sm" color="link" onClick={refreshSite}>
                    <i className="icon-refresh"></i>
                  </Button>
                  <br />
                  {info.address}
                </>
              </FormField>
              <InputField
                name="companyNameOverride"
                label="Name Override"
                value={info.companyNameOverride}
                onChange={evt => saveCompanyName(evt.target.value)}
              />
            </>
          )}
          {info.location && (
            <>
              <FormField label="ID">
                <>
                  <span className="text-truncate w-auto d-inline-block">{info.location}</span>
                  <CopyButton className="ms-1" text={info.account + "/" + info.location} />
                </>
              </FormField>
              <FormField label="Actions">
                <Button size="sm" onClick={() => setNewQuestion(true)}>
                  New Question
                </Button>
              </FormField>

              <NewQuestion
                siteInfoId={info.id}
                customerId={customer.id}
                isOpen={newQuestion}
                onClose={() => setNewQuestion(false)}
              />
            </>
          )}
        </>
      );
    };
    return EditGoogle;
  }

  duplicateWarning() {
    const OverrideWarning = ({ duplicates, agency, info, onOverride = () => {} }) => {
      const DuplicateRow = ({ duplicate, agency }) => {
        return (
          <>
            <Link to={`/customers/${duplicate.id}`}>{`${duplicate.companyName}`}</Link>

            {duplicate.agency.id !== agency.id && <>{` (${duplicate.agency.name})`}</>}
            <br />
          </>
        );
      };

      const confirmationPrompt = ({ duplicates, agency }) => {
        return (
          <>
            <span>{"Already linked to: "}</span>
            <br />
            {map(duplicates, (duplicate, idx) => (
              <DuplicateRow duplicate={duplicate} agency={agency} key={duplicate.id} />
            ))}
            <span>{"Link anyway?"}</span>
          </>
        );
      };

      const HiddenButton = useMemo(
        () =>
          withConfirmation(
            <span onClick={() => onOverride(info)} color="warning"></span>,
            { showDialog: true },
            true,
            confirmationPrompt({ duplicates, agency })
          ),
        [info, duplicates]
      );

      if (!isArray(duplicates)) duplicates = [duplicates];
      if (duplicates && duplicates.length > 0) {
        return <>{<HiddenButton />}</>;
      } else {
        return <></>;
      }
    };
    return OverrideWarning;
  }
}
