import React, { useCallback, useState } from "react";
import { PopupModal } from "react-calendly";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";

export default function ScheduleMeetingButton({ calendar, text = "Click To Schedule A Meeting" }) {
  const user = useSelector(state => state.cognito.user);
  const [isOpen, setOpen] = useState(false);
  const prefill = {
    email: user.email,
    name: user.name
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        {text}
      </Button>
      {isOpen && (
        <PopupModal
          open={isOpen}
          url={calendar}
          prefill={prefill}
          onModalClose={() => setOpen(false)}
          rootElement={document.getElementById("root")}
        />
      )}
    </>
  );
}
