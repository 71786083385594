import { useCallback } from "react";
import { useLocationQueryParamState } from "../stateHooks";
import { isNil, toString } from "lodash";

export const DEFAULT_PAGE_SIZE = 10;

export const useFrontendPagination = (dataSet, paramName = "page", pageSize = DEFAULT_PAGE_SIZE) => {
  if (isNaN(pageSize) || pageSize < 1) {
    throw new Error("Page size should be a number more than 0");
  }
  const [page, setPage] = useLocationQueryParamState(paramName, { read: v => (isNaN(+v) || v < 0 ? 0 : +v) });
  const onUpdatePage = useCallback(
    newPage => {
      const dataSetLength = dataSet?.length || 0;
      const pageCountExceeded = newPage * pageSize >= dataSetLength;
      const maxPage = Math.ceil(dataSetLength / pageSize);
      if (pageCountExceeded) {
        setPage(maxPage);
      } else if (newPage >= 0) {
        setPage(newPage);
      }
    },
    [dataSet]
  );
  return [page, onUpdatePage];
};

export default useFrontendPagination;
