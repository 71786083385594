import React, { useCallback } from "react";
import InputField from "../../../components/Form/InputField";
import { useNativeInputState } from "../../../hooks/utilHooks";
import { Button } from "reactstrap";

export default class SwickardAdminLinking {
  field() {
    return "swickardInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info }) => {
  const [dmsId, setDmsId] = useNativeInputState(info.dmsId);

  return (
    <>
      <InputField
        type="text"
        label="DMS ID"
        placeholder="DMS ID"
        onChange={e => setDmsId(e.target.value)}
        value={dmsId}
        disabled={true}
      />
    </>
  );
};

const Add = ({ onSave }) => {
  const [dmsId, setDmsId] = useNativeInputState("");
  const onLink = useCallback(
    () =>
      onSave({ source: "SWICKARD", dmsId }),
    [dmsId, onSave]
  );

  return (
    <>
      <InputField
        type="text"
        label="DMS ID"
        placeholder="DMS ID"
        onChange={setDmsId}
        value={dmsId}
      />
      <Button color="primary" disabled={!dmsId} onClick={onLink}>
        Link
      </Button>
    </>
  );
};
