import { equals, reject, toPairs } from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import memoize from "memoizee";
import { Card } from "components/Card";
import Entry, { LoadingSkeleton } from "components/Invite/Entry/Entry";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import { useModal } from "components/Modals";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import WWButton from "components/Buttons/WWButton";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import usePureMemo from "hooks/usePureMemo/usePureMemo";
import useVideoTestimonialRemover from "hooks/data/useVideoTestimonialRemover";
import useFilterQuery from "hooks/data/useFilterQuery";
import { videoLibraryPrepareFilters, normalizeVideoResponse } from "util/dataUtils/videoDataUtils";

import styles from "./Video.module.scss";
import { useCategoriesForCurrentUser } from "hooks/data/tagSetHooks";

const { container, entryWrapper, detailed, grid, removalButtonClass, removalMainContent, removalModalBody } = styles;

const VIDEO_LIVE_STATUS = "LIVE";

const getFilters = memoize(tags => [
  {
    name: "dateRange",
    label: "Date range",
    type: "dates",
    queryParams: ["startDate", "endDate"],
    primaryBar: {
      position: "NONE"
    }
  },
  {
    name: "location",
    label: "Locations",
    type: "allLocations",
    primaryBar: {
      position: "LEFT"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "campaign",
    label: "Campaign",
    type: "campaign",
    minLength: 0,
    placeholder: "Search Campaigns...",
    primaryBar: {
      position: "LEFT",
      className: "d-none d-md-flex"
    },
    secondaryBar: {
      className: "d-flex d-md-none me-5"
    },
    chip: {
      position: "NONE"
    },
    additionalParams: {
      defaultSearchParams: {
        types: ["VIDEO", "VIDEO_FOLLOW_UP"]
      }
    }
  },
  {
    name: "tags",
    label: "Categories",
    type: "checkbox",
    primaryBar: {
      position: "NONE"
    },
    options: tags
  },
  {
    name: "author",
    label: "Author",
    type: "text",
    icon: "fa fa-user"
  },
  {
    name: "sort",
    label: "Created On",
    type: "sort",
    defaultValue: "createdOn,desc",
    required: true,
    options: ["createdOn,asc", "createdOn,desc"],
    nonFilter: true,
    primaryBar: {
      position: "RIGHT"
    },
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "page",
    defaultValue: 0,
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  }
]);

export default function Video() {
  const [initialVideoId, setInitialVideoId] = useState(null);
  const categoriesQuery = useCategoriesForCurrentUser();
  const categories = categoriesQuery.data || [];
  const filters = getFilters(categories);

  const { hash, ...rest } = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    const id = hash?.slice(1);
    if (id) {
      setInitialVideoId(id);
    }
  }, []); // eslint-disable-line
  //only want on load to set initial video id instead of each hash change

  const openDetails = useCallback(
    id => () => {
      setInitialVideoId(null);
      push({ hash: id, ...rest });
    },
    [rest, push]
  );
  const closeDetails = useCallback(() => {
    setInitialVideoId(null);
    push({ ...rest });
  }, [rest, push]);

  const [removeVideo] = useVideoTestimonialRemover();
  const riseModal = useModal();
  const confirmRemoval = useCallback(
    id =>
      riseModal(resolve => <RemovalConfirmationModalBody onConfirm={resolve} onCancel={resolve} />).then(
        ([close, resolution]) => {
          if (resolution !== undefined) {
            return removeVideo(id, resolution).then(close);
          } else {
            close();
          }
        }
      ),
    [removeVideo, riseModal]
  );

  const { data, isLoading } = useFilterQuery({
    filters,
    prepareFilters:
      initialVideoId === null
        ? videoLibraryPrepareFilters
        : () => ({
            id: initialVideoId
          }),
    normalizeResponse: normalizeVideoResponse,
    additionalParams: { size: 15, status: VIDEO_LIVE_STATUS },
    url: "/interactions/media"
  });

  const entries = data?.entries || [];
  const pageInfo = data?.pageInfo;

  const selectedVideo = usePureMemo(
    (list, h) => {
      const target = h?.slice(1);
      if (!!target) {
        const appropriateVideo = list.find(v => v?.id === target);
        if (!!appropriateVideo) {
          return appropriateVideo;
        }
      } else {
        return null;
      }
    },
    entries,
    hash
  );

  return (
    <div className={`py-4 px-xs-0 px-lg-4 container-fluid ${container}`}>
      <FacetedSearch filters={filters} />
      <div className={grid}>
        {isLoading ? (
          new Array(50).fill(null).map((_, i) => (
            <div key={i}>
              <LoadingSkeleton />
            </div>
          ))
        ) : (
          <>
            {entries.map((video, i) => (
              <div key={video?.id || i} className={entryWrapper}>
                {video ? (
                  <Entry
                    onRemove={() => confirmRemoval(video.id)}
                    onDetails={openDetails(video.id)}
                    author={video.meta.author}
                    category={video.meta.category}
                    companyName={video.meta.companyName}
                    campaign={video.meta.campaign}
                    date={video.meta.date}
                    href={video.thumbnail}
                    downloadLink={video.src ? `${video.src}?download` : null}
                    formats={video.formats}
                    redeemedIncentive={video.redeemedIncentive}
                    duration={video.duration}
                  />
                ) : (
                  <LoadingSkeleton />
                )}
              </div>
            ))}
          </>
        )}
      </div>

      <FilterPagination
        isLoading={isLoading}
        pageInfo={pageInfo}
        totalPages={pageInfo?.totalPages}
        elementsLabel="Videos"
      />

      <Modal
        scrollable
        isOpen={!!selectedVideo}
        className={detailed}
        centered={true}
        unmountOnClose={true}
        toggle={closeDetails}
      >
        {!!selectedVideo ? (
          <Card className="m-0 pt-0 overflow-hidden">
            <ModalHeader className="m-0 pe-0" toggle={closeDetails} />
            <VideoPlayer
              details={selectedVideo.meta}
              src={selectedVideo.src}
              previewSrc={selectedVideo.previewSrc}
              onRemove={() => confirmRemoval(selectedVideo.id).then(closeDetails)}
              downloadLink={selectedVideo.src ? `${selectedVideo.src}?download` : null}
              formats={selectedVideo.formats}
            />
          </Card>
        ) : null}
      </Modal>
    </div>
  );
}

const removalReasonsMap = {
  poor_quality: "Poor Quality",
  poor_content: "Poor Content",
  inappropriate: "Inappropriate Content",
  other: "Other"
};

const RemovalConfirmationModalBody = ({ onConfirm, onCancel }) => {
  const [choices, setChoices] = useState([]);
  const onToggle = useCallback(
    option => () =>
      setChoices(prev => {
        const filtered = reject(equals(option))(prev);
        return filtered.length === prev.length ? [...filtered, option] : filtered;
      }),
    []
  );
  const cancel = useCallback(() => onCancel(), [onCancel]);
  const confirm = useCallback(() => onConfirm(choices.map(k => removalReasonsMap[k]).join(", ")), [choices, onConfirm]);
  return (
    <>
      <ModalHeader toggle={cancel} />
      <ModalBody className={removalModalBody}>
        <div className={removalMainContent}>
          <h3>Reason for Removal</h3>
          <div>
            {toPairs(removalReasonsMap).map(([key, value]) => (
              <Labeled key={key} checked={choices.includes(key)} onToggle={onToggle(key)} label={value} />
            ))}
          </div>
        </div>
        <WWButton className={removalButtonClass} color="danger" onClick={confirm}>
          Remove
        </WWButton>
      </ModalBody>
    </>
  );
};
