import { find, indexOf, isEmpty, map, noop } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import { loadContact } from "api/customerApi";
import { createLead } from "api/leadsApi";
import CustomerFilter from "components/Customer/CustomerFilter";
import DatePicker from "components/DateTime/DatePicker";
import AjaxLoader from "components/Misc/AjaxLoader";
import StatusMessages from "components/Misc/StatusMessages";
import { autoclose } from "components/Modals";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import CampaignSelector from "components/Campaigns/CampaignSelector/CampaignSelector";
import { permissions } from "components/Auth/permissions";
import { Staff } from "./Staff";
import { is, validEmail, validMobile } from "util/validators";
import useCustomers, { useActiveInviteCustomerLoader } from "hooks/customerHooks";
import { useURLHashSetter, useURLHashValue } from "hooks/urlHooks";
import { currentUserFullName } from "util/userUtils";
import { salutationName, SUPPORTED_SLUTATIONS } from "data/options";
import WWButton from "components/Buttons/WWButton";
import { inviteCustomerProductsKeys } from "data/products";

export const CREATE_INVITATION_MODEL_HASH = "leadCreate";

export function useInviteModalToggle() {
  const setUrlHash = useURLHashSetter();
  return useCallback(
    show => {
      if (show === undefined) {
        setUrlHash(hash => (hash === CREATE_INVITATION_MODEL_HASH ? "" : CREATE_INVITATION_MODEL_HASH));
      } else if (show) {
        setUrlHash(CREATE_INVITATION_MODEL_HASH);
      } else {
        setUrlHash("");
      }
    },
    [setUrlHash]
  );
}

const getRepresentative = (name = currentUserFullName()) => {
  const splitted = name.split(/\s+/);
  return [splitted[0], splitted.slice(1).join(" ")];
};

export const UncontrolledCreateInvitationModal = ({ onInvitationSent = noop }) => {
  const hash = useURLHashValue();
  const toggleInviteModal = useInviteModalToggle();
  const toggleHandler = useCallback(() => toggleInviteModal(undefined), [toggleInviteModal]);

  return (
    <>
      <Modal isOpen={hash === CREATE_INVITATION_MODEL_HASH} toggle={toggleHandler}>
        <ModalHeader toggle={toggleHandler} />
        <ModalBody className="text-center">
          <CreateInviteWizard onInvitationSent={onInvitationSent} />
        </ModalBody>
      </Modal>
    </>
  );
};

export const CreateInviteWizard = ({ onInvitationSent = noop }) => {
  const [stage, setStage] = useState("waiting");
  const [errors, setErrors] = useState([]);
  const [department, setDepartment] = useState("Sales");
  const [defaultRepresentative, setDefaultRepresentative] = useState(getRepresentative());

  useEffect(() => {
    loadContact("me", "interaction").then(({ data: { tags, name } }) => {
      setDepartment((tags || "").toLocaleLowerCase().includes("service") ? "Service" : "Sales");
      setDefaultRepresentative(getRepresentative(name));
    });
  }, []);

  const customers = useCustomers(
    {
      status: "ACTIVE",
      projection: "interaction",
      product: inviteCustomerProductsKeys,
      matchAllProducts: false,
      withProperties: true
    },
    {
      onSuccess: customers => {
        if (!isEmpty(customers)) {
          setStage("form");
        } else {
          setErrors(["Current user has no customers who can send Invites. Please contact support."]);
          setStage("failure");
        }
      },
      onError: () => {
        setErrors(["Failed fetching Invite customers."]);
        setStage("failure");
      },
      refetchOnMount: "always"
    }
  );

  const confirm = useConfirmationDialog();
  const prepareLeadPayload = useCallback(
    v => ({
      ...(!!v.email ? { email: v.email } : {}),
      ...(!!v.mobile ? { mobile: v.mobile } : {}),
      firstName: v.firstName,
      lastName: v.lastName,
      salutation: v.salutation,
      optOut: false,
      sendInvitation: true,
      interactions: [
        {
          tags: [v.department],
          representatives: v.representatives,
          visitTime: v.visitTime,
          reviews: [],
          invitations: [
            {
              type: v.type
            }
          ]
        }
      ]
    }),
    []
  );

  const prepareConfirmationPayload = useCallback(
    v => ({
      body: `${v.firstName} ${v.lastName} received an invitation less than 60 days ago. Are you sure you want to send another?`
    }),
    []
  );

  return (
    <Row>
      <Col>
        {stage === "form" ? (
          <InvitationCreationForm
            customers={customers.data.customers}
            dep={department}
            defaultRepresentative={defaultRepresentative}
            onInviteAboutToSend={v => {
              setStage("waiting");
              createLead(prepareLeadPayload(v), v.customer, false, v.campaignId)
                .catch(e =>
                  e.response.status === 422
                    ? autoclose(confirm(prepareConfirmationPayload(v))).then(yes =>
                        yes ? createLead(prepareLeadPayload(v), v.customer, true, v.campaignId) : Promise.resolve(null)
                      )
                    : Promise.reject(e)
                )
                .then(v => {
                  if (v) {
                    setStage("success");
                    onInvitationSent(v.data);
                  } else {
                    setStage("form");
                  }
                })
                .catch(e => {
                  setErrors([e]);
                  setStage("failure");
                });
            }}
          />
        ) : stage === "waiting" ? (
          <AjaxLoader loading={true} />
        ) : stage === "success" ? (
          <InvitationSuccess />
        ) : stage === "failure" ? (
          <InvitationFailure errors={errors} />
        ) : null}
      </Col>
    </Row>
  );
};

/**
 *
 * @param {{
 *   onInviteAboutToSend: (payload: {
 *     department: "Sales" | "Service";
 *     representatives: string[];
 *     mobile: string;
 *     email: string;
 *     name: string;
 *     customer: string;
 *     visitTime: string;
 *   }) => void;
 *   customers: string[];
 *   dep: "Sales" | "Service";
 *   defaultRepresentative: [string, string];
 * }} param0
 */
const InvitationCreationForm = ({
  onInviteAboutToSend = noop,
  customers: locations = [],
  dep = "Sales",
  defaultRepresentative
}) => {
  const [department, setDepartment] = useState(dep);
  const [representatives, setRepresentatives] = useState([defaultRepresentative || getRepresentative()]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [location, setLocation] = useState(locations[0] && locations[0].id);
  const [campaign, setCampaign] = useState();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [visitTime, setVisitTime] = useState(new Date());
  const [formValid, setFormValid] = useState(false);
  const [availableDepartments, setAvailableDepartments] = useState((locations[0] && locations[0].reviewTags) || []);
  const [salutation, setSalutation] = useState("blank");
  const [salutationChanging, setSalutationChanging] = useState(false);
  const [hasCampaigns, setHasCampaigns] = useState(locations[0] && locations[0].properties?.campaigns === "true");

  useEffect(
    () =>
      setFormValid(
        !!location &&
          !!firstName &&
          !!visitTime &&
          !!department &&
          (!!email ? is(validEmail)(email) : !!mobile) &&
          (!!mobile ? is(validMobile)(mobile) : !!email)
      ),
    [firstName, visitTime, mobile, email, location]
  );

  useEffect(() => {
    if (availableDepartments?.length === 1 || indexOf(availableDepartments, department) === -1) {
      setDepartment(availableDepartments?.[0]);
    }
  }, [availableDepartments, department]);

  const onSubmit = useCallback(
    (e, format) => {
      e.preventDefault();
      onInviteAboutToSend({
        department,
        representatives: representatives.map(([firstName, lastName]) => ({
          firstName,
          lastName
        })),
        type: format,
        firstName,
        lastName,
        email,
        mobile,
        customer: location,
        campaignId: campaign?.id,
        visitTime,
        salutation: salutationName(salutation)
      });
    },
    [
      department,
      representatives,
      firstName,
      lastName,
      email,
      mobile,
      location,
      campaign,
      visitTime,
      salutation,
      onInviteAboutToSend
    ]
  );

  const updateFirstName = () => ({ target: { value } }) => setFirstName(value);
  const updateLastName = () => ({ target: { value } }) => setLastName(value);
  const updateLocation = location => {
    if (location) {
      setAvailableDepartments(location.reviewTags);
      setLocation(location.id);
      setHasCampaigns(location.properties?.campaigns === "true");
    } else {
      setLocation(undefined);
      setHasCampaigns(false);
    }
    setCampaign(undefined);
  };

  const customerFilterLoader = useActiveInviteCustomerLoader();

  return (
    <Form onSubmit={e => onSubmit(e)}>
      <h2 className="mb-4">Review Invite</h2>
      {locations.length <= 1 ? null : (
        <FormGroup className="text-sm-start mb-4">
          <Label>Location</Label>
          <InputGroup>
            <InputGroupText className="fa fa-building" />
            <CustomerFilter
              loader={customerFilterLoader}
              onChange={({ target }) => updateLocation(target.value[0])}
              value={[location]}
            />
          </InputGroup>
        </FormGroup>
      )}
      {location && hasCampaigns && (
        <FormGroup className="text-center text-sm-start mb-4">
          <Label>Campaign</Label>
          <InputGroup>
            <InputGroupText className="fa fa-bullhorn" />
            <CampaignSelector
              className="flex-fill"
              withIcon={false}
              customerId={location}
              enabled={hasCampaigns}
              onChange={campaign => setCampaign(campaign)}
            />
          </InputGroup>
        </FormGroup>
      )}
      <FormGroup className="d-flex align-items-stretch flex-column text-center text-sm-start mb-4">
        <Label>Visit Time</Label>
        <UncontrolledDropdown>
          <DropdownToggle tag="div">
            <InputGroup>
              <InputGroupText className="fa fa-calendar" />
              <Input type="text" value={moment(visitTime).format("L")} onChange={noop} />
            </InputGroup>
          </DropdownToggle>
          <DropdownMenu className="p-0 bg-transparent border-0 overflow-visible">
            <DatePicker range={false} start={moment(visitTime)} onStartChange={v => setVisitTime(v.toDate())} />
          </DropdownMenu>
        </UncontrolledDropdown>
      </FormGroup>
      <FormGroup className="text-center text-sm-start mb-4">
        <Label>Customer</Label>
        <FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText className="fa fa-at" />
              <Input
                type="email"
                placeholder="email"
                value={email}
                onChange={({ target: { value } }) => setEmail(value)}
                invalid={!!email && !is(validEmail)(email)}
              />
            </InputGroup>
            <Input type="hidden" invalid={!!email && !is(validEmail)(email)} />
            <FormFeedback>Please enter correct email</FormFeedback>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText className="fa fa-phone" />
              <Input
                type="tel"
                placeholder="mobile"
                value={mobile}
                mask="999-999-9999"
                maskChar="_"
                onChange={({ target: { value } }) => setMobile(value.replace(/[-\s]/gi, ""))}
                tag={ReactInputMask}
              />
            </InputGroup>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroupText className="fa fa-user" />
            <ButtonDropdown isOpen={salutationChanging} toggle={() => setSalutationChanging(v => !v)}>
              <DropdownToggle color="white" className="border-info" caret>
                {salutationName(salutation) || <span className="text-muted text-capitalize">{"Prefix"}</span>}
              </DropdownToggle>
              <DropdownMenu>
                {SUPPORTED_SLUTATIONS.filter(v => v !== salutation).map(v => (
                  <DropdownItem key={v} onClick={() => setSalutation(v)}>
                    {salutationName(v) || <span className="text-muted">{"<blank>"}</span>}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
            <Input type="text" placeholder="first name" value={firstName} onChange={updateFirstName()} />
            <Input type="text" placeholder="last name" value={lastName} onChange={updateLastName()} />
          </InputGroup>
          <Input type="hidden" invalid={!firstName && (!!firstName || !!lastName)} />
          <FormFeedback>First name is required</FormFeedback>
        </FormGroup>
      </FormGroup>
      {availableDepartments?.length > 0 && (
        <FormGroup className="text-center text-sm-start mb-4">
          <Label>Department</Label>
          <Row>
            <Col className="d-flex flex-column align-items-stretch">
              <ButtonGroup className="department__nav">
                {map(availableDepartments, tag => (
                  <WWButton
                    color={department === tag ? "primary" : "light"}
                    onClick={() => setDepartment(tag)}
                    key={tag}
                  >
                    {tag}
                  </WWButton>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
        </FormGroup>
      )}
      <FormGroup className="text-center text-sm-start mb-4">
        <Label>Staff</Label>
        <Staff staff={representatives} onChange={v => setRepresentatives(v)} />
      </FormGroup>
      {hasCampaigns ? (
        <WWButton
          type="submit"
          trackingAction="Trigger Send Invite"
          disabled={!formValid || !campaign}
          color="primary"
          className="p-3 w-100"
        >
          Send Invite
        </WWButton>
      ) : (
        <FormGroup className="d-flex">
          <AuthorizationRequiredToRender roles={[permissions.REVIEW_INVITE_MANAGE]}>
            <Button type="submit" disabled={!formValid} color="primary" className="p-3 w-100">
              Send Review Invite
            </Button>
          </AuthorizationRequiredToRender>
          <AuthorizationRequiredToRender roles={[permissions.VIDEO_MANAGE]}>
            <WWButton
              type="button"
              disabled={!formValid}
              onClick={e => onSubmit(e, "VIDEO")}
              color="primary"
              className="ms-1 p-3 w-100"
            >
              Send Video Invite
            </WWButton>
          </AuthorizationRequiredToRender>
        </FormGroup>
      )}
    </Form>
  );
};

export const InvitationSuccess = () => (
  <h3>
    Success!
    <br />
    Invite has been sent.
  </h3>
);

export const InvitationFailure = ({ errors }) => {
  return (
    <>
      <h3>Something went wrong...</h3>
      <StatusMessages errors={errors[0].response?.data.errors || errors} />
    </>
  );
};
