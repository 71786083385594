import React, { useMemo } from "react";
import isBoolean from "lodash/isBoolean";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormGroup, Label, FormText, FormFeedback } from "reactstrap";
import HelpIcon from "../Misc/HelpIcon";
import styles from "./FormField.module.scss";

const { inlineInputClass, removeMargin } = styles;
/**
 *
 * @param {string} name
 * @param {string} label
 * @param {number} labelWidth - bootstrap column width
 * @param {number} inputWidth - bootstrap column width
 * @param {boolean} inline
 * @param {boolean} valid
 * @param {string} feedback
 * @param {string} tip
 * @param {string} help
 */
function FormField({
  name,
  className,
  label,
  inline = false,
  noMargin = false,
  valid,
  feedback,
  tip,
  help,
  wide = false,
  additionalControl = null,
  children
}) {
  const labelElement = useMemo(
    () =>
      typeof label === "object" ? (
        label
      ) : (
        <Label className={classnames("text-muted", "fw-normal", { "m-0": inline })} for={name}>
          {label}
          {help && <HelpIcon className="ms-1">{help}</HelpIcon>}
        </Label>
      ),
    [label]
  );

  return (
    <FormGroup
      className={classnames("align-items-center", className, {
        "d-md-flex nowrap": inline,
        [inlineInputClass]: inline,
        [removeMargin]: noMargin,
        "w-100": inline || wide
      })}
    >
      {label && labelElement}
      <div className={classnames({ "d-flex align-items-center": inline, "w-100": inline || wide })}>
        <div className="w-100">
          {children}
          {isBoolean(valid) && feedback && <FormFeedback valid={valid}>{feedback}</FormFeedback>}
          {tip && <FormText>{tip}</FormText>}
        </div>
        {additionalControl}
      </div>
    </FormGroup>
  );
}

FormField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelWidth: PropTypes.number,
  inputWidth: PropTypes.number,
  inline: PropTypes.bool,
  tip: PropTypes.string,
  feedback: PropTypes.string
};

export default FormField;
