import inviteReview from "../img/Invite_Icon_color.svg";
import inviteVideo from "../img/Invite_Video_Icon_color.svg";
import inviteVideoFollowUp from "../img/follow-up-icon_color.svg";

export const CAMPAIGN_TYPE = {
  REVIEW: "REVIEW",
  VIDEO: "VIDEO",
  VIDEO_FOLLOW_UP: "VIDEO_FOLLOW_UP"
};

export function iconForCampaignType(type) {
  if (type === "REVIEW") {
    return "bg-image-logo-invite-color";
  } else if (type === "VIDEO") {
    return "bg-image-logo--video-color";
  } else if (type === "VIDEO_FOLLOW_UP") {
    return "bg-image-logo-invite-fallow-up-color";
  }
}

export function iconUrlForCampaignType(type) {
  if (type === "REVIEW") {
    return inviteReview;
  } else if (type === "VIDEO") {
    return inviteVideo;
  } else if (type === "VIDEO_FOLLOW_UP") {
    return inviteVideoFollowUp;
  }
}
