import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import classnames from "classnames";
import Paragraph from "components/Layout/Paragraph";
import ReviewHeader from "components/Reviews/ReviewHeader";
import ReviewMessages from "components/Reviews/ReviewMessages";

import styles from "./Feed.module.scss";

const { activityModalContentClass, reviewClass, modalClass, messagesClass } = styles;

const ActivityModal = ({ opened, toggler, review }) => (
  <Modal
    className={modalClass}
    contentClassName="h-100 pd-4"
    modalClassName={activityModalContentClass}
    scrollable
    centered
    toggle={toggler}
    isOpen={opened}
  >
    <ModalHeader toggle={toggler} />
    <ModalBody>
      {review && (
        <div className="d-md-flex h-100">
          <div className={classnames("d-none d-md-block", reviewClass)}>
            <ReviewHeader review={review} spaced={false} />
            <Paragraph className="review-content mt-3 mb-0`" content={review.content} />
          </div>
          <ReviewMessages className={messagesClass} review={review} standalone />
        </div>
      )}
    </ModalBody>
  </Modal>
);

export default ActivityModal;
