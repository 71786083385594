import { intersectionBy } from "lodash";
import { adjust, groupBy, map, mergeAll, objOf, pipe, prop, toPairs } from "ramda";
import { productsSet } from "data/products";

const groupProducts = by =>
  pipe(groupBy(prop(by)), toPairs, map(pipe(adjust(0, objOf(by)), adjust(1, objOf("products")), mergeAll)));

export const groupCustomerProducts = customerProducts => {
  const mergedProducts = intersectionBy(productsSet, customerProducts, prop("product")).map(product => ({
    ...product,
    ...customerProducts.find(customerProduct => customerProduct.product === product.product)
  }));
  return groupProducts("category")(mergedProducts);
};
