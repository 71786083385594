import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import GroupEngagementTable from "./GroupEngagementTable";
import ReviewSummaryTable from "./ReviewSummaryTable";
import PendingApprovalSummaryTable from "./PendingApprovalSummaryTable";
import { WWTabs, WWTabContent, WWTabContentItem } from "components/WWTabs";
import LoadingPage from "components/Misc/LoadingPage";
import { isDdc } from "util/userUtils";

const REVIEW_SUMMARY_TAB_INDEX = "1";
const PENDING_APPROVAL_TAB_INDEX = "2";
const GROUP_ENGAGEMENT_TAB_INDEX = "3";

export default class GroupReport extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: REVIEW_SUMMARY_TAB_INDEX
    };
  }

  componentDidMount() {
    this.props.onOpenTab(this.state.activeTab === GROUP_ENGAGEMENT_TAB_INDEX);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      this.props.onOpenTab(tab === GROUP_ENGAGEMENT_TAB_INDEX);
    }
  }

  renderNoData = () => <div className="w-100 text-center text-muted">No data loaded</div>;

  render() {
    const { activeTab } = this.state;
    const { summary, engageSummary, loading, reportFilterValues } = this.props;
    const tabsOptions = [
      {
        label: "Review Summary",
        active: activeTab === REVIEW_SUMMARY_TAB_INDEX,
        onClick: () => this.toggle(REVIEW_SUMMARY_TAB_INDEX)
      },
      {
        label: "Pending Approvals",
        active: activeTab === PENDING_APPROVAL_TAB_INDEX,
        onClick: () => this.toggle(PENDING_APPROVAL_TAB_INDEX)
      },
      {
        label: "Engagement Summary",
        active: activeTab === GROUP_ENGAGEMENT_TAB_INDEX,
        onClick: () => this.toggle(GROUP_ENGAGEMENT_TAB_INDEX),
        hide: isDdc()
      }
    ];
    return (
      <>
        <WWTabs align="left" tabs={tabsOptions} />
        <WWTabContent activeTab={activeTab}>
          <WWTabContentItem tabId={REVIEW_SUMMARY_TAB_INDEX}>
            <Row>
              <Col sm="12">
                {!loading ? (
                  !!summary ? (
                    <ReviewSummaryTable summary={summary} reportFilterValues={reportFilterValues} />
                  ) : (
                    this.renderNoData()
                  )
                ) : (
                  <LoadingPage />
                )}
              </Col>
            </Row>
          </WWTabContentItem>
          <WWTabContentItem tabId={PENDING_APPROVAL_TAB_INDEX}>
            <Row>
              <Col sm="12">
                {!loading ? (
                  !!summary ? (
                    <PendingApprovalSummaryTable summary={this.props.summary} />
                  ) : (
                    this.renderNoData()
                  )
                ) : (
                  <LoadingPage />
                )}
              </Col>
            </Row>
          </WWTabContentItem>
          {!isDdc() && (
            <WWTabContentItem tabId={GROUP_ENGAGEMENT_TAB_INDEX}>
              <Row>
                <Col sm="12">
                  {!loading ? (
                    !!engageSummary ? (
                      <GroupEngagementTable summary={engageSummary} reportFilterValues={reportFilterValues} />
                    ) : (
                      this.renderNoData()
                    )
                  ) : (
                    <LoadingPage />
                  )}
                </Col>
              </Row>
            </WWTabContentItem>
          )}
        </WWTabContent>
      </>
    );
  }
}
