import { findIndex, isEmpty, startCase, trim } from "lodash";
import filter from "lodash/filter";

export function join(ary, sep) {
  return filter(ary, i => i !== undefined).join(sep);
}

export function parseUrl(event, pattern) {
  let url = event.target.value;
  if (isEmpty(url)) return;

  let match = url.match(pattern);
  if (!match && trim(url).length > 0) {
    return false;
  } else {
    return match[0];
  }
}

export function noneBlank(vals) {
  //find the first blank value and return false.
  return findIndex(vals, v => !v || v.match(/^\s*$/)) < 0;
}

export function parseStringWithKey(str) {
  const nameLength = str.indexOf("[");
  if (nameLength < 0) {
    return [str];
  }
  const keyOrIndex = str.slice(nameLength + 1, str.length - 1);
  return [str.slice(0, nameLength), isNaN(+keyOrIndex) ? keyOrIndex : +keyOrIndex];
}

export function parseFormFieldName(name) {
  const splittingByDot = name.split(".");
  return name.includes("[")
    ? splittingByDot.reduce((acc, str) => [...acc, ...parseStringWithKey(str)], [])
    : splittingByDot;
}

export const apiFieldNameForDisplay = field => {
  const predefined = {
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    primaryPhone: "Primary Phone Number",
    webUri: "Website",
    websiteUri: "Website",
    addressStatus: "Display Address Status",
    locality: "City",
    administrativeArea: "State/Province",
    sublocality: "Neighborhood/District",
    openClosedStatus: "Open/Closed Status",
    countryCode: "Country"
  };
  if (Object.keys(predefined).includes(field)) {
    return predefined[field];
  }
  return startCase(field);
};
