import { useQuery } from "react-query";
import { useGet } from "hooks/dataHooks";
import BiaxialLineChartWidget, { defaultBiaxialLineTooltipFormatter } from "./BiaxialLineChartWidget";
import RoundBadge from "components/Badges/RoundBadge/RoundBadge";
import ActiveProductsRequireToRender from "components/Auth/ActiveProductsRequireToRender";
import LinkButton from "components/Routing/LinkButton";
import moment from "moment";
import { timespan2datesConvert } from "components/DateTime/TimespanSelector.helpers";
import { every, isNil } from "lodash";

export const useReviewTrendsData = ({ dateRange }) => {
  const path = "/dash/reviewTrend";
  const params = { startDate: dateRange[0], endDate: dateRange[1] };
  const [get] = useGet(path, { params });
  return useQuery([path, params], get, {});
};

const leftLabel = "Rating";
const leftValue = "rating";
const rightLabel = "Review Volume";
const rightValue = "volume";

export const ReviewTrendsWidget = ({ timespan = [30, "days"], customerId }) => {
  const dateRange = timespan2datesConvert(timespan);
  const { data, isLoading } = useReviewTrendsData({ dateRange });
  const charData = every(
    data,
    value =>
      ((isNil(value?.rating?.value) || value?.rating?.value === "N/A") && isNil(value?.volume?.value)) ||
      value?.volume?.value === 0
  )
    ? []
    : data;

    return (
    <ActiveProductsRequireToRender products={["REVIEW_IMPORT"]}>
      <BiaxialLineChartWidget
        data={charData}
        isLoading={isLoading}
        chartProps={{
          yAxis: {
            left: { axisLine: false, domain: [1, 5], tickCount: 5, interval: 0, label: { value: leftLabel } },
            right: { domain: ["auto", "auto"], tickCount: 5, interval: 0, type: "number", label: { value: rightLabel } }
          },
          xAxis: { dataKey: `${leftValue}.timestamp`, tickFormatter: v => moment(v).format("MMM DD") },
          line: { left: { dataKey: `${leftValue}.value` }, right: { dataKey: `${rightValue}.value` } },
          tooltip: {
            content: defaultBiaxialLineTooltipFormatter({ leftLabel, rightLabel, leftValue, rightValue })
          }
        }}
        heading={
          <>
            <div>Ratings/ Reviews Trend</div>
            <div className="fs-3 d-flex flex-nowrap">
              <span className="d-flex align-items-center flex-nowrap m-1">
                <RoundBadge small color="primary" />
                <span className="text-muted ms-1">Rating</span>
              </span>
              <span className="d-flex align-items-center flex-nowrap m-1 ms-3">
                <RoundBadge small />
                <span className="text-muted ms-1">Review Volume</span>
              </span>
            </div>
          </>
        }
        headingInfo="Last 30 Days"
      >
        <div className="d-flex justify-content-center">
          <LinkButton trackingAction="Reviews Trend View full report" color="primary" to="/report/customer">
            View Full Report
          </LinkButton>
        </div>
      </BiaxialLineChartWidget>
    </ActiveProductsRequireToRender>
  );
};

export default ReviewTrendsWidget;
