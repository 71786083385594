import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ReviewReply from "./ReviewReply";

import styles from "./ReplyHistoryModal.module.scss";

const { historyModalBodyClass } = styles;

const ReplyHistoryModal = ({ history, onClose }) => (
  <Modal fade={false} isOpen={history != null}>
    <ModalHeader>
      <h1>Reply History</h1>
    </ModalHeader>
    <ModalBody className={historyModalBodyClass}>
      {history.map((h, i) => (
        <ReviewReply key={i} reply={h.entity} />
      ))}
    </ModalBody>
    <ModalFooter>
      <Button className="float-end" onClick={onClose}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

export default ReplyHistoryModal;
