import React, { useMemo } from "react";
import classnames from "classnames";
import styles from "./Callout.module.scss";

const { calloutContent } = styles;

/**
 * @param {{
 *   direction?: "left" | "right";
 *   renderActions?: () => React.ReactNode
 *   className?: string;
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} param0
 */
export const Callout = ({
  direction = "left",
  className = "",
  contentClassName = "",
  children,
  renderActions = undefined,
  ...props
}) => {
  const borderRadius = useMemo(
    () => (direction === "left" ? `0 ${radius} ${radius} ${radius}` : `${radius} 0 ${radius} ${radius}`),
    [direction]
  );
  return (
    <div style={{ borderRadius }} className={classnames(className, "border")} {...props}>
      <div className={classnames(calloutContent, contentClassName)}>{children}</div>
      {!!renderActions && renderActions()}
    </div>
  );
};

const radius = "16px";
