import React from "react";
import size from "lodash/size";

export default function DirectSmsPreview({ post }) {
  const { rendered, destinations } = post;
  const companyName = destinations && destinations[0].companyName;
  const msgPrefix = `A message from ${companyName}`;

  return (
    <div className="direct-post">
      <div className="header">
        <div className="image-circle text-center">
          <i className="fa fa-mobile-phone" aria-hidden="true"></i>
        </div>
        <div>
          <div className="name-title">SMS</div>
          <div className="datetime-subtitle">{`${size(rendered) + size(msgPrefix)} chars`}</div>
        </div>
      </div>
      <div className="sms-body">
        {msgPrefix}. {rendered}
      </div>
    </div>
  );
}
