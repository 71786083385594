/* global FB */
import * as types from "./actionTypes";
import logger from "../../util/logger";

export function facebookLogin(user) {
  return {
    type: types.FACEBOOK_LOGIN,
    user
  };
}

export function facebookLogout() {
  return dispatch => {
    FB.logout(() => {
      try {
        window.FB.XFBML.parse();
      } catch (e) {
        logger.error(e);
      }
      dispatch(completeFacebookLogout());
    });
  };
}

export function completeFacebookLogout() {
  return {
    type: types.FACEBOOK_LOGOUT
  };
}
