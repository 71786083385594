import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { isEmpty, noop, flatten } from "lodash";
import { EmojiPicker } from "components/Misc/EmojiPicker";
import StatusMessages from "components/Misc/StatusMessages";
import PlaceholdersDropdown from "./PlaceholdersDropdown";
import { useSelection } from "hooks/utilHooks";
import { findMalformedPlaceholders, getErrorsWithDetails } from "util/validators";

const combinePlaceholders = (accordionPlaceholders, placeholders) =>
  flatten(accordionPlaceholders.map(group => group.placeholders).concat(placeholders));

/**
 * A WYSIWYG style editor. Supports emoji picker and click to insert placeholders
 *
 * @param {} param0
 */
function TextEditor({
  value = "",
  onChange = noop,
  onValidChange = noop,
  withEmoji = true,
  placeholders = [],
  accordionPlaceholders = [],
  syntax = "plain",
  validator,
  type = "textarea",
  children,
  ...props
}) {
  const [binder, selection] = useSelection();
  const [errors, setErrors] = useState();
  const [valid, setValid] = useState(true);

  const insert = text => {
    const [from, to] = selection.sort();
    onChange(`${value.slice(0, from)}${text}${value.slice(to)}`);
  };

  useEffect(() => {
    const malformedSequences = findMalformedPlaceholders(
      value,
      combinePlaceholders(accordionPlaceholders, placeholders)
    );
    const validationErrors = validator ? validator(value) : undefined;
    let errorWithDetails;
    if (!!validationErrors?.length) {
      errorWithDetails = getErrorsWithDetails(validationErrors, "There are errors in your text.");
    } else if (!!malformedSequences?.length) {
      errorWithDetails = getErrorsWithDetails(malformedSequences);
    }
    if (!!errorWithDetails) {
      setErrors(errorWithDetails);
      setValid(false);
    } else {
      setErrors([]);
      setValid(true);
    }
    //temp solution to infinite loop due to obj equality
  }, [value, JSON.stringify(accordionPlaceholders), JSON.stringify(placeholders)]);

  useEffect(() => {
    onValidChange(valid);
  }, [valid]);

  return (
    <div className="d-flex flex-column align-items-stretch">
      <StatusMessages errors={errors} />
      <Input
        {...binder()}
        {...props}
        invalid={!valid}
        type={type}
        value={value}
        onChange={({ target }) => onChange(target.value)}
      />
      <div className="d-flex justify-content-end mt-2">
        <div className="me-1">{children}</div>
        <div>
          {withEmoji && <EmojiPicker onPick={emoji => insert(emoji.native)} />}
          {!isEmpty(placeholders) && (
            <PlaceholdersDropdown
              placeholders={placeholders}
              accordionPlaceholders={accordionPlaceholders}
              insertPlaceholder={insert}
              syntax={syntax}
            />
          )}
        </div>
      </div>
    </div>
  );
}

TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isValidChange: PropTypes.func,
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  syntax: PropTypes.string,
  validator: PropTypes.func,
  withEmoji: PropTypes.bool,
  textAreaControls: PropTypes.node
};

export default React.memo(TextEditor);
