import React from "react";
import { isEmpty } from "lodash";
import ReviewReply from "./ReviewReply";
import { flattenReplies } from "util/reviewUtils";

export default function ReplyThread({ review, replies }) {
  if (isEmpty(replies)) {
    return null;
  }
  return (
    <div className="reply-thread">
      {flattenReplies(replies).map(reply => (
        <ReviewReply key={reply.id} review={review} reply={reply} />
      ))}
    </div>
  );
}
