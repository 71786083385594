import { isUndefined } from "lodash";
import { Tooltip, UncontrolledTooltip } from "reactstrap";

export const WWTooltip = ({ isOpen, toggle, ...props }) => {
  return isUndefined(isOpen) ? (
    <UncontrolledTooltip {...props} />
  ) : (
    <Tooltip isOpen={isOpen} toggle={toggle} {...props} />
  );
};

export const WWInfoTip = ({ target, children }) =>
  children ? (
    <>
      <i class="fa fa-info-circle" id={target} />
      <WWTooltip target={target}>{children}</WWTooltip>
    </>
  ) : null;
