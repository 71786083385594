import { TabContent, TabPane } from "reactstrap";
import React from "react";

export const WWTabContent = ({ activeTab, children, ...props }) => (
  <TabContent activeTab={activeTab} {...props}>
    {children}
  </TabContent>
);

export const WWTabContentItem = ({ tabId, children, ...props }) => (
  <TabPane tabId={tabId} {...props}>
    {children}
  </TabPane>
);
