import React from "react";
import Block from "../Layout/Block";
import { NO_REPLY_EMAIL, INFO_EMAIL } from "../../constants/emails";

const PATH_TO_LOGO = "img/logo-180.png";

export default function DirectEmailPreview({ post }) {
  const { destinations, rendered } = post;
  const companyName = destinations && destinations[0].companyName;
  return (
    <div className="direct-post">
      <div className="header">
        <div className="image-circle text-center">
          <i className="fa fa-envelope-o" aria-hidden="true"></i>
        </div>
        <div>
          <div className="name-title">A Message from {companyName}</div>
          <div className="datetime-subtitle">{NO_REPLY_EMAIL}</div>
        </div>
      </div>
      <div className="email-body">
        <Block className="text-center">
          <img src="img/warning-40.png" alt="warning" />
          <p className="email-header">A Message from {companyName}</p>
        </Block>
        <Block>
          <p>Hello Alice Smith,</p>
          <p>{rendered}</p>
        </Block>
      </div>
      <Block className="email-footer">
        <p>
          <span>
            Powered by: <img src={PATH_TO_LOGO} width="80px" alt="Widewail" />
          </span>
          <br />
          <span>(844) 259-9238</span>
          <br />
          <span>{INFO_EMAIL}</span>
        </p>
      </Block>
    </div>
  );
}
