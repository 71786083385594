import WWChip from "components/WWChips/WWChip/WWChip";
import { useFilterValueState } from "hooks/filteringHooks";
import { valueOf } from "util/functionUtils";
import { noop } from "lodash";

const SlabFactory = () => {
  const barComponent = ({ ...props }, className) => <SlabFilterBar key={props.name} className={className} {...props} />;
  const chip = ({ ...props }, className) => <SlabChip key={props.name} {...props} className={className} />;
  const filterComponent = ({ ...props }, className) => (
    <SlabFilterBar key={props.name} {...props} className={className} />
  );
  const actionButton = ({ ...props }, className) => <SlabFilterBar key={props.name} className={className} {...props} />;

  return {
    barComponent,
    chip,
    filterComponent,
    actionButton
  };
};

export const SlabFilterBar = ({
  className,
  offCanvas,
  name,
  label,
  value,
  onClick = noop,
  color = "transparent",
  ...props
}) => {
  return (
    <div onClick={onClick} className={`rounded bg-${color} d-flex justify-content-center align-items-center py-2 px-3`}>
      <span className="text-dark text-nowrap">{label}</span>
      <h5 className="m-0 ms-2 p-0">{value}</h5>
    </div>
  );
};

export const SlabChip = ({ name, className, iconClass, defaultValue, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return value ? (
    <WWChip
      iconClass={iconClass}
      key={name}
      className="overflow-visible"
      onClick={() => setter(valueOf(defaultValue))}
      {...props}
    >
      {value}
    </WWChip>
  ) : null;
};

export default SlabFactory;
