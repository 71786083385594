import GroupedNotifications from "components/Notifications/GroupedNotifications/GroupedNotifications";
import React, { useCallback, useEffect, useState } from "react";
import { QUICK_NOTIFICATION_TIMEOUT } from "util/constants";
import { NotificationsContext, useNotificationsContextValue } from "../components/Notifications";

const Notifications = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [infos, setInfos] = useState([]);
  const rise = useNotificationsContextValue(setNotifications);
  useEffect(() => {
    setErrors(notifications.filter(notification => notification.type === "danger"));
    setWarnings(notifications.filter(notification => notification.type === "warning"));
    setInfos(notifications.filter(notification => notification.type !== "warning" && notification.type !== "danger"));
  }, [notifications]);

  const onClose = useCallback(
    notifications =>
      notifications.map(notification => setNotifications(prev => prev.filter(({ id }) => id !== notification.id))),
    []
  );

  return (
    <NotificationsContext.Provider value={rise}>
      {children}
      <div
        className="position-fixed d-flex flex-column"
        style={{
          left: "50%",
          top: 80, // right under the topbar
          transform: "translateX(-50%)",
          zIndex: 9999 // we need toasts to be over dropdown menus and modals...
        }}
      >
        <GroupedNotifications
          notifications={errors}
          onClose={() => onClose(errors)}
          title="Error"
          bannerColor="danger"
        />
        <GroupedNotifications
          notifications={warnings}
          onClose={() => onClose(warnings)}
          title="Warning"
          bannerColor="warning"
        />
        <GroupedNotifications
          notifications={infos}
          onClose={() => onClose(infos)}
          timeout={QUICK_NOTIFICATION_TIMEOUT}
        />
      </div>
    </NotificationsContext.Provider>
  );
};

export default Notifications;
