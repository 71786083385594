import WWChip from "../../../WWChips/WWChip/WWChip";
import { useFilterValueState } from "hooks/filteringHooks";
import { NONE_LABEL, WWLabelUnifiedFieldsWithTypeahead } from "components/Form/LabelSelector";
import { isEmpty, without, get } from "lodash";

const JOINER = ",";

const forceArray = (value = []) => (Array.isArray(value) ? value : [value]),
  parseFilterStateValue = value =>
    forceArray(value)
      .filter(x => x)
      .map(l => {
        const [name, value] = l.split(JOINER);
        return { name, value };
      });

export const LabelFilterFactory = () => {
  const barComponent = ({ ...props }, className) => <LabelFilterSidebar className={className} {...props} />;
  const chip = ({ ...props }, className) => <LabelFilterChip {...props} className={className} />;

  return {
    barComponent,
    chip,
    filterComponent: barComponent
  };
};

export const LabelFilterChip = ({ name, type }) => {
  const [value, setter] = useFilterValueState(name, type);

  return forceArray(value).map(v => {
    const chip = parseFilterStateValue(v)[0];

    return (
      <WWChip
        key={JSON.stringify(v)}
        iconClass="fa fa-map-pin"
        className="overflow-visible"
        onClick={() => setter(without(value, v))}
        label={chip.name}
      >
        {chip.value || <em>{NONE_LABEL}</em>}
      </WWChip>
    );
  });
};

export const LabelFilterSidebar = ({ name, type, multiple }) => {
  const [value, setter] = useFilterValueState(name, type);
  const parsedValue = parseFilterStateValue(value);

  return multiple ? (
    <WWLabelUnifiedFieldsWithTypeahead
      multiple
      labels={parsedValue}
      onChange={v => setter(v.map(({ name, value }) => [name, value].join(JOINER)))}
    />
  ) : (
    <WWLabelUnifiedFieldsWithTypeahead
      label={get(parsedValue, 0, null)}
      onChange={v => setter(isEmpty(v) ? null : [v.name, v.value].join(JOINER))}
    />
  );
};

export default LabelFilterFactory;
