import React, { useState } from "react";
import FormField from "../../../components/Form/FormField";
import { Button } from "reactstrap";
import InputField from "../../../components/Form/InputField";
export default class DealertrackDms {
  field() {
    return "dealertrackDmsInfo";
  }

  add() {
    const AddDTDMS = ({ customer, onSave }) => {
      const [enterpriseCode, setEnterpriseCode] = useState();
      const [companyNumber, setCompanyNumber] = useState();
      const [serverId, setServerId] = useState();
      const [dealertrackId, setDealertrackId] = useState();

      return (
        <>
          <InputField
            type="text"
            label="Enterprise Code"
            placeholder="Enterprise Code"
            onChange={e => setEnterpriseCode(e.target.value)}
            value={enterpriseCode}
          />
          <InputField
            type="text"
            label="Company Number"
            placeholder="Company Number"
            onChange={e => setCompanyNumber(e.target.value)}
            value={companyNumber}
          />
          <InputField
            type="text"
            label="Server ID"
            placeholder="Server ID"
            onChange={e => setServerId(e.target.value)}
            value={serverId}
          />
          <InputField
            type="text"
            label="Dealertrack ID"
            placeholder="Dealertrack ID"
            onChange={e => setDealertrackId(e.target.value)}
            value={dealertrackId}
          />

          <Button
            color="primary"
            onClick={() =>
              onSave({
                enterpriseCode,
                companyNumber,
                serverId,
                dealertrackId,
                source: "DEALERTRACK_DMS"
              })
            }
          >
            Link
          </Button>
        </>
      );
    };

    return AddDTDMS;
  }

  edit() {
    const DTDMS = ({ info, customer, refreshSite }) => (
      <>
        <FormField label="Info">
          <div>{`${info.enterpriseCode} / ${info.companyNumber} / ${info.serverId} / ${info.dealertrackId}`}</div>
        </FormField>
      </>
    );
    return DTDMS;
  }
}
