import { useQuery } from "react-query";
import { useGet } from "../dataHooks";

const BASE_URL_VIN_SOLUTION = "vinSolutions";

const vinSolutionsPaths = {
  all: [BASE_URL_VIN_SOLUTION],
  locations: () => [...vinSolutionsPaths.all, "locations"],
  users: id => [...vinSolutionsPaths.all, id, "users"]
};

const vinSolutionsKeys = {
  all: vinSolutionsPaths.all,
  locations: params => [...vinSolutionsPaths.locations(), { ...params }],
  users: (id, params) => [...vinSolutionsPaths.users(id), { ...params }]
};

export const getVinSolutionsKeyAndPath = (requestName, ...args) => {
  return [vinSolutionsKeys[requestName](...args), vinSolutionsPaths[requestName](...args)];
};

export const useVinSolutionsLocations = ({ query, enabled = false }) => {
  const params = { filter: query };
  const [queryKey, queryPath] = getVinSolutionsKeyAndPath("locations", params);
  const [get] = useGet(queryPath, { params, enabled }).then(res => res.data);
  return useQuery(queryKey, get);
};

export const useVinSolutionsUsers = ({ dealerId, query, enabled = false }) => {
  const params = { filter: query };
  const [queryKey, queryPath] = getVinSolutionsKeyAndPath("users", dealerId, params);
  const [get] = useGet(queryPath, { params, enabled });
  return useQuery(queryKey, get);
};
