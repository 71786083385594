import WWButton from "components/Buttons/WWButton";
import React from "react";
import classnames from "classnames";

const PrintScreenButton = ({ color = "light", className = "mb-4" }) => (
  <WWButton
    iconClass="fa fa-print"
    className={classnames("flex-grow-0 mx-1 text-dark no-display-printed d-none d-md-block", className)}
    color={color}
    onClick={() => window.print()}
  />
);

export default PrintScreenButton;
