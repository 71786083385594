import React from "react";
import classnames from "classnames";
import { Card as ReactstrapCard, CardProps, ButtonProps } from "reactstrap";
import WWButton from "components/Buttons/WWButton";

export const CARD_HEADER_ACTION_CLASS = "btn-light text-black-50";
export const CARD_HEADER_ACTION_DROPDOWN_CLASS = classnames(CARD_HEADER_ACTION_CLASS, "mb-0 pe-3 text-uppercase fs-2");

/**
 * @param {CardProps} props
 */
export const Card = ({ className, ...props }) => (
  <ReactstrapCard {...props} className={`${className || ""} d-flex flex-column no-pagebreak-inside`} />
);

/**
 * @param {React.HTMLAttributes<HTMLDivElement>} props
 */
export const CardHeader = ({ className = "", ...props }) => (
  <div {...props} className={`${className} d-flex card-headers`} />
);

/**
 * @param {React.HTMLAttributes<HTMLDivElement>} props
 */
export const CardBody = props => <div {...props} className={`${props.className || ""} card-body`} />;

/**
 * @param {React.HTMLAttributes<HTMLDivElement>} props
 */
export const CardHeaderActions = ({ className = "ms-auto", ...props }) => (
  <div {...props} className={`${className} card-header-actions`} />
);

/**
 * @param {ButtonProps} props
 */
export const CardHeaderAction = props => (
  <WWButton {...props} className={` ${props.color ? "" : CARD_HEADER_ACTION_CLASS} ${props.className}`} />
);

/**
 * @param {React.HTMLAttributes<HTMLHeadingElement>} props
 */
export const CardTitle = props => (
  <h4 {...props} className={`${props.className || ""}`}>
    {props.children}
  </h4>
);
