import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import Input from "components/Form/InputField";
import Page from "components/Modals/NavigableModal/Page/Page";
import StatusBarContainer from "components/Modals/NavigableModal/StatusBarContainer/StatusBarContainer";
import CardButton from "components/CardButton/CardButton";
import { useCampaignCreate } from "hooks/data/campaignHooks";
import { useStateThroughRedux } from "hooks/stateHooks";
import { useCustomersProducts } from "hooks/customerHooks";
import { inviteCustomerProductsKeys, InviteProductsKeys } from "data/products";
import { useFilterState } from "hooks/filteringHooks";

import styles from "./CampaignTypeSelection.module.scss";

const { card_button_container, card_button } = styles;

const InviteTypes = Object.freeze({
  review: "REVIEW",
  video: "VIDEO",
  videoFollowUp: "VIDEO_FOLLOW_UP"
});

const productTypeMap = {
  [InviteProductsKeys.reviewInvite]: InviteTypes.review,
  [InviteProductsKeys.inviteVideo]: InviteTypes.video
};

const campaignCustomerProducts = new Set(inviteCustomerProductsKeys);
const inviteTypesSortingFunction = (a, b) =>
  inviteCustomerProductsKeys.indexOf(a) - inviteCustomerProductsKeys.indexOf(b);

const CampaignTypeSelection = ({ setCampaignId }) => {
  const [filters] = useFilterState();
  const customer = filters?.customerId?.id;
  const [newCampaign, setNewCampaign] = useState({ customer: { id: customer }, status: "LIVE" });
  const createCampaign = useCampaignCreate();
  const customerProducts =
    useCustomersProducts(customer)
      .data?.filter(product => campaignCustomerProducts.has(product))
      .sort(inviteTypesSortingFunction) || [];

  const [, setSettingsSection] = useStateThroughRedux("campaignModal/section", undefined);

  const setType = useCallback(
    type => {
      setNewCampaign(prev => ({ ...prev, type }));
    },
    [setNewCampaign]
  );

  const setTitle = useCallback(
    event => {
      setNewCampaign(prev => ({ ...prev, name: event.target.value }));
    },
    [setNewCampaign]
  );

  const next = useCallback(() => {
    createCampaign.mutate(newCampaign);
  }, [createCampaign, newCampaign]);

  useEffect(() => {
    if (createCampaign.isSuccess) {
      setCampaignId(createCampaign.data.id);
      setSettingsSection("config");
    }
  }, [createCampaign?.data?.id]);

  useEffect(() => {
    setType(productTypeMap[customerProducts[0]]);
  }, [customerProducts[0]]);

  const hasReviewInviteProduct = useMemo(() => customerProducts.includes(InviteProductsKeys.reviewInvite), [
    customerProducts
  ]);
  const hasInviteVideoProduct = useMemo(() => customerProducts.includes(InviteProductsKeys.inviteVideo), [
    customerProducts
  ]);

  return (
    <Page id="typeSelector" section="main">
      <div className="p-5">
        <h5>Campaign Name*</h5>
        <Input id="CampaignName" placeholder="Campaign Name" onChange={setTitle} />
        <h5>Campaign Type*</h5>
        <p>
          Select what type of campaign you would like to create. Different options will be available for different
          campaign types.
        </p>
        <div className={card_button_container}>
          {hasReviewInviteProduct && (
            <CardButton
              title="Review"
              className={card_button}
              icon="bg-image-logo-invite-color custom-icon-sm"
              selected={newCampaign.type === InviteTypes.review}
              onClick={() => setType(InviteTypes.review)}
            >
              Get more reviews on sites such as Google and Facebook.
            </CardButton>
          )}
          {hasInviteVideoProduct && (
            <CardButton
              title="Video"
              className={card_button}
              icon="bg-image-logo--video-color custom-icon-sm"
              selected={newCampaign.type === InviteTypes.video}
              onClick={() => setType(InviteTypes.video)}
            >
              Collect video testimonials from your customers.
            </CardButton>
          )}
          {hasReviewInviteProduct && hasInviteVideoProduct && (
            <CardButton
              title="Follow Up"
              className={card_button}
              icon="bg-image-logo-invite-fallow-up-color custom-icon-sm"
              selected={newCampaign.type === InviteTypes.videoFollowUp}
              onClick={() => setType(InviteTypes.videoFollowUp)}
            >
              Follow up with a request for a video after someone leaves a review.
            </CardButton>
          )}
        </div>
      </div>
      <StatusBarContainer>
        <div className="d-flex justify-content-end py-2">
          <Button disabled={!newCampaign.name || !newCampaign.type} color="primary" onClick={next}>
            Next
          </Button>
        </div>
      </StatusBarContainer>
    </Page>
  );
};

export default CampaignTypeSelection;
