import { noop } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Toast, ToastBody } from "reactstrap";
import { ReplyEditor } from "../../components/Reviews/ReplyEditor";
import "./FloatingEditor.scss";

const FloatingEditor = ({
  active = false,
  onClose = noop,
  review,
  context,
  reply,
  onUpdateStatus = noop,
  onSave = noop
}) => {
  const [minimized, setMinimized] = useState(false);
  const toggleMinimized = useCallback(() => setMinimized(v => !v), []);

  return (
    <Collapse className="floating-editor-container" isOpen={active}>
      <Toast className="floating-editor d-flex flex-column">
        <div className="d-flex text-dark bg-light items-center">
          <h4 className="my-0 p-3">
            {!!reply?.id
              ? `Editing reply by ${reply?.author}`
              : `Replying to ${context === review ? "review" : "reply"} by ${context?.author}`}
          </h4>
          <div className="ms-auto d-flex items-center px-3">
            <i
              className={`fa ${minimized ? "fa-window-maximize" : "fa-window-minimize"} p-2 m-auto link button`}
              onClick={toggleMinimized}
            />
            <i className="fa fa-window-close p-2 m-auto link button" onClick={onClose} />
          </div>
        </div>
        <Collapse className="floating-editor-body" isOpen={!minimized}>
          <ToastBody>
            <ReplyEditor review={review} reply={reply} onSave={onSave} onUpdateStatus={onUpdateStatus} />
          </ToastBody>
        </Collapse>
      </Toast>
    </Collapse>
  );
};

export default FloatingEditor;
