import * as client from "./apiClient";

const baseUri = "fb";

export function clientPages(filter) {
  return client.get([baseUri, "clientpages"], { params: { name: filter } });
}

export function getAdAccounts(businessId) {
  return client.get([baseUri, `business/${businessId}/adAccount`]).then(res => res.data?._embedded?.adAccountDtoes);
}

export function linkAdAccounts(businessId, params) {
  return client.post([baseUri, `business/${businessId}/adAccount`], params);
}
