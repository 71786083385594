import React from "react";
import { Col, Media, Form, FormGroup, Label, Input, Alert, Button } from "reactstrap";
import { Player } from "video-react";
import withConfirmation from "../../components/Form/withConfirmation";
import { includes } from "lodash";

const postAttachmentStyle = {
  boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  border: "1px solid #dfe1e5",
  borderRadius: ".375rem",
  marginBottom: "1rem"
};

export default function PostAttachment({ attachment, updateAttachmentSources, removeAttachment, ...props }) {
  const { id, target, type, sources } = attachment;
  const ButtonWConfirm = withConfirmation(Button);

  return (
    <Col xs="12" sm="6" lg="6">
      <Media className="attachment position-relative" style={postAttachmentStyle}>
        <Media left>
          {type === "VIDEO" ? (
            <>
              <Alert color="warning" className="small">
                Videos are not supported to Google or LinkedIn at this time.
              </Alert>
              <Player style={{ width: "128px", height: "128px" }} width="100%" playsInline src={target} />
            </>
          ) : (
            <Media
              style={{ width: "128px", height: "128px", marginRight: "1rem" }}
              object
              src={target}
              alt="attachment"
            />
          )}
        </Media>

        <Media body>
          <Form>
            <FormGroup check style={{ padding: "4px 8px", marginLeft: "16px" }}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={includes(sources, "FACEBOOK")}
                  onChange={event => {
                    updateAttachmentSources(id, "FACEBOOK", event.target.checked);
                  }}
                />{" "}
                Facebook
              </Label>
              <br />
            </FormGroup>
            {/* <FormGroup check style={{ padding: '4px 8px', marginLeft: '16px' }}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={includes(attachment.sources, 'INSTAGRAM')}
                  onChange={(event) => {
                    updateAttachmentSources(
                      id,
                      'INSTAGRAM',
                      event.target.checked
                    )
                  }}
                />{' '}
                Instagram
              </Label><br />
            </FormGroup> */}
            <FormGroup check style={{ padding: "4px 8px", marginLeft: "16px" }}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={includes(sources, "GOOGLE")}
                  onChange={event => {
                    updateAttachmentSources(id, "GOOGLE", event.target.checked);
                  }}
                />{" "}
                Google
              </Label>
            </FormGroup>
            <FormGroup check style={{ padding: "4px 8px", marginLeft: "16px" }}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={includes(sources, "LINKEDIN")}
                  onChange={event => {
                    updateAttachmentSources(id, "LINKEDIN", event.target.checked);
                  }}
                />{" "}
                LinkedIn
              </Label>
              <br />
            </FormGroup>
          </Form>
          {/*
          <SelectField
            placeholder="All locations"
            name="attachmentSources"
            value={
              currAttachment.sources &&
              currAttachment.sources.split(',')
            }
            isMulti={true}
            simpleValue={true}
            options={['FACEBOOK', 'INSTAGRAM', 'GOOGLE']}
            onChange={evt =>
              this.updateAttachmentSources(
                currAttachment.id,
                evt.target.value
              )
            }
          />
          */}
          <ButtonWConfirm
            className="btn-danger mt-1"
            label="Delete Attachment"
            size="sm"
            onClick={() => removeAttachment(target)}
          >
            <i className="fa fa-fw fa-trash"></i>
            Delete Attachment
          </ButtonWConfirm>
        </Media>
      </Media>
    </Col>
  );
}
