import React, { useCallback, useMemo, useState } from "react";
import { Button, Collapse } from "reactstrap";
import { updateRentManagerLink } from "api/customerApi";
import { useNativeInputState } from "hooks/utilHooks";
import InputField from "components/Form/InputField";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import FormField from "components/Form/FormField";
import { useValidatePropertySitesCheckboxes } from "hooks/validationHooks";

const TOGGLES_LIST_TEXT = "Move In, Move Out, Renewal, Maintenance or Prospect";

export default class RentManagerAdminLinking {
  field() {
    return "rentManagerInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info, customer }) => {
  const [companyCode, setCompanyCode] = useState(info.companyCode);
  const [initialImportMoveIn, setInitialImportMoveIn] = useState(info.importMoveIn);
  const [importMoveIn, setImportMoveIn] = useState(initialImportMoveIn);
  const [initialImportMoveOut, setInitialImportMoveOut] = useState(info.importMoveOut);
  const [importMoveOut, setImportMoveOut] = useState(initialImportMoveOut);
  const [initialImportMaintenance, setInitialImportMaintenance] = useState(info.importMaintenance);
  const [importMaintenance, setImportMaintenance] = useState(initialImportMaintenance);
  const [initialImportRenewal, setInitialImportRenewal] = useState(info.importRenewal);
  const [importRenewal, setImportRenewal] = useState(initialImportRenewal);
  const [initialImportProspect, setInitialImportProspect] = useState(info.importProspect);
  const [importProspect, setImportProspect] = useState(initialImportProspect);

  const patchReady = useMemo(
    () =>
      initialImportMoveIn !== importMoveIn ||
      initialImportMoveOut !== importMoveOut ||
      initialImportMaintenance !== importMaintenance ||
      initialImportRenewal !== importRenewal ||
      initialImportProspect !== importProspect,
    [
      initialImportMoveIn,
      importMoveIn,
      initialImportMoveOut,
      importMoveOut,
      initialImportMaintenance,
      importMaintenance,
      initialImportRenewal,
      importRenewal,
      initialImportProspect,
      importProspect
    ]
  );

  const vaidateToggles = useValidatePropertySitesCheckboxes(
    [importMoveIn, importMoveOut, importMaintenance, importRenewal, importProspect],
    TOGGLES_LIST_TEXT
  );

  const saveToggles = useCallback(() => {
    vaidateToggles().then(validationResult => {
      if (validationResult) {
        updateRentManagerLink(customer.id, info.id, {
          importMoveIn,
          importMoveOut,
          importMaintenance,
          importRenewal,
          importProspect
        }).then(() => {
          setInitialImportMoveIn(importMoveIn);
          setInitialImportMoveOut(importMoveOut);
          setInitialImportMaintenance(importMaintenance);
          setInitialImportRenewal(importRenewal);
          setInitialImportProspect(importProspect);
        });
      }
    });
  }, [info, importMoveIn, importMoveOut, importMaintenance, importRenewal, importProspect, customer]);

  return (
    <>
      <InputField
        type="text"
        label="Company Code"
        placeholder="Company Code"
        onChange={e => setCompanyCode(e.target.value)}
        value={companyCode}
        readOnly={true}
      />
      <Labeled name="importMoveIn" checked={importMoveIn} onToggle={setImportMoveIn} label="Move In" />
      <Labeled name="importMoveOut" checked={importMoveOut} onToggle={setImportMoveOut} label="Import Move-out Leads" />
      <Labeled
        name="importMaintenance"
        checked={importMaintenance}
        onToggle={setImportMaintenance}
        label="Import Maintenance Leads"
      />
      <Labeled name="importRenewal" checked={importRenewal} onToggle={setImportRenewal} label="Import Renewal Leads" />
      <Labeled
        name="importProspect"
        checked={importProspect}
        onToggle={setImportProspect}
        label="Import Prospect Leads"
      />

      <Collapse isOpen={patchReady}>
        <FormField label=" ">
          <Button color="primary" onClick={saveToggles} disabled={!patchReady}>
            Update
          </Button>
        </FormField>
      </Collapse>
    </>
  );
};

const Add = ({ onSave }) => {
  const [companyCode, setCompanyCode] = useNativeInputState("");
  const [importMoveIn, setImportMoveIn] = useState(false);
  const [importMoveOut, setImportMoveOut] = useState(false);
  const [importMaintenance, setImportMaintenance] = useState(false);
  const [importRenewal, setImportRenewal] = useState(false);
  const [importProspect, setImportProspect] = useState(false);

  const allFilled = useMemo(() => {
    return companyCode;
  }, [companyCode]);

  const vaidateToggles = useValidatePropertySitesCheckboxes(
    [importMoveIn, importMoveOut, importMaintenance, importRenewal, importProspect],
    TOGGLES_LIST_TEXT
  );

  const onLink = useCallback(
    () =>
      vaidateToggles().then(validationResult => {
        if (validationResult) {
          onSave({
            companyCode,
            importMoveIn,
            importMoveOut,
            importMaintenance,
            importRenewal,
            importProspect,
            source: "RENT_MANAGER"
          });
        }
      }),
    [companyCode, importMoveIn, importMoveOut, importMaintenance, importRenewal, importProspect, onSave]
  );

  return (
    <>
      <InputField
        type="text"
        label="Company Code"
        placeholder="Company Code"
        onChange={setCompanyCode}
        value={companyCode}
      />
      <Labeled name="importMoveIn" checked={importMoveIn} onToggle={setImportMoveIn} label="Move in" />
      <Labeled name="importMoveOut" checked={importMoveOut} onToggle={setImportMoveOut} label="Move out" />
      <Labeled
        name="importMaintenance"
        checked={importMaintenance}
        onToggle={setImportMaintenance}
        label="Maintenance"
      />
      <Labeled name="importRenewal" checked={importRenewal} onToggle={setImportRenewal} label="Renewal" />
      <Labeled name="importProspect" checked={importProspect} onToggle={setImportProspect} label="Prospect" />

      <Button color="primary" disabled={!allFilled} onClick={onLink}>
        Link
      </Button>
    </>
  );
};
