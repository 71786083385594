import React, { useMemo } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "reactstrap";
import { SITES } from "data/sites";
import { VIEW_STATE_STORAGE_KEYS } from "data/storageKeys";
import { usePersistantViewState } from "hooks/stateHooks";

export default ({ site, onResolve }) => {
  const [disable, setDisable] = usePersistantViewState(
    VIEW_STATE_STORAGE_KEYS.MODAL_PREVENT_CUSTOM_SITE_PUBLISH,
    false
  );
  const siteLabel = useMemo(() => SITES.find(({ source }) => site === source)?.label || site, [site]);
  return (
    <>
      <ModalHeader>Action needed - your response is not published yet</ModalHeader>
      <ModalBody>
        <p>You must go to {siteLabel} to publish your response. Your reply has been copied to the clipboard.</p>
        <Button size="sm" active={disable} onClick={() => setDisable(v => !v)}>
          <i className={`fa ${disable ? "fa-check-square-o" : "fa-square-o"} me-2`} />
          Do not show this again
        </Button>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => onResolve(false)}>
          Close
        </Button>
        <Button color="warning" onClick={() => onResolve(true)}>
          Go to {siteLabel}
        </Button>
      </ModalFooter>
    </>
  );
};
