import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Time from "../DateTime/Time";
import Avatar from "../User/Avatar";
import Paragraph from "../Layout/Paragraph";
import CopyButton from "../Buttons/CopyButton";
import reactStringReplace from "react-string-replace";

import styles from "./MessageThread.module.scss";

const {
  messageContentClass,
  copyIconClass,
  dateTimeClass,
  templateInfoClass,
  messageContainerClass,
  mentionClass
} = styles;

function Message({ message, renderActions }) {
  const content = useMemo(() => message.templateData?.repMessage || message.content, [message]);
  const author = useMemo(() => message.author || message.senderId || message.createdBy, [message]);
  const gravatar = useMemo(() => message.createdBy || author, [message, author]);
  const templateInfo = useMemo(
    () => (message.template !== "ReviewNote" && message.template !== "TaggedMessage" && message.template) || null,
    [message]
  );

  const paragraphContent = useMemo(() => {
    const nodes = templateInfo ? [<div className={templateInfoClass}>{templateInfo}</div>] : [];
    return content
      ? nodes.concat(
          reactStringReplace(content, /[@][[](...+?)]\(.+?\)/gm, (match, index) => (
            <span className={mentionClass} key={`${match}_${index}`}>
              {"@" + match}
            </span>
          ))
        )
      : nodes;
  }, [content]);

  return (
    <div className={messageContainerClass}>
      <Avatar className="me-3 d-none d-sm-block" email={gravatar} />
      <div>
        <div className="d-sm-flex align-items-baseline justify-content-start  flex-wrap">
          <div>
            <div className="pe-2 mb-1 d-inline-block">
              <strong>{author}</strong>
            </div>
          </div>
          <Time
            className={classnames("text-muted", dateTimeClass)}
            date={message.createdOn}
            format="dateSlashTime"
            withIcon={false}
          />
        </div>
        {!!(content || templateInfo) && (
          <>
            <div className="d-sm-flex flex-column flex-sm-row align-items-center">
              <div className={messageContentClass}>
                <Paragraph className="m-0 p-3 py-2 flex-grow-1" content={paragraphContent} />
              </div>
              <div className="d-flex mt-2 mt-sm-2">
                {!!content && (
                  <CopyButton className={classnames("ps-2 d-none d-sm-inline", copyIconClass)} text={content} />
                )}
                {!!renderActions && renderActions()}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

Message.propTypes = {
  message: PropTypes.object.isRequired
};

export default Message;
