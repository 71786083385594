import { useCallback, useState } from "react";
import Input from "../Form/Input";
import classnames from "classnames";
import styles from "./EditableTitle.module.scss";
import { not } from "ramda";

const EditableTitle = ({ onChange, title, editable = true, placeholder="Campaign Name", name="campaign-title" }) => {
  const [titleEditable, setTitleEditable] = useState(false);
  const editToggle = useCallback(() => {
    setTitleEditable(not);
  }, []);

  return (
    <>
      {titleEditable && editable ? (
        <Input
          id={name}
          name={name}
          className="me-1"
          placeholder={placeholder}
          autoFocus
          onBlur={editToggle}
          onChange={onChange}
          value={title}
        />
      ) : (
        <h4 className={classnames("mb-0", "me-1", styles.header)} onClick={editToggle}>
          {title}
        </h4>
      )}
      {editable && <i className="fa fa-edit text-secondary" onClick={editToggle} />}
    </>
  );
};

export default EditableTitle;
