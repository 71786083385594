import * as types from "../actions/actionTypes";

export default function facebook(state = {}, action) {
  if (state === undefined) {
    return {};
  } else {
    switch (action.type) {
      case types.FACEBOOK_LOGIN:
        return Object.assign({}, state, { user: action.user });

      case types.FACEBOOK_LOGOUT:
        const newState = Object.assign({}, state);
        delete newState.user;
        return newState;

      default:
        return state;
    }
  }
}
