import { DEBOUNCE_DELAY_500_MS } from "constants/delays";
import { useGet } from "hooks/dataHooks";
import { debounce, isEqual, omit, pick } from "lodash";
import { fromPairs, toPairs } from "ramda";
import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { useFilterState } from "../filteringHooks";
import { datetime2iso, isApiDateString } from "util/dateUtils";
import { LOCATION_TYPE } from "data/customers";
import { DataProviderContext } from "containers/DataProvider";

const LOCATION_FILTER_TYPES = [
  LOCATION_TYPE.allLocations,
  LOCATION_TYPE.customerOrBrand,
  LOCATION_TYPE.customer,
  LOCATION_TYPE.locationsWithInvite,
  LOCATION_TYPE.location_label
];

const reduceFiltersByType =
  (type, pickKey = "name") =>
  (acc, filter) =>
    (Array.isArray(type) ? type.includes(filter.type) : filter.type === type) ? [...acc, filter[pickKey]] : acc;

export const convertLocationFilter = (filters, filterConfig) => {
  const locationFilterNames = filterConfig.reduce(reduceFiltersByType(LOCATION_FILTER_TYPES), []);

  return [
    locationFilterNames,
    toPairs(pick(filters, locationFilterNames)).map(([key, value]) => customerFilterConverter(key, value))
  ];
};

export const defaultPrepareFilters = (filters, projection, filterConfig = []) => {
  const datesFilterNames = filterConfig.reduce(reduceFiltersByType("dates"), []);
  const campaignFilterNames = filterConfig.reduce(reduceFiltersByType("campaign"), []);
  const contactFilterNames = filterConfig.reduce(reduceFiltersByType("contact"), []);
  const accountFilterNames = filterConfig.reduce(reduceFiltersByType("account"), []);

  const convertedDatesValues = toPairs(pick(filters, datesFilterNames)).map(([key, value]) => datesConverter(value));
  const convertedCampaignValues = toPairs(pick(filters, campaignFilterNames)).map(([key, value]) =>
    campaignFilterConverter(key, value)
  );
  const convertedContactValues = toPairs(pick(filters, contactFilterNames)).map(([key, value]) =>
    contactFilterConverter(key, value)
  );
  const [locationFilterNames, convertedLocationValues] = convertLocationFilter(filters, filterConfig);

  const excludedFilterNames = filterConfig.reduce((ret, f) => (f.excluded ? [...ret, f.name] : ret), []);
  const convertedAccountFilterValues = toPairs(pick(filters, accountFilterNames)).map(([key, value]) =>
    accountFilterConverter(key, value)
  );

  const normalized = fromPairs([
    ...toPairs({
      ...omit(filters, [
        ...datesFilterNames,
        ...locationFilterNames,
        ...campaignFilterNames,
        ...contactFilterNames,
        ...excludedFilterNames,
        ...accountFilterNames,
        "urlLoaded"
      ])
    }),
    ...convertedLocationValues,
    ...convertedCampaignValues,
    ...convertedContactValues,
    ...convertedAccountFilterValues,
    ...(convertedDatesValues?.[0] || []),
    ["projection", projection]
  ]);

  return normalized;
};

const datesConverter = dates => {
  return toPairs(dates).map(([key, value]) => [key, isApiDateString(value) ? value : datetime2iso(moment(value))]);
};

const contactFilterConverter = (key, contact) => [[key], contact.id];
const campaignFilterConverter = (key, campaign) => [key, campaign.name];
const accountFilterConverter = (_, account) => ["accountId", account.id];

const customerFilterConverter = (key, location) => {
  switch (location?.type) {
    case "CUSTOMER":
      return ["customerId", location.id];
    case "CONTACT":
      return ["contactId", location.id];
    case "BRAND":
      return ["brand", location.brand];
    case "ACCOUNT":
      return ["accountId", location.id];
    case "LOCATION_LABEL":
      return ["locationLabel", [location.name.replaceAll(",", ",,"), location.value].join(",")]; // backend expects two commas here
    default:
      return [key, location?.id];
  }
};

export const defaultNormalizeResponse = data => {
  return { data: data?._embedded, pageInfo: data?.page };
};

const missingRequired = (filter, filterValues) => filter.required && (!filterValues || !filterValues[filter.name]);

/**
 * Hook for asynchronously loading data with filters.
 * @param filters The filters config object;
 * @param url The endpoint to fetch from.
 * @param projection The name of the projection; must be supported by the endpoint.
 * @param normalizeResponse A function to transform the result.
 * @param prepareFilters A function to transform filter values.
 * @param additionalParams Additional parameters to the endpoint.
 * @param enabled {boolean} Enable/disable api request.
 * @param getEnabled {(filterValues: object) => boolean} A function for set/reset enabled synchronously with api params debouncing.
 * @param options Additional options to the underlying useQuery hook.
 * @returns {UseQueryResult<{data: *, pageInfo: *}, unknown>}
 */
export default function useFilterQuery(
  {
    filters,
    url,
    projection,
    normalizeResponse = defaultNormalizeResponse,
    prepareFilters = defaultPrepareFilters,
    additionalParams,
    enabled = true,
    getEnabled,
    debounceDelay = DEBOUNCE_DELAY_500_MS
  },
  options
) {
  const { allFiltersClosed } = useContext(DataProviderContext);
  const [filterValues] = useFilterState();
  const [filterQueryParams, setFilterQueryParams] = useState({});
  const debouncedSetFilterQueryParams = useMemo(
    () => debounce(setFilterQueryParams, debounceDelay),
    [setFilterQueryParams, debounceDelay]
  );
  useEffect(() => {
    const validFilters =
      !!filters && !!filterValues?.urlLoaded && !filters?.some(f => missingRequired(f, filterValues));
    const params = { ...prepareFilters(filterValues, projection, filters), ...additionalParams };
    if (!isEqual(params, filterQueryParams.params)) {
      debouncedSetFilterQueryParams({
        params,
        validFilters
      });
    }
  }, [filterValues, projection, filters, additionalParams, prepareFilters, filterQueryParams.params]);

  const [get] = useGet(url, { params: filterQueryParams.params });
  const queryRun = useCallback(() => get().then(normalizeResponse), [get, normalizeResponse]);
  return useQuery([url, filterQueryParams.params], queryRun, {
    enabled: !!(
      allFiltersClosed &&
      (typeof getEnabled === "function" ? getEnabled(filterValues) : enabled) &&
      filterQueryParams.validFilters
    ),
    ...options
  });
}
