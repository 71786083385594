import get from "lodash/get";
import identity from "lodash/identity";
import reduce from "lodash/reduce";
import throttle from "lodash/throttle";
import { mergeDeepLeft } from "ramda";
import { applyMiddleware, compose, createStore } from "redux";
import { thunk } from "redux-thunk";
import { getPersistedStateFragments, loadState, saveState } from "../../util/localstorage";
import logger from "../../util/logger";
import rootReducer from "../reducers";
import { reject } from "lodash";

function configureStore(initialState) {
  const loadedState = loadState();
  const loadedFragments = reject(getPersistedStateFragments("viewState/"), "feed");
  const store = createStore(
    rootReducer,
    { ...initialState, ...loadedState, viewState: mergeDeepLeft(loadedFragments, loadedState?.viewState || {}) },
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : identity
    )
  );

  store.subscribe(
    throttle(() => {
      const state = store.getState();
      logger.addGlobalContext("agencyId", get(state, "currentAgency.id"));
      logger.addGlobalContext("user", get(state, "cognito.user.email"));
      logger.addGlobalContext("auth", get(state, "cognito.user"));

      const persist = {
        currentAgency: state.currentAgency,
        viewState: reduce(
          state.viewState,
          (result, value, key) => {
            //save out any state marked as serializable
            if (value.persistent) {
              result[key] = { persistent: value.persistent };
            }
            return result;
          },
          {}
        )
      };
      // Originally had written this to allow state to mark itself as persistent
      // const persist = reduce(state, (result, value, key) => {
      //   //save out any state marked as serializable
      //   if (value.serializable === true) {
      //     result[key] = value;
      //   }
      //   return result;
      // }, {});
      saveState(persist);
    }, 1000)
  );

  return store;
}

const store = configureStore();
export default store;
