import React, { useCallback, useState } from "react";
import ConfigurableBarChartWithLegend from "../../../components/Charts/ConfigurableBarChartWithLegend";
import { getActivatedCustomers } from "../../../api/reportApi";
import moment from "moment";
import get from "lodash/get";
import map from "lodash/map";
import { Button } from "reactstrap";
import { Intervals } from "../../../constants/chart";

export default () => {
  const [totalActive, setTotalActive] = useState();
  const dataSource = useDataSource(setTotalActive);

  return (
    <ConfigurableBarChartWithLegend
      title={"Activated Customers" + (totalActive ? " - " + totalActive : "")}
      dataSource={dataSource}
      chartProps={{
        colors: {
          Activations: "rgba(128, 128, 128, .2)"
        },
        xAxis: {
          interval: 2,
          domain: ["dataMin", "dataMax"]
        },
        yAxis: {
          domain: [0, "dataMax"],
          interval: Intervals.preserveStartEnd,
          scale: "linear",
          allowDecimals: false
        },
        legend: {
          iconSize: 15
        },
        tooltip: {
          content: tooltipFormatter
        }
      }}
    />
  );
};

const tooltipFormatter = ({ active, payload, label }) => {
  if (active && payload && payload.length > 0) {
    let data = payload[0].payload;
    return (
      <Button>
        <div className={`d-flex flex-column align-items-start flex-grow-1`}>
          <span>
            <strong>{`${moment.unix(label).local().format("M/YYYY")}`}</strong>
          </span>
          {get(data, "Activations") != null ? <span>{"Activations: " + get(data, "Activations")}</span> : <></>}
        </div>
      </Button>
    );
  } else {
    return null;
  }
};

const useDataSource = setTotalActive => useCallback(getData(setTotalActive), []);

const getData = setTotalActive => () => {
  return getActivatedCustomers().then(({ totalActive, activatedCustomers }) => {
    setTotalActive(totalActive);
    return map(activatedCustomers, e => {
      return { ...e, type: "Activations" };
    });
  });
};
