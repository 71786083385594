import SimpleMultiField from "./MultiField";
import WWTypeAhead from "./Typeahead/Typeahead";
import { useGet } from "hooks/dataHooks";
import { useQuery } from "react-query";
import { get, uniqBy } from "lodash";

import style from "./LabelSelector.module.scss";

export const NONE_LABEL = "Not Set";

export const useLabelsFilter = (
    query = {}, // keys: name, value, nameOrValue
    additionalParams,
    enabled = true,
    pageSize = 10
  ) => {
    const queryKey = ["filter", "labels", query, additionalParams],
      [get] = useGet("/locationLabels", {
        params: {
          ...query,
          ...additionalParams,
          size: pageSize,
          sort: "name, asc"
        }
      });

    return useQuery({ queryKey, queryFn: get, enabled, keepPreviousData: true });
  },
  makeLabelSearch =
    (key, labelKey, { unique = true } = {}) =>
    (queryValue = "", additionalParams, enabled, pageSize) => {
      const { data = [], isLoading } = useLabelsFilter({ [key]: queryValue }, additionalParams, enabled, pageSize),
        options = (!unique ? data : uniqBy(data, label => (key ? get(label, key) : label))).filter(x => x);

      return {
        isLoading,
        labelKey,
        options
      };
    },
  SEARCHERS = {
    name: makeLabelSearch("name", "name"),
    value: makeLabelSearch("value", "value"),
    nameOrValue: makeLabelSearch("nameOrValue", label => [label.name, label.value].join(": ")),
    all: makeLabelSearch("nameOrValue", label => [label.name, label.value || NONE_LABEL].join(": "), { unique: false })
  };

export const WWLabelSplitFieldsWithTypeahead = props => {
  const { label } = props,
    makeProps = (key, { allowNew, label, onChange, clearButton = true } = {}) => ({
      allowNew,
      clearButton,
      selected: [label?.[key]].filter(x => x),
      onChange: value => value[0] && onChange({ ...label, [key]: value[0]?.[key] || value[0] }),
      onInputChange: value => onChange({ ...label, [key]: value }),
      useSearch: SEARCHERS[key],
      newSelectionPrefix: "Add: "
    });

  return (
    <span className={style.labelSelector}>
      <WWTypeAhead {...makeProps("name", props)} valid={false} placeholder="Search Label Names..." />
      <WWTypeAhead
        {...makeProps("value", props)}
        placeholder="Search Label Values..."
        additionalParams={label.name ? { nameExact: label.name } : {}}
      />
    </span>
  );
};

export const WWLabelUnifiedFieldsWithTypeahead = ({ search = "all", multiple, ...props }) => {
  const makeProps = ({ labels, label, onChange = () => {}, clearButton = true } = {}) => ({
    multiple,
    clearButton,
    selected: (multiple ? labels : [label]).filter(x => x),
    onChange: value => (multiple ? onChange(value) : onChange(value[0])),
    useSearch: SEARCHERS[search]
  });

  return (
    <span className={style.labelSelector}>
      <WWTypeAhead placeholder="Search Labels..." {...makeProps(props)} />
    </span>
  );
};

export const LabelMultiSelector = props => {
  const { split } = props,
    changeLabels = newLabels => props.onChange(newLabels),
    onLabelChange = index => newLabel => {
      const labels = [...props.labels];
      labels[index] = newLabel;
      changeLabels(labels);
    },
    onLabelRemove = index => {
      const labels = [...props.labels];
      labels.splice(index, 1);
      changeLabels(labels);
    },
    Component = props.split ? WWLabelSplitFieldsWithTypeahead : WWLabelUnifiedFieldsWithTypeahead;

  return (
    <SimpleMultiField
      headings={split ? ["Label Name", "Label Value"] : ["Label"]}
      items={props.labels}
      itemLabel="Label"
      renderItem={(label = [], index) => (
        <Component label={label} onChange={onLabelChange(index)} allowNew={props.allowNew} />
      )}
      onAddItem={() => changeLabels([...props.labels, []])}
      onRemoveItem={onLabelRemove}
    />
  );
};
