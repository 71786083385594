import { patch, get, post } from "./apiClient";

export function categoriesSearch(filter) {
  return get("google/categories", { params: { filter } });
}

export function categoriesSearchById(idFilter) {
  if (idFilter === "") {
    // Otherwise we'll fetch everything
    return Promise.resolve([]);
  } else {
    return get("google/categories", { params: { idFilter } });
  }
}

export function serviceAreaSearch(description) {
  return get("google/places", { params: { description } });
}

/**
 *
 * @param {{
 *   name: string;
 * }} listing,
 * @param {string} customerId
 * @param {string} listingId
 */
export const updateSyncMode = (customerId, listingId, syncMode) =>
  patch(["listings", customerId, listingId], {
    syncMode
  });

/**
 *
 * @param {string} customerId
 * @param {string} listingId
 */
export const publishListing = (customerId, listingId) => post(["listings", customerId, listingId, "publish"]);
