import { useContext, useEffect, useMemo } from "react";
import { FirebaseContext } from "../containers/FirebaseLayer";

/**
 * Allows you to get firebase database instance
 * @returns { import("firebase").database.Database }
 */
export const useFirebaseDB = () => useContext(FirebaseContext);

/**
 *
 * @param {string} path
 */
export const useFirebaseRef = path => {
  const db = useFirebaseDB();
  return useMemo(() => db.ref(path), [db, path]);
};

/**
 *
 * @param {import("firebase").database.Reference} ref
 * @param {string} evt
 * @param {() => any} cb
 */
export const useFirebaseEventListener = (ref, evt, cb) =>
  useEffect(() => (ref.on(evt, cb), () => ref.off(evt, cb)), [ref, evt, cb]);
