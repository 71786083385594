import React from "react";
import FieldLabel from "components/Form/FieldLabel";

const ListingFieldLabel = ({ children, link }) => (
  <FieldLabel
    className="d-inline-block mb-2"
    link={
      link && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      )
    }
  >
    {children}
  </FieldLabel>
);

export default React.memo(ListingFieldLabel);
