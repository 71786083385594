import React from "react";
import WWButton from "../Buttons/WWButton";

export default function CloseButton({ ...props }) {
  return (
    <WWButton iconClass="fa fa-close" color="danger" trackingAction="Close" {...props}>
      Close
    </WWButton>
  );
}
