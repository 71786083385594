import styles from "./WidgetContainer.module.scss";

const WidgetContainer = ({ children }) => {
  return (
    <div className="d-flex justify-content-center">
      <div className={styles.widgetContainer}>{children}</div>
    </div>
  );
};

// We need this component to let MultiCard styles count sibling elements properly.
// Widget wrapped in MultiCard should be the last child after components with single width card (or the only child).
export const WidgetsGroup = ({ children }) => <div className={styles.widgetsGroup}>{children}</div>;

export default WidgetContainer;
