import WidgetContainer, { WidgetsGroup } from "components/DashboardWidgets/CommonComponents/WidgetContainer";
import InviteOverviewWidget from "components/DashboardWidgets/InviteOverviewWidget/InviteOverviewWidget";
import ReviewTrendsWidget from "components/DashboardWidgets/ReviewTrendsWidget/ReviewTrendsWidget";
import TopicSentimentWidget from "components/DashboardWidgets/TopicSentimentWidget/TopicSentimentWidget";
import ReviewWidget from "components/DashboardWidgets/ReviewWidget/ReviewWidget";
import VideoLibraryWidget from "components/DashboardWidgets/VideoLibraryWidget/VideoLibraryWidget";
import ListingsWidget from "components/DashboardWidgets/ListingsWidget/ListingsWidget";
import LocationPerformanceWidget from "components/DashboardWidgets/LocationPerformanceWidget/LocationPerformanceWidget";
import YourProductsWidget from "components/DashboardWidgets/YourProductsWidget/YourProductsWidget";

export const UserDashboard = () => (
  <WidgetContainer>
    <WidgetsGroup>
      <ReviewWidget />
    </WidgetsGroup>
    <WidgetsGroup>
      <ReviewTrendsWidget />
      <InviteOverviewWidget />
      <LocationPerformanceWidget />
      <TopicSentimentWidget />
      <VideoLibraryWidget />
      <ListingsWidget />
      <YourProductsWidget />
    </WidgetsGroup>
  </WidgetContainer>
);

export default UserDashboard;
