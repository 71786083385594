// uncomment this line if you have something to import from your scss module
// import {} from "./GenericContentSkeleton.module.scss"

import Skeleton from "components/Skeleton";
import TextSkeleton from "../TextSkeleton/TextSkeleton";

export default function GenericContentSkeleton({ groupsAmount = 1, contentAmount }) {
  return (
    <>
      {[...Array(groupsAmount)].map((e, index) => (
        <div className="mb-5" key={index}>
          <h4>
            <Skeleton className="w-25" />
          </h4>
          <TextSkeleton rows={contentAmount} />
        </div>
      ))}
    </>
  );
}
