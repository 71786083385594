import * as client from "./apiClient";

const path = "agencies";

export function loadAgencies() {
  return client.get(path);
}

export function loadAgency(id) {
  return client.get([path, id]);
}

// export function createAgency(name) {
//   return client.postObject(path, { name })
// }

export function addContact(agencyId, contactId) {
  return client.addAssociation([path, agencyId, "contacts"], contactId);
}

export function removeContact(agencyId, contactId) {
  return client.doDelete([path, agencyId, "contacts", contactId]);
}
