import { Col, Container, Row } from "reactstrap";

export const WWContainer = ({ ...props }) => {
  return <Container {...props} />;
};

export const WWRow = ({ ...props }) => {
  return <Row {...props} />;
};

export const WWCol = ({ ...props }) => {
  return <Col {...props} />;
};
