import { isString, isUndefined, isNull } from "lodash";
import moment from "moment";
import memoize from "memoizee";

export const DISPLAY_FORMAT = "L";
export const ISO_FORMAT = "YYYY-MM-DD";

export const DAYS_OF_WEEK = Object.freeze({
  monday: "MONDAY",
  tuesday: "TUESDAY",
  wednesday: "WEDNESDAY",
  thursday: "THURSDAY",
  friday: "FRIDAY",
  saturday: "SATURDAY",
  sunday: "SUNDAY"
});

export const getLabelFromHoursValue = (hour, min) => {
  const maxMinutes = 24 * 60;
  const minutesValue = hour * 60 + (min || 0);
  if (minutesValue > maxMinutes) {
    return "";
  } else if (minutesValue === maxMinutes) {
    return "11:59 PM";
  }
  const minValue = min || 0;
  const formattedMin = minValue < 10 ? `0${minValue}` : minValue;
  const amPmHourLabelValue = hour === 0 ? "12" : hour > 12 ? hour - 12 : hour;
  return `${amPmHourLabelValue}:${formattedMin} ${hour < 12 ? "AM" : "PM"}`;
};

export const getHourOption = ({ hour, min }) => ({
  label: getLabelFromHoursValue(hour, min),
  value: isUndefined(min)
    ? { hour }
    : {
        hour,
        min
      }
});

export const getTimeOptions = () => {
  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    timeOptions.push(
      getHourOption({
        hour
      })
    );
    timeOptions.push(
      getHourOption({
        hour,
        min: 30
      })
    );
  }
  timeOptions.push({
    label: "24 hours",
    value: { hour: null }
  });
  return timeOptions;
};

export const parseHourInput = inputValue => {
  const values = inputValue.split(":");
  const invalidResult = { valid: false };
  const hoursRegExp = /^((\d+)\s*(am|pm)?)$/i;
  const hoursMatch = values[0]?.match(hoursRegExp);
  const minMatch = values[1]?.match(hoursRegExp);

  if (!hoursMatch || (isString(values[1]) && !minMatch)) {
    return invalidResult;
  }

  const [, , rawHour, hourAmPm] = hoursMatch;
  const [, , rawMin, fullAmPm] = minMatch || [];
  const amPm = (hourAmPm || fullAmPm || "").toUpperCase();

  const integerHour = Number(rawHour);
  const integerMin = Number(rawMin?.length === 1 ? rawMin * 10 : rawMin);
  const noMinutes = isUndefined(rawMin);

  if (
    isNaN(integerHour) ||
    (hourAmPm && fullAmPm) ||
    integerHour > 24 ||
    (amPm === "PM" && integerHour === 0) ||
    (amPm === "AM" && integerHour > 12) ||
    (!noMinutes && (isNaN(integerMin) || integerMin > 60))
  ) {
    return invalidResult;
  }

  const min = noMinutes || integerMin === 0 ? undefined : integerMin;

  let hour = integerHour;
  if (integerHour === 24 || (integerHour === 12 && amPm === "AM")) {
    hour = 0;
  } else if (integerHour < 12 && amPm === "PM") {
    hour = integerHour + 12;
  }

  return {
    hour,
    min,
    valid: true
  };
};

export const HOURS_OPTIONS = getTimeOptions();

export const sortHoursOptions = (a, b) => {
  const getMinutesAfterMidnight = ({ value }) => (value?.hour || 0) * 60 + (value?.min || 0);

  if (isNull(a.value.hour && b.value.hour)) {
    return isNull(a.value.hour) ? 1 : -1;
  }

  return getMinutesAfterMidnight(a) - getMinutesAfterMidnight(b);
};

export function toYMD(date) {
  return moment(date).format("YYYY-MM-DD");
}

export default function toMomentObject(dateString, customFormat) {
  const dateFormats = customFormat ? [customFormat, DISPLAY_FORMAT, ISO_FORMAT] : [DISPLAY_FORMAT, ISO_FORMAT];

  const date = moment(dateString, dateFormats, true);
  return date.isValid() ? date.hour(12) : null;
}

/**
 * Converts provided moment object to ISO format
 * in UTC timezone
 * @param {import("moment").Moment} date
 * @returns
 */
export const datetime2iso = date => (date.clone ? date.clone().toISOString(true) : undefined);

/**
 * checks to see if val is an ISOString
 * @param {string} val
 * @returns {boolean}
 */
export const isISOString = val => {
  return isString(val) && moment.parseZone(val, moment.ISO_8601, true).isValid();
};

/**
 * checks to see if the val is a valid date the backend will accept
 * @param {string} val
 * @returns {boolean}
 */
export const isApiDateString = val => {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}(Z|(\+|\-)(\d{4}|\d{2}\:\d{2}))/.test(val);
};

/**
 * @type {(a: moment.Moment) => (b: moment.Moment) => boolean}
 */
export const sameDate = a => b => a === b || (!!a && !!b && a.isSame(b, "day"));

export const now = () => moment();
export const beforeNow = unit => (amount = 1) => now().subtract(amount, unit);
export const daysBeforeNow = beforeNow("days");
export const weeksBeforeNow = beforeNow("weeks");

/**
 * @type {(a: moment.Moment) => moment.Moment}
 */
export const startOfTheDay = v =>
  v
    .clone()
    .milliseconds(0)
    .seconds(0)
    .minutes(0)
    .hours(0);

/**
 * @type {(a: moment.Moment) => moment.Moment}
 */
export const endOfTheDay = v =>
  startOfTheDay(v)
    .add(1, "day")
    .subtract(1, "millisecond");

/**
 * @type {(a: moment.Moment) => (b: moment.Moment) => boolean}
 */
export const sameDay = a => b => a?.isSame(b, "day");

export const getLastYearsSelectOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array(100)
    .fill(undefined)
    .map((_, index) => {
      const year = currentYear - index + 5;
      return { label: year, value: year };
    });
};

export const getMonthSelectOptions = () =>
  moment.monthsShort().map((month, index) => ({ value: index + 1, label: month }));

export const getDaysOfMonthSelectOptions = memoize(({ year, month }) => {
  const daysInMonth = year && month && moment(`${year}-${month}`).daysInMonth();
  return isNaN(daysInMonth)
    ? []
    : Array(daysInMonth)
        .fill(undefined)
        .map((_, index) => ({ label: index + 1, value: index + 1 }));
});
