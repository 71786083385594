import WWAccordion from "components/WWAccordion/WWAccordion";
import WWOffcanvas from "components/WWOffCanvas/WWOffcanvas";
import { useMemo } from "react";
import { filterFactory } from "../FacetedSearch";
import ResetSearchFiltersButton from "../ResetFiltersButton";
import { useFilterState } from "hooks/filteringHooks";

const FilterOffcanvas = ({ filters, isOpen, toggle, filterValues = "test" }) => {
  const [values] = useFilterState();

  const topButtons = useMemo(
    () =>
      filters
        .filter(filter => filter?.offCanvas?.position === "TOP")
        .map((filter, i) => filterFactory(filter).actionButton(filter, filter?.sideBarTopButton?.className)),
    [filters]
  );

  const accordionFilter = useMemo(
    () =>
      filters
        .filter(filter => filter?.offCanvas?.position !== "TOP" && filter?.offCanvas?.position !== "NONE")
        .map((filter, i) => {
          return {
            caption: filter.label,
            body: filterFactory(filter)?.filterComponent(filter),
            openOnInit: filter?.accordionFilter?.openOnInit || !!values?.[filter.name]
          };
        }),
    [filters, values]
  );

  return (
    <WWOffcanvas isOpen={isOpen} toggle={toggle}>
      <>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <ResetSearchFiltersButton filters={filters} />
          {topButtons}
        </div>
        <WWAccordion items={accordionFilter} />
      </>
    </WWOffcanvas>
  );
};

export default FilterOffcanvas;
