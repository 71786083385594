import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Alert, Col, Label, Row } from "reactstrap";
import { isEqual, uniqWith } from "lodash";
import classNames from "classnames";
import InputField from "components/Form/InputField";
import ToggleSwitch from "components/Form/Switch";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import TypeaheadSearchField from "components/Form/Typeahead/TypeaheadSearchField";
import SelectField from "components/Form/SelectField";
import { useErrorNotification } from "components/Notifications/notification";
import WWButton from "components/Buttons/WWButton";
import RemoveFieldControl from "components/Form/RemoveFieldControl";
import FormField from "components/Form/FormField";
import { FormContext } from "components/Form/Form";
import LockedField from "components/LockedField/LockedField";
import ListingFieldLabel from "./ListingFieldLabel";
import { americanStateAbbreviations, countryOptionsValues, currencyCodes } from "data/locations";
import { serviceAreaSearch } from "api/listingsApi";
import { getDaysOfMonthSelectOptions, getMonthSelectOptions } from "util/dateUtils";
import { validateOpeningDate } from "util/validators";
import { join } from "util/stringUtils";
import { LISTINGS_LINKS } from "constants/links";

import styles from "./Forms.module.scss";

const { inlineInputClass, dateInputClass, addCategoryClass } = styles;

const SERVICE_ITEMS = "serviceItems";
const MAX_SERVICE_ITEMS_COUNT = 100;

const openingDateMonthsOptions = getMonthSelectOptions();

const LocationForm = ({ initialValues, openingDate, serviceItemsOptions = [] }) => {
  const { serviceItems } = initialValues;
  const context = useContext(FormContext);
  const [serviceAreaOptions, setServiceAreaOptions] = useState([]);
  const [serviceAreaLoading, setServiceAreaLoading] = useState(false);
  const [serviceItemsFields, setServiceItemsFields] = useState([]);
  const [serviceItemsNextIndex, setServiceItemsNextIndex] = useState(0);
  const [serviceItemsWithCustomOptions, setServiceItemsWithCustomOptions] = useState(serviceItemsOptions || []);
  const [showOpeningDate, setShowOpeningDate] = useState();

  const serviceItemsString = JSON.stringify(serviceItems),
    usedServiceItemsNames = serviceItemsFields.reduce((acc, field) => {
      const value = context.state[`${field}.googleServiceItemId`];
      const label =
        value?.label ||
        value?.displayName ||
        serviceItemsWithCustomOptions.find(option => (option.serviceTypeId || option.value) === value?.serviceTypeId)
          ?.displayName;
      return acc.concat(label);
    }, []);

  useEffect(() => {
    setServiceItemsFields(serviceItems.length > 0 ? serviceItems.map((_, index) => `${SERVICE_ITEMS}[${index}]`) : []);
    setServiceItemsNextIndex(serviceItems?.length || 0);
  }, [serviceItemsString]);

  const isOptionDisabled = option =>
    usedServiceItemsNames?.some(usedName => usedName === (option.displayName || option.label));

  useEffect(() => {
    setServiceItemsWithCustomOptions(prv =>
      uniqWith(
        [
          ...prv,
          ...serviceItemsOptions,
          ...serviceItems
            .filter(serviceItem => serviceItem?.name)
            .map(serviceItem => Object.assign({}, { label: serviceItem.name, value: serviceItem.name }))
        ],
        isEqual
      ).sort((a, b) => Number(isOptionDisabled(a)) - Number(isOptionDisabled(b)))
    );
  }, [JSON.stringify(serviceItemsOptions), serviceItemsString, JSON.stringify(usedServiceItemsNames)]);

  const errorNotification = useErrorNotification();

  const onServiceAreaSearch = useCallback(description => {
    setServiceAreaLoading(true);
    return serviceAreaSearch(description)
      .then(({ data }) => {
        setServiceAreaOptions(data);
        setServiceAreaLoading(false);
      })
      .catch(e => {
        setServiceAreaLoading(false);
        errorNotification(e);
      });
  }, []);

  const getLocationDescription = useCallback(
    ({ googleAreaName, description }) => join([googleAreaName, description], ", "),
    []
  );

  const daysOptions = getDaysOfMonthSelectOptions(openingDate || {});

  const openingYearValidationResult = useMemo(() => validateOpeningDate(openingDate), [openingDate]);

  const addServiceItems = useCallback(() => {
    setServiceItemsFields(serviceItemsFields.concat([`${SERVICE_ITEMS}[${serviceItemsNextIndex}]`]));
    setServiceItemsNextIndex(prev => prev + 1);
  }, [serviceItemsFields, serviceItemsNextIndex]);

  const selectServiceItem = useCallback(
    event => {
      context.setRawValue(event);
    },
    [context, serviceItemsWithCustomOptions]
  );

  const removeServiceItems = useCallback(
    field => {
      setServiceItemsFields(serviceItemsFields.filter(categoryField => categoryField !== field));
    },
    [serviceItemsFields, serviceItemsWithCustomOptions]
  );

  const createFreetextOption = useMemo(
    () => name => inputValue => {
      const newOption = {
        label: inputValue,
        value: inputValue
      };
      if (!serviceItemsWithCustomOptions.find(option => isEqual(option, newOption))) {
        setServiceItemsWithCustomOptions([...serviceItemsWithCustomOptions, newOption]);
      }
      context.setRawValue({
        target: { name, value: newOption }
      });
    },
    [context, serviceItemsWithCustomOptions]
  );

  const onShowOpeningDate = useCallback(() => setShowOpeningDate(true), []);
  const onHideOpeningDate = useCallback(() => setShowOpeningDate(false), []);
  const addressLockComponent = useCallback(
    unlock => (
      <div>
        <h5 className="mb-1">
          Business Address
          <WWButton color="link" onClick={unlock} iconClass="fa fa-pencil" trackingAction={"Edit Address"} />
        </h5>
        <Row className="my-3">
          <Col>
            <p>
              {join(
                [
                  initialValues?.addressLine1,
                  initialValues?.addressLine2,
                  initialValues?.locality,
                  initialValues?.administrativeArea,
                  initialValues?.postalCode
                ],
                ", "
              )}
            </p>
          </Col>
        </Row>
      </div>
    ),
    [initialValues]
  );

  return (
    <>
      <h5 className="mb-1">Business Location</h5>
      <ListingFieldLabel link={LISTINGS_LINKS.businessLocation}>
        If customers visit your business, add an address.
      </ListingFieldLabel>
      <ToggleSwitch
        onLabel=""
        offLabel=""
        label="Show location address to customers"
        labelClass=""
        inputWidth={6}
        className="mt-3 mb-4 me-5 pe-5"
        name="addressStatus"
      />
      <LockedField lockedComponent={addressLockComponent}>
        <Alert color="primary">
          Changing your business address may require{" "}
          <a href={"https://support.google.com/business/answer/7107242?hl=en"} target={"_blank"}>
            re-verification
          </a>{" "}
          of your business on Google and can affect your search rankings.
        </Alert>
        <div className={inlineInputClass}>
          <InputField name="addressLine1" label="Street address" />
        </div>
        <div className={inlineInputClass}>
          <InputField name="addressLine2" label="Address line 2" />
        </div>
        <div className={inlineInputClass}>
          <InputField name="locality" label="City" />
        </div>
        <Row className="my-3">
          <Col sm={12} md={6}>
            <HorizontalSelectField
              name="administrativeArea"
              label="State"
              simpleValue={true}
              options={americanStateAbbreviations}
            />
          </Col>
          <Col sm={12} md={6}>
            <InputField name="postalCode" id="listingsEditPostalCode" label="ZIP Code" />
          </Col>
        </Row>
        <Row className="my-3">
          <Col sm={12} md={12}>
            <HorizontalSelectField name="countryCode" label="Country" options={countryOptionsValues} />
          </Col>
        </Row>
      </LockedField>
      <h5 className="mb-1">Service Area</h5>
      <Row className="my-3">
        <ListingFieldLabel link={LISTINGS_LINKS.serviceArea}>
          Let customers know where your location provides deliveries or services.
        </ListingFieldLabel>
        <div className="d-flex align-items-center justify-content-between">
          <TypeaheadSearchField
            className="w-100"
            id="serviceAreas"
            placeholder="Search Area"
            name="serviceAreas"
            iconClass="fa fa-search"
            initialValue={initialValues?.serviceAreas}
            isLoading={serviceAreaLoading}
            onSearch={onServiceAreaSearch}
            options={serviceAreaOptions}
            labelKey={getLocationDescription}
            multyLabelKey={getLocationDescription}
            multyValuesLabel="Selected service areas"
            caseSensitive={false}
            multySelect
          />
        </div>
      </Row>
      <Row className="my-3">
        <h5 className="mb-1">Opening Date</h5>
        <ListingFieldLabel link={LISTINGS_LINKS.openingDate}>
          Add the date you opened or will open at the address.
        </ListingFieldLabel>
        {(!!openingDate || showOpeningDate) && (
          <>
            <Col xs={9} md={11}>
              <Row>
                <Col xs={12} md={4}>
                  <Label className="text-muted fw-normal">Year*</Label>
                  <InputField
                    name="openingDate.year"
                    type="number"
                    className={dateInputClass}
                    valid={!openingYearValidationResult ? undefined : false}
                    feedbackMessage={openingYearValidationResult}
                  />
                </Col>
                <Col xs={12} md={4} className="mb-3">
                  <Label className="text-muted fw-normal">Month*</Label>
                  <SelectField
                    label="Month*"
                    name="openingDate.month"
                    options={openingDateMonthsOptions}
                    className={dateInputClass}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <Label className="text-muted fw-normal">Day</Label>
                  <SelectField label="Day" name="openingDate.day" options={daysOptions} className={dateInputClass} />
                </Col>
              </Row>
            </Col>
            <Col xs={1} className="ps-1 ps-sm-2 pe-0">
              <div className="pb-3 pb-md-0 px-0 mt-1 d-flex align-items-end align-items-md-center h-100">
                <RemoveFieldControl forField="openingDate" onClick={onHideOpeningDate} />
              </div>
            </Col>
          </>
        )}
        {!showOpeningDate && !openingDate && (
          <div className={classNames(addCategoryClass, { "mt-2": !serviceItemsFields.length })}>
            <WWButton iconClass="fa fa-plus" color="link" onClick={onShowOpeningDate}>
              Add opening date
            </WWButton>
          </div>
        )}
      </Row>
      <Row className="my-3">
        <h5 className="mb-1">Services Offered</h5>
        <ListingFieldLabel link={LISTINGS_LINKS.servicesOffered}>
          Let customers know what services you offer.
        </ListingFieldLabel>
        {serviceItemsFields.map(field => (
          <div className={inlineInputClass} key={field}>
            <Row className="my-3">
              <Col sm={12} md={5}>
                <FormField name={`${field}.googleServiceItemId`} label="Service Item">
                  <SelectField
                    creatable
                    onChange={selectServiceItem}
                    getOptionLabel={option => option.displayName || option.label}
                    getOptionValue={option => option.serviceTypeId || option.value}
                    onCreateOption={createFreetextOption(`${field}.googleServiceItemId`)}
                    isOptionDisabled={isOptionDisabled}
                    name={`${field}.googleServiceItemId`}
                    options={serviceItemsWithCustomOptions}
                  />
                </FormField>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <InputField
                  type="number"
                  className={dateInputClass}
                  name={`${field}.price`}
                  id="price"
                  label="Price (Optional)"
                />
              </Col>
              <Col xs={5} sm={5} md={3}>
                <FormField name={`${field}.currencyCode`} label="Currency">
                  <SelectField
                    placeholder="USD"
                    defaultValue="USD"
                    simpleValue
                    name={`${field}.currencyCode`}
                    options={currencyCodes}
                  />
                </FormField>
              </Col>
              <Col xs={1} className="ps-1 ps-sm-2 pe-0">
                <div className="px-0 pt-5 mt-2 d-flex align-items-center">
                  <RemoveFieldControl forField={field} onClick={() => removeServiceItems(field)} />
                </div>
              </Col>
            </Row>
          </div>
        ))}
        {serviceItemsFields.length < MAX_SERVICE_ITEMS_COUNT && (
          <div className={classNames(addCategoryClass, { "mt-2": !serviceItemsFields.length })}>
            <WWButton iconClass="fa fa-plus" color="link" onClick={addServiceItems}>
              Add Service Item
            </WWButton>
          </div>
        )}
        {serviceItemsFields.length >= MAX_SERVICE_ITEMS_COUNT && (
          <Alert color="warning">
            {serviceItemsFields.length === MAX_SERVICE_ITEMS_COUNT
              ? `This listing has ${MAX_SERVICE_ITEMS_COUNT} service items. This is the maximum that can be managed.`
              : `This listing has exceeded ${MAX_SERVICE_ITEMS_COUNT} service items, the maximum that can be managed.`}
          </Alert>
        )}
      </Row>
    </>
  );
};

export default React.memo(LocationForm);
