import React, { useState } from "react";
import { Button } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import InputField from "../../../components/Form/InputField";
import truncate from "lodash/truncate";

export default class RevolutionParts {
  field() {
    return "revolutionPartsInfo";
  }

  add() {
    const AddRevolutionParts = ({ customer, onSave }) => {
      const [username, setUsername] = useState();
      const [apiToken, setApiToken] = useState();

      return (
        <>
          <InputField
            type="text"
            label="Username"
            placeholder="Username"
            onChange={e => setUsername(e.target.value)}
            value={username}
          />
          <InputField
            type="text"
            label="API Token"
            placeholder="API Token"
            onChange={e => setApiToken(e.target.value)}
            value={apiToken}
          />

          <FormField label=" ">
            <Button
              color="primary"
              onClick={() => {
                onSave({
                  username,
                  apiToken,
                  source: "REVOLUTION_PARTS"
                });
              }}
            >
              Link
            </Button>
          </FormField>
        </>
      );
    };
    return AddRevolutionParts;
  }

  edit() {
    const EditRevolutionParts = ({ info, customer }) => {
      return (
        <>
          <FormField label="Username">
            <>
              <span className="text-truncate w-auto d-inline-block">{info.username}</span>
            </>
          </FormField>
          <FormField label="API Token">
            <>
              <span className="text-truncate w-auto d-inline-block">{truncate(info.apiToken)}</span>
            </>
          </FormField>
        </>
      );
    };
    return EditRevolutionParts;
  }
}
