import WWChip from "components/WWChips/WWChip/WWChip";
import { useFilterValueState } from "hooks/filteringHooks";
import { InputGroup, InputGroupText } from "reactstrap";
import { valueOf } from "util/functionUtils";
import classNames from "classnames";
import SelectField from "components/Form/SelectField";
import styles from "./SelectFilter.module.scss";
const { inputGroup } = styles;

const SelectFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <SelectFilterBar key={props.name} className={className} {...props} />
  );
  const chip = ({ ...props }, className) => <SelectChip key={props.name} {...props} className={className} />;
  const filterComponent = ({ ...props }, className) => (
    <SelectFilterSidebar key={props.name} {...props} className={className} />
  );
  return {
    barComponent,
    chip,
    filterComponent
  };
};

export const SelectFilterBar = ({ className, name, label, icon, loadOptions, ...props }) => {
  const [value, setter] = useFilterValueState(name);

  return (
    <InputGroup className={classNames("flex-nowrap flex-shrink-1", inputGroup, className)}>
      <InputGroupText className="fa fa-building d-none d-sm-block" />
      <SelectField
        id={name}
        name={name}
        value={value}
        loadOptions={loadOptions}
        onChange={event => setter(event.target.value)}
        {...props}
      />
    </InputGroup>
  );
};

export const SelectChip = ({ name, className, iconClass, defaultValue, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return value ? (
    <WWChip
      iconClass={iconClass}
      key={name}
      className="overflow-visible"
      onClick={() => setter(valueOf(defaultValue))}
      {...props}
    >
      {value}
    </WWChip>
  ) : null;
};

export const SelectFilterSidebar = ({ name, label, loadOptions, className, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return (
    <SelectField
      id={name}
      name={name}
      value={value}
      loadOptions={loadOptions}
      onChange={event => setter(event.target.value)}
      {...props}
    />
  );
};

export default SelectFactory;
