import moment from "moment";
import { adjust, evolve, hasPath, ifElse, map, mergeLeft, mergeRight, pathOr, paths, pipe, prepend } from "ramda";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { cleanUpRequestParams } from "util/functionUtils";
import { useGet } from "../dataHooks";
import { useCurrentAgency } from "../agencyHooks";

const defaultNormalizeRequest = pipe(
  cleanUpRequestParams,
  // fallback param values
  mergeRight({
    page: 0,
    size: 50,
    sort: "reviewDate,desc",
    filter: {}
  }),
  // always override value, even if different is present
  mergeLeft({
    latest: true
  }),
  // flatten filters
  ({ filter, ...rest }) => ({ ...rest, ...filter })
);

export const defaultNormalizeResponse = ifElse(
  hasPath(["_embedded", "reviews"]),
  pipe(
    paths([["_embedded", "reviews"], ["page"]]),
    adjust(
      0,
      map(
        evolve({
          createdOn: moment,
          reviewDate: moment
        })
      )
    )
  ),
  pipe(pathOr(undefined, ["page"]), prepend([]))
);

/**
 * @param {
 *  requestParams?: {},
 *  normalizeRequest?: function,
 *  normalizeResponse?: function,
 *  enabled?: bool,
 *  withPlaceholderData?: bool
 * }
 * @returns {import("react-query").UseQueryResult<[
 *  Array<{
 *    createdOn: import("moment").Moment,
 *    reviewDate: import("moment").Moment,
 *  }>,
 *  {
 *    size: number;
 *    totalElements: number;
 *    totalPages: number;
 *    number: number;
 *   }?
 * ]>}
 */
export const useReviewsQuery = ({
  requestParams = {},
  normalizeRequest = defaultNormalizeRequest,
  enabled = true,
  withPlaceholderData = false
}) => {
  const currentAgency = useCurrentAgency();
  const params = useMemo(() => normalizeRequest(requestParams), [requestParams]);
  const queryKey = useMemo(() => ["/reviews", params, currentAgency.id], [params, currentAgency.id]);
  const [get] = useGet("/reviews", { params });
  const queryRun = useCallback(() => get().then(defaultNormalizeResponse), [get]);
  return useQuery(queryKey, queryRun, {
    enabled,
    ...(withPlaceholderData && { placeholderData: [[], undefined] })
  });
};

/**
 * @param {{}} requestParams
 * @param boolean enabled
 * @param {{}} normalizeRequest
 * @returns {import("react-query").UseQueryResult<[
 *  Array<{
 *    createdOn: import("moment").Moment,
 *    reviewDate: import("moment").Moment,
 *  }>,
 *  {
 *    size: number;
 *    totalElements: number;
 *    totalPages: number;
 *    number: number;
 *   }?
 * ]>}
 */
const useReviews = (requestParams, enabled = true, normalizeRequest, withPlaceholderData = true) =>
  useReviewsQuery({ requestParams, enabled, normalizeRequest, withPlaceholderData });

export default useReviews;
