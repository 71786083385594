/**
 * Enables displaying of campaigns section in main navigation bar
 */
export const CAMPAIGNS = "campaigns";

/**
 * Never used
 */
export const MENTIONS = "mentions";

/**
 * Shows whether review should automatically tagged by review-auto-tagger
 * Out of date
 */
export const REVIEW_ENTITY_DETECTION = "review.entityDetection";

/**
 * Enables displaying of media tab on Listing view page
 */
export const LISTING_MEDIA = "listing.media";

/**
 * Enables adding of review topics to prompt context in AI-Service
 */
export const PROMPT_REVIEW_TOPICS = "prompt.review-topics";

/**
 * Enables adding review to prompt context in AI-Service
 */
export const PROMPT_REVIEWS = "prompt.reviews";

/**
 * Enables adding review replies to prompt context in AI-Service
 */
export const PROMPT_REVIEW_REPLIES = "prompt.review-replies";

/**
 * Never used
 */
export const LISTING_UPDATE_LOG_BKP = "listing.updateLog.bkp";

/**
 * Shows whether to use old way of entrata request authorization.
 */
export const ENTRATA_USE_CLIENT_CREDS = "entrata-use-client-creds";

/**
 * Defines whether to use  Update Log for listings or Diff log (both on frontend and backend)
 */
export const LISTING_UPDATE_LOG = "listing.updateLog";

/**
 * Never used
 */
export const UPDATE_LOG = "updateLog";

/**
 * Enables BH manager contact csv upload for bulk contact creation/managemnt
 */
export const BH_MANAGER_UPLOAD = "bhManagerUpload";
