import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { get, isEmpty, isUndefined, map, size } from "lodash";
import { includes } from "ramda";
import { GoogleBusinessProfileApi } from "api/googleBusinessProfileApi";
import AjaxLoader, { AjaxLoaderSizes } from "components/Misc/AjaxLoader";
import WWTextDetails from "components/WWTextDetails/WWTextDetails";
import ProgressTracker from "components/ProgressTracker/ProgressTracker";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import SelectField from "components/Form/SelectField";
import WWButton from "components/Buttons/WWButton";
import Block from "components/Layout/Block";
import { Col, Collapse, Row } from "reactstrap";
import WWAccordionSection from "components/WWAccordion/WWAccordionSection";
import StatusInfo from "components/Table/StatusInfo/StatusInfo";
import StatusMessages from "components/Misc/StatusMessages";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import { useNotification } from "components/Notifications/notification";
import { DefaultDuplicateWarning } from "./AdminLinking";
import { useAddSite, useSiteInfo, useUpdateDefaultReviewTag } from "hooks/customerHooks";
import AdBlockError from "components/Misc/AdBlockError";
import { SITE_SOURCE_NAMES, SOURCES } from "data/sites";
import styles from "./SelfServeLinking.module.scss";

const { linkedSitesAccordion, linkedSitesAccordionTitle } = styles;

export const GoogleIntegrationSteps = Object.freeze({
  logIn: "log in",
  selectAccount: "select account",
  linkProfiles: "link profiles"
});

const LOG_IN_STEP_INDEX = 0;
const SELECT_ACCOUNT_STEP_INDEX = 1;
const LINK_SITE_STEP_INDEX = 2;
const FINISH_STEP_INDEX = 3;

const WW_GMB_USER = "setup@widewail.com";
const googleApiClient = new GoogleBusinessProfileApi();

const GoogleLinking = ({ customer, setStepData }) => {
  const [gmbInfo, setGmbInfo] = useState(customer.gmbInfo || []);
  const [activeStepIndex, setActiveStepIndex] = useState(LOG_IN_STEP_INDEX);
  const [accountList, setAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const [locationList, setLocationList] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState();
  const [working, setWorking] = useState(false);
  const [tag, setTag] = useState();
  const [errors, setErrors] = useState();
  const [isDepartmentSpecific, setIsDepartmentSpecific] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const currentAgency = useSelector(state => state.currentAgency);

  const notify = useNotification();

  const canLinkWithoutTag = useMemo(
    () => !gmbInfo.filter(({ defaultReviewTag }) => isUndefined(defaultReviewTag)).length > 0,
    [gmbInfo]
  );

  const availableTags = useMemo(
    () =>
      customer?.reviewTagSet?.tags?.filter(
        value =>
          !includes(
            value,
            gmbInfo.map(({ defaultReviewTag }) => defaultReviewTag)
          )
      ),
    [customer?.reviewTagSet?.tags, gmbInfo]
  );

  const [authorized, setAuthorized] = useState(false);
  useEffect(() => {
    googleApiClient.setCallback((result = true) => setAuthorized(result));
  }, []);

  const reset = () => {
    setSelectedAccount(undefined);
    setSelectedLocations(undefined);
    setStepData(undefined);
  };

  const siteLinkingParams = {
    customerId: customer.id,
    field: SOURCES[SITE_SOURCE_NAMES.google].customerField
  };

  const addSite = useAddSite(siteLinkingParams);
  const updateDefaultReviewTag = useUpdateDefaultReviewTag(siteLinkingParams);
  const siteInfo = useSiteInfo(siteLinkingParams);

  useEffect(() => {
    const { data, isSuccess, isLoading } = siteInfo;
    if (!isLoading && isSuccess) {
      setGmbInfo(data?.googleMyBusinessInfoes || []);
    }
  }, [siteInfo.data, siteInfo.isSuccess, siteInfo.isLoading]);

  const linkSitePromise = useMemo(
    () => async ({ id, name, reviewUrl, address: { fullAddress } }) => {
      let hasWidewailAdmin = false;
      try {
        const { admins } = (await googleApiClient.fetchAdmins(id)) || [];
        hasWidewailAdmin = !!admins.find(
          ({ admin }) => admin.toLowerCase() === "setup widewail" || admin === WW_GMB_USER
        );
      } catch (err) {
        throw err;
      }
      return (hasWidewailAdmin ? Promise.resolve() : googleApiClient.inviteManager(id, WW_GMB_USER)).then(() =>
        addSite({
          location: id,
          account: selectedAccount.id,
          businessName: name,
          source: SITE_SOURCE_NAMES.google,
          address: fullAddress,
          reviewUrl: reviewUrl,
          defaultReviewTag: tag
        })
      );
    },
    [customer, selectedAccount, tag]
  );

  useEffect(() => {
    return () => {
      setAuthorized(false);
      googleApiClient.clearToken();
    };
  }, []);

  useEffect(() => {
    if (authorized) {
      setActiveStepIndex(SELECT_ACCOUNT_STEP_INDEX);
      googleApiClient
        .fetchAccounts()
        .then(accounts => {
          if (size(accounts) === 1) {
            setSelectedAccount(accounts[0]);
          }
          setAccountList(accounts);
        })
        .catch(error => setErrors(error));
    } else {
      setActiveStepIndex(LOG_IN_STEP_INDEX);
      reset();
    }
  }, [authorized]);

  useEffect(() => {
    if (selectedAccount) {
      setActiveStepIndex(SELECT_ACCOUNT_STEP_INDEX);
      googleApiClient
        .fetchLocations(selectedAccount.id)
        .then(locations => setLocationList(locations))
        .catch(error => setErrors(error));
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!isEmpty(selectedLocations)) {
      setActiveStepIndex(LINK_SITE_STEP_INDEX);
    }
  }, [selectedLocations]);

  const onSelectLocation = useCallback(
    ({ target: { value } }) => {
      setSelectedLocations(value);
    },
    [selectedLocations]
  );

  const onLink = useCallback(() => {
    if (!working) {
      setWorking(true);
      linkSitePromise(selectedLocations)
        .then(res => {
          setActiveStepIndex(FINISH_STEP_INDEX);
          setStepData(true);
          notify({ body: "successful profile linking" });
          setSelectedLocations(null);
        })
        .catch(error => {
          setDuplicates(error.response?.data?.duplicateLinks);
          setErrors("Access was successfully granted but there was an error linking in Widewail.");
        })
        .finally(() => setWorking(false));
    }
  }, [working, selectedLocations, tag]);

  const onTagChange = useCallback((event, info) => {
    updateDefaultReviewTag({ defaultReviewTag: event.target.value, siteId: info.id })
      .then(res => setGmbInfo(res.data))
      .catch(error => setErrors(error));
  }, []);

  return (
    <>
      <ProgressTracker
        steps={[
          GoogleIntegrationSteps.logIn,
          GoogleIntegrationSteps.selectAccount,
          GoogleIntegrationSteps.linkProfiles
        ]}
        activeStepIndex={activeStepIndex}
      ></ProgressTracker>
      <div className="mt-5 pt-1">
        <StatusMessages errors={errors} />
        <DefaultDuplicateWarning duplicates={duplicates} agency={currentAgency} />
        <h4>Log In</h4>
        To manage your reviews Widewail must be added as a manager on your business. You must have Owner permissions on
        the location before continuing.
        <div className="d-flex">
          {window.google ? (
            <div className="mt-3 mx-1">
              <WWButton onClick={() => googleApiClient.requestToken()} disabled={authorized}>
                Sign in with Google
              </WWButton>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <br />
              <AdBlockError service="Google" />
            </div>
          )}
          {authorized && (
            <div className="mt-3 mx-4">
              <WWButton
                transparent
                onClick={() => {
                  setAuthorized(false);
                  googleApiClient.clearToken();
                }}
              >
                LOG OUT
              </WWButton>
            </div>
          )}
        </div>
      </div>
      {authorized && (
        <div className="mt-4 pt-4">
          <h4>Select account</h4>
          <HorizontalSelectField
            name="selectedAccount"
            placeholder="Pick an account..."
            label="Account"
            options={accountList}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={selectedAccount}
            onChange={evt => setSelectedAccount(evt.target.value)}
          />
        </div>
      )}
      {selectedAccount && (
        <div className="mt-5">
          <h4>Link a Profile</h4>
          <SelectField
            name="selectedProfile"
            placeholder="Pick a Profile..."
            disabled={isUndefined(selectedAccount)}
            isOptionDisabled={({ id }) => !!gmbInfo.find(({ location }) => location === id)}
            options={locationList}
            getOptionLabel={({ name, address, metadata }) =>
              `${metadata && metadata.duplicate ? "(DUPLICATE)" : ""} ${name} ${address?.fullAddress &&
                "-" + address.fullAddress}`
            }
            getOptionValue={({ id }) => id}
            onChange={onSelectLocation}
            value={selectedLocations}
          />
          <div className="mt-4 pt-2">
            {canLinkWithoutTag ? (
              <>
                <Labeled
                  label="Is this a department specific profile?"
                  checked={isDepartmentSpecific}
                  onToggle={setIsDepartmentSpecific}
                />
                <Collapse isOpen={isDepartmentSpecific}>
                  <HorizontalSelectField
                    name="defaultReviewTag"
                    value={tag}
                    label="Default Review Tag"
                    simpleValue={true}
                    options={availableTags}
                    onChange={event => setTag(event.target.value)}
                    isClearable={true}
                  />
                </Collapse>
              </>
            ) : (
              <HorizontalSelectField
                name="defaultReviewTag"
                value={tag}
                label="Default Review Tag"
                simpleValue={true}
                options={availableTags}
                onChange={event => setTag(event.target.value)}
                isClearable={true}
              />
            )}
          </div>
          <div className="mt-4">
            <WWButton
              color="primary"
              disabled={!selectedLocations || working || (!canLinkWithoutTag && !tag)}
              onClick={onLink}
            >
              {working ? <AjaxLoader size={AjaxLoaderSizes.XS} /> : "LINK PROFILES"}
            </WWButton>
          </div>
        </div>
      )}
      <Block className="my-4">
        <hr />
        {size(gmbInfo) > 0 && <h4>Linked Profiles</h4>}
        {map(gmbInfo, info => (
          <Block key={info.id}>
            <WWAccordionSection
              caption={
                <div className={linkedSitesAccordion}>
                  <div className={linkedSitesAccordionTitle}>
                    <span className="fs-3">{info.businessName}</span>
                    <div className={linkedSitesAccordion}>
                      {info.defaultReviewTag && <strong className="fs-3">{info.defaultReviewTag}</strong>}
                      <StatusInfo
                        className="fs-3"
                        status="Linked"
                        statusColorMapper={{
                          Linked: "success"
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
            >
              {size(gmbInfo) > 0 && (
                <Row>
                  <Col>
                    <HorizontalSelectField
                      name="defaultReviewTag"
                      value={info.defaultReviewTag}
                      label="Default Review Tag"
                      simpleValue={true}
                      options={customer.reviewTagSet.tags}
                      onChange={event => onTagChange(event, info)}
                      isClearable={true}
                    />
                  </Col>
                </Row>
              )}
            </WWAccordionSection>
          </Block>
        ))}
      </Block>
      <div className="mt-4 py-4">
        <WWTextDetails caption="Manual Instructions">
          If you are experiencing errors linking, verify you have the proper access to your business listing by logging
          into the{" "}
          <a href="https://business.google.com/locations" target="_blank" rel="noopener noreferrer">
            Google Business Profile Manager
          </a>{" "}
          and following the steps below:
          <ol>
            <li>Click on your business in the list</li>
            <li>Click the three vertical dots to the right of the "Your business on Google"</li>
            <li>Click "Business Profile settings"</li>
            <li>Click "Managers"</li>
            <li>Add setup@widewail.com as a Manager</li>
            <li>Click invite</li>
          </ol>
          If the above steps did not work, or you were unable to add the user, then it is likely you do not have the
          correct permissions in Google Business Profile Manager to complete this task. If it was successful, then
          Widewail will automatically link your business from here.
        </WWTextDetails>
      </div>
    </>
  );
};

export default GoogleLinking;
