import WWTableBlock, { DIFF_DIRECTION, ValueWithDiff } from "components/Table/WWTableBlock";
import withAuthorization, { withAgency } from "components/Auth/Authorization";
import { WWTabs } from "components/WWTabs";
import ReportWithFilters, {
  COMPARISON_OPTIONS,
  getComparisonsOptions,
  useReportFilterQuery
} from "components/HOCs/ReportWithFilters";
import { datetime2iso, daysBeforeNow, endOfTheDay, now, startOfTheDay } from "util/dateUtils";
import { useEffect, useState } from "react";

import WWButton from "components/Buttons/WWButton";
import { SEARCHERS as getLabels } from "components/Form/LabelSelector";

import styles from "./EnterpriseReport.module.scss";
import { useFilterState } from "hooks/filteringHooks";
import { get, isEmpty, isEqual } from "lodash";
import { TableCard } from "components/Table/WWTable";
import classNames from "classnames";
import { useFilterValueState } from "hooks/filteringHooks";

import { permissions } from "../../components/Auth/permissions";
import { defaultPrepareFilters } from "hooks/data/useFilterQuery";
import { exportEnterpriseCSV } from "api/reportApi";
import { useNotification, useErrorNotification } from "components/Notifications/notification";

import { useNotes } from "components/Widgets/Notes";
import { getReviewSourceFilterConfig } from "components/FacetedSearch/FilterRecipes";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

export const GROUP_BY_DEFAULT = "Location";

const TOOLTIPS = {
  General:
    "Reviews that could not be determined to match a specific category or match multiple categories fall under the General category."
};

export const reportFilterNamer = mode => `c-${mode}`,
  trimSchema = schema => schema.filter(x => !isEmpty(x) && typeof x !== "undefined"),
  mapHeaders = (schema = []) => schema.map(a => a.label),
  serializeSchemaForFilter = (schema = []) => {
    return trimSchema(schema.map(({ label, hidden } = {}) => ({ label, hidden: !!hidden })));
  },
  deserializeSchemaFromFilter = (filterValue = {}) => {
    if (isEmpty(filterValue)) return [];

    let ret;

    if (Array.isArray(filterValue?.label)) {
      ret = filterValue?.label?.map((label, i) => ({
        label,
        hidden: get(filterValue, `hidden[${i}]`) === "true"
      }));
    } else {
      ret = [filterValue];
    }

    return trimSchema(ret);
  };

const makeFilters =
  ({ labelOptions = [] } = {}) =>
  ({ filterValues = {} } = {}) => [
    getAccountFilterConfig(),
    {
      name: "locationLabel",
      label: "Location Labels",
      type: "locationLabel"
    },
    {
      name: "dateRange",
      label: "Date range",
      type: "dates",
      queryParams: ["startDate", "endDate"],
      required: true,
      defaultValue: {
        startDate: datetime2iso(startOfTheDay(daysBeforeNow(30))),
        endDate: datetime2iso(endOfTheDay(now()))
      },
      primaryBar: {
        position: "LEFT",
        className: "d-md-flex auto-width d-none"
      },
      chip: {
        position: "NONE"
      }
    },
    {
      name: "compareMode",
      label: "Compare to...",
      type: "buttonDropdown",
      required: true,
      showLabelInMenu: true,
      clearable: false,
      defaultValue: COMPARISON_OPTIONS.RELATIVE,
      options: getComparisonsOptions(filterValues?.dateRange),
      placeholder: "Compare to...",
      primaryBar: {
        position: "LEFT",
        className: "d-none d-md-flex comparison-selector"
      },
      chip: {
        position: "NONE"
      }
    },
    getReviewSourceFilterConfig(),
    {
      name: "groupByLabel",
      label: "Group By",
      type: "buttonDropdown",
      defaultValue: GROUP_BY_DEFAULT,
      showLabelInMenu: false,
      clearable: false,
      options: labelOptions,
      placeholder: "Location",
      outerLabel: "Group By",
      primaryBar: {
        position: "RIGHT",
        className: "d-none d-md-flex"
      },
      chip: {
        position: "NONE"
      }
    },
    {
      name: "sort",
      defaultValue: "rowLabel,asc",
      required: true,
      nonFilter: true,
      offCanvas: {
        position: "NONE"
      },
      chip: {
        position: "NONE"
      }
    },
    {
      name: "report",
      defaultValue: Object.keys(REPORT_TABLES)[0],
      required: true,
      excluded: true,
      nonFilter: true,
      offCanvas: {
        position: "NONE"
      },
      chip: {
        position: "NONE"
      }
    },
    ...Object.keys(REPORT_TABLES).map(mode => ({
      name: reportFilterNamer(mode),
      required: true,
      excluded: true,
      nonFilter: true,
      defaultValue: serializeSchemaForFilter(REPORT_TABLES[mode].makeSchema(filterValues)),
      offCanvas: {
        position: "NONE"
      },
      chip: {
        position: "NONE"
      }
    }))
  ];

// wrapper to easily pull out the current and previous values from this report response
export const getValueWithDiff = (row, col) => {
  const value = get(row, col.valueKey),
    diff = value - get(row, `previous[${col.valueKey}]`);

  return [value, diff];
};
export const renderWithPrevious = (row, col) => {
  const [value, diff] = getValueWithDiff(row, col);

  return <ValueWithDiff value={value} diff={diff} {...STANDARD_COLUMNS[col.valueKey]} />;
};

// dynamically generates the first column of the table based on the groupby selected in the current filter state
const FirstColumn = ({ label }) => {
  const [filterValues, setFilterValues] = useFilterState(),
    [groupByLabel] = useFilterValueState("groupByLabel"),
    setFilterValue = () => {
      setFilterValues({
        ...filterValues,
        locationLabel: `${groupByLabel},${typeof label === "undefined" ? "" : label}`,
        groupByLabel: GROUP_BY_DEFAULT
      });
    },
    regularLabel = label || (
      <a onClick={setFilterValue} href="#" className={styles.fallback}>
        Not set
      </a>
    ),
    clickableLabel = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href="#" onClick={setFilterValue}>
        {label}
      </a>
    ),
    showClickableLabel = label && groupByLabel && groupByLabel !== GROUP_BY_DEFAULT;

  return showClickableLabel ? clickableLabel : regularLabel;
};
// By Site and By Category versions of the table cell component
const BreakdownCell = ({ row, col }) => {
  const cell = row.columns.find(r => r.columnLabel === col?.valueKey),
    breakdownRows = Object.keys(STANDARD_COLUMNS)
      .filter(k => STANDARD_COLUMNS[k].showInBreakdown)
      .map((k, i) => {
        const [value, diff] = getValueWithDiff(cell, { valueKey: k });

        return (
          <div key={k + i} className={styles.breakdownValueCell}>
            <strong>{STANDARD_COLUMNS[k].label}</strong>
            <span className={styles.valueWithDiff}>
              <ValueWithDiff
                value={value}
                diff={STANDARD_COLUMNS[k].showDiffInBreakdown ? diff : null}
                {...STANDARD_COLUMNS[k]}
              />
            </span>
          </div>
        );
      });

  return <div className={styles.enterpriseReportBreakdownCell}>{breakdownRows}</div>;
};

// Defines the core columns of the table for the overview and the rows for BreakdownCell
const STANDARD_COLUMNS = {
  totalReviews: {
    label: "Total Reviews",
    renderCell: renderWithPrevious,
    type: WWTableBlock.cellTypes.number,
    showInBreakdown: true,
    showDiffInBreakdown: true,
    sort: true
  },
  rating: {
    label: "Rating",
    renderCell: renderWithPrevious,
    type: WWTableBlock.cellTypes.number,
    showInBreakdown: true,
    showDiffInBreakdown: true,
    sort: true
  },
  negativeReviews: {
    label: "% Negative",
    type: WWTableBlock.cellTypes.percent,
    diffDirection: DIFF_DIRECTION.down,
    showInBreakdown: true,
    sort: true
  },
  repliedReviews: { label: "Response Rate", type: WWTableBlock.cellTypes.percent, showInBreakdown: true, sort: true },
  responseTime: {
    label: "Response Time",
    type: WWTableBlock.cellTypes.days,
    diffDirection: DIFF_DIRECTION.down,
    sort: true
  }
};

// given a set of fields (like location labels) generate a WWTableBlock compatible schema
const generateSchema = (fields = {}, preColumns = [], postColumns = []) => {
  const ignoreKeys = ["sort"];

  return [
    ...preColumns,
    ...Object.keys(fields).map(k => ({
      ...Object.keys(fields[k]).reduce(
        (ret, fk) => (ignoreKeys.includes(fk) ? ret : { ...ret, [fk]: fields[k][fk] }),
        {}
      ),
      valueKey: k,
      renderCell: fields[k]?.renderCell || fields[k]?.type?.renderCell || JSON.stringify,
      sortOptions: fields[k]?.sortOptions || fields[k].sort ? [`${k},asc`, `${k},desc`] : []
    })),
    ...postColumns
  ].filter(x => x);
};

export const renderGroupByCell = (row, col) => <FirstColumn label={row[col.valueKey]} />;
export const renderLocationLabelCell = (row, col) =>
  (row.locationLabels || [])
    .filter(ll => col.label === ll.name)
    .map(ll => (
      <span key={ll.value} className={styles.truncate} title={ll.value}>
        {ll.value}
      </span>
    ));

// defines the schema for the overview tab, which cares are the known location labels
export const makeOverviewSchema = ({ groupByLabel = GROUP_BY_DEFAULT, locationLabels = [] }) =>
  generateSchema(STANDARD_COLUMNS, [
    {
      label: groupByLabel,
      valueKey: "rowLabel",
      renderCell: renderGroupByCell,
      tooltip: TOOLTIPS[groupByLabel],
      sortOptions: [`rowLabel,asc`, `rowLabel,desc`]
    },
    ...(groupByLabel !== GROUP_BY_DEFAULT ? [] : locationLabels).map(l => ({
      label: l.name,
      valueKey: "locationLabels",
      fallback: "-",
      renderCell: renderLocationLabelCell,
      tooltip: TOOLTIPS[l.name],
      sortOptions: [`label:${l.name},asc`, `label:${l.name},desc`]
    }))
  ]);

export const renderDynamicCell = (row, col) => <BreakdownCell {...{ row, col }} />;

// defines the schema for By Site and By Category, which cares mor about the keys in the data returned more than the filter state
export const makeDynamicSchema = ({ groupByLabel = GROUP_BY_DEFAULT, sampleRow }) => {
  const cols = sampleRow?.columns?.reduce(
    (ret, { columnLabel } = {}) =>
      columnLabel
        ? {
            ...ret,
            [columnLabel]: {
              label: columnLabel,
              renderCell: renderDynamicCell,
              tooltip: TOOLTIPS[columnLabel]
            }
          }
        : ret,
    {}
  );
  return generateSchema(
    cols,
    [
      {
        label: groupByLabel,
        valueKey: "label",
        renderCell: renderGroupByCell,
        sortOptions: ["rowLabel,asc", "rowLabel,desc"],
        tooltip: TOOLTIPS[groupByLabel]
      }
    ],
    []
  );
};

// wraps useReportFilterQuery with some Enterprise Report specific functionality, also mananges the sorts and paging
const useEnterpriseReportQuery = ({ mode, show, selector }) => {
  const { rawData, ...filterQuery } = useReportFilterQuery({
    enabled: () => show,
    url: "/report/group",
    forceResults: true,
    additionalParams: { mode, frequency: "DAILY" },
    debounceDelay: 0,
    defaultSort: "rowLabel,asc",
    pageSize: 25
  });

  const [locationLabel, setLocationLabel] = useFilterValueState("locationLabel");
  const [groupByLabel = GROUP_BY_DEFAULT] = useFilterValueState("groupByLabel");

  // if the location label is set as 'Not Set' and the group by changes, reset the location location
  useEffect(() => {
    if (groupByLabel !== GROUP_BY_DEFAULT && isEmpty(locationLabel?.split(",")[1])) setLocationLabel(null);
  }, [groupByLabel, setLocationLabel]);

  return {
    ...filterQuery,
    rawData,
    rows: selector({ rawData, ...filterQuery })
  };
};

const useFilterSchema = mode => {
  const [rawFilterSchema, setFilterSchema] = useFilterValueState(reportFilterNamer(mode)),
    filterSchema = deserializeSchemaFromFilter(rawFilterSchema),
    [activeSchema, setActiveSchema] = useState(filterSchema);

  useEffect(() => {
    !isEmpty(activeSchema) && setFilterSchema(serializeSchemaForFilter(activeSchema));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(activeSchema)]);

  return { activeSchema, filterSchema, setActiveSchema };
};

// defines an instance of a ReportTable (eg, one of Overview, By Site, By Category)
const ReportTable = ({
  mode,
  title,
  groupByLabel = GROUP_BY_DEFAULT,
  show,
  selector,
  makeSchema,
  compact,
  locationLabels
}) => {
  const { activeSchema, filterSchema, setActiveSchema } = useFilterSchema(mode);
  const [notesComp, { isEditing: isEditingNotes, toggle: toggleNotes }] = useNotes();

  const notifySuccess = useNotification();
  const notifyError = useErrorNotification();

  const { rows, isLoading, ...enterpriseQuery } = useEnterpriseReportQuery({
    mode,
    show,
    selector
  });

  const exportCSV = () => {
    const schemaNames = Object.keys(REPORT_TABLES).map(reportFilterNamer);
    const { filterValues, filters } = enterpriseQuery;
    const exportParams = defaultPrepareFilters({ ...filterValues, mode }, null, filters);

    schemaNames.forEach(name => delete exportParams[name]);

    exportEnterpriseCSV(exportParams)
      .then(() => {
        notifySuccess({
          title: "CSV is being generated",
          body: "CSV export is being generated. You will receive an email with the report attached."
        });
      })
      .catch(notifyError);
  };

  useEffect(() => {
    if (!isLoading && !isEmpty(filterSchema) && (isEmpty(activeSchema) || rows?.length)) {
      const newSchema = makeSchema({ groupByLabel, rows, locationLabels, basisSchema: activeSchema }).map(n => {
          const prev = filterSchema?.find(a => a?.label === n?.label),
            col = {
              ...prev,
              ...n,
              // preserve hidden values if the schema reloads OR force show if option is selected in groupBy
              hidden: prev?.label === groupByLabel ? false : prev?.hasOwnProperty("hidden") ? prev?.hidden : false
            };

          return col;
        }),
        prevHeaders = mapHeaders(filterSchema),
        newHeaders = mapHeaders(newSchema);

      // if the headers match, preserver the prevous order
      isEqual([...newHeaders].sort(), [...prevHeaders].sort())
        ? setActiveSchema(prevHeaders.map(ph => newSchema.find(ns => ns.label === ph)))
        : setActiveSchema(newSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, groupByLabel]);

  const NotesButton = withAgency("Lithia")(() => (
    <WWButton onClick={toggleNotes} color={isEditingNotes ? "success" : "link"}>
      {isEditingNotes ? "Finish Editing" : "Edit Notes"}
    </WWButton>
  ));

  const EditColumsButton = ({ setShowEditor }) => {
    const numHidden = activeSchema?.filter(a => a?.hidden).length,
      hiddenContent = numHidden ? `(${numHidden} hidden)` : null;

    return (
      <WWButton onClick={() => setShowEditor(true)} color="link">
        Edit Columns {hiddenContent}
      </WWButton>
    );
  };

  // show true\false here allows for the component to render and maintain state during tab switching
  return show ? (
    <TableCard>
      <WWTableBlock
        {...enterpriseQuery}
        fallback="-"
        compact={compact}
        heightReduce="100px"
        isLoading={isLoading}
        data={rows}
        title={title}
        subHead={notesComp}
        schema={activeSchema}
        setSchema={setActiveSchema}
        tableContainerClassnames={""} // left empty on purpose
        controls={({ setShowEditor }) =>
          !isLoading && (
            <>
              <NotesButton />
              <EditColumsButton setShowEditor={setShowEditor} />
              <WWButton color="light" onClick={exportCSV}>
                Export CSV
              </WWButton>
            </>
          )
        }
      ></WWTableBlock>
    </TableCard>
  ) : null;
};

/*
Define the report tables availabe on this report., Used to render the tabs, makes the table schemas and defines
data selector functions for the raw report filter query response data

  MY_REPORT: {
    label: "My Report", // Shows in the tabs above the report
    title: "My Great Report Header Overview", // Header above the table
    selector: ({ rawData }) => rawData?._embedded?.rows.map(r => get(r, "columns[0]")), // function to the pull the relevant data from the useFilteQuery response
    makeSchema: ({ groupByLabel, locationLabels }) => makeOverviewSchema({ groupByLabel, locationLabels }) // function to define the schema used for this instance of ReportTable
  }
*/

const REPORT_TABLES = {
  OVERVIEW: {
    label: "Overview",
    title: "Enterprise Reporting Overview",
    selector: ({ rawData }) => rawData?._embedded?.rows.map(r => get(r, "columns[0]")),
    makeSchema: ({ groupByLabel, locationLabels }) => makeOverviewSchema({ groupByLabel, locationLabels })
  },
  BY_SITE: {
    label: "By Site",
    title: "Enterprise Reporting By Site",
    selector: ({ rawData }) => rawData?._embedded?.rows,
    makeSchema: ({ groupByLabel, rows }) => makeDynamicSchema({ groupByLabel, sampleRow: get(rows, "[0]") }),
    compact: true
  },
  BY_CATEGORY: {
    label: "By Category",
    title: "Enterprise Reporting By Category",
    selector: ({ rawData }) => rawData?._embedded?.rows,
    makeSchema: ({ groupByLabel, rows }) => makeDynamicSchema({ groupByLabel, sampleRow: get(rows, "[0]") }),
    compact: true
  }
};

// Main component for Enterprise Report view. Defines the tab state.
function EnterpriseReport() {
  const { options: locationLabels, isLoading } = getLabels.name(null, null, true, 100000); // this pageSize value is a hack to get the complete list
  const [{ groupByLabel } = {}] = useFilterState();
  const [activeTab, setActiveTab] = useFilterValueState("report");

  const locationLabelOptions = locationLabels.reduce(
    (ret, o) => [...ret, { label: o.name, value: o.name, valueKey: o.name }],
    []
  );
  const labelOptions = [{ label: GROUP_BY_DEFAULT, value: null }, ...locationLabelOptions];

  const tabs = Object.keys(REPORT_TABLES).map(k => ({
    ...REPORT_TABLES[k],
    mode: k,
    active: activeTab === k,
    onClick: () => setActiveTab(k),
    content: (
      <ReportTable
        mode={k}
        show={activeTab === k}
        groupByLabel={groupByLabel}
        locationLabels={locationLabels}
        {...REPORT_TABLES[k]}
      />
    )
  }));

  return (
    !isLoading && (
      <div className={classNames("py-4 px-xs-0 px-lg-4 container-fluid", styles.enterpriseReportTable)}>
        <ReportWithFilters forceResults filtersFn={makeFilters({ labelOptions })}>
          <WWTabs tabs={tabs} align="left" />
          {tabs.map(t => (
            <span key={t.mode}>{t.content}</span>
          ))}
        </ReportWithFilters>
      </div>
    )
  );
}

export default withAuthorization(permissions.REPORT_READ)(EnterpriseReport);
