import { property } from "lodash";
import * as client from "./apiClient";

// FOR DEBUGGING PURPOSES...
const storage = JSON.parse(sessionStorage.getItem("testHubspotStorage")) || {
  infos: [],
  pipelines: []
};
var nextid = JSON.parse(sessionStorage.getItem("testHubspotNextId")) || 1;

window.addEventListener("beforeunload", () => {
  sessionStorage.setItem("testHubspotNextId", nextid);
  sessionStorage.setItem("testHubspotStorage", JSON.stringify(storage));
});
// ...END DEBUGGING

/**
 *
 * @param {string} customerid
 */
export const getHubspotInfos = customerid => client.get(`customers/${customerid}/hubspotInfo`).then(property("data"));
// export const getHubspotInfos = customerid => Promise.resolve(storage.infos);

/**
 *
 * @param {string} customerid
 * @param {string} hubspotInfoId
 */
export const getHubspotPipelines = (customerid, hubspotInfoId) =>
  client.get(`hubspotInfo/${hubspotInfoId}/pipelines`).then(property("data"));
// export const getHubspotInfo = (customerid, hubspotInfoId) => Promise.resolve(storage.infos[0]);

/**
 *
 * @param {string} customerid
 * @param {string} token
 */
export const createHubspotInfo = (customerid, code) =>
  client.post(`customers/${customerid}/hubspotInfo`, null, { params: { code } }).then(property("data"));
// export const createHubspotInfo = (customerid, code) => {
//   storage.infos = [
//     ...storage.infos,
//     {
//       pipelines: [
//         {
//           id: nextid++,
//           name: "Deal",
//           stages: [
//             {
//               id: nextid++,
//               name: "Stage 1"
//             },
//             {
//               id: nextid++,
//               name: "Stage 2"
//             },
//             {
//               id: nextid++,
//               name: "Stage 3"
//             },
//             {
//               id: nextid++,
//               name: "Stage 4"
//             }
//           ]
//         },
//         {
//           id: nextid++,
//           name: "Sales",
//           stages: [
//             {
//               id: nextid++,
//               name: "Stage 1"
//             },
//             {
//               id: nextid++,
//               name: "Stage 4"
//             }
//           ]
//         },
//         {
//           id: nextid++,
//           name: "Service",
//           stages: [
//             {
//               id: nextid++,
//               name: "Stage 1"
//             },
//             {
//               id: nextid++,
//               name: "Stage 2"
//             },
//             {
//               id: nextid++,
//               name: "Stage 3"
//             }
//           ]
//         }
//       ]
//     }
//   ];
//   return Promise.resolve();
// };

/**
 *
 * @param {*} hubspotInfoId
 */
export const deleteHubspotInfo = (customerId, hubspotInfoId) =>
  client.doDelete(`customers/${customerId}/hubspotInfo/${hubspotInfoId}`).then(property("data"));
// export const deleteHubspotInfo = hubspotInfoId => {
//   storage.infos = storage.infos.filter(({ id }) => id !== hubspotInfoId);
//   return Promise.resolve();
// };

/**
 *
 * @param {*} hubspotInfoId
 */
export const getHubspotConfigurations = hubspotInfoId =>
  client.get(`hubspotInfo/${hubspotInfoId}/configuration`).then(property("data"));
// export const getHubspotPipelinesConfigurations = () => Promise.resolve(storage.pipelines);

/**
 *
 * @param {*} hibspotid
 * @param {*} pipeline
 * @param {*} stage
 * @param {*} tag
 */
export const createHubspotConfiguration = (hubspotid, pipelineId, importStage, tag) =>
  client
    .put(`hubspotInfo/${hubspotid}/configuration`, { pipelineId, importStage, defaultInteractionTag: tag })
    .then(property("data"));
// export const createHubspotConfiguration = (hubspotid, pipeline, stage, tag) => {
//   const entry = { pipeline, stage, tag, id: nextid++ };
//   storage.pipelines = [...storage.pipelines, entry];
//   return Promise.resolve(entry);
// };

/**
 *
 * @param {*} hubspotid
 * @param {*} pipelineid
 */
export const removeHubspotPipelineConfiguration = (hubspotid, pipelineid) =>
  client.doDelete(`hubspotInfo/${hubspotid}/configuration/${pipelineid}`).then(property("data"));
// export const removeHubspotPipelineConfiguration = (hubspotid, pipelineid) => {
//   storage.pipelines = storage.pipelines.filter(({ id }) => id !== pipelineid);
//   return Promise.resolve();
// };
