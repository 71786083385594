import React, { useState, useCallback, useEffect } from "react";
import CampaignTypeSelection from "./CampaignTypeSelection/CampaignTypeSelection";
import { CampaignConfig, CampaignConfigNav } from "./CampaignConfig/CampaignConfig";
import NavigableModalContainer from "components/Modals/NavigableModal/NavigableModalContainer/NavigableModalContainer";
import Navigation from "components/Modals/NavigableModal/Navigation/Navigation";
import Pages from "components/Modals/NavigableModal/Pages/Pages";
import CampaignSend from "./CampaignSend/CampaignSend";
import Input from "components/Form/Input";
import { useReduxAt, useStateThroughRedux } from "hooks/stateHooks";
import { useInviteCampaign } from "hooks/data/campaignHooks";

const CampaignEdit = ({ createNew = false, campaignId, setCampaignId, customerProducts }) => {
  const section = useReduxAt(`campaignModal/section`, undefined);
  const [, setCampaignModalOpened] = useStateThroughRedux("campaignModal/opened");
  const [title, setTitle] = useState();
  const [titleEditable, setTitleEditable] = useState(false);
  const resetCampaignId = useCallback(() => {
    setCampaignModalOpened(false);
    setCampaignId(null);
  });
  const editToggle = () => {
    setTitleEditable(prev => !prev);
  };
  const { isFetched, data } = useInviteCampaign(!createNew && campaignId);
  const { type, name } = data || {};

  useEffect(() => {
    if (isFetched && name !== undefined) {
      setTitle(name);
    } else if (isFetched) {
      resetCampaignId();
    }
  }, [isFetched, name]);

  return (
    <NavigableModalContainer
      onClosed={resetCampaignId}
      showNav={section === "config"}
      title={
        section === "config" ? (
          <>
            {titleEditable ? (
              <Input
                id={"campaign-title"}
                name={"campaign-title"}
                className="me-1"
                placeholder="Campaign Name"
                autoFocus
                onBlur={editToggle}
                onChange={event => setTitle(event.target.value)}
                value={title}
              />
            ) : (
              <>
                <i className="fa fa-edit me-2" onClick={editToggle} />
                <h4 className="mb-0 me-1 w-100 text-truncate" onClick={editToggle}>
                  {title}
                </h4>
              </>
            )}
          </>
        ) : (
          <h4>{title}</h4>
        )
      }
      reduxRoot="campaignModal"
    >
      <Navigation display={section === "config"}>
        <CampaignConfigNav type={type} />
      </Navigation>
      <Pages>
        <CampaignTypeSelection setCampaignId={setCampaignId} customerProducts={customerProducts} />
        <CampaignConfig title={title} data={data} campaignId={campaignId} resetCampaignId={resetCampaignId} />
        <CampaignSend customerId={data?.customer.id} />
      </Pages>
    </NavigableModalContainer>
  );
};

export default CampaignEdit;
