import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import * as postApi from "../../api/postApi";
import { Card, CardBody, CardHeader, CardTitle } from "../../components/Card";
import SelectField from "../../components/Form/SelectField";
import { errorCaughtNotifier, useLocalNotifications } from "../../components/Notifications/notification";
import Pager from "../../components/Paging/Pager";
import { Table, TBody, TH, THead, TR } from "../../components/Table";
import { POST_STATUS } from "../../data/options";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../redux/actions/ajaxStatusActions";
import { updateViewState } from "../../redux/actions/viewStateActions";
import PostTableRow from "./PostTableRow";

const statusLabels = {
  ALL: "All",
  DRAFT: "Draft",
  POSTED: "Posted",
  REMOVED: "Removed",
  QUEUED: "Queued",
  FAILED: "Failed",
  SCHEDULED: "Scheduled"
};

const PostList = () => {
  const [posts, setPosts] = useState();
  const [pageInfo, setPageInfo] = useState();
  const filter = useSelector(state => get(state.viewState, "postList.persistent.filter", {}));
  const pageNumber = useSelector(state => get(state.viewState, "postList.pageNumber", 0));
  const dispatch = useDispatch();
  const notify = useLocalNotifications();

  const refresh = () => {
    dispatch(beginAjaxCall());
    return postApi
      .loadPosts(filter, pageNumber, 25)
      .then(response => {
        setPosts(get(response.data, "_embedded.widewailPosts", []));
        const pageInfo = response.data.page;
        setPageInfo(pageInfo);
        dispatch(
          updateViewState("postList", {
            pageNumber: pageInfo ? pageInfo.number : 0
          })
        );
        dispatch(endAjaxCall());
      })
      .catch(() => {
        dispatch(ajaxCallError());
      });
  };

  const updateFilter = event => {
    const { name, value } = event.target;
    dispatch(
      updateViewState("postList", {
        persistent: {
          filter: {
            [name]: value === "ALL" ? null : value
          }
        },
        pageNumber: 0
      })
    );
  };

  const deletePost = useCallback(
    post => {
      if (post.id) {
        postApi
          .deletePost(post.id)
          .then(refresh)
          .catch(errorCaughtNotifier(notify));
      }
    },
    [notify]
  );

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [JSON.stringify(filter), pageNumber]);

  return (
    <Container>
      <Row xs="12" className="px-4 pt-4">
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                Posts
                <Button size="sm" color="link" onClick={refresh}>
                  <i className="icon-refresh"></i>
                </Button>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <THead>
                  <TR>
                    <TH>Modified</TH>
                    <TH>Author</TH>
                    <TH>Content</TH>
                    <TH>
                      <SelectField
                        name="status"
                        options={["ALL", ...POST_STATUS]}
                        value={filter.status || "ALL"}
                        getOptionLabel={({ value }) => statusLabels[value]}
                        simpleValue={true}
                        isClearable={true}
                        onChange={updateFilter}
                      />
                    </TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {posts &&
                    posts.map((post, i) => (
                      <PostTableRow post={post} key={post.id} editable={true} onDelete={deletePost} />
                    ))}
                </TBody>
              </Table>
              <Pager
                pageInfo={pageInfo}
                onPageChange={pageNumber => {
                  dispatch(updateViewState("postList", { pageNumber }));
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

PostList.propTypes = {
  posts: PropTypes.array
};

export default PostList;
