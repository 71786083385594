import classNames from "classnames";
import { Card, CardHeader } from "components/Card";
import React, { useEffect, useRef, useState } from "react";
import styles from "./WWTable.module.scss";
import { isFunction } from "lodash";

/**
 * @param {CardProps} props
 */
export const TableCard = ({ className, ...props }) => (
  <Card className={classNames(styles.card, { [className]: className })} {...props} />
);
export const TableCardHeader = ({ className, ...props }) => (
  <CardHeader className={classNames(styles.cardHeader, { [className]: className })} {...props} />
);

export const WWTable = ({
  id = "table-id",
  loaded,
  hightReduce = "0",
  tableContainerClassnames,
  actionElements = null,
  ...props
}) => {
  const [hideBottomShadow, setHideBottomShadow] = useState(true);
  const [hideLeftShadow, setHideLeftShadow] = useState(true);
  const [hideRightShadow, setHideRightShadow] = useState(true);
  const tableContainerRef = useRef();

  useEffect(() => {
    if (loaded) {
      const bottom =
        Math.ceil(tableContainerRef.current.scrollHeight - tableContainerRef.current.scrollTop) <=
        tableContainerRef.current.clientHeight + 20;
      const right =
        Math.ceil(tableContainerRef.current.scrollWidth - tableContainerRef.current.scrollLeft) <=
        tableContainerRef.current.clientWidth + 20;
      if (!bottom) {
        setHideBottomShadow(false);
      }
      if (!right) {
        setHideRightShadow(false);
      }
    }
  }, [loaded, tableContainerRef]);

  const handleScroll = e => {
    const atBottom = Math.ceil(e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight + 20;
    const atRight = Math.ceil(e.target.scrollWidth - e.target.scrollLeft) <= e.target.clientWidth + 20;
    atBottom ? setHideBottomShadow(true) : setHideBottomShadow(false);
    e.target.scrollLeft === 0 ? setHideLeftShadow(true) : setHideLeftShadow(false);
    atRight ? setHideRightShadow(true) : setHideRightShadow(false);
  };

  return (
    <Card className="p-0 m-0 border-0">
      <div
        id={id}
        ref={tableContainerRef}
        onScroll={handleScroll}
        style={{ maxHeight: `calc(100vh - ${hightReduce})` }}
        className={classNames(styles.tableContainer, tableContainerClassnames)}
      >
        <table className={styles.table} {...props} />
        {actionElements}
        <div className={classNames(styles.scrollIndicatorBottom, { [styles.none]: hideBottomShadow })} />
        <div className={classNames(styles.scrollIndicatorLeft, { [styles.none]: hideLeftShadow })} />
        <div className={classNames(styles.scrollIndicatorRight, { [styles.none]: hideRightShadow })} />
      </div>
    </Card>
  );
};

export const WWTHead = ({ className, ...props }) => (
  <thead className={classNames(styles.tableHead, { [className]: className })} {...props} />
);
export const WWTBody = ({ className, ...props }) => (
  <tbody className={classNames(styles.tableBody, { [className]: className })} {...props} />
);

/**
 * @param {React.HTMLAttributes<HTMLTableRowElement>} props
 */
export const WWTR = ({ className, noHover = false, onClick, ...props }) => (
  <tr
    className={classNames(
      styles.tableRow,
      { [className]: className },
      { [styles.hover]: !noHover },
      { [styles.clickable]: isFunction(onClick) }
    )}
    onClick={onClick}
    {...props}
  />
);

/**
 * @param {React.HTMLAttributes<HTMLTableCellElement>} props
 */
export const WWTH = ({ className, ...props }) => (
  <th className={classNames(styles.tableHeadCell, { [className]: className })} {...props} />
);

/**
 * @param {React.HTMLAttributes<HTMLTableCellElement>} props
 */
export const WWTD = ({ className, ...props }) => (
  <td className={classNames(styles.tableDataCell, { [className]: className })} {...props} />
);
