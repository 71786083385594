import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Collapse } from "reactstrap";
import { updateResmanLink } from "api/customerApi";
import { useNativeInputState } from "hooks/utilHooks";
import InputField from "components/Form/InputField";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import FormField from "components/Form/FormField";
import { useValidatePropertySitesCheckboxes } from "hooks/validationHooks";
import HorizontalSelectField from "../../../components/Form/HorizontalSelectField";
import { useGet } from "../../../hooks/dataHooks";
import { debounce, isEmpty } from "lodash";
import { DEBOUNCE_DELAY_DEFAULT } from "../../../constants/delays";
import { useQuery } from "react-query";

const TOGGLES_LIST_TEXT = "Tour, Move In, Move Out, Renewal, or Maintenance";

export default class ResmanAdminLinking {
  field() {
    return "resmanInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info, customer }) => {
  const [initialImportMoveIn, setInitialImportMoveIn] = useState(info.importMoveIn);
  const [importMoveIn, setImportMoveIn] = useState(initialImportMoveIn);
  const [initialImportMoveOut, setInitialImportMoveOut] = useState(info.importMoveOut);
  const [importMoveOut, setImportMoveOut] = useState(initialImportMoveOut);
  const [initialImportMaintenance, setInitialImportMaintenance] = useState(info.importMaintenance);
  const [importMaintenance, setImportMaintenance] = useState(initialImportMaintenance);
  const [initialImportRenewal, setInitialImportRenewal] = useState(info.importRenewal);
  const [importRenewal, setImportRenewal] = useState(initialImportRenewal);
  const [initialImportTour, setInitialImportTour] = useState(info.importTour);
  const [importTour, setImportTour] = useState(initialImportTour);

  const patchReady =
    initialImportMoveIn !== importMoveIn ||
    initialImportMoveOut !== importMoveOut ||
    initialImportMaintenance !== importMaintenance ||
    initialImportRenewal !== importRenewal ||
    initialImportTour !== importTour;

  const vaidateToggles = useValidatePropertySitesCheckboxes(
    [importTour, importMoveIn, importMoveOut, importMaintenance, importRenewal],
    TOGGLES_LIST_TEXT
  );

  const saveToggles = () =>
    vaidateToggles().then(validationResult => {
      if (validationResult) {
        updateResmanLink(customer.id, info.id, {
          importMoveIn,
          importMoveOut,
          importMaintenance,
          importRenewal,
          importTour
        }).then(() => {
          setInitialImportMoveIn(importMoveIn);
          setInitialImportMoveOut(importMoveOut);
          setInitialImportMaintenance(importMaintenance);
          setInitialImportRenewal(importRenewal);
          setInitialImportTour(importTour);
        });
      }
    });

  return (
    <>
      <InputField type="text" disabled label="Account ID" value={info.accountId} readOnly={true} />
      <InputField type="text" disabled label="Property Name" value={info.propertyName} readOnly={true} />
      <InputField
        type="text"
        disabled
        label="Property Id"
        placeholder="Property Id"
        value={info.propertyId}
        readOnly={true}
      />
      <Labeled name="importTour" checked={importTour} onToggle={setImportTour} label="Import Tour Leads" />
      <Labeled name="importMoveIn" checked={importMoveIn} onToggle={setImportMoveIn} label="Import Move In" />
      <Labeled name="importMoveOut" checked={importMoveOut} onToggle={setImportMoveOut} label="Import Move Out Leads" />
      <Labeled
        name="importMaintenance"
        checked={importMaintenance}
        onToggle={setImportMaintenance}
        label="Import Maintenance Leads"
      />
      <Labeled name="importRenewal" checked={importRenewal} onToggle={setImportRenewal} label="Import Renewal Leads" />

      <Collapse isOpen={patchReady}>
        <FormField label=" ">
          <Button color="primary" onClick={saveToggles} disabled={!patchReady}>
            Update
          </Button>
        </FormField>
      </Collapse>
    </>
  );
};

const Add = ({ onSave }) => {
  const [property, setProperty] = useState("");
  const [accountId, setAccountId] = useNativeInputState("");

  const [query, setQuery] = useState("");
  const queryChangeHandler = debounce(input => setQuery(input), DEBOUNCE_DELAY_DEFAULT);

  useEffect(() => {
    queryChangeHandler(accountId);
  }, [accountId]);

  const resmanProperties = useResmanProperties(accountId);
  const [importMoveIn, setImportMoveIn] = useState(false);
  const [importMoveOut, setImportMoveOut] = useState(false);
  const [importMaintenance, setImportMaintenance] = useState(false);
  const [importRenewal, setImportRenewal] = useState(false);
  const [importTour, setImportTour] = useState(false);

  const allFilled = property;

  const vaidateToggles = useValidatePropertySitesCheckboxes(
    [importMoveIn, importMoveOut, importMaintenance, importRenewal, importTour],
    TOGGLES_LIST_TEXT
  );

  const onLink = () =>
    vaidateToggles().then(validationResult => {
      if (validationResult) {
        onSave({
          propertyId: property.propertyId,
          propertyName: property.name,
          accountId: accountId,
          importMoveIn,
          importMoveOut,
          importMaintenance,
          importRenewal,
          importTour,
          source: "RESMAN"
        });
      }
    });

  return (
    <>
      <InputField label="AccountId" placeholder="AccountId" onChange={setAccountId} value={accountId} />
      <HorizontalSelectField
        name="property"
        label="Property"
        options={resmanProperties.data}
        getOptionLabel={option => option.name}
        getOptionValue={v => v.name}
        onChange={event => setProperty(event.target.value)}
        value={property}
        isMulti={false}
      />
      <Labeled name="importTour" checked={importTour} onToggle={setImportTour} label="Import Tour Leads" />
      <Labeled name="importMoveIn" checked={importMoveIn} onToggle={setImportMoveIn} label="Import Move In Leads" />
      <Labeled name="importMoveOut" checked={importMoveOut} onToggle={setImportMoveOut} label="Import Move Out Leads" />
      <Labeled
        name="importMaintenance"
        checked={importMaintenance}
        onToggle={setImportMaintenance}
        label="Import Maintenance Leads"
      />
      <Labeled name="importRenewal" checked={importRenewal} onToggle={setImportRenewal} label="Import Renewal Leads" />

      <Button color="primary" disabled={!allFilled} onClick={onLink}>
        Link
      </Button>
    </>
  );
};

const useResmanProperties = accountId => {
  const queryKey = `resman/account/${accountId}/property`;
  const [get] = useGet(queryKey);
  return useQuery([queryKey, accountId], get, { enabled: !isEmpty(accountId), select: data => data || [] });
};
