import { noop } from "lodash";
import React, { useCallback } from "react";
import { UncontrolledTooltip } from "reactstrap";
import Switch from "components/Form/Switch";
import TextEditor from "components/Form/TextEditor";
import { autoreplyMessagePlaceholders } from "data/editorPlaceholders";

export const defaultAutoreplyMessageText = `This is not a monitored line. Please give us a call at [BUSINESS PHONE]`;
export const AutoreplyMessaging = ({
  enabled = false,
  messageText = defaultAutoreplyMessageText,
  onTextChange = noop,
  onToggled = noop
}) => {
  const onToggleChange = useCallback(({ target }) => onToggled(target.checked), [onToggled]);
  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center mt-4">
        <h5 className="text-dark">
          Redirect Messaging <i id="redirect-messaging-info" className="fa fa-info-circle ms-2" />
          <UncontrolledTooltip target="redirect-messaging-info">
            Automated response to be sent if a user replies to the invite text message.
          </UncontrolledTooltip>
        </h5>
        <Switch
          name="redirect messaging enabled"
          checked={enabled}
          onChange={onToggleChange}
          className="my-0"
          value={enabled}
        />
      </div>
      <TextEditor
        disabled={!enabled}
        value={messageText}
        onChange={onTextChange}
        placeholders={autoreplyMessagePlaceholders}
      />
    </>
  );
};
