import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import QueryString from "qs";

import { withReportFilterQuery } from "components/HOCs/ReportWithFilters";
import { Card, CardBody, CardHeader, CardTitle } from "components/Card";
import StatusMessages from "components/Misc/StatusMessages";
import { TBody, TD, TH, THead, TR } from "components/Table";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import { defaultNormalizeResponse } from "hooks/data/useFilterQuery";
import { getPrepareFilters, DEFAULT_REQUEST_FIELDS } from "util/reportUtils/videoReportUtils";
import { showSkeleton } from "util/showSkeleton";

export const VideoInvitesBySender = ({
  data: { data, pageInfo },
  isError,
  isLoading,
  isFetched,
  filterValues: { dateRange }
}) => (
  <Card>
    <CardHeader>
      <CardTitle>Video Requests By Sender</CardTitle>
    </CardHeader>
    <CardBody>
      {isError && <StatusMessages className="w-100" errors="Failed loading page" />}
      <Table responsive>
        <THead>
          <TR>
            <TH>Name</TH>
            <TH>Tag</TH>
            <TH>Sent</TH>
            <TH>Review Page CTR</TH>
          </TR>
        </THead>
        {showSkeleton({ isLoading, isFetched }) ? (
          <TableBodySkeleton rows={10} cols={5} />
        ) : (
          <TBody>
            {data?.invitesBySenderDToes.map(({ sender: [name, tag, sent, ctr, reviewPageCTR] }) => (
              <TR key={name + tag}>
                <TD>
                  <Link
                    to={{
                      pathname: "/reviews/feed",
                      search: QueryString.stringify({
                        representative: name,
                        dateRange
                      })
                    }}
                  >
                    {name}
                  </Link>
                </TD>
                <TD>{tag}</TD>
                <TD>
                  {sent}
                  <span className="ms-1 text-muted">({Math.round(ctr * 100)}%)</span>
                </TD>
                <TD>{Math.round(reviewPageCTR * 100)}%</TD>
              </TR>
            ))}
          </TBody>
        )}
      </Table>
    </CardBody>
    <FilterPagination
      isLoading={showSkeleton({ isLoading, isFetched })}
      pageInfo={pageInfo}
      totalPages={pageInfo?.totalPages}
    />
  </Card>
);

export default withReportFilterQuery({
  url: "/inviteVideoReport/bySender",
  normalizeResponse: defaultNormalizeResponse,
  prepareFilters: getPrepareFilters([...DEFAULT_REQUEST_FIELDS, "page", "size"])
})(VideoInvitesBySender);
