import React from "react";
import WWButton from "../../Buttons/WWButton";
import classnames from "classnames";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import styles from "./VideoActions.module.scss";

const { actionsBarClass, download, dropdown, dropdownLinks, remove } = styles;

export default ({ downloadLink, onRemove, formats, disabled }) => (
  <div className={actionsBarClass}>
    {!!downloadLink && !formats && (
      <WWButton
        onClick={e => {
          e.stopPropagation();
        }}
        tag={"a"}
        href={downloadLink}
        iconClass={classnames("fa fa-download", download)}
        transparent
        trackingCategory="videoGallery"
        trackingAction="Download"
      />
    )}
    {!!formats && (
      <UncontrolledButtonDropdown className={dropdown}>
        <DropdownToggle
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          data-button-title="Download Droprown"
        >
          <i className={classnames("fa fa-download", download)} />
        </DropdownToggle>
        <DropdownMenu container="body" className={dropdownLinks}>
          {formats.map(({ format, name, src }) => (
            <a
              download
              href={src}
              onClick={e => {
                e.stopPropagation();
              }}
              key={name}
            >
              <DropdownItem data-button-title={`Download ${format}`}>{name}</DropdownItem>
            </a>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    )}
    <WWButton
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onRemove();
      }}
      disabled={disabled}
      iconClass={classnames("fa fa-trash", remove)}
      transparent
      trackingCategory="videoGallery"
      trackingAction="Remove"
    />
  </div>
);
