import { memo } from "react";
import { Badge } from "reactstrap";
import { useSelector } from "react-redux";
import classNames from "classnames";
import RecentBlogPosts from "./RecentBlogPosts";
import MultiplyWidgetCard, { MultiWidgetCardItem } from "../CommonComponents/MultiWidgetCard";
import WWButton from "components/Buttons/WWButton";

import styles from "./YourProductsWidget.module.scss";

const {
  rowClass,
  captionClass,
  descriptionClass,
  activeIconClass,
  nonActiveIconClass,
  badgeClass,
  buttonWrapperClass,
  linkButtonClass,
  linkIconClass
} = styles;

const getPortalLink = (page = "") => `https://www.widewail.com/${page}`;

const WIDGET_PRODUCTS = [
  {
    caption: "Invite",
    description: "Automated Review Generation Software",
    products: new Set(["REVIEW_INVITE", "REVIEW_LEAD_IMPORT", "INVITE_VIDEO"]),
    link: getPortalLink("invite")
  },
  {
    caption: "Invite Video",
    description: "Testimonial Generation Software",
    products: new Set(["INVITE_VIDEO"]),
    link: getPortalLink("invite-video")
  },
  {
    caption: "Engage",
    description: "Review Response",
    products: new Set(["REVIEW_RESPONSE"]),
    link: getPortalLink("engage")
  },
  {
    caption: "Engage Plus",
    description: "Social Media Engagement",
    products: new Set(["INSTAGRAM", "FACEBOOK_POSTS", "FACEBOOK_ADS", "GOOGLE_QUESTIONS", "LINKEDIN"]),
    link: getPortalLink("engage-plus")
  },
  {
    caption: "Listings Management",
    description: "Maintain Accurate Listings",
    products: new Set(["LISTINGS_MANAGEMENT"]),
    link: getPortalLink("listings"),
    badges: ["NEW"]
  }
];

const YourProductsWidgetRow = ({ caption, description, isActive, link, badges = [] }) => (
  <div className={rowClass}>
    <div>
      <div>
        <span className={captionClass}>{caption}</span>
        {badges.map(badge => (
          <Badge key={badge} className={badgeClass}>
            {badge}
          </Badge>
        ))}
      </div>
      <div className={descriptionClass}>{description}</div>
    </div>
    <div className={classNames({ [buttonWrapperClass]: !!link && isActive === false })}>
      {!!link && isActive === false && (
        <WWButton className={linkButtonClass} color="primary" tag="a" href={link} target="_blank">
          Learn More
          <i className={classNames(linkIconClass, "fa fa-external-link ms-2")} />
        </WWButton>
      )}
      <i
        className={classNames({
          "fa fa-check-circle": isActive,
          "fa fa-times-circle": isActive === false,
          [activeIconClass]: isActive,
          [nonActiveIconClass]: isActive === false
        })}
      />
    </div>
  </div>
);

export const YourProductsWidget = memo(({ activeProducts }) => (
  <MultiplyWidgetCard>
    <MultiWidgetCardItem heading="Your Widewail Products">
      <div className="pe-2">
        {WIDGET_PRODUCTS.map(({ caption, description, link, products, badges }) => (
          <YourProductsWidgetRow
            key={caption}
            caption={caption}
            description={description}
            link={link}
            badges={badges}
            isActive={activeProducts?.some(product => products.has(product))}
          />
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <WWButton color="primary" tag="a" href={getPortalLink("platform")} target="_blank">
          Explore All Products
          <i className={classNames("fa fa-external-link ms-2", linkIconClass)} />
        </WWButton>
      </div>
    </MultiWidgetCardItem>
    {/* Blog posts displaying is not obligatory, this part uses to make widget rows even (see multi widget card styles) */}
    <MultiWidgetCardItem heading="Most Recent Widewail Blog Posts">
      <RecentBlogPosts />
    </MultiWidgetCardItem>
  </MultiplyWidgetCard>
));

const YourProductsWidgetConnected = () => {
  const activeProducts = useSelector(state => state.currentContact?.activeProducts);
  return <YourProductsWidget activeProducts={activeProducts} />;
};

export default YourProductsWidgetConnected;
