import React, { useCallback } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import Avatar from "../User/Avatar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { pickAgency } from "../../redux/actions/agencyActions";
import LogoutDropdownItem from "../Auth/LogoutButton";
import { useStateThroughRedux } from "../../hooks/stateHooks";
import { clearViewState } from "../../redux/actions/viewStateActions";

const HeaderDropdown = ({ ...props }) => {
  const currentContact = useSelector(state => state.currentContact);
  const currentAgency = useSelector(state => state.currentAgency);
  const app = useSelector(state => state.app);
  const dispatch = useDispatch();
  const history = useHistory();
  const [, setSettingsOpened] = useStateThroughRedux("settings/opened", false);
  const [, setSettingsSection] = useStateThroughRedux("settings/section", undefined);
  const onSettingsClick = useCallback(() => {
    setSettingsOpened(true);
    setSettingsSection("userinfo");
  }, []);

  return (
    <UncontrolledButtonDropdown nav>
      <span className="d-none d-md-flex text-dark align-items-center">
        {`Hi, ${get(currentContact, "name", get(currentContact, "email", ""))}${
          currentContact.agencies?.length > 1 ? ` | ${currentAgency.name}` : ""
        }`}
      </span>
      <DropdownToggle nav>
        <Avatar email={get(currentContact, "email", "")} className="m-3" />
        {app.init && !get(currentAgency, "id") && (
          <strong>
            <br />
            Pick an agency before beginning
          </strong>
        )}
      </DropdownToggle>
      <DropdownMenu className="overflow-auto" end>
        <DropdownItem onClick={onSettingsClick}>
          <i className="fa fa-gears"></i> Settings
        </DropdownItem>
        <hr />
        {!isEmpty(currentContact.agencies) && (
          <React.Fragment>
            <DropdownItem header tag="div">
              <strong>Agency</strong>
            </DropdownItem>
            {map(currentContact.agencies, a => (
              <DropdownItem
                key={a.id}
                onClick={() => {
                  dispatch(pickAgency(a));
                  dispatch(clearViewState());
                  history.push("/#/dashboard");
                }}
              >
                {a.icon ? <img alt="" src={a.icon} width="14px" /> : <i className="fa fa-globe" />}
                {currentAgency && currentAgency.id === a.id ? (
                  <>
                    <strong>{a.name}</strong>
                    <i className="fa fa-check d-block" />
                  </>
                ) : (
                  <span>{a.name}</span>
                )}
              </DropdownItem>
            ))}
          </React.Fragment>
        )}
        {/* <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
          <DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>
          <DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>
          <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>
          <DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem>
          <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
          <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>
          <DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>
          <DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>
          <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem> */}
        <hr />
        <LogoutDropdownItem />
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default HeaderDropdown;

// function mapStateToProps(state, ownProps) {
//   return {
//     user: state.cognito.user,
//     agency: state.agency
//   };
// }

// export default connect(mapStateToProps)(HeaderDropdown);
