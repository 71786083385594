import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Form } from "reactstrap";
import { Mention, MentionsInput } from "react-mentions";
import { debounce, get } from "lodash";
import { not } from "ramda";
import * as reviewApi from "api/reviewApi";
import { loadContacts } from "api/customerApi";
import { UPDATE_SIGNATORY_TEMPLATE, WORK_WITH_CUSTOMER_TEMPLATE } from "data/messageActionTemplates";
import FormField from "components/Form/FormField";
import WWButton from "components/Buttons/WWButton";
import Avatar from "components/User/Avatar";
import { errorCaughtNotifier, useLocalNotifications } from "components/Notifications/notification";
import { permissions } from "components/Auth/permissions";
import WWOffcanvas from "components/WWOffCanvas/WWOffcanvas";
import { autoclose } from "components/Modals";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import { isCurrentUserInGroup } from "util/userUtils";
import UsersDropdown from "./UsersDropdown";
import ChangeSignatoryWidget from "./ChangeSignatoryWidget";
import { DEBOUNCE_DELAY_DEFAULT } from "constants/delays";
import { useCurrentAgency } from "hooks/agencyHooks";

import styles from "./SendMessageForm.module.scss";
import mentionsClassNames from "./WWMentionsInput.module.scss";
import { customerHasReviewResponseProduct } from "../../../util/customerUtils";
import { DEFAULT_SERVICER_NAME } from "data/customers";

const { activityClass, actionButtonsClass, buttonClass, contactWidgetClass, mentionClass } = styles;

const mapContacts = contacts =>
  contacts.map(contact => ({
    display: contact.name,
    id: contact.id
  }));

const SendMessageForm = ({ review, onSendMessage, showButtons }) => {
  const notify = useLocalNotifications();

  const [newNote, setNewNote] = useState("");
  const [loadedUsers, setLoadedUsers] = useState(null);
  const [signatoryOffcanvasOpened, setSignatoryOffcanvasOpened] = useState(false);
  const currentAgency = useCurrentAgency();

  const currentContact = useSelector(state => state.currentContact);
  const hasResponseProduct = customerHasReviewResponseProduct(review?.customer);
  const isApproval = review.status === "APPROVAL";
  const layoutResponsiveBreakout =
    hasResponseProduct && isApproval ? "lg" : hasResponseProduct || isApproval ? "md" : "sm";

  const toggleOffcanvasOpened = useCallback(() => setSignatoryOffcanvasOpened(not), [setSignatoryOffcanvasOpened]);

  const onChangeNote = useCallback(e => setNewNote(e.target.value), [setNewNote]);

  const searchContacts = useCallback(
    inputValue =>
      loadContacts({
        projection: "name",
        q: inputValue,
        type: "WIDEWAIL_USER"
      }).then(res => get(res.data, "_embedded.contacts", [])),
    []
  );

  const searchContactsForDropdown = useCallback(
    inputValue => searchContacts(inputValue).then(contacts => setLoadedUsers(mapContacts(contacts))),
    [searchContacts]
  );

  const onCloseDropdown = useCallback(() => setLoadedUsers(null), [
    loadedUsers,
    setLoadedUsers,
    searchContactsForDropdown
  ]);

  const searchContactsForMention = useCallback(
    debounce(
      (inputValue, callback) =>
        searchContacts(inputValue).then(contacts => {
          callback(mapContacts(contacts));
        }),
      DEBOUNCE_DELAY_DEFAULT
    ),
    [searchContacts]
  );

  const insertMention = useCallback(({ display, id }) => setNewNote(`${newNote} @[${display}](${id}) `), [
    setNewNote,
    newNote
  ]);

  const confirm = useConfirmationDialog();

  const ignore = useCallback(
    () =>
      autoclose(
        confirm({
          title: "Are you sure you want to mark this review as spam?"
        })
      ).then(okPressed => {
        if (okPressed) {
          reviewApi
            .saveMessage(review.id, { note: "Ignore this review." }, "IGNORED")
            .then(() => {
              onSendMessage && onSendMessage({ newStatus: "IGNORED" });
            })
            .catch(errorCaughtNotifier(notify));
        }
      }),

    [review]
  );

  const noAction = useCallback(
    () =>
      autoclose(
        confirm({
          title: "Are you sure you want to mark this review as no action needed?"
        })
      ).then(okPressed => {
        if (okPressed) {
          reviewApi
            .saveMessage(review.id, { note: "No action is needed on this review.." }, "NO_ACTION")
            .then(() => {
              onSendMessage && onSendMessage({ newStatus: "NO_ACTION" });
            })
            .catch(errorCaughtNotifier(notify));
        }
      }),

    [review]
  );

  const sendWorkWithMessage = useCallback(
    msg =>
      reviewApi
        .saveMessage(review.id, { note: WORK_WITH_CUSTOMER_TEMPLATE }, review.status)
        .then(() => onSendMessage && onSendMessage({ successMessage: "Note saved" }))
        .catch(errorCaughtNotifier(notify)),
    [review]
  );

  const updateContact = useCallback(
    msg =>
      reviewApi
        .saveMessage(review.id, { note: `${UPDATE_SIGNATORY_TEMPLATE} ${msg}` }, "CUSTOMER_RESPONDED")
        .then(() => onSendMessage && onSendMessage({ newStatus: "CUSTOMER_RESPONDED" }))
        .catch(errorCaughtNotifier(notify)),
    [review]
  );

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      reviewApi
        .saveMessage(review.id, { note: newNote }, hasResponseProduct ? "CUSTOMER_RESPONDED" : review.status)
        .then(() => {
          setNewNote("");
          if (onSendMessage) {
            onSendMessage({ newStatus: hasResponseProduct ? "CUSTOMER_RESPONDED" : review.status });
          }
        })
        .catch(errorCaughtNotifier(notify));
    },
    [review, newNote, hasResponseProduct]
  );

  return (
    <div className={contactWidgetClass}>
      {showButtons && (
        <div
          className={classnames(
            "justify-content-between align-items-center mb-5 flex-wrap",
            `d-${layoutResponsiveBreakout}-flex`
          )}
        >
          <div className={activityClass}>
            <i className="fa fa-list-ul pe-2 me-1" />
            <span>Activity</span>
          </div>

          {isCurrentUserInGroup(permissions.REVIEW_APPROVE) && (
            <div className={actionButtonsClass}>
              <WWButton disabled={review.status === "NO_ACTION"} outline className={buttonClass} onClick={noAction}>
                No action needed
              </WWButton>
              <WWButton disabled={review.status === "IGNORED"} outline className={buttonClass} onClick={ignore}>
                Mark Spam
              </WWButton>
              {isApproval && (
                <WWButton outline className={buttonClass} onClick={sendWorkWithMessage}>
                  I'm working with this customer
                </WWButton>
              )}
              {hasResponseProduct && (
                <WWButton
                  outline
                  disabled={!review?.replyCount}
                  className={buttonClass}
                  onClick={toggleOffcanvasOpened}
                >
                  Change signatory
                </WWButton>
              )}
              <WWOffcanvas isOpen={signatoryOffcanvasOpened} toggle={toggleOffcanvasOpened} direction="end">
                <ChangeSignatoryWidget customerId={review.customer.id} onSignatoryChange={updateContact} />
              </WWOffcanvas>
            </div>
          )}
        </div>
      )}
      <Form onSubmit={onSubmit}>
        <FormField wide>
          <div className="d-flex align-items-center nowrap">
            <Avatar className="me-3 d-sm-down-none" email={get(currentContact, "email", "")} />
            <MentionsInput
              placeholder={
                customerHasReviewResponseProduct(review.customer)
                  ? `Contact ${currentAgency.servicerName || currentAgency.name || DEFAULT_SERVICER_NAME}`
                  : "Leave a note for your team"
              }
              classNames={mentionsClassNames}
              value={newNote}
              onChange={onChangeNote}
              allowSuggestionsAboveCursor
              allowSpaceInQuery
            >
              <Mention
                displayTransform={(id, display) => ` @${display} `}
                trigger="@"
                className={mentionClass}
                data={searchContactsForMention}
                appendSpaceOnAdd
              />
            </MentionsInput>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <UsersDropdown
              className="mr-2"
              users={loadedUsers}
              onSelect={insertMention}
              onSearch={searchContactsForDropdown}
              onClose={onCloseDropdown}
            />
            <WWButton color="secondary" type="submit" iconClass="fa fa-paper-plane" disabled={!newNote?.length}>
              Send
            </WWButton>
          </div>
        </FormField>
      </Form>
    </div>
  );
};

export default SendMessageForm;
