import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap";
import { Card, CardBody, CardTitle } from "../../components/Card";
import CustomerFilter from "../../components/Customer/CustomerFilter";
import React, { useCallback, useEffect, useState } from "react";
import { useCustomersWithProductLoader } from "../../hooks/customerHooks";
import DatePicker from "../../components/DateTime/DatePicker";
import moment from "moment/moment";
import { fetchCsvLinks } from "../../api/importLogApi";
import { errorCaughtNotifier, useErrorNotification } from "../../components/Notifications/notification";
import { CSV_INTEGRATED_SITES } from "../../data/sites";
import {
  TableCard,
  TableCardHeader,
  WWTable,
  WWTBody,
  WWTD,
  WWTH,
  WWTHead,
  WWTR
} from "../../components/Table/WWTable";
import { map } from "lodash";
import TableBodySkeleton from "../../components/Skeleton/TableBodySkeleton";

export const CsvLinks = () => {
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [links, setLinks] = useState();
  const [source, setSource] = useState();
  const notify = useErrorNotification();
  const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

  const updateLocation = location => {
    if (location) {
      setLocation(location.id);
    } else {
      setLocation(undefined);
    }
  };

  const onTimerangeUpdate = useCallback(
    (start, end) => {
      if (start && end) {
        setStartDate(start);
        setEndDate(end);
      }
    },
    [setStartDate, setEndDate]
  );

  useEffect(() => {
    if (startDate && endDate && location && source) {
      setLoading(true);
      fetchCsvLinks(location, source, startDate.format(DEFAULT_DATE_FORMAT), endDate.format(DEFAULT_DATE_FORMAT))
        .then(({ data }) => {
          setLinks(data);
        })
        .catch(errorCaughtNotifier(notify))
        .finally(() => setLoading(false));
    }
  }, [setLinks, startDate, endDate, source, location, notify]);

  const customerFilterLoader = useCustomersWithProductLoader("REVIEW_LEAD_IMPORT");

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <Row className="d-flex align-items-stretch no-pagebreak-inside">
        <Col xs={12} md={6} className="d-flex justify-content-stretch justify-content-md-start no-pagebreak-inside">
          <Card className="justify-content-center align-items-stretch flex-grow-1 me-auto">
            <InputGroup>
              <CustomerFilter
                disabled={loading}
                loader={customerFilterLoader}
                onChange={({ target }) => updateLocation(target.value[0])}
                value={[location]}
              />
            </InputGroup>
          </Card>
        </Col>
        <Col xs={12} md="auto" className="d-flex justify-content-stretch justify-content-md-end no-pagebreak-inside">
          <Card>
            <div className="justify-content-center flex-wrap align-items-center flex-grow-1 ms-auto flex-row d-flex">
              <DatePicker
                disabled={loading}
                start={startDate}
                end={endDate}
                onChange={onTimerangeUpdate}
                numberOfMonths={2}
              ></DatePicker>
            </div>
          </Card>
        </Col>
        <Col xs={12} md="auto" className="d-flex flex-row align-items-stretch justify-content-between">
          <Card className="me-auto flex-grow-1 justify-content-center no-pagebreak-inside">
            <UncontrolledDropdown className="d-inline-flex" disabled={loading}>
              <DropdownToggle className="d-flex text-nowrap flex-nowrap">
                {!source ? "Select a source" : source}
              </DropdownToggle>
              <DropdownMenu>
                {CSV_INTEGRATED_SITES.map(s => (
                  <DropdownItem
                    key={s.source}
                    onClick={() => {
                      setSource(s.source);
                    }}
                  >
                    {s.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableCard>
            <TableCardHeader className="flex-wrap justify-content-between">
              <CardTitle>Links</CardTitle>
              {source && source === "DEALERVAULT" && <span>Dealervault is limited to 14-day windows.</span>}
            </TableCardHeader>
            <CardBody>
              <WWTable>
                <WWTHead>
                  <WWTR>
                    <WWTH>
                      Link <i id="saving-info" className="fa fa-info-circle ms-2" />
                      <UncontrolledTooltip target="saving-info">Right-click, "Save Link As..."</UncontrolledTooltip>
                    </WWTH>
                    <WWTH>Date</WWTH>
                    <WWTH>Type</WWTH>
                  </WWTR>
                </WWTHead>
                {loading && <TableBodySkeleton cols={5} rows={3} />}
                {!loading && links && (
                  <WWTBody>
                    {map(links, ({ url, type, key, date }) => (
                      <WWTR key={url}>
                        <WWTD>
                          <a href={url} download>
                            {key}
                          </a>
                        </WWTD>
                        <WWTD>
                          <span>{date}</span>
                        </WWTD>
                        <WWTD>
                          <span>{type}</span>
                        </WWTD>
                      </WWTR>
                    ))}
                  </WWTBody>
                )}
              </WWTable>
            </CardBody>
          </TableCard>
        </Col>
      </Row>
    </div>
  );
};
