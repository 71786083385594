import React from "react";
import truncate from "lodash/truncate";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import withConfirmation from "../../components/Form/withConfirmation";
import { TR, TD } from "../../components/Table";
import moment from "moment";

const PostTableRow = ({ post, editable = false, onDelete = null }) => {
  const ButtonWConfirm = withConfirmation(Button);

  return (
    <TR>
      <TD>{moment(post.lastModifiedOn).format("L")}</TD>
      <TD>{post.lastModifiedBy}</TD>
      <TD>
        <Link to={`/posts/edit/${post.id}`}>{truncate(post.content, { length: 160 })}</Link>
      </TD>
      <TD>{post.status}</TD>
      {editable && (
        <TD>
          <div className="d-flex flex-nowrap">
            <Link className="btn btn-primary" to={`/posts/edit/${post.id}`}>
              <i className="fa fa-pencil"></i>
            </Link>
            {post.status !== "REMOVED" && (
              <ButtonWConfirm className="btn-danger ms-2" label="Delete Post" onClick={() => onDelete(post)}>
                <i className="fa fa-trash"></i>
              </ButtonWConfirm>
            )}
          </div>
        </TD>
      )}
    </TR>
  );
};

export default PostTableRow;
