import { find, pick, pipe, map, values, propEq, prop } from "ramda";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import HorizontalSelectField from "../Form/HorizontalSelectField";
import { autoclose } from "../Modals";
import { useConfirmationDialog } from "../Modals/confirmation.modal";
import { useMigrateCustomerToAgency } from "hooks/agencyHooks";

export const AgencySelector = ({ customerId, currentAgency }) => {
  const currentContact = useSelector(prop("currentContact"));
  const [agency, setAgency] = useState(currentAgency);
  const options = useMemo(() => pipe(prop("agencies"), map(pipe(pick(["name"]), values)))(currentContact), [
    currentContact
  ]);
  const history = useHistory();
  const confirm = useConfirmationDialog();

  const migrateToAgency = useMigrateCustomerToAgency(customerId);

  const setAgencyHandler = useCallback(
    event => {
      let selectedAgency = find(propEq(event.target.value[0], "name"))(currentContact.agencies);
      setAgency(selectedAgency);
      migrateToAgency(selectedAgency.id).then(() => history.push("/customers"));
    },
    [currentContact, customerId]
  );

  return (
    <HorizontalSelectField
      name="agency"
      value={[agency.name]}
      simpleValue={true}
      options={options}
      onChange={event =>
        autoclose(
          confirm({
            title: "Are you sure you want to change this customer's agency?",
            body: "this will transfer all attached contacts to the new agency and send you back to your last page"
          })
        ).then(resolution => resolution && setAgencyHandler(event))
      }
      inline={false}
    />
  );
};

export default AgencySelector;
