import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { urlPreview } from "../../api/previewApi";
import WWButton from "../Buttons/WWButton";

function FacebookLinkPreview({ url, _preview, onRemove }) {
  const [preview, setPreview] = useState({});

  useEffect(() => {
    if (url) {
      urlPreview(url)
        .then(({ data }) => data)
        .then(preview => setPreview(preview))
        .catch(err => setPreview({ title: "Failed generating URL preview" }));
    }
  }, [url]);

  useEffect(() => {
    //for mocking data
    if (_preview) {
      setPreview(_preview);
    }
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {onRemove && <WWButton iconClass="close" className="float-end" tooltip="Remove Link" onClick={onRemove} />}
      {preview.img && (
        <div
          style={{
            width: "100%",
            minHeight: "262px",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${preview.img})`
          }}
        />
      )}
      <div style={{ backgroundColor: "#f2f3f5" }} className="p-2">
        <p style={{ color: "#606770", fontSize: "12px" }} className="my-1 text-uppercase text-truncate">
          {preview.url}
        </p>
        <p style={{ color: "#1d2129", fontSize: "16px", fontWeight: "600" }} className="my-1">
          {preview.title}
        </p>
        <p style={{ color: "#606770", fontSize: "12px" }} className="my-1 text-truncate">
          {preview.description}
        </p>
      </div>
    </div>
  );
}

FacebookLinkPreview.propTypes = {
  url: PropTypes.string.isRequired
};

export default FacebookLinkPreview;
