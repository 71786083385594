import React from "react";
import { useHistory } from "react-router-dom";
import WWButton from "../Buttons/WWButton";

export default function LinkButton({ to, children, color = "link", ...props }) {
  const history = useHistory();

  return (
    <WWButton onClick={() => history.push(to)} color={color} {...props}>
      {children}
    </WWButton>
  );
}
