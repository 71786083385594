import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// Styles
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
import { createBrowserHistory } from "history";
import React, { Suspense } from "react";
// typehead stylings
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { createRoot } from "react-dom/client";
import { CompatRouter } from "react-router-dom-v5-compat";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
import "simplebar-react/dist/simplebar.min.css";
import AjaxLoader from "./components/Misc/AjaxLoader";
import ScrollToTop from "./components/Misc/ScrollToTop";
import createDataLayer from "./containers/DataLayer";
import Full from "./containers/Full";
import Modals from "./containers/Modals";
import Notifications from "./containers/Notifications";
import ReduxLayer from "./containers/ReduxLayer";
// Temp fix for reactstrap
import "./scss/core/_dropdown-menu-right.scss";
// Import Main styles for this application
import "./scss/style.scss";
import "./util/logger";
import logger from "./util/logger";
//import Browser info
import platform from "platform";
// Views
import Page404 from "./views/Pages/Page404/";
import Page500 from "./views/Pages/Page500/";
import BrowserWarningBanner from "./components/Banners/BrowserWarningBanner/BrowserWarningBanner";
import TagManager from "react-gtm-module";
import { SELECT_PORTAL_ID } from "components/Form/SelectField";

// const Full = lazy(() => import("./containers/Full"));
// const Modals = lazy(() => import("./containers/Modals"));
// const Notifications = lazy(() => import("./containers/Notifications"));

const history = createBrowserHistory();
const location = history.location;

// ontime conversion from hashrouter to browserrouter
// for backwards compatibility support
// ...

// this conditions means that we have hash right after origin
// so that means it is time to replase it with actual route
// however we still can use hash for different purposes
// if it is not at the beginning of pathname

if (!!location.hash && location.pathname === "/") history.replace(location.hash.slice(1));

// ...
// end of conversion
// TagManager ...

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_ENV
};

TagManager.initialize(tagManagerArgs);
// ... TagManager

const DataLayer = createDataLayer(false);
const root = createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundary FallbackComponent={({ error }) => <Page500 error={error?.message} />} onError={e => logger.warn(e)}>
    <ReduxLayer>
      <div id={SELECT_PORTAL_ID} className="position-relative" style={{ zIndex: 1060 }} />
      <Notifications>
        <DataLayer>
          <Modals>
            <BrowserWarningBanner browser={platform.name} />
            <BrowserRouter>
              <ScrollToTop>
                <Suspense fallback={AjaxLoader}>
                  <CompatRouter>
                    <Switch>
                      <Route exact path="/404" name="Page 404" component={Page404} />
                      <Route exact path="/500" name="Page 500" component={Page500} />
                      {/*<Route exact path="/share" name="Share" component={PublicSharePage} />*/}
                      <Route path="/" name="Home" component={Full} />
                    </Switch>
                  </CompatRouter>
                </Suspense>
              </ScrollToTop>
            </BrowserRouter>
          </Modals>
        </DataLayer>
      </Notifications>
    </ReduxLayer>
  </ErrorBoundary>
);
