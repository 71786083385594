import { find, get } from "lodash";
import { PromiseStatuses } from "data/promiseStatuses";
import { engageCustomerProductsKeys } from "../data/products";

/**
 * Returns the customer name as it appears on the source site.
 *
 * @param {object} customer
 * @param {string} source Source to get the name for
 */
export function thirdpartyCompanyName(customer, source, siteInfoId) {
  const companyName = get(customer, "companyName", "");

  if (source && siteInfoId && customer) {
    const filterFunc = ({ id }) => id === siteInfoId;
    switch (source) {
      case "FACEBOOK":
        return get(find(customer.facebookInfo, filterFunc), "pageName", companyName);
      case "GOOGLE":
        return get(find(customer.gmbInfo, filterFunc), "businessName", companyName);
      default:
    }
  }

  return companyName;
}

/**
 * Returns the preferred customer name as it should appear in replies
 *
 * @param {object} customer
 * @param {string} source Source to get the name for
 */
export function preferredCompanyName(customer, source, siteInfoId) {
  const companyName = get(customer, "companyName", "");
  let info;

  // Yelp doesn't use site info IDs.
  if (source == "YELP" && customer && customer.yelpInfo && customer.yelpInfo.length == 1) {
    return customer.yelpInfo[0].companyNameOverride || companyName;
  }

  if (source && siteInfoId && customer) {
    const filterFunc = ({ id }) => id === siteInfoId;
    switch (source) {
      case "FACEBOOK":
        info = find(customer.facebookInfo, filterFunc);
        return info?.companyNameOverride || info?.pageName || companyName;
      case "GOOGLE":
        info = find(customer.gmbInfo, filterFunc);
        return info?.companyNameOverride || info?.businessName || companyName;
    }
  }

  return companyName;
}

export function siteAddress(customer, source, siteInfoId) {
  const address = get(customer, "address", "");

  if (source && siteInfoId && customer) {
    const filterFunc = ({ id }) => id === siteInfoId;
    switch (source) {
      case "GOOGLE":
        return get(find(customer.gmbInfo, filterFunc), "address", address);
      default:
    }
  }

  return address;
}

export function isProductActiveForCustomer(customer, productName, defaultValue = false) {
  return get(find(customer.products, { product: productName }), "active", defaultValue);
}

export const getLinkingErrorMessageObject = linkingResult => ({
  message:
    typeof linkingResult?.response?.data === "string"
      ? linkingResult?.response?.data
      : linkingResult?.response?.data?.errors ||
        linkingResult?.error?.details?.[0]?.fieldViolations?.[0]?.description ||
        linkingResult?.error?.message ||
        linkingResult?.message ||
        "An error has occurred."
});

export const customerFilterApiConverter = location => {
  switch (location?.type) {
    case "CUSTOMER":
      return { customerId: location.id };
    case "CONTACT":
      return { contactId: location.id };
    case "BRAND":
      return { brand: location.brand };
    default:
      return [];
  }
};

export const productsIncludeEngageProduct = products =>
  (products || []).some(r => engageCustomerProductsKeys.includes(r));

export const customerHasReviewResponseProduct = customer => productsIncludeEngageProduct(customer?.products);

export const decodeSignatureLine = customer => {
  if (!customer) {
    return "";
  }
  let decoded = customer.signatureLine || "";
  const names = customer.name ? customer.name.split(" ") : [];
  const extendedCustomer = { ...customer, firstName: names[0], lastName: names.length > 1 ? names.pop() : "" };
  const TAGS = Object.freeze({
    "[PHONE]": "phone",
    "[EMAIL]": "email",
    "[FIRST NAME]": "firstName",
    "[LAST NAME]": "lastName",
    "[TITLE]": "title",
    "[FULL NAME]": "name"
  });
  Object.keys(TAGS).forEach(tag => (decoded = decoded.replaceAll(tag, extendedCustomer[TAGS[tag]])));
  return decoded;
};
