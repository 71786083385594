import { property } from "lodash";
import * as client from "./apiClient";

/**
 *
 * @param {string} customerId
 * @param {string} token
 */
export const createKeapInfo = (customerId, code, scope) =>
  client.post(`customers/${customerId}/keapInfo`, null, { params: { code, scope } }).then(property("data"));

/**
 *
 * @param {*} keapInfoId
 */
export const deleteKeapInfo = (customerId, keapInfoId) =>
  client.doDelete(`customers/${customerId}/keapInfo/${keapInfoId}`).then(property("data"));
