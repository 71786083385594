import React, { useState, useEffect } from "react";
import PasswordInput from "components/Auth/PasswordInput";
import FormField from "components/Form/FormField";
import InputField from "components/Form/InputField";
import { validEmail } from "../../../util/validators";

const SharedPropertywareCredentials = ({ credentials, onChange, onValidChange }) => {
  const [name, setName] = useState();
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [organizationId, setOrganizationId] = useState("");

  useEffect(() => {
    setName(credentials?.name);
    setClientId(credentials?.clientId);
    setClientSecret("");
    setOrganizationId(credentials?.organizationId);
  }, [credentials]);
  useEffect(() => {
    onValidChange(
      name && clientId && (credentials?.clientSecret || clientSecret) && (credentials?.organizationId || organizationId)
    );
    onChange({ name, clientId, clientSecret: clientSecret || null, organizationId });
  }, [name, clientId, credentials, clientSecret, organizationId, onChange, onValidChange]);

  return (
    <>
      <InputField
        label="Name"
        value={name || ""}
        valid={!!name}
        onChange={event => setName(event.target.value)}
        inline={false}
      />
      <InputField
        label="Client ID"
        value={clientId}
        valid={!!clientId}
        onChange={event => setClientId(event.target.value)}
        inline={false}
      />
      <FormField tip={credentials?.credentials ? "Leave this empty to use the existing Secret" : ""}>
        <PasswordInput
          label="Private Client Secret"
          value={clientSecret}
          onChange={setClientSecret}
          lastpass={false}
          inline={false}
        />
      </FormField>
      <InputField
        label="Organization ID"
        value={organizationId}
        valid={organizationId}
        onChange={event => setOrganizationId(event.target.value)}
        inline={false}
      />
    </>
  );
};

export default SharedPropertywareCredentials;
