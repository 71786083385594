// uncomment this line if you have something to import from your scss module
// import {} from "./OrderList.module.scss"
import React from "react";
import Time from "../../../DateTime/Time";
import { Table, TBody, TD, TH, THead, TR } from "../../../Table";
import map from "lodash/map";

export default function OrderList({ orders }) {
  return (
    <Table>
      <THead>
        <TR>
          <TH>Created On</TH>
          <TH>Number</TH>
          <TH>Status</TH>
          <TH></TH>
        </TR>
      </THead>
      <TBody>
        {map(orders, o => (
          <React.Fragment key={o.id}>
            <TR>
              <TD>
                <Time format="date" date={o.createdOn} withIcon={false} />
              </TD>
              <TD>{o.phoneNumber || o.desiredAreaCode}</TD>
              <TD>{o.status}</TD>
              <TD colSpan={2}>{o.statusReason}</TD>
            </TR>
          </React.Fragment>
        ))}
      </TBody>
    </Table>
  );
}
