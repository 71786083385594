import React, { Component } from "react";
import PropTypes from "prop-types";
import * as customerApi from "../../api/customerApi";
import ContactCard from "./ContactCard";

class ContactsWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: props.contacts
    };

    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    if (!this.state.contacts) this.refresh();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.contacts && !prevProps.contacts) || this.props.contacts.length !== prevProps.contacts.length) {
      this.setState({ contacts: this.props.contacts });
    } else if (prevProps.customerId !== this.props.customerId || prevProps.tag !== this.props.tag) {
      this.refresh();
    }
  }

  refresh() {
    customerApi.loadCustomer(this.props.customerId).then(customer => {
      let contacts = customer.contacts;
      let filtered = contacts.filter(c => c.tags && c.tags.indexOf(this.props.tag) >= 0);
      this.setState({ contacts: filtered.length === 0 ? contacts : filtered });
    });
  }

  render() {
    let { onClick } = this.props;
    let { contacts } = this.state;
    return (
      <div className="d-flex flex-row flex-wrap justify-content-center align-items-stretch m-2">
        {contacts &&
          contacts.map((c, index) => (
            <div className="m-2" key={index}>
              <ContactCard onClick={onClick} contact={c} disable={this.props.disable || []}></ContactCard>
            </div>
          ))}
      </div>
    );
  }
}

ContactsWidget.propTypes = {
  customerId: PropTypes.string,
  contacts: PropTypes.array,
  tag: PropTypes.string,
  onClick: PropTypes.func
};

export default ContactsWidget;
