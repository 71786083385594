/// TODO: Revork styles to don't use ids in CSS
import "./AddressInput.scss";
import { FormFeedback, Input, InputGroup } from "reactstrap";
import InputField from "../InputField";
import HorizontalSelectField from "../HorizontalSelectField";
import {
  canadianProvinceAbbreviations,
  countries,
  countryOptionsValues,
  americanStateAbbreviations
} from "../../../data/locations";
import { useCallback, useEffect, useState } from "react";
import { get, noop } from "lodash";

/**
 * @param {Object} fullAddress -{ countryCode, streetNumber, streetName, address2, locality, administrativeArea, postalCode }
 * @param {string} [address]
 * @param {any} onChange
 * @param {boolean} [isAllowedToEdit]
 */

export default function AddressInput({
  fullAddress = {},
  legacyAddress = "",
  onChange = noop,
  isAllowedToEdit = true,
  objectPrefix = undefined
}) {
  const { countryCode, streetNumber, streetName, address2, locality, administrativeArea, postalCode } = fullAddress;
  const [displaySingleLineAddress, setDisplaySingleLineAddress] = useState(false);

  useEffect(() => {
    setDisplaySingleLineAddress((!countryCode || !locality || !administrativeArea || !postalCode) && !!legacyAddress);
  }, [countryCode, locality, administrativeArea, postalCode, legacyAddress]);

  const internalOnChange = useCallback(
    event => {
      const field = objectPrefix ? `${objectPrefix}.${event.target.name}` : event.target.name;
      onChange({ target: { name: field, value: event.target.value } });
    },
    [onChange, objectPrefix]
  );

  return (
    <div className="address">
      <InputGroup>
        <InputField
          name="streetNumber"
          value={streetNumber}
          label="Street #"
          onChange={internalOnChange}
          disabled={!isAllowedToEdit}
          inline={false}
        />
        <InputField
          name="streetName"
          className="flex-grow-1"
          value={streetName}
          label="Street Name"
          onChange={internalOnChange}
          disabled={!isAllowedToEdit}
          inline={false}
        />
      </InputGroup>
      <InputField
        name="address2"
        value={address2}
        label="Address Line 2"
        onChange={internalOnChange}
        disabled={!isAllowedToEdit}
        inline={false}
      />
      <HorizontalSelectField
        name="countryCode"
        value={{
          label: get(countries, countryCode),
          value: countryCode
        }}
        label="Country"
        isMulti={false}
        creatable={false}
        onChange={evt => internalOnChange({ target: { name: evt.target.name, value: evt.target.value.value } })}
        inline={false}
        options={countryOptionsValues}
        isDisabled={!isAllowedToEdit}
      />
      <InputGroup>
        <InputField
          name="locality"
          className="flex-grow-1"
          value={locality}
          label="City"
          onChange={internalOnChange}
          disabled={!isAllowedToEdit}
          inline={false}
        />
        <HorizontalSelectField
          name="administrativeArea"
          value={administrativeArea}
          label="State/Province"
          isMulti={false}
          creatable={false}
          simpleValue={true}
          onChange={internalOnChange}
          inline={false}
          options={countryCode === "CA" ? canadianProvinceAbbreviations : americanStateAbbreviations}
          isDisabled={!isAllowedToEdit || !countryCode}
        />
        <InputField
          name="postalCode"
          value={postalCode}
          label="Postal Code"
          onChange={internalOnChange}
          disabled={!isAllowedToEdit}
          inline={false}
        />
      </InputGroup>
      {/* old single line address for legacy customers */}
      {displaySingleLineAddress && (
        <>
          <Input type="hidden" invalid={true} />
          <FormFeedback>Please move Address information to individual fields</FormFeedback>
          <InputField
            name="address"
            value={legacyAddress}
            label="Address"
            onChange={internalOnChange}
            disabled={true}
            inline={false}
          />
        </>
      )}
    </div>
  );
}
