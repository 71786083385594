import TypeaheadFormField from "components/Form/Typeahead/TypeaheadField";
import { useAccountSearch } from "hooks/data/accountsHooks";

export const AccountTypeaheadField = props => {
    const useSearch = q => {
      let { data, isLoading, ...query } = useAccountSearch({ q });
  
      return {
        ...query,
        isLoading,
        options: (data?.[0] || []).filter(Boolean).map(({ id, name }) => ({ id, name })),
        labelKey: ({ name }) => name
      };
    };
  
    return (
      <TypeaheadFormField
        {...props}
        useSearch={useSearch}
        placeholder="Search Accounts..."
        clearButton={false}
        dropdownToggle
      />
    );
  };
