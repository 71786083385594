import get from "lodash/get";
import React, { useMemo } from "react";
import Skeleton from "components/Skeleton";
import isUndefined from "lodash/isUndefined";

/**
 * @param {{
 *   renderer?: (key: "id" | "total" | "pctChange") => (entry: {id: string; total: number; pctChange: number}) => JSX.Element;
 *   id: string;
 *   total: any;
 *   pctChange: any;
 * }} props
 */
export const Slab = ({ renderer = defaultRenderer(), ...data }) => {
  const renderId = useMemo(() => renderer("id"), [renderer]);
  const renderTotal = useMemo(() => renderer("total"), [renderer]);
  const renderPCTChange = useMemo(() => renderer("pctChange"), [renderer]);
  return (
    <div className={`d-flex flex-column align-items-start flex-grow-1`}>
      {renderId(data)}
      {renderTotal(data)}
      {renderPCTChange(data)}
    </div>
  );
};

/**
 * @type {(mapper?: (v) => JSX.Element) => (key: "id" | "total" | "pctChange") => (entry: {id: string; total: number; pctChange: number}) => JSX.Element}
 */
export const defaultRenderer = (mapper = get) => key => {
  return key === "id"
    ? entry => <span className="text-dark text-nowrap">{mapper(entry, key)}</span>
    : key === "total"
    ? entry => <h3>{entry.total === null || isUndefined(entry.total) ? "N/A" : mapper(entry, key)}</h3>
    : entry => {
        const value = mapper(entry, key);
        return entry.pctChange !== null && entry.pctChange > 0 ? (
          (<span className="text-success">{value}</span>)
        ) : entry.pctChange !== null && entry.pctChange <= 0 ? (
          (<span className="text-danger">{value}</span>)
        ) : (
          (<span className="text-dark">{"N/A"}</span>)
        );
      };
};

export const SlabSkeleton = () => (
  <div className={`d-flex flex-column align-items-stretch flex-grow-1`}>
    <Skeleton classNam="" />
    <Skeleton className="h2" />
    <Skeleton count={1} />
  </div>
);
