import { isBoolean, isNull, isNumber, isObject, isString, isUndefined } from "lodash";
import isFunction from "lodash/isFunction";
import moment, { isMoment } from "moment";
import { adjust, either, fromPairs, head, ifElse, isEmpty, isNil, keys, last, map, pipe, reject, toPairs } from "ramda";
import { isISOString } from "./dateUtils";

/**
 * Returns either fn or the result of fn() depending on if it's a function
 * @param {*} fn
 */
export function valueOf(fn) {
  if (isFunction(fn)) {
    return fn();
  }
  return fn;
}
export const pickHALData = ({ page, _embedded }) => ({
  page,
  data: _embedded?.[head(keys(_embedded))] || []
});
export const cleanUpRequestParams = pipe(toPairs, reject(pipe(last, isNil)), fromPairs);

export function encode(value) {
  if (isMoment(value)) {
    return value
      .clone()
      .utc()
      .toISOString();
  }
  if (
    isNull(value) ||
    isString(value) ||
    isBoolean(value) ||
    isNumber(value) ||
    isUndefined(value) ||
    isObject(value)
  ) {
    return value;
  }
  return JSON.stringify(value, (_, v) => {
    if (isMoment(v)) {
      return v
        .clone()
        .utc()
        .toISOString();
    }
    return v;
  });
}
//json parse is resulting in some unintended results when "false" "{}" ect is typed into a free text but still needed for local storage decoding
export function decode(v, JSONparse = false) {
  if (isISOString(v) && isNaN(v)) {
    return moment(v);
  }
  try {
    if (JSONparse) {
      return JSON.parse(v, (_, value) => (isISOString(value) ? moment(value) : value));
    } else {
      return v;
    }
  } catch (e) {
    return v;
  }
}
export const encodeForURLQuery = pipe(
  toPairs,
  reject(pipe(last, either(isNil, isEmpty))),
  map(
    adjust(1, pipe(ifElse(Array.isArray, map(encode), ifElse(isMoment, encode, ifElse(isObject, map(encode), encode)))))
  ),
  fromPairs
);
