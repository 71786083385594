import capitalize from "lodash/capitalize";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isFunction from "lodash/isFunction";
import isObject from "lodash/isObject";
import words from "lodash/words";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row, Table } from "reactstrap";
import Time from "../../components/DateTime/Time";
import Paragraph from "../../components/Layout/Paragraph";
import ParagraphDiff from "../../components/Layout/ParagraphDiff";
import { beginAjaxCall, endAjaxCall } from "../../redux/actions/ajaxStatusActions";
import AjaxLoader from "../Misc/AjaxLoader";
import "./Audit.scss";

const MAX_PARAGRAPH_CHARS = 300;

const types = Object.freeze({
  ADD: "Created",
  MOD: "Modified",
  DEL: "Deleted"
});

const isRelevant = property =>
  property !== "lastModifiedOn" && property !== "lastModifiedBy" && property !== "translatedContent";

// It only makes sense to show an entry if we're going to show at least one of its properties.
// We also only want to show entries that changed the review's "last modified on" timestamp;
// other entries are from replies, review entities, or similar nested objects.
const shouldShow = entry => entry.properties?.some(isRelevant) && entry.properties.some(p => p === "lastModifiedOn");

export default function AuditLog({ log, propertyRenderers = {} }) {
  const dispatch = useDispatch();
  const [_log, setLog] = useState(isFunction(log) ? [] : log);

  if (isFunction(log)) {
    dispatch(beginAjaxCall());
    log()
      .then(revs => setLog(revs))
      .finally(() => dispatch(endAjaxCall()));
  }

  if (!_log) return <AjaxLoader />;

  return (
    <Table>
      <thead>
        <tr>
          <th className="timestamp">Modified Time</th>
          <th>
            <Container>
              <Row>
                <Col sm="3">Field</Col>
                <Col>Old Value</Col>
                <Col>New Value</Col>
              </Row>
            </Container>
          </th>
        </tr>
      </thead>
      <tbody>
        {_log
          .map((entry, i) =>
            // We can't do this in a filter() above because it throws off the index
            shouldShow(entry) ? (
              <LogRow
                key={entry.date}
                entry={entry}
                // The log is sorted in descending order, so the old value is one ahead in the array
                prev={i < _log.length && _log[i + 1]}
                renderers={propertyRenderers}
              />
            ) : null
          )
          .filter(element => element !== null)}
      </tbody>
    </Table>
  );
}

function content(prop, value, prev) {
  if (prop.endsWith("Date") && value) {
    return <Time format="datetime" withIcon={false} date={value} />;
  }

  const toValue = val => {
    if (isArray(val)) {
      return val.map(v => toValue(v)).join(", ");
    } else if (isObject(val)) {
      return Object.keys(val)
        .filter(k => k !== "id")
        .map(k => `${capitalize(k)}: ${val[k]}`)
        .join("\n");
    } else {
      return val;
    }
  };
  if (prev && prop === "content") {
    return (
      <ParagraphDiff
        className="difference"
        content={toValue(value)}
        oldContent={toValue(prev)}
        maxChars={MAX_PARAGRAPH_CHARS}
        type="words"
      />
    );
  } else {
    return <Paragraph content={toValue(value)} maxChars={MAX_PARAGRAPH_CHARS} />;
  }
}

const LogRow = ({ entry, prev, renderers }) => (
  <tr className="entry">
    <td className="timestamp text-center">
      {entry.date && (
        <>
          <Time format="date" date={entry.date} />
          <br />
          <Time format="time" date={entry.date} withIcon={false} />
        </>
      )}
    </td>
    <td>
      <span className="lastModifiedBy">{entry.entity.lastModifiedBy}</span>
      {" - "}
      <small className="font-italic text-small">{types[entry.type]}</small>
      <Container>
        {entry.properties.filter(isRelevant).map(property => (
          <Row key={property}>
            <Col xs={12} sm="3" className="fw-bold">
              {words(property)
                .map(capitalize)
                .join(" ")}
            </Col>
            {prev && (
              <Col>
                {isFunction(renderers[property])
                  ? renderers[property](get(prev, ["entity", property]))
                  : content(property, get(prev, ["entity", property]))}
              </Col>
            )}
            <Col>
              {isFunction(renderers[property])
                ? renderers[property](get(entry, ["entity", property]))
                : content(property, get(entry, ["entity", property]), get(prev, ["entity", property]))}
            </Col>
          </Row>
        ))}
      </Container>
    </td>
  </tr>
);
