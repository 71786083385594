import React, { useCallback, useState } from "react";
import InputField from "../Form/InputField";
import { Form, FormFeedback, Input } from "reactstrap";
import CodeValidationNewPassword from "./CodeValidationNewPassword";
import AjaxLoader, { AjaxLoaderSizes } from "../Misc/AjaxLoader";
import WWButton from "../Buttons/WWButton";

import * as customerApi from "../../api/customerApi";

import styles from "./LoginContainer.module.scss";

const ForgotPassword = ({ reset }) => {
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationPage, setValidationPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const forgotPassword = useCallback(
    event => {
      event.preventDefault();
      setLoading(true);
      customerApi
        .resetPasswordByEmail(email)
        .then(() => {
          setValidationPage(true);
          setErrorMessage("");
        })
        .catch(error => {
          setValid(false);
          if (error.response.status === 422) {
            // Added a check if the email provided has opted out of comms with us. If so we want to tell the user that
            setErrorMessage(error.response.data);
          } else {
            setErrorMessage(error.message);
          }
          setLoading(false);
        });
    },
    [email]
  );

  const onEmailChange = useCallback(v => setEmail(v.target.value), [setEmail]);

  return (
    <div>
      {validationPage ? (
        <CodeValidationNewPassword resetEmail={email} />
      ) : (
        <div>
          <h5 className="text-dark">Enter your email to reset your password</h5>
          <Form className="form-horizontal mb-0">
            <InputField
              name="email"
              label="Email"
              inline={false}
              value={email}
              onChange={onEmailChange}
              debounceDelay={0}
            />
            <Input type="hidden" invalid={!valid} />
            <FormFeedback>{errorMessage}</FormFeedback>
            <WWButton trackingCategory="Auth" className={styles.linkBtn} color="link" size="sm" onClick={reset}>
              Back to Login
            </WWButton>
            <div className="mt-4">
              <WWButton
                trackingCategory="Auth"
                disabled={loading}
                type="submit"
                className="btn btn-warning col-12 py-3 fw-bold"
                onClick={forgotPassword}
              >
                {loading ? <AjaxLoader size={AjaxLoaderSizes.SM} /> : "Send Validation Code"}
              </WWButton>
            </div>
          </Form>
        </div>
      )}
      <div className="text-center mt-3">
        Questions? Contact our support team at{" "}
        <a href="mailto:support@widewail.com?subject=Password%20Support" target="_blank" rel="noopener noreferrer">
          support@widewail.com
        </a>
        .
      </div>
    </div>
  );
};

export default ForgotPassword;
