import React from "react";
import { defaultRenderer } from "./Slab";
import { TrendingChartTooltip } from "./Tooltips/TrendingChartTooltip";
import TrendingChartWithLegend from "./TrendingChartWithLegend";
import capitalize from "lodash/capitalize";
import { siteName, SOURCES } from "../../data/sites";

const VolumeChart = ({ data, renderer = defaultRenderer, children = defaultVolumeChartLegendRenderer }) => (
  <TrendingChartWithLegend
    legendRenderer={renderer(children)}
    dataSource={data}
    chartProps={{
      yAxis: {
        axisLine: false,
        domain: [v => v * 0.5, "dataMax"]
      },
      tooltip: {
        content: defaultVolumeChartTooltipFormatter
      }
    }}
  />
);

export const defaultVolumeChartTooltipFormatter = ({ active, payload }) =>
  active && payload && payload.length > 0 ? (
    <TrendingChartTooltip data={payload[0].payload}>Volume</TrendingChartTooltip>
  ) : null;

export const defaultVolumeChartLegendRenderer = (entry, key) => {
  const value = entry[key];
  return value === undefined
    ? ""
    : key === "id"
    ? value === "TOTAL"
      ? "Total"
      : SOURCES[value]
      ? siteName(value)
      : capitalize(value)
    : key === "total"
    ? value.toLocaleString()
    : `${Math.round(value * 100)}%`;
};

export default VolumeChart;
