import React, { useState, useEffect, useRef } from "react";
import { SITES } from "data/sites";
import LogoCardButton from "./LogoCardButton/LogoCardButton";

import styles from "./SelectSiteIntegrationStep.module.scss";
import { FormGroup, Input } from "reactstrap";
import { includes } from "lodash";

const { popularIntegrationsContainerClass } = styles;

const POPULAR_INTEGRATIONS = [
  { source: "GOOGLE" },
  { source: "FACEBOOK" },
  { source: "YELP" },
  { source: "CAR_GURUS" },
  { source: "DEALERRATER" },
  { source: "EDMUNDS" }
];

const LINKABLE_SITES = SITES.filter(
  site =>
    site.userLinking &&
    site.source !== "CARS_DOT_COM" &&
    !includes(
      POPULAR_INTEGRATIONS.map(integration => integration.source),
      site.source
    )
);

const SelectSiteIntegrationStep = ({ setStepData, preselectedSource = "" }) => {
  const [integrationSource, setIntegrationSource] = useState(preselectedSource);
  useEffect(() => {
    setStepData(integrationSource || null);
  }, [integrationSource]);

  const toggleSelected = newSource => setIntegrationSource(newSource === integrationSource ? "" : newSource);

  return (
    <>
      <h4>Popular Site Integrations</h4>
      <div className={popularIntegrationsContainerClass}>
        {POPULAR_INTEGRATIONS.map(({ source, disabled }) => (
          <LogoCardButton
            key={source}
            logoName={source}
            onClick={() => toggleSelected(source)}
            selected={integrationSource === source}
            disabled={disabled}
          />
        ))}
      </div>
      <h4>Other Site Integrations</h4>
      <div className="px-1">
        {LINKABLE_SITES.map((option, index) => (
          <FormGroup key={`${index}${option.source}`}>
            <label className="fw-normal">
              <Input
                type="radio"
                name={option.source}
                onChange={() => toggleSelected(option.source)}
                checked={integrationSource === option.source}
              />
              <span>{option.label}</span>
            </label>
          </FormGroup>
        ))}
      </div>
    </>
  );
};

export default SelectSiteIntegrationStep;
