import React from "react";
import PropTypes from "prop-types";
import Gravatar from "react-gravatar";
import { INFO_EMAIL } from "../../constants/emails";
import style from "./Avatar.module.scss";
const DEFAULT_AVATAR_SIZE = 40;

function Avatar({ className, size = DEFAULT_AVATAR_SIZE, defaultImg = "mp", email = INFO_EMAIL, ...props }) {
  return (
    <Gravatar
      className={`rounded-circle ${style.avatar} ${className || ""}`}
      width={size}
      height={size}
      default={defaultImg}
      email={email}
      {...props}
    />
  );
}

Avatar.propTypes = {};

export default Avatar;
