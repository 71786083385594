import React, { Component } from "react";
import _ from "lodash";

import PropTypes from "prop-types";
import { TBody, THead, TH, TR, TD, Table } from "../../components/Table";
import * as postApi from "../../api/postApi";

export default class PostShares extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: {
        totalElements: 0
      },
      shares: []
    };

    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const { postId } = this.props;
    postApi.loadPostShares(postId).then(res =>
      this.setState({
        shares: _.get(res.data, "_embedded.postShareContacts"),
        page: res.data.page
      })
    );
  }

  render() {
    const { page, shares } = this.state;
    return (
      <div>
        <Table responsive>
          <THead>
            <TR>
              <TH>Contact</TH>
              <TH>Shares</TH>
            </TR>
          </THead>
          <TBody>
            {_.map(shares, (share, i) => (
              <TR key={i}>
                <TD>{share.contactName}</TD>
                <TD>{_.size(share.sources)}</TD>
              </TR>
            ))}
          </TBody>
        </Table>
        <p>
          Showing {_.size(shares)} of {page.totalElements}
        </p>
      </div>
    );
  }
}

PostShares.propTypes = {
  postId: PropTypes.string
};
