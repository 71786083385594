import { Alert } from "reactstrap";

export function AdBlockError({ service }) {
  return (
    <Alert color="warning" className="text-center w-100">
      We can't connect to {service} right now.
      <br />
      Please disable any ad blockers and try again.
    </Alert>
  );
}

export default AdBlockError;
