import { REVIEW_SITES, LINKABLE_SITES } from "data/sites";
import { renderSourceItem } from "views/Reviews/Feed";

export const FILTER_TYPES = {
  multiSelect: "multiSelect",
  checkbox: "checkbox"
};

// Starter Filter Recipes

export const getCheckboxFilterConfig = (config = {}) => ({
  options: [],
  ...config,
  type: FILTER_TYPES.checkbox
});

export const getMultiSelectFilterConfig = (config = {}) => ({
  options: [],
  ...config,
  type: FILTER_TYPES.multiSelect,
  placeholder: "select an option",
  primaryBar: {
    position: "LEFT",
    className: "d-none d-md-flex"
  },
  chip: {
    position: "NONE"
  }
});

// Filter Recipes

const checkboxSiteMapper = site => ({ label: renderSourceItem(site.source), value: site.source });
const multiSelectSiteMapper = site => ({ label: site.label, value: site.source });

const CONFIG_MAP = {
  [FILTER_TYPES.multiSelect]: getMultiSelectFilterConfig,
  [FILTER_TYPES.checkbox]: getCheckboxFilterConfig
};

const OPTION_MAPPER = {
  [FILTER_TYPES.multiSelect]: multiSelectSiteMapper,
  [FILTER_TYPES.checkbox]: checkboxSiteMapper
};

export const getSiteIntegrationsFilterConfig = ({
  name = "integration",
  label = "Integrations",
  type = FILTER_TYPES.multiSelect,
  ...config
} = {}) => {
  return CONFIG_MAP[type]({
    name,
    label,
    ...config,
    options: LINKABLE_SITES.map(OPTION_MAPPER[type])
  });
};

export const getReviewSourceFilterConfig = ({ type = FILTER_TYPES.checkbox, ...overrides } = {}) =>
  CONFIG_MAP[type]({
    name: "reviewSource",
    label: "Review Source",
    options: REVIEW_SITES.map(OPTION_MAPPER[type]),
    ...overrides
  });
