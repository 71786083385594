import moment from "moment";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import isFunction from "lodash/isFunction";
import StatusMessages from "../Misc/StatusMessages";
import { ComparisonLinesChart } from "./ComparisonLinesChart";
import { ComparisonLinesLegend } from "./ComparisonLinesLegend";
import { SelectableSlabsRow } from "./SelectableSlabsRow";
import { convertDataToTrendingChartDataFormat } from "./TrendingChartWithLegend.helpers";

/**
 *
 * @param {{
 *   startDate: import("moment").Moment;
 *   frequency: import("moment").unitOfTime.Base;
 *   chartProps?: {
 *     xAxis?: import("recharts").XAxisProps;
 *     yAxis?: import("recharts").YAxisProps;
 *     statLine?: import("recharts").LineProps;
 *     comparisonLine?: import("recharts").LineProps;
 *     grid: import("recharts").CartesianGridProps;
 *     tooltip: import("recharts").TooltipProps;
 *   };
 *   legendRenderer?: React.ComponentProps<typeof SelectableSlabsRow>["renderer"];
 *   dataSource: (
 *       timespan: React.ComponentProps<typeof ChartConfiguration>["value"]["timespan"],
 *       frequency: React.ComponentProps<typeof ChartConfiguration>["value"]["frequency"]
 *     ) => Promise<{
 *      buckets: {
 *        stat: string[];
 *        comparison: string[];
 *      };
 *      datasets: Array<{
 *        id: string;
 *        total: number;
 *        pctChange: number;
 *        trends: {
 *          stat: number[];
 *          comparison: number[];
 *        }}>
 *     }>
 * }} param0
 */
const TrendingChartWithLegend = ({ legendRenderer, dataSource = fallbackAsyncDataSource, chartProps = {} }) => {
  const [selected, setSelected] = useState(0);
  const [chartData, setChartData] = useState(convertDataToTrendingChartDataFormat(fallbackSyncDataSource));
  const [error, setError] = useState();
  const [isHorly, setIsHorly] = useState(false);

  const formatTick = useCallback(v => moment.unix(v).format(isHorly ? "h:mm A" : "MMM DD"), [isHorly]);

  useEffect(() => {
    if (isFunction(dataSource)) {
      dataSource()
        .then(v => {
          setChartData(convertDataToTrendingChartDataFormat(v));
          setIsHorly(moment(v.buckets.stat[1]).diff(v.buckets.stat[0], "days") < 1);
          setError(undefined);
        })
        .catch(() => setError("Failed loading chart data"));
    } else if (dataSource && dataSource.datasets && dataSource.buckets) {
      setChartData(convertDataToTrendingChartDataFormat(dataSource));
      setIsHorly(moment(dataSource.buckets.stat[1]).diff(dataSource.buckets.stat[0], "days") < 1);
    }
  }, [dataSource]);

  useEffect(() => {
    if (chartData) setSelected(prev => (chartData.length <= prev ? 0 : prev));
  }, [chartData]);

  return (
    <div>
      {chartData.length > 0 ? (
        <>
          <SelectableSlabsRow
            renderer={legendRenderer}
            data={chartData}
            selected={selected}
            onChange={setSelected}
            withLoader={!error}
          />
          {chartData && (
            <>
              <ComparisonLinesChart
                data={chartData[selected]?.data || []}
                options={{
                  xAxis: {
                    tickFormatter: formatTick
                  },
                  statLine: {
                    connectNulls: true
                  },
                  comparisonLine: {
                    connectNulls: true
                  },
                  ...(chartProps || {})
                }}
              />
              <div className="d-none d-md-block w-100 d-flex justify-content-between align-items-center">
                <ComparisonLinesLegend />
              </div>
            </>
          )}
        </>
      ) : (
        <p className="p-4 border rounded">No data at this time.</p>
      )}
      {error && !chartData && <StatusMessages errors={error} />}
    </div>
  );
};

export const fallbackSyncDataSource = { buckets: { stat: [], comparison: [] }, datasets: [] };
export const fallbackAsyncDataSource = () => Promise.resolve(fallbackSyncDataSource);

export default TrendingChartWithLegend;
