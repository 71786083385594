import uniqueId from "lodash/uniqueId";
import moment from "moment";
import React, { useState } from "react";
import { ButtonDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { AuthorizationRequiredToRender } from "../../components/Auth/Authorization";
import { permissions } from "../../components/Auth/permissions";
import DatePicker from "../../components/DateTime/DatePicker";
import StatusMessages from "../../components/Misc/StatusMessages";

function QueueImportButton({ info, source, queueImport, label = "Queue Import" }) {
  const [messages, setMessages] = useState();
  const [errors, setErrors] = useState();
  const [id] = useState(uniqueId("queue-import-button"));
  const [open, setOpen] = useState(false);

  return (
    <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
      <ButtonDropdown isOpen={open} toggle={() => setOpen(!open)}>
        <DropdownToggle id={id} size="sm" color="primary" caret>
          {label}
        </DropdownToggle>
        <DropdownMenu className="p-0 border-0 overflow-visible" tag="div">
          <StatusMessages errors={errors} />
          <DatePicker
            range={false}
            onStartChange={m => {
              const queueImportEndDate = m.utc().format("YYYY-MM-DD");
              if (m.isAfter(moment(new Date()))) {
                setErrors("The date you selected is in the future.");
                setMessages(undefined);
              } else {
                queueImport({ source, siteInfoId: info.id, endDate: queueImportEndDate });
                setMessages("Import Queued");
                setErrors(undefined);
                setOpen(false);
              }
            }}
          />
        </DropdownMenu>
      </ButtonDropdown>
      <StatusMessages messages={messages} />
    </AuthorizationRequiredToRender>
  );
}

export default QueueImportButton;
