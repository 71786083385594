import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import ToggleSwitch from "components/Form/Switch";
import DatePickerField from "components/Form/DatePickerField";
import WWButton from "components/Buttons/WWButton";
import RemoveFieldControl from "components/Form/RemoveFieldControl";
import DayHoursForm from "./DayHoursForm";
import { parseFormFieldName } from "util/stringUtils";

const SPECIAL_HOURS_TYPE = "specialHours";
const MAX_SPECIAL_HOURS_COUNT = 10;

const getFormHoursPath = index => `${SPECIAL_HOURS_TYPE}[${index}]`;

const SpecialHoursForm = ({ initialValues, values }) => {
  const [specialHoursNextIndex, setSpecialHoursNextIndex] = useState(
    get(initialValues, SPECIAL_HOURS_TYPE)?.length || 0
  );
  const [specialDayFields, setSpecialDayFields] = useState([]);

  useEffect(() => {
    setSpecialDayFields(
      !!get(initialValues, SPECIAL_HOURS_TYPE)?.length
        ? initialValues[SPECIAL_HOURS_TYPE].map((_, index) => getFormHoursPath(index))
        : []
    );
  }, [initialValues]);

  const addSpecialDay = useCallback(() => {
    setSpecialDayFields(specialDayFields.concat([getFormHoursPath(specialHoursNextIndex)]));
    setSpecialHoursNextIndex(specialHoursNextIndex + 1);
  }, [specialDayFields, specialHoursNextIndex]);

  const removeSpecialDay = useCallback(
    field => {
      setSpecialDayFields(specialDayFields.filter(dayHoursField => dayHoursField !== field));
    },
    [specialDayFields]
  );

  return (
    <div className="mt-5 py-3">
      <h5 className="mb-1">Holiday Hours</h5>
      {specialDayFields.map((field, index) => {
        const opened = !!get(values, [...parseFormFieldName(field), "opened"]);
        const startDate = get(values, [...parseFormFieldName(field), "startDate"]);
        const dateSet = !!startDate;
        return (
          <div key={startDate + field}>
            <Row>
              <Col xs={9} sm={10} md={4} className="pe-0 me-0 mt-3">
                <DatePickerField name={`${field}.startDate`} />
              </Col>
              {specialDayFields.length > 0 && (
                <Col xs={2} sm={1} className="ms-3 p-2 mt-5 d-flex align-items-center">
                  <RemoveFieldControl onClick={() => removeSpecialDay(field)} forField={field} />
                </Col>
              )}
            </Row>
            {dateSet && (
              <Row className="mt-2">
                <ToggleSwitch label="" className="mb-2" name={`${field}.opened`} onLabel="" offLabel="">
                  <span className="d-inline-block ms-2">{opened ? "Open" : "Closed"}</span>
                </ToggleSwitch>
                {opened && (
                  <DayHoursForm
                    values={values}
                    initialValues={initialValues}
                    hoursField={SPECIAL_HOURS_TYPE}
                    dayIndex={index}
                  />
                )}
              </Row>
            )}
          </div>
        );
      })}
      {specialDayFields.length < MAX_SPECIAL_HOURS_COUNT && (
        <WWButton iconClass="fa fa-plus" color="link" onClick={addSpecialDay} className="p-md-0">
          Add another date
        </WWButton>
      )}
    </div>
  );
};

export default SpecialHoursForm;
