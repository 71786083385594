import { includes } from "lodash";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";

export default function SingletonButtonDropdown({
  label,
  disabled = false,
  options = [],
  selected = [],
  onSelected,
  color = "primary",
  ...props
}) {
  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle
        disabled={selected.length >= options.length || disabled}
        className="my-1"
        color={color}
        caret
        {...props}
      >
        {label}
      </DropdownToggle>
      <DropdownMenu>
        {options
          .filter(option => !includes(selected, option))
          .map((option, index) => (
            <DropdownItem key={index} onClick={() => onSelected(option)}>
              {option.label || option}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
}
