import { valueOf } from "util/functionUtils";
import { find, isArray, isUndefined } from "lodash";
import { append, equals, isNil, of, pipe, reject, until } from "ramda";
import classNames from "classnames";
import WWChip from "components/WWChips/WWChip/WWChip";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import { useFilterValueState } from "hooks/filteringHooks";

import styles from "./CheckboxFilter.module.scss";
import WWButton from "components/Buttons/WWButton";

const isCheckboxValueActive = (checkedValues, optionValue) => {
  return ((Array.isArray(checkedValues) ? checkedValues : [checkedValues]) || []).includes(optionValue);
};

const CheckboxFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <CheckboxFilterBar key={props.name} className={className} {...props} />
  );
  const chip = ({ ...props }, className) => <CheckboxChip key={props.name} {...props} className={className} />;
  const filterComponent = ({ ...props }, className) => (
    <CheckboxFilterBar sidebar key={props.name} {...props} className={className} />
  );
  return {
    barComponent,
    chip,
    filterComponent
  };
};

const getCheckboxValue = option => (isUndefined(option.value) ? option : option.value);
const getCheckboxLabel = option => (isUndefined(option.label) ? option : option.label);

export const CheckboxFilterBar = ({ className, name, label, icon, options, sidebar = false }) => {
  const [value, setter] = useFilterValueState(name);

  const onChecked = option => checked => {
    setter(pipe(until(isArray, of(Array)), reject(isNil), checked ? append(option) : reject(equals(option)))(value));
  };

  const allChecked = options.every(o => isCheckboxValueActive(value, getCheckboxValue(o)));
  const toggleAll = () => setter(allChecked ? [] : options.map(getCheckboxValue));

  return (
    <div
      className={classNames(
        "d-flex",
        { "flex-column align-items-start": sidebar, "align-items-center": !sidebar },
        className
      )}
    >
      {!sidebar && <span className="text-muted d-inline-block me-4">{label}:</span>}
      {options.map(option => {
        const checkboxValue = getCheckboxValue(option);
        const checkboxLabel = getCheckboxLabel(option);

        return (
          <Labeled
            className={classNames({ [styles.checkboxLabelClass]: !sidebar })}
            checked={isCheckboxValueActive(value, checkboxValue)}
            onToggle={onChecked(checkboxValue)}
            label={checkboxLabel}
            key={checkboxValue}
          />
        );
      })}

      <WWButton color="link" onClick={toggleAll}>
        {allChecked ? "Unselect All" : "Select All"}
      </WWButton>
    </div>
  );
};

export const CheckboxFilterSidebar = props => <CheckboxFilterBar {...props} sidebar />;

const getCheckboxChipLabel = (options, value) => {
  const labelValue = find(options, { value })?.label;
  return isUndefined(labelValue) ? value : labelValue;
};

export const CheckboxChip = ({ name, className, iconClass, defaultValue, options, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return value ? (
    Array.isArray(value) ? (
      value?.map(v => (
        <WWChip
          iconClass={iconClass}
          key={name + v}
          className="overflow-visible"
          onClick={() => setter(value.filter(chipValue => chipValue !== v))}
          {...props}
        >
          {getCheckboxChipLabel(options, v)}
        </WWChip>
      ))
    ) : (
      <WWChip key={name} className="overflow-visible" onClick={() => setter(valueOf(defaultValue))} {...props}>
        {getCheckboxChipLabel(options, value)}
      </WWChip>
    )
  ) : null;
};

export default CheckboxFactory;
