import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import WWButton from "components/Buttons/WWButton";
import Checkbox from "components/Checkbox/Checkbox";
import { WWTD, WWTR } from "components/Table/WWTable";
import { includes } from "ramda";
import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip as Tooltip } from "reactstrap";

const CustomerTableRow = ({ onLocationToggle, selectedLocations, customer, editable = false, onDelete = null }) => {
  return (
    <WWTR>
      <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
        <WWTD>
          <Checkbox checked={includes(customer.id, selectedLocations)} onToggle={onLocationToggle} />
        </WWTD>
      </AuthorizationRequiredToRender>
      <WWTD>
        <Link to={`/customers/${customer.id}`}>{customer.companyName}</Link>
        <div className="fs-2 text-muted">{customer.fullAddress}</div>
      </WWTD>
      <WWTD>{customer.brands && customer.brands}</WWTD>
      <WWTD>{customer.status}</WWTD>
      <WWTD>
        <div id={`signatories${customer.id}`}>
          {customer.signatories?.[0]?.name}{" "}
          <span className="fs-1 text-muted">
            {customer.signatories?.length > 1 && `+${customer.signatories?.length - 1}`}
          </span>
        </div>
        <Tooltip target={`signatories${customer.id}`} placement="top">
          {customer.signatories?.map((signatory, index, array) => (
            <span key={index}>
              {signatory.name}
              {index !== array.length - 1 && ","}{" "}
            </span>
          ))}
        </Tooltip>
      </WWTD>
      {editable && (
        <WWTD>
          <WWButton
            color="link"
            onClick={event => {
              event.preventDefault();
              onDelete(customer);
            }}
          >
            <i className="fa fa-trash"></i>
          </WWButton>
        </WWTD>
      )}
    </WWTR>
  );
};

export default CustomerTableRow;
