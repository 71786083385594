import React, { useMemo } from "react";
import WWScatterChart from "./WWScatterChart";
import TagsChartTooltip from "./Tooltips/TagsChartTooltip";

const TAGS_CHART_OPTIONS = {
  xAxis: {
    name: "Total Tags",
    label: { value: "Total tags" },
    domain: ["dataMin - 1", "dataMax + 1"],
    allowDecimals: false
  },
  yAxis: {
    name: "Avg. Rating",
    label: { value: "Average rating" },
    domain: [0, 5],
    tickCount: 6
  },
  zAxis: {
    range: [180, 181]
  }
};

const TagsChart = ({ data, onDotClick, tagType = "PERSON" }) => {
  const tooltipOptions = useMemo(
    () => ({
      ...TAGS_CHART_OPTIONS,
      tooltip: {
        content: ({ active, payload }) =>
          active && payload && payload.length > 0 ? <TagsChartTooltip payload={payload} tagType={tagType} /> : null,
        cursor: false
      }
    }),
    [tagType]
  );

  return <WWScatterChart data={data} options={tooltipOptions} onDotClick={onDotClick} />;
};

export default React.memo(TagsChart);
