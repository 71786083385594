// uncomment this line if you have something to import from your scss module
import styles from "./NavigableModalContainer.module.scss";
import { not } from "ramda";
import React, { useCallback } from "react";
import { noop } from "lodash";
import { useReduxAt, useStateThroughRedux } from "../../../../hooks/stateHooks";
import WWButton from "components/Buttons/WWButton";
import { WWModal, WWModalHeader } from "components/Modals/WWModal";

const { container, modal } = styles;

export const ContainerContext = React.createContext();

export default function NavigableModalContainer({
  children,
  title,
  showNav = true,
  onClosed = noop,
  reduxRoot = "settings"
}) {
  const [opened, setOpened] = useStateThroughRedux(`${reduxRoot}/opened`, false);
  const caption = useReduxAt(`${reduxRoot}/caption`, undefined);
  const toggler = useCallback(() => {
    setOpened(not);
  }, []);
  const [navigationOpened, setNavigationOpened] = useStateThroughRedux(`${reduxRoot}/navopened`, false);

  return (
    <ContainerContext.Provider value={{ reduxRoot }}>
      <WWModal
        className={modal}
        contentClassName="h-100"
        scrollable
        centered
        toggle={toggler}
        isOpen={opened}
        onClosed={onClosed}
        returnFocusAfterClose={false}
      >
        <WWModalHeader className={`bg-light align-items-center ${title ? "" : "d-none"}`} toggle={toggler}>
          <div className="d-inline-flex w-100 flex-row align-items-baseline flex-nowrap text-nowrap">
            {showNav && (
              <WWButton
                iconClass="fa fa-navicon"
                className="d-block d-md-none  me-3"
                color="light"
                active={navigationOpened}
                onClick={() => setNavigationOpened(not)}
              />
            )}
            {title}
            <span className="d-inline-block d-md-none text-nowrap">
              {caption ? (
                <>
                  <span className="text-muted mx-2">/</span>
                  {caption}
                </>
              ) : null}
            </span>
          </div>
        </WWModalHeader>
        <div className={`${container} bg-light`}>{children}</div>
      </WWModal>
    </ContainerContext.Provider>
  );
}
