import React, { useContext, useEffect, useMemo } from "react";
import { FormContext } from "../Form";
import { noop } from "lodash";
import FormField from "../FormField";
import WWTypeAhead from "./Typeahead";

export const TypeaheadFormField = ({ name, label, inline, selected, onChange, ...props }) => {
  const context = useContext(FormContext);

  useEffect(() => {
    context && context.registerField(name);
  }, [name]);

  const changeHandler = useMemo(() => {
    if (typeof onChange === "function" || typeof context?.setRawValue === "function") {
      return value => (onChange || context.setRawValue)({ target: { name, value: value?.[0] } });
    }
    return noop;
  }, [context, onChange]);

  const selectedValue = useMemo(() => selected || (context?.state[name] ? [context.state[name]] : []), [
    selected,
    context?.state,
    name
  ]);

  return (
    <FormField inline={inline} name={name} label={label}>
      <WWTypeAhead onChange={changeHandler} selected={selectedValue} {...props} />
    </FormField>
  );
};

export default TypeaheadFormField;
