import React, { useCallback } from "react";
import { Nav, NavbarBrand, NavbarToggler } from "reactstrap";
import HeaderDropdown from "./HeaderDropdown";
import { UncontrolledCreateInvitationModal, useInviteModalToggle } from "../../views/Invite/LeadCreate";
import { AuthorizationRequiredToRender } from "../Auth/Authorization";
import { permissions } from "../Auth/permissions";
import NetworkRequestIndicator from "../Misc/NetworkRequestIndicator";
import WWButton from "../Buttons/WWButton";
import Conditional from "../Misc/Conditional/Conditional";
import useCustomers from "../../hooks/customerHooks";
import { inviteCustomerProductsKeys } from "data/products";
import defaultLogo from "img/logo-light.svg";
import defaultLogoSmall from "img/logo-symbol.svg";
import { useCurrentAgency } from "../../hooks/agencyHooks";
import styles from "./Header.module.scss";
import classNames from "classnames";
import { useSidebarPersistantViewState } from "hooks/stateHooks";

const Header = () => {
  const toggleInviteModal = useInviteModalToggle();
  const toggleInviteHandler = useCallback(() => toggleInviteModal(undefined), [toggleInviteModal]);

  const inviteCustomers = useCustomers({
    product: inviteCustomerProductsKeys,
    projection: "customerName",
    status: "ACTIVE",
    matchAllProducts: false
  });

  const [minimized, , , setExpandedForMobile] = useSidebarPersistantViewState();

  const agency = useCurrentAgency();
  return (
    <header className={classNames("app-header navbar", { "brand-minimized": minimized })}>
      <UncontrolledCreateInvitationModal />
      <NavbarToggler className="d-lg-none" onClick={() => setExpandedForMobile(prev => !prev)}>
        <span className="navbar-toggler-icon"></span>
      </NavbarToggler>
      <NavbarBrand href="/" className="d-flex align-items-center">
        {minimized ? (
          <img
            className={classNames(styles.logoMinimized, "w-100")}
            src={agency?.appLogoSmall || defaultLogoSmall}
            alt="Widewail"
          />
        ) : (
          <img className="brandIcon w-100" src={agency?.appLogo || defaultLogo} alt="Widewail" />
        )}
      </NavbarBrand>
      <NetworkRequestIndicator />
      <AuthorizationRequiredToRender roles={[permissions.REVIEW_INVITE_MANAGE, permissions.VIDEO_MANAGE]}>
        <Conditional test={!!inviteCustomers?.data?.customers?.length}>
          <div className="ms-auto d-none d-sm-block">
            <WWButton
              iconClass="fa fa-plus"
              trackingCategory="App"
              trackingAction="Send Invite"
              color="warning"
              responsiveBreakpoint="lg"
              onClick={toggleInviteHandler}
            >
              Send Invite
            </WWButton>
          </div>
          <div className="review-invite review-invite--mobile d-sm-none">
            <WWButton
              trackingCategory="App"
              trackingAction="Send Invite"
              // increasing spacing leads to uneven shape, so you got oval instead of circle
              // it looks really wierd and unpleasant, so - here is the solution:
              style={{ width: 48, height: 48 }}
              iconClass="fa fa-plus"
              className="d-block rounded-circle me-3 mb-3 shadow"
              color="warning"
              onClick={toggleInviteHandler}
            />
          </div>
        </Conditional>
      </AuthorizationRequiredToRender>
      <Nav className="ms-3" navbar>
        <HeaderDropdown />
      </Nav>
    </header>
  );
};

export default Header;
