import { convertPagedResourcesResponse } from "hooks/dataHooks";
import { inviteCustomerProductsKeys } from "./products";

const convertDataForKey = dataKey => d => {
  return convertPagedResourcesResponse({ dataKey })(d);
};

export const DEFAULT_SERVICER_NAME = "Widewail";

export const BASE_URL_CONTACTS = "/contacts",
  BASE_URL_CUSTOMERS = "/customers",
  LOCATION_TYPE = {
    customer: "customer",
    account: "account",
    contact: "contact",
    brand: "brand",
    customerOrBrand: "customerOrBrand",
    locationsWithInvite: "locationsWithInvite",
    allLocations: "allLocations",
    location_label: "location_label"
  },
  LOCATION_TYPE_NAME = {
    brand: "brand",
    contact: "contactName",
    allLocations: "companyName",
    customer: "companyName",
    account: "account",
    locationsWithInvite: "companyName",
    location_label: ({ name, value }) => [name, value].join(": ")
  },
  LOCATION_ENDPOINTS = {
    [LOCATION_TYPE.customer]: BASE_URL_CUSTOMERS,
    [LOCATION_TYPE.allLocations]: `${BASE_URL_CUSTOMERS}/filter`,
    [LOCATION_TYPE.account]: `${BASE_URL_CUSTOMERS}/filter`,
    [LOCATION_TYPE.customerOrBrand]: `${BASE_URL_CUSTOMERS}/filter`,
    [LOCATION_TYPE.brand]: `${BASE_URL_CUSTOMERS}/filter`,
    [LOCATION_TYPE.location_label]: `${BASE_URL_CUSTOMERS}/filter`,
    [LOCATION_TYPE.locationsWithInvite]: BASE_URL_CUSTOMERS
  },
  LOCATION_DATA_PROCS = {
    [LOCATION_TYPE.customer]: convertDataForKey("customers"),
    [LOCATION_TYPE.account]: convertDataForKey("accounts"),
    [LOCATION_TYPE.allLocations]: convertDataForKey("responses"),
    [LOCATION_TYPE.customerOrBrand]: convertDataForKey("responses"),
    [LOCATION_TYPE.brand]: convertDataForKey("responses"),
    [LOCATION_TYPE.locationsWithInvite]: convertDataForKey("customers"),
    [LOCATION_TYPE.location_label]: convertDataForKey("responses")
  },
  LOCATION_PARAMS = {
    [LOCATION_TYPE.contact]: {
      urlQueryFields: ["id", "name"]
    },
    [LOCATION_TYPE.customer]: {
      sort: "companyName,asc",
      urlQueryFields: ["companyName", "id"]
    },
    [LOCATION_TYPE.allLocations]: {
      sort: "companyName,asc",
      projection: "customerName"
    },
    [LOCATION_TYPE.customerOrBrand]: {
      sort: "companyName,asc",
      type: ["brand", "customer", "label"], // note label, not location_label
      urlQueryFields: ["companyName", "id", "brand", "type", "name", "value"]
    },
    [LOCATION_TYPE.account]: {
      sort: "name,asc",
      type: ["account"],
      urlQueryFields: ["id", "name", "type"]
    },
    [LOCATION_TYPE.brand]: {
      sort: "brand,asc",
      type: ["brand"],
      urlQueryFields: ["brand", "type"]
    },
    [LOCATION_TYPE.locationsWithInvite]: {
      sort: "companyName,asc",
      projection: "customerName",
      withProperties: true,
      product: inviteCustomerProductsKeys,
      urlQueryFields: ["companyName", "id"]
    },
    [LOCATION_TYPE.location_label]: {
      sort: "name,asc",
      type: "label", // note label, not location_label
      urlQueryFields: ["name", "value", "type"]
    }
  },
  LOCATION_DEFAULT_URL_QUERY_FIELDS = ["contactName", "companyName", "id", "type", "brand", "name", "value"],
  LOCATION_TYPES_SET = new Set(Object.keys(LOCATION_TYPE));
