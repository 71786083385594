import ToggleSwitch from "components/Form/Switch";
import { noop } from "lodash";
import { useCallback, useEffect, useState } from "react";
import WWAccordionSection from "../WWAccordionSection";

const ToggleAccordion = ({ isOpen = false, name, onChange = noop, label, children, ...props }) => {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const handleToggleChange = useCallback(
    event => {
      setOpen(event.target.checked);
      onChange(event.target.checked);
    },
    [onChange]
  );
  return (
    <WWAccordionSection
      showCarrot={false}
      containerClassName="b-0 mb-3"
      className="px-0 py-0 m-0 b-0"
      fontSize="fs-3"
      handleOpen={noop}
      controlledOpen={open}
      borderless={true}
      caption={
        <ToggleSwitch
          onChange={handleToggleChange}
          className="mb-0"
          label={label}
          labelWidth={6}
          inputWidth={6}
          onLabel=""
          offLabel=""
          name={name}
          checked={open}
          value={open}
        />
      }
    >
      {children}
    </WWAccordionSection>
  );
};

export default ToggleAccordion;
