import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Select from "react-select";
import { isEmpty, get, size, debounce } from "lodash";
import InputField from "components/Form/InputField";
import StatusMessages from "components/Misc/StatusMessages";
import Block from "components/Layout/Block";
import FormField from "components/Form/FormField";
import { useVinSolutionsLocations, useVinSolutionsUsers } from "hooks/data/vinSolutionsHooks";
import { DEBOUNCE_DELAY_DEFAULT } from "constants/delays";
import { SITE_SOURCE_NAMES, SOURCES } from "data/sites";
import { useAddAndRemoveSite } from "hooks/customerHooks";

export default function VinSolutionsLinking({ customer, updateSiteField }) {
  const [errors, setErrors] = useState([]);
  const [vinSolutionsInfo, setVinSolutionsInfo] = useState();
  const [selectedDealer, setSelectedDealer] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [showHelpText, setShowHelpText] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState();

  const onSearch = useCallback(
    newQueryValue => {
      if (isEmpty(selectedDealer)) {
        setUsers([]);
        setSelectedUser(null);
      } else if (newQueryValue) {
        setQuery(newQueryValue);
      }
    },
    [selectedDealer]
  );

  const siteField = SOURCES[SITE_SOURCE_NAMES.vinSolutions].customerField;

  const [addSite, removeSite] = useAddAndRemoveSite({
    customerId: customer.id,
    field: siteField
  });

  const onRemoveSite = useCallback(() => {
    removeSite(vinSolutionsInfo.id).then(data => {
      updateSiteField(siteField, data);
      setVinSolutionsInfo(undefined);
    });
  }, [updateSiteField, vinSolutionsInfo, siteField]);

  const vinSolutionsLocations = useVinSolutionsLocations({ query: customer.companyName, enable: !vinSolutionsInfo });
  const vinSolutionsUsers = useVinSolutionsUsers({
    dealerId: selectedDealer.dealerId,
    query,
    enabled: !!selectedDealer && !!query
  });

  useEffect(() => {
    setVinSolutionsInfo(get(customer, [siteField, 0]));
  }, [customer, siteField]);

  useEffect(() => {
    const { isLoading, isSuccess, data } = vinSolutionsLocations;
    if (!isLoading && isSuccess) {
      if (size(data) === 1) {
        setSelectedDealer(data[0]);
      } else {
        setShowHelpText(true);
      }
    }
  }, [vinSolutionsLocations]);

  useEffect(() => {
    const { isLoading, isSuccess, data } = vinSolutionsUsers;
    if (!isLoading && isSuccess) {
      setUsers(
        data.map(({ FullName, UserId, IlmAccess }) => ({
          label: FullName,
          value: {
            fullName: FullName,
            userId: UserId,
            role: IlmAccess
          }
        }))
      );
    }
  }, [vinSolutionsUsers]);

  return (
    <Container>
      <Row>
        <Col>
          <StatusMessages errors={errors} />
        </Col>
      </Row>
      {vinSolutionsInfo && vinSolutionsInfo.id ? (
        <>
          <Row>
            <Col>
              <strong>Linked Dealer - Linked User</strong>
            </Col>
          </Row>

          <Row>
            <Col>
              {vinSolutionsInfo.companyName + " - " + vinSolutionsInfo.userName + " "}
              <Button color="link" className="ms-1 p-0" onClick={onRemoveSite}>
                (unlink)
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {showHelpText ? (
            <>
              <Row>
                <Block>
                  <strong>Link your VinSolutions Account</strong>
                  <p>
                    We could not connect your VinSolutions Account automatically. Please contact your representative for
                    help linking your VinSolutions account.
                  </p>
                </Block>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Block>
                  <strong>Link your VinSolutions Account</strong>
                  <p>
                    In order to link VinSolutions to Widewail, you must grant vendor access to <strong>Widewail</strong>{" "}
                    before entering the information below.
                  </p>
                  <p>
                    We strongly recommend creating a <strong>Widewail</strong> specific user in VinSolutions with the{" "}
                    <strong>Manager</strong> role.
                  </p>
                </Block>
              </Row>
              <Row>
                <Col>
                  <InputField
                    inline={false}
                    disabled={true}
                    type="text"
                    label={"Company Name"}
                    placeholder="Company Name"
                    onChange={() => {}}
                    value={selectedDealer ? selectedDealer.companyName : ""}
                  />
                  <FormField label="User">
                    <Select
                      className="w-100"
                      isDisabled={selectedDealer == null}
                      options={users}
                      onChange={u => {
                        setSelectedUser(u);
                      }}
                      onInputChange={debounce(onSearch, DEBOUNCE_DELAY_DEFAULT)}
                      value={selectedUser}
                      placeholder="Search for User"
                    />
                  </FormField>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <Button
                    color="primary"
                    disabled={!selectedDealer || !selectedUser}
                    onClick={() =>
                      addSite({
                        ...selectedDealer,
                        userId: selectedUser.value.userId,
                        userName: selectedUser.value.fullName,
                        source: "VIN_SOLUTIONS"
                      })
                        .then(res => {
                          setVinSolutionsInfo({
                            ...selectedDealer,
                            id: res.data[0].id,
                            userId: selectedUser.value.userId,
                            userName: selectedUser.value.fullName
                          });
                        })
                        .catch(err => {
                          setErrors(["Failed saving VinSolutions information."]);
                        })
                    }
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
}
