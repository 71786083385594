// uncomment this line if you have something to import from your scss module
import styles from "./NewPhoneBrand.module.scss";

import { useCallback, useEffect, useMemo, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { ConfirmationButton } from "../../../Form/withConfirmation";
import { noneBlank } from "../../../../util/stringUtils";
import { useFormEventSetter } from "../../../../hooks/stateHooks";
import { useCustomerSmsOrderSubmit } from "../../../../hooks/customerHooks";
import PhoneNumberField from "../../../Form/PhoneNumberField";
import FormField from "../../../Form/FormField";

import { validAreaCode, areaCodeFeedback, removeUSCodeFromPhone } from "../../../../util/phoneNumberUtils";
import BrandEditor from "../BrandEditor/BrandEditor";
import { PhoneBrandFlows } from "../../../../data/options";
import CampaignEditor, { getEmptyCampaign } from "../CampaignEditor/CampaignEditor";

import { useCampaignMessageFlow } from "hooks/customerHooks";
import { validPhoneNumber, validWebsite } from "util/validators";

import { useAreaCodeFields } from "../useAreaCodeFields";

const phonePrefix = number => {
  if (number) {
    return number.substring(0, 3);
  }
  return undefined;
};

export default function NewPhoneBrand({ customer, contacts, previousOrder }) {
  const [flow, setFlow] = useState(previousOrder?.phoneNumber ? PhoneBrandFlows.EXISTING : PhoneBrandFlows.NEW);

  // Per Shea, geographic fallback is almost always desired.
  const [fallback, setFallback] = useState(true);
  const { data: { messageFlow, messageFlowNeedsReview } = {} } = useCampaignMessageFlow(customer.id);

  const [order, setOrder] = useState({
    customerId: customer.id,
    desiredAreaCode: previousOrder?.desiredAreaCode || phonePrefix(customer.businessPhone),
    phoneNumber: previousOrder?.phoneNumber,
    fallbackCity: previousOrder?.fallbackCity || customer.locality,
    fallbackState: previousOrder?.fallbackState || customer.administrativeArea,
    hosted: !!previousOrder?.hosted,
    brand: {
      name: customer.companyName,
      legalName: customer.companyName,
      brandRelationship: "SMALL_ACCOUNT",
      entityType: "PRIVATE_PROFIT",
      vertical: "RETAIL",
      street: `${customer.streetNumber} ${customer.streetName}`,
      city: customer.locality,
      state: customer.administrativeArea,
      countryCode: customer.countryCode,
      postalCode: customer.postalCode,
      letterOfAuthorization: null,
      ...(previousOrder?.brand || null),
      brandId: null,
      id: null
    },
    campaign: getEmptyCampaign(customer, previousOrder)
  });
  const setOrderField = useFormEventSetter(setOrder);

  // The brand's vertical also needs to be set on the campaign.
  useEffect(() => {
    setOrderField({ target: { name: "campaign.vertical", value: order.brand.vertical } });
  }, [setOrderField, order.brand.vertical]);

  // if there is no messageflow, use the default
  useEffect(() => {
    setOrderField({ target: { name: "campaign.messageFlow", value: order.campaign.messageFlow || messageFlow } });
  }, [messageFlow]);

  const setBrand = useCallback(
    brand => {
      setOrderField({ target: { name: "brand", value: brand } });
    },
    [setOrderField]
  );

  const setCampaign = useCallback(
    campaign => {
      setOrderField({ target: { name: "campaign", value: campaign } });
    },
    [setOrderField]
  );

  const {
    desiredAreaCode,
    areaCodefields,
    fallbackCity,
    fallbackState,
    fallbackAreaCodes,
    valid: areaCodesvalid
  } = useAreaCodeFields({
    customer,
    desiredAreaCode: order.desiredAreaCode,
    useAnyNumberInCity: fallback
  });

  const postOrder = useCustomerSmsOrderSubmit(customer.id);
  const submit = () => {
    if (flow === PhoneBrandFlows.NEW) {
      postOrder({
        ...order,
        desiredAreaCode,
        fallbackCity,
        fallbackState,
        fallbackAreaCodes,
        phoneNumber: null
      });
    } else {
      postOrder(order);
    }
  };

  // for validation, ensure the desiredAreaCode on the order updates on change
  useEffect(() => {
    setOrderField({ target: { name: "desiredAreaCode", value: desiredAreaCode } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desiredAreaCode]);

  useEffect(() => {
    if (fallback) {
      setOrder(
        Object.assign({}, order, { fallbackCity: customer.locality, fallbackState: customer.administrativeArea })
      );
    } else {
      setOrder(Object.assign({}, order, { fallbackCity: "", fallbackState: "" }));
    }
  }, [fallback]);

  const validOrder =
    (areaCodesvalid || flow !== PhoneBrandFlows.NEW) &&
    (!messageFlowNeedsReview || order.campaign.messageFlowReviewed) &&
    validWebsite(order.brand.website) &&
    validPhoneNumber(order.brand.supportContactPhone) &&
    noneBlank([
      flow === PhoneBrandFlows.NEW ? order.desiredAreaCode : order.phoneNumber,
      order.brand.name,
      order.brand.legalName,
      order.brand.ein,
      order.brand.supportContactEmail,
      order.brand.supportContactFirstName,
      order.brand.supportContactLastName,
      order.brand.supportContactPhone,
      order.campaign.sample1,
      order.campaign.description
    ]);

  useEffect(() => {
    setOrderField({
      target: {
        name: "hosted",
        value: flow === PhoneBrandFlows.EXISTING && !!order.brand.letterOfAuthorization
      }
    });
  }, [flow, order.brand.letterOfAuthorization]);

  return (
    <>
      <div className="d-flex justify-content-around">
        <FormGroup>
          <Input
            type="radio"
            name="flow"
            onChange={() => setFlow(PhoneBrandFlows.NEW)}
            checked={flow === PhoneBrandFlows.NEW}
          />
          <span>New Number</span>
        </FormGroup>
        <FormGroup>
          <Input
            type="radio"
            name="flow"
            onChange={() => setFlow(PhoneBrandFlows.EXISTING)}
            checked={flow === PhoneBrandFlows.EXISTING}
          />
          <span>Existing Number</span>
        </FormGroup>
      </div>
      {flow === PhoneBrandFlows.NEW ? (
        areaCodefields
      ) : (
        <>
          <FormField inline={false} name="phoneNumber" label="Existing Number">
            <PhoneNumberField
              onValueChange={values =>
                setOrderField({
                  target: {
                    value: values.value,
                    name: "phoneNumber"
                  }
                })
              }
              value={removeUSCodeFromPhone(order.phoneNumber)}
            />
          </FormField>
        </>
      )}
      <hr />
      <BrandEditor
        customer={customer}
        contacts={contacts}
        brand={order.brand}
        onChange={setBrand}
        allowHostedNumber={flow === PhoneBrandFlows.EXISTING}
      />

      <CampaignEditor
        campaign={order.campaign}
        onChange={setCampaign}
        messageFlow={messageFlow}
        messageFlowNeedsReview={messageFlowNeedsReview}
      />

      <div className="d-flex justify-content-center">
        <ConfirmationButton disabled={!validOrder} onClick={submit}>
          Request Phone Number
        </ConfirmationButton>
      </div>
    </>
  );
}
