import styles from "./DropdownAccordion.module.scss";
import WWAccordionSection from "../WWAccordionSection";

export default function DropdownAccordion({ label, borderless, children }) {
  return (
    <WWAccordionSection
      fontSize="fs-3"
      className={styles.label}
      bodyClassName="p-0"
      caption={label}
      borderless={borderless}
    >
      {children}
    </WWAccordionSection>
  );
}
