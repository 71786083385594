import React, { useCallback } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import DropdownAccordion from "components/WWAccordion/DropdownAccordion/DropdownAccordion";
import { isEmpty } from "lodash";

export const SYNTAX = {
  plain: {
    startChar: "[",
    endChar: "]"
  },
  thymeleaf: {
    startChar: "[(${",
    endChar: "})]"
  }
};

const PlaceholdersDropdown = ({ placeholders, syntax = "plain", accordionPlaceholders, insertPlaceholder }) => {
  const onSelectPlaceholder = useCallback(
    placeholder => {
      insertPlaceholder(`${SYNTAX[syntax].startChar}${placeholder}${SYNTAX[syntax].endChar}`);
    },
    [insertPlaceholder, syntax]
  );

  return (
    <UncontrolledButtonDropdown className="mt-1 me-1 pull-right">
      <DropdownToggle color="primary" size="sm" caret>
        <i className="fa fa-plus me-2" />
        Placeholders
      </DropdownToggle>
      <DropdownMenu>
        {!isEmpty(accordionPlaceholders) && (
          <>
            {accordionPlaceholders.map(({ label, placeholders }, index) => (
              <DropdownAccordion key={index} label={label} borderless>
                {placeholders.map(({ value, label }, index) => (
                  <DropdownItem key={index} className="py-2" onClick={() => onSelectPlaceholder(value)}>
                    <span className="ps-2">{label}</span>
                  </DropdownItem>
                ))}
              </DropdownAccordion>
            ))}
          </>
        )}
        {placeholders.map(({ value, label }, index) => (
          <DropdownItem key={index} onClick={() => onSelectPlaceholder(value)}>
            {label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default React.memo(PlaceholdersDropdown);
