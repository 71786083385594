import { get } from "./apiClient";

/**
 * Get the TCR brand(s) matching a company name.
 * @param {string} companyName
 */
export function brandSearch(companyName) {
  return get(["services/tcr/brands"], {
    params: { companyName }
  }).then(res => res.data);
}

/**
 * Get the TCR campaign(s) for a brand.
 * @param {string} brandId the ID of the TCR brand
 */
export function campaignSearch(brandId) {
  return get(["services/tcr/campaigns", brandId]).then(res => res.data);
}
