import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button } from "reactstrap";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";
import { debounce, get, size, find, map, isArray } from "lodash";
import { updateCompanyNameOverride } from "api/customerApi";
import * as facebookApi from "api/facebookApi";
import BlockedUsers from "../BlockedUsers";
import InputField from "components/Form/InputField";
import withConfirmation from "components/Form/withConfirmation";
import FormField from "components/Form/FormField";
import CopyButton from "components/Buttons/CopyButton";
import StatusMessages from "components/Misc/StatusMessages";
import { DEBOUNCE_DELAY_500_MS } from "constants/delays";
import { isProductActiveForCustomer } from "util/customerUtils";

export default class Facebook {
  field() {
    return "facebookInfo";
  }

  add() {
    const AddFacebook = ({ info, customer, onSave }) => {
      const [selectedPage, setSelectedPage] = useState();
      const [reviewUrl, setReviewUrl] = useState();
      const [error, setError] = useState([]);

      return (
        <>
          <StatusMessages errors={error} />
          <FormField label="Page">
            <AsyncSelect
              style={{ zIndex: 999 }}
              className="w-100"
              async
              placeholder="Search by name or id..."
              loadOptions={debounce((inputValue, callback) => {
                if (size(inputValue) > 1) {
                  facebookApi
                    .clientPages(inputValue)
                    .then(res => {
                      callback(res.data);
                      setError([]);
                    })
                    .catch(error => setError(error));
                } else {
                  callback([]);
                }
              }, DEBOUNCE_DELAY_500_MS)}
              getOptionLabel={({ name, location }) =>
                `${name} - ${get(location, "street")} ${get(location, "city")}, ${get(location, "state")}`
              }
              // getOptionValue={({ id, name }) => Object.assign({ id, name, source: 'FACEBOOK' })}
              onChange={({ id, name }) =>
                setSelectedPage({
                  pageId: id,
                  pageName: name,
                  source: "FACEBOOK"
                })
              }
            />
          </FormField>
          <InputField label="Review Url" value={reviewUrl} onChange={e => setReviewUrl(e.target.value)} />
          <FormField label=" ">
            <Button
              color="primary"
              onClick={() => {
                if (reviewUrl) {
                  onSave({ source: "FACEBOOK", reviewUrl });
                } else {
                  onSave(selectedPage);
                }
              }}
            >
              Link
            </Button>
          </FormField>
        </>
      );
    };
    return AddFacebook;
  }

  edit() {
    const EditFacebook = ({ info, customer, refreshSite }) => {
      const [manageBlockedUsers, setManageBlockedUsers] = useState(false);
      const [manageBlockedUsersEnabled, setManageBlockedUsersEnabled] = useState(false);

      const isProductActive = useMemo(() => productName => isProductActiveForCustomer(customer, productName), [
        customer
      ]);

      useEffect(
        () => setManageBlockedUsersEnabled(isProductActive("FACEBOOK_POSTS") || isProductActive("FACEBOOK_ADS")),
        [customer]
      );

      const saveCompanyName = useCallback(
        name => updateCompanyNameOverride(customer.id, "facebookInfo", info.id, name),
        [info.id, customer.id]
      );

      return (
        <>
          {info.pageId && (
            <>
              <FormField label="Page">
                <>
                  <a href={"https://www.facebook.com/" + info.pageId} target="_blank" rel="noopener noreferrer">
                    {info.pageName + " "} <i className="fa fa-external-link" />
                  </a>
                  <Button size="sm" color="link" onClick={refreshSite}>
                    <i className="icon-refresh"></i>
                  </Button>
                </>
              </FormField>
              <InputField
                name="companyNameOverride"
                label="Name Override"
                value={info.companyNameOverride}
                onChange={evt => saveCompanyName(evt.target.value)}
              />
              <FormField label="ID">
                <div className="text-nowrap">
                  {info.pageId}
                  <span className="ps-1">
                    <CopyButton text={info.pageId} />
                  </span>
                </div>
              </FormField>
              {manageBlockedUsersEnabled && (
                <>
                  <FormField label="Actions">
                    <Button size="sm" onClick={() => setManageBlockedUsers(!manageBlockedUsers)}>
                      Manage Blocked Users
                    </Button>
                  </FormField>
                  <BlockedUsers
                    customerId={customer.id}
                    isOpen={manageBlockedUsers}
                    onClose={() => setManageBlockedUsers(false)}
                  />
                </>
              )}
            </>
          )}
        </>
      );
    };
    return EditFacebook;
  }

  duplicateWarning() {
    const OverrideWarning = ({ duplicates, agency, info, onOverride = () => {} }) => {
      const DuplicateRow = ({ duplicate, agency }) => {
        return (
          <>
            <Link to={`/customers/${duplicate.id}`}>{`${duplicate.companyName}`}</Link>

            {duplicate.agency.id !== agency.id && <>{` (${duplicate.agency.name})`}</>}
            <br />
          </>
        );
      };

      const confirmationPrompt = ({ duplicates, agency }) => {
        return (
          <>
            <span>{"Already linked to: "}</span>
            <br />
            {map(duplicates, (duplicate, idx) => (
              <DuplicateRow duplicate={duplicate} agency={agency} key={duplicate.id} />
            ))}
            <span>{"Link anyway?"}</span>
          </>
        );
      };

      const HiddenButton = useMemo(
        () =>
          withConfirmation(
            <span onClick={() => onOverride(info)} color="warning"></span>,
            { showDialog: true },
            true,
            confirmationPrompt({ duplicates, agency })
          ),
        [info, duplicates]
      );

      if (!isArray(duplicates)) duplicates = [duplicates];
      if (duplicates && duplicates.length > 0) {
        return <>{<HiddenButton />}</>;
      } else {
        return <></>;
      }
    };
    return OverrideWarning;
  }
}
