import React from "react";
import { Badge } from "reactstrap";

const BADGE_STYLE = "p-2 mt-1 mb-3 d-block";

const ReplyStatusBadge = ({ nextRetryOn, status, reportedAs }) => {
  if (nextRetryOn) {
    return (
      <Badge color="warning" className={BADGE_STYLE}>
        PENDING RETRY
      </Badge>
    );
  } else if (status === "REMOVED") {
    return (
      <Badge color="dark" className={BADGE_STYLE}>
        THIS POST HAS BEEN REMOVED
      </Badge>
    );
  } else if (reportedAs) {
    return (
      <Badge color="dark" className={BADGE_STYLE}>
        REPORTED AS {reportedAs}
      </Badge>
    );
  }
  return null;
};

export default ReplyStatusBadge;
