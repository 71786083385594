import { useCallback, useMemo, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import isDeepEqual from "fast-deep-equal/react";
import { convertPagedResourcesResponse, useDelete, useGet, usePost, usePut } from "../dataHooks";

const BASE_URL = "/sharedCredentials";

export const useSharedCredentials = ({ page = 0, size = 10, enabled = true, ...params }) => {
  const paramRef = useRef(params);
  if (!isDeepEqual(paramRef.current, params)) {
    paramRef.current = params;
  }
  const query = useMemo(() => ({ page, size, ...params }), [page, size, params]);
  const queryKey = useMemo(() => [BASE_URL, query], [query]);
  const [get] = useGet(`${BASE_URL}`, { params: { ...query } });
  const queryRun = useCallback(
    () => get().then(convertPagedResourcesResponse({ dataKey: "sharedCredentialsSummaryDtoes" })),
    [get]
  );
  return useQuery(queryKey, queryRun, {
    enabled: enabled,
    keepPreviousData: false
  });
};

export const useSharedCredentialsCreate = () => {
  const post = usePost();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return post(BASE_URL, { data });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(BASE_URL)
    }
  );
};

export const useSharedCredentialsUpdate = () => {
  const put = usePut();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return put(`${BASE_URL}/${data.id}`, { data });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(BASE_URL)
    }
  );
};

export const useSharedCredentialsDelete = () => {
  const del = useDelete();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return del(`${BASE_URL}/${data.id}`);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(BASE_URL)
    }
  );
};
