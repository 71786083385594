import React from "react";
import FileButton from "react-file-button";
import { Button } from "reactstrap";

const DefaultButton = ({ label }) => (
  <Button color="primary">
    <i className="fa fa-upload" /> {label || "Upload"}
  </Button>
);

export const FileUploadButton = ({ onChange, multiple = false, children, ...props }) => {
  return (
    <FileButton
      multiple={multiple}
      onChange={onChange}
      {...props}
      style={{ overflow: "inherit", cursor: "pointer" }}
      renderButton={value => children || <DefaultButton label={props.buttonLabel} />}
    />
  );
};
