import React, { useState, useEffect } from "react";
import PasswordInput from "components/Auth/PasswordInput";
import FormField from "components/Form/FormField";
import InputField from "components/Form/InputField";

const SharedEntrataCredentials = ({ credentials, onChange, onValidChange }) => {
  const [name, setName] = useState();
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [apiUrl, setApiUrl] = useState("");

  useEffect(() => {
    setName(credentials?.name);
    setClientId(credentials?.clientId);
    setClientSecret("");
    setApiUrl(credentials?.apiUrl);
  }, [credentials]);
  useEffect(() => {
    onValidChange(name && clientId && (credentials?.clientSecret || clientSecret) && (credentials?.apiUrl || apiUrl));
    onChange({ name, clientId, clientSecret: clientSecret || null, apiUrl });
  }, [name, clientId, credentials, clientSecret, apiUrl, onChange, onValidChange]);

  return (
    <>
      <InputField
        label="Name"
        value={name || ""}
        valid={!!name}
        onChange={event => setName(event.target.value)}
        inline={false}
      />
      <InputField
        label="Client ID"
        value={clientId}
        valid={!!clientId}
        onChange={event => setClientId(event.target.value)}
        inline={false}
      />
      <FormField tip={credentials?.credentials ? "Leave this empty to use the existing Secret" : ""}>
        <PasswordInput
          label="Private Client Secret"
          value={clientSecret}
          onChange={setClientSecret}
          lastpass={false}
          inline={false}
        />
      </FormField>
      <InputField
        label="Api URL"
        value={apiUrl}
        valid={!!apiUrl}
        onChange={event => setApiUrl(event.target.value)}
        inline={false}
      />
    </>
  );
};

export default SharedEntrataCredentials;
