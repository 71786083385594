import React from "react";
import { WWCol, WWRow } from "./Layout";

/**
 * A single row with a single column
 */
export default function Block({ children, rowClass = "py-1", ...props }) {
  return (
    <WWRow className={rowClass}>
      <WWCol {...props}>{children}</WWCol>
    </WWRow>
  );
}
