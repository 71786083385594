import React, { Component } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody, Form, FormGroup, Input, Label, Button } from "reactstrap";
import * as api from "../../api/customerApi";
import AjaxLoader from "../../components/Misc/AjaxLoader";
import StatusMessages from "components/Misc/StatusMessages";

export default class NewQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      question: "",
      answer: "",
      file: null,
      working: false
    };

    this.onChange = this.onChange.bind(this);
    this.onFile = this.onFile.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.props.isOpen && !prevProps.isOpen === false) {
      this.setState({ question: "", answer: "" });
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onFile(event) {
    this.setState({ file: event.target.files[0] });
  }

  onSave() {
    this.setState({ working: true }, () => {
      let p;
      if (this.state.file) {
        p = api.postQnAFile(this.props.customerId, this.props.siteInfoId, this.state.file);
      } else {
        p = api.postQnA(this.props.customerId, this.props.siteInfoId, this.state.question, this.state.answer);
      }

      p.then(() => this.props.onClose()).catch(error =>
        this.setState({ err: error.response?.data.errors || ["Loading questions failed. Check GBP page."] })
      );
    });
  }

  render() {
    let { isOpen, onClose } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <h1>New Google Question</h1>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="question">Question</Label>
              <Input type="text" name="question" id="question" value={this.state.question} onChange={this.onChange} />
            </FormGroup>
            <FormGroup>
              <Label for="question">Answer</Label>
              <Input type="textarea" name="answer" id="answer" value={this.state.answer} onChange={this.onChange} />
            </FormGroup>
            <FormGroup>
              <Label for="file">Upload CSV</Label>
              <Input type="file" name="file" onChange={this.onFile} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <StatusMessages errors={this.state.err} />
          {this.state.working && <AjaxLoader />}
          <Button color="primary" onClick={() => this.onSave()}>
            Post
          </Button>
          <Button onClick={() => onClose()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
