import axios from "axios";
import config from "../config";

export function listLocations(boulevardInfo, filter = "") {
  return axios.post(config.api + "/boulevard/" + boulevardInfo.businessId + "/locations", null, {
    params: {
      filter
    }
  });
}
