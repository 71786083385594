import InputField from "components/Form/InputField";
import CheckboxLabeled from "../../Checkbox/Labeled/CheckboxLabeled";
import { UncontrolledTooltip } from "reactstrap";

import { useState } from "react";
import { validAreaCode } from "util/phoneNumberUtils";

export const useAreaCodeFields = (props = {}) => {
  const { customer = {} } = props;

  const [desiredAreaCode, setDesiredAreaCode] = useState(props.desiredAreaCode || ""),
    [useAnyNumberInCity, setUseAnyNumberinCity] = useState(props.useAnyNumberInCity),
    [useAlternateAreaCodes, setUseAlternateAreaCodes] = useState(props.useAlternateAreaCodes),
    [alternateAreaCodes, setAlternateAreaCodes] = useState(props.alternateAreaCodes || ""),
    fallbackAreaCodes = alternateAreaCodes
      ?.replace(/\s/g, "")
      ?.split(",")
      .filter(x => x),
    //form validation uses `undefined` to not show the valid input styles, so empty values return `undefined` for this check
    areaCodeValid = desiredAreaCode.length === 0 ? undefined : validAreaCode(desiredAreaCode),
    alternatesValid = fallbackAreaCodes.length === 0 ? undefined : fallbackAreaCodes.every(validAreaCode);

  const areaCodefields = (
    <>
      <InputField
        name="desiredAreaCode"
        value={desiredAreaCode}
        label="Desired Area Code"
        onChange={event => setDesiredAreaCode(event.target.value)}
        inline={false}
        valid={areaCodeValid}
        feedback={!areaCodeValid && "Enter a valid area code"}
      />
      <CheckboxLabeled
        label="Use any phone number in the customer's city"
        checked={useAnyNumberInCity}
        onToggle={setUseAnyNumberinCity}
      />
      <CheckboxLabeled
        label="Use alternative area codes if needed"
        checked={useAlternateAreaCodes}
        onToggle={setUseAlternateAreaCodes}
      />
      {useAlternateAreaCodes && (
        <>
          <InputField
            feedback={!alternatesValid && "Entries must all be valid area codes"}
            name="alternateAreaCodes"
            value={alternateAreaCodes}
            valid={fallbackAreaCodes.length === 0 ? undefined : alternatesValid}
            label={
              <>
                Alternate Area Codes <i className="fa fa-question-circle" id="alternateAreaCodesHelp" />
                <UncontrolledTooltip target="#alternateAreaCodesHelp">
                  Enter one or more comma separated area codes to use if the preferred area code is unavailable. For a
                  toll-free area code, enter '8**'.
                </UncontrolledTooltip>
              </>
            }
            onChange={event => setAlternateAreaCodes(event.target.value)}
            inline={false}
          />
        </>
      )}
    </>
  );

  return {
    valid: [areaCodeValid, alternatesValid].every(v => typeof v === "undefined" || v), // the validation state of the entire area code field set counts `true` and `undefined` as both valid
    desiredAreaCode,
    useAnyNumberInCity,
    useAlternateAreaCodes,
    areaCodefields,
    fallbackCity: useAnyNumberInCity ? customer.locality : null,
    fallbackState: useAnyNumberInCity ? customer.administrativeArea : null,
    alternateAreaCodes: useAlternateAreaCodes ? alternateAreaCodes : null,
    fallbackAreaCodes
  };
};
