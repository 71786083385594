import React from "react";
import classNames from "classnames";
import styles from "./SlicedCard.module.scss";

const { slicedCardClass, slicedCardTopClass, slicedCardBottomClass } = styles;

export const SlicedCardTop = ({ className, children }) => (
  <div className={classNames("card", slicedCardTopClass, className)}>{children}</div>
);

export const SlicedCard = ({ className, children }) => (
  <div className={classNames("card no-pagebreak-inside", slicedCardClass, className)}>{children}</div>
);

export const SlicedCardBottom = ({ className, children }) => (
  <div className={classNames("card", slicedCardBottomClass, className)}>{children}</div>
);
