const AREA_CODE_PATTERN = /^[0-9*]{3}$/;

export function validAreaCode(areaCode, optional) {
  if (optional && !areaCode) {
    // Return undefined so we don't show anything for an empty field.
    return undefined;
  } else {
    return AREA_CODE_PATTERN.test(areaCode);
  }
}

export function areaCodeFeedback(areaCode, optional) {
  if (validAreaCode(areaCode, optional)) {
    return "";
  } else if (!areaCode) {
    return "An area code is required.";
  } else {
    return "An area code should be exactly three digits.";
  }
}

export const addUSCodeToPhone = value => {
  if (!value) {
    return "";
  } else if (value.startsWith("+1")) {
    return value;
  } else {
    return `+1${value}`;
  }
};

export const removeUSCodeFromPhone = phone => (phone?.indexOf("+1") === 0 ? phone.substring(2) : phone);

export const formatSupportPhoneNumber = phone => {
  const match = phone.replace(/\D/g, "").match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
};
