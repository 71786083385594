import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Col, Row, UncontrolledTooltip } from "reactstrap";
import { debounce, get, isNil } from "lodash";
import classnames from "classnames";
import { categoriesSearch } from "api/listingsApi";
import InputField from "components/Form/InputField";
import WWButton from "components/Buttons/WWButton";
import TypeaheadSearchField from "components/Form/Typeahead/TypeaheadSearchField";
import RemoveFieldControl from "components/Form/RemoveFieldControl";
import { DEBOUNCE_DELAY_DEFAULT } from "constants/delays";
import { useErrorNotification } from "components/Notifications/notification";
import TextSkeleton from "components/Skeleton/TextSkeleton/TextSkeleton";
import ListingFieldLabel from "./ListingFieldLabel";
import { LISTINGS_LINKS } from "constants/links";
import CharacterCount from "../../../../components/CharacterCount/CharacterCount";
import LockedField from "../../../../components/LockedField/LockedField";
import HelpIcon from "components/Misc/HelpIcon";
import { isCurrentUserInGroup } from "../../../../util/userUtils";
import classNames from "classnames";
import { permissions } from "../../../../components/Auth/permissions";
import { useHistory } from "react-router-dom";

import styles from "./Forms.module.scss";

const { textareaClass, inlineInputClass, addCategoryClass, warningIconClass } = styles;

const ADDITIONAL_CATEGORY = "categories.secondaryCategories";
const MIN_LENGTH_TO_SEARCH = 2;
const MAX_DESCRIPTION_LENGTH = 750;
const MAX_ABOUT_LENGTH = 255;
const MAX_ADDITIONAL_CATEGORIES_COUNT = 9;

const renderLabel = (label, key) => (entity = {}) => (
  <div>
    {label}:
    {entity[key] ? (
      <span className="text-capitalize"> {entity[key].toLowerCase()}</span>
    ) : (
      <span> Cannot match this category</span>
    )}
  </div>
);

const facebookLabel = renderLabel("Facebook Category", "facebookLabel");
const aggregatorLabel = renderLabel("Aggregator Category", "aggregatorLabel");

const AboutForm = ({ initialValues, values, loaded }) => {
  const { categories } = initialValues;
  const { primaryCategory, secondaryCategories } = categories || {};
  const [additionalCategoryFields, setAdditionalCategoryFields] = useState([]);
  const [additionalCategoryNextIndex, setAdditionalCategoryNextIndex] = useState(0);
  const errorNotification = useErrorNotification();
  useEffect(() => {
    setAdditionalCategoryFields(
      secondaryCategories && secondaryCategories.length > 0
        ? secondaryCategories.map((_, index) => `${ADDITIONAL_CATEGORY}[${index}]`)
        : []
    );
    // should set once on initial categories update
    setAdditionalCategoryNextIndex(secondaryCategories?.length || 0);
  }, [categories]);

  const addCategory = useCallback(() => {
    setAdditionalCategoryFields(prev => prev.concat([`${ADDITIONAL_CATEGORY}[${additionalCategoryNextIndex}]`]));
    setAdditionalCategoryNextIndex(prev => prev + 1);
  }, [additionalCategoryFields, additionalCategoryNextIndex]);

  const removeCategory = useCallback(
    field => {
      setAdditionalCategoryFields(prev => prev.filter(categoryField => categoryField !== field));
    },
    [additionalCategoryFields]
  );

  const [categoriesOptions, setCategoriesOptions] = useState({ primaryCategory: [] });
  const [loadingCategories, setLoadingCategories] = useState({});

  const setCategoryLoading = useCallback((field, value) => setLoadingCategories(prev => ({ ...prev, [field]: value })));

  const getOnCategorySearch = useMemo(
    () => fieldName =>
      debounce(filter => {
        if (filter) {
          setCategoryLoading(fieldName, true);
          categoriesSearch(filter)
            .then(({ data }) => {
              const categories = data.map(({ name, displayName, moreHoursTypes }) => ({
                value: name,
                label: displayName,
                hours: moreHoursTypes
              }));
              setCategoriesOptions(prev => ({ ...prev, [fieldName]: categories }));
              setCategoryLoading(fieldName, false);
            })
            .catch(e => {
              setCategoryLoading(fieldName, false);
              errorNotification(e);
            });
        }
      }, DEBOUNCE_DELAY_DEFAULT),
    [categoriesOptions, loadingCategories, setCategoryLoading, setCategoriesOptions]
  );
  const onPrimaryCategorySearch = useCallback(getOnCategorySearch("primaryCategory"), [getOnCategorySearch]);
  const businessNameLockComponent = useCallback(
    unlock => (
      <Row className="my-4">
        <Col>
          {values?.businessName}
          <WWButton color="link" onClick={unlock} iconClass="fa fa-pencil" trackingAction={"Edit Company Name"} />
        </Col>
      </Row>
    ),
    [values?.businessName]
  );
  const showFacebookWarning = !values.hasFullFacebookPermissions && !isNil(values.facebookInfo);

  return (
    <>
      <Row>
        <h5 className="mb-1">Location Name</h5>
        <LockedField lockedComponent={businessNameLockComponent}>
          <div className="d-flex align-items-center mt-1">
            <Alert color="primary">
              Changing your location name from Widewail is not supported by Facebook. It may also require{" "}
              <a href={"https://support.google.com/business/answer/7107242?hl=en"} target={"_blank"}>
                re-verification
              </a>{" "}
              of your business on Google and can affect your search rankings.
            </Alert>
          </div>
          <InputField
            label={
              <ListingFieldLabel link={LISTINGS_LINKS.locationName}>
                Enter your location name as it appears to customers in the real world.
              </ListingFieldLabel>
            }
            name="businessName"
          />
        </LockedField>
      </Row>
      <Row className="my-4">
        <h5 className="mb-1">
          Location Category <FacebookWarningIcon show={showFacebookWarning} label={"category"} values={values} />
        </h5>
        <ListingFieldLabel link={LISTINGS_LINKS.locationCategory}>
          Help customers find your location by industry.
        </ListingFieldLabel>
        {loaded ? (
          <TypeaheadSearchField
            id="categories.primaryCategory"
            className={classnames(inlineInputClass)}
            placeholder="Primary Category"
            label="Primary Category"
            name="categories.primaryCategory"
            isLoading={!!loadingCategories.primaryCategory}
            minLength={MIN_LENGTH_TO_SEARCH}
            onSearch={onPrimaryCategorySearch}
            options={categoriesOptions.primaryCategory}
            defaultInputValue={primaryCategory?.label}
            caseSensitive={false}
            inline
            tip={
              <span>
                Categories can differ across directories.
                <HelpIcon className="ps-1">
                  {facebookLabel(primaryCategory)}
                  {aggregatorLabel(primaryCategory)}
                </HelpIcon>
              </span>
            }
          />
        ) : (
          <div className="py-3">
            <TextSkeleton />
          </div>
        )}
        {loaded &&
          additionalCategoryFields.map((field, index) => (
            <TypeaheadSearchField
              key={field}
              id={field}
              className={inlineInputClass}
              placeholder="Additional Category"
              label="Additional Category"
              name={field}
              isLoading={!!loadingCategories[field]}
              minLength={MIN_LENGTH_TO_SEARCH}
              onSearch={getOnCategorySearch(field)}
              options={categoriesOptions[field] || []}
              initialValue={get(initialValues, `[${field}].label`)}
              caseSensitive={false}
              additionalControl={
                <RemoveFieldControl
                  className="p-3 align-self-start"
                  onClick={() => removeCategory(field)}
                  forField={field}
                />
              }
              inline
              tip={
                <span>
                  Categories can differ across directories.
                  <HelpIcon className="ps-1">
                    {facebookLabel(secondaryCategories?.[index])}
                    {aggregatorLabel(secondaryCategories?.[index])}
                  </HelpIcon>
                </span>
              }
            />
          ))}
        {additionalCategoryFields.length < MAX_ADDITIONAL_CATEGORIES_COUNT && (
          <div className={addCategoryClass}>
            <WWButton className="pe-0" iconClass="fa fa-plus" color="link" onClick={addCategory}>
              Add another category
            </WWButton>
          </div>
        )}
      </Row>
      <Row>
        <h5 className="mb-1">Description</h5>
        <ListingFieldLabel link={LISTINGS_LINKS.description}>Describe your location to customers.</ListingFieldLabel>
        <InputField
          className={textareaClass}
          type="textarea"
          rows={2}
          name="description"
          maxTextLength={MAX_DESCRIPTION_LENGTH}
        />
        <CharacterCount count={values?.description?.length} max={MAX_DESCRIPTION_LENGTH} />
      </Row>
      <Row>
        <h5 className="mb-1">
          About <FacebookWarningIcon show={showFacebookWarning} label={"About Section"} values={values} />
        </h5>
        <ListingFieldLabel>A shorter description for your customers on Facebook.</ListingFieldLabel>
        <InputField
          disabled={showFacebookWarning}
          className={textareaClass}
          type="textarea"
          rows={2}
          name="about"
          maxTextLength={MAX_ABOUT_LENGTH}
        />
        <CharacterCount count={values?.about?.length} max={MAX_ABOUT_LENGTH} />
      </Row>
    </>
  );
};

export const FacebookWarningIcon = ({ show, label, values }) => {
  const history = useHistory();
  const linkTosSteIntegrationsHandler = () => {
    if (isCurrentUserInGroup([permissions.LOCATION_MANAGE])) {
      history.push(`/customers/${values.customer.id}/siteIntegrations`);
    }
  };

  if (show) {
    return (
      <>
        <i
          onClick={linkTosSteIntegrationsHandler}
          className={classNames("fa fa-exclamation-triangle ms-2 text-warning", { [warningIconClass]: true })}
          id={`facebook-${label.replace(/\s+/g, "")}-warning`}
        />
        <UncontrolledTooltip target={`facebook-${label.replace(/\s+/g, "")}-warning`}>
          Facebook {label} will not update until Facebook is relinked.{" "}
          {isCurrentUserInGroup([permissions.LOCATION_MANAGE]) ? "Click here to relink your Facebook" : ""}
        </UncontrolledTooltip>
      </>
    );
  } else {
    return null;
  }
};

export default AboutForm;
