import React, { Component } from "react";
import get from "lodash/get";
import MemberList from "../../components/Form/ListField";
import * as api from "../../api/customerApi";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

export default class BlockedUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null
    };

    this.refresh = this.refresh.bind(this);
    this.unblockUser = this.unblockUser.bind(this);
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.props.isOpen && !this.state.users) this.refresh();
  }

  refresh() {
    api.loadBlockedUsers(this.props.customerId).then(res =>
      this.setState({
        users: get(res.data, "_embedded.thirdpartyUsers", [])
      })
    );
  }

  unblockUser(user) {
    api.unblockUser(this.props.customerId, user.id).then(() => this.refresh());
  }

  render() {
    return (
      <Modal fade={false} isOpen={this.props.isOpen}>
        <ModalBody>
          <MemberList
            members={this.state.users}
            onRemove={this.unblockUser}
            getOptionLabel={({ name }) => name}
            getMemberLabel={({ name }) => name}
            getMemberValue={({ id }) => id}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => this.props.onClose()}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
