import { path, pathOr } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Collapse } from "reactstrap";
import { cloneDeep } from "lodash";
import HorizontalSelectField from "../../../components/Form/HorizontalSelectField";
import InputField from "../../../components/Form/InputField";
import FormField from "../../../components/Form/FormField";
import CopyButton from "../../../components/Buttons/CopyButton";
import { bySource } from "../../../data/sites";
import { useStateThroughPipe } from "../../../hooks/utilHooks";
import { updateYardiMaintenanceLink } from "../../../api/customerApi";
import SharedCredentialsSelector from "components/SharedCredentials/SharedCredentialsSelector";

export default class YardiMaintenanceAdminLinking {
  field() {
    return "yardiMaintenanceInfo";
  }

  add() {
    return YardiMaintenanceLinkAdd;
  }

  edit() {
    const EditYardiMaintenance = ({ info, customer }) => {
      const [sharedCredentials, setSharedCredentials] = useState(info.sharedCredentials);
      const [propertyId, setPropertyId] = useStateThroughPipe(info.propertyId, nativeInputEventPipe);
      const [initialInfo, setInitialInfo] = useState([]);
      const [dirty, setDirty] = useState(false);

      const updateYardiMaintenance = useCallback(() => {
        return updateYardiMaintenanceLink(customer.id, info.id, {
          sharedCredentials,
          propertyId
        }).then(data => {
          setInitialInfo(data);
        });
      }, [propertyId, sharedCredentials, customer, info]);

      useEffect(() => {
        setInitialInfo(cloneDeep(info));
      }, [info]);

      useEffect(() => {
        setDirty(propertyId !== initialInfo.propertyId || sharedCredentials?.id !== initialInfo?.sharedCredentials?.id);
      }, [initialInfo, propertyId, sharedCredentials]);
      return (
        <>
          <InputField name="propertyId" label="Property ID" value={info.propertyId} onChange={setPropertyId} />
          <CopyButton className="ms-1" text={info.propertyId} />
          <SharedCredentialsSelector
            label="Shared credentials"
            source="YARDI"
            id={sharedCredentials?.id}
            onChange={setSharedCredentials}
          />
          {!sharedCredentials && (
            <>
              <InputField inline={false} disabled={true} label="Username" value={info.username} />
              <InputField inline={false} disabled={true} label="Database name" value={info.databaseName} />
              <InputField inline={false} disabled={true} label="Server name" value={info.serverName} />
              <InputField inline={false} disabled={true} label="Service URL" value={info.serviceUrl} />
            </>
          )}
          <Collapse isOpen={dirty}>
            <FormField label=" ">
              <Button color="primary" onClick={updateYardiMaintenance} disabled={!dirty}>
                Update
              </Button>
            </FormField>
          </Collapse>
        </>
      );
    };
    return EditYardiMaintenance;
  }
}

const nativeInputEventPipe = path(["target", "value"]);
const { source } = bySource("YARDI_MAINTENANCE");

const YardiMaintenanceLinkAdd = ({ onSave }) => {
  const [sharedCredentials, setSharedCredentials] = useState();
  const [username, setUsername] = useStateThroughPipe("", nativeInputEventPipe);
  const [password, setPassword] = useStateThroughPipe("", nativeInputEventPipe);
  const [databaseName, setDatabaseName] = useStateThroughPipe("", nativeInputEventPipe);
  const [serverName, setServerName] = useStateThroughPipe("", nativeInputEventPipe);
  const [propertyId, setPropertyId] = useStateThroughPipe("", nativeInputEventPipe);
  const [serviceUrl, setServiceUrl] = useStateThroughPipe("", nativeInputEventPipe);

  const [platform, setPlatform] = useStateThroughPipe(
    { value: "SQL_SERVER", label: "SQL Server" },
    pathOr({ value: "SQL_SERVER", label: "SQL Server" }, ["target", "value"])
  );
  const allFilled = useMemo(() => {
    if (sharedCredentials) {
      return propertyId;
    } else {
      return propertyId && username && password && databaseName && serverName && serviceUrl && platform;
    }
  }, [sharedCredentials, username, password, databaseName, serverName, propertyId, serviceUrl, platform]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      onSave({
        sharedCredentials,
        username,
        password,
        databaseName,
        serverName,
        propertyId,
        serviceUrl,
        platform: platform.value,
        source
      });
    },
    [onSave, sharedCredentials, username, password, databaseName, serverName, propertyId, serviceUrl, platform]
  );

  return (
    <Form onSubmit={onSubmit}>
      <InputField inline={false} label="Property ID" value={propertyId} onChange={setPropertyId} />
      <SharedCredentialsSelector label="Shared credentials" source="YARDI" onChange={setSharedCredentials} />
      {!sharedCredentials && (
        <>
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Username"
            value={username}
            onChange={setUsername}
          />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Password"
            value={password}
            onChange={setPassword}
          />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Database name"
            value={databaseName}
            onChange={setDatabaseName}
          />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Server name"
            value={serverName}
            onChange={setServerName}
          />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Service URL"
            value={serviceUrl}
            onChange={setServiceUrl}
          />
          <HorizontalSelectField
            name="platform"
            inline={false}
            isDisabled={sharedCredentials}
            label="Platform"
            value={platform}
            simpleValue={false}
            isMulti={false}
            options={[
              { value: "SQL_SERVER", label: "SQL Server" },
              { value: "ORACLE", label: "Oracle" }
            ]}
            onChange={setPlatform}
          />
        </>
      )}
      <Button type="submit" color="primary" disabled={!allFilled}>
        Link
      </Button>
    </Form>
  );
};
