import React, { useCallback, useState } from "react";
import { InputGroup, InputGroupText } from "reactstrap";
import WWButton from "../../../components/Buttons/WWButton";
import { useMemo } from "react";
import { bySource } from "../../../data/sites";
import { deleteHubspotInfo } from "../../../api/hubspotApi";

export default class HubspotAdminLinking {
  field() {
    return bySource("HUBSPOT").customerField;
  }
  edit() {
    return HubspotAdminLinkingEdit;
  }
  add() {
    return () => null;
  }
}

const HubspotAdminLinkingEdit = ({ info, customer }) => {
  const { id: hubspotId, customerId, pipelineConfigurations: configurations } = useMemo(
    () => ({
      customerId: info.customerId || customer.id,
      pipelineConfigurations: info.pipelineConfigurations || [],
      id: info.id
    }),
    [info, customer]
  );
  const [connected, setConnected] = useState(!!hubspotId);
  useMemo(() => setConnected(!!hubspotId), [hubspotId]);

  const unlinkHubspot = useCallback(() => {
    deleteHubspotInfo(customerId, hubspotId).then(() => setConnected(false));
  }, [customerId, hubspotId]);

  return (
    <>
      {connected === true && (
        <div className="d-flex flex-column justify-content-stretch">
          <h4>Connected Pipelines</h4>
          {configurations.length === 0 ? <p className="text-muted">There is no connected pipelines yet.</p> : null}
          {configurations.map(({ id, name, importStageLabel, defaultInteractionTag: tag }) => (
            <div className="d-flex flex-row align-items-stretch justify-content-stretch flex-nowrap">
              <InputGroup className="my-1 flex-grow-1 d-flex flex-column flex-sm-row flex-nowrap" key={id}>
                <InputGroupText className="d-flex flex-row flex-sm-column justify-content-start justify-content-sm-center align-items-start w-100">
                  <span className="text-muted">
                    Pipeline<span className="d-inline d-sm-none pe-2">:</span>
                  </span>
                  <span className="text-body text-wrap text-start">{name}</span>
                </InputGroupText>
                <InputGroupText className="d-flex flex-row flex-sm-column justify-content-start justify-content-sm-center align-items-start flex-grow-1 w-100">
                  <span className="text-muted">
                    Stage<span className="d-inline d-sm-none pe-2">:</span>
                  </span>
                  <span className="text-body text-wrap text-start">{importStageLabel}</span>
                </InputGroupText>
                <InputGroupText className="d-flex flex-row flex-sm-column justify-content-start justify-content-sm-center align-items-start flex-grow-1 w-100">
                  <span className="text-muted">
                    Tag<span className="d-inline d-sm-none pe-2">:</span>
                  </span>
                  <span className="text-body text-wrap text-start">{tag}</span>
                </InputGroupText>
              </InputGroup>
            </div>
          ))}
          <div className="mt-4">
            <div className="mt-3 d-flex justify-content-center">
              <WWButton iconClass="fa fa-ban" color="danger" size="sm" outline onClick={unlinkHubspot}>
                Disconnect HubSpot
              </WWButton>
            </div>
          </div>
        </div>
      )}
      {connected === false && <p>You are not connceted to Hubspot</p>}
    </>
  );
};
