import { get } from "lodash";
import { useEffect, useState } from "react";
import WWButton from "components/Buttons/WWButton";
import { DashboardWidget } from "components/Widgets/DashboardWidget";
import { useReportFilterQuery } from "components/HOCs/ReportWithFilters";
import WWTableBlock from "components/Table/WWTableBlock";
import { exportGmbPerformanceReport } from "api/reportApi";
import { defaultPrepareFilters } from "hooks/data/useFilterQuery";
import WWTypeAhead from "components/Form/Typeahead/Typeahead";
import { Highlighter } from "react-bootstrap-typeahead";
import { useFilterState } from "hooks/filteringHooks";
import { useCustomersFilter } from "hooks/customerHooks";

const schema = [
  {
    label: "Location",
    sortOptions: ["companyName,asc", "companyName,desc"],
    renderCell: ({ locationName, siteName }) => (
      <>
        <div>{siteName || <em>N/A</em>}</div>
        <span className="fs-1 text-uppercase text-muted">{locationName}</span>
      </>
    )
  },
  {
    label: "Visits",
    valueKey: "visits",
    sortOptions: ["visits,asc", "visits,desc"],
    type: WWTableBlock.cellTypes.diff
  },
  {
    label: "Directions",
    valueKey: "directions",
    sortOptions: ["directions,asc", "directions,desc"],
    type: WWTableBlock.cellTypes.diff
  },
  {
    label: "Calls",
    valueKey: "calls",
    sortOptions: ["calls,asc", "calls,desc"],
    type: WWTableBlock.cellTypes.diff
  }
];

export const GMBReportWidget = ({
  data = [],
  actions,
  isLoading,
  pageNumber,
  setPageNumber,
  pageSize,
  totalPages,
  sort,
  setSort,
  selectedBusiness,
  setSelectedBusiness
}) => {
  const tableProps = {
    schema,
    isLoading,
    pageNumber,
    setPageNumber,
    pageSize,
    totalPages,
    actions,
    sort,
    setSort,
    data,
    showPageNumbers: false
  };

  return (
    <DashboardWidget
      title="Google Business Profile"
      actions={actions}
      className="p-0"
      titleClassName="ms-sm-5 ms-2 mt-sm-5 mt-2 mb-0"
      actionsClassName="me-sm-5 me-2 mt-sm-5 mt-2 mb-0 ms-auto"
    >
      <div className="p-5 pb-0 pt-0">
        <div className="mt-2" style={{ maxWidth: 400 }}>
          <BusinessTypeAhead selectedBusiness={selectedBusiness} setSelectedBusiness={setSelectedBusiness} />
        </div>
        <small className="text-muted">Data from Google can take up to 7 days to show up in Widewail.</small>
      </div>
      <WWTableBlock {...tableProps} pageSize={pageSize * 1.5} />
    </DashboardWidget>
  );
};

//////////////////////////////////////////////////
const renderMenuItemChildren = (option, props) => (
    <div className="my-1">
      <Highlighter search={props.text}>{option.businessName}</Highlighter>
      {option.defaultReviewTag && <span className="text-muted mx-1">({option.defaultReviewTag})</span>}
      <small className="text-muted d-block">{option.address}</small>
    </div>
  ),
  labelKey = option => `${option.businessName}${option.defaultReviewTag ? `(${option.defaultReviewTag})` : ""}`,
  gmbCustomerReducer = (customers = []) =>
    customers.reduce(
      (acc, v) =>
        v.gmbInfo?.length > 0
          ? [
              ...acc,
              ...v.gmbInfo.map(gmb => ({
                companyName: v.companyName,
                id: v.id,
                siteId: gmb.id,
                location: gmb.location,
                businessName: gmb.businessName || "",
                address: gmb.address || "",
                defaultReviewTag: v.defaultReviewTag || ""
              }))
            ]
          : acc,
      []
    );
const BusinessTypeAhead = ({ selectedBusiness, setSelectedBusiness }) => {
  const useSearch = name => {
    let [filterValues] = useFilterState(),
      id = filterValues?.location?.id,
      brands = filterValues?.location?.brand,
      { data, isLoading } = useCustomersFilter({ name, projection: "customerGmb", brands, id });

    return {
      labelKey,
      isLoading,
      options: gmbCustomerReducer(data?.[0] || []),
      selected: selectedBusiness,
      renderer: renderMenuItemChildren,
      onChange: setSelectedBusiness
    };
  };

  return <WWTypeAhead useSearch={useSearch} placeholder="All GBPs" />;
};
//////////////////////////////////////////////////

export default function GMBReportWidgetWithQuery() {
  const [selectedBusiness, setSelectedBusiness] = useState(),
    siteId = get(selectedBusiness, "[0].siteId"),
    filterQuery = useReportFilterQuery({
      url: "/report/gmb-performance/by-site-info",
      pageSize: 5,
      defaultSort: "companyName,asc",
      additionalParams: { siteId },
      freqFilter: f => f !== "HOURLY",
      normalizeResponse: resp => ({ ...resp, results: get(resp, "_embedded.performanceForSiteInfoes", []) })
    }),
    { isLoading, filterValues, filters, sort } = filterQuery,
    actions = (
      <WWButton
        onClick={() =>
          exportGmbPerformanceReport({
            ...defaultPrepareFilters({ ...filterValues, siteId }, null, filters),
            sort
          }).then(({ data }) => {
            window.open(data, "_blank");
          })
        }
      >
        CSV Export
      </WWButton>
    );

  // when the location on the report filters changes, reset the selected business
  useEffect(() => {
    setSelectedBusiness([]);
  }, [filterValues.location]);

  return (
    <GMBReportWidget
      {...{
        ...filterQuery,
        data: filterQuery.data.results,
        isLoading,
        actions,
        selectedBusiness,
        setSelectedBusiness
      }}
    />
  );
}
