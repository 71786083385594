import { GB, KB, MB } from "constants/filesize";

export const OPEN_CLOSED_STATUSES = Object.freeze({
  open: "OPEN",
  openForBusinessUnspecified: "OPEN_FOR_BUSINESS_UNSPECIFIED",
  openWithNoMainHours: "OPEN_WITH_NO_MAIN_HOURS",
  closedTemporary: "CLOSED_TEMPORARILY",
  closedPermanentely: "CLOSED_PERMANENTLY"
});

const JPG_PNG = "JPG or PNG";
const RESOLUTION_720 = { tall: 720, wide: 720 };

const DEFAULT_IMAGE_SIZE = {
  format: JPG_PNG,
  maxSize: 10 * MB
};
const GOOGLE_IMAGE_SIZE = {
  format: JPG_PNG,
  resolution: RESOLUTION_720,
  maxSize: 5 * MB,
  minSize: 10 * KB
};
const FB_IMAGE_SIZE = GOOGLE_IMAGE_SIZE;

const ADDITIONAL_PHOTOS_DESCRIPTION =
  "Upload additional images to show customers what they can expect at your business.";
const COVER_PHOTO_DESCRIPTION = "Upload an image of your business that will be featured on directories.";
const PROFILE_PHOTO_DESCRIPTION = "Upload an image to represent your business.";
const VIDEO_DESCRIPTION = "Upload videos to show customers what they can expect at your business.";

const LISTING_MEDIA_TYPES = Object.freeze({
  photo: "PHOTO",
  googleProfile: "GOOGLE_PROFILE",
  googleCoverPhoto: "GOOGLE_COVER_PHOTO",
  FBProfile: "FB_PROFILE",
  FBCoverPhoto: "FB_COVER_PHOTO",
  FBVideo: "FB_VIDEO"
});

const MEDIA_VALIDATORS = Object.freeze({
  googleCoverPhoto: "GOOGLE_COVER_PHOTO",
  googleImage: "GOOGLE_IMAGE",
  FBProfile: "FB_PROFILE_PICTURE",
  FBCoverPhoto: "FB_COVER_PHOTO"
});

const GOOGLE_IMAGE_ACCEPT = "image/jpg, image/jpeg, image/png, image/gif";
export const DEFAULT_IMAGE_ACCEPT = "image/jpg, image/jpeg, image/png";
export const DEFAULT_VIDEO_ACCEPT = "video/mp4, video/quicktime, video/webm, video/mpeg, video/x-m4v";

export const MEDIA_RULES = Object.freeze({
  [LISTING_MEDIA_TYPES.photo]: {
    name: "Additional Photos",
    description: ADDITIONAL_PHOTOS_DESCRIPTION,
    listingMediaType: LISTING_MEDIA_TYPES.photo,
    override: false,
    ...DEFAULT_IMAGE_SIZE
  },
  [LISTING_MEDIA_TYPES.googleProfile]: {
    name: "Profile photo",
    description: PROFILE_PHOTO_DESCRIPTION,
    listingMediaType: LISTING_MEDIA_TYPES.googleProfile,
    validator: MEDIA_VALIDATORS.googleImage,
    accept: GOOGLE_IMAGE_ACCEPT,
    override: true,
    ...GOOGLE_IMAGE_SIZE
  },
  [LISTING_MEDIA_TYPES.googleCoverPhoto]: {
    name: "Cover photo",
    description: COVER_PHOTO_DESCRIPTION,
    listingMediaType: LISTING_MEDIA_TYPES.googleCoverPhoto,
    validator: MEDIA_VALIDATORS.googleCoverPhoto,
    ratio: "16:9",
    accept: GOOGLE_IMAGE_ACCEPT,
    override: true,
    ...GOOGLE_IMAGE_SIZE
  },
  [LISTING_MEDIA_TYPES.FBProfile]: {
    name: "Profile photo",
    description: PROFILE_PHOTO_DESCRIPTION,
    listingMediaType: LISTING_MEDIA_TYPES.FBProfile,
    validator: MEDIA_VALIDATORS.FBProfile,
    override: true,
    ...FB_IMAGE_SIZE
  },
  [LISTING_MEDIA_TYPES.FBCoverPhoto]: {
    name: "Cover photo",
    description: COVER_PHOTO_DESCRIPTION,
    listingMediaType: LISTING_MEDIA_TYPES.FBCoverPhoto,
    validator: MEDIA_VALIDATORS.FBCoverPhoto,
    override: true,
    ...FB_IMAGE_SIZE
  },
  [LISTING_MEDIA_TYPES.FBVideo]: {
    name: "Featured Videos",
    description: VIDEO_DESCRIPTION,
    listingMediaType: LISTING_MEDIA_TYPES.FBVideo,
    video: true,
    override: false,
    format: "MPEG, QuickTime",
    maxSize: 4 * GB,
    maxDuration: 120
  }
});

export const MEDIA_SCHEME = [
  {
    header: "Google Photos",
    mediaItems: [MEDIA_RULES[LISTING_MEDIA_TYPES.googleProfile], MEDIA_RULES[LISTING_MEDIA_TYPES.googleCoverPhoto]]
  },
  {
    header: "Facebook Photos",
    mediaItems: [MEDIA_RULES[LISTING_MEDIA_TYPES.FBProfile], MEDIA_RULES[LISTING_MEDIA_TYPES.FBCoverPhoto]]
  },
  {
    header: "General Photos",
    mediaItems: [MEDIA_RULES[LISTING_MEDIA_TYPES.photo]]
  },
  {
    header: "Facebook Videos",
    mediaItems: [MEDIA_RULES[LISTING_MEDIA_TYPES.FBVideo]]
  }
];
