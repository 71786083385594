import React, { useCallback, useEffect, useState } from "react";
import { find, get, set, isEqual, every, cloneDeep, toString, identity, isEmpty } from "lodash";
import {
  Alert,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Row,
  UncontrolledButtonDropdown
} from "reactstrap";
import { not } from "ramda";
import { Card, CardBody, CardHeader, CardHeaderAction, CardHeaderActions, CardTitle } from "components/Card";
import DraggableCard from "components/Card/DraggableCard/DraggableCard";
import DividerButton from "components/DividerButton/DividerButton";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import HtmlSelectField from "components/Form/HtmlSelectField";
import Input from "components/Form/Input";
import SaveBar from "components/Form/SaveBar";
import EditableTitle from "components/Header/EditableTitle";
import { EditableList } from "components/EditableList/EditableList";
import CampaignSelector from "components/Campaigns/CampaignSelector/CampaignSelector";
import WWButton from "components/Buttons/WWButton";
import SelectLocationPrompt from "components/Misc/SelectLocationPrompt";
import CampaignEdit from "../Campaigns/CampaignEdit/CampaignEdit";
import { useAutomatedCampaignsUpdate } from "hooks/data/campaignHooks";
import { useLocationQueryParamState, useStateThroughRedux } from "hooks/stateHooks";
import { Link } from "react-router-dom";

import "./AutomatedCampaigns.scss";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import useFilterQuery from "hooks/data/useFilterQuery";
import { useFilterState } from "hooks/filteringHooks";
import { useTagSetByCustomerId } from "hooks/data/tagSetHooks";

import { post } from "api/apiClient";
import classNames from "classnames";
import { useQueryClient } from "react-query";
import { useErrorNotification } from "components/Notifications/notification";

const CAMPAIGN_FLOWS_PATH = "campaignFlows";

const AccountMessage = ({ account }) => {
  const [isLoading, setIsLoading] = useState(),
    [{ customerId }] = useFilterState(),
    errorNotify = useErrorNotification(),
    queryClient = useQueryClient(),
    createCustomerConfig = () => {
      setIsLoading(true);
      post(`${CAMPAIGN_FLOWS_PATH}?customerId=${customerId.id}`)
        .then(() => queryClient.invalidateQueries(CAMPAIGN_FLOWS_PATH))
        .catch(errorNotify)
        .finally(() => setIsLoading(false));
    };

  return (
    <Alert color="warning">
      This customer is using the automated campaign configuration from{" "}
      <Link to={`/accounts/${account.id}`}>{account.name}</Link> Some options may not be available.
      <br />
      <WWButton size="small" onClick={createCustomerConfig} className="mt-2" diabled={isLoading}>
        Convert to Customer-level Automated Campaign Config
      </WWButton>
    </Alert>
  );
};

export const AutomatedCampaigns = ({ showCloneButton = false }) => {
  const [filterValues] = useFilterState();
  const tagSetQuery = useTagSetByCustomerId(filterValues?.customerId?.id, { enabled: !!filterValues?.customerId?.id });
  const tagSet = tagSetQuery.data?.tags || [];
  const [editingCampaignId, setEditingCampaignId] = useLocationQueryParamState("modalCampaignId");
  const [, setSettingsOpened] = useStateThroughRedux("campaignModal/opened", false);
  const [, setSettingsSection] = useStateThroughRedux("campaignModal/section", undefined);
  const [automatedCampaigns, setAutomatedCampaigns] = useState([]);
  const [validForPublish, setValidForPublish] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState();
  const [collapsedView, setCollapsedView] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [hidePrompt, setHidePrompt] = useState(false);
  const updateAutomatedCampaigns = useAutomatedCampaignsUpdate();

  const filters = [
    {
      name: "customerId",
      label: "Location",
      type: "locationsWithInvite",
      minLength: 0,
      required: true,
      primaryBar: {
        position: "LEFT",
        className: "d-md-flex auto-width"
      },

      chip: {
        position: "NONE"
      }
    }
  ];

  const { data = {}, ...filterQuery } = useFilterQuery({
      filters,
      url: CAMPAIGN_FLOWS_PATH,
      normalizeResponse: identity
    }),
    isLoading = filterQuery.isLoading || isEmpty(data),
    { account } = data,
    isAccountConfig = !isEmpty(account),
    readOnly = isAccountConfig;

  const viewCampaign = id => {
    Promise.resolve()
      .then(() => {
        setHidePrompt(true);
      })
      .then(() => {
        setEditingCampaignId(id);
      });
  };

  const handleAutomatedCampaignsUpdate = path => value => {
    const index = path[0];
    const newArr = [...automatedCampaigns];
    set(newArr, path, value);
    if (newArr[index].name === null && newArr[index].campaign.name) {
      newArr[index].name = newArr[index].campaign.name;
    }
    setAutomatedCampaigns(newArr);
  };

  const addCriteria = (index, value) => {
    let newArr = [...automatedCampaigns];
    newArr[index].audiences[0].criteria.push(value);
    setAutomatedCampaigns(newArr);
  };

  const removeCriteria = (index, criteriaIndex) => {
    let newArr = [...automatedCampaigns];
    newArr[index].audiences[0].criteria.splice(criteriaIndex, 1);
    setAutomatedCampaigns(newArr);
  };

  const addAutomatedCampaign = (index, automatedCampaign = []) => {
    let newArr = cloneDeep(automatedCampaigns);
    newArr.splice(index, 0, {
      name: null,
      tempId: Date.now(),
      fallbackCampaigns: automatedCampaign?.fallbackCampaigns || [],
      campaign: automatedCampaign?.campaign,
      audiences: [
        //Scrub Ids from a cloned campaign so they can be saved
        {
          criteria: automatedCampaign?.audiences?.[0]?.criteria
            ? automatedCampaign?.audiences?.[0]?.criteria.map(({ id, ...rest }) =>
                rest?.interactionMatchingRules
                  ? {
                      ...rest,
                      interactionMatchingRules: rest?.interactionMatchingRules.map(({ id, ...rest }) => rest)
                    }
                  : rest
              )
            : []
        }
      ]
    });
    setAutomatedCampaigns(newArr);
  };

  const removeAutomatedCampaign = index => {
    let newArr = [...automatedCampaigns];
    newArr.splice(index, 1);
    setAutomatedCampaigns(newArr);
  };

  const publish = callback => {
    updateAutomatedCampaigns
      .mutateAsync({ id: data.id, automatedCampaigns: automatedCampaigns.map(({ tempId, ...rest }) => rest) })
      .then(() => callback);
  };

  useEffect(() => {
    setAutomatedCampaigns(data?.automatedCampaigns ? cloneDeep(data?.automatedCampaigns) : []);
  }, [data.automatedCampaigns]);

  useEffect(() => {
    if (isEqual(data?.automatedCampaigns, automatedCampaigns)) {
      setValidForPublish(false);
      setInvalidMessage(null);
      setDirty(false);
    } else {
      setDirty(true);
      if (!every(automatedCampaigns, "campaign")) {
        setValidForPublish(false);
        setInvalidMessage("A campaign must be selected for each automated campaign");
      } else if (!every(automatedCampaigns, "name")) {
        setValidForPublish(false);
        setInvalidMessage("A campaign must have a name");
      } else if (!!find(automatedCampaigns, campaign => isEqual(campaign.audiences[0].criteria, []))) {
        setValidForPublish(false);
        setInvalidMessage("You must have at least one criteria for each automated campaign");
      } else {
        setValidForPublish(true);
        setInvalidMessage(null);
      }
    }
  }, [automatedCampaigns, data.automatedCampaigns]);

  useEffect(() => {
    if (editingCampaignId) {
      setSettingsOpened(true);
      setSettingsSection("config");
    } else {
      setSettingsOpened(false);
      setHidePrompt(false);
    }
  }, [editingCampaignId, setSettingsSection, setSettingsOpened]);

  const CriteriaRemover = ({ index, criteriaIndex }) => {
    return (
      <i
        className={classNames("fa ms-2 fa-trash fa-lg text-danger", { "opacity-25": readOnly })}
        onClick={() => !readOnly && removeCriteria(index, criteriaIndex)}
      />
    );
  };

  return (
    <div className="mt-4 container-fluid">
      <FacetedSearch showFiltersButton={false} filters={filters} />
      {!filterValues?.customerId?.id ? (
        <SelectLocationPrompt />
      ) : isLoading ? null : (
        <Row>
          <Col>
            {isAccountConfig && <AccountMessage account={account} />}
            <Card>
              {!collapsedView && (
                <CardHeader className="flex-wrap justify-content-center justify-content-md-between">
                  <CardTitle>Automated Campaigns</CardTitle>
                  <CardHeaderActions className="ms-0">
                    <Link
                      disabled={readOnly}
                      className="btn btn-secondary text-uppercase"
                      to={`/invite/campaigns?customerId[id]=${filterValues?.customerId?.id}&customerId[companyName]=${filterValues?.customerId?.companyName}`}
                    >
                      View Campaigns
                    </Link>
                    <WWButton
                      disabled={readOnly}
                      className="btn btn-secondary text-uppercase"
                      onClick={() => setCollapsedView(not)}
                    >
                      {collapsedView ? "Edit Mode" : "Reorder Mode"}
                    </WWButton>
                    <CardHeaderAction
                      className="d-md-inline mx-1"
                      color="primary"
                      onClick={() => addAutomatedCampaign(0)}
                      disabled={readOnly || collapsedView}
                    >
                      Add Automated Campaign
                    </CardHeaderAction>
                  </CardHeaderActions>
                </CardHeader>
              )}
              <CardBody>
                {!isLoading && filterValues?.customerId?.id && (
                  <EditableList
                    className="automated-campaign-container"
                    keyer={getId}
                    idMatcher={idMatcher}
                    data={automatedCampaigns}
                    onChange={newOrder => setAutomatedCampaigns(newOrder)}
                  >
                    {(onDragStart, item, index, list) => (
                      <div className="w-100">
                        <div className="d-flex align-items-stretch flex-row">
                          <DraggableCard
                            onDragStart={onDragStart}
                            item={item}
                            index={index}
                            list={list}
                            collapsed={collapsedView}
                            cardActions={
                              !collapsedView && (
                                <>
                                  {showCloneButton && (
                                    <WWButton
                                      iconClass="fa fa-clone"
                                      className="btn-light m-0"
                                      size="sm"
                                      onClick={() => addAutomatedCampaign(index + 1, item)}
                                    >
                                      Clone
                                    </WWButton>
                                  )}
                                  <WWButton
                                    disabled={readOnly}
                                    iconClass="fa fa-trash"
                                    className="btn-light text-danger m-0"
                                    size="sm"
                                    onClick={() => removeAutomatedCampaign(index)}
                                  >
                                    Delete
                                  </WWButton>
                                </>
                              )
                            }
                            header={
                              <EditableTitle
                                title={item.name}
                                editable={!readOnly && !collapsedView}
                                onChange={event => handleAutomatedCampaignsUpdate([index, "name"])(event.target.value)}
                              />
                            }
                          >
                            <div className="col-12 col-md-5 p-0">
                              <h5 className="mb-3">Select Campaign</h5>
                              <div className="flex-fill">
                                <CampaignSelector
                                  disabled={readOnly}
                                  customerId={filterValues?.customerId?.id}
                                  excludeInactive={false}
                                  value={item.campaign}
                                  onChange={handleAutomatedCampaignsUpdate([index, "campaign"])}
                                />
                              </div>
                              {item.campaign && (
                                <p
                                  className={classNames("link text-secondary mt-1", { "opacity-25": readOnly })}
                                  onClick={() => !readOnly && viewCampaign(item.campaign.id)}
                                >
                                  View Campaign
                                </p>
                              )}
                            </div>
                            <div className="col-12 col-md-6 p-0">
                              {item.audiences?.[0].criteria.length === 0 && <h5 className="mb-3">Select Audiences</h5>}
                              {item.audiences?.[0].criteria?.map((criteria, criteriaIndex, criteriaList) => (
                                <>
                                  <div className="d-flex flex-grow-1">
                                    {criteria.type === "percentage" && (
                                      <div className="flex-grow-1 mb-2">
                                        <h5 className="mb-3"> Percentage of All Leads</h5>
                                        <span className="d-flex align-items-center">
                                          <InputGroup>
                                            <Input
                                              disabled={readOnly}
                                              className=""
                                              type="number"
                                              min={1}
                                              step={1}
                                              max={100}
                                              value={criteria.percentage}
                                              onChange={event =>
                                                handleAutomatedCampaignsUpdate([
                                                  index,
                                                  "audiences",
                                                  "0",
                                                  "criteria",
                                                  criteriaIndex,
                                                  "percentage"
                                                ])(event.target.value)
                                              }
                                            />
                                            <InputGroupText className="fa fa-percent" />
                                          </InputGroup>
                                          <CriteriaRemover {...{ index, criteriaIndex }} />
                                        </span>
                                        <Input type="hidden" invalid={criteria.percentage > 100} />
                                        <FormFeedback>You cannot have a percentage greater that 100%</FormFeedback>
                                      </div>
                                    )}
                                    {criteria.type === "interaction" && (
                                      <div className="flex-grow-1 w-100 mb-2">
                                        <h5 className="mb-3">Tagged with</h5>
                                        <span className="d-flex align-items-center">
                                          <HorizontalSelectField
                                            disabled={readOnly}
                                            formFieldClass="w-100"
                                            value={criteria.interactionMatchingRules?.[0].tags}
                                            options={tagSet}
                                            isMulti={true}
                                            simpleValue={true}
                                            onChange={event =>
                                              handleAutomatedCampaignsUpdate([
                                                index,
                                                "audiences",
                                                "0",
                                                "criteria",
                                                criteriaIndex,
                                                "interactionMatchingRules",
                                                "0",
                                                "tags"
                                              ])(event.target.value)
                                            }
                                          />
                                          <CriteriaRemover {...{ index, criteriaIndex }} />
                                        </span>
                                        <Input type="hidden" invalid={criteria.percentage > 100} />
                                        <FormFeedback>You cannot have a percentage greater that 100%</FormFeedback>
                                      </div>
                                    )}
                                    {criteria.type === "reviewRating" && (
                                      <div className="flex-grow-1 mb-2">
                                        <h5 className="mb-3">Ratings of</h5>
                                        <span className="d-flex align-items-center justify-content-between flex-wrap">
                                          <span className="d-flex align-items-center">
                                            <HtmlSelectField
                                              disabled={readOnly}
                                              groupClassName="m-0 me-1"
                                              value={criteria.lowRating}
                                              options={ratingOptions}
                                              onChange={event =>
                                                handleAutomatedCampaignsUpdate([
                                                  index,
                                                  "audiences",
                                                  "0",
                                                  "criteria",
                                                  criteriaIndex,
                                                  "lowRating"
                                                ])(event.target.value)
                                              }
                                            />
                                            to
                                            <HtmlSelectField
                                              disabled={readOnly}
                                              groupClassName="m-0 ms-1"
                                              value={criteria.highRating}
                                              options={ratingOptions}
                                              onChange={event =>
                                                handleAutomatedCampaignsUpdate([
                                                  index,
                                                  "audiences",
                                                  "0",
                                                  "criteria",
                                                  criteriaIndex,
                                                  "highRating"
                                                ])(event.target.value)
                                              }
                                            />
                                          </span>
                                          <CriteriaRemover {...{ index, criteriaIndex }} />
                                          <Input type="hidden" invalid={criteria.highRating < criteria.lowRating} />
                                          <FormFeedback>
                                            You cannot have a max rating lower than your low rating
                                          </FormFeedback>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  {criteriaIndex + 1 < criteriaList.length && (
                                    <p className="mb-2 text-dark text-muted">AND</p>
                                  )}
                                </>
                              ))}
                              <CriteriaSelector
                                item={item}
                                index={index}
                                addCriteria={addCriteria}
                                disabled={readOnly}
                              />
                            </div>
                          </DraggableCard>
                        </div>
                        {!collapsedView && (
                          <DividerButton onClick={() => addAutomatedCampaign(index + 1)} disabled={readOnly} />
                        )}
                      </div>
                    )}
                  </EditableList>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <CampaignEdit campaignId={editingCampaignId} setCampaignId={setEditingCampaignId} />
      {dirty && (
        <SaveBar
          prompt={invalidMessage}
          hideCondition={hidePrompt}
          disableSave={!validForPublish}
          withCancel={false}
          withSaveAndClose={false}
          onSave={publish}
        />
      )}
    </div>
  );
};

const criteriaOptions = [
  {
    type: "percentage",
    percentage: 100
  },
  {
    type: "reviewRating",
    lowRating: 1,
    highRating: 5
  },
  {
    type: "interaction",
    interactionMatchingRules: [
      {
        tags: ["General"],
        conditions: []
      }
    ]
  }
];

const CriteriaSelector = ({ item, index, addCriteria, disabled }) => {
  return (
    <UncontrolledButtonDropdown>
      {item.campaign && criteriaFilterOptions(item).length > 0 && (
        <DropdownToggle className="my-1" color="primary" caret disabled={disabled}>
          Add Audience
        </DropdownToggle>
      )}
      {!item.campaign && <div class="d-md-flex align-items-center text-dark">Select a campaign to begin</div>}
      <DropdownMenu disabled={disabled}>
        {criteriaFilterOptions(item).map(v => (
          <DropdownItem key={v.type} onClick={() => addCriteria(index, v)}>
            <>
              <i className="fa fa-plus-circle text-secondary" />
              {optionsCriteriaTypeConverter(v.type)}
            </>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};
const getId = obj => {
  return toString(get(obj, "id") || get(obj, "tempId"));
};

const idMatcher = id => v => v.id === id || v.tempId === id;

const optionsCriteriaTypeConverter = type => {
  if (type === "reviewRating") {
    return "Review Rating";
  } else if (type === "percentage") {
    return "Percentage";
  } else if (type === "interaction") {
    return "Interaction";
  }
};

const criteriaFilterOptions = item => {
  if (!item.campaign) {
    return [];
  } else if (item.campaign?.type !== "VIDEO_FOLLOW_UP") {
    return criteriaOptions
      .filter(option => option.type !== "reviewRating")
      .filter(
        criteriaOption =>
          item.audiences?.[0].criteria.findIndex(criteria => criteria.type === criteriaOption.type) === -1
      );
  } else {
    return criteriaOptions.filter(
      criteriaOption => item.audiences?.[0].criteria.findIndex(criteria => criteria.type === criteriaOption.type) === -1
    );
  }
};

const ratingOptions = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
