import axios from "axios";
import config from "../config";
import qs from "qs";
import store from "../redux/store/configureStore";
import _get from "lodash/get";
import set from "lodash/set";
import isArray from "lodash/isArray";
import join from "lodash/join";
import startsWith from "lodash/startsWith";
import { flow, fromPairs, isNil, last, reject, toPairs } from "lodash";
import logger from "../util/logger";
import { Auth } from "aws-amplify";

axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: "repeat" });
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";

axios.interceptors.request.use(
  requestConfig => {
    const user = store.getState().cognito.user;
    const currentAgency = store.getState().currentAgency;
    let needsAuthHeader = false;

    if (currentAgency && currentAgency.id) {
      requestConfig.headers["x-ww-agency-id"] = currentAgency.id;
    }

    if (startsWith(requestConfig.url, config.api)) {
      needsAuthHeader = true;
    }

    if (user && needsAuthHeader) {
      return Auth.currentSession()
        .then(session => {
          requestConfig.withCredentials = true;
          requestConfig.headers["Authorization"] = session.idToken.jwtToken;
          return requestConfig;
        })
        .catch(e => {
          logger.warn(e);
          return Promise.reject(e);
        });
    }
    return requestConfig;
  },
  e => logger.warn(new Error(e))
);

function toApiUrl(path) {
  if (isArray(path)) {
    return join([config.api, ...path], "/");
  } else {
    return `${config.api}/${path}`;
  }
}

function doRequest(method, path, options = {}) {
  return axios({
    method,
    url: toApiUrl(path),
    data: options.data,
    headers: { ...options.headers },
    params: { ...options.params }
  });
}

export function rawGet(url, options) {
  return axios.get(url, options);
}

/**
 *
 * @param {string[] | string} path
 * @param {*} options
 * @returns {Promise<import("axios").AxiosResponse>}
 */
export function get(path, options) {
  return doRequest("get", path, options);
}

export function postEmpty(path, options) {
  return post(path, null, options);
}

export function postFormData(path, data) {
  return post(path, data, {
    headers: {
      "Content-type": "multipart/form-data"
    }
  });
}

/**
 *
 * @param {string[] | string} path
 * @param {*} data
 * @param {*} options
 * @returns {Promise<import("axios").AxiosResponse>}
 */
export function post(path, data, options) {
  return doRequest("post", path, {
    data,
    ...options
  });
}

export function put(path, data, options) {
  return doRequest("put", path, {
    data,
    ...options
  });
}

export function patch(path, data, options) {
  return doRequest("patch", path, {
    data,
    ...options
  });
}

export function addAssociation(path, id) {
  return doRequest("post", path, {
    data: id,
    headers: {
      "Content-type": "text/uri-list"
    }
  });
}

export function doDelete(path) {
  return doRequest("delete", path);
}

const trackableFields = ["createdOn", "lastModifiedOn"];

export function wrapDates(object, fields) {
  if (!object) return object;
  for (const field of [...fields, ...trackableFields]) {
    const val = _get(object, field);
    if (val) {
      set(object, field, new Date(val));
    }
  }
  return object;
}

/**
 *
 * @param {} params - key value pairs
 * @returns
 */
export const rejectNilParams = params => fromPairs(reject(toPairs(params), flow(last, isNil)));
