import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import QueryString from "qs";
import classnames from "classnames";
import { WWTD, WWTR } from "components/Table/WWTable";
import WWStackedChartBar from "components/Charts/WWStackedChartBar/WWStackedChartBar";
import IconCheckbox from "components/Checkbox/IconCheckbox/IconCheckbox";
import { roundToOne } from "util/roundToOne";

import styles from "./TagsReport.module.scss";
import WWButton from "../../components/Buttons/WWButton";

const { sentimentCellClass } = styles;

const UNKNOWN_SENTIMENT_VALUE = "UNTAGGED";
const REVIEW_FEED_PATHNAME = "/reviews/feed";

const TagsReportTableRow = ({
  count,
  averageRating,
  value,
  positiveSentimentCount,
  negativeSentimentCount,
  neutralSentimentCount,
  unknownSentimentCount,
  params: additionalParams,
  onTagToggle = () => {},
  tagType,
  isChecked
}) => {
  const history = useHistory();
  const basicQueryObject = {
    [`entity_${tagType}[mode]`]: "MATCH",
    [`entity_${tagType}[match]`]: value,
    ...additionalParams
  };

  const toReviewFeed = sentimentValue =>
    history.push({
      pathname: REVIEW_FEED_PATHNAME,
      search: QueryString.stringify({
        ...basicQueryObject,
        [`entity_${tagType}[sentiment]`]: sentimentValue
      })
    });

  const hasSentiments = !!(positiveSentimentCount || neutralSentimentCount || negativeSentimentCount);

  return (
    <WWTR>
      <WWTD>
        <Link
          to={{
            pathname: REVIEW_FEED_PATHNAME,
            search: QueryString.stringify(basicQueryObject)
          }}
        >
          {value}
        </Link>
      </WWTD>
      <WWTD className={classnames("text-center", { [sentimentCellClass]: hasSentiments })}>
        {hasSentiments ? (
          <WWStackedChartBar
            values={[
              { name: "Positive", count: positiveSentimentCount || 0 },
              { name: "Neutral", count: neutralSentimentCount || 0 },
              { name: "Negative", count: negativeSentimentCount || 0 },
              { name: "Unknown", count: unknownSentimentCount || 0 }
            ]}
            onValueClick={sentiment =>
              toReviewFeed(sentiment === "Unknown" ? UNKNOWN_SENTIMENT_VALUE : (sentiment || "").toUpperCase())
            }
          />
        ) : (
          <WWButton color="link" className="py-0" onClick={() => toReviewFeed(UNKNOWN_SENTIMENT_VALUE)}>
            Click to add sentiment
          </WWButton>
        )}
      </WWTD>
      <WWTD className="text-center">{count}</WWTD>
      <WWTD className="text-center">{roundToOne(averageRating)}</WWTD>
      <WWTD className="text-center d-none d-md-block">
        <IconCheckbox
          iconUnchecked="fa fa-eye"
          iconChecked="fa fa-eye-slash text-muted"
          checked={isChecked}
          onToggle={toggle => onTagToggle(value, toggle)}
        />
      </WWTD>
    </WWTR>
  );
};

export default TagsReportTableRow;
