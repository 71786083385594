import noop from "lodash/noop";
import moment from "moment";
import { adjust, always, evolve, hasPath, ifElse, map, of, pathOr, paths, pipe, prepend, thunkify } from "ramda";
import { createContext, useCallback, useContext, useMemo } from "react";

export const EMPTY_PAGE = [
  undefined,
  {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    number: 0
  }
];

/**
 * @type {import("react").Context<(conf: import("axios").AxiosRequestConfig) => [() => Promise<any>, () => void]>}
 */
export const ExecutorContext = createContext(always([thunkify(Promise.resolve), noop]));

const getUrl = path => (Array.isArray(path) ? path.join("/") : path);

/**
 * @param {string | (string | number)[] | undefined} path
 * @param {{
 *  data?: any;
 *  headers?: import("ramda").Dictionary<string>;
 *  params?: import("ramda").Dictionary<any>;
 * }} options
 */
export const useGet = (path, options = {}) => {
  const invokerFactory = useContext(ExecutorContext);
  return useMemo(() => invokerFactory({ method: "GET", url: getUrl(path), ...options }), [
    invokerFactory,
    path,
    options
  ]);
};

/**
 * @type {() =>
 *   (path: string | (string | number)[], options: {
 *  data?: any;
 *  headers?: import("ramda").Dictionary<string>;
 *  params?: import("ramda").Dictionary<any>;
 * }) => void}
 */
export const usePost = () => {
  const invokerFactory = useContext(ExecutorContext);
  return useCallback((path, options = {}) => invokerFactory({ method: "POST", url: getUrl(path), ...options })[0](), [
    invokerFactory
  ]);
};

/**
 * @type {() =>
 *   (path: string | (string | number)[], options: {
 *  data?: any;
 *  headers?: import("ramda").Dictionary<string>;
 *  params?: import("ramda").Dictionary<any>;
 * }) => void}
 */
export const usePut = () => {
  const invokerFactory = useContext(ExecutorContext);
  return useCallback((path, options = {}) => invokerFactory({ method: "PUT", url: getUrl(path), ...options })[0](), [
    invokerFactory
  ]);
};

/**
 * @type {() =>
 *   (path: string | (string | number)[], options: {
 *  data?: any;
 *  headers?: import("ramda").Dictionary<string>;
 *  params?: import("ramda").Dictionary<any>;
 * }) => void}
 */
export const usePatch = () => {
  const invokerFactory = useContext(ExecutorContext);
  return useCallback((path, options) => invokerFactory({ method: "PATCH", url: getUrl(path), ...options })[0](), [
    invokerFactory
  ]);
};

/**
 * @returns {
 *   (url: string | (string | number)[] | undefined, options: {
 *  data?: any;
 *  headers?: import("ramda").Dictionary<string>;
 *  params?: import("ramda").Dictionary<any>;
 * }) => void
 * }
 */
export const useDelete = () => {
  const invokerFactory = useContext(ExecutorContext);
  return useCallback((path, options = {}) => invokerFactory({ method: "DELETE", url: getUrl(path), ...options })[0](), [
    invokerFactory
  ]);
};

/**
 * Converts a WW API paged resouces response into something simpler w/ just the data and
 * page info.
 *
 * Transforms:
 * {
 *  _embedded: {
 *      "dataKey": [{}, ..]
 *  },
 *  page: {
 *     total: ...
 *  }
 * }
 *
 * Into:
 * [[...dataKey array...], {...paging object...}]
 *
 * @param {hateosResponse: object} response
 * @returns
 */
export const convertPagedResourcesResponse = ({ dataKey, dateFields = [] }) =>
  ifElse(
    hasPath(["_embedded", dataKey]),
    //extract the actual data array, page info and apply date transformations
    pipe(
      paths([["_embedded", dataKey], ["page"]]),
      adjust(0, map(evolve(dateFields.reduce((fields, f) => ({ [f]: moment, ...fields }), {}))))
    ),
    //otherwise create an empty response and page
    pipe(pathOr(undefined, ["page"]), of(Object), prepend([]))
  );

export const evolveDates = ({ dateFn = moment, additionalFields = [] } = {}) => {
  const fields = ["createdOn", "lastModifiedOn", "removedOn", ...additionalFields];
  const evolution = fields.reduce((prev, cur) => Object.assign(prev, { [cur]: dateFn }), {});
  return evolve(evolution);
};

/**
 * Converts Date/moment objects to date strings like yyyy/mm/dd
 * @param {} param0
 * @returns
 */
export const evolveToLocalDates = ({ fields = [] }) => {
  const evolution = fields.reduce(
    (prev, cur) => Object.assign(prev, { [cur]: d => (d ? moment.utc(d).format("YYYY-MM-DD") : undefined) }),
    {}
  );
  return evolve(evolution);
};
