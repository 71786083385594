// uncomment this line if you have something to import from your scss module
import { capitalize, isArray, isObject } from "lodash";
import moment from "moment";
import { always, complement, flip, isNil, propOr, when } from "ramda";
import { useMemo } from "react";
import Skeleton from "components/Skeleton";
import { useCustomerFilterId2NameMapper } from "../../../hooks/customerHooks";
import usePureMemo from "../../../hooks/usePureMemo/usePureMemo";
import Time from "../../DateTime/Time";
import styles from "./Legend.module.scss";
import { bySource } from "../../../data/sites";
import { reviewStatusName } from "../../../data/options";
import { useInviteCampaignWithInitialData } from "../../../hooks/data/campaignHooks";

const { container, holder } = styles;

const SKELETON_WIDTH = 24;
const SKELETON_HEIGHT = 24;
const DEFAULT_SKELETON_WIDTH = 120;

/**
 *
 * @param {{type: string; value: any}} param0
 * @returns
 */
export default function Legend({ type, value }) {
  const formatter = usePureMemo(type2Fromatter, type);
  const formattedValue = usePureMemo(formatter, value);

  return <>{formattedValue}</>;
}

export const LoadingSkeleton = ({ width = DEFAULT_SKELETON_WIDTH }) => (
  <div className={container}>
    <Skeleton circle width={SKELETON_WIDTH} height={SKELETON_HEIGHT} />
    <Skeleton count={1} width={width} height={SKELETON_HEIGHT} />
  </div>
);

export const type2Icon = flip(propOr(null))({
  date: "calendar"
});

export const type2Fromatter = type => {
  const formatter = {
    date: v => <DateRenderer value={v} />,
    daterange: v => <DateRangeRenderer value={v} />,
    location: v => <LocationRenderer value={v} />,
    representative: v => <RepresentativeRenderer value={v} />,
    category: v => <CategoryRenderer value={v} />,
    status: v => <StatusRenderer value={v} />,
    source: v => <SourceRenderer value={v} />,
    rating: v => <RatingRenderer value={v} />,
    campaign: v => <CampaignRenderer value={v} />
  }[type];
  if (!formatter) {
    return v => (
      <FallbackRenderer type={type}>
        <ValueHolder type={type}>{v}</ValueHolder>
      </FallbackRenderer>
    );
  }
  return formatter;
};

const ValueHolder = ({ type, children }) => {
  const sanitizedKids = usePureMemo(when(isObject, always(null)), children);
  return (
    <span className={holder} title={`${capitalize(type)}: ${children}`}>
      {sanitizedKids}
    </span>
  );
};

const FallbackRenderer = ({ type, children }) => {
  const icon = usePureMemo(type2Icon, type);

  return (
    <div className={container}>
      {!!icon && <i className={`fa fa-${icon}`} />}
      {children}
    </div>
  );
};

const DateRenderer = ({ value }) => (
  <FallbackRenderer type="date">
    <Time withIcon={false} format="date" date={value || moment()} />
  </FallbackRenderer>
);

const DateRangeRenderer = ({ value }) => (
  <FallbackRenderer type="date">
    <ValueHolder type={"Date Range"}>{value?.map?.(v => v.format("L")).join(" ― ")}</ValueHolder>
  </FallbackRenderer>
);

const LocationRenderer = ({ value }) => {
  const mapper = useCustomerFilterId2NameMapper();
  const thing2show = useMemo(
    () =>
      value ? (
        <ValueHolder type="location">
          {value.type === "brand"
            ? value.value
            : value.type === "contactId"
            ? mapper(value.value)[0]
            : mapper(value.value)[1]}
        </ValueHolder>
      ) : (
        <Skeleton count={1} width={120} height={24} />
      ),
    [value, mapper]
  );
  return <FallbackRenderer type="location">{thing2show}</FallbackRenderer>;
};

const RepresentativeRenderer = ({ value }) => (
  <>
    {(isArray(value) ? value : [value]).filter(complement(isNil)).map(v => (
      <FallbackRenderer key={v} type="representative">
        <ValueHolder type="representative">{v}</ValueHolder>
      </FallbackRenderer>
    ))}
  </>
);

const SourceRenderer = ({ value }) => (
  <>
    <FallbackRenderer key={value} type="source">
      <ValueHolder type="source">{bySource(value)?.label || value}</ValueHolder>
    </FallbackRenderer>
  </>
);

const StatusRenderer = ({ value }) => (
  <>
    <FallbackRenderer key={value} type="status">
      <ValueHolder type="status">{reviewStatusName(value) || value}</ValueHolder>
    </FallbackRenderer>
  </>
);

const RatingRenderer = ({ value }) => (
  <div title={`Rating: ${value}`}>
    {value} {value > 1 ? "Stars" : "Star"}
  </div>
);

const CategoryRenderer = ({ value }) => (
  <>
    <FallbackRenderer key={value} type="category">
      <ValueHolder type="category">{isArray(value) ? value[0] : value}</ValueHolder>
    </FallbackRenderer>
  </>
);

const CampaignRenderer = ({ value }) => {
  const { data: campaign } = useInviteCampaignWithInitialData(value);

  return (
    <FallbackRenderer key={value} type="campaign">
      <ValueHolder type="campaign">{campaign?.name}</ValueHolder>
    </FallbackRenderer>
  );
};
