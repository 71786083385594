import { DashboardWidget } from "components/Widgets/DashboardWidget";
import VolumeChart from "components/Charts/VolumeChart";
import { withReportFilterQuery } from "components/HOCs/ReportWithFilters";
import { getPrepareFilters, DEFAULT_REQUEST_FIELDS } from "util/reportUtils/videoReportUtils";

const VideosSubmitted = ({ data }) => (
  <DashboardWidget title="Video Submission Volume">
    <VolumeChart data={data} />
  </DashboardWidget>
);

export default withReportFilterQuery({
  url: "/inviteVideoReport/submissions",
  prepareFilters: getPrepareFilters([...DEFAULT_REQUEST_FIELDS, "compareMode"])
})(VideosSubmitted);
