import React from "react";
import { Link } from "react-router-dom";
import map from "lodash/map";
import { TBody, TD, THead, TR, TH, Table } from "../../components/Table";
import { bySource } from "../../data/sites";

const FailedPostsTable = ({ failedPosts }) => {
  return (
    <Table>
      <THead>
        <TR>
          <TH scope="col">Location</TH>
          <TH scope="col">Platform</TH>
        </TR>
      </THead>
      <TBody>
        {map(failedPosts, post => (
          <TR>
            <TD>
              <Link to={`/reviews/list/edit/${post.id}`}>{post.customer.companyName}</Link>
            </TD>
            <TD>
              <Link to={`/reviews/list/edit/${post.id}`}>{bySource(post.source).label}</Link>
            </TD>
          </TR>
        ))}
      </TBody>
    </Table>
  );
};

export default FailedPostsTable;
