import React, { Component } from "react";
import { Row, Col, Table, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { beginAjaxCall, endAjaxCall, ajaxCallError } from "../../redux/actions/ajaxStatusActions";
import * as api from "api/importLogApi";
import Pager from "components/Paging/Pager";
import withAuthorization from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import { WWTabs, WWTabContent, WWTabContentItem } from "components/WWTabs";

class InboundList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      pending: [],
      page: 0,
      content: null,
      activeTab: 1
    };

    this.refresh = this.refresh.bind(this);
    this.load = this.load.bind(this);
    this.toggle = this.toggle.bind(this);
    this.delete = this.delete.bind(this);
    this.requeue = this.requeue.bind(this);
  }

  refresh(list) {
    this.props.dispatch(beginAjaxCall());
    return api
      .listInbound(list, 30, this.state.page)
      .then(res => {
        this.setState({ [list]: res.data });
        this.props.dispatch(endAjaxCall());
      })
      .catch(() => this.props.dispatch(ajaxCallError()));
  }

  load(bucket, key, raw) {
    this.props.dispatch(beginAjaxCall());
    api
      .fetchInbound(bucket, key, raw)
      .then(res => {
        this.setState({ bucket, key, content: res.data, raw });
        this.props.dispatch(endAjaxCall());
      })
      .catch(() => this.props.dispatch(ajaxCallError()));
  }

  delete() {
    api.deleteInbound(this.state.bucket, this.state.key).then(() => {
      this.setState({ content: null, key: null, bucket: null });
    });
  }

  requeue() {
    api.requeue(this.state.bucket, this.state.key).then(() => {
      this.setState({ content: null, key: null, bucket: null });
    });
  }

  componentDidMount() {
    this.toggle("pending");
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.refresh(tab).then(() => {
        this.setState({
          activeTab: tab
        });
      });
    }
  }

  render() {
    const tabsOptions = [
      {
        label: "Pending",
        active: this.state.activeTab === "pending",
        onClick: () => this.toggle("pending")
      },
      {
        label: "Failed",
        active: this.state.activeTab === "failed",
        onClick: () => this.toggle("failed")
      },
      {
        label: "Processed",
        active: this.state.activeTab === "processed",
        onClick: () => this.toggle("processed")
      }
    ];
    return (
      <div className="px-4 pt-4">
        <WWTabs tabs={tabsOptions} />
        <WWTabContent activeTab={this.state.activeTab}>
          <WWTabContentItem tabId="pending">
            <Row>
              <Col>
                <Button onClick={() => api.queueImport()}>Queue Import</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <InboundTable items={this.state.pending} onLoad={this.load} />
                <Pager
                  pageInfo={this.state.pageInfo}
                  onPageChange={page => {
                    // eslint-disable-next-line
                    this.state.page = page;
                    this.refresh("pending");
                  }}
                />
              </Col>
            </Row>
          </WWTabContentItem>
          <WWTabContentItem tabId="failed">
            <Row>
              <Col>
                <InboundTable items={this.state.failed} onLoad={this.load} />
                <Pager
                  pageInfo={this.state.pageInfo}
                  onPageChange={page => {
                    // eslint-disable-next-line
                    this.state.page = page;
                    this.refresh("failed");
                  }}
                />
              </Col>
            </Row>
          </WWTabContentItem>
          <WWTabContentItem tabId="processed">
            <Row>
              <Col>
                <InboundTable items={this.state.processed} onLoad={this.load} />
                <Pager
                  pageInfo={this.state.pageInfo}
                  onPageChange={page => {
                    // eslint-disable-next-line
                    this.state.page = page;
                    this.refresh("processed");
                  }}
                />
              </Col>
            </Row>
          </WWTabContentItem>
        </WWTabContent>
        <Modal fade={false} isOpen={this.state.content} size="lg">
          <ModalHeader>
            <Button onClick={() => this.setState({ content: null })}>Close</Button>
          </ModalHeader>
          <ModalBody>
            {this.state.content ? (
              this.state.raw ? (
                <pre>{this.state.content}</pre>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
              )
            ) : (
              <br />
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ content: null })}>Close</Button>
            <Button color="danger" onClick={() => this.delete()}>
              Delete
            </Button>
            <Button color="warning" onClick={() => this.requeue()}>
              Re-queue
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const InboundTable = ({ items, onLoad }) => {
  return (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Date</th>
          <th>Key</th>
          <th>Source</th>
        </tr>
      </thead>
      <tbody>
        {items &&
          items.map((item, i) => (
            <tr key={i}>
              <td xs="sm">{new Date(item.lastModified).toLocaleString()}</td>
              <td>
                <Button color="link" onClick={() => onLoad(item.bucketName, item.key, false)}>
                  {item.key}
                </Button>
                <Button color="link" className="small" onClick={() => onLoad(item.bucketName, item.key, true)}>
                  {" (raw)"}
                </Button>
              </td>
              <td>Yelp</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

function mapStateToProps(state, ownProps) {
  return {};
}

export default withAuthorization(permissions.WIDEWAIL_ADMIN)(connect(mapStateToProps)(InboundList));
