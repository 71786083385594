import React from "react";
import size from "lodash/size";
import map from "lodash/map";

import { useSelector, useDispatch } from "react-redux";
import { clearMessages } from "../../redux/actions/appActions";
import { Toast, ToastBody, ToastHeader, Button } from "reactstrap";

const TYPE_ICONS = {
  error: "danger",
  warning: "warning",
  info: "info",
  success: "success"
};

const AppMessages = () => {
  const messages = useSelector(state => state.app.messages);
  const dispatch = useDispatch();

  if (size(messages) < 1) return <React.Fragment />;

  return (
    <div style={{ position: "absolute", top: 60, right: 10, zIndex: 99 }}>
      {map(messages, (m, i) => (
        <Toast isOpen={true} key={i}>
          <ToastHeader icon={TYPE_ICONS[m.type]}>{m.title}</ToastHeader>
          <ToastBody>{m.message}</ToastBody>
        </Toast>
      ))}
      <Toast isOpen={true}>
        <ToastBody className="text-center">
          <Button color="primary" onClick={() => dispatch(clearMessages())}>
            Clear All
          </Button>
        </ToastBody>
      </Toast>
    </div>
  );
};

export default AppMessages;
