import React from "react";
import { noop } from "lodash";
import { components } from "react-select";
import SelectField from "components/Form/SelectField";

const selectFieldStyles = {
  dropdownIndicator: () => ({ display: "none" }),
  indicatorsContainer: () => ({ display: "none" }),
  menu: () => ({ display: "none" }),
  input: () => ({ display: "none" })
};

function BadgeList({ value = [], onChange = noop, onRemove = noop, MultiValueClassNameRender = noop, name, ...props }) {
  return (
    <SelectField
      name={name}
      placeholder=""
      isClearable={false}
      isMulti
      value={value}
      onChange={onChange}
      components={{
        MultiValue
      }}
      onRemove={onRemove}
      MultiValueClassNameRender={MultiValueClassNameRender}
      styles={selectFieldStyles}
      {...props}
    />
  );
}

const MultiValue = props => {
  const { onRemove, MultiValueClassNameRender } = props.selectProps;
  const removeProps = {
    ...props.removeProps,
    onClick: event => {
      onRemove(props.data);
      event.stopPropagation();
    }
  };

  return (
    <components.MultiValue className={MultiValueClassNameRender(props.data)} {...props} removeProps={removeProps} />
  );
};

export default React.memo(BadgeList);
