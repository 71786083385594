import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { loadPagedReplies } from "../../../api/reviewApi";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../../redux/actions/ajaxStatusActions";

export default () => {
  const dispatch = useDispatch();
  return useCallback((id, page = 0, size = 300) => {
    dispatch(beginAjaxCall());
    return loadPagedReplies(id, page, size)
      .then(({ data }) => {
        dispatch(endAjaxCall());
        return data._embedded?.reviewReplies || [];
      })
      .catch(e => {
        dispatch(ajaxCallError());
        return Promise.reject(e);
      });
  });
};
