import React, { useState, useCallback } from "react";
import styles from "./WWTextDetails.module.scss";

const { containerClass, captionClass, textClass, togglerClass } = styles;

const WWTextDetails = ({ children, caption = "", openOnInit = false }) => {
  const [isOpen, setIsOpen] = useState(openOnInit);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
  const togglerIcon = isOpen ? "fa-minus-circle" : "fa-plus-circle";
  return (
    <div className={containerClass}>
      <div className={captionClass} onClick={toggle}>
        <i className={`fa ${togglerIcon} ${togglerClass}`} />
        {caption}
      </div>
      {isOpen && <div className={textClass}>{children}</div>}
    </div>
  );
};

export default WWTextDetails;
