import map from "lodash/map";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPendingApprovals } from "api/reportApi";
import { Table, TBody, TD, TR } from "components/Table";
import { DashboardWidget } from "components/Widgets/DashboardWidget";
import { shouldRedirectToReviewFeed } from "util/userUtils";
import { getApprovalSearch } from "util/getApprovalSearch";

export default () => {
  const redirectOptedInUser = shouldRedirectToReviewFeed();
  const approvePath = redirectOptedInUser ? "/reviews/feed" : "/approve";

  const [pendingApprovals, setPendingApprovals] = useState([]);
  const loadPendingApprovals = usePendingApprovalsLoader();

  useEffect(() => {
    loadPendingApprovals().then(a => setPendingApprovals(a));
  }, [loadPendingApprovals]);

  return (
    <DashboardWidget title="Approval Reviews / Customer">
      <Table responsive>
        <TBody>
          {map(pendingApprovals, (item, i) => {
            const approveSearch = redirectOptedInUser ? getApprovalSearch() : `contactId=${item.id}`;
            return (
              <TR key={i}>
                <TD>
                  <strong>
                    <Link to={`/customers/${item.id}`}>{item.companyName}</Link>{" "}
                    <i
                      className="fa fa-external-link link"
                      onClick={() => window.open(`${approvePath}?${approveSearch}`, "_blank")}
                    />
                  </strong>
                </TD>
                <TD>{item.total}</TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </DashboardWidget>
  );
};

const usePendingApprovalsLoader = () => useCallback(getPendingApprovals, []);
