import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import StatusMessages from "../../../components/Misc/StatusMessages";
import config from "../../../config";

class Page500 extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <h1 className="display-3 me-4">Oops</h1>
                <p>
                  <StatusMessages
                    errors={[
                      {
                        details: this.props.error,
                        message: "Error"
                      }
                    ]}
                  />
                </p>
                <p>
                  Refresh this page or{" "}
                  <Button
                    className="m-0 p-0"
                    color="link"
                    onClick={() => {
                      localStorage.clear();
                      window.open(config.cognito.redirectUri, "_self");
                    }}
                  >
                    click here
                  </Button>
                  {" to log out and try again."}
                </p>
              </span>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6"></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page500;
