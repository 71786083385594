import { isFunction, noop } from "lodash";
import React, { useEffect } from "react";
import useMeasure from "react-use-measure";
import usePureMemo from "../../../hooks/usePureMemo/usePureMemo";

export default ({ children, onResize = noop, ...props }) => {
  const [ref, { width, height }] = useMeasure({ scroll: false });
  useEffect(() => {
    onResize({ width, height });
  }, [width, height]);
  const kid = usePureMemo((w, h, c) => (isFunction(c) ? c(w, h) : c), width, height, children);

  return (
    <div ref={ref} {...props}>
      {kid}
    </div>
  );
};
