// uncomment this line if you have something to import from your scss module
import { not } from "ramda";
import { useStateThroughRedux } from "../../../../hooks/stateHooks";
import styles from "./Navigation.module.scss";
import { useContext } from "react";
import { ContainerContext } from "../NavigableModalContainer/NavigableModalContainer";

const { container, active, dimmer } = styles;

export default function Navigation({ children, display = true }) {
  const context = useContext(ContainerContext);
  const [navigationOpened, setNavigationOpened] = useStateThroughRedux(`${context.reduxRoot}/navopened`, false);
  return (
    <>
      <div className={`${container} ${navigationOpened ? active : ""} bg-light py-3 ${!display ? "d-none" : ""}`}>
        {children}
      </div>
      <div className={dimmer} onClick={() => setNavigationOpened(not)} />
    </>
  );
}
