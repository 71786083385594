import FormField from "components/Form/FormField";
import React, { useCallback, useState } from "react";
import { Input, InputGroup } from "reactstrap";
import WWButton from "../Buttons/WWButton";
import { isFunction } from "lodash";

const PasswordInput = ({
  label = "Password",
  onChange,
  password,
  newPassword = true,
  name,
  lastpass = true,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const onPasswordChange = isFunction(onChange) ? v => onChange(v.target.value) : undefined;
  const onShowPasswordToggle = useCallback(() => setShowPassword(prev => !prev), [setShowPassword]);

  return (
    <FormField inline={false} label={label}>
      <InputGroup>
        <Input
          md={12}
          id={newPassword ? "New Password" : "Password"}
          name={name}
          type={showPassword ? "text" : "password"}
          placeholder={label}
          value={password}
          onChange={onPasswordChange}
          data-lpignore={!lastpass}
          {...props}
        />
        <WWButton
          className={showPassword ? "bg-warning border-0" : "bg-grey border-0"}
          iconClass={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
          onClick={onShowPasswordToggle}
        />
      </InputGroup>
    </FormField>
  );
};

export default PasswordInput;
