import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import ToggleAccordion from "components/WWAccordion/ToggleAccordion/ToggleAccordion";
import { get, isEmpty, isEqual, join, set, trim, isUndefined, values, isBoolean, omit, identity, uniqBy } from "lodash";
import qs from "querystring";
import { stringify as qsStringify } from "qs";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, CardBody, Col, Collapse, Form, FormFeedback, Row } from "reactstrap";
import * as agencyApi from "../../api/agencyApi";
import { searchContacts } from "../../api/crmApi";
import * as api from "../../api/customerApi";
import * as emailApi from "../../api/emailApi";
import withAuthorization, { AuthorizationRequiredToRender } from "../../components/Auth/Authorization";
import { AgencyAdminFragment } from "../../components/Auth/AuthorizedComponents";
import { permissions } from "../../components/Auth/permissions";
import { Card, CardHeader, CardHeaderAction, CardHeaderActions, CardTitle } from "../../components/Card";
import signature from "../../components/Contacts/ContactSignature";
import CustomerFilter from "../../components/Customer/CustomerFilter";
import CheckboxField from "../../components/Form/CheckboxField";
import FormField from "../../components/Form/FormField";
import HorizontalSelectField from "../../components/Form/HorizontalSelectField";
import InputField from "../../components/Form/InputField";
import ListField from "../../components/Form/ListField";
import PhoneNumberField from "../../components/Form/PhoneNumberField";
import SaveBar from "../../components/Form/SaveBar";
import ToggleSwitch from "../../components/Form/Switch";
import withConfirmation from "../../components/Form/withConfirmation";
import { ModalsContext, autoclose } from "../../components/Modals";
import { errorCaughtNotifier, withLocalNotifications } from "../../components/Notifications/notification";
import SearchDialog from "../../components/SearchResults/SearchDialog";
import { TD } from "../../components/Table";
import { WWTabs } from "../../components/WWTabs";
import Time from "../../components/DateTime/Time";
import {
  PREFERRED_CONTACT_METHOD,
  DEFAULT_TAGS,
  CONTACT_STATUS,
  SIGNATORY_TAG,
  AUTORESPONSE_TAG
} from "../../data/options";
import { CONTACT_TYPE_WIDEWAIL_USER } from "util/constants";
import { currentContact, shouldRedirectToReviewFeed } from "util/userUtils";
import { getApprovalSearch } from "util/getApprovalSearch";
import GroupMembershipButton from "./GroupMembershipButton";
import Conditional from "components/Misc/Conditional/Conditional";
import Input from "components/Form/Input";
import TextEditor from "components/Form/TextEditor";
import ListingsNotificationsSettingsForm from "../Settings/prefabs/Account/Notifications/ListingsNotificationsSettingsForm";
import DigestNotificationsSettingsForm from "../Settings/prefabs/Account/Notifications/DigestNotificationsSettings";
import { openLinkIntoTheNewTab } from "util/navUtils";
import { signaturePlaceholders } from "data/editorPlaceholders";
import { ContactMethods } from "data/options";
import { useCatagoriesByContactIdWithFallbacks } from "hooks/data/tagSetHooks";
import { useQueryClient } from "react-query";
import useContact, {
  contactsKeys,
  useAddContactToAgency,
  useContactActiveProducts,
  useContactEmailIsSuppressed,
  useContactMobileIsSuppressed,
  useDeleteContactEmailSuppression,
  useDeleteContactMobileSuppression,
  useLinkCustomerContact,
  useRemoveAllCustomersFromContact,
  useRemoveContactFromAgency,
  useRemoveCustomerContact,
  useSaveContact,
  useUpdateContactManagedBy
} from "../../hooks/data/useContact";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";

import StatusMessages from "components/Misc/StatusMessages";
import { AccountTypeaheadField } from "components/Account/AccountSelector";

const ButtonWConfirm = withConfirmation(Button);

const LocationManager = withAuthorization([permissions.LOCATION_MANAGE])(React.Fragment);
const ContactManageBtn = withAuthorization([permissions.CONTACT_MANAGE])(ButtonWConfirm);
const AgencyCreate = withAuthorization([permissions.WIDEWAIL_ADMIN])(React.Fragment);

const defaultContactValues = {
  notificationPreferences: {
    receiveSmsNotifications: true,
    receiveSpamNotifications: true,
    receiveDailyPerformanceDigest: false,
    receiveWeeklyPerformanceDigest: false,
    receiveMonthlyPerformanceDigest: true,
    receiveMonthlySiteRatingReport: false,
    receiveMonthlySentimentReport: false,
    receiveVideoNotifications: true,
    receiveOutstandingApprovalsSummary: false,
    receiveAchievementNotifications: true
  },
  responderConfig: { isAgencyResponder: false }
};

const viewApprovals = contact => {
  const redirectOptedInUser = shouldRedirectToReviewFeed();
  const approvePath = redirectOptedInUser ? "/reviews/feed" : "/approve";
  const approveSearch = redirectOptedInUser
    ? getApprovalSearch({ location: { id: contact.id, type: "CONTACT" } })
    : `contactId=${contact.id}`;
  openLinkIntoTheNewTab(`${approvePath}?${approveSearch}`);
};

const viewReport = contact => {
  const { id, name } = contact;
  openLinkIntoTheNewTab(`/report/group?${qsStringify({ contact: { id, name } })}`);
};

/////////////
const feddieFields = contact => get(contact, "identityProvider.providedFields", []),
  isFeddie = contact => !isEmpty(get(contact, "identityProvider")),
  canEdit = contact => field => !isFeddie || !feddieFields(contact).includes(field);

////////////

const EditContact = props => {
  const queryClient = useQueryClient();
  const contactIdParam = props.match.params.id || null;
  const query = qs.parse(props.location.search.substr(1));
  const type = query.type || CONTACT_TYPE_WIDEWAIL_USER;
  const [dirty, setDirty] = useState(false);
  const [validFields, setValidFields] = useState({
    signatureLine: true
  });
  const [contact, setContact] = useState({
    type,
    activeProducts: [],
    tags: DEFAULT_TAGS.join(","),
    receiveShares: false,
    preferredContactMethod: props.currentAgency?.defaultContactMethod,
    signatureLine: props.currentAgency?.defaultSignatureLine || "",
    status: "ACTIVE",
    ...defaultContactValues
  });

  const contactQuery = useContact(
    contactIdParam,
    {},
    {
      enabled: !!contactIdParam && contactIdParam !== "new",
      onError: error => {
        if (error.response.status === 404) {
          props.notify({
            icon: "danger",
            body: "Contact not found. Please check your selected agency."
          });
        } else {
          errorCaughtNotifier(props.notify, "Failed loading contact");
        }
      }
    },
    identity
  );

  const isDisabled = f => !canEdit(contact)(f);

  const resetContactFromQuery = () => {
    if (contactQuery.data) {
      const newContact = { ...contactQuery.data, accounts: contactQuery.data._embedded.accounts || [] };
      !("responderConfig" in newContact) && (newContact.responderConfig = { isAgencyResponder: false });
      setContact(omit(newContact, "_embedded"));
    }
  };

  useEffect(() => {
    if (!dirty) {
      resetContactFromQuery();
    }
  }, [contactQuery.data, dirty]);

  const activeProductsQuery = useContactActiveProducts(contactQuery.data?.id);
  const activeProducts = activeProductsQuery?.data;

  const redirect = props.history.goBack;

  const linkCustomerMutate = useLinkCustomerContact(contact);

  const linkCustomer = (customerId, contactId) => {
    linkCustomerMutate.mutate({ customerId, contactId });
  };

  const saveContactMutation = useSaveContact((res, variables) => {
    setDirty(false);
    setContact({
      ...res
    });
    if (query.customerId) {
      linkCustomer(query.customerId, res.id);
    }
    queryClient.invalidateQueries(contactsKeys.detail(res.id));
    variables.shouldClose ? redirect() : props.history.push(`/customers/contacts/${res.id}`);
  });

  //shouldClose is a boolean parameter passed down by SaveBar
  const onSubmit = shouldClose => {
    if (
      contact.preferredContactMethod === ContactMethods.CUSTOM &&
      (contact.signatureLine === null || trim(contact.signatureLine).length === 0)
    ) {
      props.notify({
        icon: "danger",
        body: "Custom Signature cannot be blank if the preferred response contact method is CUSTOM"
      });
    } else {
      const fieldsToOmit = ["signaturePreview"];
      if (contact.preferredContactMethod !== ContactMethods.CUSTOM) {
        fieldsToOmit.push("signatureLine");
      }
      return saveContactMutation.mutate({ contact: omit(contact, fieldsToOmit), shouldClose });
    }
  };

  const deleteContact = () => {
    api.deleteContact(contact.id);
    redirect();
  };

  const cancel = () => {
    setDirty(false);
    resetContactFromQuery();
    redirect();
  };

  const updateContact = event => {
    const field = event.target.name;
    let newContact = Object.assign({}, contact);
    if (field === "tags" || field === "audience") {
      set(newContact, field, event.target.value.join(","));
    } else {
      set(newContact, field, field === "email" ? trim(event.target.value) : event.target.value);
    }
    if (contact[field] !== newContact[field] || field === "_embedded.accounts") {
      setDirty(true);
      setContact(newContact);
    }
  };

  const updateResponderConfig = event => {
    const field = event.target.name;
    const responderConfig = {
      ...contact.responderConfig,
      [field]: event.target.value
    };
    const newContact = {
      ...contact,
      responderConfig
    };
    if (!isEqual(contact.responderConfig !== newContact.responderConfig)) {
      setContact(newContact);
      setDirty(true);
    }
  };

  const updateNotifPrefs = (event, field) => {
    if (isUndefined(field)) {
      field = event.target.name;
    }
    const notificationPreferences = set(
      contact.notificationPreferences,
      field,
      isBoolean(event) ? event : event.target?.checked
    );
    const newContact = {
      ...contact,
      notificationPreferences
    };
    if (!isEqual(contact["notificationPreferences"] !== newContact["notificationPreferences"])) {
      setContact(newContact);
      setDirty(true);
    }
  };

  const resetPassword = () => {
    api
      .resetPassword(contact.id)
      .then(() => {
        props.notify({
          body: "Email with new password sent."
        });
      })
      .catch(errorCaughtNotifier(props.notify));
  };

  const populateFromCrm = record => {
    const contact = Object.assign({}, contact, {
      name: join(
        [record.firstname, record.lastname].filter(s => s),
        " "
      ),
      title: record.jobtitle,
      phone: record.phone,
      mobile: record.mobilephone,
      email: trim(record.email),
      hubspotId: record.id,
      type: CONTACT_TYPE_WIDEWAIL_USER
    });
    setContact(contact);
    setDirty(true);
  };

  const onSignatureChange = text =>
    updateContact({
      target: {
        name: "signatureLine",
        value: text
      }
    });

  const onSignatureValidChange = valid => setValidFields(prev => ({ ...prev, signatureLine: valid }));

  const widewailContact = contact?.type === CONTACT_TYPE_WIDEWAIL_USER;
  let currentUser = currentContact();

  const contactId = contact?.id;
  const pathname = props.location.pathname;
  const isNotifPrefsActive = pathname.endsWith("/notificationPreferences");
  const tabsOptions = [
    {
      label: "User Information",
      active: !isNotifPrefsActive,
      onClick: () => props.history.push(`/customers/contacts/${contactId}`)
    },
    {
      label: "Notifications",
      active: isNotifPrefsActive,
      onClick: () => props.history.push(`/customers/contacts/${contactId || "me"}/notificationPreferences`),
      hide: !contact?.email
    }
  ];

  const isCustomMethodChosen = contact?.preferredContactMethod === ContactMethods.CUSTOM;
  const newUserWithNoLocationMustHaveAccount = contactIdParam === "new" && !query.customerId && isEmpty(contact.accounts);

  return (
    <div>
      <AuthorizationRequiredToRender roles={[permissions.REVIEW_APPROVE, permissions.REPORT_READ]}>
        <WWTabs className="bg-white pt-2" tabs={tabsOptions} />
      </AuthorizationRequiredToRender>
      <div className="px-4 pt-4">
        {dirty && (
          <SaveBar
            confirmationPrompt={
              (!contact.email && "Save contact without an email? This contact will not be able to log in.") ||
              (contact.status === "INACTIVE" && props.match.params.id !== "new" && "Save contact as Inactive?")
            }
            onSave={onSubmit}
            onCancel={cancel}
            disableSave={newUserWithNoLocationMustHaveAccount || Object.values(validFields).some(field => !field)}
          />
        )}
        {!isNotifPrefsActive ? (
          <Row>
            {isFeddie(contact) && (
              <StatusMessages warnings="This contact is authenticated through a third party identity provider. Some options may not be available." />
            )}
            <Col>
              <Card>
                <CardHeader className="card--header__nav-wrap">
                  <CardTitle>Edit Contact</CardTitle>
                  <CardHeaderActions className="card--header__nav">
                    {props.currentAgency.name === "Widewail" && contact?.id === undefined && (
                      <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                        <ModalsContext.Consumer>
                          {raiseModal => (
                            <CardHeaderAction
                              color="primary"
                              onClick={() =>
                                raiseModal(resolve => (
                                  <SearchDialog
                                    close={resolve}
                                    title="Search Hubspot Contacts"
                                    allowEmptyTerm={true}
                                    onSearch={term => {
                                      if (term || query.customerId) {
                                        return searchContacts({
                                          q: term,
                                          customerId: query.customerId
                                        });
                                      } else {
                                        return [];
                                      }
                                    }}
                                    onPick={populateFromCrm}
                                    renderRow={record => (
                                      <TD>
                                        <strong>
                                          {isEmpty(record.firstname) || isEmpty(record.lastname)
                                            ? trim(record.email)
                                            : `${record.firstname} ${record.lastname}`}
                                        </strong>
                                        <br />
                                        <span className="text-muted">{record.jobtitle}</span>
                                      </TD>
                                    )}
                                  />
                                )).then(([close]) => close())
                              }
                            >
                              Import from Hubspot
                            </CardHeaderAction>
                          )}
                        </ModalsContext.Consumer>
                      </AuthorizationRequiredToRender>
                    )}
                    {contact?.id && (
                      <>
                        <AuthorizationRequiredToRender roles={[permissions.CONTACT_MANAGE]}>
                          <ButtonWConfirm
                            color="warning"
                            onClick={() => resetPassword()}
                            disabled={isFeddie(contact) || isDisabled("password")}
                          >
                            Reset Password
                          </ButtonWConfirm>
                        </AuthorizationRequiredToRender>
                      </>
                    )}
                  </CardHeaderActions>
                </CardHeader>
                <CardBody>
                  <div className="card-nav">
                    {contact?.id && (
                      <>
                        <AuthorizationRequiredToRender roles={[permissions.REPORT_READ]}>
                          <CardHeaderAction onClick={() => viewReport(contact)}>View Report</CardHeaderAction>
                        </AuthorizationRequiredToRender>
                        <AuthorizationRequiredToRender roles={[permissions.REVIEW_APPROVE]}>
                          <CardHeaderAction onClick={() => viewApprovals(contact)}>View Approvals</CardHeaderAction>
                        </AuthorizationRequiredToRender>
                        <Conditional test={!!contact?.email}>
                          <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                            <GroupMembershipButton contact={contact} readOnly={isDisabled("groups")} />
                          </AuthorizationRequiredToRender>
                        </Conditional>
                      </>
                    )}
                  </div>
                  <ContactErrorMessages contact={contact} accountCondition={newUserWithNoLocationMustHaveAccount} />
                  <Form className="form-horizontal">
                    <InputField
                      name="name"
                      label="Name"
                      onChange={updateContact}
                      value={contact?.name}
                      inline={false}
                      disabled={isDisabled("name")}
                    />
                    <InputField
                      name="pronouns"
                      label="Pronouns"
                      onChange={updateContact}
                      value={contact?.pronouns}
                      placeholder="eg. she/her, he/him, they/them"
                      inline={false}
                    />
                    {widewailContact && (
                      <Fragment>
                        <InputField
                          name="title"
                          label="Title"
                          onChange={updateContact}
                          value={contact?.title}
                          inline={false}
                          disabled={isDisabled("title")}
                        />
                        <InputField
                          name="phone"
                          label="Phone"
                          onChange={updateContact}
                          value={contact?.phone}
                          inline={false}
                          disabled={isDisabled("phone")}
                        />
                      </Fragment>
                    )}
                    <ContactMobileInput contact={contact} onChange={updateContact} disabled={isDisabled("mobile")} />
                    <ContactEmailInput contact={contact} onChange={updateContact} disabled={isDisabled("email")} />
                    <HorizontalSelectField
                      name="preferredContactMethod"
                      value={contact?.preferredContactMethod}
                      label="Preferred Response Contact Method"
                      simpleValue={true}
                      options={PREFERRED_CONTACT_METHOD}
                      onChange={updateContact}
                      inline={false}
                      isDisabled={isDisabled("contactMethod")}
                    />
                    <Collapse
                      isOpen={
                        !isCustomMethodChosen || (isUndefined(contact.signaturePreview) && !!contact.signatureLine)
                      }
                    >
                      <FormField inline={false} label="Signature Preview">
                        {signature(contact, isCustomMethodChosen)}
                      </FormField>
                    </Collapse>
                    <Collapse isOpen={isCustomMethodChosen}>
                      <FormField inline={false} label="Custom Signature">
                        <TextEditor
                          type="input"
                          disabled={isDisabled("signatureLine")}
                          value={contact?.signatureLine}
                          onChange={onSignatureChange}
                          onValidChange={onSignatureValidChange}
                          placeholders={signaturePlaceholders}
                          withEmoji={false}
                        />
                      </FormField>
                    </Collapse>
                    <ContactTagSelector
                      contact={contact}
                      value={contact?.tags && contact.tags?.length > 0 ? contact?.tags.split(",") : []}
                      onChange={updateContact}
                      customerId={query?.customerId}
                      isDisabled={isDisabled("tags")}
                    />
                    {activeProducts?.includes("SHARE") ? (
                      <CheckboxField
                        name="receiveShares"
                        label="Receive SharePlus Posts"
                        onChange={updateContact}
                        value={contact?.receiveShares}
                        inline={false}
                      />
                    ) : null}
                    <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                      <CheckboxField
                        name="isAgencyResponder"
                        label="Agency Responder"
                        onChange={updateResponderConfig}
                        value={contact?.responderConfig?.isAgencyResponder}
                        inline={false}
                      />
                    </AuthorizationRequiredToRender>
                    <Collapse isOpen={contact?.receiveShares && activeProducts?.includes("SHARE")}>
                      <HorizontalSelectField
                        name="audience"
                        value={contact.audience ? contact.audience.split(",") : []}
                        label="Share Plus Audience"
                        isMulti={true}
                        creatable={true}
                        simpleValue={true}
                        onChange={updateContact}
                        inline={false}
                      />
                    </Collapse>
                    {contact?.id !== currentUser.id && (
                      <HorizontalSelectField
                        name="status"
                        value={contact?.status}
                        label="Status"
                        simpleValue={true}
                        options={CONTACT_STATUS}
                        onChange={updateContact}
                        inline={false}
                      />
                    )}
                    <AgencyAdminFragment>
                      <InputField
                        type="textarea"
                        name="notes"
                        label="Notes"
                        onChange={updateContact}
                        style={{ height: "100px" }}
                        value={contact?.notes}
                        inline={false}
                      />
                      <InputField
                        name="apiKey"
                        label="API Key"
                        onChange={updateContact}
                        value={contact?.apiKey}
                        inline={false}
                      />
                    </AgencyAdminFragment>
                    <AgencyAdminFragment>
                      <FormField inline={false} label="Last Seen">
                        {!!contact?.lastSeenOn ? (
                          <Time withIcon={false} format="dateSlash" date={contact?.lastSeenOn} />
                        ) : (
                          "N/A"
                        )}
                      </FormField>
                    </AgencyAdminFragment>
                  </Form>
                  <Row>
                    {contact?.id && currentUser.id !== contact?.id && (
                      <ContactManageBtn
                        className="btn-center"
                        color="danger"
                        label="Delete Contact"
                        onClick={deleteContact}
                      >
                        Delete
                      </ContactManageBtn>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <LocationManager>
                {!query.customerId && (
                  <Row>
                    <Col>
                      <ContactAccounts contact={contact} contactIdParam={contactIdParam} onChange={updateContact} />
                    </Col>
                  </Row>
                )}
              </LocationManager>
              <LocationManager>
                {contact?.id && (
                  <Row>
                    <Col>
                      <ContactCustomers contact={contact} readOnly={isDisabled("customers")} />
                    </Col>
                  </Row>
                )}
              </LocationManager>
              <AgencyCreate>
                {contact?.id && (
                  <Row>
                    <Col>
                      <ContactAgencies contact={contact} />
                    </Col>
                  </Row>
                )}
              </AgencyCreate>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col xs={12} md={7}>
                <AuthorizationRequiredToRender roles={[permissions.REVIEW_APPROVE]}>
                  <Card>
                    <CardHeader>
                      <CardTitle>Review Response Notifications</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form className="form-horizontal">
                        <ToggleSwitch
                          label="Receive SMS Notifications"
                          labelWidth={6}
                          inputWidth={6}
                          onLabel=""
                          offLabel=""
                          name="receiveSmsNotifications"
                          onChange={updateNotifPrefs}
                          checked={contact?.notificationPreferences.receiveSmsNotifications}
                          value="contact.notificationPreferences.receiveSmsNotifications"
                        />
                        <ToggleSwitch
                          label="Receive Spam Notifications"
                          labelWidth={6}
                          inputWidth={6}
                          onLabel=""
                          offLabel=""
                          name="receiveSpamNotifications"
                          onChange={updateNotifPrefs}
                          checked={contact?.notificationPreferences.receiveSpamNotifications}
                          value="contact.notificationPreferences.receiveSpamNotifications"
                        />
                        <ToggleAccordion
                          onChange={value => {
                            ["oneStar", "twoStar", "threeStar", "fourStar", "fiveStar"].map(star =>
                              updateNotifPrefs(value, `receiveNewReviewNotifications.${star}`)
                            );
                          }}
                          isOpen={
                            !values(contact?.notificationPreferences.receiveNewReviewNotifications).every(
                              value => value === false
                            )
                          }
                          label={"Receive New Review Notifications"}
                          name="receiveNewReviewNotifications"
                        >
                          <Labeled
                            checked={contact?.notificationPreferences.receiveNewReviewNotifications?.oneStar}
                            onToggle={event => updateNotifPrefs(event, "receiveNewReviewNotifications.oneStar")}
                            name="receiveWeeklyPerformanceDigest.oneStar"
                            label={"One Star"}
                          />
                          <Labeled
                            checked={contact?.notificationPreferences.receiveNewReviewNotifications?.twoStar}
                            onToggle={event => updateNotifPrefs(event, "receiveNewReviewNotifications.twoStar")}
                            name="receiveNewReviewNotifications.twoStar"
                            label={"Two Star"}
                          />
                          <Labeled
                            checked={contact?.notificationPreferences.receiveNewReviewNotifications?.threeStar}
                            onToggle={event => updateNotifPrefs(event, "receiveNewReviewNotifications.threeStar")}
                            name="receiveNewReviewNotifications.threeStar"
                            label={"Three Star"}
                          />
                          <Labeled
                            checked={contact?.notificationPreferences.receiveNewReviewNotifications?.fourStar}
                            onToggle={event => updateNotifPrefs(event, "receiveNewReviewNotifications.fourStar")}
                            name="receiveNewReviewNotifications.fourStar"
                            label={"Four Star"}
                          />
                          <Labeled
                            checked={contact?.notificationPreferences.receiveNewReviewNotifications?.fiveStar}
                            onToggle={event => updateNotifPrefs(event, "receiveNewReviewNotifications.fiveStar")}
                            name="receiveNewReviewNotifications.fiveStar"
                            label={"Five Star"}
                          />
                        </ToggleAccordion>
                      </Form>
                    </CardBody>
                  </Card>
                </AuthorizationRequiredToRender>
                {widewailContact && (
                  <AuthorizationRequiredToRender roles={[permissions.REPORT_READ]}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Digest Notifications</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <DigestNotificationsSettingsForm contact={contact} onChange={updateNotifPrefs} />
                      </CardBody>
                    </Card>
                  </AuthorizationRequiredToRender>
                )}
                {activeProducts?.includes("LISTINGS_MANAGEMENT") && (
                  <AuthorizationRequiredToRender roles={[permissions.LISTINGS_MANAGE]}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Listings Notifications</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <ListingsNotificationsSettingsForm contact={contact} onChange={updateNotifPrefs} />
                      </CardBody>
                    </Card>
                  </AuthorizationRequiredToRender>
                )}
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export const ContactTagSelector = ({ contact, value, onChange, customerId, ...props }) => {
  const [catagories] = useCatagoriesByContactIdWithFallbacks(contact?.id, customerId);
  const options = [...catagories, SIGNATORY_TAG, AUTORESPONSE_TAG];

  return (
    <HorizontalSelectField
      {...props}
      name="tags"
      value={value}
      label="Tags"
      isMulti={true}
      creatable={false}
      simpleValue={true}
      options={options}
      onChange={onChange}
      inline={false}
      help={
        <>
          <p>Tagging contacts sets which reviews they are responsible for managing.</p>
          <p>
            Special Tags
            <ul>
              <li>Signatory - Contact information can be used in responses</li>
              <li>Autoresponse - Contact information can be used in negative, no comment, reviews.</li>
            </ul>
          </p>
        </>
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    currentAgency: state.currentAgency
  };
}

const withQueryClient = Component => props => {
  const queryClient = useQueryClient();
  return <Component {...props} queryClient={queryClient} />;
};

const ContactEmailInput = ({ contact, onChange, disabled }) => {
  const isEmailSuppressedQuery = useContactEmailIsSuppressed(contact?.id);
  const isEmailSuppressed = isEmailSuppressedQuery.data;
  const deleteSuppressionMutation = useDeleteContactEmailSuppression(contact?.id);
  const deleteSuppression = v => deleteSuppressionMutation.mutate(v);

  return (
    <FormField inline={false} name="email" label="Email">
      <Input
        name="email"
        disabled={!isEmpty(contact?.id)}
        onChange={onChange}
        value={contact?.email || ""}
        invalid={isEmailSuppressed}
      />
      <FormFeedback>
        The provided email has previously opted out from communication with Widewail.
        <AgencyAdminFragment>
          <Button color="link" className="float-end" onClick={deleteSuppression}>
            Remove opt-out
          </Button>
        </AgencyAdminFragment>
      </FormFeedback>
      <AgencyAdminFragment>
        {!contact?.emailDisabled && !isEmailSuppressed && (
          <Button
            color="link"
            className="float-end"
            onClick={() =>
              emailApi.sendEmailTest(contact?.id).then(() =>
                this.props.notify({
                  body: "Test message sent"
                })
              )
            }
          >
            Send test message
          </Button>
        )}
      </AgencyAdminFragment>
    </FormField>
  );
};

const ContactMobileInput = ({ contact, onChange, ...props }) => {
  const isMobileSuppressedQuery = useContactMobileIsSuppressed(contact?.id);
  const isMobileSuppressed = isMobileSuppressedQuery.data;
  const deleteSuppressionMutation = useDeleteContactMobileSuppression(contact?.id);
  const deleteSuppression = v => deleteSuppressionMutation.mutate(v);
  return (
    <FormField inline={false} name="mobile" label="Mobile Phone">
      <PhoneNumberField
        {...props}
        style={{ width: "100%" }}
        onValueChange={values =>
          onChange({
            target: { value: values.value, name: "mobile" }
          })
        }
        value={contact?.mobile}
        placeholder="Mobile phone"
        invalid={isMobileSuppressed}
      />
      <FormFeedback>
        The provided mobile number has previously opted out from communication with Widewail.
        <AgencyAdminFragment>
          <Button color="link" className="float-end" onClick={deleteSuppression}>
            Remove opt-out
          </Button>
        </AgencyAdminFragment>
      </FormFeedback>
      <AgencyAdminFragment>
        {!isMobileSuppressed && (
          <Button
            color="link"
            className="float-end"
            onClick={() =>
              emailApi.sendSmsTest(contact?.id).then(() =>
                this.props.notify({
                  body: "Test message sent"
                })
              )
            }
          >
            Send test message
          </Button>
        )}
      </AgencyAdminFragment>
    </FormField>
  );
};

const ContactAccounts = ({ contact, contactIdParam, onChange }) => {
  const accounts = contact?.accounts || [];

  const addAccount = account => {
    if (account.target.value !== undefined) {
      onChange({
        target: {
          name: "accounts",
          value: uniqBy([...accounts, account.target.value], "id")
        }
      });
    }
  };

  const removeAccount = account => {
    onChange({
      target: {
        name: "accounts",
        value: accounts.filter(a => a.id !== account.id)
      }
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Accounts</CardTitle>
      </CardHeader>
      <CardBody>
        <ListField
          placeholder="Search Accounts..."
          members={accounts}
          onRemove={removeAccount}
          getOptionLabel={({ name }) => name}
          getMemberLabel={({ name }) => name}
          getMemberValue={({ id }) => id}
        >
          <AccountTypeaheadField name="account" onChange={addAccount} selectOnlyOption={contactIdParam === "new"} />
        </ListField>
      </CardBody>
    </Card>
  );
};

const ContactCustomers = ({ contact, readOnly }) => {
  const label = readOnly ? "Associated Customers" : "Add a customer";

  const contactQuery = useContact(
    contact.id,
    {},
    {
      enabled: !!contact.id && contact.id !== "new"
    },
    identity
  );
  const confirm = useConfirmationDialog();

  const customers = get(contactQuery.data, "_embedded.customers", []);
  const managedBy = contactQuery.data?.managedBy;

  const linkCustomerMutate = useLinkCustomerContact(contact);
  const removeCustomerMutate = useRemoveCustomerContact(contact);
  const removeAllCustomersMutate = useRemoveAllCustomersFromContact(contact);

  const linkCustomer = customer => {
    linkCustomerMutate.mutate({ customerId: customer.id, contactId: contact.id });
  };

  const removeCustomer = customer => {
    removeCustomerMutate.mutate(customer.id);
  };

  const removeAllCustomersFromContact = () => {
    autoclose(
      confirm({
        title: "Are you sure you want to remove all customers from this contact?"
      })
    ).then(confirm => confirm && removeAllCustomersMutate.mutate());
  };

  const updateManagedByMutate = useUpdateContactManagedBy(contact);

  const updateContactManagedBy = customer => {
    updateManagedByMutate.mutate(customer?.id);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Customers</CardTitle>
      </CardHeader>
      <CardBody>
        <AgencyAdminFragment>
          <FormField name="managedBy" label="Managed By">
            <CustomerFilter
              disabled={readOnly}
              name="managedBy"
              loading={updateManagedByMutate.isLoading || contactQuery.isLoading}
              value={[get(managedBy, "id", undefined)]}
              onChange={event => {
                const value = event.target.value[0];
                updateContactManagedBy(value);
              }}
            />
          </FormField>
        </AgencyAdminFragment>
        <FormField name="addCustomer" label={label}>
          <ListField
            readOnly={readOnly}
            placeholder="Search Customers..."
            members={customers}
            onAdd={linkCustomer}
            onRemove={removeCustomer}
            onRemoveAll={removeAllCustomersFromContact}
            cacheOptions={false}
            loadOptions={(inputValue, callback) => {
              api.loadCustomerNames(inputValue).then(res => {
                let current = customers.map(i => i.id);
                let dataCustomers = get(res.data, "_embedded.customers", []).filter(i => current.indexOf(i.id) < 0);
                callback(dataCustomers);
              });
            }}
            getOptionLabel={({ companyName }) => companyName}
            getMemberLabel={({ id, companyName }) => <Link to={`/customers/${id}`}>{companyName}</Link>}
            getMemberValue={({ id }) => id}
          />
        </FormField>
      </CardBody>
    </Card>
  );
};

const ContactAgencies = ({ contact }) => {
  const contactQuery = useContact(
    contact.id,
    {},
    {
      enabled: !!contact.id && contact.id !== "new"
    },
    identity
  );
  const agencies = get(contactQuery.data, "_embedded.agencies", []);
  const addContactToAgencyMutate = useAddContactToAgency(contact.id);

  const linkAgency = agency => {
    addContactToAgencyMutate.mutate(agency.id);
  };

  const removeContactFromAgencyMutate = useRemoveContactFromAgency(contact.id);
  const removeAgency = agency => {
    removeContactFromAgencyMutate.mutate(agency.id);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Agencies</CardTitle>
      </CardHeader>
      <CardBody>
        <ListField
          placeholder="Search Agencies..."
          members={agencies}
          onAdd={linkAgency}
          onRemove={removeAgency}
          loadOptions={(inputValue, callback) => {
            agencyApi.loadAgencies(inputValue).then(res => {
              callback(res.data._embedded.agencies);
            });
          }}
          getOptionLabel={({ name }) => name}
          getMemberLabel={({ name }) => name}
          getMemberValue={({ id }) => id}
        />
      </CardBody>
    </Card>
  );
};

const ContactErrorMessages = ({ contact, accountCondition }) => {
  const formErrors = [
    { field: "name", value: "Name is a required field" },
    { field: "title", value: "Title is a required field" },
    { field: "phone", value: "Phone is a required field" },
    { field: "accounts", value: "An Account is required" }
  ]
    .filter(({ field }) => {
      if (field === "accounts") {
        return accountCondition;
      } else {
        return !contact[field];
      }
    })
    .map(({ value }) => value);
  return <>{formErrors.length > 0 && <StatusMessages errors={formErrors} />}</>;
};

export default withRouter(withQueryClient(withLocalNotifications(connect(mapStateToProps)(EditContact))));
