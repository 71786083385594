import React from "react";
import ToggleSwitch from "components/Form/Switch";
import { Form } from "reactstrap";

const DigestNotificationsSettingsForm = ({ contact, onChange }) => (
  <Form className="form-horizontal">
    <ToggleSwitch
      label="Receive Widewail Daily Digest"
      labelWidth={6}
      inputWidth={6}
      onLabel=""
      offLabel=""
      name="receiveDailyPerformanceDigest"
      onChange={onChange}
      checked={contact?.notificationPreferences.receiveDailyPerformanceDigest}
      value="contact.notificationPreferences
                          .receiveDailyPerformanceDigest"
    />
    <ToggleSwitch
      label="Receive Widewail Weekly Digest"
      labelWidth={6}
      inputWidth={6}
      onLabel=""
      offLabel=""
      name="receiveWeeklyPerformanceDigest"
      onChange={onChange}
      checked={contact?.notificationPreferences.receiveWeeklyPerformanceDigest}
      value="contact.notificationPreferences
                          .receiveWeeklyPerformanceDigest"
    />
    <ToggleSwitch
      label="Receive Widewail Monthly Activity Report"
      labelWidth={6}
      inputWidth={6}
      onLabel=""
      offLabel=""
      name="receiveMonthlyPerformanceDigest"
      onChange={onChange}
      checked={contact?.notificationPreferences.receiveMonthlyPerformanceDigest}
      value="contact.notificationPreferences
                          .receiveMonthlyPerformanceDigest"
    />
    <ToggleSwitch
      label="Receive Widewail Monthly Site Rating Report"
      labelWidth={6}
      inputWidth={6}
      onLabel=""
      offLabel=""
      name="receiveMonthlySiteRatingReport"
      onChange={onChange}
      checked={contact?.notificationPreferences.receiveMonthlySiteRatingReport}
      value="contact.notificationPreferences.receiveMonthlySiteRatingReport"
    />
    <ToggleSwitch
      label="Receive Monthly Sentiment Report"
      labelWidth={6}
      inputWidth={6}
      onLabel=""
      offLabel=""
      name="receiveMonthlySentimentReport"
      onChange={onChange}
      checked={contact?.notificationPreferences.receiveMonthlySentimentReport}
      value="contact.notificationPreferences.receiveMonthlySentimentReport"
    />
    <ToggleSwitch
      label="Receive Video Notifications"
      labelWidth={6}
      inputWidth={6}
      onLabel=""
      offLabel=""
      name="receiveVideoNotifications"
      onChange={onChange}
      checked={contact?.notificationPreferences.receiveVideoNotifications}
      value="contact.notificationPreferences.receiveVideoNotifications"
    />
    <ToggleSwitch
      label="Receive Outstanding Approvals Summary"
      labelWidth={6}
      inputWidth={6}
      onLabel=""
      offLabel=""
      name="receiveOutstandingApprovalsSummary"
      onChange={onChange}
      checked={contact?.notificationPreferences.receiveOutstandingApprovalsSummary}
      value="contact.notificationPreferences.receiveOutstandingApprovalsSummary"
    />
    <ToggleSwitch
      label="Receive Daily Review Digest"
      labelWidth={6}
      inputWidth={6}
      onLabel=""
      offLabel=""
      name="receiveReviewDigest"
      onChange={onChange}
      checked={contact?.notificationPreferences.receiveReviewDigest}
      value="contact.notificationPreferences.receiveReviewDigest"
    />
    <ToggleSwitch
      label="Receive Achievement Notifications"
      labelWidth={6}
      inputWidth={6}
      onLabel=""
      offLabel=""
      name="receiveAchievementNotifications"
      onChange={onChange}
      checked={contact?.notificationPreferences.receiveAchievementNotifications}
      value="contact.notificationPreferences.receiveAchievementNotifications"
    />
  </Form>
);

export default React.memo(DigestNotificationsSettingsForm);
