import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions/authStatusActions";
import { Auth } from "aws-amplify";
import InputField from "../Form/InputField";
import { Button, Form } from "reactstrap";
import PasswordStrength from "./PasswordStrength";
import AjaxLoader, { AjaxLoaderSizes } from "../Misc/AjaxLoader";
import logger from "../../util/logger";

const CodeValidationNewPassword = ({ resetEmail = "" }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(resetEmail);
  const [valid, setValid] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const forgotPasswordSubmit = useCallback(
    event => {
      event.preventDefault();
      setLoading(true);
      Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword)
        .then(() =>
          Auth.signIn(email.toLowerCase(), newPassword).then(user =>
            dispatch(loginUser(user.signInUserSession, "signedin"))
          )
        )
        .catch(error => {
          setValid(false);
          if (error.code === "InvalidParameterException") {
            setErrorMessage("Could not reset your password. Make sure your email, code and password are all correct.");
          } else {
            setErrorMessage(error.message);
          }
          logger.error(error.message);
          setLoading(false);
        });
    },
    [email, code, newPassword]
  );

  const onEmailChange = useCallback(v => setEmail(v.target.value), [setEmail]);
  const onCodeChange = useCallback(v => setCode(v.target.value), [setCode]);

  return (
    <div>
      <h4 className="text-dark">Reset Password</h4>
      <Form className="form-horizontal">
        <InputField
          autocomplete="email"
          dataLpIgnore="false"
          name="email"
          label="Email"
          inline={false}
          type="email"
          value={email}
          onChange={onEmailChange}
        />
        <InputField name="code" label="Code" inline={false} value={code} onChange={onCodeChange} />
        <PasswordStrength password={newPassword} error={valid} errorMessage={errorMessage} onChange={setNewPassword} />
        <div>
          <Button
            disabled={loading}
            type="submit"
            className="btn btn-warning col-12 py-3 fw-bold"
            onClick={forgotPasswordSubmit}
          >
            {loading ? <AjaxLoader size={AjaxLoaderSizes.SM} /> : "Set new password"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CodeValidationNewPassword;
