import React, { useState } from "react";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import PropTypes from "prop-types";
import { Alert, Button } from "reactstrap";
import Paragraph from "../Layout/Paragraph";

/**
 * Display status message or error messages.
 * Messages can either be a:
 *    - string
 *    - array of strings
 *    - array of objects of the shape {message: [string], details [string|object]}
 * @param {*} param0
 */
const StatusMessages = ({ messages = [], errors = [], warnings = [] }) => {
  return (
    <div className="w-100">
      <AlertComponent color="danger" messages={errors} />
      <AlertComponent color="dark" messages={messages} />
      <AlertComponent color="warning" messages={warnings} />
    </div>
  );
};

export const Messages = ({ messages }) => {
  if (!isArray(messages)) messages = [messages];

  if (messages && messages.length > 0) {
    return (
      <ul className={`m-0 ${messages.length > 1 ? "" : "list-unstyled"}`}>
        {messages.map((m, idx) => (
          <li key={idx}>
            <Display msg={m} id={idx} />
          </li>
        ))}
      </ul>
    );
  }
};

const Display = ({ msg, id }) => {
  const [details, setDetails] = useState({});

  if (isString(msg)) return <span>{msg}</span>;
  if (isObject(msg)) {
    return (
      <span>
        {`${msg.message} `}
        {details[id] ? (
          <>
            <Paragraph
              className="m-0 font-monospace"
              content={isString(msg.details) ? msg.details : JSON.stringify(msg.details)}
            />
            <Button
              color="link"
              className="p-0"
              onClick={() => {
                const update = Object.assign({}, details);
                delete update[id];
                setDetails(update);
              }}
            >
              hide
            </Button>
          </>
        ) : (
          <Button color="link" className="p-0" onClick={() => setDetails(Object.assign({}, details, { [id]: true }))}>
            details...
          </Button>
        )}
      </span>
    );
  }
};

const AlertComponent = ({ color, messages }) => {
  if (!isArray(messages)) messages = [messages];

  if (messages && messages.length > 0) {
    return (
      <Alert color={color}>
        <Messages messages={messages} />
      </Alert>
    );
  }
  return <></>;
};

StatusMessages.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  warnings: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object])
};

export default StatusMessages;
