import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import _ from "lodash";
import { updateYelpLink } from "api/customerApi";
import { bySource, SITE_SOURCE_NAMES, SOURCES } from "data/sites";
import CopyButton from "components/Buttons/CopyButton";
import Block from "components/Layout/Block";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import { YELP_EMAIL } from "constants/emails";
import StatusMessages from "components/Misc/StatusMessages";
import WWButton from "components/Buttons/WWButton";
import InputField from "components/Form/InputField";
import { useAddAndRemoveSite } from "hooks/customerHooks";

function encodeUrlParameter(str) {
  return encodeURIComponent(str).replace(/%20/g, "+");
}

const urlValidatorRegex = /^https:\/\/www.yelp.com\/.+/;
const site = bySource(SITE_SOURCE_NAMES.yelp);
const source = SOURCES[SITE_SOURCE_NAMES.yelp];
const siteName = site.label;
const siteInfoField = site.customerField;
const searchUrl = "https://www.yelp.com/search?find_desc=";

function YelpLinking({ customer, widewailAccount = YELP_EMAIL, showInstructions = true }) {
  const [errors, setErrors] = useState([]);
  const [siteInfos, setSiteInfos] = useState([]);
  const [siteUrlValid, setSiteUrlValid] = useState();
  const [enableUpdate, setEnableUpdate] = useState(false);

  useEffect(() => {
    setSiteInfos(customer[siteInfoField]);
  }, [customer]);

  const setAddress = useCallback(
    (idx, address) => {
      const infos = [...siteInfos];
      infos[idx].address = address;
      infos[idx].source = source;
      setSiteInfos(infos);
      setEnableUpdate(true);
    },
    [siteInfos]
  );

  const setDefaultReviewTag = useCallback(
    (idx, tag) => {
      const infos = [...siteInfos];
      infos[idx].source = source;
      infos[idx].defaultReviewTag = tag;
      setSiteInfos(infos);
      setEnableUpdate(true);
    },
    [siteInfos]
  );

  const siteLinkingParams = {
    customerId: customer.id,
    field: siteInfoField
  };
  const [addSite, removeSite] = useAddAndRemoveSite(siteLinkingParams);

  const updateYelp = useCallback(
    (siteId, payLoad) =>
      updateYelpLink(customer.id, siteId, payLoad).catch(err => setErrors([`Failed updating ${siteName}`])),
    [customer]
  );

  return (
    <Container>
      <Row>
        <Col>
          <StatusMessages errors={errors} />
        </Col>
      </Row>
      {showInstructions && (
        <Row>
          <Col>
            <p>
              To manage your Yelp reviews Widewail must be added as a manager on your account. Please do the following:
            </p>
            <ol>
              <li>
                Log in to Yelp at{" "}
                <a
                  alt="Yelp business manager"
                  href="https://biz.yelp.com/settings"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://biz.yelp.com/settings
                </a>
              </li>
              <li>
                Go to <strong>Account Settings</strong>
              </li>
              <li>
                Click <strong>User Management</strong> in the left navigation menu
                <br />
                <small>
                  If this option is not available, you will need to complete the form on Yelp&apos;s{" "}
                  <a
                    alt="share access request"
                    href="https://www.yelp.com/support/contact/business_share"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    share access request
                  </a>{" "}
                  page to invite a new user by selecting Share Access to this business with another person
                </small>
              </li>
              <li>
                Enter <strong>Reputation Management</strong> as the Job Title
              </li>
              <li>
                Provide the email address{" "}
                <strong>
                  <CopyButton>{widewailAccount}</CopyButton>
                </strong>
              </li>
              <li>Select the business location(s) for which you would like to grant access.</li>
              <li>
                Click <strong>Send Invite</strong>
              </li>
            </ol>
            <p>Once the above steps are complete enter in the URL to your business&apos; Yelp page below.</p>
          </Col>
        </Row>
      )}
      {_.map(
        siteInfos,
        (siteInfo, idx) =>
          siteInfo.id && (
            <React.Fragment key={idx}>
              <Block>
                <i
                  className="me-1 fa fa-close"
                  onClick={() => {
                    removeSite(siteInfo.id)
                      .then(res => {
                        setSiteInfos(res.data);
                      })
                      .catch(() => setErrors([`Failed removing ${siteName}`]));
                  }}
                />
                {_.startsWith(siteInfo.url, "http") ? (
                  <a className="text-truncate" href={siteInfo.url} target="_blank" rel="noreferrer noopener">
                    {siteInfo.url}
                    <i className="ms-1 fa fa-external-link" />
                  </a>
                ) : (
                  <span>{siteInfo.url}</span>
                )}
              </Block>
              <Block>
                <InputField
                  name="addressOverride"
                  placeholder="Address override"
                  tip="Use this field if the address of the yelp listing is different from that in Widewail."
                  value={siteInfo.address}
                  onChange={evt => setAddress(idx, evt.target.value)}
                />
              </Block>
              <Row>
                <Col>
                  <HorizontalSelectField
                    name="defaultReviewTag"
                    value={siteInfo.defaultReviewTag}
                    label="Default Review Tag"
                    simpleValue={true}
                    options={customer.reviewTagSet.tags}
                    onChange={evt => setDefaultReviewTag(idx, evt.target.value)}
                    isClearable={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <WWButton
                    color="primary"
                    disabled={!enableUpdate}
                    onClick={() =>
                      updateYelp(siteInfo.id, {
                        defaultReviewTag: siteInfo.defaultReviewTag,
                        address: siteInfo.address
                      })
                    }
                  >
                    Update
                  </WWButton>
                </Col>
              </Row>
            </React.Fragment>
          )
      )}
      {_.size(siteInfos) === 0 && (
        <div>
          <Row>
            <Col>
              <p>
                Click on the link below to search for your dealership&apos;s {siteName} pages. Copy &amp; paste that URL
                below.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputField
                inline={false}
                tip="Ctrl+C to copy and Ctrl+V to paste"
                name="accountUrl"
                placeholder={`${siteName} URL...`}
                valid={siteUrlValid}
                label={
                  <span>
                    Copy and paste the{" "}
                    <a
                      href={`${searchUrl}${encodeUrlParameter(customer.companyName)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {siteName} URL
                    </a>{" "}
                    for {customer.companyName} below.
                  </span>
                }
                onChange={evt => {
                  const url = evt.target.value;

                  if (_.isEmpty(url)) {
                    setSiteUrlValid(undefined);
                  } else {
                    let match = url.match(urlValidatorRegex);
                    if (!match && _.trim(url).length > 0) {
                      setSiteUrlValid(false);
                    } else {
                      setSiteUrlValid(true);
                      addSite({
                        source,
                        url
                      })
                        .then(res => {
                          setSiteInfos(res.data);
                          setErrors([]);
                        })
                        .catch(() => {
                          setErrors([`Failed adding ${siteName}`]);
                        });
                    }
                  }
                }}
              />
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
}

YelpLinking.propTypes = {
  customer: PropTypes.object.isRequired,
  widewailAccount: PropTypes.string
};

export default YelpLinking;
