import React, { useCallback, useContext } from "react";
import classnames from "classnames";
import { FormContext } from "components/Form/Form";

const RemoveFieldControl = ({ onClick, forField, className }) => {
  const context = useContext(FormContext);
  const clickHandler = useCallback(
    e => {
      onClick && onClick(e);
      context?.removeField(forField);
    },
    [onClick, context]
  );
  return (
    <i className={classnames("icon-close", className)} onClick={clickHandler} data-testid="remove-field-control" />
  );
};

export default RemoveFieldControl;
