import React, { useState } from "react";
import { Modal } from "reactstrap";
import { ModalsContext, useModalsContextValue } from "../components/Modals";

export default ({ children }) => {
  const [modals, setModals] = useState([]);
  const rise = useModalsContextValue(setModals);

  return (
    <ModalsContext.Provider value={rise}>
      {modals.map(v => (
        <Modal
          key={v.id}
          isOpen={v.open}
          fade={true}
          backdrop={true}
          centered={true}
          onClosed={() => setModals(prev => prev.filter(({ id }) => id !== v.id))}
        >
          {v.factory()}
        </Modal>
      ))}
      {children}
    </ModalsContext.Provider>
  );
};
