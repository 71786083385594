import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateReply } from "../../../api/reviewApi";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../../redux/actions/ajaxStatusActions";

export default () => {
  const dispatch = useDispatch();
  return useCallback(patch => {
    dispatch(beginAjaxCall());
    return updateReply(patch)
      .then(({ data }) => {
        dispatch(endAjaxCall());
        return data;
      })
      .catch(e => {
        dispatch(ajaxCallError());
        return Promise.reject(e);
      });
  });
};
