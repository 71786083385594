import axios from "axios";
import config from "../config";

export function listProperties({ clientId, clientSecret, apiUrl, sharedCredentials, filter }) {
  return axios.post(config.api + "/entrata/properties", {
    clientId,
    clientSecret,
    apiUrl,
    sharedCredentials,
    filter
  });
}
