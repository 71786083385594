import React, { useCallback, useMemo, useState } from "react";
import { findIndex, isEmpty, size } from "lodash";
import classnames from "classnames";
import { UncontrolledTooltip } from "reactstrap";
import * as messageApi from "api/messageApi";
import WWButton from "components/Buttons/WWButton";
import Message from "./Message";
import { filterMessageHasContent } from "util/messageFilters";

import styles from "./MessageThread.module.scss";
import AjaxLoader from "components/Misc/AjaxLoader";

const { messageThreadClass, showAllButtonClass, showAllButtonContentClass } = styles;

const COLLAPSED_MESSAGES_LENGTH = 2;

const transportIcons = {
  EmailTransport: "fa-envelope-o",
  SMSTransport: "fa-mobile fa-lg"
};

function failedRecipients(msg) {
  return msg.recipients ? msg.recipients?.filter(r => r.result === "FAILED") : [];
}

function cancelable(msg) {
  //has recipients and all of those recipients are in QUEUED status
  return size(msg.recipients) > 0 && findIndex(msg.recipients, r => r.result !== "QUEUED") < 0;
}

const MessageThread = ({
  messages,
  onRemove,
  toggleAllActivity,
  standalone,
  className,
  showRecipients = false,
  showBlank = false
}) => {
  const [expanded, setExpanded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const filteredMessages = useMemo(() => (showBlank ? messages : filterMessageHasContent(messages)), [
    messages,
    showBlank
  ]);

  const onPressShowAll = useCallback(() => (toggleAllActivity ? toggleAllActivity() : setExpanded(!expanded)), [
    toggleAllActivity,
    setExpanded
  ]);

  if (!filteredMessages?.length) {
    return null;
  }

  return (
    <div className={classnames(messageThreadClass, className)}>
      {filteredMessages
        .slice(0, expanded || standalone ? filteredMessages.length : COLLAPSED_MESSAGES_LENGTH)
        .map(m => (
          <Message
            key={m.id}
            message={m}
            renderActions={() =>
              showRecipients ? (
                <div className="message-actions d-flex ps-2">
                  {!isEmpty(failedRecipients(m)) && <i style={{ paddingLeft: "5px" }} className="fa fa-warning" />}
                  <i id={`${m.transport}-${m.id}`} className={classnames("ps-1 fa", transportIcons[m.transport])} />
                  <UncontrolledTooltip target={`${m.transport}-${m.id}`}>
                    {m.recipients?.map((r, i) => <MessageRecipient key={i} recipient={r} />) || null}
                  </UncontrolledTooltip>
                  {cancelable(m) && (
                    <>
                      <UncontrolledTooltip target={`cancel-${m.id}`}>Cancel message</UncontrolledTooltip>
                      <i
                        id={`cancel-${m.id}`}
                        disabled={processing}
                        className={classnames("ps-1 text-danger fa", { "fa-remove": !processing })}
                        onClick={() => {
                          setProcessing(true);
                          messageApi
                            .cancelMessage(m.id)
                            .then(() => onRemove && onRemove(m.id))
                            .finally(() => {
                              setProcessing(false);
                            });
                        }}
                      />
                      {processing && <AjaxLoader size={4} />}
                    </>
                  )}
                </div>
              ) : null
            }
          />
        ))}
      {!expanded && !standalone && filteredMessages.length > COLLAPSED_MESSAGES_LENGTH && (
        <div className="d-flex justify-content-left mt-2">
          <WWButton
            color="link"
            className={showAllButtonClass}
            contentClass={showAllButtonContentClass}
            iconClass="fa fa-expand"
            onClick={onPressShowAll}
          >
            Show All Activity
          </WWButton>
        </div>
      )}
      {standalone && <div />}
    </div>
  );
};

const MessageRecipient = ({ recipient }) => {
  return <div>{recipient.name + " - " + recipient.result}</div>;
};

export default MessageThread;
