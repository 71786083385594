import React from "react";
import WWStackedChartBar from "../WWStackedChartBar/WWStackedChartBar";

import styles from "./TagsChartTooltip.module.scss";

const { tagsChartTooltipClass, valueContainerClass, sentimentsContainerClass, valueClass, nameClass } = styles;

const TOTAL_TAGS_PAYLOAD_INDEX = 0;
const AVG_RATING_PAYLOAD_INDEX = 1;

const DEFAULT_SENTIMENTS = {
  positiveSentimentCount: 0,
  negativeSentimentCount: 0,
  neutralSentimentCount: 0,
  unknownSentimentCount: 0
};

const getCaptionFromMeta = (meta, tagType) =>
  !!meta?.length
    ? meta.length > 3
      ? `${meta[0].value}, ${meta[1].value} and ${meta.length - 2} more ${tagType === "TOPIC" ? "topics" : "people"}.`
      : meta.map(({ value }) => value).join(", ")
    : "";

const TagsChartTooltip = ({ payload, tagType }) => {
  const meta = payload[TOTAL_TAGS_PAYLOAD_INDEX]?.payload?.meta;
  const sentiments = meta?.[0].sentiments || DEFAULT_SENTIMENTS;
  return (
    <div className={tagsChartTooltipClass}>
      <div className="mb-1">{getCaptionFromMeta(meta, tagType)}</div>
      <div className="d-flex mb-2">
        <div className={valueContainerClass}>
          <div className={valueClass}>{payload[TOTAL_TAGS_PAYLOAD_INDEX]?.value}</div>
          <div className={nameClass}>{payload[TOTAL_TAGS_PAYLOAD_INDEX]?.name}</div>
        </div>
        <div className={valueContainerClass}>
          <div className={valueClass}>{payload[AVG_RATING_PAYLOAD_INDEX]?.value || null}</div>
          <div className={nameClass}>{payload[AVG_RATING_PAYLOAD_INDEX]?.name}</div>
        </div>
      </div>
      {meta?.length === 1 && (
        <div className={sentimentsContainerClass}>
          Sentiment Analysis
          <div className="mt-2">
            <WWStackedChartBar
              values={[
                { name: "Positive", count: sentiments.positiveSentimentCount },
                { name: "Neutral", count: sentiments.neutralSentimentCount },
                { name: "Negative", count: sentiments.negativeSentimentCount },
                { name: "Unknown", count: sentiments.unknownSentimentCount }
              ]}
            />
          </div>
        </div>
      )}
      <div className="text-muted fs-2 fst-italic">Click dot to hide data point</div>
    </div>
  );
};

export default React.memo(TagsChartTooltip);
