import * as apiClient from "./apiClient";
import axios from "axios";

const baseUri = "linkedIn";

const proxyBaseUri = "linkedInProxy";

const linkedInUri = "https://api.linkedin.com/v2/me";

export function createAccessToken(authCode) {
  return apiClient.postEmpty(proxyBaseUri + "/accesstoken", {
    params: { authCode: authCode }
  });
}

export function me() {
  return apiClient.get(proxyBaseUri + "/me");
}

export function pages(contactId = "me") {
  return apiClient.get(proxyBaseUri + "/pages/" + contactId);
}
