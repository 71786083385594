import React from "react";
import { Toast, ToastBody } from "reactstrap";
import style from "./BottomBanner.module.scss";

export default function BottomBanner({ children, ...props }) {
  return (
    <div className={style.container}>
      <Toast className={style.toast}>
        <ToastBody className={style.toastBody + " d-flex justify-content-center align-items-center flex-wrap"}>
          {children}
        </ToastBody>
      </Toast>
    </div>
  );
}
