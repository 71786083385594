import axios from "axios";
import config from "../config";
import { toYMD } from "../util/dateUtils";
import isEmpty from "lodash/isEmpty";
import { loadReviews } from "./reviewApi";
import { property } from "lodash";
import moment from "moment";
import { rejectNilParams } from "./apiClient";

export function loadSummaryReport({ customerId, contactId, startDate, endDate, include, brand, engagePlus = false }) {
  return axios.get(config.api + "/report", {
    headers: { Accept: "application/json" },
    params: {
      startDate: moment(startDate).toISOString(true),
      endDate: moment(endDate).toISOString(true),
      customerId,
      contactId,
      include,
      brand,
      engagePlus
    }
  });
}

export function loadReviewThreads(customerId, contactId, startDate, endDate, threshold, tag, download = false) {
  const params = {
    customerId,
    startDate: toYMD(startDate),
    endDate: toYMD(endDate),
    threshold,
    contactId
  };
  if (!isEmpty(tag)) {
    params.tag = tag;
  }
  return axios.get(config.api + "/report/reviewThreads", {
    headers: { Accept: download ? "text/plain" : "application/json" },
    params
  });
}

export function exportReviewListCSV(filter) {
  return axios.get(config.api + "/report/reviews", {
    params: {
      partnerId: "widewail",
      ...filter
    }
  });
}

export function exportEnterpriseCSV(params) {
  return axios.post(config.api + "/report/group/export", {}, { params });
}

export function exportCustomers(filter = null, startDate, endDate) {
  return axios.get(config.api + "/report/customers", {
    params: {
      startDate,
      endDate,
      ...filter
    }
  });
}

export function exportBillingCustomers(filter = null, startDate, endDate) {
  return axios.get(config.api + "/report/billing", {
    params: {
      startDate,
      endDate,
      ...filter
    }
  });
}

export function exportContacts(filter = {}) {
  return axios.get(config.api + "/report/contacts", {
    params: {
      ...filter
    }
  });
}

export function exportTagsReport(params, type = "PERSON") {
  return axios.get(`${config.api}/report/reviewEntities`, {
    params: {
      ...rejectNilParams(params),
      type
    }
  });
}

export function exportGroupReport(params) {
  return axios.get(`${config.api}/report/export`, {
    params
  });
}

export function exportLifetimeTrend(params) {
  return axios.get(`${config.api}/dash/getSiteTrendReport/export`, {
    params
  });
}

export function exportGmbPerformanceReport(params) {
  return axios.get(`${config.api}/report/gmb-performance/by-site-info/export`, {
    params
  });
}

/**
 * @param {"HOURLY" | "DAILY" | "MONTHLY"} frequency
 * @param {string} startDate "YYYY-MM-DD"
 * @param {string} endDate "YYYY-MM-DD"
 * @returns {Promise<{
 *   buckets: string[];
 *   datasets: Array<{
 *     id: string;
 *     total: number;
 *     pctChange: number;
 *     trends: {
 *       stat: number[];
 *       comparison: number[];
 *     }
 *   }>
 * }>}
 */
export const getReviewVolumeReportSummary = (frequency, startDate, endDate, customerId, compareTo, campaignNames) => {
  return axios
    .get(config.api + "/graph/volume", {
      params: {
        frequency,
        startDate,
        endDate,
        customerId,
        campaignNames,
        compareTo
      }
    })
    .then(({ data }) => data);
};

/**
 * @param {"HOURLY" | "DAILY" | "MONTHLY"} frequency
 * @param {string} startDate "YYYY-MM-DD"
 * @param {string} endDate "YYYY-MM-DD"
 * @returns {import("axios").AxiosPromise<{
 *   buckets: string[];
 *   datasets: Array<{
 *     id: string;
 *     total: number;
 *     pctChange: number;
 *     trends: {
 *       stat: number[];
 *       comparison: number[];
 *     }
 *   }>
 * }>}
 */
export const getReviewRatingReportSummary = (frequency, startDate, endDate, customerId, compareTo) => {
  return axios
    .get(config.api + "/graph/rating", {
      params: {
        frequency,
        startDate,
        endDate,
        customerId,
        compareTo
      }
    })
    .then(({ data }) => data);
};

export const getGMBPerformanceSummary = (customerId, timeSpan, frequency, siteId, compareTo, timeZone) => {
  return axios
    .get(config.api + `/graph/gmb-performance`, {
      params: {
        customerId,
        timeSpan,
        frequency,
        siteId,
        compareTo,
        timeZone
      }
    })
    .then(({ data }) => data);
};

/**
 * @param {number} count
 */
export const getRecentApprovals = count => {
  return loadReviews(
    {
      status: "APPROVAL"
    },
    0,
    count,
    "reviewDate,desc"
  );
};

/**
 * @param {string} startDate "YYYY-MM-DD"
 * @param {string} endDate "YYYY-MM-DD"
 */
export const getInvitationsSummary = (startDate, endDate, customerId, compareTo) => {
  return axios
    .get(config.api + `/dash/invitations`, {
      params: {
        startDate,
        endDate,
        customerId,
        compareTo
      }
    })
    .then(({ data }) => data);
};

export const getLifetimeAverage = customerId => {
  return axios.get(config.api + `/dash/lifeTimeAverage/${customerId}`).then(({ data }) => data);
};

export const getDashboardSettings = () => {
  return axios.get(config.api + `/dash/settings`).then(({ data }) => data);
};

export const getReviewStatusCount = () => {
  return axios.get(config.api + `/dash/reviewStatusCount`).then(({ data }) => data);
};

export const getReviewHourly = () => {
  return axios.get(config.api + `/dash/reviewHourly`).then(({ data }) => data);
};

export const getPublishedHourly = () => {
  return axios.get(config.api + `/dash/publishedHourly`).then(({ data }) => data);
};

export const getPendingApprovals = () => {
  return axios.get(config.api + `/dash/pendingApprovals`).then(({ data }) => data);
};

export const getActivatedCustomers = () => {
  return axios.get(config.api + `/dash/activatedCustomers`).then(({ data }) => data);
};

export const getImportedReviews = () => {
  return axios.get(config.api + `/dash/importedReviews`).then(({ data }) => data);
};

export const getImportFailures = () => {
  return axios.get(config.api + `/dash/importFailures`).then(({ data }) => data);
};

export const getReplyHourly = () => {
  return axios.get(config.api + `/dash/replyHourly`).then(({ data }) => data);
};

export const getAuthoredReplies = timeZone => {
  return axios
    .get(config.api + `/dash/publishedReplies`, {
      params: {
        timeZone: timeZone
      }
    })
    .then(({ data }) => data);
};

export const getAgencyPublishedReplies = (startDate, endDate) => {
  return axios
    .get(config.api + `/dash/agencyPublishedReplies`, {
      params: {
        startDate: startDate,
        endDate: endDate
      }
    })
    .then(({ data }) => data);
};

export const getCustomerReviewsVolumeData = params =>
  axios
    .get(`${config.api}/report/volume`, {
      params: rejectNilParams(params)
    })
    .then(property("data"));

export const getSubTagsData = params =>
  axios
    .get(`${config.api}/report/subtags`, {
      params: rejectNilParams(params)
    })
    .then(property("data"));

export function exportInviteReport(params) {
  return axios.get(`${config.api}/invitereport/bySender/export`, {
    params: rejectNilParams(params)
  });
}
