import { isEmpty } from "lodash";
import memoize from "memoizee";

const NOT_SMS_TRANSPORT_FILTER = message => message.transport !== "SMSTransport";

const HAS_NOT_CANCELLED_FILTER = message =>
  isEmpty(message.recipients) || message.recipients.some(recipient => recipient.result !== "CANCELED");

const HAS_CONTENT_FILTER = message => !isEmpty(message.content) || !isEmpty(message.templateData?.repMessage);

const filteredMessages = (messages, filter) => messages.filter(filter);

export const filterNotSmsMessages = memoize(messages => filteredMessages(messages, NOT_SMS_TRANSPORT_FILTER));

export const filterNotCancelledMessages = memoize(messages => filteredMessages(messages, HAS_NOT_CANCELLED_FILTER));

export const filterMessageHasContent = memoize(messages => filteredMessages(messages, HAS_CONTENT_FILTER));
