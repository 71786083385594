import { keyBy, omit } from "lodash";
import size from "lodash/size";

export function hasUnPublishedWidewailReplies(review) {
  if (size(review.replies) > 0) {
    for (let i = 0; i < review.replies.length; i++) {
      const reply = review.replies[i];
      if (hasUnPublishedWidewailReplies(reply)) {
        return true;
      } else if ("WIDEWAIL" === reply.source && "PUBLISHED" !== reply.status && "REMOVED" !== reply.status) {
        return true;
      }
    }
  }
  return false;
}

export function mapLanguageCode(code) {
  let languageNames = new Intl.DisplayNames(["en"], { type: "language" });
  return languageNames.of(code);
}

export const LANGUAGE_CODES = [
  { code: "en", language: "English" },
  { code: "es", language: "Spanish" },
  { code: "fr", language: "French" },
  { code: "other", language: "Other" },
  { code: "UNKNOWN", language: "Unknown" }
];

export const LANGUAGE_CODES_LABEL_VALUE = LANGUAGE_CODES.map(language => ({
  label: language.language,
  value: language.code
}));

export function byCode(code) {
  return keyBy(LANGUAGE_CODES, "code")[code];
}

export function byLanguage(language) {
  return keyBy(LANGUAGE_CODES, "language")[language];
}

export function flattenReplies(replies) {
  const result = [];
  replies.forEach(reply => {
    result.push(omit(reply, "replies"));
    if (reply.replies) {
      result.push(...reply.replies);
    }
  });
  return result;
}

export function isAdOrPost(review) {
  return review?.type === "POST" || review?.type === "AD";
}
