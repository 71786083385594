import classNames from "classnames";
import styles from "./DividerButton.module.scss";

const { container, lineContainer, line, icon, downArrowTop, downArrowBottom, addDisabled } = styles;

export default function DividerButton({ onClick = () => {}, disabled }) {
  return (
    <div className={lineContainer}>
      <div className={"flow-arrow " + downArrowTop} />
      <div className={classNames(container, { [addDisabled]: disabled })} onClick={() => !disabled && onClick()}>
        <i className={"fa fa-plus-circle fa-lg text-secondary rounded-circle add-campaign-icon-button " + icon} />
        <hr className={"border-secondary " + line} />
      </div>
      <i className={"fa fa-arrow-down flow-arrow " + downArrowBottom} />
    </div>
  );
}
