import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownToggle,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Alert
} from "reactstrap";
import { Card, CardTitle, CardBody, CardHeader, CardHeaderActions } from "../../components/Card";
import { AuthorizationRequiredToRender } from "../../components/Auth/Authorization";
import { autoclose, useModal } from "../../components/Modals";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { errorCaughtNotifier, useLocalNotifications } from "../../components/Notifications/notification";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../redux/actions/ajaxStatusActions";
import {
  createTemplate,
  createTemplateSet,
  loadTemplates,
  loadTemplateSets,
  removeTemplate,
  updateTemplate
} from "../../api/responseTemplateApi";
import FormField from "../../components/Form/FormField";
import TextEditor from "../../components/Form/TextEditor";
import { Collapse } from "reactstrap";
import WWButton from "../../components/Buttons/WWButton";
import { useConfirmationDialog } from "../../components/Modals/confirmation.modal";
import { first, noop } from "lodash";
import CustomerFilter from "../../components/Customer/CustomerFilter";

export default () => {
  const [sets, setSets] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedSet, setSelectedSet] = useState();
  const [newTemplateContent, setNewTemplateContent] = useState();
  const [newTemplateEditorOpened, setNewTemplateEditorOpened] = useState(false);
  const [editing, setEditing] = useState();
  const [currentEditingValue, setCurrentEditingValue] = useState("");

  const createTemplateSet = useTemplatesSetCreator();
  const loadTemplatesSets = useTemplatesSetsLoader();
  const createTemplate = useTemplatesCreator();
  const loadTemplates = useTempaltesLoader();
  const updateTemplate = useTemplateUpdater();
  const removeTemplate = useTemplateRemover();
  const confirm = useConfirmationDialog();

  useEffect(() => void loadTemplatesSets(undefined, true).then(setSets), []);
  useEffect(() => void setSelectedSet(sets[0]), [sets]);
  useEffect(() => void (selectedSet && loadTemplates(selectedSet.id).then(setTemplates)), [selectedSet]);

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <Row>
        <Col>
          <Card>
            <CardHeader className="flex-wrap">
              <CardTitle className="text-dark">Response Templates</CardTitle>
              <CardHeaderActions>
                {selectedSet?.system === false && !newTemplateEditorOpened ? (
                  <Button
                    color="light"
                    className="me-2 text-nowrap"
                    onClick={() => setNewTemplateEditorOpened(v => !v)}
                  >
                    New template
                    <i className="ms-2 fa fa-plus" />
                  </Button>
                ) : null}
                <ButtonGroup className="flex-wrap">
                  {selectedSet ? (
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret={true}
                        color="light"
                        className="overflow-hidden text-nowrap"
                        style={{ textOverflow: "ellipsis" }}
                      >
                        {selectedSet.name}
                      </DropdownToggle>
                      <DropdownMenu>
                        {sets
                          .filter(v => v !== selectedSet)
                          .map(v => (
                            <DropdownItem
                              key={v.id}
                              onClick={() => {
                                setSelectedSet(v);
                                setTemplates([]);
                              }}
                            >
                              {v.name}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  ) : null}
                  <AuthorizationRequiredToRender roles={["AGENCY_ADMIN"]}>
                    <Button
                      className="text-nowrap"
                      onClick={() =>
                        createTemplateSet()
                          .then(v => {
                            if (v) {
                              setSets(prev => [v, ...prev]);
                              setSelectedSet(v);
                            }
                          })
                          .catch()
                      }
                      color="light"
                    >
                      New set
                      <i className="fa fa-files-o ms-2" />
                    </Button>
                  </AuthorizationRequiredToRender>
                </ButtonGroup>
              </CardHeaderActions>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={selectedSet?.system === true}>
                <Alert className="my-4" color="warning">
                  This template set is read only. No templates could be added, modified or removed.
                </Alert>
              </Collapse>
              <Collapse isOpen={newTemplateEditorOpened}>
                <div className="my-4">
                  <TextEditor
                    value={newTemplateContent}
                    placeholders={palceholders}
                    placeholder="Enter new template content here"
                    onChange={setNewTemplateContent}
                    syntax="thymeleaf"
                  >
                    <Button
                      className="mt-1 me-1"
                      color="light"
                      size="sm"
                      onClick={() => setNewTemplateEditorOpened(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="mt-1"
                      color="warning"
                      size="sm"
                      disabled={!newTemplateContent}
                      onClick={() =>
                        createTemplate(selectedSet.id, newTemplateContent)
                          .then(({ id }) => {
                            setTemplates(prev => [{ id, text: newTemplateContent }, ...prev]);
                            setNewTemplateContent("");
                          })
                          .catch()
                      }
                    >
                      Save
                    </Button>
                  </TextEditor>
                </div>
              </Collapse>
              <Collapse isOpen={templates.length > 0}>
                <ListGroup>
                  {templates.map(({ id, text }) => (
                    <ListGroupItem key={id} className="d-flex align-items-stretch flex-column">
                      {editing !== id ? (
                        <div className="d-flex align-items-center">
                          <div className="text-dark me-3">{text}</div>
                          {selectedSet.system ? null : (
                            <div className="d-flex align-items-center ms-auto">
                              <ButtonGroup>
                                <WWButton
                                  disabled={editing === id}
                                  color="light"
                                  iconClass="fa fa-edit"
                                  onClick={() => {
                                    setEditing(id);
                                    setCurrentEditingValue(text);
                                  }}
                                >
                                  Edit
                                </WWButton>
                                <WWButton
                                  disabled={editing === id}
                                  color="light"
                                  iconClass="fa fa-trash"
                                  onClick={() =>
                                    autoclose(
                                      confirm({
                                        title: "Remove template from set?",
                                        body: "This action is irreversible. Are you sure?"
                                      })
                                    )
                                      .then(
                                        confirmed =>
                                          confirmed &&
                                          removeTemplate(id).then(() =>
                                            setTemplates(prev => prev.filter(v => v.id !== id))
                                          )
                                      )
                                      .catch(noop)
                                  }
                                >
                                  Remove
                                </WWButton>
                              </ButtonGroup>
                            </div>
                          )}
                        </div>
                      ) : (
                        <TextEditor
                          value={currentEditingValue}
                          placeholders={palceholders}
                          placeholder="Enter template content here"
                          onChange={setCurrentEditingValue}
                          syntax="thymeleaf"
                        >
                          <Button color="light" size="sm" className="m-1" onClick={() => setEditing(undefined)}>
                            Cancel
                          </Button>
                          <Button
                            className="m-1"
                            color="warning"
                            size="sm"
                            disabled={!currentEditingValue}
                            onClick={() =>
                              updateTemplate(editing, currentEditingValue, selectedSet?.id)
                                .then(() => {
                                  setTemplates(prev =>
                                    prev.map(v =>
                                      v.id === editing
                                        ? {
                                            id: editing,
                                            text: currentEditingValue
                                          }
                                        : v
                                    )
                                  );
                                  setEditing(undefined);
                                  setCurrentEditingValue("");
                                })
                                .catch()
                            }
                          >
                            Apply
                          </Button>
                        </TextEditor>
                      )}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Collapse>
              <Collapse isOpen={templates.length === 0}>
                <Alert className="text-center" color="light">
                  There is no templates in selected set.
                </Alert>
              </Collapse>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const TemplateSetCreateModal = ({ resolve }) => {
  const [name, setName] = useState("");
  const [auto, setAuto] = useState(false);
  const [sentiment, setSentiment] = useState("POSITIVE");
  const [customer, setCustomer] = useState();
  const [strategy, setStrategy] = useState("BaseResponseStrategy");

  return (
    <>
      <ModalHeader>Creating new templates set</ModalHeader>
      <ModalBody className="d-flex flex-column">
        <FormField label="Customer">
          <CustomerFilter
            value={[customer]}
            onChange={({ target: { value } }) => setCustomer(value?.[0]?.id)}
            autoSelect={first}
          />
        </FormField>
        <FormField label="Set name" inline={false}>
          <Input
            type="text"
            value={name}
            onChange={({ target }) => setName(target.value)}
            placeholder="enter templates set name here"
          />
        </FormField>
        <FormField label="Responding behavior" inline={false}>
          <ButtonGroup className="w-100">
            <Button color="light" active={!auto} onClick={() => setAuto(false)}>
              Manual
            </Button>
            <Button color="light" active={auto} onClick={() => setAuto(true)}>
              Automatic
            </Button>
          </ButtonGroup>
        </FormField>
        <FormField label="Review sentiment" inline={false}>
          <ButtonGroup className="w-100">
            <Button color="light" active={sentiment === "POSITIVE"} onClick={() => setSentiment("POSITIVE")}>
              Positive
            </Button>
            <Button color="light" active={sentiment === "NEGATIVE"} onClick={() => setSentiment("NEGATIVE")}>
              Negative
            </Button>
          </ButtonGroup>
        </FormField>
        <FormField label="Response strategy" inline={false}>
          <ButtonGroup className="w-100">
            <Button
              color="light"
              active={strategy === "BaseResponseStrategy"}
              onClick={() => setStrategy("BaseResponseStrategy")}
            >
              Base
            </Button>
            <Button
              color="light"
              active={sentiment === "ReviewResponseStrategy"}
              onClick={() => setStrategy("ReviewResponseStrategy")}
            >
              Review
            </Button>
          </ButtonGroup>
        </FormField>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => resolve({ name, auto, sentiment, customer, strategy })}
          disabled={!name || !customer}
        >
          Create
        </Button>
        <Button color="light" onClick={() => resolve()}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};

const useTemplatesSetCreator = () => {
  const riseModal = useModal();
  const dispatch = useDispatch();
  const notify = useLocalNotifications();
  return useCallback(
    () =>
      autoclose(riseModal(resolve => <TemplateSetCreateModal resolve={resolve} />)).then(config => {
        if (config) {
          dispatch(beginAjaxCall());
          return createTemplateSet(config)
            .then(({ data }) => {
              dispatch(endAjaxCall());
              return data;
            })
            .catch(e => {
              dispatch(ajaxCallError());
              errorCaughtNotifier(notify)(e);
              return Promise.reject(e);
            });
        }
        return Promise.resolve();
      }),
    []
  );
};

const useTemplatesSetsLoader = () => {
  const dispatch = useDispatch();
  const notify = useLocalNotifications();
  return useCallback((cutsomerId, includeAuto) => {
    dispatch(beginAjaxCall());
    return loadTemplateSets(cutsomerId, includeAuto)
      .then(({ data }) => {
        dispatch(endAjaxCall());
        return data;
      })
      .catch(e => {
        dispatch(ajaxCallError());
        errorCaughtNotifier(notify)(e);
        return Promise.resolve([]);
      });
  }, []);
};

const useTemplatesCreator = () => {
  const dispatch = useDispatch();
  const notify = useLocalNotifications();
  return useCallback((setId, content) => {
    dispatch(beginAjaxCall());
    return createTemplate({ setId, content })
      .then(({ data }) => {
        dispatch(endAjaxCall());
        return data;
      })
      .catch(e => {
        dispatch(ajaxCallError());
        errorCaughtNotifier(notify)(e);
        return Promise.reject(e);
      });
  }, []);
};

const useTempaltesLoader = () => {
  const dispatch = useDispatch();
  const notify = useLocalNotifications();
  return useCallback(setId => {
    dispatch(beginAjaxCall());
    return loadTemplates(setId)
      .then(({ data }) => {
        dispatch(endAjaxCall());
        return data;
      })
      .catch(e => {
        dispatch(ajaxCallError());
        errorCaughtNotifier(notify)(e);
        return Promise.resolve([]);
      });
  }, []);
};

const useTemplateUpdater = () => {
  const dispatch = useDispatch();
  const notify = useLocalNotifications();
  return useCallback((id, content, setId) => {
    dispatch(beginAjaxCall());
    return updateTemplate({ id, content, setId })
      .then(({ data }) => {
        dispatch(endAjaxCall());
        return data;
      })
      .catch(e => {
        dispatch(ajaxCallError());
        errorCaughtNotifier(notify)(e);
        return Promise.reject(e);
      });
  }, []);
};

const useTemplateRemover = () => {
  const dispatch = useDispatch();
  const notify = useLocalNotifications();
  return useCallback(id => {
    dispatch(beginAjaxCall());
    return removeTemplate(id)
      .then(({ data }) => {
        dispatch(endAjaxCall());
        return data;
      })
      .catch(e => {
        dispatch(ajaxCallError());
        errorCaughtNotifier(notify)(e);
        return Promise.reject(e);
      });
  }, []);
};

const palceholders = [
  {
    value: "customer.companyName",
    label: "Company Name"
  },
  {
    value: "reviewAuthor.firstName",
    label: "Review Author First Name"
  },
  {
    value: "reviewAuthor.lastName",
    label: "Review Author Last Name"
  },
  {
    value: "#numbers.formatDecimal(review.rating,1,0)",
    label: "Rating"
  }
];
