import { isFunction, isUndefined, isEqual, isArray, noop, stubTrue } from "lodash";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import { loadCustomers } from "../../api/customerApi";
import useCustomers, { useCustomerFilterId2NameMapper } from "hooks/customerHooks";
import { useErrorNotification } from "../Notifications/notification";
import "./CustomerFilter.scss";

/**
 *
 * @param {{
 *   name: string;
 *   onChange?: (v: {target: { name: string; value: [{id: string; companyName: string}] }}) => void;
 *   value?: string[];
 *   multiple?: boolean;
 *   placeholder?: string;
 *   loading?: boolean;
 *   disabled?: boolean | (opts: {id: string; companyName: string}[]) => boolean;
 *   autofocus?: boolean;
 *   clearButton?: boolean;
 *   id?: string;
 *   size?: "small" | "large" | undefined;
 *   inputProps?: any;
 *   loader?: (query: string) => Promise<{id: string}[]>;
 *   autoSelect?: (v: {id: string; companyName: string}[]) => {id: string; companyName: string} | undefined;
 * }} param0
 */
export const CustomerFilter = ({
  name,
  onChange = noop,
  value = [],
  loading = false,
  multiple = undefined,
  placeholder = "Search for a customer",
  disabled = false,
  autofocus = false,
  clearButton = true,
  size = undefined,
  id = "customer-filter",
  autoSelect = noop,
  loader = defaultLoader,
  renderMenuItemChildren = defaultRenderer,
  types = undefined,
  initialQueryValue = null,
  ...props
}) => {
  const [selection, setSelection] = useState([]);
  const [options, setOptions] = useState();
  const [loads, setLoads] = useState(0);

  const errorNotification = useErrorNotification();

  const customerFilterMapper = useCustomerFilterId2NameMapper();

  useEffect(() => {
    setLoads(v => (loading ? v + 1 : v - 1));
  }, [loading]);

  const onSelectedChange = useCallback(
    value => {
      setSelection(value);
      onChange({
        target: {
          value,
          name
        }
      });
    },
    [onChange, name]
  );

  const onSearch = useCallback(
    query => {
      setLoads(v => v + 1);
      return loader(query || null)
        .then(loadedLocations => {
          const locations = types ? loadedLocations.filter(({ type }) => types.includes(type)) : loadedLocations;
          setOptions(prev =>
            locations.map(
              location =>
                prev?.find(v => (location.type === "BRAND" ? v.brand === location.brand : v.id === location.id)) ||
                location
            )
          );
          return locations;
        })
        .catch(errorNotification)
        .finally(() => setLoads(v => v - 1));
    },
    [loader, types, errorNotification]
  );

  const onInputChange = useCallback(value => !value && onSearch(null), []);

  useEffect(
    () => {
      onSearch(null).then(locations => {
        if (locations && locations.length > 0) {
          const potentialSelection = autoSelect(locations);
          if (!!potentialSelection) {
            onSelectedChange([potentialSelection]);
          }
        }
      });
    },
    [] // no dependencies! Should be executed only once.
  );

  const needsAddQueryParamBrand = useMemo(() => {
    const { type, brand } = initialQueryValue || {};
    return type === "BRAND" && !isUndefined(options) && !options.some(option => isEqual(option, { brand, type }));
  }, [initialQueryValue, options]);

  const customersWithQueryParamsBrand = useCustomers(
    {
      filters: {
        status: "ACTIVE",
        brands: initialQueryValue?.brand
      }
    },
    { enabled: needsAddQueryParamBrand }
  );

  useEffect(() => {
    if (needsAddQueryParamBrand) {
      const { isSuccess, data } = customersWithQueryParamsBrand;
      if (
        isSuccess &&
        isArray(data?.customers) &&
        data.customers.some(customer => customer.brands.split(",").includes(initialQueryValue?.brand))
      ) {
        setOptions(prev => [{ brand: initialQueryValue?.brand, type: "BRAND" }].concat(prev));
      }
    }
  }, [needsAddQueryParamBrand, customersWithQueryParamsBrand, options]);

  useEffect(() => {
    setSelection(
      value.filter(Boolean).map(id => {
        const selectedOption = options?.find(v => (v.type === "BRAND" ? v.brand === id : v.id === id));
        const [contactName, companyName] =
          isUndefined(selectedOption) && selectedOption?.type !== "BRAND" ? customerFilterMapper(id) : [];

        if (selectedOption) {
          return selectedOption;
        } else if (companyName) {
          return { id, companyName };
        } else if (contactName) {
          return { id, contactName };
        } else {
          return { id, companyName: "" };
        }
      })
    );
  }, [options, value, customerFilterMapper]);

  return (
    <AsyncTypeahead
      clearButton={clearButton}
      isLoading={loads > 0}
      placeholder={placeholder}
      id={id}
      onFocus={event => {
        const inputNode = event.target;
        inputNode.setSelectionRange(0, inputNode.value.length);
      }}
      name={name}
      options={options}
      onChange={onSelectedChange}
      onInputChange={onInputChange}
      onSearch={onSearch}
      labelKey={option => (option?.type === "BRAND" ? option?.brand : option?.companyName) || option?.contactName || ""}
      selected={selection}
      useCache={false}
      minLength={0}
      multiple={multiple}
      disabled={isFunction(disabled) ? disabled(options) : disabled}
      filterBy={stubTrue}
      autoFocus={autofocus}
      size={size}
      paginate={false}
      renderMenuItemChildren={renderMenuItemChildren}
      data-test-id="invite-location-dropdown"
      {...props}
    />
  );
};

export default CustomerFilter;

const optionsTypeConverter = option => {
  if (option?.type === "BRAND") {
    return ["fa-tag", option.brand];
  } else if (option.type === "CONTACT") {
    return ["fa-user", option.contactName];
  } else {
    return ["fa-building", option?.companyName];
  }
};

export const defaultLoader = query =>
  loadCustomers({ q: query }, 0, 25, "customerName").then(({ data }) => {
    return data._embedded?.customers || [];
  });

const defaultRenderer = (option, props) => {
  const [icon, name] = optionsTypeConverter(option);
  return (
    <div className="d-flex flex-row align-items-start justify-content-stretch">
      <i className={`me-1 mt-1 flex-grow-0 flex-shrink-0 fa ${icon}`} />
      <span className="flex-grow-1 overflow-hidden text-wrap">
        <Highlighter search={props.text}>{name}</Highlighter>
        {option.brands && (
          <>
            <br />
            <small className="text-muted">
              <Highlighter search={props.text}>{option.brands}</Highlighter>
            </small>
          </>
        )}
      </span>
    </div>
  );
};
