import CollapsibleCard from "../../Card/CollapsibleCard/CollapsibleCard";
import Input from "../../Form/Input";
import WWButton from "../../Buttons/WWButton";
import RichEditor from "../../RichEditor/RichEditor";
import ToggleSwitch from "components/Form/Switch";
import EditableTitle from "components/Header/EditableTitle";
import classNames from "classnames";
import HorizontalSelectField from "../../Form/HorizontalSelectField";
import { get, isEmpty, isNil, noop } from "lodash";
import {
  RATINGS_LABEL_PAIRS,
  RESPONDER_NOTE_COLOR_OPTIONS,
  REVIEW_TYPE_LABEL_VALUE,
  SITE_LABEL_PAIRS
} from "../../../data/options";
import AddButton from "components/Buttons/AddButton";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import SelectField from "components/Form/SelectField";

import styles from "../ResponderNotes.module.scss";
import { LANGUAGE_CODES_LABEL_VALUE } from "util/reviewUtils";
import { useTagSetById } from "hooks/data/tagSetHooks";

const { conditionValueInput, select, rulesContainer, buttonContainer } = styles;

const ResponderNoteField = ({ note, tags, tagSetId, handleNotesUpdate = () => noop, removeNote = () => noop }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onChange = eventPath => path => event => handleNotesUpdate(path)(get(event.target, eventPath));
  const onInputChange = onChange("value");
  const onCheckChange = onChange("checked");
  const onSelectChange = onChange("value.value");
  //combines old rules with new ones joining them with an && operator
  const insert = text => {
    handleNotesUpdate(["ruleCondition"])(
      isNil(note.ruleCondition) || isEmpty(note.ruleCondition) ? text : `${note.ruleCondition} && ${text}`
    );
  };
  const preparedTagsPassed = Array.isArray(tags);
  const tagSetQuery = useTagSetById(tagSetId, { enabled: !preparedTagsPassed && tagSetId !== undefined });

  return (
    <>
      <CollapsibleCard
        className="mb-3 nowrap"
        header={
          <EditableTitle
            placeholder="Note Title"
            name={"title" + note?.id || note?.tempId}
            title={note.title}
            editable={true}
            onChange={onInputChange(["title"])}
          />
        }
      >
        <div>
          <label className="text-muted fw-normal form-label">Visibility Rules</label>
          <div className="d-flex nowrap ">
            <Input
              label="Rules"
              className="w-100"
              placeholder="Always show"
              value={note.ruleCondition}
              onChange={onInputChange(["ruleCondition"])}
            />
            <AddButton className="ms-2" onClick={() => setIsOpen(true)} />
          </div>
        </div>
        <div className="w-100">
          <label className="text-muted fw-normal form-label">Note</label>
          <RichEditor name="note" value={note.note} onChange={handleNotesUpdate(["note"])} />
        </div>
        <div className="w-50">
          <HorizontalSelectField
            name="color"
            value={RESPONDER_NOTE_COLOR_OPTIONS.find(({ value }) => value === note.color)}
            label="Color"
            isMulti={false}
            creatable={false}
            getOptionLabel={({ iconClass, label }) => (
              <>
                <i className={classNames([iconClass, "me-2"])} />
                <span>{label}</span>
              </>
            )}
            onChange={onSelectChange(["color"])}
            inline={false}
            options={RESPONDER_NOTE_COLOR_OPTIONS}
          />
        </div>
        <div className="d-flex justify-content-between w-100">
          <div className="align-self-start">
            <label className="text-muted fw-normal form-label">Require Confirmation</label>
            <ToggleSwitch
              className="mb-0"
              labelWidth={6}
              inputWidth={6}
              onLabel=""
              offLabel=""
              name="requireConfirmation"
              onChange={onCheckChange(["requireConfirmation"])}
              checked={note.requireConfirmation}
              value={note.requireConfirmation}
            />
          </div>
          <div className="align-content-end">
            <WWButton size="sm" color="danger" iconClass="fa fa-trash" onClick={removeNote}>
              Delete
            </WWButton>
          </div>
        </div>
      </CollapsibleCard>
      {isOpen && (
        <ResponderRulesModel
          tags={tags || tagSetQuery.data?.tags}
          isOpen={isOpen}
          toggle={() => setIsOpen(false)}
          addRule={insert}
        />
      )}
    </>
  );
};

const ResponderRulesModel = ({ isOpen, toggle, addRule, tags }) => {
  const [reviewField, setReviewField] = useState();
  const [conditionValue, setConditionValue] = useState();
  const [ruleValue, setRuleValue] = useState();
  const fullRule = constructRule(reviewField, conditionValue, ruleValue);

  return (
    <Modal centered className="d-flex justify-content-center" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="px-4" toggle={toggle}>
        Visibility Rule
      </ModalHeader>
      <ModalBody className="px-4">
        <div className={rulesContainer}>
          <SelectField
            className={select}
            value={reviewField}
            name="reviewField"
            isMulti={false}
            creatable={false}
            onChange={event => setReviewField(event.target.value)}
            inline={false}
            options={reviewFieldOptions}
          />
          <SelectField
            className={select}
            isDisabled={!reviewField}
            name="conditionValue"
            isMulti={false}
            creatable={false}
            onChange={event => setConditionValue(event.target.value.value)}
            inline={false}
            options={createConditionValueOptions(reviewField?.type)}
          />
          <ConditionsInput tags={tags} reviewField={reviewField?.value} setRuleValue={setRuleValue} />
        </div>
        <div className={buttonContainer}>
          <WWButton outline onClick={toggle}>
            Cancel
          </WWButton>
          <WWButton
            disabled={!reviewField || !conditionValue || !ruleValue}
            color="primary"
            onClick={() => {
              addRule(fullRule);
              toggle();
            }}
          >
            Add Rule
          </WWButton>
        </div>
      </ModalBody>
    </Modal>
  );
};

const ConditionsInput = ({ reviewField, setRuleValue, tags }) => {
  const selectProps = reviewField => {
    switch (reviewField) {
      case REVIEW_FIELD_VALUES.reviewRating:
        return {
          onChange: event => setRuleValue(event.target.value.value),
          options: RATINGS_LABEL_PAIRS
        };
      case REVIEW_FIELD_VALUES.reviewSource:
        return {
          onChange: event => setRuleValue(event.target.value.value),
          options: SITE_LABEL_PAIRS
        };
      case REVIEW_FIELD_VALUES.reviewType:
        return {
          onChange: event => setRuleValue(event.target.value.value),
          options: REVIEW_TYPE_LABEL_VALUE
        };
      case REVIEW_FIELD_VALUES.reviewLanguage:
        return {
          onChange: event => setRuleValue(event.target.value.value),
          options: LANGUAGE_CODES_LABEL_VALUE
        };
      case REVIEW_FIELD_VALUES.reviewCategory:
        return {
          simpleValue: true,
          onChange: event => setRuleValue(event.target.value),
          options: tags
        };
      default:
        return false;
    }
  };

  return (
    <>
      {!!selectProps(reviewField) ? (
        <SelectField
          className={select}
          key={reviewField}
          name={reviewField}
          isMulti={false}
          creatable={false}
          inline={false}
          {...selectProps(reviewField)}
        />
      ) : (
        <Input onChange={event => setRuleValue(event.target.value)} className={conditionValueInput} />
      )}
    </>
  );
};
const REVIEW_FIELD_VALUES = {
  reviewRating: "review.rating",
  reviewSource: "review.source",
  reviewType: "review.type",
  reviewContent: "review.content",
  reviewCategory: "review.tags",
  reviewTopic: "hasTopic",
  reviewLanguage: "review.language",
  reviewCustomerBrands: "review.customer.brands"
};

const REVIEW_FIELD_TYPE = {
  object: "OBJECT",
  string: "STRING",
  number: "NUMBER"
};

const reviewFieldOptions = [
  { value: REVIEW_FIELD_VALUES.reviewRating, label: "Review Rating", type: REVIEW_FIELD_TYPE.number },
  { value: REVIEW_FIELD_VALUES.reviewSource, label: "Review Source", type: REVIEW_FIELD_TYPE.string },
  { value: REVIEW_FIELD_VALUES.reviewType, label: "Review Type", type: REVIEW_FIELD_TYPE.string },
  { value: REVIEW_FIELD_VALUES.reviewContent, label: "Review Content", type: REVIEW_FIELD_TYPE.string },
  { value: REVIEW_FIELD_VALUES.reviewCategory, label: "Review Category", type: REVIEW_FIELD_TYPE.string },
  { value: REVIEW_FIELD_VALUES.reviewTopic, label: "Review Topic", type: REVIEW_FIELD_TYPE.object },
  { value: REVIEW_FIELD_VALUES.reviewLanguage, label: "Review Language", type: REVIEW_FIELD_TYPE.string },
  { value: REVIEW_FIELD_VALUES.reviewCustomerBrands, label: "Customer Brand", type: REVIEW_FIELD_TYPE.string }
];

const createConditionValueOptions = type => {
  switch (type) {
    case REVIEW_FIELD_TYPE.number:
      return [
        { value: "==", label: "Is Equal To" },
        { value: "!=", label: "Is Not Equal To" },
        { value: ">", label: "Is Greater Than" },
        { value: ">=", label: "Is Greater Than or Equal To" },
        { value: "<", label: "Is Less Than" },
        { value: "<=", label: "Is Less Than or Equal To" }
      ];
    case REVIEW_FIELD_TYPE.string:
      return [
        { value: "==", label: "Is Equal To" },
        { value: "!=", label: "Is Not Equal To" },
        { value: "contains", label: "Contains" }
      ];
    case REVIEW_FIELD_TYPE.object:
      return [{ value: "contains", label: "Contains" }];
    default:
      return [];
  }
};

const constructRule = (reviewField, conditionValue, ruleValue) => {
  switch (reviewField?.type) {
    case REVIEW_FIELD_TYPE.number:
      return `${reviewField.value} ${conditionValue} ${ruleValue}`;
    case REVIEW_FIELD_TYPE.string:
      if (conditionValue === "contains") {
        return `${reviewField.value}.contains("${ruleValue}")`;
      } else {
        return `${reviewField.value} ${conditionValue} "${ruleValue}"`;
      }
    case REVIEW_FIELD_TYPE.object:
      return `${reviewField.value}(review, "${ruleValue}") `;
    default:
      return "";
  }
};

export default ResponderNoteField;
