import React, { useState, useCallback, useMemo } from "react";
import _, { entries } from "lodash";
import HorizontalSelectField from "../Form/HorizontalSelectField";
import { Input, FormFeedback } from "reactstrap";
import DeleteButton from "../Buttons/DeleteButton";
import InputField from "../Form/InputField";
import WWButton from "components/Buttons/WWButton";
import { useTagSetByCustomerId } from "hooks/data/tagSetHooks";

// Reference used for displaying the operator to the user. If more operations are needed, add them here.
const opLabelValuePairs = {
  IS: "is",
  NOT_IS: "is not",
  CONTAINS: "contains",
  NOT_CONTAINS: "does not contain",
  STARTS_WITH: "starts with",
  NOT_STARTS_WITH: "does not start with",
  ENDS_WITH: "ends with",
  NOT_ENDS_WITH: "does not end with"
};

const permitsEmptyValue = condition => condition.op === "IS" || condition.op === "NOT_IS";

const postProcessorIsValid = rule => !rule.postProcessor || rule.postProcessor.startsWith("com.widewail");

const RuleEditor = ({ site, customer, onChangesCommitted, rules = [] }) => {
  const getValidationError = useCallback(
    condition => {
      const { field, op, value } = condition;
      if (!field) {
        return "Please select a field";
      }
      if (!op) {
        return "Please select a condition";
      }
      if (!value && !permitsEmptyValue(condition)) {
        return "The chosen condition does not permit an empty value";
      }
      let restriction = site.fieldTypeRestrictions && site.fieldTypeRestrictions[condition.field];
      if (restriction) {
        return restriction(value);
      } else {
        return null;
      }
    },
    [site]
  );

  const [localRules, setLocalRules] = useState(
    rules.map(({ tags, conditions, postProcessor, ...junk }) => ({ tags, conditions, postProcessor }))
  );
  const [editing, setEditing] = useState(false);
  const tagSetQuery = useTagSetByCustomerId(customer?.id, { enabled: !!customer?.id });
  const tagSet = tagSetQuery.data?.tags || [];

  const tagsAreValid = useMemo(() => !localRules.find(rule => rule.tags === ""), [localRules]);

  const conditionsAreValid = useMemo(
    () =>
      !_.flatMap(localRules, r => r.conditions).find(getValidationError) &&
      !localRules.find(rule => !postProcessorIsValid(rule)),
    [localRules, getValidationError]
  );

  const removeRule = useCallback(
    index => {
      const newRules = localRules.filter((item, i) => i !== index);
      setLocalRules(newRules);
    },
    [localRules, setLocalRules]
  );

  const addRule = useCallback(() => {
    const update = [
      ...localRules,
      {
        tags: [],
        conditions: []
      }
    ];
    setLocalRules(update);
  }, [localRules, setLocalRules]);

  const updateRule = useCallback(
    (idx, updatedRule) => {
      const update = localRules.map((rule, i) => (idx === i ? updatedRule : rule));
      setLocalRules(update);
    },
    [localRules, setLocalRules]
  );

  return (
    <div>
      {!editing && (
        <div>
          <p className="text-muted mx-3">Current rules:</p>
          {localRules.map((rule, index) => (
            <div key={index} className="d-flex flex-row align-items-start mx-3">
              <div className="flex-grow-1 flex-shrink-1 pb-3">
                <p className="m-0 p-0">Rule: {index + 1}</p>
                <p className="m-0 p-0">
                  Tags:
                  {rule.tags.map((tag, index) => (
                    <span key={index}> {tag}</span>
                  ))}
                </p>
                {rule.postProcessor && <p className="m-0 p-0">{`Post-processor: ${rule.postProcessor}`}</p>}
                {rule.conditions.map((condition, index) => (
                  <p key={index} className="m-0 p-0">
                    Condition {index + 1}: {_.get(site.fieldsLabelValuePairs, condition.field) || condition.field}{" "}
                    {_.get(opLabelValuePairs, condition.op)} {condition.value || "blank or unknown"}
                  </p>
                ))}
              </div>
              {/* <DeleteButton
                className="fa fa-trash"
                onClick={() => removeRule(index)}
                confirmationPrompt=""
              /> */}
            </div>
          ))}
          <WWButton className="col-12 my-3 mx-0" onClick={() => setEditing(!editing)}>
            Edit Rules
          </WWButton>
        </div>
      )}

      {editing && (
        <div>
          {localRules.map((rule, index) => (
            <div key={index}>
              <DeleteButton className="ms-3 me-1" confirmationPrompt="" onClick={() => removeRule(index)} />
              <span>Rule: {index + 1}</span>
              <RuleRow
                rule={rule}
                site={site}
                tagSet={tagSet}
                onChange={rule => updateRule(index, rule)}
                getValidationError={getValidationError}
              />
            </div>
          ))}
          <WWButton className="col-12 my-3 mx-0" onClick={addRule}>
            Add Import Rule
          </WWButton>
          <WWButton
            disabled={!conditionsAreValid || !tagsAreValid}
            className="col-12 my-3 mx-0 bg-primary"
            onClick={() => {
              onChangesCommitted(localRules);
              setEditing(!editing);
            }}
          >
            Save Rules
          </WWButton>
          <Input type="hidden" invalid={!conditionsAreValid || !tagsAreValid} />
          <FormFeedback>Please fill out all forms before saving</FormFeedback>
        </div>
      )}
    </div>
  );
};

export default RuleEditor;

const RuleRow = ({ rule, onChange, tagSet, site, getValidationError }) => {
  const addCondition = useCallback(() => {
    const update = rule.conditions.concat({
      op: "",
      field: "",
      value: ""
    });
    onChange(Object.assign({}, rule, { conditions: update }));
  }, [rule, onChange]);

  const updateCondition = useCallback(
    (newCondition, idx) => {
      const newConditions = rule.conditions.map((condition, i) => (idx === i ? newCondition : condition));
      onChange(Object.assign({}, rule, { conditions: newConditions }));
    },
    [rule, onChange]
  );

  const removeCondition = useCallback(
    index => {
      const newConditions = rule.conditions.filter((item, i) => i !== index);
      onChange(Object.assign({}, rule, { conditions: newConditions }));
    },
    [rule, onChange]
  );

  const setPostProcessor = useCallback(evt => onChange(Object.assign({}, rule, { postProcessor: evt.target.value })), [
    rule,
    onChange
  ]);

  return (
    <div>
      <HorizontalSelectField
        name="Tags"
        value={rule.tags}
        options={tagSet}
        label="Tags"
        isMulti={true}
        simpleValue={true}
        onChange={evt => onChange(Object.assign({}, rule, { tags: evt.target.value }))}
      />
      <Input type="hidden" invalid={rule.tags === ""} />
      <FormFeedback>Please select a tag</FormFeedback>
      {(site.fieldsLabelValuePairs || site.freeTextRulesEditing) && (
        <div>
          {rule.conditions.map((condition, index) => (
            <div className="mt-3" key={index}>
              <DeleteButton className="ms-3 me-1" confirmationPrompt="" onClick={() => removeCondition(index)} />
              <span>Condition: {index + 1}</span>
              <ConditionEditor
                condition={condition}
                onChange={newCondition => updateCondition(newCondition, index)}
                site={site}
                getValidationError={getValidationError}
              />
            </div>
          ))}
          <WWButton className="col-12 my-3 mx-0" onClick={addCondition}>
            Add Condition
          </WWButton>
        </div>
      )}
      <InputField
        name="postProcessor"
        label="Post-processor (uncommon)"
        value={rule.postProcessor}
        inline={false}
        onChange={setPostProcessor}
        valid={postProcessorIsValid(rule)}
      />
    </div>
  );
};

const ConditionEditor = ({ condition, onChange, site, getValidationError }) => {
  const opOptionsValues = useMemo(() => entries(opLabelValuePairs).map(([value, label]) => ({ value, label })), []);

  const fieldOptions = useMemo(
    () =>
      entries(site.fieldsLabelValuePairs).map(([value, label]) => ({
        value,
        label
      })),
    [site.fieldsLabelValuePairs]
  );

  return (
    <div>
      {site.freeTextRulesEditing ? (
        <InputField
          label="Where Field"
          inline={false}
          onChange={evt => onChange(Object.assign({}, condition, { field: evt.target.value }))}
          value={condition.field}
        />
      ) : (
        <HorizontalSelectField
          label="Where Field"
          className="px-0 mx-0"
          name="DMSdropDown"
          value={
            condition.field && {
              label: _.get(site.fieldsLabelValuePairs, condition.field),
              value: condition.field
            }
          }
          simpleValue={false}
          options={fieldOptions}
          onChange={evt => onChange(Object.assign({}, condition, { field: evt.target.value.value }))}
        />
      )}
      <HorizontalSelectField
        className="px-0 mx-0"
        name="operation"
        value={
          condition.op && {
            label: _.get(opLabelValuePairs, condition.op),
            value: condition.op
          }
        }
        simpleValue={false}
        options={opOptionsValues}
        onChange={evt => onChange(Object.assign({}, condition, { op: evt.target.value.value }))}
      />

      <InputField
        className="px-0 mx-0"
        value={condition.value}
        placeholder="enter your value here"
        onChange={evt => onChange(Object.assign({}, condition, { value: evt.target.value }))}
      />
      <Input type="hidden" invalid={getValidationError(condition)} />
      <FormFeedback>{getValidationError(condition)}</FormFeedback>
    </div>
  );
};
