import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import React from "react";
import { FormFeedback } from "reactstrap";
import FormField from "./FormField";
import Input from "./Input";

const InputField = ({
  name,
  className,
  inputClassName,
  label,
  labelWidth,
  inputWidth,
  inline,
  valid,
  feedbackMessage,
  feedback,
  tip,
  placeholder,
  type,
  children,
  value,
  onChange,
  additionalControl,
  maxTextLength,
  normalizer,
  ...props
}) => {
  return (
    <FormField
      label={label}
      className={className}
      name={name}
      inputWidth={inputWidth}
      labelWidth={labelWidth}
      valid={valid}
      tip={tip}
      feedback={feedback}
      additionalControl={additionalControl}
      inline={inline}
    >
      <Input
        {...props}
        id={props.id || name}
        className={inputClassName}
        name={name}
        type={type}
        placeholder={isString(placeholder) ? placeholder : isString(label) ? label : ""}
        valid={!isUndefined(valid) && valid}
        invalid={!isUndefined(valid) && !valid}
        //fix uncontrolled component errors when value is undefined
        value={onChange ? value || "" : value}
        onChange={onChange}
        maxTextLength={maxTextLength}
        normalizer={normalizer}
      >
        {children}
      </Input>
      <Input type="hidden" invalid={isUndefined(valid) ? valid : !valid} />
      <FormFeedback>{feedbackMessage}</FormFeedback>
    </FormField>
  );
};

InputField.propTypes = {
  ...FormField.propTypes,
  type: (props, name) => {
    if (props[name] === "radio") throw new Error("InputField does not support radio buttons. Use RadioField instead.");
  }
};

export default InputField;
