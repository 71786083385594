import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions/authStatusActions";
import { Auth } from "aws-amplify";
import PasswordInput from "./PasswordInput";
import NewPasswordRequired from "./NewPasswordRequired";
import AjaxLoader, { AjaxLoaderSizes } from "../Misc/AjaxLoader";
import WWButton from "../Buttons/WWButton";
import { trim } from "lodash";

import styles from "./LoginContainer.module.scss";

// component to collect password entry from user in classical crediential based login

export function PasswordFields({ email, onForgotPassword, setErrorMessage, reset }) {
  const dispatch = useDispatch();
  const [passwordChange, setPasswordChange] = useState(false);
  const [password, setPassword] = useState("");
  const [user, setUser] = useState();
  const [requiredAttributes, setRequiredAttributes] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const signIn = () => {
    setLoading(true);

    Auth.signIn(trim(email).toLowerCase(), trim(password))
      .then(user => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setRequiredAttributes(user.challengeParam.requiredAttributes); // the array of required attributes, e.g ['email', 'phone_number']
          setPasswordChange(true);
          setUser(user);
          setErrorMessage("");
        } else {
          dispatch(loginUser(user.signInUserSession, "signedin"));
        }
      })
      .catch(error => {
        if (error.code === "InvalidParameterException") {
          setErrorMessage("Invalid username or password");
        } else {
          error.message === "Custom auth lambda trigger is not configured for the user pool."
            ? setErrorMessage("Password cannot be empty")
            : setErrorMessage(error.message);
        }
        setLoading(false);
      });
  };

  return passwordChange ? (
    <NewPasswordRequired user={user} requiredAttributes={requiredAttributes} />
  ) : (
    <>
      <PasswordInput
        label={`Password for ${email}`}
        placeholder={""}
        name="password"
        newPassword={false}
        onChange={setPassword}
        onKeyPress={({ key }) => key === "Enter" && signIn()}
        value={password}
      />
      <div className="mt-0">
        <WWButton
          disabled={isLoading}
          trackingCategory="Auth"
          data-testid="login"
          className="btn btn-warning col-12 py-3 fw-bold"
          onClick={signIn}
        >
          {isLoading ? <AjaxLoader size={AjaxLoaderSizes.SM} /> : "Login"}
        </WWButton>
      </div>
      <div className="mt-4">
        <WWButton className={styles.linkBtn} color="link" size="sm" onClick={onForgotPassword}>
          Forgot your password?
        </WWButton>
      </div>
      <div className="mt-1">
        <WWButton className={styles.linkBtn} color="link" size="sm" onClick={reset}>
          Not {email}?
        </WWButton>
      </div>
    </>
  );
}
