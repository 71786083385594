import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import colors from "../../constants/colors";

const AJAX_LOADER_DEFAULT_COLOR = colors.grayDark;

export const AjaxLoaderSizes = Object.freeze({
  DEFAULT: 35,
  SM: "1em",
  XS: 4
});

const AjaxLoader = ({
  loading = true,
  size = AjaxLoaderSizes.DEFAULT,
  color = AJAX_LOADER_DEFAULT_COLOR,
  ...props
}) => <ScaleLoader loading={loading} height={size} color={color} {...props} />;

export default AjaxLoader;
