import React, { useCallback, useEffect, useState } from "react";
import { always, until } from "ramda";
import moment from "moment";
import Calendar from "components/DateTime/Calendar/Calendar";
import { START_DATE, END_DATE } from "react-dates/constants";
import { endOfTheDay, sameDay, startOfTheDay } from "util/dateUtils";

const SidebarDateRangePicker = ({ value, onChange }) => {
  const [focused, setFocused] = useState(START_DATE);
  const [start, setStart] = useState(value?.[0] && startOfTheDay(value[0]));
  const [end, setEnd] = useState(value?.[1] && endOfTheDay(value[1]));

  const onFocusChange = useCallback(v => setFocused(v || START_DATE), [setFocused]);
  const onDatesChange = useCallback(
    ({ startDate, endDate }) => {
      const dateStart = startDate && startOfTheDay(startDate);
      const dateEnd = endDate && endOfTheDay(endDate);
      setStart(dateStart);
      setEnd(focused === END_DATE ? dateEnd : null);
      setFocused(focused === END_DATE && dateStart && dateEnd ? START_DATE : END_DATE);
      if (focused === END_DATE && !!dateStart && !!dateEnd) {
        onChange([dateStart, dateEnd]);
      }
    },
    [focused, onChange, setStart, setEnd]
  );

  useEffect(() => {
    setStart(value?.[0] ? until(sameDay(value[0]), always(startOfTheDay(value[0]))) : null);
    setEnd(value?.[1] ? until(sameDay(value[1]), always(startOfTheDay(value[1]))) : null);
  }, [value]);

  return (
    <Calendar
      numberOfMonths={1}
      daySize={32}
      focusedInput={focused}
      onFocusChange={onFocusChange}
      startDate={start}
      endDate={end}
      onDatesChange={onDatesChange}
      initialVisibleMonth={() => end || moment()}
    />
  );
};

export default SidebarDateRangePicker;
