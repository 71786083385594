import React, { useState, useCallback } from "react";
import { Row, Col, Button } from "reactstrap";
import ContactsTable from "./ContactsTable";
import withAuthorization, { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import { CardTitle, CardBody, CardHeaderActions } from "components/Card";
import { exportContacts } from "api/reportApi";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import useFilterQuery, { defaultPrepareFilters } from "hooks/data/useFilterQuery";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import { TableCard, TableCardHeader } from "components/Table/WWTable";
import { useFilterState } from "hooks/filteringHooks";
import { useErrorNotification } from "components/Notifications/notification";
import BulkUpdatesModal from "components/BulkUpdates/BulkUpdatesModal";
import BottomBanner from "components/Modals/BottomBanner/BottomBanner";
import WWButton from "components/Buttons/WWButton";
import { CONTACT_BULK_FIELD_OPTIONS } from "data/options";
import { updateBulkContacts } from "api/customerApi";
import useBulkUpdates from "hooks/bulkUpdatesHooks";
import { showSkeleton } from "../../util/showSkeleton";

import BhManagerUpload from "components/Widgets/BhManagerUpload";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

const getFilters = () => [
  {
    name: "id",
    label: "Name",
    type: "contact",
    chip: {
      position: "NONE"
    },
    primaryBar: {
      position: "LEFT"
    }
  },
  getAccountFilterConfig(),
  {
    name: "location",
    label: "Locations",
    type: "customerOrBrand",
    primaryBar: {
      position: "LEFT",
      className: "d-none d-md-flex"
    },
    secondaryBar: {
      className: "d-flex d-md-none"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    icon: "fa fa-envelope",
    primaryBar: {
      position: "LEFT",
      className: "d-none d-md-flex"
    },
    secondaryBar: {
      className: "d-flex d-md-none"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "phone",
    label: "Phone",
    type: "text",
    icon: "fa fa-phone",
    primaryBar: {
      position: "LEFT",
      className: "d-none d-md-flex"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "sort",
    defaultValue: "name,asc",
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "page",
    defaultValue: 0,
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  }
];

const ContactsList = ({ history }) => {
  const [exporting, setExporting] = useState(false);
  const [filterValue] = useFilterState();

  const errorNotify = useErrorNotification();

  const onExport = useCallback(() => {
    setExporting(true);
    exportContacts(defaultPrepareFilters(filterValue, undefined, filters) || {})
      .then(({ data }) => {
        setExporting(false);
        window.open(data, "_blank");
      })
      .catch(errorNotify);
  }, [filterValue]);

  const filters = getFilters();

  const reviewsQuery = useFilterQuery({
    filters,
    url: `/contacts`,
    additionalParams: { type: "WIDEWAIL_USER", size: 25 }
  });

  const { selectedIds, setSelectedIds, useBulkFilters, onIdToggle, onAllIdToggle, openBulkModal } = useBulkUpdates();

  return (
    <div className="px-md-4 pt-4 container-fluid">
      <FacetedSearch filters={filters} />
      <Row>
        <Col>
          <TableCard>
            <TableCardHeader>
              <CardTitle className="d-none d-sm-block">Contacts</CardTitle>
              <CardHeaderActions>
                <BhManagerUpload />
                <Button color="primary" disabled={exporting} onClick={onExport}>
                  Export CSV
                </Button>
                <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                  <Button color="warning" onClick={() => history.push("/customers/contacts/new")}>
                    New Contact
                  </Button>
                </AuthorizationRequiredToRender>
              </CardHeaderActions>
            </TableCardHeader>
            <CardBody>
              <ContactsTable
                selectedContacts={selectedIds}
                onAllContactsToggle={onAllIdToggle(reviewsQuery.data?.data?.contacts.map(contact => contact.id))}
                onContactToggle={onIdToggle}
                contacts={reviewsQuery.data?.data?.contacts || []}
                skeleton={showSkeleton(reviewsQuery) ? { rows: 6, cols: 10 } : undefined}
              />
            </CardBody>
          </TableCard>
          <FilterPagination
            isLoading={showSkeleton(reviewsQuery)}
            pageInfo={reviewsQuery.data?.pageInfo}
            totalPages={reviewsQuery.data?.pageInfo?.totalPages}
            elementsLabel="Contacts"
          />
        </Col>
      </Row>
      <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
        {selectedIds.length > 0 && (
          <BottomBanner>
            <div className="d-flex justify-content-center flex-wrap">
              <WWButton color="primary" onClick={() => openBulkModal(false)}>
                {`BULK UPDATE (${selectedIds.length} CONTACTS)`}
              </WWButton>
              <WWButton contentClass="fs-3" color="link" onClick={() => openBulkModal(true)}>
                Update all {reviewsQuery.data?.pageInfo.totalElements} contacts that match filters
              </WWButton>
            </div>
            <WWButton contentClass="" className="justify-end" size="sm" color="link" onClick={() => setSelectedIds([])}>
              Clear Selection
            </WWButton>
          </BottomBanner>
        )}
      </AuthorizationRequiredToRender>
      <BulkUpdatesModal
        label="contacts"
        type="contact"
        options={CONTACT_BULK_FIELD_OPTIONS}
        bulkUpdateCallback={updateBulkContacts}
        selectedIds={useBulkFilters ? null : selectedIds}
        filters={useBulkFilters ? defaultPrepareFilters(filterValue, undefined, filters) : null}
        numberOfIds={useBulkFilters ? reviewsQuery.data?.pageInfo.totalElements : selectedIds?.length}
      />
    </div>
  );
};

export default withAuthorization(permissions.CONTACT_MANAGE)(ContactsList);
