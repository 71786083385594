import { initialReportState } from "./reportReducer";
import { initialAppState } from "./appReducer";
import { initialState as viewState } from "./viewStateReducer";
import { initialState as userDashboard } from "./userDashboardReducer";
import { CACHE_INITIAL_VALUE, CACHE_STATE_KEY } from "./customersCacheReducer";

export default {
  ajaxCallsInProgress: 0,
  cognito: {},
  report: initialReportState,
  currentContact: undefined,
  agency: undefined,
  app: initialAppState,
  facebook: {},
  google: {},
  viewState,
  userDashboard,
  [CACHE_STATE_KEY]: CACHE_INITIAL_VALUE
};
