import React, { useState, useEffect } from "react";
import InputField from "components/Form/InputField";
import PasswordInput from "components/Auth/PasswordInput";
import FormField from "components/Form/FormField";

import { validEmail } from "util/validators";

const SharedEmailAndPassword = ({ credentials, onChange, onValidChange }) => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    setName(credentials?.name);
    setEmail(credentials?.email);
    setPassword(null);
  }, [credentials]);

  useEffect(() => {
    if (!password) {
      setPassword(null);
    }
  }, [password]);

  useEffect(() => {
    onValidChange(name && email && (credentials?.password || password));
    onChange({ name, email, password });
  }, [name, email, password, credentials?.password, onChange, onValidChange]);

  return (
    <>
      <InputField
        label="Name"
        value={name || ""}
        valid={!!name}
        onChange={event => setName(event.target.value)}
        inline={false}
      />
      <InputField
        label="Email Address"
        value={email || ""}
        valid={email ? validEmail(email) : false}
        onChange={event => setEmail(event.target.value)}
        inline={false}
      />
      <FormField tip={credentials?.password ? "Leave this empty to use the existing password" : ""}>
        <PasswordInput label="Password" value={password || ""} onChange={setPassword} lastpass={false} inline={false} />
      </FormField>
    </>
  );
};

export default SharedEmailAndPassword;
