import * as types from "../actions/actionTypes";

export const initialState = {
  customerIds: []
};

export default function report(state, action) {
  if (state === undefined) {
    return initialState;
  } else {
    switch (action.type) {
      case types.USER_DASHBOARD_SET_CUSTOMERS:
        return Object.assign({}, state, { customerIds: action.customerIds });

      default:
        return state;
    }
  }
}
