import * as types from "./actionTypes";

/**
 * Merge a sparse object into the view state
 * @param {String} view
 * @param {Object} data
 */
export function updateViewState(view, data) {
  return {
    type: types.UPDATE_VIEW_STATE,
    view,
    data
  };
}

export function updatePath(path, data) {
  return {
    type: types.UPDATE_PATH,
    view: path,
    data
  };
}

export function clearViewState() {
  return {
    type: types.CLEAR_VIEW_STATE
  };
}
