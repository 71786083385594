import { useMemo, useState, useCallback, useEffect } from "react";
import { noop } from "lodash";
import qs from "qs";
import { useSelector } from "react-redux";
import LinkButton from "components/Routing/LinkButton";
import ReviewMiniCard from "components/Review/ReviewMiniCard/ReviewMiniCard";
import Carousel from "components/Carousel/Carousel";
import ActiveProductsRequireToRender from "components/Auth/ActiveProductsRequireToRender";
import WidgetCard from "../CommonComponents/WidgetCard";
import { useDashboardReviewsCount, useDashboardReviews } from "hooks/data/dashboardReviewHooks";
import { productsIncludeEngageProduct } from "util/customerUtils";

import styles from "./ReviewWidget.module.scss";

const {
  reviewWidgetCardClass,
  cardBodyClass,
  caughtUpBannerClass,
  caughtUpTextClass,
  itemsContainerClass,
  MINI_CARD_WIDTH,
  MINI_CARD_MARGIN
} = styles;

const CARD_WIDTH = parseInt(MINI_CARD_WIDTH);
const CARD_MARGIN = parseInt(MINI_CARD_MARGIN);

const CaughtUpBanner = ({ showApproval }) => (
  <div>
    <div className={caughtUpBannerClass} />
    <div className={caughtUpTextClass}>
      All your reviews {showApproval ? "are approved" : "have replies"}, great job!
    </div>
  </div>
);

export const ReviewWidget = ({
  reviews,
  isLoading,
  totalLength,
  reviewsCount,
  showApproval = false,
  loadNextPage = noop
}) => {
  const renderEmptyBanner = useMemo(() => () => <CaughtUpBanner showApproval={showApproval} />, []);
  const renderItem = useMemo(() => review => <ReviewMiniCard showApproval={showApproval} review={review} />, []);
  const canShowHeadingInfo = totalLength !== undefined && reviewsCount !== undefined;
  const headingInfo = canShowHeadingInfo
    ? `${reviewsCount.replied} of ${reviewsCount.totalReviews} Reviews Have Replies`
    : undefined;

  return (
    <WidgetCard
      className={reviewWidgetCardClass}
      bodyClassName={cardBodyClass}
      heading={`Reviews Waiting for ${showApproval ? "Approval" : "Replies"}`}
      headingInfo={headingInfo}
      rigid={false}
    >
      <Carousel
        items={reviews}
        isLoading={isLoading}
        totalLength={totalLength || 0}
        loadNextPage={loadNextPage}
        itemWidth={CARD_WIDTH + CARD_MARGIN}
        itemHeight={245}
        maxDisplayedItems={3}
        renderItem={renderItem}
        renderEmptyBanner={renderEmptyBanner}
        itemsContainerClassName={itemsContainerClass}
      >
        {!!totalLength && (
          <div className="d-flex justify-content-center mt-1 mt-md-3">
            <LinkButton
              color="primary"
              to={{
                pathname: "reviews/feed",
                search: qs.stringify({
                  status: showApproval ? "APPROVAL" : "CUSTOMER_UNREPLIED"
                })
              }}
            >
              View Reviews
            </LinkButton>
          </div>
        )}
      </Carousel>
    </WidgetCard>
  );
};

export const ReviewWidgetWithQuery = ({ showApproval }) => {
  const [reviewPages, setReviewPages] = useState([]);
  const [page, setPage] = useState(0);
  const [pageInfo, setPageInfo] = useState({});

  const reviewsCount = useDashboardReviewsCount().data;
  const reviewsQuery = useDashboardReviews(
    {
      params: {
        ...(showApproval ? { status: "APPROVAL" } : { status: "CUSTOMER_UNREPLIED" })
      }
    },
    true,
    undefined,
    false
  );

  const loadNextPage = useCallback(() => {
    if (pageInfo && pageInfo.totalPages - 1 > page) {
      setPage(prev => prev + 1);
    }
  }, [pageInfo, page]);

  useEffect(() => {
    if (reviewsQuery.isSuccess) {
      const { data } = reviewsQuery;
      const pageNumber = data?.[1]?.number;
      const reviewsData = data?.[0];
      const nextPages = [...reviewPages];
      if (pageNumber !== undefined) {
        nextPages[pageNumber] = reviewsData;
      }
      setReviewPages(nextPages);
      setPageInfo(data?.[1]);
    }
  }, [reviewsQuery.data, reviewsQuery.isSuccess]);

  const reviews = useMemo(() => reviewPages?.flat() || [], [reviewPages]);

  return (
    <ReviewWidget
      isLoading={reviewsQuery.isLoading}
      reviews={reviews}
      totalLength={pageInfo?.totalElements}
      reviewsCount={reviewsCount}
      loadNextPage={loadNextPage}
      showApproval={showApproval}
    />
  );
};

export default () => {
  const activeProducts = useSelector(state => state.currentContact?.activeProducts);
  return (
    <ActiveProductsRequireToRender products={["REVIEW_IMPORT"]}>
      <ReviewWidgetWithQuery showApproval={productsIncludeEngageProduct(activeProducts)} />
    </ActiveProductsRequireToRender>
  );
};
