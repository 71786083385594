import _, { get, noop, uniqBy, omit, isNil } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import qs from "qs";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  UncontrolledButtonDropdown,
  UncontrolledDropdown
} from "reactstrap";
import * as api from "../../api/commonApi";
import * as crm from "../../api/crmApi";
import * as customerApi from "../../api/customerApi";
import * as tagSetApi from "../../api/tagSetApi";
import withAuthorization, { AuthorizationRequiredToRender } from "../../components/Auth/Authorization";
import { permissions } from "../../components/Auth/permissions";
import { Card, CardBody, CardHeader, CardHeaderAction, CardHeaderActions, CardTitle } from "../../components/Card";
import AdvocateUpload from "../../components/Customer/AdvocateUpload";
import DatePicker from "../../components/DateTime/DatePicker";
import FormField from "../../components/Form/FormField";
import HorizontalSelectField from "../../components/Form/HorizontalSelectField";
import InputField from "../../components/Form/InputField";
import ListField from "../../components/Form/ListField";
import PhoneNumberField from "../../components/Form/PhoneNumberField";
import SaveBar from "../../components/Form/SaveBar";
import withConfirmation from "../../components/Form/withConfirmation";
import WWButton from "../../components/Buttons/WWButton";
import CopyButton from "../../components/Buttons/CopyButton";
import ScheduleMeeting from "../../components/Misc/ScheduleMeeting";
import { Messages } from "../../components/Misc/StatusMessages";
import { ModalsContext, autoclose } from "../../components/Modals";
import { errorCaughtNotifier, withLocalNotifications } from "../../components/Notifications/notification";
import { Table, TBody, TD, TH, THead, TR } from "../../components/Table";
import { CUSTOMER_STATUSES } from "../../data/options";
import { bySource, DMS_SITES, SITES } from "../../data/sites";
import timezones from "../../data/timezones";
import { beginAjaxCall, endAjaxCall } from "../../redux/actions/ajaxStatusActions";
import {
  CONTACT_TYPE_SHARE_CONTACT,
  CONTACT_TYPE_WIDEWAIL_USER,
  INVITE_LANDING_PAGE_PREFIX,
  ONBOARDING_PREFIX
} from "../../util/constants";
import logger from "../../util/logger";
import { join } from "../../util/stringUtils";
import { isCurrentUserInGroup } from "../../util/userUtils";
import AdminLinking from "./AdminLinking";
import CdkDmsLinking from "./CdkDmsLinking";
import DealerbuiltLinking from "./DealerbuiltLinking";
import DealertrackDmsLinking from "./DealertrackDmsLinking";
import VinSolutionsLinking from "./VinSolutionsLinking";
import AddressInput from "../../components/Form/AddressInput/AddressInput";
import AgencySelector from "../../components/Agency/AgencySelector";
import { adjust, map, mergeAll, objOf, pipe, prop, propOr, toPairs, pick } from "ramda";
import { loadAgency } from "../../api/agencyApi";
import { americanStateAbbreviations, stateOrProvince2Code } from "../../data/locations";
import { WWTabs } from "../../components/WWTabs";
import SmsSetup from "./SmsSetup/SmsSetup";
import LinkedSitesTable from "./SiteIntergrations/LinkedSitesTable/LinkedSitesTable";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import { isProductActiveForCustomer } from "util/customerUtils";
import CdkFortellisLinking from "./CdkFortellisLinking";
import { openLinkIntoTheNewTab } from "util/navUtils";
import { getApprovalSearch } from "util/getApprovalSearch";
import MediaCard from "views/Listings/ListingsEdit/Forms/MediaCard";
import { MB } from "constants/filesize";
import { ContactTypeahead } from "components/Form/Typeahead/Typeahead";
import StatusMessages from "components/Misc/StatusMessages";
import { LabelMultiSelector } from "components/Form/LabelSelector";
import FieldLabel from "components/Form/FieldLabel";
import ResponderNotesPage from "components/ResponderNotes/ResponderNotesPage";
import { riseConfimationDialog } from "components/Modals/confirmation.modal";
import ProductsCard from "components/Products/ProductsCard";
import ImportFromHubspotAction from "./ImportFromHubspotAction";

import { useAccountAddCustomer, useAccountRemoveCustomer } from "hooks/data/accountsHooks";
import { AccountTypeaheadField } from "components/Account/AccountSelector";

const LOCATION_MANAGER_PERMS = [permissions.LOCATION_MANAGE, permissions.AGENCY_ADMIN];

const ButtonWConfirm = withConfirmation(Button);

const TABS = {
  info: { label: "Customer Information" },
  sms: { label: "SMS Setup", path: "/SMS", permissions: [permissions.AGENCY_ADMIN] },
  siteIntegrations: { label: "Site Integrations", path: "/siteIntegrations" },
  responderNotes: { label: "Responder Notes", path: "/responderNotes", permissions: [permissions.AGENCY_ADMIN] }
};

class EditCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: {
        companyName: "",
        address: "",
        countryCode: "US",
        contacts: [],
        brands: null,
        responsePreference: "Casual",
        status: "PENDING",
        enableAutoResponse: true,
        products: [],
        customFields: {},
        companyLogo: null,
        locationLabels: []
      },
      contacts: null,
      shareContacts: null,
      messages: [],
      messagesPage: { number: 0 },
      contactCustomer: false,
      newQuestion: false,
      dirty: false,
      errors: {},
      reviewTagSetOptions: [],
      uploadAdvocates: false,
      customFieldPairs: []
    };

    this.addContact = this.addContact.bind(this);
    this.addSite = this.addSite.bind(this);
    this.removeSite = this.removeSite.bind(this);
    this.saveSite = this.saveSite.bind(this);
    this.updateSiteField = this.updateSiteField.bind(this);
    this.toggleSiteDisabledState = this.toggleSiteDisabledState.bind(this);
    this.removeContact = this.removeContact.bind(this);
    this.removeAllContactsFromCustomer = this.removeAllContactsFromCustomer.bind(this);
    this.updateCustomerState = this.updateCustomerState.bind(this);
    this.updateCustomerField = this.updateCustomerField.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.sendReport = this.sendReport.bind(this);
    this.queueImport = this.queueImport.bind(this);
    this.loadCustomer = this.loadCustomer.bind(this);
    this.loadBrandOptions = this.loadBrandOptions.bind(this);
    this.linkContact = this.linkContact.bind(this);
    this.uploadShareContacts = this.uploadShareContacts.bind(this);
    this.refreshSite = this.refreshSite.bind(this);
    this.updateCustomerProducts = this.updateCustomerProducts.bind(this);
    this.addCustomField = this.addCustomField.bind(this);
    this.removeCustomField = this.removeCustomField.bind(this);
    this.updateCustomField = this.updateCustomField.bind(this);
    this.updateDefaultReviewTag = this.updateDefaultReviewTag.bind(this);
    this.groupLinkedSites = this.groupLinkedSites.bind(this);
    this.populateFromCrm = this.populateFromCrm.bind(this);
    this.hasDmsLink = this.hasDmsLink.bind(this);
    this.validateFile = this.validateFile.bind(this);
    this.openApprovalViewTab = this.openApprovalViewTab.bind(this);
    this.openCustomerReportTab = this.openCustomerReportTab.bind(this);
    this.setLabels = this.setLabels.bind(this);
    this.doSave = this.doSave.bind(this);
    this.loadPageAfterSave = this.loadPageAfterSave.bind(this);
  }

  loadAgencyInfo(id) {
    return loadAgency(id).then(
      pipe(
        prop("data"),
        propOr({}, "defaultProducts"),
        toPairs,
        map(pipe(adjust(0, objOf("product")), adjust(1, objOf("active")), mergeAll))
      )
    );
  }

  componentDidMount() {
    const customerId = this.props.match.params.id;
    if (customerId) {
      this.loadCustomer(customerId);
    } else {
      this.loadAgencyInfo(this.props.currentAgency?.id).then(products =>
        this.setState({
          customer: {
            ...this.state.customer,
            products
          },
          generateSlug: true
        })
      );
    }
    tagSetApi.loadAgencyTags(this.props.currentAgency?.id).then(res => {
      let options = _.map(res.data, ts => ({
        value: ts,
        label: ts.name,
        isDefault: ts.isDefault,
        tags: ts.tags
      }));
      options = _.orderBy(options, ["isDefault"], ["desc"]);
      this.setState({ reviewTagSetOptions: options }, () => {
        if (!customerId) {
          this.updateCustomerState(
            {
              target: {
                name: "reviewTagSet",
                type: "select",
                value: options[0]
              }
            },
            () => {
              if (qs.parse(this.props.location.search.slice(1))?.account) {
                this.updateCustomerField("account", qs.parse(this.props.location.search.slice(1))?.account);
              }
            }
          );
        }
      });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.customer !== prevState.customer) {
      this.setState({
        linkedSites: this.groupLinkedSites(this.state.customer)
      });
      this.validateCustomerFields();
    }
  }

  validateCustomerFields = () => {
    if (!this.state.customer.account && this.state.customer.id === undefined) {
      this.setState(state => ({ errors: { ...state.errors, account: "Account must be set for a new customer" } }));
    } else if (!!this.state.errors.account) {
      this.setState(state => ({ errors: { ...state.errors, account: undefined } }));
    }
    const validate = (condition, field, message) => {
      this.setState(state => ({ errors: { ...state.errors, [field]: condition ? message : undefined } }));
    };
    validate(
      this.state.customer.companyName === undefined || this.state.customer.companyName === "",
      "companyName",
      "Location name can't be blank"
    );
    validate(
      !this.state.customer.account && this.state.customer.id === undefined,
      "account",
      "Account must be set for a new customer"
    );
  };

  loadShareContacts(id, page) {
    if (isCurrentUserInGroup(permissions.CREATE_SHARE)) {
      return customerApi
        .loadCustomerShareContacts(id, {
          size: 20,
          sort: "name",
          page
        })
        .then(response =>
          this.setState({
            shareContactsPage: response.data.page,
            shareContacts: _.get(response.data, "_embedded.contacts")
          })
        );
    }
  }

  groupLinkedSites(customer) {
    return SITES.filter(site => (site.linkable || site.editable) && (!site.groups || isCurrentUserInGroup(site.groups)))
      .flatMap(site => _.get(customer, site.customerField, []))
      .sort((a, b) => {
        if (!a.id) return -1;
        if (!b.id) return 1;
        return a.source?.localeCompare(b.source);
      });
  }

  loadCustomer(id) {
    this.props.dispatch(beginAjaxCall());
    return customerApi
      .loadCustomer(id)
      .then(customer => {
        if (isCurrentUserInGroup([permissions.AGENCY_ADMIN, permissions.WIDEWAIL_ADMIN])) {
          this.initialCustomerAccount = customer.account;
          return this.loadAgencyInfo(this.props.currentAgency?.id).then(products => {
            const updatedCustomer = {
              ...customer,
              products: products.map(a => ({
                ...a,
                ...(customer.products.find(b => b.product === a.product) || {})
              }))
            };
            this.setState({
              customer: updatedCustomer,
              customFieldPairs: _.toPairs(customer.customFields),
              generateSlug: _.isEmpty(customer.inviteLandingPageSlug),
              linkedSites: this.groupLinkedSites(customer)
            });
          });
        } else {
          this.setState({
            customer,
            customFieldPairs: _.toPairs(customer.customFields),
            generateSlug: _.isEmpty(customer.inviteLandingPageSlug),
            linkedSites: this.groupLinkedSites(customer)
          });
          return customer;
        }
      })
      .then(() => customerApi.loadCustomerContacts(id, { projection: "name" }))
      .then(response => this.setState({ contacts: _.get(response.data, "_embedded.contacts") }))
      .then(() => this.loadShareContacts(id))
      .catch(error => {
        if (error.response?.status === 404) {
          this.props.notify({
            icon: "danger",
            body: "Customer not found. Please check your selected agency."
          });
        } else {
          this.props.notify({
            icon: "danger",
            body: (
              <Messages
                messages={{
                  message: "Failed loading customer",
                  details: error.response?.data || error
                }}
              />
            )
          });
        }
      })
      .finally(() => this.props.dispatch(endAjaxCall()));
  }

  addContact() {
    let c = Object.assign({}, this.state.customer);
    c.contacts.push({ name: "", email: "", phone: "", type: "Escalation" });
    this.setState({ customer: c });
  }

  addSite(field, source) {
    let customer = Object.assign({}, this.state.customer);
    if (_.endsWith(field, "Confirmed")) {
      customer[field] = true;
    } else {
      if (customer[field]) {
        customer[field] = [{ source }, ...customer[field]];
      } else {
        customer[field] = [{ source }];
      }
    }
    this.setState({
      customer,
      linkedSites: this.groupLinkedSites(customer)
    });
  }

  saveSite(field, data, override = false, onError = noop) {
    customerApi
      .addSite(this.state.customer.id, field, data, override)
      .then(res => {
        let customer = Object.assign({}, this.state.customer);
        customer[field] = res.data;
        this.setState({ customer, dirty: true });
      })
      .catch(err => {
        onError(err);
      });
  }

  removeSite(field, siteId) {
    if (!_.endsWith(field, "Confirmed")) {
      if (this.state.customer[field] && siteId) {
        customerApi.removeSite(this.state.customer.id, field, siteId).then(res => {
          this.updateSiteField(field, res.data);
        });
      } else {
        // Cancel adding new site by removing stub object.
        this.updateSiteField(
          field,
          Array.isArray(this.state.customer[field])
            ? this.state.customer[field].filter(site => !_.isEmpty(site.id))
            : []
        );
      }
    }
  }

  updateSiteField(field, data) {
    const customer = { ...this.state.customer, [field]: data };
    this.setState({ customer });
  }

  toggleSiteDisabledState(field, siteId) {
    const sites = this.state.customer[field];
    const toggledSite = sites?.find(({ id }) => id === siteId);
    const disabled = toggledSite?.disabled || false;
    return (
      disabled
        ? customerApi.enableSite(this.state.customer.id, siteId)
        : customerApi.disableSite(this.state.customer.id, siteId)
    )
      .then(() => {
        this.setState({
          customer: {
            ...this.state.customer,
            [field]: sites.map(site => ({ ...site, disabled: site.id === siteId ? !site.disabled : site.disabled }))
          }
        });
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  refreshSite(field, siteId) {
    let customer = Object.assign({}, this.state.customer);
    if (customer[field] && siteId) {
      customerApi.refresh(this.state.customer.id, field, siteId).then(res => {
        customer[field] = res.data;
        this.setState({ customer });
      });
    }
  }

  updateDefaultReviewTag(field, siteId, defaultReviewTag) {
    let customer = Object.assign({}, this.state.customer);
    if (customer[field] && siteId) {
      customerApi.updateDefaultReviewTag(this.state.customer.id, field, siteId, defaultReviewTag).then(res => {
        customer[field] = res.data;
        this.setState({ customer });
      });
    }
  }

  // removeContact(idx) {
  //   let c = Object.assign({}, this.state.customer);
  //   c.contacts.splice(idx, 1);
  //   this.setState({ customer: c });
  // }

  updateCustomerField(field, value, callback) {
    let customer = Object.assign({}, this.state.customer);
    _.set(customer, field, value);
    if (this.state.customer[field] !== customer[field]) {
      return this.setState({ customer, dirty: true }, callback);
    }
  }

  updateCustomerProducts(event) {
    const productName = event.target.name;
    let customer = Object.assign({}, this.state.customer);
    let products = this.state.customer.products;
    let product = _.remove(products, { product: productName })[0];
    if (!product) {
      product = { product: productName };
    }
    _.set(product, "active", event.target.checked);
    products = [product, ...products];
    customer["products"] = products;
    this.setState({ customer, dirty: true });
  }

  get isReviewLeadImportProductActive() {
    return isProductActiveForCustomer(this.state.customer, "REVIEW_LEAD_IMPORT");
  }

  get formErrors() {
    return Object.values(this.state.errors).filter(Boolean);
  }

  updateCustomerState(event, callback) {
    const field = event.target.name;
    if ("brands" === field || "alternateNames" === field) {
      return this.updateCustomerField(field, event.target.value.join(",") || null, callback);
    } else if ("timezone" === field) {
      return this.updateCustomerField(field, event.target.value.name, callback);
    } else if ("reviewTagSet" === field) {
      return this.updateCustomerField(field, event.target.value?.value, callback);
    } else if ("hipaaCompliant" === field) {
      return this.updateCustomerField(field, event.target.checked, callback);
    } else if (event.target.className === "form-check-input") {
      return this.updateCustomerField(field, event.target.checked, callback);
    } else {
      if ("inviteLandingPageSlug" === field) {
        this.setState({ generateSlug: false }, callback);
      } else if ("companyName" === field && this.state.generateSlug) {
        this.updateCustomerField("inviteLandingPageSlug", event.target.value.replace(/[^A-Za-z0-9]+/g, ""), callback);
      }

      return this.updateCustomerField(field, event.target.value, callback);
    }
  }

  getCustomerForSave() {
    const labelReducer = (acc, locationLabel) => {
      const { name, value } = {
        name: locationLabel.name?.trim(),
        value: locationLabel.value?.trim()
      };

      if (name && value) {
        acc = [...acc, { name, value }];
      }
      return acc;
    };
    const labelUniqer = ({ name, value }) => (name + value).toLowerCase();
    const fieldsToOmit = [
      "carsDotComInfo",
      "dealerraterInfo",
      "facebookInfo",
      "gmbInfo",
      "instagram",
      "hubspotInfo"
    ];
    return {
      ...omit(this.state.customer, fieldsToOmit),
      customFields: _.fromPairs(this.state.customFieldPairs),
      locationLabels: uniqBy(this.state.customer.locationLabels.reduce(labelReducer, []), labelUniqer)
    };
  }

  get accountIsChanged() {
    const accountId = this.state.customer?.account?.id;
    return accountId !== undefined && accountId !== this.initialCustomerAccount?.id;
  }

  saveCustomer() {
    return customerApi
      .saveCustomer(this.getCustomerForSave(this.state.customer))
      .catch(errorCaughtNotifier(this.props.notify));
  }

  //shouldClose is a boolean parameter passed down by SaveBar
  async doSave(shouldClose) {
    const { dataMutations } = this.props;
    const accountId = this.state.customer?.account?.id;
    let customerId = this.state.customer.id;
    const promises = [];
    if (this.state.dirty) {
      const savePromise = this.saveCustomer();
      // for new customer sent requests in series, at the same time otherwise
      if (customerId === undefined) {
        customerId = (await savePromise).data.id;
      } else {
        promises.push(savePromise);
      }
    }
    this.setState({ isSaving: true });
    await Promise.all(promises);
    this.setState({ dirty: false, isSaving: false });
    if (shouldClose) {
      this.redirect();
    } else {
      this.loadPageAfterSave(this.state.customer.id === undefined, customerId);
    }
  }

  loadPageAfterSave = (isNew, id) => {
    this.loadCustomer(id);
    if (isNew) {
      this.props.history.push(id);
    }
  };

  deleteCustomer() {
    customerApi.deleteCustomer(this.state.customer.id);
    this.redirect();
  }

  redirect() {
    this.props.history.goBack();
  }

  sendReport() {
    customerApi
      .sendMontlyReport(this.state.customer.id)
      .then(() => this.props.notify({ body: "Report sent.", timeout: 3000 }))
      .catch(() =>
        this.props.notify({
          icon: "danger",
          body: "Failed sending report"
        })
      );
  }

  queueImport(source, siteInfoId, endDate) {
    customerApi
      .queueImport(this.state.customer.id, source, siteInfoId, endDate)
      .then(() => this.props.notify({ body: "Import queued.", timeout: 3000 }))
      .catch(() => this.props.notify({ body: "Import queue failed.", icon: "danger" }));
  }

  loadBrandOptions() {
    return api.fetch("/tags").then(res => {
      return res.data;
    });
  }

  linkContact(c) {
    customerApi
      .linkCustomerContact(this.state.customer.id, c)
      .then(() => {
        let list = (c.type === CONTACT_TYPE_WIDEWAIL_USER ? this.state.contacts : this.state.shareContacts) || [];
        let key = (c.type === CONTACT_TYPE_WIDEWAIL_USER ? "contacts" : "shareContacts") || [];

        this.setState({ [key]: [c, ...list] });
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  removeContact(c) {
    customerApi
      .removeCustomerContact(this.state.customer.id, c.id, c.type)
      .then(res => {
        if (c.type === CONTACT_TYPE_WIDEWAIL_USER) {
          this.setState({
            contacts: this.state.contacts.filter(contact => c.id !== contact.id)
          });
        } else {
          this.setState({
            shareContacts: this.state.shareContacts.filter(contact => c.id !== contact.id)
          });
        }
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  removeAllContactsFromCustomer() {
    customerApi
      .removeAllContactsFromCustomer(this.state.customer.id)
      .then(() => {
        this.setState({
          contacts: []
        });
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  addCustomField() {
    let customFieldPairs = this.state.customFieldPairs;
    customFieldPairs.push(["", ""]);
    this.setState({ customFieldPairs, dirty: true });
  }

  updateCustomField(cf, index) {
    let customFieldPairs = this.state.customFieldPairs;
    customFieldPairs[index] = cf;
    this.setState({ customFieldPairs, dirty: true });
  }

  removeCustomField(index) {
    let customFieldPairs = this.state.customFieldPairs;
    _.pullAt(customFieldPairs, index);
    this.setState({ customFieldPairs, dirty: true });
  }

  uploadShareContacts(event) {
    const file = event.target.files[0];
    const { customer } = this.state;
    customerApi
      .postShareContactsFile(customer.id, file)
      .then(() => this.loadShareContacts(customer.id))
      .catch(err => {
        logger.error(err);
        this.props.notify({
          icon: "danger",
          body: "Failed parsing contacts file"
        });
      });
  }

  populateFromCrm(record) {
    // handling special edge cases when administrativeArea is not a 2 letters code
    const administrativeArea = record.state.length === 2 ? record.state : stateOrProvince2Code(record.state);
    const customer = {
      ...this.state.customer,
      hubspotId: record.id,
      companyName: record.name,
      businessPhone: _.replace(record.phone, /\D/g, ""),
      countryCode: americanStateAbbreviations.indexOf(administrativeArea) >= 0 ? "US" : "CA",
      streetNumber: record.address?.split(/\s+/)?.shift(),
      streetName: record.address?.split(/\s+/)?.slice(1).join(" "),
      locality: record.city,
      administrativeArea,
      postalCode: record.zip,
      timezone: record.timezone,
      inviteLandingPageSlug: this.state.customer.inviteLandingPageSlug || record.name.replace(/[^A-Za-z0-9]+/g, ""),
      reviewTagSet:
        (record.industry === "AUTOMOTIVE" &&
          this.state.reviewTagSetOptions.find(v => v.value.name === "Automotive")?.value) ||
        this.state.reviewTagSetOptions.find(v => v.value.name === "General")?.value
    };
    this.setState({ customer });
  }

  hasDmsLink() {
    let customer = this.state.customer;
    let dmsSourceFields = DMS_SITES.map(s => s.customerField);
    return _.find(dmsSourceFields, f => _.size(_.get(customer, f)) > 0) !== undefined;
  }
  validateFile(v) {
    const file = v.target.files[0];
    const size = file.size / MB;
    if (size > 2) {
      this.props.notify({
        icon: "danger",
        body: "The file is too large"
      });
    } else if (!/^image\/.+$/.test(file.type)) {
      this.props.notify({
        icon: "danger",
        body: "The file is not an image"
      });
    } else {
      return file;
    }
  }

  openApprovalViewTab() {
    openLinkIntoTheNewTab(
      `/reviews/feed?${getApprovalSearch({ location: { id: this.state.customer.id, type: "CUSTOMER" } })}`
    );
  }

  openCustomerReportTab() {
    openLinkIntoTheNewTab(`/report/customer?location%5Btype%5D=CUSTOMER&location%5Bid%5D=${this.state.customer.id}`);
  }

  setLabels(locationLabels) {
    this.setState({ dirty: true, customer: { ...this.state.customer, locationLabels } });
  }

  isActiveTab = tab => {
    const { pathname } = this.props.location;
    const activeTabByPath = Object.values(TABS).find(({ path }) => !!path && pathname.endsWith(path));
    if ((!activeTabByPath && !tab.path) || activeTabByPath?.label === tab.label) {
      return true;
    }
    return false;
  };

  setTabPath = tab => {
    const pathId = this.state.customer.id || "new";
    const tabPath = tab.path || "";
    this.props.history.push(`/customers/${pathId}${tabPath}`);
  };

  get tabsOptions() {
    return Object.values(TABS).map(tab => ({
      label: tab.label,
      active: this.isActiveTab(tab),
      onClick: () => this.setTabPath(tab),
      hide: (!!tab.permissions && !isCurrentUserInGroup(tab.permissions)) || (!!tab.path && !this.state.customer.id)
    }));
  }

  render() {
    const { currentAgency } = this.props;
    let brands;
    if (this.state.customer.brands && this.state.customer.brands.length > 0) {
      brands = this.state.customer.brands.split(",");
    }
    let alternateNames;
    if (this.state.customer.alternateNames && this.state.customer.alternateNames.length > 0) {
      alternateNames = this.state.customer.alternateNames.split(",");
    }
    const isLocationManager = isCurrentUserInGroup(LOCATION_MANAGER_PERMS);
    const isNewCustomer = !this.props.match.params.id;

    return (
      <div>
        <WWTabs className="bg-white pt-2" tabs={this.tabsOptions} />
        <div className="py-4 px-4 container-fluid">
          {this.isActiveTab(TABS.sms) && (
            <SmsSetup
              customer={this.state.customer}
              contacts={this.state.contacts}
              onPhoneAssigned={smsPhoneNumberId =>
                this.setState({ customer: { ...this.state.customer, smsPhoneNumberId }, dirty: true })
              }
              onPhoneRemoved={() =>
                this.setState({
                  customer: { ...this.state.customer, smsPhoneNumberId: undefined },
                  dirty: true
                })
              }
            />
          )}
          {this.isActiveTab(TABS.info) && (
            <>
              {(this.state.dirty || this.accountIsChanged) && this.formErrors.length === 0 && (
                <SaveBar
                  prompt="Save changes?"
                  confirmationPrompt="Save changes?"
                  onSave={this.doSave}
                  disableSave={this.state.isSaving}
                />
              )}
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <CardTitle>Customer Details</CardTitle>
                      <CardHeaderActions>
                        {this.state.customer.id && (
                          <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                            <ButtonWConfirm color="primary" onClick={this.sendReport}>
                              Send Report
                            </ButtonWConfirm>
                          </AuthorizationRequiredToRender>
                        )}
                      </CardHeaderActions>
                    </CardHeader>
                    <CardBody>
                      <div className="card-nav">
                        {this.state.customer.id && (
                          <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                            <Button onClick={this.openCustomerReportTab}>View Report</Button>
                            <Button onClick={this.openApprovalViewTab}>Approval View</Button>
                            <Button onClick={() => this.props.history.push(this.state.customer.id + "/history")}>
                              History
                            </Button>
                          </AuthorizationRequiredToRender>
                        )}
                        <ImportFromHubspotAction
                          currentAgencyName={currentAgency.name}
                          onPickCustomer={this.populateFromCrm}
                          enabled={isNewCustomer}
                        />
                      </div>
                      {this.formErrors.length > 0 && <StatusMessages errors={this.formErrors} />}
                      <Form className="form-horizontal">
                        <InputField
                          name="companyName"
                          value={this.state.customer.companyName}
                          label="Name"
                          onChange={this.updateCustomerState}
                          disabled={!isLocationManager}
                          inline={false}
                        />
                        <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                          <FormField inline={false} name="account" label="Account">
                            <AccountTypeaheadField
                              name="account"
                              filterBy={() => true}
                              selected={this.state.customer?.account ? [this.state.customer.account] : []}
                              onChange={this.updateCustomerState}
                              selectOnlyOption={isNewCustomer}
                            />
                          </FormField>
                        </AuthorizationRequiredToRender>
                        <AddressInput
                          fullAddress={pick(
                            [
                              "countryCode",
                              "streetNumber",
                              "streetName",
                              "address2",
                              "locality",
                              "administrativeArea",
                              "postalCode"
                            ],
                            this.state.customer
                          )}
                          legacyAddress={this.state.customer.address}
                          onChange={this.updateCustomerState}
                          isAllowedToEdit={isLocationManager}
                        />
                        <FormField inline={false} name="businessPhone" label="Business Phone">
                          <PhoneNumberField
                            style={{ width: "100%" }}
                            onValueChange={values =>
                              this.updateCustomerState({
                                target: {
                                  value: values.value,
                                  name: "businessPhone"
                                }
                              })
                            }
                            value={this.state.customer.businessPhone}
                            placeholder="Business phone"
                          />
                        </FormField>
                        <HorizontalSelectField
                          name="timezone"
                          label="Time Zone"
                          options={timezones}
                          value={timezones.filter(tz => tz.name === this.state.customer.timezone)[0]}
                          getOptionLabel={tz => `(GMT ${tz.offset}) - ${tz.label}`}
                          getOptionValue={tz => tz.name}
                          onChange={this.updateCustomerState}
                          disabled={!isLocationManager}
                          inline={false}
                        />
                        <InputField
                          name="locationCode"
                          value={this.state.customer.locationCode}
                          label="Location Code"
                          onChange={this.updateCustomerState}
                          inline={false}
                        />
                        <AuthorizationRequiredToRender
                          roles={[permissions.REVIEW_INVITE_MANAGE, permissions.AGENCY_ADMIN]}
                        >
                          <InputField
                            name="inviteLandingPageSlug"
                            value={this.state.customer.inviteLandingPageSlug}
                            label="Landing Page Slug"
                            onChange={this.updateCustomerState}
                            valid={/^[A-Za-z0-9]*$/.test(this.state.customer.inviteLandingPageSlug) ? undefined : false}
                            feedback="Can only contain letters or numbers"
                            disabled={!isLocationManager}
                            inline={false}
                          />
                          {this.state.customer.inviteLandingPageSlug && (
                            <FormField label="Landing Page URL" inline={false}>
                              <a
                                className="me-2"
                                href={`${INVITE_LANDING_PAGE_PREFIX}${this.state.customer.inviteLandingPageSlug}`}
                              >
                                {`${INVITE_LANDING_PAGE_PREFIX}${this.state.customer.inviteLandingPageSlug}`}
                              </a>
                              <CopyButton
                                text={`${INVITE_LANDING_PAGE_PREFIX}${this.state.customer.inviteLandingPageSlug}`}
                              />
                            </FormField>
                          )}
                          <HorizontalSelectField
                            async={true}
                            name="brands"
                            value={brands}
                            label="Brands"
                            isMulti={true}
                            creatable={true}
                            simpleValue={true}
                            loadOptions={(inputValue, callback) => {
                              this.loadBrandOptions().then(options => {
                                callback(_.filter(options, o => _.startsWith(_.toLower(o), _.toLower(inputValue))));
                              });
                            }}
                            onChange={this.updateCustomerState}
                            inline={false}
                          />

                          <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                            <InputField
                              name="notes"
                              label="Notes"
                              type="textarea"
                              style={{ height: "100px" }}
                              onChange={this.updateCustomerState}
                              value={this.state.customer.notes}
                              inline={false}
                            />
                          </AuthorizationRequiredToRender>
                          <Labeled
                            name="hipaaCompliant"
                            checked={this.state.customer.hipaaCompliant}
                            onChange={this.updateCustomerState}
                            label="HIPAA Compliant Location"
                          />
                          <label className="text-muted fw-normal form-label">Logo</label>
                          <MediaCard
                            name="Company Logo"
                            media={this.state.customer.companyLogo}
                            showMedia={!!this.state.customer.companyLogo}
                            showInput={
                              isCurrentUserInGroup(permissions.LOCATION_MANAGE)
                                ? !this.state.customer.companyLogo
                                : false
                            }
                            inputName="companyLogo"
                            value={this.state.customer.companyLogo}
                            disabled={!isLocationManager}
                            helperText="The file should be image of less than 2MB size"
                            deleteCallback={() => this.updateCustomerField("companyLogo", undefined)}
                            preProcessingCallback={this.validateFile}
                            onSuccessCallback={media => this.updateCustomerField("companyLogo", media[0])}
                            onFailCallback={errorCaughtNotifier(this.props.notify)}
                          />
                        </AuthorizationRequiredToRender>
                        <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                          <HorizontalSelectField
                            components={{
                              DropdownIndicator: null
                            }}
                            name="alternateNames"
                            value={alternateNames}
                            label="Alternate Names"
                            isMulti={true}
                            creatable={true}
                            simpleValue={true}
                            onChange={this.updateCustomerState}
                            inline={false}
                          />
                          <HorizontalSelectField
                            name="reviewTagSet"
                            value={{
                              label: _.get(this.state.customer, "reviewTagSet.name"),
                              value: this.state.customer.reviewTagSet
                            }}
                            label="Review Tag Set"
                            multi={false}
                            creatable={false}
                            defaultOptions={true}
                            isSearchable={false}
                            async={false}
                            options={this.state.reviewTagSetOptions}
                            onChange={this.updateCustomerState}
                            inline={false}
                          />
                          <HorizontalSelectField
                            name="status"
                            value={this.state.customer.status}
                            label="Status"
                            simpleValue={true}
                            options={CUSTOMER_STATUSES}
                            onChange={this.updateCustomerState}
                            inline={false}
                          />
                          <FormField name="activeDate" label="Active Date" inline={false}>
                            <div className="d-flex content-stretch flex-nowrap flex-grow">
                              <UncontrolledDropdown className="flex-grow-1">
                                <DropdownToggle tag="div">
                                  <InputGroup>
                                    <InputGroupText className="fa fa-calendar" />
                                    <Input
                                      type="text"
                                      onChange={noop}
                                      value={
                                        this.state.customer.activeDate
                                          ? moment(this.state.customer.activeDate).format("L")
                                          : ""
                                      }
                                      placeholder="select activation date"
                                    />
                                  </InputGroup>
                                </DropdownToggle>
                                <DropdownMenu className="p-0 bg-transparent border-0 overflow-visible" tag="div">
                                  <DatePicker
                                    start={moment(this.state.customer.activeDate)}
                                    onStartChange={date => this.updateCustomerField("activeDate", date.format())}
                                    range={false}
                                  />
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              <WWButton
                                color="warning"
                                iconClass="fa fa-remove"
                                className="ms-2"
                                onClick={() => this.updateCustomerField("activeDate", undefined)}
                                disabled={!this.state.customer.activeDate}
                              />
                            </div>
                          </FormField>
                          <FormField name="deactivationDate" label="Scheduled Deactivation Date" inline={false}>
                            <div className="d-flex content-stretch flex-nowrap flex-grow">
                              <UncontrolledDropdown className="flex-grow-1">
                                <DropdownToggle tag="div">
                                  <InputGroup>
                                    <InputGroupText className="fa fa-calendar" />
                                    <Input
                                      type="text"
                                      onChange={noop}
                                      value={
                                        this.state.customer.deactivationDate
                                          ? moment(this.state.customer.deactivationDate).format("L")
                                          : ""
                                      }
                                      placeholder="select deactivation date"
                                    />
                                  </InputGroup>
                                </DropdownToggle>
                                <DropdownMenu className="p-0 bg-transparent border-0 overflow-visible" tag="div">
                                  <DatePicker
                                    start={moment(this.state.customer.deactivationDate)}
                                    onStartChange={date => this.updateCustomerField("deactivationDate", date.format())}
                                    range={false}
                                  />
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              <WWButton
                                iconClass="fa fa-remove"
                                color="warning"
                                className="ms-2"
                                onClick={() => this.updateCustomerField("deactivationDate", undefined)}
                                disabled={!this.state.customer.deactivationDate}
                              />
                            </div>
                          </FormField>
                        </AuthorizationRequiredToRender>
                      </Form>
                      <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                        {this.state.customer.id && (
                          <ButtonWConfirm
                            color="danger"
                            className="btn-center"
                            label="Delete Customer"
                            onClick={this.deleteCustomer}
                          >
                            Delete
                          </ButtonWConfirm>
                        )}
                      </AuthorizationRequiredToRender>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle style={{ marginBottom: "0.5rem" }}>Labels</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FieldLabel>
                        Labels allow for categorizing locations using custom naming conventions. Labels can be used as
                        filters on locations, listings, and reports.
                      </FieldLabel>
                      <LabelMultiSelector
                        split
                        allowNew
                        labels={this.state.customer.locationLabels}
                        onChange={this.setLabels}
                      />
                    </CardBody>
                  </Card>
                  <AuthorizationRequiredToRender roles={[permissions.WIDEWAIL_ADMIN]}>
                    <Row>
                      <Col>
                        <Card>
                          <CardHeader>
                            <CardTitle>Agency</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <AgencySelector customerId={this.state.customer.id} currentAgency={currentAgency} />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </AuthorizationRequiredToRender>
                  <AuthorizationRequiredToRender roles={[permissions.LOCATION_MANAGE, permissions.CREATE_SHARE]}>
                    <Row>
                      <Col>
                        <Card>
                          <CardHeader>
                            <CardTitle>Custom Fields</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Table>
                              <THead>
                                <TR>
                                  <TH>Field</TH>
                                  <TH>Value</TH>
                                  <TH></TH>
                                </TR>
                              </THead>
                              <TBody>
                                {_.map(this.state.customFieldPairs, (cf, index) => (
                                  <CustomFieldListItem
                                    key={index}
                                    customField={cf}
                                    index={index}
                                    updateCustomField={() => this.updateCustomField(cf, index)}
                                    removeCustomField={() => this.removeCustomField(index)}
                                  />
                                ))}
                              </TBody>
                            </Table>
                            <div style={{ textAlign: "right" }}>
                              <WWButton
                                className="pe-0 ta-l"
                                iconClass="fa fa-plus"
                                color="link"
                                onClick={() => this.addCustomField()}
                              >
                                Add Field
                              </WWButton>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </AuthorizationRequiredToRender>
                  {!isCurrentUserInGroup("AGENCY_ADMIN") && (
                    <>
                      {!this.hasDmsLink() && this.isReviewLeadImportProductActive ? (
                        <>
                          <Row>
                            <Col className="text-center mb-4">
                              <UncontrolledButtonDropdown>
                                <DropdownToggle tag="div" color="primary">
                                  <Button id="caret" color="primary">
                                    {"Add DMS Link"}
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {DMS_SITES.filter(s => s.linkable)
                                    .sort((a, b) => a.label.localeCompare(b.label))
                                    .map(s => (
                                      <DropdownItem
                                        key={s.source}
                                        onClick={() => this.addSite(s.customerField, s.source)}
                                      >
                                        <i className={`fa ${s.icon} me-2`} />
                                        {s.label}
                                      </DropdownItem>
                                    ))}
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          {_.size(_.get(this.state.customer, "dealertrackDmsInfo")) > 0 && (
                            <Row>
                              <Col>
                                <Card>
                                  <CardHeader>
                                    <CardTitle>
                                      <i className={`me-1 fa ${bySource("DEALERTRACK_DMS").icon}`} />
                                      Dealertrack DMS
                                    </CardTitle>
                                  </CardHeader>
                                  <CardBody>
                                    <DealertrackDmsLinking
                                      customer={this.state.customer}
                                      updateSiteField={this.updateSiteField}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          )}
                          {_.size(_.get(this.state.customer, "cdkDmsInfo")) > 0 && (
                            <Row>
                              <Col>
                                <Card>
                                  <CardHeader>
                                    <CardTitle>
                                      <i className={`me-1 fa ${bySource("CDK_DMS").icon}`} />
                                      CDK DMS
                                    </CardTitle>
                                  </CardHeader>
                                  <CardBody>
                                    <CdkDmsLinking
                                      customer={this.state.customer}
                                      updateSiteField={this.updateSiteField}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          )}
                          {_.size(_.get(this.state.customer, "cdkFortellisInfo")) > 0 && (
                            <Row>
                              <Col>
                                <Card>
                                  <CardHeader>
                                    <CardTitle>
                                      <i className={`me-1 fa ${bySource("CDK_FORTELLIS").icon}`} />
                                      CDK Fortellis
                                    </CardTitle>
                                  </CardHeader>
                                  <CardBody>
                                    <CdkFortellisLinking
                                      customer={this.state.customer}
                                      onRemove={this.updateSiteField}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          )}
                          {_.size(_.get(this.state.customer, "vinSolutionsInfo")) > 0 && (
                            <Row>
                              <Col>
                                <Card>
                                  <CardHeader>
                                    <CardTitle>
                                      <i className={`me-1 fa ${bySource("VIN_SOLUTIONS").icon}`} />
                                      VinSolutions
                                    </CardTitle>
                                  </CardHeader>
                                  <CardBody>
                                    <VinSolutionsLinking
                                      customer={this.state.customer}
                                      updateSiteField={this.updateSiteField}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          )}
                          {_.size(_.get(this.state.customer, "dealerbuiltInfo")) > 0 && (
                            <Row>
                              <Col>
                                <Card>
                                  <CardHeader>
                                    <CardTitle>
                                      <i className={`me-1 fa ${bySource("DEALERBUILT").icon}`} />
                                      DealerBuilt
                                    </CardTitle>
                                  </CardHeader>
                                  <CardBody>
                                    <DealerbuiltLinking
                                      customer={this.state.customer}
                                      onRemove={this.updateSiteField}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          )}

                          {/* {_.size(_.get(this.state.customer, bySource("HUBSPOT").customerField)) > 0 && ( */}
                          {/* )} */}
                        </>
                      )}
                    </>
                  )}
                </Col>
                <Col>
                  {!isCurrentUserInGroup("AGENCY_ADMIN") && currentAgency.onboardingCalendarLink && (
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            <p>
                              If you would like a guided walk-though of setting up your location in Widewail use the
                              link below to schedule a screen share with an onboarding team representative.
                            </p>
                            <ScheduleMeeting calendar={currentAgency.onboardingCalendarLink} />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                  <AuthorizationRequiredToRender roles={[permissions.LOCATION_MANAGE, permissions.AGENCY_ADMIN]}>
                    <Row>
                      <Col>
                        <Card>
                          <AuthorizationRequiredToRender roles={[permissions.CONTACT_MANAGE]}>
                            <p>
                              Add members of your team to this location. Adding members allows people to approve review
                              responses or have their contact information added to responses.
                            </p>
                          </AuthorizationRequiredToRender>
                          <CardHeader>
                            <CardTitle>Users</CardTitle>
                            <CardHeaderActions>
                              <AuthorizationRequiredToRender roles={[permissions.CONTACT_MANAGE]}>
                                <Link
                                  to={`/customers/contacts/new?customerId=${this.state.customer.id}&type=WIDEWAIL_USER`}
                                >
                                  <CardHeaderAction>New user</CardHeaderAction>
                                </Link>
                              </AuthorizationRequiredToRender>
                              <CardHeaderAction
                                title="Send an email to all contacts"
                                onClick={() =>
                                  window.open("mailto:" + _.join(_.map(this.state.contacts, "email")), "_blank")
                                }
                              >
                                New Email
                              </CardHeaderAction>
                            </CardHeaderActions>
                          </CardHeader>
                          <CardBody>
                            <ModalsContext.Consumer>
                              {riser => (
                                <ListField
                                  placeholder="Search Contacts..."
                                  members={this.state.contacts}
                                  onRemove={this.removeContact}
                                  onRemoveAll={() =>
                                    autoclose(
                                      riseConfimationDialog(riser)({
                                        title: "Are you sure you want to remove all contacts from this location?"
                                      })
                                    ).then(confirmed => {
                                      confirmed && this.removeAllContactsFromCustomer();
                                    })
                                  }
                                  getMemberValue={({ id }) => id}
                                  getMemberLabel={({ id, name, title, tags }) => (
                                    <Link to={`/customers/contacts/${id}`}>
                                      {join(
                                        [name, title, tags?.indexOf("Signatory") >= 0 ? "Signatory" : undefined],
                                        " - "
                                      )}
                                    </Link>
                                  )}
                                >
                                  <ContactTypeahead
                                    minLength={3}
                                    filterFn={o => !this.state.contacts?.map(c => c.id).includes(o.id)}
                                    onChange={(selected = []) => {
                                      const contact = get(selected, "[0]");
                                      contact?.id && this.linkContact(contact);
                                    }}
                                  />
                                </ListField>
                              )}
                            </ModalsContext.Consumer>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    {false && (
                      <AuthorizationRequiredToRender roles={[permissions.CREATE_SHARE]}>
                        <Row>
                          <Col>
                            <Card>
                              <CardHeader className="flex-wrap">
                                <CardTitle>Advocates</CardTitle>
                                <CardHeaderActions>
                                  <Link
                                    to={`/customers/contacts/new?customerId=${this.state.customer.id}&type=SHARE_CONTACT`}
                                  >
                                    <CardHeaderAction>New</CardHeaderAction>
                                  </Link>
                                  {this.state.customer.id && (
                                    <CardHeaderAction
                                      title="Upload a new advocates file"
                                      onClick={() => this.setState({ uploadAdvocates: true })}
                                    >
                                      Upload
                                    </CardHeaderAction>
                                  )}
                                </CardHeaderActions>
                              </CardHeader>
                              <CardBody>
                                <ListField
                                  placeholder="Search Contacts..."
                                  members={this.state.shareContacts}
                                  page={this.state.shareContactsPage}
                                  onPageChange={page => this.loadShareContacts(this.state.customer.id, page)}
                                  onRemove={this.removeContact}
                                  getMemberValue={({ id }) => id}
                                  getMemberLabel={({ id, name, title }) => (
                                    <Link to={`/customers/contacts/${id}`}>{join([name, title], " - ")}</Link>
                                  )}
                                >
                                  <ContactTypeahead
                                    minLength={3}
                                    type={CONTACT_TYPE_SHARE_CONTACT}
                                    onChange={(selected = []) => {
                                      const contact = get(selected, "[0]");
                                      contact?.id && this.linkContact(contact);
                                    }}
                                  />
                                </ListField>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </AuthorizationRequiredToRender>
                    )}
                  </AuthorizationRequiredToRender>
                  <ProductsCard
                    customer={this.state.customer}
                    updateCustomerProducts={this.updateCustomerProducts}
                    updateCustomer={this.updateCustomerField}
                  />
                  <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                    <Row>
                      <Col>
                        <Card>
                          <Card className="bg-light border-0 d-flex justify-content-center align-items-center">
                            <h4 className="mb-2">External Site Linking</h4>
                            <p className="text-center">
                              Copy link below to connect Facebook and Google pages without a Widewail Login
                            </p>
                            <CopyButton type="button" text={ONBOARDING_PREFIX + this.state.customer.id}>
                              COPY LINK
                            </CopyButton>
                          </Card>
                          <CardHeader>
                            <CardTitle>Sites</CardTitle>
                            <CardHeaderActions>
                              <UncontrolledButtonDropdown>
                                <DropdownToggle tag="div">
                                  <CardHeaderAction>Add Site</CardHeaderAction>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {SITES.filter(s => s.linkable && (!s.groups || isCurrentUserInGroup(s.groups)))
                                    .sort((a, b) => a.label.localeCompare(b.label))
                                    .map(s => (
                                      <DropdownItem
                                        key={s.source}
                                        onClick={() => this.addSite(s.customerField, s.source)}
                                      >
                                        <i className={`fa ${s.icon} me-2`} />
                                        {s.label}
                                      </DropdownItem>
                                    ))}
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                            </CardHeaderActions>
                          </CardHeader>
                          <CardBody>
                            {_.map(this.state.linkedSites, (info, i) => (
                              <React.Fragment key={info?.id || i}>
                                <hr className="border-top w-100 my-4" />
                                <AdminLinking
                                  info={info}
                                  customer={this.state.customer}
                                  onRemove={this.removeSite}
                                  onSave={this.saveSite}
                                  onDisabledToggled={this.toggleSiteDisabledState}
                                />
                              </React.Fragment>
                            ))}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </AuthorizationRequiredToRender>
                </Col>
              </Row>

              {/* <CustomerMessageComposer
          isOpen={this.state.contactCustomer}
          customerId={this.state.customer.id}
          onClose={() => this.setState({ contactCustomer: false })}
        /> */}
              <AdvocateUpload
                isOpen={this.state.uploadAdvocates}
                customerId={this.state.customer.id}
                onClose={() => this.setState({ uploadAdvocates: false })}
                uploadShareContacts={event => this.uploadShareContacts(event)}
              />
            </>
          )}
          {this.isActiveTab(TABS.siteIntegrations) && (
            <LinkedSitesTable
              onRemove={this.removeSite}
              reload={() => {
                this.loadCustomer(this.state.customer.id);
              }}
              updateCustomerField={this.updateCustomerField}
              currentAgency={currentAgency}
              customer={this.state.customer}
              linkedSites={this.state.linkedSites}
            />
          )}
          {this.isActiveTab(TABS.responderNotes) && (
            <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
              <ResponderNotesPage
                customerId={this.state.customer.id}
                tags={this.state.customer.reviewTagSet?.tags}
                basePath={`/customers/${this.state.customer.id}`}
              />
            </AuthorizationRequiredToRender>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentAgency: state.currentAgency
  };
}

function CustomFieldListItem({ customField, index, updateCustomField, removeCustomField }) {
  return (
    <TR>
      <TD>
        <Input
          name={"fieldName_" + index}
          value={customField[0]}
          placeholder="Field name"
          onChange={e => {
            customField[0] = e.target.value;
            updateCustomField(customField, index);
          }}
        />
      </TD>
      <TD>
        <Input
          name={"value_" + index}
          value={customField[1]}
          placeholder="Value"
          onChange={e => {
            customField[1] = e.target.value;
            updateCustomField(customField, index);
          }}
        />
      </TD>
      <TD>
        <WWButton
          iconClass="fa fa-trash"
          color="light"
          tooltip="Remove field"
          onClick={() => removeCustomField(customField, index)}
        />
      </TD>
    </TR>
  );
}

/// TODO: change all requests (removeContact, saveCustomer...) to React query in this wrapper OR rewrite EditCustomer as a functional component
const EditCustomerWithDataMutations = ({ ...props }) => {
  const addCustomer = useAccountAddCustomer();
  const removeCustomer = useAccountRemoveCustomer();
  return <EditCustomer {...props} dataMutations={{ addCustomer, removeCustomer }} />;
};

export default withAuthorization(permissions.LOCATION_MANAGE)(
  withLocalNotifications(withRouter(connect(mapStateToProps)(EditCustomerWithDataMutations)))
);

export function loadCustomerHistory(id) {
  return customerApi.loadCustomerHistory(id).then(res => res.data);
}
