import WWButton from "components/Buttons/WWButton";
import { DatePickerDropdownField } from "components/DateTime/DatePicker";
import Input from "components/Form/Input";
import Label from "components/Form/Label";
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { groupCustomerProducts } from "util/productsUtils";

import styles from "./Products.module.scss";
import moment from "moment";
const { container, groupedProductsGrid, checkbox, modal } = styles;

const isOutsideRangeProductFormat = day => day.isBefore(moment().add(1, "days"));

const ProductActivationDeactivationModal = ({ customerProducts, onUpdate, isOpen, toggle }) => {
  const [date, setDate] = useState(null);
  const [products, setProducts] = useState(
    customerProducts.map(product => {
      const { scheduledActivationDate, scheduledDeactivationDate, ...rest } = product;
      return rest;
    })
  );

  const groupedProducts = groupCustomerProducts(products);
  const updateProductsScheduledDate = type => event => {
    const productName = event.target.name;
    setProducts(prv =>
      prv.map(product =>
        product.product === productName
          ? { ...product, [type]: event.target.checked ? date.format("YYYY-MM-DD") : undefined }
          : product
      )
    );
  };

  return (
    <Modal centered className={modal} isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="px-4" toggle={toggle}>
        Schedule Product Activation/Deactivation
      </ModalHeader>
      <ModalBody className="px-4">
        <DatePickerDropdownField value={date} onChange={setDate} isOutsideRange={isOutsideRangeProductFormat} />
        <div className={container}>
          {groupedProducts?.map(({ category, products }) => (
            <div key={category}>
              <div className={groupedProductsGrid}>
                <span className="fs-lg mb-0">{category}</span> <span>Activate</span>
                <span>Deactivate</span>
                {products.map(({ product, active, scheduledActivationDate, scheduledDeactivationDate, label }) => (
                  <React.Fragment key={product}>
                    <Label className="fw-normal mb-0">{label}</Label>
                    <Input
                      className={checkbox}
                      disabled={!date || active}
                      type="checkbox"
                      name={product}
                      checked={!!scheduledActivationDate}
                      onChange={updateProductsScheduledDate("scheduledActivationDate")}
                    />
                    <Input
                      className={checkbox}
                      disabled={!date || !active}
                      type="checkbox"
                      name={product}
                      checked={!!scheduledDeactivationDate}
                      onChange={updateProductsScheduledDate("scheduledDeactivationDate")}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-2 d-flex justify-content-end">
          <WWButton className="me-2" color="danger" onClick={toggle}>
            Cancel
          </WWButton>
          <WWButton
            onClick={() => {
              onUpdate("products", products);
              toggle();
            }}
          >
            Schedule
          </WWButton>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProductActivationDeactivationModal;
