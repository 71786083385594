import React from "react";
import { Link } from "react-router-dom";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import Time from "components/DateTime/Time";
import { WWTBody, WWTD, WWTH, WWTHead, WWTR, WWTable } from "components/Table/WWTable";
import FilterTableSort from "components/FacetedSearch/Sort/FilterTableSort/FilterTableSort";
import Checkbox from "components/Checkbox/Checkbox";
import TableBodySkeleton from "../../components/Skeleton/TableBodySkeleton";

const SORT_OPTIONS_LAST_SEEN = ["lastSeenOn,asc", "lastSeenOn,desc"];
const SORT_OPTIONS_NAME = ["name,asc", "name,desc"];

const ContactsTable = ({ contacts, selectedContacts = [], onAllContactsToggle, onContactToggle, skeleton }) => {
  return (
    <WWTable>
      <WWTHead>
        <WWTR>
          <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
            <WWTH>
              <Checkbox
                checked={contacts.every(contact => selectedContacts.includes(contact.id))}
                onToggle={onAllContactsToggle}
              />
            </WWTH>
          </AuthorizationRequiredToRender>
          <WWTH>
            <FilterTableSort label="Name" options={SORT_OPTIONS_NAME} />
          </WWTH>
          <WWTH>Email</WWTH>
          <WWTH>Title</WWTH>
          <WWTH>Phone</WWTH>
          <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
            <WWTH>
              <FilterTableSort label="Last Seen" options={SORT_OPTIONS_LAST_SEEN} />
            </WWTH>
          </AuthorizationRequiredToRender>
        </WWTR>
      </WWTHead>
      {skeleton ? (
        <TableBodySkeleton rows={skeleton.rows} cols={skeleton.cols} />
      ) : (
        <WWTBody>
          {contacts.map(contact => (
            <ContactsTableRow
              key={contact.id}
              contact={contact}
              selectedContacts={selectedContacts}
              onContactToggle={onContactToggle(contact.id)}
            />
          ))}
        </WWTBody>
      )}
    </WWTable>
  );
};

const ContactsTableRow = ({ contact, selectedContacts, onContactToggle }) => {
  return (
    <WWTR>
      <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
        <WWTD>
          <Checkbox checked={selectedContacts.includes(contact.id)} onToggle={onContactToggle} />
        </WWTD>
      </AuthorizationRequiredToRender>
      <WWTD>
        <Link to={`/customers/contacts/${contact.id}`}>{contact.name}</Link>
      </WWTD>
      {["email", "title", "phone"].map(f => (
        <WWTD key={f}>{contact[f]}</WWTD>
      ))}
      <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
        <WWTD>
          <Time withIcon={false} format="dateSlash" date={contact.lastSeenOn} emptyDateText="N/A" />
        </WWTD>
      </AuthorizationRequiredToRender>
    </WWTR>
  );
};

export default ContactsTable;
