import { capitalize } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Messages } from "../Misc/StatusMessages";
import { NotificationsContext, useNotifier } from "./index";
import "./notification.scss";

const mapIconToTitle = icon => {
  switch (icon) {
    case "danger":
      return "Error";
    case "info":
      return "Information";
    default:
  }
  return capitalize(icon);
};

const mapIconToBg = icon => {
  switch (icon) {
    case "danger":
      return "bg-red-200";
    case "success":
      return "bg-green-200";
    case "info":
      return "bg-blue-200";
    default:
  }
  return "";
};

/**
 * Notification component itself. Contains predefined visuals
 * and some specific behavior.
 *
 * @param {{
 *   title?: string;
 *   icon?: string;
 *   children?: React.ReactNode;
 * }} param0
 */
export const Notification = ({ icon = "info", title = mapIconToTitle(icon), children }) => {
  return (
    <>
      <div>
        <span className="fw-bolder">{title}</span>
      </div>
      {children}
    </>
  );
};

/**
 * @param {(factory: (resolve: () => void; close: () => void) => JSX.Element) => Promise<void | () => void>} notifier
 * @returns {(opts: {title?: string; body?: JSX.Element; timeout?: number; icon?: string; closable?: boolean; ) => Promise<void | () => void>}
 */
const riseNotification = notifier => ({
  title = "",
  body = null,
  timeout = Number.POSITIVE_INFINITY,
  icon = "info",
  closable = true
} = {}) => {
  return notifier((resolve, close) => {
    if (!closable) {
      resolve(close);
    }
    return (
      <Notification icon={icon} onClose={closable ? () => resolve(close) : null} title={title} timeout={timeout}>
        {body}
      </Notification>
    );
  }).then(close => (closable ? close() : close));
};

/**
 * Actual hook to be used in application. If you are up to
 * rise some ui notifications in your components - use it and
 * call the returned callback when it is time to notify.
 */
export const useNotification = () => {
  const notifier = useNotifier();
  return useMemo(() => riseNotification(notifier), [notifier]);
};

export const withNotifications = BaseComponent => ({ ...props }) => {
  return (
    <NotificationsContext.Consumer>
      {notifier => <BaseComponent {...props} notify={riseNotification(notifier)} />}
    </NotificationsContext.Consumer>
  );
};

export const withLocalNotifications = BaseComponent => ({ ...props }) => {
  const tracker = useRef([]);
  useEffect(
    () => () => {
      tracker.current.forEach(v => v());
      tracker.current = [];
    },
    []
  );

  const wrapNotifier = useCallback(
    notifier => ({
      title = "",
      body = null,
      timeout = Number.POSITIVE_INFINITY,
      icon = "info",
      closable = true
    } = {}) => {
      return notifier((resolve, close) => {
        const closer = () => {
          tracker.current = tracker.current.filter(v => v !== close);
          close();
        };
        const resolver = () => resolve(closer);
        tracker.current = [...tracker.current, closer];
        if (!closable) {
          resolver();
        }
        return (
          <Notification icon={icon} onClose={closable ? resolver : null} title={title} timeout={timeout}>
            {body}
          </Notification>
        );
      }).then(close => (closable ? close() : close));
    },
    []
  );

  return (
    <NotificationsContext.Consumer>
      {notifier => <BaseComponent {...props} notify={wrapNotifier(notifier)} />}
    </NotificationsContext.Consumer>
  );
};

/**
 * @returns {(opts: {title?: string; body?: JSX.Element; timeout?: number; icon?: string; closable?: boolean; ) => Promise<void | () => void>}
 */
export const useLocalNotifications = () => {
  const notifier = useNotifier();
  const tracker = useRef([]);
  useEffect(
    () => () => {
      tracker.current.forEach(v => v());
      tracker.current = [];
    },
    []
  );

  return useCallback(
    ({ title = "", body = null, timeout = Number.POSITIVE_INFINITY, icon = "info", closable = true } = {}) =>
      notifier((resolve, close) => {
        const closer = () => {
          tracker.current = tracker.current.filter(v => v !== close);
          close();
        };
        const resolver = () => resolve(closer);
        tracker.current = [...tracker.current, closer];
        if (!closable) {
          resolver();
        }
        return (
          <Notification icon={icon} onClose={closable ? resolver : null} title={title} timeout={timeout}>
            {body}
          </Notification>
        );
      }).then(close => (closable ? close() : close)),
    [notifier]
  );
};

export const errorCaughtNotifier = (notifier, title = "") => err => {
  notifier({
    icon: "danger",
    title,
    body: <Messages messages={err.response?.data.errors || err} />
  });
};

export const useErrorNotification = () => {
  const notifier = useLocalNotifications();
  return useMemo(() => errorCaughtNotifier(notifier), [notifier]);
};
