import React, { useState } from "react";
import { Button } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import CopyButton from "../../../components/Buttons/CopyButton";
import HorizontalSelectField from "../../../components/Form/HorizontalSelectField";
import { debounce } from "lodash";
import { get } from "../../../api/apiClient";

export default class CdkFortellis {
  field() {
    return "cdkFortellisInfo";
  }

  add() {
    return AddCdkFortellis;
  }

  edit() {
    return EditCdkFortellis;
  }
}

const EditCdkFortellis = ({ info }) => {
  return (
    <>
      <FormField label="Subscription ID">
        <div className="text-nowrap">
          {info.subscriptionId} <CopyButton className="ms-1" text={info.subscriptionId} />
        </div>
      </FormField>
    </>
  );
};

const searchSubscriptions = debounce((filter, callback) => {
  get(["fortellis-subscription"], { params: { filter } })
    .then(response => response.data)
    .then(callback);
});

const AddCdkFortellis = ({ onSave }) => {
  const [subscriptionId, setSubscriptionId] = useState();

  return (
    <>
      <HorizontalSelectField
        name="fortellisSubscription"
        label="Fortellis Subscription"
        async={true}
        getOptionLabel={subscription => `${subscription.name} (${subscription.id})`}
        loadOptions={searchSubscriptions}
        onChange={e => setSubscriptionId(e.target.value.id)}
        isMulti={false}
        creatable={false}
        inline={false}
      />

      <Button
        color="primary"
        onClick={() =>
          onSave({
            subscriptionId,
            source: "CDK_FORTELLIS"
          })
        }
      >
        Link
      </Button>
    </>
  );
};
