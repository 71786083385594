import { useCallback, useMemo } from "react";
import { groupBy, filter, omitBy, isUndefined, isNil } from "lodash";
import memoize from "memoizee";
import { useListingMediaPatch, useListingMediaDelete } from "hooks/data/listingsDataHooks";
import { useLocalNotifications, useErrorNotification } from "components/Notifications/notification";
import MediaCard from "./MediaCard";
import ListingFieldLabel from "./ListingFieldLabel";
import { roundToOne } from "util/roundToOne";
import { DEFAULT_VIDEO_ACCEPT, DEFAULT_IMAGE_ACCEPT, MEDIA_RULES, MEDIA_SCHEME } from "data/listings";
import { MB, GB } from "constants/filesize";
import { FacebookWarningIcon } from "./AboutForm";

import styles from "./Forms.module.scss";
const { mediaGroup } = styles;

const getHelperText = memoize(({ resolution, ratio }) => {
  if (resolution && ratio) {
    return `${resolution.tall}px tall x ${resolution.wide}px wide and ${ratio} aspect ratio`;
  } else if (resolution) {
    return `${resolution.tall}px tall x ${resolution.wide}px wide`;
  } else if (ratio) {
    return `${ratio} aspect ratio`;
  } else {
    return false;
  }
});

const getMaxSizeLabel = memoize(maxSize => {
  const showInGB = maxSize >= GB;
  return `Up to ${roundToOne(maxSize / (showInGB ? GB : MB))} ${showInGB ? "GB" : "MB"}`;
});

const MediaForm = ({ values, listingId, setListing }) => {
  const notify = useLocalNotifications();
  const errorNotification = useErrorNotification();
  const saveMedia = useListingMediaPatch({ listingId });
  const deleteMedia = useListingMediaDelete({ listingId });

  const preProcessingMedia = useCallback(event => {
    const file = event.target.files[0];
    const size = file && file.size;
    const type = event.target.name;
    const mediaRule = MEDIA_RULES[type] || {};
    const maxSize = mediaRule.maxSize;
    const minSize = mediaRule.minSize;
    let validationMessage;
    if (maxSize && size > maxSize) {
      validationMessage = "The file is too large";
    } else if (minSize && size < minSize) {
      validationMessage = "The file is too small";
    }

    if (!isUndefined(validationMessage)) {
      notify({
        icon: "danger",
        body: validationMessage
      });
      return;
    }
    return file;
  }, []);

  const onSuccessCallback = useCallback(
    (mediaResponse, type) => {
      const mediaRule = MEDIA_RULES[type];
      saveMedia({
        mediaId: mediaResponse[0].id,
        type,
        override: mediaRule.override
      }).then(({ media }) => {
        setListing({ ...values, media });
      });
    },
    [values]
  );

  const deleteMediaById = useCallback(
    async mediaId => {
      try {
        const { media } = await deleteMedia(mediaId);
        setListing({ ...values, media });
      } catch (e) {
        errorNotification(e.response?.data?.error || e);
      }
    },
    [values]
  );

  const loadedMedia = useMemo(
    () => groupBy(filter(values.media || [], ["deleted", false]), "listingMediaType"),
    [values.media]
  );

  return MEDIA_SCHEME.map(({ header, format, mediaItems }, index) => (
    <section className="mb-5" key={index}>
      <h5 className="mb-1">{header}</h5>
      <div className="font-xs mb-3">
        {mediaItems[0].format && (
          <div>
            Format: <span className="text-muted">{mediaItems[0].format}</span>
          </div>
        )}
        {mediaItems[0].maxSize && (
          <div>
            File size: <span className="text-muted">{getMaxSizeLabel(mediaItems[0].maxSize)}</span>
          </div>
        )}
        {mediaItems[0].maxDuration && (
          <div>
            Duration: <span className="text-muted">Up to {mediaItems[0].maxDuration} seconds long</span>
          </div>
        )}
      </div>
      {mediaItems.map(({ name, accept, description, override, video, resolution, ratio, listingMediaType }) => {
        const loaded = loadedMedia[listingMediaType];
        const hasLoadedMedia = loaded?.length;
        const processingUploadText = `You may leave the page while the ${
          video ? "video" : "image"
        } finishes processing.`;
        const helperText = getHelperText({ resolution, ratio });
        const typeAccepted = accept || (video ? DEFAULT_VIDEO_ACCEPT : DEFAULT_IMAGE_ACCEPT);
        const apiOptions = omitBy(
          {
            validationRuleName: MEDIA_RULES[listingMediaType].validator,
            type: MEDIA_RULES[listingMediaType].video ? "VIDEO" : "IMAGE"
          },
          isUndefined
        );
        const mediaIsDisabled =
          !values.hasFullFacebookPermissions &&
          !isNil(values.facebookInfo) &&
          (listingMediaType === "FB_PROFILE" ||
            listingMediaType === "FB_COVER_PHOTO" ||
            listingMediaType === "FB_VIDEO");
        return (
          <>
            {name} <FacebookWarningIcon show={mediaIsDisabled} label={name} values={values} />
            <div>{description && <ListingFieldLabel>{description}</ListingFieldLabel>}</div>
            {hasLoadedMedia && (
              <div
                className={
                  mediaGroup + " mb-3 d-flex flex-wrap align-items-end justify-content-center justify-content-md-start"
                }
              >
                {loaded.map(({ media }) => (
                  <MediaCard
                    media={media}
                    isVideo={video}
                    name={name}
                    showMedia={hasLoadedMedia}
                    showInput={false}
                    deleteCallback={deleteMediaById}
                    helperText={helperText}
                    processingUploadText={processingUploadText}
                    accept={typeAccepted}
                    inputName={listingMediaType}
                    inline={false}
                    disabled={mediaIsDisabled}
                  />
                ))}
              </div>
            )}
            {(!hasLoadedMedia || !override) && (
              <div className="mb-3">
                <MediaCard
                  accept={typeAccepted}
                  isVideo={video}
                  inputName={listingMediaType}
                  options={apiOptions}
                  processingUploadText={processingUploadText}
                  helperText={helperText}
                  preProcessingCallback={preProcessingMedia}
                  onSuccessCallback={onSuccessCallback}
                  onFailCallback={e => errorNotification(e.response?.data?.error || e)}
                  inline={false}
                  disabled={mediaIsDisabled}
                />
              </div>
            )}
          </>
        );
      })}
    </section>
  ));
};

export default MediaForm;
