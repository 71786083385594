import { property } from "lodash";
import * as client from "./apiClient";

/**
 *
 * @param {string} customerId
 * @param {string} token
 */
export const createSquareInfo = (customerId, data, params) =>
  client.post(`customers/${customerId}/squareInfo`, data, { params }).then(property("data"));

/**
 *
 * @param {string} customerId
 * @param {string} siteId
 */
export function getSquareLocations(customerId, siteId, patch) {
  return client.get(["customers", customerId, "squareInfo", siteId, "locations"], patch);
}

/**
 *
 * @param {string} customerId
 * @param {string} siteId
 */
export function updateSquareInfo(customerId, siteId, patch) {
  return client.patch(["customers", customerId, "squareInfo", siteId], patch);
}

/**
 *
 * @param {*} squareInfoId
 */
export const deleteSquareInfo = (customerId, squareInfoId) =>
  client.doDelete(`customers/${customerId}/squareInfo/${squareInfoId}`).then(property("data"));
