import React, { useState, useEffect } from "react";

import InputField from "components/Form/InputField";
import PasswordInput from "components/Auth/PasswordInput";
import FormField from "components/Form/FormField";
import HorizontalSelectField from "components/Form/HorizontalSelectField";

const PLATFORM_OPTIONS = [
  { value: "SQL_SERVER", label: "SQL Server" },
  { value: "ORACLE", label: "Oracle" }
];

const SharedYardiCredentials = ({ credentials, onChange, onValidChange }) => {
  const [name, setName] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [databaseName, setDatabaseName] = useState();
  const [serverName, setServerName] = useState();
  const [serviceUrl, setServiceUrl] = useState();
  const [platform, setPlatform] = useState();

  useEffect(() => {
    setName(credentials?.name);
    setUsername(credentials?.username);
    setPassword(null);
    setDatabaseName(credentials?.databaseName);
    setServerName(credentials?.serverName);
    setServiceUrl(credentials?.serviceUrl);
    setPlatform(credentials?.platform);
  }, [credentials]);

  useEffect(() => {
    if (!password) {
      setPassword(null);
    }
  }, [password]);

  useEffect(() => {
    onValidChange(
      name && username && databaseName && serverName && serviceUrl && platform && (credentials?.password || password)
    );
    onChange({
      name,
      username,
      password,
      databaseName,
      serverName,
      serviceUrl,
      platform
    });
  }, [name, username, password, databaseName, serverName, serviceUrl, platform, credentials, onChange, onValidChange]);

  return (
    <>
      <InputField
        label="Name"
        value={name || ""}
        valid={!!name}
        onChange={event => setName(event.target.value)}
        inline={false}
      />
      <InputField
        label="Yardi username"
        value={username || ""}
        valid={!!username}
        onChange={event => setUsername(event.target.value)}
        inline={false}
      />
      <FormField tip={credentials?.password ? "Leave this empty to use the existing password" : ""}>
        <PasswordInput label="Password" value={password || ""} onChange={setPassword} inline={false} lastpass={false} />
      </FormField>
      <InputField
        label="Database name"
        value={databaseName || ""}
        valid={!!databaseName}
        onChange={event => setDatabaseName(event.target.value)}
      />
      <InputField
        label="Server"
        value={serverName || ""}
        valid={!!serverName}
        onChange={event => setServerName(event.target.value)}
      />
      <InputField
        label="Service URL"
        value={serviceUrl || ""}
        valid={!!serviceUrl}
        onChange={event => setServiceUrl(event.target.value)}
      />
      <HorizontalSelectField
        name="platform"
        inline={false}
        label="Platform"
        value={{ value: platform, label: PLATFORM_OPTIONS.find(option => option.value === platform)?.label }}
        simpleValue={false}
        isMulti={false}
        options={PLATFORM_OPTIONS}
        onChange={event => setPlatform(event.target.value.value)}
      />
    </>
  );
};

export default SharedYardiCredentials;
