import WWChip from "../../../WWChips/WWChip/WWChip";
import { useFilterValueState } from "hooks/filteringHooks";
import { valueOf } from "util/functionUtils";
import { includes, isArray, keys } from "lodash";
import { append, equals, isNil, of, pipe, prop, reject, until } from "ramda";
import { Card } from "components/Card";
import AdminProductSet from "components/Products/AdminProductSet";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loadAgency } from "api/agencyApi";
import { productsSet } from "data/products";

const ProductsFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <ProductsFilterBar key={props.name} className={className} {...props} />
  );
  const chip = ({ ...props }, className) => <ProductsChip key={props.name} {...props} className={className} />;
  const filterComponent = ({ ...props }, className) => (
    <ProductsFilterSidebar key={props.name} {...props} className={className} />
  );

  return {
    barComponent,
    chip,
    filterComponent
  };
};

export const ProductsFilterBar = ({ className, name, label, options, placeholder, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return null;
};

export const ProductsChip = ({ name, className, options, iconClass, defaultValue, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return value ? (
    Array.isArray(value) ? (
      value?.map(v => (
        <WWChip
          iconClass={iconClass}
          key={name + v}
          className="overflow-visible"
          onClick={() => setter(value.filter(chipValue => chipValue !== v))}
          {...props}
        >
          {v}
        </WWChip>
      ))
    ) : (
      <WWChip key={name} className="overflow-visible" onClick={() => setter(valueOf(defaultValue))} {...props}>
        {value}
      </WWChip>
    )
  ) : null;
};

export const ProductsFilterSidebar = ({ name, label, className, options, placeholder, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  const [products, setProducts] = useState([]);
  const currentAgency = useSelector(prop("currentAgency"));
  useEffect(() => {
    loadAgency(currentAgency.id).then(res =>
      setProducts(productsSet.filter(product => keys(res.data.defaultProducts).includes(product.product)))
    );
  }, [currentAgency]);
  const onChecked = useCallback(
    event => {
      setter(
        pipe(
          until(isArray, of(Array)),
          reject(isNil),
          event.target.checked ? append(event.target.name) : reject(equals(event.target.name))
        )(value)
      );
    },
    [value, setter]
  );
  return (
    <Card color="light">
      <div className="d-flex flex-column align-items-start">
        <AdminProductSet
          customer={{
            products: products.map(product =>
              includes(value, product.product) ? { ...product, active: true } : { ...product, active: false }
            )
          }}
          onUpdate={onChecked}
        />
      </div>
    </Card>
  );
};

export default ProductsFactory;
