import { parse } from "qs";
import { adjust, apply, fromPairs, ifElse, map, pipe, toPairs, unapply } from "ramda";
import { useMemo } from "react";
import { useLocation } from "react-router";
import { decode } from "../../util/functionUtils";

export const fallbackUnmapper = unapply(adjust(1, ifElse(Array.isArray, map(decode), decode)));
export const defaultDecoderFactory = (unmapper = fallbackUnmapper) => pipe(toPairs, map(apply(unmapper)), fromPairs);

const defaultDecoder = defaultDecoderFactory();
export default function useURLQueryValue(decoder = defaultDecoder) {
  const { search } = useLocation();
  return useMemo(() => decoder(parse(search.slice(1))), [search]);
}
