import map from "lodash/map";
import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Intervals, TICK_SIZE_DEFAULT, TICK_SIZE_SM } from "../../constants/chart";
import colors from "../../constants/colors";

const MIN_POINT_SIZE = 0.1;

const InteractiveBarChart = ({ chartData = { keys: [], data: [] }, options = {} }) => {
  return (
    <ResponsiveContainer {...(options.container || {})}>
      <BarChart data={chartData?.data || []} {...(options.chart || {})}>
        {options.legend !== null ? <Legend verticalAlign="top" iconSize={20} {...(options.legend || {})} /> : null}
        <CartesianGrid vertical={false} {...(options.grid || {})} />
        <XAxis
          scale="auto"
          tickSize={TICK_SIZE_DEFAULT}
          stroke={colors.chartStrokeGray}
          interval={Intervals.preserveStartEnd}
          {...(options.xAxis || {})}
        />
        <YAxis
          tickSize={TICK_SIZE_SM}
          stroke={colors.chartStrokeGray}
          interval={Intervals.preserveStartEnd}
          {...(options.yAxis || {})}
        />
        <Tooltip {...(options.tooltip || {})} />
        {map(chartData?.keys || [], ({ key, color }, i) => (
          <Bar
            key={key}
            dataKey={key}
            fill={color || colors.chartBlack}
            legendType="square"
            minPointSize={MIN_POINT_SIZE}
            isAnimationActive={!window.matchMedia("print").matches}
            {...(options.line || {})}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default InteractiveBarChart;
