import { noop } from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { getAuthoredReplies } from "../../../api/reportApi";
import { SlabCloud } from "../../../components/Charts/SlabCloud";
import { DashboardWidget } from "../../../components/Widgets/DashboardWidget";
import { currentContactName } from "../../../util/userUtils";

const AuthoredReplies = () => {
  const [data, setData] = useState([]);
  const name = useMemo(() => currentContactName(), []);

  useEffect(() => {
    getAuthoredReplies(moment.tz.guess())
      .then(replies => setData(replies.map(v => ({ id: v.type, ...v }))))
      .catch(noop);
  }, [setData]);

  return (
    <DashboardWidget title={`${name}'s Authored Replies`}>
      <SlabCloud data={data} renderer={authoredRepliesRenderer} />
    </DashboardWidget>
  );
};

const KEY = [
  {
    source: "TOTAL",
    label: "Total"
  },
  {
    source: "POSITIVES",
    label: "Positives"
  },
  {
    source: "NEGATIVES",
    label: "Negatives"
  },
  {
    source: "SHORT",
    label: "Short"
  },
  {
    source: "LONG",
    label: "Long"
  }
];

const authoredRepliesRenderer = key => {
  return key === "id"
    ? ({ type }) => (
        <span className="flex-grow-0 text-nowrap text-muted">{KEY.find(v => v.source === type)?.label || type}</span>
      )
    : key === "total"
    ? ({ value }) => (
        <h2 className="m-0 d-flex justify-content-center align-items-center flex-grow-1">
          {value === null ? "N/A" : value}
        </h2>
      )
    : () => <></>;
};

export default AuthoredReplies;
