import React from "react";
import CustomerTableRow from "./CustomerTableRow";
import { WWTable, WWTBody, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import Checkbox from "components/Checkbox/Checkbox";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import FilterTableSort from "components/FacetedSearch/Sort/FilterTableSort/FilterTableSort";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";

const CustomerTable = ({
  customers,
  editable = false,
  onDelete = null,
  onLocationToggle,
  onAllLocationToggle,
  selectedLocations = [],
  sortOptions,
  skeleton
}) => {
  return (
    <WWTable>
      <WWTHead>
        <WWTR>
          <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
            <WWTH>
              <Checkbox
                checked={customers.map(customer => customer.id).every(value => selectedLocations.includes(value))}
                onToggle={onAllLocationToggle}
              />
            </WWTH>
          </AuthorizationRequiredToRender>
          <WWTH>
            <FilterTableSort label="Name" options={sortOptions} />
          </WWTH>
          <WWTH>Brands</WWTH>
          <WWTH>Status</WWTH>
          <WWTH>Signatories</WWTH>
        </WWTR>
      </WWTHead>

      {skeleton ? (
        <TableBodySkeleton rows={skeleton.rows} cols={skeleton.cols} />
      ) : (
        <WWTBody>
          {customers &&
            customers.map(customer => (
              <CustomerTableRow
                onLocationToggle={onLocationToggle(customer.id)}
                selectedLocations={selectedLocations}
                customer={customer}
                key={customer.id}
                editable={editable}
                onDelete={onDelete}
              />
            ))}
        </WWTBody>
      )}
    </WWTable>
  );
};

export default CustomerTable;
