import React, { useCallback, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import classnames from "classnames";

import styles from "./WWAccordion.module.scss";
import { isFunction, isUndefined } from "lodash";
import { not } from "ramda";

const { card, cardHeader, cardBody, toggleIcon, captionText, borderlessClass, opened } = styles;

const WWAccordionSection = ({
  caption,
  subCaption,
  children,
  borderless,
  openOnInit,
  className,
  fontSize = "fs-4",
  showCarrot = true,
  bodyClassName,
  containerClassName,
  handleOpen,
  controlledOpen
}) => {
  const [internalOpen, setInternalOpen] = useState(openOnInit);
  const open = useCallback(() => {
    if (isFunction(handleOpen)) {
      return handleOpen(not);
    } else {
      return setInternalOpen(not);
    }
  }, [handleOpen]);
  const isOpen = useMemo(() => (isUndefined(controlledOpen) ? internalOpen : controlledOpen), [
    controlledOpen,
    internalOpen
  ]);

  return (
    <Card className={classnames(card, containerClassName)}>
      <CardHeader
        className={classnames(fontSize, cardHeader, className, {
          [borderlessClass]: borderless,
          [opened]: isOpen
        })}
        onClick={open}
      >
        <div className={captionText} data-cy={caption}>
          {caption}
          {subCaption && <div>{subCaption}</div>}
        </div>
        {showCarrot && (
          <div className={toggleIcon}>
            <i className={`fa fa-angle-${isOpen ? "up" : "down"}`} />
          </div>
        )}
      </CardHeader>
      <Collapse isOpen={isOpen}>
        {isOpen && (
          <CardBody>
            <div className={classnames(cardBody, bodyClassName)}>{children}</div>
          </CardBody>
        )}
      </Collapse>
    </Card>
  );
};

export default WWAccordionSection;
