import StatusInfo from "components/Table/StatusInfo/StatusInfo";
import Time from "components/DateTime/Time";

const LastSyncedInfo = ({ listing, isSyncModeOn, tooltipContainer }) => (
  <>
    <StatusInfo
      status={isSyncModeOn ? listing?.status || "Unknown" : "SYNCING DISABLED"}
      statusColorMapper={{ Unknown: "dark", OK: "success", PROCESSING: "warning", ERROR: "danger" }}
    />
    {listing?.lastUpdateOn ? (
      <>
        <Time
          className="fs-1 text-uppercase text-muted"
          withIcon={false}
          date={listing.lastUpdateOn}
          format="ago"
          tooltipContainer={tooltipContainer}
        />{" "}
        <span className="fs-1 text-uppercase text-muted">ago</span>
      </>
    ) : (
      <br />
    )}
  </>
);

export default LastSyncedInfo;
