import { useGet } from "hooks/dataHooks";
import { isUndefined, omit } from "lodash";
import { toPairs } from "ramda";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

const normalizeRequest = ({ filters, sort, pageNumber }) =>
  [
    ...toPairs({
      ...omit(filters, "location", "dates"),
      ...(!!filters?.location ? { [filters.location.type]: filters.location.value } : {}),
      ...("dates" in filters
        ? {
          startDate: filters.dates[0].format("YYYY-MM-DD"),
          endDate: filters.dates[1].format("YYYY-MM-DD")
        }
        : {})
    }),
    ["page", pageNumber],
    ["sort", sort]
  ].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

const normalizeResponse = data => {
  return { leads: data?._embedded?.reviewLeads, pageInfo: data?.page };
};

const queryKeys = {
  all: ["reviewLeads"],
  interactions: id => [...queryKeys.all, id, "interactions"]
};

/**
 *
 * @param {{[index: string]: any}} filters
 * @param {string} sorting
 * @param {[number, number]} page
 * @returns {import("react-query").UseQueryResult<{
 *  customer: {
 *     companyName: string;
 *     id: string
 *   }
 *   mobile: string;
 *   interactions: Array<{
 *     invitations: any[];
 *     representatives: string[];
 *     tags: string[];
 *     id: string;
 *   }>;
 *   email: string;
 *   name: string;
 *   id: string;
 * }>>}
 */
export default function useReviewLeads({ filters = [], sort, pageNumber }) {
  const params = useMemo(
    () =>
      normalizeRequest({
        filters,
        sort,
        pageNumber
      }),
    [filters, sort, pageNumber]
  );
  const queryKey = useMemo(() => ["/reviewLeads", params], [params]);
  const [get] = useGet("/reviewLeads", { params });
  const queryRun = useCallback(() => get().then(normalizeResponse), [get]);
  return useQuery(queryKey, queryRun, {
    enabled: !isUndefined(filters) && !isUndefined(sort) && !isUndefined(pageNumber)
  });
}

export const useReviewLeadInteractions = (id, page, size = 10) => {
  const queryKey = [queryKeys.interactions(id), page];
  const [queryFn] = useGet(queryKeys.interactions(id).join("/"), { params: { page, size } });

  return useQuery({
    queryKey,
    queryFn,
    enabled: !!id,
    keepPreviousData: true,
    select: data => {
      let {
        _embedded: { interactions = [] },
        page = {}
      } = data;

      return { interactions, pageInfo: page };
    }
  });
};
