import React, { useCallback, useMemo, useState } from "react";
import { Button, Collapse } from "reactstrap";
import InputField from "../../../components/Form/InputField";
import { useNativeInputState } from "../../../hooks/utilHooks";
import Labeled from "../../../components/Checkbox/Labeled/CheckboxLabeled";
import { updateAppfolioLink, updateYardiPropertyId } from "../../../api/customerApi";
import FormField from "../../../components/Form/FormField";

export default class Appfolio {
  field() {
    return "appfolioInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info, customer }) => {
  const [apiKey, setApiKey] = useState(info.apiKey);
  const [apiSecret, setApiSecret] = useState(info.apiSecret);
  const [siteId, setSiteId] = useState(info.siteId);
  const [initialImportMoveIn, setInitialImportMoveIn] = useState(info.importMoveIn);
  const [importMoveIn, setImportMoveIn] = useState(initialImportMoveIn);
  const [initialImportMoveOut, setInitialImportMoveOut] = useState(info.importMoveOut);
  const [importMoveOut, setImportMoveOut] = useState(initialImportMoveOut);
  const [initialImportMaintenance, setInitialImportMaintenance] = useState(info.importMaintenance);
  const [importMaintenance, setImportMaintenance] = useState(initialImportMaintenance);

  const patchReady = useMemo(
    () =>
      initialImportMoveIn !== importMoveIn ||
      initialImportMoveOut !== importMoveOut ||
      initialImportMaintenance !== importMaintenance,
    [
      initialImportMoveIn,
      importMoveIn,
      initialImportMoveOut,
      importMoveOut,
      initialImportMaintenance,
      importMaintenance
    ]
  );
  const saveToggles = useCallback(
    e => {
      updateAppfolioLink(customer.id, info.id, { importMoveIn, importMoveOut, importMaintenance }).then(() => {
        setInitialImportMoveIn(importMoveIn);
        setInitialImportMoveOut(importMoveOut);
        setInitialImportMaintenance(importMaintenance);
      });
    },
    [info, importMoveIn, importMoveOut, importMaintenance, customer]
  );

  return (
    <>
      <InputField
        type="text"
        label="API key"
        placeholder="API key"
        onChange={e => setApiKey(e.target.value)}
        value={apiKey}
        readOnly={true}
      />
      <InputField
        type="text"
        label="API Secret"
        placeholder="Api Secret"
        onChange={e => setApiSecret(e.target.value)}
        value={apiSecret}
        readOnly={true}
      />
      <InputField
        type="text"
        label="Site ID"
        placeholder="Site ID"
        onChange={e => setSiteId(e.target.value)}
        value={siteId}
        readOnly={true}
      />
      <Labeled name="importMoveIn" checked={importMoveIn} onToggle={setImportMoveIn} label="Import Move-in Leads" />
      <Labeled name="importMoveOut" checked={importMoveOut} onToggle={setImportMoveOut} label="Import Move-out Leads" />
      <Labeled
        name="importMaintenance"
        checked={importMaintenance}
        onToggle={setImportMaintenance}
        label="Import Maintenance Leads"
      />

      <Collapse isOpen={patchReady}>
        <FormField label=" ">
          <Button color="primary" onClick={saveToggles} disabled={!patchReady}>
            Update
          </Button>
        </FormField>
      </Collapse>
    </>
  );
};

const Add = ({ onSave }) => {
  const [apiKey, setApiKey] = useNativeInputState("");
  const [apiSecret, setApiSecret] = useNativeInputState("");
  const [siteId, setSiteId] = useNativeInputState("");
  const [importMoveIn, setImportMoveIn] = useState(true);
  const [importMoveOut, setImportMoveOut] = useState(true);
  const [importMaintenance, setImportMaintenance] = useState(true);
  const onLink = useCallback(
    () =>
      onSave({
        apiSecret,
        apiKey,
        siteId,
        importMoveIn,
        importMoveOut,
        importMaintenance,
        source: "APPFOLIO"
      }),
    [apiSecret, apiKey, siteId, importMoveIn, importMoveOut, importMaintenance, onSave]
  );

  return (
    <>
      <InputField type="text" label="API Key" placeholder="API Key" onChange={setApiKey} value={apiKey} />
      <InputField type="text" label="API Secret" placeholder="API Secret" onChange={setApiSecret} value={apiSecret} />
      <InputField type="text" label="Site ID" placeholder="Site ID" onChange={setSiteId} value={siteId} />
      <Labeled name="importMoveIn" checked={importMoveIn} onToggle={setImportMoveIn} label="Import Move-in Leads" />
      <Labeled name="importMoveOut" checked={importMoveOut} onToggle={setImportMoveOut} label="Import Move-out Leads" />
      <Labeled
        name="importMaintenance"
        checked={importMaintenance}
        onToggle={setImportMaintenance}
        label="Import Maintenance Leads"
      />
      <Button color="primary" disabled={!apiKey || !apiSecret || !siteId} onClick={onLink}>
        Link
      </Button>
    </>
  );
};
