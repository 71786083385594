import { useCallback } from "react";
import { startCase } from "lodash";
import { usePropertySitesValidationDialog } from "components/Modals/validation.modal";

/**
 * For Yardy, Propertyware, Rent manager, site linking
 * @param {array[boolean]} checkboxesValues Array of checkboxes values to validation
 * @param {string} [text=undefined] Text with list of checkboxes labels, comma separated
 */
export const useValidatePropertySitesCheckboxes = (checkboxesValues, text = undefined) => {
  const validationDialog = usePropertySitesValidationDialog();

  let valuesArray;
  let textToInsert;

  if (!checkboxesValues.length) {
    throw new Error("Checkboxes values array should not be empty.");
  }

  if (typeof checkboxesValues[0] === "object") {
    valuesArray = checkboxesValues.map(({ value }) => value);
    const textArray = checkboxesValues.map(({ label }) => startCase(label));
    const lastTextItem = textArray.pop();
    textToInsert = `${textArray.join(", ")}, or ${lastTextItem}`;
  } else {
    valuesArray = checkboxesValues;
    textToInsert = text;
  }

  return useCallback(() => {
    if (valuesArray.some(value => !!value)) {
      return Promise.resolve(true);
    }
    return validationDialog(textToInsert).then(() => Promise.resolve(false));
  }, [checkboxesValues]);
};
