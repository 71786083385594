import { identity } from "lodash";
import React, { useMemo } from "react";
import { Button, ButtonGroup, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useModal } from "./index";

/**
 * @param {(factory: (resolve: (v: boolean) => void) => JSX.Element) => Promise<{resolution: boolean; close: () => void}>} riser
 * @returns {(opts: {title?: string; allowNoOption?: boolean, options?: any[]; keyer?: (v: any) => string; renderer?: (v: any) => React.ReactNode}) => Promise<{resolution: T; close: () => void}}
 */
export const riseOptionsDialog = riser => ({
  title = "Pick an option",
  allowNoOption = true,
  options = [],
  renderer = identity,
  keyer = identity
} = {}) =>
  riser(resolve => (
    <>
      <ModalHeader className="d-flex justify-content-center">{title}</ModalHeader>
      <ModalBody className="d-flex">
        <ButtonGroup vertical className="m-auto">
          {options.map(option => (
            <Button className="mb-2" key={keyer(option)} color="secondary" onClick={() => resolve(option)}>
              {renderer(option)}
            </Button>
          ))}
        </ButtonGroup>
      </ModalBody>
      {allowNoOption ? (
        <ModalFooter>
          <Button color="warning" onClick={() => resolve(null)}>
            Cancel
          </Button>
        </ModalFooter>
      ) : null}
    </>
  ));

export const useOptionsDialog = () => {
  const riser = useModal();
  return useMemo(() => riseOptionsDialog(riser), [riser]);
};
