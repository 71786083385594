import styles from "./CheckboxLabeled.module.scss";

import { noop } from "lodash";
import Checkbox from "../Checkbox";
import classNames from "classnames";

export default function Labeled({ checked, disabled = false, onToggle = noop, label, className, ...props }) {
  return (
    <label className={classNames(styles.labeledCheckbox, className)}>
      <Checkbox checked={checked} disabled={disabled} onToggle={onToggle} data-cy={label} {...props} />
      <span className={classNames({ "ps-2": true, "text-muted": disabled })}>{label}</span>
    </label>
  );
}
