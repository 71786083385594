// uncomment this line if you have something to import from your scss module
// import {} from "./TextSkeleton.module.scss"

import Skeleton from "components/Skeleton";

export default function TextSkeleton({ rows = 5 }) {
  return (
    <>
      {[...Array(rows)].map((e, index) => (
        <Skeleton key={index} className="w-100" />
      ))}
    </>
  );
}
