import React from "react";
import withAuthorization from "./Authorization";
import { Row, Col } from "reactstrap";
import { permissions } from "./permissions";

export const WidewailAdmin = withAuthorization([permissions.WIDEWAIL_ADMIN]);
export const AgencyAdmin = withAuthorization([permissions.AGENCY_ADMIN]);

// Collection of helper components to provide easy authorized components

export const WidewailAdminRow = WidewailAdmin(Row);
export const WidewailAdminCol = WidewailAdmin(Col);
export const WidewailAdminFragment = WidewailAdmin(React.Fragment);

export const AgencyAdminRow = AgencyAdmin(Row);
export const AgencyAdminFragment = AgencyAdmin(React.Fragment);
