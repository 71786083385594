import { prop } from "ramda";
import { useSelector } from "react-redux";
import { usePost } from "./dataHooks";
import { useMutation } from "react-query";

const BASE_URL_AGENCIES = "agencies";

export const useCurrentAgency = () => {
  return useSelector(prop("currentAgency"));
};

export function useMigrateCustomerToAgency(customerId) {
  const queryPath = [BASE_URL_AGENCIES, "migrate-customer", customerId];
  const post = usePost();
  const mutation = useMutation(agencyId => post(queryPath, { data: { toAgency: agencyId } }));
  return mutation.mutateAsync;
}
