import { includes } from "lodash";
import keyBy from "lodash/keyBy";
import { permissions } from "../components/Auth/permissions";

/*
 * Configuration for thirdparty sites.
 {
   source: // source constant
   customerField: //field on the customer object where info is stored
   label: //display name
   icon: //site icon
   linkable: //does this site support linking in WW
   userLinking: //can a widewail user link this site own their own
   editable: //does this site support editing if it does not support linking
   invitable: //site is a supported invite destination
   rulesEditing: //does this dms support rules for importing
   queueable: //does this support queue import or disable import button 
   fieldsLabelValuePairs: //if this dms supports adding conditions add the value and display value here
   fieldTypeRestrictions: //restrictions on the data type of the field values
   isReviewSite: //the site hosts reviews and should be displayed in the review list and feed as an option
   isLifetimeRatingSite: //the site support lifetime ratings
 }
*/

const FIELD_TYPE = {
  NON_NEGATIVE_INTEGER: value => (value.match(/^[0-9]+$/) ? null : "Must be a non-negative integer"),
  oneOf: options => value =>
    includes(options, value) ? null : `Must be one of ${options.reduce((a, b) => `${a} or ${b}`)}`
};

export const SITE_SOURCE_NAMES = Object.freeze({
  apartmentRatings: "APARTMENT_RATINGS",
  apartmentsDotCom: "APARTMENTS_DOT_COM",
  appfolio: "APPFOLIO",
  boulevard: "BOULEVARD",
  carfax: "CARFAX",
  carGurus: "CAR_GURUS",
  carsDotCom: "CARS_DOT_COM",
  carvix: "CARVIX",
  ccc: "CCC",
  cdkDms: "CDK_DMS",
  cdkFortellis: "CDK_FORTELLIS",
  cmsMax: "CMS_MAX",
  datashake: "DATASHAKE",
  dealerDotCom: "DEALER_DOT_COM",
  dealerbuilt: "DEALERBUILT",
  dealerrater: "DEALERRATER",
  dealertrackDms: "DEALERTRACK_DMS",
  dealervault: "DEALERVAULT",
  directMessage: "DIRECT_MESSAGE",
  edmunds: "EDMUNDS",
  entrata: "ENTRATA",
  facebook: "FACEBOOK",
  google: "GOOGLE",
  hubspot: "HUBSPOT",
  inntopia: "INNTOPIA",
  inspectionSupportNetwork: "INSPECTION_SUPPORT_NETWORK",
  instagram: "INSTAGRAM",
  keap: "KEAP",
  linkedin: "LINKEDIN",
  neustar: "NEUSTAR",
  mindbody: "MINDBODY",
  openTable: "OPEN_TABLE",
  propertyware: "PROPERTYWARE",
  proResponse: "PRORESPONSE",
  realPage: "REAL_PAGE",
  rentManager: "RENT_MANAGER",
  resman: "RESMAN",
  revolutionParts: "REVOLUTION_PARTS",
  rome: "ROME",
  salesforce: "SALESFORCE",
  serviceFusion: "SERVICE_FUSION",
  serviceTitan: "SERVICE_TITAN",
  sevenrooms: "SEVENROOMS",
  square: "SQUARE",
  swickard: "SWICKARD",
  tekion: "TEKION",
  treez: "TREEZ",
  uberall: "UBERALL",
  vinSolutions: "VIN_SOLUTIONS",
  widewail: "WIDEWAIL",
  widewailSystem: "WIDEWAIL_SYSTEM",
  yardi: "YARDI",
  yardiMaintenance: "YARDI_MAINTENANCE",
  yelp: "YELP"
});

export const SITES = [
  {
    source: SITE_SOURCE_NAMES.google,
    customerField: "gmbInfo",
    label: "Google",
    icon: "fa-google",
    linkable: true,
    userLinking: true,
    invitable: true,
    queueable: true,
    isReviewSite: true,
    color: 0xea4235,
    isLifetimeRatingSite: true
  },
  {
    source: SITE_SOURCE_NAMES.widewail,
    customerField: "",
    label: "Widewail",
    icon: "",
    linkable: false
  },
  {
    source: SITE_SOURCE_NAMES.widewailSystem,
    customerField: "",
    label: "Widewail System",
    icon: "",
    linkable: false
  },
  {
    source: SITE_SOURCE_NAMES.facebook,
    customerField: "facebookInfo",
    label: "Facebook",
    icon: "fa-facebook",
    linkable: true,
    userLinking: true,
    invitable: true,
    queueable: true,
    isReviewSite: true,
    color: 0x1877f2,
    isLifetimeRatingSite: true
  },
  {
    source: SITE_SOURCE_NAMES.carsDotCom,
    customerField: "carsDotComInfo",
    label: "Cars.com",
    icon: "fa-car",
    linkable: true,
    userLinking: true,
    invitable: true,
    queueable: true,
    isReviewSite: true,
    color: 0x703b95,
    isLifetimeRatingSite: true
  },
  {
    source: SITE_SOURCE_NAMES.dealerrater,
    customerField: "dealerraterInfo",
    label: "Dealerrater",
    icon: "fa-check-circle-o",
    linkable: true,
    userLinking: true,
    queueable: true,
    invitable: true,
    isReviewSite: true,
    color: 0xf26a2b,
    isLifetimeRatingSite: true
  },
  {
    source: SITE_SOURCE_NAMES.yelp,
    customerField: "yelpInfo",
    label: "Yelp",
    icon: "fa-yelp",
    linkable: true,
    userLinking: true,
    isReviewSite: true,
    queueable: false
  },
  {
    source: SITE_SOURCE_NAMES.carGurus,
    customerField: "carGurusInfo",
    label: "CarGurus",
    icon: "fa-smile-o",
    linkable: true,
    userLinking: true,
    invitable: true,
    isReviewSite: true,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.instagram,
    customerField: "instagramInfo",
    label: "Instagram",
    icon: "fa-instagram",
    linkable: true,
    isReviewSite: true,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.edmunds,
    customerField: "edmundsInfo",
    label: "Edmunds",
    icon: "fa-car",
    linkable: true,
    userLinking: true,
    invitable: true,
    queueable: true,
    isReviewSite: true,
    color: 0x017ee5
  },
  {
    source: SITE_SOURCE_NAMES.carfax,
    customerField: "carfaxInfo",
    label: "Carfax",
    icon: "fa-car",
    linkable: true,
    userLinking: true,
    isReviewSite: true,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.dealertrackDms,
    customerField: "dealertrackDmsInfo",
    label: "Dealertrack DMS",
    icon: "fa-car",
    linkable: true,
    isDms: true,
    rulesEditing: true,
    feedPreview: true,
    queueable: true,
    fieldsLabelValuePairs: {
      stocknumber: "stock number",
      make: "make",
      franchiseCode: "sales group (aka franchise code)",
      opCode: "op code",
      serviceType: "service type"
    }
  },
  {
    source: SITE_SOURCE_NAMES.vinSolutions,
    customerField: "vinSolutionsInfo",
    label: "VinSolutions",
    icon: "fa-car",
    linkable: true,
    isDms: true,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.cdkDms,
    customerField: "cdkDmsInfo",
    label: "CDK DMS",
    icon: "fa-car",
    linkable: true,
    isDms: true,
    rulesEditing: true,
    feedPreview: true,
    queueable: true,
    fieldsLabelValuePairs: {
      stocknumber: "stock number",
      make: "make",
      dealType: "deal type",
      laborOpCode: "op code",
      laborType: "labor type",
      closingManager: "closing manager"
    }
  },
  {
    source: SITE_SOURCE_NAMES.cdkFortellis,
    customerField: "cdkFortellisInfo",
    label: "CDK Fortellis",
    icon: "fa-car",
    linkable: true,
    isDms: true,
    rulesEditing: true,
    feedPreview: true,
    queueable: true,
    fieldsLabelValuePairs: {
      stocknumber: "stock number",
      make: "make",
      dealType: "deal type",
      laborOpCode: "op code",
      laborType: "labor type",
      closingManager: "closing manager"
    }
  },
  {
    source: SITE_SOURCE_NAMES.dealerDotCom,
    customerField: "dealerDotComInfo",
    label: "Dealer.com",
    icon: "fa-globe",
    queueable: false,
    linkable: true
  },
  {
    source: SITE_SOURCE_NAMES.linkedin,
    customerField: "linkedinInfo",
    label: "LinkedIn",
    icon: "fa-linkedin-square",
    linkable: true,
    isReviewSite: true,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.directMessage,
    customerField: "directMessageInfo",
    label: "Direct Message",
    icon: "fa-envelope-o",
    queueable: false,
    linkable: false
  },
  {
    source: SITE_SOURCE_NAMES.rome,
    customerField: "romeInfo",
    label: "Rome",
    icon: "fa-institution",
    linkable: true,
    queueable: false
  },
  {
    source: SITE_SOURCE_NAMES.inntopia,
    customerField: "inntopiaInfo",
    label: "Inntopia",
    icon: "fa-institution",
    linkable: true,
    queueable: false
  },
  {
    source: SITE_SOURCE_NAMES.ccc,
    customerField: "cccInfo",
    label: "CCC",
    icon: "fa-car",
    linkable: true,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.hubspot,
    customerField: "hubspotInfo",
    label: "Hubspot",
    icon: "fa-handshake-o",
    linkable: true,
    userLinking: true,
    freeTextRulesEditing: true,
    queueable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.keap,
    customerField: "keapInfo",
    label: "Keap",
    icon: "fa-handshake-o",
    linkable: false,
    userLinking: true,
    editable: true,
    queueable: true,
    isDms: true,
    feedPreview: true
  },
  {
    source: SITE_SOURCE_NAMES.dealerbuilt,
    customerField: "dealerbuiltInfo",
    label: "Dealerbuilt",
    icon: "fa-car",
    linkable: true,
    queueable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.mindbody,
    customerField: "mindbodyInfo",
    label: "Mindbody",
    icon: "fa-heartbeat",
    linkable: false,
    userLinking: true,
    editable: true,
    queueable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.cmsMax,
    customerField: "cmsMaxInfo",
    label: "CMS MAX",
    icon: "fa-car",
    linkable: true,
    isDms: false,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.sevenrooms,
    customerField: "sevenroomsInfo",
    label: "SevenRooms",
    icon: "fa-cutlery",
    linkable: true,
    queueable: true,
    isDms: true,
    feedPreview: true,
    rulesEditing: true
  },
  {
    source: SITE_SOURCE_NAMES.serviceTitan,
    customerField: "serviceTitanInfo",
    label: "Service Titan",
    icon: "fa-briefcase",
    linkable: true,
    isDms: true,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.serviceFusion,
    customerField: "serviceFusionInfo",
    label: "Service Fusion",
    icon: "fa-briefcase",
    linkable: true,
    isDms: true,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.entrata,
    customerField: "entrataInfo",
    label: "Entrata",
    icon: "fa-home",
    linkable: true,
    queueable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.boulevard,
    customerField: "boulevardInfo",
    label: "Boulevard",
    icon: "fa-home",
    linkable: true,
    queueable: true,
    isDms: true,
    rulesEditing: true,
    fieldsLabelValuePairs: {
      staffId: "Staff ID",
      serviceId: "Service ID",
      staffFirstName: "Staff First Name",
      staffLastName: "Staff Last Name",
      serviceName: "Service Name"
    }
  },
  {
    source: SITE_SOURCE_NAMES.yardi,
    customerField: "yardiInfo",
    label: "Yardi",
    icon: "fa-home",
    linkable: true,
    queueable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.salesforce,
    customerField: "salesforceInfo",
    label: "Salesforce",
    icon: "fa-globe",
    linkable: true,
    queueable: false,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.revolutionParts,
    customerField: "revolutionPartsInfo",
    label: "RevolutionParts",
    icon: "fa-wrench",
    linkable: true,
    queueable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.carvix,
    customerField: "carvixInfo",
    label: "Carvix",
    icon: "fa-car",
    linkable: true,
    queueable: false,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.swickard,
    customerField: "swickardInfo",
    label: "Swickard",
    icon: "fa-building",
    linkable: true,
    queueable: false,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.inspectionSupportNetwork,
    customerField: "inspectionSupportInfo",
    label: "Inspection Support",
    icon: "fa-home",
    linkable: true,
    isDms: false,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.treez,
    customerField: "treezInfo",
    label: "Treez",
    icon: "fa-leaf",
    linkable: true,
    isDms: true,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.apartmentsDotCom,
    customerField: "apartmentsDotComInfo",
    label: "Apartments.com",
    icon: "fa-building",
    linkable: true,
    isDms: true,
    queueable: true,
    isReviewSite: true,
    invitable: true
  },
  {
    source: SITE_SOURCE_NAMES.apartmentRatings,
    customerField: "apartmentRatingsInfo",
    label: "ApartmentRatings",
    icon: "fa-building",
    linkable: true,
    isDms: false,
    queueable: true,
    isReviewSite: true,
    invitable: true,
    isLifetimeRatingSite: true
  },
  {
    source: SITE_SOURCE_NAMES.appfolio,
    customerField: "appfolioInfo",
    label: "Appfolio",
    icon: "fa-home",
    linkable: true,
    isDms: true,
    queueable: true,
    isReviewSite: false,
    rulesEditing: true,
    fieldsLabelValuePairs: {
      propertyId: "Property Id"
    }
  },
  {
    source: SITE_SOURCE_NAMES.uberall,
    customerField: "uberallInfo",
    label: "Uberall",
    icon: "fa-connectdevelop",
    linkable: true,
    isDms: false,
    queueable: true
  },
  {
    source: SITE_SOURCE_NAMES.datashake,
    customerField: "datashakeInfo",
    label: "Datashake",
    icon: "fa-connectdevelop",
    linkable: true,
    isDms: false,
    queueable: false,
    isReviewSite: false
  },
  {
    source: SITE_SOURCE_NAMES.rentManager,
    customerField: "rentManagerInfo",
    label: "Rent Manager",
    icon: "fa-home",
    linkable: true,
    isDms: true,
    queueable: true,
    isReviewSite: false,
    rulesEditing: true,
    fieldsLabelValuePairs: {
      propertyId: "Property Id"
    },
    fieldTypeRestrictions: {
      propertyId: FIELD_TYPE.NON_NEGATIVE_INTEGER
    }
  },
  {
    source: SITE_SOURCE_NAMES.dealervault,
    customerField: "dealervaultInfo",
    label: "Dealervault",
    icon: "fa-car",
    linkable: true,
    queueable: false,
    rulesEditing: true,
    fieldsLabelValuePairs: {
      make: "make",
      model: "model",
      newUsed: "new or used (N/U)"
    },
    fieldTypeRestrictions: {
      newUsed: FIELD_TYPE.oneOf(["N", "U"])
    },
    isCsvIntegrated: true
    // isDms: true, //leaving this out for now so it doesn't show up under the "Add DMS Site" dropdown
  },
  {
    source: SITE_SOURCE_NAMES.propertyware,
    customerField: "propertywareInfo",
    label: "Propertyware",
    icon: "fa-home",
    linkable: true,
    queueable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.square,
    customerField: "squareInfo",
    label: "Square",
    icon: "fa-square",
    linkable: false,
    userLinking: true,
    editable: true,
    queueable: true,
    isDms: true,
    rulesEditing: true,
    fieldsLabelValuePairs: {
      categoryName: "Category Name"
    }
  },
  {
    source: SITE_SOURCE_NAMES.proResponse,
    customerField: "proResponseInfo",
    label: "ProResponse",
    icon: "fa-car",
    linkable: true,
    queueable: false,
    isDms: true,
    isCsvIntegrated: true
  },
  {
    source: SITE_SOURCE_NAMES.neustar,
    customerField: "neustarInfo",
    label: "Listings Aggregator",
    icon: "fa-star",
    linkable: true,
    editable: true,
    queueable: false,
    groups: [permissions.LISTINGS_MANAGE]
  },
  {
    source: SITE_SOURCE_NAMES.openTable,
    customerField: "openTableInfo",
    label: "OpenTable",
    icon: "bg-image-logo-open-table",
    linkable: true,
    editable: true,
    queueable: true,
    isReviewSite: true,
    invitable: true
  },
  {
    source: SITE_SOURCE_NAMES.tekion,
    customerField: "tekionInfo",
    label: "Tekion",
    icon: "fa-car",
    linkable: true,
    editable: true,
    queueable: true,
    invitable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.realPage,
    customerField: "realPageInfo",
    label: "Real Page",
    icon: "fa-home",
    linkable: true,
    editable: true,
    queueable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.yardiMaintenance,
    customerField: "yardiMaintenanceInfo",
    label: "Yardi Maintenance",
    icon: "fa-home",
    linkable: true,
    queueable: true,
    isDms: true
  },
  {
    source: SITE_SOURCE_NAMES.resman,
    customerField: "resmanInfo",
    label: "ResMan",
    icon: "fa-home",
    linkable: true,
    queueable: true,
    isDms: true
  }
];

export const SOURCES = keyBy(SITES, "source");
export const SOURCE_NAMES = SITES.sort((a, b) => a.label.localeCompare(b.label)).map(s => s.source);

export const DMS_SITES = SITES.filter(s => s.isDms);
export const REVIEW_SITES = SITES.filter(s => s.isReviewSite);
export const LINKABLE_SITES = SITES.filter(s => s.linkable);

export const CSV_INTEGRATED_SITES = SITES.filter(s => s.isCsvIntegrated).concat({
  source: "BULK_UPLOAD",
  label: "Bulk Upload"
});

export function bySource(source) {
  return SOURCES[source] || {};
}

export function byLabel(label) {
  return keyBy(SITES, "label")[label] || {};
}

export const siteName = source => SITES.find(v => v.source === source)?.label || source;
