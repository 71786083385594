import axios from "axios";
import config from "../config";
import { join } from "../util/stringUtils";
import map from "lodash/map";

const baseUri = config.api + "/shares";

export function listShares(contactId = undefined) {
  return axios.get(join([baseUri, "contact", contactId], "/"));
}

export function trackShare(contactId, postId, source) {
  const params = {
    source,
    contactId
  };
  return axios.post(`${baseUri}/share/${postId}`, {}, { params });
}

export function optOut(contactId) {
  const params = {
    contactId
  };

  return axios.post(`${baseUri}/optout`, {}, { params });
}

export function getShareTargetOptions(searchPrefix = null, destinations = []) {
  return axios.get(`${baseUri}/audience`, {
    params: {
      searchPrefix: searchPrefix,
      customerIds: map(destinations, destination => {
        return destination.customerId;
      })
    }
  });
}
