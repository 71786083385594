import isEmpty from "lodash/isEmpty";
import React from "react";
import Time from "../../components/DateTime/Time";
import FormField from "../../components/Form/FormField";
import CopyButton from "../../components/Buttons/CopyButton";
import { bySource } from "../../data/sites";
import { INVITE_LANDING_PAGE_PREFIX } from "../../util/constants";
import { Link } from "react-router-dom";

/**
 * @param { import('react').DetailedHTMLProps<import('react').HTMLAttributes<HTMLDivElement>, HTMLDivElement> } param0
 */
export const Invitation = ({
  invitation: {
    shortCode,
    sendDate,
    automatic,
    status,
    statusMessage,
    openedOn,
    clicks,
    deliveryStatus,
    campaign,
    destinations
  },
  reviews,
  className,
  ...props
}) => {
  return (
    <div {...props} className={`${className} px-4 pt-3 bg-light d-inline-block w-100`}>
      {shortCode && (
        <FormField label="Invite link">
          <div className="w-100 d-flex align-items-center justify-content-between">
            <span className="overflow-hidden">{`${INVITE_LANDING_PAGE_PREFIX}${shortCode}`}</span>
            <CopyButton text={`${INVITE_LANDING_PAGE_PREFIX}${shortCode}`} className="ms-auto" />
          </div>
        </FormField>
      )}
      <FormField label="Date sent">
        {deliveryStatus === "QUEUED" ? (
          "Pending"
        ) : deliveryStatus === "FAILED" ? (
          <>
            <Time withIcon={false} format="date" date={sendDate} /> {" - Failed"}
          </>
        ) : sendDate ? (
          <Time withIcon={false} format="date" date={sendDate} />
        ) : (
          <span className="text-muted">Unknown</span>
        )}
      </FormField>
      {campaign && <FormField label="Campaign">{campaign.name}</FormField>}
      <FormField label="Invite type">{automatic ? "Automatic" : "Manual"}</FormField>
      <FormField label="Status">
        {destinations &&
          status !== "SKIPPED" &&
          destinations
            .filter(d => d.result !== "UNKNOWN")
            .map(d => {
              return d.result === "SENT" ? (
                <div>
                  <strong>{d.deliveryMethod}</strong> - {status}
                </div>
              ) : d.result !== "FAILED" ? (
                <div>
                  <strong>{d.deliveryMethod}</strong> - {d.result}
                </div>
              ) : (
                <div>
                  <strong>{d.deliveryMethod}</strong> {" - "}
                  <span className="text-danger">
                    {d.result}
                    {d.resultReason && <span> {d.resultReason}</span>}
                  </span>
                </div>
              );
            })}
        {statusMessage && (
          <div>
            {status === "SKIPPED" && <div>{status}</div>}
            {statusMessage}
          </div>
        )}
      </FormField>
      {openedOn && (
        <FormField label="Opened On">
          <Time withIcon={false} format="date" date={openedOn} />
        </FormField>
      )}
      {!isEmpty(clicks) && (
        <>
          <FormField label="Review Site">{bySource(clicks[0].source).label || clicks[0].source}</FormField>
          {!isEmpty(reviews) && (
            <FormField label="Review Generated">
              {reviews.map(review => (
                <div>
                  <Link to={review.searchPageUrl}>Link to Review</Link>
                </div>
              ))}
            </FormField>
          )}
        </>
      )}
    </div>
  );
};
