import { isEqual, set } from "lodash";
import { always, evolve, isNil, isEmpty, pipe, when, either, ifElse, split } from "ramda";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CONTACT_TYPE_WIDEWAIL_USER } from "util/constants";
import { convertPagedResourcesResponse, useDelete, useGet, usePatch, usePost } from "../dataHooks";

const BASE_URL = "/contacts";
const BASE_URL_PERMISSIONS = "permissions";

const defaultNormalizeResponse = pipe(
  evolve({
    preferredContactMethod: when(either(isNil, isEmpty), always("CUSTOM")),
    tags: ifElse(either(isNil, isEmpty), always([]), split(",")),
    audience: ifElse(either(isNil, isEmpty), always([]), split(",")),
    responderConfig: when(isNil, always({ isAgencyResponder: false }))
  })
);

export const contactsKeys = {
  all: [BASE_URL],
  lists: () => [...contactsKeys.all, "lists"],
  list: query => [...contactsKeys.lists(), { query }],
  details: () => [...contactsKeys.all, "detail"],
  detail: id => [...contactsKeys.details(), id],
  activeProducts: id => [...contactsKeys.details(id), "activeProducts"],
  groups: id => [...contactsKeys.all, id, "groups"],
  suppressedEmail: id => [...contactsKeys.all, id, "suppressedEmail"],
  suppressedMobile: id => [...contactsKeys.all, id, "suppressedMobile"],
  permissions: [BASE_URL_PERMISSIONS]
};

/**
 * @param {{id?: string}} v
 * @returns {import("react-query").UseQueryResult<{}>}
 */
const useContact = (id = "me", params = {}, options = {}, normalizeResponse = defaultNormalizeResponse) => {
  const queryKey = contactsKeys.detail(id);
  const [get] = useGet(`${BASE_URL}/${id}`, { params });
  const queryRun = () => get().then(normalizeResponse);
  return useQuery(queryKey, queryRun, {
    placeholderData: null,
    isDataEqual: isEqual,
    ...options
  });
};

export const useSaveContact = onSuccess => {
  const patch = usePatch();
  const post = usePost();
  return useMutation(
    ({ contact, shouldClose }) => {
      let saveContact = Object.assign({}, contact);
      if (contact.receiveShares === false) {
        set(saveContact, "audience", null);
      }
      return contact.id
        ? patch(`${BASE_URL}/${contact.id}`, {
            data: saveContact
          })
        : post(`${BASE_URL}`, { data: saveContact });
    },
    {
      onSuccess: onSuccess
    }
  );
};

/**
 *
 * @param {{
 *  page?: number;
 *  size?: number;
 *  enabled?: boolean;
 * }} param0
 * @returns
 */
export const useContacts = ({
  page = 0,
  size = 20,
  enabled = true,
  contactType = CONTACT_TYPE_WIDEWAIL_USER,
  ...params
}) => {
  const query = { ...params, page, size, type: contactType };
  const queryKey = contactsKeys.list(query);

  const [get] = useGet(BASE_URL, { params: query });
  const queryRun = () => get().then(convertPagedResourcesResponse({ dataKey: "contacts" }));
  return useQuery(queryKey, queryRun, {
    enabled
  });
};

export const useContactEmailIsSuppressed = (id, options = {}) => {
  const queryKey = contactsKeys.suppressedEmail(id);
  const [get] = useGet(`${BASE_URL}/${id}/suppressedEmail`);
  return useQuery(queryKey, get, { enabled: !!id, ...options });
};

export const useDeleteContactEmailSuppression = (id, options = {}) => {
  const queryKey = contactsKeys.suppressedEmail(id);
  const del = useDelete();
  const queryClient = useQueryClient();
  return useMutation(() => {
        return del(`${BASE_URL}/${id}/suppressedEmail`, options);
      }, {
        onSuccess: () => queryClient.invalidateQueries(queryKey)
      }
  )
};

export const useContactMobileIsSuppressed = (id, options = {}) => {
  const queryKey = contactsKeys.suppressedMobile(id);
  const [get] = useGet(`${BASE_URL}/${id}/suppressedMobile`);
  return useQuery(queryKey, get, { enabled: !!id, ...options });
};

export const useDeleteContactMobileSuppression = (id, options = {}) => {
  const queryKey = contactsKeys.suppressedMobile(id);
  const del = useDelete();
  const queryClient = useQueryClient();
  return useMutation(() => {
        return del(`${BASE_URL}/${id}/suppressedMobile`, options);
      }, {
        onSuccess: () => queryClient.invalidateQueries(queryKey)
      }
  )
};

export const useGetGroups = (id, options = {}) => {
  const queryKey = contactsKeys.groups(id);
  const [get] = useGet(`${BASE_URL}/${id}/groups`);
  return useQuery(queryKey, get, { enabled: !!id && options.enabled, ...options });
};

export const useGroupAdd = id => {
  const post = usePost();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return post(`${BASE_URL}/${id}/groups`, { data });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(contactsKeys.groups(id))
    }
  );
};

export const useGroupDelete = id => {
  const del = useDelete();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return del(`${BASE_URL}/${id}/groups/${data}`);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(contactsKeys.groups(id))
    }
  );
};

export const useGetPermissions = (options = {}) => {
  const queryKey = contactsKeys.permissions;
  const [get] = useGet("/permissions");
  return useQuery(queryKey, get, { ...options });
};

export const useLinkCustomerContact = contact => {
  let path = contact.type === CONTACT_TYPE_WIDEWAIL_USER ? "contacts" : "shareContacts";
  const post = usePost();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return post(`${"customers"}/${data?.customerId}/${path}`, {
        data: data.contactId,
        headers: {
          "Content-type": "text/uri-list"
        }
      });
    },
    {
      onSuccess: (data, variables) =>
        Promise.all([
          queryClient.invalidateQueries(contactsKeys.detail(variables.contactId)),
          queryClient.invalidateQueries(`tagSets/contact/${variables.contactId}`)
        ])
    }
  );
};

export function useRemoveCustomerContact(contact) {
  const assoc = contact.type === CONTACT_TYPE_WIDEWAIL_USER ? "contacts" : "shareContacts";
  const del = useDelete();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return del(`${"customers"}/${data}/${assoc}/${contact.id}`);
    },
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries(contactsKeys.detail(contact.id)),
          queryClient.invalidateQueries(`tagSets/contact/${contact.id}`)
        ])
    }
  );
}

export function useRemoveAllCustomersFromContact(contact) {
  const del = useDelete();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return del(`contacts/${contact.id}/customers/*`);
    },
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries(contactsKeys.detail(contact.id)),
          queryClient.invalidateQueries(`tagSets/contact/${contact.id}`)
        ])
    }
  );
}

export const useUpdateContactManagedBy = contact => {
  const post = usePost();
  const queryClient = useQueryClient();
  return useMutation(
    id => {
      return post(`contacts/${contact.id}/managedBy`, { data: { id } });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(contactsKeys.detail(contact.id))
    }
  );
};

export const useAddContactToAgency = contactId => {
  const post = usePost();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return post(`agencies/${data}/contacts`, {
        data: contactId,
        headers: {
          "Content-type": "text/uri-list"
        }
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(contactsKeys.detail(contactId))
    }
  );
};

export const useRemoveContactFromAgency = contactId => {
  const del = useDelete();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return del(`agencies/${data}/contacts/${contactId}`);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(contactsKeys.detail(contactId))
    }
  );
};

export const useContactActiveProducts = (contactId, options = {}) => {
  const queryKey = contactsKeys.activeProducts(contactId);
  const [get] = useGet(`${BASE_URL}/${contactId}/activeProducts`);
  return useQuery(queryKey, get, { enabled: !!contactId && options?.enabled, ...options });
};

export default useContact;
