export const LINE_CHART_WIDTH = 40;
export const LINE_CHART_HEIGHT = 40;

export const STROKE_WIDTH = 2;
export const TICK_SIZE_DEFAULT = 20;
export const TICK_SIZE_SM = 15;
export const TICK_TRUNCATE_VALUE_MIN = 100000;
export const STROKE_DASHARRAY = "4 4";

export const LineTypes = Object.freeze({
  monotone: "monotone"
});

export const AxisTypes = Object.freeze({
  number: "number",
  category: "category"
});

export const Intervals = Object.freeze({
  preserveStart: "preserveStart",
  preserveEnd: "preserveEnd",
  preserveStartEnd: "preserveStartEnd"
});
