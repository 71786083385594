import React, { useCallback, useEffect, useMemo, useState } from "react";
import WWButton from "../../../components/Buttons/WWButton";
import { bySource } from "../../../data/sites";
import { deleteSquareInfo } from "../../../api/squareApi";
import StatusMessages from "../../../components/Misc/StatusMessages";
import { updateSquareInfo } from "api/squareApi";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import { Alert, Collapse } from "reactstrap";
import { isEmpty } from "lodash";

export default class SquareAdminLinking {
  field() {
    return bySource("SQUARE").customerField;
  }
  edit() {
    return SquareAdminLinkingEdit;
  }
  add() {
    return () => null;
  }
}

const SquareAdminLinkingEdit = ({ info, customer }) => {
  const [errors, setErrors] = useState();
  const [importNewPayment, setImportNewPayment] = useState(info.importNewPayment);
  const [importCompletedOrder, setImportCompletedOrder] = useState(info.importCompletedOrder);
  const [importPaidInvoice, setImportPaidInvoice] = useState(info.importPaidInvoice);
  const [initialInfo, setInitialInfo] = useState(info);

  const patchReady = useMemo(
    () =>
      importNewPayment !== initialInfo.importNewPayment ||
      importCompletedOrder !== initialInfo.importCompletedOrder ||
      importPaidInvoice !== initialInfo.importPaidInvoice,
    [importNewPayment, importCompletedOrder, importPaidInvoice, initialInfo]
  );

  const onUpdate = useCallback(() => {
    updateSquareInfo(customer.id, info.id, {
      importNewPayment,
      importCompletedOrder,
      importPaidInvoice,
      locations: info.locations
    })
      .then(res => setInitialInfo(res.data))
      .catch(error => setErrors(error));
  }, [info, customer, importNewPayment, importCompletedOrder, importPaidInvoice]);

  return (
    <>
      <div className="mt-4">
        <StatusMessages errors={errors} />
        {isEmpty(initialInfo.locations) ? (
          <Alert color="danger">No Locations connected</Alert>
        ) : (
          <div className="mb-3">
            <h5 className="mb-1">Locations</h5>
            {initialInfo.locations.map(location => (
              <span>
                <strong>{location.name}:</strong> {location.id}
              </span>
            ))}
          </div>
        )}
        <h5 className="mb-3">Trigger invitations on</h5>
        <Labeled
          name="importNewPayment"
          checked={importNewPayment}
          onToggle={setImportNewPayment}
          label="New Payment"
        />
        <Labeled
          name="importCompletedOrder"
          checked={importCompletedOrder}
          onToggle={setImportCompletedOrder}
          label="Completed Order"
        />
        <Labeled
          name="importPaidInvoice"
          checked={importPaidInvoice}
          onToggle={setImportPaidInvoice}
          label="Paid Invoice"
        />
        <Collapse isOpen={patchReady}>
          <WWButton color="primary" className="mt-3" onClick={onUpdate}>
            Update Square
          </WWButton>
        </Collapse>
        <h6>Square Access Token: {info.tokenStatus}</h6>
      </div>
    </>
  );
};
