import { get } from "lodash";
import React, { useCallback, useEffect } from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";
import Time from "../../components/DateTime/Time";
import { useLocationQueryParamState, useStateThroughRedux } from "../../hooks/stateHooks";
import { TableCard, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import WWButton from "components/Buttons/WWButton";
import StatusInfo from "components/Table/StatusInfo/StatusInfo";
import { useHistory } from "react-router-dom";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import MainBody from "components/FacetedLayout/MainBody/MainBody";
import HelpIcon from "components/Misc/HelpIcon";
import CampaignEdit from "./Campaigns/CampaignEdit/CampaignEdit";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import { INVITE_STATUS_LABEL_VALUE } from "data/options";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import useFilterQuery from "hooks/data/useFilterQuery";
import FilterTableSort from "components/FacetedSearch/Sort/FilterTableSort/FilterTableSort";
import { isWidewailAdmin } from "util/userUtils";
import { showSkeleton } from "util/showSkeleton";
import { useHasLocationIdParameter } from "hooks/customerHooks";
import SelectLocationPrompt from "components/Misc/SelectLocationPrompt";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";
import { useFilterState } from "hooks/filteringHooks";

const HIGHT_OF_HEADERS_AND_FOOTERS = "345px";

const facets = ({ isAdmin, filterValues }) => [
  {
    name: "dateRange",
    label: "Date range",
    type: "dates",
    queryParams: ["startDate", "endDate"]
  },
  {
    name: "status",
    label: "Status",
    type: "multiSelect",
    options: INVITE_STATUS_LABEL_VALUE,
    primaryBar: {
      position: "LEFT",
      className: "d-md-flex"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "name",
    label: "Customer Name",
    type: "text"
  },
  getAccountFilterConfig(),
  {
    name: "location",
    label: "Location",
    type: "allLocations",
    required: isAdmin && !filterValues?.account?.id,
    minLength: 0,
    primaryBar: {
      position: "LEFT",
      className: "d-md-flex auto-width d-none"
    },
    secondaryBar: {
      className: "d-flex d-md-none"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "repName",
    label: "Staff Name",
    type: "text",
    icon: "fa fa-user",
    primaryBar: {
      position: "LEFT",
      className: "d-none d-md-flex auto-width"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "campaign",
    label: "Campaign",
    type: "campaign",
    minLength: 0,
    placeholder: "Search Campaigns...",
    primaryBar: {
      position: "NONE"
    }
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "tel",
    inputType: "tel"
  },
  {
    name: "sort",
    defaultValue: "lastInteractionDate,desc",
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "page",
    defaultValue: 0,
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  }
];

export const LeadsList = () => {
  const [editingCampaignId, setEditingCampaignId] = useLocationQueryParamState("modalCampaignId");
  const [, setSettingsOpened] = useStateThroughRedux("campaignModal/opened", false);
  const [, setSettingsSection] = useStateThroughRedux("campaignModal/section", undefined);
  const history = useHistory();
  const filterValues = useFilterState();

  const viewCampaign = useCallback(
    (e, id) => {
      e.stopPropagation();
      setEditingCampaignId(id);
    },
    [setEditingCampaignId]
  );

  useEffect(() => {
    if (editingCampaignId) {
      setSettingsOpened(true);
      setSettingsSection("config");
    }
  }, [editingCampaignId]);

  const hasLocationSelected = useHasLocationIdParameter(),
    isAdmin = isWidewailAdmin(),
    showResults = !isAdmin || hasLocationSelected,
    filters = facets(isAdmin, filterValues),
    leadsQuery = useFilterQuery({
      filters,
      url: `/reviewLeads/experimental`,
      projection: "list",
      additionalParams: { type: "WIDEWAIL_USER", size: 25 }
    }),
    { data, isLoading, isFetched } = leadsQuery;

  return (
    <>
      <div className="pt-4 pb-0 px-md-4 container-fluid">
        <FacetedSearch filters={filters} />
        <MainBody>
          {!showResults ? (
            <SelectLocationPrompt />
          ) : (
            <>
              <TableCard>
                <WWTable id="invite-list" hightReduce={HIGHT_OF_HEADERS_AND_FOOTERS} loaded={!isLoading}>
                  <WWTHead>
                    <WWTR>
                      <WWTH>
                        <FilterTableSort label="Customer" options={["name,asc", "name,desc"]} />
                      </WWTH>
                      <WWTH className="d-none d-md-table-cell">Invite Status</WWTH>
                      <WWTH>
                        <FilterTableSort
                          label="Interaction Date"
                          options={["latestInteractionDate,desc", "latestInteractionDate,asc"]}
                        />
                      </WWTH>
                      <WWTH>Interaction Info</WWTH>
                      <WWTH>Campaign</WWTH>
                    </WWTR>
                  </WWTHead>
                  {showSkeleton({ isLoading, isFetched }) ? (
                    <TableBodySkeleton cols={5} rows={20} />
                  ) : (
                    <WWTBody>
                      {data?.data?.reviewLeads?.map(lead => (
                        <WWTR key={lead.id} onClick={() => history.push(`${history.location.pathname}/${lead.id}`)}>
                          <WWTD>
                            <span className="d-flex flex-nowrap align-items-center">
                              <Badge
                                className="p-2 me-2 d-md-none"
                                color={get(
                                  {
                                    QUEUED: "dark",
                                    OPENED: "success",
                                    UNOPENED: "warning",
                                    FAILED: "danger"
                                  },
                                  lead.interactions[0]?.invitations[0]?.status,
                                  "dark"
                                )}
                              >
                                {(lead.interactions[0]?.invitations[0] &&
                                  lead.interactions[0]?.invitations[0]?.status) ||
                                  "Queued"}
                              </Badge>
                              <div className="d-flex flex-column align-items-start">
                                <span>
                                  {lead.firstName} {lead.lastName}
                                </span>
                                <small id={"customer-tooltip" + lead.id} className="text-uppercase ww-font-xxs">
                                  {lead.customer.companyName}
                                </small>
                              </div>
                            </span>
                            <UncontrolledTooltip
                              container={"body"}
                              target={"customer-tooltip" + lead.id}
                              placement="top"
                            >
                              {lead.customer.companyName}
                            </UncontrolledTooltip>
                          </WWTD>
                          <WWTD className="d-none d-md-table-cell">
                            <div className="d-flex flex-column align-items-start">
                              <StatusInfo
                                status={
                                  (lead.interactions[0]?.invitations[0] &&
                                    lead.interactions[0]?.invitations[0]?.status) ||
                                  "Queued"
                                }
                              >
                                {lead.interactions[0]?.invitations[0]?.status === "FAILED" && (
                                  <HelpIcon className="ms-1 text-danger" icon="info">
                                    {lead.interactions[0]?.invitations[0]?.statusMessage || "Unknown"}
                                  </HelpIcon>
                                )}
                              </StatusInfo>
                              <Time
                                className="ww-font-xxs text-uppercase"
                                withIcon={false}
                                format="date"
                                date={lead.interactions[0]?.invitations[0]?.lastModifiedOn}
                              />
                            </div>
                          </WWTD>
                          <WWTD>
                            <Time withIcon={false} format="date" date={lead.interactions[0]?.visitTime} />
                          </WWTD>
                          <WWTD>
                            <span>{lead.interactions[0]?.tags[0]}</span>
                            <>
                              {(representatives =>
                                representatives.length > 2 ? (
                                  <>
                                    <div>
                                      {(({ firstName, lastName }) => `${firstName} ${lastName}`)(representatives[0])}
                                    </div>
                                    <div className="text-muted">and {representatives.length - 1} more...</div>
                                  </>
                                ) : (
                                  representatives.map(({ firstName, lastName }) => (
                                    <small
                                      className="text-uppercase ww-font-xxs d-block"
                                      key={`${firstName} ${lastName}`}
                                    >{`${firstName} ${lastName}`}</small>
                                  ))
                                ))(lead.interactions[0]?.representatives || [])}
                            </>
                          </WWTD>
                          <WWTD>
                            {lead.interactions[0]?.invitations[0]?.campaign?.id && (
                              <WWButton
                                className="ps-0 position-static"
                                color="link"
                                onClick={e => viewCampaign(e, lead.interactions[0]?.invitations[0]?.campaign?.id)}
                              >
                                {lead.interactions[0]?.invitations[0]?.campaign?.name}
                              </WWButton>
                            )}
                          </WWTD>
                        </WWTR>
                      ))}
                    </WWTBody>
                  )}
                </WWTable>
              </TableCard>
              <FilterPagination
                isLoading={showSkeleton(leadsQuery)}
                pageInfo={leadsQuery.data?.pageInfo}
                totalPages={leadsQuery.data?.pageInfo?.totalPages}
                elementsLabel="Invites"
              />
            </>
          )}
        </MainBody>
        <CampaignEdit campaignId={editingCampaignId} setCampaignId={setEditingCampaignId} />
      </div>
    </>
  );
};
