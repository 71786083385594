import { path } from "ramda";
import { useCallback, useState, useMemo } from "react";
import { Button, Form } from "reactstrap";
import InputField from "../../../components/Form/InputField";
import CopyButton from "../../../components/Buttons/CopyButton";
import { bySource } from "../../../data/sites";
import { useStateThroughPipe } from "../../../hooks/utilHooks";
import { noop } from "lodash";
import SharedCredentialsSelector from "components/SharedCredentials/SharedCredentialsSelector";

const { source } = bySource("APARTMENTS_DOT_COM");

export default class ApartmentsDotComAdminLinking {
  field() {
    return "apartmentsDotComInfo";
  }

  add() {
    return ApartmentsDotComLinkAdd;
  }

  edit() {
    return ApartmentsDotComLinkEdit;
  }
}

const nativeInputEventPipe = path(["target", "value"]);

const ApartmentsDotComLinkAdd = ({ onSave }) => {
  const [sharedCredentials, setSharedCredentials] = useState();
  const [email, setEmail] = useStateThroughPipe("", nativeInputEventPipe);
  const [password, setPassword] = useStateThroughPipe("", nativeInputEventPipe);
  const [listingKey, setListingKey] = useStateThroughPipe("", nativeInputEventPipe);

  const allFilled = useMemo(() => {
    if (sharedCredentials) {
      return listingKey;
    } else {
      return email && password && listingKey;
    }
  }, [sharedCredentials, email, password, listingKey]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      onSave({ email, password, listingKey, sharedCredentials, source });
    },
    [onSave, email, password, listingKey, sharedCredentials]
  );

  return (
    <Form onSubmit={onSubmit}>
      <InputField inline={false} label="Listing Key" value={listingKey} onChange={setListingKey} />
      <SharedCredentialsSelector
        label="Shared credentials"
        source="APARTMENTS_DOT_COM"
        onChange={setSharedCredentials}
      />
      {!sharedCredentials && (
        <>
          <InputField inline={false} disabled={sharedCredentials} label="Email" value={email} onChange={setEmail} />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Password"
            value={password}
            onChange={setPassword}
            type="password"
          />
        </>
      )}
      <Button type="submit" color="primary" disabled={!allFilled}>
        Link
      </Button>
    </Form>
  );
};

const ApartmentsDotComLinkEdit = ({ info }) => {
  return (
    <>
      <InputField
        name="sharedCredentials"
        label="Shared Credentials"
        value={info.sharedCredentials?.name}
        disabled={true}
      />
      <InputField name="email" label="Email" value={info.email} disabled={true} onChange={noop} />
      <CopyButton className="ml-1" text={info.email} />
      <InputField name="listingKey" label="Listing Key" value={info.listingKey} disabled={true} onChange={noop} />
      <CopyButton className="ml-1" text={info.listingKey} />
    </>
  );
};
