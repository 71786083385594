import React, { useCallback, useEffect, useMemo, useState } from "react";
import FormField from "components/Form/FormField";
import * as entrataApi from "api/entrataApi";
import SharedCredentialsSelector from "components/SharedCredentials/SharedCredentialsSelector";
import InputField from "components/Form/InputField";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import WWButton from "components/Buttons/WWButton";
import { useErrorNotification } from "components/Notifications/notification";
// import { useStateThroughPipe } from "../../../hooks/utilHooks";
import { useValidatePropertySitesCheckboxes } from "../../../hooks/validationHooks";
import Labeled from "../../../components/Checkbox/Labeled/CheckboxLabeled";
import { cloneDeep, get } from "lodash";
import { Button, Collapse } from "reactstrap";
import { updateEntrataLink } from "../../../api/customerApi";

const useImportOptions = (info = {}) => {
  const useImportInfoState = (key, init) => useState(get(info, key, init));

  const [importMoveIn, setImportMoveIn] = useImportInfoState("importMoveIn", true),
    [importRenewal, setImportRenewal] = useImportInfoState("importRenewal", true),
    [importTour, setImportTour] = useImportInfoState("importTour", true),
    [importMaintenance, setImportMaintenance] = useImportInfoState("importMaintenance", false),
    importOptions = useMemo(
      () => [
        { name: "importMoveIn", label: "Move in", value: importMoveIn, setter: setImportMoveIn },
        { name: "importRenewal", label: "Renewal", value: importRenewal, setter: setImportRenewal },
        { name: "importTour", label: "Tour", value: importTour, setter: setImportTour },
        {
          name: "importMaintenance",
          label: "Maintenance",
          value: importMaintenance,
          setter: setImportMaintenance
        }
      ],
      [importMoveIn, importRenewal, importTour, importMaintenance]
    );

  return [importOptions, { importMoveIn, importRenewal, importTour, importMaintenance }];
};

export default class Entrata {
  field() {
    return "entrataInfo";
  }

  add() {
    const AddEntrata = ({ customer, onSave }) => {
      const [sharedCredentials, setSharedCredentials] = useState();
      const [clientSecret, setClientSecret] = useState("");
      const [clientId, setClientId] = useState("");
      const [apiUrl, setApiUrl] = useState("");
      const [property, setProperty] = useState("");
      const [buildingOptions, setBuildingOptions] = useState([]);
      const [filter, setFilter] = useState("");
      const [loading, setLoading] = useState(false);
      const errorNotification = useErrorNotification();

      const [importOptions, importOptionVals] = useImportOptions();

      useEffect(() => {
        if ((clientSecret && clientId && apiUrl) || sharedCredentials) {
          setLoading(true);
          entrataApi
            .listProperties({
              clientSecret,
              clientId,
              apiUrl,
              filter,
              sharedCredentials: sharedCredentials ? { id: sharedCredentials.id } : null
            })
            .then(res => setBuildingOptions(res.data))
            .catch(errorNotification)
            .finally(() => setLoading(false));
        } else {
          setBuildingOptions([]);
        }
      }, [clientSecret, clientId, apiUrl, sharedCredentials, filter]);

      const onSubmit = useCallback(() => {
        onSave({
          sharedCredentials,
          clientSecret,
          clientId,
          apiUrl,
          propertyId: property.value,
          propertyName: property.label,
          address: property.address,
          source: "ENTRATA",
          ...importOptionVals
        });
      }, [sharedCredentials, clientSecret, clientId, apiUrl, property, JSON.stringify(importOptionVals)]);

      const convertedBuildingOptions = useMemo(
        () =>
          buildingOptions?.map(option => ({
            label: option.propertyName,
            value: option.propertyId,
            address: option.address
          })),
        [buildingOptions]
      );
      return (
        <>
          <SharedCredentialsSelector label="Shared Credentials" source="ENTRATA" onChange={setSharedCredentials} />
          {!sharedCredentials && (
            <>
              <InputField
                type="text"
                label="Client Secret"
                placeholder="Client Secret"
                onChange={e => setClientSecret(e.target.value)}
                value={clientSecret}
              />
              <InputField
                type="text"
                label="Client ID"
                placeholder="Client ID"
                onChange={e => setClientId(e.target.value)}
                value={clientId}
              />
              <InputField
                type="text"
                label="Api URL"
                placeholder="Api URL"
                onChange={e => setApiUrl(e.target.value)}
                value={apiUrl}
              />
            </>
          )}
          <HorizontalSelectField
            name="Property"
            label="Property"
            isLoading={loading}
            isClearable
            isDisabled={(!clientSecret || !clientId || !apiUrl) && !sharedCredentials}
            options={convertedBuildingOptions}
            onInputChange={event => setFilter(event)}
            onChange={event => setProperty(event.target.value)}
            inline={false}
          />
          <FormField label=" ">
            <WWButton
              color="primary"
              disabled={(!clientSecret || !clientId || !apiUrl) && !sharedCredentials}
              onClick={onSubmit}
            >
              Link
            </WWButton>
          </FormField>
          {importOptions.map(({ name, label, value, setter }) => (
            <Labeled key={name} name={name} checked={value} onToggle={setter} label={label} />
          ))}
        </>
      );
    };
    return AddEntrata;
  }

  edit() {
    const EditEntrata = ({ info, customer }) => {
      const [initialInfo, setInitialInfo] = useState([]);
      const [dirty, setDirty] = useState(false);
      const [importOptions, importOptionsVals] = useImportOptions(info);

      const validateActions = useValidatePropertySitesCheckboxes(importOptions);
      const updateEntrata = () =>
        validateActions().then(validationResult => {
          if (validationResult) {
            updateEntrataLink(customer.id, info.id, importOptionsVals).then(data => {
              setInitialInfo(data);
            });
          }
        });
      useEffect(() => {
        setInitialInfo(cloneDeep(info));
      }, [info]);

      useEffect(() => {
        setDirty(importOptions.some(opt => get(initialInfo, opt.name) !== opt.value));
      }, [initialInfo, JSON.stringify(importOptionsVals)]);

      return (
        <>
          <FormField label="Property">
            <span className="text-truncate w-auto d-inline-block">{info.propertyName}</span>
            <span className="text-truncate w-auto d-inline-block">{info.address}</span>
          </FormField>
          <FormField label="Property ID">
            <span className="text-truncate w-auto d-inline-block">{info.propertyId}</span>
          </FormField>
          {importOptions.map(({ name, label, value, setter }) => (
            <Labeled key={name} name={name} checked={value} onToggle={setter} label={label} />
          ))}

          <Collapse isOpen={dirty}>
            <FormField label=" ">
              <Button color="primary" onClick={updateEntrata} disabled={!dirty}>
                Update
              </Button>
            </FormField>
          </Collapse>
        </>
      );
    };
    return EditEntrata;
  }
}
