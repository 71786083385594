import React, { useCallback, useState } from "react";
import { InputGroup, InputGroupText } from "reactstrap";
import WWButton from "../../../components/Buttons/WWButton";
import { useMemo } from "react";
import { bySource } from "../../../data/sites";
import { deleteKeapInfo } from "../../../api/keapApi";
import StatusMessages from "../../../components/Misc/StatusMessages";
import FormField from "../../../components/Form/FormField";
import CopyButton from "../../../components/Buttons/CopyButton";

export default class KeapAdminLinking {
  field() {
    return bySource("KEAP").customerField;
  }
  edit() {
    return KeapAdminLinkingEdit;
  }
  add() {
    return () => null;
  }
}

const KeapAdminLinkingEdit = ({ info, customer }) => {
  const [errors, setErrors] = useState();

  const unlinkKeap = useCallback(() => {
    deleteKeapInfo(customer.id, info.id).catch(error => setErrors(error));
  }, [customer, info]);

  return (
    <>
      <div className="mt-4">
        <StatusMessages errors={errors} />
        <FormField label="Connected" inline={false}>
          <div className="text-nowrap">
            {info.companyName}
            <span className="ps-1">
              <CopyButton text={info.companyName} />
            </span>
          </div>
        </FormField>
      </div>
    </>
  );
};
