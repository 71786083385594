import React from "react";
import { Label } from "reactstrap";
import classnames from "classnames";

import styles from "./FieldLabel.module.scss";

const { labelClass } = styles;

const FieldLabel = ({ className, children, link }) => (
  <span className={classnames(labelClass, className)}>
    <Label>{children}</Label>
    {!!link && " "}
    {link ? link : null}
  </span>
);

export default FieldLabel;
