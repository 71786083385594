import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Progress, Util } from "reactstrap";
import classNames from "classnames";

const propTypes = {
  header: PropTypes.any,
  icon: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  invert: PropTypes.bool
};

const defaultProps = {
  header: "87.500",
  icon: "icon-people",
  color: "info",
  value: "25",
  children: "Visitors",
  invert: false
};

class Widget04 extends Component {
  render() {
    const { className, cssModule, header, icon, color, value, children, invert, ...attributes } = this.props;

    // demo purposes only
    const progress = { style: "", color: color, value: value };
    const card = { style: "", bgColor: "", icon: icon };

    if (invert) {
      progress.style = "progress-white";
      progress.color = "";
      card.style = "text-white";
      card.bgColor = "bg-" + color;
    }

    const classes = Util.mapToCssModules(classNames(className, card.style, card.bgColor, "border-dark"), cssModule);
    progress.style = classNames("progress-xs mt-3 mb-0", progress.style);

    return (
      <Card className={classes} {...attributes}>
        <CardBody>
          <div className="h3 text-muted text-end mb-2">
            <i className={card.icon}></i>
          </div>
          <div className="h4 mb-0 fw-bold">{header}</div>
          <small className="text-muted text-uppercase fw-bold">{children}</small>
          <Progress className={progress.style} color={progress.color} value={progress.value} />
        </CardBody>
      </Card>
    );
  }
}

Widget04.propTypes = propTypes;
Widget04.defaultProps = defaultProps;

export default Widget04;
