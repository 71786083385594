import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { isFunction, noop } from "lodash";
import { permissions } from "components/Auth/permissions";
import Time from "components/DateTime/Time";
import Avatar from "components/User/Avatar";
import Paragraph from "components/Layout/Paragraph";
import { Card } from "components/Card";
import HelpIcon from "components/Misc/HelpIcon";
import WWButton from "components/Buttons/WWButton";
import ReplyActions from "components/Reviews/Reply/ReplyActions";
import { errorCaughtNotifier, useLocalNotifications } from "components/Notifications/notification";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import EditBox from "views/Reviews/Feed/EditBox";
import Attachments from "../Attachments";
import Reactions from "../Reactions";
import ReplyStatusBadge from "./ReplyStatusBadge";
import ReplyActionBanner from "./ReplyActionBanner";
import ReplyBox from "views/Reviews/Feed/ReplyBox";
import * as approvalApi from "api/approvalApi";
import { isCurrentUserInGroup } from "util/userUtils";
import { isAdOrPost } from "util/reviewUtils";

import "../Reviews.scss";
import styles from "./ReplyReview.module.scss";

import { customerHasReviewResponseProduct } from "../../../util/customerUtils";

const {
  replyContainerClass,
  subreplyClass,
  approvalClass,
  darkClass,
  withActionBarClass,
  actionBarClass,
  approveButtonClass,
  unrepliedBadgeClass
} = styles;

const DAYS_COLORED_RED = 2;

function ReviewReply({
  review,
  reply,
  reply: { status, source, author, lastModifiedBy, lastModifiedOn },
  replyActionsCallbacks,
  onReplyUpdate,
  onPublishReply,
  onReplyEdit,
  showApprovalDate = false,
  showStatusDropdown,
  dark = false,
  legacy = false
}) {
  const agency = useSelector(state => state.currentAgency);
  const [requestProcessing, setRequestProcessing] = useState(false);
  const [editing, setEditing] = useState(false);
  const [replying, setReplying] = useState(false);

  const notify = useLocalNotifications();

  const onStartEditing = useCallback(() => setEditing(true), [setEditing]);
  const onFinishEditing = useCallback(() => setEditing(false), [setEditing]);
  const onSaveEdited = useMemo(() => onReplyEdit && onReplyEdit(reply, review), [reply, review, onReplyEdit]);

  const onStartReplying = useCallback(() => setReplying(true), [setReplying]);
  const onFinishReplying = useCallback(() => setReplying(false), [setReplying]);

  const onShowHistory = useCallback(() => replyActionsCallbacks?.onShowHistory(reply) || noop, [
    reply,
    replyActionsCallbacks
  ]);
  const onUnpublish = useCallback(() => replyActionsCallbacks?.onUnpublish(reply, review) || noop, [
    reply,
    review,
    replyActionsCallbacks
  ]);
  const onPublish = useCallback(() => (isFunction(onPublishReply) ? onPublishReply(reply, review) : noop), [
    reply,
    review,
    onPublishReply
  ]);
  const onDelete = useCallback(() => replyActionsCallbacks?.onDelete(reply, review) || noop, [
    reply,
    review,
    replyActionsCallbacks
  ]);

  const onReplySave = useMemo(
    () =>
      replyActionsCallbacks &&
      replyActionsCallbacks.onReplySaveFactory(reply.relatedReview || review, reply.thirdpartyId),
    [replyActionsCallbacks, reply, review]
  );

  const onSentimentChange = useMemo(() => {
    return isAdOrPost(reply.relatedReview || review) && replyActionsCallbacks
      ? replyActionsCallbacks.onSentimentChange
      : undefined;
  }, [reply, review, replyActionsCallbacks]);

  const approveSingleReply = useCallback(async () => {
    try {
      setRequestProcessing(true);
      await approvalApi.approveSingleReply(review.id, reply.id);
      setRequestProcessing(false);
      return onReplyUpdate ? onReplyUpdate() : null;
    } catch (e) {
      setRequestProcessing(false);
      errorCaughtNotifier(notify)(e);
    }
  }, [reply, review, notify, onReplyUpdate]);

  const sourceName = () => {
    if (reply.source === "WIDEWAIL") {
      // Reviews can only live in a single agency, so we can cheat here and
      // just assume replies were authored from within that agency. This makes
      // is so we show the agency name in the by line of the reply
      if (agency) {
        return `at ${agency.servicerName || agency.name}`;
      }
    }
    return "";
  };

  const timeWaitingForApproval = useMemo(() => lastModifiedOn && moment().diff(lastModifiedOn, "days") + 1, [
    lastModifiedOn
  ]);

  const avatarEmail = useMemo(() => (source === "WIDEWAIL" ? lastModifiedBy : author), [reply]);

  const shouldRenderReplyActions = useMemo(
    () =>
      isCurrentUserInGroup(permissions.REVIEW_REPLY) &&
      !customerHasReviewResponseProduct(review?.customer) &&
      !!replyActionsCallbacks,
    [replyActionsCallbacks]
  );

  const shouldRenderActionBar = useMemo(
    () =>
      (status === "DRAFT" ||
        status === "APPROVED" ||
        (isCurrentUserInGroup(permissions.REVIEW_REPLY) && status === "APPROVAL")) &&
      source === "WIDEWAIL" &&
      !legacy,
    [status, onPublishReply, legacy]
  );

  const isSubreply = useMemo(
    // check if thirdpartyParentId exists for rendering of test replies without thirdpartyParentId
    () => review && reply.thirdpartyParentId && reply.thirdpartyParentId !== review?.thirdpartyId,
    [reply, review]
  );

  const replyContainerClassName = useMemo(
    () =>
      classnames({
        [replyContainerClass]: !legacy,
        [approvalClass]: status === "APPROVAL",
        [withActionBarClass]: shouldRenderActionBar,
        [darkClass]: dark
      }),
    [legacy, status, shouldRenderActionBar, dark]
  );

  if (!reply) {
    return null;
  }

  return (
    <>
      <div className={classnames({ [subreplyClass]: isSubreply })}>
        <div className={replyContainerClassName}>
          {editing && isFunction(onReplyEdit) ? (
            <div className="pb-3">
              <EditBox onFinish={onFinishEditing} onSave={onSaveEdited} initialContent={reply.content} />
            </div>
          ) : (
            <>
              <div className="d-flex flex-column text-dark align-items-sm-start px-0 pb-2">
                <Paragraph content={reply.content} />
                {reply.translatedContent && (
                  <Card color="secondary" className="text-light px-3 border-0 rounded-1">
                    <h4 className="mb-2">
                      Widewail Translation
                      <HelpIcon className="ww-font-md ms-2" icon="info">
                        Widewail translation for internal use only
                      </HelpIcon>
                    </h4>
                    <Paragraph content={reply.translatedContent} />
                  </Card>
                )}
                <ReplyStatusBadge status={reply.status} nextRetryOn={reply.nextRetryOn} reportedAs={reply.reportedAs} />
                <div className="d-md-flex align-content-between w-100">
                  {/* TODO: need to update this to work for more than just WW emails */}
                  <div className="d-sm-flex w-100">
                    <Avatar className="d-none d-sm-block me-2" email={avatarEmail} />
                    <div className="me-auto px-sm-1">
                      <div>
                        <div className="pe-1 mb-1 d-inline-block reply-author">
                          <strong>{reply.author}</strong>
                        </div>
                        {sourceName()}
                        {reply.status === "PUBLISHED" &&
                          reply.publishedBy &&
                          reply.publishedBy.toLowerCase() !== "system" && <div>Published by {reply.publishedBy}</div>}
                      </div>
                      <Time
                        className="text-muted reply-date"
                        date={reply.publishedOn || reply.createdOn || reply.lastModifiedOn}
                        format="dateSlashTime"
                        withIcon={false}
                      />
                      {reply.source !== "WIDEWAIL" && reply.status !== "PUBLISHED" && (
                        <Badge className={classnames(unrepliedBadgeClass, "bg-danger d-block p-2 mt-2 mb-1")}>
                          Unpublished
                        </Badge>
                      )}
                    </div>
                  </div>
                  <AuthorizationRequiredToRender roles={[permissions.REVIEW_APPROVE]}>
                    {reply.status === "APPROVAL" && showApprovalDate && (
                      <div className="mt-3 mt-sm-4 d-flex flex-column flex-sm-row w-100 align-items-center justify-content-end">
                        <div
                          className={classnames("days-to-approval", {
                            "days-to-approval-recent": timeWaitingForApproval > DAYS_COLORED_RED
                          })}
                        >
                          Days waiting for approval: {timeWaitingForApproval}
                        </div>
                        <WWButton
                          disabled={requestProcessing}
                          className={approveButtonClass}
                          color="primary"
                          tooltip="Approve reply"
                          trackingAction="Approve reply"
                          onClick={approveSingleReply}
                        >
                          Approve
                        </WWButton>
                      </div>
                    )}
                  </AuthorizationRequiredToRender>
                </div>
                <div className="d-flex align-items-center">
                  {reply.attachments && <Attachments attachments={reply.attachments} />}
                  {reply.reactions && (
                    <div className="reply-reactions">
                      <Reactions id={reply.id} reactions={reply.reactions} />
                    </div>
                  )}
                </div>
              </div>
              {shouldRenderReplyActions && !!replyActionsCallbacks && (
                <ReplyActions
                  reply={reply}
                  review={review}
                  onShowHistory={onShowHistory}
                  onUnpublish={onUnpublish}
                  onDelete={onDelete}
                  onSentimentChange={onSentimentChange}
                  onUpdateStatus={replyActionsCallbacks.onUpdateStatus}
                  onEdit={onStartEditing}
                  onReply={onStartReplying}
                  showStatusDropdown={showStatusDropdown}
                />
              )}
            </>
          )}
        </div>
        {shouldRenderActionBar && (
          <div className={actionBarClass}>
            <ReplyActionBanner
              reply={reply}
              onClick={onPublish}
              showRemovedBanner={false}
              showPublishNow={isFunction(onPublishReply)}
            />
          </div>
        )}
      </div>
      {shouldRenderReplyActions && !!replyActionsCallbacks && replying && (
        <div className="ms-5 py-2">
          <ReplyBox onFinish={onFinishReplying} onSave={onReplySave} />
        </div>
      )}
    </>
  );
}

ReviewReply.propTypes = {
  reply: PropTypes.object.isRequired,
  review: PropTypes.object,
  replyActionsCallbacks: PropTypes.object,
  onReplyUpdate: PropTypes.func,
  onPublishReply: PropTypes.func,
  onReplyEdit: PropTypes.func,
  actions: PropTypes.func,
  showApprovalDate: PropTypes.bool,
  showStatusDropdown: PropTypes.bool,
  dark: PropTypes.bool,
  legacy: PropTypes.bool
};

export default ReviewReply;
