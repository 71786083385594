import React from "react";
import { currentUserHasFeature, isCurrentUserInGroup } from "../../util/userUtils";
import isEmpty from "lodash/isEmpty";
import { useCurrentAgency } from "hooks/agencyHooks";

/*
    HOC for providing role base authentication around components.
    https://medium.com/better-programming/role-based-authorization-in-react-c70bb7641db4

    Can be used to create an authorized component:
       withAuthorization(['role1','role2'])(Button)

    Or can be used inline in render to create one off authorized sections
       {withAuthorization(['role1','role2'])
        <div>
            <p>some authorized section</p>
        </div>
       }
*/
const withAuthorization = roles => WrappedComponent => {
  if (WrappedComponent.prototype === undefined && isCurrentUserInGroup(roles)) {
    return WrappedComponent;
  }

  return class withAuthorization extends React.Component {
    render() {
      if (isCurrentUserInGroup(roles)) return <WrappedComponent {...this.props} />;
      return <React.Fragment />;
    }
  };
};

export const withAgency = requiredAgency => Component => props => {
  const currentAgency = useCurrentAgency();

  return currentAgency?.id === requiredAgency || currentAgency?.name === requiredAgency ? (
    <Component {...props} />
  ) : null;
};

export const withFeature =
  (features = []) =>
  Component =>
  props =>
    features.every(f => currentUserHasFeature(f)) ? <Component {...props} /> : null;

/**
 * Wrapper for components you wish to render only
 * when current user have appropriate permissions to do it.
 * Specify this permissions as a list for roles parameter.
 *
 * @param {{roles: string[]; children: React.ReactNode}} param0
 */
export const AuthorizationRequiredToRender = ({ roles, children }) =>
  isEmpty(roles) || isCurrentUserInGroup(roles) ? <>{children}</> : null;

export default withAuthorization;
