import React, { useCallback } from "react";
import { getReviewHourly } from "../../../api/reportApi";
import ConfigurableAreaChartWithLegend from "../../../components/Charts/ConfigurableAreaChartWithLegend";
import moment from "moment";
import get from "lodash/get";
import { Button } from "reactstrap";
import { Intervals } from "../../../constants/chart";

export default () => {
  const dataSource = useDataSource();

  return (
    <ConfigurableAreaChartWithLegend
      title="Incoming / Hour"
      dataSource={dataSource}
      chartProps={{
        colors: {
          REVIEW: "rgba(87, 170, 228, 1.0)",
          POST: "rgba(20, 184, 69, 1.0)",
          QUESTION: "rgba(255, 198, 89, 1.0)"
        },
        xAxis: {
          domain: ["dataMin", "dataMax"]
        },
        yAxis: {
          domain: [0, dataMax => (dataMax ? dataMax * 1.1 : 1)],
          interval: Intervals.preserveStartEnd,
          scale: "linear",
          allowDecimals: false
        },
        legend: {
          iconSize: 15
        },
        tooltip: {
          content: tooltipFormatter
        }
      }}
    />
  );
};

const tooltipFormatter = ({ active, payload, label }) => {
  if (active && payload && payload.length > 0) {
    let data = payload[0].payload;
    return (
      <Button>
        <div className={`d-flex flex-column align-items-start flex-grow-1`}>
          <span>
            <strong>{`${moment.unix(label).local().format("ddd, hA")}`}</strong>
          </span>
          {get(data, "REVIEW") != null ? <span>{"Reviews: " + get(data, "REVIEW")}</span> : <></>}
          {get(data, "POST") != null ? <span>{"Posts: " + get(data, "POST")}</span> : <></>}
          {get(data, "QUESTION") != null ? <span>{"Questions: " + get(data, "QUESTION")}</span> : <></>}
        </div>
      </Button>
    );
  } else {
    return null;
  }
};

// const tooltipFormatter = (p) => {
//   console.log(p)
// };
const useDataSource = () => useCallback(getReviewHourly, []);
