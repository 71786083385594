import { WWTBody, WWTD, WWTR } from "components/Table/WWTable";
import { times } from "ramda";
import Skeleton from "components/Skeleton";
import "./index.scss";

/**
 * Table body placeholder.
 *
 * @returns
 */
export default function TableBodySkeleton({ rows = 10, cols = 5, ...props }) {
  return (
    <WWTBody>
      {times(i => {
        return (
          <WWTR key={i}>
            <WWTD colSpan={cols}>
              <Skeleton className="w-100" />
            </WWTD>
          </WWTR>
        );
      }, rows)}
    </WWTBody>
  );
}
