import React from "react";
import { includes, isUndefined } from "lodash";
import { useSelector } from "react-redux";

const ActiveProductsRequireToRender = ({ products, children }) => {
  // <></> is using below for case when children are undefined
  const activeProducts = useSelector(state => state.currentContact?.activeProducts);
  const currentUserHasProducts = products?.every(product => includes(activeProducts, product));
  return isUndefined(products) || currentUserHasProducts ? <>{children}</> : null;
};

export default ActiveProductsRequireToRender;
