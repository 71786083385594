import { Auth } from "aws-amplify";
import axios from "axios";
import qs from "qs";
import { always, assocPath, isNil, not, path, pipe, prop, when } from "ramda";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import config from "../config";
import { ExecutorContext } from "hooks/dataHooks";
import DataProvider from "./DataProvider";
import logger from "util/logger";

const cognitoUserSelector = path(["cognito", "user"]);
const currentAgencySelector = path(["currentAgency", "id"]);
const defaultParamsSerializer = params => qs.stringify(params, { arrayFormat: "repeat" });
const enrichRequestWithAuth = req => session => ({
  ...req,
  withCredentials: true,
  headers: {
    ...(req.headers || {}),
    Authorization: session.idToken.jwtToken
  }
});
const warn = e => logger.warn(new Error(e));

// Factory for cancelable request runner.

/**
 * @param {import("axios").AxiosInstance} axi
 */
const makeCancelableRequestFactory = axi => ({ url, data, headers, params, method } = {}) => {
  const cancelSource = axios.CancelToken.source();
  return [
    () => axi.request({ method, url, data, headers, params, cancelToken: cancelSource.token }).then(prop("data")),
    () => cancelSource.cancel("Request was cancelled")
  ];
};

const useAxiosInstance = interceptor =>
  useMemo(() => {
    const axi = axios.create({ baseURL: config.api });
    if (!!interceptor) {
      axi.interceptors.request.use(interceptor, warn);
    }
    axi.defaults.paramsSerializer = defaultParamsSerializer;
    axi.defaults.headers.get["Accept"] = "application/json";
    axi.defaults.headers.post["Content-Type"] = "application/json";
    axi.defaults.headers.delete["Content-Type"] = "application/json";
    axi.defaults.headers.put["Content-Type"] = "application/json";
    axi.defaults.headers.patch["Content-Type"] = "application/json";
    return axi;
  }, [interceptor]);

// Provider

export const ProviderWithEnrichment = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const requestFactory = useMemo(() => makeCancelableRequestFactory(axiosInstance), [axiosInstance]);
  return (
    <ExecutorContext.Provider value={requestFactory}>
      <DataProvider>{children}</DataProvider>
    </ExecutorContext.Provider>
  );
};

export const ProviderWithoutEnrichment = ({ children }) => {
  const currentCognitoUser = useSelector(cognitoUserSelector);
  const currentAgencyId = useSelector(currentAgencySelector);
  const currentAgencyDefined = useCallback(always(not(isNil(currentAgencyId))), [currentAgencyId]);
  const requestNeedAuthorization = useCallback(req => !!currentCognitoUser && !(req.url || "").startsWith("https:"), [
    currentCognitoUser
  ]);
  const axiosRequestInterceptor = useCallback(
    requestConfig =>
      Promise.resolve(requestConfig)
        .then(when(currentAgencyDefined, assocPath(["headers", "x-ww-agency-id"], currentAgencyId)))
        .then(when(requestNeedAuthorization, req => Auth.currentSession().then(enrichRequestWithAuth(req))))
        .catch(pipe(warn, Promise.reject)),
    [currentAgencyDefined, requestNeedAuthorization, currentAgencyId]
  );
  const axiosInstance = useAxiosInstance(axiosRequestInterceptor);
  const requestFactory = useMemo(() => makeCancelableRequestFactory(axiosInstance), [axiosInstance]);
  return (
    <ExecutorContext.Provider value={requestFactory}>
      <DataProvider>{children}</DataProvider>
    </ExecutorContext.Provider>
  );
};

const ProviderFactory = (skipRequestEnrichment = false) =>
  skipRequestEnrichment ? ProviderWithEnrichment : ProviderWithoutEnrichment;

export default ProviderFactory;
