// uncomment this line if you have something to import from your scss module
// import styles from "./CharacterCount.module.scss"

import React from "react";
import PropTypes from "prop-types";

export default function CharacterCount({ count, max }) {
  return (
    <div className="text-muted font-xs w-100 text-end">
      {count || 0}
      {max && ` / ${max}`}
      {" Characters"}
    </div>
  );
}

CharacterCount.propTypes = {
  count: PropTypes.number.isRequired,
  max: PropTypes.number
};
