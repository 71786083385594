import React, { useEffect, useState } from "react";
import WWButton from "../Buttons/WWButton";
import { Popover, PopoverHeader, PopoverBody, Button } from "reactstrap";
import { range, uniqueId, isNil } from "lodash";
import Select from "react-select";
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";
import Time from "./Time";

const TWELWE_HOURS = 12;
const AM = "AM";
const PM = "PM";

const toSelectOption = v => Object.assign({ label: v, value: v });
const hourOptions = range(1, 13).map(h => toSelectOption(h));
const minuteOptions = range(0, 60, 5).map(m => toSelectOption(m));
const amPmOptions = [AM, PM].map(v => toSelectOption(v));

function convert12to24(hour12, amPm) {
  let hour24 = parseInt(hour12, 10);
  if (amPm === AM && hour24 === TWELWE_HOURS) {
    hour24 = 0;
  } else if (amPm === PM && hour24 < TWELWE_HOURS) {
    hour24 += TWELWE_HOURS;
  }
  return hour24;
}

function convert24to12(hour24) {
  const hour12 = hour24 % TWELWE_HOURS || TWELWE_HOURS;
  return [hour12, hour24 < TWELWE_HOURS ? AM : PM];
}

function _minute(date) {
  return date && date.getMinutes();
}

function _hour(date) {
  return date && convert24to12(date.getHours())[0];
}

function _amPm(date) {
  return date && convert24to12(date.getHours())[1];
}

/**
 * A Date / time selector widget that works with plain Javascript Date objects.
 */
export default function DateTimeSelector({
  date,
  onChange,
  target,
  showTime = true,
  size = "md",
  color = "link",
  clearable = true,
  format = "datetime",
  emptyDateText
}) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [id, setButtonId] = useState(target ? target : uniqueId("datetime-button-"));
  const [internalDate, setInternalDate] = useState(date);
  const [amPm, setAmPm] = useState(_amPm(date));
  const [minute, setMinute] = useState(_minute(date));
  const [hour, setHour] = useState(_hour(date));

  const close = () => setPopoverOpen(false);

  useEffect(() => {
    if (!isNil(target)) {
      setButtonId(target);
    }
  }, [target]);

  useEffect(() => {
    setInternalDate(date);
    if (date) {
      setAmPm(_amPm(date));
      setMinute(_minute(date));
      setHour(_hour(date));
    }
  }, [date && date.getTime()]);

  useEffect(() => {
    if (internalDate) {
      const update = new Date(
        internalDate.getFullYear(),
        internalDate.getMonth(),
        internalDate.getDate(),
        hour ? convert12to24(hour, amPm) : internalDate.getHours(),
        minute || _minute(internalDate)
      );

      //ignore no-op updates
      if (onChange && (!date || update.toLocaleString() !== date.toLocaleString())) onChange(update);
    } else {
      //if they aren't both nil
      if (internalDate !== date) onChange && onChange();
    }

    if (!showTime) close();
  }, [minute, hour, amPm, internalDate && internalDate.toLocaleDateString()]);

  return (
    <>
      {!target && (
        <span>
          <WWButton
            id={id}
            onClick={evt => evt.preventDefault()}
            size={size}
            className="ps-0 ms-0"
            iconClass="fa fa-calendar"
            color={color}
          >
            <Time withIcon={false} format={format} date={date} emptyDateText={emptyDateText} />
          </WWButton>
          {clearable && internalDate && (
            <WWButton
              size={size}
              className="ps-0 ms-0"
              iconClass="fa fa-close"
              color={color}
              tooltip="Clear date"
              onClick={() => setInternalDate()}
            />
          )}
        </span>
      )}
      <Popover
        trigger="click"
        placement="top"
        target={id}
        toggle={() => setPopoverOpen(!popoverOpen)}
        isOpen={popoverOpen}
      >
        <PopoverHeader>Select a Date {showTime && " and Time"}</PopoverHeader>
        <PopoverBody className="p-0">
          {/* I was unable to find the option to make date picker appear syncroniously with
              correctly precomputed height and width... So I just take computed result
              from inspector and wrap calendar in a fixed size div...
          */}
          <div style={{ height: 300 }}>
            <DayPickerSingleDateController
              noBorder={true}
              onDateChange={date => setInternalDate(date.toDate())}
              date={moment(date)}
              transitionDuration={0}
              daySize={32}
            />
          </div>
          {showTime && (
            <div className="datetime-selector w-100">
              <Select
                onChange={e => setHour(e.value)}
                options={hourOptions}
                placeholder="Hours"
                value={date && toSelectOption(hour)}
              />
              <Select
                onChange={e => setMinute(e.value)}
                options={minuteOptions}
                placeholder="Minutes"
                value={date && toSelectOption(date.getMinutes())}
              />
              <Select
                onChange={e => setAmPm(e.value)}
                options={amPmOptions}
                placeholder="AM"
                value={date && toSelectOption(amPm)}
              />
            </div>
          )}
          {showTime && (
            <div className="p-2 text-center">
              <Button onClick={close} size="sm" color="primary" style={{ width: "100%" }}>
                Done
              </Button>
            </div>
          )}
        </PopoverBody>
      </Popover>
    </>
  );
}
