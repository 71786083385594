import { propOr } from "ramda";
import React, { useMemo } from "react";
import { FormGroup } from "reactstrap";
import Time from "components/DateTime/Time";
import Input from "components/Form/Input";
import Label from "components/Form/Label";
import { groupCustomerProducts } from "util/productsUtils";
import usePureMemo from "hooks/usePureMemo/usePureMemo";

/**
 *
 * @param {{
 *  onUpdate: function
 *  customer: object
 * }} props
 * @returns
 */
export default function AdminProductSet({ customer, onUpdate }) {
  const customerProducts = usePureMemo(propOr([], "products"), customer);
  const products = useMemo(() => groupCustomerProducts(customerProducts), [customerProducts]);
  return (
    <>
      {products?.map(({ category, products }) => (
        <div key={category} className="mb-3">
          <p className="fs-lg mb-0">{category}</p>
          <div className="d-flex flex-row flex-wrap justify-content-start">
            {products.map(({ product, active, activationDate, deactivationDate, label }) => (
              <FormGroup check key={product} style={{ flexBasis: "165px" }} className="m-2">
                <Label check>
                  <Input type="checkbox" name={product} checked={active} onChange={onUpdate} />
                  {label}
                </Label>
                {(activationDate || deactivationDate) && (
                  <span className="text-muted d-flex flex-wrap">
                    {activationDate && (
                      <>
                        <Time className="d-block text-muted" withIcon={false} date={activationDate} />{" "}
                        <span className="mx-1">to</span>
                      </>
                    )}
                    {!active && deactivationDate ? (
                      <Time className="d-block text-danger" withIcon={false} date={deactivationDate} />
                    ) : (
                      "Present"
                    )}
                  </span>
                )}
              </FormGroup>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}
