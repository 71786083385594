import React from "react";
import classnames from "classnames";
import { AuthorizationRequiredToRender } from "../Auth/Authorization";
import { Card, CardBody, CardHeader, CardHeaderActions, CardTitle } from "../Card";
import AjaxLoader from "components/Misc/AjaxLoader";

/**
 * @deprecated
 */
export const DashboardWidget = ({
  title,
  roles,
  children,
  actions,
  className,
  titleClassName,
  actionsClassName,
  isLoading
}) => {
  return (
    <AuthorizationRequiredToRender roles={roles}>
      <Card className={classnames("flex-grow-1", className)}>
        {(!!title || !!actions) && (
          <CardHeader className="d-flex align-items-center">
            {!!title && <CardTitle className={titleClassName}>{title}</CardTitle>}
            {!!actions && <CardHeaderActions className={actionsClassName}>{actions}</CardHeaderActions>}
          </CardHeader>
        )}
        {isLoading ? <AjaxLoader /> : <CardBody className="flex-grow-1">{children}</CardBody>}
      </Card>
    </AuthorizationRequiredToRender>
  );
};
