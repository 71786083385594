import "@webscopeio/react-textarea-autocomplete/style.css";
import _, { flow, isNull, join, noop, omit, size } from "lodash";
import moment from "moment";
import React, { Component, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledButtonDropdown,
  UncontrolledDropdown
} from "reactstrap";
import copy from "copy-to-clipboard";
import * as customerApi from "api/customerApi";
import * as api from "api/reviewApi";
import * as approvalApi from "api/approvalApi";
import withAuthorization, { AuthorizationRequiredToRender } from "../../components/Auth/Authorization";
import { AgencyAdminFragment, AgencyAdminRow } from "../../components/Auth/AuthorizedComponents";
import { permissions } from "../../components/Auth/permissions";
import { Card, CardBody, CardHeader, CardHeaderAction, CardHeaderActions, CardTitle } from "../../components/Card";
import CustomerMessageComposer from "../../components/Contacts/CustomerMessageComposer";
import FormField from "../../components/Form/FormField";
import ReactionSelector from "../../components/Form/ReactionSelector";
import SentimentSelector from "../../components/Form/SentimentSelector";
import Toggle from "../../components/Form/Toggle";
import { Typeahead } from "../../components/Form/Typeahead/Typeahead";
import WWButton from "../../components/Buttons/WWButton";
import ButtonArea from "../../components/Layout/ButtonArea";
import MessageThread from "../../components/Messages/MessageThread";
import AjaxLoader from "../../components/Misc/AjaxLoader";
import CopyButton from "../../components/Buttons/CopyButton";
import LoadingPage from "../../components/Misc/LoadingPage";
import { Messages } from "../../components/Misc/StatusMessages";
import { errorCaughtNotifier, withLocalNotifications } from "../../components/Notifications/notification";
import Pager from "../../components/Paging/Pager";
import { Callout } from "../../components/Reviews/Callout";
import Review from "../../components/Reviews/Review";
import ReviewReply from "../../components/Reviews/Reply/ReviewReply";
import ReviewThread from "../../components/Reviews/ReviewThread";
import ReplyActions from "../../components/Reviews/Reply/ReplyActions";
import ReplyActionBanner from "../../components/Reviews/Reply/ReplyActionBanner";
import { Table, TBody, TD, TR } from "../../components/Table";
import ReplyHistoryModal from "components/Reviews/Reply/ReplyHistoryModal";
import HipaaModal from "components/Reviews/Hipaa/HipaaModal";
import ResponderAiGeneratedResponse from "components/Review/ResponderAiGeneratedResponse/ResponderAiGeneratedResponse";
import FlagSelectDropdown from "components/Reviews/FlagSelectDropdown/FlagSelectDropdown";
import ReportAsDropdown from "./ReportAsDropdown";
import StatusDropdown from "./StatusDropdown";
import FloatingEditor from "./FloatingEditor";
import InteractionInfo from "./InteractionInfo";
import { bySource } from "data/sites";
import { getReplyReportAsOptionLabel } from "data/reportReasons";
import { RESPONDER_FLAG_VALUES } from "data/options";
import { useFirebaseDB } from "hooks/firebaseHooks";
import { beginAjaxCall, endAjaxCall } from "../../redux/actions/ajaxStatusActions";
import { LONG_NOTIFICATION_TIMEOUT, QUICK_NOTIFICATION_TIMEOUT } from "util/constants";
import logger from "util/logger";
import { currentUser, currentUserFullName, isCurrentUserInGroup } from "util/userUtils";
import { roundToOne } from "util/roundToOne";
import { filterNotCancelledMessages } from "util/messageFilters";
import { thirdpartyReviewUrl } from "util/siteUtils";
import { isAdOrPost } from "util/reviewUtils";
import { customerHasReviewResponseProduct, isProductActiveForCustomer } from "util/customerUtils";
import isEmpty from "lodash/isEmpty";
import { useFilterSearch } from "hooks/filteringHooks";
import { useTagSetById } from "hooks/data/tagSetHooks";
import ResponderNotes from "components/ResponderNotes/ResponderNotes";

function canReply(review) {
  let can = true;
  if (review.type === "QUESTION") {
    can = true;
  } else if (review.replies && review.replies.length > 0) {
    if (["GOOGLE", "CARS_DOT_COM"].indexOf(review.source) >= 0) can = false;
  }
  return can && review.responderFlag !== RESPONDER_FLAG_VALUES.doNotRespond && review.canReply;
}

const ReviewReplyPermission = withAuthorization(permissions.REVIEW_REPLY)(React.Fragment);
const ContactMessagePermission = withAuthorization(permissions.REVIEW_REPLY)(React.Fragment);
const LocationManagePermission = withAuthorization(permissions.LOCATION_MANAGE)(React.Fragment);

class ReplyReview extends Component {
  constructor(props) {
    super(props);

    this.dbRef = props.firebaseDB.ref("review_reply/" + this.props.match.params.id);

    this.state = {
      review: undefined,
      reply: {
        author: props.author,
        createdOn: new Date(),
        content: "",
        state: "DRAFT"
      },
      editors: {},
      notes: [],
      contactCustomer: false,
      loadingReplies: true,
      loadingMessages: true,
      loadingHistory: true,
      loadingMatches: true,
      loadingSimilarReviews: true,
      matchSearch: "",
      matches: [],
      lockedNotificationCloseCB: noop,
      repliesSortingStatus: "recentlyRepliedFirst",
      similarReviewsPage: 0
    };

    this.updateReplyState = this.updateReplyState.bind(this);
    this.saveReview = this.saveReview.bind(this);
    this.approveReview = this.approveReview.bind(this);
    this.saveReply = this.saveReply.bind(this);
    this.openDealerraterDealerPanel = this.openDealerraterDealerPanel.bind(this);
    this.openThirdPartyUrl = this.openThirdPartyUrl.bind(this);
    this.openApartmentRatings = this.openApartmentRatings.bind(this);
    this.openCarGurus = this.openCarGurus.bind(this);
    this.loadReview = this.loadReview.bind(this);
    this.loadReplies = this.loadReplies.bind(this);
    this.loadMessages = this.loadMessages.bind(this);
    this.loadHistory = this.loadHistory.bind(this);
    this.loadMatches = this.loadMatches.bind(this);
    this.selectHistory = this.selectHistory.bind(this);
    this.loadSimilar = this.loadSimilar.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.updateTags = this.updateTags.bind(this);
    this.updateTopics = this.updateTopics.bind(this);
    this.updateSentiment = this.updateSentiment.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.newReply = this.newReply.bind(this);
    this.hasReplies = this.hasReplies.bind(this);
    this.editReply = this.editReply.bind(this);
    this.unpublishReply = this.unpublishReply.bind(this);
    this.updateInternalTags = this.updateInternalTags.bind(this);
    this.reactReview = this.reactReview.bind(this);
    this.reactReply = this.reactReply.bind(this);
    this.unreactReply = this.unreactReply.bind(this);
    this.deleteReview = this.deleteReview.bind(this);
    this.updateReplySentiment = this.updateReplySentiment.bind(this);
    this.updateReplyStatus = this.updateReplyStatus.bind(this);
    this.updateReply = this.updateReply.bind(this);
    this.deleteReply = this.deleteReply.bind(this);
    this.removeMessage = this.removeMessage.bind(this);
    this.close = this.close.bind(this);
    this.removeCurrentEditor = this.removeCurrentEditor.bind(this);
    this.linkInteraction = this.linkInteraction.bind(this);
    this.unlinkInteraction = this.unlinkInteraction.bind(this);
    this.filterInteractions = this.filterMatches.bind(this);
    this.closeCustomerMessageComposer = this.closeCustomerMessageComposer.bind(this);
    this.reportAs = this.reportAs.bind(this);
    this.reportReplyAs = this.reportReplyAs.bind(this);
    this.setFlag = this.setFlag.bind(this);
  }

  componentDidMount() {
    //Prevents these dbRef.on starting before ReviewListRow cleans up theirs with dbRef.off
    setTimeout(() => {
      this.dbRef.on("child_added", data =>
        this.setState({
          editors: {
            ...this.state.editors,
            [data.key]: data.val()
          }
        })
      );
      this.dbRef.on("child_removed", data =>
        this.setState({
          editors: omit(this.state.editors, [data.key])
        })
      );
      this.dbRef.on("child_changed", data =>
        this.setState({
          editors: {
            ...this.state.editors,
            [data.key]: data.val()
          }
        })
      );
    }, 0);

    let id = this.props.match.params.id;
    if (id) {
      this.loadReview(id);
    }

    const user = currentUser();
    this.dbRef.child(user.sub).set(currentUserFullName() || user.email);

    window.addEventListener("beforeunload", this.removeCurrentEditor);
  }

  componentWillUnmount() {
    this.dbRef.off();
    this.removeCurrentEditor();
    window.removeEventListener("beforeunload", this.removeCurrentEditor);
  }

  removeCurrentEditor() {
    const user = currentUser();
    this.dbRef.child(user.sub).remove();
  }

  componentDidUpdate(__, prevState) {
    if (_.get(this.state, "review.reactions", []).length !== _.get(prevState, "review.reactions", []).length) {
      let review = this.state.review;
      let accountReacted = false;
      if (review.source === "FACEBOOK") {
        let pageNames = _.map(review.customer.facebookInfo, info => {
          return info.pageName;
        });
        accountReacted =
          _.filter(review.reactions, r => _.findIndex(pageNames, name => name === r.author) !== -1).length >= 1;
      } else if (review.source === "LINKEDIN") {
        let orgNames = _.map(review.customer.linkedInInfo, info => {
          return info.organizationName;
        });
        accountReacted =
          _.filter(review.reactions, r => _.findIndex(orgNames, name => name === r.author) !== -1).length >= 1;
      }
      this.setState({ accountReacted });
    }

    if (!prevState.review && this.state.review) {
      this.loadReplies();
      this.loadMessages();
      this.loadHistory();
      this.loadSimilar();
      this.loadMatches();
    }

    if (prevState.review?.responderFlag !== this.state.review?.responderFlag) {
      if (this.state.review?.responderFlag === RESPONDER_FLAG_VALUES.doNotRespond) {
        this.props
          .notify({
            title: "Review is locked",
            body: "This review is locked. Unlock before replying.",
            closable: false,
            icon: "warning"
          })
          .then(lockedNotificationCloseCB => this.setState({ lockedNotificationCloseCB }));
      } else {
        this.state.lockedNotificationCloseCB();
      }
    }
  }

  removeMessage() {
    this.loadMessages();
  }

  updateReplyState(event) {
    const field = event.target.name;
    let reply = Object.assign({}, this.state.reply);
    if (field === "customer") {
      reply.customer.id = event.target.value.id;
    } else {
      _.set(reply, field, event.target.value);
    }
    return this.setState({ reply });
  }

  updateStatus(status) {
    //not using setState b/c we're leavin this page anyway
    api
      .updateStatus(this.state.review.id, status)
      .then(() => {
        if (status === "UNREPLIED") {
          let review = Object.assign({}, this.state.review, { status });
          this.setState({ review }, () =>
            this.props.notify({
              body: "Saved",
              icon: "info",
              timeout: 1500
            })
          );
        } else {
          this.close();
        }
      })
      .catch(errorCaughtNotifier(this.props.notify, "Status was not updated"));
  }

  reportAs(value) {
    const {
      review,
      review: { id }
    } = this.state;
    return api
      .reportAs(id, value)
      .then(({ data }) => {
        this.setState({ review: { ...review, reportedAs: data.reportedAs, status: data.status } }, () =>
          this.props.notify({
            body: value ? `Reported as ${getReplyReportAsOptionLabel(value).toLowerCase()}` : "Cleared",
            icon: "info",
            timeout: 1500
          })
        );
        if (!isNull(value)) {
          window.open(thirdpartyReviewUrl(this.state.review), "_blank");
        }
      })
      .catch(err => errorCaughtNotifier(this.props.notify, "Reporting failed")(err));
  }

  reportReplyAs(reply, value) {
    return api
      .reportReplyAs(reply.id, value)
      .then(() => this.loadReplies())
      .catch(err => errorCaughtNotifier(this.props.notify, "Reporting failed")(err));
  }

  close() {
    this.props.history.push({
      pathname: "/reviews/list",
      search: this.props.queueSearchValue
    });
  }

  loadReplies() {
    const { review } = this.state;
    api
      .loadPagedReplies(review.id)
      .then(res => {
        let reply = this.state.reply;
        const replies = _.get(res.data, ["_embedded", "reviewReplies"], []);
        if (replies && replies.length > 0) {
          reply = replies[0];
        }
        this.setState({
          review: Object.assign(this.state.review, { replies }),
          reply,
          loadingReplies: false
        });
      })
      .catch(err =>
        this.setState(
          {
            loadingReplies: false
          },
          () => errorCaughtNotifier(this.props.notify, "Failed loading replies")(err)
        )
      );
  }

  loadSimilar() {
    const { review, similarReviewsPage } = this.state;
    this.setState({ loadingSimilarReviews: true });
    api
      .loadSimilar(review.id, similarReviewsPage)
      .then(res =>
        this.setState({
          similar: _.get(res.data, ["_embedded", "similarReviewDtoes"], []),
          pageInfo: res.data.page
        })
      )
      .catch(errorCaughtNotifier(this.props.notify, "Failed to load similar reviews"))
      .finally(() => this.setState({ loadingSimilarReviews: false }));
  }

  loadHistory() {
    const { review } = this.state;
    api
      .loadHistory(review.id)
      .then(res =>
        this.setState({
          history: _.reverse(res.data.revisions),
          legacyHistory: res.data.legacy
        })
      )
      .finally(() => this.setState({ loadingHistory: false }));
  }

  loadMatches() {
    const { review } = this.state;
    if (isProductActiveForCustomer(review.customer, "REVIEW_LEAD_IMPORT")) {
      if (!this.state.review.interaction && size(this.state.matches) === 0) {
        const { review } = this.state;
        api
          .loadMatches(review.id)
          .then(res => {
            this.setState({
              matches: res.data || []
            });
          })
          .finally(() => this.setState({ loadingMatches: false }));
      } else {
        this.setState({ loadingMatches: false });
      }
    }
  }

  linkInteraction(interaction, position) {
    // we does not care about successful linking. Just about actual
    // clicking on the action. So we do not put logging in response promise.
    logger.action("link_interaction", {
      reviewId: this.state.review.id,
      interaction,
      position
    });
    api
      .linkInteraction(this.state.review.id, interaction.id)
      .then(() => {
        let review = Object.assign({}, this.state.review);
        review["interaction"] = interaction;
        this.setState({ review });
      })
      .catch(errorCaughtNotifier(this.props.notify, "Failed to link interaction"));
  }

  unlinkInteraction() {
    // we does not care about successful unlinking. Just about actual
    // clicking on the action. So we do not put logging in response promise.
    logger.action("unlink_interaction", {
      reviewId: this.state.review.id,
      interaction: this.state.review.interaction
    });
    api
      .unlinkInteraction(this.state.review.id, this.state.review.interaction.id)
      .then(() => {
        let review = Object.assign({}, this.state.review);
        review["interaction"] = null;
        this.setState({ review });
        this.loadMatches();
      })
      .catch(errorCaughtNotifier(this.props.notify, "Failed to unlink interaction"));
  }

  filterMatches(interactions, filter) {
    if (!filter || !_.trim(filter)) {
      return interactions;
    }
    return _.filter(interactions, i => {
      const r = i.reviewLead;
      return _.includes(
        _.toLower(r.firstName + " " + r.lastName + " - " + join(i.tags, ",")),
        _.toLower(_.trim(filter))
      );
    });
  }

  selectHistory(idx) {
    const entry = this.state.history[idx].entity;
    const review = Object.assign(this.state.review, {
      content: entry.content,
      rating: entry.rating,
      sentiment: entry.sentiment,
      attachments: entry.attachments
    });

    this.setState({ review });
  }

  loadMessages() {
    const { review } = this.state;
    api
      .loadMessages(review.id)
      .then(res =>
        this.setState({
          loadingMessages: false,
          notes: _.get(res.data, ["_embedded", "messages"], [])
        })
      )
      .catch(err =>
        this.setState(
          {
            loadingMessages: false
          },
          () => errorCaughtNotifier(this.props.notify, "Failed to load messages")(err)
        )
      );
  }

  loadReview(id) {
    this.props.dispatch(beginAjaxCall());
    return api
      .loadReview(id)
      .then(response => this.setState({ review: response.data }))
      .catch(error => {
        console.log(error);
        if (error.response.status === 404) {
          this.props.notify({
            icon: "danger",
            body: <Messages messages="Please check your selected agency." />,
            title: "Review not found"
          });
        } else {
          errorCaughtNotifier(this.props.notify, "Failed loading review")(error);
        }
      })
      .finally(() => this.props.dispatch(endAjaxCall()));
  }

  saveReview(validateTags = true) {
    if (validateTags && _.isEmpty(this.state.review.tags)) {
      return new Promise((_, reject) => {
        this.props.notify({
          icon: "danger",
          title: "Review category is required"
        });
        reject("tag required");
      });
    } else {
      return api
        .saveReview(this.state.review)
        .then(() =>
          this.props.notify({
            body: "Saved",
            icon: "info",
            timeout: QUICK_NOTIFICATION_TIMEOUT
          })
        )
        .catch(errorCaughtNotifier(this.props.notify));
    }
  }

  approveReview() {
    approvalApi
      .approveReview(this.state.review.id)
      .then(() => {
        this.props.notify({
          title: "Approved",
          icon: "success",
          timeout: QUICK_NOTIFICATION_TIMEOUT
        });
        this.loadReview(this.props.match.params.id);
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  saveReply(reply, publish, publishNow = false, sendForApproval = false) {
    if (!isCurrentUserInGroup(permissions.REVIEW_REPLY)) {
      return this.approveReview();
    }
    return new Promise((resolve, reject) => {
      if (_.isEmpty(this.state.review.tags)) {
        this.props.notify({
          icon: "danger",
          title: "Review category is required"
        });
        reject("tag required");
      } else {
        api
          .saveReply(
            Object.assign(
              {},
              reply.status === "REMOVED" && !publish
                ? {
                    ...reply,
                    status: "DRAFT"
                  }
                : reply,
              {
                review: { id: this.state.review.id }
              }
            ),
            publish,
            sendForApproval
          )
          .then(() => this.loadReplies())
          .then(() => {
            this.setState(
              {
                editing: undefined
              },
              () =>
                this.props.notify({
                  title: publish ? "Published" : "Saved",
                  icon: "success",
                  timeout: QUICK_NOTIFICATION_TIMEOUT
                })
            );
          })
          .then(() => {
            const shouldEditOnOtherSite = [
              "DEALERRATER",
              "YELP",
              "OPEN_TABLE",
              "APARTMENT_RATINGS",
              "CAR_GURUS"
            ].includes(this.state.review.source);
            if (publish) {
              if (publishNow && shouldEditOnOtherSite) {
                copy(reply.content);
              }
              if (this.state.review.source === "DEALERRATER") {
                this.openDealerraterDealerPanel();
              } else if (this.state.review.source === "YELP") {
                this.openThirdPartyUrl();
              } else if (this.state.review.source === "OPEN_TABLE") {
                this.openThirdPartyUrl();
              } else if (this.state.review.source === "APARTMENT_RATINGS") {
                this.openApartmentRatings();
              } else if (this.state.review.source === "CAR_GURUS") {
                this.openCarGurus();
              } else {
                this.close();
              }
            } else if (reply.status === "REVIEW") {
              this.close();
            }
          })
          .then(() => resolve())
          .catch(err => {
            errorCaughtNotifier(this.props.notify)(err);
            reject();
          });
      }
    });
  }

  cancel(event) {
    event.preventDefault();
    this.close();
  }

  openDealerraterDealerPanel() {
    window.open(
      "https://www.dealerrater.com/dp/" +
        this.state.review.customer.dealerraterInfo[0].dealerId +
        "/reviews/" +
        this.state.review.thirdpartyId,
      "_blank"
    );
  }

  openThirdPartyUrl() {
    window.open(this.state.review.thirdpartyUrl, "_blank");
  }

  openApartmentRatings() {
    window.open("https://exchange.satisfacts.com/reviews/" + this.state.review.thirdpartyId, "_blank");
  }

  openCarGurus() {
    window.open(
      "https://www.cargurus.com/Cars/dealerdashboard/selfservice/manageSalesReviews.action?serviceProvider=" +
        this.state.review.customer.carGurusInfo[0].dealerId,
      "_blank"
    );
  }

  updateTags(event) {
    let review = Object.assign({}, this.state.review, {
      [event.target.name]: event.target.value
    });
    this.setState({ review }, () => {
      this.saveReview().catch(errorCaughtNotifier(this.props.notify));
    });
  }

  updateTopics(event) {
    let reviewEntities = [
      ...(this.state.review.reviewEntities?.filter(entity => entity.type !== "TOPIC") || []),
      ...(event.target.value?.map(value => ({ type: "TOPIC", value })) || [])
    ];
    let review = omit(Object.assign({}, this.state.review, { reviewEntities }), "subTags");
    this.setState({ review }, () => {
      this.saveReview().catch(errorCaughtNotifier(this.props.notify));
    });
  }

  updateSentiment(sentiment) {
    let review = { ...this.state.review, sentiment };
    this.setState({ review }, () => {
      this.saveReview().catch(errorCaughtNotifier(this.props.notify));
    });
  }

  updateContent(content, translatedContent) {
    let review = { ...this.state.review, content, translatedContent };
    this.setState({ review }, () => {
      this.saveReview(false).catch(errorCaughtNotifier(this.props.notify));
    });
  }

  updateInternalTags(tags) {
    let review = Object.assign({}, this.state.review, { internalTags: tags });
    this.setState({ review }, () => {
      this.saveReview().catch(errorCaughtNotifier(this.props.notify));
    });
  }

  updateNotes(event) {
    let review = Object.assign({}, this.state.review, {
      notes: event.target.value
    });
    this.setState({ review });
  }

  newReply(context, reply, replyToReply = false) {
    this.setState({
      editing: {
        target: {
          author: this.props.author,
          createdOn: new Date(),
          content: reply?.content || "",
          auto: reply?.auto || false,
          source: "WIDEWAIL",
          thirdpartyParentId: context.thirdpartyId,
          translatedContent: "",
          parentId: context.id,
          replyToReply
        },
        context
      }
    });
  }

  unpublishReply(reply) {
    return api
      .unpublishReply(reply.id)
      .then(() => {
        this.loadReplies();
        this.props.notify({
          timeout: QUICK_NOTIFICATION_TIMEOUT,
          icon: "success",
          title: "Unpublished reply"
        });
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  hasReplies() {
    if (_.filter(this.state.review.replies, r => r.status === "PUBLISHED").length > 0) {
      return true;
    }
    // if (this.state.review.history) {
    //   for (let i = 0; i < this.state.history.length; i++) {
    //     if (
    //       _.filter(
    //         this.state.review.history[i].replies,
    //         (r) => r.status === "PUBLISHED"
    //       ).length > 0
    //     ) {
    //       return true;
    //     }
    //   }
    // }
  }

  editReply(target, context) {
    // let review = Object.assign({}, this.state.review);
    // review.replies.forEach(r => { if (r.id == reply.id) r.editing = true });
    // this.setState({ review });
    this.setState({ editing: { target, context } });
  }

  reactReview(reaction) {
    api
      .reactReview(this.state.review.id, reaction)
      .then(res => {
        let reactions = res.data._embedded.reviewReactions;
        this.setState({
          review: Object.assign({}, this.state.review, { reactions }, { liked: true })
        });
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  unreactReview(reaction) {
    api
      .unreactReview(this.state.review.id, reaction)
      .then(res => {
        const reactions = res.data._embedded.reviewReactions;
        this.setState({
          review: Object.assign({}, this.state.review, { reactions }, { liked: false })
        });
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  updateReply(id, field, value) {
    let updatedReplies = this.finaAndUpdateReply(id, field, value, this.state.review.replies);

    this.setState({
      review: Object.assign({}, this.state.review, { updatedReplies })
    });
  }

  finaAndUpdateReply(id, field, value, replies) {
    return replies.map(reply => {
      if (reply.replies) {
        reply.replies = this.finaAndUpdateReply(id, field, value, reply.replies);
      }
      if (reply.id === id) {
        reply[field] = value;
      }
      return reply;
    });
  }

  reactReply(replyId, reaction) {
    api
      .reactReply(replyId, reaction)
      .then(res => {
        this.updateReply(replyId, "reactions", res.data._embedded.reviewReplyReactions);
        this.updateReply(replyId, "liked", true);
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  unreactReply(replyId, reaction) {
    api
      .unreactReply(replyId, reaction)
      .then(res => {
        this.updateReply(replyId, "reactions", res.data?._embedded?.reviewReplyReactions);
        this.updateReply(replyId, "liked", false);
      })
      .catch(errorCaughtNotifier(this.props.notify));
  }

  updateReplySentiment(reply, sentiment) {
    reply.sentiment = sentiment;
    api.updateReplySentiment(reply).then(() => this.updateReply(reply.id, "sentiment", sentiment));
  }

  updateReplyStatus(reply, status) {
    reply.status = status;
    api.updateReplyStatus(reply).then(() => this.loadReplies());
  }

  deleteReview() {
    api
      .deleteReview(this.state.review.id)
      .then(() => this.close())
      .catch(errorCaughtNotifier(this.props.notify));
  }

  setFlag(value) {
    this.setState(
      {
        review: {
          ...this.state.review,
          responderFlag: value
        }
      },
      () => this.saveReview()
    );
  }

  blockAuthor(authorId) {
    customerApi.blockUser(this.state.review.customer.id, authorId).then(() =>
      this.props.notify({
        timeout: LONG_NOTIFICATION_TIMEOUT,
        icon: "success",
        title: "Blocked author"
      })
    );
  }

  deleteReply(reply) {
    api.deleteReply(reply.id).then(() => this.loadReplies());
  }

  closeCustomerMessageComposer(redirectAfterClose, messageSent) {
    if (redirectAfterClose) {
      this.close();
    } else {
      this.setState({ contactCustomer: false });
      if (messageSent) {
        this.props.notify({
          body: "Notification sent",
          icon: "info",
          timeout: 1500
        });
        this.loadMessages();
      }
    }
  }

  get notCancelledNotes() {
    return filterNotCancelledMessages(this.state.notes);
  }

  render() {
    const { history, legacyHistory, similar, editors } = this.state;

    if (!this.state.review) {
      return <LoadingPage />;
    }

    const review =
      this.state.review.source === "FACEBOOK" ||
      this.state.review.source === "INSTAGRAM" ||
      this.state.review.source === "DEALERRATER" ||
      this.state.review.type === "AD"
        ? sortRecentylRepliedFirst(this.state.repliesSortingStatus !== "recentlyRepliedFirst")(this.state.review)
        : this.state.review;

    return (
      <div className="py-4 px-lg-4 container-fluid">
        {size(editors) > 1 && (
          <Row>
            <Col>
              <Alert color="danger">
                Editors:
                <ul>
                  {Object.values(editors).map((v, i) => (
                    <li key={i}>{v}</li>
                  ))}
                </ul>
              </Alert>
            </Col>
          </Row>
        )}
        {/* {review.responderFlag === RESPONDER_FLAG_VALUES.doNotRespond && (
          <Row>
            <Col>
              <Alert color="warning">
                This review is locked. Unlock before replying.
              </Alert>
            </Col>
          </Row>
        )} */}
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardHeader>
                <CardTitle>Reply</CardTitle>
                <FlagSelectDropdown selectedFlag={review.responderFlag} onSelectFlag={this.setFlag} />
                <Toggle
                  className={`ms-2`}
                  toggledIcon="fa-lock text-danger"
                  unToggledIcon="fa-unlock text-muted"
                  value={this.state.review?.responderFlag === RESPONDER_FLAG_VALUES.doNotRespond}
                  onClick={({ target }) =>
                    this.setState(
                      {
                        review: {
                          ...this.state.review,
                          responderFlag: target.value ? RESPONDER_FLAG_VALUES.doNotRespond : null
                        }
                      },
                      () => this.saveReview()
                    )
                  }
                />
                <CardHeaderActions className="ms-auto d-flex justify-content-end">
                  <AgencyAdminFragment>
                    <StatusDropdown
                      review={review}
                      currentStatus={this.state.review.status}
                      onUpdateStatus={this.updateStatus}
                    />
                    {review.source === "DEALERRATER" && (
                      <CardHeaderAction onClick={() => this.openDealerraterDealerPanel()}>
                        Dealer Panel
                      </CardHeaderAction>
                    )}
                    {review.source === "YELP" && (
                      <CardHeaderAction onClick={() => this.openThirdPartyUrl()}>Open Yelp</CardHeaderAction>
                    )}
                    {review.source === "OPEN_TABLE" && (
                      <CardHeaderAction onClick={() => this.openThirdPartyUrl()}>Open OpenTable</CardHeaderAction>
                    )}
                    {review.source === "APARTMENT_RATINGS" && (
                      <CardHeaderAction onClick={() => this.openApartmentRatings()}>
                        Open Apartment Ratings
                      </CardHeaderAction>
                    )}
                    {review.source === "CAR_GURUS" && (
                      <CardHeaderAction onClick={() => this.openCarGurus()}>Open CarGurus</CardHeaderAction>
                    )}
                    {(review.reportedAs || review.canReport) && (
                      <ReportAsDropdown review={review} reportAs={this.reportAs} />
                    )}
                  </AgencyAdminFragment>
                </CardHeaderActions>
              </CardHeader>

              <TopicCategorySelector
                review={review}
                reviewTagSetID={this.state.review.customer.reviewTagSetId}
                updateTopics={this.updateTopics}
                updateTags={this.updateTags}
              />

              <CardBody>
                {_.map(legacyHistory, (h, i) => (
                  <React.Fragment>
                    <MessageThread key={`${h.id}.messages`} messages={h.messages} showRecipients showBlank={true} />
                    <ReviewThread key={i} review={h} />
                  </React.Fragment>
                ))}

                <Row>
                  <Col className="mt-4">
                    <div className="d-flex mb-2">
                      {isAdOrPost(review) && (
                        <SentimentSelector
                          className="me-1"
                          sentiment={review.sentiment}
                          onChange={this.updateSentiment}
                        />
                      )}
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          disabled={!review.canDelete && !review.canLike}
                          className="text-capitalize ps-0 fs-3"
                          color="link"
                          caret
                        >
                          Actions
                        </DropdownToggle>
                        <DropdownMenu>
                          {review.canDelete && (
                            <DropdownItem onClick={this.deleteReview}>
                              <i className="fa fa-trash" />
                              Delete
                            </DropdownItem>
                          )}
                          {review.canLike && (
                            <DropdownItem
                              onClick={() => (review.liked ? this.unreactReview("LIKE") : this.reactReview("LIKE"))}
                            >
                              <i className={`fa ${review.liked ? `fa-thumbs-down` : `fa-thumbs-up`}`} />
                              {review.liked ? "Unlike" : "Like"}
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                      <Link className="ms-auto" to={location => `${location.pathname}/history`} target="_blank">
                        History
                        <Badge color="secondary" className="ms-1">
                          {_.size(history)}
                        </Badge>
                      </Link>
                    </div>
                    <Review
                      review={review}
                      withAge={false}
                      onChange={this.updateContent}
                      allowContentEditing={review?.source === "YELP"}
                      allowTranslationEditing={!!review.translatedContent}
                      showReportedAsBadge={false}
                      showTagLabel
                    />
                  </Col>
                </Row>
                <ResponderNotes id={review.id} />
                {review.source === "YELP" && this.props.currentAgency?.yelpResponderInstructions && (
                  <Row>
                    <Col>
                      <Alert color="warning">
                        <strong className="alert-heading">
                          <i className="fa fa-yelp" />
                          {" Yelp"}
                        </strong>
                        <p>{this.props.currentAgency?.yelpResponderInstructions}</p>
                      </Alert>
                    </Col>
                  </Row>
                )}
                {review.directoryProviderType === "UBERALL" && review.source === "FACEBOOK" && (
                  <Row>
                    <Col>
                      <Alert color="warning">
                        <strong className="alert-heading">Uberall</strong>
                        <p>
                          Facebook doesn't support threading through Uberall and only shows comments at the base level
                        </p>
                      </Alert>
                    </Col>
                  </Row>
                )}
                {this.state.loadingReplies && <AjaxLoader />}
                {!this.state.loadingReplies &&
                  review.replies?.length > 1 &&
                  (review.source === "FACEBOOK" ||
                    review.source === "INSTAGRAM" ||
                    review.source === "DEALERRATER" ||
                    review.type === "AD") && (
                    <RepliesSorter
                      value={this.state.repliesSortingStatus}
                      onChange={v => this.setState({ repliesSortingStatus: v })}
                    />
                  )}
                {_.map(review.replies, (reply, i) => (
                  <Row key={i} className="px-2">
                    <Col>
                      <EditableReply
                        size={{ size: 11 }}
                        review={review}
                        reply={reply}
                        newReply={() => this.newReply(reply)}
                        editReply={() => this.editReply(reply, review)}
                        unpublishReply={this.unpublishReply}
                        reactReply={this.reactReply}
                        unreactReply={this.unreactReply}
                        saveReply={this.saveReply}
                        updateSentiment={this.updateReplySentiment}
                        updateStatus={this.updateReplyStatus}
                        deleteReply={this.deleteReply}
                        reportReplyAs={this.reportReplyAs}
                      />
                      {_.map(reply.replies, (rr, i) => (
                        <EditableReply
                          key={rr.id}
                          size={{ size: 10, offset: 1 }}
                          review={review}
                          reply={rr}
                          newReply={() => this.newReply(reply, "", true)}
                          editReply={() => this.editReply(rr, reply)}
                          unpublishReply={this.unpublishReply}
                          reactReply={this.reactReply}
                          unreactReply={this.unreactReply}
                          saveReply={this.saveReply}
                          updateSentiment={this.updateReplySentiment}
                          updateStatus={this.updateReplyStatus}
                          deleteReply={this.deleteReply}
                          reportReplyAs={this.reportReplyAs}
                        />
                      ))}
                    </Col>
                  </Row>
                ))}
                <ReviewReplyPermission>
                  {canReply(review) && (
                    <Row className="text-center mt-0">
                      <Col>
                        <hr className="mt-0" />
                        {isEmpty(review.replies) && (
                          <ResponderAiGeneratedResponse
                            review={review}
                            onUseReply={content => this.newReply(review, { content })}
                          />
                        )}
                        <Button color="warning" onClick={() => this.newReply(review)}>
                          Reply
                        </Button>
                      </Col>
                    </Row>
                  )}
                </ReviewReplyPermission>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            {isProductActiveForCustomer(review.customer, "REVIEW_LEAD_IMPORT") && (
              <>
                {this.state.loadingMatches ? (
                  <AjaxLoader />
                ) : (
                  <Row>
                    <Col>
                      <Card>
                        <CardHeader>
                          <CardTitle>Interaction</CardTitle>
                        </CardHeader>
                        <CardBody>
                          {this.state.review.interaction ? (
                            <>
                              <InteractionInfo
                                key={1}
                                interaction={this.state.review.interaction}
                                linkable={false}
                                onUnlink={() => this.unlinkInteraction()}
                              />
                            </>
                          ) : (
                            <>
                              <AuthorizationRequiredToRender roles={[permissions.REVIEW_READ]}>
                                <FormField>
                                  <Input
                                    type="text"
                                    placeholder="Search for an interaction"
                                    value={this.state.matchSearch}
                                    onChange={evt => {
                                      this.setState({
                                        matchSearch: evt.target.value || ""
                                      });
                                    }}
                                  />
                                </FormField>
                                <div>{"Matches"}</div>
                                {_.map(
                                  this.filterMatches(this.state.matches, this.state.matchSearch),
                                  (interaction, i) => (
                                    <InteractionInfo
                                      key={i}
                                      interaction={interaction}
                                      onLink={() => this.linkInteraction(interaction, i)}
                                      linkable={true}
                                    />
                                  )
                                )}
                              </AuthorizationRequiredToRender>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              </>
            )}
            {!_.isEmpty(similar) && (
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <CardTitle className="me-3">Similar Reviews</CardTitle>
                      {this.state.loadingSimilarReviews ? (
                        <AjaxLoader />
                      ) : (
                        <Pager
                          pageInfo={this.state.pageInfo}
                          onPageChange={page => {
                            this.setState({ similarReviewsPage: page }, this.loadSimilar);
                          }}
                        />
                      )}
                    </CardHeader>
                    <CardBody>
                      <Table responsive>
                        <TBody>
                          {_.map(similar, r => (
                            <TR key={r.id}>
                              <TD>{bySource(r.source).label}</TD>
                              <TD>{r.author}</TD>
                              <TD>{r.status}</TD>
                              <TD>{r.customer.companyName}</TD>
                              {r.rating ? <TD>{roundToOne(r.rating)}</TD> : <></>}
                              <TD>
                                <i
                                  className="fa fa-external-link link"
                                  onClick={() => window.open("/reviews/list/edit/" + r.id, "_blank")}
                                />
                              </TD>
                            </TR>
                          ))}
                        </TBody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <CardTitle>Location Info</CardTitle>
                    <CardHeaderActions>
                      <LocationManagePermission>
                        <CardHeaderAction onClick={() => window.open("/customers/" + review.customer.id, "_blank")}>
                          Edit Customer
                        </CardHeaderAction>
                      </LocationManagePermission>
                    </CardHeaderActions>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <FormField label="Company Name">{review.customer.companyName}</FormField>
                    <FormField label="Address">{review.customer.fullAddress}</FormField>
                    <FormField label="Response Preference">
                      {review.customer.responsePreference || "Not specified"}
                    </FormField>
                    <FormField label="Notes">
                      {review.customer.notes && review.customer.notes.split("\n").map((l, i) => <div key={i}>{l}</div>)}
                    </FormField>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {review.canContactCustomer && (
              <ContactMessagePermission>
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        <CardTitle>Customer Messages</CardTitle>
                        <CardHeaderActions>
                          <CardHeaderAction onClick={() => this.setState({ contactCustomer: true })}>
                            Contact Customer
                          </CardHeaderAction>
                        </CardHeaderActions>
                      </CardHeader>
                      {this.state.loadingMessages ? (
                        <AjaxLoader />
                      ) : (
                        (this.notCancelledNotes?.length && (
                          <CardBody>
                            <MessageThread
                              messages={this.notCancelledNotes}
                              onRemove={this.removeMessage}
                              showRecipients
                              showBlank={true}
                            />
                          </CardBody>
                        )) ||
                        null
                      )}
                    </Card>
                  </Col>
                </Row>
              </ContactMessagePermission>
            )}
            {review.directoryProviderType === "UBERALL" && (
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <CardTitle>Review Directory</CardTitle>
                      <CardHeaderActions>
                        <CardHeaderAction
                          color="primary"
                          onClick={() => {
                            this.saveReply(this.state.reply, true, true, true);
                          }}
                          disabled={
                            !this.state.reply || !this.state.reply.content || this.state.reply.status === "PUBLISHED"
                          }
                        >
                          Send to Uberall for Approval
                        </CardHeaderAction>
                      </CardHeaderActions>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <CardTitle>Review Notes</CardTitle>
                    <CardHeaderActions>
                      <CardHeaderAction color="primary" onClick={() => this.saveReview(false)}>
                        Save
                      </CardHeaderAction>
                    </CardHeaderActions>
                  </CardHeader>
                  <CardBody>
                    <Input
                      type="textarea"
                      style={{ height: "100px" }}
                      value={review.notes}
                      onChange={this.updateNotes}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <AgencyAdminRow>
              <Col>
                <Card>
                  <CardHeader>
                    <CardTitle>Review Data</CardTitle>
                  </CardHeader>
                  <CardBody className="pt-4">
                    <FormField label="Widewail Tag">
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret size="sm" color="secondary">
                          {_.get(this.state.review, "internalTags", "Set...")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => this.updateInternalTags("Best of")}>Best Of</DropdownItem>
                          <DropdownItem onClick={() => this.updateInternalTags("Worst of")}>Worst Of</DropdownItem>
                          <DropdownItem onClick={() => this.updateInternalTags("Widewail Save")}>
                            Widewail Save
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </FormField>
                    <FormField label="Status">{review.status}</FormField>
                    <FormField label="Thirdparty ID">
                      <span>
                        {_.truncate(review.thirdpartyId) + " "}
                        <CopyButton text={review.thirdpartyId} />
                      </span>
                    </FormField>
                    <FormField label="Import Time">{new Date(review.createdOn).toLocaleString()}</FormField>
                    <FormField name="reviewActions" label="Actions">
                      <ButtonArea size="sm">
                        <WWButton
                          size="sm"
                          onClick={() =>
                            customerApi.queueImport(
                              review.customer.id,
                              review.source,
                              review.siteInfoId,
                              null,
                              review.thirdpartyId
                            )
                          }
                        >
                          Queue Import
                        </WWButton>
                      </ButtonArea>
                    </FormField>
                  </CardBody>
                </Card>
              </Col>
            </AgencyAdminRow>
          </Col>
        </Row>

        <CustomerMessageComposer
          isOpen={this.state.contactCustomer}
          customerId={review.customer.id}
          reviewId={review.id}
          onClose={this.closeCustomerMessageComposer}
          contactTag={contactTag(review.tags)}
        />

        <FloatingEditor
          active={this.state.editing !== undefined}
          onClose={() => this.setState({ editing: undefined })}
          review={review}
          context={this.state.editing?.context}
          reply={this.state.editing?.target}
          onUpdateStatus={this.updateStatus}
          onSave={this.saveReply}
        />
        <HipaaModal isHipaa={review.customer.hipaaCompliant} onGoBack={this.close} />
      </div>
    );
  }
}

function contactTag(reviewTag) {
  switch (reviewTag) {
    case "Finance":
      return "Sales";
    case "Parts":
    case "Collision":
      return "Service";
    default:
      return reviewTag;
  }
}

const EditableReply = ({
  review,
  reply,
  editReply,
  newReply,
  unpublishReply,
  reactReply,
  unreactReply,
  saveReply,
  updateSentiment,
  updateStatus,
  deleteReply,
  reportReplyAs
}) => {
  const [history, setHistory] = useState();
  const subreply = useMemo(() => review.thirdpartyId !== reply.thirdpartyParentId, [reply, review.thirdpartyId]);
  const renderActionsBanner = useMemo(
    () => () => (
      <ReplyActionBanner
        reply={reply}
        onClick={() => saveReply(reply, true, true)}
        showPublishNow={!customerHasReviewResponseProduct(review.customer)}
      />
    ),
    [reply, saveReply, review.customer]
  );

  const loadHistory = useCallback(() => {
    api.loadReplyHistory(reply.id).then(res => setHistory(res.data));
  }, [reply]);

  return (
    <>
      <div className={`flex-grow-1 my-3 ${subreply ? "ms-5" : ""}`}>
        <Callout
          direction={subreply ? "right" : "left"}
          className={subreply ? "" : "bg-light"}
          renderActions={renderActionsBanner}
        >
          <>
            <ReviewReply reply={reply} legacy />
            <AuthorizationRequiredToRender roles={[permissions.REVIEW_REPLY]}>
              <div className="reply-sub-actions justify-content-between align-items-center d-flex">
                <ReactionSelector
                  source={review.source}
                  enabled={reply.canLike && reply.status === "PUBLISHED"}
                  onChange={reaction =>
                    reply.liked ? unreactReply(reply.id, reaction) : reactReply(reply.id, reaction)
                  }
                  text={reply.liked ? "Unlike" : "Like"}
                  reply={true}
                />
                <ReplyActions
                  reply={reply}
                  review={review}
                  onEdit={editReply}
                  onReply={newReply}
                  onShowHistory={loadHistory}
                  onUnpublish={unpublishReply}
                  onUpdateStatus={updateStatus}
                  onDelete={deleteReply}
                  onReportReplyAs={reportReplyAs}
                />
                {isAdOrPost(review) && (
                  <SentimentSelector
                    sentiment={reply.sentiment}
                    onChange={sentiment => updateSentiment(reply, sentiment)}
                  />
                )}
              </div>
            </AuthorizationRequiredToRender>
          </>
        </Callout>
      </div>
      <AuthorizationRequiredToRender roles={[permissions.REVIEW_REPLY]}>
        {history && <ReplyHistoryModal history={history} onClose={() => setHistory(undefined)} />}
      </AuthorizationRequiredToRender>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentContact: state.currentContact,
    currentAgency: state.currentAgency,
    author: currentUserFullName()
  };
}

const RepliesSorter = ({ value = "recentlyRepliedLast", onChange = noop }) => {
  const [state, setState] = useState(value);
  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <UncontrolledDropdown>
      <DropdownToggle>{`Sort: ${repliesSortStatus2Name(state)}`}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem active={state === "recentlyRepliedFirst"} onClick={() => onChange("recentlyRepliedFirst")}>
          {repliesSortStatus2Name("recentlyRepliedFirst")}
        </DropdownItem>
        <DropdownItem active={state === "recentlyRepliedLast"} onClick={() => onChange("recentlyRepliedLast")}>
          {repliesSortStatus2Name("recentlyRepliedLast")}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const repliesSortStatus2Name = state =>
  ({
    recentlyRepliedFirst: "Recently replied first",
    recentlyRepliedLast: "Recently replied last"
  })[state];

const withFirebaseDB =
  BaseComponent =>
  ({ ...props }) => {
    const db = useFirebaseDB();
    return <BaseComponent {...props} firebaseDB={db} />;
  };

const withReviewQueueSearchValue =
  BaseComponent =>
  ({ ...props }) => {
    const searchValue = useFilterSearch(["reviews,list"]);
    return <BaseComponent {...props} queueSearchValue={searchValue} />;
  };

export default withFirebaseDB(
  withReviewQueueSearchValue(
    withAuthorization(permissions.REVIEW_READ)(
      withLocalNotifications(withRouter(connect(mapStateToProps)(ReplyReview)))
    )
  )
);

export function loadReviewHistory(id) {
  return api.loadHistory(id).then(res => res.data.revisions);
}

const sorter = (picker, asc) => (a, b) => {
  const pickedA = picker(a);
  const pickedB = picker(b);
  return (pickedA.isBefore(pickedB) ? -1 : 1) * (asc ? 1 : -1);
};

/**
 * @todo need to implement single pass sorting algorithm once we have time
 */
export const sortRecentylRepliedFirst =
  (asc = true) =>
  review => {
    const timePicker = v => v.publishedOn || v.createdOn;
    const replyPicker = v => v.replies?.slice()?.sort(subrepliesSorter)?.[0] || v;

    // using `flow` here in order to make function pointrfree
    // ignoring `asc` param for sorting order because for subreplies we always need most recently updated first
    const subrepliesSorter = sorter(flow(timePicker, moment));

    // using `flow` again
    // `slice` used for array copy, because `sort` method mutates it but we want subreplies to flow naturally
    const sortReplies = sorter(flow(replyPicker, timePicker, moment), asc);
    return review
      ? {
          ...review,
          replies: (review.replies || []).sort(sortReplies)
        }
      : review;
  };

const TopicCategorySelector = ({ review, reviewTagSetID, updateTopics, updateTags }) => {
  const tagSetQuery = useTagSetById(reviewTagSetID);
  const categoryOptions = tagSetQuery.data?.tags?.filter(category => category !== null) || [];
  const topicOptions = tagSetQuery.data?.topics?.filter(topic => topic !== null) || [];
  return (
    <Row>
      <Col xs={12} lg={6} className="my-3 d-flex flex-column align-items-stretch">
        <h5 className="text-muted">Category</h5>
        <Typeahead
          id="tagsCategory"
          name="category"
          selected={review.tags ? [review.tags] : []}
          onChange={([value]) => updateTags({ target: { value, name: "tags" } })}
          options={categoryOptions}
          minLength={0}
          maxResults={10}
          filterBy={() => true}
          placeholder="Category"
        />
      </Col>
      <Col xs={12} lg={6} className="my-3 d-flex flex-column align-items-stretch">
        <h5 className="text-muted">Topics</h5>
        <Typeahead
          id="tagsTopics"
          name="topics"
          disabled={_.isEmpty(review.tags)}
          labelKey={v => v}
          selected={review.reviewEntities?.filter(entity => entity.type === "TOPIC")?.map(entity => entity.value) || []}
          onChange={value => updateTopics({ target: { value } })}
          options={topicOptions || []}
          minLength={0}
          multiple={true}
          maxResults={10}
          placeholder="Topics"
        />
      </Col>
    </Row>
  );
};
