import UserInfo from "./prefabs/Account/UserInfo/UserInfo";
import NotificationsSettings from "./prefabs/Account/Notifications/NotificationsSettings";
import { useReduxAt, useStateThroughRedux } from "../../hooks/stateHooks";
import { useCallback } from "react";
import { not } from "ramda";
import NavigableModalContainer from "../../components/Modals/NavigableModal/NavigableModalContainer/NavigableModalContainer";
import Pages from "../../components/Modals/NavigableModal/Pages/Pages";
import Navigation from "../../components/Modals/NavigableModal//Navigation/Navigation";
import Page from "../../components/Modals/NavigableModal/Page/Page";
import NavigationItem from "../../components/Modals/NavigableModal/NavigationItem/NavigationItem";
import PasswordEmailChange from "./prefabs/Account/PasswordEmailChange/PasswordEmailChange";

export default function Settings() {
  const [opened, setOpened] = useStateThroughRedux("settings/opened", false);
  const caption = useReduxAt("settings/caption", undefined);
  const toggler = useCallback(() => setOpened(not), []);
  const [navigationOpened, setNavigationOpened] = useStateThroughRedux("settings/navopened", false);
  const section = useReduxAt("settings/section", undefined);

  return (
    <NavigableModalContainer title="Settings" reduxRoot="settings">
      <Navigation>
        {/* <NavigationGroup caption="Account">
          <NavigationItem sub id="userinfo">
            User Info
          </NavigationItem>
          <NavigationItem sub id="notifications">
            Notifications
          </NavigationItem>
        </NavigationGroup> */}
        <NavigationItem id="userinfo">User Info</NavigationItem>
        <NavigationItem id="notifications">Notifications</NavigationItem>
        <NavigationItem id="passwordEmailChange">Email & Password</NavigationItem>
      </Navigation>
      <Pages>
        <Page id="userinfo">
          <UserInfo id="userinfo" />
        </Page>
        <Page id="notifications">
          <NotificationsSettings id="notifications" />
        </Page>
        <Page id="passwordEmailChange">
          <PasswordEmailChange id="passwordEmailChange" />
        </Page>
      </Pages>
    </NavigableModalContainer>
  );
}
