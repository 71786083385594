import _, { noop } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Col, InputGroup, InputGroupText, Row } from "reactstrap";
import { loadSummaryReport } from "api/reportApi";
import { Card, CardBody, CardHeader, CardTitle } from "components/Card";
import CustomerFilter from "components/Customer/CustomerFilter";
import DatePicker from "components/DateTime/DatePicker";
import Ring from "components/Misc/Ring";
import { errorCaughtNotifier, useLocalNotifications } from "components/Notifications/notification";
import { Table, TBody, TD, TH, THead, TR } from "components/Table";
import Widget04 from "components/Widgets/Widget04";
import SelectLocationPrompt from "components/Misc/SelectLocationPrompt";
import EngagePlus from "./EngagePlus";
import { useReportSharedTimerangeState } from "hooks/stateHooks";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../redux/actions/ajaxStatusActions";
import * as siteUtils from "util/siteUtils";
import { hasAgencyAdminSelectedALocation, isDdc } from "util/userUtils";
import { INFO_EMAIL } from "constants/emails";
import * as utils from "./util";

export default ({}) => {
  const dataLoader = useDataLoader();
  const [summary, setSummary] = useState(null);
  const [customer, setCustomer] = useCustomerState();
  const [timerange, setTimerange] = useReportSharedTimerangeState();
  const adminLocationPicked = useMemo(() => hasAgencyAdminSelectedALocation(customer), [customer]);

  useEffect(() => {
    adminLocationPicked &&
      dataLoader({
        customerId: customer,
        endDate: timerange[1],
        startDate: timerange[0]
      })
        .then(setSummary)
        .catch(noop);
  }, [customer, timerange]);

  const onTimerangeUpdate = useCallback((start, end) => setTimerange([start, end]), []);

  const onCustomerChange = useCallback(v => setCustomer(v.target.value[0]?.id || undefined), []);

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <Row className="d-flex align-items-stretch no-pagebreak-inside">
        <Col xs={12} md={6} className="d-flex justify-content-stretch justify-content-md-start no-pagebreak-inside">
          <Card className="justify-content-center align-items-stretch flex-grow-1 me-auto">
            <InputGroup>
              <InputGroupText className="fa fa-users d-none d-sm-block" />
              <CustomerFilter
                inputProps={{
                  style: {
                    minWidth: "255px",
                    backgroundColor: !adminLocationPicked && "pink"
                  }
                }}
                name="customer"
                placeholder={adminLocationPicked ? "All locations" : "Please select a location"}
                value={[customer]}
                onChange={onCustomerChange}
              />
            </InputGroup>
          </Card>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-stretch justify-content-md-end no-pagebreak-inside">
          <Card className="justify-content-center align-items-center flex-grow-1 ms-auto">
            <DatePicker
              start={timerange[0]}
              end={timerange[1]}
              onChange={onTimerangeUpdate}
              quickSelect={true}
              numberOfMonths={2}
            />
          </Card>
        </Col>
      </Row>
      {adminLocationPicked ? (
        <>
          {!!summary && (
            <>
              {!isDdc() && (
                <Row className="no-pagebreak-inside">
                  <Col>
                    <Card>
                      <CardHeader className="engage-card__header">
                        <i className="fa fa-2x fa-facebook-official me-4" />
                        <CardTitle>Facebook</CardTitle>
                      </CardHeader>
                      {summary.posts && (
                        <CardBody>
                          <Row className="engage-card__row">
                            <Col>
                              <div className="d-flex flex-row justify-content-center">
                                <div className="text-center">
                                  <Ring
                                    color={utils.rating_colors(_.get(summary, "posts.sentiment"))}
                                    text={_.get(summary, "posts.sentiment")}
                                  />
                                  <br />
                                  <label>Post Sentiment</label>
                                </div>
                              </div>
                              <div className="d-flex flex-row">
                                <div
                                  className="text-center px-2 justify-content-center"
                                  style={{ flex: 1, marginRight: "auto" }}
                                >
                                  <h2>{_.get(summary, "posts.count")}</h2>
                                  <label>Total Posts</label>
                                </div>
                                <div xs="auto" className="text-center justify-content-center px-2" style={{ flex: 1 }}>
                                  <h2>{_.get(summary, "posts.shares", 0)}</h2>
                                  <label>Total Shares</label>
                                </div>
                                <div
                                  xs="auto"
                                  className="text-center px-2 justify-content-center"
                                  style={{ flex: 1, marginLeft: "auto" }}
                                >
                                  <h2>{_.get(summary, "posts.interactions")}</h2>
                                  <label>Total Interactions</label>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex flex-row justify-content-center">
                                <div className="text-center">
                                  <Ring
                                    color={utils.rating_colors(_.get(summary, "ads.sentiment"))}
                                    text={_.get(summary, "ads.sentiment")}
                                  />
                                  <br />
                                  <label>Ad Sentiment</label>
                                </div>
                              </div>
                              <div className="d-flex flex-row">
                                <div
                                  className="text-center justify-content-center px-2"
                                  style={{ flex: 1, marginRight: "auto" }}
                                >
                                  <h2>{_.get(summary, "ads.count")}</h2>
                                  <label>Total Ads</label>
                                </div>
                                <div xs="auto" className="text-center justify-content-center px-2" style={{ flex: 1 }}>
                                  <h2>{_.get(summary, "ads.interactions")}</h2>
                                  <label>Total Interactions</label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="engage-card__data">
                            <Col>
                              <CardHeader>
                                <CardTitle>Most Interacted Posts</CardTitle>
                              </CardHeader>
                              <Table responsive>
                                <THead>
                                  <TR>
                                    <TH>Author</TH>
                                    <TH>Content</TH>
                                    <TH>Reactions</TH>
                                    <TH>Comments</TH>
                                    <TH>Shares</TH>
                                  </TR>
                                </THead>
                                <TBody>
                                  {_.map(_.get(summary, "posts.topPosts"), p => (
                                    <TR>
                                      <TD>{p.author}</TD>
                                      <TD>
                                        <a
                                          href={siteUtils.facebookReviewLink(p)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {_.truncate(_.get(p, "content", "(Click to view)"), { length: 100 })}
                                        </a>
                                      </TD>
                                      <TD>
                                        <strong>{p.reactions}</strong>
                                      </TD>
                                      <TD>
                                        <strong>{p.comments}</strong>
                                      </TD>
                                      <TD>
                                        <strong>{p.shares}</strong>
                                      </TD>
                                    </TR>
                                  ))}
                                </TBody>
                              </Table>
                            </Col>
                            <Col>
                              <CardHeader>
                                <CardTitle>Most Interacted Ads</CardTitle>
                              </CardHeader>
                              <Table responsive>
                                <THead>
                                  <TR>
                                    <TH>Author</TH>
                                    <TH>Content</TH>
                                    <TH>Reactions</TH>
                                    <TH>Comments</TH>
                                  </TR>
                                </THead>
                                <TBody>
                                  {_.map(_.get(summary, "ads.topPosts"), p => (
                                    <TR>
                                      <TD>{p.author}</TD>
                                      <TD>
                                        <a
                                          href={siteUtils.facebookReviewLink(p)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {_.truncate(p.content, { length: 100 })}
                                        </a>
                                      </TD>
                                      <TD>
                                        <strong>{p.reactions}</strong>
                                      </TD>
                                      <TD>
                                        <strong>{p.comments}</strong>
                                      </TD>
                                    </TR>
                                  ))}
                                </TBody>
                              </Table>
                            </Col>
                          </Row>
                        </CardBody>
                      )}
                      {!summary.posts && (
                        <CardBody>
                          <EngagePlus />
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </Row>
              )}
              {!isDdc() && (
                <Row className="no-pagebreak-inside">
                  <Col>
                    <Card>
                      <CardHeader className="engage-card__header">
                        <i className="fa fa-google fa-2x me-4" color="blue" />
                        <CardTitle>Google Questions</CardTitle>
                      </CardHeader>
                      {summary.questions && (
                        <CardBody>
                          <Row>
                            <Col xs={12} sm={6} md={{ size: 4, offset: 2 }} lg={{ size: 3, offset: 3 }}>
                              <Widget04
                                icon="fa fa-star orange"
                                color="orange"
                                header={_.get(summary, "questions.count")}
                                value={100}
                              >
                                Total Questions
                              </Widget04>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3}>
                              <Widget04
                                icon="fa fa-thumbs-up orange"
                                color="orange"
                                header={_.get(summary, "questions.answers")}
                                value={100}
                              >
                                Total Answers
                              </Widget04>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardHeader>
                                <CardTitle>Recent Questions</CardTitle>
                              </CardHeader>
                              <Table responsive>
                                <THead>
                                  <TR>
                                    <TH>Author</TH>
                                    <TH>Question</TH>
                                    <TH>Answers</TH>
                                  </TR>
                                </THead>
                                <TBody>
                                  {_.map(_.get(summary, "questions.recent"), q => (
                                    <TR>
                                      <TD>{q.author}</TD>
                                      <TD>
                                        <a
                                          href={siteUtils.googlePageLink(summary.customer.companyName) + "#lpqa=d,2"}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {_.truncate(q.content, { length: 100 })}
                                        </a>
                                      </TD>
                                      <TD>
                                        <strong>{q.comments}</strong>
                                      </TD>
                                    </TR>
                                  ))}
                                </TBody>
                              </Table>
                            </Col>
                          </Row>
                        </CardBody>
                      )}
                      {!summary.questions && (
                        <CardBody>
                          <EngagePlus />
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </Row>
              )}
              {!isDdc() && (
                <Row className="no-pagebreak-inside">
                  <Col>
                    <Card>
                      <CardHeader className="engage-card__header">
                        <i className="fa fa-2x fa-instagram me-4" />
                        <CardTitle>Instagram</CardTitle>
                      </CardHeader>
                      {summary.instagram && (
                        <CardBody>
                          <Row>
                            <Col xs={12} sm={6} md={{ size: 4, offset: 2 }} lg={{ size: 3, offset: 3 }}>
                              <Widget04
                                icon="fa fa-sticky-note orange"
                                color="orange"
                                header={_.get(summary, "instagram.count")}
                                value={100}
                              >
                                Total Posts
                              </Widget04>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3}>
                              <Widget04
                                icon="fa fa-thumbs-up orange"
                                color="orange"
                                header={_.get(summary, "instagram.interactions", 0)}
                                value={100}
                              >
                                Total Interactions
                              </Widget04>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardHeader>
                                <CardTitle>Top 5 most interacted posts</CardTitle>
                              </CardHeader>
                              <Table responsive>
                                <THead>
                                  <TR>
                                    <TH>Post</TH>
                                    <TH>Interactions</TH>
                                  </TR>
                                </THead>
                                <TBody>
                                  {_.map(_.slice(_.get(summary, "instagram.topPosts", []), 0, 5), q => (
                                    <TR>
                                      <TD>
                                        <a
                                          href={q.thirdpartyUrl || siteUtils.instagramPostLink(q.thirdpartyId)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {_.truncate(q.content, { length: 100 })}
                                        </a>
                                      </TD>
                                      <TD>
                                        <strong>{(q.comments || 0) + (q.reactions || 0)}</strong>
                                      </TD>
                                    </TR>
                                  ))}
                                </TBody>
                              </Table>
                            </Col>
                          </Row>
                        </CardBody>
                      )}
                      {!summary.questions && (
                        <CardBody>
                          <p className="text-center lead">
                            Manage customer interactions on Facebook Posts, Ads and Google Questions.
                            <br />
                            Contact your Widewail Representative today to sign up for Engage Plus!
                            <br />
                            (844) 259-9238
                            <br />
                            <a href={`mailto:${INFO_EMAIL}`}>{INFO_EMAIL}</a>
                          </p>
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      ) : (
        <SelectLocationPrompt />
      )}
    </div>
  );
};

/**
 * @type {() => (v: { customerId: string; startDate: string; endDate: string; contactId?: string; include?: boolean; engagePlus?: boolean }) => Promise}
 */
const useDataLoader = () => {
  const dispatch = useDispatch();
  const notify = useLocalNotifications();
  return useCallback(({ customerId, startDate, endDate, include = true, contactId = undefined, engagePlus = true }) => {
    dispatch(beginAjaxCall());
    return loadSummaryReport({ customerId, startDate, endDate, include, contactId, engagePlus })
      .then(({ data }) => {
        dispatch(endAjaxCall());
        return data.parent;
      })
      .catch(e => {
        // logger.error(e);
        dispatch(ajaxCallError());
        errorCaughtNotifier(notify)(e);
      });
  });
};

/**
 * @type {() => [string, (v: string) => void]}
 */
const useCustomerState = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const value = useMemo(() => match.params.customer, [match]);
  const setter = useCallback(
    v => {
      const pathname = !!v
        ? !!match.params.customer
          ? match.path.replace(":customer", v)
          : `${match.path}/${v}`
        : !!match.params.customer
        ? match.path.replace(":customer", "")
        : match.path;
      history.push({
        ...history.location,
        pathname
      });
    },
    [match]
  );

  return [value, setter];
};
