import { memo, useState, useCallback } from "react";
import { useHistory } from "react-router";
import ResponderNotesEditor from "components/ResponderNotes/ResponderNotesEdit/ResponderNotesEditor";
import { useResponderNotesUpdate } from "hooks/data/responderNotesHooks";
import SaveBar from "components/Form/SaveBar";
import { Card, CardBody, CardHeader, CardTitle } from "../Card";

export const ResponderNotesPage = ({ accountId, customerId, tags, tagSetId, enabled = true, basePath }) => {
  const [notes, setNotes] = useState([]);
  const [touched, setTouched] = useState(false);
  const updateNotes = useResponderNotesUpdate({ params: { accountId, customerId } });
  const history = useHistory();
  const onSave = useCallback(
    async shouldClose => {
      await updateNotes(notes.filter(({ note }) => typeof note === "string"));
      setTouched(false);
      if (shouldClose && basePath) {
        history.push(basePath);
      }
    },
    [notes, history, basePath]
  );
  const onCancel = useCallback(() => basePath && history.push(basePath), [history]);
  const onChange = useCallback(
    notes => {
      if (!touched) {
        setTouched(true);
      }
      setNotes(notes);
    },
    [touched]
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Responder Instructions</CardTitle>
      </CardHeader>
      <CardBody>
        <ResponderNotesEditor
          onChange={onChange}
          customerId={customerId}
          accountId={accountId}
          tags={tags}
          tagSetId={tagSetId}
          enabled={enabled}
        />
        {touched && (
          <SaveBar
            prompt="Save Responder Notes?"
            confirmationPrompt="Save Responder Notes"
            onSave={onSave}
            onCancel={onCancel}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default memo(ResponderNotesPage);
