import { useState, useCallback, useMemo } from "react";
import { omitBy, isUndefined } from "lodash";
import GroupReport from "./GroupReport";
import SelectLocationPrompt from "components/Misc/SelectLocationPrompt";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import withAuthorization from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import useFilterQuery from "hooks/data/useFilterQuery";
import { useFilterState } from "hooks/filteringHooks";
import { LOCATION_TYPE } from "data/customers";
import { hasWidewailAdminSelectedALocation, isCurrentUserInGroup } from "util/userUtils";
import { endOfTheDay, startOfTheDay, datetime2iso, now } from "util/dateUtils";

const getFilters = (filterValues = {}) => [
  {
    name: "brand",
    label: "Brand",
    type: LOCATION_TYPE.brand,
    disabled: !!filterValues.contact,
    icon: "tag",
    primaryBar: {
      position: "LEFT"
    },
    required: !!filterValues.contact || !isCurrentUserInGroup("WIDEWAIL_ADMIN") ? false : true,
    chip: {
      position: "NONE"
    }
  },
  {
    name: "dateRange",
    label: "Date range",
    type: "dates",
    queryParams: ["startDate", "endDate"],
    primaryBar: {
      position: "LEFT",
      className: "d-none d-sm-flex"
    },
    secondaryBar: {
      className: "d-flex d-sm-none"
    },
    defaultValue: {
      startDate: datetime2iso(startOfTheDay(now().startOf("month"))),
      endDate: datetime2iso(endOfTheDay(now()))
    },
    required: true,
    chip: {
      position: "NONE"
    }
  },
  {
    name: "contact",
    label: "Contact",
    disabled: !!filterValues.brand,
    type: LOCATION_TYPE.contact,
    nonFilter: true,
    primaryBar: {
      position: "NONE"
    },
    offCanvas: {
      position: "NONE"
    }
  }
];

const getPrepareFilters = contactId => filters => {
  return omitBy(
    {
      startDate: filters?.dateRange?.startDate,
      endDate: filters?.dateRange?.endDate,
      brand: filters?.brand?.brand,
      contactId
    },
    isUndefined
  );
};

const getWidewailAdminLocationIsPicked = apiValues =>
  hasWidewailAdminSelectedALocation(null, apiValues?.brand, apiValues?.contact?.id);

export const CustomerSummaryReport = () => {
  const [engagementTabIsOpen, setEngagementTabIsOpen] = useState(false);
  const [filterValues] = useFilterState();

  const filters = useMemo(() => getFilters(filterValues), [filterValues]);

  const basicReportParams = {
    filters,
    url: `/report`,
    prepareFilters: getPrepareFilters(filterValues?.contact?.id),
    normalizeResponse: data => data
  };

  const reportQueryOptions = { staleTime: Infinity };

  const groupReport = useFilterQuery(
    {
      ...basicReportParams,
      additionalParams: {
        engagePlus: false
      },
      getEnabled: apiValues => {
        return !!apiValues?.dateRange && getWidewailAdminLocationIsPicked(apiValues) && !engagementTabIsOpen;
      }
    },
    reportQueryOptions
  );

  const engageReport = useFilterQuery(
    {
      ...basicReportParams,
      additionalParams: {
        engagePlus: true
      },
      getEnabled: apiValues => {
        return !!apiValues?.dateRange && getWidewailAdminLocationIsPicked(apiValues) && engagementTabIsOpen;
      }
    },
    reportQueryOptions
  );

  const reportFilterValues = useMemo(
    () => ({
      dateRange: filterValues?.dateRange,
      brand: filterValues?.brand,
      contact: filterValues?.contact
    }),
    [filterValues]
  );

  const onOpenTab = useCallback(isEngagedTab => setEngagementTabIsOpen(isEngagedTab), [setEngagementTabIsOpen]);

  return (
    <div className="pt-4 ps-4 pe-4">
      <FacetedSearch filters={filters} showFiltersButton={false} />
      {getWidewailAdminLocationIsPicked(filterValues) ? (
        <GroupReport
          loading={groupReport.isLoading}
          summary={groupReport.data}
          engageSummary={engageReport.data}
          onOpenTab={onOpenTab}
          reportFilterValues={reportFilterValues}
        />
      ) : (
        <SelectLocationPrompt type="brand" />
      )}
    </div>
  );
};

export default withAuthorization(permissions.REPORT_READ)(CustomerSummaryReport);
