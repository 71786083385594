import React, { useEffect, useState } from "react";
import { bySource } from "../../data/sites";
import "./ReviewSiteButton.scss";
import { DisabledSiteWarningIconWithTooltip } from "./Configuration/InvitePageSiteOrder";

export const PrimarySiteButton = ({ site, disabled }) => {
  const [source, setSource] = useState(bySource(site));
  useEffect(() => {
    setSource(bySource(site));
  }, [site]);

  return (
    <div className="review-site-button primary d-flex justify-content-center align-items-stretch overflow-hidden w-100">
      {source.invitable ? (
        <>
          <div
            style={{
              backgroundImage: `url(/img/logos/primary/${site}.png)`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}
            className="logo overflow-hidden flex-grow-0 flex-shrink-0 rounded-0"
          />
          <div className={`d-flex justify-content-center align-items-center text-white label px-4 w-100 site-${site}`}>
            Review us on {source.label || site}
            <DisabledSiteWarningIconWithTooltip site={site} disabled={disabled} />
          </div>
        </>
      ) : (
        <>
          <div className="fa-3x logo fa fa-globe overflow-hidden d-flex align-items-center px-2 text-muted flex-grow-0 flex-shrink-0" />
          <div className="w-100 d-flex justify-content-center align-items-center text-white label px-4">
            Review us on {source.label || site}
            <DisabledSiteWarningIconWithTooltip site={site} disabled={disabled} />
          </div>
        </>
      )}
    </div>
  );
};

export const SecondarySiteButton = ({ site, disabled }) => {
  const [source, setSource] = useState(bySource(site));
  useEffect(() => {
    setSource(bySource(site));
  }, [site]);
  return (
    <div className="review-site-button secondary border-muted border d-flex justify-content-center align-items-center w-100">
      {source.invitable ? (
        <img className="logo" src={`/img/logos/secondary/${site}.png`} alt={source.label} />
      ) : (
        <p className="text-muted m-0 p-0">{source.label || site}</p>
      )}
      <DisabledSiteWarningIconWithTooltip site={site} disabled={disabled} />
    </div>
  );
};
