import WWChip from "components/WWChips/WWChip/WWChip";
import { valueOf } from "util/functionUtils";
import c from "classnames";

import { useFilterValueState } from "hooks/filteringHooks";
import { useInviteCampaigns } from "hooks/data/campaignHooks";

import WWTypeahead from "components/Form/Typeahead/Typeahead";

import { useCustomersFilterById, useCustomersFilter } from "hooks/customerHooks";
import { InputGroup, InputGroupText } from "reactstrap";
import { Highlighter } from "react-bootstrap-typeahead";
import { get } from "lodash";
import { LOCATION_TYPE, LOCATION_TYPE_NAME } from "data/customers";

import styles from "../TypeAheadFilter/TypeAheadFilter.module.scss";
import { useContacts } from "hooks/data/useContact";
import { useAccountSearch } from "hooks/data/accountsHooks";

const { typeAheadFilterClass } = styles;

export const getOptionType = (o = {}) => {
    let { type } = o;

    return ((Array.isArray(type) ? type[0] : type) || LOCATION_TYPE.customer).toLowerCase();
  },
  getLocationLabel = label => location => {
    if (label) {
      return label;
    } else {
      const locationTypeName = LOCATION_TYPE_NAME[getOptionType(location)];

      if (Array.isArray(locationTypeName)) {
        return locationTypeName.find(name => !!get(location, name));
      } else if (typeof locationTypeName === "function") {
        return locationTypeName(location);
      } else {
        return get(location, locationTypeName, "");
      }
    }
  },
  getCampaignLabel = () => campaign => campaign?.name,
  getAccountLabel = () => account => account?.name;

const useUpdateCustomerWithNameById = value => {
  const isNotBrandValue = ![LOCATION_TYPE.brand, LOCATION_TYPE.location_label, LOCATION_TYPE.account].includes(
    value?.type
  );
  const valueLabel = getLocationLabel()(value);
  const enabled = isNotBrandValue && !valueLabel && !!value?.id;
  const { data = [] } = useCustomersFilterById({
    id: value?.id,
    options: {
      enabled
    }
  });
  const dataLabel = getLocationLabel()(data[0]);

  return valueLabel || dataLabel || undefined;
};

// Custom menu item renderer to render the types of customers that come back
// search: brands, locations and contacts
export const locationRenderer = (option, props) => {
  let type = getOptionType(option);

  return (
    <div className="d-flex flex-row align-items-start justify-content-stretch">
      <i
        className={c("me-1 mt-1 flex-grow-0 flex-shrink-0 fa", {
          "fa-tag": type === LOCATION_TYPE.brand,
          "fa-user": type === LOCATION_TYPE.contact,
          "fa-building": type === LOCATION_TYPE.customer,
          "fa-map-pin": type === LOCATION_TYPE.location_label,
          "fa-sitemap": type === LOCATION_TYPE.account
        })}
      />
      <span className="flex-grow-1 overflow-hidden text-wrap">
        <Highlighter search={props.text}>{getLocationLabel()(option)}</Highlighter>
        {option.brands && (
          <>
            <br />
            <small className="text-muted">
              <Highlighter search={props.text}>{option.brands}</Highlighter>
            </small>
          </>
        )}
      </span>
    </div>
  );
};

// this is a generator for typeahead filter functionality to encapsulate some
// boilerplate setup code that is the same for multiple types of the typeahead
export const makeUseSearch =
  ({
    name,
    type,
    useSearch, // this will be the specific query for the instance of the typeahead
    labelKey,
    useLabelFromId = () => {},
    renderer,
    additionalParams: { defaultSearchParams } = {},
    additionalArgs = {}
  }) =>
  query => {
    const [value, onChange] = useFilterValueState(name, type),
      { data, isFetching } = useSearch({
        name: query,
        type,
        defaultSearchParams,
        ...additionalArgs
      });
    const label = useLabelFromId(value, onChange);
    let selected = [value].filter(x => x);
    selected = selected.length ? selected : [];

    let ret = {
      name,
      type,
      selected,
      labelKey: labelKey(label),
      renderer,
      onChange,
      options: data?.[0] || [],
      isLoading: isFetching
    };

    return ret;
  };
// END Custom hooks

export const TypeaheadChip = ({ iconClass, defaultValue, useSearch, ...props }) => {
    const { selected, onChange, labelKey } = useSearch();

    return selected.length === 0 ? null : (
      <WWChip
        iconClass={iconClass}
        className="overflow-visible"
        onClick={() => onChange(valueOf(defaultValue))}
        {...props}
      >
        {selected.map(labelKey).join(", ")}
      </WWChip>
    );
  },
  TypeaheadFilterBar = props => (
    <InputGroup className={c("flex-nowrap", typeAheadFilterClass, props.className)}>
      <InputGroupText className={`fa fa-${props.icon} d-none d-sm-block`} />
      <WWTypeahead {...props} />
    </InputGroup>
  ),
  TypeaheadFilterSidebar = props => <WWTypeahead {...props} />;

const TypeheadFactory = opts => {
  const useSearch = makeUseSearch(opts);

  const barComponent = ({ ...props }, className) => (
      <TypeaheadFilterBar {...{ ...props, ...opts, className, useSearch }} />
    ),
    filterComponent = ({ ...props }, className) => (
      <TypeaheadFilterSidebar {...{ ...props, ...opts, className, useSearch }} />
    ),
    chip = ({ ...props }, className) => <TypeaheadChip {...{ ...props, ...opts, className, useSearch }} />;

  return {
    barComponent,
    chip,
    filterComponent
  };
};

// Generate a typeahead related to Location data or data related to a location,
// ie: Locations|Customers, Contacts, and Brands
//
// `variant` defines which of those things this typeahead will define
export const LocationTypeaheadFactory = filterConfig => {
  const isBrand = filterConfig.type === LOCATION_TYPE_NAME.brand;

  return TypeheadFactory({
    ...filterConfig,
    icon: isBrand ? "tag" : "building",
    placeholder: filterConfig.placeholder || `Search ${isBrand ? "Brands" : "Locations"}...`,
    useSearch: useCustomersFilter,
    renderer: locationRenderer,
    labelKey: getLocationLabel,
    useLabelFromId: useUpdateCustomerWithNameById
  });
};

export const CampaignTypeaheadFactory = filterConfig =>
  TypeheadFactory({
    ...filterConfig,
    icon: "bullhorn",
    placeholder: "Search Campaigns...",
    useSearch: useInviteCampaigns,
    labelKey: getCampaignLabel
  });

export const ContactTypeaheadFactory = filterConfig =>
  TypeheadFactory({
    ...filterConfig,
    icon: "user",
    placeholder: "Search Contacts...",
    useSearch: useContacts,
    labelKey: getCampaignLabel
  });

export const AccountTypeaheadFactory = filterConfig =>
  TypeheadFactory({
    ...filterConfig,
    icon: "sitemap",
    placeholder: "Search Accounts...",
    useSearch: useAccountSearch,
    labelKey: getAccountLabel
  });

export default TypeheadFactory;

export const getAccountFilterConfig = config => ({
  name: "account",
  label: "Account",
  agencyAdmin: true,
  primaryBar: {
    position: "NONE"
  },
  ...config,
  type: LOCATION_TYPE.account
});
