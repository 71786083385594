import React, { useCallback } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledTooltip
} from "reactstrap";
import classnames from "classnames";
import { isFunction, isNull } from "lodash";
import SentimentSelector from "components/Form/SentimentSelector";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import WWButton from "components/Buttons/WWButton";
import { autoclose } from "components/Modals";
import { replyStatuses, RESPONDER_FLAG_VALUES } from "data/options";
import { getReplyReportAsOptionLabel, REPLY_REPORT_AS_OPTIONS } from "data/reportReasons";
import { isAdOrPost } from "util/reviewUtils";

import styles from "./ReplyActions.module.scss";

const { actionsContainerClass, actionButtonsClass } = styles;

const ReplyActions = ({
  reply,
  review,
  onShowHistory,
  onReply,
  onEdit,
  onUnpublish,
  onUpdateStatus,
  onDelete,
  onSentimentChange,
  onReportReplyAs,
  showStatusDropdown = true
}) => {
  const sentimentChangeCallback = useCallback(sentiment => onSentimentChange(reply, sentiment), [reply]);

  const confirm = useConfirmationDialog();
  return (
    <div className={classnames("d-flex justify-content-between align-items-center w-100", actionsContainerClass)}>
      <div className={actionButtonsClass}>
        <span>
          <WWButton contentClass="text-capitalize" color="link" onClick={() => onShowHistory(reply)}>
            History
          </WWButton>
        </span>
        {/* these spans are needed for the tooltip as disabled bootstrap buttons wont show tooltips */}
        <span id={`reply${reply.id}`}>
          <WWButton
            contentClass="text-capitalize"
            disabled={!reply.canReply || review.responderFlag === RESPONDER_FLAG_VALUES.doNotRespond}
            color="link"
            onClick={() => onReply(reply)}
          >
            Reply
          </WWButton>
          {!reply.canReply && (
            <UncontrolledTooltip target={`reply${reply.id}`} placement="top">
              {reply.cantReplyReason}
            </UncontrolledTooltip>
          )}
        </span>
        <span id={`edit${reply.id}`}>
          <WWButton
            contentClass="text-capitalize"
            disabled={!reply.canEdit || review.responderFlag === RESPONDER_FLAG_VALUES.doNotRespond}
            color="link"
            onClick={onEdit}
          >
            Edit
          </WWButton>
          {!reply.canEdit && (
            <UncontrolledTooltip target={`edit${reply.id}`} placement="top">
              {reply.cantEditReason}
            </UncontrolledTooltip>
          )}
        </span>
        <span id={`delete${reply.id}`}>
          <WWButton
            contentClass="text-capitalize"
            disabled={review.responderFlag === RESPONDER_FLAG_VALUES.doNotRespond}
            color="link"
            label="Delete Reply"
            onClick={() =>
              autoclose(
                confirm({
                  title: "Are you sure you want to delete this reply?",
                  body: <p>Have you made sure that this reply has been unpublished?</p>
                })
              ).then(v => v && onDelete(reply))
            }
          >
            Delete
          </WWButton>
          <UncontrolledTooltip target={`delete${reply.id}`} placement="top">
            Delete from Widewail
          </UncontrolledTooltip>
        </span>
        {reply.status === "PUBLISHED" && (
          <span id={`publish${reply.id}`}>
            <WWButton
              contentClass="text-capitalize"
              disabled={!reply.canUnpublish || review.responderFlag === RESPONDER_FLAG_VALUES.doNotRespond}
              color="link"
              label="Unpublish Reply"
              onClick={() =>
                autoclose(confirm({ title: "Are you sure you want to unpublish this reply?" })).then(
                  v => v && onUnpublish(reply)
                )
              }
            >
              Unpublish
            </WWButton>
            <UncontrolledTooltip target={`publish${reply.id}`} placement="top">
              {reply.cantUnpublishReason || "Remove from review site"}
            </UncontrolledTooltip>
          </span>
        )}

        {showStatusDropdown && (
          <UncontrolledButtonDropdown>
            <DropdownToggle
              disabled={review.responderFlag === RESPONDER_FLAG_VALUES.doNotRespond}
              id={`status_dropdown${reply.id}`}
              caret
              color="link"
              className="ps-0 text-capitalize"
            >
              Status
            </DropdownToggle>
            <UncontrolledTooltip target={`status_dropdown${reply.id}`}>
              This status change is used to correct an incorrect status of a reply
            </UncontrolledTooltip>
            <DropdownMenu>
              {replyStatuses
                .filter(value => value !== reply.status)
                .map(status => (
                  <DropdownItem
                    key={status}
                    className="ps-5"
                    onClick={() =>
                      autoclose(
                        confirm({
                          title: `Are you sure you want to correct this reply to be in status: ${status}?`
                        })
                      ).then(v => v && onUpdateStatus(reply, status))
                    }
                  >
                    {status}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )}
        {isFunction(onReportReplyAs) && (
          <>
            {isNull(reply.reportedAs) ? (
              <UncontrolledButtonDropdown>
                <span className="d-flex ps-0" id={`report${reply.id}`}>
                  <DropdownToggle
                    caret
                    color="link"
                    className="text-capitalize"
                    disabled={!reply.canReport}
                    title="report as"
                  >
                    Report as
                  </DropdownToggle>
                </span>
                {!reply.canReport && !!reply.cantReportReason && (
                  <UncontrolledTooltip target={`report${reply.id}`} placement="top">
                    {reply.cantReportReason}
                  </UncontrolledTooltip>
                )}
                <DropdownMenu>
                  {REPLY_REPORT_AS_OPTIONS.filter(({ value }) => value !== reply.reportedAs).map(option => (
                    <DropdownItem
                      key={option.value}
                      className="ps-5"
                      onClick={() =>
                        autoclose(
                          confirm({
                            title: `Are you sure you want to report this reply as ${option.label.toLowerCase()}?`
                          })
                        ).then(v => v && onReportReplyAs(reply, option.value))
                      }
                    >
                      {option.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            ) : (
              <WWButton
                className="ps-0"
                contentClass="text-capitalize"
                color="link"
                label={`Clear ${getReplyReportAsOptionLabel(reply.reportedAs)} status`}
                onClick={() =>
                  autoclose(
                    confirm({
                      title: `Are you sure you want to clear the ${getReplyReportAsOptionLabel(
                        reply.reportedAs
                      ).toLowerCase()} status for this reply?`
                    })
                  ).then(v => v && onReportReplyAs(reply, null))
                }
              >
                {`Clear ${getReplyReportAsOptionLabel(reply.reportedAs)} status`}
              </WWButton>
            )}
          </>
        )}
      </div>
      {isAdOrPost(review) && !!onSentimentChange && (
        <div>
          <SentimentSelector onChange={sentimentChangeCallback} sentiment={reply.sentiment} />
        </div>
      )}
    </div>
  );
};

export default ReplyActions;
