import { memo } from "react";
import classNames from "classnames";
import memoize from "memoizee";
import WidgetCard from "../CommonComponents/WidgetCard";
import DonutChart from "components/Charts/DonutChart/DonutChart";
import Skeleton from "components/Skeleton";

import styles from "./DonutChartWidget.module.scss";
import { roundToOne } from "util/roundToOne";

const {
  donutChartContentClass,
  chartPointerClass,
  rightPositionClass,
  leftPositionClass,
  widgetContentClass,
  totalClass,
  mutedTextClass,
  underlinedCtrClass,
  ctrClass,
  ctrClassUnavailable,
  ctrLabelClass,
  skeletonPointerClass
} = styles;
const POSITION_FACTOR = 110;
const TOP_OFFSET = 65;
const POINTER_LENGTH = 150;
const POINTER_POSITION_OFFSET = 10;
const POINTER_POSITION_OFFSET_LOWER = 8; // for better resolution in the lower values range
const MIDDLE_RIGHT_CTR = 25;

/*
 * this function calculates position of pointer with CTR value around the donut chart and returns inline style for this position
 * @param {number | null} ctr value of CTR in percent for display in the donut chart
 * @returns {{top: string, left?: string, right?: string}} inline style value for donut chart pointer position
 * */
const getChartPointerPosition = memoize(percentCTR => {
  const moreThanFifty = percentCTR > 50;
  let ctrForCalc;

  ctrForCalc = moreThanFifty
    ? Math.min(Math.max(percentCTR, 50 + POINTER_POSITION_OFFSET), 100 - POINTER_POSITION_OFFSET)
    : Math.min(Math.max(percentCTR, POINTER_POSITION_OFFSET_LOWER), 50 - POINTER_POSITION_OFFSET);

  const top = TOP_OFFSET - Math.trunc(Math.cos((ctrForCalc * Math.PI) / 50) * POSITION_FACTOR);
  const rightOrLeft =
    Math.trunc(Math.sin((ctrForCalc * Math.PI) / 50) * POSITION_FACTOR) + (percentCTR > 50 ? -1 : 1) * POINTER_LENGTH;

  return moreThanFifty
    ? { top: `${top}px`, left: `calc(50% + ${rightOrLeft}px)` }
    : { top: `${top}px`, right: `calc(50% - ${rightOrLeft}px` };
});

export const DonutChartWidget = ({
  heading,
  headingInfo,
  isLoading,
  total = 0,
  value = 0,
  ctr = 0,
  ctrName,
  children,
  renderValueDescription
}) => {
  const moreThanFifty = !!total && ctr > 0.5;
  const pointerPositionClass = classNames(
    chartPointerClass,
    { [leftPositionClass]: moreThanFifty },
    { [rightPositionClass]: !moreThanFifty }
  );
  const percentCTR = ctr * 100;
  const pointerPositionStyle = getChartPointerPosition(total === 0 ? MIDDLE_RIGHT_CTR : percentCTR);

  return (
    <WidgetCard heading={heading} headingInfo={headingInfo}>
      <div className={widgetContentClass}>
        {isLoading ? (
          <Skeleton circle width={210} height={210} />
        ) : (
          <DonutChart percentValue={percentCTR}>
            <div className={donutChartContentClass}>
              <div className={totalClass}>{total}</div>
              {!!renderValueDescription && renderValueDescription({ total, value })}
            </div>
          </DonutChart>
        )}
        {isLoading ? (
          <div>
            <Skeleton className={skeletonPointerClass} />
          </div>
        ) : (
          <div className={pointerPositionClass} style={pointerPositionStyle}>
            <div className={underlinedCtrClass}>
              {!!total ? (
                <>
                  <span className={ctrClass}>{!!value ? roundToOne(percentCTR) || "< 1" : 0}%</span>{" "}
                  <span className={mutedTextClass}>({value})</span>
                </>
              ) : (
                <span className={ctrClassUnavailable}>N/A</span>
              )}
            </div>
            <div className={ctrLabelClass}>{ctrName}</div>
          </div>
        )}
      </div>
      {children}
    </WidgetCard>
  );
};

export default memo(DonutChartWidget);
