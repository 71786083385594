import React, { useCallback, useState } from "react";
import Input from "../../components/Form/Input";
import Switch from "../../components/Form/Switch";
import { Collapse, FormFeedback, FormGroup } from "reactstrap";
import { noop } from "lodash";
import { useTagSetByCustomerId } from "hooks/data/tagSetHooks";

export default function InviteDeliveryRuleConfiguration({ customerId, rules = [], onChange }) {
  const tagSetQuery = useTagSetByCustomerId(customerId, { enabled: !!customerId });
  const allTags = tagSetQuery.data?.tags || [];

  const removeRule = useCallback(
    tag => {
      const update = rules.filter((r, i) => r.tag !== tag);
      onChange(update);
    },
    [rules, onChange]
  );

  const addRule = useCallback(
    tag => {
      const update = [
        ...rules,
        {
          tag: tag,
          delayDays: 0,
          sendEmail: true,
          sendSms: true
        }
      ];
      onChange(update);
    },
    [rules, onChange]
  );

  const updateRule = useCallback(
    (tag, updatedRule) => {
      const update = rules.map((rule, i) => {
        return tag === rule.tag ? updatedRule : rule;
      });
      onChange(update);
    },
    [rules, onChange]
  );

  const toggle = useCallback(
    tag => e => {
      e.target.checked ? addRule(tag) : removeRule(tag);
    },
    [addRule, removeRule]
  );

  const findRule = tag => {
    let rule = rules.filter((r, i) => r?.tag === tag);
    return rule[0];
  };

  return (
    <div>
      <p>
        Invites are sent between 10am - 6pm local time. Any invites received by Widewail outside of these hours will
        send at the next available time. Activate rules below to override default rules when invitations are sent.
      </p>
      <div className="pb-3">
        <Rule tag={undefined} updateRule={updateRule} rule={findRule(undefined)} />
      </div>
      {allTags.map((tag, index) => (
        <div key={index} className="flex-grow-1 bg-light border px-3 py-2 my-1">
          <Rule tag={tag} onToggleChange={toggle(tag, index)} updateRule={updateRule} rule={findRule(tag)} />
        </div>
      ))}
    </div>
  );
}

const Rule = ({ rule, tag, onToggleChange = noop, updateRule }) => {
  const [days, setDays] = useState(rule?.delayDays || 1);

  return (
    <div className="delivery-rules">
      {tag && (
        <div className="text-nowrap d-flex flex-nowrap align-items-center">
          <Switch
            name={tag}
            onChange={e => onToggleChange(e)}
            value={!!rule}
            checked={!!rule}
            inputWidth={12}
            className="align-self-center my-0"
          ></Switch>
          <h5>{tag} Delivery Rules</h5>
        </div>
      )}
      <Collapse isOpen={!!rule || tag === undefined}>
        <div className="delivery-rule d-flex flex-wrap justify-content-between pt-3">
          <div className="flex-grow-1 text-sm-wrap text-md-nowrap">
            <h5>{tag || "Default"} Message Delay</h5>
            <hr className="mt-0" />
            <FormGroup check className="d-flex flex-nowrap align-items-center my-2">
              <Input
                type="radio"
                name={`${tag || "defaultTag"}Radio`}
                onChange={() => updateRule(tag, Object.assign({}, rule, { delayDays: 0 }))}
                checked={rule?.delayDays === 0 || rule?.delayDays === undefined}
              />
              <span>Send invitations immediately</span>
            </FormGroup>
            <FormGroup check className="d-flex flex-nowrap align-items-center my-2">
              <Input
                type="radio"
                name={`${tag || "defaultTag"}Radio`}
                className="my-0"
                onChange={() => updateRule(tag, Object.assign({}, rule, { delayDays: days }))}
                checked={rule?.delayDays > 0}
              />
              <span>
                Send invitations after
                <Input
                  disabled={rule?.delayDays === 0 || rule?.delayDays === undefined}
                  type="number"
                  min="1"
                  max="60"
                  step="1"
                  className="d-inline mx-2 p-1"
                  style={{ width: "3rem", height: "1.5rem" }}
                  name="delay"
                  value={rule?.delayDays || days || 1}
                  onChange={evt => {
                    setDays(evt.target.value);
                    updateRule(tag, Object.assign({}, rule, { delayDays: evt.target.value }));
                  }}
                />
                days
              </span>
            </FormGroup>
            <div>
              <Input type="hidden" invalid={rule?.delayDays > 60} />
              <FormFeedback>Delay cannot be grater than 60 days.</FormFeedback>
            </div>
          </div>
          <div className="flex-grow-1 text-nowrap">
            <h5>{tag || "Default"} Message Type</h5>
            <hr className="mt-0" />
            <FormGroup check className="d-flex my-2">
              <Input
                type="checkbox"
                onChange={e => updateRule(tag, Object.assign({}, rule, { sendSms: e.target.checked }))}
                checked={!!rule?.sendSms}
              />
              <span>Send SMS Invites</span>
            </FormGroup>
            <FormGroup check className="d-flex">
              <Input
                type="checkbox"
                onChange={e => updateRule(tag, Object.assign({}, rule, { sendEmail: e.target.checked }))}
                checked={!!rule?.sendEmail}
              />
              <span>Send Email Invites</span>
            </FormGroup>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
