import React from "react";
import { capitalize, get } from "lodash";
import { Row, Col } from "reactstrap";
import memoize from "memoizee";
import ToggleSwitch from "components/Form/Switch";
import DayHoursForm from "./DayHoursForm";
import classnames from "classnames";
import { DAYS_OF_WEEK } from "util/dateUtils";

import styles from "./../Forms.module.scss";

const { daySwitchWrapperClass } = styles;

export const getDayOfWeekById = memoize(dayId => Object.keys(DAYS_OF_WEEK).find(day => DAYS_OF_WEEK[day] === dayId));

const DaysOfWeekForm = ({ initialValues, values, field }) => {
  return Object.keys(DAYS_OF_WEEK).map((day, index) => {
    const path = field.split(".");
    const lastPathItem = path.pop();
    const switchOn =
      !!get(values, [...path, lastPathItem, day, "hasOpenHours"]) ||
      !!get(values, [...path, `${lastPathItem}[${day}]`, "hasOpenHours"]);

    return (
      <Row key={`${field}[${day}]`} className="mt-2">
        <Col sm={12} md={4} lg={3}>
          <ToggleSwitch
            label={capitalize(DAYS_OF_WEEK[day])}
            labelClass="text-start pb-1"
            className={classnames("mt-3 flex-column-reverse flex-md-row flex-nowrap", daySwitchWrapperClass)}
            minWidth={200}
            name={`${field}[${day}].hasOpenHours`}
            value={switchOn}
            onLabel=""
            offLabel=""
          >
            <span className="d-inline-block ms-2">{switchOn ? "Open" : "Closed"}</span>
          </ToggleSwitch>
        </Col>
        <Col className="mt-3 pe-0 ps-lg-2">
          {switchOn && <DayHoursForm initialValues={initialValues} values={values} hoursField={field} dayIndex={day} />}
        </Col>
      </Row>
    );
  });
};

export default DaysOfWeekForm;
