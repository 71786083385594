import React, { useState } from "react";
import FormField from "../../../components/Form/FormField";
import { Button } from "reactstrap";
import CopyButton from "../../../components/Buttons/CopyButton";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import * as vinSolutionsApi from "../../../api/vinSolutionsApi";
import logger from "../../../util/logger";
import Select from "react-select";
import { DEBOUNCE_DELAY_DEFAULT } from "../../../constants/delays";

export default class VinSolutions {
  field() {
    return "vinSolutionsInfo";
  }

  add() {
    const AddVinSolutions = ({ customer, onSave }) => {
      const [selectedDealer, setSelectedDealer] = useState();
      const [dealers, setDealers] = useState([]);
      const [selectedUser, setSelectedUser] = useState();
      const [users, setUsers] = useState([]);

      const loadDealers = filter => {
        if (!isEmpty(filter)) {
          vinSolutionsApi
            .locations(filter)
            .then(res => {
              setDealers(
                res.data.map(p =>
                  Object.assign(
                    {},
                    {
                      label: p.companyName,
                      value: {
                        dealerId: p.dealerId,
                        companyName: p.companyName,
                        source: "VIN_SOLUTIONS"
                      }
                    }
                  )
                )
              );
            })
            .catch(err => logger.error(err));
        }
      };

      const loadUsers = filter => {
        if (isEmpty(selectedDealer)) {
          setUsers([]);
          setSelectedUser(null);
        } else {
          vinSolutionsApi
            .users(selectedDealer.value["dealerId"], filter)
            .then(res => {
              setUsers(
                res.data.map(u =>
                  Object.assign(
                    {},
                    {
                      label: u.FullName,
                      value: {
                        fullName: u.FullName,
                        userId: u.UserId,
                        role: u.IlmAccess
                      }
                    }
                  )
                )
              );
            })
            .catch(err => logger.error(err));
        }
      };

      const clearUsers = () => {
        setUsers([]);
        setSelectedUser(null);
      };

      return (
        <>
          <FormField label="Dealer">
            <Select
              className="w-100"
              options={dealers}
              onChange={l => {
                setSelectedDealer(l);
                clearUsers();
              }}
              onInputChange={debounce(loadDealers, DEBOUNCE_DELAY_DEFAULT)}
              value={selectedDealer}
              isClearable={true}
              placeholder="Dealer Name"
            />
          </FormField>

          <FormField label="User">
            <Select
              className="w-100"
              isDisabled={selectedDealer == null}
              options={users}
              onChange={u => setSelectedUser(u)}
              onInputChange={debounce(loadUsers, DEBOUNCE_DELAY_DEFAULT)}
              value={selectedUser}
              placeholder="User"
            />
          </FormField>

          <FormField label=" ">
            <Button
              disabled={!selectedDealer || !selectedUser}
              color="primary"
              onClick={() =>
                onSave({
                  ...selectedDealer.value,
                  source: "VIN_SOLUTIONS",
                  userId: selectedUser.value.userId,
                  userName: selectedUser.value.fullName
                })
              }
            >
              Link
            </Button>
          </FormField>
        </>
      );
    };

    return AddVinSolutions;
  }

  edit() {
    const EditVin = ({ info, customer, refreshSite }) => (
      <>
        <FormField label="Dealer Name">
          <div className="text-nowrap">
            {info.companyName}
            <span className="ps-1">
              <CopyButton text={info.companyName} />
            </span>
          </div>
        </FormField>
        <FormField label="User">
          <div className="text-nowrap">
            {info.userName}
            <span className="ps-1">
              <CopyButton text={info.userName} />
            </span>
          </div>
        </FormField>
      </>
    );
    return EditVin;
  }
}
