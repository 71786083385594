import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from "reactstrap";
import WWButton from "../Buttons/WWButton";
import { FileUploadButton } from "../../components/Form/FileUploadButton";

export default class AdvocateUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerId: ""
    };
  }

  render() {
    // let { errors, messages, recipients, message, contacts } = this.state
    // let { isOpen, onClose, reviewId } = this.props
    let { isOpen, onClose, uploadShareContacts } = this.props;
    return (
      <Modal size="lg" fade={false} isOpen={isOpen}>
        <ModalHeader>
          <h1>Upload Advocates</h1>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="text-start">
              <div className="px-2 py-2">
                <h2>{"Instructions"}</h2>
                <ol>
                  <li>
                    Prepare a CSV file to upload. The CSV file should have the following headers "name", "email",
                    "mobile", and "tags"
                  </li>
                  <li>
                    For each advocate, enter a row corresponding to the advocate's information. The "name" column should
                    contain the full name of the advocate. "name" and "email" are required for each advocate.
                  </li>
                  <li>
                    Click on the "Upload" button. A window will open where one can select the CSV file to upload. Select
                    the file and click the open button to upload the advocates.
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="me-2" href={process.env.PUBLIC_URL + "/advocate-example.csv"} download>
            Download Example
          </Button>
          <FileUploadButton
            color="primary"
            className="me-2"
            size="sm"
            name="LOA"
            accept="application/pdf"
            onChange={event => {
              uploadShareContacts(event);
              onClose();
            }}
          />
          <WWButton color="danger" iconClass="times" onClick={() => onClose()} tooltip="Cancel Upload">
            Cancel
          </WWButton>
        </ModalFooter>
      </Modal>
    );
  }
}
