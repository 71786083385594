import { assocPath, reduce } from "ramda";
import { decode } from "./functionUtils";
import logger from "./logger";
import { isNil } from "lodash";

const STATE_KEY = "state";

export const loadState = () => {
  try {
    const state = localStorage.getItem(STATE_KEY);
    if (state === null) {
      return undefined;
    }
    return JSON.parse(state);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  if (state) {
    try {
      localStorage.setItem(STATE_KEY, JSON.stringify(state));
    } catch (err) {
      logger.error(err);
    }
  }
};

export const getPersistedStateFragments = (prefix = "viewState/") => {
  const pairs = [];
  let i = 0;
  while (i < localStorage.length) {
    const key = localStorage.key(i);
    if (key.startsWith(prefix)) {
      pairs.push([key, localStorage.getItem(key)]);
    }
    i++;
  }
  return reduce(
    (acc, [path, val]) => assocPath(path.slice(prefix.length).split("/"), decode(val, true))(acc),
    {},
    pairs
  );
};

export const readLocalStorage = key => {
  const persistedState = localStorage.getItem(key);
  const parsedState = isNil(persistedState) ? undefined : JSON.parse(persistedState);
  return parsedState;
};

export const writeLocalStorage = (key, state) => localStorage.setItem(key, JSON.stringify(state));
