import React from "react";
import PropTypes from "prop-types";
import Review from "./Review";
import ReplyThread from "./Reply/ReplyThread";
import { Card, CardBody } from "../Card";

export default function ReviewThread({ review, reviewActions, replyActions }) {
  return (
    <Card>
      <CardBody>
        <Review review={review} reviewActions={reviewActions} />
      </CardBody>
      <ReplyThread review={review} replies={review.replies} replyActions={replyActions} />
    </Card>
  );
}

ReviewThread.propTypes = {
  review: PropTypes.object.isRequired,
  reviewActions: PropTypes.func,
  replyActions: PropTypes.func
};
