import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useModal } from "../Modals";
import { ModalBody, ModalHeader } from "reactstrap";
import Input from "../Form/Input";
import map from "lodash/map";
import noop from "lodash/noop";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import { Button } from "reactstrap";
import AjaxLoader from "../Misc/AjaxLoader";
import { Table, TR } from "../Table";
import { isArray, isObject } from "lodash";

/**
 * Simple modal dialog for taking in string input and showing a list of pickable search results
 *
 * @param {} param0
 */
export function SearchDialogButton({
  onSearch,
  onPick,
  renderRow,
  children,
  initialSearchTerm,
  allowEmptyTerm,
  ...props
}) {
  const raise = useModal();

  return (
    <Button
      {...props}
      onClick={() =>
        raise(resolve => (
          <SearchDialog
            onSearch={onSearch}
            onPick={onPick}
            renderRow={renderRow}
            initialSearchTerm={initialSearchTerm}
            title={children}
            close={resolve}
            allowEmptyTerm={allowEmptyTerm}
          />
        )).then(([close, value]) => close())
      }
    >
      {children}
    </Button>
  );
}

// TODO: add paging support
const SearchDialog = ({
  onSearch,
  onPick,
  renderRow,
  initialSearchTerm,
  title,
  allowEmptyTerm = false, //control is onSearch is fired w/ an empty search term
  close = noop
}) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [results, setResults] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    if (!allowEmptyTerm && isEmpty(searchTerm)) {
      setResults([]);
    } else if (isFunction(onSearch)) {
      setWorking(true);
      const result = onSearch(searchTerm);
      if (isArray(result)) {
        setResults(result);
        setWorking(false);
      } else {
        result.then(setResults).then(() => setWorking(false));
      }
    }
  }, [searchTerm, onSearch]);

  return (
    <>
      <ModalHeader className="d-flex w-100 justify-content-center">
        {title}
        <i className="fa fa-close float-end ms-2" onClick={close} />
      </ModalHeader>
      <ModalBody className="d-flex">
        <div className="w-100">
          <div>
            <Input type="input" value={searchTerm} onChange={evt => setSearchTerm(evt.target.value)} />
          </div>
          <div className="mt-2 w-100">
            {working && (
              <div className="text-center">
                <AjaxLoader />
              </div>
            )}
            {!working && (
              <Table className="w-100" striped>
                {map(results, (row, i) => (
                  <TR key={i} className="link" onClick={() => (close(), onPick(row))}>
                    {renderRow(row)}
                  </TR>
                ))}
              </Table>
            )}
          </div>
        </div>
      </ModalBody>
    </>
  );
};

SearchDialog.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onPick: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  initialSearchTerm: PropTypes.string,
  title: PropTypes.string,
  allowEmptyTerm: PropTypes.bool
};

export default SearchDialog;
