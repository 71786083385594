import React, { useMemo } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { CARD_HEADER_ACTION_DROPDOWN_CLASS } from "components/Card";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import { autoclose } from "components/Modals";
import { reviewStatusName } from "data/options";

const DROPDOWN_STATUSES = ["REPLIED", "UNREPLIED", "IGNORED", "REMOVED", "APPROVAL", "REVIEW", "NO_ACTION"];

const StatusDropdown = ({ review: { tags }, currentStatus, onUpdateStatus }) => {
  const confirm = useConfirmationDialog();
  return (
    <UncontrolledButtonDropdown disabled={!tags}>
      <DropdownToggle
        caret
        disabled={!tags}
        className={CARD_HEADER_ACTION_DROPDOWN_CLASS}
        data-cy="review-status-dropdown"
      >
        {reviewStatusName(currentStatus)}
      </DropdownToggle>
      <DropdownMenu>
        {DROPDOWN_STATUSES.map(status => {
          const statusLabel = reviewStatusName(status);
          return (
            <DropdownItem
              onClick={() =>
                autoclose(
                  confirm({
                    title: `Are you sure you want to change status for this review to "${statusLabel}" ?`
                  })
                ).then(v => v && onUpdateStatus(status))
              }
            >
              {statusLabel}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default StatusDropdown;
