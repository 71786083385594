import { intersection } from "lodash";
import moment from "moment";

/** @typedef {Extract<moment.unitOfTime.Base, "month" | "week" | "day" | "hour"} Frequency */
/** @typedef {["month", "week", "day", "hour"]} Frequencies */

/**
 * @param {Frequency} unit
 */
export const frequencyUnit2frequencyName = unit => {
  switch (unit) {
    case "day":
      return "Daily";
    case "hour":
      return "Hourly";
    case "month":
      return "Monthly";
    case "week":
      return "Weekly";
    default:
  }
  return;
};

/**
 * @param {Frequency} unit
 */
export const frequencyUnit2frequencyBackendValue = unit => {
  switch (unit) {
    case "day":
      return "DAILY";
    case "hour":
      return "HOURLY";
    case "month":
      return "MONTHLY";
    case "week":
      return "WEEKLY";
    default:
  }
  return;
};

/** @type {Frequencies} */
export const defaultFrequenciesOptions = ["month", "week", "day", "hour"];

/**
 *
 * @param {[number, moment.unitOfTime.Base]} param0
 * @param {Frequency[]} allowed
 * @returns {Frequency[]}
 */
export const getAvailableFrequencyOptions = ([duration, unit], allowed = defaultFrequenciesOptions) => {
  const days = moment.duration({ [unit]: duration }).asDays();
  return intersection(
    days <= 1
      ? ["hour"]
      : days < 7
      ? ["day"]
      : days < 28
      ? ["day", "week"]
      : days < 84 // 28 * 3
      ? ["week", "month", "day"]
      : ["month", "week"],
    allowed
  );
};
