import { useParams } from "react-router-dom";
import { WWTabs, WWTabContent, WWTabContentItem } from "components/WWTabs";
import AccountWhitespaceTable from "./AccountWhitespaceTable";
import EditAccountForm from "./EditAccountForm";
import ResponderNotesPage from "components/ResponderNotes/ResponderNotesPage";
import { permissions } from "components/Auth/permissions";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { useTabProps } from "hooks/tabsHooks";
import { useAccount } from "hooks/data/accountsHooks";
import styles from "./EditAccount.module.scss";

const TABS = ["general", "products", "responder notes"];

const { tabContentClass, tabContentItemClass } = styles;

export const EditAccount = () => {
  const { accountId } = useParams();
  const isNew = accountId === "new";
  const [activeTab, getTabProps] = useTabProps(TABS[0]);
  const { data, isLoading } = useAccount({
    accountId,
    enabled: !isNew && activeTab !== TABS[1]
  });
  return (
    <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
      <WWTabs tabs={(isNew ? [TABS[0]] : TABS).map(getTabProps)} className="bg-white pt-2 mb-3" />
      <div className="pb-4 px-xs-0 px-lg-4 container-fluid">
        <WWTabContent className={tabContentClass} activeTab={activeTab}>
          <WWTabContentItem className={tabContentItemClass} tabId={TABS[0]}>
            <EditAccountForm isNew={isNew} account={data} isLoading={isLoading} />
          </WWTabContentItem>
          {!isNew && (
            <>
              <WWTabContentItem className={tabContentItemClass} tabId={TABS[1]}>
                <AccountWhitespaceTable accountId={accountId} enabled={activeTab === TABS[1]} />
              </WWTabContentItem>
              <WWTabContentItem className={tabContentItemClass} tabId={TABS[2]}>
                <ResponderNotesPage
                  tagSetId={data?.tagSet.id}
                  accountId={accountId}
                  enabled={activeTab === TABS[2]}
                  basePath="#general"
                />
              </WWTabContentItem>
            </>
          )}
        </WWTabContent>
      </div>
    </AuthorizationRequiredToRender>
  );
};

export default EditAccount;
