import "./WWPagination.scss";

import { CSmartPagination } from "@coreui/react-pro";
import classNames from "classnames";
import { noop } from "lodash";

export default function WWPagination({
  setPageNumber = noop,
  pageNumber,
  pages,
  infinitePagination = false,
  loading = true
}) {
  if (pageNumber && pages) {
    return (
      <CSmartPagination
        align="center"
        className={classNames("WWPagination", { disabled: loading, "infinite-pagination": infinitePagination })}
        activePage={pageNumber}
        pages={pages}
        onActivePageChange={e => !loading && setPageNumber(e - 1)}
        limit={5}
        doubleArrows={false}
        previousButton={
          <div className="d-flex justify-content-center align-content-center">
            <i className="fa fa-chevron-left paging-icon pe-1" />
            <span>Prev</span>
          </div>
        }
        nextButton={
          <div className="d-flex justify-content-center align-content-center">
            <span>Next</span>
            <i className="fa fa-chevron-right paging-icon ps-1" />
          </div>
        }
      />
    );
  } else {
    return null;
  }
}
