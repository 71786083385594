import React, { useCallback, useState } from "react";
import ConfigurableBarChartWithLegend from "../../../components/Charts/ConfigurableBarChartWithLegend";
import { getImportedReviews } from "../../../api/reportApi";
import moment from "moment";
import get from "lodash/get";
import map from "lodash/map";
import { Button } from "reactstrap";
import { Intervals } from "../../../constants/chart";

export default () => {
  const [totalActive, setTotalActive] = useState();
  const dataSource = useDataSource();

  return (
    <ConfigurableBarChartWithLegend
      title={"Imported Reviews"}
      dataSource={dataSource}
      chartProps={{
        colors: {
          "Imported Reviews": "rgba(128, 128, 128, .2)",
          "Auto-replies": "rgba(128, 128, 128, .2)"
        },
        xAxis: {
          interval: 0,
          domain: ["dataMin", "dataMax"]
        },
        yAxis: {
          domain: [0, "dataMax"],
          interval: Intervals.preserveStartEnd,
          scale: "linear",
          allowDecimals: false
        },
        legend: {
          iconSize: 15
        },
        tooltip: {
          content: tooltipFormatter
        }
      }}
    />
  );
};

const tooltipFormatter = ({ active, payload, label }) => {
  if (active && payload && payload.length > 0) {
    let data = payload[0].payload;
    return (
      <Button>
        <div className={`d-flex flex-column align-items-start flex-grow-1`}>
          <span>
            <strong>{`${moment.unix(label).local().format("M/YYYY")}`}</strong>
          </span>
          {get(data, "Imported Reviews") != null ? (
            <span>{"Imported Reviews: " + get(data, "Imported Reviews")}</span>
          ) : (
            <></>
          )}
          {get(data, "Auto-replies") != null ? <span>{"Auto-replies: " + get(data, "Auto-replies")}</span> : <></>}
        </div>
      </Button>
    );
  } else {
    return null;
  }
};

const useDataSource = () => useCallback(getData, []);

const getData = () => {
  return getImportedReviews().then(({ importedReviews, autoReplyReviews }) => {
    let importedReviewData = map(importedReviews, e => {
      return { ...e, type: "Imported Reviews" };
    });
    let autoReplyData = map(autoReplyReviews, e => {
      return { ...e, type: "Auto-replies" };
    });
    return [...importedReviewData, ...autoReplyData];
  });
};
