import { memo } from "react";
import * as crm from "api/crmApi";
import { permissions } from "components/Auth/permissions";
import { ModalsContext } from "components/Modals";
import { CardHeaderAction } from "components/Card";
import SearchDialog from "components/SearchResults/SearchDialog";
import { TD } from "components/Table";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";

const HUBSPOT_AGENCIES = ["Widewail", "BH Management"];

const ImportFromHubspotAction = ({ onPickCustomer, currentAgencyName, enabled }) => (
  <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
    {HUBSPOT_AGENCIES.some(a => a === currentAgencyName) && enabled && (
      <ModalsContext.Consumer>
        {raiseModal => (
          <CardHeaderAction
            color="primary"
            onClick={() =>
              raiseModal(resolve => (
                <SearchDialog
                  close={resolve}
                  title="Search Hubspot Customers"
                  onSearch={term => crm.searchCompanies({ q: term })}
                  onPick={onPickCustomer}
                  renderRow={record => (
                    <TD>
                      <strong>{record.name}</strong>
                      <br />
                      <span className="text-muted">
                        {[record.address, record.city, record.state, record.zip].join(", ")}
                      </span>
                    </TD>
                  )}
                />
              )).then(([close]) => close())
            }
          >
            Import from Hubspot
          </CardHeaderAction>
        )}
      </ModalsContext.Consumer>
    )}
  </AuthorizationRequiredToRender>
);

export default memo(ImportFromHubspotAction);
