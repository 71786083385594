import { useState, useEffect, useMemo, useCallback } from "react";
import { useSharedCredentials } from "hooks/data/credentialsHooks";
import { loadCredentials } from "api/sharedCredentialsApi";
import { useErrorNotification } from "components/Notifications/notification";
import SelectField from "components/Form/SelectField";
import { debounce, noop } from "lodash";
import FormField from "components/Form/FormField";
import { DEBOUNCE_DELAY_DEFAULT } from "constants/delays";

export const SharedCredentialsSelector = ({
  id = "",
  source = "",
  label = "",
  emptyLabel = "None",
  onChange = noop,
  onInputChange = noop,
  ...props
}) => {
  const errorNotification = useErrorNotification();

  const [name, setName] = useState("");
  const [debouncedName, setDebouncedName] = useState("");
  useEffect(() => {
    onInputChange(debouncedName);
  }, [debouncedName, onInputChange]);

  // we can't debounce setName b/c that will mess up showing what the user typed
  // in the select field. Thus we have these name hooks

  const nameChangeHandler = useCallback(
    debounce(input => setDebouncedName(input), DEBOUNCE_DELAY_DEFAULT),
    []
  );
  useEffect(() => {
    nameChangeHandler(name);
  }, [name]);

  const { data, isFetching } = useSharedCredentials({ size: 50, name: debouncedName, source });
  const options = useMemo(
    () =>
      data && [
        { value: null, label: emptyLabel },
        ...data[0].map(credential => ({
          value: credential.id,
          label: credential.name
        }))
      ],
    [data, emptyLabel]
  );

  const [selected, setSelected] = useState(id);
  const onselect = useCallback(
    selected => {
      setSelected(selected);
      if (selected) {
        loadCredentials(selected)
          .then(result => {
            onChange(result.data);
            onInputChange(result.data.name);
          })
          .catch(errorNotification);
      } else {
        onChange(null);
        onInputChange("");
      }
    },
    [onChange, onInputChange, errorNotification]
  );

  return (
    <FormField label={label}>
      <SelectField
        name="scselector"
        value={options?.find(option => option.value == selected)}
        isLoading={isFetching}
        options={options || []}
        isSearchable={true}
        inputValue={name}
        onInputChange={setName}
        data-lpignore={true}
        onChange={event => onselect(event.target.value.value)}
        {...props}
      />
    </FormField>
  );
};

export default SharedCredentialsSelector;
