import React from "react";
import { useState } from "react";
import { Card, CardBody, CardHeaderAction } from "../../components/Card";
import FormField from "../../components/Form/FormField";
import DateTimeSelector from "../../components/DateTime/DateTimeSelector";
import Time from "../../components/DateTime/Time";
import HtmlSelectField from "../../components/Form/HtmlSelectField";
import { Staff } from "./Staff";
import { Input, Col, Row } from "reactstrap";
import moment from "moment";
import isEqual from "lodash/isEqual";
import GenericContentSkeleton from "components/Skeleton/GenericContentSkeleton/GenericContentSkeleton";
import { get } from "lodash";

export const DummyInteraction = () => {
  return (
    <Card>
      <CardBody>
        <GenericContentSkeleton />
      </CardBody>
    </Card>
  );
};

export const Interaction = ({ interaction = {}, onChangesCommitted, children, tagSet, isLoading }) => {
  const [localInteraction, setLocalInteraction] = useState(interaction);
  const [notes, setNotes] = useState(interaction.notes);
  const [visitTime, setVisitTime] = useState(new Date(interaction.visitTime));
  const [representatives, setRepresentatives] = useState(
    interaction?.representatives?.map(obj => [obj.firstName, obj.lastName])
  );
  const [tags, setTags] = useState(get(interaction, "tags[0]"));

  return isLoading ? (
    <DummyInteraction />
  ) : (
    <Card>
      <CardBody>
        <CardHeaderAction
          className="ms-auto"
          disabled={
            notes === localInteraction.notes &&
            tags === localInteraction.tags?.[0] &&
            isEqual(
              representatives,
              localInteraction?.representatives?.map(obj => [obj.firstName, obj.lastName])
            ) &&
            moment(visitTime).isSame(localInteraction.visitTime, "minute")
          }
          color="primary"
          onClick={() => {
            onChangesCommitted({
              ...interaction,
              notes,
              tags: [tags],
              representatives: representatives.map(([firstName, lastName]) => ({
                firstName,
                lastName
              })),
              visitTime: visitTime.toISOString()
            });
            setLocalInteraction({
              ...interaction,
              notes,
              tags,
              representatives: representatives.map(([firstName, lastName]) => ({
                firstName,
                lastName
              })),
              visitTime: visitTime.toISOString()
            });
          }}
        >
          Update
        </CardHeaderAction>
        <FormField name="date" label="Date">
          <DateTimeSelector date={visitTime} clearable={false} onChange={date => setVisitTime(date)} />
        </FormField>
        <FormField name="department" label="Department">
          <HtmlSelectField
            name="department"
            value={tags}
            options={tagSet}
            onChange={({ target }) => setTags(target.value)}
          />
        </FormField>
        <FormField name="representatives" label="Representatives">
          <Row>
            <Col>
              <Staff staff={representatives} onChange={v => setRepresentatives(v)} />
            </Col>
          </Row>
        </FormField>
        <FormField name="sent" label="Review invite sent">
          {interaction.invitations?.filter(invitation => {
            let status = invitation.status;
            return status !== "QUEUED" && status !== "SKIPPED";
          })?.length
            ? "Yes"
            : "No"}
        </FormField>
        <FormField name="createdOn" label="Created On">
          <Time format="datetime" withIcon={false} date={interaction.createdOn} />
        </FormField>
        {interaction.estimatedSendTime && interaction.status != "EVALUATED" && (
          <FormField name="estimatedSendTime" label="Estimated Send Time">
            <Time format="datetime" withIcon={false} date={interaction.estimatedSendTime} />
          </FormField>
        )}
        <FormField name="notes" label="Notes">
          <Input
            type="textarea"
            value={notes}
            placeholder="enter your notes here"
            onChange={({ target }) => setNotes(target.value)}
          />
        </FormField>
        {children}
      </CardBody>
    </Card>
  );
};
