import { useState, useEffect } from "react";
import moment from "moment";
import { useGet } from "hooks/dataHooks";
import classNames from "classnames";
import { useQuery } from "react-query";
import WidgetCard from "../CommonComponents/WidgetCard";
import { NoDataCallToAction } from "../CallsToActionBanners/CallsToActionBanners";
import LinkButton from "components/Routing/LinkButton";
import Skeleton from "components/Skeleton";
import ActiveProductsRequireToRender from "components/Auth/ActiveProductsRequireToRender";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import { normalizeVideoResponse, VIDEO_FORMATS, DisplayedVideoFormats } from "util/dataUtils/videoDataUtils";
import { currentContact } from "util/userUtils";

import styles from "./VideoLibraryWidget.module.scss";

const {
  thumbnailClass,
  scaledThumbnailClass,
  selectedClass,
  videoClass,
  dateClass,
  playIconClass,
  bannerClass
} = styles;

const getVideoNeedsResize = formats => {
  const originalDisplayedFormats = new Set([
    DisplayedVideoFormats[VIDEO_FORMATS.highRes],
    DisplayedVideoFormats[VIDEO_FORMATS.original]
  ]);
  const videoFormat = formats?.find(({ format }) => originalDisplayedFormats.has(format));
  return videoFormat ? videoFormat.height / videoFormat.width > 1.3 : false;
};

export const useVideoLibrary = ({ params = {} }) => {
  const path = "interactions/media";
  const [get] = useGet(path, { params });
  return useQuery([path, params], () => get().then(normalizeVideoResponse));
};

const VideoWidgetSkeleton = () => (
  <div className="d-flex flex-column align-items-center w-100">
    <Skeleton width={320} height={200} />
    <div className="d-flex justify-content-between mt-2 w-100">
      {new Array(4).fill(0).map((_, index) => (
        <Skeleton key={index} height={65} width={115} />
      ))}
    </div>
  </div>
);

export const VideoLibraryWidget = ({ data: { entries = [] } = {}, isLoading }) => {
  const [selected, setSelected] = useState();
  useEffect(() => {
    setSelected(entries[0]?.id);
  }, [entries]);
  const selectedVideo = entries.find(({ id }) => id === selected);
  return (
    <WidgetCard heading="Newest Invite Videos">
      {isLoading ? (
        <VideoWidgetSkeleton />
      ) : !!entries?.length ? (
        <>
          {!!selectedVideo && (
            <video className={videoClass} src={selectedVideo.previewSrc} controls={true} autoPlay={false} />
          )}
          <div className="d-flex w-100 justify-content-between">
            {entries.map(({ id, thumbnail, meta, formats }) => (
              <div
                key={id}
                className={classNames(thumbnailClass, {
                  [selectedClass]: id === selected,
                  [scaledThumbnailClass]: getVideoNeedsResize(formats)
                })}
                onClick={() => setSelected(id)}
              >
                <img src={thumbnail} />
                {id !== selected && (
                  <div className={playIconClass}>
                    <i className="fa fa-play-circle-o" />{" "}
                  </div>
                )}
                <div className={dateClass}>{moment().diff(meta.date, "days")} days ago</div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            <LinkButton color="primary" to="/invite/video">
              SEE ALL VIDEOS
            </LinkButton>
          </div>
        </>
      ) : (
        <NoDataCallToAction
          className={bannerClass}
          text="You have no videos yet"
          buttonText="Build a Video Campaign"
          link="invite/campaigns"
        />
      )}
    </WidgetCard>
  );
};

const VideoLibraryWidgetWithQuery = () => {
  const { data, isLoading } = useVideoLibrary({
    params: {
      size: 4,
      contactId: currentContact().id,
      status: "LIVE"
    }
  });
  return <VideoLibraryWidget data={data} isLoading={isLoading} />;
};

export default () => (
  <ActiveProductsRequireToRender products={["INVITE_VIDEO"]}>
    <AuthorizationRequiredToRender roles={[permissions.VIDEO_MANAGE]}>
      <VideoLibraryWidgetWithQuery />
    </AuthorizationRequiredToRender>
  </ActiveProductsRequireToRender>
);
