import { ContactMethods } from "data/options";
import { decodeSignatureLine } from "util/customerUtils";

export default function signature(contact={}, decode = false) {
  const { name, title, preferredContactMethod, signatureLine, email, phone } = contact;
  if (ContactMethods.CUSTOM === preferredContactMethod) {
    return decode ? decodeSignatureLine(contact) : signatureLine;
  }
  return [name, title, ContactMethods.EMAIL === preferredContactMethod ? email : phone].join(" - ");
}
