import React from "react";
import { ExampleLine } from "./ExampleLine";

export const ComparisonLinesLegend = () => {
  return (
    <div className="graph__legend">
      <div className="d-flex flex-row align-items-center flex-nowrap m-1">
        <ExampleLine dashed={false} />
        <span className="text-muted">current time period</span>
      </div>
      <div className="d-flex flex-row align-items-center flex-nowrap m-1">
        <ExampleLine dashed={true} />
        <span className="text-muted">previous time period</span>
      </div>
    </div>
  );
};
