// uncomment this line if you have something to import from your scss module
import moment from "moment";
import classnames from "classnames";
import Skeleton from "components/Skeleton";
import Measurable from "../../../views/Reviews/Feed/Measurable";
import { Card } from "../../Card";
import { LoadingSkeleton as LegendLoadingSkeleton } from "../../Reviews/Legend/Legend";
import VideoActions from "./VideoActions";

import styles from "./Entry.module.scss";
import React from "react";

const {
  authorClass,
  authorDateContainerClass,
  dateClass,
  metadataClass,
  card,
  legend,
  thumbnail,
  thumbnailInfo,
  thumbnailBadges,
  categoryInfo,
  winnerBadge
} = styles;

const SKELETON_HEIGHT = 28;
const SKELETON_WIDTH = 24;
const SKELETON_MULTIPLYERS = [0.2, 0.3, 0.4];

export default function Entry({
  href,
  date,
  category,
  campaign,
  companyName,
  author,
  onRemove,
  onDetails,
  redeemedIncentive,
  duration = null,
  downloadLink,
  formats
}) {
  const categoryValue = Array.isArray(category) ? category[0] : category;
  return (
    <Card className={card} onClick={onDetails}>
      <div className={thumbnail}>
        <div className={thumbnailBadges}>{redeemedIncentive && <div className={winnerBadge}>Winner</div>}</div>
        <img src={href} />
        <div className={thumbnailInfo}>
          {!!categoryValue && <div className={categoryInfo}>{categoryValue}</div>}
          {!!duration && <div>{moment.utc(duration * 1000).format("H:mm:ss")}</div>}
        </div>
      </div>
      <div className={legend}>
        <div className={authorDateContainerClass}>
          {!!author && <div className={authorClass}>{author}</div>}
          {!!date && <div className={dateClass}>{moment(date).format("MM/DD/YYYY")}</div>}
        </div>
        {!!companyName && (
          <div className={metadataClass}>
            <i className="fa fa-building" />
            {companyName}
          </div>
        )}
        {!!campaign && (
          <div className={metadataClass}>
            <i className="fa fa-bullhorn" />
            {campaign}
          </div>
        )}
      </div>
      <VideoActions onRemove={onRemove} downloadLink={downloadLink} formats={formats} />
    </Card>
  );
}

export const LoadingSkeleton = ({ className = "" }) => (
  <Card className={classnames(card, className)}>
    <div className={thumbnail}></div>
    <Measurable className={legend}>
      {w => (
        <>
          {SKELETON_MULTIPLYERS.map(mul => (
            <LegendLoadingSkeleton width={w * mul} />
          ))}
          <Skeleton circle width={SKELETON_WIDTH} height={SKELETON_HEIGHT} />
        </>
      )}
    </Measurable>
  </Card>
);
