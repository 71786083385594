import React, { useContext, useEffect, useCallback, useMemo } from "react";
import { PatternFormat } from "react-number-format";
import { FormContext } from "./Form";
import { get, noop } from "lodash";

export default function PhoneNumberField({
  name = "phoneNumber",
  placeholder = "Phone number",
  invalid = false,
  value,
  onValueChange,
  ...props
}) {
  const context = useContext(FormContext);
  useEffect(() => {
    context && context.registerField(name);
  }, [name]);

  const inputValue = useMemo(() => {
    if (value || !name || !context) {
      return value;
    } else {
      return get(context.state, context.parseFieldName(name));
    }
  }, [value, context?.state, name]);

  const changeHandler = useCallback(
    value => {
      if (onValueChange) {
        return onValueChange(value);
      } else if (context) {
        return context.setTextField({ target: { name, ...value } });
      }
      return noop;
    },
    [onValueChange, name]
  );

  return (
    <PatternFormat
      className={`${invalid ? "is-invalid " : ""}form-control`}
      type="tel"
      name={name}
      isNumericString={true}
      format="+1 (###) ###-####"
      placeholder={placeholder}
      mask="_"
      value={inputValue}
      onValueChange={changeHandler}
      {...props}
    />
  );
}
