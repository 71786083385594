import React from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";
import { logoutAgency } from "../../redux/actions/agencyActions";
import { logoutUser } from "../../redux/actions/authStatusActions";

export const useLogout = clearLocalStorage => {
  const dispatch = useDispatch();

  return () => {
    clearLocalStorage && localStorage.clear();
    dispatch(logoutAgency());
    dispatch(logoutUser());
  };
};

export const LogoutDropdownItem = () => {
  const logout = useLogout();

  return (
    <DropdownItem onClick={logout} className="dropdown__logout">
      <i className="fa fa-lock"></i> Logout
    </DropdownItem>
  );
};

export default LogoutDropdownItem;
