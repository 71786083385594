import classNames from "classnames";
import React, { useCallback } from "react";
import { autoclose } from "../Modals";
import { useConfirmationDialog } from "../Modals/confirmation.modal";
import WWButton from "./WWButton";
import styles from "./DeleteButton.module.scss";

/**
 * Standard delete button. Specify type to get either a delete icon
 * or an actual button.
 *
 * @callback onClickCallback
 *
 * @param {string} [type=icon]
 * @param {string} confirmationPrompt
 * @param {string} confirmationTitle
 * @param {boolean} squareButton
 * @param {string} className
 * @param {onClickCallback} onClick
 */
export default function DeleteButton({
  type = "icon",
  confirmationPrompt = "Are you sure?",
  confirmationTitle = "Delete?",
  onClick,
  className,
  iconClass = "fa fa-trash",
  squareButton,
  children = "Delete",
  stopPropagation,
  ...props
}) {
  const confirm = useConfirmationDialog();
  const onButtonClick = useCallback(
    e => {
      stopPropagation && e.stopPropagation();
      return autoclose(
        confirm({
          title: confirmationTitle,
          body: <p>{confirmationPrompt}</p>
        })
      ).then(v => v && onClick(e));
    },
    [onClick, confirmationPrompt, confirmationTitle]
  );

  return type === "icon" ? (
    <i className={`fa fa-trash link ${className || ""}`} onClick={onButtonClick} {...props} />
  ) : (
    <WWButton
      color="danger"
      iconClass={iconClass}
      className={classNames(className, { [styles.squareButton]: squareButton })}
      onClick={onButtonClick}
      {...props}
    >
      {children}
    </WWButton>
  );
}
