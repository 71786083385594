import React, { useCallback, useMemo, useState } from "react";
import { Button, Collapse } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import InputField from "../../../components/Form/InputField";
import CopyButton from "../../../components/Buttons/CopyButton";
import Labeled from "../../../components/Checkbox/Labeled/CheckboxLabeled";
import { updateServiceFusionInfo } from "../../../api/customerApi";

export default class ServiceFusion {
  field() {
    return "serviceFusionInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info, customer }) => {
  const [initialClientId, setInitialClientId] = useState(info.clientId);
  const [clientId, setClientId] = useState(initialClientId);

  const [initialClientSecret, setInitialClientSecret] = useState(info.clientSecret);
  const [clientSecret, setClientSecret] = useState(initialClientSecret);

  const [initialImportFullyPaidInvoices, setInitialImportFullyPaidInvoices] = useState(info.importFullyPaidInvoices);
  const [importFullyPaidInvoices, setImportFullyPaidInvoices] = useState(initialImportFullyPaidInvoices);

  const patchReady = useMemo(
    () =>
      initialImportFullyPaidInvoices !== importFullyPaidInvoices ||
      initialClientId !== clientId ||
      initialClientSecret !== clientSecret,
    [
      initialClientId,
      clientId,
      initialClientSecret,
      clientSecret,
      initialImportFullyPaidInvoices,
      importFullyPaidInvoices
    ]
  );
  const saveToggles = useCallback(
    e => {
      updateServiceFusionInfo(customer.id, info.id, {
        clientId,
        clientSecret,
        importFullyPaidInvoices
      }).then(() => {
        setInitialClientId(clientId);
        setInitialClientSecret(clientSecret);
        setInitialImportFullyPaidInvoices(importFullyPaidInvoices);
      });
    },
    [info, clientId, clientSecret, importFullyPaidInvoices, customer]
  );

  return (
    <>
      <InputField
        type="text"
        label="Service Fusion Client ID"
        placeholder="Client ID"
        onChange={e => setClientId(e.target.value)}
        value={clientId}
      />
      <CopyButton text={info.clientId} />
      <InputField
        type="password"
        label="Service Fusion Client Secret"
        placeholder="Client Secret"
        onChange={e => setClientSecret(e.target.value)}
        value={clientSecret}
        disabled={true}
      />
      <Labeled
        name="importFullyPaidInvoices"
        checked={importFullyPaidInvoices}
        onToggle={setImportFullyPaidInvoices}
        label="Import only fully paid invoices"
      />

      <Collapse isOpen={patchReady}>
        <FormField label=" ">
          <Button color="primary" onClick={saveToggles} disabled={!patchReady}>
            Update
          </Button>
        </FormField>
      </Collapse>
    </>
  );
};

const Add = ({ onSave }) => {
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [importFullyPaidInvoices, setImportFullyPaidInvoices] = useState(false);

  const onLink = useCallback(
    () =>
      onSave({
        clientId,
        clientSecret,
        importFullyPaidInvoices,
        source: "SERVICE_FUSION"
      }),
    [clientId, clientSecret, importFullyPaidInvoices, onSave]
  );

  return (
    <>
      <InputField
        type="text"
        label="Service Fusion Client ID"
        placeholder="Client ID"
        onChange={e => setClientId(e.target.value)}
        value={clientId}
      />
      <InputField
        type="text"
        label="Service Fusion Client Secret"
        placeholder="Client Secret"
        onChange={e => setClientSecret(e.target.value)}
        value={clientSecret}
      />
      <Labeled
        name="importFullyPaidInvoices"
        checked={importFullyPaidInvoices}
        onToggle={setImportFullyPaidInvoices}
        label="Import only fully paid invoices"
      />
      <Button color="primary" disabled={!clientId || !clientSecret} onClick={onLink}>
        Link
      </Button>
    </>
  );
};
