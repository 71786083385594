import React, { useEffect, useState, useCallback } from "react";
import { get } from "lodash";
import SendMessageForm from "components/Reviews/SendMessageForm/SendMessageForm";
import MessageThread from "components/Messages/MessageThread";
import { errorCaughtNotifier, useLocalNotifications } from "components/Notifications/notification";
import * as api from "api/reviewApi";

import styles from "./Review.module.scss";
import classnames from "classnames";

const { reviewMessagesClass, reviewMessagesModalClass } = styles;

const ReviewMessages = ({
  review,
  review: { id },
  toggleAllActivity,
  className,
  standalone = false,
  onSendMessage = null
}) => {
  const notify = useLocalNotifications();

  const [messages, setMessages] = useState(review.messages || []);

  useEffect(() => {
    setMessages(review.messages);
  }, [review]);

  const loadMessages = useCallback(
    () =>
      api
        .loadMessages(id)
        .then(res => setMessages(get(res.data, ["_embedded", "messages"])))
        .catch(errorCaughtNotifier(notify)),
    []
  );

  useEffect(() => {
    if (standalone) {
      loadMessages();
    }
  }, [standalone]);

  if (!review) {
    return null;
  }

  return (
    <div className={classnames(reviewMessagesClass, className, { [reviewMessagesModalClass]: standalone })}>
      <SendMessageForm
        review={review}
        onSendMessage={standalone ? loadMessages : onSendMessage}
        showButtons={!standalone}
      />
      {messages?.length > 0 && (
        <MessageThread
          messages={messages}
          showRecipients={false}
          standalone={standalone}
          toggleAllActivity={() => toggleAllActivity(loadMessages)}
        />
      )}
    </div>
  );
};

export default ReviewMessages;
