import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">
        <span>
          <a href="http://www.widewail.com">Widewail</a> &copy; {new Date().getFullYear()}
        </span>
      </footer>
    );
  }
}

export default Footer;
