import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "components/Card";
import { Col, Row } from "reactstrap";
import { useCurrentAgency } from "hooks/agencyHooks";
import ListField from "components/Form/ListField";
import { identity } from "lodash";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import { usePatchTopics, useTagSetById, useTagSetsByAgency } from "hooks/data/tagSetHooks";
import styles from "./EditAgency.module.scss";
import classNames from "classnames";

const { categoryContainer } = styles;

const EditAgency = () => {
  const [category, setCategory] = useState({});
  const agency = useCurrentAgency();
  const tagSetQuery = useTagSetsByAgency(agency.id, {
    enabled: !!agency.id,
    onSuccess: data => setCategory({ value: data[0].id, label: data[0].name })
  });
  const categoryOptions = tagSetQuery.data?.map(tagSet => ({ label: tagSet.name, value: tagSet.id })) || [];
  const tagsQuery = useTagSetById(category.value, { enabled: !!category.value });
  const tagSets = tagsQuery.data?.topics || [];
  const updateTopics = usePatchTopics(category.value);

  const onAdd = tag => {
    return updateTopics.mutate({ topics: [...tagSets, tag.value] });
  };

  const onRemove = tag => {
    return updateTopics.mutate({ topics: tagSets.filter(topic => topic !== tag) });
  };
  return (
    <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
      <div className="px-4 pt-4 container-fluid">
        <Row>
          <Col xs={12} md={6}>
            <h3>Edit Agency: {agency.name}</h3>
            <Card>
              <CardHeader className="card--header__nav-wrap">
                <CardTitle>Manage Topics</CardTitle>
              </CardHeader>
              <CardBody>
                <div className={classNames("bg-light p-2 mb-2", categoryContainer)}>
                  <HorizontalSelectField
                    label="Topics Tag Set"
                    value={category}
                    inline={true}
                    noMargin={true}
                    options={categoryOptions}
                    onChange={event => setCategory(event.target.value)}
                  />
                </div>
                <ListField
                  label="Topic Tags"
                  members={tagSets}
                  placeholder="Add a Topic"
                  getMemberLabel={identity}
                  getMemberValue={identity}
                  options={[]}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  creatable={true}
                />
              </CardBody>
            </Card>
          </Col>
          <Col />
        </Row>
      </div>
    </AuthorizationRequiredToRender>
  );
};

export default EditAgency;
