export const permissions = {
  WIDEWAIL_ADMIN: "WIDEWAIL_ADMIN",

  AGENCY_ADMIN: "AGENCY_ADMIN",
  AGENCY_CREATE: "AGENCY_CREATE",
  AGENCY_MANAGE: "AGENCY_MANAGE",

  RESPONSE_MANAGER: "RESPONSE_MANAGER",

  REVIEW_READ: "REVIEW_READ",
  REVIEW_REPLY: "REVIEW_REPLY",
  REVIEW_APPROVE: "REVIEW_APPROVE",
  REVIEW_INVITE_MANAGE: "REVIEW_INVITE_MANAGE",
  TEMPLATE_MANAGE: "TEMPLATE_MANAGE",

  VIDEO_MANAGE: "VIDEO_MANAGE",

  LOCATION_MANAGE: "LOCATION_MANAGE",

  LISTINGS_MANAGE: "LISTINGS_MANAGE",
  LISTINGS_READ: "LISTINGS_READ",

  REPORT_READ: "REPORT_READ",

  CREATE_POST: "CREATE_POST",
  CREATE_SHARE: "CREATE_SHARE",

  SHARE_DIRECT: "SHARE_DIRECT",

  DASHBOARD_READ: "DASHBOARD_READ",

  CONTACT_MANAGE: "CONTACT_MANAGE",
  CONTACT_MESSAGE: "CONTACT_MESSAGE",

  HUBSPOT_API_USER: "HUBSPOT_API_USER",

  LEGACY_REVIEW_REPORT: "LEGACY_REVIEW_REPORT"
};
