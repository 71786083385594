import React, { useCallback, useState, useEffect } from "react";
import { Row, Col, Input, Form, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Card, CardHeader, CardTitle, CardHeaderActions, CardHeaderAction, CardBody } from "../../components/Card";
import { getLeadDetails, addInvitation, updateLead, updateInteraction } from "../../api/leadsApi";
import FormField from "../../components/Form/FormField";
import AjaxLoader from "../../components/Misc/AjaxLoader";
import { AddInteractionWizard } from "./AddInteraction";
import { Interaction } from "./Interaction";
import { Invitation } from "./Invitation";
import moment from "moment";
import SendInviteButtons from "./SendInviteButtons";
import { salutationName, salutationValue, SUPPORTED_SLUTATIONS } from "../../data/options";
import { errorCaughtNotifier, useLocalNotifications } from "../../components/Notifications/notification";
import HtmlSelectField from "../../components/Form/HtmlSelectField";
import PhoneNumberField from "../../components/Form/PhoneNumberField";
import { is, validEmail, validMobileLength, validName } from "../../util/validators";
import { currentUserHasFeature } from "util/userUtils";
import { useTagSetByCustomerId } from "hooks/data/tagSetHooks";
import { CAMPAIGNS } from "data/featureFlags";
import { useReviewLeadInteractions } from "hooks/data/useReviewLeads";
import WWPagination from "components/Paging/WWPagination/WWPagination";

export const LeadEdit = ({ id }) => {
  const [mobile, setMobile] = useState("");
  const [mobileValid, setMobileValid] = useState(true);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [salutation, setSalutation] = useState("");
  const [nameValid, setNameValid] = useState(true);
  const [status, setStatus] = useState();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [interactionAdding, setInteractionAdding] = useState(false);
  const [lastInviteDate, setLastInviteDate] = useState();
  const [invitationConfirmPrompt, setInvitationConfirmPrompt] = useState();
  const [properties, setProperties] = useState({});
  const [sensitive, setSensitive] = useState(true);

  const tagSetQuery = useTagSetByCustomerId(customer?.id, { enabled: !!customer?.id });
  const tagSet = tagSetQuery.data?.tags || [];

  const notify = useLocalNotifications();

  const formValid = () => {
    if (email && !emailValid) return false;
    if (firstName && !nameValid) return false;
    if (mobile && !mobileValid) return false;
    return true;
  };

  // load paged intereactions for this review lead
  const [currentPage, setCurrentPage] = useState(0),
    query = useReviewLeadInteractions(id, currentPage),
    { data: { interactions = [], pageInfo: { totalPages } = {} } = {} } = query;

  useEffect(
    () =>
      void getLeadDetails(id)
        .then(({ reviewLead, properties }) => {
          setMobile(reviewLead.mobile);
          setFirstName(reviewLead.firstName);
          setLastName(reviewLead.lastName);
          setSalutation(reviewLead.salutation);
          setEmail(reviewLead.email);
          setCustomer(reviewLead.customer);
          setStatus(reviewLead.status);
          setProperties(properties);
          setSensitive(reviewLead.sensitive);
          if (reviewLead.latestInvitationDate) {
            const d = moment(reviewLead.latestInvitationDate);
            setLastInviteDate(d);
            setInvitationConfirmPrompt(
              `${reviewLead.firstName} ${reviewLead.lastName
              } received an invitation ${d.fromNow()}. Are you sure you want to send another?`
            );
          }
        })
        .catch(errorCaughtNotifier(notify))
        .finally(() => setLoading(false)),
    [id, notify]
  );

  const updateEmail = useCallback(e => {
    const value = e.target.value;
    setEmailValid(is(validEmail)(value));
    setEmail(value);
  }, []);

  const updateMobile = useCallback(({ value }) => {
    setMobileValid(is(validMobileLength)(value));
    setMobile(value);
  }, []);

  const updateFirstName = useCallback(e => {
    const value = e.target.value;
    setNameValid(is(validName)(value));
    setFirstName(value);
  }, []);

  const updateLastName = useCallback(e => {
    const value = e.target.value;
    setLastName(value);
  }, []);

  const invitationConfirm = () => {
    if (!lastInviteDate) return false;
    const ago = lastInviteDate.diff(moment(), "days");
    return ago < properties["invite.cooldown.days"];
  };

  const sendInvitation = (interactionId, deliveryMethod, type) => {
    addInvitation({ deliveryMethod, type })(interactionId)
      .then(() => getLeadDetails(id))
      .then(query.refetch)
      .catch(errorCaughtNotifier(notify));
  };

  const interactionsPager =
    totalPages > 1 ? (
      <WWPagination
        loading={query.isFetching}
        pageNumber={currentPage + 1 || 1}
        pages={totalPages}
        setPageNumber={setCurrentPage}
      />
    ) : null;

  const onModalClose = () => {
    setInteractionAdding(false);
  };

  return (
    <div className=" p-4 container-fluid">
      {loading ? (
        <AjaxLoader loading="true"></AjaxLoader>
      ) : (
        firstName && (
          <Row>
            <Col>
              <Modal isOpen={interactionAdding}>
                <ModalHeader toggle={onModalClose} />
                <ModalBody className="text-center">
                  <AddInteractionWizard
                    onInteractionAdded={() => {
                      onModalClose();
                      query.refetch();
                    }}
                    lead={{ id, customer }}
                  />
                </ModalBody>
              </Modal>
              <Card>
                <CardHeader>
                  <CardTitle>Customer Details</CardTitle>
                  <CardHeaderActions>
                    <CardHeaderAction color="primary" type="submit" form="customer" disabled={!formValid()}>
                      Save
                    </CardHeaderAction>
                  </CardHeaderActions>
                </CardHeader>
                <CardBody>
                  <Form
                    id="customer"
                    onSubmit={e => {
                      e.preventDefault();
                      updateLead({
                        firstName,
                        lastName,
                        email,
                        mobile,
                        status,
                        salutation: salutationName(salutation)
                      })(id);
                    }}
                  >
                    <FormField label="Salutation">
                      <HtmlSelectField
                        name="salutation"
                        placeholder="<blank>"
                        value={salutationName(salutation)}
                        options={SUPPORTED_SLUTATIONS.map(v => (v === "blank" ? "<blank>" : salutationName(v)))}
                        onChange={({ target }) => {
                          target.value === "<blank>" ? setSalutation("") : setSalutation(salutationValue(target.value));
                        }}
                      />
                    </FormField>
                    <FormField label="First Name" feedback="First name is required">
                      <Input
                        type="text"
                        placeholder="first name"
                        value={firstName}
                        onChange={updateFirstName}
                        invalid={!firstName}
                      />
                    </FormField>
                    <FormField label="Last Name">
                      <Input
                        type="text"
                        readOnly={sensitive}
                        placeholder="last name"
                        value={lastName}
                        onChange={updateLastName}
                      />
                    </FormField>
                    <FormField label="Mobile" feedback="Please enter correct phone number">
                      <PhoneNumberField
                        style={{ width: "100%" }}
                        onValueChange={updateMobile}
                        value={mobile}
                        placeholder="Enter phone here"
                        invalid={sensitive ? false : !mobileValid}
                      />
                    </FormField>
                    <FormField label="Email" feedback="Please enter correct email">
                      <Input
                        type="email"
                        placeholder="Enter email here"
                        value={email}
                        onChange={updateEmail}
                        invalid={!emailValid}
                      />
                    </FormField>
                    <FormField label="Location">{customer.companyName}</FormField>
                    <FormField label="Status">
                      <HtmlSelectField
                        name="status"
                        value={status}
                        options={["ACTIVE", "IGNORED"]}
                        onChange={({ target }) => setStatus(target.value)}
                      />
                    </FormField>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Row>
                <Col className="d-flex flex-row align-items-center">
                  <h4 className="mb-0">Interactions</h4>
                  <Button color="primary" className="ms-auto" onClick={() => setInteractionAdding(true)}>
                    Add Interaction
                  </Button>
                </Col>
              </Row>
              <br />
              {interactionsPager}
              {query.isLoading
                ? Array.from({ length: 3 }).map((_, i) => <Interaction isLoading={true} key={i} />)
                : interactions.map(v => {
                  return (
                    <Row className="mt-4" key={v.id}>
                      <Col>
                        <Interaction
                          isLoading={query.isFetching}
                          interaction={v}
                          tagSet={tagSet}
                          onChangesCommitted={({ notes, representatives, tags, visitTime }) =>
                            updateInteraction({ notes, representatives, tags, visitTime })(v.id)
                          }
                        >
                          {(v.invitations || []).map(invitation => (
                            <Invitation
                              className="my-3"
                              key={invitation.id}
                              invitation={invitation}
                              reviews={v.reviews}
                            />
                          ))}
                          {!currentUserHasFeature(CAMPAIGNS) && (
                            <SendInviteButtons
                              color={v.invitations && v.invitations.length > 0 ? "light" : "primary"}
                              reviewLeadMobile={mobile}
                              reviewLeadEmail={email}
                              shouldConfirm={invitationConfirm}
                              confirmationPrompt={invitationConfirmPrompt}
                              onClick={(destinationType, type) => sendInvitation(v.id, destinationType, type)}
                            />
                          )}
                        </Interaction>
                      </Col>
                    </Row>
                  );
                })}
              {interactionsPager}
            </Col>
          </Row>
        )
      )}
    </div>
  );
};
