// uncomment this line if you have something to import from your scss module
import { join, size } from "lodash";
import { toPairs } from "ramda";
import Legend from "../Reviews/Legend/Legend";
import VideoActions from "../Invite/Entry/VideoActions";
import styles from "./VideoPlayer.module.scss";

const { legend, video, container } = styles;

export default function VideoPlayer({
  className,
  containerStyle,
  details,
  formats,
  downloadLink,
  previewSrc,
  autoplay = true,
  onRemove,
  onLoad,
  disabled
}) {
  return (
    <div className={join([container, className], " ")} style={containerStyle}>
      <div className={video}>
        <video src={previewSrc} controls={true} autoPlay={autoplay} onLoadedData={onLoad} />
      </div>
      {size(details) > 0 && (
        <div className={legend}>
          {toPairs(details || {}).map(([type, value]) => (
            <div key={`${type}:${value}`}>{type === "campaign" ? value : <Legend type={type} value={value} />}</div>
          ))}
        </div>
      )}
      <VideoActions disabled={disabled} onRemove={onRemove} formats={formats} downloadLink={downloadLink} />
    </div>
  );
}
