import * as types from "./actionTypes";
import * as customerApi from "../../api/customerApi";
import { initializeAgency } from "./agencyActions";
import logger from "../../util/logger";

export function loadCurrentContact() {
  return dispatch => {
    customerApi
      .loadContact()
      .then(
        response => response.data,
        error => logger.error("Failed loading contact: " + JSON.stringify(error))
      )
      .then(contact => {
        dispatch(initializeAgency(contact.agencies));
        dispatch(currentContact(contact));
      });
  };
}

export function currentContact(contact) {
  return {
    type: types.CURRENT_CONTACT_RECEIVE,
    contact
  };
}

export function currentContactActiveProducts(activeProducts) {
  return {
    type: types.CURRENT_CONTACT_ACTIVE_PRODUCTS,
    activeProducts
  };
}

/**
 * @param {{ [name: string]?: boolean | string | null }} feature
 **/
export function currentContactFeature(feature) {
  return {
    type: types.CURRENT_CONTACT_FEATURES,
    feature
  };
}
