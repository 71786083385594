import React, { useCallback, useState } from "react";
import { Button } from "reactstrap";
import InputField from "../../../components/Form/InputField";
import { useNativeInputState } from "../../../hooks/utilHooks";

export default class CMSMax {
  field() {
    return "cmsMaxInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info }) => {
  const [apiKey, setApiKey] = useState(info.apiKey);
  const [baseUrl, setBaseUrl] = useState(info.baseUrl);

  return (
    <>
      <InputField
        type="text"
        label="CMS MAX API key"
        placeholder="API key"
        onChange={e => setApiKey(e.target.value)}
        value={apiKey}
        readOnly={true}
      />
      <InputField
        type="text"
        label="API Base URL"
        placeholder="https://<customer>.cmsmax.com"
        onChange={e => setBaseUrl(e.target.value)}
        value={baseUrl}
        readOnly={true}
      />
    </>
  );
};

const Add = ({ onSave }) => {
  const [apiKey, setApiKey] = useNativeInputState("");
  const [baseUrl, setBaseUrl] = useNativeInputState("");
  const onLink = useCallback(
    () =>
      onSave({
        baseUrl,
        apiKey,
        source: "CMS_MAX"
      }),
    [baseUrl, apiKey, onSave]
  );

  return (
    <>
      <InputField type="text" label="CMS MAX API key" placeholder="API key" onChange={setApiKey} value={apiKey} />
      <InputField
        label="API Base URL"
        type="text"
        placeholder="https://<customer>.cmsmax.com"
        onChange={setBaseUrl}
        value={baseUrl}
      />
      <Button color="primary" disabled={!apiKey || !baseUrl} onClick={onLink}>
        Link
      </Button>
    </>
  );
};
