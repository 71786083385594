const BASE_LINK_URL = "https://support.google.com/business/answer/";

export const LISTINGS_LINKS = Object.freeze({
  locationName: `${BASE_LINK_URL}3038177#name`,
  locationCategory: `${BASE_LINK_URL}3038177#Categories`,
  description: `${BASE_LINK_URL}3038177#description`,
  phoneNumber: `${BASE_LINK_URL}3038177#websitephone`,
  website: `${BASE_LINK_URL}3038177#websitephone`,
  businessLocation: `${BASE_LINK_URL}3038177#address`,
  serviceArea: `${BASE_LINK_URL}9157481?hl=en&sjid=4891331225515154919-NA#zippy=%2Cadd-or-edit-a-service-area`,
  openingDate: `${BASE_LINK_URL}9174409?hl=en&sjid=4891331225515154919-NA`,
  servicesOffered: `${BASE_LINK_URL}9455399?hl=en-GB&sjid=4891331225515154919-NA`,
  hours: `${BASE_LINK_URL}3038177#hours`
});
