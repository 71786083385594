import moment from "moment";
import { omit } from "lodash";

const CUSTOM_DATE_RANGE = { startDate: moment().startOf("month"), endDate: moment() };
const TODAY_DATE_RANGE = { startDate: moment(), endDate: moment() };
const YESTERDAY_DATE_RANGE = {
  startDate: moment().subtract(1, "days"),
  endDate: moment().subtract(1, "days")
};
const LAST_WEEK_DATE_RANGE = {
  startDate: moment()
    .day(-1)
    .subtract(6, "days"),
  endDate: moment().day(-1)
};
const LAST_MONTH_DATE_RANGE = {
  startDate: moment()
    .startOf("month")
    .subtract(1, "month"),
  endDate: moment()
    .startOf("month")
    .subtract(1, "days")
};
const LAST_7_DAYS_DATE_RANGE = { startDate: moment().subtract(6, "days"), endDate: moment() };
const LAST_30_DAYS_DATE_RANGE = { startDate: moment().subtract(29, "days"), endDate: moment() };
const LAST_90_DAYS_DATE_RANGE = { startDate: moment().subtract(89, "days"), endDate: moment() };
const LAST_180_DAYS_DATE_RANGE = { startDate: moment().subtract(179, "days"), endDate: moment() };

export const datePickerOptions = {
  Custom: CUSTOM_DATE_RANGE,
  Today: TODAY_DATE_RANGE,
  Yesterday: YESTERDAY_DATE_RANGE,
  "Last Week": LAST_WEEK_DATE_RANGE,
  "Last Month": LAST_MONTH_DATE_RANGE,
  "Last 7 days": LAST_7_DAYS_DATE_RANGE,
  "Last 30 days": LAST_30_DAYS_DATE_RANGE,
  "Last 90 days": LAST_90_DAYS_DATE_RANGE,
  "Last 180 days": LAST_180_DAYS_DATE_RANGE
};

export const dateRangeOptions = omit(datePickerOptions, "Today", "Yesterday");
export const datePickerOptionsKeys = Object.keys(datePickerOptions);
export const dateRangeOptionsKeys = Object.keys(dateRangeOptions);

export const dropdownDatePickerOptions = [
  { label: "Today", value: TODAY_DATE_RANGE },
  { label: "Last Week", value: LAST_WEEK_DATE_RANGE },
  { label: "Last Month", value: LAST_MONTH_DATE_RANGE },
  { label: "Last 7 days", value: LAST_7_DAYS_DATE_RANGE },
  { label: "Last 30 days", value: LAST_30_DAYS_DATE_RANGE },
  { label: "Last 90 days", value: LAST_90_DAYS_DATE_RANGE },
  { label: "Last 180 days", value: LAST_180_DAYS_DATE_RANGE }
];
