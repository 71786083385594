import React, { useCallback, useState } from "react";
import { Button } from "reactstrap";
import InputField from "../../../components/Form/InputField";
import { useNativeInputState } from "../../../hooks/utilHooks";

export default class InspectionSupportAdminLinking {
  field() {
    return "inspectionSupportInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info }) => {
  const [companyKey, setCompanyKey] = useNativeInputState(info.companyKey);
  const [apiUrl, setApiUrl] = useNativeInputState(info.apiUrl);
  return (
    <>
      <InputField
        type="text"
        label="Company Key"
        placeholder="API key"
        onChange={e => setCompanyKey(e.target.value)}
        value={companyKey}
        readOnly={true}
      />
      <InputField
        type="text"
        label="API URL"
        onChange={e => setApiUrl(e.target.value)}
        value={apiUrl}
        readOnly={true}
      />
    </>
  );
};

const Add = ({ onSave }) => {
  const [companyKey, setCompanyKey] = useNativeInputState("");
  const [apiUrl, setApiUrl] = useNativeInputState("");
  const [accessKey, setAccessKey] = useNativeInputState("");
  const [secretKey, setSecretKey] = useNativeInputState("");
  const onLink = useCallback(
    () =>
      onSave({
        source: "INSPECTION_SUPPORT_NETWORK",
        apiUrl,
        companyKey,
        accessKey,
        secretKey
      }),
    [apiUrl, companyKey, accessKey, secretKey, onSave]
  );

  return (
    <>
      <InputField
        type="text"
        label="Company Key"
        placeholder="Company Key"
        onChange={setCompanyKey}
        value={companyKey}
      />
      <InputField label="API URL" type="text" placeholder="url.com" onChange={setApiUrl} value={apiUrl} />
      <InputField label="Access key" type="text" placeholder="Access key" onChange={setAccessKey} value={accessKey} />
      <InputField label="Secret key" type="text" placeholder="Secret key" onChange={setSecretKey} value={secretKey} />
      <Button color="primary" disabled={!apiUrl || !companyKey || !accessKey || !secretKey} onClick={onLink}>
        Link
      </Button>
    </>
  );
};
