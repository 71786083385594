import { valueOf } from "util/functionUtils";
import { useMemo } from "react";
import { find, isArray, isUndefined, noop } from "lodash";
import classNames from "classnames";
import WWChip from "components/WWChips/WWChip/WWChip";
import { CheckboxFilterBar } from "../CheckboxFilter/CheckboxFilter";
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown, UncontrolledTooltip } from "reactstrap";
import Checkbox from "components/Checkbox/Checkbox";
import { useFilterValueState, useToggleFilter } from "hooks/filteringHooks";

import styles from "./MultiSelectFilter.module.scss";

const MultiSelectFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <MultiSelectFilterBar key={props.name} className={className} {...props} />
  );
  const chip = ({ ...props }, className) => <MultiSelectChip key={props.name} {...props} className={className} />;
  const filterComponent = ({ ...props }, className) => (
    <MultiSelectFilterSidebar sidebar key={props.name} {...props} className={className} />
  );
  return {
    barComponent,
    chip,
    filterComponent
  };
};

export const MultiSelectFilterBar = ({
  className,
  name,
  label,
  itemToggle = true,
  icon,
  options,
  clearable = true,
  sidebar = false,
  ...props
}) => {
  const [value, setter] = useFilterValueState(name);
  const [isOpen, toggle] = useToggleFilter();
  const normalizedValue = useMemo(() => (Array.isArray(value) ? value : [value]), [value]);
  const normalizedOptions = useMemo(() => (Array.isArray(options) ? options : [options]), [options]);
  const displayValue = useMemo(
    () =>
      (normalizedValue &&
        normalizedValue.length > 0 &&
        normalizedOptions
          .filter(option => normalizedValue?.includes(option?.value))
          .map(option => option.label)
          .join(", ")) ||
      "Any",
    [normalizedValue, normalizedOptions]
  );

  const dropdownTargetName = label.replace(/\s+/g, "_");

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      className={classNames(styles.dropdownButton, "text-truncate flex-shrink-1", className)}
    >
      <DropdownToggle
        id={`MultiSelect-${dropdownTargetName}`}
        color="light"
        className={classNames(
          styles.dropdownButton,
          "flex-grow-1 d-flex fs-3 text-truncate text-capitalize flex-nowrap w-100"
        )}
      >
        <span className="text-muted me-2">{label}: </span>
        <span className="text-nowrap text-truncate">{displayValue}</span>
        <UncontrolledTooltip target={`MultiSelect-${dropdownTargetName}`} placement="top">
          {displayValue}
        </UncontrolledTooltip>
      </DropdownToggle>
      <DropdownMenu container="body">
        {clearable && (
          <DropdownItem toggle={false} onClick={() => setter()}>
            Clear
          </DropdownItem>
        )}
        {normalizedOptions.map(v => (
          <DropdownItem
            toggle={false}
            key={v.value}
            onClick={() =>
              setter(
                isArray(normalizedValue)
                  ? normalizedValue?.includes(v.value)
                    ? [normalizedValue.filter(option => option !== v.value)]
                    : [...normalizedValue, v.value]
                  : [v.value]
              )
            }
          >
            <div className="d-flex align-items-center">
              <Checkbox checked={normalizedValue && normalizedValue.indexOf(v.value) >= 0} onToggle={noop} />
              <span className="ps-2">{v?.label}</span>
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export const MultiSelectFilterSidebar = props => <CheckboxFilterBar {...props} sidebar />;

const getMultiSelectChipLabel = (options, value) => {
  const labelValue = find(options, { value })?.label;
  return isUndefined(labelValue) ? value : labelValue;
};

export const MultiSelectChip = ({ name, className, iconClass, defaultValue, options, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return value ? (
    Array.isArray(value) ? (
      value?.map(v => (
        <WWChip
          iconClass={iconClass}
          key={name + v}
          className="overflow-visible"
          onClick={() => setter(value.filter(chipValue => chipValue !== v))}
          {...props}
        >
          {getMultiSelectChipLabel(options, v)}
        </WWChip>
      ))
    ) : (
      <WWChip
        iconClass={iconClass}
        key={name}
        className="overflow-visible"
        onClick={() => setter(valueOf(defaultValue))}
        {...props}
      >
        {getMultiSelectChipLabel(options, value)}
      </WWChip>
    )
  ) : null;
};

export default MultiSelectFactory;
