import classNames from "classnames";
import { Badge } from "reactstrap";
import styles from "./RoundBadge.module.scss";

const RoundBadge = ({ children, color, small, className }) => (
  <Badge color={color} className={classNames(styles.roundBadge, { [styles.smallRoundBadge]: small }, className)}>
    {children}
  </Badge>
);

export default RoundBadge;
