import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import replace from "lodash/replace";
import trim from "lodash/trim";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import EmailButton from "../../components/Buttons/EmailButton";
import InputField from "../../components/Form/InputField";
import Paragraph from "../../components/Layout/Paragraph";
import CopyButton from "../../components/Buttons/CopyButton";
import WWTextDetails from "components/WWTextDetails/WWTextDetails";

const CARFAX_SITE_PATTERN = /https:\/\/www.carfax.com\/(Reviews|Dealer)-.+_([a-zA-Z0-9]+)/;

export function parseCarfax(event) {
  return parseUrl(event, CARFAX_SITE_PATTERN);
}

function parseUrl(event, pattern) {
  let url = event.target.value;
  if (isEmpty(url)) return;

  let match = url.match(pattern);
  if (!match && trim(url).length > 0) {
    return false;
  } else {
    return match[1];
  }
}

export function encodeUrlParameter(str) {
  return encodeURIComponent(str).replace(/%20/g, "+");
}

function buildTemplate(url, customer) {
  return `Hi Carfax team, 
Please map the R%26R's for the account below to Widewail to allow access for review response purposes.

${customer.companyName} - ${customer.fullAddress} - ${url}

Thank you all!`;
}

export const CarfaxUrlInputField = ({ value, onChange, valid, companyName }) => {
  return (
    <InputField
      inline={false}
      valid={valid}
      feedback={valid === false ? "Not a valid Carfax URL" : ""}
      tip="CTRL+C to copy and CTRL-V to paste"
      name="carfaxUrl"
      label={
        <span>
          Copy and paste the{" "}
          <a
            href={`https://www.google.com/search?q=site:www.carfax.com+${encodeUrlParameter(companyName)}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Carfax URL
          </a>{" "}
          for {companyName} below.
        </span>
      }
      placeholder="https://carfax.com/..."
      onChange={onChange}
      value={value}
    />
  );
};

export default function CarfaxLinking({ customer }) {
  const [carfaxInfo, setCarfaxInfo] = useState(get(customer, "carfaxInfo[0]", {}));
  const [emailBody, setEmailBody] = useState(buildTemplate(carfaxInfo?.carfaxUrl, customer));
  const [carfaxValid, setCarfaxValid] = useState(CARFAX_SITE_PATTERN.test(carfaxInfo?.carfaxUrl || ""));
  const currentUser = useSelector(state => state.cognito.user);
  const onboardingEmail = useSelector(state => state.currentAgency.onboardingEmail);
  const [toLine, setToLine] = useState(`dealersupport@carfax.com;${currentUser.email};${onboardingEmail};`);

  useEffect(() => {
    setCarfaxInfo(get(customer, "carfaxInfo[0]", {}));
  }, [customer]);

  useEffect(() => {
    setToLine(`dealersupport@carfax.com;${currentUser.email};${onboardingEmail};`);
  }, [currentUser.email, onboardingEmail]);

  useEffect(() => {
    setEmailBody(buildTemplate(carfaxInfo?.carfaxUrl, customer));
    setCarfaxValid(CARFAX_SITE_PATTERN.test(carfaxInfo?.carfaxUrl || ""));
  }, [carfaxInfo, customer]);

  return (
    <Container>
      <Row>
        <Col>
          <p>
            Click on the link below to search for your dealership&apos;s Carfax.com pages. Copy &amp; paste that URL
            below.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <CarfaxUrlInputField
            companyName={customer.companyName}
            onChange={evt => setCarfaxInfo(prev => ({ ...prev, carfaxUrl: trim(evt.target.value) }))}
            valid={carfaxValid}
            value={carfaxInfo?.carfaxUrl}
          />
        </Col>
      </Row>
      {carfaxValid && (
        <>
          <Row>
            <Col>
              <p>
                You must send an email to Carfax to request that Widewail be granted access to your account. Click{" "}
                <i>Send Email</i> to open a template email which you can use to request access. Send this email from the
                email address associated with your Carfax accounts.
              </p>
              <EmailButton to={toLine} subject="New Account Access" body={emailBody} />
            </Col>
          </Row>
          <WWTextDetails caption="Manual Instructions">
            <div>
              If the email button above is not opening your email client you can manually copy and paste the email
              below.
              <br />
              <br />
              <Row>
                <Col xs="auto">To:</Col>
                <Col>
                  <CopyButton>{toLine}</CopyButton>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">Subject:</Col>
                <Col>
                  <CopyButton>New Account Access</CopyButton>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col>
                  <code>
                    <CopyButton text={replace(emailBody, "%26", "&")}>
                      <Paragraph content={replace(emailBody, "%26", "&")} />
                    </CopyButton>
                  </code>
                </Col>
              </Row>
            </div>
          </WWTextDetails>
        </>
      )}
    </Container>
  );
}
