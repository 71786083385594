import { createSquareInfo } from "api/squareApi";
import { useErrorNotification } from "components/Notifications/notification";
import { useLocationQueryParamValue } from "hooks/stateHooks";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Col, Collapse, Row } from "reactstrap";
import { Card } from "../../components/Card";
import AjaxLoader from "../../components/Misc/AjaxLoader";
import StatusMessages from "../../components/Misc/StatusMessages";
import { LOCAL_STORAGE_KEYS } from "../../data/storageKeys";
import styles from "./OAuthResult.module.scss";

export const SquareOAuthResult = () => {
  const history = useHistory();
  const authorizationCode = useLocationQueryParamValue("code");
  const state = useLocationQueryParamValue("state");
  const awaitedRedirectCustomer = localStorage.getItem(LOCAL_STORAGE_KEYS.AWAITED_REDIRECT_CUSTOMER);
  const awaitedState = localStorage.getItem(LOCAL_STORAGE_KEYS.SQUARE_STATE);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const stateValid = useMemo(() => !!state && awaitedState === state, [state, awaitedState]);
  const errorNotify = useErrorNotification();

  useEffect(() => {
    if (!!authorizationCode && !!awaitedRedirectCustomer && stateValid) {
      setLoading(true);
      createSquareInfo(awaitedRedirectCustomer, {
        authorizationCode
      })
        .then(() => window.location.replace(`/customers/${awaitedRedirectCustomer}/siteIntegrations?site=SQUARE`))
        .catch(error => {
          errorNotify(error);
          setErrors(error.response?.data.errors);
        })
        .finally(() => setLoading(false));
    }
  }, [authorizationCode, history, awaitedRedirectCustomer, stateValid, errorNotify]);

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <Row>
        <Col xs={12} className="d-flex flex-column justify-content-center align-items-center mt-3">
          <Card className={styles.container}>
            {errors.length > 0 ? (
              <StatusMessages errors={errors} />
            ) : !authorizationCode || !stateValid ? (
              <>
                <p>Access not authorized. Please try again or contact support if the problem continues.</p>
              </>
            ) : !awaitedRedirectCustomer ? (
              <p>
                Authenticated to an unknown Widewail location. Please try again or contact support if the problem
                continues.
              </p>
            ) : (
              <>
                <Collapse isOpen={loading}>
                  <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                    <div className="mx-auto mb-3">
                      <AjaxLoader />
                    </div>
                    Please wait while we connect your location to Square.
                  </div>
                </Collapse>
              </>
            )}
            {awaitedRedirectCustomer && (
              <Link to={`/customers/${awaitedRedirectCustomer}/siteIntegrations?site=SQUARE`}>
                Back to Site Linking
              </Link>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
