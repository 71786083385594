import "./SmsSetup.scss";

import { Button, Col, Collapse, FormGroup, InputGroup, Row } from "reactstrap";
import Input from "../../../components/Form/Input";
import { Card, CardBody, CardTitle } from "../../../components/Card";
import React, { useEffect, useMemo, useState } from "react";
import TollFree from "../../../components/Customer/SmsSetup/TollFree/TollFree";
import ExistingPhoneBrand from "../../../components/Customer/SmsSetup/ExistingPhoneBrand/ExistingPhoneBrand";
import NewPhoneBrand from "../../../components/Customer/SmsSetup/NewPhoneBrand/NewPhoneBrand";
import OrderList from "../../../components/Customer/SmsSetup/OrderList/OrderList";
import { useCustomerInviteConfiguration, useCustomerSmsOrders } from "../../../hooks/customerHooks";
import size from "lodash/size";
import { not } from "ramda";
import AjaxLoader from "../../../components/Misc/AjaxLoader";

export default function SmsSetup({ customer, contacts }) {
  const [flow, setFlow] = useState("TOLL");
  const [configuration] = useCustomerInviteConfiguration(customer.id);
  const { data: orders, status, isLoading } = useCustomerSmsOrders(customer.id);
  const [toggleOrderForm, setToggleOrderForm] = useState(false);

  useEffect(() => {
    if (status === "success") {
      setToggleOrderForm(size(orders) === 0);
    }
  }, [status, orders]);

  const previousOrder = useMemo(() => {
    if (orders) {
      // Select the most recent order with a valid EIN.
      const candidates = orders.filter(order => order?.brand?.ein);
      return candidates[candidates.length - 1];
    } else {
      return undefined;
    }
  }, [orders]);

  return (
    <Row className="d-flex justify-content-center sms-container">
      {isLoading && <AjaxLoader />}
      <Col xs={12} md={7}>
        {size(orders) > 0 && (
          <Card>
            <CardTitle>
              Orders
              <Button
                color="link"
                onClick={() =>
                  window.open(
                    "https://widewail.atlassian.net/wiki/spaces/OS/pages/1015809/SMS+Setup+for+Invite+Accounts",
                    "_blank"
                  )
                }
              >
                Help...
              </Button>
            </CardTitle>
            <CardBody>
              <OrderList customer={customer} orders={orders} />
              {!toggleOrderForm && (
                <Button color="link" onClick={() => setToggleOrderForm(not)}>
                  Create New Order
                </Button>
              )}
            </CardBody>
          </Card>
        )}
        <Collapse isOpen={toggleOrderForm}>
          <Card>
            <CardTitle>SMS Setup</CardTitle>
            <CardBody>
              <FormGroup className="py-2" check>
                <InputGroup>
                  <Input
                    type="radio"
                    name="purchase-#-type"
                    onChange={() => setFlow("TOLL")}
                    checked={flow === "TOLL"}
                  />
                  <span>Toll Free</span>
                </InputGroup>
                <InputGroup>
                  <Input
                    type="radio"
                    name="purchase-#-type"
                    onChange={() => setFlow("EXIST_BRAND")}
                    checked={flow === "EXIST_BRAND"}
                  />
                  <span>Existing Brand</span>
                </InputGroup>
                <InputGroup>
                  <Input
                    type="radio"
                    name="purchase-#-type"
                    onChange={() => setFlow("NEW_BRAND")}
                    checked={flow === "NEW_BRAND"}
                  />
                  <span>New Brand</span>
                </InputGroup>
              </FormGroup>
              <hr />
              {(flow === "TOLL" && <TollFree customerId={customer.id} />) ||
                (flow === "EXIST_BRAND" && <ExistingPhoneBrand customer={customer} />) ||
                (flow === "NEW_BRAND" && (
                  <NewPhoneBrand
                    customer={customer}
                    contacts={contacts}
                    previousOrder={previousOrder}
                    initSample={configuration.config.smsMessage}
                  />
                ))}
            </CardBody>
          </Card>
        </Collapse>
      </Col>
    </Row>
  );
}
