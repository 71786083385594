import { permissions } from "components/Auth/permissions";
import WWButton from "components/Buttons/WWButton";
import { Card, CardBody, CardHeader, CardTitle } from "components/Card";
import Time from "components/DateTime/Time";
import Block from "components/Layout/Block";
import { groupBy, isEmpty } from "lodash";
import { useState } from "react";
import AdminProductSet from "./AdminProductSet";
import ProductActivationDeactivationModel from "./ProductActivationDeactivationModal";
import { productsSet } from "data/products";
import withAuthorization from "components/Auth/Authorization";

import styles from "./Products.module.scss";
import classNames from "classnames";
const { removeButton, scheduledListContainer } = styles;

const ProductsCard = ({ updateCustomerProducts, customer, updateCustomer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const customerProducts = customer?.products || [];

  const removeProductDate = (productName, type) => {
    updateCustomer(
      "products",
      customerProducts.map(product => (product.product === productName ? { ...product, [type]: undefined } : product))
    );
  };

  return (
    <Block>
      <Card>
        <CardHeader>
          <CardTitle>Products</CardTitle>
          {!isEmpty(customerProducts) && (
            <div>
              <WWButton className="ms-2" size="sm" iconClass="fa fa-clock-o" onClick={() => setIsOpen(true)}>
                Schedule
              </WWButton>
            </div>
          )}
        </CardHeader>
        <CardBody>
          <div className={scheduledListContainer}>
            <ProductsSetForSchedule
              type="scheduledActivationDate"
              removeProductDate={removeProductDate}
              customerProducts={customerProducts}
              title="Activation scheduled for"
            />
            <ProductsSetForSchedule
              type="scheduledDeactivationDate"
              removeProductDate={removeProductDate}
              customerProducts={customerProducts}
              title="Deactivate scheduled for"
            />
          </div>
          <AdminProductSet onUpdate={updateCustomerProducts} customer={customer} />
        </CardBody>
      </Card>
      {isOpen && (
        <ProductActivationDeactivationModel
          onUpdate={updateCustomer}
          customerProducts={customerProducts}
          isOpen={isOpen}
          toggle={() => setIsOpen(prev => !prev)}
        />
      )}
    </Block>
  );
};

const ProductsSetForSchedule = ({ customerProducts, title, removeProductDate, type }) => {
  const productsSetForSchedule = Object.entries(
    groupBy(
      customerProducts.filter(p => p[type]),
      type
    )
  );
  return (
    <>
      {productsSetForSchedule.map(([date, products]) => (
        <div key={`active${date}`}>
          <span className="fs-2">
            {title} <Time format="dateSlash" withIcon={false} date={date} />
          </span>
          <ul>
            {products.map(p => (
              <li key={p.product} className="fs-2">
                {productsSet.find(product => product.product === p.product)?.label}{" "}
                <i
                  className={classNames("fa fa-close text-danger", removeButton)}
                  onClick={() => removeProductDate(p.product, type)}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default withAuthorization(permissions.AGENCY_ADMIN)(ProductsCard);
