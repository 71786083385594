import React from "react";
import startsWith from "lodash/startsWith";
import Label from "../../components/Form/Label";
import Time from "../../components/DateTime/Time";

export default function SiteInfoImportStatus({ info }) {
  if (!info || !info.importStatus) return <></>;

  const importStatusClass = startsWith(info.importStatus, "ERROR") ? "text-danger" : "text-success";

  return (
    <>
      <Label className={"me-2 fw-bold " + importStatusClass}>{info.importStatus ? info.importStatus : "OK"}</Label>
      <Time withIcon={false} format="ago" date={info.lastImportSuccess} />
      {" ago"}
    </>
  );
}
