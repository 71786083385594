import { roundToOne } from "../roundToOne";

export const tagsChartDataMapper = (data, removedValues = []) => {
  const dataMap = {};
  const dots = [];
  data
    .filter(dataPoint => !removedValues.includes(dataPoint.value))
    .forEach(
      ({
        count,
        averageRating,
        value,
        positiveSentimentCount,
        negativeSentimentCount,
        neutralSentimentCount,
        unknownSentimentCount
      }) => {
        const roundedRating = roundToOne(averageRating);
        const sentiments = {
          positiveSentimentCount,
          negativeSentimentCount,
          neutralSentimentCount,
          unknownSentimentCount
        };
        if (dataMap[roundedRating]) {
          if (dataMap[roundedRating][count]) {
            dataMap[roundedRating][count].push({ value, sentiments });
          } else {
            dataMap[roundedRating][count] = [{ value, sentiments }];
          }
        } else {
          dataMap[roundedRating] = { [count]: [{ value, sentiments }] };
        }
      }
    );
  Object.keys(dataMap).forEach(averageRatingKey => {
    Object.keys(dataMap[averageRatingKey]).forEach(countKey =>
      dots.push({ x: Number(countKey), y: Number(averageRatingKey), meta: dataMap[averageRatingKey][countKey] })
    );
  });
  return dots;
};
