import { isEmpty, pick, isNull } from "lodash";
import moment from "moment";
import { assoc } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormFeedback, UncontrolledTooltip } from "reactstrap";
import Input from "../../../../../components/Form/Input";
import DateTimeSelector from "../../../../../components/DateTime/DateTimeSelector";
import Form from "../../../../../components/Form/Form";
import Switch from "../../../../../components/Form/Switch";
import TextEditor from "../../../../../components/Form/TextEditor";
import { HashNavigationItem } from "../../../../../components/Modals/NavigableModal/NavigationItem/NavigationItem";
import Page from "../../../../../components/Modals/NavigableModal/Page/Page";
import StatusBarContainer from "../../../../../components/Modals/NavigableModal/StatusBarContainer/StatusBarContainer";
import GenericContentSkeleton from "../../../../../components/Skeleton/GenericContentSkeleton/GenericContentSkeleton";
import { useCampaignUpdate } from "../../../../../hooks/data/campaignHooks";
import { useStateThroughRedux } from "../../../../../hooks/stateHooks";
import { AutoreplyMessaging } from "../../../Configuration/AutoreplyMessaging";
import InvitePageSiteOrder from "../../../Configuration/InvitePageSiteOrder";
import RichEditor from "../../../../../components/RichEditor/RichEditor";
import TextSkeleton from "../../../../../components/Skeleton/TextSkeleton/TextSkeleton";
import { useAdditionalPlaceholders } from "hooks/customerHooks";
import InputArray from "components/Form/InputArray/InputArray";
import WWButton from "components/Buttons/WWButton";
import { useLocalNotifications } from "components/Notifications/notification";
import MediaCard from "views/Listings/ListingsEdit/Forms/MediaCard";
import { MB } from "constants/filesize";
import {
  staffNotificationPlaceholders,
  noStaffNotificationPlaceholders,
  noInteractionNotificationPlaceholders
} from "data/editorPlaceholders";
import LockedField from "components/LockedField/LockedField";
import { INVITE_LANDING_PAGE_PREFIX } from "util/constants";

const titlesLabels = ["Contact First Name", "Contact Name"];
const customFieldLabel = ["Custom Field"];

const MAX_FILE_SIZE = 2 * MB;

const createAccordionPlaceholders = (labels, placeholders, noPrefix = false) => {
  if (!isEmpty(placeholders)) {
    return labels.map(label =>
      Object.assign(
        {},
        {
          label: label,
          placeholders: placeholders?.map(placeholder =>
            Object.assign(
              {},
              { label: placeholder, value: noPrefix ? placeholder : label.toUpperCase() + ":" + placeholder }
            )
          )
        }
      )
    );
  } else return [];
};

const isShortCodeValid = (shortCode = "") => {
  if (shortCode.length < 2) return false;
  if (shortCode.length > 255) return false;
  if (shortCode.match(/[^a-z0-9]/gi)) return false;

  return true;
};

export const CampaignConfig = ({ title, data, campaignId, resetCampaignId }) => {
  const [config, setConfig] = useState();
  const [incentive, setIncentive] = useState();
  const [shortCode, setShortCode] = useState();
  const [updatedFields, setUpdatedFields] = useState([]);
  const [campaign, setCampaign] = useState();
  const [, setDatePickerOpen] = useState(false);
  const [validText, setValidText] = useState(true);
  const [settingsSection, setSettingsSection] = useStateThroughRedux("campaignModal/section", undefined);
  const [demoVideo, setDemoVideo] = useState();
  const [customLogo, setCustomLogo] = useState();
  const [, setSettingsOpened] = useStateThroughRedux("campaignModal/opened");

  const notify = useLocalNotifications();

  const placeholderInfo = useAdditionalPlaceholders(data?.customer.id);

  const accordionPlaceholders = useMemo(
    () =>
      createAccordionPlaceholders(titlesLabels, placeholderInfo.data?.titles)?.concat(
        createAccordionPlaceholders(customFieldLabel, placeholderInfo.data?.customFields, true)
      ),
    [placeholderInfo]
  );

  const updateCampaign = useCampaignUpdate(campaignId);

  const invalidDelayDays = config?.delayDays > 60 || config?.delayDays < 0,
    invalidConfig = invalidDelayDays || !validText || !isShortCodeValid(shortCode),
    archivedCampaign = campaign?.status === "ARCHIVED";

  useEffect(() => {
    if (campaignId === undefined && settingsSection === "config") {
      setSettingsOpened(false);
    }
  }, [campaignId]);
  useEffect(() => {
    setConfig(data?.configuration);
  }, [data]);
  useEffect(() => {
    setShortCode(data?.shortCode);
  }, [data?.shortCode]);
  useEffect(() => {
    setIncentive(data?.incentive);
  }, [data]);
  useEffect(() => {
    setUpdatedFields(data?.customizedFields || []);
  }, [data]);
  useEffect(() => {
    setCampaign(pick(data, ["startDate", "endDate", "type", "status"]));
  }, [data]);
  useEffect(() => {
    setDemoVideo(data?.demoVideo);
  }, [data]);
  useEffect(() => {
    setCustomLogo(data?.logo);
  }, [data]);
  const save = useCallback(() => {
    // new object containing only modified fields
    const update = updatedFields.reduce((acc, field) => Object.assign(acc, { [field]: config[field] }), {});
    return updateCampaign.mutateAsync({
      id: campaignId,
      type: data?.type,
      name: title,
      configuration: update,
      shortCode: shortCode,
      incentive,
      ...campaign
    });
  }, [updatedFields, title, incentive, campaign, shortCode]);

  const saveAndClose = useCallback(() => {
    save().then(resetCampaignId);
  }, [save, resetCampaignId]);

  const saveAndSend = useCallback(() => {
    save().then(() => setSettingsSection("send"));
  }, [save, setSettingsSection]);

  const setConfigHandler = useCallback(
    field => value => {
      setConfig(prev => ({ ...prev, [field]: value }));
      setUpdatedFields(prev => (prev.indexOf(field) === -1 ? [...prev, field] : [...prev]));
    },
    []
  );

  const onFileValidation = useCallback(event => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      notify({
        icon: "danger",
        body: "The file is too large"
      });
    } else if (!/^image\/.+$/.test(file.type)) {
      notify({
        icon: "danger",
        body: "The file is not an image"
      });
    } else {
      return file;
    }
  }, []);

  const toggleIncentive = useCallback(event => {
    if (event.target.checked) {
      setIncentive({ incentiveConfiguration: { type: "prizePool", refreshRateType: "X_DAYS" } });
    } else {
      setIncentive(undefined);
    }
  }, []);

  return (
    <Page id="config">
      <div className="p-4 pe-md-5 flex-grow-1">
        {!config && <GenericContentSkeleton groupsAmount={2} />}
        {!!config && (
          <>
            <div className="mb-5">
              <h4 id="delivery-rules">Delivery Rules</h4>
              <p>
                Invites are sent between 10am - 6pm local time. Any invites received by Widewail outside of these hours
                will send at the next available time.
              </p>
              <div>
                {/* <HorizontalSettingsLabel label="Expiration Date">
                  <div>
                    <DateTimeSelector
                      date={config?.expirationDate ? new Date(config?.expirationDate + " ") : undefined}
                      onChange={changeDate}
                      format="date"
                      showTime={false}
                      emptyDateText="Pick a Date"
                    />
                  </div>
                </HorizontalSettingsLabel>
                <hr className="border-light m-1" /> */}
                {data?.type !== "VIDEO_FOLLOW_UP" && (
                  <>
                    <HorizontalSettingsLabel
                      label="Days to Delay Invite Send"
                      tooltip="if set to 0 invites will be sent out ASAP"
                    >
                      <Input type="hidden" invalid={config?.delayDays > 60 || config?.delayDays < 0} />
                      <FormFeedback>Delay cannot be grater than 60 days.</FormFeedback>
                      <Input
                        name="delay"
                        type="number"
                        className="d-inline my-0 mx-2 p-1 form-control"
                        style={{ width: "3rem" }}
                        min="0"
                        max="60"
                        step="1"
                        value={config?.delayDays}
                        onChange={event => setConfigHandler("delayDays")(event.target.value)}
                      />
                    </HorizontalSettingsLabel>
                    <hr className="border-light m-1" />
                  </>
                )}
                <HorizontalSettingsLabel label="Send SMS Invites">
                  <Switch
                    name="send-sms-invites"
                    checked={config?.smsEnabled}
                    onChange={event => setConfigHandler("smsEnabled")(event.target.checked)}
                    className="my-0"
                  />
                </HorizontalSettingsLabel>
                <hr className="border-light m-1" />
                <HorizontalSettingsLabel label="Send Email Invites">
                  <Switch
                    name="send-email-invites"
                    checked={config?.emailEnabled}
                    onChange={event => setConfigHandler("emailEnabled")(event.target.checked)}
                    className="my-0"
                  />
                </HorizontalSettingsLabel>
              </div>
            </div>
            <div className="mb-5">
              <h4 id="messaging">Messaging</h4>
              <p>"Reply STOP to opt-out" will be added to all SMS messages automatically.</p>
              <h5>
                Primary SMS Messaging <i id="recommended-SMS-messaging-info" className="fa fa-info-circle ms-2" />
                <UncontrolledTooltip target="recommended-SMS-messaging-info">
                  Personalizing content for your customers can increase engagement. Using "Placeholders", you can input
                  personalized tokens into SMS review requests. This information will automatically be pulled from the
                  CRM be Widewail. This is the Widewail recommended template. Edit the text here to update
                  communications for all future customers.
                </UncontrolledTooltip>
              </h5>
              <TextEditor
                value={config?.primarySmsMessage}
                onChange={setConfigHandler("primarySmsMessage")}
                onValidChange={setValidText}
                accordionPlaceholders={accordionPlaceholders}
                placeholders={staffNotificationPlaceholders}
              />
              <h5 className="text-dark mt-4">
                Default SMS Messaging <i id="alternative-messging-info" className="fa fa-info-circle ms-2" />
                <UncontrolledTooltip target="alternative-messging-info">
                  If any of the placeholders in the Primary SMS Messagaging are not available the Default SMS Message
                  will be used.
                </UncontrolledTooltip>
              </h5>
              <TextEditor
                value={config?.defaultSmsMessage}
                onChange={setConfigHandler("defaultSmsMessage")}
                onValidChange={setValidText}
                placeholders={noStaffNotificationPlaceholders}
              />
              <h5>
                Primary Email Messaging <i id="recommended-email-messaging-info" className="fa fa-info-circle ms-2" />
                <UncontrolledTooltip target="recommended-email-messaging-info">
                  Personalizing content for your customers can increase engagement. Using "Placeholders", you can input
                  personalized tokens into Email review requests. This information will automatically be pulled from the
                  CRM be Widewail. This is the Widewail recommended template. Edit the text here to update
                  communications for all future customers.
                </UncontrolledTooltip>
              </h5>
              <TextEditor
                value={config?.primaryEmailMessage}
                onChange={setConfigHandler("primaryEmailMessage")}
                onValidChange={setValidText}
                accordionPlaceholders={accordionPlaceholders}
                placeholders={staffNotificationPlaceholders}
              />
              <h5 className="text-dark mt-4">
                Default Email Messaging <i id="alternative-messging-info" className="fa fa-info-circle ms-2" />
                <UncontrolledTooltip target="alternative-messging-info">
                  If any of the placeholders in the Primary Email Messagaging are not available the Default Email
                  Message will be used.
                </UncontrolledTooltip>
              </h5>
              <TextEditor
                value={config?.defaultEmailMessage}
                onChange={setConfigHandler("defaultEmailMessage")}
                onValidChange={setValidText}
                placeholders={noStaffNotificationPlaceholders}
              />
              <AutoreplyMessaging
                messageText={config?.inboundAutoreplyMessage}
                onTextChange={setConfigHandler("inboundAutoreplyMessage")}
                enabled={config?.inboundAutoreplyEnabled}
                onToggled={setConfigHandler("inboundAutoreplyEnabled")}
              />
            </div>
            <div className="mb-5">
              <h4 id="landing-page">Landing Page</h4>
              <h5>Landing Page Url</h5>
              <p>
                This is the url that recipients of this Campaign will see. A unique ID has been created but it may be
                changed. The domain for this url cannot be changed.
              </p>
              <p>
                Changing this url will invalidate all previous invites for this campaign. It is not recommended to
                change this active or previously active campaigns.
              </p>
              <div className="mb-5">
                <LockedField
                  lockedComponent={
                    <b>
                      {INVITE_LANDING_PAGE_PREFIX}
                      {shortCode}
                    </b>
                  }
                >
                  <Input type="hidden" invalid={!isShortCodeValid(shortCode)} />
                  <span>
                    <b>{INVITE_LANDING_PAGE_PREFIX}</b>
                    <Input
                      name="shortCode"
                      value={shortCode}
                      style={{ width: "10rem" }}
                      onChange={event => setShortCode(event.target.value)}
                      className="my-0 d-inline"
                      minLength="2"
                      maxLength="255"
                    />
                  </span>
                  <FormFeedback>
                    Must be 2-255 characters in length and can only contain letters and numbers.
                  </FormFeedback>
                </LockedField>
              </div>

              <h5>Logo Image</h5>
              <p>
                Upload an image to serve as the logo for this campaign. If a file is not uploaded, the logo uploaded on
                your location page will be used.
              </p>
              <div className="mb-5">
                <MediaCard
                  name="Company Logo"
                  helperText="The file should be an image less than 2MB."
                  showMedia={!!config.logoUrl}
                  media={customLogo}
                  processingUploadText="You may save and close the Campaign while the image finishes processing."
                  preProcessingCallback={onFileValidation}
                  onSuccessCallback={media => {
                    setCustomLogo(media?.[0]);
                    setConfigHandler("logoUrl")(media?.[0]?.targetUrl);
                  }}
                  onFailCallback={() => {
                    setCustomLogo(null);
                    return notify({
                      icon: "danger",
                      body: "There was an error uploading the image"
                    });
                  }}
                  inputName="logoUrl"
                  value={config.logoUrl}
                  deleteCallback={() => {
                    setCustomLogo();
                    setConfigHandler("logoUrl")(null);
                  }}
                />
              </div>
              {data?.type !== "REVIEW" && (
                <>
                  <h5>Demo Video</h5>
                  <p>
                    This is the sample video your customers will see when they are invited to leave a video review. We
                    recommend recording the video in vertical orientation with your phone and keeping it less than 30
                    seconds.
                  </p>
                  <div className="mb-4">
                    <MediaCard
                      name="Custom Video"
                      showMedia={!!demoVideo}
                      showInput={!demoVideo}
                      isVideo={true}
                      media={demoVideo}
                      processingUploadText="You may save and close the Campaign while the video finishes processing."
                      preProcessingCallback={e => e.target.files[0]}
                      onSuccessCallback={media => {
                        setDemoVideo(media?.[0]);
                        setConfigHandler("sampleVideoMediaId")(media?.[0].id);
                      }}
                      onFailCallback={() => {
                        setDemoVideo(null);
                        return notify({
                          icon: "danger",
                          body: "There was an error uploading the video"
                        });
                      }}
                      deleteCallback={() => {
                        setConfigHandler("sampleVideoMediaId")(null);
                        setDemoVideo(null);
                      }}
                      inputName="videoUpload"
                    />
                  </div>
                  <h5>
                    Video Bullet Points <i id="video-bullet-point-info" className="fa fa-info-circle ms-2" />
                    <UncontrolledTooltip target="video-bullet-point-info">
                      These Bullet points will show below the demo video
                    </UncontrolledTooltip>
                    <WWButton size="sm" color="link" onClick={() => setConfigHandler("bulletPoints")(null)}>
                      Reset Bullet Points
                    </WWButton>
                  </h5>
                  <div className="mb-4">
                    {isNull(config?.bulletPoints) && "Bullet points will be reset to default after saving"}
                    <InputArray
                      name="videoBulletPoints"
                      placeholder="Bullet point"
                      values={config?.bulletPoints || []}
                      onChange={setConfigHandler("bulletPoints")}
                      maxArrayLength={10}
                      addMoreText="Add Bullet Point"
                      bulletPoints
                    />
                  </div>
                </>
              )}
              <h5>
                Primary Landing Page Text <i id="landing-page-text-info" className="fa fa-info-circle ms-2" />
                <UncontrolledTooltip target="landing-page-text-info">
                  This is the text the user will see when they follow the link from the invitation.
                </UncontrolledTooltip>
              </h5>
              <TextEditor
                value={config?.primaryLandingPageText}
                onChange={setConfigHandler("primaryLandingPageText")}
                onValidChange={setValidText}
                accordionPlaceholders={accordionPlaceholders}
                placeholders={staffNotificationPlaceholders}
              />
              <h5>
                Default Landing Page Text
                <i id="alternative-landing-page-text-info" className="fa fa-info-circle ms-2" />
                <UncontrolledTooltip target="alternative-landing-page-text-info">
                  If any of the placeholders in the Primary Landing Page Text are not available the Default Landing Page
                  Text will be used.
                </UncontrolledTooltip>
              </h5>
              <TextEditor
                value={config?.defaultLandingPageText}
                onChange={setConfigHandler("defaultLandingPageText")}
                onValidChange={setValidText}
                placeholders={noInteractionNotificationPlaceholders}
              />
            </div>
            {data?.type !== "VIDEO_FOLLOW_UP" && (
              <div>
                <h4 id="review-sites">Review Sites</h4>
                <p>Manage which sites customers can leave reviews and add custom review sites</p>
                {data && <InvitePageSiteOrder data={data} config={config} setConfig={setConfigHandler} />}
              </div>
            )}
            <div className="mb-5">
              <h4 id="landing-page">Start/Stop Dates</h4>
              <p>
                Optionally set dates to start/stop this campaign. Campaigns outside of these dates will not show up in
                campaign selectors or be matched in automated campaigns. This can be useful when combined with an
                incentive that should only run during certain dates.
              </p>
              <HorizontalSettingsLabel label="Start Date">
                <DateTimeSelector
                  date={campaign.startDate?.toDate()}
                  onChange={date => setCampaign(assoc("startDate", date ? moment(date) : undefined))}
                  format="date"
                  showTime={false}
                  emptyDateText="Start On..."
                />
              </HorizontalSettingsLabel>
              <HorizontalSettingsLabel label="End Date">
                <DateTimeSelector
                  date={campaign.endDate?.toDate()}
                  onChange={date => setCampaign(assoc("endDate", date ? moment(date) : undefined))}
                  format="date"
                  showTime={false}
                  emptyDateText="End On..."
                />
              </HorizontalSettingsLabel>
              <hr className="border-light m-1" />
            </div>
            {data?.type !== "REVIEW" && (
              <div>
                <h4 id="incentive-engine">Incentive</h4>
                <p>Incentivize your customers to submit videos by offering prizes.</p>
                <HorizontalSettingsLabel label="Run an Incentive Campaign">
                  <Switch name="enable-incentive" checked={!!incentive} onChange={toggleIncentive} className="my-0" />
                </HorizontalSettingsLabel>
                <hr className="border-light m-1" />
                {!!incentive && (
                  <>
                    <Form state={incentive} setState={setIncentive}>
                      {incentive.incentiveConfiguration.type === "prizePool" ? (
                        <PrizePoolIncentive />
                      ) : incentive.type === "sweepstakes" ? null : null}
                    </Form>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <CampaignConfigBar
        invalid={invalidConfig}
        archived={archivedCampaign}
        inactive={data?.customer?.status !== "ACTIVE"}
        close={resetCampaignId}
        saveAndClose={saveAndClose}
        saveAndSend={saveAndSend}
      />
    </Page>
  );
};

export const CampaignConfigNav = ({ type }) => {
  return (
    <>
      <HashNavigationItem href="#delivery-rules">Delivery Rules</HashNavigationItem>
      <HashNavigationItem href="#messaging">Messaging</HashNavigationItem>
      <HashNavigationItem href="#landing-page">Landing Page</HashNavigationItem>
      {type ? (
        <>
          {type !== "VIDEO_FOLLOW_UP" && <HashNavigationItem href="#review-sites">Sites</HashNavigationItem>}
          {type !== "REVIEW" && <HashNavigationItem href="#incentive-engine">Incentive Engine</HashNavigationItem>}
        </>
      ) : (
        <TextSkeleton rows={1} />
      )}
    </>
  );
};

const CampaignConfigBar = ({ invalid, archived, inactive, saveAndClose, saveAndSend, close }) => {
  return (
    <StatusBarContainer>
      <div className="d-flex justify-content-end py-2">
        {archived && <span className="p-1">This campaign is archived</span>}
        <WWButton onClick={close} color="light" className="bg-white me-2">
          Cancel
        </WWButton>
        <WWButton disabled={invalid} onClick={saveAndClose} color="light" className="bg-white me-2">
          Save &amp; Close
        </WWButton>
        <WWButton disabled={invalid || archived || inactive} onClick={saveAndSend} color="primary">
          Save &amp; Send
        </WWButton>
      </div>
    </StatusBarContainer>
  );
};

const HorizontalSettingsLabel = ({ label, tooltip, children }) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center mt-4">
      <div className="text-muted">
        {label}
        {tooltip && (
          <>
            <i id={convertStringToCssClass(tooltip)} className="fa fa-info-circle ms-2" />
            <UncontrolledTooltip target={convertStringToCssClass(tooltip)}>{tooltip}</UncontrolledTooltip>
          </>
        )}
      </div>
      {children}
    </div>
  );
};

const PrizePoolIncentive = (incentive, setIncentive) => (
  <>
    <div className="mb-3">
      <h5>Prize pool settings</h5>
      <p>Prize pool incentives allow a set number of winners every set amount of time.</p>
      <Input
        name="incentiveConfiguration.poolSize"
        type="number"
        className="d-inline my-0 mx-2 p-1 form-control"
        style={{ width: "6rem" }}
        min="0"
        step="1"
      />
      {" winners every "}
      <Input
        name="incentiveConfiguration.refreshRate"
        type="number"
        className="d-inline my-0 mx-2 p-1 form-control"
        style={{ width: "6rem" }}
        min="0"
        step="1"
      />
      {" days."}
    </div>
    <div className="mb-3">
      <h5>Post-submission Instructions</h5>
      <p>After a user has uploaded a video these are the instructions they will follow to claim the prize.</p>
      <RichEditor name="incentiveConfiguration.postSubmissionInstructions" />
    </div>
    <div className="mb-3">
      <h5>Try Again Later</h5>
      <p>If a user uploads a video, but the prize pool is empty, they'll be shown this message.</p>
      <RichEditor name="incentiveConfiguration.tryAgainLater" />
    </div>
  </>
);

const convertStringToCssClass = tooltip =>
  tooltip.toLowerCase().replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~\s]/g, "-");
