import { Card } from "components/Card";
import WidgetCard from "./WidgetCard";
import styles from "./MultiWidgetCard.module.scss";

const { cardClass, cardItemClass, cardItemBodyClass } = styles;

export const MultiWidgetCardItem = ({ children, heading, headingInfo }) => (
  <WidgetCard
    rigid={false}
    className={cardItemClass}
    bodyClassName={cardItemBodyClass}
    heading={heading}
    headingInfo={headingInfo}
  >
    {children}
  </WidgetCard>
);

const MultiWidgetCard = ({ children }) => <Card className={cardClass}>{children}</Card>;

export default MultiWidgetCard;
