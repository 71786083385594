import * as types from "../actions/actionTypes";
import findIndex from "lodash/findIndex";

export const initialAppState = {
  init: false,
  scripts: [],
  messages: []
};

export default function app(state = initialAppState, action) {
  switch (action.type) {
    case types.APP_INIT_SUCCESS:
      return Object.assign({}, state, { init: true });

    case types.APP_SET_VERSION:
      return Object.assign({}, state, { version: action.version });

    case types.APP_SCRIPT_LOADED:
      return Object.assign({}, state, {
        scripts: [action.script, ...state.scripts]
      });

    case types.APP_ADD_MESSAGE:
      if (findIndex(state.messages, m => m.key === action.message.key) < 0) {
        return Object.assign({}, state, {
          messages: [...state.messages, action.message]
        });
      }
      return state;

    case types.APP_CLEAR_MESSAGES:
      state.messages.forEach(m => m.onDismiss && m.onDismiss());
      return Object.assign({}, state, { messages: [] });

    default:
      return state;
  }
}
