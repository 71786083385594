import React, { useCallback } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import replace from "lodash/replace";
import WWButton from "./WWButton";

//encodeURIComponent was creating emails with +'s on IE
const encode = txt => replace(replace(txt, /(\r\n)|(\r)|(\n)/g, "%0D%0A"), /\s/g, "%20");
const opener = url => () => window.open(url, "_blank");

export default function EmailButton({ to, subject = "", body, bcc = "", cc = "" }) {
  const sendDefault = useCallback(opener(`mailto:${to}?subject=${encode(subject)}&body=${encode(body)}`), [
    to,
    subject,
    body,
    bcc,
    cc
  ]);

  const sendGmail = useCallback(
    opener(
      `https://mail.google.com/mail/?view=cm&fs=1&to=${to}&su=${encode(subject)}&bcc=${bcc}&cc=${cc}&body=${encode(
        body
      )}`
    ),
    [to, subject, body, bcc, cc]
  );

  const sendOutlook = useCallback(
    opener(
      `https://outlook.live.com/mail/0/deeplink/compose?to=${to}&subject=${encode(
        subject
      )}&bcc=${bcc}&cc=${cc}&body=${encode(body)}`
    ),
    [to, subject, body, bcc, cc]
  );

  return (
    <UncontrolledButtonDropdown>
      <WWButton id="caret" color="primary" onClick={sendDefault}>
        {"Send Email"}
      </WWButton>
      <DropdownToggle split color="primary" />
      <DropdownMenu>
        <DropdownItem onClick={sendGmail}>Gmail</DropdownItem>
        <DropdownItem onClick={sendOutlook}>Outlook.com</DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
}
