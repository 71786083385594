import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { trim, isEmpty, get, isUndefined, replace } from "lodash";
import InputField from "components/Form/InputField";
import StatusMessages from "components/Misc/StatusMessages";
import { useSelector } from "react-redux";
import Paragraph from "components/Layout/Paragraph";
import CopyButton from "components/Buttons/CopyButton";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import WWTextDetails from "components/WWTextDetails/WWTextDetails";
import { useAddAndRemoveSite } from "hooks/customerHooks";
import { SITE_SOURCE_NAMES, SOURCES } from "data/sites";
import EmailButton from "components/Buttons/EmailButton";

const CAR_GURUS_SITE_PATTERN = /https:\/\/www.cargurus.com\/Cars\/m-.+-(sp\d+)/;

const CAR_GURUS_SUPPORT_EMAIL = "support@cargurus.com";

function parseCarGurus(event) {
  return parseUrl(event, CAR_GURUS_SITE_PATTERN);
}

function parseUrl(event, pattern) {
  let url = event.target.value;
  if (isEmpty(url)) return;

  let match = url.match(pattern);
  if (!match && trim(url).length > 0) {
    return false;
  } else {
    return match[1];
  }
}

function encodeUrlParameter(str) {
  return encodeURIComponent(str).replace(/%20/g, "+");
}

function buildTemplate(dealerId, widewailAccount, userName, customer) {
  return `Hi CarGurus team, 

This is ${userName} from ${customer.companyName}. Would you please add access to the below accounts to ${widewailAccount}?
  
  CarGurus Dealer Id: ${dealerId}

Thank you all for your help.
  `;
}

export default function CarGurusLinking({ customer, widewailAccount = "cargurus@inbound.widewail.com" }) {
  const [errors, setErrors] = useState([]);
  const [carGurusValid, setCarGurusValid] = useState(undefined);
  const [carGurusInfo, setCarGurusInfo] = useState();
  const [emailBody, setEmailBody] = useState();
  const [toLine, setToLine] = useState();
  const currentUser = useSelector(state => state.cognito.user);
  const onboardingEmail = useSelector(state => state.currentAgency.onboardingEmail);
  const siteField = SOURCES[SITE_SOURCE_NAMES.carGurus].customerField;
  const [addSite, removeSite] = useAddAndRemoveSite({
    customerId: customer.id,
    field: siteField
  });

  useEffect(() => {
    setCarGurusInfo(get(customer, [siteField, 0]));
  }, [customer, siteField]);

  useEffect(() => {
    setToLine(`${CAR_GURUS_SUPPORT_EMAIL};${currentUser.email};${onboardingEmail}`);
  }, [currentUser.email, onboardingEmail]);

  useEffect(() => {
    if (carGurusInfo) {
      setEmailBody(buildTemplate(carGurusInfo.dealerId, widewailAccount, currentUser.name, customer));
    }
  }, [carGurusInfo, widewailAccount, currentUser.name, customer]);

  return (
    <Container>
      <Row>
        <Col>
          <p>
            Click on the link below to search for your dealership&apos;s CarGurus.com pages. Copy &amp; paste that URL
            below.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <StatusMessages errors={errors} />
        </Col>
      </Row>
      {carGurusInfo ? (
        <>
          <Row>
            <Col>CarGurus ID</Col>
            <Col>
              <strong>{carGurusInfo.dealerId}</strong>
              <i
                className="ms-1 fa fa-close"
                onClick={() => {
                  removeSite(carGurusInfo.id).then(() => {
                    setCarGurusInfo(undefined);
                    setCarGurusValid(undefined);
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <HorizontalSelectField
                name="defaultReviewTag"
                value={carGurusInfo.defaultReviewTag}
                label="Default Review Tag"
                simpleValue={true}
                options={["Sales"]}
                isDisabled={true}
                isClearable={false}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <InputField
              inline={false}
              valid={carGurusValid}
              feedback={!carGurusValid && "Not a valid CarGurus URL"}
              tip="CTRL+C to copy and CTRL-V to paste"
              name="carGurusUrl"
              label={
                <span>
                  Copy and paste the{" "}
                  <a
                    href={`https://www.google.com/search?q=site:www.cargurus.com+${encodeUrlParameter(
                      customer.companyName
                    )}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    CarGurus URL
                  </a>{" "}
                  for {customer.companyName} below.
                </span>
              }
              placeholder="https://cargurus.com/..."
              onChange={evt => {
                const id = parseCarGurus(evt);
                const carGurusUrl = evt.target.value;

                if (id === false || isUndefined(id)) {
                  setCarGurusValid(id);
                } else {
                  addSite({
                    source: SITE_SOURCE_NAMES.carGurus,
                    dealerId: id,
                    url: carGurusUrl,
                    defaultReviewTag: "Sales"
                  })
                    .then(res => {
                      setCarGurusInfo({
                        id: res[0].id,
                        dealerId: id,
                        url: carGurusUrl,
                        defaultReviewTag: "Sales"
                      });
                      setCarGurusValid(true);
                    })
                    .catch(err => {
                      setErrors(["Failed saving CarGurus ID."]);
                    });
                }
              }}
            />
          </Col>
        </Row>
      )}
      {carGurusInfo && (
        <>
          <Row>
            <Col>
              <p>
                You must send an email to CarGurus to request that Widewail be granted access to your account. Click{" "}
                <i>Send Email</i> to open a template email which you can use to request access. Send this email from the
                email address associated with your CarGurus accounts.
              </p>
              <EmailButton to={toLine} subject="New Account Access" body={emailBody} />
            </Col>
          </Row>

          <WWTextDetails caption="Manual Instructions">
            <div>
              If the email button above is not opening your email client you can manually copy and paste the email
              below.
              <br />
              <br />
              <Row>
                <Col xs="auto">To:</Col>
                <Col>
                  <CopyButton>{toLine}</CopyButton>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">Subject:</Col>
                <Col>
                  <CopyButton>New Account Access</CopyButton>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col>
                  <code>
                    <CopyButton text={emailBody}>
                      <Paragraph content={emailBody} />
                    </CopyButton>
                  </code>
                </Col>
              </Row>
            </div>
          </WWTextDetails>
        </>
      )}
    </Container>
  );
}
