import { get } from "lodash";
import classNames from "classnames";
import styles from "./StatusInfo.module.scss";
/**
 *
 * @param {String} status
 * @param {Object} statusColorMapper
 */
export default function StatusInfo({
  status,
  statusColorMapper = { QUEUED: "dark", OPENED: "success", UNOPENED: "warning", FAILED: "danger" },
  children,
  className,
  ...props
}) {
  const color = get(statusColorMapper, status, "dark");
  return (
    <div className={classNames("d-flex justify-content-start align-items-center", className)} {...props}>
      <div data-testid="status-marker" className={`rounded-circle ${styles.statusCircle} me-2 bg-${color}`} />
      <span>{status}</span>
      {children}
    </div>
  );
}
