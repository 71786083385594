import classNames from "classnames";
import { Alert } from "reactstrap";
import styles from "./CircularIcon.module.scss";

export default function CircularIcon({ color = "danger", iconColor = color, icon = "home", ...props }) {
  return (
    <Alert color={color} className={classNames(styles.roundIcon, "m-0")} {...props}>
      <i className={classNames(`fa fa-${icon}`, `text-${iconColor}`)} />
    </Alert>
  );
}
