import {useMemo} from "react";
import {useQuery} from "react-query";
import {useGet} from "../dataHooks";

/**
 * @param {string} id
 * @returns {import("react-query").UseQueryResult<{
 *   id: string;
 *   name: string;
 *   supportEmail: string;
 *   supportPhone: string;
 *   yelpEmail: string;
 *   edmundsEmail: stirng;
 *   carGurusEmail: string;
 *   dealerraterEmail: string;
 *   defaultProducts: import("ramda").Dictionary<boolean>
 * }>}
 */
export function useGoogleLocations(filter, accountName) {
  const queryKey = useMemo(() => ["googleLocations", filter, accountName], [filter, accountName]);
  const [get] = useGet("/google/locations", { params: { filter, accountName } });
  return useQuery(queryKey, get, {
    enabled: !!filter && !!accountName
  });
}

export function useGoogleAccounts() {
  const queryKey = "googleAccounts";
  const [get] = useGet("/google/accounts");
  return useQuery(queryKey, get);
}
