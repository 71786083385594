import React from "react";
import classnames from "classnames";
import colors from "constants/colors";
import styles from "./DonutChart.module.scss";

const { donutChartClass, donutHoleClass, contentClass } = styles;

const DEFAULT_COLORS = { total: colors.blue, value: colors.navy };

const DonutChart = ({ percentValue = 0, className, colors = DEFAULT_COLORS, children }) => {
  const { total, value } = colors;
  return (
    <div
      className={classnames(donutChartClass, className)}
      style={{
        background: `conic-gradient(${value} 0 ${percentValue}%, ${total} ${percentValue}% 100%)`
      }}
    >
      <div className={donutHoleClass}>
        <div className={contentClass}>{children}</div>
      </div>
    </div>
  );
};

export default DonutChart;
