/* eslint-disable */
import React from "react";

import * as types from "./actionTypes";
import * as api from "../../api/apiClient";

const STORAGE_KEY = "currentAgency";

export function initComplete() {
  return {
    type: types.APP_INIT_SUCCESS
  };
}

export function scriptLoaded(script) {
  return {
    type: types.APP_SCRIPT_LOADED,
    script
  };
}

export function checkVersion() {
  return dispatch => {
    //append hash to bust browser cache
    // api.rawGet('/version?t=' + new Date().getTime()).then(
    //   response => response.data,
    //   error => console.log(error)
    // )
    //   .then(version => {
    //     dispatch(setAppVersion(version))
    //     if(version !== __webpack_hash__) {
    //       dispatch(appMessage({
    //         key: 'app_new_version',
    //         type: 'error',
    //         title: 'New Version!',
    //         message: <span>A new version of Widewail is available. <a href="/">Click here</a> to reload before continuing.</span>
    //       }))
    //     }
    //   })
  };
}

export function setAppVersion(version) {
  return {
    type: types.APP_SET_VERSION,
    version
  };
}

export function appMessage(message) {
  if (!message.key) {
    console.error("Ignoring app message. Perhaps you forgot to define a key.");
    return;
  }
  return {
    type: types.APP_ADD_MESSAGE,
    message
  };
}

export function clearMessages() {
  return {
    type: types.APP_CLEAR_MESSAGES
  };
}
