import { LOCAL_STORAGE_KEYS } from "data/storageKeys";
import { isFunction } from "lodash";
import { assocPath, dissocPath, either, isEmpty, isNil, path } from "ramda";
import { getPersistedStateFragments, writeLocalStorage } from "../../util/localstorage";
import * as types from "../actions/actionTypes";

/**
 * View state holds global view state. Keys are view names, data is the data to store for that view.
 * A special key 'persistent' can be present in the data. Anything in there will be persistented to
 * local storage and restored on application load. Persistent and non-persistent data is stored together
 * in the redux store so that we easily issue bulk updates and manage state for a single view within
 * the same object.
 *
 * State shape
 * {
 *   myView1 : {
 *     transientValue1: "Abc"
 *     persistent: {
 *        persistentValue1: "xyz"
 *     }
 *   }
 * }
 */

/// TODO: no initial state for persistent values, fix it
export const initialState = getPersistedStateFragments("viewState/");

export default function viewState(state = initialState, { type, view, data }) {
  switch (type) {
    case types.UPDATE_VIEW_STATE:
      return !state[view]
        ? { ...state, [view]: data }
        : {
            ...state,
            [view]: {
              ...state[view],
              ...data,
              persistent: {
                ...state[view]?.persistent,
                ...data?.persistent
              }
            }
          };

    case types.UPDATE_PATH: {
      if (either(isNil, isEmpty)(data)) {
        return dissocPath(view, state);
      }
      const oldval = path(view, state);
      const newval = isFunction(data) ? data(oldval) : data;
      return oldval === newval ? state : assocPath(view, newval, state);
    }

    case types.CLEAR_VIEW_STATE: {
      writeLocalStorage(LOCAL_STORAGE_KEYS.FILTER_STATE, {});
      return { ...initialState, sidebar: { ...state.sidebar }, brand: { ...state.brand } };
    }

    default:
      return state;
  }
}
