import { Button, Collapse } from "reactstrap";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import FormField from "../../../components/Form/FormField";
import InputField from "../../../components/Form/InputField";
import { parseUrl } from "../../../util/stringUtils";
import { updateEdmundsLink } from "../../../api/customerApi";
import { americanStates } from "../../../data/locations";
import { useNativeInputState } from "../../../hooks/utilHooks";

function encodeUrlParameter(str) {
  return encodeURIComponent(str).replace(/%20/g, "+");
}

const searchUrl = "https://www.edmunds.com/dealerships/#";
const urlValidatorRegex = /^https:\/\/www.edmunds.com\/.+/;
const urlSplittingRegex = /https:\/\/www\.edmunds\.com\/dealerships(?:\/all)?\/(?<state>[a-zA-Z-]+)\/(?<city>[a-zA-Z-]+)\/(?<name>[a-zA-Z-]+)(?:_(?<id>\d+))?\/?/;
const siteName = "Edmunds";
const siteInfoField = "edmundsInfo";
const field = "EDMUNDS";

export default class Edmunds {
  field() {
    return siteInfoField;
  }

  add() {
    const AddEdmunds = ({ customer, onSave }) => {
      const [isURLValid, setIsUrlValid] = useState(undefined);
      const [url, setUrl] = useState();
      const [dealerId, setDealerId] = useNativeInputState("");
      const [dealerName, setDealerName] = useNativeInputState(customer.companyName);
      const [dealerState, setDealerState] = useNativeInputState(americanStates[customer.administrativeArea]);
      const [dealerCity, setDealerCity] = useNativeInputState(customer.locality);

      const allFilled = useMemo(() => {
        return dealerId && dealerCity && dealerName && dealerState;
      }, [dealerId, dealerCity, dealerName, dealerState]);

      useEffect(() => {
        if (typeof url === "string") {
          const urlParams = url.match(urlSplittingRegex);
          const { groups } = urlParams || {};
          const { city, id, name, state } = groups || {};
          const getEventValue = value => ({ target: { value } });
          id && setDealerId(getEventValue(id));
          city && setDealerCity(getEventValue(city));
          name && setDealerName(getEventValue(name));
          state && setDealerState(getEventValue(state));
        }
      }, [url]);

      return (
        <>
          <InputField
            inline={false}
            tip="Ctrl+C to copy and Ctrl+V to paste"
            name="accountUrl"
            placeholder={`${siteName} URL...`}
            valid={isURLValid}
            label={
              <span>
                Copy and paste the{" "}
                <a
                  href={`${searchUrl}${encodeUrlParameter(customer.companyName)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {siteName} URL
                </a>{" "}
                for {customer.companyName} below.
              </span>
            }
            onChange={evt => {
              setUrl(evt.target.value);
              setIsUrlValid(parseUrl(evt, urlValidatorRegex) && true);
            }}
          />
          <InputField type="text" label="Dealer ID" placeholder="Dealer ID" onChange={setDealerId} value={dealerId} />
          <InputField
            type="text"
            label="Dealer Name"
            placeholder="Dealer Name"
            onChange={setDealerName}
            value={dealerName}
          />
          <InputField
            type="text"
            label="Dealer State"
            placeholder="Dealer State"
            onChange={setDealerState}
            value={dealerState}
          />
          <InputField
            type="text"
            label="Dealer City"
            placeholder="Dealer City"
            onChange={setDealerCity}
            value={dealerCity}
          />
          <Button
            color="primary"
            disabled={!allFilled}
            onClick={() =>
              onSave({
                url,
                dealerState,
                dealerId,
                dealerCity,
                dealerName,
                source: field
              })
            }
          >
            Link
          </Button>
        </>
      );
    };
    return AddEdmunds;
  }

  edit() {
    const EditEdmunds = ({ info, customer }) => {
      const [initialUrl, setInitialUrl] = useState(info.url);
      const [url, setUrl] = useState(initialUrl);
      const [isURLValid, setIsUrlValid] = useState(undefined);
      const [initialDealerId, setInitialDealerId] = useState(info.dealerId);
      const [dealerId, setDealerId] = useState(initialDealerId);
      const [initialDealerName, setInitialDealerName] = useState(info.dealerName);
      const [dealerName, setDealerName] = useState(initialDealerName);
      const [initialDealerState, setInitialDealerState] = useState(info.dealerState);
      const [dealerState, setDealerState] = useState(initialDealerState);
      const [initialDealerCity, setInitialDealerCity] = useState(info.dealerCity);
      const [dealerCity, setDealerCity] = useState(initialDealerCity);

      const patchReady = useMemo(
        () =>
          initialDealerId !== dealerId ||
          initialDealerName !== dealerName ||
          initialDealerState !== dealerState ||
          initialDealerCity !== dealerCity ||
          initialUrl !== url,
        [
          initialDealerId,
          dealerId,
          initialDealerName,
          dealerName,
          initialDealerState,
          dealerState,
          initialDealerCity,
          dealerCity,
          initialUrl,
          url
        ]
      );

      const updateEdmunds = useCallback(
        () =>
          updateEdmundsLink(customer.id, info.id, {
            url,
            dealerId,
            dealerName,
            dealerState,
            dealerCity
          }).then(() => {
            setInitialUrl(url);
            setInitialDealerId(dealerId);
            setInitialDealerName(dealerName);
            setInitialDealerState(dealerState);
            setInitialDealerCity(dealerCity);
          }),
        [url, customer, info, dealerId, dealerName, dealerCity, dealerState]
      );
      return (
        <>
          <InputField
            inline={false}
            tip="Ctrl+C to copy and Ctrl+V to paste"
            name="accountUrl"
            placeholder={`${siteName} URL...`}
            valid={isURLValid}
            value={url}
            label={
              <span>
                Copy and paste the{" "}
                <a
                  href={`${searchUrl}${encodeUrlParameter(customer.companyName)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {siteName} URL
                </a>{" "}
                for {customer.companyName} below.
              </span>
            }
            onChange={evt => {
              setUrl(evt.target.value);
              setIsUrlValid(parseUrl(evt, urlValidatorRegex) && true);
            }}
          />
          <InputField
            type="text"
            label="Dealer ID"
            placeholder="Dealer ID"
            onChange={e => setDealerId(e.target.value)}
            value={dealerId}
          />
          <InputField
            type="text"
            label="Dealer Name"
            placeholder="Dealer Name"
            onChange={e => setDealerName(e.target.value)}
            value={dealerName}
          />
          <InputField
            type="text"
            label="Dealer State"
            placeholder="Dealer State"
            onChange={e => setDealerState(e.target.value)}
            value={dealerState}
          />
          <InputField
            type="text"
            label="Dealer City"
            placeholder="Dealer City"
            onChange={e => setDealerCity(e.target.value)}
            value={dealerCity}
          />
          <Collapse isOpen={patchReady}>
            <FormField label=" ">
              <Button color="primary" onClick={updateEdmunds} disabled={!patchReady}>
                Update
              </Button>
            </FormField>
          </Collapse>
        </>
      );
    };
    return EditEdmunds;
  }
}
