import React from "react";
import ToggleSwitch from "components/Form/Switch";
import { Form } from "reactstrap";

const ListingsNotificationsSettingsForm = ({ contact, onChange }) => (
  <Form className="form-horizontal">
    <ToggleSwitch
      label="“Real Time” Change Notification Email"
      onLabel=""
      offLabel=""
      name="listingsRealtimeEmailNotifications"
      onChange={onChange}
      checked={contact?.notificationPreferences.listingsRealtimeEmailNotifications}
      value="contact.notificationPreferences.listingsRealtimeEmailNotifications"
    />
    <ToggleSwitch
      label="“Real Time” Change Notification SMS"
      onLabel=""
      offLabel=""
      name="listingsRealtimeSmsNotifications"
      onChange={onChange}
      checked={contact?.notificationPreferences.listingsRealtimeSmsNotifications}
      value="contact.notificationPreferences.listingsRealtimeSmsNotifications"
    />
  </Form>
);

export default React.memo(ListingsNotificationsSettingsForm);
