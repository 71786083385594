import { reject } from "lodash";
import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { Label } from "reactstrap";
import WWButton from "../../Buttons/WWButton";
import SelectField from "../../Form/SelectField";
import BadgeList from "components/Badges/BadgeList/BadgeList";
import { not } from "ramda";
import WWOffcanvas from "components/WWOffCanvas/WWOffcanvas";
import { addOrUpdateEntities, deleteEntities } from "api/reviewApi";
import TagsEdit from "../Tags/TagsEdit";

import styles from "./ReviewTagSelector.module.scss";
import { useErrorNotification } from "components/Notifications/notification";
import { useTagSetById } from "hooks/data/tagSetHooks";
const { personMention, topicMention } = styles;

const mapTagsToOptions = tags => tags.map(tag => ({ label: `${tag[0].toUpperCase()}${tag.slice(1)}`, value: tag }));

const LabelWithSentiment = ({ children, sentiment }) => {
  const iconsMap = {
    POSITIVE: "fa fa-thumbs-up",
    NEGATIVE: "fa fa-thumbs-down",
    NEUTRAL: "fa fa-circle"
  };
  return (
    <div className="d-inline-block">
      {!!sentiment && <i className={classNames(iconsMap[sentiment], "px-1")} />}
      {children}
    </div>
  );
};

const ReviewTagSelector = ({ review, categoryTags = [], updateReviewCategory }) => {
  const [openOffcanvas, setOpenOffcanvas] = useState(false);
  const [tags, setTags] = useState(review.reviewEntities || []);
  const tagSetQuery = useTagSetById(review.customer.reviewTagSetId);
  const topics = tagSetQuery.data?.topics;
  const errorNotify = useErrorNotification();

  const onTagRemove = useCallback(value => {
    setTags(prev => reject(prev, value));
  }, []);

  const onAddOrUpdate = useCallback(
    (type, value, sentiment = null) => {
      const tagEntity = { type, value, sentiment };
      const isTheSameTag = tag => tag.value === value && tag.type === type;
      const newEntity = !tags.some(isTheSameTag);
      return addOrUpdateEntities(review.id, [tagEntity])
        .then(() => {
          setTags(prev => (newEntity ? [...prev, tagEntity] : prev.map(tag => (isTheSameTag(tag) ? tagEntity : tag))));
        })
        .catch(error => errorNotify(error));
    },
    [review, errorNotify, tags]
  );

  const onRemove = useCallback(
    tagEntity =>
      deleteEntities(review.id, [tagEntity])
        .then(() => {
          onTagRemove(tagEntity);
        })
        .catch(error => errorNotify(error)),
    [review, onTagRemove, errorNotify]
  );

  const getBadgeContent = useCallback(
    ({ value, sentiment }) => <LabelWithSentiment sentiment={sentiment}>{value}</LabelWithSentiment>,
    []
  );

  const classNameRenderer = useCallback(data => {
    switch (data.type) {
      case "PERSON":
        return personMention;
      case "TOPIC":
        return topicMention;
      default:
        break;
    }
  }, []);

  const onOpenOffcanvas = useCallback(() => setOpenOffcanvas(true), []);
  const onToggelOffcanvas = useCallback(() => setOpenOffcanvas(not), []);

  return (
    <>
      <div className="review-tags-container flex-row">
        <div className="subtags-category-container">
          <div className="d-flex justify-content-between align-items-center">
            <Label className="text-muted fw-normal mb-0">Category</Label>
          </div>
          <SelectField
            styles={{ "border-radius": "3px" }}
            id="category"
            name="tags"
            placeholder="Category"
            options={mapTagsToOptions(categoryTags)}
            defaultValue={review.tags ? mapTagsToOptions([review.tags]) : []}
            onChange={e => {
              updateReviewCategory(review, [e.target.value.value]);
              return e;
            }}
          />
        </div>
        <div className="subtags-category-container">
          <div className="d-flex justify-content-between align-items-center">
            <Label className="text-muted fw-normal mb-0">Tags</Label>
            <WWButton className="p-0" iconClass="fa fa-plus-circle" color="link" onClick={onOpenOffcanvas}>
              manage tags & sentiment
            </WWButton>
          </div>
          <div onClick={onOpenOffcanvas}>
            <BadgeList
              name="tagsList"
              onRemove={onRemove}
              getOptionLabel={getBadgeContent}
              value={tags}
              MultiValueClassNameRender={classNameRenderer}
            />
          </div>
        </div>
      </div>
      <>
        <WWOffcanvas isOpen={openOffcanvas} toggle={onToggelOffcanvas} direction="end" wide>
          <TagsEdit
            label="People"
            type="PERSON"
            review={review}
            tags={tags}
            onAddOrUpdate={onAddOrUpdate}
            onRemove={onRemove}
          />
          <TagsEdit
            label="Topics"
            type="TOPIC"
            options={topics}
            review={review}
            tags={tags}
            onAddOrUpdate={onAddOrUpdate}
            onRemove={onRemove}
          />
        </WWOffcanvas>
      </>
    </>
  );
};

export default ReviewTagSelector;
