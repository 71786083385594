import React, { useMemo } from "react";
import { FormGroup, FormText } from "reactstrap";
import { isEmpty } from "lodash";
import classNames from "classnames";
import RadioGroup from "components/Form/RadioGroup";
import SpecialHoursForm from "./SpecialHoursForm";
import AdditionalHoursForm from "./AdditionalHoursForm";
import DaysOfWeekForm from "./DaysOfWeekForm";
import ListingFieldLabel from "../ListingFieldLabel";
import { LISTINGS_LINKS } from "constants/links";
import { OPEN_CLOSED_STATUSES } from "data/listings";

import styles from "../Forms.module.scss";

const { hoursRadioClass } = styles;

const HOURS_OPTIONS = [
  {
    name: OPEN_CLOSED_STATUSES.open,
    labelText: "Open with main hours",
    labelDescription: "Show when your location is open"
  },
  {
    name: OPEN_CLOSED_STATUSES.openWithNoMainHours,
    labelText: "Open with no main hours",
    labelDescription: "Don’t show any hours when possible, otherwise show that your location is open 24/7"
  },
  {
    name: OPEN_CLOSED_STATUSES.closedTemporary,
    labelText: "Temporarily closed",
    labelDescription: "Show that your location will open again in the future"
  },
  {
    name: OPEN_CLOSED_STATUSES.closedPermanentely,
    labelText: "Permanently closed",
    labelDescription: "Show that your location no longer exists"
  }
];

const REGULAR_HOURS_TYPE = "regularHours";

const HoursForm = ({ initialValues, values, moreHoursTypes }) => {
  const options = useMemo(() => {
    const renderHoursLabel = (mainText, description) => (
      <div className="d-inline-flex flex-column">
        <span className="fw-normal lh-1">{mainText}</span>
        <FormText className="lh-1 mt-1 mb-2">{description}</FormText>
      </div>
    );
    const labels = HOURS_OPTIONS.reduce(
      (previous, option) => ({
        ...previous,
        [option.name]: renderHoursLabel(option.labelText, option.labelDescription)
      }),
      {}
    );
    return HOURS_OPTIONS.map(({ name }) => ({ optionName: name, label: labels[name] }));
  });

  return (
    <>
      <h5 className="mb-1">Hours</h5>
      <ListingFieldLabel link={LISTINGS_LINKS.hours}>
        Set main locations hours or mark your locations as closed.
      </ListingFieldLabel>
      <FormGroup className="py-2">
        <RadioGroup name="openClosedStatus" options={options} className={hoursRadioClass} />
      </FormGroup>
      <div className={classNames({ "d-none": values?.openClosedStatus !== OPEN_CLOSED_STATUSES.open })}>
        <DaysOfWeekForm initialValues={initialValues} values={values} field={REGULAR_HOURS_TYPE} />
        <SpecialHoursForm values={values} initialValues={initialValues} />
        {!isEmpty(moreHoursTypes) && (
          <AdditionalHoursForm values={values} initialValues={initialValues} moreHoursTypes={moreHoursTypes} />
        )}
      </div>
    </>
  );
};

export default HoursForm;
