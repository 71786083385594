import React, { useMemo } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { noop, find } from "lodash";
import classnames from "classnames";
import styles from "./WWTabs.module.scss";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const { navLinkClass, navLinkClassActive, tabsClass, leftAligned, rightAligned, tabsDropdownMenu } = styles;

const Alignments = Object.freeze({
  left: "left",
  right: "right",
  center: "center"
});

const DEFAULT_TEXT_TRANSFORM = "uppercase";

export const WWTabs = ({ tabs, className, align = Alignments.center }) => {
  const navClassName = classnames(className, tabsClass, {
    [leftAligned]: align === Alignments.left,
    [rightAligned]: align === Alignments.right
  });

  const filteredTabs = useMemo(() => tabs.filter(tab => !tab.hide), [tabs]);

  return (
    <Nav tabs className={navClassName}>
      <div className="d-none d-md-flex">
        {filteredTabs.map((tab, i) => (
          <WWTab key={`${tab?.label}${i}`} {...tab} />
        ))}
      </div>
      <UncontrolledDropdown className="d-inline-flex d-md-none">
        <DropdownToggle
          color="link"
          className={classnames(
            className,
            "fs-3 d-flex d-md-none text-nowrap flex-nowrap align-items-center text-decoration-none"
          )}
        >
          {find(tabs, ["active", true])?.label} <i className="ms-1 fa fa-angle-down" />
        </DropdownToggle>
        <DropdownMenu className={tabsDropdownMenu}>
          {filteredTabs.map((tab, i) => (
            <DropdownItem key={`${tab?.label}${i}`}>
              <WWTab {...tab} />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export const WWTab = ({ active, label, onClick, textTransform = DEFAULT_TEXT_TRANSFORM }) => (
  <NavItem>
    <NavLink
      onClick={onClick || noop}
      className={classnames(navLinkClass, { [navLinkClassActive]: !!active })}
      style={textTransform && { textTransform }}
    >
      {label}
    </NavLink>
  </NavItem>
);
