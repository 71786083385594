import React, { useCallback } from "react";
import ConfigurableAreaChartWithLegend from "../../../components/Charts/ConfigurableAreaChartWithLegend";
import { getPublishedHourly } from "../../../api/reportApi";
import moment from "moment";
import get from "lodash/get";
import map from "lodash/map";
import { Button } from "reactstrap";
import { Intervals } from "../../../constants/chart";

export default () => {
  const dataSource = useDataSource();
  return (
    <ConfigurableAreaChartWithLegend
      title="Published Replies / Hour"
      dataSource={dataSource}
      chartProps={{
        colors: {
          "Published Replies": "rgba(128, 128, 128, 1.0)"
        },
        xAxis: {
          tickCount: 8,
          domain: [
            dataMin =>
              dataMin
                ? dataMin
                : moment()
                    .subtract(8, "hours")
                    .unix(),
            dataMax => (dataMax ? dataMax : moment().unix())
          ]
        },
        yAxis: {
          domain: [0, dataMax => (dataMax ? dataMax * 1.1 : 1)],
          interval: Intervals.preserveStartEnd,
          // tickCount: 10,
          scale: "linear",
          allowDecimals: false
        },
        legend: {
          iconSize: 15,
          formatter: legendFormatter
        },
        tooltip: {
          content: tooltipFormatter
        }
      }}
    />
  );
};
const legendFormatter = (value, entry, index) => {
  return "Published Replies";
};

const tooltipFormatter = ({ active, payload, label }) => {
  if (active && payload && payload.length > 0) {
    let data = payload[0].payload;
    return (
      <Button>
        <div className={`d-flex flex-column align-items-start flex-grow-1`}>
          <span>
            <strong>
              {`${moment
                .unix(label)
                .local()
                .format("ddd, hA")}`}
            </strong>
          </span>
          {get(data, "Published Replies") != null ? (
            <span>{"Published Replies: " + get(data, "Published Replies")}</span>
          ) : (
            <></>
          )}
        </div>
      </Button>
    );
  } else {
    return null;
  }
};

const useDataSource = () => useCallback(getData, []);

const getData = () => {
  return getPublishedHourly().then(data => {
    return map(data, e => {
      return { ...e, type: "Published Replies" };
    });
  });
};
