import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { tryCatch } from "ramda";
import { createContext, useEffect, useMemo } from "react";
import { getFirebaseCustomToken } from "../api/firebaseApi";
import config from "../config";
import { isCurrentUserInGroup } from "util/userUtils";
import { permissions } from "components/Auth/permissions";
import { useErrorNotification } from "components/Notifications/notification";

export const FirebaseContext = createContext(null);

export default function FirebaseLayer({ children = null }) {
  const errorNotify = useErrorNotification();

  const app = useMemo(
    tryCatch(() => firebase.app())(() => firebase.initializeApp(config.firebase)),
    []
  );
  const db = useMemo(() => firebase.database(app), [app]);
  useEffect(
    () =>
      void getFirebaseCustomToken()
        .then(res => firebase.auth(app).signInWithCustomToken(res.data))
        .catch(error => {
          if (isCurrentUserInGroup([permissions.AGENCY_ADMIN, permissions.WIDEWAIL_ADMIN])) {
            return errorNotify(error);
          }
        }),
    [app]
  );
  return <FirebaseContext.Provider value={db}>{children}</FirebaseContext.Provider>;
}
