import React from "react";
import { bySource } from "../../data/sites";
import CustomerSiteTableRow from "./CustomerSiteTableRow";
import ToggleSwitch from "../Form/Switch";
import HelpIcon from "../Misc/HelpIcon";
import { isCurrentUserInGroup } from "../../util/userUtils";
import { TBody, TH, TR, THead, TD, Table } from "../../components/Table";
import { permissions } from "../Auth/permissions";
import "./CustomerSiteTable.scss";

const LABEL_WIDTH = 12;
const INPUT_WIDTH = 12;

/**
 * Helper function to get count of all destinations of a certain source
 * @param  {object}  post   Post object
 * @param  {string} source  source name: FACEBOOK, GOOGLE, etc…
 * @return {int}
 */
const numDestinationsWithSite = (post, source) => {
  return post.destinations.filter(destination => {
    return destination.source === source;
  }).length;
};

/**
 * Helper function to get count of all customers with toggle-able site
 * @param  {array}  customers     Customer collection
 * @param  {string} siteInfoName  siteInfo name: facebookInfo, etc…
 * @return {int}
 */
const numCustomersWithSiteExists = (customers = [], siteInfoName) => {
  return customers.filter(customer => {
    return customer.sites.hasOwnProperty(siteInfoName);
  }).length;
};

/**
 * Helper function to get count of all customers with toggle-able site
 * @param  {array}  customers     Customer collection
 * @param  {string} siteInfoName  siteInfo name: facebookInfo, etc…
 * @return {int}
 */
const numCustomersWithSiteEnabled = (customers = [], siteInfoName) => {
  return customers.filter(customer => {
    return customer.sites[siteInfoName];
  }).length;
};

/**
 * Helper function to determine whether all, none or some of the rows are set
 * Correspondingly returns a 1, 0, or intermediate value to represent this.
 * @param  {array}  customers     Customer collection
 * @param  {string} siteInfoName  siteInfo name: facebookInfo, etc…
 * @return {float}
 */
const fractionOfAllSitesEnabled = (customers = [], siteInfoName) => {
  const customerWithSiteEnabled = numCustomersWithSiteEnabled(customers, siteInfoName);
  const customersWithSiteExists = numCustomersWithSiteExists(customers, siteInfoName);

  if (customersWithSiteExists === 0) {
    return 0;
  }

  return customerWithSiteEnabled / customersWithSiteExists;
};

const CustomerSiteTable = ({ post, customers, onChange = null, disabled = false }) => {
  // Vertically crop list of locations down to 5 or so in scrollable container.
  return (
    <div className="scroll-y" style={{ maxHeight: "290px" }}>
      <Table size="sm" striped borderless className={disabled ? "tw-auto disabled" : "tw-auto"}>
        <thead>
          <tr>
            <th scope="col"></th>
            {isCurrentUserInGroup(permissions.CREATE_POST) && (
              <>
                <th scope="col" className="align-middle site-toggle">
                  <ToggleSwitch
                    className="text-center"
                    icon={bySource("FACEBOOK").icon}
                    label={bySource("FACEBOOK").label}
                    labelWidth={LABEL_WIDTH}
                    inputWidth={INPUT_WIDTH}
                    onLabel=""
                    offLabel=""
                    name="FACEBOOK:All"
                    checked={fractionOfAllSitesEnabled(customers, "facebookInfo") > 0}
                    onChange={onChange}
                    value="facebookInfo"
                    type={fractionOfAllSitesEnabled(customers, "facebookInfo") < 1 ? "secondary" : "primary"}
                  />
                </th>
                {/* <th scope="col" className="align-middle site-toggle">
                <ToggleSwitch
                  className="text-center"
                  icon={bySource("INSTAGRAM").icon}
                  label={`Instagram: ${numDestinationsWithSite(
                    post,
                    'INSTAGRAM'
                  )}`}
                  labelWidth={12}
                  inputWidth={12}
                  onLabel=""
                  offLabel=""
                  name="INSTAGRAM:All"
                  checked={
                    fractionOfAllSitesEnabled(customers, 'instagramInfo') > 0
                  }
                  onChange={onChange}
                  value="instagramInfo"
                  type={
                    fractionOfAllSitesEnabled(customers, 'instagramInfo') < 1
                      ? 'secondary'
                      : 'primary'
                  }
                />
              </th> */}
                <th scope="col" className="align-middle site-toggle">
                  <ToggleSwitch
                    className="text-center"
                    icon={bySource("GOOGLE").icon}
                    label={bySource("GOOGLE").label}
                    labelWidth={LABEL_WIDTH}
                    inputWidth={INPUT_WIDTH}
                    onLabel=""
                    offLabel=""
                    name="GOOGLE:All"
                    checked={fractionOfAllSitesEnabled(customers, "gmbInfo") > 0}
                    onChange={onChange}
                    value="gmbInfo"
                    type={fractionOfAllSitesEnabled(customers, "gmbInfo") < 1 ? "secondary" : "primary"}
                  />
                </th>
                {numCustomersWithSiteExists(customers, "linkedInInfo") > 0 && (
                  <th scope="col" className="align-middle site-toggle">
                    <ToggleSwitch
                      className="text-center"
                      icon={bySource("LINKEDIN").icon}
                      label={bySource("LINKEDIN").label}
                      labelWidth={LABEL_WIDTH}
                      inputWidth={INPUT_WIDTH}
                      onLabel=""
                      offLabel=""
                      name="LINKEDIN:All"
                      checked={fractionOfAllSitesEnabled(customers, "linkedInInfo") > 0}
                      onChange={onChange}
                      value="linkedInInfo"
                      type={fractionOfAllSitesEnabled(customers, "linkedInInfo") < 1 ? "secondary" : "primary"}
                    />
                  </th>
                )}
              </>
            )}
            {isCurrentUserInGroup(permissions.SHARE_DIRECT) && (
              <th scope="col" className="align-middle site-toggle">
                <ToggleSwitch
                  className="text-center"
                  icon={bySource("DIRECT_MESSAGE").icon}
                  label={
                    <span>
                      {bySource("DIRECT_MESSAGE").label}
                      <HelpIcon className="ps-2">
                        Direct messages send your post directly to your Share+ audience. They will receive an email
                        and/or sms right away containing your post.
                      </HelpIcon>
                    </span>
                  }
                  labelWidth={LABEL_WIDTH}
                  inputWidth={INPUT_WIDTH}
                  onLabel=""
                  offLabel=""
                  name="DIRECT_MESSAGE:All"
                  checked={fractionOfAllSitesEnabled(customers, "directMessageInfo") > 0}
                  onChange={onChange}
                  value="directMessageInfo"
                  type={fractionOfAllSitesEnabled(customers, "directMessageInfo") < 1 ? "secondary" : "primary"}
                />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {customers &&
            customers.map((customer, i) => (
              <CustomerSiteTableRow
                key={customer.id}
                customer={customer}
                onChange={onChange}
                showLinkedIn={numCustomersWithSiteExists(customers, "linkedInInfo") > 0}
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CustomerSiteTable;
