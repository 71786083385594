import { useCallback, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { isNil } from "lodash";
import { useGet, usePatch, useDelete } from "hooks/dataHooks";

/**
 * @param {{
 *  customerId: number;
 *  listingId: number;
 * }}
 * @returns {import("react-query").UseQueryResult<ListingData>
 */
export const useListing = ({ customerId, listingId }, options) => {
  const queryKey = `listings/${customerId}/${listingId}`;
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { enabled: !isNil(customerId) && !isNil(listingId), ...options });
};
/**
 * @param {{
 *  customerId: number;
 *  listingId: number;
 * }}
 * @returns {import("react-query").UseQueryResult<ListingData>
 */
export const useListingPatch = ({ customerId, listingId }) => {
  const patch = usePatch();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    data => {
      return patch(`listings/${customerId}/${listingId}`, { data });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(`listings/${customerId}/${listingId}`)
    }
  );
  return useCallback(mutation.mutateAsync, [mutation]);
};
/**
 * @param {{
 *  customerId: number;
 *  listingId: number;
 * }}
 * @returns {import("react-query").UseQueryResult<ListingLog>}
 */
export const useListingLog = ({ customerId, listingId, params, onSuccess }) => {
  const url = `listings/${customerId}/${listingId}/updateLog`;
  const [get] = useGet(url, { params });
  const queryKey = useMemo(() => [url, params], [url, params]);
  return useQuery(queryKey, get, { onSuccess, enabled: !isNil(customerId) && !isNil(listingId) });
};

export const useServiceItems = categories => {
  const queryKey = useMemo(() => ["serviceTypes", categories], [categories]);
  const [get] = useGet("google/serviceTypes", { params: { categories } });
  return useQuery(queryKey, get, {
    enabled: !!categories
  });
};

export const useListingMediaPatch = ({ customerId, listingId }) => {
  const patch = usePatch();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    data => {
      const { type, override } = data;
      return patch(`listings/${listingId}/media/${data.mediaId}`, { params: { type, override } });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(`listings/${customerId}/${listingId}`)
    }
  );
  return useCallback(mutation.mutateAsync, [mutation]);
};

export const useListingMediaDelete = ({ customerId, listingId }) => {
  const del = useDelete();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    mediaId => {
      return del(`listings/${listingId}/media/${mediaId}`, {});
    },
    {
      onSuccess: () => queryClient.invalidateQueries(`listings/${customerId}/${listingId}`)
    }
  );
  return useCallback(mutation.mutateAsync, [mutation]);
};

/**
 * @param {{
*  customerId: string;
*  listingId: string;
* }}
 * @returns {import("react-query").UseQueryResult<ListingSites>}
*/
export const useListingSites = ({ customerId, listingId, params }, options) => {
 const queryKey = [`listings/${customerId}/${listingId}/publicationState`, params];
 const [get] = useGet(`listings/${customerId}/${listingId}/publicationState`, { params: params });
 return useQuery(queryKey, get, { enabled: !isNil(customerId) && !isNil(listingId), ...options });
};
