import React, { useCallback, useMemo, useState } from "react";
import { Container, Row, Col, Button, Collapse } from "reactstrap";
import _ from "lodash";
import InputField from "../../../components/Form/InputField";
import { bySource } from "../../../data/sites";
import FormField from "../../../components/Form/FormField";
import { parseUrl } from "../../../util/stringUtils";
import { updateYelpLink, updateCompanyNameOverride } from "../../../api/customerApi";
import { init } from "ramda";

function encodeUrlParameter(str) {
  return encodeURIComponent(str).replace(/%20/g, "+");
}

const urlValidatorRegex = /^https:\/\/www.yelp.com\/.+/;
const site = bySource("YELP");
const source = site.source;
const siteName = site.label;
const siteInfoField = site.customerField;
const searchUrl = "https://www.yelp.com/search?";

export default class Yelp {
  field() {
    return siteInfoField;
  }

  add() {
    const AddYelp = ({ customer, onSave }) => {
      const [isURLValid, setIsUrlValid] = useState(undefined);
      const [url, setUrl] = useState("");

      return (
        <>
          <InputField
            inline={false}
            tip="Ctrl+C to copy and Ctrl+V to paste"
            name="accountUrl"
            placeholder={`${siteName} URL...`}
            valid={isURLValid}
            label={
              <span>
                Copy and paste the{" "}
                <a
                  href={`${searchUrl}find_desc=${encodeUrlParameter(
                    customer.companyName
                  )}&find_loc=${encodeUrlParameter(customer.locality + ", " + customer.administrativeArea)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {siteName} URL
                </a>{" "}
                for {customer.companyName} below.
              </span>
            }
            onChange={evt => {
              setUrl(evt.target.value);
              setIsUrlValid(parseUrl(evt, urlValidatorRegex) && true);
            }}
          />
          <Button
            color="primary"
            disabled={!isURLValid}
            onClick={() =>
              onSave({
                url,
                source: source
              })
            }
          >
            Link
          </Button>
        </>
      );
    };
    return AddYelp;
  }

  edit() {
    const EditYelp = ({ info, customer }) => {
      const [initialUrl, setInitialUrl] = useState(info.url);
      const [url, setUrl] = useState(initialUrl);
      const [isURLValid, setIsUrlValid] = useState(true);
      const [initialAddress, setInitialAddress] = useState(info.address);
      const [address, setAddress] = useState(initialAddress);
      const [initialCompanyNameOverride, setInitialCompanyNameOverride] = useState(info.companyNameOverride);
      const [companyNameOverride, setCompanyNameOverride] = useState(initialCompanyNameOverride);

      const patchReady = useMemo(
        () => initialUrl !== url || initialAddress !== address || initialCompanyNameOverride !== companyNameOverride,
        [initialUrl, url, initialAddress, address, initialCompanyNameOverride, companyNameOverride]
      );

      const updateYelp = useCallback(
        () =>
          updateYelpLink(customer.id, info.id, {
            ...(url !== undefined ? { url: url } : {}),
            ...(address !== undefined ? { address: address } : {}),
            ...(companyNameOverride !== undefined ? { companyNameOverride: companyNameOverride } : {})
          }).then(() => {
            setInitialUrl(url);
            setInitialAddress(address);
            setCompanyNameOverride(companyNameOverride);
            setInitialCompanyNameOverride(companyNameOverride);
          }),
        [url, customer, info, address, companyNameOverride]
      );

      return (
        <>
          <InputField
            inline={false}
            tip="Ctrl+C to copy and Ctrl+V to paste"
            name="accountUrl"
            placeholder={`${siteName} URL...`}
            valid={isURLValid}
            value={url}
            label={
              <span>
                Copy and paste the{" "}
                <a
                  href={`${searchUrl}${encodeUrlParameter(customer.companyName)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {siteName} URL
                </a>{" "}
                for {customer.companyName} below.
              </span>
            }
            onChange={evt => {
              setUrl(evt.target.value);
              setIsUrlValid(parseUrl(evt, urlValidatorRegex) && true);
            }}
          />
          <InputField
            inline={false}
            name="addressOverride"
            label="Address Override"
            placeholder="Address override"
            tip="Use this field if the address of the yelp listing is different from that in Widewail."
            value={address}
            onChange={evt => setAddress(evt.target.value)}
          />
          <InputField
            name="companyNameOverride"
            label="Name Override"
            value={info.companyNameOverride}
            onChange={evt => setCompanyNameOverride(evt.target.value)}
          />
          <Collapse isOpen={patchReady}>
            <FormField label=" ">
              <Button color="primary" onClick={updateYelp} disabled={!patchReady || !isURLValid}>
                Update
              </Button>
            </FormField>
          </Collapse>
        </>
      );
    };
    return EditYelp;
  }
}
