import { useCallback, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import Page from "../../../../../components/Modals/NavigableModal/Page/Page";
import StatusBarContainer from "../../../../../components/Modals/NavigableModal/StatusBarContainer/StatusBarContainer";
import { useLocationQueryParamState, useStateThroughRedux } from "../../../../../hooks/stateHooks";
import { getFileUploadUrl } from "../../../../../api/leadsApi";
import StatusMessages from "../../../../../components/Misc/StatusMessages";
import { upload } from "../../../../../api/mediaApi";
import { useNotification } from "../../../../../components/Notifications/notification";
import WWButton from "../../../../../components/Buttons/WWButton";

const DEFAULT_ERROR_MESSAGE = "There was an error processing your request.";

const CampaignSend = ({ customerId }) => {
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState(null);
  const [campaignId] = useLocationQueryParamState("modalCampaignId");
  const [, setSettingsOpened] = useStateThroughRedux("campaignModal/opened");
  const notify = useNotification();

  const checkFile = useCallback(
    csvFile => {
      if (csvFile?.size > 2097152) {
        setFile(null);
        setErrors({
          message: "File size is to large",
          details: "Use CSV file 2MB or less"
        });
      } else {
        setErrors(null);
        setFile(csvFile);
      }
    },
    [setFile, setErrors]
  );

  const close = useCallback(() => {
    setSettingsOpened(false);
  }, [setSettingsOpened]);

  const resetState = useCallback(() => {
    setFile(null);
    setErrors(null);
  }, []);

  const onSave = useCallback(() => {
    if (disabled || !customerId || !campaignId) {
      if (!disabled) {
        setErrors({
          message: DEFAULT_ERROR_MESSAGE,
          details: `No ${!customerId ? "customer" : "campaign"} id found`
        });
      }
      return;
    }
    setDisabled(true);
    getFileUploadUrl(customerId, "WIDEWAIL", campaignId)
      .then(res =>
        upload(file, res.data).then(() => {
          resetState();
          notify({
            timeout: 5000,
            body: "File uploaded successfully! You will receive an email with the results"
          });
          close();
        })
      )
      .catch(error => {
        setErrors({
          message: DEFAULT_ERROR_MESSAGE,
          details: error?.message
        });
      })
      .finally(() => setDisabled(false));
  }, [file, disabled, customerId, resetState, campaignId]);

  return (
    <Page id="send">
      <div className="p-3">
        <div clsasName="flex-nowrap">
          <h4>Send from CSV</h4>
          <h5>Instructions</h5>
          <p>
            <ul>
              <li>Please add only relevant content to CSV template, do not alter the column format.</li>
              <li>"lead full name" field and one of either "lead mobile" or "lead email" fields are required.</li>
              <li>
                If "lead full name", "lead first name" and "lead last name" fields are all populated, Widewail will
                default to using just the "lead first name" and "lead last name" fields.
              </li>
              <li>
                "visit date" should be in the form <b>yyyy-mm-dd hh:mm</b> or <b>M/d/yy H:mm</b>, eg.{" "}
                <i>2020-08-24 15:00</i> or <i>3/14/20 13:30</i>.
              </li>
            </ul>
          </p>
          <WWButton
            iconClass="fa fa-download"
            color="secondary"
            size="sm"
            className="me-2 mb-3"
            href={process.env.PUBLIC_URL + "/reviewlead-example.csv"}
            download
          >
            Download Template
          </WWButton>
          <FormGroup className="mt-2">
            <Input
              type="file"
              name="file"
              accept=".csv"
              onChange={event => {
                checkFile(event.target.files[0]);
              }}
            />
          </FormGroup>
        </div>
        {errors && <StatusMessages errors={errors} />}
      </div>
      <StatusBarContainer>
        <div className="d-flex justify-content-end py-2">
          <WWButton
            iconClass="fa fa-upload"
            color="primary"
            onClick={() => {
              onSave();
            }}
            disabled={!file || disabled}
          >
            Send Now
          </WWButton>
        </div>
      </StatusBarContainer>
    </Page>
  );
};

export default CampaignSend;
