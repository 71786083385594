import React, { Component } from "react";
import _ from "lodash";
import { Row, Col, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import qs from "qs";
import * as util from "./util";
import { REVIEW_TAGS } from "data/options";
import LoadingPage from "components/Misc/LoadingPage";

class GroupEngagementTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: []
    };
  }

  toggleGroup = id => {
    if (this.state.expandedRows.indexOf(id) >= 0) {
      this.setState({
        expandedRows: this.state.expandedRows.filter(e => e !== id)
      });
    } else {
      this.setState({ expandedRows: [...this.state.expandedRows, id] });
    }
  };

  renderRow = (summary, lvl) => {
    const {
      reportFilterValues: { dateRange }
    } = this.props;
    // let negPercent =
    //   summary.reviews.count > 0
    //     ? Math.ceil(
    //         (summary.reviews.negativeCount / summary.reviews.count) * 100
    //       )
    //     : 0;
    let { expandedRows } = this.state;
    return (
      <tr key={summary.customer.companyName}>
        <td>
          {lvl === 0 && (
            <i
              className={classnames(
                "fa",
                "link",
                expandedRows.indexOf(summary.customer.companyName) >= 0 ? "fa-minus-square-o" : "fa-plus-square-o"
              )}
              style={{ fontWeight: "normal", paddingRight: "4px" }}
              onClick={() => this.toggleGroup(summary.customer.companyName)}
            />
          )}
          <strong style={{ paddingLeft: Math.max(0, 24 * lvl) }}>
            {summary.customer.id ? (
              <Link
                className="link"
                to={{
                  pathname: "/report/customer",
                  search: qs.stringify({
                    location: { id: summary.customer.id, companyName: summary.customer.companyName, type: "CUSTOMER" },
                    dateRange
                  })
                }}
                target="_blank"
              >
                {summary.customer.companyName}
              </Link>
            ) : (
              <span>{summary.customer.companyName}</span>
            )}
          </strong>
        </td>
        <td>{_.get(summary, "posts.sentiment", "No data")}</td>
        <td>{_.get(summary, "posts.count", "No data")}</td>
        <td>{_.get(summary, "posts.interactions", "No data")}</td>
        {REVIEW_TAGS.map(tag => {
          if (_.get(summary, "questions.tags." + tag)) {
            let c = summary.questions.tags[tag];
            return <td key={tag}>{c}</td>;
          } else {
            return <td key={tag}>No data</td>;
          }
        })}
      </tr>
    );
  };

  render() {
    let { bordered = true, summary } = this.props;
    let { expandedRows } = this.state;
    if (!summary) {
      return <LoadingPage />;
    }

    return (
      <Row style={{ overflow: "visible" }}>
        <Col style={{ overflow: "visible" }}>
          <Table responsive bordered={bordered} hover style={{ overflow: "visible", width: "100%", height: "100%" }}>
            <thead>
              <tr className="text-center">
                <th style={{ border: "none" }} />
                <th style={{ border: "none" }} colSpan="3">
                  Facebook Posts
                </th>
                <th style={{ border: "none" }} colSpan="3">
                  Google Questions
                </th>
              </tr>
              <tr>
                <th />
                <th>Overall Sentiment</th>
                <th>Total Posts</th>
                <th>Total Interactions</th>
                {REVIEW_TAGS.map(t => (
                  <th key={t}>{t}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.renderRow(summary.parent, summary.included ? 0 : -1)}
              {expandedRows.indexOf(summary.parent.customer.companyName) >= 0 &&
                summary.children.map(c => this.renderRow(c, summary.included ? 1 : -1))}
              {summary.included &&
                summary.included.map(include => {
                  let retval = [this.renderRow(include.parent, 0)];
                  expandedRows.indexOf(include.parent.customer.companyName) >= 0 &&
                    retval.push(include.children.map(summary => this.renderRow(summary, 1)));
                  return retval;
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}

export const GroupEngagementTableLegend = () => (
  <Table responsive size="sm" style={{ width: "130px", fontSize: ".75em", textAlign: "center" }}>
    <thead>
      <tr>
        <th>Rating</th>
        <th>% Negative</th>
      </tr>
    </thead>
    <tbody>
      <tr className="perf-good">
        <td>{">= 4.6"}</td>
        <td>{"0% - 10%"}</td>
      </tr>
      <tr className="perf-warn">
        <td>{"4.0 - 4.5"}</td>
        <td>{"11% - 19%"}</td>
      </tr>
      <tr className="perf-bad">
        <td>{"< 4.0"}</td>
        <td>{"> 20%"}</td>
      </tr>
    </tbody>
  </Table>
);

export default connect(util.reportMapStateToProps)(GroupEngagementTable);
