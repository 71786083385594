import uniqueId from "lodash/uniqueId";
import React, { useCallback, useContext } from "react";

export const NotificationsContext = React.createContext();

/**
 * This hook designed to be used in application once, where all
 * the notifications are going to be located. Here is where all
 * the magic happens.
 *
 * @param {React.Dispatch<React.SetStateAction<{id: string; open: boolean; factory: () => JSX.Element}[]>>} setter
 * @returns {<T = any>(factory: (resolve: (v: T) => void) => JSX.Element) => Promise<[T, () => void]>}
 */
export const useNotificationsContextValue = setter =>
  useCallback(
    factory =>
      new Promise(resolve => {
        const id = uniqueId("notification");
        const close = () => setter(prev => prev.map(v => (v.id === id ? { ...v, open: false } : v)));
        setter(prev => [
          ...prev,
          {
            id,
            type: factory(resolve, close).props.icon,
            open: true,
            factory: () => factory(resolve, close)
          }
        ]);
      }),
    [setter]
  );

/**
 * This hook is a base building block for actual notification risers.
 * It contains no visuals and only helps to integrate with global
 * notifications system. Do not use it in application directly.
 *
 * @returns {(factory: (resolve: () => void) => JSX.Element) => Promise<void>}
 */
export const useNotifier = () => useContext(NotificationsContext);
