import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import InputField from "../../../Form/InputField";

import { useErrorNotification } from "../../../Notifications/notification";
import { FileUploadButton } from "../../../Form/FileUploadButton";
import * as customerApi from "../../../../api/customerApi";
import Labeled from "../../../Checkbox/Labeled/CheckboxLabeled";

function notBlank(value) {
  return value && value.trim() ? undefined : false;
}

export default function HostedNumberEditor({ customer, onChange }) {
  const errorNotification = useErrorNotification();
  const [hosted, setHosted] = useState(false);

  const [loaAuthorizingPerson, setLoaAuthorizingPerson] = useState();
  const [s3object, setS3Object] = useState();

  useEffect(() => {
    onChange(
      hosted
        ? {
            businessName: customer.companyName,
            houseNumber: customer.streetNumber,
            streetName: customer.streetName,
            city: customer.locality,
            state: customer.administrativeArea,
            zip: customer.postalCode,
            loaAuthorizingPerson: loaAuthorizingPerson,
            s3object: s3object
          }
        : null
    );
  }, [hosted, loaAuthorizingPerson, s3object]);

  const uploadLoa = event => {
    const file = event.target.files[0];
    const objectName = uuidv4();
    customerApi
      .uploadLetterOfAuthorization(customer.id, objectName, file)
      .then(() => setS3Object(objectName))
      .catch(err => {
        console.log(err);
        errorNotification(err);
      });
  };

  return (
    <>
      <Labeled name="hosted" checked={hosted} onToggle={setHosted} label="Hosted number?" />
      {hosted && (
        <>
          <h5>Letter of Authorization</h5>
          <FileUploadButton color="primary" size="sm" name="LOA" accept="application/pdf" onChange={uploadLoa} />
          <InputField
            name="name"
            className="flex-grow-1"
            inline={false}
            disabled={true}
            value={s3object ? "Letter of authorization uploaded!" : "Please upload a letter of authorization!"}
            valid={!!s3object}
          />
          <InputField
            name="loaAuthorizingPerson"
            className="flex-grow-1"
            value={loaAuthorizingPerson}
            label="LOA authorized by"
            onChange={event => setLoaAuthorizingPerson(event.target.value)}
            inline={false}
            valid={notBlank(loaAuthorizingPerson)}
            tip="The name of the brand representative who signed the letter of authorization"
          />
        </>
      )}
    </>
  );
}
