import * as types from "./actionTypes";
import { initComplete } from "../actions/appActions";
import size from "lodash/size";

export function initializeAgency(agencies) {
  return dispatch => {
    let agency;
    if (size(agencies) === 1) {
      agency = agencies[0];
    }
    if (agency) {
      dispatch(pickAgency(agency));
    }
    dispatch(initComplete());
  };
}

export function pickAgency(agency) {
  return {
    type: types.AGENCY_SET,
    agency
  };
}

export function logoutAgency() {
  return { type: types.LOGOUT_AGENCY };
}
