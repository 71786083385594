import React from "react";
import find from "lodash/find";
import indexOf from "lodash/indexOf";
import isEmpty from "lodash/isEmpty";

export default function GooglePostExample({
  postText = "Create a post",
  attachments = [],
  posterName = "Your Business",
  likes = 200,
  comments = 20,
  ...props
}) {
  const img = find(attachments, a => a.type === "PHOTO" && (isEmpty(a.sources) || indexOf(a.sources, "GOOGLE") >= 0));
  return (
    <div className="google-example-post">
      <div className="google-example-header">
        <div className="google-image-circle text-center">
          <i className="fa fa-building" aria-hidden="true"></i>
        </div>
        <div>
          <div className="name-title">{posterName}</div>
          <div className="datetime-subtitle">Now</div>
        </div>
      </div>
      {img && <img className="google-example-image" alt="Post attachment" src={img.target}></img>}
      <div className="google-example-body">{postText}</div>
    </div>
  );
}
