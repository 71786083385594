import { intersection, isEmpty, isFunction, keys, startsWith } from "lodash";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useURLQuerySetter from "./useURLQuerySetter/useURLQuerySetter";
import useURLQueryValue from "./useURLQueryValue/useURLQueryValue";
import { either, isNil, join, pipe, reject, split, identity } from "ramda";
import { CREATE_INVITATION_MODEL_HASH } from "views/Invite/LeadCreate";

export const useURLHashValue = () => {
  const { hash } = useLocation();
  return startsWith(hash, "#") ? hash.substring(1) : hash;
};

export const useURLHashSetter = (useReplace = false) => {
  const location = useLocation();
  const history = useHistory();
  const currentHash = useURLHashValue();
  return useCallback(
    valueOrFunction => {
      const newLoc = Object.assign(location);
      if (isFunction(valueOrFunction)) {
        newLoc.hash = encodeURIComponent(valueOrFunction(currentHash));
      } else {
        newLoc.hash = encodeURIComponent(valueOrFunction || "");
      }
      if (useReplace) {
        history.replace(newLoc);
      } else {
        history.push(newLoc);
      }
    },
    [location, history, useReplace]
  );
};

export const useHashState = (useReplace = false) => {
  const setHash = useURLHashSetter(useReplace);
  const hash = useURLHashValue();
  //this is here as the lead creator can be open on any page and works as an override to non lead creator hashes
  return [hash === CREATE_INVITATION_MODEL_HASH ? undefined : hash, setHash];
};

export const useURLQueryRedirect = (queryToReplace, queryReplacementCB) => {
  const setUrl = useURLQuerySetter(true, identity);
  const queryValue = useURLQueryValue(identity);
  useEffect(() => {
    if (!isEmpty(intersection(keys(queryValue), queryToReplace))) {
      setUrl(queryReplacementCB(queryValue));
    }
  }, [queryValue, queryToReplace, queryReplacementCB]);
};

export const useReduxPathFromUrlPath = path => {
  return pipe(split("/"), reject(either(isNil, isEmpty)), join(","))(path);
};
