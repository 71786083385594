import { isArray, noop } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Col, Collapse, Container, Row } from "reactstrap";
import { postRules } from "../../api/ruleEditorApi";
import FeedPreview from "../../components/DmsRules/FeedPreview";
import RuleEditor from "../../components/DmsRules/RuleEditor";
import DeleteButton from "../../components/Buttons/DeleteButton";
import FormField from "../../components/Form/FormField";
import HorizontalSelectField from "../../components/Form/HorizontalSelectField";
import { autoclose, useModal } from "../../components/Modals";
import { errorCaughtNotifier, useLocalNotifications } from "../../components/Notifications/notification";
import { bySource } from "../../data/sites";
import Carfax from "./Admin/CarfaxAdminLinking";
import CCCAdminLinking from "./Admin/CCCAdminLinking";
import CdkDms from "./Admin/CdkDmsAdminLinking";
import CdkFortellis from "./Admin/CdkFortellisAdminLinking";
import CMSMax from "./Admin/CMSMaxAdminLinking";
import Dealerbuilt from "./Admin/DealerbuiltAdminLinking";
import DealerId from "./Admin/DealerIdAdminLinking";
import DealertrackDms from "./Admin/DealertrackDmsAdminLinking";
import Edmunds from "./Admin/EdmundsAdminLinking";
import Facebook from "./Admin/FacebookAdminLinking";
import Google from "./Admin/GoogleAdminLinking";
import HubspotAdminLinking from "./Admin/HubspotAdminLinking";
import Instagram from "./Admin/InstagramAdminLinking";
import SimpleLink from "./Admin/LinkButtonAdminLinking";
import LinkedIn from "./Admin/LinkedInAdminLinking";
import ServiceTitan from "./Admin/ServiceTitanAdminLinking";
import Sevenrooms from "./Admin/SevenroomsAdminLinking";
import VinSolutions from "./Admin/VinSolutionsAdminLinking";
import Yelp from "./Admin/YelpAdminLinking";
import QueueImportButton from "./QueueImportButton";
import SiteInfoImportStatus from "./SiteInfoImportStatus";
import MindbodyAdminLinking from "./Admin/MindbodyAdminLinking";
import KeapAdminLinking from "./Admin/KeapAdminLinking";
import DealervaultAdminLinking from "./Admin/DealervaultAdminLinking";
import ServiceFusion from "./Admin/ServiceFusionAdminLinking";
import Entrata from "./Admin/EntrataAdminLinking";
import YardiAdminLinking from "./Admin/YardiAdminLinking";
import TreezAdminLinking from "./Admin/TreezAdminLinking";
import Boulevard from "./Admin/BoulevardAdminLinking";
import SalesforceAdminLinking from "./Admin/SalesforceAdminLinking";
import RevolutionPartsAdminLinking from "./Admin/RevolutionPartsAdminLinking";
import InspectionSupportAdminLinking from "./Admin/InspectionSupportAdminLinking";
import ApartmentsDotComAdminLinking from "./Admin/ApartmentsDotComAdminLinking";
import ApartmentRatingsAdminLinking from "./Admin/ApartmentRatingsAdminLinking";
import Appfolio from "./Admin/AppfolioAdminLinking";
import UberallAdminLinking from "./Admin/UberallAdminLinking";
import RentManagerAdminLinking from "./Admin/RentManagerAdminLinking";
import PropertywareAdminLinking from "./Admin/PropertywareAdminLinking";
import SquareAdminLinking from "./Admin/SquareAdminLinking";
import DatashakeAdminLinking from "./Admin/DatashakeAdminLinking";
import NeustarAdminLinking from "./Admin/NeustarAdminLinking";
import OpenTableAdminLinking from "./Admin/OpenTableAdminLinking";
import RealPageAdminLinking from "./Admin/RealPageAdminLinking";
import TekionAdminLinking from "./Admin/TekionAdminLinking";
import SwickardAdminLinking from "./Admin/SwickardAdminLinking";
import ResmanAdminLinking from "./Admin/ResmanAdminLinking";
import { useTagSetByCustomerId } from "hooks/data/tagSetHooks";
import { useUpdateDefaultReviewTag, useSiteInfoRefresh, useQueueImport } from "hooks/customerHooks";
import YardiMaintenanceAdminLinking from "./Admin/YardiMaintenanceAdminLinking";

const sourceComponents = {
  GOOGLE: new Google(),
  FACEBOOK: new Facebook(),
  INSTAGRAM: new Instagram(),
  DEALER_DOT_COM: new DealerId("dealerDotComInfo", "DEALER_DOT_COM"),
  CARS_DOT_COM: new DealerId("carsDotComInfo", "CARS_DOT_COM", null, true),
  DEALERRATER: new DealerId("dealerraterInfo", "DEALERRATER", null, true),
  CAR_GURUS: new DealerId("carGurusInfo", "CAR_GURUS", "Sales"),
  INNTOPIA: new SimpleLink("inntopiaInfo", "INNTOPIA"),
  ROME: new SimpleLink("romeInfo", "ROME"),
  DEALERVAULT: new DealervaultAdminLinking(),
  CARFAX: new Carfax(),
  DEALERTRACK_DMS: new DealertrackDms(),
  LINKEDIN: new LinkedIn(),
  VIN_SOLUTIONS: new VinSolutions(),
  CDK_DMS: new CdkDms(),
  CDK_FORTELLIS: new CdkFortellis(),
  CCC: new CCCAdminLinking(),
  HUBSPOT: new HubspotAdminLinking(),
  KEAP: new KeapAdminLinking(),
  DEALERBUILT: new Dealerbuilt(),
  EDMUNDS: new Edmunds(),
  YELP: new Yelp(),
  CMS_MAX: new CMSMax(),
  SEVENROOMS: new Sevenrooms(),
  MINDBODY: new MindbodyAdminLinking(),
  SERVICE_TITAN: new ServiceTitan(),
  SERVICE_FUSION: new ServiceFusion(),
  ENTRATA: new Entrata(),
  BOULEVARD: new Boulevard(),
  YARDI: new YardiAdminLinking(),
  YARDI_MAINTENANCE: new YardiMaintenanceAdminLinking(),
  SALESFORCE: new SalesforceAdminLinking(),
  REVOLUTION_PARTS: new RevolutionPartsAdminLinking(),
  CARVIX: new DealerId("carvixInfo", "CARVIX"),
  INSPECTION_SUPPORT_NETWORK: new InspectionSupportAdminLinking(),
  TREEZ: new TreezAdminLinking(),
  APARTMENTS_DOT_COM: new ApartmentsDotComAdminLinking(),
  APARTMENT_RATINGS: new ApartmentRatingsAdminLinking(),
  APPFOLIO: new Appfolio(),
  UBERALL: new UberallAdminLinking(),
  RENT_MANAGER: new RentManagerAdminLinking(),
  PROPERTYWARE: new PropertywareAdminLinking(),
  SQUARE: new SquareAdminLinking(),
  DATASHAKE: new DatashakeAdminLinking(),
  PRORESPONSE: new SimpleLink("proResponseInfo", "PRORESPONSE"),
  NEUSTAR: new NeustarAdminLinking(),
  OPEN_TABLE: new OpenTableAdminLinking(),
  TEKION: new TekionAdminLinking(),
  REAL_PAGE: new RealPageAdminLinking(),
  SWICKARD: new SwickardAdminLinking(),
  RESMAN: new ResmanAdminLinking()
};

const STATUS_VALUES = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  IN_ERROR: "IN_ERROR"
};
export const DefaultDuplicateWarning = ({ duplicates, agency }) => {
  if (!isArray(duplicates)) duplicates = [duplicates];
  if (duplicates && duplicates.length > 0 && duplicates[0] !== undefined) {
    let duplicate = duplicates[0];
    return (
      <Alert color={"danger"}>
        <span>{"Already linked to: "}</span>
        <Link to={`/customers/${duplicate.id}`}>{`${duplicate.companyName}`}</Link>

        {duplicate.agency.id !== agency.id && <>{` (${duplicate.agency.name})`}</>}
      </Alert>
    );
  }
  return <></>;
};

export default function AdminLinking({
  info,
  customer,
  onSave = noop,
  onRemove = noop,
  onDisabledToggled = noop,
  children
}) {
  const [sourceComponent, setSourceComponent] = useState();
  const [duplicates, setDuplicates] = useState([]);
  const [newInfo, setNewInfo] = useState(null);
  const [defaultReviewCategory, setDefaultReviewCategory] = useState();

  const notify = useLocalNotifications();
  const currentAgency = useSelector(state => state.currentAgency);

  const tagSetQuery = useTagSetByCustomerId(customer.id, { enabled: !!customer.id });
  const categories = tagSetQuery.data?.tags || [];

  useEffect(() => {
    setDefaultReviewCategory(info.defaultReviewTag);
  }, [info]);

  const Add = useMemo(() => (sourceComponent ? sourceComponent.add() : undefined), [sourceComponent]);
  const Edit = useMemo(() => (sourceComponent ? sourceComponent.edit() : undefined), [sourceComponent]);
  const field = useMemo(() => (sourceComponent ? sourceComponent.field() : undefined), [sourceComponent]);
  const site = useMemo(() => bySource(info.source), [info.source]);
  const raiseModal = useModal();

  const DuplicateWarning = useMemo(
    () => (sourceComponent?.duplicateWarning ? sourceComponent.duplicateWarning() : DefaultDuplicateWarning),
    [sourceComponent]
  );

  useEffect(() => {
    if (info) {
      const sc = sourceComponents[info.source];
      setSourceComponent(sc);
    }
  }, [info]);

  const updateDefaultReviewCategory = useUpdateDefaultReviewTag({
    customerId: customer.id,
    field
  });

  const onChangeDefaultTag = useCallback(
    evt =>
      updateDefaultReviewCategory({
        siteId: info.id,
        defaultReviewTag: evt.target.value
      }).then(({ defaultReviewTag }) => setDefaultReviewCategory(defaultReviewTag)),
    [updateDefaultReviewCategory, info]
  );

  const addSite = data => {
    setNewInfo(data);
    onSave(field, data, false, err => {
      errorCaughtNotifier(notify)(err);
      setDuplicates(err.response.data.duplicateLinks || []);
    });
  };

  const addSiteOverride = data => {
    onSave(field, data, true, err => {
      errorCaughtNotifier(notify)(err);
      setDuplicates(err.response.data.duplicateLinks || []);
    });
  };

  const removeSite = useCallback(() => {
    onRemove(field, info.id);
    setDuplicates([]);
  }, [field, info, onRemove]);

  const toggleSiteDisabledState = useCallback(
    () => onDisabledToggled(field, info.id),
    [field, info, onDisabledToggled]
  );

  const refreshSite = useSiteInfoRefresh({ customerId: customer.id, siteId: info.id, field });

  const queueImport = useQueueImport(customer.id);

  if (!sourceComponent && !children) {
    return null;
  }

  if (!info.id) {
    return (
      <Container className="admin-site-info">
        {DuplicateWarning && (
          <DuplicateWarning
            info={newInfo}
            duplicates={duplicates}
            agency={currentAgency}
            onOverride={addSiteOverride}
          />
        )}

        <Row className="mb-2">
          <Col xs="auto">
            <DeleteButton confirmationPrompt="" className="me-2" onClick={removeSite} />
            <strong className="h4">{site.label}</strong>
          </Col>
        </Row>
        <Row>
          <Col>
            {!!sourceComponent && <Add customer={customer} onSave={addSite} />}
            {children}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="admin-site-info">
      <Row className="mb-2">
        <Col xs="auto">
          {site.feedPreview && (
            <i
              className="fa fa-eye me-2"
              onClick={() => {
                autoclose(raiseModal(resolve => <FeedPreview customer={customer} info={info} onClose={resolve} />));
              }}
            />
          )}
          {site.queueable === true && (
            <i
              className={`fa fa-ban link me-2 ${info.disabled ? "text-danger" : ""}`}
              onClick={toggleSiteDisabledState}
            />
          )}
          <DeleteButton
            confirmationPrompt="Widewail will no longer import data from this location. Existing reviews will be marked read-only."
            className="me-2"
            onClick={removeSite}
          />
          <span>
            <strong className="h4">{site.label}</strong>
            {!!info.directoryProviderType && <small>{` Managed by ${info.directoryProviderType}`}</small>}
          </span>
        </Col>
        <Col className="text-center">
          <SiteInfoImportStatus info={info} />
        </Col>
        <Col xs="auto">
          {site.queueable === true && <QueueImportButton info={info} queueImport={queueImport} source={info.source} />}
        </Col>
      </Row>
      <Collapse isOpen={info.disabled}>
        <Row>
          <Col>
            <Alert color="warning">This site link is disabled</Alert>
          </Col>
        </Row>
      </Collapse>
      <Collapse isOpen={info.status === STATUS_VALUES.IN_ERROR}>
        <Row>
          <Col>
            <Alert color="warning">This site link is in error. It will be periodically checked to see if it is resolved.</Alert>
          </Col>
        </Row>
      </Collapse>
      {!site.isDms && (
        <HorizontalSelectField
          name="defaultReviewCategory"
          value={defaultReviewCategory}
          label="Default Review Category"
          simpleValue={true}
          options={categories}
          onChange={onChangeDefaultTag}
          isClearable={true}
          inline={false}
        />
      )}
      {info.reviewUrl && (
        <FormField label="Review URL" inline={false}>
          <a href={info.reviewUrl}>{info.reviewUrl}</a>
        </FormField>
      )}
      {!!sourceComponent && <Edit info={info} customer={customer} refreshSite={refreshSite} />}
      {(site.rulesEditing || site.freeTextRulesEditing) && (
        <RuleEditor
          onChangesCommitted={payLoad => postRules(payLoad, customer.id, site.customerField, info.id)}
          customer={customer}
          site={site}
          rules={info.importRules}
        />
      )}
      {children}
    </Container>
  );
}
