import classNames from "classnames";
import Time from "components/DateTime/Time";
import StatusInfo from "components/Table/StatusInfo/StatusInfo";
import { WWTD, WWTR } from "components/Table/WWTable";
import { siteName } from "data/sites";
import { startsWith } from "lodash";
import { createSiteLogoUrl, siteInfoLocationDescriptor, siteInfoUrl } from "util/siteUtils";
import SiteDescriptor from "../SiteDescriptor";
import styles from "../SiteRowRenderers.module.scss";
import WWButton from "components/Buttons/WWButton";
import { autoclose, useModal } from "components/Modals";
import FacebookLoginButton from "components/Facebook/FacebookLoginButton";
import { useCurrentFacebookUser, useFacebookBusinesses } from "hooks/data/facebookApiHooks";
import { ModalBody, ModalHeader } from "reactstrap";
import { useMemo, useState } from "react";
import { regenerateUserKey } from "api/customerApi";
import { useErrorNotification } from "components/Notifications/notification";
import AjaxLoader from "components/Misc/AjaxLoader";
import { isProductActiveForCustomer } from "util/customerUtils";
import SelectField from "components/Form/SelectField";

const FacebookSiteRowRenderer = ({ index, customer, rowClicked, site }) => {
  const confirm = useConfirmationDialog();
  return (
    <>
      <WWTR noHover>
        <WWTD className="ps-0 pt-0">
          <div className={classNames(" d-flex flex-nowrap align-items-center mb-2", { "mt-5": index !== 0 })}>
            <img className={styles.logo} alt={`${siteName(site.source)} Logo`} src={createSiteLogoUrl(site.source)} />
            <h4 className={"m-0 ms-2 " + styles.siteLabel}>{siteName(site.source)}</h4>
          </div>
        </WWTD>
        <WWTD className="pt-0" colSpan={6} />
      </WWTR>
      {site.siteInfo.map(info => (
        <WWTR
          onClick={site.userLinking ? () => rowClicked(site.source) : undefined}
          noHover={!site.userLinking}
          key={info.id}
        >
          <WWTD>
            <SiteDescriptor descriptor={siteInfoLocationDescriptor(info)} url={siteInfoUrl(info)} />
          </WWTD>
          <WWTD>
            {info?.sharedCredentials && (
              <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                <span className="font-xs text-uppercase">Shared Credentials</span> {info.sharedCredentials.name}
              </div>
            )}
          </WWTD>
          <WWTD />
          <WWTD>
            {info.defaultReviewTag && (
              <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                <span className="font-xs">TAG</span> {info.defaultReviewTag}
              </div>
            )}
          </WWTD>
          <WWTD>
            {info?.importStatus && (
              <div className={styles.tableCell}>
                <StatusInfo
                  status={startsWith(info.importStatus, "ERROR")}
                  statusColorMapper={{ true: "danger", false: "success" }}
                >
                  {info.importStatus}
                </StatusInfo>
                <Time className="font-xs text-uppercase" withIcon={false} format="ago" date={info.lastImportSuccess} />
                <span className="font-xs"> AGO</span>
              </div>
            )}
          </WWTD>
          <WWTD>
            <WWButton
              onClick={event => {
                event.stopPropagation();
                return autoclose(confirm({ info, customer }));
              }}
            >
              Relink Facebook
            </WWButton>
          </WWTD>
        </WWTR>
      ))}
    </>
  );
};

export const riseFacebookLinkDialog =
  riser =>
  ({ info, customer }) => {
    return riser(resolve => <FacebookRelinkModal info={info} customer={customer} resolve={resolve} />);
  };

const FacebookRelinkModal = ({ resolve, info, customer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState();
  const useAdAccounts = isProductActiveForCustomer(customer, "FACEBOOK_ADS");
  const facebookUser = useCurrentFacebookUser();
  const businessFetch = useFacebookBusinesses();
  const errorNotify = useErrorNotification();
  const onSelectBusiness = event => {
    const business = event.target.value;
    setSelectedBusiness(business);
  };

  return (
    <>
      <ModalHeader className="px-4" toggle={() => resolve(false)}>
        Relink Facebook
      </ModalHeader>
      <ModalBody className="px-4">
        <p className="mb-3">
          You must reauthorize the facebook account{" "}
          {!info?.businessId && "and select the business for this link to relink."}
        </p>
        <FacebookLoginButton facebookAdsEnabled={useAdAccounts} />
        {!info?.businessId && (
          <>
            <h5 className="mt-2">Select Business</h5>
            <SelectField
              name="business"
              isLoading={businessFetch.isFetching}
              options={businessFetch.data || []}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              onChange={onSelectBusiness}
              hideSelectedOptions
            />
          </>
        )}
        <p className="mt-3">Click Relink to save changes. Window will close automatically when saved.</p>
        <WWButton
          className="mt-3"
          disabled={!facebookUser || (!selectedBusiness?.id && !info?.businessId) || isLoading}
          color="primary"
          onClick={() => {
            setIsLoading(true);
            regenerateUserKey({
              customerId: customer.id,
              siteInfoId: info.id,
              businessId: selectedBusiness?.id,
              userAccessToken: facebookUser.accessToken
            })
              .then(() => resolve(true))
              .catch(errorNotify)
              .finally(() => setIsLoading(false));
          }}
        >
          {isLoading ? <AjaxLoader size={10} /> : "Relink"}
        </WWButton>
      </ModalBody>
    </>
  );
};

export const useConfirmationDialog = () => {
  const riser = useModal();
  return useMemo(() => riseFacebookLinkDialog(riser), [riser]);
};

export default FacebookSiteRowRenderer;
