import classNames from "classnames";
import qs from "qs";
import querystring from "querystring";
import { fromPairs, isNil, last, pipe, reject, toPairs } from "ramda";
import React, { Component, useMemo, createRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Badge, Nav, NavItem, NavLink as RsNavLink } from "reactstrap";
import { omit, isFunction } from "lodash";
import { encodeForURLQuery } from "util/functionUtils";
import { isCurrentUserInGroup } from "util/userUtils";
import SidebarFooter from "./SidebarFooter";
import SidebarForm from "./SidebarForm";
import SidebarHeader from "./SidebarHeader";
import nav from "./_nav";
import { useReduxAt } from "hooks/stateHooks";
import { useReduxPathFromUrlPath } from "hooks/urlHooks";
import { useSidebarPersistantViewState } from "hooks/stateHooks";

const DEFAULT_MINIMIZED_NAVBAR_Y_OFFSET = -16;

class Sidebar extends Component {
  state = {
    minimizedYOffset: DEFAULT_MINIMIZED_NAVBAR_Y_OFFSET
  };

  layoutBreakpointRef = createRef();

  get isMobileResolution() {
    return this.layoutBreakpointRef.current && getComputedStyle(this.layoutBreakpointRef.current).display !== "none";
  }

  get isMinimized() {
    return this.props.minimized && !this.isMobileResolution;
  }

  setMinimizedYOffset = scrollTop => {
    if (this.isMinimized) {
      this.setState({ minimizedYOffset: DEFAULT_MINIMIZED_NAVBAR_Y_OFFSET - scrollTop });
    }
  };

  handleScroll = e => {
    this.setMinimizedYOffset(e.target.scrollTop);
  };

  handleClick = e => {
    e.preventDefault();
    e.target.closest("li").classList.toggle("open");
  };

  activeRoute(routeName, props) {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    return props.location.pathname.indexOf(routeName) > -1 ? "nav-item nav-dropdown open" : "nav-item nav-dropdown";
  }

  collapseForMobile = () => {
    this.props.setExpandedForMobile(false);
  };

  onMinimizerClick = () => {
    const { minimized, setMinimized } = this.props;
    if (minimized) {
      this.collapseForMobile();
    }
    setMinimized(prev => !prev);
  };

  render() {
    const props = this.props;
    const activeRoute = this.activeRoute;
    const handleClick = this.handleClick;
    const linkOffsetStyle = this.isMinimized ? { marginTop: `${this.state.minimizedYOffset}px` } : undefined;

    // badge addon to NavItem
    const badge = badge => {
      if (badge) {
        const classes = classNames(badge.class);
        return (
          <Badge className={classes} color={badge.variant}>
            {badge.text}
          </Badge>
        );
      }
    };

    // simple wrapper for nav-title item
    const wrapper = item => {
      const name = itemName(item.name);
      return item.wrapper && item.wrapper.element
        ? React.createElement(item.wrapper.element, item.wrapper.attributes, name)
        : name;
    };

    // nav list section title
    const title = (title, key) => {
      const classes = classNames("nav-title", title.class);
      return (
        <li key={key} className={classes}>
          {wrapper(title)}{" "}
        </li>
      );
    };

    // nav list divider
    const divider = (divider, key) => {
      const classes = classNames("divider", divider.class);
      return <li key={key} className={classes}></li>;
    };

    const itemName = name => (isFunction(name) ? name() : name);

    // nav item with nav link
    const navItem = (item, key, isTopLevel) => {
      const classes = {
        item: classNames(item.class),
        link: classNames("nav-link", {
          [`nav-link-${item.variant}`]: !!item.variant
        }),
        icon: classNames(item.icon)
      };
      return navLink(item, key, classes, isTopLevel);
    };

    // nav link
    const navLink = (item, key, classes, isTopLevel) => {
      const url = item.url || "";

      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ? (
            <RsNavLink href={url} className={classes.link}>
              <i className={classes.icon}></i>
              <span className="label" style={isTopLevel ? linkOffsetStyle : undefined}>
                {itemName(item.name)}
                {badge(item.badge)}
              </span>
            </RsNavLink>
          ) : (
            <NavLinkWrapper
              item={item}
              className={classes.link}
              onClick={this.collapseForMobile}
              activeClassName={item.noNavHighlightWhenOpened ? "" : "active"}
              exact
            >
              <i className={classes.icon}></i>
              <span className="label" style={isTopLevel ? linkOffsetStyle : undefined}>
                {itemName(item.name)}
                {badge(item.badge)}
              </span>
            </NavLinkWrapper>
          )}
        </NavItem>
      );
    };

    const navDropdown = (item, key) => {
      const name = itemName(item.name);
      return (
        <li key={key} className={activeRoute(item.url, props)}>
          {/* eslint-disable-next-line */}
          <a className="nav-link nav-dropdown-toggle pe-3" href="#" onClick={handleClick.bind(this)}>
            <i data-cy={`nav-icon-${name}`} className={item.icon}></i>
            <span className="label" style={linkOffsetStyle}>
              {name}
            </span>
          </a>
          <ul
            className="nav-dropdown-items"
            style={
              this.isMinimized
                ? { marginTop: `${this.state.minimizedYOffset - DEFAULT_MINIMIZED_NAVBAR_Y_OFFSET}px` }
                : undefined
            }
          >
            {navList(item.children)}
          </ul>
        </li>
      );
    };

    // nav type
    const navType = (item, idx, isTopLevel) =>
      item.title
        ? title(item, idx)
        : item.divider
        ? divider(item, idx)
        : item.children
        ? navDropdown(item, idx)
        : navItem(item, idx, isTopLevel);

    // nav list
    const navList = (items, isTopLevel = false) => {
      return items
        .filter(item => isCurrentUserInGroup(item.groups))
        .filter(item => !isFunction(item.isVisible) || item.isVisible(item))
        .map((item, index) => navType(item, index, isTopLevel));
    };

    const isExternal = url => {
      const link = url ? url.substring(0, 4) : "";
      return link === "http";
    };

    // sidebar-nav root
    return (
      <>
        <div className="sidebar">
          <SidebarHeader />
          <SidebarForm />
          <nav className="sidebar-nav" onMouseEnter={this.handleScroll} onScroll={this.handleScroll}>
            <Nav>{navList(nav.items, true)}</Nav>
          </nav>
          <SidebarFooter />
          <button className="sidebar-minimizer" type="button" onClick={this.onMinimizerClick} />
        </div>
        <div className="sidebar-closer" ref={this.layoutBreakpointRef} onClick={this.collapseForMobile} />
      </>
    );
  }
}

export const NavLinkWrapper = ({ item, ...props }) => {
  const url = useMemo(() => item.url || "", [item]);
  const urlKey = `nav${url.replaceAll("/", "-")}`;
  const reduxPath = useReduxPathFromUrlPath(url);
  const relatedReduxValue = useReduxAt(reduxPath);
  /*prevents using the page filter as part of the nav link currently hard coded to "page" and will
  not work for pages with multiple components with pagination */
  const normalized = encodeForURLQuery(omit(relatedReduxValue, ["page", "urlLoaded"]));
  const reduxSearch = qs.stringify(normalized, { indices: false });
  const location = useLocation();
  const rawSearchValue = useMemo(() => location.search, [location]);
  //actualSearchValue will be depreciated as the new filter takes over
  const actualSearchValue = useMemo(() => {
    if (item.populateURLQuery) {
      return querystring.stringify(
        encodeForURLQuery(
          pipe(
            toPairs,
            reject(pipe(last, isNil)),
            fromPairs
          )(
            isFunction(item.populateURLQuery)
              ? item.populateURLQuery(relatedReduxValue, rawSearchValue)
              : relatedReduxValue
          )
        )
      );
    }
    return "";
  }, [item, relatedReduxValue, rawSearchValue]);
  return (
    <NavLink
      to={{ pathname: url, search: actualSearchValue || reduxSearch }}
      {...props}
      className={classNames(props.className, urlKey)}
      data-button-title={urlKey}
    />
  );
};

export const SidebarWithViewState = props => {
  const [minimized, , setMinimized, setExpandedForMobile] = useSidebarPersistantViewState();
  return <Sidebar {...{ minimized, setMinimized, setExpandedForMobile }} {...props} />;
};

export default SidebarWithViewState;
