import React, { useMemo, useState } from "react";
import { Button, InputGroup } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import CopyButton from "../../../components/Buttons/CopyButton";
import Input from "../../../components/Form/Input";
/*
 * Site info admin panel for site info objects which just contain a single dealer id
 */
export default class DealerId {
  constructor(field, source, defaultReviewTag, numeric) {
    this.fieldName = field;
    this.source = source;
    this.defaultReviewTag = defaultReviewTag;
    this.numeric = numeric;

    this.field = this.field.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
  }

  field() {
    return this.fieldName;
  }

  add() {
    const AddDealerId = ({ customer, onSave }) => {
      const [dealerId, setDealerId] = useState();
      const valid = useMemo(() => dealerId && (!this.numeric || dealerId.match(/^[0-9]+$/)), [dealerId]);

      return (
        <InputGroup>
          <Input
            type="text"
            placeholder={this.numeric ? "ID (must be numeric)" : "ID"}
            onChange={e => setDealerId(e.target.value)}
            value={dealerId}
          />
          <Button
            color="primary"
            onClick={() =>
              onSave({
                dealerId,
                source: this.source,
                defaultReviewTag: this.defaultReviewTag
              })
            }
            disabled={!valid}
          >
            Link
          </Button>
        </InputGroup>
      );
    };

    return AddDealerId;
  }

  edit() {
    const EditDealerId = ({ info, customer }) => (
      <>
        {info.url && (
          <FormField label="Location" inline={false}>
            <a href={info.url} target="_blank" rel="noopener noreferrer">
              {info.companyName || customer.companyName} <i className="fa fa-external-link" />
            </a>
          </FormField>
        )}
        <FormField label="ID" inline={false}>
          <div className="text-nowrap">
            {info.dealerId}
            <span className="ps-1">
              <CopyButton text={info.dealerId} />
            </span>
          </div>
        </FormField>
      </>
    );
    return EditDealerId;
  }
}
