import colors from "../scss/exports/_colors.module.scss";

const chartColors = {
  chartBlack: "#000",
  chartLightGray: "#f8f9fa",
  chartGridDarkGray: "#eeeeee",
  chartStrokeGray: "#dedddb",
  chartStokeYellow: "#ffb930",
  chartStrokeNavy: "#283f57",
  chartStrokeBlue: "#1976d2",
};

export default {
  ...colors,
  ...chartColors
};
