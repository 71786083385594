import { diffSentences } from "diff";
import { isString } from "lodash";
import size from "lodash/size";
import truncate from "lodash/truncate";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Button } from "reactstrap";

/**
 * Display a block of text showing the differences between two strings
 * that can collapse content beyond the set max number of characters
 * that may contain line breaks
 * @param {string} content - The new content
 * @param {string} oldContent - What you are comparing the content too
 * @param {number} maxChars - Then number of chars you want to display before trunca
 */

const ParagraphDiff = ({ content, oldContent, maxChars, ...props }) => {
  const [expanded, setExpanded] = useState(false);
  const expandable = maxChars && size(content) > maxChars;

  const lineBreakAdder = useCallback(content =>
    isString(content)
      ? content
          .split(/\r?\n|\r/)
          .map((c, i) => (
            <span style={{ overflowWrap: "anywhere" }} key={"span" + i}>
              {c}
            </span>
          ))
          .reduce(
            (acc, elem) =>
              acc === null ? (
                elem
              ) : (
                <>
                  {acc}
                  <br />
                  {elem}
                </>
              ),
            null
          )
      : content
  );

  if (expandable && !expanded) {
    content = truncate(content, { length: maxChars });
    oldContent = truncate(oldContent, { length: maxChars });
  }
  const diff = diffSentences(oldContent || "", content || "");
  const result = diff.map((part, index) => {
    if (part.added) {
      return (
        <ins className="bg-green-300 text-decoration-none" key={index}>
          {lineBreakAdder(part.value)}
        </ins>
      );
    }
    if (part.removed) {
      return (
        <del className="bg-red-200 text-decoration-none" key={index}>
          {lineBreakAdder(part.value)}
        </del>
      );
    }
    return <span key={index}>{lineBreakAdder(part.value)}</span>;
  });

  return (
    <p {...props}>
      {result}
      {expandable && (
        <Button className="p-0" color="link" onClick={() => setExpanded(!expanded)}>
          {expanded ? " (less)" : "(more)"}
        </Button>
      )}
    </p>
  );
};

ParagraphDiff.propTypes = {
  content: PropTypes.string
};

export default ParagraphDiff;
