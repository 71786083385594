import { useMemo } from "react";
import memoize from "memoizee";
import classNames from "classnames";
import qs from "qs";
import { Link } from "react-router-dom";
import {
  DEFAULT_COLORS_CLASS_MAP,
  BACKGROUND_COLORS_MAP_INDEX,
  FOREGROUND_COLORS_MAP_INDEX
} from "components/Charts/WWStackedChartBar/WWStackedChartBar";
import LinkButton from "components/Routing/LinkButton";
import ActiveProductsRequireToRender from "components/Auth/ActiveProductsRequireToRender";
import WidgetCard from "../CommonComponents/WidgetCard";
import { NoDataCallToAction } from "../CallsToActionBanners/CallsToActionBanners";
import Skeleton from "../../Skeleton";
import useTagsReportData from "hooks/data/useTagsReportData";
import { useDashboardReviewsCount } from "hooks/data/dashboardReviewHooks";
import { datetime2iso, endOfTheDay, startOfTheDay, now, daysBeforeNow } from "util/dateUtils";
import { currentContact } from "util/userUtils";

import styles from "./TopicSentimentWidget.module.scss";

const DISPLAYED_TAGS_COUNT = 6;
const HIDDEN_TAGS = new Set(["Representative"]);
const SIZE_REQUEST_PARAM = DISPLAYED_TAGS_COUNT + HIDDEN_TAGS.size;

const {
  yAxisLegendClass,
  xAxisLegendClass,
  sentimentLegendClass,
  cardBodyClass,
  chartContainerClass,
  noDataClass,
  chartClass,
  chartAndXAxisClass,
  stackedBarClass,
  stackedBarItemClass,
  skeletonClass
} = styles;

const SENTIMENTS_ORDER = [
  "negativeSentimentCount",
  "neutralSentimentCount",
  "positiveSentimentCount",
  "unknownSentimentCount"
];

const getSentimentColorMapField = dataField => dataField.replace("SentimentCount", "");

const getColorClass = (sentiment, background = false) =>
  DEFAULT_COLORS_CLASS_MAP[getSentimentColorMapField(sentiment)][
    background ? BACKGROUND_COLORS_MAP_INDEX : FOREGROUND_COLORS_MAP_INDEX
  ];

const calcAllSentiments = dataItem => SENTIMENTS_ORDER.reduce((acc, sentiment) => acc + dataItem[sentiment], 0);

const getXAxisSteps = memoize(max => {
  const roundTo5 = value => Math.trunc(value / 5) * 5;
  if (max === null) {
    return [0, 50, 100];
  } else if (max < 6) {
    return [0, max];
  } else if (max < 11) {
    return [0, 5, max];
  } else if (max < 21) {
    return [0, 5, 10, max];
  } else if (max < 2000) {
    return [0, roundTo5(max * 0.25), roundTo5(max * 0.5), roundTo5(max * 0.75), max];
  }
  return [0, roundTo5(max * 0.5), max];
});

const TopicSentimentWidgetSkeleton = () => (
  <div className={skeletonClass}>
    <div>
      {new Array(6).fill(0).map((_, index) => (
        <Skeleton key={index} height={16} width={100} />
      ))}
    </div>
    <Skeleton height={180} width={350} />
  </div>
);

export const TopicSentimentWidget = ({ data = [], isLoading, headingInfo }) => {
  const maxSentiments = useMemo(
    () => (data?.length ? data.reduce((acc, dataItem) => Math.max(acc, calcAllSentiments(dataItem)), 0) : null),
    [data]
  );

  const xAxisSteps = getXAxisSteps(maxSentiments);

  const getXAxisStepStyle = (step, index) => {
    const endOfScale = maxSentiments === null ? 100 : maxSentiments;
    return index === xAxisSteps.length - 1
      ? { right: 0 }
      : {
          left: `${Math.trunc((step / endOfScale) * 100 + (index === 0 ? 5 : 0))}%`
        };
  };

  return (
    <WidgetCard bodyClassName={cardBodyClass} heading="Topic/Sentiment" headingInfo={headingInfo}>
      <div>
        {SENTIMENTS_ORDER.map(sentiment => (
          <span key={sentiment} className={sentimentLegendClass}>
            <i className={classNames("fa fa-circle", getColorClass(sentiment))} />
            {getSentimentColorMapField(sentiment)}
          </span>
        ))}
      </div>
      <div className="d-flex flex-column justify-content-between h-100">
        {isLoading ? (
          <TopicSentimentWidgetSkeleton />
        ) : (
          <div className={classNames(chartContainerClass, { [noDataClass]: maxSentiments === null })}>
            <div className={yAxisLegendClass}>
              {maxSentiments !== null
                ? data.map(({ value }, index) => (
                    <Link
                      key={index}
                      to={{
                        pathname: "/reviews/feed",
                        search: qs.stringify({
                          entity_TOPIC: { mode: "MATCH", match: value }
                        })
                      }}
                    >
                      {value}
                    </Link>
                  ))
                : null}
            </div>
            <div className={chartAndXAxisClass}>
              <div className={chartClass}>
                {maxSentiments === null ? (
                  <NoDataCallToAction text="You have no tagged reviews" buttonText="TAG REVIEWS" link="/reviews/feed" />
                ) : (
                  data.map((item, index) => (
                    <div key={index} className={stackedBarClass}>
                      {SENTIMENTS_ORDER.map(sentiment =>
                        item[sentiment] ? (
                          <div
                            style={{ width: `${(item[sentiment] / maxSentiments) * 100}%` }}
                            key={sentiment}
                            className={classNames(stackedBarItemClass, getColorClass(sentiment, true))}
                          />
                        ) : null
                      )}
                    </div>
                  ))
                )}
              </div>
              <div className={xAxisLegendClass}>
                {xAxisSteps.map((step, index) => (
                  <div key={index} style={getXAxisStepStyle(step, index)}>
                    {step}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <LinkButton trackingAction="Topic Sentiment View full report" color="primary" to="/report/tags">
            View Full Report
          </LinkButton>
        </div>
      </div>
    </WidgetCard>
  );
};

export const TopicSentimentWidgetWithQuery = () => {
  const defaultParams = {
    startDate: datetime2iso(startOfTheDay(daysBeforeNow(30))),
    endDate: datetime2iso(endOfTheDay(now()))
  };
  const { data, isLoading } = useTagsReportData({
    params: {
      ...defaultParams,
      size: SIZE_REQUEST_PARAM,
      sort: "count,desc",
      type: "TOPIC",
      contactId: currentContact().id
    }
  });
  const filteredData = useMemo(
    () => data?.filter(({ value }) => !HIDDEN_TAGS.has(value)).slice(0, DISPLAYED_TAGS_COUNT),
    [data]
  );
  const reviewsCount = useDashboardReviewsCount().data;
  const headingInfo =
    reviewsCount !== undefined ? `${reviewsCount.havingEntities} of ${reviewsCount.totalReviews} Reviews Tagged` : "";

  return (
    <ActiveProductsRequireToRender products={["REVIEW_IMPORT"]}>
      <TopicSentimentWidget data={filteredData} isLoading={isLoading} headingInfo={headingInfo} />
    </ActiveProductsRequireToRender>
  );
};

export default TopicSentimentWidgetWithQuery;
