import React, { useState, useCallback, useMemo, useEffect } from "react";
import { noop, isUndefined } from "lodash";
import TextEditor from "components/Form/TextEditor";
import WWButton from "components/Buttons/WWButton";

export default ({ onFinish = noop, onSave = noop, initialContent, additionalControls }) => {
  const [content, setContent] = useState("");
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!isUndefined(initialContent)) {
      setContent(initialContent);
    }
  }, [initialContent]);

  const save = useMemo(
    () => async (publish = false) => {
      setProcessing(true);
      const result = await onSave(publish, content);
      setProcessing(false);
      onFinish();
      return result;
    },
    [content, setProcessing, onFinish]
  );

  const onSaveClick = useCallback(() => save(), [save]);
  const onSaveAndPublishClick = useCallback(() => save(true), [save]);

  return (
    <TextEditor disabled={processing} placeholder="Enter your reply here" onChange={setContent} value={content}>
      {additionalControls}
      <WWButton size="sm" color="secondary" onClick={onFinish} disabled={processing} className="me-1 mt-1">
        Cancel
      </WWButton>
      <WWButton size="sm" color="warning" className="me-1 mt-1" disabled={!content || processing} onClick={onSaveClick}>
        Save
      </WWButton>
      <WWButton
        size="sm"
        color="warning"
        className="me-1 mt-1"
        disabled={!content || processing}
        onClick={onSaveAndPublishClick}
      >
        Save {"&"} Publish
      </WWButton>
    </TextEditor>
  );
};
