import React from "react";
import { Slab } from "components/Charts/Slab";
import { Card } from "components/Card";
import { Col, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import HelpIcon from "components/Misc/HelpIcon";

import { useCurrentAgency } from "hooks/agencyHooks";
import { withReportFilterQuery } from "components/HOCs/ReportWithFilters";

const reviewSummeryRenderer = key => {
  return key === "id"
    ? ({ id }) => <span className="flex-grow-0 text-nowrap text-muted">{id}</span>
    : key === "total"
      ? ({ total }) => (
          <h2 className="m-0 d-flex justify-content-center align-items-center flex-grow-1">
            {total === null ? "N/A" : total}
          </h2>
        )
      : () => null;
};

export const Summary = withReportFilterQuery({
  url: "/invitereport/stats"
})(({ data: data }) => {
  const currentAgency = useCurrentAgency();

  if (!data) return null;

  return (
    <Row className="my-1 no-pagebreak-inside">
      <Col>
        <SimpleBar>
          <div className="flex-nowrap d-flex flex-row justify-content-start">
            <Card className="mx-2 flex-shrink-0 flex-grow-1">
              <div className="px-2">
                <Slab
                  id={
                    <div>
                      Review Volume
                      <HelpIcon className="px-4" icon="info">
                        Volume reporting is not affected by campaign selection
                      </HelpIcon>
                    </div>
                  }
                  total={data.reviewVolume}
                  renderer={reviewSummeryRenderer}
                />
              </div>
            </Card>
            <Card className="mx-2 flex-shrink-0 flex-grow-1">
              <div className="px-2">
                <Slab id={"Review Requests Sent"} total={data.reviewRequestsSent} renderer={reviewSummeryRenderer} />
              </div>
            </Card>
            {currentAgency?.name !== "OhMD" && (
              <>
                <Card className="mx-2 flex-shrink-0 flex-grow-1">
                  <div className="px-2">
                    <Slab
                      id={"Review Requests Opened"}
                      total={data.reviewRequestsOpen}
                      renderer={reviewSummeryRenderer}
                    />
                  </div>
                </Card>
                <Card className="mx-2 flex-shrink-0 flex-grow-1">
                  <div className="px-2">
                    <Slab
                      id={"Review Landing Page Clicks"}
                      total={data.reviewLandingPageClicks}
                      renderer={reviewSummeryRenderer}
                    />
                  </div>
                </Card>
              </>
            )}
          </div>
        </SimpleBar>
      </Col>
    </Row>
  );
});

export default Summary;
