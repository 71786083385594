import moment from "moment";

export const defaultTimespansOptions = [
  [24, "hours"],
  [7, "days"],
  [30, "days"],
  [1, "year"]
];

/**
 * @param {[number, "day" | "week" | "month" | "year"]} timespan
 * @returns {[string, string]}
 */
export const timespan2datesConvert = ([duration, unit], converter = v => v.toISOString(true)) => {
  const now = moment();
  const endDate = unit === "year" ? now.clone().endOf("days") : now.clone().endOf(unit);
  const startDate = endDate
    .clone()
    .subtract(duration - 1, unit)
    .startOf(unit);
  return [converter(startDate), converter(endDate)];
};
