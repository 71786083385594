import { FormGroup, InputGroup } from "reactstrap";
import HorizontalSelectField from "../../../Form/HorizontalSelectField";
import { SUB_USECASES, USECASES } from "../../../../data/options";
import InputField from "../../../Form/InputField";
import { useCallback } from "react";
import { INVITE_LANDING_PAGE_PREFIX } from "../../../../util/constants";

const DEFAULT_SAMPLE_1 = `Hi [CUSTOMER FIRST NAME], it's [EMPLOYEE FIRST NAME] from [LOCATION NAME]. Have a moment to leave us a review? To leave a review, click here ${INVITE_LANDING_PAGE_PREFIX}123456. Reply STOP to opt-out.`;
const DEFAULT_SAMPLE_2 = "This is not a monitored line. Please give us a call at [LOCATION PHONE NUMBER].";
const DEFAULT_DESCRIPTION =
  "Customers are sent follow-up messages once a transaction has completed to invite them to leave a review for the business.";

function CampaignEditor({ campaign, onChange, messageFlowNeedsReview }) {
  const setCampaignField = useCallback(
    event => {
      const update = { [event.target.name]: event.target.value };
      onChange(Object.assign({}, campaign, update));
    },
    [onChange, campaign]
  );

  return (
    <>
      <h4 className="pb-2">Campaign Info</h4>
      <InputGroup>
        <HorizontalSelectField
          name="usecase"
          formFieldClass="flex-grow-1"
          value={campaign.usecase}
          simpleValue={true}
          label="Use case"
          onChange={setCampaignField}
          options={USECASES}
          inline={false}
          tip="You shouldn't need to change these"
        />
        <HorizontalSelectField
          name="subUsecases"
          formFieldClass="flex-grow-1"
          value={campaign.subUsecases}
          simpleValue={true}
          label="Sub-use cases"
          onChange={setCampaignField}
          options={SUB_USECASES}
          inline={false}
          isMulti={true}
        />
      </InputGroup>
      <InputField
        name="sample1"
        label="Sample"
        type="textarea"
        value={campaign.sample1}
        inline={false}
        onChange={setCampaignField}
      />
      <InputField
        name="description"
        className="flex-grow-1"
        type="textarea"
        value={campaign.description}
        label="Description"
        onChange={setCampaignField}
        disabled={false}
        inline={false}
      />
      <InputField
        name="messageFlow"
        className="flex-grow-1"
        type="textarea"
        value={campaign.messageFlow}
        label="Opt-in Steps"
        onChange={setCampaignField}
        disabled={false}
        inline={false}
      />
      {messageFlowNeedsReview && (
        <FormGroup check className="my-4">
          <InputField
            name="messageFlowReviewed"
            className="flex-grow-1"
            type="checkbox"
            checked={campaign.messageFlowReviewed || false}
            onChange={() =>
              setCampaignField({ target: { name: "messageFlowReviewed", value: !campaign.messageFlowReviewed } })
            }
          />
          <span>I have reviewed this information against the customer's website.</span>
        </FormGroup>
      )}
    </>
  );
}

export const getEmptyCampaign = (customer, previousOrder) => {
  return {
    usecase: "LOW_VOLUME",
    subUsecases: ["CUSTOMER_CARE", "POLLING_VOTING"],
    sample1: DEFAULT_SAMPLE_1.replace("[LOCATION NAME]", customer.companyName),
    sample2: DEFAULT_SAMPLE_2,
    vertical: "RETAIL",
    description: DEFAULT_DESCRIPTION,
    ...(previousOrder?.campaign || null),
    campaignId: null,
    id: null
  };
};

export default CampaignEditor;
