import React from "react";
import ContactsWidget from "components/Contacts/ContactsWidget";
import TextSkeleton from "components/Skeleton/TextSkeleton/TextSkeleton";
import { useCustomerContacts } from "hooks/data/useCustomerContacts";

const ChangeSignatoryWidget = ({ customerId, onSignatoryChange }) => {
  const { data, isLoading } = useCustomerContacts({ customerId });
  const contacts = data?._embedded?.contacts;

  return isLoading ? (
    <TextSkeleton rows={5} />
  ) : (
    <>
      <h4 className="text-center mb-4">Pick a new signatory below</h4>
      {!!contacts && (
        <ContactsWidget contacts={contacts} onClick={onSignatoryChange} disable={["title", "name", "contact"]} />
      )}
    </>
  );
};

export default ChangeSignatoryWidget;
