import { useGet } from "../dataHooks";
import { useQuery } from "react-query";
import { isNil } from "lodash";

/**
 * @param {{
 *  customerId: number;
 * }}
 * @returns {import("react-query").UseQueryResult<{_embedded: {contacts: CustomerContacts[]}}>
 */
export const useCustomerContacts = ({ customerId }) => {
  const queryKey = `approval/approvals/${customerId}/contacts`;
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { enabled: !isNil(customerId) });
};
