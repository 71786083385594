import styles from "./LearningCenter.module.scss";

const LearningCenter = () => {
  return (
    <div>
      <div className={`wistia_channel wistia_async_2oz8odywyl mode=inline ${styles.learningCenter}`} />
    </div>
  );
};

export default LearningCenter;
