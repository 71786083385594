import LinkButton from "components/Routing/LinkButton";
import classNames from "classnames";

import styles from "./CallsToActionBanners.module.scss";

const {
  improveBannerClass,
  buttonsContainerClass,
  bannerTextClass,
  buttonClass,
  outlineButtonClass,
  startNewBannerClass
} = styles;

export const ImproveBanner = ({ heading, text, updateButton, editButton, className }) => (
  <div className={classNames(improveBannerClass, className)}>
    <div>
      <h5>{heading}</h5>
      <div className={bannerTextClass}>{text}</div>
    </div>
    <div className={buttonsContainerClass}>
      {!!updateButton && (
        <LinkButton color="primary" className={buttonClass} to={updateButton.to}>
          {updateButton.text}
        </LinkButton>
      )}
      {!!editButton && (
        <LinkButton className={outlineButtonClass} to={editButton.to}>
          {editButton.text}
        </LinkButton>
      )}
    </div>
  </div>
);

export const NoDataCallToAction = ({ text, buttonText, link, className }) => (
  <div className={classNames(startNewBannerClass, className)}>
    <div>{text}</div>
    <LinkButton className={outlineButtonClass} to={link}>
      {buttonText}
    </LinkButton>
  </div>
);
