import WWButton from "components/Buttons/WWButton";
import styles from "./BrowserWarningBanner.module.scss";
import { usePersistantViewState } from "hooks/stateHooks";
import { VIEW_STATE_STORAGE_KEYS } from "data/storageKeys";

const { banner, contents, buttonWithIcon } = styles;

export default function BrowserWarningBanner({ browser }) {
  const [isOpen, setIsOpen] = usePersistantViewState(VIEW_STATE_STORAGE_KEYS.SHOW_IE11_BANNER, browser === "IE");
  return (
    <>
      {isOpen && (
        <div className={banner}>
          <h5 className={contents}>Widewail is no longer actively supporting IE11; Please use an updated browser.</h5>
          <WWButton
            data-testid="banner-button-test"
            className={buttonWithIcon}
            onClick={() => setIsOpen(false)}
            iconClass="fa fa-close"
          />
        </div>
      )}
    </>
  );
}
