import qs from "querystring";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { createHubspotInfo } from "../../api/hubspotApi";
import { Card } from "../../components/Card";
import Footer from "../../components/Footer/Footer";
import AjaxLoader from "../../components/Misc/AjaxLoader";
import StatusMessages from "../../components/Misc/StatusMessages";
import { LOCAL_STORAGE_KEYS } from "../../data/storageKeys";

export const HubspotOAuthResult = () => {
  const history = useHistory();
  const code = useMemo(() => qs.parse(window.location.search.slice(1)).code, []);
  const awaitedRedirectCustomer = localStorage.getItem(LOCAL_STORAGE_KEYS.AWAITED_REDIRECT_CUSTOMER);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (!!code && !!awaitedRedirectCustomer) {
      createHubspotInfo(awaitedRedirectCustomer, code)
        .then(() => window.location.replace(`/customers/${awaitedRedirectCustomer}/siteIntegrations?site=HUBSPOT`))
        .catch(setErrors);
    }
  }, [code, history, awaitedRedirectCustomer]);

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center position-absolute"
      style={{ top: 0, bottom: 0, left: "10vw", right: "10vw" }}
    >
      <div className="mt-auto">
        {errors.length > 0 ? (
          <StatusMessages errors={errors} />
        ) : !code ? (
          <Card>
            Something went wrong with HubSpot authorization. Please try again or contact support if the problem
            continues.
          </Card>
        ) : !awaitedRedirectCustomer ? (
          <Card>
            Authenticated to an unknown Widewail location. Please try again or contact support if the problem continues.
          </Card>
        ) : (
          <Card>
            <div className="mx-auto mb-3">
              <AjaxLoader />
            </div>
            Please wait while we connect your location to HubSpot.
          </Card>
        )}
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};
