import React from "react";
import { withFeature } from "components/Auth/Authorization";
import { Button } from "reactstrap";

import { permissions } from "components/Auth/permissions";
import withAuthorization from "components/Auth/Authorization";
import { FileUploadButton } from "components/Form/FileUploadButton";
import { BH_MANAGER_UPLOAD } from "data/featureFlags";

import { useS3FileUploadWithPostProcess } from "hooks/data/useS3FileUploadWithPostProcess";

export const BhManagerUpload = () => {
  const uploadPath = "/contacts/uploadBHCsvForManagers",
    postProcessPath = "/contacts/processBHCsvForManagers",
    successMessage = "CSV is processing. You will recieve an email when processing is complete",
    upload = useS3FileUploadWithPostProcess({ uploadPath, postProcessPath, successMessage });

  return (
    <FileUploadButton accept=".csv" onChange={upload}>
      <Button>
        <i className="fa fa-upload" /> Upload Managers CSV
      </Button>
    </FileUploadButton>
  );
};

export default withFeature([BH_MANAGER_UPLOAD])(withAuthorization([permissions.AGENCY_ADMIN])(BhManagerUpload));
