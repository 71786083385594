import { useCallback } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

{
  /*
Promise wrapped Facebook API calls adapted from
https://gist.github.com/recca0120/c4fa99a358460e8189ab04907e8e96e4
*/
}
function fbApi(...params) {
  return new Promise(async (resolve, reject) => {
    const callback = response => {
      if (response.error) {
        reject(response.error);
      } else {
        resolve(response);
      }
    };

    if (params.length > 3) {
      params = params.slice(0, 3);
    }

    params.push(callback);

    window.FB.api(...params);
  });
}

export const useCurrentFacebookUser = () => {
  const facebookState = useSelector(state => state.facebook);
  const facebookUser = facebookState.user;
  return facebookUser;
};

/**
 * Load ad accounts the currently user has access to in the specified business.
 *
 * @param businessId
 * @param opts
 * @returns {UseQueryResult<{name: *, id: *, business_id: *}[], unknown>}
 */
export const useFacebookAdAccounts = (businessId, opts = {}) => {
  const facebookState = useSelector(state => state.facebook);
  const filters = [];
  if (opts.filter?.name) {
    filters.push({ field: "name_or_id_or_owner_obo_business", operator: "CONTAIN", value: opts.filter.name });
  }
  const queryFn = useCallback(
    () =>
      Promise.all([
        fbApi(`${businessId}/client_ad_accounts`, {
          fields: "id,name,business",
          filtering: filters,
          limit: 100
        }),
        fbApi(`${businessId}/owned_ad_accounts`, { fields: "id,name,business", filtering: filters, limit: 100 })
      ]).then(accounts =>
        // flatten the 2 calls into 1 array unique by business id
        [
          ...new Map(
            accounts
              .flatMap(account => account.data)
              .map(({ id, name, business }) => ({ id, name, business_id: business.id }))
              .map(item => [item.id, item])
          ).values()
        ]
      ),
    [businessId]
  );
  return useQuery([facebookState?.user?.id, businessId, "adaccounts", opts], queryFn, {
    enabled: !!businessId && !!facebookState?.user?.id
  });
};

/**
 * Load Facebook Business Manager accounts the currently logged in user has access to
 *
 * @returns {UseQueryResult<unknown, unknown>}
 */
export const useFacebookBusinesses = () => {
  const facebookState = useSelector(state => state.facebook);
  const queryFn = useCallback(() => fbApi("me/businesses", { fields: "id,name", limit: 100 }).then(res => res.data), [
    facebookState?.user?.id
  ]);
  return useQuery(`${facebookState?.user?.id}/businesses`, queryFn, {
    enabled: !!facebookState?.user?.id
  });
};

/**
 * Load pages the currently logged in user has access to in the specified business manager
 *
 * @param businessId
 * @param opts
 * @returns {UseQueryResult<unknown, unknown>}
 */
export const useFacebookBusinessPages = (businessId, opts = {}) => {
  const facebookState = useSelector(state => state.facebook);
  const filters = [];
  if (opts.filter?.name) {
    filters.push({ field: "name_or_id_or_owner_business", operator: "CONTAIN", value: opts.filter.name });
  }
  const queryFn = useCallback(
    () =>
      Promise.all([
        fbApi(`${businessId}/owned_pages`, { fields: "id,name,access_token", filtering: filters, limit: 100 }),
        fbApi(`${businessId}/client_pages`, { fields: "id,name,access_token", filtering: filters, limit: 100 })
      ]).then(responses => [
        ...new Map(responses.flatMap(response => response.data).map(item => [item.id, item])).values()
      ]),
    [businessId, opts]
  );
  return useQuery([facebookState?.user?.id, businessId, "owned_pages", opts], queryFn, {
    enabled: !!businessId && !!facebookState?.user?.id
  });
};

/**
 * Load pages the currently logged in user has access to in the specified business manager
 *
 * @param businessId
 * @param opts
 * @returns {UseQueryResult<unknown, unknown>}
 */
export const useAllFacebookBusinessPages = businessId => {
  const facebookState = useSelector(state => state.facebook);
  const queryFn = useCallback(async () => {
    const pages = [];
    let after = null;
    for (let endpoint of ["owned_pages", "client_pages"]) {
      do {
        const res = await fbApi(`${businessId}/${endpoint}`, { fields: "id,name,access_token", limit: 100, after });
        console.log(res);
        after = res?.paging?.cursors?.after;
        pages.push(...res.data);
      } while (after != null);
    }
    return [...new Map(pages.map(item => [item.id, item])).values()];
  }, [businessId]);
  return useQuery([facebookState?.user?.id, businessId, "owned_pages"], queryFn, {
    enabled: !!businessId && !!facebookState?.user?.id
  });
};
