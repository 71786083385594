import { path } from "ramda";
import { useCallback, useState, useMemo } from "react";
import { Button, Form } from "reactstrap";
import InputField from "../../../components/Form/InputField";
import CopyButton from "../../../components/Buttons/CopyButton";
import { bySource } from "../../../data/sites";
import { useStateThroughPipe } from "../../../hooks/utilHooks";
import { noop } from "lodash";
import SharedCredentialsSelector from "components/SharedCredentials/SharedCredentialsSelector";
import PasswordInput from "components/Auth/PasswordInput";
import { validEmail } from "../../../util/validators";

const { source } = bySource("UBERALL");

export default class UberallAdminLinking {
  field() {
    return "uberallInfo";
  }

  add() {
    return UberallLinkAdd;
  }

  edit() {
    return UberallLinkEdit;
  }
}

const nativeInputEventPipe = path(["target", "value"]);

const UberallLinkAdd = ({ onSave }) => {
  const [sharedCredentials, setSharedCredentials] = useState();
  const [locationId, setLocationId] = useStateThroughPipe("", nativeInputEventPipe);
  const [apiKey, setApiKey] = useStateThroughPipe("", nativeInputEventPipe);
  const [negativeApprovalUser, setNegativeApprovalUser] = useStateThroughPipe("", nativeInputEventPipe);

  const allFilled = useMemo(() => {
    if (sharedCredentials) {
      return !!locationId;
    } else {
      return !!(apiKey && locationId);
    }
  }, [sharedCredentials, locationId, apiKey]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      onSave({ apiKey: apiKey, locationId: locationId, sharedCredentials, source });
    },
    [onSave, apiKey, locationId, sharedCredentials]
  );

  return (
    <Form onSubmit={onSubmit}>
      <InputField inline={false} label="Location id" value={locationId} onChange={setLocationId} />
      <SharedCredentialsSelector label="Shared credentials" source="UBERALL" onChange={setSharedCredentials} />
      {!sharedCredentials && (
        <>
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Private Api Key"
            value={apiKey}
            onChange={setApiKey}
            data-lpignore={true}
          />
          <InputField
            inline={false}
            disabled={sharedCredentials}
            label="Negative Approval User (Email)"
            valid={negativeApprovalUser ? validEmail(negativeApprovalUser) : false}
            value={negativeApprovalUser}
            onChange={setNegativeApprovalUser}
          />
        </>
      )}
      <Button type="submit" color="primary" disabled={!allFilled}>
        Link
      </Button>
    </Form>
  );
};

const UberallLinkEdit = ({ info }) => {
  return (
    <>
      <InputField
        name="sharedCredentials"
        label="Shared credentials"
        value={info.sharedCredentials?.name}
        disabled={true}
      />
      <InputField inline={false} disabled label="Location id" value={info.locationId} onChange={noop} />
      <CopyButton className="ml-1" text={info.locationId} />
      <InputField inline={false} disabled={true} label="Private Api Key" value={info.apiKey} onChange={noop} />
      <InputField
        inline={false}
        disabled={true}
        label="Negative Approval User (Email)"
        value={info.negativeApprovalUser}
        onChange={noop}
      />
    </>
  );
};
