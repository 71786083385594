import { useCallback, useMemo, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import isDeepEqual from "fast-deep-equal/react";
import { convertPagedResourcesResponse, evolveDates, evolveToLocalDates, useGet, usePost, usePut } from "../dataHooks";
import { pipe } from "ramda";
import { isArray } from "lodash";
import { isNil } from "lodash";
import { useLocationQueryParamSetter, useLocationQueryParamValue } from "hooks/stateHooks";

const BASE_URL = "/campaigns";
const additionalDateFields = ["startDate", "endDate"];
const normalizeCampaignResponse = evolveDates({ additionalFields: additionalDateFields });

/**
 *
 * @param {{
 *  page?: number;
 *  size?: number;
 *  enabled?: boolean;
 *  include?: {string} - force a campaign to be included in the results
 * }} param0
 * @returns
 */
export const useInviteCampaigns = ({
  page = 0,
  size = 10,
  enabled = true,
  byName = false,
  include,
  defaultSearchParams = {},
  ...params
}) => {
  const paramRef = useRef(params);
  if (!isDeepEqual(paramRef.current, params)) {
    paramRef.current = params;
  }
  const url = byName ? `${BASE_URL}/names` : `${BASE_URL}`;
  const query = useMemo(() => ({ page, size, ...params }), [page, size, paramRef.current]);
  const queryKey = useMemo(() => [url, query], [url, query]);
  // "include" is deliberately left out of the query key
  const [get] = useGet(url, { params: { ...query, ...defaultSearchParams, include } });
  const queryRun = useCallback(
    () => get().then(convertPagedResourcesResponse({ dataKey: "campaignListDtoes", dateFields: additionalDateFields })),
    [get]
  );
  return useQuery(queryKey, queryRun, {
    enabled: enabled,
    keepPreviousData: true
  });
};

/**
 *
 * @param {{string}} id
 * @returns
 */
export const useInviteCampaign = id => {
  const queryKey = useMemo(() => `${BASE_URL}/${id}`, [id]);
  const [get] = useGet(queryKey);
  return useQuery(queryKey, () => get().then(normalizeCampaignResponse), {
    enabled: !!id
  });
};

export const useInviteCampaignWithInitialData = id => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => `${BASE_URL}/${id}`, [id]);
  const [get] = useGet(queryKey);
  return useQuery(queryKey, () => get().then(normalizeCampaignResponse), {
    enabled: !!id,
    initialData: () => {
      // check the query cache for loading all campaigns to see if we already have
      // this campaign
      const data = queryClient.getQueryData({
        predicate: q => isArray(q.queryKey) && q.queryKey.length > 0 && q.queryKey[0] === BASE_URL
      });
      return data?.[0].find(c => c.id === id);
    }
  });
};

export const useCampaignClone = () => {
  const post = usePost();
  const queryClient = useQueryClient();
  return useMutation(
    id =>
      post(`${BASE_URL}/${id}/clone`, {
        data: { id }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(BASE_URL)
    }
  );
};

export const useCampaignCreate = () => {
  const post = usePost();
  const queryClient = useQueryClient();
  return useMutation(
    campaign =>
      post(`${BASE_URL}`, {
        data: campaign
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(BASE_URL)
    }
  );
};

export const useCampaignConfigurationUpdate = id => {
  const post = usePut();
  const queryClient = useQueryClient();
  return useMutation(
    configuration =>
      post(`${BASE_URL}/${id}/configuration`, {
        data: configuration
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(`${BASE_URL}/${id}`)
    }
  );
};

export const useCampaignUpdate = id => {
  const post = usePut();
  const queryClient = useQueryClient();
  return useMutation(
    pipe(evolveToLocalDates({ fields: ["startDate", "endDate"] }), campaign =>
      post(`${BASE_URL}/${campaign.id}`, {
        data: campaign
      })
    ),
    {
      onSuccess: () => queryClient.invalidateQueries(`${BASE_URL}`)
    }
  );
};

export const useAutomatedCampaigns = ({ ...params }) => {
  const paramRef = useRef(params);
  if (!isDeepEqual(paramRef.current, params)) {
    paramRef.current = params;
  }
  const query = useMemo(() => ({ ...params }), [paramRef.current]);
  const queryKey = useMemo(() => ["/campaignFlows", query], [query]);
  const [get] = useGet("/campaignFlows", { params: query });
  const queryRun = useCallback(() => get().then(data => data), [get]);
  return useQuery(queryKey, queryRun, {
    enabled: !isNil(params?.customerId) || !isNil(params?.agencyId),
    keepPreviousData: true
  });
};

export const useAutomatedCampaignsUpdate = () => {
  const post = usePut();
  const queryClient = useQueryClient();
  return useMutation(
    automatedCampaigns =>
      post(`/campaignFlows/${automatedCampaigns.id}`, {
        data: automatedCampaigns
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(`/campaignFlows`)
    }
  );
};

const CAMPAIGN_NAME_QUERY_PARAM = "campaignName";

export const useCampaignNameParameter = () => {
  const campaign = useLocationQueryParamValue(CAMPAIGN_NAME_QUERY_PARAM);
  return { campaign };
};

export const useCampaignNameParameterSetter = (localStorageKey = null) => {
  return useLocationQueryParamSetter(CAMPAIGN_NAME_QUERY_PARAM);
};
