import { Col, Row } from "reactstrap";
import VolumeWidget from "../Reports/VolumeWidget";
import ReportWithFilters, { getComparisonsOptions, COMPARISON_OPTIONS } from "components/HOCs/ReportWithFilters";
import SummaryWidget from "./Widgets/SummaryWidget";
import RatingWidget from "./Widgets/RatingWidget";
import NegativeReviewsWidget from "./Widgets/NegativeReviewsWidget";
import LifetimeAverageTrend from "./Widgets/LifetimeAverageTrend";
import GMBReport from "./Widgets/GMBReportWidget";
import PrintScreenButton from "components/Misc/PrintScreenButton";
import WWButton from "components/Buttons/WWButton";

import { useNotes } from "components/Widgets/Notes";
import { withAgency } from "components/Auth/Authorization";

import { datetime2iso, endOfTheDay, startOfTheDay, now, daysBeforeNow } from "util/dateUtils";
import { DashboardWidget } from "components/Widgets/DashboardWidget";
import { getReviewSourceFilterConfig } from "components/FacetedSearch/FilterRecipes";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

const filtersFn = ({ isAdmin, filterValues = {} }) => [
  getAccountFilterConfig(),
  {
    name: "location",
    label: "Location",
    type: "allLocations",
    required: isAdmin && !filterValues?.account?.id,
    minLength: 0,
    primaryBar: {
      position: "LEFT",
      className: "d-md-flex auto-width d-none"
    },
    secondaryBar: {
      className: "d-flex d-md-none"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "dateRange",
    label: "Date range",
    type: "dates",
    queryParams: ["startDate", "endDate"],
    required: true,
    defaultValue: {
      startDate: datetime2iso(startOfTheDay(daysBeforeNow(30))),
      endDate: datetime2iso(endOfTheDay(now()))
    },
    primaryBar: {
      position: "LEFT",
      className: "d-md-flex auto-width d-none"
    },
    chip: {
      position: "NONE"
    }
  },
  getReviewSourceFilterConfig(),
  {
    name: "compareMode",
    label: "Compare to...",
    type: "buttonDropdown",
    required: true,
    showLabelInMenu: true,
    clearable: false,
    defaultValue: COMPARISON_OPTIONS.RELATIVE,
    options: getComparisonsOptions(filterValues?.dateRange),
    placeholder: "Compare to...",
    primaryBar: {
      position: "LEFT",
      className: "d-none d-md-flex comparison-selector"
    },
    chip: {
      position: "NONE"
    }
  }
];

export const SinglePointReport = () => {
  const reportSections = [
      <SummaryWidget />,
      <VolumeWidget onlyAssignedLocations={false} />,
      <RatingWidget />,
      <LifetimeAverageTrend />,
      <GMBReport />,
      <NegativeReviewsWidget />
    ],
    [notesComp, { notes, isEditing: isEditingNotes, toggle: toggleNotes }] = useNotes(),
    NotesButton = withAgency("Lithia")(() => (
      <WWButton onClick={toggleNotes} color={isEditingNotes ? "success" : "warning"} iconClass="fa fa-sticky-note">
        {isEditingNotes ? "Finish Editing" : "Edit Notes"}
      </WWButton>
    ));

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <ReportWithFilters
        filtersFn={filtersFn}
        filterBarChildren={
          <>
            <PrintScreenButton color="warning" className="mb-0" />
            <NotesButton />
          </>
        }
        children={
          <>
            {(notes || isEditingNotes) && <DashboardWidget>{notesComp}</DashboardWidget>}
            {reportSections.map((rs, i) => (
              <Row className="no-pagebreak-inside" key={i}>
                <Col>{rs}</Col>
              </Row>
            ))}
          </>
        }
      />
    </div>
  );
};

export default SinglePointReport;
