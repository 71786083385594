import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { ImproveBanner } from "../CallsToActionBanners/CallsToActionBanners";
import ActiveProductsRequireToRender from "components/Auth/ActiveProductsRequireToRender";
import Skeleton from "components/Skeleton";
import { useGet } from "hooks/dataHooks";
import DonutChartWidget from "./DonutChartWidget";

import styles from "./InviteOverviewWidget.module.scss";
import { currentContact } from "util/userUtils";
import { inviteCustomerProductsKeys } from "data/products";
import { get } from "api/apiClient";
import useCustomers from "hooks/customerHooks";

const { chartContentTextClass } = styles;
const INVITES_REPORT_LINK = "/report/invite";
const EDIT_BUTTON_DESCRIPTION = { text: "SEND AN INVITE", to: "dashboardNew#leadCreate" };

export const useInviteOverviewData = () => {
  const path = "/dash/inviteOverview";
  const [get] = useGet(path);
  return useQuery(path, get);
};

export const InviteOverviewWidget = () => {
  const { data, isLoading } = useInviteOverviewData();
  const query = useCustomers({
    sort: "companyName,asc",
    projection: "customerName",
    matchAllProducts: false,
    product: inviteCustomerProductsKeys,
    status: "ACTIVE"
  });
  const firstLocation = query.data?.customers?.[0];
  const updateButtonDescription = {
    text: "UPDATE CAMPAIGNS",
    to: `invite/campaigns${
      query.data?.customers?.length === 1
        ? `?customerId[companyName]=${firstLocation?.companyName}&customerId[id]=${firstLocation?.id}`
        : ""
    }`
  };
  const renderValueDescription = useCallback(
    ({ total }) => (
      <>
        <div className={chartContentTextClass}>Total Invites Sent</div>
        {total > 0 && <Link to={INVITES_REPORT_LINK}>View all</Link>}
      </>
    ),
    []
  );

  return (
    <ActiveProductsRequireToRender products={["REVIEW_INVITE"]}>
      <DonutChartWidget
        total={data?.totalInvitations}
        value={data?.openedInvitations}
        ctr={data?.ctr}
        isLoading={isLoading}
        heading="Invite Overview"
        headingInfo="Last 30 Days"
        ctrName="Invites CTR"
        renderValueDescription={renderValueDescription}
      >
        {isLoading ? (
          <Skeleton height={150} />
        ) : (
          <ImproveBanner
            heading="Improve your CTR and send Invites"
            text="A good click trough rate is between 12% - 15%. Customize your campaigns and start sending out Invites!"
            updateButton={updateButtonDescription}
            editButton={EDIT_BUTTON_DESCRIPTION}
          />
        )}
      </DonutChartWidget>
    </ActiveProductsRequireToRender>
  );
};

export default InviteOverviewWidget;
