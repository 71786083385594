import React, { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { Col, Row } from "reactstrap";
import classNames from "classnames";
import { get, isEqual } from "lodash";
import SelectField from "components/Form/SelectField";
import WWButton from "components/Buttons/WWButton";
import RemoveFieldControl from "components/Form/RemoveFieldControl";
import FieldLabel from "components/Form/FieldLabel";
import { FormContext } from "components/Form/Form";
import { parseFormFieldName } from "util/stringUtils";
import { HOURS_OPTIONS, getLabelFromHoursValue, parseHourInput, sortHoursOptions, getHourOption } from "util/dateUtils";

const DayHoursForm = ({ initialValues, values, hoursField, dayIndex }) => {
  const context = useContext(FormContext);
  const getFormHoursPath = useCallback(index => `${hoursField}[${dayIndex}].hours[${index}]`, [hoursField, dayIndex]);

  const initialHours = useMemo(() => {
    const path = [...parseFormFieldName(hoursField), dayIndex];
    return Object.keys(get(initialValues, path) || []).reduce((acc, key) => {
      if (key === "hours") {
        return acc.concat(get(initialValues, [...path, key]));
      }
      return acc;
    }, []);
  }, [initialValues]);

  const [dayHoursFields, setDayHoursFields] = useState([]);
  const [hoursOptions, setHoursOptions] = useState({ open: HOURS_OPTIONS, close: HOURS_OPTIONS });
  const [open24HoursIndices, setOpen24HoursIndices] = useState(new Set());

  useEffect(() => {
    const newSet = new Set();
    dayHoursFields.forEach((field, index) => {
      const open24hours =
        isEqual(get(values, parseFormFieldName(`${field}.openHour`)), { hour: 0 }) &&
        isEqual(get(values, parseFormFieldName(`${field}.closeHour`)), { hour: 24 });
      if (open24hours) {
        newSet.add(index);
      }
    });
    setOpen24HoursIndices(newSet);
  }, [dayHoursFields, values]);

  useEffect(() => {
    // seeking options created by user in loaded data
    const customOptions = initialHours.reduce((acc, { openHour, closeHour }) => {
      const findAndPushCustomOption = (field, initialHourValue) => {
        if (
          initialHourValue.hour < 24 &&
          !hoursOptions[field].find(option => isEqual(option.value, initialHourValue))
        ) {
          acc[field].push({
            value: initialHourValue,
            label: getLabelFromHoursValue(initialHourValue.hour, initialHourValue.min)
          });
        }
      };
      findAndPushCustomOption("open", openHour);
      findAndPushCustomOption("close", closeHour);
      return acc;
    }, hoursOptions);

    if (customOptions) {
      setHoursOptions(
        Object.fromEntries(Object.entries(customOptions).map(pair => [pair[0], pair[1].sort(sortHoursOptions)]))
      );
    }

    setDayHoursFields(
      initialHours?.length ? initialHours.map((_, index) => getFormHoursPath(index)) : [getFormHoursPath(0)]
    );
  }, [initialHours]);

  const addDayHours = useCallback(
    () => setDayHoursFields(dayHoursFields.concat([getFormHoursPath(dayHoursFields.length)])),
    [dayHoursFields]
  );
  const removeDayHours = useCallback(
    field => {
      setDayHoursFields(dayHoursFields.filter(dayHoursField => dayHoursField !== field));
    },
    [dayHoursFields]
  );

  const formatCreateLabel = useCallback(inputValue => inputValue.toUpperCase(), []);

  const isValidNewHourOption = useCallback(inputValue => {
    const { valid } = parseHourInput(inputValue);
    return valid;
  }, []);

  const getHandleCreateOpenHours = useMemo(
    () => name => inputValue => {
      const hoursTypeKey = name.includes("openHour") ? "open" : "close";
      const { hour, min } = parseHourInput(inputValue);
      const newOption = {
        value: {
          hour,
          min
        },
        label: getLabelFromHoursValue(hour, min)
      };
      if (!hoursOptions[hoursTypeKey].find(option => isEqual(option, newOption))) {
        setHoursOptions({
          ...hoursOptions,
          [hoursTypeKey]: [...hoursOptions[hoursTypeKey], newOption].sort(sortHoursOptions)
        });
      }
      context.setSelectValue({
        target: { name, value: newOption }
      });
    },
    [context, hoursOptions]
  );

  const setHourSelectValue = useCallback(
    e => {
      const {
        target: { value, name }
      } = e;
      const { hour } = value.value;

      const openFieldName = name.replace(/.closeHour$/, ".openHour");
      const closeFieldName = name.replace(/.openHour$/, ".closeHour");

      if (hour === null) {
        // set 24 hours open
        context.setSelectValue({
          target: { name: openFieldName, value: getHourOption({ hour: 0 }) }
        });
        context.setSelectValue({
          target: { name: closeFieldName, value: getHourOption({ hour: 24 }) }
        });
      } else if (isEqual(get(values, closeFieldName), { hour: 24 })) {
        // reset 24 hours
        context.setSelectValue({
          target: { name: closeFieldName, value: { value: null } }
        });
        context.setSelectValue(e);
      } else {
        context.setSelectValue(e);
      }
    },
    [context, values]
  );

  return (
    <>
      {dayHoursFields.map((field, index) => {
        const openFieldName = `${field}.openHour`;
        const closeFieldName = `${field}.closeHour`;
        const open24hours = open24HoursIndices.has(index);
        return (
          <Row key={field} className="mb-2">
            <Col xs={10} md={5} className="pb-1 pe-3 px-md-0 ps-md-2 px-md-3">
              <FieldLabel>Opens at:</FieldLabel>
              <SelectField
                creatable
                formatCreateLabel={formatCreateLabel}
                isValidNewOption={isValidNewHourOption}
                onCreateOption={getHandleCreateOpenHours(openFieldName)}
                onChange={setHourSelectValue}
                name={openFieldName}
                value={open24hours ? { label: "24 hours", value: { hour: null } } : null}
                options={hoursOptions.open}
              />
            </Col>
            <Col xs={10} md={5} className={classNames("pb-1 pe-3 px-md-0 ps-md-2 px-md-3", { "d-none": open24hours })}>
              <FieldLabel>Closes at:</FieldLabel>
              <SelectField
                creatable
                formatCreateLabel={formatCreateLabel}
                isValidNewOption={isValidNewHourOption}
                onCreateOption={getHandleCreateOpenHours(closeFieldName)}
                onChange={setHourSelectValue}
                name={closeFieldName}
                options={hoursOptions.close}
              />
            </Col>
            {index > 0 && index === dayHoursFields.length - 1 && (
              <Col xs={1} className="ps-1 ps-sm-2">
                <div className="ps-0 pt-5 d-flex align-items-center">
                  <RemoveFieldControl forField={field} onClick={() => removeDayHours(field)} />
                </div>
              </Col>
            )}
          </Row>
        );
      })}
      {open24HoursIndices.size === 0 && (
        <Row>
          <Col sm={12} md={5} className="offset-md-5 pe-3 text-center text-sm-end">
            <WWButton iconClass="fa fa-plus" color="link" className="p-md-0" onClick={addDayHours}>
              Add more hours
            </WWButton>
          </Col>
        </Row>
      )}
    </>
  );
};

export default React.memo(DayHoursForm);
