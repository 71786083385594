import { convertDataToComparisonLinesChartFormat } from "./ComparisonLinesChart.helpers";
import moment from "moment";

/**
 * @param {{
 *   datasets: Array<{
 *     id: string;
 *     total: number;
 *     pctChange: number;
 *     trends: {
 *       stat: number[];
 *       comparison: number[];
 *     }
 *   }>
 * }} value
 * @param {import("moment").Moment} startTime
 * @param {import("moment").unitOfTime.Base} frequencyUnit
 */
export const convertDataToTrendingChartDataFormat = ({ datasets, buckets: { stat, comparison } }) =>
  datasets.map(({ id, total, pctChange, trends }) => ({
    total,
    pctChange,
    id,
    data: convertDataToComparisonLinesChartFormat(trends.stat, trends.comparison, stat, comparison)
  }));
