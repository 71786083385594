import { either, filter, identity, isEmpty, isNil, path, pipe, unapply } from "ramda";
import { useCallback } from "react";
import { Button, Form } from "reactstrap";
import InputField from "../../../components/Form/InputField";
import CopyButton from "../../../components/Buttons/CopyButton";
import { bySource } from "../../../data/sites";
import usePureMemo from "../../../hooks/usePureMemo/usePureMemo";
import { useStateThroughPipe } from "../../../hooks/utilHooks";

const { source } = bySource("TREEZ");

export default class TreezAdminLinking {
  field() {
    return "treezInfo";
  }

  add() {
    return TreezLinkAdd;
  }

  edit() {
    return TreezLinkEdit;
  }
}

const nativeInputEventPipe = path(["target", "value"]);

const TreezLinkAdd = ({ onSave }) => {
  const [dispensaryName, setDispensaryName] = useStateThroughPipe("", nativeInputEventPipe);
  const [apiKey, setApiKey] = useStateThroughPipe("", nativeInputEventPipe);
  const allFilled = usePureMemo(
    pipe(unapply(identity), filter(either(isNil, isEmpty)), isEmpty),
    dispensaryName,
    apiKey
  );
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      onSave({ dispensaryName, apiKey, source });
    },
    [onSave, dispensaryName, apiKey, source]
  );

  return (
    <Form onSubmit={onSubmit}>
      <InputField inline={false} label="Dispensary Name" value={dispensaryName} onChange={setDispensaryName} />
      <InputField inline={false} label="API Key" value={apiKey} onChange={setApiKey} type="password" />
      <Button type="submit" color="primary" disabled={!allFilled}>
        Link
      </Button>
    </Form>
  );
};

const TreezLinkEdit = ({ info }) => {
  return (
    <>
      <InputField
        name="dispensaryName"
        label="Dispensary Name"
        value={info.dispensaryName}
        disabled={true}
        onChange={() => {}}
      />
      <CopyButton className="ms-1" text={info.dispensaryName} />
    </>
  );
};
