import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Choose the Emoji Mart set to use
import emojiData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const emojiPickerStyle = {
  zIndex: 1030
};
export function EmojiPicker({ onPick }) {
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  return (
    <Dropdown
      size="sm"
      className="pull-right mt-1 "
      isOpen={emojiPickerOpen}
      toggle={() => setEmojiPickerOpen(!emojiPickerOpen)}
    >
      <DropdownToggle color="primary">
        <i className="fa fa-lg fa-smile-o"></i>
      </DropdownToggle>
      <DropdownMenu className="p-0 overflow-visible">
        <Picker
          style={emojiPickerStyle}
          emoji=""
          title=""
          data={emojiData}
          set="native"
          onEmojiSelect={onPick}
          theme="light"
        />
      </DropdownMenu>
    </Dropdown>
  );
}
