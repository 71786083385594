import { Card } from "components/Card";
import { Slab } from "components/Charts/Slab";

const videoSummaryRenderer = key => {
  return key === "id"
    ? ({ id }) => <span className="flex-grow-0 text-nowrap text-muted">{id}</span>
    : key === "total"
    ? ({ total }) => (
        <h2 className="m-0 d-flex justify-content-center align-items-center flex-grow-1">
          {total === null ? "N/A" : total}
        </h2>
      )
    : () => <></>;
};

const VideoSummaryWidgetContent = ({ data = {} }) => {
  const { submissions, opened, sent } = data;
  return (
    <div className="flex-nowrap d-flex flex-row justify-content-start">
      <Card className="mx-2 flex-shrink-0 flex-grow-1">
        <div className="px-2">
          <Slab id={"Videos Submitted"} total={submissions} renderer={videoSummaryRenderer} />
        </div>
      </Card>
      <Card className="mx-2 flex-shrink-0 flex-grow-1">
        <div className="px-2">
          <Slab id={"Video Requests Opened"} total={opened} renderer={videoSummaryRenderer} />
        </div>
      </Card>
      <Card className="mx-2 flex-shrink-0 flex-grow-1">
        <div className="px-2">
          <Slab id={"Video Requests Sent"} total={sent} renderer={videoSummaryRenderer} />
        </div>
      </Card>
    </div>
  );
};

export default VideoSummaryWidgetContent;
