import React from "react";
import { useSelector } from "react-redux";
import { NavItem, NavLink as RsNavLink } from "reactstrap";
import { formatSupportPhoneNumber } from "util/phoneNumberUtils";

const SidebarFooter = () => {
  const currentAgency = useSelector(state => state.currentAgency);

  const handleClick = e => {
    e.preventDefault();
    document.getElementById("sidebar-footer-dropdown").classList.toggle("open");
  };

  return (
    <div className="sidebar-footer sidebar-nav">
      <ul className="nav">
        <li id="sidebar-footer-dropdown" className="nav-item nav-dropdown">
          <a className="nav-link nav-dropdown-toggle pe-3" onClick={handleClick.bind(this)} href="#">
            <i className="fa fa-info"></i>
            <strong>Contact Support</strong>
          </a>
          <ul className="nav-dropdown-items">
            {!!currentAgency.supportPhone && (
              <NavItem>
                <RsNavLink href={`tel:${currentAgency.supportPhone}`}>
                  <i className="fa fa-phone"></i>
                  Call Us: {formatSupportPhoneNumber(currentAgency.supportPhone)}
                </RsNavLink>
              </NavItem>
            )}
            <NavItem>
              <RsNavLink href={`mailto:${currentAgency.supportEmail}`} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-envelope"></i>
                Email Us
              </RsNavLink>
            </NavItem>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default SidebarFooter;
