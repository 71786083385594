import { entries, identity, noop, size } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import Measurable from "./Measurable";

export default ({ children, crop = [0, 0], keyer = identity, list = [], onScrolled = noop, fallbackHeight = 400 }) => {
  const [finderRef, { top }] = useMeasure({ scroll: true });
  const [visibles, setVisibles] = useState([]);
  const [before, setBefore] = useState(0);
  const [after, setAfter] = useState(0);
  const [countBefore, setCountBefore] = useState(0);
  const [countAfter, setCountAfter] = useState(0);
  const [entities, setEntities] = useState({ list: [], heights: {} });

  useEffect(() => {
    setEntities(prev => ({
      list: list,
      heights: list.reduce((acc, v) => (key => ({ ...acc, [key]: prev.heights[key] || fallbackHeight }))(keyer(v)), {})
    }));
  }, [list, keyer]);

  useEffect(() => {
    onScrolled({
      top: countBefore,
      bottom: countAfter,
      current: visibles.length
    });
  }, [countBefore, countAfter, visibles.length]);

  const changed = useCallback(
    (a, b) => size(b) !== size(a) || entries(b).reduce((v, [key, value]) => v || a[key] !== value, false),
    []
  );

  const updateHeight = useCallback(
    (...entries) =>
      setEntities(prev => {
        const heights = entries.reduce((v, [key, value]) => ({ ...v, [key]: value }), prev.heights);
        return changed(prev.heights, heights) ? { ...prev, heights } : prev;
      }),
    []
  );

  useEffect(() => {
    const { list, heights } = entities;
    const computed = list.reduce(
      (acc, v, i, l) => {
        const key = keyer(v);
        const height = heights[key];
        const offset = acc.offset;
        return offset + height + top >= crop[0] && offset + top <= crop[1]
          ? {
              ...acc,
              visibles: [...acc.visibles, { offset, height, entity: v, next: l[i + 1], prev: l[i - 1] }],
              offset: offset + height
            }
          : offset + height + top < crop[0]
          ? {
              ...acc,
              countBefore: acc.countBefore + 1,
              before: acc.before + height,
              offset: offset + height
            }
          : {
              ...acc,
              countAfter: acc.countAfter + 1,
              after: acc.after + height,
              offset: offset + height
            };
      },
      {
        before: 0,
        countBefore: 0,
        after: 0,
        countAfter: 0,
        offset: 0,
        visibles: []
      }
    );
    setVisibles(prev =>
      prev.length !== computed.visibles.length || computed.visibles.some(({ entity }, i) => entity !== prev[i].entity)
        ? computed.visibles
        : prev
    );
    setBefore(computed.before);
    setAfter(computed.after);
    setCountBefore(computed.countBefore);
    setCountAfter(computed.countAfter);
  }, [entities, crop, top, keyer]);

  return (
    <div ref={finderRef} className="position-relative d-flex flex-column justify-content-start align-items-stretch">
      <div style={{ height: before }} />
      {visibles.map(({ height, offset, entity, next, prev }) => (
        <Measurable
          key={keyer(entity)}
          onResize={({ height }) => {
            if (Math.round(height) > 0) {
              updateHeight([keyer(entity), Math.round(height)]);
            }
          }}
        >
          {children({ height, offset, entity, next, prev })}
        </Measurable>
      ))}
      <div style={{ height: after }} />
    </div>
  );
};
