import { get, isUndefined } from "lodash";
import { DashboardWidget } from "components/Widgets/DashboardWidget";
import VolumeChart from "components/Charts/VolumeChart";
import HelpIcon from "components/Misc/HelpIcon";

import { useReportFilterQuery } from "components/HOCs/ReportWithFilters";
import { useDispatch } from "react-redux";
import { errorCaughtNotifier, useLocalNotifications } from "components/Notifications/notification";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../redux/actions/ajaxStatusActions";
import { useCallback, useState } from "react";
import { frequencyUnit2frequencyBackendValue } from "components/DateTime/FrequencySelector.helpers";
import { getCustomerReviewsVolumeData } from "api/reportApi";
import { sortDatasetsForPropertyManagement } from "util/arrayUtils";
import { useDateRangeParameters } from "hooks/datesHooks";
import { useBrandParameter, useContactIdParameter, useCustomerIdParameter } from "hooks/customerHooks";
import { useCampaignNameParameter } from "hooks/data/campaignHooks";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";

export const lowRatingPercentColorConverter = (entry, matchingColor = "danger", defaultColor = "success") =>
    entry.id === "1-Star" || entry.id === "2-Star" || entry.id === "3-Star"
      ? `text-${matchingColor}`
      : `text-${defaultColor}`,
  legendRenderer =
    (mapper = get) =>
    key => {
      return key === "id"
        ? entry => <span className="text-dark text-nowrap">{mapper(entry, key)}</span>
        : key === "total"
          ? entry => <h3>{entry.total === null || isUndefined(entry.total) ? "N/A" : mapper(entry, key)}</h3>
          : entry => {
              const value = mapper(entry, key);
              return entry.pctChange !== null && entry.pctChange > 0 ? (
                <span className={lowRatingPercentColorConverter(entry)}>{value}</span>
              ) : entry.pctChange !== null && entry.pctChange <= 0 ? (
                <span className={lowRatingPercentColorConverter(entry, "success", "danger")}>{value}</span>
              ) : (
                <span className="text-dark">{"N/A"}</span>
              );
            };
    };

export function VolumeWidget({ title = "Volume", data, actions, showCampaignHelpText, isLoading }) {
  return (
    <DashboardWidget
      isLoading={isLoading}
      title={
        <div>
          {title}
          {showCampaignHelpText && (
            <HelpIcon className="px-4" icon="info">
              This chart is not affected by campaign selection
            </HelpIcon>
          )}
        </div>
      }
      actions={actions}
    >
      <VolumeChart data={data} renderer={legendRenderer} />
    </DashboardWidget>
  );
}

export default function VolumeWidgetWithQuery({ onlyAssignedLocations, ...props }) {
  const filterQuery = useReportFilterQuery({
    url: "/report/volume",
    sources: {
      SITE: "Site",
      PROFIT_CENTER: "Category",
      INTERACTION_TYPE: "Interaction type",
      RATING: "Rating"
    },
    additionalParams: { onlyAssignedLocations },
    freqFilter: f => f !== "HOURLY"
  });

  return <VolumeWidget {...filterQuery} {...props} />;
}

/// legacy Volume Widget

const useVolumeDataLoader = (
  customerId,
  brand,
  contactId,
  startDate,
  endDate,
  source,
  frequency,
  compareMode,
  onlyAssignedLocations,
  campaign
) => {
  const dispatch = useDispatch();
  const notify = useLocalNotifications();

  return useCallback(() => {
    dispatch(beginAjaxCall());
    return getCustomerReviewsVolumeData({
      customerId,
      brand,
      contactId,
      startDate,
      endDate,
      frequency: frequencyUnit2frequencyBackendValue(frequency),
      source,
      compareMode,
      onlyAssignedLocations,
      campaignNames: campaign
    })
      .then(v => {
        dispatch(endAjaxCall());
        return {
          ...v,
          datasets: sortDatasetsForPropertyManagement(v.datasets)
        };
      })
      .catch(e => {
        dispatch(ajaxCallError(e));
        errorCaughtNotifier(notify)(e);
        return Promise.reject(e);
      });
  }, [customerId, brand, contactId, startDate, endDate, source, frequency, compareMode, campaign]);
};

const useSourceRenderer = () =>
  useCallback(
    source =>
      ({
        SITE: "Site",
        PROFIT_CENTER: "Category",
        INTERACTION_TYPE: "Interaction type",
        RATING: "Rating"
      })[source],
    []
  );

export function LegacyVolumeWidget(props) {
  const { frequency, timerange, comparisonMode } = useDateRangeParameters();
  const { customer } = useCustomerIdParameter();
  const { brand } = useBrandParameter();
  const { contact } = useContactIdParameter();
  const [source, setSource] = useState("SITE");
  const { campaign } = useCampaignNameParameter();
  const sourceRenderer = useSourceRenderer();
  const dataLoader = useVolumeDataLoader(
    customer,
    brand,
    contact,
    ...timerange.map(v => v.toISOString(true)),
    source,
    frequency,
    comparisonMode,
    props.onlyAssignedLocations,
    campaign
  );

  return (
    <VolumeWidget
      {...props}
      data={dataLoader}
      actions={
        <div className="d-flex flex-row flex-nowrap align-items-baseline">
          <span className="d-none d-sm-block text-muted me-2">Explore by: </span>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret>{sourceRenderer(source)}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setSource("SITE")}>{sourceRenderer("SITE")}</DropdownItem>
              <DropdownItem onClick={() => setSource("PROFIT_CENTER")}>{sourceRenderer("PROFIT_CENTER")}</DropdownItem>
              <DropdownItem onClick={() => setSource("INTERACTION_TYPE")}>
                {sourceRenderer("INTERACTION_TYPE")}
              </DropdownItem>
              <DropdownItem onClick={() => setSource("RATING")}>{sourceRenderer("RATING")}</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      }
    />
  );
}
