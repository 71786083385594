import React, { useCallback, useMemo, useState } from "react";
import { not } from "ramda";
import isFunction from "lodash/isFunction";
import WWButton from "../Buttons/WWButton";

/**
 * Component that can show 2 different components based on a locked state.
 * @param initiallyLocked - whether the field should be locked initially
 * @param lockedComponent - React component or factory function that returns a React component
 * @param children - the component to show when the field is unlocked
 * @returns {JSX.Element}
 * @constructor
 */
export default function LockedField({ initiallyLocked = true, lockedComponent, children }) {
  const [locked, setLocked] = useState(initiallyLocked);
  const toggle = useCallback(() => setLocked(not), [setLocked]);
  const lc = useMemo(() => {
    if (isFunction(lockedComponent)) {
      return lockedComponent(toggle);
    } else {
      return (
        <>
          {lockedComponent}
          <WWButton iconClass="fa fa-pencil" color="link" data-testid="default" onClick={toggle}></WWButton>
        </>
      );
    }
  }, [lockedComponent]);

  if (locked) {
    return <div>{lc}</div>;
  }

  return <>{children}</>;
}
