import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { trim, isEmpty, get, isUndefined } from "lodash";
import InputField from "components/Form/InputField";
import StatusMessages from "components/Misc/StatusMessages";
import CopyButton from "components/Buttons/CopyButton";
import Paragraph from "components/Layout/Paragraph";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import EmailButton from "components/Buttons/EmailButton";
import WWTextDetails from "components/WWTextDetails/WWTextDetails";
import { useAddAndRemoveSite, useUpdateDefaultReviewTag } from "hooks/customerHooks";
import { SITE_SOURCE_NAMES, SOURCES } from "data/sites";

const CARS_DOT_COM_PATTERN = /https:\/\/www.cars.com\/dealers\/(\d+)\/.+/;
const DEALERRATER_PATTERN = /https:\/\/www.dealerrater.com\/dp\/(\d+)\/dashboard/;
const DEALERRATER_SITE_PATTERN = /https:\/\/www.dealerrater.com\/dealer\/.+?-(\d+)\/.*/;
const DEALERRATER_SUPPORT_EMAIL = "help@dealerrater.com";
function parseCarsDotCom(event) {
  return parseUrl(event, CARS_DOT_COM_PATTERN);
}

function parseDealerrater(event) {
  const id = parseUrl(event, DEALERRATER_PATTERN);
  if (id) {
    return id;
  }
  return parseUrl(event, DEALERRATER_SITE_PATTERN);
}

function parseUrl(event, pattern) {
  let url = event.target.value;
  if (isEmpty(url)) return;

  let match = url.match(pattern);
  if (!match && trim(url).length > 0) {
    return false;
  } else {
    return match[1];
  }
}

function buildTemplate(dealerraterId, carsDotComId, email) {
  return `Hello DealerRater Team,
Would you please add access for ${email} for the following dealers.

DealerRater ID:  ${dealerraterId || ""}
Cars.com ID: ${carsDotComId || ""}
`;
}

function encodeUrlParameter(str) {
  return encodeURIComponent(str).replace(/%20/g, "+");
}

export default function CarsDealerraterLinking({ customer }) {
  const [errors, setErrors] = useState([]);
  const [dealerraterValid, setDealerraterValid] = useState(undefined);
  const [dealerraterInfo, setDealerraterInfo] = useState();
  const [carsDotComValid, setCarsDotComValid] = useState(undefined);
  const [carsDotComInfo, setCarsDotComInfo] = useState();
  const userEmail = useSelector(state => state.cognito.user?.email);
  const currentAgency = useSelector(state => state.currentAgency);
  const [emailBody, setEmailBody] = useState();
  const [toLine, setToLine] = useState();

  const dealeraterSiteField = SOURCES[SITE_SOURCE_NAMES.dealerrater].customerField;
  const carsDotComSiteField = SOURCES[SITE_SOURCE_NAMES.carsDotCom].customerField;
  const API_HOOKS_CONFIG_DEALERRATER = {
    customerId: customer.id,
    field: dealeraterSiteField
  };
  const API_HOOKS_CONFIG_CARS_DOT_COM = {
    customerId: customer.id,
    field: carsDotComSiteField
  };
  const [addSiteDealerrater, removeSiteDealerrater] = useAddAndRemoveSite(API_HOOKS_CONFIG_DEALERRATER);
  const updateDefaultTagDealerrater = useUpdateDefaultReviewTag(API_HOOKS_CONFIG_DEALERRATER);
  const [addSiteCarsDotCom, removeSiteCarsDotCom] = useAddAndRemoveSite(API_HOOKS_CONFIG_CARS_DOT_COM);
  const updateDefaultTagCarsDotCom = useUpdateDefaultReviewTag(API_HOOKS_CONFIG_CARS_DOT_COM);

  useEffect(() => {
    setDealerraterInfo(get(customer, [dealeraterSiteField, 0]));
    setCarsDotComInfo(get(customer, [carsDotComSiteField, 0]));
  }, [JSON.stringify(customer)]);

  useEffect(() => {
    setToLine(`${DEALERRATER_SUPPORT_EMAIL};${userEmail};${currentAgency.dealerraterEmail}`);
  }, [userEmail, currentAgency]);

  useEffect(() => {
    if (dealerraterInfo || carsDotComInfo) {
      setEmailBody(buildTemplate(dealerraterInfo?.dealerId, carsDotComInfo?.dealerId, currentAgency.dealerraterEmail));
    }
  }, [dealerraterInfo, carsDotComInfo]);

  return (
    <Container>
      <Row>
        <Col>
          <p>
            Click on the links below to search for your dealership&apos;s Cars.com and Dealerrater pages. Copy &amp;
            paste those URLs below.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <StatusMessages errors={errors} />
        </Col>
      </Row>

      {dealerraterInfo ? (
        <>
          <Row>
            <Col>Dealerrater ID</Col>
            <Col>
              <strong>{dealerraterInfo.dealerId}</strong>
              <i
                className="ms-1 fa fa-close"
                onClick={() => {
                  removeSiteDealerrater(dealerraterInfo.id).then(() => {
                    setDealerraterInfo(undefined);
                    setDealerraterValid(undefined);
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <HorizontalSelectField
                name="defaultReviewTag"
                value={dealerraterInfo.defaultReviewTag}
                label="Default Review Tag"
                simpleValue={true}
                options={customer.reviewTagSet.tags}
                onChange={evt => {
                  updateDefaultTagDealerrater({
                    siteId: dealerraterInfo.id,
                    defaultReviewTag: evt.target.value
                  }).then(res => {
                    setDealerraterInfo({
                      id: res.data[0].id,
                      dealerId: res.data[0].dealerId
                    });
                    setDealerraterValid(true);
                  });
                }}
                isClearable={true}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <InputField
              inline={false}
              valid={dealerraterValid}
              feedback={!dealerraterValid && "Not a valid Dealerrater URL"}
              tip="CTRL+C to copy and CTRL-V to paste"
              name="dealerraterUrl"
              label={
                <span>
                  Copy and paste the{" "}
                  <a
                    href={`https://dealerrater.com/search/?q=${encodeUrlParameter(customer.companyName)}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Dealerrater URL
                  </a>{" "}
                  for {customer.companyName} below.
                </span>
              }
              placeholder="https://dealerrater.com/dealer/..."
              onChange={evt => {
                const id = parseDealerrater(evt);
                if (id === false || isUndefined(id)) {
                  setDealerraterValid(id);
                } else {
                  addSiteDealerrater({
                    source: SITE_SOURCE_NAMES.dealerrater,
                    dealerId: id,
                    dealerUrl: evt.target.value
                  })
                    .then(res => {
                      setDealerraterInfo({ id: res[0].id, dealerId: id });
                      setDealerraterValid(true);
                      setErrors();
                    })
                    .catch(() => {
                      setErrors(["Failed saving Dealerrater ID."]);
                    });
                }
              }}
            />
          </Col>
        </Row>
      )}
      {carsDotComInfo ? (
        <>
          <Row>
            <Col>Cars.com ID</Col>
            <Col>
              <strong>{carsDotComInfo.dealerId}</strong>
              <i
                className="ms-1 fa fa-close"
                onClick={() => {
                  removeSiteCarsDotCom(carsDotComInfo.id).then(() => {
                    setCarsDotComInfo(undefined);
                    setCarsDotComValid(undefined);
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <HorizontalSelectField
                name="defaultReviewTag"
                value={carsDotComInfo.defaultReviewTag}
                label="Default Review Tag"
                simpleValue={true}
                options={customer.reviewTagSet.tags}
                onChange={evt => {
                  updateDefaultTagCarsDotCom({
                    siteId: carsDotComInfo.id,
                    defaultReviewTag: evt.target.value
                  }).then(res => {
                    setCarsDotComInfo({
                      id: res.data[0].id,
                      dealerId: res.data[0].dealerId
                    });
                    setCarsDotComValid(true);
                  });
                }}
                isClearable={true}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <InputField
              name="cdcUrl"
              inline={false}
              label={
                <span>
                  Copy and paste the{" "}
                  <a
                    href={`https://www.google.com/search?q=site:cars.com+${encodeUrlParameter(
                      customer.companyName
                    )}+${encodeUrlParameter(customer.fullAddress)}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Cars.com URL
                  </a>{" "}
                  for {customer.companyName} below.
                </span>
              }
              tip="CTRL+C to copy and CTRL-V to paste"
              valid={carsDotComValid}
              feedback={!carsDotComValid && "Not a valid Cars.com URL"}
              placeholder="https://www.cars.com/dealers/..."
              onChange={evt => {
                const id = parseCarsDotCom(evt);
                if (id === false || isUndefined(id)) {
                  setCarsDotComValid(id);
                } else {
                  addSiteCarsDotCom({
                    source: SITE_SOURCE_NAMES.carsDotCom,
                    dealerId: id,
                    dealerUrl: evt.target.value
                  })
                    .then(res => {
                      setCarsDotComInfo({ id: res[0].id, dealerId: id });
                      setCarsDotComValid(true);
                      setErrors();
                    })
                    .catch(error => {
                      setErrors(["Failed saving Cars.com ID."]);
                    });
                }
              }}
            />
          </Col>
        </Row>
      )}
      <Row>
        {(dealerraterInfo || carsDotComInfo) && (
          <Col>
            <p>
              You must send an email to Dealerrater and Cars.com to request that Widewail be granted access to your
              account. Click <i>Send Email</i> to open a template email which you can use to request access. Send this
              email from the email address associated with your Dealerrater and Cars.com accounts.
            </p>
            <EmailButton to={toLine} subject="New Account Access" body={emailBody} />
          </Col>
        )}
      </Row>
      <WWTextDetails caption="Manual Instructions">
        <div>
          If the email button above is not opening your email client you can manually copy and paste the email below.
          <br />
          <br />
          <Row>
            <Col xs="auto">To:</Col>
            <Col>
              <CopyButton>{toLine}</CopyButton>
            </Col>
          </Row>
          <Row>
            <Col xs="auto">Subject:</Col>
            <Col>
              <CopyButton>New Account Access</CopyButton>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <code>
                <CopyButton text={emailBody}>
                  <Paragraph content={emailBody} />
                </CopyButton>
              </code>
            </Col>
          </Row>
        </div>
      </WWTextDetails>
    </Container>
  );
}
