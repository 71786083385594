import React, { useCallback, useRef, useEffect, useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import classnames from "classnames";
import styles from "./WWOffcanvas.module.scss";

const { offcanvasClass, wideOffcanvasClass } = styles;

const SCROLL_TOP_THRESHOLD = 48;

const WWOffcanvas = ({ toggle, children, isOpen, wide = false, ...props }) => {
  const [scrolled, setScrolled] = useState(false);
  const removeEventListenerRef = useRef(null);

  const bodyRefCallback = useCallback(node => {
    if (node !== null) {
      const wheelListener = e => {
        const { top } = e.currentTarget.getBoundingClientRect();
        if (top < SCROLL_TOP_THRESHOLD) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };

      node.addEventListener("wheel", wheelListener);
      removeEventListenerRef.current = [node.removeEventListener, wheelListener];
    }
  }, []);
  useEffect(
    () => () => {
      const removeEventListenerParams = removeEventListenerRef.current;
      if (removeEventListenerParams) {
        const [removeListenerCallback, listenerId] = removeEventListenerParams;
        return removeListenerCallback("wheel", listenerId);
      }
    },
    []
  );
  useEffect(() => {
    if (!isOpen) {
      setScrolled(false);
    }
  }, [isOpen]);

  return (
    <Offcanvas
      toggle={toggle}
      isOpen={isOpen}
      {...props}
      className={classnames(offcanvasClass, { [wideOffcanvasClass]: wide })}
    >
      <OffcanvasHeader className={scrolled ? "scrolled" : ""} toggle={toggle} />
      <OffcanvasBody>
        <div ref={bodyRefCallback} style={{ height: "100%" }}>
          {children}
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default React.memo(WWOffcanvas);
