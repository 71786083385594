import { property } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { Button, InputGroup, InputGroupText } from "reactstrap";
import {
  createHubspotConfiguration,
  deleteHubspotInfo,
  getHubspotConfigurations,
  getHubspotInfos,
  getHubspotPipelines,
  removeHubspotPipelineConfiguration
} from "api/hubspotApi";
import DeleteButton from "components/Buttons/DeleteButton";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import WWButton from "components/Buttons/WWButton";
import AjaxLoader from "components/Misc/AjaxLoader";
import StatusMessages from "components/Misc/StatusMessages";
import { LOCAL_STORAGE_KEYS } from "data/storageKeys";
import { useTagSetByCustomerId } from "../../hooks/data/tagSetHooks";

const HubspotLinking = () => {
  const [pipelines, setPipelines] = useState([]);
  const [tags, setTags] = useState([]);
  const [configurations, setConfigurations] = useState([]);

  const [errors, setErrors] = useState([]);
  const [connected, setConnected] = useState(undefined);
  const [pipeline, setPipeline] = useState();
  const [stage, setStage] = useState();
  const [tag, setTag] = useState();
  const [hubspotId, setHubspotId] = useState();

  const selectablePipelines = useMemo(
    () => (pipelines || []).filter(({ id }) => !configurations.find(v => v.pipelineId === id)),
    [pipelines, configurations]
  );

  const match = useRouteMatch();
  const customerId = useMemo(() => match.params.id);

  const onConfigurationRemove = useCallback(
    id => () =>
      removeHubspotPipelineConfiguration(hubspotId, id).then(() =>
        setConfigurations(v => v?.filter(conf => conf.id !== id))
      ),
    [hubspotId]
  );

  const unlinkHubspot = useCallback(() => {
    deleteHubspotInfo(customerId, hubspotId).then(() => {
      setPipelines([]);
      setPipeline(undefined);
      setConfigurations([]);
      setConnected(false);
    });
  }, [customerId, hubspotId]);

  const customerTags = useTagSetByCustomerId(customerId, { enabled: !!customerId })?.data?.tags || [];

  useEffect(() => {
    getHubspotInfos(customerId)
      .then(infos => {
        const empty = infos.length === 0;
        setConnected(!empty);
        if (!empty) {
          const [{ id }] = infos;
          setHubspotId(id);
          return getHubspotPipelines(customerId, id)
            .then(v => {
              setPipelines(v);
              setPipeline(v[0]);
              setStage(v[0]?.stages[0] || undefined);
            })
            .then(() => getHubspotConfigurations(id))
            .then(setConfigurations);
        }
      })
      .catch(setErrors);
  }, [customerId]);

  const pipelineName = useCallback(id => pipelines.find(v => v.id === id)?.label || id, [pipelines]);

  const createPipelineConfiguration = useCallback(
    () =>
      createHubspotConfiguration(hubspotId, pipeline?.id, stage?.id, tag)
        .then(resp => setConfigurations(prev => [...prev, resp]))
        .catch(() => setErrors(["Cannot link pipeline. Make sure it is not connected to another location."])),
    [hubspotId, pipeline, stage, tag]
  );

  useEffect(() => {
    const pipelineToSelect = selectablePipelines[0];
    setPipeline(pipelineToSelect);
    setStage(pipelineToSelect?.stages[0]);
    if (!pipelineToSelect) {
      setTag(undefined);
    }
  }, [selectablePipelines]);

  const authURL = useMemo(
    () =>
      "https://app.hubspot.com/oauth/authorize" +
      `?client_id=${encodeURIComponent(process.env.REACT_APP_HUBSPOT_CLIENTID)}` +
      `&scope=${encodeURIComponent(
        "crm.schemas.deals.read crm.objects.owners.read crm.objects.contacts.write crm.objects.companies.write crm.objects.companies.read crm.objects.deals.read crm.schemas.contacts.read crm.objects.deals.write crm.objects.contacts.read crm.schemas.companies.read oauth tickets"
      )}` +
      `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_COGNITO_REDIRECTURI}oauth/hubspot`)}`,
    []
  );

  const onConnectClick = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.AWAITED_REDIRECT_CUSTOMER, customerId);
    window.open(authURL);
  }, [authURL, customerId]);

  return (
    <>
      <StatusMessages errors={errors} />
      {connected === undefined && <AjaxLoader />}
      {connected === false && (
        <Button color="primary" className="w-100" onClick={onConnectClick}>
          Connect to Hubspot
        </Button>
      )}
      {connected === true && (
        <div className="d-flex flex-column justify-content-stretch">
          <p>
            Your location is connected to HubSpot. Select which of your HubSpot pipelines Widewail should monitor for
            incoming review leads.
          </p>
          <h4>Connected Pipelines</h4>
          {configurations.length === 0 ? <p className="text-muted">You have no connected pipelines yet.</p> : null}
          {configurations.map(({ id, pipelineId, importStageLabel, defaultInteractionTag: tag }) => (
            <div className="d-flex flex-row align-items-stretch justify-content-stretch flex-nowrap">
              <InputGroup className="my-1 flex-grow-1 d-flex flex-column flex-sm-row flex-nowrap" key={id}>
                <InputGroupText className="d-flex flex-row flex-sm-column justify-content-start justify-content-sm-center align-items-start w-100">
                  <span className="text-muted">
                    Pipeline<span className="d-inline d-sm-none pe-2">:</span>
                  </span>
                  <span className="text-body text-wrap text-start">{pipelineName(pipelineId)}</span>
                </InputGroupText>
                <InputGroupText className="d-flex flex-row flex-sm-column justify-content-start justify-content-sm-center align-items-start flex-grow-1 w-100">
                  <span className="text-muted">
                    Stage<span className="d-inline d-sm-none pe-2">:</span>
                  </span>
                  <span className="text-body text-wrap text-start">{importStageLabel}</span>
                </InputGroupText>
                <InputGroupText className="d-flex flex-row flex-sm-column justify-content-start justify-content-sm-center align-items-start flex-grow-1 w-100">
                  <span className="text-muted">
                    Tag<span className="d-inline d-sm-none pe-2">:</span>
                  </span>
                  <span className="text-body text-wrap text-start">{tag}</span>
                </InputGroupText>
              </InputGroup>
              <DeleteButton
                onClick={onConfigurationRemove(id)}
                color="warning"
                className="btn btn-warning ms-1 d-flex align-items-center my-1"
              />
            </div>
          ))}
          <div className="mt-4">
            {selectablePipelines.length > 0 ? (
              <>
                <h4>Add Pipeline</h4>
                <div className="my-1 flex-grow-1 d-flex flex-column align-items-stretch">
                  <HorizontalSelectField
                    label="Pipeline"
                    inline={false}
                    options={(selectablePipelines || [])
                      .filter(v => v !== pipeline)
                      .map(v => ({ label: v.label, value: v }))}
                    value={{
                      label: pipeline?.label,
                      value: pipeline
                    }}
                    onChange={v => {
                      setPipeline(property("target.value.value")(v));
                      setStage(property("target.value.value.stages.0")(v));
                    }}
                    name="pipeline"
                  />
                  <HorizontalSelectField
                    label="Stage"
                    inline={false}
                    options={
                      pipelines
                        .find(v => v === pipeline)
                        ?.stages?.filter(v => v !== stage)
                        .map(v => ({
                          label: v.label,
                          value: v
                        })) || []
                    }
                    value={{
                      label: stage?.label,
                      value: stage
                    }}
                    onChange={v => setStage(property("target.value.value")(v))}
                    name="stage"
                  />
                  <HorizontalSelectField
                    label="Tag"
                    inline={false}
                    options={(customerTags || []).filter(v => v !== tag)}
                    value={tag}
                    onChange={v => setTag(property("target.value")(v))}
                    simpleValue={true}
                    name="tag"
                  />
                  <WWButton
                    iconClass="fa fa-plus"
                    color="primary"
                    disabled={!pipeline || !stage || !tag}
                    onClick={createPipelineConfiguration}
                    className="flex-grow-1 mx-3"
                  >
                    Connect
                  </WWButton>
                </div>
              </>
            ) : null}
            <div className="mt-3 d-flex justify-content-center">
              <WWButton iconClass="fa fa-ban" color="danger" size="sm" outline onClick={unlinkHubspot}>
                Disconnect HubSpot
              </WWButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HubspotLinking;
