import { isNil, get, throttle, omit } from "lodash";
import { map, path, toPairs } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import * as client from "api/apiClient";
import { loadCustomerNames, loadCustomers, loadCustomersFilter, loadSiteInfos } from "api/customerApi";
import { beginAjaxCall, endAjaxCall } from "../redux/actions/ajaxStatusActions";
import { actionMergeCustomersIn } from "../redux/actions/customerCacheActions";
import { CACHE_INITIAL_VALUE, CACHE_STATE_KEY } from "../redux/reducers/customersCacheReducer";
import { useLocationQueryParamValue, usePersistantViewState } from "hooks/stateHooks";
import { evolveDates, useGet, usePost, usePatch, useDelete } from "./dataHooks";
import { inviteCustomerProductsKeys } from "data/products";
import {
  BASE_URL_CONTACTS,
  BASE_URL_CUSTOMERS,
  LOCATION_DATA_PROCS,
  LOCATION_ENDPOINTS,
  LOCATION_PARAMS,
  LOCATION_TYPE
} from "data/customers";
import { VIEW_STATE_STORAGE_KEYS } from "data/storageKeys";

const BASE_URL_TAGS = "tagSets";

const customerPaths = {
  all: [BASE_URL_CUSTOMERS],
  some: () => [...customerPaths.all],
  customer: id => [...customerPaths.all, id],
  products: id => [...customerPaths.all, id, "products"],
  placeholders: id => [...customerPaths.all, id, "placeholders"],
  siteInfo: (id, field) => [...customerPaths.all, id, field],
  removeSite: (id, field, siteId) => [...customerPaths.all, id, field, siteId],
  addSite: (id, field) => [...customerPaths.all, id, field],
  smsOrders: id => [...customerPaths.all, id, "smsOrders"],
  updateDefaultTag: (id, siteId, field) => [...customerPaths.all, id, field, siteId, "defaultReviewTag"]
};

export const customerKeys = {
  all: customerPaths.all,
  some: params => [...customerPaths.some(), { ...params }],
  customer: (id, params) => [...customerPaths.customer(id), { ...params }],
  products: id => customerPaths.products(id),
  placeholders: id => customerPaths.placeholders(id),
  siteInfo: (id, field) => customerPaths.siteInfo(id, field),
  smsOrders: id => customerPaths.smsOrders(id)
};

const tagsPaths = {
  all: [BASE_URL_TAGS],
  customers: id => [...tagsPaths.all, "customers", id],
  agency: () => [...tagsPaths.all, "tags"]
};

const tagsKeys = {
  all: tagsPaths.all,
  customers: (id, params) => [...tagsPaths.customers(id), { ...params }],
  agency: () => tagsPaths.agency()
};

export const getCustomerKeyAndPath = (requestName, ...args) => [
  customerKeys[requestName](...args),
  customerPaths[requestName](...args)
];

export const getTagsKeyAndPath = (requestName, ...args) => [
  tagsKeys[requestName](...args),
  tagsPaths[requestName](...args)
];

const normalizeCustomersRequest = ({
  filters,
  pageNumber,
  projection,
  sort,
  matchAllProducts,
  product,
  status,
  withProperties
}) =>
  [
    ...toPairs({
      ...omit(filters, "location"),
      ...(!!filters?.location ? { id: filters.location.value } : {})
    }),
    ["page", pageNumber],
    ["sort", sort],
    ["projection", projection],
    ["size", 25],
    ["matchAllProducts", matchAllProducts],
    ["withProperties", withProperties],
    ["product", product],
    ["status", status]
  ].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

const normalizeCustomersResponse = data => {
  return { customers: data?._embedded?.customers, pageInfo: data?.page };
};

/**
 *
 * @param {{[index: string]: any}} filters
 * @param {[number, number]} page
 * @returns {import("react-query").UseQueryResult<{
 *  active: boolean;
 *  address: string;
 *  brands: string;
 *  companyName: string;
 *  countryCode: string;
 *  fullAddress: string;
 *  id: string;
 *  lastModifiedOn: string;
 *  status: string;
 * }>>}
 */

export default function useCustomers(
  {
    filters = [],
    pageNumber = 0,
    sort = "companyName,asc",
    projection = "customerList",
    matchAllProducts = true,
    product,
    status,
    withProperties
  } = {},
  options
) {
  const params = useMemo(
    () =>
      normalizeCustomersRequest({
        filters,
        pageNumber,
        projection,
        sort,
        matchAllProducts,
        product,
        status,
        withProperties
      }),
    [filters, pageNumber, sort, projection, matchAllProducts]
  );
  const [queryKey, queryPath] = getCustomerKeyAndPath("some", params);
  const [get] = useGet(queryPath, { params });
  const queryRun = useCallback(() => get().then(normalizeCustomersResponse), [get]);
  return useQuery(queryKey, queryRun, {
    ...options
  });
}
export function useCustomer({ customerId, params, options }) {
  const [queryKey, queryPath] = getCustomerKeyAndPath("customer", customerId, params);
  const [get] = useGet(queryPath, { params });
  const queryRun = useCallback(() => get().then(data => data), [get]);
  return useQuery(queryKey, queryRun, {
    ...options
  });
}

export const useCustomerSmsOrders = customerId => {
  const [queryKey, queryPath] = getCustomerKeyAndPath("smsOrders", customerId);
  const [get] = useGet(queryPath);
  const queryRun = useCallback(() => get().then(map(evolveDates())), [get]);
  return useQuery(queryKey, queryRun, { enabled: !!customerId });
};

export const useCustomerSmsOrderSubmit = customerId => {
  const queryPath = customerPaths.smsOrders(customerId);
  const invalidateQueryKey = customerKeys.smsOrders(customerId);
  const post = usePost();
  const queryClient = useQueryClient();
  const mutation = useMutation(order => post(queryPath, { data: order }), {
    onSuccess: () => queryClient.invalidateQueries(invalidateQueryKey)
  });

  return mutation.mutateAsync;
};

export const useCustomersFilter = ({ type = LOCATION_TYPE.customer, name, size = 10, activeOnly = true, ...rest }) => {
  const queryKey = [type, "filter", name, ...Object.values(rest)],
    [get] = useGet(LOCATION_ENDPOINTS[type], {
      params: {
        page: 0,
        q: name,
        size,
        activeOnly,
        ...rest,
        ...LOCATION_PARAMS[type]
      }
    }),
    queryFn = () => get().then(LOCATION_DATA_PROCS[type]);
  return useQuery({ queryKey, queryFn, get });
};

export const useCustomersFilterById = ({ id, options }) => {
  const allLocationsType = LOCATION_TYPE.allLocations;
  const queryKey = [allLocationsType, "filter"];
  const [get] = useGet(`${BASE_URL_CUSTOMERS}/filter`, {
    params: {
      id,
      q: "",
      page: 0,
      size: 1
    }
  });
  const queryFn = () =>
    get().then(res => {
      return LOCATION_DATA_PROCS[allLocationsType](res)?.[0];
    });
  return useQuery({ queryKey, queryFn, get, ...options });
};

/**
 * Sharing Network Hook for using current logged in user info.
 * @returns {{
 *  title: string;
 *  phone: string;
 *  agencies: Array<{
 *    id: string;
 *    name: string;
 *  }>;
 *  tags: string;
 *  email: string;
 *  name: string;
 *  id: string;
 *  type: string;
 * }}
 */
export const useLoggedInUserInfo = () => {
  const authState = useSelector(path(["cognito", "authState"]));
  const queryKey = useMemo(() => `${BASE_URL_CONTACTS}/me`, []);
  const [get] = useGet(queryKey);
  const { data } = useQuery(queryKey, get, {
    enabled: authState === "signedin"
  });
  return data;
};

export const useCustomersProducts = customerId => {
  const [queryKey, queryPath] = getCustomerKeyAndPath("products", customerId);
  const [get] = useGet(queryPath);
  return useQuery(queryKey, get, { enabled: !isNil(customerId) });
};

/**
 * Sharing Network Hook for using customers, that relates to specified product
 * @param {string} initialContactId
 * @returns {[Array<{companyName: string; id: string;}>, (contactId: string) => void]}
 */
export const useProductCustomers = initialProduct => {
  const [state, setState] = usePersistantViewState(VIEW_STATE_STORAGE_KEYS.PRODUCT_CUSTOMERS, []);
  const [product, setProduct] = useState(initialProduct);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!product) {
      dispatch(beginAjaxCall());
      loadCustomerNames(null, product)
        .then(({ data }) => setState(data._embedded.customers || []))
        .finally(dispatch(endAjaxCall()));
    }
  }, [product]);

  return [state, setProduct];
};

/**
 *
 * @param {string} initialCustomerId
 * @returns {[{
 *  sites: Array<{ name: string; url: string; custom: boolean }>;
 *  config: {
 *    primarySiteOrder: string[];
 *    secondarySiteOrder?: string[];
 *    customSites?: Array<{
 *      url: string;
 *      name: string;
 *    }>;
 *    primarySmsMessage: string;
 *    primaryEmailMessage: string;
 *    defaultSmsMessage: string;
 *    defaultEmailMessage: string;
 *    id: string;
 *    ownedBy: { id: string };
 *  }
 * }, (customerId: string) => void]}
 */
export const useCustomerInviteConfiguration = initialCustomerId => {
  const [state, setState] = useState({
    sites: [],
    config: { primarySiteOrder: [] }
  });
  const [customerId, setCustomerId] = useState(initialCustomerId);
  const dispatch = useDispatch();

  const preformRequest = useCallback(() => {
    if (!!customerId) {
      dispatch(beginAjaxCall());
      client
        .get(`inviteConfigurations/customer/${customerId}`)
        .then(({ data }) => setState(data))
        .finally(dispatch(endAjaxCall()));
    }
  }, [customerId, dispatch]);

  useEffect(() => {
    preformRequest();
  }, [preformRequest]);

  return [state, setCustomerId, preformRequest];
};

export const useCampaignMessageFlow = customerId => {
  const queryKey = `${BASE_URL_CUSTOMERS}/${customerId}/smsConfiguration`;
  const [get] = useGet(queryKey);
  return useQuery(queryKey, get, { enabled: !isNil(customerId) });
};

export const useUpdateCustomerInviteConfiguration = customerId => {
  const dispatch = useDispatch();
  const callback = useCallback(
    update => {
      if (customerId) {
        dispatch(beginAjaxCall());
        return client
          .post(`inviteConfigurations/customer/${customerId}`, update)
          .then(v => v.data)
          .finally(() => dispatch(endAjaxCall()));
      }
      return Promise.resolve({ sites: [], config: { primarySiteOrder: [] } });
    },
    [customerId]
  );

  return callback;
};

const useCachedCustomers = () => useSelector(s => get(s, [CACHE_STATE_KEY], CACHE_INITIAL_VALUE));

/**
 * @type {() => (id: string) => string}
 */
export const useCustomerFilterId2NameMapper = () => {
  const cached = useCachedCustomers();
  const [pending, setPending] = useState([]);
  const dispatch = useDispatch();
  const throttledLoader = useCallback(
    throttle(
      v => {
        if (v.length > 0) {
          loadCustomersFilter({ id: v }, 0, v.length, "customerName")
            .then(({ data }) => {
              const customers = data._embedded?.responses || [];
              if (customers.length > 0) {
                dispatch(actionMergeCustomersIn(customers));
              }
              setPending([]);
            })
            .catch(error => console.log(error));
        }
      },
      500,
      { leading: false, trailing: true }
    ),
    []
  );
  useEffect(() => {
    throttledLoader(pending);
  }, [pending, throttledLoader]);
  return useCallback(
    id => {
      const found = cached.find(v => v.id === id);
      if (!found) {
        setPending(prev => (prev.includes(id) ? prev : [...prev, id]));
      }
      return [found?.contactName, found?.companyName];
    },
    [cached]
  );
};

/**
 * @type {() => (query: string, product: string) => Promise<Array<{id: string; companyName: string}>>}
 */
export const useCustomersNamesLoader = () => {
  return useCallback(
    (query, product, size = 25) =>
      loadCustomers({ q: query, product }, 0, size, "customerName").then(({ data }) => {
        return data._embedded?.customers || [];
      }),
    []
  );
};

/**
 * @type {(product: string) => (query: string) => Promise<Array<{id: string; companyName: string}>>}
 */
export const useCustomersWithProductLoader = product => {
  return useCallback(
    query =>
      loadCustomers({ q: query, product, withProperties: true }, 0, 25, "customerName").then(({ data }) => {
        return data._embedded?.customers || [];
      }),
    [product]
  );
};

export const useInteractionCustomersMatchingFilterLoader = filter => {
  return useCallback(
    query =>
      loadCustomers({ q: query, ...filter, withProperties: true }, 0, 25, "interaction").then(({ data }) => {
        return data._embedded?.customers || [];
      }),
    [filter]
  );
};

/**
 * customer filter for product that supports brands and contacts
 * @type {(product: string) => (query: string) => Promise<Array<{id: string; companyName: string}>>}
 */
export const useCustomersFilterWithProductLoader = product => {
  return useCallback(
    query =>
      loadCustomersFilter({ q: query, ...(product && { product }) }, 0, 25, "customerName").then(({ data }) => {
        return data._embedded?.responses || [];
      }),
    [product]
  );
};

export const useGMBLinkedCustomersLoader = () => {
  return useCallback(
    (query, id, brands) =>
      loadCustomers({ q: query, id, brands }, 0, 25, "customerGmb").then(({ data }) => {
        return (data._embedded?.customers || []).reduce(
          (acc, v) =>
            v.gmbInfo?.length > 0
              ? [
                  ...acc,
                  ...v.gmbInfo.map(gmb => ({
                    companyName: v.companyName,
                    id: v.id,
                    siteId: gmb.id,
                    location: gmb.location,
                    businessName: gmb.businessName || "",
                    address: gmb.address || "",
                    defaultReviewTag: v.defaultReviewTag || ""
                  }))
                ]
              : acc,
          []
        );
      }),
    []
  );
};

export const useCustomerIdParameter = (localStorageKey = null) => {
  let customer = useLocationQueryParamValue("customer");
  if (!customer && localStorageKey) {
    customer = localStorage.getItem(localStorageKey);
  }
  return { customer };
};

export const useContactIdParameter = () => {
  const contact = useLocationQueryParamValue("contact");
  return { contact };
};

export const useBrandParameter = () => {
  const brand = useLocationQueryParamValue("brand");
  return { brand };
};

export const useHasLocationIdParameter = () => {
  return [
    useLocationQueryParamValue("location[id]"),
    useLocationQueryParamValue("location[brand]"),
    useLocationQueryParamValue("account[id]"),
    useLocationQueryParamValue("account[name]"),
    useLocationQueryParamValue("customer"),
    useLocationQueryParamValue("contact"),
    useLocationQueryParamValue("contactName"),
    useLocationQueryParamValue("brand"),
    useLocationQueryParamValue("location[name]"),
    useLocationQueryParamValue("location[value]")
  ].some(x => x);
};

export function useAdditionalPlaceholders(id) {
  const [queryKey, queryPath] = getCustomerKeyAndPath("placeholders", id);
  const [get] = useGet(queryPath);
  const queryRun = useCallback(() => get(), [get]);
  return useQuery(queryKey, queryRun, {
    placeholderData: [],
    enabled: !!id
  });
}

export const useActiveInviteCustomerLoader = () => {
  return useInteractionCustomersMatchingFilterLoader({
    product: inviteCustomerProductsKeys,
    status: "ACTIVE"
  });
};

export const useSiteInfo = ({ customerId, field }) => {
  const [queryKey, queryPath] = getCustomerKeyAndPath("siteInfo", customerId, field);
  const [get] = useGet(queryPath);
  const queryRun = useCallback(
    () =>
      get().then(data => {
        return data?._embedded;
      }),
    [get]
  );
  return useQuery(queryKey, queryRun, { enabled: !!customerId });
};

export function useUpdateDefaultReviewTag({ customerId, field, onSuccess }) {
  const patch = usePatch();
  const mutation = useMutation(
    ({ defaultReviewTag, siteId }) => {
      const path = customerPaths.updateDefaultTag(customerId, siteId, field);
      return patch(path, {
        data: defaultReviewTag,
        headers: {
          "Content-type": "text/plain"
        }
      });
    },
    {
      onSuccess
    }
  );
  return mutation.mutateAsync;
}

export const useAddSite = ({ customerId, field, override = false }) => {
  const queryPath = customerPaths.addSite(customerId, field);
  const post = usePost();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    entity =>
      post(queryPath, {
        data: entity,
        params: { override }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(customerKeys.siteInfo(customerId, field))
    }
  );
  return mutation.mutateAsync;
};

export const useRemoveSite = ({ customerId, field }) => {
  const del = useDelete();
  const queryClient = useQueryClient();
  const mutation = useMutation(siteId => del(customerPaths.removeSite(customerId, field, siteId)), {
    onSuccess: () => queryClient.invalidateQueries(customerKeys.siteInfo(customerId, field))
  });
  return mutation.mutateAsync;
};

export const useAddAndRemoveSite = ({ customerId, field, override = false }) => {
  return [useAddSite({ customerId, field, override }), useRemoveSite({ customerId, field })];
};

export function useSiteInfoRefresh({ customerId, field, siteId, onSuccess }) {
  const path = [BASE_URL_CUSTOMERS, customerId, field, siteId, "refresh"];
  const post = usePost();
  const mutation = useMutation(
    () => {
      return post(path);
    },
    {
      onSuccess
    }
  );
  return mutation.mutateAsync;
}

export function useQueueImport(customerId) {
  const post = usePost();
  const mutation = useMutation(({ source, siteInfoId, endDate = null, thirdpartyId = null }) => {
    return post(["import/customer", customerId], {
      params: {
        source,
        siteInfoId,
        endDate,
        thirdpartyId
      }
    });
  });
  return mutation.mutateAsync;
}

export function useSiteInfos(customerId) {
  const [siteInfos, setSiteInfos] = useState([]);
  useEffect(() => {
    if (customerId) {
      loadSiteInfos(customerId)
        .then(res => setSiteInfos(res.data))
        .catch(() => setSiteInfos([]));
    } else {
      setSiteInfos([]);
    }
  }, [customerId]);
  return siteInfos;
}
