const labelsToPlaceholderOptions = labels => labels.map(v => Object.assign({}, { label: v, value: v.toUpperCase() }));

const staffNotificationPlaceholderLabels = [
  "Customer First Name",
  "Customer Last Name",
  "Staff First Name",
  "Staff Last Name",
  "Location",
  "Salutation"
];

const noStaffNotificationPlaceholderLabels = ["Location", "Customer First Name", "Customer Last Name"];

const noStaffInviteNotificationPlaceholderLabels = [
  "Customer First Name",
  "Customer Last Name",
  "Location",
  "Salutation"
];

const noInteractionNotificationPlaceholderLabels = ["Location"];

const autoreplyMessagePlaceholderLabels = ["Location", "Business phone"];

const signaturePlaceholderLabels = ["First Name", "Last Name", "Full Name", "phone", "Email", "Title"];

export const staffNotificationPlaceholders = labelsToPlaceholderOptions(staffNotificationPlaceholderLabels);
export const signaturePlaceholders = labelsToPlaceholderOptions(signaturePlaceholderLabels);

export const noStaffNotificationPlaceholders = labelsToPlaceholderOptions(noStaffNotificationPlaceholderLabels);

export const noStaffInviteNotificationPlaceholders = labelsToPlaceholderOptions(
  noStaffInviteNotificationPlaceholderLabels
);

export const noInteractionNotificationPlaceholders = labelsToPlaceholderOptions(
  noInteractionNotificationPlaceholderLabels
);

export const autoreplyMessagePlaceholders = labelsToPlaceholderOptions(autoreplyMessagePlaceholderLabels);

export const placeholderSynonyms = {
  "STAFF FIRST NAME": "EMPLOYEE FIRST NAME",
  "STAFF LAST NAME": "EMPLOYEE LAST NAME"
};
