import { useEffect, useState, useRef } from "react";
import { unescape } from "lodash";
import XMLParser from "react-xml-parser";
import useMeasure from "react-use-measure";
import Skeleton from "components/Skeleton";
import { useErrorNotification } from "components/Notifications/notification";

import styles from "./RecentBlogPosts.module.scss";

const { blogPostsClass, blogPostClass, textBlockClass } = styles;

const RSS_LINK = "https://www.widewail.com/blog/rss.xml";

const getFeed = async () => {
  try {
    const response = await fetch(RSS_LINK);
    const xml = await response.text();
    const parser = new XMLParser().parseFromString(xml);
    let items = parser
      .getElementsByTagName("item")
      .slice(0, 2)
      .map(({ children }) => {
        return (children || []).reduce((acc, next) => {
          acc[next.name] = next.value;
          if (next.name === "description") {
            const tmp = document.createElement("div");
            tmp.innerHTML = unescape(next.value);
            const image = tmp.querySelector("img")?.getAttribute("src");
            const description = tmp.querySelector("p")?.innerText;
            acc = { ...acc, image, description };
            tmp.remove();
          }
          return acc;
        }, {});
      });
    return items;
  } catch (e) {
    throw e;
  }
};

const RecentBlogPosts = () => {
  const errorNotify = useErrorNotification();
  const [blogPosts, setBlogPosts] = useState(null);
  const [resizeRef, { width }] = useMeasure();
  const [wasShown, setWasShown] = useState(false);

  useEffect(() => {
    if (width !== 0) {
      setWasShown(true);
    }
  }, [width]);

  useEffect(() => {
    if (wasShown) {
      getFeed()
        .then(setBlogPosts)
        .catch(errorNotify);
    }
  }, [wasShown]);

  return (
    <div className={blogPostsClass} ref={resizeRef}>
      {Array.isArray(blogPosts)
        ? blogPosts.map((post, index) => (
            <div key={index} className={blogPostClass}>
              {!!post.image && <img src={post.image} />}
              <a href={post.link} target="_blank">
                <div className={textBlockClass}>
                  <h5>{post.title}</h5>
                  <p>{post.description}</p>
                </div>
              </a>
            </div>
          ))
        : <Skeleton width={460} height={440} />}
    </div>
  );
};

export default RecentBlogPosts;
