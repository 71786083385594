import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import AuditLog from "../../components/Audit/AuditLog";
import { useDispatch } from "react-redux";
import { endAjaxCall, beginAjaxCall } from "../../redux/actions/ajaxStatusActions";
import { useParams } from "react-router-dom";
import LoadingPage from "../../components/Misc/LoadingPage";
import Block from "../../components/Layout/Block";

export default function AuditLogScreen({ load, log, propertyRenderers }) {
  const [history, setHistory] = useState(log);
  const dispatch = useDispatch();
  const { id } = useParams();

  function loadHistory() {
    if (id && load) {
      dispatch(beginAjaxCall());
      load(id)
        .then(revisions => setHistory(revisions))
        .finally(() => dispatch(endAjaxCall()));
    }
  }

  useEffect(() => {
    loadHistory();
  }, [id, load]);

  if (!history) return <LoadingPage />;

  return (
    <div className="px-4 pt-4">
      <Block>
        <Card>
          <CardBody>
            <AuditLog log={history} propertyRenderers={propertyRenderers} />
          </CardBody>
        </Card>
      </Block>
    </div>
  );
}
