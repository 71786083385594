import { either, filter, identity, isEmpty, isNil, path, pipe, unapply } from "ramda";
import { useCallback } from "react";
import { Form } from "reactstrap";
import InputField from "../../../components/Form/InputField";
import { bySource } from "../../../data/sites";
import usePureMemo from "../../../hooks/usePureMemo/usePureMemo";
import { useStateThroughPipe } from "../../../hooks/utilHooks";
import { noop } from "lodash";
import WWButton from "components/Buttons/WWButton";

const { source } = bySource("APARTMENT_RATINGS");

export default class ApartmentRatingsAdminLinking {
  field() {
    return "apartmentRatingsInfo";
  }

  add() {
    return ApartmentRatingsLinkAdd;
  }

  edit() {
    return ApartmentRatingsLinkEdit;
  }
}

const nativeInputEventPipe = path(["target", "value"]);

const ApartmentRatingsLinkAdd = ({ onSave }) => {
  const [propertyId, setPropertyId] = useStateThroughPipe("", nativeInputEventPipe);
  const allFilled = usePureMemo(pipe(unapply(identity), filter(either(isNil, isEmpty)), isEmpty), propertyId);
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      onSave({ propertyId, source });
    },
    [onSave, propertyId, source]
  );

  return (
    <Form onSubmit={onSubmit}>
      <InputField inline={false} label="Property Id" value={propertyId} onChange={setPropertyId} />
      <WWButton type="submit" color="primary" disabled={false}>
        Link
      </WWButton>
    </Form>
  );
};

const ApartmentRatingsLinkEdit = ({ info, customer }) => {
  return (
    <>
      <InputField name="propertyId" label="Property Id" value={info.propertyId} disabled={true} onChange={noop} />
    </>
  );
};
