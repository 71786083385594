import { AsyncTypeahead } from "react-bootstrap-typeahead";
import React, { useContext, useCallback, useEffect, useState, useRef } from "react";
import { Label, InputGroup, InputGroupText } from "reactstrap";
import { isUndefined } from "lodash";
import { FormContext } from "../Form";
import RemovableListItem from "components/Misc/RemovableListItem";
import FormField from "../FormField";

const TypeaheadSearchField = ({
  onInputChange,
  onSearch,
  placeholder,
  options,
  name,
  label,
  inline,
  className,
  minLength,
  filterBy = () => true,
  initialValue,
  defaultSelected,
  multySelect,
  labelKey,
  multyLabelKey,
  multyValuesLabel,
  iconClass,
  additionalControl,
  inputWidth,
  children,
  tip,
  ...props
}) => {
  const typeaheadRef = useRef(null);
  const context = useContext(FormContext);

  const [selected, setSelected] = useState(initialValue || []);

  useEffect(() => {
    context && context.registerField(name);
  }, [name]);

  const onChange = useCallback(
    value => {
      if (isUndefined(value?.[0])) {
        return;
      }
      if (multySelect) {
        const next = [...selected, value[0]];
        setSelected(next);
        typeaheadRef.current.clear();
        return context.setRawValue({ target: { name, value: next } });
      }
      return context.setSelectValueWithLabel({ target: { name, value: value[0] } });
    },
    [context, multySelect, selected]
  );

  const onRemove = useCallback(
    value => {
      const next = selected.filter(({ googleAreaId }) => googleAreaId !== value.googleAreaId);
      setSelected(next);
      context.setRawValue({ target: { name, value: next } });
    },
    [selected]
  );

  const toLabel = multyLabelKey || labelKey;
  return (
    <FormField
      label={label}
      className={className}
      name={name}
      inline={inline}
      additionalControl={additionalControl}
      tip={tip}
    >
      {iconClass ? (
        <InputGroup>
          <InputGroupText>
            <i className={iconClass} />
          </InputGroupText>
          <AsyncTypeahead
            ref={typeaheadRef}
            onInputChange={onInputChange}
            onSearch={onSearch}
            placeholder={placeholder}
            options={options}
            name={name}
            onChange={onChange}
            defaultInputValue={multySelect ? undefined : initialValue}
            minLength={minLength}
            labelKey={labelKey}
            filterBy={filterBy}
            useCache={false}
            {...props}
          >
            {children}
          </AsyncTypeahead>
        </InputGroup>
      ) : (
        <AsyncTypeahead
          ref={typeaheadRef}
          onInputChange={onInputChange}
          onSearch={onSearch}
          placeholder={placeholder}
          options={options}
          name={name}
          onChange={onChange}
          defaultInputValue={multySelect ? undefined : initialValue}
          minLength={minLength}
          labelKey={labelKey}
          {...props}
        >
          {children}
        </AsyncTypeahead>
      )}
      {multySelect && (
        <>
          {!!multyValuesLabel && !!selected?.length && (
            <>
              <Label className="my-2 py-1 text-muted font-sm fw-normal d-block">{multyValuesLabel}</Label>
              {selected.map(value => (
                <RemovableListItem
                  key={value.label}
                  value={toLabel ? toLabel(value) : value.label}
                  onRemove={() => onRemove(value)}
                />
              ))}
            </>
          )}
        </>
      )}
    </FormField>
  );
};

export default React.memo(TypeaheadSearchField);
