import { deleteSquareInfo, getSquareLocations, updateSquareInfo } from "api/squareApi";
import * as customerApi from "../../api/customerApi";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import Block from "components/Layout/Block";
import Chip from "components/WWChips/WWChip/WWChip";
import React, { useCallback, useEffect, useState } from "react";
import { Alert } from "reactstrap";
import StatusMessages from "../../components/Misc/StatusMessages";
import { LOCAL_STORAGE_KEYS } from "../../data/storageKeys";
import SelectField from "../../components/Form/SelectField";
import { includes, isEmpty, map, omit, size } from "lodash";
import WWAccordionSection from "components/WWAccordion/WWAccordionSection";
import DeleteButton from "components/Buttons/DeleteButton";
import AjaxLoader from "components/Misc/AjaxLoader";
import { autoclose } from "components/Modals";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import WWButton from "components/Buttons/WWButton";
import { useNotification } from "components/Notifications/notification";
import ProgressTracker from "components/ProgressTracker/ProgressTracker";
import { v4 as uuidv4 } from "uuid";

export const squareIntegrationSteps = Object.freeze({
  authorize: "authorize square",
  selectLocation: "select location",
  linkSquare: "link square"
});

const AUTHORIZE_STEP_INDEX = 0;
const SELECT_LOCATION_STEP_INDEX = 1;
const LINK_SITE_STEP_INDEX = 2;
const FINISH_STEP_INDEX = 3;

const SquareLinking = ({ customer }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(AUTHORIZE_STEP_INDEX);
  const [errors, setErrors] = useState([]);
  const [connected, setConnected] = useState();
  const [importNewPayment, setImportNewPayment] = useState(customer.squareInfo?.[0]?.importNewPayment);
  const [importCompletedOrder, setImportCompletedOrder] = useState(customer.squareInfo?.[0]?.importCompletedOrder);
  const [importPaidInvoice, setImportPaidInvoice] = useState(customer.squareInfo?.[0]?.importPaidInvoice);
  const [squareLocations, setSquareLocations] = useState();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [squareInfo, setSquareInfo] = useState({});
  const confirm = useConfirmationDialog();
  const notify = useNotification();

  const authURL = useCallback(
    state =>
      `${process.env.REACT_APP_SQUARE_BASE_URL}/oauth2/authorize` +
      `?client_id=${encodeURIComponent(process.env.REACT_APP_SQUARE_CLIENTID)}` +
      `&scope=CUSTOMERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_READ+ORDERS_READ+EMPLOYEES_READ+INVOICES_READ+ITEMS_READ` +
      `&session=false` +
      `&state=${state}`,
    []
  );

  const reload = useCallback(async () => {
    try {
      if (customer.id) {
        const sites = await customerApi.fetchSites(customer.id, "squareInfo");
        const newSquareInfo = sites.data?.[0] || {};
        setSquareInfo(newSquareInfo);
        setImportNewPayment(newSquareInfo.importNewPayment);
        setImportCompletedOrder(newSquareInfo.importCompletedOrder);
        setImportPaidInvoice(newSquareInfo.importPaidInvoice);
        const infoFetched = !isEmpty(newSquareInfo);
        setConnected(infoFetched);
        if (infoFetched) {
          const { data } = await getSquareLocations(customer.id, newSquareInfo.id);
          setSquareLocations(
            data.filter(
              location =>
                !includes(
                  newSquareInfo.locations.map(currentLocation => currentLocation.id),
                  location.id
                )
            )
          );
          isEmpty(newSquareInfo.locations)
            ? setActiveStepIndex(SELECT_LOCATION_STEP_INDEX)
            : setActiveStepIndex(FINISH_STEP_INDEX);
        }
      }
    } catch (error) {
      setErrors(error.response?.data?.errors || error);
    }
  }, [customer]);

  useEffect(() => {
    reload();
  }, [customer.id, reload]);

  const onConnectClick = useCallback(() => {
    const state = uuidv4();
    localStorage.setItem(LOCAL_STORAGE_KEYS.AWAITED_REDIRECT_CUSTOMER, customer.id);
    localStorage.setItem(LOCAL_STORAGE_KEYS.SQUARE_STATE, state);
    window.location.replace(authURL(state));
  }, [authURL, customer.id]);

  const updateSquare = useCallback(() => {
    updateSquareInfo(customer.id, squareInfo.id, {
      importNewPayment,
      importCompletedOrder,
      importPaidInvoice,
      locations: [...squareInfo.locations, ...selectedLocations]
    })
      .then(() => {
        setSelectedLocations([]);
        reload();
        notify({ body: "success" });
      })
      .catch(error => setErrors(error.response?.data?.errors || error));
  }, [
    customer.id,
    selectedLocations,
    importNewPayment,
    importCompletedOrder,
    importPaidInvoice,
    squareInfo,
    reload,
    notify
  ]);

  const onSelectLocation = useCallback(
    ({ target: { value } }) => {
      if (!selectedLocations.find(({ id }) => id === value.id)) {
        setSelectedLocations([...selectedLocations, omit(value, "address")]);
        setActiveStepIndex(LINK_SITE_STEP_INDEX);
      }
    },
    [selectedLocations]
  );

  const unlinkSquare = useCallback(() => {
    deleteSquareInfo(customer.id, customer?.squareInfo[0].id)
      .then(() => {
        setConnected(false);
        setActiveStepIndex(AUTHORIZE_STEP_INDEX);
      })
      .catch(error => setErrors(error.response?.data?.errors || error));
  }, [customer]);

  const removeSquareLocation = useCallback(
    (event, removedLocation) => {
      event.stopPropagation();
      updateSquareInfo(customer.id, squareInfo.id, {
        locations: squareInfo.locations.filter(location => location.id !== removedLocation.id)
      })
        .then(() => {
          reload();
        })
        .catch(error => setErrors(error.response?.data?.errors || error));
    },
    [customer.id, squareInfo, reload]
  );

  return (
    <>
      <ProgressTracker
        steps={[
          squareIntegrationSteps.authorize,
          squareIntegrationSteps.selectLocation,
          squareIntegrationSteps.linkSquare
        ]}
        activeStepIndex={activeStepIndex}
      />
      <StatusMessages errors={errors} />
      {connected === undefined && <AjaxLoader />}
      {connected === true ? (
        <div>
          <h4>Square Access Token: {squareInfo.tokenStatus}</h4>
          <h5 className="mb-3">Trigger invitations on</h5>
          <Labeled
            name="importNewPayment"
            checked={importNewPayment}
            onToggle={setImportNewPayment}
            label="New Payment"
          />
          <Labeled
            name="importCompletedOrder"
            checked={importCompletedOrder}
            onToggle={setImportCompletedOrder}
            label="Completed Order"
          />
          <Labeled
            name="importPaidInvoice"
            checked={importPaidInvoice}
            onToggle={setImportPaidInvoice}
            label="Paid Invoice"
          />
          <Block className="my-4">
            <h4>Select Locations</h4>
            <SelectField
              name="locations"
              options={squareLocations}
              isDisabled={!squareLocations}
              getOptionLabel={({ name }) => name}
              getOptionValue={o => o}
              onChange={onSelectLocation}
            />
          </Block>
          {selectedLocations.map((location, index) => {
            return (
              <Chip
                key={index}
                onClick={() => {
                  const locations = [...selectedLocations];
                  locations.splice(index, 1);
                  setSelectedLocations(locations);
                }}
              >
                {location.name}
              </Chip>
            );
          })}
          <WWButton
            disabled={(isEmpty(squareInfo.locations) || squareInfo.disabled) && isEmpty(selectedLocations)}
            color="primary"
            className="w-100"
            onClick={updateSquare}
          >
            {squareInfo?.disabled ? "Finish linking" : "Update Linking"}
          </WWButton>
          <Block className="my-4">
            {size(squareInfo.locations) > 0 ? (
              <>
                <hr />
                <h4>Linked Locations</h4>
                {map(squareInfo.locations, location => (
                  <Block key={location.id}>
                    <WWAccordionSection
                      caption={
                        <div className="d-flex align-items-center">
                          <DeleteButton
                            type="button"
                            size="sm"
                            tooltip="Remove location"
                            squareButton
                            iconClass="fa fa-chain-broken"
                            confirmationPrompt="Widewail will no longer import data from this location."
                            onClick={event => removeSquareLocation(event, location)}
                          >
                            {null}
                          </DeleteButton>
                          <div className=" ms-3 flex-shrink-1 d-flex align-items-center flex-wrap">
                            <span className="pe-2 flex-shrink-1 fs-3 truncate">{location.name}</span>
                          </div>
                        </div>
                      }
                    >
                      <div className=" ms-3 flex-shrink-1 d-flex align-items-center flex-wrap">
                        <span className="pe-2 flex-shrink-1 fs-3 truncate">Location ID: {location.id}</span>
                      </div>
                    </WWAccordionSection>
                  </Block>
                ))}
              </>
            ) : (
              <Alert color={"danger"}>To finish your linking please add a location</Alert>
            )}
          </Block>
          <WWButton
            color="primary"
            className="w-100"
            onClick={() =>
              autoclose(
                confirm({
                  title: "Unlink Square",
                  body: (
                    <p>Widewail will no longer import data for this location and all access tokens will be revoked</p>
                  )
                })
              ).then(confirm => confirm && unlinkSquare())
            }
          >
            Unlink Square
          </WWButton>
        </div>
      ) : (
        <WWButton color="primary" className="w-100" onClick={onConnectClick}>
          Connect to Square
        </WWButton>
      )}
    </>
  );
};

export default SquareLinking;
