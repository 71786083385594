import { Amplify, Auth } from "aws-amplify";
import { path, prop } from "ramda";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { isUndefined } from "lodash";
import { loadConfigurationHistory } from "../api/inviteConfigurationApi";
import withAuthorization from "../components/Auth/Authorization";
import LoginContainer, { useClientId } from "../components/Auth/LoginContainer";
import { permissions } from "../components/Auth/permissions";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import AjaxLoader from "../components/Misc/AjaxLoader";
import AppMessages from "../components/Misc/AppMessages";
import { autoclose, useModal } from "../components/Modals";
import { riseOptionsDialog } from "../components/Modals/options.modal";
import { errorCaughtNotifier, useLocalNotifications } from "../components/Notifications/notification";
import Sidebar from "../components/Sidebar/Sidebar";
import config from "../config";
import { pickAgency } from "../redux/actions/agencyActions";
import { initComplete } from "../redux/actions/appActions";
import { loginUser } from "../redux/actions/authStatusActions";
import { currentContact, currentContactActiveProducts } from "../redux/actions/currentContactActions";
import logger from "../util/logger";
import { isCurrentUserInGroup } from "../util/userUtils";
import { getApprovalSearch } from "../util/getApprovalSearch";
import AuditLogScreen from "../views/Audit/AuditLogScreen";
import ContactsList from "../views/Contacts/ContactsList";
import EditContact from "../views/Contacts/EditContact";
import CustomerList from "../views/Customer/CustomerList";
import EditCustomer, { loadCustomerHistory } from "../views/Customer/EditCustomer";
import AdminDashboard from "../views/Dashboard/AdminDashboard";
import SinglePointReport from "../views/Dashboard/SinglePointReport";
import UserDashboard from "../views/Dashboard/UserDashboard/UserDashboard";
import { ConfigurationPage } from "../views/Invite/InviteConfiguration";
import { InviteReporting } from "../views/Invite/InviteReporting";
import InviteVideoReporting from "../views/Invite/Video/InviteVideoReporting";
import { LeadEdit } from "../views/Invite/LeadEdit";
import { LeadsList } from "../views/Invite/LeadsList";
import VideoReviews from "../views/Invite/Video/Video";
import ImportLogList from "../views/Logs/ImportLogList";
import InboundList from "../views/Logs/InboundList";
import { CsvLinks } from "../views/Logs/CsvLinks";
import EditPost from "../views/Post/EditPost";
import PostList from "../views/Post/PostList";
import CustomerSummaryReport from "../views/Reports/CustomerSummaryReport";
import Engage from "../views/Reports/Engage";
import ReviewThreadList from "../views/Reports/ReviewThreadList";
import TagsReport from "../views/Reports/TagsReport";
import EditResponseTemplates from "../views/ResponseTemplates/EditResponseTemplates";
import ReviewFeed from "../views/Reviews/Feed";
import ReplyReview, { loadReviewHistory } from "../views/Reviews/ReplyReview";
import ResponderPerformance from "../views/Reviews/ResponderPerformance";
import { ReviewList } from "../views/Reviews/ReviewList";
import Settings from "../views/Settings/Settings";
import ShareList from "../views/Sharing/ShareList";
import { HubspotOAuthResult } from "../views/Tools/HubspotOAuthResult";
import { KeapOAuthResult } from "../views/Tools/KeapOAuthResult";
import Welcome from "../views/Welcome/Welcome";
import FirebaseLayer from "./FirebaseLayer";
import { AutomatedCampaigns } from "../views/Invite/AutomatedCampaigns/AutomatedCampaigns";
import { CampaignsList } from "../views/Invite/Campaigns/CampaignsList";
import CredentialsList from "../views/Credentials/CredentialsList";
import { useLoggedInUserInfo } from "../hooks/customerHooks";
import TagManager from "react-gtm-module";
import { useCurrentRouteName } from "hooks/routeHooks";
import { SquareOAuthResult } from "views/Tools/SquareOAuthResult";
import LearningCenter from "views/LearningCenter/LearningCenter";
import ListingsList from "views/Listings/ListingsList/ListingsList";
import { USE_NEW_DASHBOARD_FEATURE } from "data/optInFeatures";
import useFilterPersistence from "hooks/useFilterPersistence";
import EditAgency from "views/Agencies/EditAgency";
import { useSidebarPersistantViewState } from "hooks/stateHooks";
import classNames from "classnames";
import { useContactActiveProducts } from "hooks/data/useContact";
import EnterpriseReport from "views/Reports/EnterpriseReport";
import AccountsList from "views/Account/AccountsList";
import EditAccount from "views/Account/EditAccount";
import { useQueryClient } from "react-query";
import { ListingsPage } from "views/Listings/ListingsPage/ListingsPage";

// const LoggedOutPage = lazy(() => import("../views/Pages/Login/LoggedOutPage"));
// const LoginContainer = lazy(() => import("../components/Auth/LoginContainer"));
// const AuditLogScreen = lazy(() => import("../views/Audit/AuditLogScreen"));
// const ContactsList = lazy(() => import("../views/Contacts/ContactsList"));
// const EditContact = lazy(() => import("../views/Contacts/EditContact"));
// const CustomerList = lazy(() => import("../views/Customer/CustomerList"));
// const UserDashboard = lazy(() => import("../views/Dashboard/UserDashboard"));
// const ImportLogList = lazy(() => import("../views/Logs/ImportLogList"));
// const InboundList = lazy(() => import("../views/Logs/InboundList"));
// const EditPost = lazy(() => import("../views/Post/EditPost"));
// const PostList = lazy(() => import("../views/Post/PostList"));
// const CustomerSummaryReport = lazy(() => import("../views/Reports/CustomerSummaryReport"));
// const Engage = lazy(() => import("../views/Reports/Engage"));
// const ReviewThreadList = lazy(() => import("../views/Reports/ReviewThreadList"));
// const EditResponseTemplates = lazy(() => import("../views/ResponseTemplates/EditResponseTemplates"));
// const ReviewFeed = lazy(() => import("../views/Reviews/Feed"));
// const ResponderPerformance = lazy(() => import("../views/Reviews/ResponderPerformance"));
// const ReviewApproval = lazy(() => import("../views/Reviews/ReviewApproval"));
// const ShareList = lazy(() => import("../views/Sharing/ShareList"));
// const FeedExplorer = lazy(() => import("../views/Tools/FeedExplorer"));
// const Welcome = lazy(() => import("../views/Welcome/Welcome"));
// const SinglePointReport = lazy(() => import("../views/Dashboard/SinglePointReport"));
// const AdminDashboard = lazy(() => import("../views/Dashboard/AdminDashboard"));
const MockReviewCreator = lazy(() => import("../views/Reviews/Creator/MockReviewCreator"));

//not authorized in the component itself so users can access to edit their own info
const AuthEditContact = withAuthorization(permissions.CONTACT_MANAGE)(EditContact);

const Full = () => {
  const [loading, setLoading] = useState(true);
  const authState = useSelector(path(["cognito", "authState"]));
  const user = useSelector(path(["cognito", "user"]));
  const app = useSelector(prop("app"));
  const features = useSelector(path(["currentContact", "features"]));
  const currentAgency = useSelector(prop("currentAgency"));
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useLocalNotifications();
  const location = useLocation();
  const me = useLoggedInUserInfo();
  const currentRoute = useCurrentRouteName();
  const activeProductsQuery = useContactActiveProducts(me?.id);
  const [minimizedSidebar, expandedForMobileSidebar] = useSidebarPersistantViewState();
  const queryClient = useQueryClient();
  const cognitoClientId = useClientId();

  useFilterPersistence();

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: config.cognito.region,
        userPoolId: config.cognito.userPool,
        userPoolWebClientId: cognitoClientId,
        authenticationFlowType: "USER_PASSWORD_AUTH"
      }
    });
  }, []);

  useEffect(() => {
    const { data, isLoading } = activeProductsQuery;
    if (!isLoading && !isUndefined(data)) {
      dispatch(currentContactActiveProducts(data));
    }
  }, [activeProductsQuery.isLoading, activeProductsQuery.data]);

  useEffect(() => {
    document.title = (!!currentRoute && currentRoute + " | ") + "Widewail";
  }, [currentRoute]);

  useEffect(() => {
    Auth.currentSession()
      .then(data => {
        dispatch(loginUser(data, "signedin"));
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (me && !app.init) {
      const tagManagerArgs = {
        dataLayer: {
          event: "userInfo",
          userId: me.id,
          agencyName: currentAgency.name,
          agencyAdmin: isCurrentUserInGroup([permissions.AGENCY_ADMIN]),
          wwEmployee: /.*@widewail.com/g.test(me.email),
          wwResponder: isCurrentUserInGroup([permissions.REVIEW_REPLY])
        },
        events: {
          sendUserInfo: "userInfo"
        }
      };
      TagManager.dataLayer(tagManagerArgs);

      dispatch(currentContact(me));
      Promise.resolve(
        !currentAgency?.id && me.agencies.length > 1
          ? autoclose(
              riseOptionsDialog(modal)({
                title: "Pick an agency",
                allowNoOption: false,
                options: me.agencies,
                keyer: v => v.id,
                renderer: v => v.name
              })
            )
          : !!currentAgency?.id
            ? me.agencies.find(agency => agency.id === currentAgency?.id)
            : me.agencies[0]
      )
        .then(resolution => {
          dispatch(pickAgency(resolution));
          dispatch(initComplete());
          setLoading(false);
        })
        .catch(error => {
          errorCaughtNotifier(notify, "Failed loading contact")(error);
          logger.error("Failed loading contact: " + JSON.stringify(error));
        });
    }
  }, [app, dispatch, modal, notify, me, currentAgency]);

  useEffect(() => {
    if (app.init && authState) {
      queryClient.clear();
    }
  }, [currentAgency?.id]);

  const redirectOptedInUser = features?.[USE_NEW_DASHBOARD_FEATURE.name] === USE_NEW_DASHBOARD_FEATURE.optInValue;

  let defaultRoute;
  if (isCurrentUserInGroup([permissions.AGENCY_ADMIN])) {
    defaultRoute = "/admindash";
  } else {
    defaultRoute = "/dashboard";
  }

  if (authState === "signedin" && user && app.init) {
    return (
      <div
        className={classNames("app sidebar-fixed", {
          "sidebar-minimized": minimizedSidebar,
          "sidebar-mobile-show": expandedForMobileSidebar
        })}
      >
        <FirebaseLayer>
          <AppMessages />
          <Header />
          <div className="app-body">
            <Sidebar location={location} redirectOptedInUser={redirectOptedInUser} />
            <main className="main position-relative">
              <Breadcrumb />
              <Container className="p-0" fluid>
                <Suspense fallback={<AjaxLoader />}>
                  <Settings />
                  <Switch>
                    <Route exact path="/oauth/hubspot" name="Hubspot" component={HubspotOAuthResult} />
                    <Route exact path="/oauth/keap" name="Keap" component={KeapOAuthResult} />
                    <Route exact path="/oauth/square" name="Square" component={SquareOAuthResult} />
                    {/* <Route exact path="/oauth/servicefusion" name="ServiceFusion" component={ServiceFusionOAuthResult} /> // using admin linking instead of oath flow*/}
                    <Route exact path="/welcome" name="Welcome" component={Welcome} />
                    <Route exact path="/invite/leads/:id" name="Lead Details">
                      {props => <LeadEdit {...props} id={props.match.params.id} />}
                    </Route>
                    <Route exact path="/invite/leads" name="Leads" component={LeadsList} />
                    <Route exact path="/invite/video" name="Video Testimonials" component={VideoReviews} />
                    <Route exact path="/invite/configure" name="Configure Leads" component={ConfigurationPage} />
                    <Route
                      exact
                      path="/invite/automatedCampaigns"
                      name="Automated Campaigns"
                      component={AutomatedCampaigns}
                    />
                    <Route
                      exact
                      path="/invite/configure/:id/history"
                      name="Configuration History"
                      render={() => <AuditLogScreen load={id => loadConfigurationHistory(id).then(res => res.data)} />}
                    />
                    <Route exact path="/invite/campaigns" name="Campaigns" component={CampaignsList} />
                    <Route exact path="/credentials" name="Credentials" component={CredentialsList} />
                    <Route exact path="/dashboard" name="Dashboard" component={UserDashboard} />
                    <Route exact path="/admindash" name="Admin Dashboard" component={AdminDashboard} />
                    <Route exact path="/posts/new" name="Create" component={EditPost} />
                    <Route
                      exact
                      path="/posts/edit/:id"
                      name="Edit Post"
                      render={props => <EditPost {...props} key={props.match.params.id} />}
                    />
                    <Route exact path="/posts/drafts" name="Drafts" render={props => <PostList {...props} />} />
                    <Route exact path="/posts/posts" name="Posts" component={PostList} />
                    <Route exact path="/posts" name="Posts" component={PostList} />
                    <Route exact path="/posts/share" name="Ripple" component={ShareList} />
                    <Redirect exact from="/report" to="/report/group" />
                    <Route exact path="/report/group" name="Group Comparison" component={CustomerSummaryReport} />
                    <Route exact path="/report/customer" name="Review Performance" component={SinglePointReport} />
                    <Route
                      exact
                      path="/report/customer/:customer"
                      name="Review Performance"
                      component={SinglePointReport}
                    />
                    <Route exact path="/report/invite" name="Invite Summary" component={InviteReporting} />
                    <Route exact path="/report/invite/:target" name="Invite Summary" component={InviteReporting} />
                    <Route exact path="/report/video" name="Invite Video Summary" component={InviteVideoReporting} />
                    <Route exact path="/report/engage" name="Engage" component={Engage} />
                    <Route exact path="/report/engage/:customer" name="Engage" component={Engage} />
                    <Route exact path="/report/reviews" name="Review Summary" component={ReviewThreadList} />
                    <Route exact path="/report/tags" name="Tags Report" component={TagsReport} />
                    <Route exact path="/report/enterprise" name="Enterprise Report" component={EnterpriseReport} />

                    <Redirect from="/approve" to={{ pathname: "/reviews/feed", search: `?${getApprovalSearch()}` }} />
                    <Redirect
                      from="/approve/old"
                      to={{ pathname: "/reviews/feed", search: `?${getApprovalSearch()}` }}
                    />

                    <Route exact path="/customers/contacts" name="Contacts" component={ContactsList} />
                    <Route exact path="/customers/contacts/:id" name="Edit Contact" component={AuthEditContact} />
                    <Route
                      exact
                      path="/customers/contacts/:id/notificationPreferences"
                      name="Edit Contact"
                      component={AuthEditContact}
                    />
                    <Route exact path="/user" name="Edit Contact" component={EditContact} />
                    <Route exact path="/customers" name="Customers" component={CustomerList} />
                    <Route exact path="/customers/new" name="Add Customer" component={EditCustomer} />
                    <Route exact path="/customers/:id" name="Edit Customer" component={EditCustomer} />
                    <Route exact path="/customers/:id/SMS" name="Edit Customer" component={EditCustomer} />
                    <Route exact path="/customers/:id/siteIntegrations" name="Edit Customer" component={EditCustomer} />
                    <Route exact path="/customers/:id/responderNotes" name="Edit Customer" component={EditCustomer} />
                    <Route
                      exact
                      path="/customers/:id/history"
                      name="Customer History"
                      render={props => <AuditLogScreen load={loadCustomerHistory} />}
                    />
                    <Route exact path="/agency" name="Edit Agency" component={EditAgency} />
                    <Route exact path="/listings" name="Listings" component={ListingsList} />
                    <Redirect exact from="/reviews" to="/reviews/list" />
                    <Route exact path="/reviews/list" name="Reviews" component={ReviewList} />
                    <Route exact path="/reviews/feed" name="Reviews Feed" component={ReviewFeed} />
                    <Route
                      exact
                      path="/reviews/templates"
                      name="Response Templates"
                      component={EditResponseTemplates}
                    />
                    <Redirect exact from="/reviews/edit/:id" to="/reviews/list/edit/:id" />
                    <Route exact path="/reviews/list/edit/:id" name="Edit Review" component={ReplyReview} />
                    <Route
                      exact
                      path="/reviews/list/edit/:id/history"
                      name="Review History"
                      render={props => <AuditLogScreen load={loadReviewHistory} />}
                    />
                    <Route
                      exact
                      path="/reviews/responders"
                      name="Responder Performance"
                      component={ResponderPerformance}
                    />
                    <Route exact path="/reviews/creator" name="Review Creator" component={MockReviewCreator} />
                    <Route exact path="/log/import" name="Import Log" component={ImportLogList} />
                    <Route exact path="/log/inbound" name="Import Log" component={InboundList} />
                    <Route exact path="/log/csvLinks" name="Import Log" component={CsvLinks} />
                    <Route exact path="/learning" name="Learning Center" component={LearningCenter} />
                    <Route exact path="/listings/:customerId/:listingId" name="listing" component={ListingsPage} />
                    <Route exact path="/accounts" name="Edit Account" component={AccountsList} />
                    <Route exact path="/accounts/:accountId" name="Edit Account" component={EditAccount} />
                    <Redirect from="/" to={defaultRoute} />
                  </Switch>
                </Suspense>
              </Container>
            </main>
          </div>
          <Footer />
        </FirebaseLayer>
      </div>
    );
  } else {
    return <LoginContainer isAppLoading={loading} />;
  }
};

export default Full;
