import React, { useCallback, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import moment from "moment";
import Input from "components/Form/Input";

const InnerDateRangeInput = ({ date, setDate, placeholder }) => {
  const [value, setValue] = useState();

  const formatDateForInput = useCallback(dateValue => dateValue?.format("L"), []);

  useEffect(() => {
    const formattedDate = formatDateForInput(date);
    if (formattedDate !== value) {
      setValue(formattedDate);
    }
  }, [date]);

  const onChange = useCallback(
    ({ target: { value } }) => {
      setValue(value);
      const momentValue = moment(value);
      if (formatDateForInput(momentValue) === value) {
        setDate(momentValue);
      }
    },
    [value]
  );

  return <Input tag={InputMask} mask="99/99/9999" value={value} onChange={onChange} placeholder={placeholder} />;
};

export default InnerDateRangeInput;
