import { noop } from "lodash";
import React, { useState, useCallback } from "react";
import { Button } from "reactstrap";
import TextEditor from "../../../components/Form/TextEditor";

export default ({ onFinish = noop, onSave = noop, initialContent = "" }) => {
  const [content, setContent] = useState(initialContent);
  const [processing, setProcessing] = useState(false);

  const onSaveClick = useCallback(async () => {
    setProcessing(true);
    const result = await onSave(content);
    onFinish();
    setProcessing(false);
    return result;
  }, [content, setProcessing, onFinish]);

  return (
    <TextEditor onChange={setContent} value={content}>
      <Button size="sm" color="secondary" onClick={onFinish} className="me-1 mt-1">
        Cancel
      </Button>
      <Button size="sm" color="warning" className="me-1 mt-1" disabled={!content || processing} onClick={onSaveClick}>
        Save
      </Button>
    </TextEditor>
  );
};
