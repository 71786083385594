import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { isEmpty, replace } from "lodash";
import FormField from "components/Form/FormField";
import InputField from "components/Form/InputField";
import StatusMessages from "components/Misc/StatusMessages";
import { useSiteInfo, useAddAndRemoveSite } from "hooks/customerHooks";
import Block from "components/Layout/Block";
import WWAccordionSection from "components/WWAccordion/WWAccordionSection";
import DeleteButton from "components/Buttons/DeleteButton";
import { DefaultDuplicateWarning } from "./AdminLinking";
import { SITE_SOURCE_NAMES, SOURCES } from "data/sites";

const encode = txt => replace(replace(txt, /(\r\n)|(\r)|(\n)/g, "%0D%0A"), /\s/g, "%20");

function encodeUrlParameter(str) {
  return encodeURIComponent(str).replace(/%20/g, "+");
}

const searchUrl = "https://www.edmunds.com/dealerships/#";
const urlSplittingRegex = /https:\/\/www\.edmunds\.com\/dealerships(?:\/all)?\/(?<state>[a-zA-Z-]+)\/(?<city>[a-zA-Z-]+)\/(?<name>[a-zA-Z-]+)(?:_(?<id>\d+))?\/?/;
const siteName = SOURCES[SITE_SOURCE_NAMES.edmunds].label;
const siteInfoField = SOURCES[SITE_SOURCE_NAMES.edmunds].customerField;
const field = SITE_SOURCE_NAMES.edmunds;

const EdmundsLinking = ({ customer }) => {
  const [isURLValid, setIsUrlValid] = useState(undefined);
  const [url, setUrl] = useState();
  const [errors, setErrors] = useState([]);
  const [displayInstructions, setDisplayInstructions] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const currentAgency = useSelector(state => state.currentAgency);
  const currentUser = useSelector(state => state.cognito.user);

  const siteLinkingParams = { customerId: customer.id, field: siteInfoField };
  const siteInfo = useSiteInfo(siteLinkingParams);
  const [addSite, removeSite] = useAddAndRemoveSite(siteLinkingParams);

  useEffect(() => {
    if (typeof url === "string") {
      const urlParams = url.match(urlSplittingRegex);
      const { groups } = urlParams || {};
      const { city, id, name, state } = groups || {};
      if (id) {
        addSite({
          source: field,
          url: url,
          dealerState: state,
          dealerId: id,
          dealerCity: city,
          dealerName: name
        })
          .then(() => {
            setErrors();
            setIsUrlValid(true);
            setDuplicates([]);
          })
          .catch(err => {
            setErrors([`Failed adding ${siteName}`]);
            setDuplicates(err.response.data.duplicateLinks || []);
            setIsUrlValid(false);
            setDisplayInstructions(true);
          });
      } else {
        setErrors(["Could not parse URL."]);
        setIsUrlValid(false);
        setDisplayInstructions(true);
      }
    }
  }, [url]);

  const onUnlink = useCallback(
    (event, info) => {
      event.stopPropagation();
      removeSite(info.id);
    },
    [customer]
  );

  const emailBody = useMemo(
    () => `Hi Widewail team,
    This is ${currentUser?.name} from ${customer.companyName}. I need help linking my Edmunds account to my Widewail account.

Thank you all for your help.`,
    [customer.companyName, currentUser]
  );

  return (
    <>
      <StatusMessages errors={errors} />
      <DefaultDuplicateWarning duplicates={duplicates} agency={currentAgency} />
      <h4 className="mb-2">Add a New Edmunds Site</h4>
      <InputField
        inline={false}
        tip="Ctrl+C to copy and Ctrl+V to paste"
        name="accountUrl"
        placeholder={`${siteName} URL...`}
        valid={isURLValid}
        label={
          <span>
            Copy and paste the{" "}
            <a
              href={`${searchUrl}${encodeUrlParameter(customer.companyName)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {siteName} URL
            </a>{" "}
            for {customer.companyName} below.
          </span>
        }
        onChange={evt => {
          setUrl(evt.target.value);
        }}
      />
      {displayInstructions && (
        <div className="mb-4">
          <p>Please try again or contact support if the problem continues.</p>{" "}
          <div className="d-flex align-items-center justify-content-center">
            <i className="fa fa-envelope me-2" />
            <a
              href={`mailto:${currentAgency.supportEmail}?subject=Edmunds%20Linking%20Support&body=${encode(
                emailBody
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {currentAgency.supportEmail}
            </a>
          </div>
        </div>
      )}
      {!isEmpty(siteInfo.data?.edmundsInfoes) && (
        <>
          <hr />
          <h4 className="mb-2">Linked Edmunds Locations</h4>
        </>
      )}
      {siteInfo.data?.edmundsInfoes?.map(siteInfo => (
        <Block key={siteInfo.id}>
          <WWAccordionSection
            caption={
              <div className="d-flex align-items-center">
                <DeleteButton
                  type="button"
                  size="sm"
                  tooltip="Remove Site"
                  squareButton
                  iconClass="fa fa-chain-broken"
                  confirmationPrompt="Widewail will no longer import data from this location. Existing reviews will be marked read-only."
                  onClick={event => onUnlink(event, siteInfo)}
                >
                  {null}
                </DeleteButton>
                <div className=" ms-3 flex-shrink-1 d-flex align-items-center flex-wrap">
                  <span className="pe-2 flex-shrink-1 fs-3 truncate">{siteInfo.dealerName}</span>
                </div>
              </div>
            }
          >
            {!isEmpty(siteInfo) && (
              <div>
                <FormField label="ID">{siteInfo.dealerId}</FormField>
                <FormField className="text-capitalize" label="Location">
                  {siteInfo.dealerCity}, {siteInfo.dealerState}
                </FormField>
              </div>
            )}
          </WWAccordionSection>
        </Block>
      ))}
    </>
  );
};

export default EdmundsLinking;
