import { memo } from "react";
import moment from "moment";
import qs from "qs";
import { Badge } from "reactstrap";
import StarRating from "components/Reviews/StarRating/StarRating";
import { Card, CardHeader, CardBody } from "components/Card";
import { Link } from "react-router-dom";

import styles from "./ReviewMiniCard.module.scss";

const {
  miniCardClass,
  cardBodyClass,
  starRatingClass,
  badgeContainerClass,
  headingClass,
  contentClass,
  footerClass,
  infoClass,
  infoItemClass,
  overdueClass,
  linkClass,
  cardContainerClass
} = styles;
const BRIEF_LENGTH = 250;

const overdueApproval = replies => {
  const approvalReply = replies?.find(({ status }) => status === "APPROVAL");
  if (approvalReply) {
    const { lastModifiedOn } = approvalReply;
    const overdue = lastModifiedOn && moment().diff(lastModifiedOn, "days") + 1;
    return `${overdue} Day${overdue > 1 ? "s" : ""} Overdue`;
  }
  return "";
};

const LinkToReview = ({ children, status, review: { author, customer } }) => (
  <Link
    className={linkClass}
    to={{
      pathname: "reviews/feed",
      search: qs.stringify({
        status,
        location: customer,
        author
      })
    }}
  >
    {children}
  </Link>
);

export const ReviewMiniCard = ({ review, showApproval }) => {
  const {
    rating,
    reviewDate,
    content,
    author,
    type,
    replySummary,
    customer: { companyName }
  } = review;
  const isExpandable = content && content.length > BRIEF_LENGTH;
  const linkToReviewStatus = showApproval ? "APPROVAL" : "CUSTOMER_UNREPLIED";
  return (
    <div className={cardContainerClass}>
      <Card className={miniCardClass}>
        <CardHeader className={headingClass}>
          {type === "REVIEW" ? (
            <StarRating className={starRatingClass} rating={rating} showNumber={false} />
          ) : (
            <div className={badgeContainerClass}>
              <Badge color="dark">{type}</Badge>
            </div>
          )}
          {new Date(reviewDate).toLocaleDateString()}
        </CardHeader>
        <CardBody className={cardBodyClass}>
          <div className={contentClass}>
            {isExpandable ? (
              <>
                {content.slice(0, BRIEF_LENGTH)}
                ...
                <LinkToReview review={review} status={linkToReviewStatus}>
                  Read more
                </LinkToReview>
              </>
            ) : (
              content
            )}
          </div>
          <div className={infoClass}>
            <div className={infoItemClass}>
              <i className="fa fa-user" />
              {author}
            </div>
            <div className={infoItemClass}>
              <i className="fa fa-building" />
              {companyName}
            </div>
          </div>
        </CardBody>
      </Card>
      <div className={footerClass}>
        {showApproval ? <span className={overdueClass}>{overdueApproval(replySummary?.replies)}</span> : <span />}
        <LinkToReview review={review} status={linkToReviewStatus}>
          {showApproval ? `View ${type?.toLowerCase()}` : "Reply now"}
        </LinkToReview>
      </div>
    </div>
  );
};

export default memo(ReviewMiniCard);
