import React from "react";

export default function EngagePlus() {
  return (
    <p className="text-center lead">
      Manage customer interactions on Facebook Posts, Ads and Google Questions.
      <br />
      Contact your Widewail Representative today to sign up for Engage Plus!
      <br />
      (844) 259-9238
      <br />
      <a href="mailto:info@widewail.com">info@widewail.com</a>
    </p>
  );
}
