import WWButton from "components/Buttons/WWButton";
import isUrl from "is-url";
import { isEqual, noop } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Tooltip,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap";
import { EditableList, useDragStart } from "../../../components/EditableList/EditableList";
import { bySource } from "../../../data/sites";
import { arrayOfOneOrLess } from "../../../util/arrayUtils";
import { CustomSiteEntry } from "../InviteConfiguration";
import { PrimarySiteButton, SecondarySiteButton } from "../ReviewSiteButton";
import styles from "./InvitePageSiteOrder.module.scss";
import { getCustomSiteError } from "../../../util/validators";

const { dragButton } = styles;

const initialNewSitePayload = { name: "", url: "" };

const InvitePageSiteOrder = ({ data, config, setConfig }) => {
  const [customs, setCustoms] = useState(data.configuration?.customSites || []);
  const [options, setOptions] = useState(data.sites || []);
  const [primaries, setPrimaries] = useState(data?.configuration?.primarySiteOrder || []);
  const [secondaries, setSecondaries] = useState(data?.configuration?.secondarySiteOrder || []);
  const sites = useMemo(() => data?.sites || [], [data]);

  useEffect(() => {
    setCustoms(data.configuration?.customSites || []);
    setOptions(data.sites || []);
    setPrimaries(data?.configuration?.primarySiteOrder || []);
    setSecondaries(data?.configuration?.secondarySiteOrder || []);
  }, [data]);

  const binder = useDragStart();

  useEffect(() => {
    if (!isEqual(primaries, config?.primarySiteOrder)) {
      setConfig("primarySiteOrder")(primaries);
    }
  }, [primaries, config, data]);

  useEffect(() => {
    if (!isEqual(secondaries, config?.secondarySiteOrder)) {
      setConfig("secondarySiteOrder")(secondaries);
    }
  }, [secondaries, config, data]);

  useEffect(() => {
    if (!isEqual(customs, config?.customSites) && !(isEqual(customs, []) && config?.customSites === undefined)) {
      setConfig("customSites")(customs);
    }
  }, [customs, config, data]);

  useEffect(
    () =>
      setOptions(prev => {
        const newopts = [...sites.filter(v => !v.custom), ...customs];
        return isEqual(prev, newopts) ? prev : newopts;
      }),
    [customs, sites]
  );

  const [primariesOptions, setPrimariesOptions] = useState([]);
  useEffect(
    () =>
      setPrimariesOptions(prev => {
        const newopts = options.filter(({ name }) => !primaries.includes(name));
        return isEqual(prev, newopts) ? prev : newopts;
      }),
    [primaries, options]
  );

  const [secondariesOptions, setSecondariesOptions] = useState([]);
  useEffect(
    () =>
      setSecondariesOptions(prev => {
        const newopts = options.filter(
          ({ name }) => !secondaries.includes(name) && !(primaries.length === 1 && name === primaries[0])
        );
        return isEqual(prev, newopts) ? prev : newopts;
      }),
    [primaries, options, secondaries, sites]
  );
  const [newSite, setNewSite] = useState(initialNewSitePayload);
  const onNewItemChange = useCallback(patch => setNewSite(prev => ({ ...prev, ...patch })), []);
  const onNewItemAdd = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setNewSite(initialNewSitePayload);
      setCustoms(prev => [...prev, newSite]);
    },
    [newSite]
  );

  const validationError = useMemo(() => getCustomSiteError(options, newSite.url, newSite.name), [options, newSite]);

  const [tooltipActive, setTooltipActive] = useState(undefined);
  const activateTooltipOn = useCallback(
    i => active => (active ? setTooltipActive(i) : setTooltipActive(undefined)),
    []
  );

  const onExistingItemRemove = useCallback(
    item => () => {
      setCustoms(data => data.filter(v => v !== item));
      setPrimaries(data => data.filter(v => v !== item.name));
      setSecondaries(data => data.filter(v => v !== item.name));
    },
    []
  );

  const onExistingItemUpdate = useCallback(
    current => update => {
      setCustoms(data => data.map(v => (v === current ? update : v)));
      setPrimaries(data => data.map(v => (v === current.name ? update.name : v)));
      setSecondaries(data => data.map(v => (v === current.name ? update.name : v)));
    },
    []
  );
  const isSiteButtonDisabled = useCallback(
    item => sites?.find(site => site.name === item.toUpperCase())?.state === "DISABLED",
    [sites]
  );
  return (
    <>
      <div className="d-flex flex-column align-items-center pt-4">
        <div className="d-flex flex-column mx-auto align-items-stretch">
          <EditableList data={primaries} onChange={newPrimaries => setPrimaries(newPrimaries)}>
            {(onDragStart, item, _, list) => (
              <div className="d-flex align-items-stretch flex-row w-100">
                <WWButton
                  color="warning"
                  iconClass="fa fa-bars"
                  className={
                    "flex-grow me-0 border-0 bg-transparent" +
                    (arrayOfOneOrLess(list) ? " invisible " : " ") +
                    dragButton
                  }
                  disabled={arrayOfOneOrLess(list)}
                  {...binder(list.length > 1 ? onDragStart : noop)}
                />
                <PrimarySiteButton site={item.toUpperCase()} disabled={isSiteButtonDisabled(item)} />
                <WWButton
                  iconClass="fa fa-trash-o"
                  className={"flex-grow ms-0 border-0 bg-transparent" + (arrayOfOneOrLess(list) ? " invisible" : "")}
                  color="warning"
                  disabled={arrayOfOneOrLess(list)}
                  onClick={() => setPrimaries(prev => prev.filter(prim => prim !== item))}
                />
              </div>
            )}
          </EditableList>
          <UncontrolledDropdown className="w-100 mb-3">
            <DropdownToggle disabled={primariesOptions.length === 0} className="w-100 my-1" color="light">
              + Add Site
            </DropdownToggle>
            <DropdownMenu>
              {primariesOptions.map(v => (
                <DropdownItem
                  key={v.name}
                  onClick={() => {
                    setPrimaries(prev => [...prev, v.name]);
                    setSecondaries(prev => prev.filter(item => item !== v.name));
                  }}
                >
                  {bySource(v.name).label || v.name}

                  <span>
                    <DisabledSiteWarningIconWithTooltip site={v.name} disabled={isSiteButtonDisabled(v.name)} />
                  </span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          {secondaries.length > 0 ? (
            <>
              <p className="text-center font-italic fw-bold text-muted">other options</p>
              <EditableList data={secondaries} onChange={updated => setSecondaries(updated)}>
                {(onDragStart, item, _, list) => (
                  <div className="d-flex align-items-center flex-row w-100">
                    <WWButton
                      color="warning"
                      iconClass="fa fa-bars"
                      className={
                        "flex-grow me-0 border-0 bg-transparent d-flex" +
                        (arrayOfOneOrLess(list) ? " invisible " : " ") +
                        dragButton
                      }
                      disabled={arrayOfOneOrLess(list)}
                      {...binder(list.length > 1 ? onDragStart : noop)}
                    />
                    <div className="w-100 my-auto">
                      <SecondarySiteButton site={item.toUpperCase()} disabled={isSiteButtonDisabled(item)} />
                    </div>
                    <WWButton
                      iconClass="fa fa-trash-o"
                      className="flex-grow ms-0 border-0 bg-transparent"
                      color="warning"
                      onClick={() => setSecondaries(prev => prev.filter(prim => prim !== item))}
                    />
                  </div>
                )}
              </EditableList>
            </>
          ) : (
            <p className="text-muted d-flex justify-content-center">No secondary links</p>
          )}
          <UncontrolledDropdown className="w-100">
            <DropdownToggle
              disabled={secondariesOptions.length === 0 || (arrayOfOneOrLess(primaries) && arrayOfOneOrLess(options))}
              className="w-100 my-1"
              color="light"
            >
              + Add Site
            </DropdownToggle>
            <DropdownMenu>
              {secondariesOptions.map(v => (
                <DropdownItem
                  key={v.name}
                  onClick={() => {
                    setSecondaries(prev => [...prev, v.name]);
                    setPrimaries(prev => prev.filter(item => item !== v.name));
                  }}
                >
                  {bySource(v.name).label || v.name}
                  <span>
                    <DisabledSiteWarningIconWithTooltip site={v.name} disabled={isSiteButtonDisabled(v.name)} />
                  </span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <div className="mt-5">
        <h4 id="delivery-rules">Custom Sites</h4>
        {customs?.length > 0 ? (
          customs.map((v, i) => (
            <div className="mb-2 d-flex flex-row flex-nowrap" key={i}>
              <CustomSiteEntry data={v} onChange={onExistingItemUpdate(v)} />
              <Tooltip
                target={`removeExistingItem-${i}`}
                toggle={primaries.length === 1 && primaries[0] === v.name ? activateTooltipOn(i) : noop()}
                isOpen={i === tooltipActive}
                autohide={true}
              >
                You can not remove site, that is the last in primaries section. Primaries section must not be empty.
              </Tooltip>
              <div id={`removeExistingItem-${i}`}>
                <WWButton
                  iconClass="fa fa-trash"
                  disabled={primaries.length === 1 && primaries[0] === v.name}
                  className="ms-2"
                  color="warning"
                  onClick={onExistingItemRemove(v)}
                />
              </div>
            </div>
          ))
        ) : (
          <p className="text-muted d-flex justify-content-center">No custom sites defined</p>
        )}
        <Form className="mt-2" onSubmitCapture={onNewItemAdd}>
          <FormGroup>
            <div className="d-flex flex-nowrap align-items-stretch">
              <CustomSiteEntry data={newSite} onChange={onNewItemChange} />
              <WWButton
                iconClass="fa fa-plus"
                type="submit"
                className="ms-2"
                color="primary"
                disabled={!!validationError}
              />
            </div>
            <Input type="hidden" invalid={newSite !== initialNewSitePayload && !!validationError} />
            {validationError ? <FormFeedback>{validationError}</FormFeedback> : null}
          </FormGroup>
        </Form>
      </div>
    </>
  );
};

export default InvitePageSiteOrder;

export const DisabledSiteWarningIconWithTooltip = ({ site, disabled }) => {
  const id = site.toLowerCase() + "-disabled-warning";
  if (disabled) {
    return (
      <>
        <i id={id} className="fa fa-exclamation-triangle ms-2 text-warning" />
        <UncontrolledTooltip target={id}>
          This link is disabled and will not show on that Invite landing page.
        </UncontrolledTooltip>
      </>
    );
  } else {
    return null;
  }
};
