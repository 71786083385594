import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const useNavigationPrompt = ({ message, when = true }) => {
  const history = useHistory();
  const unblock = useRef();
  const [initialPathname, setInitialPathname] = useState();

  useEffect(() => {
    setInitialPathname(history.location.pathname);
  }, [history.location]);

  useEffect(() => {
    unblock.current = history.block(({ pathname }) => {
      if (when && pathname !== initialPathname) {
        return window.confirm(message);
      }
      return true;
    });
    // prevents double prompt appearance
    return unblock.current;
  }, [history, initialPathname, when]);
};
