/**
 * Wrap a button element or component so it puts a confirm modal in front of the action.
 */

import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import isFunction from "lodash/isFunction";
import * as ReactIs from "react-is";

/**
 * Wrap a source button with a JSX HOC Component with confirm Modal.
 * @param  {Element|Component} button         Original button component or element to wrap.
 * @param  {Object}            overrideProps  Optional overrides or extra props.
 * @param  {Function}          shouldConfirm Function returning true to display confirmation or false to proceed as normal
 * @return {Component} a wrapped Component
 */
function withConfirmation(
  button,
  overrideProps = {},
  shouldConfirm = () => true,
  prompt = "Are you sure?",
  title = "Confirm Action"
) {
  return class ButtonWithConfirmation extends Component {
    // Helper to set clear display name in browser.
    static getDisplayName(c) {
      return c.displayName || c.name || "Component";
    }

    constructor(props) {
      super(props);

      const noop = () => {};

      // Get props from source button, if provided.
      // Fallbacks to this wrapper attributes, with defaults, so the cascade is:
      // override props > wrapper attrs > wrapped button attrs > defaults

      const { props: { onClick: buttonOnClick, label: buttonLabel, children: buttonChildren, ...buttonProps } = {} } =
        button;

      const { onClick = buttonOnClick || noop, label = buttonLabel, children = buttonChildren || [] } = props;

      const modalHeaderContent = label || "Confirm Action";

      // Override label is prioritized over any prop values.
      this.modalHeaderContent = overrideProps.label ? overrideProps.label : modalHeaderContent;

      // Store onClick action to use in Modal confirm.
      this.onClickConfirm = overrideProps.onClick ? overrideProps.onClick.bind(this) : onClick.bind(this);

      this.displayModal = this.displayModal.bind(this);
      this.choose = this.choose.bind(this);

      this.state = { dialog: overrideProps.showDialog ? overrideProps.showDialog : false };

      this.buttonProps = buttonProps;

      ButtonWithConfirmation.displayName = `withConfirm(${ButtonWithConfirmation.getDisplayName(button)}`;
    }

    displayModal() {
      if (!isFunction(shouldConfirm) || shouldConfirm()) {
        this.setState({ dialog: true });
      } else {
        this.onClickConfirm();
      }
    }

    choose(confirm) {
      this.setState({ dialog: false }, () => confirm && this.onClickConfirm());
    }

    element() {
      // checks if button is a class or functional Component
      // Note this forwards props down to the enclosed element, which can cause an error if an attribute is not recognized.
      // A custom prop on an HTML element, for example.
      if (ReactIs.isValidElementType(button)) {
        const ButtonWithConfirm = button;
        return (
          <ButtonWithConfirm {...this.buttonProps} {...this.props} onClick={this.displayModal}>
            {this.props.children}
          </ButtonWithConfirm>
        );
      } else if (React.isValidElement(button)) {
        // Original button is an immutable element, clone it with our onClick override.
        return React.cloneElement(button, {
          ...this.props,
          onClick: this.displayModal
        });
      }

      // Omit unhandled types.
      return null;
    }

    render() {
      return (
        <>
          {this.element()}
          <Modal key="confirmdialog" fade={false} keyboard={true} isOpen={this.state.dialog}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
              <p className="text-center">{prompt}</p>
              <div className="text-center">
                <Button className="m-1" color="success" onClick={() => this.choose(true)}>
                  Yes
                </Button>
                <Button className="m-1" color="danger" onClick={() => this.choose(false)}>
                  No
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      );
    }
  };
}

export default withConfirmation;

export const ConfirmationButton = ({ shouldConfirm, title, prompt, overrideProps, children, ...props }) => {
  const ButtonWithConfirmation = withConfirmation(Button, overrideProps, shouldConfirm, prompt, title);
  return <ButtonWithConfirmation {...props}>{children}</ButtonWithConfirmation>;
};
