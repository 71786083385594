import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Tooltip, Button } from "reactstrap";
import uniqueId from "lodash/uniqueId";
import { noop, isNil } from "lodash";
import classnames from "classnames";
import RoundBadge from "../Badges/RoundBadge/RoundBadge";

import styles from "./WWButton.module.scss";

const { defaultButtonClass, transparentButtonClass, badgeContentClass, smallRoundBadgeClass } = styles;

/**
 * The default Widewail Button for all things button.
 *
 * @param {{
 *  trackingCategory - the name you give to a group of events, like "Videos" or "CTA buttons".
 *  trackingAction - the type of interaction you want to measure, like "Play button clicks" or "Form button clicks".
 *  trackingLabel - a way to provide additional information about specific website elements to identify a unique event, like a product name, video title, or URL.
 * }} params
 * @returns
 */
export default function WWButton({
  iconClass,
  children = "",
  tooltip = "",
  tooltipPlacement = "right",
  className,
  responsiveBreakpoint = "xs",
  transparent = false,
  onClick = noop,
  disabled,
  disableDuringClick = false,
  trackingCategory,
  trackingAction,
  trackingLabel,
  contentClass = "text-uppercase",
  badgeContent,
  badgeColor = "secondary",
  badgeResponsiveBreakpoint = "md",
  ...props
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [id, setButtonId] = useState(props.id);
  const [processing, setProcessing] = useState(false);

  // any button with multiple children must be given a trackingAction to prevent an object being sent to GTM
  const GtmButtonTitle = useMemo(() => trackingAction || children || tooltip || iconClass, [
    trackingAction,
    children,
    tooltip,
    iconClass
  ]);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    if (!props.id) {
      setButtonId(uniqueId("tooltip-button-"));
    }
  }, [props.id]);

  const clickHandler = useCallback(
    event => {
      onClick(event);
    },
    [onClick]
  );

  const asyncClickHandler = useCallback(
    async event => {
      try {
        setProcessing(true);
        await onClick(event);
      } finally {
        setProcessing(false);
      }
    },
    [onClick]
  );

  const responsiveClass = useMemo(
    () => (responsiveBreakpoint === "xs" ? "d-inline" : `d-none  d-${responsiveBreakpoint}-inline`),
    [responsiveBreakpoint]
  );

  const buttonClassName = classnames(defaultButtonClass, className, {
    "shadow-none": transparent,
    [disabled]: props.disabled,
    [transparentButtonClass]: transparent
  });

  return (
    <Button
      data-button-title={GtmButtonTitle}
      className={buttonClassName}
      id={id}
      onClick={disableDuringClick ? asyncClickHandler : clickHandler}
      disabled={disabled || processing}
      {...props}
    >
      <div className="text-truncate">
        {iconClass && <i data-button-title={GtmButtonTitle} className={`${iconClass}`} aria-hidden="true" />}
        {children && (
          <div
            data-button-title={GtmButtonTitle}
            className={classnames(responsiveClass, contentClass, {
              "ms-1": !!iconClass
            })}
          >
            {children}
          </div>
        )}
      </div>
      {!isNil(badgeContent) && (
        <RoundBadge
          color={badgeColor}
          className={classnames(badgeContentClass, {
            [smallRoundBadgeClass]: props.size === "sm",
            [`d-${badgeResponsiveBreakpoint}-none`]: !isNil(badgeResponsiveBreakpoint)
          })}
        >
          {badgeContent}
        </RoundBadge>
      )}
      {tooltip !== "" && id !== undefined ? (
        <Tooltip placement={tooltipPlacement} isOpen={tooltipOpen} target={id} toggle={toggle}>
          {tooltip}
        </Tooltip>
      ) : null}
    </Button>
  );
}
