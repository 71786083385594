import memoize from "memoizee";
import { groupBy } from "lodash";

export const REPORT_REASONS = [
  { value: "DUPLICATE_REVIEW", label: "A duplicate of another review" },
  { value: "INTELLECTUAL_PROPERTY_VIOLATION", label: "An intellectual property violation" },
  { value: "UNAUTHORIZED_SALES", label: "An unauthorized sale" },
  { value: "INCORRECT_DEALER_PAGE", label: "A review for another page" },
  { value: "YELP_WRONG_PAGE", label: "A review for another page" },
  { value: "CONFLICT_OF_INTEREST", label: "Conflict of interest" },
  { value: "FORMER_EMPLOYEE", label: "Conflict of interest" },
  { value: "YELP_POSTED_BY_AFFILIATE", label: "Conflict of interest" },
  { value: "YELP_POSTED_BY_COMPETITOR", label: "Conflict of interest" },
  { value: "DISCRIMINATION_OR_HATE_SPEECH", label: "Discrimination or hate speech" },
  { value: "HATE_SPEECH", label: "Discrimination or hate speech" },
  { value: "HATE_SPEECH_OR_SYMBOLS", label: "Discrimination or hate speech" },
  { value: "YELP_THREATS_OR_HATE_SPEECH", label: "Discrimination or hate speech" },
  { value: "FALSE_INFORMATION", label: "False information" },
  { value: "SCAM_OR_FRAUD", label: "Fraud" },
  { value: "FRAUDULENT_REVIEW", label: "Fraud" },
  { value: "HARASSMENT", label: "Harassment" },
  { value: "BULLYING_OR_HARASSMENT", label: "Harassment" },
  { value: "NOT_HELPFUL", label: "Not helpful" },
  { value: "OFF_TOPIC", label: "Off-topic" },
  { value: "YELP_OFF_TOPIC", label: "Off-topic" },
  { value: "INAPPROPRIATE_CONTENT", label: "Off-topic" },
  { value: "SOMETHING_ELSE", label: "Other" },
  { value: "PERSONAL_INFORMATION", label: "Personal information" },
  { value: "YELP_PRIVACY_STANDARDS", label: "Personal information" },
  { value: "PROFANITY", label: "Profanity" },
  { value: "EATING_DISORDERS", label: "Promoting eating disorders" },
  { value: "SUICIDE_OR_SELF_INJURY", label: "Promoting self-harm" },
  { value: "VIOLENCE", label: "Promoting violence or dangerous organizations" },
  { value: "VIOLENCE_OR_DANGEROUS_ORGANIZATIONS", label: "Promoting violence or dangerous organizations" },
  { value: "YELP_PROMOTION", label: "Promotional material" },
  { value: "SALE_OF_ILLEGAL_OR_REGULATED_GOODS", label: "Sale of illegal or regulated goods" },
  { value: "NUDITY", label: "Sexual content" },
  { value: "NUDITY_OR_SEXUAL_ACTIVITY", label: "Sexual content" },
  { value: "YELP_SEXUAL_CONTENT", label: "Sexual content" },
  { value: "SPAM", label: "Spam" },
  { value: "IT_IS_SPAM", label: "Spam" },
  { value: "TERRORISM", label: "Terrorism" },
  { value: "YELP_EXTRAORDINARY_CIRCUMSTANCE", label: "Unusual or extraordinary circumstances" }
];

const GROUPED_REPORT_REASONS = groupBy(REPORT_REASONS, "label");

export const GROUPED_REPORT_REASONS_FIRST_ITEMS = Object.keys(GROUPED_REPORT_REASONS).map(
  key => GROUPED_REPORT_REASONS[key][0]
);

export const REPLY_REPORT_AS_OPTIONS = [
  { label: "Spam", value: "SPAM" },
  { label: "Clear", value: null }
];

const getReportAsOptionLabel = memoize(
  (value, optionsList) => optionsList.find(option => option.value === value)?.label || ""
);

export const getReplyReportAsOptionLabel = value => getReportAsOptionLabel(value, REPLY_REPORT_AS_OPTIONS);

export const getReviewReportAsOptionLabel = value => getReportAsOptionLabel(value, REPORT_REASONS);

export const getReportedAsLabel = (review, value) => (review.canReportAs || {})[value || review.reportedAs];
