import FormField from "../../../components/Form/FormField";
import { Button } from "reactstrap";
import InputField from "../../../components/Form/InputField";
import { errorCaughtNotifier, useLocalNotifications } from "../../../components/Notifications/notification";
import { updateDealerbuiltClosedDealStatus, updateDealerbuiltCloseRepairStatus } from "../../../api/customerApi";
import HorizontalSelectField from "../../../components/Form/HorizontalSelectField";
import React, { useCallback, useEffect, useState } from "react";
import CopyButton from "../../../components/Buttons/CopyButton";
import { property } from "lodash";

export default class Dealerbuilt {
  field() {
    return "dealerbuiltInfo";
  }

  add() {
    const AddDealerbuilt = ({ customer, onSave }) => {
      const [storeId, setStoreId] = useState();
      const [serviceLocationId, setServiceLocationId] = useState();
      const [closedDealStatus, setClosedDealStatus] = useState("Accounting");
      const [closedRepairStatus, setClosedRepairStatus] = useState("Closed");

      return (
        <>
          <InputField
            type="text"
            label="Store ID"
            placeholder="Store ID"
            onChange={e => setStoreId(e.target.value)}
            value={storeId}
          />
          <InputField
            type="text"
            label="Service Location ID"
            placeholder="Service Location ID"
            onChange={e => setServiceLocationId(e.target.value)}
            value={serviceLocationId}
          />
          <HorizontalSelectField
            name="closed_deal_status"
            value={closedDealStatus}
            options={closedDealStatusUpdateValuesSet}
            label="Closed Deal Status"
            isMulti={false}
            simpleValue={true}
            onChange={e => setClosedDealStatus(e.target.value)}
            placeholder="Accounting"
          />
          <HorizontalSelectField
            name="closed_repair_status"
            value={closedRepairStatus}
            options={closedRepairStatusUpdateValuesSet}
            label="Closed Repair Status"
            isMulti={false}
            simpleValue={true}
            onChange={e => setClosedRepairStatus(e.target.value)}
            placeholder="Closed"
          />
          <Button
            color="primary"
            onClick={() =>
              onSave({
                storeId,
                serviceLocationId,
                closedDealStatus,
                closedRepairStatus,
                source: "DEALERBUILT"
              })
            }
          >
            Link
          </Button>
        </>
      );
    };

    return AddDealerbuilt;
  }

  edit() {
    const EditDealerbuilt = ({ info, customer }) => {
      const [closedDealStatus, setClosedDealStatus] = useState(info.closedDealStatus);
      useEffect(() => {
        setClosedDealStatus(info.closedDealStatus);
      }, [info.closedDealStatus]);
      const [closedRepairStatus, setClosedRepairStatus] = useState(info.closedRepairStatus);
      useEffect(() => {
        setClosedRepairStatus(info.closedRepairStatus);
      }, [info.closedRepairStatus]);
      const notify = useLocalNotifications();
      const onClosedDealStatusChanged = useCallback(
        ({ target }) =>
          updateDealerbuiltClosedDealStatus(customer.id, info.id, target.value)
            .then(property("closedDealStatus"))
            .then(setClosedDealStatus)
            .catch(errorCaughtNotifier(notify)),
        [info, customer]
      );
      const onClosedRepairStatusChanged = useCallback(
        ({ target }) =>
          updateDealerbuiltCloseRepairStatus(customer.id, info.id, target.value)
            .then(property("closedRepairStatus"))
            .then(setClosedRepairStatus)
            .catch(errorCaughtNotifier(notify)),
        [info, customer]
      );

      return (
        <>
          <FormField label="Store ID">
            <div className="text-nowrap">
              {info.storeId} <CopyButton className="ms-1" text={info.storeId} />
            </div>
          </FormField>
          <FormField label="Service Location ID">
            <div className="text-nowrap">
              {info.serviceLocationId} <CopyButton className="ms-1" text={info.serviceLocationId} />
            </div>
          </FormField>
          <HorizontalSelectField
            name="closed_deal_status"
            value={closedDealStatus}
            options={closedDealStatusUpdateValuesSet}
            label="Closed Deal Status"
            isMulti={false}
            simpleValue={true}
            onChange={onClosedDealStatusChanged}
            placeholder="Accounting"
          />
          <HorizontalSelectField
            name="closed_repair_status"
            value={closedRepairStatus}
            options={closedRepairStatusUpdateValuesSet}
            label="Closed Repair Status"
            isMulti={false}
            simpleValue={true}
            onChange={onClosedRepairStatusChanged}
            placeholder="Closed"
          />
        </>
      );
    };
    return EditDealerbuilt;
  }
}

const closedDealStatusUpdateValuesSet = [
  "Open",
  "Closed",
  "Canceled",
  "New",
  "Sold",
  "Approved",
  "Processed",
  "Accounting"
];

const closedRepairStatusUpdateValuesSet = [
  // "None",
  "Open",
  "ReadyForPickup",
  "Closing",
  "Closed",
  "Posted",
  "Cancelled"
];
