import React from "react";
import moment from "moment";
import { ExampleLine } from "../ExampleLine";
import { isNil, get } from "lodash";

export const TrendingChartTooltip = ({
  children = null,
  data,
  format = "ddd, MMM Do",
  valueRenderer = defaultTooltipValueRenderer
}) => (
  <div className="btn btn-light">
    <div className={`d-flex flex-column align-items-start flex-grow-1`}>
      {children && (
        <span className="mb-2">
          <strong>{children}</strong>
        </span>
      )}
      <span className="d-flex flex-nowrap align-items-center">
        <ExampleLine dashed={false} />
        {!isNil(get(data, `stat.value`))
          ? `${moment.unix(get(data, `stat.timestamp`)).format(format)}: ${valueRenderer(get(data, `stat.value`))}`
          : "No Data"}
      </span>
      <span className="d-flex flex-nowrap align-items-center">
        <ExampleLine dashed={true} />
        {!isNil(get(data, `comparison.value`))
          ? `${moment.unix(get(data, `comparison.timestamp`)).format(format)}: ${valueRenderer(
              get(data, `comparison.value`)
            )}`
          : "No Data"}
      </span>
    </div>
  </div>
);

export const defaultTooltipValueRenderer = v => (v === null ? "n/a" : v);
