import React, { useMemo } from "react";
import { groupBy } from "lodash";
import ToggleSwitch from "components/Form/Switch";
import TextSkeleton from "components/Skeleton/TextSkeleton/TextSkeleton";
import ListingFieldLabel from "./ListingFieldLabel";

const MoreForm = ({ attributes }) => {
  const moreOptions = useMemo(() => {
    const groupedAttributes = groupBy(
      attributes?.filter(({ valueType }) => valueType === "BOOL"),
      "groupDisplayName"
    );
    return Object.keys(groupedAttributes).map(key => ({
      header: key,
      options: groupedAttributes[key]
    }));
  }, [attributes]);

  if (!attributes) {
    return <TextSkeleton rows={16} />;
  }

  if (moreOptions.length === 0) {
    return <div className="mb-5 text-center text-muted">No options for selected categories</div>;
  }

  return moreOptions.map(({ header, options }, index) => (
    <div key={header}>
      <h5 className="mb-1">{header}</h5>
      {index === 0 && (
        <ListingFieldLabel>
          Let customers know more about your location by showing attributes on your Business Page. They may appear
          publicly on Search, Maps, and other Google services.
        </ListingFieldLabel>
      )}
      {options.map(({ displayName, parent }) => (
        <ToggleSwitch
          key={parent}
          label={displayName}
          inputWidth={6}
          className="mt-3 mb-4 me-5 pe-5"
          name={`more[${parent}]`}
          onLabel="Yes"
          offLabel="No"
        />
      ))}
    </div>
  ));
};

export default React.memo(MoreForm);
