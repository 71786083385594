import WWButton from "components/Buttons/WWButton";
import BulkUpdatesModal from "components/BulkUpdates/BulkUpdatesModal";
import BottomBanner from "components/Modals/BottomBanner/BottomBanner";
import { TableCard, TableCardHeader } from "components/Table/WWTable";
import { keys, omit } from "lodash";
import { prop, toPairs } from "ramda";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import * as reportApi from "api/reportApi";
import { WidewailAdminFragment } from "components/Auth/AuthorizedComponents";
import withAuthorization, { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import { CardBody, CardHeaderAction, CardHeaderActions, CardTitle } from "components/Card";
import DatePicker from "components/DateTime/DatePicker";
import CustomerTable from "./CustomerTable";
import { loadAgency } from "api/agencyApi";
import { productsSet } from "data/products";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import useFilterQuery from "hooks/data/useFilterQuery";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import { CUSTOMER_BULK_FIELD_OPTIONS, CUSTOMER_STATUSES_LABEL_PAIRS } from "data/options";
import { useFilterState } from "hooks/filteringHooks";
import { updateBulkCustomers } from "api/customerApi";
import useBulkUpdates from "hooks/bulkUpdatesHooks";
import { showSkeleton } from "util/showSkeleton";
import { getSiteIntegrationsFilterConfig } from "components/FacetedSearch/FilterRecipes";
import { isCurrentUserInGroup } from "util/userUtils";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

const filterValueConverter = filters => {
  const normalized = [
    ...toPairs({
      ...omit(filters, "location", "page", "account")
    }),
    ["id", filters?.location?.id],
    ["accountId", filters?.account?.id],
    ["matchAllProducts", true]
  ].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  return normalized;
};

const customerListPrepareFilters = (filters, projection) => {
  const normalized = [
    ...toPairs({
      ...omit(filters, ["location", "account"])
    }),
    ["id", filters?.location?.id],
    ["accountId", filters?.account?.id],
    ["matchAllProducts", true],
    ["projection", projection]
  ].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  return normalized;
};

const exportCsv = (start, end, filter) =>
  reportApi
    .exportCustomers(filterValueConverter(filter), start?.toISOString(true), end?.toISOString(true))
    .then(res => {
      window.open(res.data, "_blank");
    });

const exportBillingCsv = (start, end, filter) =>
  reportApi
    .exportBillingCustomers(filterValueConverter(filter), start?.toISOString(true), end?.toISOString(true))
    .then(res => {
      window.open(res.data, "_blank");
    });

const SORT_OPTIONS = ["companyName,asc", "companyName,desc"];

const getFilters = () => [
  getAccountFilterConfig(),
  {
    name: "location",
    label: "Locations",
    type: "customer",
    primaryBar: {
      position: "LEFT"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "brands",
    label: "Brands",
    type: "text",
    icon: "fa fa-tag",
    primaryBar: {
      position: "LEFT",
      className: "d-none d-md-flex"
    },
    secondaryBar: {
      className: "d-flex d-md-none"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "locationLabel",
    label: "Location Labels",
    type: "locationLabel"
  },
  {
    name: "fa",
    label: "Address",
    type: "text",
    icon: "fa fa-map-marker",
    primaryBar: {
      position: "LEFT",
      className: "d-none d-md-flex"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "status",
    label: "Status",
    type: "buttonDropdown",
    options: CUSTOMER_STATUSES_LABEL_PAIRS,
    placeholder: "status",
    primaryBar: {
      position: "RIGHT",
      className: "d-none d-md-flex"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "signatory",
    label: "Signatories",
    type: "buttonDropdown",
    options: [
      { label: "no signatories", value: "NONE" },
      { label: "has signatories", value: "ANY" }
    ],
    placeholder: "select an option",
    primaryBar: {
      position: "NONE"
    }
  },
  {
    name: "product",
    label: "Products",
    type: "products",
    primaryBar: {
      position: "NONE"
    }
  },
  {
    name: "page",
    defaultValue: 0,
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "sort",
    defaultValue: SORT_OPTIONS[0],
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  },
  getSiteIntegrationsFilterConfig()
];

const CustomerList = () => {
  const [exportStart, setExportStart] = useState(null);
  const [exportEnd, setExportEnd] = useState(null);
  const [filterValues] = useFilterState();
  const [products, setProducts] = useState([]);
  const currentAgency = useSelector(prop("currentAgency"));
  const history = useHistory();
  const filters = getFilters();

  useEffect(() => {
    loadAgency(currentAgency.id).then(res =>
      setProducts(productsSet.filter(product => keys(res.data.defaultProducts).includes(product.product)))
    );
  }, [currentAgency]);

  const bulkEditOptions = useMemo(
    () => CUSTOMER_BULK_FIELD_OPTIONS.filter(option => !option.permissions || isCurrentUserInGroup(option.permissions)),
    []
  );

  const { data, isLoading, isFetched } = useFilterQuery({
    filters,
    url: "/customers",
    projection: "customerList",
    prepareFilters: customerListPrepareFilters
  });

  //* CSV export
  const onTimerangeUpdate = useCallback(
    (start, end) => {
      if (start && end) {
        setExportStart(start);
        setExportEnd(end);
      }
    },
    [setExportStart, setExportEnd]
  );

  const clearDates = useCallback(() => {
    setExportStart(null);
    setExportEnd(null);
  }, [setExportStart, setExportEnd]);
  const onExportClick = useCallback(
    () => exportCsv(exportStart, exportEnd, filterValues),
    [exportStart, exportEnd, filterValues]
  );
  const onBillingExportClick = useCallback(
    () => exportBillingCsv(exportStart, exportEnd, filterValues),
    [exportStart, exportEnd, filterValues]
  );
  //* CSV export end

  const { selectedIds, setSelectedIds, useBulkFilters, onIdToggle, onAllIdToggle, openBulkModal } = useBulkUpdates();

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <FacetedSearch filters={filters} />
      <Row>
        <Col>
          <TableCard>
            <TableCardHeader>
              <CardTitle>Locations</CardTitle>
              <CardHeaderActions>
                <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                  <UncontrolledDropdown>
                    <DropdownToggle tag="div">
                      <CardHeaderAction>Export CSV</CardHeaderAction>
                    </DropdownToggle>
                    <DropdownMenu className="px-3 overflow-visible">
                      <div style={{ minWidth: 375 }} className="d-flex justify-content-around align-items-center">
                        <DatePicker
                          start={exportStart}
                          end={exportEnd}
                          onChange={onTimerangeUpdate}
                          quickSelect={true}
                          numberOfMonths={2}
                        />
                        <WWButton
                          color="warning"
                          iconClass="fa fa-remove"
                          className=" ms-2"
                          type="button"
                          onClick={clearDates}
                        />
                      </div>
                      <div className="mt-2 d-flex justify-content-around">
                        <WWButton iconClass="fa fa-download" color="primary" onClick={onExportClick}>
                          Export
                        </WWButton>
                        <WidewailAdminFragment>
                          <WWButton iconClass="fa fa-download" color="primary" onClick={onBillingExportClick}>
                            Billing Export
                          </WWButton>
                        </WidewailAdminFragment>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </AuthorizationRequiredToRender>
                <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
                  <div className="">
                    <WWButton className="text-nowrap" color="warning" onClick={() => history.push("/customers/new")}>
                      New Location
                    </WWButton>
                  </div>
                </AuthorizationRequiredToRender>
              </CardHeaderActions>
            </TableCardHeader>
            <CardBody>
              <CustomerTable
                customers={data?.data?.customers || []}
                filterable={true}
                onAllLocationToggle={onAllIdToggle(data?.data?.customers?.map(customer => customer.id))}
                onLocationToggle={onIdToggle}
                selectedLocations={selectedIds}
                sortOptions={SORT_OPTIONS}
                skeleton={showSkeleton({ isLoading, isFetched }) ? { cols: 5, rows: 10 } : undefined}
              />
            </CardBody>
          </TableCard>
          <FilterPagination
            isLoading={showSkeleton({ isLoading, isFetched })}
            pageInfo={data?.pageInfo}
            totalPages={data?.pageInfo?.totalPages}
            elementsLabel="Locations"
          />
        </Col>
      </Row>
      <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
        {selectedIds.length > 0 && (
          <BottomBanner>
            <div className="d-flex justify-content-center flex-wrap">
              <WWButton color="primary" onClick={() => openBulkModal(false)}>
                {`BULK UPDATE (${selectedIds.length} LOCATIONS)`}
              </WWButton>
              <WWButton contentClass="fs-3" color="link" onClick={() => openBulkModal(true)}>
                Update all {data?.pageInfo.totalElements} locations that match filters
              </WWButton>
            </div>
            <WWButton contentClass="" className="justify-end" size="sm" color="link" onClick={() => setSelectedIds([])}>
              Clear Selection
            </WWButton>
          </BottomBanner>
        )}
      </AuthorizationRequiredToRender>
      <BulkUpdatesModal
        options={bulkEditOptions}
        bulkUpdateCallback={updateBulkCustomers}
        products={products}
        selectedIds={useBulkFilters ? null : selectedIds}
        filters={useBulkFilters ? filterValueConverter(filterValues) : null}
        numberOfIds={useBulkFilters ? data?.pageInfo.totalElements : selectedIds?.length}
      />
    </div>
  );
};

export default withAuthorization(permissions.LOCATION_MANAGE)(CustomerList);
