import get from "lodash/get";
import map from "lodash/map";
import reduce from "lodash/reduce";
import size from "lodash/size";
import toLower from "lodash/toLower";
import "platform";
import qs from "qs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown
} from "reactstrap";
import * as shareApi from "../../api/shareApi";
import LoadingPage from "../../components/Misc/LoadingPage";
import Review from "../../components/Reviews/Review";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../redux/actions/ajaxStatusActions";

class ShareList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shares: []
    };

    this.refresh = this.refresh.bind(this);
    this.share = this.share.bind(this);
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search.substr(1));
    if (query.contactId) {
      this.setState({ contactId: query.contactId }, () => this.refresh());
    } else {
      this.refresh();
    }
  }

  refresh() {
    this.props.dispatch(beginAjaxCall());
    shareApi
      .listShares(this.state.contactId)
      .then(res => {
        this.setState({ shares: get(res.data, "_embedded.postShareContacts") }, () =>
          this.props.dispatch(endAjaxCall())
        );
      })
      .catch(err => {
        this.props.dispatch(ajaxCallError());
      });
  }

  share(postId, source, url) {
    const { contactId } = this.state;
    return shareApi
      .trackShare(contactId, postId, source)
      .then(res => this.refresh())
      .then(() => {
        if (url) window.open(url, "_blank");
      });
  }

  render() {
    const { shares, contactId } = this.state;
    const { loading } = this.props;
    if (loading) {
      return <LoadingPage />;
    }

    if (size(shares) === 0) {
      return (
        <div className="container">
          <Row>
            <Col className="text-center">
              <p className="h1">Nothing to share.</p>
              <p>
                <Button color="link" onClick={() => this.refresh()}>
                  Check for new posts now!
                </Button>
              </p>
              <p>
                <OptOut contactId={contactId} />
              </p>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div className="container">
        <Row>
          <Col xs={12} md={{ size: 4, offset: 4 }}>
            {map(shares, s => (
              <Row key={s.post.id} className="py-2 mb-2" style={{ backgroundColor: "#fff" }}>
                <Col>
                  <SharePost post={s.post} onShare={this.share} />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p>
              <OptOut contactId={contactId} />
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

// const iOS = () => {
//   const platform = window.platform
//   console.log(`${platform.name} -- ${platform.os.family}`)
//   return platform.name === 'Safari' && get(platform, 'os.family') === 'iOS'
// }

const SharePost = ({ post, onShare }) => {
  const children = reduce(
    post.children,
    (result, value) => {
      (result[value.source] || (result[value.source] = [])).push(value);
      return result;
    },
    {}
  );

  return (
    <Container>
      <Row>
        <Col>
          <Review
            review={post}
            withAge={false}
            actions={post => (
              <>
                {children["FACEBOOK"] && (
                  <ShareButton
                    post={post}
                    children={children["FACEBOOK"]}
                    onShare={c => {
                      window.FB.ui({ method: "share", href: c.thirdpartyUrl });
                      onShare(post.id, "FACEBOOK");
                    }}
                  />
                )}

                {children["INSTAGRAM"] && (
                  <ShareButton
                    post={post}
                    children={children["INSTAGRAM"]}
                    onShare={c => onShare(post.id, "INSTAGRAM", c.thirdpartyUrl)}
                  />
                )}

                <Button color="link" onClick={() => onShare(post.id, "NOOP")}>
                  <i className="fa fa-ban" />
                  {" Hide"}
                </Button>
              </>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};

const buttonTitles = {
  FACEBOOK: "Share on Facebook",
  INSTAGRAM: "Like on Instagram"
};

const ShareButton = ({ post, children, onShare }) => {
  if (size(children) === 1) {
    const c = children[0];
    return (
      <Button color="link" onClick={() => onShare(c)}>
        <i className={`fa fa-${toLower(c.source)}`} />
        {` ${buttonTitles[c.source]}`}
      </Button>
    );
  } else if (size(children) > 1) {
    return (
      <UncontrolledButtonDropdown>
        <DropdownToggle color="link" caret>
          <i className={`fa fa-${toLower(children[0].source)}`} />
          {` ${buttonTitles[children[0].source]}`}
        </DropdownToggle>
        <DropdownMenu>
          {children.map((c, i) => (
            <DropdownItem key={i} onClick={() => onShare(c)}>
              {c.customer.companyName}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }
};

class OptOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unsubscribed: false,
      error: false
    };

    this.optOut = this.optOut.bind(this);
  }

  optOut() {
    const { contactId } = this.props;
    shareApi
      .optOut(contactId)
      .then(res => this.setState({ unsubscribed: true }))
      .catch(err => this.setState({ error: true }));
  }

  render() {
    const { unsubscribed, error } = this.state;
    if (error) {
      return <span>Something went wrong. Please try again later.</span>;
    }
    if (unsubscribed) {
      return <span>You have been unsubscribed.</span>;
    } else {
      return (
        <Button color="link" onClick={this.optOut}>
          Unsubscribe from sharing notifications
        </Button>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    loading: state.ajaxCallsInProgress > 0
  };
}

export default withRouter(connect(mapStateToProps)(ShareList));
