import React, { useState, useRef } from "react";
import { noop, uniqueId } from "lodash";
import { Tooltip } from "reactstrap";
import classnames from "classnames";

import styles from "./WWChip.module.scss";
import WWButton from "components/Buttons/WWButton";

const {
  chip,
  closeIcon,
  content,
  container,
  iconWrapperClass,
  disabledClass,
  successClass,
  alertClass,
  noClickAction,
  wrapContentClass
} = styles;

const Chip = ({
  children = null,
  onClick,
  disabled = false,
  success = false,
  alert = false,
  useCapitalCase = false,
  wrapContent = false,
  iconClass,
  iconTooltip,
  label
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const className = classnames(chip, "ww-font-xs", {
    [disabledClass]: disabled,
    [successClass]: success,
    [alertClass]: alert
  });
  const { current: id } = useRef(uniqueId("i"));

  return (
    <WWButton
      trackingAction="Chip"
      color="light"
      className={classnames(className, { [noClickAction]: !onClick, [wrapContentClass]: wrapContent })}
      contentClass={classnames({ "text-capitalize": useCapitalCase })}
      onClick={onClick || noop}
      disabled={disabled}
    >
      <div className={container}>
        <div className="d-inline-block">
          {!!iconClass
            ? (Array.isArray(iconClass) ? iconClass : [iconClass]).map((icon, index) =>
                !!icon ? (
                  <i
                    className={classnames(iconWrapperClass, icon)}
                    aria-hidden="true"
                    id={index === 0 ? id : undefined}
                  />
                ) : null
              )
            : null}
        </div>
        <div className={content}>
          <span className="text-capitalize text-muted">{label && label + ": "}</span>
          {children}
        </div>
        {disabled || !onClick ? null : (
          <div className={closeIcon}>
            <i className="fa fa-times-circle" />
          </div>
        )}
        {iconTooltip && (
          <Tooltip
            isOpen={tooltipOpen}
            target={id}
            toggle={() => {
              setTooltipOpen(!tooltipOpen);
            }}
          >
            {iconTooltip}
          </Tooltip>
        )}
      </div>
    </WWButton>
  );
};

export default Chip;
