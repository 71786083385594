import isUrl from "is-url";
import memoize from "memoizee";
import { placeholderSynonyms } from "data/editorPlaceholders";

const CORRECT_MOBILE_PHONE_LENGTH = 10;
const MIN_NAME_LENGTH = 2;
const MIN_AVAILABLE_OPENING_YEAR = 1100;
const MAX_AVAILABLE_OPENING_YEAR_DIFF = 5;

export const is = predicate => value => predicate(value);

export const validEmail = value =>
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]$)/i.test(
    value
  );

export const validMobile = value => /^[0-9]{10,10}$/.test(value);

export const validMobileLength = value => value && value.length === CORRECT_MOBILE_PHONE_LENGTH;

export const validName = value => value && value.length > MIN_NAME_LENGTH;

export const validWebsite = value => value && value.toString().match(/^(http|https):\/\/[^.]+\.[^.]/);

export const validPhoneNumber = value =>
  value && value.toString().match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);

const getPlaceholderValues = memoize(
  (placeholders, synonyms = undefined) =>
    placeholders.flatMap(({ value }) => {
      const synonym = synonyms?.[value];
      return synonym === undefined ? value : [value, synonym];
    }),
  { length: 2 }
);

export const findMalformedPlaceholders = (text = "", placeholders = [], synonyms = placeholderSynonyms) => {
  const allTags = text.match(/(?<=\[).*?(?=\])/g) || [];
  const placeholderValues = getPlaceholderValues(placeholders, synonyms);
  const nonExistTags = allTags.filter(tag => !placeholderValues.includes(tag));
  const incorrectSequences =
    text.match(/(?:\\[\\s*\\])|(?:\[[^\]\[]*\[) |(?:\][^\]\[]*\])|(?:^[^\[]*\])|(?:\[[^\]]*$)/g) || [];
  return nonExistTags.map(tag => `[${tag}]`).concat(incorrectSequences);
};

export const getErrorsWithDetails = (errors = [], message = "There could be malformed placeholders in your text.") => ({
  message,
  details: errors.map(v => `• ${v}`).join("\n")
});

export const validateOpeningDate = date => {
  const currentYear = new Date().getFullYear();
  if (!date) {
    return null;
  }
  if (date.year < MIN_AVAILABLE_OPENING_YEAR) {
    return "this year is too far in the past";
  }
  if (date.year > currentYear + MAX_AVAILABLE_OPENING_YEAR_DIFF) {
    return "this year is too far in the future";
  }
  return null;
};

const FORBIDDEN_SITES = [
  { host: "cargurus.com", label: "CarGurus" },
  { host: "carfax.com", label: "CarFax" },
  { host: "yelp.com", label: "Yelp" }
];

export const getCustomSiteError = (options, url, name) => {
  if (!name || !isUrl(url)) {
    return "Please specify both a name and a URL.";
  } else if (options.find(v => v.name === name)) {
    return `A site named ${name} already exists`;
  } else {
    const host = new URL(url).host.toLowerCase();
    const site = FORBIDDEN_SITES.find(s => host.endsWith(s.host));
    if (site != null) {
      return `Asking for reviews is against ${site.label}'s terms of service`;
    } else {
      return "";
    }
  }
};
