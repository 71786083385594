import { objOf, pathOr, pipe, propOr, assoc, prop } from "ramda";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Collapse, Form, Input, InputGroup } from "reactstrap";
import { sendEmailTest, sendSmsTest } from "../../../../../api/emailApi";
import { AuthorizationRequiredToRender } from "../../../../../components/Auth/Authorization";
import { isCurrentUserInGroup } from "../../../../../util/userUtils";
import { permissions } from "../../../../../components/Auth/permissions";
import signature from "../../../../../components/Contacts/ContactSignature";
import CheckboxField from "../../../../../components/Form/CheckboxField";
import FormField from "../../../../../components/Form/FormField";
import HorizontalSelectField from "../../../../../components/Form/HorizontalSelectField";
import InputField from "../../../../../components/Form/InputField";
import PhoneNumberField from "../../../../../components/Form/PhoneNumberField";
import { useLocalNotifications } from "../../../../../components/Notifications/notification";
import { PREFERRED_CONTACT_METHOD } from "../../../../../data/options";
import useContact from "../../../../../hooks/data/useContact";
import StatusBar from "../../../StatusBar/StatusBar";
import useContactUpdater from "../../../../../hooks/data/useContactUpdater";
import useOwnContactUpdater from "../../../../../hooks/data/useOwnContactUpdater";
import usePureEffect from "../../../../../hooks/usePureEffect/usePureEffect";
import Skeleton from "components/Skeleton";
import { useSelector } from "react-redux";
import WWButton from "components/Buttons/WWButton";
import { useStateThroughRedux } from "hooks/stateHooks";
import { ContainerContext } from "components/Modals/NavigableModal/NavigableModalContainer/NavigableModalContainer";
import { ContactTagSelector } from "views/Contacts/EditContact";

const targetsValue = pathOr(undefined, ["target", "value"]);
const notification = {
  body: "Test message sent"
};
const responderConfig = pipe(targetsValue, objOf("isAgencyResponder"));

export default function UserInfo({ id }) {
  const currentContact = useSelector(prop("currentContact"));
  const notify = useLocalNotifications();
  const { data: initialContact } = useContact(currentContact.id);
  const [contact, setContact] = useState(initialContact);
  usePureEffect(setContact, initialContact);

  const context = useContext(ContainerContext);
  const [, setSection] = useStateThroughRedux(`${context.reduxRoot}/section`, undefined);

  const setter = (key, picker) => v => setContact(assoc(key, picker(v)));

  const setName = setter("name", targetsValue);
  const setEmail = setter("email", targetsValue);
  const setPronouns = setter("pronouns", targetsValue);
  const setTitle = setter("title", targetsValue);
  const setPhone = setter("phone", propOr(undefined, "value"));
  const setMobile = setter("mobile", propOr(undefined, "value"));
  const setPreferredContactMethod = setter("preferredContactMethod", targetsValue);
  const setSignatureLine = setter("signatureLine", targetsValue);
  const setTags = setter("tags", targetsValue);
  const setReceiveShares = setter("receiveShares", targetsValue);
  const setIsAgencyResponder = setter("responderConfig", responderConfig);
  const setAudience = setter("audience", targetsValue);
  const setNotes = setter("notes", targetsValue);
  const setApiKey = setter("apiKey", targetsValue);

  const signatureExample = useMemo(() => (!!contact ? signature(contact) : ""), [contact]);

  const contactManager = useMemo(() => isCurrentUserInGroup([permissions.CONTACT_MANAGE]), []);

  const [normalSave] = useOwnContactUpdater();
  const [privilegedSave] = useContactUpdater(id);
  const save = useMemo(() => {
    return contactManager ? privilegedSave : normalSave;
  }, [contactManager, privilegedSave, normalSave]);

  // Status bar integration...
  const [status, setStatus] = useState("clean");
  useEffect(() => {
    if (!!contact && !!initialContact) {
      if (status === "clean" && !isEqual(contact, initialContact)) {
        setStatus("dirty");
      } else if (status === "dirty" && isEqual(contact, initialContact)) {
        setStatus("clean");
      } else if (status === "savingRequested") {
        setStatus("saving");
        save(contact.id, contact)
          .then(() => setStatus("success"))
          .catch(() => setStatus("failure"));
      } else if (status === "cleanRequested") {
        setContact(initialContact);
        setStatus("clean");
      } else if (status === "success") {
        const timeoutid = setTimeout(() => setStatus("clean"), 1000);
        return () => clearTimeout(timeoutid);
      }
    }
  }, [status, initialContact, contact]);
  // ...Status bar integration

  const onSubmit = useCallback(e => {
    e.preventDefault();
    setStatus("savingRequested");
  }, []);
  const sendTestSMS = useCallback(() => sendSmsTest(contact.id).then(() => notify(notification)), [contact, notify]);
  const sendTestEmail = useCallback(() => sendEmailTest(contact.id).then(() => notify(notification)), [
    contact,
    notify
  ]);

  return (
    <>
      {!contact ? (
        <LoadingSkeleton />
      ) : (
        <Form onSubmit={onSubmit} className="p-3">
          <InputField
            label="Name"
            placeholder="First and last names"
            onChange={setName}
            value={contact.name}
            inline={false}
          />
          <InputField
            label="Pronouns"
            onChange={setPronouns}
            value={contact.pronouns}
            placeholder="eg. she/her, he/him, they/them"
            inline={false}
          />
          <InputField
            placeholder="Your title in Widewail"
            label="Title"
            onChange={setTitle}
            value={contact.title}
            inline={false}
          />
          <FormField inline={false} label="Phone">
            <PhoneNumberField
              style={{ width: "100%" }}
              onValueChange={setPhone}
              value={contact.phone}
              placeholder="Your phone number for Widewail"
            />
          </FormField>
          <FormField inline={false} label="Mobile Phone">
            <PhoneNumberField
              style={{ width: "100%" }}
              onValueChange={setMobile}
              value={contact.mobile}
              placeholder="Mobile phone"
            />
            <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
              <Button disabled={!contact.id} size="sm" color="link" className="float-end" onClick={sendTestSMS}>
                Send test message
              </Button>
            </AuthorizationRequiredToRender>
          </FormField>
          <FormField inline={false} name="email" label="Email">
            <InputGroup>
              <Input disabled onChange={setEmail} value={contact.email} />
              <WWButton
                onClick={() => setSection("passwordEmailChange")}
                className="border-0"
                color="primary"
                iconClass="fa fa-edit"
                tooltip="Edit Email"
              />
            </InputGroup>
            <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
              <Button
                disabled={!contact.id || !!contact.emailDisabled}
                size="sm"
                color="link"
                className="float-end"
                onClick={sendTestEmail}
              >
                Send test message
              </Button>
            </AuthorizationRequiredToRender>
          </FormField>
          <HorizontalSelectField
            value={contact.preferredContactMethod}
            label="Preferred Response Contact Method"
            simpleValue={true}
            options={PREFERRED_CONTACT_METHOD}
            onChange={setPreferredContactMethod}
            inline={false}
          />
          <Collapse isOpen={contact.preferredContactMethod !== "CUSTOM"}>
            <FormField inline={false} label="Signature Preview">
              {signatureExample}
            </FormField>
          </Collapse>
          <Collapse isOpen={contact.preferredContactMethod === "CUSTOM"}>
            <InputField
              inline={false}
              value={contact.signatureLine}
              label="Custom Signature"
              placeholder="Your signature"
              onChange={setSignatureLine}
            />
          </Collapse>
          <ContactTagSelector contact={contact} value={contact.tags} onChange={setTags} />
          {contact.activeProducts?.includes("SHARE") ? (
            <CheckboxField
              label="Receive SharePlus Posts"
              onChange={setReceiveShares}
              value={contact.receiveShares}
              inline={false}
            />
          ) : null}
          <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
            <CheckboxField
              label="Agency Responder"
              onChange={setIsAgencyResponder}
              value={contact.responderConfig?.isAgencyResponder}
              inline={false}
            />
          </AuthorizationRequiredToRender>
          <Collapse isOpen={contact.receiveShares && contact.activeProducts?.includes("SHARE")}>
            <HorizontalSelectField
              value={contact.audience}
              label="Share Plus Audience"
              isMulti={true}
              creatable={true}
              simpleValue={true}
              onChange={setAudience}
              inline={false}
            />
          </Collapse>
          <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
            <FormField label="Notes" inline={false}>
              <TextareaAutosize
                className="form-control"
                onChange={setNotes}
                value={contact.notes}
                placeholder="Any additional information here"
              />
            </FormField>
            <InputField
              label="API Key"
              onChange={setApiKey}
              value={contact.apiKey}
              inline={false}
              placeholder="Token value to access API"
            />
          </AuthorizationRequiredToRender>
        </Form>
      )}
      <StatusBar status={status} onStatusChange={setStatus} />
    </>
  );
}

const LoadingSkeleton = ({ amount = 20 }) =>
  new Array(amount).fill().map((_, i) => (
    <div className="d-flex flex-column justify-content-start align-items-start p-3" key={i}>
      <Skeleton count={1} className="w-25 d-block mb-2 flex-grow-0" height={20} />
      <Skeleton count={1} className="d-block mb-5 flex-grow-1" height={40} />
    </div>
  ));
