import { useCallback, useContext } from "react";
import { Button } from "reactstrap";
import { useStateThroughRedux } from "../../../../hooks/stateHooks";
import { ContainerContext } from "../NavigableModalContainer/NavigableModalContainer";
/**
 *
 * @param {{
 *  children: string;
 *  disabled?: boolean;
 *  sub?: boolean;
 *  id: string;
 * }} param0
 * @returns
 */
export default function NavigationItem({ onClick, children, disabled = false, sub = false, id }) {
  const context = useContext(ContainerContext);
  const [section, setSection] = useStateThroughRedux(`${context.reduxRoot}/section`, undefined);
  const [, setCaption] = useStateThroughRedux(`${context.reduxRoot}/caption`, undefined);
  const [, setNavigationOpened] = useStateThroughRedux(`${context.reduxRoot}/navopened`, false);
  const _onClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      setSection(id);
      setCaption(children);
      setNavigationOpened(false);
    }
  }, [id, children]);
  return (
    <Button
      color={section === id ? "primary" : "light"}
      onClick={_onClick}
      className={`${sub ? "mb-1" : "fw-bolder"} rounded-0 d-flex flex-row justify-content-start`}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export function HashNavigationItem({ children, href, disabled = false, sub = false }) {
  return (
    <a
      href={href}
      className={`${sub ? "mb-1" : "fw-bolder"} btn btn-light rounded-0 d-flex flex-row justify-content-start`}
      disabled={disabled}
    >
      {children}
    </a>
  );
}
