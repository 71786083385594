import axios from "axios";
import config from "../config";
import qs from "qs";
import forEach from "lodash/forEach";
import { Util } from "reactstrap";
import * as client from "./apiClient";

const BASE_HEADERS = { Accept: "application/json" };

export function loadReviews(filter = null, page = 0, size = 50, sort = "reviewDate,desc") {
  return axios
    .get(config.api + "/reviews", {
      params: {
        size,
        page,
        sort,
        latest: true,
        ...filter
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      }
    })
    .then(response => {
      //the name of the key under "_embedded" can change based on projection but there will always be only one
      const keys = Object.keys(response.data?._embedded || {});
      if (keys.length > 0) {
        forEach(response.data._embedded[keys[0]], r => {
          r.createdOn = new Date(r.createdOn);
          r.reviewDate = new Date(r.reviewDate);
        });
      }
      return response;
    });
}

export function exportReviewsCSV(filter = null) {
  return axios
    .get(config.api + "/report/reviews", {
      params: {
        partnerId: "widewail",
        ...filter
      }
    })
    .then(({ data }) => data);
}

export function loadReview(reviewId, projection = "details") {
  return axios
    .get(`${config.api}/reviews/${reviewId}`, {
      params: { projection }
    })
    .then(response => {
      if (response.data.reviewDate) response.data.reviewDate = new Date(response.data.reviewDate);
      return response;
    });
}

export function loadPagedReplies(reviewId, page = 0, size = 300) {
  return axios.get(`${config.api}/reviews/${reviewId}/replies`, {
    params: {
      page,
      size
    }
  });
}

export function loadMessages(reviewId) {
  return axios.get(`${config.api}/reviews/${reviewId}/messages`, {
    params: { projection: "messageSummary" }
  });
}

export function saveMessage(reviewId, note, status) {
  let patch = {
    message: note.note
  };

  return axios.post(`${config.api}/reviews/${reviewId}/messages`, patch, {
    params: {
      status
    }
  });
}

export function loadLatestMessages(reviewIds) {
  return axios
    .get(`${config.api}/reviews/messages`, {
      params: { reviewIds }
    })
    .then(({ data }) => data);
}

export function loadSimilar(reviewId, page = 0, size = 5) {
  return axios.get(`${config.api}/reviews/${reviewId}/similar`, {
    params: {
      page,
      size
    }
  });
}

export function loadHistory(reviewId) {
  return axios.get(`${config.api}/reviews/${reviewId}/history`);
}

export function loadReplies(filter = null, page = 0, size = 50) {
  return axios
    .get(config.api + "/replies", {
      params: {
        size: size,
        page: page,
        sort: "createdOn,desc",
        ...filter
      }
    })
    .then(response => {
      response.data._embedded.reviewReplies.forEach(r => (r.createdOn = new Date(r.createdOn)));
      return response;
    });
}

/**
 * @returns {Promise<number>}
 */
export function loadPendingResponses() {
  return axios.get(`${config.api}/dash/approvals`).then(v => v.data?.count || 0);
}

/**
 * @returns {Promise<number>}
 */
export function loadInactiveCustomers() {
  return axios.get(`${config.api}/dash/customers`).then(v => v.data?.inactiveCount || 0);
}

export function saveReview(review) {
  if (review.id) {
    const patch = {
      id: review.id,
      tags: review.tags,
      internalTags: review.internalTags,
      notes: review.notes,
      sentiment: review.sentiment || null,
      responderFlag: review.responderFlag || null,
      content: review.content,
      translatedContent: review.translatedContent,
      reviewEntities: review.reviewEntities
    };
    return axios.patch(config.api + "/reviews/" + review.id, patch, {
      headers: BASE_HEADERS
    });
  } else {
    return axios.post(config.api + "/customers/" + review.customerId + "/reviews", review, {
      headers: BASE_HEADERS
    });
  }
}

export function updateStatus(id, status) {
  return axios.patch(config.api + "/reviews/" + id + "/status", { status: status }, { headers: BASE_HEADERS });
}

export function reportAs(id, reportAs) {
  return axios.patch(config.api + "/reviews/" + id + "/report", { reportAs });
}

/**
 *
 * @returns {import("axios").AxiosPromise<any>}
 */
export function saveReply(reply, publish = false, sendForApproval = false) {
  return reply.id
    ? axios.put(config.api + "/replies/" + reply.id, reply, {
        headers: BASE_HEADERS,
        params: { publish: publish, sendForApproval: sendForApproval }
      })
    : axios.post(config.api + "/replies", reply, {
        headers: BASE_HEADERS,
        params: { publish: publish, sendForApproval: sendForApproval }
      });
}

export function updateReplySentiment(reply) {
  return axios.patch(config.api + "/replies/" + reply.id + "/sentiment", {
    sentiment: reply.sentiment || null
  });
}

export function updateReplyStatus(reply) {
  return axios.patch(config.api + "/replies/" + reply.id, {
    id: reply.id,
    status: reply.status
  });
}

export function updateReply(reply) {
  return axios.put(config.api + "/replies/" + reply.id, Util.omit(reply, "id"));
}

export function deleteReview(id) {
  return axios.delete(config.api + "/reviews/" + id, {
    headers: BASE_HEADERS
  });
}

export function deleteReply(id) {
  return axios.delete(config.api + "/replies/" + id);
}

export function publishReply(id) {
  return axios.post(config.api + "/replies/" + id + "/publish");
}

export function unpublishReply(id) {
  return axios.post(config.api + "/replies/" + id + "/publish", {}, { params: { unpublish: true } });
}

export function loadReplyHistory(replyId) {
  return axios.get(config.api + "/replies/" + replyId + "/history");
}

export function reportReplyAs(replyId, reportAs) {
  return axios.patch(config.api + "/replies/" + replyId + "/report", { reportAs });
}

export function reactReview(id, reaction = "LIKE") {
  return axios.post(config.api + "/reviews/" + id + "/reactions", null, {
    params: { type: reaction }
  });
}

export function unreactReview(id, reaction = "LIKE") {
  return axios.delete(config.api + "/reviews/" + id + "/reactions", null, {
    params: { type: reaction }
  });
}

export function reactReply(id, reaction = "LIKE") {
  return axios.post(config.api + "/replies/" + id + "/reactions", null, {
    params: { type: reaction }
  });
}

export function unreactReply(id, reaction = "LIKE") {
  return axios.delete(config.api + "/replies/" + id + "/reactions", {
    params: { type: reaction }
  });
}

export function loadMatches(id) {
  return axios.get(config.api + "/reviews/" + id + "/interactions/matches");
}

export function linkInteraction(reviewId, interactionId) {
  return axios.put(config.api + "/reviews/" + reviewId + "/interactions/" + interactionId);
}

export function unlinkInteraction(reviewId, interactionId) {
  return axios.delete(config.api + "/reviews/" + reviewId + "/interactions/" + interactionId);
}

export function getEntities(id, type, value, filters) {
  return axios.get(config.api + "/reviews/" + id + "/entities/", {
    params: {
      type,
      value,
      startDate: filters?.startDate,
      endDate: filters?.endDate
    }
  });
}

export function addOrUpdateEntities(id, entities) {
  return axios.put(config.api + "/reviews/" + id + "/entities", entities);
}

export function deleteEntities(id, entities) {
  return axios.delete(config.api + "/reviews/" + id + "/entities", { data: entities });
}

export function updateBulkReviews(reviewIds, filters, updates) {
  return client.patch(["reviews"], { reviewIds, filters, updates });
}
