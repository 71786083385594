import qs from "querystring";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col } from "reactstrap";
import { createKeapInfo } from "../../api/keapApi";
import { Card } from "../../components/Card";
import AjaxLoader from "../../components/Misc/AjaxLoader";
import StatusMessages from "../../components/Misc/StatusMessages";
import { LOCAL_STORAGE_KEYS } from "../../data/storageKeys";

export const KeapOAuthResult = () => {
  const history = useHistory();
  const code = useMemo(() => qs.parse(window.location.search.slice(1)).code, []);
  const scope = useMemo(() => qs.parse(window.location.search.slice(1)).scope, []);
  const awaitedRedirectCustomer = localStorage.getItem(LOCAL_STORAGE_KEYS.AWAITED_REDIRECT_CUSTOMER);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (!!code && !!awaitedRedirectCustomer) {
      createKeapInfo(awaitedRedirectCustomer, code, scope)
        .then(() => window.location.replace(`/customers/${awaitedRedirectCustomer}/siteIntegrations?site=KEAP`))
        .catch(setErrors);
    }
  }, [code, scope, history, awaitedRedirectCustomer]);

  return (
    <Col xs={12} className="d-flex flex-column justify-content-center align-items-center">
      {errors.length > 0 ? (
        <StatusMessages errors={errors} />
      ) : !code ? (
        <Card>
          Something went wrong with Keap authorization. Please try again or contact support if the problem continues.
        </Card>
      ) : !awaitedRedirectCustomer ? (
        <Card>
          Authenticated to an unknown Widewail location. Please try again or contact support if the problem continues.
        </Card>
      ) : (
        <Card>
          <div className="mx-auto mb-3">
            <AjaxLoader />
          </div>
          Please wait while we connect your location to Keap.
        </Card>
      )}
    </Col>
  );
};
