import { map } from "lodash";
import classnames from "classnames";
import { roundToOne } from "util/roundToOne";
import styles from "./StarRating.module.scss";

const starsArray = rating => map(Array(5), (x, i) => roundToOne(rating) - 1 >= i);

const StarRating = ({ className, rating, formattedStarRating, showNumber = true, spaced = false }) => {
  return (
    <div className={classnames("d-flex align-items-center", className)}>
      {showNumber && <span className="font-3xl pe-2">{formattedStarRating || roundToOne(rating)}</span>}
      {map(starsArray(rating), (star, index) => (
        <i
          className={`${styles.star} text-warning fa-2x fa ${star ? "fa-star" : "fa-star-o"} ${
            spaced ? "pe-2" : "pe-1"
          }`}
          key={index}
        />
      ))}
    </div>
  );
};

export default StarRating;
