import React, { useContext, useEffect, useState } from "react";
import InputField from "components/Form/InputField";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import AdminProductSet from "components/Products/AdminProductSet";
import { startsWith, toLower, filter, get, join, includes, uniqBy } from "lodash";
import * as api from "api/commonApi";
import { loadContacts, loadCustomerNames } from "api/customerApi";
import { CONTACT_STATUS, CUSTOMER_STATUSES, REVIEW_STATUS } from "data/options";
import FormField from "components/Form/FormField";
import DeleteButton from "components/Buttons/DeleteButton";
import styles from "../BulkUpdatesModal.module.scss";
import { Card } from "components/Card";
import { Link } from "react-router-dom";
import { BulkUpdateContext } from "../BulkUpdatesModal";
import { LabelMultiSelector, WWLabelUnifiedFieldsWithTypeahead } from "components/Form/LabelSelector";
import ResponderNotesEditor from "components/ResponderNotes/ResponderNotesEdit/ResponderNotesEditor";
import { useCurrentAgency } from "hooks/agencyHooks";
import { useTagSetsByAgency } from "hooks/data/tagSetHooks";
import ListField from "components/Form/ListField";

const onFieldRemove = (field, customerSetter, fieldsSetter) => {
  fieldsSetter(prev => prev.filter(bulkField => bulkField.value !== field));
  customerSetter(customer => ({ ...customer, [field]: field === "addContactIds" ? [] : null }));
};

const onProductUpdate = (field, setter) => event => {
  setter(customer => ({
    ...customer,
    [field]: includes(customer[field], event.target.name)
      ? customer[field].filter(product => product !== event.target.name)
      : [...(customer[field] || []), event.target.name]
  }));
};

const loadBrandOptions = () => {
  return api.fetch("/tags").then(res => {
    return res.data;
  });
};

const TextAreaRender = ({ field, label }) => {
  const { setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext);

  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove(field, setBulkUpdateValues, setBulkFormFields)}
      />
      <InputField className="w-100" name={field} label={label} type="textarea" style={{ height: "100px" }} />
    </div>
  );
};
const statusOptions = type => {
  switch (type) {
    case "location":
      return CUSTOMER_STATUSES;
    case "contact":
      return CONTACT_STATUS;
    case "review":
      return REVIEW_STATUS;
    default:
      return [];
  }
};

const StatusRenderer = ({ field, label }) => {
  const { type, setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext);
  const options = statusOptions(type);
  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove("status", setBulkUpdateValues, setBulkFormFields)}
      />
      <HorizontalSelectField name={field} label={label} formFieldClass="w-100" simpleValue options={options} />
    </div>
  );
};

const HipaaRenderer = ({ field, label }) => {
  const { setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext);

  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove("updateHipaaCompliant", setBulkUpdateValues, setBulkFormFields)}
      />
      <HorizontalSelectField
        name={field}
        label={label}
        formFieldClass="w-100"
        getOptionLabel={option => option.label}
        getOptionValue={option => option.value}
        options={[
          { label: "Add HIPAA Compliance", value: true },
          { label: "Remove HIPAA Compliance", value: false }
        ]}
      />
    </div>
  );
};

const useBulkListFieldsState = field => {
  const [values, setValues] = useState([]);
  const { setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext);

  const onAdd = value => {
    setValues(prev => uniqBy([...prev, value], "id"));
  };

  const onRemove = value => {
    setValues(prev => [...prev].filter(entity => entity.id !== value.id));
  };

  useEffect(() => {
    setBulkUpdateValues(prev => ({
      ...prev,
      [field]: values?.map(entity => entity.id)
    }));
  }, [values]);

  return { values, onAdd, onRemove, setBulkFormFields, setBulkUpdateValues };
};

const ModifyContactsRenderer = ({ field, label }) => {
  const { values, onAdd, onRemove, setBulkFormFields, setBulkUpdateValues } = useBulkListFieldsState(field);
  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove(field, setBulkUpdateValues, setBulkFormFields)}
      />
      <FormField label={label} className="w-100">
        <Card color="light">
          <ListField
            placeholder="Search Contacts..."
            members={values}
            onAdd={onAdd}
            onRemove={onRemove}
            loadOptions={(inputValue, callback) => {
              loadContacts({
                projection: "name",
                q: inputValue,
                type: "WIDEWAIL_USER"
              }).then(res => {
                callback(get(res.data, "_embedded.contacts", []));
              });
            }}
            getOptionLabel={({ name, title }) => join([name, title], " - ")}
            getMemberLabel={({ id, name, title }) => (
              <Link to={`/customers/contacts/${id}`}>{join([name, title], " - ")}</Link>
            )}
            getMemberValue={({ id }) => id}
          />
        </Card>
      </FormField>
    </div>
  );
};

const ModifyCustomersRenderer = ({ field, label }) => {
  const { values, onAdd, onRemove, setBulkFormFields, setBulkUpdateValues } = useBulkListFieldsState(field);

  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove(field, setBulkUpdateValues, setBulkFormFields)}
      />
      <FormField label={label} className="w-100">
        <Card color="light">
          <ListField
            placeholder="Search Customers..."
            members={values}
            onAdd={onAdd}
            onRemove={onRemove}
            cacheOptions={false}
            loadOptions={(inputValue, callback) => {
              loadCustomerNames(inputValue).then(res => {
                let dataCustomers = get(res.data, "_embedded.customers", []);
                callback(dataCustomers);
              });
            }}
            getOptionLabel={({ companyName }) => companyName}
            getMemberLabel={({ id, companyName }) => <Link to={`/customers/${id}`}>{companyName}</Link>}
            getMemberValue={({ id }) => id}
          />
        </Card>
      </FormField>
    </div>
  );
};

const BrandRenderer = ({ field, label }) => {
  const { setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext);
  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove(field, setBulkUpdateValues, setBulkFormFields)}
      />
      <HorizontalSelectField
        formFieldClass="w-100"
        async
        name={field}
        label={label}
        isMulti
        creatable
        simpleValue
        loadOptions={(inputValue, callback) => {
          loadBrandOptions().then(options => {
            callback(filter(options, o => startsWith(toLower(o), toLower(inputValue))));
          });
        }}
      />
    </div>
  );
};

const ProductsRenderer = ({ field, label, products }) => {
  const { setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext);

  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove(field, setBulkUpdateValues, setBulkFormFields)}
      />
      <FormField label={label} className="w-100">
        <Card color="light">
          <AdminProductSet customer={{ products: products }} onUpdate={onProductUpdate(field, setBulkUpdateValues)} />
        </Card>
      </FormField>
    </div>
  );
};

const AddLabelRenderer = ({ field, label }) => {
  const { bulkUpdateValues, setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext),
    { addLocationLabels = [] } = bulkUpdateValues;

  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove(field, setBulkUpdateValues, setBulkFormFields)}
      />
      <FormField label={label} className="w-100">
        <LabelMultiSelector
          split
          allowNew
          labels={addLocationLabels}
          onChange={labels => setBulkUpdateValues(prev => ({ ...prev, [field]: labels }))}
        />
      </FormField>
    </div>
  );
};

const RemoveLabelRenderer = ({ field, label }) => {
  const { bulkUpdateValues, setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext),
    { removeLocationLabels = [] } = bulkUpdateValues;

  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove(field, setBulkUpdateValues, setBulkFormFields)}
      />
      <FormField label={label} className="w-100">
        <WWLabelUnifiedFieldsWithTypeahead
          multiple
          labels={removeLocationLabels}
          onChange={l => setBulkUpdateValues(prev => ({ ...prev, [field]: l }))}
        />
      </FormField>
    </div>
  );
};

const InputFieldRender = ({ field, label, placeholder }) => {
  const { setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext);

  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove(field, setBulkUpdateValues, setBulkFormFields)}
      />
      <InputField className="w-100" name={field} label={label} placeholder={placeholder} />
    </div>
  );
};

const ResponderNotesRenderer = ({ field, label }) => {
  const { setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext);
  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove(field, setBulkUpdateValues, setBulkFormFields)}
      />
      <ResponderNotesEditor onChange={l => setBulkUpdateValues(prev => ({ ...prev, [field]: l }))} />
    </div>
  );
};

const TagSetSelectorRenderer = ({ field, label }) => {
  const { setBulkUpdateValues, setBulkFormFields } = useContext(BulkUpdateContext);
  const agency = useCurrentAgency();
  const tagSetQuery = useTagSetsByAgency(agency.id, {
    enabled: !!agency.id
  });
  const categoryOptions = tagSetQuery.data?.map(tagSet => ({ label: tagSet.name, value: tagSet.id })) || [];

  return (
    <div className={styles.formGroupContainer}>
      <DeleteButton
        squareButton
        className="me-2 mt-1"
        onClick={() => onFieldRemove("updateHipaaCompliant", setBulkUpdateValues, setBulkFormFields)}
      />
      <HorizontalSelectField
        name={field}
        label={label}
        formFieldClass="w-100"
        getOptionLabel={option => option.label}
        getOptionValue={option => option.value}
        options={categoryOptions}
      />
    </div>
  );
};

const FieldsRenderer = ({ field, label, placeholder, products }) => {
  switch (field) {
    case "prependCustomerNotes":
    case "replaceCustomerNotes":
    case "appendCustomerNotes":
      return <TextAreaRender field={field} label={label} />;
    case "addResponderNotes":
      return <ResponderNotesRenderer field={field} label={label} />;
    case "removeResponderNotes":
      return <InputFieldRender field={field} label={label} placeholder={placeholder} />;
    case "status":
      return <StatusRenderer field={field} label={label} />;
    case "addContactIds":
    case "removeContactIds":
      return <ModifyContactsRenderer field={field} label={label} />;
    case "addCustomerIds":
    case "removeCustomerIds":
      return <ModifyCustomersRenderer field={field} label={label} />;
    case "removeBrands":
    case "addBrands":
      return <BrandRenderer field={field} label={label} />;
    case "addProducts":
    case "removeProducts":
      return <ProductsRenderer field={field} label={label} products={products} />;
    case "updateHipaaCompliant":
      return <HipaaRenderer field={field} label={label} />;
    case "addLocationLabels":
      return <AddLabelRenderer field={field} label={label} />;
    case "removeLocationLabels":
      return <RemoveLabelRenderer field={field} label={label} />;
    case "replaceTagSetId":
      return <TagSetSelectorRenderer field={field} label={label} />;
    default:
      return <></>;
  }
};

export default FieldsRenderer;
