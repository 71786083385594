import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions/authStatusActions";
import { Auth } from "aws-amplify";
import InputField from "../Form/InputField";
import { Form } from "reactstrap";
import PasswordStrength from "./PasswordStrength";
import AjaxLoader, { AjaxLoaderSizes } from "../Misc/AjaxLoader";
import WWButton from "../Buttons/WWButton";

const NewPasswordRequired = ({ user, requiredAttributes = [] }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [valid, setValid] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const createNewPassword = useCallback(
    event => {
      event.preventDefault();
      setLoading(true);
      if (requiredAttributes.length > 0) {
        Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword,
          // OPTIONAL, the required attributes
          {
            name: name
          }
        )
          .then(user => {
            dispatch(loginUser(user.signInUserSession, "signedin"));
          })
          .catch(error => {
            setValid(false);
            setErrorMessage(error.message);
            setLoading(false);
          });
      } else {
        Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword
        )
          .then(user => {
            dispatch(loginUser(user.signInUserSession, "signedin"));
          })
          .catch(error => {
            setValid(false);
            setErrorMessage(error.message);
            setLoading(false);
          });
      }
    },
    [newPassword, name, user, requiredAttributes]
  );

  const onNameChange = useCallback(v => setName(v.target.value), [setName]);

  return (
    <div>
      <h4 className="text-dark"> Set new password</h4>
      <Form className="form-horizontal">
        {requiredAttributes.length > 0 && (
          <InputField name="name" label="Full Name" inline={false} value={name} onChange={onNameChange} />
        )}
        <PasswordStrength password={newPassword} error={valid} errorMessage={errorMessage} onChange={setNewPassword} />
        <div className="mt-4">
          <WWButton
            trackingCategory="Auth"
            disabled={loading}
            type="submit"
            className="btn btn-warning col-md-12 py-3 fw-bold"
            onClick={createNewPassword}
          >
            {loading ? <AjaxLoader size={AjaxLoaderSizes.SM} /> : "Set New Password"}
          </WWButton>
        </div>
      </Form>
    </div>
  );
};

export default NewPasswordRequired;
