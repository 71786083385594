import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import endsWith from "lodash/endsWith";

export default function ajaxCallsInProgress(state = initialState.ajaxCallsInProgress, action) {
  if (action.type === types.BEGIN_AJAX_CALL) {
    return state + 1;
  } else if (endsWith(action.type, "_SUCCESS") || action.type === types.AJAX_CALL_ERROR) {
    return state - 1;
  }
  return state;
}
