import { path } from "ramda";
import { createContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import {
  useSharedCredentialsCreate,
  useSharedCredentialsUpdate,
  useSharedCredentialsDelete
} from "hooks/data/credentialsHooks";
import { loadCredentials } from "api/sharedCredentialsApi";
import WWButton from "../../components/Buttons/WWButton";
import { useErrorNotification } from "components/Notifications/notification";

import SharedEmailAndPassword from "./Editors/SharedEmailAndPassword";
import SharedYardiCredentials from "./Editors/SharedYardiCredentials";
import { SITES } from "../../data/sites";
import DeleteButton from "components/Buttons/DeleteButton";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import SharedUberallCredentials from "./Editors/SharedUberallCredentials";
import SharedPropertywareCredentials from "./Editors/SharedPropertywareCredentials";
import SharedEntrataCredentials from "./Editors/SharedEntrataCredential";

const EDITORS = {
  APARTMENTS_DOT_COM: SharedEmailAndPassword,
  UBERALL: SharedUberallCredentials,
  YARDI: SharedYardiCredentials,
  PROPERTYWARE: SharedPropertywareCredentials,
  ENTRATA: SharedEntrataCredentials
};

const defaultEditor = () => "Please select a source!";

export const NEW = "new";

export const isSupported = source => !!EDITORS[source];

const SOURCES = SITES.filter(site => isSupported(site.source))
  .sort((a, b) => a.label.localeCompare(b.label))
  .map(site => ({
    value: site.source,
    label: (
      <>
        <i className={`fa ${site.icon} me-2`} /> {site.label}
      </>
    )
  }));

const SOURCE_TO_LABEL = SITES.map(site => {
  const result = {};
  result[site.source] = site.label;
  return result;
}).reduce((a, b) => ({ ...a, ...b }));

const CredentialsModalContext = createContext();

export const CredentialsEditor = ({ id, defaultSource, close }) => {
  const agencyId = useSelector(path(["currentAgency", "id"]));
  const errorNotification = useErrorNotification();
  const editing = useMemo(() => id && id !== NEW, [id]);

  const [source, setSource] = useState();
  const [update, setUpdate] = useState();
  const [valid, setValid] = useState();
  const [credentials, setCredentials] = useState();
  useEffect(() => {
    setSource(credentials?.source || defaultSource);
  }, [credentials, defaultSource]);
  useEffect(() => {
    if (editing) {
      loadCredentials(id)
        .then(response => response.data)
        .then(setCredentials)
        .catch(errorNotification);
    } else {
      setCredentials(null);
    }
  }, [id, source, editing, errorNotification]);

  const title = useMemo(() => {
    const label = SOURCE_TO_LABEL[source] || "shared";
    if (editing) {
      return <>Editing {label} credentials</>;
    } else {
      return <>Creating new {label} credentials</>;
    }
  }, [source, editing]);

  const sharedCredentialsCreate = useSharedCredentialsCreate();
  const sharedCredentialsUpdate = useSharedCredentialsUpdate();
  const saveAndClose = () =>
    (editing ? sharedCredentialsUpdate : sharedCredentialsCreate)
      .mutateAsync({ id, source, agency: { id: agencyId }, ...update })
      .then(close);

  const sharedCredentialsDelete = useSharedCredentialsDelete();
  const deleteAndClose = () =>
    sharedCredentialsDelete.mutateAsync({ id, source, agency: { id: agencyId }, ...update }).then(close);

  const Editor = useMemo(() => EDITORS[source] || defaultEditor, [source]);

  return (
    <CredentialsModalContext.Provider value={null}>
      <Modal isOpen={!!id} onClosed={close}>
        <ModalHeader className="bg-light" toggle={close}>
          {title}
        </ModalHeader>
        <div className="p-4 pe-md-5 flex-grow-1">
          <HorizontalSelectField
            name="Source"
            label="Credential type..."
            options={SOURCES}
            isMulti={false}
            value={SOURCES.find(s => s.value === source)}
            onChange={event => setSource(event.target.value.value)}
          />
          <Editor credentials={credentials} onChange={setUpdate} onValidChange={setValid} />
        </div>
        <ModalFooter className="bg-light">
          <WWButton disabled={!valid} onClick={saveAndClose}>
            Save &amp; Close
          </WWButton>
          {editing && (
            <DeleteButton
              onClick={deleteAndClose}
              confirmationPrompt="Are you sure you want to delete these shared credentials? (If deleting them would break any existing links, Widewail will detect them and cancel the deletion.)"
              type="button"
            />
          )}
        </ModalFooter>
      </Modal>
    </CredentialsModalContext.Provider>
  );
};
