import axios from "axios";
import config from "../config";

const baseUri = config.api + "/approval";

/**
 * This file should be removed once approval page is fully removed
 */

export function saveNote(reviewId, note, status = "CUSTOMER_RESPONDED") {
  let patch = {
    note: note.note,
    noteAuthor: note.noteAuthor
  };

  return axios.post(baseUri + "/notes/" + reviewId, patch, {
    params: {
      status
    }
  });
}

export function loadReviews(
  customerId,
  contactId,
  status,
  page = 0,
  size = 25,
  sort = "reviewDate",
  direction = "ASC",
  projection = "full"
) {
  return axios.get(baseUri + "/reviews", {
    params: {
      ...(customerId ? { customerId } : {}),
      ...(contactId ? { contactId } : {}),
      ...(status ? { status } : {}),
      sort: `${sort},${direction}`,
      page,
      size,
      projection
    }
  });
}

export function approveReview(reviewId) {
  return axios.post(baseUri + "/approve/" + reviewId);
}

export function approveSingleReply(reviewId, replyId) {
  return axios.post(baseUri + "/approve/" + reviewId + "/reply/" + replyId);
}

export function approveAll(customerId) {
  let params = { all: true };
  if (customerId) {
    params.customerId = customerId;
  }
  return axios.post(
    baseUri + "/approve",
    {},
    {
      params
    }
  );
}

export function ignore(reviewId) {
  return axios.post(baseUri + "/ignore/" + reviewId);
}

export function loadCustomerContacts(customerId) {
  return axios.get(baseUri + "/approvals/" + customerId + "/contacts");
}

//Looks unused
// export function updateContact(contactId, update) {
//   return axios.post(baseUri + "/contacts/" + contactId, update);
// }
