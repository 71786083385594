import { updateEmailAndPassword } from "api/customerApi";
import PasswordInput from "components/Auth/PasswordInput";
import PasswordStrength from "components/Auth/PasswordStrength";
import Form from "components/Form/Form";
import { autoclose } from "components/Modals";
import { useConfirmationDialog } from "components/Modals/confirmation.modal";
import { useErrorNotification } from "components/Notifications/notification";
import { useFormState } from "hooks/stateHooks";
import { isEqual } from "lodash";
import { prop } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutAgency } from "../../../../../redux/actions/agencyActions";
import { logoutUser } from "../../../../../redux/actions/authStatusActions";
import { validEmail } from "util/validators";
import StatusBar from "views/Settings/StatusBar/StatusBar";
import InputField from "../../../../../components/Form/InputField";

export default function PasswordEmailChange() {
  const currentContact = useSelector(prop("currentContact"));
  const notify = useErrorNotification();
  const dispatch = useDispatch();
  const confirm = useConfirmationDialog();
  const initialEmail = useMemo(() => ({ email: currentContact.email }), []);
  const [updatedInfo, setUpdatedInfo] = useFormState(initialEmail);
  const setPassword = useCallback(
    key => value => {
      setUpdatedInfo(updatedInfo => ({ ...updatedInfo, [key]: value }));
    },
    [setUpdatedInfo]
  );
  const submit = useCallback((id, updatedInfo) => {
    updateEmailAndPassword(id, updatedInfo)
      .then(() => {
        dispatch(logoutAgency());
        dispatch(logoutUser());
        window.location.reload();
      })
      .catch(err => notify(err));
  }, []);

  // Status bar integration...
  const [status, setStatus] = useState("clean");
  useEffect(() => {
    if (!!updatedInfo && !!initialEmail) {
      if (status === "clean" && !isEqual(updatedInfo, initialEmail) && validEmail(updatedInfo?.email)) {
        setStatus("dirty");
      } else if (status === "savingRequested") {
        autoclose(
          confirm({
            title: "Change Email and Password",
            body:
              "Changing your email or password will log you out of Widewail and you will have to log back in using your new credentials.",
            choices: ["Continue and Log out", "Go Back"],
            color: ["success", "danger"]
          })
        ).then(confirmed => {
          if (confirmed) {
            setStatus("saving");
            updateEmailAndPassword(currentContact.id, updatedInfo)
              .then(() => {
                setStatus("success");
                dispatch(logoutAgency());
                dispatch(logoutUser());
                window.location.reload();
              })
              .catch(err => {
                notify(err);
                setStatus("failure");
              });
          } else {
            setStatus("dirty");
          }
        });
      } else if (status === "cleanRequested") {
        setUpdatedInfo(initialEmail);
        setStatus("clean");
      } else if (status === "success") {
        const timeoutid = setTimeout(() => setStatus("clean"), 1000);
        return () => clearTimeout(timeoutid);
      }
    }
  }, [status, currentContact.id, initialEmail, updatedInfo, setUpdatedInfo, submit]);
  // ...Status bar integration

  return (
    <>
      <div className="p-3">
        <Form state={updatedInfo} setState={setUpdatedInfo}>
          <InputField
            value={updatedInfo?.email}
            tip="To change your email you must also create a new password"
            name="email"
            label="Email"
            autocomplete="email"
            dataLpIgnore="false"
            valid={validEmail(updatedInfo?.email)}
            feedbackMessage="Make sure your email is valid"
          />
          <PasswordInput
            newPassword={false}
            name="oldPassword"
            label="Current Password"
            password={updatedInfo.oldPassword || ""}
            onChange={setPassword("oldPassword")}
          />
          <PasswordStrength password={updatedInfo?.newPassword} onChange={setPassword("newPassword")} />
        </Form>
      </div>
      <StatusBar cancelButtonText="Cancel" status={status} onStatusChange={setStatus} />
    </>
  );
}
