import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  FormGroup,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Label,
  UncontrolledButtonDropdown
} from "reactstrap";
import { isEmpty } from "lodash";
import { Tooltip } from "react-lightweight-tooltip";
import * as customerApi from "api/customerApi";
import FormField from "components/Form/FormField";
import StatusMessages from "components/Misc/StatusMessages";
import AjaxLoader from "components/Misc/AjaxLoader";
import { autoclose, ModalsContext } from "../Modals";
import { riseConfimationDialog } from "../Modals/confirmation.modal";
import { contactCustomerTemplates } from "data/messageTemplates";
import { ACTIVE_CONTACT } from "data/options";
import { findMalformedPlaceholders } from "util/validators";

import styles from "../../scss/_custom.scss";
import { currentContactName } from "util/userUtils";

const canContact = c => c.email && c.status === ACTIVE_CONTACT;

class CustomerMessageComposer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recipients: [],
      message: "",
      errors: { checkbox: [], textarea: [], network: [] },
      dropdownOpen: false
    };

    this.addMessageContact = this.addMessageContact.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    if (this.props.isOpen) this.loadContacts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.customerId !== prevProps.customerId || (this.props.isOpen && !prevProps.isOpen)) {
      this.loadContacts();
    } else if (
      this.state.contacts &&
      this.props.contactTag &&
      (this.props.contactTag !== prevProps.contactTag || !prevState.contacts)
    ) {
      //auto select contacts based on an optional tag
      let recipients = this.state.contacts
        .filter(c => canContact(c) && c.tags && c.tags.indexOf(this.props.contactTag) >= 0)
        .map(c => c.id);
      this.setState({ recipients });
    }

    if (prevState.message !== this.state.message) {
      const malformedSequences = findMalformedPlaceholders(this.state.message, ["[XXX]"]);
      const textareaErrors = malformedSequences
        ? malformedSequences.map(sequence => `Please fill this placeholder in your text: ${sequence}`)
        : [];
      this.setErrorsOnField("textarea", textareaErrors);
    }
  }

  addMessageContact(evt) {
    let { recipients } = this.state;
    if (evt.target.checked) {
      this.setState({ recipients: [...recipients, evt.target.value] });
      this.setErrorsOnField("checkbox", []);
    } else {
      this.setState({
        recipients: recipients.filter(c => c !== evt.target.value)
      });
    }
  }

  loadContacts() {
    let { customerId } = this.props;
    customerApi.loadCustomerContacts(customerId).then(res => {
      this.setState({
        contacts: res.data._embedded.contacts
      });
    });
  }

  sendMessage(template, redirectAfterClose = true, messageSent = false) {
    const { recipients, message, errors } = this.state;
    const { onClose, author, reviewId, customerId } = this.props;

    const checkboxErrors = !recipients || isEmpty(recipients) ? ["Select one or more recipients."] : [];

    this.setErrorsOnField("checkbox", checkboxErrors);

    if (isEmpty(checkboxErrors) && isEmpty(errors.textarea)) {
      return customerApi
        .sendContactEmail(reviewId, customerId, recipients, message, author, template)
        .then(() => onClose(redirectAfterClose, messageSent))
        .catch(err => this.setState({ errors: { network: err.response.data.errors } }));
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  setErrorsOnField(field, errors) {
    this.setState({
      errors: {
        ...this.state.errors,
        [field]: errors
      }
    });
  }

  render() {
    const { messages, recipients, message, contacts, errors } = this.state;
    const { isOpen, onClose, reviewId } = this.props;

    const allErrors = [].concat(...Object.values(this.state.errors));

    return (
      <Modal fade={false} isOpen={isOpen}>
        <ModalHeader>Contact Customer</ModalHeader>
        <ModalBody>
          <Form>
            {contacts ? (
              <FormField label="To:" inputWidth={10} labelWidth={2} name="recipients">
                <span>
                  {contacts &&
                    contacts.filter(canContact).map(c => (
                      <FormGroup key={c.id} check>
                        <Label check>
                          <Input
                            invalid={!isEmpty(errors.checkbox)}
                            disabled={c.emailDisabled}
                            type="checkbox"
                            value={c.id}
                            onChange={this.addMessageContact}
                            checked={recipients.indexOf(c.id) >= 0}
                          />{" "}
                          {c.name + " - " + c.title}
                          {c.emailDisabled && (
                            <Tooltip content="Email disabled">
                              <i
                                className="fa fa-warning"
                                style={{
                                  paddingLeft: "5px",
                                  color: styles.warning
                                }}
                              />
                            </Tooltip>
                          )}
                        </Label>
                      </FormGroup>
                    ))}
                </span>
              </FormField>
            ) : (
              <AjaxLoader />
            )}
            <Input
              type="textarea"
              style={{ height: "200px" }}
              id={message}
              name={message}
              placeholder="Write a message (optional)..."
              onChange={evt => this.setState({ message: evt.target.value })}
              value={message}
              invalid={!isEmpty(errors.textarea)}
              className="mb-2"
            />
            <StatusMessages errors={allErrors} messages={messages} />
            <div className="d-flex justify-content-end">
              <UncontrolledButtonDropdown size="sm">
                <DropdownToggle caret>Select message template</DropdownToggle>
                <DropdownMenu>
                  {contactCustomerTemplates.map(({ title, text }) => (
                    <DropdownItem onClick={() => this.setState({ message: text })}>{title}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <ModalsContext.Consumer>
            {riser => (
              <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret>
                  <i className="fa fa-send-o" />
                  {" Send..."}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Template</DropdownItem>
                  <DropdownItem onClick={() => this.sendMessage("NegativeApproval")}>New Approval</DropdownItem>
                  {reviewId && (
                    <DropdownItem
                      onClick={() =>
                        autoclose(
                          riseConfimationDialog(riser)({
                            title: "Are you sure you want to send this message?",
                            body: message
                          })
                        ).then(confirmed => {
                          confirmed && this.sendMessage("NegativeCommunication", false, true);
                        })
                      }
                    >
                      Review Notification
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </ButtonDropdown>
            )}
          </ModalsContext.Consumer>
          <Button color="danger" onClick={() => onClose(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

CustomerMessageComposer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  reviewId: PropTypes.string,
  contactTag: PropTypes.string
};

function mapStateToProps(state) {
  return {
    author: currentContactName()
  };
}

export default connect(mapStateToProps)(CustomerMessageComposer);
