import React from "react";
import { siteName } from "data/sites";

import { DashboardWidget } from "components/Widgets/DashboardWidget";
import RatingsChart from "components/Charts/RatingsChart";

import { withReportFilterQuery } from "components/HOCs/ReportWithFilters";

const interactionTypeRenderer = () => type =>
    ({
      POST: "Post",
      REVIEW: "Review"
    }[type]),
  legendRenderer = source => (entry, key) => {
    const value = entry[key];
    return key === "id"
      ? value === "TOTAL"
        ? "Average"
        : source === "SITE"
        ? siteName(value)
        : source === "INTERACTION_TYPE"
        ? interactionTypeRenderer(value) || value
        : value
      : key === "total"
      ? value
      : `${Math.round(value * 100)}%`;
  };

export function RatingWidget({ source, data, actions, isLoading }) {
  return (
    <DashboardWidget title="Rating" actions={actions} isLoading={isLoading}>
      <RatingsChart data={data} renderer={legendRenderer(source)} />
    </DashboardWidget>
  );
}

export default withReportFilterQuery({
  url: "/report/rating",
  sources: {
    SITE: "Site",
    PROFIT_CENTER: "Category",
    INTERACTION_TYPE: "Interaction type"
  }
})(RatingWidget);
