import React from "react";
import LinkedInReactionSelector from "./LinkedInReactionSelector";
import FacebookReactionSelector from "./FacebookReactionSelector";

const ReactionSelector = ({ enabled = true, source, isReply = false, onChange, text }) => {
  if (source === "LINKEDIN") {
    return <LinkedInReactionSelector enabled={enabled} isReply={isReply} onChange={onChange} text={text} />;
  } else if (source === "FACEBOOK") {
    return <FacebookReactionSelector enabled={enabled} onChange={onChange} text={text} />;
  }
  return <></>;
};

export default ReactionSelector;
