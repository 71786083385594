import { debounce, isString, noop, uniqueId } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { iconUrlForCampaignType } from "../../../data/campaigns";
import { useInviteCampaigns } from "../../../hooks/data/campaignHooks";
import SelectField from "../../Form/SelectField";
import { DEBOUNCE_DELAY_DEFAULT } from "../../../constants/delays";

/**
 * Campaign selector. The value prop can either be the campaign object returned by this
 * selector or a campaign id to fetch instead
 *
 * @param {{
 *  withIcon?: boolean - if the campaign type icon should show for the selected value
 * }} param0
 * @returns
 */
export default function CampaignSelector({
  onChange = noop,
  customerId,
  brandName,
  types,
  excludeInactive = true,
  enabled = true,
  status = "LIVE",
  withIcon = true,
  isClearable = false,
  name,
  value,
  byName = false,
  size = 10,
  disabled,
  ...props
}) {
  const selectName = useMemo(() => props.name || `campaign-${uniqueId()}`);
  const [input, setInput] = useState("");
  // we can't debounce setInput b/c that will mess up showing what the user typed
  // in the select field. Thus we have these name hooks
  const [campaignName, setCampaignName] = useState("");
  const nameChangeHandler = useCallback(
    debounce(input => setCampaignName(input), DEBOUNCE_DELAY_DEFAULT),
    []
  );
  useEffect(() => {
    nameChangeHandler(input);
  }, [nameChangeHandler, input]);

  const { data, isFetching } = useInviteCampaigns({
    status,
    name: campaignName,
    brandName,
    customerId,
    types,
    excludeInactive,
    enabled,
    sort: "name",
    size: size,
    include: isString(value) ? value : value?.id,
    byName: byName,
    enabled: !disabled
  });
  const campaigns = useMemo(() => data?.[0], [byName, data]);

  // see react-select styles API for what's going on here
  const styles = useMemo(() => {
    const style = {
      control: (provided, state) => ({
        ...provided,
        borderRadius: "0px",
        paddingLeft: "5px"
      })
    };
    if (withIcon) {
      style.option = (styles, { data }) => ({ ...styles, marginLeft: "0.25rem", ...icon(data.type) });
      style.input = (styles, { hasValue }) => (hasValue ? { ...styles, ...icon() } : styles);
      style.singleValue = (styles, { data }) => ({ ...styles, ...icon(data.type) });
    }
    return style;
  }, [withIcon]);

  const selectValue = useMemo(() => {
    if (value && campaigns) {
      // value is a campaign id or name
      if (isString(value)) {
        if (byName) {
          return campaigns.filter(c => c.name === value);
        } else {
          return campaigns.filter(c => c.id === value);
        }
      }
      return value;
    } else if (value === null) {
      // null and undefined mean different things to react-select
      return value;
    }
    return undefined;
  }, [value, byName, campaigns]);

  return (
    <SelectField
      id="campaign-selector"
      name={selectName}
      placeholder="Search for a campaign..."
      isLoading={isFetching}
      options={campaigns}
      isSearchable={true}
      isClearable={isClearable}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ name }) => name}
      onChange={event => onChange(event.target.value)}
      inputValue={input}
      onInputChange={setInput}
      value={selectValue ?? value}
      styles={styles}
      isDisabled={disabled}
      {...props}
    />
  );
}

const icon = type => ({
  display: "flex",
  justifyContent: "flex-start",
  backgroundImage: `url(${iconUrlForCampaignType(type)})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "24px",
  backgroundPosition: "left center",
  ":before": {
    backgroundColor: "transparent",
    content: '" "',
    display: "block",
    marginRight: 24
  }
});
