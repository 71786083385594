import { useCallback, useEffect } from "react";

/**
 * @type {<T extends any[]>(cb: (...v: T) => void, ...deps: T) => void}
 */
export default function usePureEffect(cb, ...deps) {
  const effect = useCallback(() => cb(...deps), [cb, ...deps]);
  return useEffect(() => {
    effect();
  }, [effect]);
}
