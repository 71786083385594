import WWChipsBag from "components/WWChips/WWChipsBag/WWChipsBag";
import { useFilterState } from "hooks/filteringHooks";
import { Fragment as F, useMemo } from "react";
import { Collapse } from "reactstrap";
import { filterFactory } from "../FacetedSearch";
import ResetSearchFiltersButton from "../ResetFiltersButton";

import style from "./ChipBar.module.scss";
const { container, chipsContainer } = style;

const ChipBar = ({ filters }) => {
  const [filtersValues = {}] = useFilterState(),
    filtersWithChips = filters.filter(f => f?.chip?.position !== "NONE"),
    chipFilters = useMemo(
      () => filtersWithChips.map((filter, i) => <F key={i}>{filterFactory(filter)?.chip(filter)}</F>),
      [filtersWithChips]
    ),
    showChipBar = filtersWithChips.some(f => Object.keys(filtersValues).includes(f?.name));

  return (
    <Collapse className="d-none d-md-block" isOpen={showChipBar}>
      {showChipBar && (
        <div className={container}>
          <div className={chipsContainer}>
            <WWChipsBag wrap={false}>{chipFilters}</WWChipsBag>
          </div>
          <ResetSearchFiltersButton filters={filters} />
        </div>
      )}
    </Collapse>
  );
};

export default ChipBar;
