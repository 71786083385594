import WWButton from "components/Buttons/WWButton";
import InputField from "../InputField";
import React, { useCallback } from "react";
import RemoveFieldControl from "../RemoveFieldControl";
import RoundBadge from "components/Badges/RoundBadge/RoundBadge";

const InputArray = ({
  values = [],
  name,
  onChange,
  addMoreText = "Add More",
  maxArrayLength = 10,
  bulletPoints,
  ...props
}) => {
  const handleOnChange = useCallback(
    index => event => {
      onChange(Object.assign([], values, { [index]: event.target.value }));
    },
    [values]
  );
  return (
    <>
      {values.map((value, index) => (
        <InputField
          key={`${name}-${index}`}
          name={`${name}[${index}]`}
          inline
          label={bulletPoints && <RoundBadge className="me-2 d-none d-md-flex">{index + 1}</RoundBadge>}
          value={value}
          onChange={handleOnChange(index)}
          additionalControl={
            <RemoveFieldControl className="ms-2" onClick={() => onChange(values.filter((_, i) => index !== i))} />
          }
          {...props}
        />
      ))}
      {values.length < maxArrayLength && (
        <div className="d-flex flex-row-reverse">
          <WWButton iconClass="fa fa-plus" color="link" onClick={() => onChange(values.concat(""))}>
            {addMoreText}
          </WWButton>
        </div>
      )}
    </>
  );
};

export default InputArray;
