import { property } from "lodash";
import isString from "lodash/isString";
import set from "lodash/set";
import { CONTACT_TYPE_WIDEWAIL_USER } from "../util/constants";
import * as client from "./apiClient";
import { upload } from "./mediaApi";

const baseUri = "customers";
const baseUriContacts = "contacts";
const dateFields = ["activeDate", "deactivationDate"];

export function loadCustomers(filter = null, page = 0, size = 25, projection = "customerList") {
  return client.get(baseUri, {
    params: {
      size,
      page,
      sort: "companyName,asc",
      projection,
      ...filter
    }
  });
}

export function loadCustomersFilter(filter = {}, size = 25) {
  return client.get(`${baseUri}/filter`, {
    params: {
      size,
      ...filter
    }
  });
}

export function loadCustomer(customerId, projection = "details") {
  let params = {};
  if (projection) {
    params.projection = projection;
  }
  return client.get(`${baseUri}/${customerId}`, { params }).then(res => client.wrapDates(res.data, dateFields));
}

export function loadCustomerContacts(customerId, params) {
  return client.get(`${baseUri}/${customerId}/contacts`, {
    params: { ...params }
  });
}

export function loadCustomerShareContacts(customerId, params) {
  return client.get(`${baseUri}/${customerId}/shareContacts`, {
    params: { ...params }
  });
}

export function loadContactCustomers(contactId, projection = "name", product) {
  let params = {};
  if (projection) {
    set(params, "projection", projection);
  }

  if (product) {
    set(params, "product", product);
  }
  return client.get([baseUriContacts, contactId, "customers"], {
    params: params
  });
}

export function loadCustomerNames(filter = null, product = null) {
  return client.get(baseUri, {
    params: {
      q: filter,
      product,
      projection: "customerName"
    }
  });
}

export function loadContacts(filter = {}, page = 0, size = 25) {
  if (!filter.type) filter.type = "WIDEWAIL_USER";

  return client.get(baseUriContacts, {
    params: {
      ...filter,
      page,
      size
    }
  });
}

export function loadContact(id, projection) {
  return client.get([baseUriContacts, isString(id) ? id : "me"], projection ? { params: { projection } } : {});
}

export function saveContact(contact) {
  let saveContact = Object.assign({}, contact);
  if (contact.receiveShares === false) {
    set(saveContact, "audience", null);
  }
  return contact.id
    ? client.patch([baseUriContacts, contact.id], saveContact)
    : client.post(baseUriContacts, saveContact);
}

export function deleteContact(id) {
  return client.doDelete([baseUriContacts, id]);
}

export function deleteField(id, field) {
  return client.doDelete([baseUri, id, field]);
}

export function linkCustomerContact(customerId, contact) {
  let path = contact.type === CONTACT_TYPE_WIDEWAIL_USER ? "contacts" : "shareContacts";
  return client.addAssociation([baseUri, customerId, path], contact.id);
}

export function saveFacebookInfo(id, info) {
  return client.post("link", {
    id: id,
    updatedFields: ["facebookInfo"],
    facebookInfo: info
  });
}

export function saveGoogleInfo(id, info) {
  return client.post("link", {
    id: id,
    updatedFields: ["gmbInfo"],
    gmbInfo: info
  });
}

export function removeCustomerContact(customerId, contactId, type) {
  const assoc = type === CONTACT_TYPE_WIDEWAIL_USER ? "contacts" : "shareContacts";
  return client.doDelete([baseUri, customerId, assoc, contactId]);
}

export function removeAllContactsFromCustomer(customerId) {
  return client.doDelete([baseUri, customerId, "contacts", "*"]);
}

export function saveCustomer(customer) {
  return customer.id ? client.patch([baseUri, customer.id], customer) : client.post(baseUri, customer);
}

export function updateBulkCustomers(customerIds, filters, updates) {
  return client.patch([baseUri], { customerIds, filters, updates });
}

export function updateBulkContacts(contactIds, filters, updates) {
  return client.patch([baseUriContacts], { contactIds, filters, updates });
}

export function sendMontlyReport(id) {
  return client.postEmpty("email/monthly", {
    params: { id: id }
  });
}

export function queueImport(customerId, source, siteInfoId, endDate = null, thirdpartyId = null) {
  return client.postEmpty(["import/customer", customerId], {
    params: {
      source,
      siteInfoId,
      endDate,
      thirdpartyId
    }
  });
}

export function previewImport(customerId, source, siteInfoId, endDate = null, thirdpartyId = null) {
  return client
    .postEmpty(["import/customer", customerId, "preview"], {
      params: {
        source,
        siteInfoId,
        endDate,
        thirdpartyId
      }
    })
    .then(response => response.data);
}

export function sendContactEmail(reviewId, customerId, recipients, message, from, template) {
  return client.post("email/reviewContact", message, {
    headers: {
      "Content-type": "text/plain"
    },
    params: {
      reviewId,
      customerId,
      to: recipients,
      from,
      tmpl: template
    }
  });
}

export function loadMessages(customerId, page = 0) {
  return client.get("messages", {
    params: {
      customerId,
      page,
      size: 10,
      sort: "createdOn,desc"
    }
  });
}

export function loadBlockedUsers(customerId) {
  return client.get(`fb/customer/${customerId}/blocked`);
}

export function unblockUser(customerId, userId) {
  return client.doDelete(`fb/customer/${customerId}/blocked/${userId}`);
}

export function blockUser(customerId, userId) {
  return client.post(`fb/customer/${customerId}/blocked`, { id: userId });
}

export function regenerateUserKey({ customerId, siteInfoId, businessId, userAccessToken }) {
  return client.post(`fb/customer/${customerId}/siteInfo/${siteInfoId}/regenerateUserKey`, {
    userAccessToken,
    businessId
  });
}

export function postQnA(customerId, siteInfoId, question, answer) {
  return client.post(`google/customer/${customerId}/${siteInfoId}/questions`, {
    question,
    answer
  });
}

export function postQnAFile(customerId, siteInfoId, file) {
  let formData = new FormData();
  formData.append("file", file);
  return client.postFormData(`google/customer/${customerId}/${siteInfoId}/questions/file`, formData);
}

export function postShareContactsFile(customerId, file) {
  let formData = new FormData();
  formData.append("file", file);
  return client.postFormData(`${baseUri}/${customerId}/shareContacts/file`, formData);
}

export function uploadLetterOfAuthorization(customerId, objectName, file) {
  return client
    .post(`${baseUri}/${customerId}/smsOrders/letterOfAuthorization/${objectName}`, {})
    .then(res => upload(file, res.data));
}

export function deleteCustomer(id) {
  return client.doDelete([baseUri, id]);
}

export function fetchSites(customerId, field) {
  return client.get([baseUri, customerId, field]);
}

export function addSite(customerId, field, entity, override = false) {
  return client.post([baseUri, customerId, field], entity, {
    params: {
      override
    }
  });
}

export function removeSite(customerId, field, siteId) {
  return client.doDelete([baseUri, customerId, field, siteId]);
}

export function enableSite(customerId, siteId) {
  return client.post([baseUri, customerId, "sites", siteId, "enable"]);
}

export function disableSite(customerId, siteId) {
  return client.post([baseUri, customerId, "sites", siteId, "disable"]);
}

export function updateDefaultReviewTag(customerId, field, siteId, defaultReviewTag) {
  return client.patch([baseUri, customerId, field, siteId, "defaultReviewTag"], defaultReviewTag, {
    headers: {
      "Content-type": "text/plain"
    }
  });
}

export function updateCompanyNameOverride(customerId, field, siteId, companyNameOverride) {
  return client.patch([baseUri, customerId, field, siteId, "companyNameOverride"], companyNameOverride, {
    headers: {
      "Content-type": "text/plain"
    }
  });
}

export function updateCarfaxLink(customerId, siteId, patch) {
  return client.patch([baseUri, customerId, "carfaxInfo", siteId], patch);
}

export function updateNeustarLink(customerId, siteId, patch) {
  return client.patch([baseUri, customerId, "neustarInfo", siteId], patch);
}

export function updateApartmentRatingsLink(customerId, siteId, patch) {
  return client.patch([baseUri, customerId, "apartmentRatingsInfo", siteId], patch);
}

export function updateAppfolioLink(customerId, siteId, patch) {
  return client.patch([baseUri, customerId, "appfolioInfo", siteId], patch);
}

export function updateRentManagerLink(customerId, siteId, patch) {
  return client.patch([baseUri, customerId, "rentManagerInfo", siteId], patch);
}

export function updateRealPageLink(customerId, siteId, patch) {
  return client.patch([baseUri, customerId, "realPageInfo", siteId], patch);
}

export function updateServiceFusionInfo(customerId, siteId, patch) {
  return client.patch([baseUri, customerId, "serviceFusionInfo", siteId], patch);
}

export function updateYelpLink(customerId, siteId, patch) {
  return client.patch([baseUri, customerId, "yelpInfo", siteId], patch);
}

export function updateEdmundsLink(customerId, siteId, patch) {
  return client.patch([baseUri, customerId, "edmundsInfo", siteId], patch);
}

export function updateDealerbuiltClosedDealStatus(customerId, siteId, closedDealStatus) {
  return client
    .patch([baseUri, customerId, "dealerbuiltInfo", siteId, "closedDealStatus"], closedDealStatus, {
      headers: {
        "Content-type": "text/plain"
      }
    })
    .then(property("data"));
}

export function updateDealerbuiltCloseRepairStatus(customerId, siteId, closedRepairStatus) {
  return client
    .patch([baseUri, customerId, "dealerbuiltInfo", siteId, "closedRepairStatus"], closedRepairStatus, {
      headers: {
        "Content-type": "text/plain"
      }
    })
    .then(property("data"));
}

export function updateCdkDmsClosedSaleStatus(customerId, field, siteId, closedSaleStatus) {
  return client
    .patch([baseUri, customerId, field, siteId, "closedSaleStatus"], closedSaleStatus, {
      headers: {
        "Content-type": "text/plain"
      }
    })
    .then(property("data"));
}

export function updateDealervaultGroupId(customerId, field, siteId, groupId) {
  return client
    .patch([baseUri, customerId, field, siteId, "groupId"], groupId, {
      headers: {
        "Content-type": "text/plain"
      }
    })
    .then(property("data"));
}

export function getMindbodyLink(customerId, siteId) {
  return client.get([baseUri, customerId, "mindbody", siteId, "activationCode"]);
}

export function updateMindbodyMode(customerId, siteId, mode) {
  return client.put([baseUri, customerId, "mindbodyInfo", siteId, "mode"], mode).then(property("data"));
}

export function updateSevenroomsClosedStatuses(customerId, field, siteId, completedReservationStatuses) {
  return client
    .patch([baseUri, customerId, field, siteId, "completedReservationStatuses"], completedReservationStatuses, {
      headers: {
        "Content-type": "text/plain"
      }
    })
    .then(property("data"));
}

export function updateYardiPropertyId(customerId, siteId, propertyId) {
  return client
    .patch([baseUri, customerId, "yardiInfo", siteId, "propertyId"], propertyId, {
      headers: {
        "Content-Type": "text/plain"
      }
    })
    .then(property("data"));
}

export function updateYardiLink(customerId, siteId, yardiPatch) {
  return client.patch([baseUri, customerId, "yardiInfo", siteId], yardiPatch).then(property("data"));
}

export function updateYardiMaintenanceLink(customerId, siteId, yardiMaintenancePatch) {
  return client
    .patch([baseUri, customerId, "yardiMaintenanceInfo", siteId], yardiMaintenancePatch)
    .then(property("data"));
}

export function updateOpenTableInfo(customerId, siteId, openTablePatch) {
  return client.patch([baseUri, customerId, "openTableInfo", siteId], openTablePatch).then(property("data"));
}

export function updateResmanLink(customerId, siteId, resmanPatch) {
  return client.patch([baseUri, customerId, "resmanInfo", siteId], resmanPatch).then(property("data"));
}

export function updateTekionInfo(customerId, siteId, tekionPatch) {
  return client.patch([baseUri, customerId, "tekionInfo", siteId], tekionPatch).then(property("data"));
}

export function updatePropertywareLink(customerId, siteId, propertywarePatch) {
  return client.patch([baseUri, customerId, "propertywareInfo", siteId], propertywarePatch).then(property("data"));
}

export function getPropertywareBuildings(data) {
  return client.post(["propertywareInfo", "buildings"], data).then(property("data"));
}

export function updateEntrataLink(customerId, siteId, entrataPatch) {
  return client.patch([baseUri, customerId, "entrataInfo", siteId], entrataPatch).then(property("data"));
}

export function loadGroups(customerId) {
  return client.get(`${baseUriContacts}/${customerId}/groups`);
}

export function addGroup(customerId, groups) {
  return client.post(`${baseUriContacts}/${customerId}/groups`, groups);
}

export function deleteGroup(customerId, group) {
  return client.doDelete(`${baseUriContacts}/${customerId}/groups/${group}`);
}

export function resetPassword(contactId) {
  return client.post(`${baseUriContacts}/${contactId}/resetPassword`);
}

export function resetPasswordByEmail(email) {
  return client.postEmpty("passwordReset", { params: { email } });
}

export function updateEmailAndPassword(contactId, updatedEmailAndPassword) {
  return client.post(`${baseUriContacts}/${contactId}/login`, updatedEmailAndPassword);
}

export function updateAccessToken(infoId, token) {
  return client.post(`${baseUriContacts}/${infoId}/token`, token, {
    headers: { "content-type": "text/plain" }
  });
}

export function contactAgencies(contactId) {
  return client.get([baseUriContacts, contactId, "agencies"]);
}

export function refresh(customerId, field, siteInfoId) {
  return client.post(`customers/${customerId}/${field}/${siteInfoId}/refresh`);
}

export function getCustomFields(customerId) {
  return client.get(`customers/${customerId}/customField`);
}

export function saveCustomField(customerId, customField = null) {
  if (customField === null) {
    return client.post(`customers/${customerId}/customField`, customField);
  } else {
    return client.patch(`customers/${customerId}/customField/${customField.id}`, customField);
  }
}

export function removeCustomField(customerId, customField) {
  return client.doDelete(`customers/${customerId}/customField/${customField.id}`);
}

export function loadCustomerHistory(id) {
  return client.get([baseUri, id, "history"]);
}

export function updateContactManagedBy(id, customerId) {
  return client.post(`contacts/${id}/managedBy`, { id: customerId });
}

export function removeSmsPhoneNumber(phoneNumber) {
  return client.doDelete([baseUri, "smsPhoneNumber", phoneNumber]);
}

export function assignSmsPhoneNumber(organizationSid, brandSid, smsPhoneNumber) {
  return client.postEmpty([baseUri, "smsPhoneNumber"], {
    params: { smsPhoneNumber, brandSid, organizationSid }
  });
}

export function loadSiteInfos(customerId) {
  return client.get([baseUri, customerId, "siteInfoSummaries"]);
}

export function customerReviewEntities(customerId, type, value) {
  return client.get([baseUri, customerId, "reviewEntities"], {
    params: {
      value,
      type
    }
  });
}

export function saveResponderNotes(customerId, notes) {
  return client.put(["responderNoteRule"], notes, { params: { customerId } });
}
