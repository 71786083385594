import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useMemo } from "react";
import { autoclose, useModal } from "./index";

export const risePropertySiteValidationDialog = riser => textToInsert =>
  autoclose(
    riser(resolve => (
      <>
        <ModalHeader className="d-flex w-100 justify-content-center">
          You need to select at least one action
        </ModalHeader>
        <ModalBody>Please select at least one event ({textToInsert}) to import.</ModalBody>
        <ModalFooter>
          <Button onClick={() => resolve()}>Ok</Button>
        </ModalFooter>
      </>
    ))
  );

export const usePropertySitesValidationDialog = () => {
  const riser = useModal();
  return useMemo(() => risePropertySiteValidationDialog(riser), [riser]);
};
