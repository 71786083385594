import React, { useState } from "react";
import classnames from "classnames";
import { noop, capitalize } from "lodash";
import { UncontrolledTooltip } from "reactstrap";

import styles from "./SentimentSelector.module.scss";

const { iconClass, sentimentsClass, smallIconClass } = styles;

const iconsMap = {
  POSITIVE: { icon: "fa-smile-o", selectedColor: "text-yellow-dark" },
  NEUTRAL: { icon: "fa-meh-o", selectedColor: "text-yellow-dark" },
  NEGATIVE: { icon: "fa-frown-o", selectedColor: "text-yellow-dark" }
};

const tagSentimentIconMap = {
  NEGATIVE: { icon: "fa-thumbs-o-down", selectedIcon: "fa-thumbs-down", selectedColor: "text-danger" },
  NEUTRAL: { icon: "fa-circle-o", selectedIcon: "fa-circle", selectedColor: "text-warning" },
  POSITIVE: { icon: "fa-thumbs-o-up", selectedIcon: "fa-thumbs-up", selectedColor: "text-success" }
};

const sentimentSelectorMaps = Object.freeze({
  default: iconsMap,
  tag: tagSentimentIconMap
});

/**
 * @param {{
 *   sentiment?: keyof mappings;
 *   selectorStyle?: "default", "tag"
 *   onChange?: (v: keyof mappings) => void | Promise<void>;
 * }} param0
 */
export const SentimentSelector = ({ className, sentiment, onChange = noop, selectorStyle = "default" }) => {
  const sentimentSelectorMap = sentimentSelectorMaps[selectorStyle];
  const [updating, setUpdating] = useState(false);
  return (
    <div className={classnames(sentimentsClass, className)}>
      {Object.keys(sentimentSelectorMap).map(key => {
        const sentimentStyles = sentimentSelectorMap[key];
        const icon = sentimentStyles.icon;
        const selectedIcon = sentimentStyles.selectedIcon || icon;
        const selectedColor = sentimentStyles.selectedColor;
        const isSelected = sentiment === key;
        const isTagStyle = selectorStyle === "tag";
        return (
          <div
            key={key}
            onClick={async () => {
              if (!updating) {
                setUpdating(true);
                await onChange(sentiment === key ? undefined : key);
                setUpdating(false);
              }
            }}
            className={classnames("p-1 d-inline-block", { "ps-2": isTagStyle })}
          >
            <i
              id={key}
              className={classnames(
                "fa",
                iconClass,
                { [icon]: !isSelected },
                {
                  [smallIconClass]: isTagStyle,
                  [selectedIcon]: isSelected,
                  [selectedColor]: isSelected
                }
              )}
            />
            <UncontrolledTooltip target={key}>{`${capitalize(key)} Sentiment`}</UncontrolledTooltip>
          </div>
        );
      })}
    </div>
  );
};

export default SentimentSelector;
