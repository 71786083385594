import { siteAddress, thirdpartyCompanyName } from "./customerUtils";
import get from "lodash/get";

const DEALERRATER_DOMAIN = "https://www.dealerrater.com/";
const FACEBOOK_DOMAIN = "https://www.facebook.com/";
const INSTAGRAM_DOMAIN = "https://www.instagram.com/";
const GOOGLE_DOMAIN = "https://www.google.com/search?q=";
const CARS_DOMAIN = "https://www.cars.com/dealers/";

export function encodeUrlParameter(str) {
  return encodeURIComponent(str).replace(/%20/g, "+");
}

export function thirdpartyReviewUrl(review, replyingLink = false) {
  let customer = review.customer;

  if (review.thirdpartyUrl) return review.thirdpartyUrl;

  switch (review.source) {
    case "FACEBOOK":
      return facebookReviewLink(review);
    case "DEALERRATER":
      return replyingLink
        ? dealerraterReplyingPageLink(get(customer, "dealerraterInfo[0].dealerId"), review.thirdpartyId)
        : dealerraterPageLink(customer.companyName, get(customer, "dealerraterInfo[0].dealerId"));
    case "CARS_DOT_COM":
      return carsDotComReviewLink(
        customer.companyName,
        get(customer, "carsDotComInfo[0].dealerId"),
        review.thirdpartyId
      );
    case "GOOGLE":
      return googlePageLink(customer, review.siteInfoId);
    case "CARFAX":
      return carfaxPageLink(get(customer, "carfaxInfo[0].carfaxUrl"));
    default:
      return review.thirdpartyUrl;
  }
}

/**
 * takes a source and return the sites logo url.
 *
 * @param {object} info
 */
export const createSiteLogoUrl = source =>
  `/img/logos/primary/${
    [
      "CAR_GURUS",
      "CARS_DOT_COM",
      "DEALERRATER",
      "EDMUNDS",
      "FACEBOOK",
      "GOOGLE",
      "DEALER_DOT_COM",
      "DEALERVAULT",
      "INNTOPIA",
      "INSTAGRAM",
      "YELP",
      "MINDBODY",
      "SQUARE",
      "OPEN_TABLE",
      "TEKION"
    ].includes(source)
      ? source
      : "PLACEHOLDER"
  }.png`;

/**
 * takes a siteInfo and return the sites url.
 *
 * @param {object} info
 */
export const siteInfoUrl = info => {
  switch (info.source) {
    case "GOOGLE":
      return `https://www.google.com/search?q=${info.businessName} ${info.address}`;
    case "FACEBOOK":
      return facebookPageLink(info.pageId);
    case "INSTAGRAM":
      return `https://www.instagram.com/${info.username}`;
    case "DEALERRATER":
    case "CARS_DOT_COM":
      return info.dealerUrl;
    default:
      return info.url;
  }
};

/**
 * takes a siteInfo and return the sites Descriptor value label pair.
 *
 * @param {object} info
 */
export const siteInfoLocationDescriptor = info => {
  switch (info.source) {
    case "GOOGLE":
      return { value: info.businessName || info.companyName, label: "Business Name" };
    case "FACEBOOK":
      return { value: info.pageName || info.companyName, label: "Page Name" };
    case "INSTAGRAM":
      return { value: info.username || info.companyName, label: "Username" };
    case "DEALERRATER":
      return { value: info.dealerName || info.companyName, label: "Dealer Name" };
    case "APARTMENT_RATINGS":
      return { value: info.propertyId, label: "property Id" };
    case "APARTMENTS_DOT_COM":
      return { value: info.listingKey, label: "listings Key" };
    case "APPFOLIO":
      return { value: info.siteId, label: "Site Id" };
    case "UBERALL":
      return { value: info.locationId, label: "Location Id" };
    case "YARDI":
    case "YARDI_MAINTENANCE":
      return { value: info.propertyId, label: "Property Id" };
    case "RENT_MANAGER":
      return { value: info.companyCode, label: "Company Code" };
    case "PROPERTYWARE":
      return info.propertyName
        ? { value: info.propertyName, label: "Property Name" }
        : { value: info.organizationId, label: "Organization ID" };
    case "OPEN_TABLE":
      return { value: info.restaurantId, label: "Restaurant ID" };
    case "TEKION":
      return { value: info.dealerId, label: "Dealer ID" };
    default:
      return { value: info.companyName, label: "Company Name" };
  }
};

export function dealerraterPageLink(companyName, dealerraterId) {
  if (!dealerraterId) return undefined;

  let dashedCompanyName = companyName.replace(/\s+/g, "-");
  return DEALERRATER_DOMAIN + "dealer/" + dashedCompanyName + "-review-" + dealerraterId;
}

export function dealerraterReplyingPageLink(dealerraterId, dealerraterReviewId) {
  if (!dealerraterId) return undefined;

  return DEALERRATER_DOMAIN + "dp/" + dealerraterId + "/reviews/" + dealerraterReviewId;
}

export function carsDotComPageLink(companyName, carsDotComId) {
  if (!carsDotComId) return undefined;

  let dashedCompanyName = companyName.replace(/\s+/g, "-");
  return CARS_DOMAIN + carsDotComId + "/" + dashedCompanyName.toLowerCase() + "/reviews/";
}

export function carsDotComReviewLink(companyName, carsDotComDealerId, carsDotComReviewId) {
  let dashedCompanyName = companyName.replace(/\s+/g, "-");
  return (
    CARS_DOMAIN + carsDotComDealerId + "/" + dashedCompanyName.toLowerCase() + "/reviews/"
    // +
    // carsDotComReviewId
  );
}

export function googlePageLink(customer, siteInfoId = undefined) {
  return (
    GOOGLE_DOMAIN +
    encodeUrlParameter(thirdpartyCompanyName(customer, "GOOGLE", siteInfoId)) +
    " " +
    siteAddress(customer, "GOOGLE", siteInfoId)
  );
}

export function instagramPostLink(id) {
  return `${INSTAGRAM_DOMAIN}p/${id}`;
}

export function facebookPageLink(facebookPageId) {
  return FACEBOOK_DOMAIN + facebookPageId;
}

export function facebookReviewLink(review) {
  if (review.thirdpartyUrl) return review.thirdpartyUrl;
  return FACEBOOK_DOMAIN + review.thirdpartyId;
}

export function carfaxPageLink(carfaxUrl) {
  return carfaxUrl;
}

export function yelpPageLink() {}
