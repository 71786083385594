import { Col, Row, Table } from "reactstrap";
import { Card, CardBody, CardHeader, CardTitle } from "components/Card";
import { withReportFilterQuery } from "components/HOCs/ReportWithFilters";
import { TBody, TD, TH, THead, TR } from "components/Table";
import { getPrepareFilters, DEFAULT_REQUEST_FIELDS } from "util/reportUtils/videoReportUtils";

export const VideoReportData = ({ data }) => (
  <Row className="my-1">
    <Col className="no-pagebreak-inside">
      <Card>
        <CardHeader>
          <CardTitle>Video Requests by Source</CardTitle>
        </CardHeader>
        <CardBody className="mt-3">
          <Table responsive>
            <THead>
              <TR>
                <TH></TH>
                <TH>Sent</TH>
                <TH>Opened</TH>
                <TH>Open Rate</TH>
              </TR>
            </THead>
            <TBody>
              <TR>
                <TD>Manual</TD>
                <TD>{data.manualSendOpenRate?.sent || 0}</TD>
                <TD>{data.manualSendOpenRate?.opened || 0}</TD>
                <TD>
                  {data.manualSendOpenRate?.openRate
                    ? Math.round(data.manualSendOpenRate?.openRate * 100) + "%"
                    : "N/A"}
                </TD>
              </TR>
              <TR>
                <TD>Automated</TD>
                <TD>{data.automatedSendOpenRate?.sent || 0}</TD>
                <TD>{data.automatedSendOpenRate?.opened || 0}</TD>
                <TD>
                  {data.automatedSendOpenRate?.openRate
                    ? Math.round(data.automatedSendOpenRate?.openRate * 100) + "%"
                    : "N/A"}
                </TD>
              </TR>
              {data?.fiveStarFollowUp && (
                <>
                  <TR>
                    <TD>5 Star Follow Up</TD>
                    <TD>{data.fiveStarFollowUp?.sent || 0}</TD>
                    <TD>{data.fiveStarFollowUp?.opened || 0}</TD>
                    <TD>
                      {data.fiveStarFollowUp?.openRate
                        ? Math.round(data.fiveStarFollowUp?.openRate * 100) + "%"
                        : "N/A"}
                    </TD>
                  </TR>
                </>
              )}
            </TBody>
          </Table>
        </CardBody>
      </Card>
    </Col>
    <Col className="no-pagebreak-inside">
      <Card>
        <CardHeader>
          <CardTitle>Video Requests by Destination</CardTitle>
        </CardHeader>
        <CardBody className="mt-3">
          <Table responsive>
            <THead>
              <TR>
                <TH></TH>
                <TH>SMS</TH>
                <TH>Email</TH>
              </TR>
            </THead>
            <TBody>
              <TR>
                <TD>Sent by Destination</TD>
                <TD>{data.sentByDestination?.sms || 0}</TD>
                <TD>{data.sentByDestination?.email || 0}</TD>
              </TR>
              <TR>
                <TD>Opened by Destination</TD>
                <TD>{data.openedByDestination?.sms || 0}</TD>
                <TD>{data.openedByDestination?.email || 0}</TD>
              </TR>
            </TBody>
          </Table>
        </CardBody>
      </Card>
    </Col>
    <Col className="no-pagebreak-inside">
      <Card>
        <CardHeader>
          <CardTitle>Video Requests Open Rate</CardTitle>
        </CardHeader>
        <CardBody className="mt-3">
          <Table responsive>
            <THead>
              <TR>
                <TH></TH>
                <TH>Sent</TH>
                <TH>Opened</TH>
                <TH>Open Rate</TH>
              </TR>
            </THead>
            <TBody>
              {data?.sendOpenRateByTag?.map(rate => (
                <TR>
                  <TD>{rate.tag}</TD>
                  <TD>{rate?.sent || 0}</TD>
                  <TD>{rate?.opened || 0}</TD>
                  <TD>{rate?.openRate ? Math.round(rate?.openRate * 100) + "%" : "N/A"}</TD>
                </TR>
              ))}
            </TBody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default withReportFilterQuery({
  url: "/inviteVideoReport",
  prepareFilters: getPrepareFilters(DEFAULT_REQUEST_FIELDS)
})(VideoReportData);
