import classNames from "classnames";
import styles from "./SiteRowRenderers.module.scss";

const SiteDescriptor = ({ descriptor, url }) => {
  return (
    <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
      <span className="font-xs text-uppercase">{descriptor?.label}</span>
      <span className="d-flex align-items-center">
        {descriptor?.value}
        {url && (
          <a
            onClick={e => e.stopPropagation()}
            className="ms-2 d-flex align-items-center"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-external-link" />
          </a>
        )}
      </span>
    </div>
  );
};

export default SiteDescriptor;
