import React, { useCallback } from "react";
import { noop } from "lodash";
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, ResponsiveContainer, Tooltip } from "recharts";
import colors from "constants/colors";
import classNames from "classnames";
import styles from "./WWScatterChart.module.scss";

const { dotClickable } = styles;

const CustomDot = ({ x, y, meta, stroke, fill, width, height, onClick = noop, payload }) => {
  const center = {
    x: x + width / 2,
    y: y + height / 2
  };
  const moreThenOne = meta?.length && meta.length > 1;
  const dotRadius = moreThenOne ? 12 : 8;
  const oneDigit = meta?.length && meta.length < 10;
  const clickHandler = useCallback(() => onClick(payload.meta), [payload]);
  return (
    <>
      <circle
        stroke={stroke}
        fill={fill}
        cx={center.x}
        cy={center.y}
        r={dotRadius}
        className={classNames({ [dotClickable]: onClick })}
        onClick={clickHandler}
      />
      {moreThenOne && (
        <text
          fontSize="smaller"
          x={center.x}
          y={center.y}
          stroke="black"
          dy=".27rem"
          dx={oneDigit ? "-0.25rem" : "-0.47rem"}
          className={classNames({ [dotClickable]: onClick })}
          onClick={clickHandler}
        >
          {meta?.length || null}
        </text>
      )}
    </>
  );
};

const WWScatterChart = ({ data = [], onDotClick, options }) => (
  <ResponsiveContainer width="100%" height={440}>
    <ScatterChart width="100%" data={data}>
      <CartesianGrid stroke={colors.chartGridLightGray} opacity={0.5} {...(options?.grid || {})} />
      <XAxis
        dataKey="x"
        type="number"
        tickLine={false}
        tickMargin={8}
        tick={{ stroke: colors.grayLight, fontSize: 14 }}
        stroke={colors.grayMediumDark}
        {...(options?.xAxis || {})}
        label={{ dy: 25, fill: colors.grayLight, ...options?.xAxis?.label }}
      />
      <YAxis
        dataKey="y"
        tickLine={false}
        tickMargin={8}
        tick={{ stroke: colors.grayLight, fontSize: 14 }}
        stroke={colors.grayMediumDark}
        {...(options?.yAxis || {})}
        label={{ angle: -90, dx: -35, fill: colors.grayLight, ...options?.yAxis?.label }}
      />
      <ZAxis dataKey="z" {...(options?.zAxis || {})} />
      <Scatter
        name="scatterChart"
        data={data}
        fill={colors.chartStokeYellow}
        isAnimationActive={false}
        shape={<CustomDot onClick={onDotClick} />}
      />
      <Tooltip {...(options?.tooltip || {})} />
    </ScatterChart>
  </ResponsiveContainer>
);

export default WWScatterChart;
