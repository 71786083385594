import styles from "./DraggableCard.module.scss";
import { Button, Collapse } from "reactstrap";
import classnames from "classnames";
import { useDragStart } from "../../EditableList/EditableList";
import WWButton from "components/Buttons/WWButton";

const {
  container,
  headerContainer,
  headerContainerCollapsed,
  headerActions,
  dragButton,
  bodyContainer,
  title
} = styles;

const BASIC_HEADER_BUTTON_CLASS = "flex-grow m-0 ps-0 pe-0 border-0 bg-transparent text-dark";

export default function DraggableCard({ onDragStart, list = [], children, header, cardActions, collapsed = false }) {
  const binder = useDragStart();
  const noHeaderIconNeeded = list.length <= 1;
  return (
    <div className={container}>
      <div className={collapsed ? headerContainerCollapsed : headerContainer}>
        <div className={title}>
          {collapsed && (
            <WWButton
              size="sm"
              className={classnames(BASIC_HEADER_BUTTON_CLASS, dragButton, { invisible: noHeaderIconNeeded })}
              disabled={noHeaderIconNeeded}
              {...binder(noHeaderIconNeeded ? () => {} : onDragStart)}
            >
              <i className="fa fa-bars fa-lg" />
            </WWButton>
          )}
          {header}
        </div>
        {!collapsed && <div className={headerActions}>{cardActions}</div>}
      </div>
      <Collapse isOpen={!collapsed}>
        <div className={bodyContainer}>{children}</div>
      </Collapse>
    </div>
  );
}
