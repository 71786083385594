import React, { useCallback, useState } from "react";
import { Button } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import SelectField from "../../../components/Form/SelectField";
import { updateNeustarLink } from "../../../api/customerApi";
import { useNeustarListings } from "hooks/data/neustarHooks";

export default class NeustarAdminLinking {
  field() {
    return "neustarInfo";
  }

  add() {
    return AddNeustar;
  }

  edit() {
    return EditNeustar;
  }
}

const AddNeustar = ({ customer, onSave }) => {
  const [siteInfo, setSiteInfo] = useState({ source: "NEUSTAR" });

  const onSelectListing = useCallback(value => {
    setSiteInfo({
      source: "NEUSTAR",
      ...value
    });
  }, []);

  return (
    <>
      <FormField label="Existing Listing (leave blank if new)">
        <NeustarListingSelector onChange={onSelectListing} selectedId={siteInfo.listingId} isClearable={true} />
      </FormField>

      <Button color="primary" onClick={() => onSave(siteInfo)}>
        Link
      </Button>
    </>
  );
};

const EditNeustar = ({ info, customer }) => {
  const [siteInfo, setSiteInfo] = useState({ ...info });
  const [isReady, setIsReady] = useState(false);

  const onSelectListing = useCallback(
    value => {
      setSiteInfo({
        source: "NEUSTAR",
        ...value
      });
      setIsReady(value.listingId !== info.listingId);
    },
    [info]
  );

  const updateNeustar = useCallback(() => {
    updateNeustarLink(customer.id, info.id, siteInfo).then(() => setIsReady(false));
  }, [customer, info, siteInfo, setIsReady]);

  return (
    <>
      <FormField label="Listing (blank if new)">
        <NeustarListingSelector onChange={onSelectListing} selectedId={siteInfo.listingId} isClearable={false} />
      </FormField>
      <FormField label=" ">
        <Button color="primary" onClick={updateNeustar} disabled={!isReady}>
          Update
        </Button>
      </FormField>
    </>
  );
};

const NeustarListingSelector = ({ onChange, selectedId, ...props }) => {
  const { data, isFetching } = useNeustarListings();
  return (
    <SelectField
      {...props}
      name="neustarListing"
      value={selectedId && data ? data.find(d => d.listingId === selectedId) : null}
      className="w-100"
      isLoading={isFetching}
      options={data || []}
      getOptionLabel={option => `${option.name} - ${option.address}`}
      onChange={evt => onChange(evt.target.value)}
    />
  );
};
