import { useEffect } from "react";
import { filtersWithoutUrlStatus, useFilterReduxKey, useFilterState } from "hooks/filteringHooks";
import { LOCAL_STORAGE_KEYS } from "data/storageKeys";
import { forEach, isEmpty, omit } from "lodash";
import { useUpdateRedux } from "./stateHooks";
import { readLocalStorage, writeLocalStorage } from "util/localstorage";

const useFilterPersistence = () => {
  const [filterValues] = useFilterState();
  const key = useFilterReduxKey();
  const updateValue = useUpdateRedux();

  useEffect(() => {
    if (!isEmpty(key) && !isEmpty(filtersWithoutUrlStatus(filterValues))) {
      writeLocalStorage(LOCAL_STORAGE_KEYS.FILTER_STATE, {
        ...readLocalStorage(LOCAL_STORAGE_KEYS.FILTER_STATE),
        [key]: filtersWithoutUrlStatus(filterValues)
      });
    }
  }, [filterValues]);

  useEffect(() => {
    forEach(omit(readLocalStorage(LOCAL_STORAGE_KEYS.FILTER_STATE), key), (value, viewKey) => {
      updateValue(value, [viewKey]);
    });
  }, []);
};

export default useFilterPersistence;
