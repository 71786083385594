import { DAYS_OF_WEEK } from "util/dateUtils";
import { isUndefined, isNull } from "lodash";

export const getHoursEntity = ({ openHour, closeHour, openMin, closeMin }) => ({
  openHour: {
    hour: openHour,
    ...(openMin && { min: openMin })
  },
  closeHour: {
    hour: closeHour,
    ...(closeMin && { min: closeMin })
  }
});

export const getDaysOfWeekHours = (dayHours, additionalFields = {}) => {
  const result = [];
  Object.keys(dayHours).forEach(day => {
    const { hours, hasOpenHours } = dayHours[day];
    if (!hasOpenHours || !hours) {
      return;
    }
    const dayValue = DAYS_OF_WEEK[day];
    hours.forEach(hour => {
      if (isNull(hour)) {
        return;
      }
      const { openHour, closeHour } = hour;
      if (!isUndefined(openHour) && !isUndefined(closeHour)) {
        const hoursEntity = {
          openDay: dayValue,
          closeDay: dayValue,
          openHour: openHour.hour,
          closeHour: closeHour.hour,
          ...(openHour.min && { openMin: openHour.min }),
          ...(closeHour.min && { closeMin: closeHour.min }),
          ...additionalFields
        };
        result.push(hoursEntity);
      }
    });
  });
  return result;
};

export const getRegularHours = formValues => formValues?.regularHours && getDaysOfWeekHours(formValues.regularHours);

export const getMoreHours = (formValues, moreHoursTypes) => {
  let result = [];
  const moreHours = formValues?.moreHours;
  if (!moreHours) {
    return [];
  }
  Object.keys(moreHours).forEach(hoursTypeId => {
    const hoursTypeName = moreHoursTypes?.find(hoursType => hoursType.hoursTypeId === hoursTypeId)?.displayName;
    if (hoursTypeName) {
      result = result.concat(getDaysOfWeekHours(moreHours[hoursTypeId], { hoursTypeId, hoursTypeName }));
    }
  });
  return result;
};

export const getSpecialHours = formValues => {
  const { specialHours } = formValues;
  return specialHours
    ?.filter(specialHour => !!specialHour?.startDate)
    .map(({ startDate, opened, hours }) => {
      let hoursEntities;
      const basicEntity = {
        startDate,
        endDate: startDate,
        closed: !opened
      };
      if (opened) {
        hoursEntities = hours?.map(({ closeHour, openHour }) => {
          const openMin = openHour?.min || 0;
          const closeMin = closeHour?.min || 0;
          return {
            ...basicEntity,
            openHour: openHour?.hour,
            closeHour: closeHour?.hour,
            openMin,
            closeMin
          };
        });
      }
      return opened ? hoursEntities : [basicEntity];
    })
    .flat();
};

export const getAttributes = (formValues, attributes) =>
  attributes?.reduce((acc, { parent, valueType }) => {
    return formValues?.more?.[parent]
      ? acc.concat([
          {
            name: parent,
            type: valueType,
            values: [!!formValues?.more?.[parent]]
          }
        ])
      : acc;
  }, []);

export const getServiceItems = ({ serviceItems }, googleCategoryId) =>
  serviceItems
    ?.filter(
      serviceItem => !!serviceItem?.googleServiceItemId?.serviceTypeId || serviceItem?.googleServiceItemId?.value
    )
    .map(serviceItem => {
      const name = serviceItem?.googleServiceItemId?.value;
      return {
        name,
        googleCategoryId: name ? googleCategoryId : undefined,
        googleServiceItemId: serviceItem?.googleServiceItemId?.serviceTypeId,
        price: serviceItem?.price,
        currencyCode: serviceItem?.currencyCode || "USD"
      };
    });
