import { memo, useRef } from "react";
import { useQuery } from "react-query";
import { get } from "lodash";
import WidgetCard from "../CommonComponents/WidgetCard";
import WWTableBlock from "components/Table/WWTableBlock";
import { ImproveBanner } from "../CallsToActionBanners/CallsToActionBanners";
import LastSyncedInfo from "components/Listings/LastSyncedInfo";
import LinkButton from "components/Routing/LinkButton";
import ActiveProductsRequireToRender from "components/Auth/ActiveProductsRequireToRender";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import { defaultNormalizeResponse } from "hooks/data/useFilterQuery";
import { useGet } from "hooks/dataHooks";

import styles from "./ListingsWidget.module.scss";

const { buttonClass, seAllButtonContainer, tableContainerClass, tableInnerContainerClass } = styles;
const UPDATE_BUTTON_DESCRIPTION = { text: "Edit Listings", to: "listings" };
const LISTINGS_LIST_SIZE = 10;

const defaultParams = {
  projection: "summary",
  activeOnly: "true",
  size: LISTINGS_LIST_SIZE
};

const ListingInfoCell = ({ listing }) => {
  const cellRef = useRef();
  return (
    <div ref={cellRef} className="d-flex flex-column flex-sm-row align-items-center">
      <div>
        <LastSyncedInfo listing={listing} tooltipContainer={cellRef} isSyncModeOn={listing.syncMode === "READ_WRITE"} />
      </div>
      {listing.status === "ERROR" && (
        <LinkButton className={buttonClass} to={`listings/${listing.customerId}/${listing.id}`}>
          Learn more
        </LinkButton>
      )}
    </div>
  );
};

const tableSchema = [
  {
    label: "Listing Name",
    valueKey: "businessName"
  },
  {
    label: "Status/Last Syncing",
    renderCell: listing => <ListingInfoCell listing={listing} />
  }
];

export const useListings = ({ params } = { params: defaultParams }) => {
  const path = "listings";
  const [get] = useGet(path, { params });
  return useQuery([path, params], () => get().then(defaultNormalizeResponse));
};

export const ListingsWidget = memo(({ listings, totalElements, isLoading }) => {
  return (
    <WidgetCard heading="Listing Status">
      <WWTableBlock
        schema={tableSchema}
        data={listings}
        isLoading={isLoading}
        tableContainerClassnames={tableContainerClass}
        tableBodyClassnames={tableInnerContainerClass}
      >
        {!isLoading && (totalElements || 0) > LISTINGS_LIST_SIZE && (
          <div className={seAllButtonContainer}>
            <LinkButton color="primary" to="listings">
              See All
            </LinkButton>
          </div>
        )}
      </WWTableBlock>
      <ImproveBanner
        heading="Stay Up-to-Date"
        text="If there have been any changes to your business, update your listings to ensure the correct information is reflected online."
        updateButton={UPDATE_BUTTON_DESCRIPTION}
      />
    </WidgetCard>
  );
});

const ListingsWidgetWithQuery = () => {
  const { data, isLoading } = useListings();
  return (
    <ListingsWidget
      listings={get(data, "data.widewailListingSummaryDtoes", [])}
      totalElements={get(data, "pageInfo.totalElements")}
      isLoading={isLoading}
    />
  );
};

export default () => (
  <ActiveProductsRequireToRender products={["LISTINGS_MANAGEMENT"]}>
    <AuthorizationRequiredToRender roles={[permissions.LISTINGS_MANAGE]}>
      <ListingsWidgetWithQuery />
    </AuthorizationRequiredToRender>
  </ActiveProductsRequireToRender>
);
