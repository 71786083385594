import { pathOr } from "ramda";
import { useCallback } from "react";
import { Button, Form, FormFeedback } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import HorizontalSelectField from "../../../components/Form/HorizontalSelectField";
import CopyButton from "../../../components/Buttons/CopyButton";
import { bySource } from "../../../data/sites";
import { useStateThroughPipe } from "../../../hooks/utilHooks";
import { defaultPrevented } from "../../Reports/util";

const { source, customerField, label } = bySource("SALESFORCE");

export default class SalesforceAdminLinking {
  field() {
    return customerField;
  }

  add() {
    return SalesforceLinkAdd;
  }

  edit() {
    return SalesforceLinkEdit;
  }
}

const salesforceTypeOptions = [
  { value: "TEAM_MARINE", label: "Team Marine" },
  { value: "GREENSPARK_SOLAR", label: "Greenspark Solar" },
  { value: "INTEMPUS_REALTY", label: "Intempus Realty" },
  { value: "SALESFORCE_GENERAL", label: "Salesforce General" },
  { value: "EMPIRE_COMMUNITIES_OF_ATLANTA", label: "Empire Communities Of Atlanta" },
  { value: "EMPIRE_COMMUNITIES_PROSPECT", label: "Empire Communities (Prospect)" },
  { value: "SIMPLE_LOGGER", label: "Log Request Payload" }
];

const SalesforceLinkEdit = ({ info }) => (
  <FormField inline={false}>
    <CopyButton text={info.handlerUrl}>{info.handlerUrl}</CopyButton>
    <small className="text-muted d-block">
      You should use this token to set up integration on the Salesforce side.
    </small>
  </FormField>
);

const SalesforceLinkAdd = ({ onSave }) => {
  const [type, setType] = useStateThroughPipe(
    salesforceTypeOptions[0],
    pathOr(salesforceTypeOptions[0], ["target", "value"])
  );
  const onSubmit = useCallback(
    defaultPrevented(() =>
      onSave({
        type: type.value,
        source
      })
    ),
    [type]
  );
  return (
    <Form onSubmit={onSubmit}>
      <HorizontalSelectField
        inline={false}
        label={`${label} integration type`}
        value={type}
        simpleValue={false}
        isMulti={false}
        options={salesforceTypeOptions}
        disabled={salesforceTypeOptions?.length <= 1}
        onChange={setType}
      />
      <Button type="submit" color="primary">
        Link
      </Button>
    </Form>
  );
};
