import TableSortDropdown from "components/Table/TableSortDropdown/TableSortDropdown";
import TableSortToggle from "components/Table/TableSortToggle/TableSortToggle";
import { useFilterValueState } from "hooks/filteringHooks";

export const FilterTableSortFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <FilterTableSort key={props.name} className={className} {...props} />
  );
  const chip = ({ ...props }, className) => <></>;
  const filterComponent = ({ ...props }, className) => (
    <FilterTableSort key={props.name} {...props} className={className} />
  );
  return {
    barComponent,
    chip,
    filterComponent
  };
};

const FilterTableSort = ({ label, options = [], sortParamName = "sort" }) => {
  const [value, setter] = useFilterValueState(sortParamName);
  if (options.length === 2) {
    return <TableSortToggle name={label} value={value} toggleOptions={options} sortSetter={setter} />;
  } else if (options.length > 2) {
    return <TableSortDropdown name={label} value={value} sortSetter={setter} options={options} />;
  } else {
    return null;
  }
};

export default FilterTableSort;
