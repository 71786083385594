import React, { useState } from "react";
import classnames from "classnames";
import { isEmpty, groupBy } from "lodash";
import { Card } from "components/Card";
import Time from "components/DateTime/Time";
import { createSiteLogoUrl } from "util/siteUtils";
import { apiFieldNameForDisplay } from "util/stringUtils";
import { UncontrolledTooltip } from "reactstrap";
import { useListingLog } from "hooks/data/listingsDataHooks";
import WWButton from "components/Buttons/WWButton";
import AjaxLoader from "components/Misc/AjaxLoader";

import styles from "./ListingsEdit.module.scss";

const { cardsClass, dateTimeClass, syncLogClass, syncLogItemClass, syncLogContentClass } = styles;
const ICON_WIDTH = 24;

const UpdateLog = ({ listingId, customerId }) => {
  const [page, setPage] = useState(0);
  const [updateLog, setUpdateLog] = useState([]);
  const listingLogQuery = useListingLog({
    listingId,
    customerId,
    params: { page },
    onSuccess: data =>
      setUpdateLog(prev =>
        data?._embedded?.listingUpdateLogs ? [...prev, ...data?._embedded?.listingUpdateLogs] : prev
      )
  });
  const log = updateLog && (Array.isArray(updateLog) ? updateLog : [updateLog]);
  const fullDateTimeClass = classnames(dateTimeClass, "text-muted f-2 lh-1");
  const isSyncFix = lastModifiedBy =>
    lastModifiedBy === "REMOTE" || lastModifiedBy === "SYSTEM" || lastModifiedBy === "system";
  return (
    <Card className={classnames("p-3", cardsClass, syncLogClass)}>
      <h5>Sync Log</h5>
      <div className={syncLogContentClass}>
        {isEmpty(log) ? (
          <span className="text-muted">Sync log is empty.</span>
        ) : (
          log.map(logItems => {
            const DateTime = logItems.createdOn;
            return (
              <div className={syncLogItemClass}>
                {DateTime ? (
                  <Time className={fullDateTimeClass} date={DateTime} format="dateSlashTime" withIcon={false} />
                ) : (
                  <span className={fullDateTimeClass}>Unknown time</span>
                )}
                <div className="f-2 text-sm-start">
                  {logItems.entries.map(({ fieldName, source, lastModifiedBy, id }) => (
                    <div key={id} className="d-flex align-items-center">
                      <img
                        src={createSiteLogoUrl(logItems.directory)}
                        alt={logItems.directory}
                        width={ICON_WIDTH}
                        className="me-1"
                      />
                      {apiFieldNameForDisplay(fieldName)}
                      <i
                        id={`entry${id}`}
                        className={classnames("ms-2", {
                          "bg-image-sync-fix-icon px-3": isSyncFix(lastModifiedBy),
                          "bg-image-sync-update-icon px-3": /^\S+@\S+\.\S+$/.test(lastModifiedBy)
                        })}
                      />
                      <UncontrolledTooltip target={`entry${id}`}>
                        {isSyncFix(lastModifiedBy)
                          ? "Fixing an inconsistency found by Widewail"
                          : "Pushing an update made by your team"}
                      </UncontrolledTooltip>
                    </div>
                  ))}
                </div>
              </div>
            );
          })
        )}
        <div className="d-flex justify-content-center align-items-center">
          {listingLogQuery.isLoading ? (
            <AjaxLoader />
          ) : (
            <>
              {listingLogQuery?.data?.page?.totalPages > listingLogQuery?.data?.page?.number + 1 && (
                <WWButton color="link" onClick={() => setPage(prev => prev + 1)}>
                  Load More
                </WWButton>
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default UpdateLog;
