import { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { noop } from "lodash";
import classNames from "classnames";
import { useSwipeable } from "react-swipeable";
import useMeasure from "react-use-measure";
import Skeleton from "components/Skeleton";

import styles from "./Carousel.module.scss";

const { widgetItemsClass, rightArrow, leftArrow, itemsContainerClass, skeletonItemClass } = styles;

export const Carousel = ({
  items,
  itemWidth,
  itemHeight,
  maxDisplayedItems = 1,
  renderItem,
  renderEmptyBanner,
  isLoading,
  totalLength = 0,
  loadNextPage = noop,
  className,
  itemsContainerClassName,
  children
}) => {
  // itemsOffset - value for calculation of left margin, startItem - first rendered item.
  // we use both values for shift items to left or right so that several reviews (maxDisplayedItems or 2 * maxDisplayedItems)
  // will be rendered but hidden beyond the "window" on each side
  const [itemsOffset, setItemsOffset] = useState(0);
  const [startItem, setStartItem] = useState(0);
  const [withTransition, setWithTransition] = useState(false);

  const containerRef = useRef();
  const nextShift = useRef(0);
  const [resizeRef, { width }] = useMeasure();

  const getVisibleCards = () => {
    return process.env.NODE_ENV === "test"
      ? maxDisplayedItems
      : Math.round((containerRef.current?.clientWidth || 0) / itemWidth);
  };

  const loadNextPageIfNeeded = () => {
    const firstIndex = startItem + itemsOffset;
    const lastIndex = firstIndex + visibleCards;
    if (lastIndex > items.length && lastIndex < totalLength - 1) {
      loadNextPage();
    }
  };

  const [visibleCards, setVisibleCards] = useState(0);

  useEffect(() => {
    setVisibleCards(getVisibleCards());
  }, []);

  useEffect(() => {
    loadNextPageIfNeeded();
  }, [visibleCards]);

  useEffect(() => {
    const nextVisibleCards = getVisibleCards();
    if (nextVisibleCards !== visibleCards) {
      setVisibleCards(nextVisibleCards);
    }
  }, [width, visibleCards]);

  const onTransitionEnd = () => {
    setWithTransition(false);
    let shift = nextShift.current;
    if (shift < 0) {
      shift = Math.max(shift, -startItem);
    } else {
      shift = Math.min(shift, totalLength - 1 - startItem);
    }
    nextShift.current = 0;
    loadNextPageIfNeeded();
    if (shift) {
      setItemsOffset(prev => {
        return prev - shift;
      });
      setStartItem(prev => {
        return prev + shift;
      });
    }
  };

  const fullOffset = itemsOffset + startItem;

  const onShiftBack = useCallback(() => {
    const cardsToShift = Math.min(visibleCards, fullOffset);
    if (fullOffset - cardsToShift + nextShift.current < startItem) {
      return;
    }
    // if after margin change less than maxDisplayedItems will be left after the right border (rendered but hidden),
    // change index of first rendered item after the margin transition's end
    if (itemsOffset - cardsToShift + nextShift.current < maxDisplayedItems && startItem + nextShift.current > 0) {
      nextShift.current -= cardsToShift;
    }
    setWithTransition(true);
    setItemsOffset(prev => prev - cardsToShift);
  }, [visibleCards, itemsOffset, startItem, nextShift.current]);

  const onShiftAhead = useCallback(() => {
    const cardsToShift = Math.min(visibleCards, totalLength - (fullOffset + visibleCards));
    const plannedOffset = fullOffset + nextShift.current + cardsToShift;
    // items.length + 1 is used below to let items move for one extra step and start loading the next page
    if (plannedOffset === 0 || plannedOffset >= Math.min(startItem + 4 * maxDisplayedItems, items.length + 1)) {
      return;
    }
    // if after margin change less than 2 * maxDisplayedItems will be before left border (rendered but hidden),
    // change index of first rendered item after the margin transition's end
    if (itemsOffset + cardsToShift + nextShift.current > 2 * maxDisplayedItems) {
      nextShift.current += cardsToShift;
    }
    setWithTransition(true);
    setItemsOffset(prev => prev + cardsToShift);
  }, [visibleCards, itemsOffset, startItem, nextShift.current, items, fullOffset]);

  const handlers = useSwipeable({
    onSwipedLeft: onShiftAhead,
    onSwipedRight: onShiftBack
  });

  const reviewsWindowStyle = useMemo(
    () => ({
      transform: `translateX(${-itemsOffset * itemWidth}px)`,
      transition: withTransition ? "1.5s ease transform" : "none"
    }),
    [itemsOffset, withTransition]
  );

  return (
    <div className={className} {...handlers}>
      {!isLoading && !totalLength ? (
        renderEmptyBanner && renderEmptyBanner()
      ) : (
        <>
          {fullOffset > 0 && (
            <div onClick={isLoading ? undefined : onShiftBack} className={leftArrow} role="button">
              <i className="fa fa-chevron-left" />
            </div>
          )}
          <div ref={resizeRef}>
            <div className={classNames(itemsContainerClass, itemsContainerClassName)} ref={containerRef}>
              {isLoading ? (
                new Array(maxDisplayedItems)
                  .fill(null)
                  .map((item, index) => (
                    <Skeleton
                      containerClassName={skeletonItemClass}
                      key={index}
                      width={itemWidth}
                      height={itemHeight || "100%"}
                      className={skeletonItemClass}
                    />
                  ))
              ) : (
                <div style={reviewsWindowStyle} onTransitionEnd={onTransitionEnd} data-testid="test-container-id">
                  {items.slice(startItem, startItem + maxDisplayedItems * 4).map(item => (
                    <div className={widgetItemsClass} key={item.id}>
                      {renderItem && renderItem(item)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {fullOffset + visibleCards < totalLength && (
            <div onClick={isLoading ? undefined : onShiftAhead} className={rightArrow} role="button">
              <i className="fa fa-chevron-right" />
            </div>
          )}
        </>
      )}
      {children}
    </div>
  );
};

export default Carousel;
