import React from "react";
import AjaxLoader from "./AjaxLoader";
import { Container } from "reactstrap";
import Logo from "../Branding/Logo";

const LoadingPage = () => (
  <Container fluid className="mt-4">
    <div className="text-center">
      <Logo />
      <AjaxLoader />
    </div>
  </Container>
);

export default LoadingPage;
