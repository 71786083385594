import { get, isEmpty, isNil, isUndefined } from "lodash";
import classnames from "classnames";
import pluralize from "pluralize";
import { WWTable, WWTBody, WWTH, WWTD, WWTHead, WWTR, TableCardHeader } from "components/Table/WWTable";
import TableSortToggle from "components/Table/TableSortToggle/TableSortToggle";
import WWPagination from "components/Paging/WWPagination/WWPagination";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import { roundToOne } from "util/roundToOne";

import style from "./WWTableBlock.module.scss";
import TableSortDropdown from "./TableSortDropdown/TableSortDropdown";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import WWButton from "components/Buttons/WWButton";
import { useEffect, useMemo, useState } from "react";
import Checkbox from "components/Checkbox/Checkbox";
import { EditableList, useDragStart } from "components/EditableList/EditableList";
import { WWInfoTip } from "components/WWTooltip/WWTooltip";

export const DIFF_DIRECTION = {
  down: "diffDirectionDown",
  up: "diffDirectionUp"
};

export const defaultRenderCell = (row, { valueKey } = {}) => (valueKey ? get(row, valueKey, "") : "");
export const getColType = col => (typeof col?.type === "object" ? col?.type : WWTableBlock.cellTypes[col?.type]);
export const renderDiffCell =
  (prefix, suffix = "Changed") =>
  row => <ValueWithDiff value={row[prefix]} diff={row[`${prefix}${suffix}`]} />;

export const ValueWithDiff = ({
  value,
  diff,
  type = WWTableBlock.cellTypes.number,
  diffDirection = DIFF_DIRECTION.up
}) => {
  const displayMod = !isNil(diff) && diff >= 0 ? "+" : "",
    displayVal = isNil(diff) || isNaN(diff) ? "N/A" : type.renderValue(diff),
    diffIsPositive = diff > 0,
    diffIsNegative = diff < 0,
    diffClasses = {
      "text-success":
        (diffDirection === DIFF_DIRECTION.up && diffIsPositive) ||
        (diffDirection === DIFF_DIRECTION.down && diffIsNegative),
      "text-danger":
        (diffDirection === DIFF_DIRECTION.up && diffIsNegative) ||
        (diffDirection === DIFF_DIRECTION.down && diffIsPositive)
    };

  return (
    <span>
      <span>{isNil(value) || isNaN(value) ? "N/A" : type.renderValue(value)}</span>
      {!isNil(diff) ? (
        <small className={classnames("ms-1", "fs-1", diffClasses)}>
          ({displayMod}
          {displayVal})
        </small>
      ) : (
        <small> </small> // left blank to allow for alignment via flex styles
      )}
    </span>
  );
};

export const SchemaEditorItem = ({ item, updateItem, index, onDragStart }) => {
  const binder = useDragStart();

  return (
    <div data-column-index={index} className={style.schemaColumn}>
      <span onClick={() => updateItem({ hidden: !item.hidden })}>
        <Checkbox checked={!!!item.hidden} /> {item.label}
      </span>
      <span className={style.handle} {...binder(onDragStart)}>
        <i className="fa fa-bars" />
      </span>
    </div>
  );
};

export const SchemaEditor = ({ schema, setSchema, onClose }) => {
  const [columns, setColumns] = useState(schema);
  const [showAll, setShowAll] = useState();

  const updateColumn = index => col => {
    const newCols = [...columns];
    newCols[index] = { ...newCols[index], ...col };
    setColumns(newCols);
  };

  const toggleAll = () => setShowAll(!showAll);

  useEffect(() => {
    setColumns([...columns].map(c => ({ ...c, hidden: showAll })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  useEffect(() => {
    setColumns(schema);
  }, [schema]);

  return (
    <Modal isOpen={true}>
      <ModalHeader>Edit Columns</ModalHeader>
      <ModalBody>
        <div className={style.schemaColumnWrapper}>
          <EditableList data={columns} onChange={setColumns}>
            {(onDragStart, item, index) => (
              <SchemaEditorItem item={item} onDragStart={onDragStart} updateItem={updateColumn(index)} />
            )}
          </EditableList>
        </div>
      </ModalBody>
      <ModalFooter>
        <WWButton transparent onClick={toggleAll}>
          {showAll ? "Show" : "Hide"} All
        </WWButton>
        <WWButton onClick={onClose} color="light">
          Cancel
        </WWButton>
        <WWButton
          color="primary"
          onClick={() => {
            setSchema(columns);
            onClose();
          }}
        >
          Save
        </WWButton>
      </ModalFooter>
    </Modal>
  );
};

/* 

Example table schema

const schema = [
  {
    label: "Location",
    sortOptions: ["siteInfoRating.companyName,desc", "siteInfoRating.companyName,asc"],
    renderCell: site => (
      <>
        <div>{site.siteName || site.locationName}</div>
        <span className="fs-1 text-uppercase text-muted">{`${site.source}${
          !site.locationName ? "" : ` - ${site.locationName}`
        }`}</span>
      </>
    )
  },
  {
    label: "Rating",
    hidden: false,
    valueKey: "rating",
    fallback: "No Rating",
    type: WWTableBlock.cellTypes.diff,
    sortOptions: ["siteInfoRating.rating,desc", "siteInfoRating.rating,asc"]
  },
  {
    label: "Volume",
    valueKey: "volume",
    type: WWTableBlock.cellTypes.diff,
    sortOptions: ["siteInfoRating.volume,desc", "siteInfoRating.volume,asc"]
  },
  {
    label: "Totals"
    valueKey: "total",
    type: WWTableBlock.cellTypes.number,
    sortOptions: ["siteInfoRating.total,desc", "siteInfoRating.total,asc"]
  }
];

 */

const isCellBlank = renderedCell => {
  return isUndefined(renderedCell) || renderedCell === "" || (Array.isArray(renderedCell) && renderedCell.length === 0);
};

export default function WWTableBlock({
  isLoading, // should render the loading skeleton or not
  sort, // the current sort value
  setSort = () => {}, // function to set the sort
  schema = [], // {valueKey, label, sortOptions = [], renderCell = () => {}}
  setSchema, // function, if present enables column hide & sort
  data = [], // array of data that renders as the rows of the table
  pageNumber = 0, // current page number
  totalPages = 0, // total number of pages in the data set
  setPageNumber = () => {}, // function to set page number to show
  pageSize = 25, // used to show rows in table skeleton
  showPageNumbers = true,
  heightReduce = "0px",
  compact = false,
  tableContainerClassnames = "mt-3 mb-3",
  tableBodyClassnames,
  children,
  title,
  controls,
  subHead,
  ...props
}) {
  const id = useMemo(
    () => props.id || crypto.randomUUID(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  ); // create/use a unique id for use with keys and tooltips, etc
  const columns = schema.filter(({ hidden } = {}) => !hidden);
  const [showEditor, setShowEditor] = useState();
  const keyer = (idx, ...args) => ["table", id, idx, ...args].join("-");

  const rowMapper = (row = {}, i) => (
    <WWTR noHover key={keyer(i, "row")}>
      {columns?.map((column, ii) => {
        const fallback = column?.fallback || props?.fallback,
          type = getColType(column),
          renderCell = column?.renderCell || type?.renderCell || defaultRenderCell,
          renderedCell = renderCell(row, column, fallback),
          cellContent = isCellBlank(renderedCell) ? column?.fallback || fallback : renderedCell;

        return (
          <WWTD
            className={classnames(column?.className, column?.valueKey, style[type?.className], { "py-0": compact })}
            key={keyer(i, "row", ii, "cell")}
          >
            {cellContent}
          </WWTD>
        );
      })}
    </WWTR>
  );

  const headerMapper = (column = {}, i) => {
    let { label, sortOptions = [], headerClassName, tooltip } = column,
      ret = label;

    const type = getColType(column);

    if (sortOptions?.length === 2) {
      ret = <TableSortToggle name={label} value={sort} toggleOptions={sortOptions} sortSetter={setSort} />;
    } else if (sortOptions.length > 2) {
      ret = <TableSortDropdown name={label} value={sort} sortSetter={setSort} options={sortOptions} />;
    }

    return (
      <WWTH
        key={keyer(i, "header")}
        className={classnames(headerClassName, style[type?.className], { "h-100 py-1": compact })}
      >
        {ret} <WWInfoTip target={keyer(i, "header", "tooltip")}>{tooltip}</WWInfoTip>
      </WWTH>
    );
  };

  return (
    <div className="flex-grow-1">
      <div className={tableContainerClassnames}>
        {(title || controls) && (
          <TableCardHeader className={style.header}>
            <strong>{title}</strong>
            <div>{controls && controls({ setShowEditor })}</div>
          </TableCardHeader>
        )}
        {subHead}
        {!isLoading && isEmpty(data) ? (
          <p className="p-4 mx-5 border rounded">No data at this time.</p>
        ) : (
          <WWTable tableContainerClassnames={tableBodyClassnames} hightReduce={heightReduce} actionElements={children}>
            {!isLoading && (
              <WWTHead className={classnames({ "h-100": compact })}>
                <WWTR>{columns?.map(headerMapper)}</WWTR>
              </WWTHead>
            )}
            {!isLoading ? (
              <WWTBody>{data?.map(rowMapper)}</WWTBody>
            ) : (
              <TableBodySkeleton cols={schema.length} rows={pageSize} />
            )}
          </WWTable>
        )}
      </div>
      <br />
      {totalPages > 1 && (
        <WWPagination
          loading={isLoading}
          pageNumber={pageNumber + 1}
          pages={totalPages}
          setPageNumber={setPageNumber}
          infinitePagination={!showPageNumbers}
        />
      )}
      <br />
      {showEditor && setSchema && (
        <SchemaEditor schema={schema} setSchema={setSchema} onClose={() => setShowEditor(false)} />
      )}
    </div>
  );
}

// Canned types for sharing styles and formatting
WWTableBlock.cellTypes = {
  number: {
    className: "number",
    renderValue: v => (isNil(v) || isNaN(v) ? "N/A" : roundToOne(v).toLocaleString()),
    renderCell: (row, col) => WWTableBlock.cellTypes.number.renderValue(row[col.valueKey])
  },
  days: {
    className: "days",
    renderValue: v => (isNil(v) || isNaN(v) ? "N/A" : `${roundToOne(v)} ${pluralize("Days", v)}`),
    renderCell: (row, col) => WWTableBlock.cellTypes.days.renderValue(row[col.valueKey])
  },
  percent: {
    className: "percent",
    renderValue: v => (isNil(v) || isNaN(v) ? "N/A" : roundToOne(v * 100) + "%"),
    renderCell: (row, col) => WWTableBlock.cellTypes.percent.renderValue(row[col.valueKey])
  },
  diff: {
    className: "diff",
    renderValue: (v, type = WWTableBlock.cellTypes.number) => type.renderValue(v),
    renderCell: (row, col) => renderDiffCell(col.valueKey, col.diffKey)(row)
  }
};
