import React from "react";
import classnames from "classnames";
import WWButton from "../../Buttons/WWButton";

import styles from "./ReplyActionBanner.module.scss";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import { noop } from "lodash";

const { actionsContainer } = styles;

const bannerTextForStatus = {
  APPROVED: "Approved response",
  APPROVAL: "Waiting for approval",
  DRAFT: "Unpublished response"
};

export const ReplyActionBanner = ({ reply, onClick = noop, showRemovedBanner = true, showPublishNow = true }) => {
  switch (reply.status) {
    case "APPROVAL":
    case "APPROVED":
    case "DRAFT":
      return !reply.nextRetryOn && reply.source === "WIDEWAIL" ? (
        <div className={classnames("bg-danger", actionsContainer)}>
          <span className="flex-grow-1">{bannerTextForStatus[reply.status]}</span>
          {showPublishNow && (
            <AuthorizationRequiredToRender roles={[permissions.REVIEW_REPLY]}>
              <WWButton
                iconClass="fa fa-upload"
                className="btn-light"
                tooltip="Publish reply"
                onClick={onClick}
                disableDuringClick
              >
                Publish Now
              </WWButton>
            </AuthorizationRequiredToRender>
          )}
        </div>
      ) : null;
    case "REMOVED":
      return showRemovedBanner ? (
        <div className={classnames("bg-warning", actionsContainer)}>
          <span className="text-dark">REMOVED RESPONSE</span>
        </div>
      ) : null;
    default:
      return null;
  }
};

export default ReplyActionBanner;
