import classNames from "classnames";
import WWButton from "components/Buttons/WWButton";

import styles from "./MultiField.module.scss";

const defaultRenderItem = ({ item }) => JSON.stringify(item);

// This default version is controlled and does not have built in state management
export function WWSimpleMultiField({
  className,
  items = [],
  headings,
  itemLabel,
  renderItem = defaultRenderItem,
  onAddItem = () => {},
  onRemoveItem = () => {}
}) {
  return (
    <div className={classNames("ww-multi-field", className)}>
      {items.length > 0 && (
        <div className={styles.wwMultiFieldHeadings}>
          {headings.map((h, i) => (
            <div key={i} className={styles.wwMultiFieldHeading}>
              {h}
            </div>
          ))}
          {typeof onRemoveItem === "function" && <div className={styles.wwMultiFieldRemove} />}
        </div>
      )}
      <div className="ww-multi-field-items">
        {items.map((item, index) =>
          item ? (
            <div className={styles.wwMultiFieldItem} key={index}>
              <span>{renderItem(item, index)}</span>
              {typeof onRemoveItem === "function" && (
                <div className={styles.wwMultiFieldRemove}>
                  <WWButton
                    color="light"
                    iconClass="fa fa-trash"
                    className={styles.wwMultiFieldRemove}
                    onClick={() => onRemoveItem(index, item)}
                  />
                </div>
              )}
            </div>
          ) : null
        )}
      </div>
      <div className={styles.wwMultiFieldControls}>
        <WWButton className="pe-0" iconClass="fa fa-plus" color="link" onClick={onAddItem}>
          Add {itemLabel}
        </WWButton>
      </div>
    </div>
  );
}

export default WWSimpleMultiField;
