import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import FilterTableSort from "components/FacetedSearch/Sort/FilterTableSort/FilterTableSort";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import { TableCard, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import ListingsTableRow from "./ListingsTableRow/ListngTableRow";
import useFilterQuery from "hooks/data/useFilterQuery";
import { showSkeleton } from "util/showSkeleton";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

const ListingsList = () => {
  const filters = useMemo(
    () => [
      getAccountFilterConfig(),
      {
        name: "location",
        label: "Locations",
        type: "allLocations",
        primaryBar: {
          position: "LEFT"
        },
        chip: {
          position: "NONE"
        }
      },
      {
        name: "status",
        label: "Status",
        type: "checkbox",
        options: [
          { label: "OK", value: "OK" },
          { label: "Processing", value: "PROCESSING" },
          { label: "Error", value: "ERROR" }
        ],
        primaryBar: {
          position: "RIGHT",
          className: "d-none d-md-flex"
        },
        secondaryBar: {
          className: "d-flex d-md-none"
        },
        chip: {
          position: "NONE"
        }
      },
      {
        name: "page",
        defaultValue: 0,
        required: true,
        nonFilter: true,
        offCanvas: {
          position: "NONE"
        },
        chip: {
          position: "NONE"
        }
      },
      {
        name: "sort",
        nonFilter: true,
        offCanvas: {
          position: "NONE"
        },
        chip: {
          position: "NONE"
        }
      }
    ],
    []
  );

  const queryClient = useQueryClient();
  const { data, isLoading, isFetched } = useFilterQuery({
    filters,
    additionalParams: { activeOnly: true },
    url: `/listings`,
    projection: "summary"
  });
  const refresh = useCallback(() => queryClient.invalidateQueries({ queryKey: ["/listings"] }), [queryClient]);

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <FacetedSearch filters={filters} />
      <TableCard data-cy="tags-report-table" className="border-top-0 pt-0">
        <WWTable loaded={!isLoading}>
          <WWTHead>
            <WWTR>
              <WWTH>
                <FilterTableSort label="Listing Name" options={["businessName,asc", "businessName,desc"]} />
              </WWTH>
              <WWTH>
                <FilterTableSort label="Last Synced" options={["lastUpdateOn,asc", "lastUpdateOn,desc"]} />
              </WWTH>
              <WWTH>
                <FilterTableSort label="Last Update Made" options={["lastUpdate,asc", "lastUpdate,desc"]} />
              </WWTH>
              <WWTH>Syncing</WWTH>
              <WWTH />
            </WWTR>
          </WWTHead>
          {showSkeleton({ isLoading, isFetched }) ? (
            <TableBodySkeleton rows={8} cols={5} />
          ) : (
            <WWTBody>
              {!data?.data?.widewailListingSummaryDtoes ? (
                <WWTR>
                  <WWTD className="text-muted text-center p-3" colSpan={5}>
                    No data
                  </WWTD>
                </WWTR>
              ) : (
                data.data?.widewailListingSummaryDtoes?.map(listing => (
                  <ListingsTableRow key={listing.id} listing={listing} refresh={refresh} />
                ))
              )}
            </WWTBody>
          )}
        </WWTable>
      </TableCard>
      <FilterPagination
        isLoading={showSkeleton({ isLoading, isFetched })}
        pageInfo={data?.pageInfo}
        totalPages={data?.pageInfo?.totalPages}
        elementsLabel="Listings"
      />
    </div>
  );
};

export default ListingsList;
