import React, { useState, useEffect } from "react";
import { AsyncTypeahead as AsncTphd, Typeahead as Tphd, ClearButton } from "react-bootstrap-typeahead";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { useContacts } from "hooks/data/useContact";
import { CONTACT_TYPE_WIDEWAIL_USER } from "util/constants";
import { useErrorNotification } from "components/Notifications/notification";
import "./Typeahead.scss";
import styles from "./Typeahead.module.scss";

const { typeaheadDropdownClass, activeDropdownClass } = styles;

/**
 * @param {import("react-bootstrap-typeahead").TypeaheadProps} param0
 */
export const Typeahead = ({ ...props }) => <Tphd {...props} />;

/**
 * @param {import("react-bootstrap-typeahead").AsyncTypeaheadProps} param0
 */
export const AsyncTypeahead = ({ ...props }) => <AsncTphd {...props} />;

/**
 * Generic Search-based Typeahead. Replaces the above generics
 *
 * @param {string} [ name ] - The name of the field.
 * @param {function} [ useSearch ] - Custom hook to run search and side effect.
 * @param {string} [ id ] - DOM id, required by AsyncTypeahead
 * @param {string} [ labelKey ] - Which key in the data responses should be used
 * to display a value in the dropdown
 * @param {string} [ className ] - DOM className
 * @param {boolean} [ clearButton ] - show the 'X' button in the field to clear result's
 * @param {boolean} [ useCache ] - flag to use the built in caching or not
 * @param {boolean} [ showErrors ] - show errors in the error popup
 * @param {boolean} [ multiple ] - is this a multiselect?
 * @param {boolean} [ disabled ] - field is disabled
 *
 * useSearch should return something like this:
       {
         name, // name of the form field
         options, // available options from search results or []
         selected, // the currently selected value from options or []
         labelKey, // key in option to render inside input when selected
         renderer, // function for how to render an option in the dropdown
         onChange, // function to fire on select of an option from dropdown
         isLoading, // boolean is a request for results pending
       }
 *
 *
 */

export const WWTypeAhead = ({
  id,
  className,
  clearButton = true,
  dropdownToggle = false,
  showErrors = true,
  useCache = false,
  placeholder = "Search...",
  useSearch = () => {
    return {};
  },
  onChange = () => {},
  onInputChange = () => {},
  multiple,
  minLength = 0,
  positionFixed = true,
  disabled = false,
  allowNew = false,
  additionalParams,
  size,
  selectOnlyOption,
  defaultOpen,
  ...props
}) => {
  const [query, onSearch] = useState();
  const [firstLoadedOption, setFirstLoadedOption] = useState();
  const errorNotification = useErrorNotification(),
    {
      name,
      isLoading,
      isFetched,
      error,
      onChange: onSelect = () => {},
      selected = props.selected || [],
      options = props.options || [],
      labelKey,
      renderer
    } = useSearch(query, additionalParams);

  useEffect(() => {
    if (!query && isFetched && !firstLoadedOption) {
      setFirstLoadedOption(options);
    }
  }, [isFetched, query, firstLoadedOption, options]);

  if (showErrors && error) errorNotification(error);
  const hasSelected = !!selected?.length;

  // custom clear button allows for force clearing of the input field
  const customControls = args => {
    if (dropdownToggle && !(clearButton && hasSelected)) {
      return (
        <div
          onClick={e => args.toggleMenu(e)}
          className={classNames("rbt-aux", typeaheadDropdownClass, { [activeDropdownClass]: args.isMenuShown })}
        >
          <i className="fa fa-chevron-down" />
        </div>
      );
    }
    if (clearButton & hasSelected) {
      return (
        <div className="rbt-aux">
          <ClearButton
            onClick={() => {
              setTimeout(() => {
                args.onClear();
                onChange([]);
              }, 1);
            }}
            onFocus={e => e.stopPropagation()}
            onMouseDown={e => e.preventDefault()}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    if (selectOnlyOption) {
      const onlySelectedOption = selectOnlyOption && firstLoadedOption?.length === 1 && [firstLoadedOption[0]];
      if (onlySelectedOption) {
        onChange(onlySelectedOption);
      }
    }
  }, [selectOnlyOption, firstLoadedOption]);

  return (
    <AsyncTypeahead
      {...props}
      id={id || uniqueId(name)}
      size={size}
      useCache={useCache}
      multiple={multiple}
      renderMenuItemChildren={renderer}
      className={className}
      isLoading={isLoading}
      options={options}
      disabled={disabled}
      placeholder={placeholder}
      selected={selected}
      labelKey={labelKey}
      minLength={minLength}
      allowNew={allowNew}
      onInputChange={onInputChange}
      onChange={v => {
        onSelect(v);
        onChange(v);
        // forcing the query on select seems to solve the problem
        // of react-bootstrap-typeahead not always showing the correct
        // value in the input box after selection
        onSearch(v);
      }}
      onSearch={onSearch}
      positionFixed={positionFixed}
      // when there's a value selected in the typeahead and the user tries to
      // search for something new, ensure the old value is nulled
      onKeyDown={() => selected && onSelect(null)}
      defaultOpen={defaultOpen}
    >
      {customControls}
    </AsyncTypeahead>
  );
};

export default WWTypeAhead;

export const ContactTypeahead = props => {
  const { filterFn = x => x, type = CONTACT_TYPE_WIDEWAIL_USER } = props;

  const useSearch = q => {
    let { data, isLoading, ...query } = useContacts({ q, contactType: type });

    return {
      ...query,
      isLoading,
      options: (data?.[0] || []).filter(filterFn),
      labelKey: ({ name, title }) => [name, title].join(" - ")
    };
  };

  return <WWTypeAhead {...props} useSearch={useSearch} placeholder="Search Contacts..." />;
};
