import * as apiClient from "./apiClient";

const baseUri = "vinSolutions";

export function locations(filter) {
  return apiClient.get(baseUri + "/locations", { params: { filter } });
}

export function users(dealerId, filter) {
  return apiClient.get(baseUri + `/locations/${dealerId}/users`, { params: { filter } });
}
