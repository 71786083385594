import { cloneDeep, isEqual, isObject, omit, pick } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Collapse } from "reactstrap";
import { useQueryClient } from "react-query";
import memoize from "memoizee";
import * as api from "api/reviewApi";
import { exportReviewsCSV, loadLatestMessages, saveReview } from "api/reviewApi";
import useModalGotosite from "./use.modal.gotosite";
import useReplyPublisher from "./use.reply.publisher";
import useReplyRemover from "./use.reply.remover";
import useReplySaver from "./use.reply.saver";
import useReplyUnpublisher from "./use.reply.unpublisher";
import useReplyUpdater from "./use.reply.updater";
import useReviewRepliesLoader from "./use.review.replies.loader";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import MainBody from "components/FacetedLayout/MainBody/MainBody";
import AjaxLoader from "components/Misc/AjaxLoader";
import { errorCaughtNotifier, useLocalNotifications } from "components/Notifications/notification";
import { permissions } from "components/Auth/permissions";
import ReplyHistoryModal from "components/Reviews/Reply/ReplyHistoryModal";
import ActivityModal from "./ActivityModal";
import VirtualList from "./VirtualList";
import ReviewEntity from "./ReviewEntity";
import { VIEW_STATE_STORAGE_KEYS } from "data/storageKeys";
import { GROUPED_REPORT_REASONS_FIRST_ITEMS } from "data/reportReasons";
import { RATINGS_LABEL_PAIRS, REVIEW_TYPE_LABEL_VALUE, STATUS_LABEL_VALUES } from "data/options";
import { SITES } from "data/sites";
import { useFilterReduxKey, useFilterState } from "hooks/filteringHooks";
import { useURLQueryRedirect } from "hooks/urlHooks";
import useFilterQuery from "hooks/data/useFilterQuery";
import useReviews from "hooks/data/useReviews";
import { useCurrentAgency } from "hooks/agencyHooks";
import { usePersistantViewState, useStateThroughRedux } from "hooks/stateHooks";
import { useClipboardCopier, useWindowSize } from "hooks/utilHooks";
import { getEntityById } from "util/arrayUtils";
import { datetime2iso, endOfTheDay, now, startOfTheDay, weeksBeforeNow } from "util/dateUtils";
import { thirdpartyReviewUrl } from "util/siteUtils";
import { isCurrentUserInGroup } from "util/userUtils";
import { flattenReplies } from "util/reviewUtils";
import { customerFilterApiConverter } from "util/customerUtils";
import { getDefaultApproveFilters } from "util/getApprovalSearch";
import { LONG_NOTIFICATION_TIMEOUT } from "util/constants";

import styles from "./Feed.module.scss";
import { useCategoriesForCurrentUser, useTopicsForCurrentUser } from "../../../hooks/data/tagSetHooks";
import { getReviewSourceFilterConfig } from "components/FacetedSearch/FilterRecipes";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

export const renderSourceItem = source => {
  const descriptor = SITES.find(site => site.source === source);
  return !!descriptor ? (
    <div className="d-flex justify-content-start align-items-center flex-nowrap">
      <i className={`fa ${descriptor.icon} me-2`} />
      {descriptor.label}
    </div>
  ) : isObject(source) ? null : (
    source
  );
};

const { container, mainBodyContainerClass } = styles;

const isThisACustomCase = memoize(({ source }) =>
  ["DEALERRATER", "YELP", "APARTMENT_RATINGS", "CAR_GURUS", "OPEN_TABLE"].includes(source)
);

const replaceLegacyDatesAndLocationQuery = queryValue => {
  const newQueryValue = {
    ...omit(queryValue, ["dates", "customerId", "contactId", "brand", "filters"]),
    ...("dates" in queryValue
      ? { dateRange: { startDate: queryValue.dates?.[0], endDate: queryValue.dates?.[1] } }
      : {}),
    ...("customerId" in queryValue ? { location: { type: "CUSTOMER", id: queryValue.customerId } } : {}),
    ...("contactId" in queryValue ? { location: { type: "CONTACT", id: queryValue.contactId } } : {}),
    ...("brand" in queryValue ? { location: { type: "BRAND", brand: queryValue.brand } } : {})
  };
  return newQueryValue;
};

const defaultApproveFilters = getDefaultApproveFilters();

const ReviewFeed = ({ reviewsPageSize = 10 }) => {
  const [openedActivityModal, setOpenedActivityModal] = useStateThroughRedux([
    "reviews",
    "feedNonFilter",
    "messages_modal"
  ]);

  const [, windowHeight] = useWindowSize();
  const key = useFilterReduxKey();
  const [page, setPage] = useStateThroughRedux([key, "page"]);
  const resetPage = useCallback(() => setPage(0), [setPage]);
  const nextPage = useCallback(() => setPage(page => page + 1), [setPage]);
  const [reviews, setReviews] = useState([]);
  const [listSituation, setListSituation] = useState(0);
  const topicsQuery = useTopicsForCurrentUser();
  const topics = topicsQuery.data || [];
  const categoriesQuery = useCategoriesForCurrentUser();
  const categories = categoriesQuery.data || [];
  const [filterValues, setFilterValue] = useFilterState();
  const [reviewHistory, setReviewHistory] = useState();
  const [total, setTotal] = useState(0);
  const notify = useLocalNotifications();

  useURLQueryRedirect(["dates", "customerId", "contactId", "brand", "filters"], replaceLegacyDatesAndLocationQuery);

  const queryClient = useQueryClient();
  const invalidateReplies = useCallback(() => queryClient.invalidateQueries(["/reviews"]), [queryClient]);

  const backendPreparedFilters = useMemo(() => {
    const filters = filterValues || {};
    return filters
      ? {
          ...omit(filters, "dateRange", "location", "category"),
          ...("location" in filters ? customerFilterApiConverter(pick(filters, "location").location) : {}),
          ...("category" in filters ? { tags: filters.category } : {}),
          ...("dateRange" in filters
            ? {
                startDate: filters.dateRange.startDate,
                endDate: filters.dateRange.endDate
              }
            : {})
        }
      : {};
  }, [filterValues]);

  const onExport = useCallback(
    () =>
      exportReviewsCSV({
        feed: true,
        rs: true,
        projection: "reviewFeed",
        size: 1000,
        export: true,
        startDate: datetime2iso(startOfTheDay(weeksBeforeNow(2))),
        endDate: datetime2iso(endOfTheDay(now())),
        ...backendPreparedFilters
      })
        .then(v => window.open(v, "_blank"))
        .catch(errorCaughtNotifier(notify)),
    [backendPreparedFilters, notify]
  );

  const isApprovalFilterSelected = useMemo(
    () => isEqual(omit(filterValues, "page", "sort"), omit(defaultApproveFilters, "sort")),
    [filterValues]
  );

  const approvalReviews = useReviews({ status: "APPROVAL", size: 1 }, !isApprovalFilterSelected);

  const totalNumberOfApprovalReviews = useMemo(
    () =>
      (!approvalReviews?.isLoading && approvalReviews?.data?.[1]?.totalElements) ||
      (isApprovalFilterSelected && total) ||
      undefined,
    [approvalReviews, isApprovalFilterSelected, total]
  );

  const showApprovals = useCallback(() => {
    setFilterValue(defaultApproveFilters);
    return "";
  }, [setFilterValue]);

  const currentAgency = useCurrentAgency();

  const filters = useMemo(
    () => [
      {
        name: "dateRange",
        label: "Date range",
        type: "dates",
        queryParams: ["startDate", "endDate"],
        defaultValue: {
          startDate: datetime2iso(startOfTheDay(weeksBeforeNow(2))),
          endDate: datetime2iso(endOfTheDay(now()))
        },
        chip: {
          position: "NONE"
        },
        primaryBar: {
          position: "LEFT",
          className: "d-none d-md-flex"
        }
      },
      {
        name: "download",
        label: "Download",
        type: "button",
        onClick: onExport,
        className: "ww-font-xs",
        icon: "fa fa-download",
        transparent: true,
        disabled: reviews.length === 0,
        offCanvas: { position: "TOP" }
      },
      {
        name: "type",
        label: "Type",
        type: "checkbox",
        options: REVIEW_TYPE_LABEL_VALUE
      },
      getAccountFilterConfig(),
      {
        name: "location",
        label: "Locations",
        type: "allLocations",
        primaryBar: {
          position: "LEFT",
          className: "d-none d-md-flex"
        },
        chip: {
          position: "NONE"
        }
      },
      {
        name: "locationLabel",
        label: "Location Labels",
        type: "locationLabel",
        multiple: true
      },
      {
        name: "approval",
        label: `My Approvals`,
        type: "button",
        color: "primary",
        onClick: showApprovals,
        badgeContent: totalNumberOfApprovalReviews,
        primaryBar: {
          position: isCurrentUserInGroup(permissions.REVIEW_APPROVE) ? "RIGHT" : "NONE"
        },
        offCanvas: { position: "NONE" }
      },
      {
        name: "rating",
        label: "Rating",
        type: "checkbox",
        options: RATINGS_LABEL_PAIRS
      },
      {
        name: "tags",
        label: "Categories",
        type: "checkbox",
        options: categories
      },
      {
        name: "status",
        label: "Status",
        type: "checkbox",
        options: STATUS_LABEL_VALUES(currentAgency.servicerName)
      },
      {
        name: "reportedAs",
        label: "Reported As",
        type: "checkbox",
        options: GROUPED_REPORT_REASONS_FIRST_ITEMS
      },
      {
        name: "entity_TOPIC",
        type: "entity",
        label: "Topic",
        entityType: "TOPIC",
        options: topics
      },
      {
        name: "entity_PERSON",
        type: "entity",
        label: "Person",
        entityType: "PERSON"
      },
      getReviewSourceFilterConfig(),
      {
        name: "author",
        label: "Author",
        type: "text",
        icon: "fa fa-user"
      },
      {
        name: "representative",
        label: "Staff",
        type: "text",
        icon: "fa fa-user"
      },
      {
        name: "sort",
        label: "Review Date",
        type: "sort",
        defaultValue: "reviewDate,desc",
        required: true,
        options: ["reviewDate,desc", "reviewDate,asc"],
        nonFilter: true,
        primaryBar: {
          position: "RIGHT"
        },
        offCanvas: {
          position: "NONE"
        },
        chip: {
          position: "NONE"
        }
      }
    ],
    [topics, categories, reviews.length, onExport, showApprovals, currentAgency, totalNumberOfApprovalReviews]
  );

  const reviewItems = useMemo(
    () =>
      reviews.map(review => {
        const replies = flattenReplies(review.replies);
        const allRepliesRemoved = !!replies?.every(({ status }) => status === "REMOVED");
        return { ...review, replyCount: replies.length, allRepliesRemoved };
      }),
    [reviews]
  );

  const allDataLoaded = useMemo(() => reviews.length >= total, [reviews, total]);

  const [noMorePublishingAlert] = usePersistantViewState(
    VIEW_STATE_STORAGE_KEYS.MODAL_PREVENT_CUSTOM_SITE_PUBLISH,
    false
  );

  const loadReplies = useReviewRepliesLoader();
  const replyUnpublish = useReplyUnpublisher();
  const replySave = useReplySaver();
  const replyRemove = useReplyRemover();
  const confirmRedirect2Site = useModalGotosite();
  const copy2Clipboard = useClipboardCopier();
  const replyUpdate = useReplyUpdater();
  const replyPublish = useReplyPublisher();
  const [previousFilterValue, setPreviousFilterValue] = useState(filterValues);

  const { isLoading } = useFilterQuery(
    {
      filters,
      url: `/reviews`,
      projection: "reviewFeed",
      additionalParams: { latest: true, feed: true, rs: true, size: reviewsPageSize, includeProducts: true }
    },
    {
      onSuccess: data => {
        layoutEffectCb({
          reviews: (data?.data?.reviews || []).map(review => ({
            ...omit(review, "replySummary"),
            replies: review?.replySummary?.replies || [],
            count: review?.replySummary?.count || 0
          })),
          total: data?.pageInfo?.totalElements || 0
        });
      }
    }
  );

  useEffect(() => {
    if (!isEqual(omit(previousFilterValue, "page"), omit(filterValues, "page")) && !isLoading) {
      setTotal(0);
      resetPage();
      setPreviousFilterValue(filterValues);
    }
  }, [filterValues, previousFilterValue, isLoading, resetPage]);

  const layoutEffectCb = useCallback(
    v => {
      setReviews(prev => [
        ...(total > 0 ? prev.slice(0, page * reviewsPageSize) : []),
        ...v.reviews,
        ...(total > 0 ? prev.slice((page + 1) * reviewsPageSize) : [])
      ]);
      setTotal(v.total);
      const reviewsWithMessages = v.reviews && v.reviews.map(({ id }) => id);
      reviewsWithMessages?.length > 0 &&
        loadLatestMessages(reviewsWithMessages).then(result => {
          setReviews(prevReviewState =>
            prevReviewState.map(review => (result[review.id] ? { ...review, messages: result[review.id] } : review))
          );
        });
      //prevents calling next page immediately after prev call
      setListSituation(1);
    },
    [page, reviewsPageSize, total]
  );

  useEffect(() => {
    if (listSituation <= 0 && !allDataLoaded) {
      nextPage();
    }
  }, [listSituation, allDataLoaded]); //eslint-disable-line
  //prevents infinite loop

  const onLoadPrevReplies = useCallback(
    review => {
      const updater = positivePredictedReviewRepliesUpdater(review);
      return loadReplies(review.id)
        .then(replies => setReviews(updater(replies)))
        .catch(errorCaughtNotifier(notify));
    },
    [loadReplies, notify]
  );

  const onReplySaveFactory = useCallback(
    (review, parent) => {
      const { source, status } = review;

      const updateStatus = positivePredictedReviewStatusUpdater(review);

      return (publish, content) =>
        replySave(review, content, parent, publish)
          .then(() => {
            if (status === "UNREPLIED") {
              setReviews(updateStatus("REPLIED"));
            }
          })
          .then(() =>
            isThisACustomCase(review) && publish
              ? copy2Clipboard(content)
                  .then(() => noMorePublishingAlert || confirmRedirect2Site(source))
                  .then(v => {
                    if (v) {
                      window.open(thirdpartyReviewUrl(review, true));
                    }
                  })
              : Promise.resolve()
          )
          .then(() => onLoadPrevReplies(review))
          .catch(errorCaughtNotifier(notify));
    },
    [replySave, noMorePublishingAlert, copy2Clipboard, confirmRedirect2Site, notify, onLoadPrevReplies]
  );

  const loadHistory = useCallback(reply => {
    api.loadReplyHistory(reply.id).then(res => setReviewHistory(res.data));
  }, []);

  const onSentimentChange = useCallback(
    (reply, sentiment) =>
      replyUpdate({ ...reply, sentiment })
        .then(() => setReviews(positivePredictedReviewReplySentimentUpdater(reply.relatedReview)(reply)(sentiment)))
        .catch(errorCaughtNotifier(notify)),
    [notify, replyUpdate]
  );

  const onUpdateStatus = useCallback(
    (reply, status) =>
      replyUpdate({ ...reply, status })
        .then(invalidateReplies)
        .catch(errorCaughtNotifier(notify)),
    [notify, replyUpdate, invalidateReplies]
  );

  const onPublishReply = useCallback(
    (reply, review) =>
      replyPublish(reply.id)
        .then(() =>
          isThisACustomCase(review)
            ? copy2Clipboard(reply.content)
                .then(() => noMorePublishingAlert || confirmRedirect2Site(review.source))
                .then(v => {
                  if (v) {
                    window.open(thirdpartyReviewUrl(review, true));
                  }
                })
            : Promise.resolve()
        )
        .then(() =>
          setReviews(positivePredictedReviewReplyStatusUpdater(reply.relatedReview || review)(reply)("PUBLISHED"))
        )
        .catch(errorCaughtNotifier(notify)),
    [confirmRedirect2Site, copy2Clipboard, noMorePublishingAlert, notify, replyPublish]
  );

  const onReplyUnpublish = useCallback(
    (reply, review) =>
      replyUnpublish(reply.id)
        .then(() =>
          setReviews(positivePredictedReviewReplyStatusUpdater(reply.relatedReview || review)(reply)("DRAFT"))
        )
        .catch(errorCaughtNotifier(notify)),
    [replyUnpublish, notify]
  );

  const onReplyDelete = useCallback(
    (reply, review) =>
      replyRemove(reply.id)
        .then(() => {
          setReviews(positivePredictedReviewReplyRemover(reply.relatedReview || review)(reply));
        })
        .catch(errorCaughtNotifier(notify)),
    [replyRemove, notify]
  );

  const onReplyEdit = useCallback(
    (reply, review) => content =>
      replyUpdate({ ...reply, content })
        .then(() =>
          setReviews(positivePredictedReviewReplyContentUpdater(reply.relatedReview || review)(reply)(content))
        )
        .catch(errorCaughtNotifier(notify)),
    [replyUpdate, notify]
  );

  const updateReviewCategory = useCallback(
    (review, [tags]) => {
      setReviews(prev => prev.map(v => (v.id === review.id ? { ...v, tags } : v))); // update immediatly
      saveReview({ ...review, tags }).catch(e => {
        errorCaughtNotifier(notify)(e);
        setReviews(prev => prev.map(v => (v.id === review.id ? review : v))); // rollback to previous state in case of error
      });
    },
    [setReviews, notify]
  );

  const getOnSendMessage = useCallback(
    review =>
      ({ newStatus, successMessage } = {}) => {
        notify({
          body: successMessage || newStatus === review.status ? "Message sent" : "Review status was updated",
          timeout: LONG_NOTIFICATION_TIMEOUT
        });
        if (newStatus) {
          setReviews(positivePredictedReviewStatusUpdater(review)(newStatus));
        }
        invalidateReplies();
      },
    [invalidateReplies, notify]
  );

  const replyActionsCallbacks = useMemo(
    () => ({
      onDelete: onReplyDelete,
      onReplySaveFactory: onReplySaveFactory,
      onSentimentChange: onSentimentChange,
      onShowHistory: loadHistory,
      onUnpublish: onReplyUnpublish,
      onUpdateStatus: onUpdateStatus
    }),
    [onReplyDelete, onReplySaveFactory, onSentimentChange, loadHistory, onReplyUnpublish, onUpdateStatus]
  );

  const userHasReplyPermissions = useMemo(() => isCurrentUserInGroup([permissions.REVIEW_REPLY]), []);

  return (
    <>
      <div className={`py-4 px-xs-0 px-lg-4 container-fluid ${container}`}>
        <FacetedSearch filters={filters} />
        <MainBody className={mainBodyContainerClass}>
          <VirtualList
            crop={[-windowHeight, windowHeight * 2]}
            keyer={item => item.id}
            list={reviewItems}
            onScrolled={v => setListSituation(v.bottom)}
          >
            {({ entity }) => (
              <ReviewEntity
                review={entity}
                setReviews={setReviews}
                onReplyEdit={onReplyEdit}
                onReplyUpdate={invalidateReplies}
                onPublishReply={onPublishReply}
                replyActionsCallbacks={replyActionsCallbacks}
                updateReviewCategory={updateReviewCategory}
                onSendMessage={getOnSendMessage(entity)}
                setOpenedActivityModal={setOpenedActivityModal}
              />
            )}
          </VirtualList>
          <Collapse isOpen={reviews.length > 0 && allDataLoaded}>
            <p id="all-data" className="mx-auto my-5 text-center">
              That is all we have
            </p>
          </Collapse>
          <Collapse isOpen={reviews.length === 0 && allDataLoaded}>
            <p className="text-center mx-auto my-4">
              All caught up!
              <i className="fa fa-thumbs-up" />
            </p>
          </Collapse>
          <Collapse isOpen={!allDataLoaded && isLoading}>
            <div className="mx-auto w-100">
              <AjaxLoader />
            </div>
          </Collapse>
        </MainBody>
        <ActivityModal
          review={!!openedActivityModal && getEntityById(reviews, openedActivityModal.reviewId)}
          opened={!!openedActivityModal}
          toggler={() => {
            openedActivityModal.onClose();
            setOpenedActivityModal(false);
          }}
        />
        {reviewHistory && userHasReplyPermissions && (
          <ReplyHistoryModal history={reviewHistory} onClose={() => setReviewHistory(undefined)} />
        )}
      </div>
    </>
  );
};

const positivePredictedReviewRepliesUpdater = review => replies => prev =>
  prev.map(v => (v.id === review.id ? { ...v, replies } : v));

const positivePredictedReviewStatusUpdater = review => status => prev =>
  prev.map(v => (v.id === review.id ? { ...v, status } : v));

export const replyFieldUpdater = (review, reply, prev, field) => {
  function predictReplies(replies) {
    // cloneDeep prevents mutation of rep.replies
    return cloneDeep(replies).map(rep => {
      if (rep.replies) {
        rep.replies = predictReplies(rep.replies);
      }
      return rep.id === reply.id ? { ...rep, ...field } : rep;
    });
  }

  return prev.map(v =>
    v.id === review.id
      ? {
          ...v,
          replies: predictReplies(v.replies)
        }
      : v
  );
};

const positivePredictedReviewReplySentimentUpdater = review => reply => sentiment => prev =>
  replyFieldUpdater(review, reply, prev, { sentiment });

const positivePredictedReviewReplyStatusUpdater = review => reply => status => prev =>
  replyFieldUpdater(review, reply, prev, { status });

const positivePredictedReviewReplyContentUpdater = review => reply => content => prev =>
  replyFieldUpdater(review, reply, prev, { content });

export const positivePredictedReviewReplyRemover = review => reply => prev => {
  function predictReplies(replies) {
    // cloneDeep prevents mutation of rep.replies
    return cloneDeep(replies).filter(rep => {
      if (rep.replies) {
        rep.replies = predictReplies(rep.replies);
      }
      return rep.id !== reply.id;
    });
  }

  return prev.map(v =>
    v.id === review.id
      ? {
          ...v,
          replies: predictReplies(v.replies),
          status: "UNREPLIED"
        }
      : v
  );
};

export default ReviewFeed;
