import { get, flatMap, isNil } from "lodash";
import { HTTP_STATUSES } from "constants/httpStatuses";

const GPB_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENTID;
const GBP_MANAGEMENT_API = "https://mybusinessaccountmanagement.googleapis.com/v1";
const GBP_INFORMATION_API = "https://mybusinessbusinessinformation.googleapis.com/v1";
const GPB_SCOPE = "https://www.googleapis.com/auth/business.manage";

export class GoogleBusinessProfileApi {
  constructor() {
    if (typeof GoogleBusinessProfileApi.instance === "object") {
      return GoogleBusinessProfileApi.instance;
    }
    GoogleBusinessProfileApi.apiManagementPath = GBP_MANAGEMENT_API;
    GoogleBusinessProfileApi.apiInformationPath = GBP_INFORMATION_API;
    GoogleBusinessProfileApi.instance = this;
    return GoogleBusinessProfileApi.instance;
  }

  setCallback(callback) {
    this.callback = callback;
    if (window.google) {
      this.client = window.google.accounts.oauth2.initTokenClient({
        client_id: GPB_CLIENT_ID,
        scope: GPB_SCOPE,
        callback: tokenResponse => {
          if (tokenResponse?.access_token) {
            this.token = tokenResponse.access_token;
            callback && callback();
          }
        }
      });
    }
  }

  async requestToken() {
    await this.client.requestAccessToken();
  }

  getAuthorized() {
    return !!this.token;
  }

  clearToken() {
    this.token = undefined;
  }

  gFetch(path, body = null, query = undefined, method) {
    if (!Array.isArray(path)) {
      path = [path];
    }
    const url = new URL(path.join("/"));
    if (query) {
      url.search = new URLSearchParams(query).toString();
    }

    if (this.token) {
      return fetch(url, {
        method: method || body ? "POST" : "GET",
        query,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-type": "application/json",
          Accept: "application/json"
        }
      }).then(async res => {
        const data = await res.json();
        if (res.ok) {
          return data;
        } else {
          if (res.status === HTTP_STATUSES.notAuthorized) {
            this.clearToken();
            this.callback(false);
          } else {
            throw data;
          }
        }
      });
    } else {
      throw new Error("Not logged in");
    }
  }

  buildAddress(address) {
    return flatMap(["addressLines", "locality", "administrativeArea", "postalCode"], field => address?.[field])
      .filter(f => !isNil(f))
      .join(", ");
  }

  fetchAccounts() {
    return this.gFetch([GoogleBusinessProfileApi.apiManagementPath, "accounts"]).then(({ accounts }) =>
      accounts.map(({ name, accountName }) => ({ id: name, name: accountName }))
    );
  }

  fetchLocations(accountId) {
    const readMask = encodeURI("name,title,metadata,storefrontAddress");
    return this.gFetch([GoogleBusinessProfileApi.apiInformationPath, accountId, "locations"], null, { readMask }).then(
      ({ locations }) =>
        locations?.map(location => ({
          account: accountId,
          id: location.name,
          name: location.title,
          address: { ...location.storefrontAddress, fullAddress: this.buildAddress(location.storefrontAddress) },
          reviewUrl: get(location, "metadata.newReviewUrl"),
          placeId: get(location, "metadata.placeId")
        }))
    );
  }

  fetchAdmins(locationId) {
    return this.gFetch([GoogleBusinessProfileApi.apiManagementPath, locationId, "admins"]);
  }

  inviteManager(locationId, email) {
    return this.gFetch([GoogleBusinessProfileApi.apiManagementPath, locationId, "admins"], {
      admin: email,
      role: "MANAGER"
    });
  }
}
