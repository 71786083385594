import { omit, pick } from "lodash";
import { defaultPrepareFilters } from "hooks/data/useFilterQuery";

export const DEFAULT_REQUEST_FIELDS = [
  "brand",
  "customerId",
  "contactId",
  "startDate",
  "endDate",
  "campaignNames",
  "locationLabel",
  "accountId"
];

export const getPrepareFilters = requestFields => (filters, projection, filterConfig) => {
  const prepared = defaultPrepareFilters(filters, projection, filterConfig);
  console.log("!!!!!!!", { prepared });
  console.log(requestFields ? pick(prepared, requestFields) : omit(prepared, "campaignName"));
  return requestFields ? pick(prepared, requestFields) : omit(prepared, "campaignName");
};
