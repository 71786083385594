import React, { useCallback, useMemo, useEffect, useState } from "react";
import { noop, isUndefined, isNil } from "lodash";
import * as moment from "moment";
import classNames from "classnames";
import { DayPickerRangeController } from "react-dates";
import { START_DATE, END_DATE } from "react-dates/constants";
import { DropdownMenu, DropdownToggle, Form, Dropdown } from "reactstrap";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import InnerDateRangeInput from "./InnerDateRangeInput";
import { useWindowSize } from "hooks/utilHooks";
import { useToggleFilter } from "hooks/filteringHooks";
import { endOfTheDay, startOfTheDay } from "util/dateUtils";
import { dropdownDatePickerOptions } from "data/dateRangeOptions";

import styles from "./DateRangeFilter.module.scss";

const WINDOWS_WIDTH_THRESHOLD = 520;

const DATE_FIELDS = Object.freeze({
  startDate: "startDate",
  endDate: "endDate"
});

/**
 * @param {{
 *   dateRange: [Moment, Moment];
 *   onChange: (dates: [Moment, Moment]) => void;
 * }}
 */
const DropdownDateRangePicker = ({ dateRange, onChange = noop }) => {
  const [dateRangeStartValue, dateRangeEndValue] = useMemo(() => dateRange || [], [dateRange]);
  const [windowWidth] = useWindowSize();
  const [focused, setFocused] = useState(START_DATE);
  const [isOpen, toggle] = useToggleFilter();
  const getDateValueToSet = useCallback(value => value && startOfTheDay(moment(value)), []);
  const isDatesEqual = useCallback(
    (prev, next) =>
      isNil(prev) ? isNil(next) : isNil(next) ? false : moment(startOfTheDay(next)).diff(startOfTheDay(prev)) === 0,
    []
  );

  const [innerDateRange, setInnerDateRange] = useState({
    [DATE_FIELDS.startDate]: getDateValueToSet(dateRangeStartValue),
    [DATE_FIELDS.endDate]: getDateValueToSet(dateRangeEndValue)
  });

  const setInnerValue = useMemo(
    () => field => value =>
      setInnerDateRange(prev => (isDatesEqual(prev[field], value) ? prev : { ...prev, [field]: value })),
    []
  );

  const setInnerStart = useCallback(setInnerValue(DATE_FIELDS.startDate), []);
  const setInnerEnd = useCallback(setInnerValue(DATE_FIELDS.endDate), []);

  const onDatesChange = useCallback(
    ({ startDate, endDate }) => {
      const dateStart = startDate && startOfTheDay(startDate);
      const dateEnd = endDate && endOfTheDay(endDate);
      setInnerStart(dateStart);
      setInnerEnd(focused === END_DATE ? dateEnd : null);
      setFocused(focused === END_DATE && dateStart && dateEnd ? START_DATE : END_DATE);
      if (dateStart && dateEnd) {
        onChange([dateStart, dateEnd]);
      }
    },
    [focused, onChange]
  );

  useEffect(() => {
    setInnerStart(getDateValueToSet(dateRangeStartValue));
    setInnerEnd(getDateValueToSet(dateRangeEndValue));
  }, [dateRangeStartValue, dateRangeEndValue]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      const { startDate, endDate } = innerDateRange;
      const dateStart = startDate && startOfTheDay(startDate);
      const dateEnd = endDate && endOfTheDay(endDate);
      if (dateStart && dateEnd) {
        onChange([dateStart, dateEnd]);
      }
    },
    [innerDateRange, onChange]
  );

  // affects only on mount
  const initialVisibleMonth = useCallback(() => dateRangeStartValue || moment().subtract(1, "M"), [dateRange]);

  const onQuickSelectDateChange = useCallback(
    event => {
      if (isUndefined(event)) {
        return undefined;
      }
      const {
        target: {
          value: {
            value: { startDate, endDate }
          }
        }
      } = event;
      setInnerDateRange({ startDate, endDate });
      const dateStart = startDate && startOfTheDay(startDate);
      const dateEnd = endDate && endOfTheDay(endDate);
      onChange([dateStart, dateEnd]);
    },
    [onChange]
  );

  // prevents closing dropdown without date range change. only for selection with mouse, keyboard events work as usual
  const customOptions = useMemo(
    () =>
      dropdownDatePickerOptions.map(({ label, value }) => ({
        value,
        label: (
          <div className="w-100" onMouseDown={() => onQuickSelectDateChange({ target: { value: { value } } })}>
            {label}
          </div>
        )
      })),
    [onQuickSelectDateChange]
  );

  const renderCalendarInfo = useCallback(() => {
    return (
      <div className="pb-3 mx-4">
        <HorizontalSelectField
          label="Date range"
          name="dropdownDatePickerCustomOption"
          options={customOptions}
          onChange={onQuickSelectDateChange}
        />
      </div>
    );
  }, [onQuickSelectDateChange, customOptions]);

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle color="light" className="text-nowrap fs-3 text-capitalize" tag="div">
        <div className="d-flex w-100 input-group flex-nowrap">
          <i className="fa fa-calendar d-inline-block input-group-text" />
          <div className={classNames("form-control", styles.dateRangeToggleClass)}>
            <Form className="d-inline-block" onBlur={onSubmit} onSubmit={onSubmit}>
              <InnerDateRangeInput date={innerDateRange.startDate} setDate={setInnerStart} placeholder="Start date" />
            </Form>
            <div
              className={classNames("d-inline-block mx-1", {
                "text-muted": !(innerDateRange.startDate || innerDateRange.endDate)
              })}
            >
              →
            </div>
            <Form className="d-inline-block" onBlur={onSubmit} onSubmit={onSubmit}>
              <InnerDateRangeInput date={innerDateRange.endDate} setDate={setInnerEnd} placeholder="End date" />
            </Form>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu className="border-0 p-0 bg-transparent overflow-visible" container="body">
        <DayPickerRangeController
          startDate={innerDateRange.startDate || null}
          endDate={innerDateRange.endDate || null}
          onDatesChange={onDatesChange}
          focusedInput={focused}
          onFocusChange={setFocused}
          minimumNights={1}
          keepOpenOnDateSelect={true}
          renderCalendarInfo={renderCalendarInfo}
          initialVisibleMonth={initialVisibleMonth}
          numberOfMonths={windowWidth > WINDOWS_WIDTH_THRESHOLD ? 2 : 1}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(DropdownDateRangePicker);
