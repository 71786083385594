import * as copy from "copy-to-clipboard";
import { debounce } from "lodash";
import { identity, path } from "ramda";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { DEBOUNCE_DELAY_100_MS } from "../constants/delays";

const getInitialState = (delay, running) => ({
  running,
  startedAt: +new Date(),
  timeRemaining: delay
});

/**
 * Simple pauseble timeout hook.
 * @param {() => void} callback callback to be invoked on timeout elapsed;
 * @param {number} delay
 * @param {boolean} runnung
 * @returns {[() => void, () => void, () => void]} Set of controlling functions: pause, run and reset.
 */
export const useTimeout = (callback, delay = 0, running = true) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const [timerState, setTimerState] = useState(getInitialState(delay, running));

  const pause = useCallback(() => {
    const now = +new Date();
    setTimerState({
      running: false,
      startedAt: null,
      timeRemaining: timerState.timeRemaining - (now - timerState.startedAt)
    });
  }, [timerState]);

  const run = useCallback(() => {
    const now = +new Date();
    setTimerState({
      running: true,
      startedAt: now,
      timeRemaining: timerState.timeRemaining
    });
  }, [timerState]);

  const reset = useCallback(() => {
    setTimerState(getInitialState(delay, running));
  }, [delay, running]);

  useEffect(() => {
    if (
      timerState.running == true &&
      timerState.timeRemaining < Number.POSITIVE_INFINITY &&
      timerState.timeRemaining > 0
    ) {
      // set the timeout
      const timeout = setTimeout(savedCallback.current, timerState.timeRemaining);
      return () => {
        // clear the timeout if this effect is cleaned up
        clearTimeout(timeout);
      };
    }
  }, [timerState]);

  return [pause, run, reset];
};

/**
 * Allows you to track and set caret position within text field
 * @returns {[
 *  () => { onFocus: () => void, onBlur: () => void },
 *  [number, number] | undefined,
 *  React.Dispatch<[number, number] | undefined>
 * ]}
 */
export const useSelection = (defaultSelection = [0, 0]) => {
  const [selection, setSelection] = useState(defaultSelection);
  const [tracking, setTracking] = useState(undefined);

  const onAboutToUpdateSelection = useCallback(() => {
    if (tracking) {
      const startOffset = tracking.selectionStart;
      const endOffset = tracking.selectionEnd;
      const backwardDirection = tracking.selectionDirection === "backward";
      setSelection(v =>
        backwardDirection
          ? v && v[1] === startOffset && v[0] === endOffset
            ? v
            : [endOffset, startOffset]
          : v && v[0] === startOffset && v[1] === endOffset
          ? v
          : [startOffset, endOffset]
      );
    }
  }, [tracking]);

  useLayoutEffect(() => {
    document.addEventListener("pointerup", onAboutToUpdateSelection);
    document.addEventListener("pointercancel", onAboutToUpdateSelection);
    document.addEventListener("touchend", onAboutToUpdateSelection);
    document.addEventListener("touchcancel", onAboutToUpdateSelection);
    document.addEventListener("keyup", onAboutToUpdateSelection);
    return () => {
      document.removeEventListener("pointerup", onAboutToUpdateSelection);
      document.removeEventListener("pointercancel", onAboutToUpdateSelection);
      document.removeEventListener("touchend", onAboutToUpdateSelection);
      document.removeEventListener("touchcancel", onAboutToUpdateSelection);
      document.removeEventListener("keyup", onAboutToUpdateSelection);
    };
  }, [onAboutToUpdateSelection]);

  const onFocusIn = useCallback(({ target }) => setTracking(target), []);
  const onFocusOut = useCallback(() => {
    setTracking(undefined);
  }, []);
  const binder = useCallback(() => ({ onFocus: onFocusIn, onBlur: onFocusOut }), []);
  const manualySetSelection = useCallback(
    payload => {
      if (tracking) {
        if (typeof payload === "function") {
          setSelection(prev => {
            const [start, end] = payload(prev);
            tracking.setSelectionRange(start, end, start < end ? "backward" : "forward");
            return [start, end];
          });
        } else {
          const [start, end] = payload;
          tracking.setSelectionRange(start, end, start < end ? "backward" : "forward");
          setSelection([start, end]);
        }
      }
    },
    [tracking]
  );

  return [binder, selection, manualySetSelection];
};

export const useWindowSize = (debounceValue = DEBOUNCE_DELAY_100_MS) => {
  const [windowSize, setWindowSize] = useState([0, 0]);
  const handleResize = useCallback(
    debounce(
      () =>
        setWindowSize(prev =>
          prev[0] !== window.innerWidth || prev[1] !== window.innerHeight
            ? [window.innerWidth, window.innerHeight]
            : prev
        ),
      debounceValue
    ),
    [debounceValue]
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return windowSize;
};

export const useClipboardCopier = () => {
  return useCallback(content => {
    return Promise.resolve(copy(content));
  });
};

/**
 * @type {<T>(releaser?: (cb: (v: T[]) => void) => typeof cb) => [T[], (v: T) => void]}
 */
export const useAccumulator = (releaser = cb => debounce(cb, DEBOUNCE_DELAY_100_MS)) => {
  const [accum, setAccum] = useState([]);
  const [pending, setPending] = useState([]);
  const addPending = useCallback((...entries) => setPending(prev => [...prev, ...entries]), []);
  const releasePending = useMemo(
    () =>
      releaser(waiting => {
        if (waiting.length > 0) {
          setAccum(waiting);
          setPending([]);
        }
      }),
    [releaser]
  );
  useEffect(() => ((releasePending(pending), () => releasePending.cancel())), [pending, releasePending]);

  return [accum, addPending];
};

/**
 *
 * @type {<T, U>(init: T, pipe: (v: U) => T) => [ T, (v: U) => void ]}
 */
export const useStateThroughPipe = (init, pipe = identity) => {
  const [state, setState] = useState(init);
  return useMemo(() => [state, v => setState(pipe(v))], [pipe, state]);
};

/**
 *
 * @type {(init: string) => [string, (v: { target: { value: string } }) => void]}
 */
export const useNativeInputState = init => useStateThroughPipe(init, path(["target", "value"]));

/**
 *
 * @type {(fn: function) => function}
 */
export const useOnceCallable = fn => {
  const countRef = useRef(false);
  return (...args) => {
    if (!countRef.current) {
      countRef.current = true;
      return fn(...args);
    }
  };
};

/**
 *
 * @type {(fn: function) => any}
 */
export const useOnce = fn => useOnceCallable(fn)();
