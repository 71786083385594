import React from "react";
import classnames from "classnames";

import styles from "./ProgressTracker.module.scss";

const { progressTrackerClass, progressTrackerStepClass, activeStepClass, passedStepClass, stepNameClass } = styles;

const ProgressTracker = ({ steps, activeStepIndex = 0 }) => (
  <ul className={progressTrackerClass}>
    {steps.map((step, index) => (
      <li
        key={index}
        style={{ flexBasis: steps.length > 1 ? `${100 / steps.length}%` : "100%" }}
        className={classnames(progressTrackerStepClass, {
          [activeStepClass]: index === activeStepIndex,
          [passedStepClass]: index < activeStepIndex
        })}
      >
        <div className={stepNameClass}>{step}</div>
      </li>
    ))}
  </ul>
);

export default ProgressTracker;
