import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { get } from "lodash";
import { useAddAndRemoveSite } from "hooks/customerHooks";
import InputField from "components/Form/InputField";
import StatusMessages from "components/Misc/StatusMessages";
import Block from "components/Layout/Block";
import { SITE_SOURCE_NAMES, SOURCES } from "data/sites";

export default function DealertrackDmsLinking({ customer, updateSiteField }) {
  const [errors, setErrors] = useState([]);
  const [dealertrackDmsInfo, setDealertrackDmsInfo] = useState();
  const [enterpriseCode, setEnterpriseCode] = useState();
  const [companyNumber, setCompanyNumber] = useState();
  const [serverId, setServerId] = useState();
  const [dealertrackId, setDealertrackId] = useState();

  const siteField = SOURCES[SITE_SOURCE_NAMES.dealertrackDms].customerField;

  useEffect(() => {
    setDealertrackDmsInfo(get(customer, [siteField, 0]));
  }, [customer, siteField]);

  const [addSite, removeSite] = useAddAndRemoveSite({
    customerId: customer.id,
    field: siteField
  });

  const onRemoveSite = useCallback(() => {
    removeSite(dealertrackDmsInfo.id).then(data => {
      updateSiteField(siteField, data);
      setDealertrackDmsInfo(undefined);
    });
  }, [updateSiteField, dealertrackDmsInfo, siteField]);

  return (
    <Container>
      <Row>
        <Col>
          <StatusMessages errors={errors} />
        </Col>
      </Row>
      {dealertrackDmsInfo && dealertrackDmsInfo.id ? (
        <>
          <Row>
            <Col>
              <strong>Linked Account</strong>
              <Button color="link" className="ms-1 p-0" onClick={onRemoveSite}>
                (unlink)
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <InputField
                inline={false}
                disabled={true}
                onChange={() => {}}
                name="Enterprise Code"
                label={<span>Enterprise Code</span>}
                value={dealertrackDmsInfo.enterpriseCode}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputField
                inline={false}
                disabled={true}
                onChange={() => {}}
                name="Company Code"
                label={<span>Company Code</span>}
                value={dealertrackDmsInfo.companyNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputField
                inline={false}
                disabled={true}
                onChange={() => {}}
                name="Server ID"
                label={<span>Server ID</span>}
                value={dealertrackDmsInfo.serverId}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputField
                inline={false}
                disabled={true}
                onChange={() => {}}
                name="Dealertrack ID"
                label={<span>Dealertrack ID</span>}
                value={dealertrackDmsInfo.dealertrackId}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Block>
              <strong>Link your Dealertrack DMS Account</strong>
              <p>
                In order to link your Dealertreack DMS to Widewail, you must grant vendor access to{" "}
                <strong>Widewail</strong>.
              </p>
              <p>
                Instructions are how to fill out the four fields can be found{" "}
                <a href={process.env.PUBLIC_URL + "/dealertrackDMS.pdf"}>here</a>.
              </p>
              <p>
                In the example, the fields are: <br />
                <span className="fw-bold">
                  Enterprise Code: TRN1
                  <br />
                  Company Code: TR1 <br />
                  Server ID: ARKONA8 <br />
                  Dealertrack ID: 277299
                </span>
              </p>
            </Block>
          </Row>
          <Row>
            <Col>
              <InputField
                inline={false}
                type="text"
                label="Enterprise Code"
                placeholder="Enterprise Code"
                onChange={e => setEnterpriseCode(e.target.value)}
                value={enterpriseCode}
              />
              <InputField
                inline={false}
                type="text"
                label="Company Code"
                placeholder="Company Code"
                onChange={e => setCompanyNumber(e.target.value)}
                value={companyNumber}
              />
              <InputField
                inline={false}
                type="text"
                label="Server ID"
                placeholder="Server ID"
                onChange={e => setServerId(e.target.value)}
                value={serverId}
              />

              <InputField
                inline={false}
                type="text"
                label="Dealertrack ID"
                placeholder="Dealertrack ID"
                onChange={e => setDealertrackId(e.target.value)}
                value={dealertrackId}
              />
              <Button
                color="primary"
                disabled={!(enterpriseCode && companyNumber && serverId && dealertrackId)}
                onClick={() =>
                  addSite({
                    enterpriseCode,
                    companyNumber,
                    serverId,
                    dealertrackId,
                    source: "DEALERTRACK_DMS"
                  })
                    .then(res => {
                      setDealertrackDmsInfo({
                        id: res.data[0].id,
                        enterpriseCode,
                        companyNumber,
                        serverId,
                        dealertrackId
                      });
                    })
                    .catch(err => {
                      setErrors(["Failed saving Dealertrack DMS information."]);
                    })
                }
              >
                Save
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
