import { useEffect } from "react";
import { Provider } from "react-redux";
import { checkVersion } from "../redux/actions/appActions";
import defaultStore from "../redux/store/configureStore";

const ReduxLayer = ({ children, versionCheck = true, store = defaultStore }) => {
  useEffect(() => {
    if (versionCheck) {
      const id = setInterval(() => store.dispatch(checkVersion()), 60000);
      return () => clearInterval(id);
    }
  }, [versionCheck]);
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxLayer;
