import React, { useMemo } from "react";
import { useModal } from "./index";
import { WWModalBody, WWModalFooter, WWModalHeader } from "./WWModal";
import WWButton from "components/Buttons/WWButton";

/**
 * @param {(factory: (resolve: (v: boolean) => void) => JSX.Element) => Promise<{resolution: boolean; close: () => void}>} riser
 * @returns {(opts: {title?: string; body?: JSX.Element, choices?: [string, string]}) => Promise<{resolution: T; close: () => void}}
 */
export const riseConfimationDialog = riser => ({
  title = "Are you sure?",
  body = null,
  choices = ["Yes", "No"],
  color = ["success", "secondary"]
} = {}) =>
  riser(resolve => (
    <>
      <WWModalHeader className="px-4">{title}</WWModalHeader>
      <WWModalBody className="px-4">{body}</WWModalBody>
      <WWModalFooter className="px-4">
        <WWButton color={color[0]} onClick={() => resolve(true)}>
          {choices[0]}
        </WWButton>
        <WWButton color={color[1]} onClick={() => resolve(false)}>
          {choices[1]}
        </WWButton>
      </WWModalFooter>
    </>
  ));

export const useConfirmationDialog = () => {
  const riser = useModal();
  return useMemo(() => riseConfimationDialog(riser), [riser]);
};
