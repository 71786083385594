import React from "react";
import { ConfirmationButton } from "../../components/Form/withConfirmation";
import noop from "lodash/noop";
import { permissions } from "../../components/Auth/permissions";
import { AuthorizationRequiredToRender } from "../../components/Auth/Authorization";
import styles from "./SendInviteButtons.module.scss";

const { container, buttonContainer } = styles;

const SendInviteButtons = ({
  reviewLeadMobile,
  reviewLeadEmail,
  shouldConfirm = noop,
  confirmationPrompt,
  onClick,
  color = "primary",
  ...props
}) => (
  <div className={container} {...props}>
    {reviewLeadMobile && (
      <div className={buttonContainer}>
        <AuthorizationRequiredToRender roles={[permissions.REVIEW_INVITE_MANAGE]}>
          <ConfirmationButton
            className="m-0"
            color={color}
            shouldConfirm={shouldConfirm}
            prompt={confirmationPrompt}
            onClick={() => onClick("SMS")}
          >
            Send Review Invite SMS
          </ConfirmationButton>
        </AuthorizationRequiredToRender>
        <AuthorizationRequiredToRender roles={[permissions.VIDEO_MANAGE]}>
          <ConfirmationButton
            className="m-0"
            color={color}
            shouldConfirm={shouldConfirm}
            prompt={confirmationPrompt}
            onClick={() => onClick("SMS", "VIDEO")}
          >
            Send Video Invite SMS
          </ConfirmationButton>
        </AuthorizationRequiredToRender>
      </div>
    )}
    {reviewLeadEmail && (
      <div className={buttonContainer}>
        <AuthorizationRequiredToRender roles={[permissions.REVIEW_INVITE_MANAGE]}>
          <ConfirmationButton
            className="m-0"
            color={color}
            shouldConfirm={shouldConfirm}
            prompt={confirmationPrompt}
            onClick={() => onClick("EMAIL")}
          >
            Send Review Invite Email
          </ConfirmationButton>
        </AuthorizationRequiredToRender>
        <AuthorizationRequiredToRender roles={[permissions.VIDEO_MANAGE]}>
          <ConfirmationButton
            className="m-0"
            color={color}
            shouldConfirm={shouldConfirm}
            prompt={confirmationPrompt}
            onClick={() => onClick("EMAIL", "VIDEO")}
          >
            Send Video Invite Email
          </ConfirmationButton>
        </AuthorizationRequiredToRender>
      </div>
    )}
  </div>
);

export default SendInviteButtons;
