import React from "react";
import WWButton from "../Buttons/WWButton";

const FacebookReactionSelector = ({ enabled = true, onChange, text = "Like" }) => {
  return (
    <WWButton
      iconClass={enabled ? "thumbs-o-up" : "thumbs-up"}
      contentClass="text-capitalize"
      className="d-block"
      disabled={!enabled}
      color="link"
      onClick={() => onChange("LIKE")}
    >
      {text}
    </WWButton>
  );
};

export default FacebookReactionSelector;
