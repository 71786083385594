import React, { useCallback } from "react";
import WWButton from "components/Buttons/WWButton";
import useURLQuerySetter from "hooks/useURLQuerySetter/useURLQuerySetter";
import { valueOf } from "util/functionUtils";
import { useFilterResetter } from "hooks/filteringHooks";

const ResetSearchFiltersButton = ({ filters, ...props }) => {
  const resetAllFilters = useFilterResetter(filters);

  return (
    <WWButton className="overflow-visible" onClick={resetAllFilters} {...props} transparent>
      <span className="">Reset all filters</span>
    </WWButton>
  );
};

export default ResetSearchFiltersButton;
