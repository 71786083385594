import React, { useState } from "react";
import { Label as _Label, UncontrolledTooltip } from "reactstrap";
import uniqueId from "lodash/uniqueId";

export default function Label({ tooltip, children, ...props }) {
  const [id] = useState(uniqueId("label"));

  return (
    <>
      <_Label id={id} {...props}>
        {children}
      </_Label>
      {tooltip && (
        <UncontrolledTooltip target={id} placement="top">
          {tooltip}
        </UncontrolledTooltip>
      )}
    </>
  );
}
