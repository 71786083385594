import React, { useMemo, useCallback, useState } from "react";
import { isFunction, isNull } from "lodash";
import classNames from "classnames";

import styles from "./WWStackedChartBar.module.scss";

const {
  wrapperClass,
  stackedBarClass,
  stackedBarItemClass,
  negativeColorClass,
  neutralColorClass,
  positiveColorClass,
  unknownColorClass,
  hoveredColorClass,
  negativeBackgroundClass,
  neutralBackgroundClass,
  positiveBackgroundClass,
  unknownBackgroundClass,
  hoveredBackgroundClass,
  stackedBarLegendClass,
  legendNameClass,
  withClickHandlerClass
} = styles;

export const DEFAULT_COLORS_CLASS_MAP = Object.freeze({
  positive: [positiveColorClass, positiveBackgroundClass],
  negative: [negativeColorClass, negativeBackgroundClass],
  neutral: [neutralColorClass, neutralBackgroundClass],
  unknown: [unknownColorClass, unknownBackgroundClass]
});

const DEFAULT_ORDERED_VALUE_NAMES = ["Positive", "Neutral", "Negative", "Unknown"];

export const FOREGROUND_COLORS_MAP_INDEX = 0;
export const BACKGROUND_COLORS_MAP_INDEX = 1;

const WWStackedChartBar = ({
  className,
  colorsClassMap = DEFAULT_COLORS_CLASS_MAP,
  orderedValueNames = DEFAULT_ORDERED_VALUE_NAMES,
  values,
  onValueClick
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const sortedValues = useMemo(
    () =>
      values
        .filter(({ name }) => orderedValueNames.includes(name))
        .sort((a, b) => orderedValueNames.indexOf(a) - orderedValueNames.indexOf(b)),
    [values]
  );

  const totalCount = sortedValues.reduce((acc, next) => acc + next.count, 0);
  const getWidth = count =>
    `${count === 0 && totalCount === 0 ? 100 / sortedValues.length : (count / totalCount) * 100}%`;

  const clickHandlers = useMemo(
    () => (isFunction(onValueClick) ? sortedValues.map(({ name }) => () => onValueClick(name)) : []),
    [sortedValues, onValueClick]
  );

  const hoverHandlers = useMemo(
    () => (isFunction(onValueClick) ? sortedValues.map(({ name }) => () => setHoveredItem(name)) : []),
    [setHoveredItem]
  );

  const clearHoveredItem = useCallback(() => setHoveredItem(null));

  const getItemClass = ({ name, color, index }, background = false) =>
    classNames(
      colorsClassMap[color || name.toLowerCase()][
        background ? BACKGROUND_COLORS_MAP_INDEX : FOREGROUND_COLORS_MAP_INDEX
      ] || unknownColorClass,
      {
        [withClickHandlerClass]: isFunction(clickHandlers[index]),
        [hoveredColorClass]: !background && !isNull(hoveredItem) && hoveredItem !== name,
        [hoveredBackgroundClass]: background && !isNull(hoveredItem) && hoveredItem !== name
      }
    );

  return sortedValues.length > 0 ? (
    <div className={classNames(wrapperClass, className)} onMouseLeave={clearHoveredItem}>
      <div className={stackedBarClass}>
        {sortedValues.map(({ count, color, name }, index) =>
          count > 0 || totalCount === 0 ? (
            <div
              key={`bar_${name}`}
              style={{ width: getWidth(count) }}
              className={classNames(stackedBarItemClass, getItemClass({ name, color, index }, true))}
              onClick={clickHandlers[index]}
              onMouseEnter={hoverHandlers[index]}
            />
          ) : null
        )}
      </div>
      <div className={stackedBarLegendClass}>
        {sortedValues.map(({ color, count, name }, index) => (
          <div
            key={`ld_${name}`}
            className={getItemClass({ name, color, index })}
            onClick={clickHandlers[index]}
            onMouseEnter={hoverHandlers[index]}
          >
            <span className={legendNameClass}>{name} - </span>
            {count}
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default WWStackedChartBar;
