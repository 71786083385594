import { Button } from "reactstrap";
import get from "lodash/get";
import { UncontrolledTooltip } from "reactstrap";

import WWTableBlock from "components/Table/WWTableBlock";
import { SITES } from "data/sites";
import ReportWithFilters, { withReportFilterQuery } from "components/HOCs/ReportWithFilters";

import withAuthorization from "../../components/Auth/Authorization";
import { permissions } from "../../components/Auth/permissions";
import { useQueryClient } from "react-query";

const schema = [
  {
    label: "Start",
    renderCell: ({ startDate }) => new Date(startDate).toLocaleString()
  },
  {
    label: "Customer",
    valueKey: "visits",
    renderCell: ({ customer }) => customer?.companyName
  },
  {
    label: "Source",
    valueKey: "source",
    renderCell: ({ source }) =>
      get(
        SITES.find(s => s.source === source),
        "label",
        source
      )
  },
  {
    label: "Imported",
    valueKey: "numImported"
  },
  {
    label: "Skipped",
    valueKey: "numSkipped"
  },
  {
    label: "Failed",
    valueKey: "numFailed"
  },
  {
    label: "Status",
    valueKey: "status",
    renderCell: item =>
      item.success ? (
        "Success"
      ) : (
        <>
          Failed{" "}
          {item.errorMessage && (
            <>
              <span id={"error-" + item.id} className="fa fa-question-circle" />
              <UncontrolledTooltip target={"#error-" + item.id}>{item.errorMessage}</UncontrolledTooltip>
            </>
          )}
        </>
      )
  }
],
  filtersFn = () => [
    {
      name: "source",
      label: "Source...",
      type: "buttonDropdown",
      clearable: true,
      options: SITES.map(s => ({ label: s.label, value: s.source })),
      placeholder: "Source",
      primaryBar: {
        position: "LEFT",
        className: "d-none d-md-flex comparison-selector"
      },
      chip: {
        position: "NONE"
      }
    },
    {
      name: "success",
      label: "Status...",
      type: "buttonDropdown",
      clearable: true,
      options: [
        { label: "Success", value: "true" },
        { label: "Failed", value: "false" }
      ],
      placeholder: "Status",
      primaryBar: {
        position: "LEFT",
        className: "d-none d-md-flex comparison-selector"
      },
      chip: {
        position: "NONE"
      }
    }
  ],
  ImportLogTable = withReportFilterQuery({
    url: "/importlogs",
    pageSize: 25,
    forceResults: true,
    additionalParams: {
      sort: "startDate,desc"
    }
  })(({ data, isLoading, setPageNumber }) => {
    const { page } = data;

    return (
      <WWTableBlock
        schema={schema}
        isLoading={isLoading}
        pageSize={page?.size}
        setPageNumber={setPageNumber}
        pageNumber={page?.number}
        totalPages={page?.totalPages}
        data={data?._embedded?.importJobLogs}
        heightReduce={0}
      />
    );
  });

export function ImportLog() {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries({ queryKey: ["/importlogs"] });

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <ReportWithFilters
        forceResults
        filtersFn={filtersFn}
        showFiltersButton={false}
        filterBarChildren={
          <Button onClick={invalidateQuery}>
            <i className="icon-refresh"></i> Refresh
          </Button>
        }
      >
        <ImportLogTable />
      </ReportWithFilters>
    </div>
  );
}

export default withAuthorization(permissions.WIDEWAIL_ADMIN)(ImportLog);
