import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { facebookLogin, facebookLogout } from "../../redux/actions/facebookActions";
import { Button } from "reactstrap";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import isEmpty from "lodash/isEmpty";
import "./FacebookLoginButton.scss";
import StatusMessages from "components/Misc/StatusMessages";
import { useCurrentFacebookUser } from "hooks/data/facebookApiHooks";
import AdBlockError from "components/Misc/AdBlockError";

const FACEBOOK_ADS_SCOPE = "ads_read,ads_management";

export default function FacebookLoginButton({ size = "medium", facebookAdsEnabled = false }) {
  const dispatch = useDispatch();
  const facebookUser = useCurrentFacebookUser();
  const [error, setError] = useState();

  const handleChange = useCallback(response => {
    if (!isEmpty(response.accessToken)) {
      window.FB.api("/me", function(me) {
        if (me.error) {
          setError(me.error);
        } else {
          dispatch(facebookLogin({ name: me.name, id: me.id, accessToken: response.accessToken }));
          setError();
        }
      });
    }
  }, []);

  const extraScopeString = useMemo(() => (facebookAdsEnabled ? `,${FACEBOOK_ADS_SCOPE}` : ""), [facebookAdsEnabled]);

  return (
    <div>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APPID}
        autoLoad={false}
        version="18.0"
        scope={`${process.env.REACT_APP_FACEBOOK_BASIC_SCOPE}${extraScopeString}`}
        // onClick={componentClicked}
        callback={handleChange}
        render={renderProps =>
          renderProps.isSdkLoaded ? (
            <button onClick={renderProps.onClick} className="loginBtn loginBtn--facebook">
              {facebookUser ? `Logged in as ${facebookUser.name}` : "Log in With Facebook"}
            </button>
          ) : (
            <AdBlockError service="Facebook" />
          )
        }
      />
      {facebookUser && (
        <Button color="link" onClick={() => dispatch(facebookLogout())}>
          Logout
        </Button>
      )}
      {error && <StatusMessages errors={error} />}
    </div>
  );
}
