import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { reviewStatusName, reviewTypeName } from "data/options";
import { bySource } from "data/sites";
import { roundToOne } from "util/roundToOne";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { WWTD, WWTR } from "components/Table/WWTable";
import Time from "components/DateTime/Time";
import { RESPONDER_FLAG_VALUES } from "data/options";
import Checkbox from "components/Checkbox/Checkbox";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";

import style from "./ReviewListRow.module.scss";

const {
  greenFlagRowClass,
  blueFlagRowClass,
  tealFlagRowClass,
  purpleFlagRowClass,
  brownFlagRowClass,
  yellowFlagRowClass
} = style;

const getClassName = ({ beingEdited, review }) => {
  let className;
  const { responderFlag, replySummary } = review;
  const responderFlagColorsMap = {
    [RESPONDER_FLAG_VALUES.followUp]: blueFlagRowClass,
    [RESPONDER_FLAG_VALUES.green]: greenFlagRowClass,
    [RESPONDER_FLAG_VALUES.teal]: tealFlagRowClass,
    [RESPONDER_FLAG_VALUES.purple]: purpleFlagRowClass,
    [RESPONDER_FLAG_VALUES.brown]: brownFlagRowClass,
    [RESPONDER_FLAG_VALUES.yellow]: yellowFlagRowClass,
    [RESPONDER_FLAG_VALUES.doNotRespond]: "bg-orange-200"
  };
  if (beingEdited) {
    className = "bg-pink-200";
  } else if (responderFlag) {
    className = responderFlagColorsMap[responderFlag];
  } else if (replySummary?.pendingRetry) {
    className = "bg-yellow-200";
  }
  return className;
};

const ReviewListRow = ({ review, index, isSelected, onReviewToggle, beingEdited = false }) => {
  const source = useMemo(() => bySource(review.source), [review]);
  const className = getClassName({ beingEdited, review });
  return (
    <WWTR className={`${className}`} noHover={beingEdited || review.responderFlag}>
      <AuthorizationRequiredToRender roles={permissions.RESPONSE_MANAGER}>
        <WWTD>
          <Checkbox checked={isSelected} onToggle={onReviewToggle} />
        </WWTD>
      </AuthorizationRequiredToRender>
      <WWTD>
        <Time withIcon={false} date={new Date(review?.reviewDate)} />
      </WWTD>
      <WWTD>
        <div className="fs-2 text-muted"> {reviewTypeName(review.type)}</div>
        {source.label}
      </WWTD>
      <WWTD id={`customer${review?.id}`}>
        <Link to={`/customers/${review.customer.id}`}>{review.customer.companyName}</Link>
        <UncontrolledTooltip target={`customer${review?.id}`}>{review.customer.companyName}</UncontrolledTooltip>
      </WWTD>
      <WWTD id={`review${review?.id}`} className="text-wrap">
        <div className="fs-2 text-muted"> {review.author}</div>
        <Link to={`/reviews/list/edit/${review.id}`} data-cy={`review-title-${index}`}>
          {review.title ? review.title : "(no title)"}
        </Link>
        <UncontrolledTooltip target={`review${review?.id}`}>
          {review.title ? review.title : "(no title)"}
        </UncontrolledTooltip>
        {review.reportedAs && (
          <>
            <br />
            <Badge pill>Reported as {review.reportedAsFriendlyName}</Badge>
          </>
        )}
      </WWTD>
      <WWTD>{review.formattedStarRating || roundToOne(review.rating)}</WWTD>
      <WWTD>{reviewStatusName(review.status)}</WWTD>
    </WWTR>
  );
};

export default ReviewListRow;
