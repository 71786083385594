import React, { useCallback, useEffect, useMemo, useState } from "react";
import { not } from "ramda";
import classNames from "classnames";
import Review from "components/Reviews/Review";
import ReviewTagSelector from "components/Reviews/ReviewTagSelector/ReviewTagSelector";
import WWButton from "components/Buttons/WWButton";
import ReviewReply from "components/Reviews/Reply/ReviewReply";
import ReviewMessages from "components/Reviews/ReviewMessages";
import { permissions } from "components/Auth/permissions";
import { useNotification } from "components/Notifications/notification";
import { SlicedCard, SlicedCardBottom, SlicedCardTop } from "./SlicedCard";
import ReplyBox from "./ReplyBox";
import { RESPONDER_FLAG_VALUES } from "data/options";
import { flattenReplies } from "util/reviewUtils";
import { customerHasReviewResponseProduct } from "util/customerUtils";
import { isCurrentUserInGroup } from "util/userUtils";
import { useResponseBotPreflightReview, useResponseBotReview } from "hooks/data/responseBotHooks";

import styles from "./Feed.module.scss";
import { useTagSetById } from "../../../hooks/data/tagSetHooks";

const { replyCardClass, replyHeaderClass, inlineLinkClass, collapseButtonClass } = styles;

const MemoizedWWButton = React.memo(WWButton);

export const ReviewEntity = ({
  review,
  setReviews,
  onReplyEdit,
  onReplyUpdate,
  onPublishReply,
  replyActionsCallbacks,
  updateReviewCategory,
  onSendMessage,
  setOpenedActivityModal
}) => {
  const replies = useMemo(() => flattenReplies(review.replies).filter(reply => reply.status !== "REMOVED"), [
    review.replies
  ]);
  const onCancel = useCallback(() => setReplying(false), []);
  const [replying, setReplying] = useState(false);
  const [collapseReplies, setCollapseReplies] = useState(false);
  const [enableGenerateResponse, setEnableGenerateResponse] = useState(false);
  const [showGenerateResponseButton, setShowGenerateResponseButton] = useState(
    review.customer.products?.includes("AI_ASSIST")
  );

  const { isLoading: isEligibleLoading, data: isEligible } = useResponseBotPreflightReview(review.id, {
    enabled: replying
  });

  const notify = useNotification();
  const generateResponse = useResponseBotReview(review.id, { enabled: enableGenerateResponse });

  useEffect(() => {
    if (generateResponse.isSuccess) {
      if (!generateResponse.data && enableGenerateResponse) {
        notify({ body: "Response was not generated for this review" });
      }
      setShowGenerateResponseButton(false);
    }
    if (generateResponse.isFetched) {
      setEnableGenerateResponse(false);
    }
  }, [generateResponse, enableGenerateResponse, notify]);

  const categoryTags = useTagSetById(review.customer.reviewTagSetId);

  const userHasReplyPermissions = useMemo(() => isCurrentUserInGroup([permissions.REVIEW_REPLY]), []);

  const onReplySave = replyActionsCallbacks.onReplySaveFactory(review, review.thirdpartyId);

  const onGenerateResponseClick = useCallback(() => setEnableGenerateResponse(true), []);

  const userCanReply = useMemo(() => userHasReplyPermissions && !customerHasReviewResponseProduct(review?.customer), [
    userHasReplyPermissions,
    review
  ]);

  const engageBannerText = useMemo(() => {
    switch (review.status) {
      case "NO_ACTION":
        return "This review has been marked as No Action. No action is needed on this review, but it will be included in reporting.";
      case "IGNORED":
        return "This review has been marked as spam. Widewail will not track any further updates to this review, and it will not be included in reporting.";
      default:
        return "There is no response on this review yet. Our team will draft a response shortly.";
    }
  }, [review.status]);

  const hasReplies = !!replies?.length;

  return (
    <>
      <SlicedCardTop className="w-100">
        <Review review={review} withAge={false} showReviewTopics={false} showType />
        <ReviewTagSelector
          updateReviewCategory={updateReviewCategory}
          categoryTags={categoryTags?.data?.tags}
          review={review}
          setReviews={setReviews}
        />
      </SlicedCardTop>
      <SlicedCard className={classNames("w-100", replyCardClass)}>
        {replying ? (
          <div className="pt-4 pb-3">
            <ReplyBox
              onFinish={onCancel}
              onSave={onReplySave}
              initialContent={generateResponse.data}
              additionalControls={
                showGenerateResponseButton ? (
                  <MemoizedWWButton
                    size="sm"
                    iconClass="fa fa-magic"
                    disabled={generateResponse.isLoading || !isEligible || isEligibleLoading}
                    className="me-1 mt-1"
                    onClick={onGenerateResponseClick}
                    outline
                  >
                    {isEligibleLoading
                      ? "Checking..."
                      : isEligible
                      ? "Generate a New Reply"
                      : "Ineligible for Reply Generation (likely too short)"}
                  </MemoizedWWButton>
                ) : null
              }
            />
          </div>
        ) : (
          <div
            className={classNames("mt-sm-3", {
              "my-3 mb-md-4": !review.replyCount || review.allRepliesRemoved,
              "my-2 mb-sm-3 mt-md-3": collapseReplies
            })}
          >
            <div className="d-flex justify-content-between">
              <div
                className={classNames("d-none d-md-flex align-items-center", replyHeaderClass, {
                  "mb-2": !review.replyCount
                })}
              >
                <i className="fa fa-comment text-dark text-muted pe-2" /> <span>Response</span>
              </div>
              {hasReplies && (
                <WWButton
                  iconClass={`fa fa-angle-${collapseReplies ? "down" : "up"}`}
                  className={collapseButtonClass}
                  contentClass="text-capitalize"
                  color="link"
                  onClick={() => setCollapseReplies(not)}
                >
                  {collapseReplies ? "Show Replies" : "Hide Replies"}
                </WWButton>
              )}
            </div>
            {!hasReplies && (
              <>
                <span>{userCanReply ? "There is no response posted. " : engageBannerText}</span>
                {userCanReply && review.responderFlag !== RESPONDER_FLAG_VALUES.doNotRespond && (
                  <WWButton
                    className={inlineLinkClass}
                    contentClass="test-capitalize"
                    color="link"
                    onClick={() => setReplying(true)}
                  >
                    Reply Now!
                  </WWButton>
                )}
              </>
            )}
          </div>
        )}
      </SlicedCard>

      <SlicedCard className={replyCardClass}>
        {collapseReplies
          ? []
          : replies.map((reply, index) => (
              <div
                key={`rp-${index}`}
                className={classNames("w-100 mt-sm-3", {
                  "pb-sm-3 pb-md-4": index === replies.length - 1
                })}
              >
                <ReviewReply
                  review={review}
                  reply={reply}
                  onReplyUpdate={onReplyUpdate}
                  onPublishReply={userCanReply ? onPublishReply : null}
                  onReplyEdit={onReplyEdit}
                  replyActionsCallbacks={replyActionsCallbacks}
                  showApprovalDate
                  showStatusDropdown={false}
                />
              </div>
            ))}
      </SlicedCard>
      <SlicedCardBottom>
        <ReviewMessages
          review={review}
          onSendMessage={onSendMessage}
          toggleAllActivity={onClose =>
            setOpenedActivityModal({
              reviewId: review.id,
              onClose
            })
          }
        />
      </SlicedCardBottom>
    </>
  );
};

export default React.memo(ReviewEntity);
