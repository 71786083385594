import React, { useState, useEffect } from "react";
import PasswordInput from "components/Auth/PasswordInput";
import FormField from "components/Form/FormField";
import InputField from "components/Form/InputField";
import { validEmail } from "../../../util/validators";

const SharedUberallCredentials = ({ credentials, onChange, onValidChange }) => {
  const [name, setName] = useState();
  const [apiKey, setApiKey] = useState();
  const [negativeApprovalUser, setNegativeApprovalUser] = useState();

  useEffect(() => {
    setName(credentials?.name);
    setApiKey(null);
    setNegativeApprovalUser(credentials?.negativeApprovalUser);
  }, [credentials]);
  useEffect(() => {
    onValidChange(
      name && (credentials?.apiKey || apiKey) && (credentials?.negativeApprovalUser || negativeApprovalUser)
    );
    onChange({ name, apiKey: apiKey, negativeApprovalUser: negativeApprovalUser || null });
  }, [name, credentials, apiKey, negativeApprovalUser, onChange, onValidChange]);

  return (
    <>
      <InputField
        label="Name"
        value={name || ""}
        valid={!!name}
        onChange={event => setName(event.target.value)}
        inline={false}
      />
      <FormField tip={credentials?.credentials ? "Leave this empty to use the existing credentials" : ""}>
        <PasswordInput
          label="Private API key"
          value={apiKey || ""}
          onChange={setApiKey}
          lastpass={false}
          inline={false}
        />
        <InputField
          label="Negative Approval User (Email)"
          value={negativeApprovalUser || ""}
          valid={negativeApprovalUser ? validEmail(negativeApprovalUser) : false}
          onChange={event => setNegativeApprovalUser(event.target.value)}
          inline={false}
        />
      </FormField>
    </>
  );
};

export default SharedUberallCredentials;
