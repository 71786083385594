import React, { useEffect, useState } from "react";
import logger from "util/logger";
import { path as pather } from "ramda";
import ForgotPassword from "./ForgotPassword";
import { Card } from "../Card";
import { PasswordFields } from "./PasswordFields";
import classNames from "classnames";
import { validEmail } from "util/validators";

import widewailLogo from "../../img/widewail-logo.gif";
import bg from "../../img/blob-cropped.svg";

import styles from "./LoginContainer.module.scss";

import WWButton from "../Buttons/WWButton";
import InputField from "../Form/InputField";
import AjaxLoader, { AjaxLoaderSizes } from "../Misc/AjaxLoader";
import { useLocationQueryParamValue } from "hooks/stateHooks";
import { isEmpty } from "lodash";

import { Auth } from "aws-amplify";
import {
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUserSession,
  CognitoIdToken
} from "amazon-cognito-identity-js";

import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLogout } from "./LogoutButton";
import { useLocation } from "react-router-dom";

export const EmailFields = ({ email, setEmail, isLoading, onSubmit }) => {
  const enterOk = !isLoading && validEmail(email);

  // if the user has landed on the email entry screen, null the localStorage to start from scratch
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      <InputField
        autocomplete="email"
        dataLpIgnore="false"
        label="Login with your Email Address"
        placeholder=""
        inline={false}
        onChange={e => setEmail(e.target.value)}
        onKeyPress={({ key }) => enterOk && key === "Enter" && onSubmit()}
        value={email}
        debounceDelay={0}
      />
      <WWButton
        trackingCategory="Auth"
        disabled={!enterOk}
        data-testid="login"
        onClick={onSubmit}
        className="btn btn-warning col-12 py-3 fw-bold"
      >
        {isLoading ? <AjaxLoader size={AjaxLoaderSizes.SM} /> : "Continue"}
      </WWButton>
    </>
  );
};

export const AUTH_TYPES = {
    SAML_FEDERATED_IDP: "SAML_FEDERATED_IDP",
    USERNAME_PASSWORD: "USERNAME_PASSWORD"
  },
  MODES = {
    enterEmail: "enterEmail",
    forgotPassword: "forgotPassword",
    enterPassword: "enterPassword"
  },
  MODE_RENDERERS = {
    [MODES.enterEmail]: EmailFields,
    [MODES.enterPassword]: ({ setMode, ...props }) => (
      <PasswordFields {...props} onForgotPassword={() => setMode(MODES.forgotPassword)} />
    ),
    [MODES.forgotPassword]: ForgotPassword
  },
  TOKEN_EXCHANGE_BASE_PATH = "/oauth2/token",
  REDIRECT_URI = process.env.REACT_APP_COGNITO_REDIRECTURI,
  createUser = ({ access_token, id_token, refresh_token }) => {
    // Creates the Amplify authed user context manually
    if (![access_token, id_token, refresh_token].every(x => x)) return;

    const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({
          IdToken: id_token
        }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: refresh_token
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: access_token
        })
      }),
      userName = session.getIdToken().decodePayload()["cognito:username"];

    try {
      const currentUser = Auth.createCognitoUser(userName);
      currentUser.setSignInUserSession(session);

      logger.info("SSO User successfully initialized", { userName });
      window.location = REDIRECT_URI;
    } catch (e) {
      logger.error("SSO User failed to initialize", { userName });
    }
  };

export const useIsBhSso = () => {
    // Encapulated the logic for checking is the executing flow is BH-SSO
    const location = useLocation(),
      isBh = location.pathname.includes("bhSso");

    return isBh;
  },
  //Site linking via oauth can collide with login, so ignore it
  useOauthCode = () => {
    const location = useLocation();
    const code = useLocationQueryParamValue("code");
    const isSiteLinking = location.pathname.includes("oauth");

    return isSiteLinking ? null : code;
  },
  useClientId = () => {
    // IDP-initiated logins require different clientIds to auth against
    // logic for these cases is incapsulated in this hook. Additionally
    // set the client in localStorage so that after returning from the
    //  SSO code exchange, use that as the predetermined value.
    //
    //  Logic checks happen first however to handle potentially explicit choices
    //  in flow
    const existingClientId = localStorage.getItem("client"),
      bhInferredClientId = useIsBhSso() && process.env.REACT_APP_COGNITO_BH_CLIENTID,
      ret = bhInferredClientId || existingClientId || process.env.REACT_APP_COGNITO_CLIENTID;

    localStorage.setItem("client", ret);

    return ret;
  },
  useCodeExchangePath = () => {
    // Exists as a hook to encapsulate potential IDP-specfic behaviors
    return TOKEN_EXCHANGE_BASE_PATH;
  },
  useRedirectUri = () => {
    // Returns the IDP-specific redirect url from the cognito code exchange
    if (useIsBhSso()) return REDIRECT_URI + "bhSso";
    else return REDIRECT_URI;
  };

export const useFigureOutAuthForUser = ({ email, enabled = false }) => {
    // Login flow is determined by the value of the email entered by the user:
    // @widewail.com -> Google SSO
    // @dealer.com -> Okta SSO
    // etc...
    // @*.* -> password entry
    // note that IDP-initiated login skips this step, ie BH via Azure
    const queryKey = ["auth", "ssoLookup", email],
      queryFn = () =>
        fetch(process.env.REACT_APP_AUTH_LOOKUP_URI, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          method: "POST",
          body: email
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              return AUTH_TYPES.USERNAME_PASSWORD;
            }
          })
          .catch(error => {
            logger.error("Error determining auth for email entry:", { email, error });
            throw error;
          });

    return useQuery({ queryKey, queryFn, enabled });
  },
  useCodeExchange = code => {
    // When an un-authed user comes into the app with a 'code' query param, that means
    // they are returning from a third-party idp check. We have to take the code and exchange
    // it with cognito for a JWT which will will use to authorize the user
    const path = useCodeExchangePath(),
      logout = useLogout(true),
      clientId = useClientId(),
      redirectUri = useRedirectUri(),
      queryKey = ["auth", "codeExchange", code],
      dataParams = {
        code,
        client_id: clientId,
        redirect_uri: useRedirectUri(),
        grant_type: "authorization_code"
      },
      queryFn = async () => {
        logger.info("SSO code exchange initiated", { clientId, path, redirectUri });

        return fetch(`https://${process.env.REACT_APP_COGNITO_LOGINDOMAIN}${path}`, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          method: "POST",
          body: new URLSearchParams(dataParams)
        })
          .then(response => {
            if (response.ok) return response.json();
            else throw new Error("Failed token exchange");
          })
          .catch(error => {
            logger.error("SSO code exchange failed", { clientId, path, redirectUri, error });
            logout();
          });
      };

    return useQuery({ queryKey, queryFn, enabled: !!code && !!path });
  };

const AppLoader = () => {
  const [showTimeoutError, setShowTimeoutError] = useState(false),
    logout = useLogout(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowTimeoutError(true), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <p style={{ textAlign: "center", fontSize: "14px" }}>
      {showTimeoutError ? (
        <>
          Oops, looks like things are taking a little longer than expected. <br />
          {"Click "}
          {/* eslint-disable-next-line */}
          <a href="#" onClick={logout}>
            here
          </a>{" "}
          to log in again.
        </>
      ) : (
        <>Loading Widewail&hellip;</>
      )}
    </p>
  );
};

const LoginContainer = ({ isAppLoading }) => {
  const code = useOauthCode(),
    isSigningOut = useSelector(pather(["cognito", "signingOut"])),
    [mode, setMode] = useState(MODES.enterEmail),
    [email, setEmail] = useState(""),
    [getAuthOk, setGetAuthOk] = useState(false),
    [isAuthLoading, setIsAuthLoading] = useState(!!code || isSigningOut),
    [errorMessage, setErrorMessage] = useState(),
    { data: authFlow = {}, isLoading: isFiguringOutAuth } = useFigureOutAuthForUser({
      email,
      enabled: getAuthOk
    }),
    { authenticationFlow, path, params } = authFlow,
    { data: tokens, isLoading: isExchangingCode } = useCodeExchange(code),
    isFeddie = authenticationFlow === AUTH_TYPES.SAML_FEDERATED_IDP,
    AuthComponent = MODE_RENDERERS[mode],
    isWorking = isAuthLoading || isFiguringOutAuth || isExchangingCode || !isEmpty(tokens);

  // if the auth type for given user is not federated, ask for the password
  useEffect(() => {
    if (!isFiguringOutAuth && getAuthOk && !isFeddie) {
      setMode(MODES.enterPassword);
    }
  }, [isFiguringOutAuth, getAuthOk, isFeddie]);

  // if the auth type for given user is federated, send them to the IDP through our lambda
  useEffect(() => {
    if (isFeddie && path && params && !isFiguringOutAuth) {
      setIsAuthLoading(true);
      logger.info("SSO flow initiated");
      window.location = `https://${process.env.REACT_APP_COGNITO_LOGINDOMAIN}${path}?${new URLSearchParams(
        params
      ).toString()}`;
    }
  }, [isFeddie, authenticationFlow, path, params, isFiguringOutAuth]);

  // if we were able to successfully exchange the IDP code for Cognito tokens, set up the user
  useEffect(() => {
    if (!isExchangingCode && !isEmpty(tokens)) {
      setIsAuthLoading(true);
      logger.info("SSO token exchange started");
      createUser(tokens);
    }
  }, [tokens, isExchangingCode, authenticationFlow]);

  useEffect(() => {
    setErrorMessage(null);
  }, [mode]);

  const authProps = {
    email,
    setEmail,
    mode,
    setMode,
    setErrorMessage,
    isLoading: isWorking,
    reset: () => {
      setMode(MODES.enterEmail);
      setEmail("");
      setGetAuthOk(false);
      setErrorMessage(null);
      setIsAuthLoading(false);
    },
    onSubmit: () => setGetAuthOk(true)
  };

  return (
    <div className={classNames(styles.login, "app bg-white")} style={{ backgroundImage: bg }}>
      <Card className="container-sm my-auto shadow p-5" style={{ maxWidth: "480px" }}>
        <header className="d-flex justify-content-center mb-0">
          <img src={widewailLogo} alt="Widewail"></img>
        </header>
        <br />
        {errorMessage && <article className={styles.errorMessage}>{errorMessage}</article>}
        {!isAppLoading && !isAuthLoading ? <AuthComponent {...authProps} /> : <AppLoader />}
      </Card>
    </div>
  );
};

export default LoginContainer;
