import React from "react";
import FormField from "../../../components/Form/FormField";
import CopyButton from "../../../components/Buttons/CopyButton";
export default class Instagram {
  field() {
    return "instagramInfo";
  }

  add() {
    const AddInstagram = ({ customer, onSave }) => <></>;
    return AddInstagram;
  }

  edit() {
    const EditInstagram = ({ info, customer, refreshSite }) => (
      <>
        <FormField label="User">
          <a href={`https://www.instagram.com/${info.username}`} target="_blank" rel="noopener noreferrer">
            {info.username + "  "} <i className="fa fa-external-link" />
          </a>
        </FormField>
        <FormField label="ID">
          <div className="text-nowrap">
            {info.username}
            <span className="ps-1">
              <CopyButton text={info.username} />
            </span>
          </div>
        </FormField>
      </>
    );
    return EditInstagram;
  }
}
