import React, { useEffect, useState } from "react";
import TextArea from "react-textarea-autosize";

export const Notes = ({ mode, notes, onChange = () => {} }) => {
  return (
    <div className={"px-5 mb-3"}>
      {mode === Notes.mode.edit ? (
        <TextArea onChange={onChange} className="w-100" minRows={5}>
          {notes}
        </TextArea>
      ) : (
        notes
      )}
    </div>
  );
};

Notes.mode = {
  edit: "edit",
  show: "show"
};

export const useNotes = (startingNotes, onChange = () => {}) => {
  const [mode, setMode] = useState(Notes.mode.show),
    [notes, setNotes] = useState(startingNotes),
    toggleMode = mode => (mode === Notes.mode.show ? Notes.mode.edit : Notes.mode.show),
    toggle = () => setMode(toggleMode(mode));

  useEffect(() => {
    onChange(notes);
  }, [notes]);

  return [
    <Notes {...{ mode, notes, onChange: e => setNotes(e.target.value) }} />,
    { notes, isEditing: mode === Notes.mode.edit, setNotes, setMode, toggle }
  ];
};

export default Notes;
