import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { unpublishReply } from "../../../api/reviewApi";
import { useLocalNotifications } from "../../../components/Notifications/notification";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "../../../redux/actions/ajaxStatusActions";
import { QUICK_NOTIFICATION_TIMEOUT } from "../../../util/constants";

export default () => {
  const notify = useLocalNotifications();
  const dispatch = useDispatch();
  return useCallback(id => {
    dispatch(beginAjaxCall());
    return unpublishReply(id)
      .then(() => {
        dispatch(endAjaxCall());
        notify({
          timeout: QUICK_NOTIFICATION_TIMEOUT,
          icon: "success",
          title: "Reply unpublished"
        });
      })
      .catch(e => {
        dispatch(ajaxCallError());
        return Promise.reject(e);
      });
  });
};
