import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types"; // ES6
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Creatable from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import { FormContext } from "./Form";
import { get, noop, find, isBoolean, debounce } from "lodash";
import { DEBOUNCE_DELAY_DEFAULT } from "../../constants/delays";

export const SELECT_PORTAL_ID = "container-made-exclusively-for-select-field";

function toSelectOptions(opts) {
  if (Array.isArray(opts)) {
    return opts.map(s => Object.assign({}, { value: s, label: s }));
  } else if (opts) {
    return { value: opts, label: opts };
  }
  return opts;
}

function fromSelectOption(value) {
  if (Array.isArray(value)) {
    return value.map(v => v.value);
  } else if (value) {
    return value.value;
  }
  return value;
}

//toSelectValue doesn't work with selects that have objects as there value like timezones in customerEdit
const toSelectValue = (value, opts) => {
  if (Array.isArray(value)) {
    return value.map(v => find(opts, { value: v }));
  } else if (value || isBoolean(value)) {
    return find(opts, { value }) || find(opts, value);
  }
  return value;
};

export const DEFAULT_STYLES = {
  control: provided => ({
    ...provided,
    borderRadius: "0px",
    paddingLeft: "5px"
  })
};

// Select wrapper that formats events to look like standard html form events and
// adds some convenience around working with simple values
// simpleValue and multi are mutually exclusive
const SelectField = React.forwardRef(
  (
    {
      name,
      onChange,
      async,
      creatable,
      value,
      options,
      simpleValue = false,
      loadOptions,
      menuPortalTarget = document.getElementById(SELECT_PORTAL_ID),
      styles = DEFAULT_STYLES,
      ...props
    },
    ref
  ) => {
    const SelectComponent = useMemo(() => {
      if (async && creatable) return AsyncCreatableSelect;
      if (async) return AsyncSelect;
      if (creatable) return Creatable;
      return Select;
    }, [async, creatable]);

    const context = useContext(FormContext);

    useEffect(() => {
      context && context.registerField(name);
    }, [name]);

    const changeHandler = useMemo(() => {
      if (onChange) {
        return onChange;
      } else if (context) {
        return simpleValue
          ? context.setTextField
          : props.isMulti
            ? context.setSelectMultiValue
            : context.setSelectValue;
      }
      return noop;
    });

    const inputValue = useMemo(() => {
      // is the value explicitly provided or is a context unavailable?
      if (value || !name || !context) {
        return value;
      } else {
        return get(context.state, context.parseFieldName(name));
      }
    }, [value, context?.state, name]);

    const normalizedOptions = useMemo(() => (simpleValue ? toSelectOptions(options) : options), [simpleValue, options]);
    const normalizedValue = useMemo(
      //toSelectValue breaks existing dropdowns that have objects as values so we fall back to value if formContext is not used
      () => (simpleValue ? toSelectOptions(inputValue) : context ? toSelectValue(inputValue, options) : value),
      [simpleValue, value, inputValue, options]
    );

    const loadOptionsCallback = useMemo(
      () =>
        loadOptions &&
        debounce((inputValue, callback) => {
          loadOptions(inputValue, options => void callback(simpleValue ? toSelectOptions(options) : options));
        }, DEBOUNCE_DELAY_DEFAULT),
      [loadOptions]
    );

    return (
      <SelectComponent
        {...props}
        styles={{ ...styles, ...props.styleOptions }}
        menuPortalTarget={menuPortalTarget}
        ignoreCase={true}
        value={normalizedValue}
        options={normalizedOptions}
        loadOptions={loadOptionsCallback}
        ref={ref}
        onChange={inputValue =>
          //make it look like a standard form event
          changeHandler &&
          changeHandler({
            target: {
              name,
              type: "select",
              value: simpleValue ? fromSelectOption(inputValue) : inputValue
            }
          })
        }
      />
    );
  }
);

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  loadOptions: PropTypes.func,
  multi: PropTypes.bool
};

export default SelectField;
