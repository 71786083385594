import { useCallback, useMemo } from "react";
import { useGet, usePost } from "../dataHooks";
import { useQuery } from "react-query";

const BASE_URI = "/responseBot";

export const useResponseBotReview = (id, options = {}) => {
  return useResponseBot(id, "review", options);
};

export const useResponseBotPreflightReview = (id, options = {}) => {
  return useResponseBot(id, "preflight/review", options);
};

const useResponseBot = (id, type, options = {}) => {
  const queryKey = useMemo(() => `${BASE_URI}/${type}/${id}`, [id, type]);
  const [get] = useGet(queryKey);
  const queryRun = useCallback(() => get(), [get]);
  return useQuery(queryKey, queryRun, {
    ...options
  });
};

export const useResponseBotReviewReply = (reviewId, replyId, options = {}) => {
  const queryKey = useMemo(() => `${BASE_URI}/review/${reviewId}${replyId ? "/" + replyId : ""}`, [reviewId, replyId]);
  const [get] = useGet(queryKey);
  const queryRun = useCallback(() => get(), [get]);
  return useQuery(queryKey, queryRun, {
    ...options
  });
};

export const usePreGeneratedResponses = (ids, enabled = true) => {
  const queryKey = useMemo(() => [`${BASE_URI}/forIds`, ids], [ids]);
  const post = usePost();
  const queryRun = useCallback(() => post(`${BASE_URI}/forIds`, ids), [ids, post]);
  return useQuery(queryKey, queryRun, {
    enabled
  });
};
