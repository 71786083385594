import { useState } from "react";
import { useStateThroughRedux } from "./stateHooks";
import { uniq } from "lodash";

const useBulkUpdates = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [, setSettingsSection] = useStateThroughRedux("bulkUpdates/section", "bulkUpdates");
  const [, setBulkUpdatesOpened] = useStateThroughRedux("bulkUpdates/opened", true);
  const [useBulkFilters, setUseBulkFilters] = useState(false);

  const onIdToggle = id => toggle =>
    toggle ? setSelectedIds(prev => [...prev, id]) : setSelectedIds(prev => prev.filter(value => value !== id));

  const onAllIdToggle = ids => toggle =>
    toggle
      ? setSelectedIds(prev => uniq([...prev, ...ids]))
      : setSelectedIds(prev => prev.filter(value => !ids.includes(value)));

  const openBulkModal = useFilters => {
    setBulkUpdatesOpened(true);
    setSettingsSection("bulkUpdates");
    setUseBulkFilters(useFilters);
  };

  return {
    selectedIds,
    setSelectedIds,
    useBulkFilters,
    onIdToggle,
    onAllIdToggle,
    openBulkModal,
    setBulkUpdatesOpened
  };
};

export default useBulkUpdates;
