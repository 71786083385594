import React from "react";

import QueryString from "qs";
import StatusMessages from "components/Misc/StatusMessages";
import { exportInviteReport } from "api/reportApi";
import { Link } from "react-router-dom";
import WWButton from "components/Buttons/WWButton";
import { TableCard } from "components/Table/WWTable";
import { CardBody } from "components/Card";
import { withInviteReportFilterQuery } from "./RequestBy";
import WWTableBlock from "components/Table/WWTableBlock";
import { defaultPrepareFilters } from "hooks/data/useFilterQuery";

export const InvitesBySender = props => {
  const {
    data: { senders },
    isError,
    filterValues = {},
    filters
  } = props;

  const { dateRange } = filterValues,
    exportInvitesCsv = () =>
      exportInviteReport(defaultPrepareFilters(filterValues, null, filters)).then(res => {
        window.open(res.data, "_blank");
      });

  const schema = [
    {
      label: "Name",
      valueKey: "name",
      renderCell: ({ name }) => (
        <Link
          to={{
            pathname: "/reviews/feed",
            search: QueryString.stringify({
              representative: name,
              dateRange
            })
          }}
        >
          {name}
        </Link>
      )
    },
    { label: "Tag", valueKey: "tag" },
    {
      label: "Avg. Rating (Total)",
      renderCell: ({ rating, reviewTotal }) =>
        rating ? (
          <>
            {rating.toFixed(1)}
            <span className="ms-1 text-muted">({reviewTotal})</span>
          </>
        ) : (
          "N/A"
        )
    },
    {
      label: "Sent",
      sortOptions: ["sent,asc", "sent,desc"],
      renderCell: ({ sent, ctr }) => (
        <>
          {sent}
          <span className="ms-1 text-muted">({Math.round(ctr * 100)}%)</span>
        </>
      )
    },
    {
      label: "Review Page CTR",
      sortOptions: ["reviewPageCTR,asc", "reviewPageCTR,desc"],
      renderCell: ({ reviewPageCTR }) => <>{Math.round(reviewPageCTR * 100)}%</>
    }
  ];

  return (
    <TableCard>
      <CardBody>
        {isError && (
          <StatusMessages
            className="w-100"
            errors="Failed loading page. Note this report cannot be show for time ranges greater than 31 days."
          />
        )}

        <WWTableBlock
          {...props}
          title="Invites By Sender"
          schema={schema}
          data={senders}
          controls={() => (
            <WWButton className="btn-outline-secondary" onClick={exportInvitesCsv}>
              Export CSV
            </WWButton>
          )}
        />
      </CardBody>
    </TableCard>
  );
};

export default withInviteReportFilterQuery(InvitesBySender, {
  url: "/invitereport/bySender",
  defaultSort: "sent,asc",
  pageSize: 10,
  normalizeResponse: resp => ({
    ...resp,
    senders: Object.values(resp?._embedded?.invitesBySenderDToes || {}).map(
      ({ sender: [name, tag, sent, ctr, reviewPageCTR, rating, reviewTotal] }) => ({
        name,
        tag,
        sent,
        ctr,
        reviewPageCTR,
        rating,
        reviewTotal
      })
    )
  })
});
