import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { getAgencyPublishedReplies } from "../../api/reportApi";
import { Card, CardHeader, CardTitle } from "../../components/Card";
import DatePicker from "../../components/DateTime/DatePicker";
import { errorCaughtNotifier, useErrorNotification } from "../../components/Notifications/notification";
import { Table, TBody, TD, TH, THead, TR } from "../../components/Table";
import { format_percent } from "../Reports/util";

const ResponderPerformance = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [endDate, setEndDate] = useState(moment());
  const notify = useErrorNotification();

  useEffect(() => {
    getAgencyPublishedReplies(startDate.startOf("day").toISOString(true), endDate.endOf("day").toISOString(true))
      .then(v => {
        setData(v);
      })
      .catch(errorCaughtNotifier(notify));
  }, [setData, startDate, endDate]);

  const onTimerangeUpdate = useCallback((startDate, endDate) => {
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, []);

  const Responders = useMemo(
    () =>
      data.map(d => (
        <TR key={d.AUTHOR}>
          <TD>{d.AUTHOR}</TD>
          <TD>{d.TOTAL}</TD>
          <TD>
            {d.POSITIVES} ({format_percent(d.POSITIVES, d.TOTAL)})
          </TD>
          <TD>
            {d.NEGATIVES} ({format_percent(d.NEGATIVES, d.TOTAL)})
          </TD>
          <TD>
            {d.LONG} ({format_percent(d.LONG, d.TOTAL)})
          </TD>
          <TD>
            {d.SHORT} ({format_percent(d.SHORT, d.TOTAL)})
          </TD>
        </TR>
      )),
    [data]
  );

  return (
    <div className="py-4 px-xs-0 px-lg-4 container-fluid">
      <Row>
        <Col xs={12} className="d-flex justify-content-stretch justify-content-md-end">
          <Card>
            <DatePicker
              start={startDate}
              end={endDate}
              onChange={onTimerangeUpdate}
              quickSelect={true}
              numberOfMonths={2}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Card className="col-12">
            <Col>
              <CardHeader>
                <CardTitle>Responder Comparison</CardTitle>
              </CardHeader>
              <Table responsive>
                <THead>
                  <TR>
                    <TH>Author</TH>
                    <TH>Total</TH>
                    <TH>Positives</TH>
                    <TH>Negatives</TH>
                    <TH>Long</TH>
                    <TH>Short</TH>
                  </TR>
                </THead>
                <TBody>{Responders}</TBody>
              </Table>
            </Col>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ResponderPerformance;
