import axios from "axios";
import config from "../config";
import startsWith from "lodash/startsWith";

const baseUri = config.api + "/importlogs";

export function load(filter = null, limit = 20, page = 0) {
  return axios.get(baseUri, {
    params: {
      size: limit,
      page: page,
      sort: "startDate,desc",
      ...filter
    }
  });
}

export function listInbound(list, limit = 20, page = 0) {
  let params = {
    size: limit,
    page: page
  };

  if (list === "pending") return axios.get(config.api + "/inbound/yelp", { params });
  else if (list === "failed") return axios.get(config.api + "/inbound/dead", { params });
  else if (list === "processed") return axios.get(config.api + "/inbound/processed", { params });
}

export function fetchInbound(bucket, key, raw = false) {
  return axios.get(config.api + "/inbound/" + bucket + "/" + key, {
    params: {
      raw
    }
  });
}

export function deleteInbound(bucket, key) {
  return axios.delete(config.api + "/inbound/" + bucket + "/" + key);
}

export function queueImport() {
  return axios.post(config.api + "/import/inbound", null, {
    params: { source: "YELP" }
  });
}

export function requeue(bucket, key) {
  let path;
  if (startsWith(bucket, "processed")) {
    path = "/inbound/processed/requeue";
  } else {
    path = "/inbound/dead/requeue";
  }

  return axios.post(config.api + path, key, {
    headers: {
      "Content-type": "text/plain"
    }
  });
}

export function fetchCsvLinks(customerId, source, startDate, endDate) {
  return axios.get(config.api + "/import/csvLinks", {
    params: { customerId, source, startDate, endDate }
  });
}
