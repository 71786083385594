import { COMPARISON_OPTIONS, getComparisonsOptions, ReportWithFilters } from "components/HOCs/ReportWithFilters";
import VideoInvitesBySender from "./Widgets/VideoInvitesBySender";
import VideoSummary from "./Widgets/VideoSummary";
import VideosSubmitted from "./Widgets/VideoSubmitted";
import VideoInvitesSent from "./Widgets/VideoInvitesSent";
import VideoReportData from "./Widgets/VideoReportData";
import { datetime2iso, endOfTheDay, now, startOfTheDay } from "util/dateUtils";
import { LOCATION_TYPE } from "data/customers";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

const filtersFn = ({ isAdmin, filterValues = {} }) => [
  getAccountFilterConfig(),
  {
    name: "location",
    label: "Location",
    type: LOCATION_TYPE.allLocations,
    required: isAdmin && !filterValues?.account?.id,
    minLength: 0,
    primaryBar: {
      position: "LEFT"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "campaignNames",
    label: "Campaign",
    type: "campaign",
    minLength: 0,
    placeholder: "Search Campaigns...",
    primaryBar: {
      position: "LEFT",
      className: "d-none d-md-flex"
    },
    secondaryBar: {
      className: "d-flex d-md-none me-5"
    },
    chip: {
      position: "NONE"
    },
    additionalParams: {
      defaultSearchParams: {
        types: ["VIDEO", "VIDEO_FOLLOW_UP"]
      }
    }
  },
  {
    name: "dateRange",
    label: "Date range",
    type: "dates",
    queryParams: ["startDate", "endDate"],
    required: true,
    defaultValue: {
      startDate: datetime2iso(startOfTheDay(now().startOf("month"))),
      endDate: datetime2iso(endOfTheDay(now()))
    },
    primaryBar: {
      position: "LEFT",
      className: "d-md-flex auto-width d-none"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "compareMode",
    label: "Compare to...",
    type: "buttonDropdown",
    required: true,
    showLabelInMenu: true,
    clearable: false,
    defaultValue: COMPARISON_OPTIONS.RELATIVE,
    options: getComparisonsOptions(filterValues?.dateRange),
    placeholder: "Compare to...",
    primaryBar: {
      position: "RIGHT",
      className: "d-none d-md-flex comparison-selector"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "page",
    defaultValue: 0,
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  }
];

const InviteVideoReporting = () => (
  <div className="py-4 px-xs-0 px-lg-4 container-fluid">
    <ReportWithFilters filtersFn={filtersFn}>
      <VideoSummary />
      <VideosSubmitted />
      <VideoInvitesSent />
      <VideoReportData />
      <VideoInvitesBySender />
    </ReportWithFilters>
  </div>
);

export default InviteVideoReporting;
