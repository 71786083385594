import { useCallback, useState, memo, useEffect, useMemo } from "react";
import DeleteButton from "components/Buttons/DeleteButton";
import classNames from "classnames";
import styles from "./MediaCard.module.scss";
import AjaxLoader from "components/Misc/AjaxLoader";
import { DEFAULT_IMAGE_ACCEPT, DEFAULT_VIDEO_ACCEPT } from "data/listings";
import InputField from "components/Form/InputField";
import { useCheckMediaStatus, useUploadMedia } from "hooks/data/mediaHooks";
import { Alert } from "reactstrap";
import PercentProgress from "components/Misc/PercentProgress";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import { isUndefined } from "lodash";
import Label from "components/Form/Label";

const {
  mediaDeleteButtonClass,
  mediaVideoDeleteButtonClass,
  mediaWrapperClass,
  mediaContentContainer,
  loadedClass,
  errorMessage
} = styles;

const MediaCard = ({
  isVideo,
  name,
  media,
  deleteCallback,
  showMedia,
  showInput = true,
  helperText,
  inputName,
  isUploading,
  options = {},
  autoplay = false,
  accept = isVideo ? DEFAULT_VIDEO_ACCEPT : DEFAULT_IMAGE_ACCEPT,
  preProcessingCallback,
  onSuccessCallback,
  onFailCallback,
  processingUploadText = `You may save and close the page while the image finishes processing.`,
  disabled,
  ...props
}) => {
  const [loaded, setLoaded] = useState(!!isVideo);
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState();
  const [pctComplete, setPctComplete] = useState(0);

  const { data, isLoading } = useCheckMediaStatus(media?.id, {
    enabled: !!media?.id && !(status === "LIVE" || status === "REMOVED" || status === "ERROR"),
    refetchInterval: 2000
  });

  const showDeleteButton = useMemo(() => (!isVideo && !uploading) || status === "ERROR", [isVideo, uploading, status]);
  const showLoader = useMemo(() => status === "PENDING" || isLoading || isUndefined(status), [isLoading, status]);

  useEffect(() => {
    setStatus(data?.status || (isLoading || uploading ? "LOADING" : "UNDEFINED"));
  }, [data, isLoading, uploading]);

  const deleteMediaHandler = useCallback(() => {
    setUploading(false);
    setStatus();
    return deleteCallback(media?.id);
  }, [media?.id]);

  const onLoadHandler = useCallback(() => {
    setLoaded(true);
  }, []);

  const uploadMedia = useUploadMedia();

  const onChangeHandler = useCallback(
    file => {
      const processedFile = preProcessingCallback(file);
      const mediaOptions = isVideo
        ? { ...options, type: "VIDEO", format: processedFile?.type?.split(";")?.[0] }
        : options;
      if (processedFile) {
        setUploading(true);
        uploadMedia(processedFile, { ...mediaOptions, onPercentComplete: setPctComplete })
          .then(media => {
            setUploading(false);
            onSuccessCallback(media, inputName);
          })
          .catch(error => {
            setUploading(false);
            return onFailCallback(error);
          });
      }
    },
    [preProcessingCallback, onSuccessCallback, onFailCallback, options]
  );

  if (showMedia || uploading) {
    return (
      <>
        <div className={classNames(mediaWrapperClass, { [loadedClass]: loaded })}>
          <div className={mediaContentContainer}>
            {status === "LIVE" && (
              <>
                {isVideo ? (
                  <VideoPlayer
                    onLoad={onLoadHandler}
                    autoplay={autoplay}
                    disabled={disabled}
                    onRemove={deleteMediaHandler}
                    previewSrc={data.targetUrl}
                  />
                ) : (
                  <img onLoad={onLoadHandler} key={data.targetUrl} src={data.targetUrl} alt={name} />
                )}
              </>
            )}
            {uploading && <PercentProgress progress={pctComplete} strokeWidth={7} fontSize=".7rem" />}
            {showLoader && (
              <div className="d-flex justify-content-center flex-wrap">
                <AjaxLoader />
                {status === "PENDING" && (
                  <div className="mx-3">
                    <Label className="mb-0 fw-bold text-success">Upload complete!</Label> {processingUploadText}
                  </div>
                )}
              </div>
            )}
            {(status === "ERROR" || status === "UNDEFINED") && (
              <Alert color="danger" className={errorMessage}>
                An Error occurred in processing this {isVideo ? "video" : "image"}
              </Alert>
            )}
          </div>
          {showDeleteButton && (
            <DeleteButton
              type="default"
              children={null}
              disabled={disabled}
              className={classNames(mediaDeleteButtonClass, { [mediaVideoDeleteButtonClass]: isVideo })}
              onClick={deleteMediaHandler}
            />
          )}
        </div>
      </>
    );
  } else if (showInput) {
    return (
      <>
        {uploading && <AjaxLoader loading />}
        <InputField
          disabled={disabled}
          accept={accept}
          className="mb-sm-0"
          name={inputName}
          type="file"
          onChange={onChangeHandler}
          inline={false}
          {...props}
        />
        {helperText && <div className="font-xs text-muted">{helperText}</div>}
      </>
    );
  }
};

export default memo(MediaCard);
