import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

/**
 * A fancier checkbox
 */
const Toggle = ({
  value,
  label,
  name,
  onClick,
  className,
  toggledIcon = "fa-check-square-o",
  unToggledIcon = "fa-square-o"
}) => {
  const click = useCallback(
    () =>
      onClick({
        target: {
          name,
          value: !value
        }
      }),
    [onClick, name, value]
  );

  const iconStyle = useMemo(
    () => ({
      width: "16px",
      height: "16px"
    }),
    []
  );

  const controlStyle = useMemo(
    () => ({
      cursor: "pointer"
    }),
    []
  );

  const icon = value ? toggledIcon : unToggledIcon;

  return (
    <span className={className} style={controlStyle} onClick={click}>
      <i style={iconStyle} className={`fa ${icon} me-2`} aria-hidden="true" />
      {label}
    </span>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  toggledIcon: PropTypes.string,
  unToggledIcon: PropTypes.string
};

export default Toggle;
