import { get, patch, post, rejectNilParams } from "./apiClient";
import axios from "axios";

/**
 * @returns {Promise<Array<{
 *   customer: {
 *     companyName: string;
 *     id: string
 *   }
 *   mobile: string;
 *   interactions: Array<{
 *     invitations: any[];
 *     representatives: string[];
 *     tags: string[];
 *     id: string;
 *   }>;
 *   email: string;
 *   name: string;
 *   id: string;
 * }>>}
 */
export const getLeadsList = (filters = []) =>
  get(["reviewLeads"], {
    params: filters.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
  }).then(({ data }) => data);

/**
 *
 * @param {{
 *   name: string;
 *   email: string;
 *   mobile: string;
 *   optOut: boolean;
 *   interactions: Array<{
 *     tags: string[];
 *   }>
 * }} invite,
 * @param {string} customerid
 * @param {boolean} bypassMatching
 */
export const createLead = (invite, customerid, forceInvite, campaignId) =>
  post(["reviewLeads", "customer", customerid], invite, {
    params: { forceInvite, campaignId }
  });

/**
 * @param {string} id
 */
export const getLeadDetails = id =>
  get(["reviewLeads", id], { params: { includeInteractions: false } }).then(({ data }) => data);

/**
 *
 * @param {{
 *  representatives: string[];
 *  tags: string[];
 *  invitations: any[];
 *  reviews: any[];
 *  notes: string[];
 *  visitTime: string;
 * }} interaction
 *
 * @returns {(reviewLeadId: string) => Promise<any>} function
 */
export const addInteraction = interaction => reviewLeadId =>
  post(["reviewLeads", reviewLeadId, "interactions"], interaction).then(({ data }) => data);

export const getInteraction = id => get(["interactions", id]).then(({ data }) => data);

/**
 * 
 * @param {{
    representatives: string[];
    tags: string[];
    invitations: any[];
    reviews: any[];
    notes: string[];
    visitTime: string;
   }} interaction 
 * 
 * @returns {(reviewLeadId: string) => Promise<any>} function
 */
export const updateInteraction = interaction => id => patch(["interactions", id], interaction).then(({ data }) => data);

/**
 * 
 * @param {{
     name: string;
     email: string;
     mobile: string;
   }} leadPatch`,
 */
export const updateLead = leadPatch => leadId => patch(["reviewLeads", leadId], leadPatch).then(({ data }) => data);

/**
 *
 * @param {{
 *   status: "SENT" | "OPENED" | "FAILED";
 *   deliveryMethod: "EMAIL" | "SMS";
 *   statusMessage: string;
 *   source: string;
 *   resultReason: string;
 * }} invitation
 */
export const addInvitation = invitation => interactionId =>
  post(["interactions", interactionId, "invitations"], invitation).then(({ data }) => data);

export const uploadLeadsList = (file, sendInvites, url) => {
  let formData = new FormData();
  formData.append("file", file);
  // formData.append("sendInvites", sendInvites);
  return axios.put(url, formData, {
    headers: {
      "Content-type": "multipart/form-data"
    }
  });
};

export function getFileUploadUrl(locationId, source, campaignId = undefined) {
  return get(["reviewLeads", "customer", locationId, "s3Upload"], {
    params: { source, campaignId }
  });
}

export const getInvitesReportData = params => {
  // const mockDataSource = new Array(30).fill().map((_, i) => i);
  // const mockGranularity = ["day"];
  return get(["invitereport"], {
    params: rejectNilParams(params)
  }).then(v => v.data);
};
