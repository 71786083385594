// uncomment this line if you have something to import from your scss module
import classnames from "classnames";
import { noop } from "lodash";
import styles from "./CardButton.module.scss";
import { Card } from "../Card";

const { card_selected, selected_icon, card } = styles;

const CardButton = ({ icon, title, selected, disabled, className, children, onClick, withoutTitle = false }) => {
  return (
    <Card
      role="button"
      onClick={disabled ? noop : onClick}
      className={classnames(card, className, { [card_selected]: selected })}
    >
      {selected && <i className={"fa fa-check-circle " + selected_icon} />}
      {!withoutTitle && (
        <span className="mb-2">
          <i className={icon} /> <strong>{title}</strong>
        </span>
      )}
      {children}
    </Card>
  );
};

export default CardButton;
