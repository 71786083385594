import React from "react";
import { LineChart, Line } from "recharts";
import colors from "../../constants/colors";
import { LineTypes, LINE_CHART_HEIGHT, LINE_CHART_WIDTH, STROKE_DASHARRAY, STROKE_WIDTH } from "../../constants/chart";

export const ExampleLine = ({ dashed = false, color = colors.chartStokeYellow }) => (
  <div style={{ width: LINE_CHART_WIDTH, height: LINE_CHART_HEIGHT }}>
    <LineChart width={LINE_CHART_WIDTH} height={LINE_CHART_HEIGHT} data={exampleLineData}>
      <Line
        isAnimationActive={false}
        type={LineTypes.monotone}
        dataKey="v"
        stroke={color}
        strokeWidth={STROKE_WIDTH}
        dot={false}
        {...(dashed ? { strokeDasharray: STROKE_DASHARRAY } : {})}
      />
    </LineChart>
  </div>
);

const exampleLineData = [{ v: 0.2 }, { v: 0.2 }, { v: 0.5 }, { v: 0.8 }, { v: 0.8 }];
