import { useGet, usePut } from "hooks/dataHooks";
import { useQuery, useQueryClient, useMutation } from "react-query";

const SIZE_TO_GET_ALL = 1000;

export const useResponderNotes = (params, options) => {
  const queryKey = ["responderNoteRule", params];
  const [get] = useGet("responderNoteRule", { params, size: SIZE_TO_GET_ALL });
  return useQuery(queryKey, get, {
    select: data => data?._embedded?.responderNoteRuleDtoes || [],
    ...options
  });
};

export const useResponderNotesByReviewId = reviewId => {
  const [get] = useGet(["reviews", reviewId, "responderNotes"]);
  return useQuery(["responderNote", reviewId], get, { select: data => data || [] });
};

export const useResponderNotesUpdate = ({ params }) => {
  const put = usePut();
  const queryClient = useQueryClient();
  const mutation = useMutation(data => put(["responderNoteRule"], { data, params }), {
    onSuccess: () => queryClient.invalidateQueries(["responderNoteRule", params])
  });
  return mutation.mutateAsync;
};
