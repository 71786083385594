import _, { isUndefined, isArray } from "lodash";
import { adjust, fromPairs, keys, length, map, pipe, toPairs } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import classNames from "classnames";
import * as reportApi from "api/reportApi";
import * as commonApi from "api/commonApi";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import { errorCaughtNotifier, useLocalNotifications } from "components/Notifications/notification";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import FilterTableSort from "components/FacetedSearch/Sort/FilterTableSort/FilterTableSort";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import { TableCard, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import ReviewListRow from "./ReviewListRow";
import {
  RATINGS_LABEL_PAIRS,
  REVIEW_STATUS_LABEL_VALUE,
  REVIEW_TYPE_LABEL_VALUE,
  RESPONDER_FLAG_VALUES,
  reviewQueueDateSortOptions,
  YES_NO_OPTIONS,
  REVIEW_BULK_FIELD_OPTIONS
} from "data/options";
import { useFirebaseEventListener, useFirebaseRef } from "hooks/firebaseHooks";
import useFilterQuery, { defaultPrepareFilters } from "hooks/data/useFilterQuery";
import { useFilterState, useFilterValueState } from "hooks/filteringHooks";
import { LANGUAGE_CODES_LABEL_VALUE } from "util/reviewUtils";
import { showSkeleton } from "util/showSkeleton";
import { datetime2iso, daysBeforeNow, endOfTheDay, now, startOfTheDay } from "util/dateUtils";
import { RESPONDER_FLAG_OPTIONS } from "components/Reviews/FlagSelectDropdown/FlagSelectDropdown";
import { FIVE_MINUTES_MILLISECONDS } from "constants/time";
import Checkbox from "components/Checkbox/Checkbox";
import useBulkUpdates from "hooks/bulkUpdatesHooks";
import BottomBanner from "components/Modals/BottomBanner/BottomBanner";
import WWButton from "components/Buttons/WWButton";
import BulkUpdatesModal from "components/BulkUpdates/BulkUpdatesModal";
import { updateBulkReviews } from "api/reviewApi";

import styles from "./ReviewList.module.scss";
import { getReviewSourceFilterConfig } from "components/FacetedSearch/FilterRecipes";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

const { tableContainerClassnames, lockColorClass, multySelectSecondaryClass, locationFilter } = styles;

const UNSELECTIVE_STATUSES = ["REPLIED", "NO_ACTION"];
const RESPONDER_OPTIONS = [
  ...RESPONDER_FLAG_OPTIONS,
  {
    value: RESPONDER_FLAG_VALUES.doNotRespond,
    label: "Do not respond",
    iconClass: classNames("fa fa-lock", lockColorClass)
  }
];
export const statusIsNotSelective = status =>
  isUndefined(status) ||
  (isArray(status) && status.some(v => UNSELECTIVE_STATUSES.includes(v))) ||
  UNSELECTIVE_STATUSES.includes(status);

export const filtersRequireDate = filters => {
  if (isUndefined(filters)) return false;
  const { status, dateRange, customerId, sort } = filters;
  return !!sort && isUndefined(dateRange) && isUndefined(customerId) && statusIsNotSelective(status);
};

const exportCsv = filter =>
  reportApi.exportReviewListCSV(filter).then(res => {
    window.open(res.data, "_blank");
  });

export const ReviewList = () => {
  const [pageInfo, setPageInfo] = useState();
  const [filterValue] = useFilterState();
  const [, setDateRangeFilter] = useFilterValueState("dateRange");

  useEffect(() => {
    if (filtersRequireDate(filterValue)) {
      setDateRangeFilter({
        startDate: datetime2iso(startOfTheDay(daysBeforeNow(30))),
        endDate: datetime2iso(endOfTheDay(now()))
      });
    }
  }, [setDateRangeFilter, filterValue]);

  const loadBrandOptions = useCallback(
    (inputValue, callback) =>
      commonApi
        .fetch("/tags")
        .then(res => res.data)
        .then(options => callback(_.filter(options, o => _.startsWith(_.toLower(o), _.toLower(inputValue))))),
    []
  );

  const notify = useLocalNotifications();

  const onExport = useCallback(() => {
    exportCsv(defaultPrepareFilters(filterValue, undefined, filters)).catch(errorCaughtNotifier(notify));
  }, [filterValue, filters]);

  const filters = useMemo(
    () => [
      {
        name: "dateRange",
        label: "Date range",
        type: "dates",
        queryParams: ["startDate", "endDate"],
        chip: {
          position: "NONE"
        },
        primaryBar: {
          position: "LEFT",
          className: "d-none d-md-flex"
        }
      },
      getAccountFilterConfig(),
      {
        name: "customerId",
        label: "Location",
        type: "customer",
        primaryBar: {
          position: "LEFT",
          className: classNames("d-none d-md-flex", locationFilter)
        }
      },
      {
        name: "notCustomerId",
        label: "Exclude Location",
        type: "customer"
      },
      {
        name: "download",
        label: "Download",
        type: "button",
        onClick: onExport,
        className: "ww-font-xs",
        icon: "fa fa-download",
        transparent: true,
        offCanvas: { position: "TOP" }
      },
      {
        name: "status",
        label: "Status",
        type: "multiSelect",
        options: REVIEW_STATUS_LABEL_VALUE,
        defaultValue: "UNREPLIED",
        placeholder: "select an option",
        primaryBar: {
          position: "LEFT",
          className: "d-none d-sm-flex"
        },
        secondaryBar: {
          position: "LEFT",
          className: classNames("d-flex d-sm-none mw-100", multySelectSecondaryClass)
        },
        chip: {
          position: "NONE"
        }
      },
      {
        name: "rating",
        label: "Rating",
        type: "multiSelect",
        options: RATINGS_LABEL_PAIRS,
        primaryBar: {
          position: "LEFT",
          className: "d-none d-md-flex"
        },
        chip: {
          position: "NONE"
        }
      },
      getReviewSourceFilterConfig(),
      {
        name: "type",
        label: "Type",
        type: "checkbox",
        options: REVIEW_TYPE_LABEL_VALUE
      },
      {
        name: "languages",
        label: "Language",
        type: "buttonDropdown",
        options: LANGUAGE_CODES_LABEL_VALUE,
        placeholder: "select an option"
      },
      {
        name: "brandsInclude",
        label: "Brand",
        type: "select",
        icon: "fa fa-building",
        async: true,
        isMulti: true,
        creatable: false,
        simpleValue: true,
        inline: true,
        loadOptions: loadBrandOptions
      },
      {
        name: "brandsExclude",
        label: "Exclude Brand",
        type: "select",
        icon: "fa fa-building",
        async: true,
        isMulti: true,
        creatable: false,
        simpleValue: true,
        inline: true,
        loadOptions: loadBrandOptions
      },
      {
        name: "responderFlag",
        label: "Responder Flag",
        type: "buttonDropdown",
        options: RESPONDER_OPTIONS,
        placeholder: "select an option",
        transformOptionToNode: ({ label, iconClass }) => (
          <>
            {!!iconClass && <i className={iconClass} />}
            {label}
          </>
        )
      },
      {
        name: "customerWithResponderNotes",
        label: "With Responder Notes",
        type: "buttonDropdown",
        options: YES_NO_OPTIONS,
        placeholder: "Any"
      },
      {
        name: "hipaaCompliant",
        label: "HIPAA Compliant",
        type: "buttonDropdown",
        options: YES_NO_OPTIONS,
        placeholder: "Any"
      },
      {
        name: "replyAuthor",
        label: "Reply Author",
        type: "contact",
        agencyResponder: true
      },
      {
        name: "author",
        label: "Author",
        type: "text",
        icon: "fa fa-user"
      },
      {
        name: "reviewCount",
        label: "Reviews:",
        type: "slab",
        value: pageInfo?.totalElements || 0,
        offCanvas: {
          position: "NONE"
        },
        chip: {
          position: "NONE"
        },
        primaryBar: {
          position: "RIGHT"
        }
      },
      {
        name: "sort",
        defaultValue: "reviewDate,asc",
        options: ["reviewDate,desc", "reviewDate,asc"],
        required: true,
        nonFilter: true,
        offCanvas: {
          position: "NONE"
        },
        chip: {
          position: "NONE"
        }
      },
      {
        name: "page",
        defaultValue: 0,
        required: true,
        nonFilter: true,
        offCanvas: {
          position: "NONE"
        },
        chip: {
          position: "NONE"
        }
      }
    ],
    [pageInfo, onExport]
  );

  const reviewsQuery = useFilterQuery(
    {
      filters,
      url: `/reviews`,
      projection: "list",
      additionalParams: { requireFilters: true }
    },
    {
      onSuccess: data => {
        setPageInfo(data.pageInfo);
      },
      refetchInterval: FIVE_MINUTES_MILLISECONDS
    }
  );
  const reviews = useMemo(() => reviewsQuery?.data?.data?.reviewListDtoes || [], [reviewsQuery]);

  const [reviewsBeingEdited, setReviewsBeingEdited] = useState({});
  const onUpdate = useCallback(data => setReviewsBeingEdited(convertData(data.val())), []);
  const ref = useFirebaseRef("review_reply");
  useFirebaseEventListener(ref, "value", onUpdate);

  const { selectedIds, setSelectedIds, useBulkFilters, onIdToggle, onAllIdToggle, openBulkModal } = useBulkUpdates();

  return (
    <AuthorizationRequiredToRender roles={[permissions.REVIEW_READ]}>
      <div className="py-4 px-xs-0 px-lg-4 container-fluid">
        <FacetedSearch filters={filters} />
        <Row>
          <Col>
            <TableCard className="border-top-0 pt-0">
              <WWTable tableContainerClassnames={tableContainerClassnames}>
                <WWTHead>
                  <WWTR>
                    <AuthorizationRequiredToRender roles={[permissions.RESPONSE_MANAGER]}>
                      <WWTH>
                        <Checkbox
                          checked={reviews.map(review => review.id).every(value => selectedIds.includes(value))}
                          onToggle={onAllIdToggle(reviews?.map(review => review?.id))}
                        />
                      </WWTH>
                    </AuthorizationRequiredToRender>
                    <WWTH width="12%">
                      <FilterTableSort label="Date" options={reviewQueueDateSortOptions} />
                    </WWTH>
                    <WWTH width="10%">Site</WWTH>
                    <WWTH width="15%">
                      <FilterTableSort
                        label="Customer"
                        options={["customer_companyName,asc", "customer_companyName,desc"]}
                      />
                    </WWTH>
                    <WWTH>Title</WWTH>
                    <WWTH width="9%">Rating</WWTH>
                    <WWTH width="12%">Status</WWTH>
                  </WWTR>
                </WWTHead>
                {showSkeleton(reviewsQuery) ? (
                  <TableBodySkeleton rows={10} cols={6} />
                ) : (
                  <>
                    {reviews.length > 0 ? (
                      <WWTBody>
                        <>
                          {reviews.map((review, index) => (
                            <ReviewListRow
                              isSelected={selectedIds.includes(review?.id)}
                              onReviewToggle={onIdToggle(review.id)}
                              review={review}
                              index={index}
                              key={review.id}
                              beingEdited={reviewsBeingEdited[review.id] > 0}
                            />
                          ))}
                        </>
                      </WWTBody>
                    ) : (
                      <WWTD colSpan={6}>
                        <h2 style={{ textAlign: "center", width: "100%" }}>
                          All caught up!
                          <span className="ms-2 fa fa-thumbs-o-up" />
                        </h2>
                      </WWTD>
                    )}
                  </>
                )}
              </WWTable>
            </TableCard>
            <FilterPagination
              isLoading={showSkeleton(reviewsQuery)}
              pageInfo={pageInfo}
              totalPages={pageInfo?.totalPages}
              elementsLabel="Reviews"
            />
          </Col>
        </Row>
      </div>
      {selectedIds.length > 0 && (
        <BottomBanner>
          <div className="d-flex justify-content-center flex-wrap">
            <WWButton color="primary" onClick={() => openBulkModal(false)}>
              {`BULK UPDATE (${selectedIds.length} REVIEWS)`}
            </WWButton>
            <WWButton contentClass="fs-3" color="link" onClick={() => openBulkModal(true)}>
              Update all {pageInfo?.totalElements} reviews that match filters
            </WWButton>
          </div>
          <WWButton contentClass="" className="justify-end" size="sm" color="link" onClick={() => setSelectedIds([])}>
            Clear Selection
          </WWButton>
        </BottomBanner>
      )}
      <BulkUpdatesModal
        label="Reviews"
        type="review"
        options={REVIEW_BULK_FIELD_OPTIONS}
        bulkUpdateCallback={updateBulkReviews}
        selectedIds={useBulkFilters ? null : selectedIds}
        filters={useBulkFilters ? defaultPrepareFilters(filterValue) : null}
        numberOfIds={useBulkFilters ? pageInfo.totalElements : selectedIds?.length}
      />
    </AuthorizationRequiredToRender>
  );
};

// Lets describe what is happening here:
// we have input like following:
// ```
// {
//   "a": {
//     "b": "c",
//     "d": "e"
//   },
//   "f": {
//     "g": "h"
//   }
// }
const convertData = pipe(
  toPairs, // [["a", { "b": "c", "d": "e"}], ["f", { "g": "h" }]]
  map(
    // iterate over input with function defined next
    // lets consider what is happening with the first element of list
    adjust(
      1, // modify second item of tuple
      pipe(
        keys, // ["a", ["b", "d"]]
        length // ["a", 2]
      )
    )
  ), // [["a", 2], ["f", 1]]
  fromPairs // { "a": 2, "f": 1 }
);
