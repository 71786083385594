import React from "react";
import { Table as ReactstrapTable } from "reactstrap";

export const Table = ReactstrapTable;

export const THead = props => <thead {...props} />;
export const TBody = props => <tbody {...props} />;

/**
 * @param {React.HTMLAttributes<HTMLTableRowElement>} props
 */
export const TR = props => <tr {...props} />;

/**
 * @param {React.HTMLAttributes<HTMLTableHeaderCellElement>} props
 */
export const TH = props => (
  <th {...props} className={props?.className || "border-top-0 fw-normal ps-0 pe-2 py-3 text-muted"} />
);

/**
 * @param {React.HTMLAttributes<HTMLTableDataCellElement>} props
 */
export const TD = props => (
  <td {...props} className={props.className || "border-bottom-0 ps-0 pe-2 py-4 align-middle"} />
);
