import moment from "moment";
import { omit, get } from "lodash";
import { convertLocationFilter } from "hooks/data/useFilterQuery";

export const VIDEO_FORMATS = Object.freeze({
  highRes: "high-res",
  thumbnail: "thumbnail",
  original: "original",
  web: "web"
});

export const DisplayedVideoFormats = Object.freeze({
  [VIDEO_FORMATS.highRes]: "HD",
  [VIDEO_FORMATS.web]: "Web",
  [VIDEO_FORMATS.original]: "Original"
});

export const getSrcWithFormat = ({ formats }, videoFormat) =>
  formats?.length > 0 ? formats?.find(({ format }) => format === videoFormat)?.targetUrl : null;

export const transformVideoData = video => ({
  id: video.id,
  formats:
    video.formats?.length > 0
      ? video.formats
          .filter(({ type }) => type === "VIDEO")
          .map(({ format, width, height, targetUrl }) => ({
            format: DisplayedVideoFormats[format],
            name: `${DisplayedVideoFormats[format]} (${height}p)`,
            src: `${targetUrl}?download`,
            width,
            height
          }))
      : undefined,
  src: !video.formats?.length ? video.targetUrl : null,
  previewSrc: getSrcWithFormat(video, VIDEO_FORMATS.web) || `${video.targetUrl}_web.mp4`,
  thumbnail: getSrcWithFormat(video, VIDEO_FORMATS.thumbnail) || `${video.targetUrl}_thumb-00001.png`,
  signed: video.signedRelease,
  duration: video.duration,
  meta: {
    date: moment(video.createdOn),
    category: video.interaction.tags,
    campaign: video.interaction.campaign?.name,
    author: `${video.interaction.reviewLead.firstName} ${video.interaction.reviewLead.lastName}`,
    companyName: video.interaction.reviewLead.customer.companyName,
    location: video.interaction.reviewLead.customer.id,
    representative: (video.interaction.representatives || []).map(r => `${r.firstName} ${r.lastName}`)
  },
  redeemedIncentive: video.redeemedIncentive
});

export const normalizeVideoResponse = data => ({
  entries: data?._embedded && data._embedded.interactionMediaListDtoes.map(transformVideoData),
  pageInfo: data?.page
});

export const videoLibraryPrepareFilters = (filters, projection, filterConfig) => {
  const apiFieldTransformRules = {
    author: {
      apiFieldName: "interaction.author"
    },
    campaign: {
      apiFieldName: "interaction.invitation.campaign",
      path: ["campaign", "id"]
    },
    tags: {
      apiFieldName: "interaction.tags"
    }
  };
  const fieldsForTransform = Object.keys(apiFieldTransformRules);
  const [locationFilterNames, convertedLocationValues] = convertLocationFilter(filters, filterConfig);
  const normalized = {
    ...omit(filters, ["dateRange", ...fieldsForTransform, ...locationFilterNames]),
    ...Object.fromEntries(convertedLocationValues),
    ...filters?.dateRange
  };
  fieldsForTransform.forEach(field => {
    const rule = apiFieldTransformRules[field];
    const fieldValue = filters?.[field];
    if (fieldValue) {
      normalized[rule.apiFieldName] = get(filters, rule.path, fieldValue);
    }
  });
  return normalized;
};
