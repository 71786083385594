import WWChip from "../../../WWChips/WWChip/WWChip";
import { useFilterValueState } from "hooks/filteringHooks";
import Input from "components/Form/Input";
import { InputGroup, InputGroupText } from "reactstrap";
import styles from "./FreeTextFilter.module.scss";
import classNames from "classnames";
import { valueOf } from "util/functionUtils";

const { input } = styles;

const FreeTextFactory = () => {
  const barComponent = ({ ...props }, className) => (
    <FreeTextFilterBar key={props.name} className={className} {...props} />
  );
  const chip = ({ ...props }, className) => <FreeTextChip key={props.name} {...props} className={className} />;
  const filterComponent = ({ ...props }, className) => (
    <FreeTextFilterSidebar key={props.name} {...props} className={className} />
  );

  return {
    barComponent,
    chip,
    filterComponent
  };
};

export const FreeTextFilterBar = ({
  className,
  name,
  label,
  icon,
  inputType = "text",
  pattern,
  placeholder,
  ...props
}) => {
  const [value, setter] = useFilterValueState(name);
  return (
    <InputGroup key={name} className={classNames(className, "flex-nowrap", input)}>
      <InputGroupText className={`${icon} d-none d-md-block`} />
      <Input
        type={inputType}
        pattern={pattern}
        placeholder={placeholder || label}
        value={value}
        label={label}
        name={name}
        onChange={e => setter(e.target.value)}
      />
    </InputGroup>
  );
};

export const FreeTextChip = ({ name, className, iconClass, defaultValue, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return value ? (
    <WWChip
      iconClass={iconClass}
      key={name}
      className="overflow-visible"
      onClick={() => setter(valueOf(defaultValue))}
      {...props}
    >
      {value}
    </WWChip>
  ) : null;
};

export const FreeTextFilterSidebar = ({ name, label, className, inputType = "text", pattern, ...props }) => {
  const [value, setter] = useFilterValueState(name);
  return (
    <InputGroup key={name} className={className}>
      <Input
        type={inputType}
        pattern={pattern}
        placeholder={label}
        value={value}
        label={label}
        name={name}
        onChange={e => setter(e.target.value)}
      />
    </InputGroup>
  );
};

export default FreeTextFactory;
