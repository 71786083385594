import classNames from "classnames";
import DeleteButton from "components/Buttons/DeleteButton";
import Time from "components/DateTime/Time";
import StatusInfo from "components/Table/StatusInfo/StatusInfo";
import { WWTD, WWTR } from "components/Table/WWTable";
import { siteName } from "data/sites";
import { startsWith } from "lodash";
import { createSiteLogoUrl, siteInfoLocationDescriptor, siteInfoUrl } from "util/siteUtils";
import SiteDescriptor from "../SiteDescriptor";
import styles from "../SiteRowRenderers.module.scss";

const DefaultSiteRowRenderer = ({ index, onRemove, rowClicked, site }) => {
  return (
    <>
      <WWTR noHover>
        <WWTD className="ps-0 pt-0">
          <div className={classNames(" d-flex flex-nowrap align-items-center mb-2", { "mt-5": index !== 0 })}>
            <img className={styles.logo} alt={`${siteName(site.source)} Logo`} src={createSiteLogoUrl(site.source)} />
            <h4 className={"m-0 ms-2 " + styles.siteLabel}>{siteName(site.source)}</h4>
          </div>
        </WWTD>
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
      </WWTR>
      {site.siteInfo.map(info => (
        <WWTR
          onClick={site.userLinking ? () => rowClicked(site.source) : undefined}
          noHover={!site.userLinking}
          key={info.id}
        >
          <WWTD>
            <SiteDescriptor descriptor={siteInfoLocationDescriptor(info)} url={siteInfoUrl(info)} />
          </WWTD>
          <WWTD>
            {info?.sharedCredentials && (
              <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                <span className="font-xs text-uppercase">Shared Credentials</span> {info.sharedCredentials.name}
              </div>
            )}
          </WWTD>
          <WWTD />
          <WWTD>
            {info.defaultReviewTag && (
              <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                <span className="font-xs">TAG</span> {info.defaultReviewTag}
              </div>
            )}
          </WWTD>
          <WWTD>
            {info?.importStatus && (
              <div className={styles.tableCell}>
                <StatusInfo
                  status={startsWith(info.importStatus, "ERROR")}
                  statusColorMapper={{ true: "danger", false: "success" }}
                >
                  {info.importStatus}
                </StatusInfo>
                <Time className="font-xs text-uppercase" withIcon={false} format="ago" date={info.lastImportSuccess} />
                <span className="font-xs"> AGO</span>
              </div>
            )}
          </WWTD>
          <WWTD>
            {site.userLinking && (
              <DeleteButton
                type="button"
                size="sm"
                tooltip="Remove Site"
                squareButton
                iconClass="fa fa-chain-broken"
                confirmationPrompt="Widewail will no longer import data from this location. Existing reviews will be marked read-only."
                stopPropagation
                onClick={() => {
                  onRemove(site.customerField, info.id);
                }}
              >
                {null}
              </DeleteButton>
            )}
          </WWTD>
        </WWTR>
      ))}
    </>
  );
};

export default DefaultSiteRowRenderer;
