import { memo } from "react";
import { Link } from "react-router-dom";
import { TableCard, TableCardHeader, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import useFilterQuery from "hooks/data/useFilterQuery";
import { showSkeleton } from "util/showSkeleton";
import { permissions } from "components/Auth/permissions";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import { CardHeaderActions, CardTitle } from "components/Card";
import LinkButton from "components/Routing/LinkButton";
import { getContactName } from "util/getContactName";

const filters = [
  {
    name: "name",
    label: "Location",
    type: "text",
    icon: "fa fa-building",
    placeholder: "Search Accounts...",
    primaryBar: {
      position: "LEFT"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "page",
    defaultValue: 0,
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  }
];

const AccountsList = memo(({ data, showSkeleton }) => {
  const accounts = data?.data?.accounts || [];
  return (
    <AuthorizationRequiredToRender roles={[permissions.AGENCY_ADMIN]}>
      <div className="py-4 px-xs-0 px-lg-4 container-fluid">
        <FacetedSearch showFiltersButton={false} filters={filters} />
        <TableCard>
          <TableCardHeader>
            <CardTitle>Accounts</CardTitle>
            <CardHeaderActions>
              <LinkButton color="primary" to="/accounts/new">
                Add Account
              </LinkButton>
            </CardHeaderActions>
          </TableCardHeader>
          <WWTable loaded={!showSkeleton}>
            <WWTHead>
              <WWTR>
                <WWTH>Name</WWTH>
                <WWTH>Primary Contact</WWTH>
                <WWTH className="text-center">Active Locations</WWTH>
              </WWTR>
            </WWTHead>
            {showSkeleton ? (
              <TableBodySkeleton rows={8} cols={8} />
            ) : (
              <WWTBody>
                {accounts.length > 0 ? (
                  accounts.map(({ name, pointsOfContact, customers, id, locationCount }) => (
                    <WWTR>
                      <WWTD>
                        <Link to={`/accounts/${id}`}>{name}</Link>
                      </WWTD>
                      <WWTD>
                        {getContactName(pointsOfContact?.find(({ type }) => type === "PRIMARY")) || (
                          <span className="text-muted">N/A</span>
                        )}
                      </WWTD>
                      <WWTD className="text-center">{locationCount}</WWTD>
                    </WWTR>
                  ))
                ) : (
                  <WWTR>
                    <WWTD colSpan={3} className="text-muted text-center">
                      No data
                    </WWTD>
                  </WWTR>
                )}
              </WWTBody>
            )}
          </WWTable>
        </TableCard>
        <FilterPagination
          isLoading={showSkeleton}
          pageInfo={data?.pageInfo}
          totalPages={data?.pageInfo?.totalPages}
          elementsLabel="Accounts"
        />
      </div>
    </AuthorizationRequiredToRender>
  );
});

const ConnectedAccountsList = () => {
  const query = useFilterQuery({
    filters,
    projection: "list",
    additionalParams: { "customerStatus": "ACTIVE" },
    url: ["/accounts"]
  });

  return <AccountsList showSkeleton={showSkeleton(query)} {...query} />;
};

export default ConnectedAccountsList;
