import classNames from "classnames";
import RoundBadge from "components/Badges/RoundBadge/RoundBadge";
import WWButton from "components/Buttons/WWButton";
import { useFilterState } from "hooks/filteringHooks";
import { isEmpty, keys } from "lodash";
import { Fragment as F, useMemo } from "react";
import { filterFactory } from "../FacetedSearch";

import style from "./FilterBar.module.scss";

const { container, filterButtonContainer, filterCount, actionsContainer, leftGroup, rightGroup } = style;

const sortFilters = (filters, filterFn, classNamePath) =>
  filters
    .filter(filterFn)
    .map((filter, i) => (
      <F key={i}>{filterFactory(filter)?.barComponent(filter, filter?.[classNamePath]?.className)}</F>
    ));

const FilterBar = ({ filters, toggle, showFiltersButton = true, children }) => {
  const [filterValues] = useFilterState();

  const [barFiltersLeft, barFiltersRight, barFilterBottom] = useMemo(() => {
    return [
      sortFilters(filters, value => value?.primaryBar?.position === "LEFT", "primaryBar"),
      sortFilters(filters, value => value?.primaryBar?.position === "RIGHT", "primaryBar"),
      sortFilters(filters, value => !!value?.secondaryBar, "secondaryBar")
    ];
  }, [filters]);
  const filtersCount =
    (!isEmpty(filterValues) &&
      filters.filter(filter => !filter.nonFilter && keys(filterValues).includes(filter.name))?.length) ||
    0;

  return (
    <>
      <div className={container}>
        {showFiltersButton && (
          <div className={filterButtonContainer}>
            <WWButton
              color="warning"
              iconClass="fa fa-sliders"
              responsiveBreakpoint="md"
              onClick={toggle}
              trackingAction="Filters"
            >
              Filters
            </WWButton>
            {filtersCount > 0 && (
              <RoundBadge color="secondary" className={classNames(filterCount, "d-md-none")}>
                {filtersCount}
              </RoundBadge>
            )}
          </div>
        )}
        <div className={actionsContainer}>
          <div className={leftGroup}>{barFiltersLeft}</div>
          <div className={rightGroup}>
            {barFiltersRight}
            {children}
          </div>
        </div>
      </div>
      <div
        className={classNames(container, actionsContainer, "d-md-none", {
          "d-none": isEmpty(barFilterBottom)
        })}
      >
        {barFilterBottom}
      </div>
    </>
  );
};

export default FilterBar;
