import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { truncate, isString, isArray, size } from "lodash";
import { Button } from "reactstrap";

import styles from "./Paragrahp.module.scss";

const TextWithBreaks = ({ content, keyPrefix = "" }) => {
  const breakRegExp = /\r?\n|\r/;
  return isString(content) && content.search(breakRegExp) > -1
    ? content.split(breakRegExp).map((part, index, array) => (
        <span key={index}>
          {part}
          {index < array.length - 1 && <br />}
        </span>
      ))
    : content || null;
};

/**
 * Display a block of text that may contain line breaks
 * @param {ReactNode} content
 * @param {number | undefined} maxChars
 */
const Paragraph = ({ content, maxChars, ...props }) => {
  const [expanded, setExpanded] = useState(false);
  const expandable = maxChars && size(content) > maxChars;

  const contentForSingleNode = useMemo(
    () => (expandable && !expanded ? truncate(content || "", { length: maxChars }) : content),
    [content, maxChars, expandable, expanded]
  );

  return (
    <p className={styles.paragraphClass} {...props}>
      {isArray(content) ? (
        content.map((part, index) => (isString(part) ? <TextWithBreaks key={index} content={part} /> : part)).flat()
      ) : (
        <TextWithBreaks content={contentForSingleNode} />
      )}

      {expandable && (
        <Button className="p-0" color="link" onClick={() => setExpanded(!expanded)}>
          {expanded ? " (less)" : "(more)"}
        </Button>
      )}
    </p>
  );
};

Paragraph.propTypes = {
  content: PropTypes.node
};

export default Paragraph;
