import React, { useState, Children } from "react";
import styles from "./WWChipsBag.module.scss";
import classnames from "classnames";

const { bag, hasBorder, chipWrap } = styles;

const WWChipsBag = ({ children, borderless, wrap = true }) => {
  return Children.count(children) ? (
    <div className={classnames(bag, { [hasBorder]: borderless, [chipWrap]: wrap })}>{children}</div>
  ) : null;
};

export default WWChipsBag;
