import React, { useCallback, useState } from "react";
import { Button } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import Select from "react-select";
import logger from "../../../util/logger";
import debounce from "lodash/debounce";
import InputField from "../../../components/Form/InputField";
import { updateSevenroomsClosedStatuses } from "../../../api/customerApi";
import isEmpty from "lodash/isEmpty";
import truncate from "lodash/truncate";
import property from "lodash/property";
import { errorCaughtNotifier, useLocalNotifications } from "../../../components/Notifications/notification";
import * as sevenroomsApi from "../../../api/sevenroomsApi";
import HorizontalSelectField from "../../../components/Form/HorizontalSelectField";
import CopyButton from "../../../components/Buttons/CopyButton";

const statuses = [
  "PAID",
  "COMPLETE",
  "ARRIVED_PARTIAL",
  "ARRIVED",
  "ORDER_PLACED",
  "FIRST_COURSE",
  "SECOND_COURSE",
  "THIRD_COURSE",
  "FOURTH_COURSE",
  "DESSERT",
  "CHECK_DROPPED",
  "BUS_TABLE"
];
export default class Sevenrooms {
  field() {
    return "sevenroomsInfo";
  }

  add() {
    const AddSevenrooms = ({ customer, onSave }) => {
      const [selectedLocation, setSelectedLocation] = useState();
      const [sevenroomsVenues, setSevenroomsVenues] = useState();
      const [clientId, setClientId] = useState();
      const [clientSecret, setClientSecret] = useState();
      const [venueGroupId, setVenueGroupId] = useState();
      const [completedReservationStatuses, setCompletedReservationStatuses] = useState(["PAID", "COMPLETE"]);

      const loadSevenroomsLocationOptions = (venueInfo, filter = "") => {
        sevenroomsApi
          .listVenues(venueInfo, filter)
          .then(res => {
            setSevenroomsVenues(
              res.data.map(l => {
                return {
                  label: `${l.name}`,
                  value: { ...venueInfo, venueId: l.id, venueName: l.name }
                };
              })
            );
          })
          .catch(err => logger.error(err));
      };

      const updateStatuses = useCallback(
        value => {
          setCompletedReservationStatuses(value.join(","));
        },
        [customer]
      );

      return (
        <>
          <InputField
            type="text"
            label="Client ID"
            placeholder="Client ID"
            onChange={e => setClientId(e.target.value)}
            value={clientId}
          />
          <InputField
            type="text"
            label="Client Secret"
            placeholder="Client Secret"
            onChange={e => setClientSecret(e.target.value)}
            value={clientSecret}
          />
          <InputField
            type="text"
            label="Venue Group ID"
            placeholder="Venue Group ID"
            onChange={e => setVenueGroupId(e.target.value)}
            value={venueGroupId}
          />
          <FormField label="Venue">
            <Select
              className="w-100"
              enabled={!isEmpty(clientId) && !isEmpty(clientSecret) && !isEmpty(venueGroupId)}
              options={sevenroomsVenues}
              placeholder="Type to see venues"
              onChange={v => setSelectedLocation(v)}
              onInputChange={debounce(input => {
                if (!isEmpty(clientId) && !isEmpty(clientSecret) && !isEmpty(venueGroupId) && input !== "") {
                  loadSevenroomsLocationOptions({ clientId, clientSecret, venueGroupId }, input);
                }
              }, 250)}
              value={selectedLocation}
            />
          </FormField>
          <HorizontalSelectField
            name="completedReservationStatuses"
            value={completedReservationStatuses}
            label="Completed Reservation Statuses"
            isMulti={true}
            options={statuses}
            simpleValue={true}
            onChange={evt => updateStatuses(evt.target.value)}
            inline={false}
          />
          <FormField label=" ">
            <Button
              color="primary"
              onClick={() => {
                onSave({
                  ...selectedLocation.value,
                  completedReservationStatuses: isEmpty(completedReservationStatuses)
                    ? ""
                    : completedReservationStatuses.join(","),
                  source: "SEVENROOMS"
                });
              }}
            >
              Link
            </Button>
          </FormField>
        </>
      );
    };
    return AddSevenrooms;
  }

  edit() {
    const EditSevenrooms = ({ info, customer }) => {
      const parseCompletedStatuses = combinedString => {
        return !isEmpty(combinedString) ? combinedString.split(",") : [];
      };

      const [completedReservationStatuses, setCompletedReservationStatuses] = useState(
        parseCompletedStatuses(info.completedReservationStatuses)
      );

      const notify = useLocalNotifications();

      const onCompletedReservationStatusChanged = useCallback(
        ({ target }) =>
          updateSevenroomsClosedStatuses(customer.id, "sevenroomsInfo", info.id, target.value.join(","))
            .then(property("completedReservationStatuses"))
            .then(parseCompletedStatuses)
            .then(setCompletedReservationStatuses)
            .catch(errorCaughtNotifier(notify)),
        [info, customer]
      );
      return (
        <>
          <>
            <FormField label="Client ID">
              <>
                <span className="text-truncate w-auto d-inline-block">{truncate(info.clientId)}</span>
                <CopyButton className="ms-1" text={info.clientId} />
              </>
            </FormField>
            <FormField label="Client Secret">
              <>
                <span className="text-truncate w-auto d-inline-block">{truncate(info.clientSecret)}</span>
              </>
            </FormField>
            <FormField label="Venue Group ID">
              <>
                <span className="text-truncate w-auto d-inline-block">{truncate(info.venueGroupId)}</span>
                <CopyButton className="ms-1" text={info.venueGroupId} />
              </>
            </FormField>
            <FormField label="Venue (Venue ID)">
              <>
                <span className="text-truncate w-auto d-inline-block">
                  {info.venueName + " (" + truncate(info.venueId) + ")"}
                </span>
                <CopyButton className="ms-1" text={info.venueId} />
              </>
            </FormField>
            <HorizontalSelectField
              name="completedReservationStatuses"
              value={completedReservationStatuses}
              label="Completed Reservation Statuses"
              isMulti={true}
              options={statuses}
              simpleValue={true}
              onChange={onCompletedReservationStatusChanged}
              inline={false}
              help={
                <>
                  <p>Choose which statuses will trigger invitations.</p>
                </>
              }
            />
          </>
        </>
      );
    };
    return EditSevenrooms;
  }
}
