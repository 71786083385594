import React from "react";
import { bySource } from "../../data/sites";
import { Row, Col } from "reactstrap";
import ToggleSwitch from "../Form/Switch";
import { isCurrentUserInGroup } from "../../util/userUtils";
import { permissions } from "../Auth/permissions";

const CustomerTableRow = ({ customer, onChange = null, showLinkedIn, ...props }) => {
  return (
    <tr className="mb-0">
      <td className="align-middle">{customer.companyName}</td>
      {isCurrentUserInGroup(permissions.CREATE_POST) && (
        <>
          <td className="align-middle">
            {"facebookInfo" in customer.sites && (
              <ToggleSwitch
                className="mb-0 mt-2 text-center"
                icon={bySource("FACEBOOK").icon}
                labelWidth={0}
                inputWidth={12}
                onLabel=""
                offLabel=""
                name={`FACEBOOK:${customer.id}`}
                checked={customer.sites["facebookInfo"]}
                onChange={onChange}
                value={bySource("FACEBOOK").customerField}
              />
            )}
            {!("facebookInfo" in customer.sites) && (
              <Row>
                <Col className="fa-stack mt-2 mb-2 text-center">
                  <i className={`fa ${bySource("FACEBOOK").icon} fa-stack-1x text-dark`}></i>
                  <i className="fa fa-ban fa-stack-2x text-dark" style={{ opacity: 0.5 }}></i>
                </Col>
              </Row>
            )}
          </td>
          {/* <td className="align-middle">
        {'instagramInfo' in customer.sites && (
          <ToggleSwitch
            className="mb-0 mt-2 text-center"
            icon={bySource("INSTAGRAM").icon}
            labelWidth={0}
            inputWidth={12}
            onLabel=""
            offLabel=""
            name={`INSTAGRAM:${customer.id}:`}
            checked={customer.sites['instagramInfo']}
            onChange={onChange}
            value="instagramInfo"
          />
        )}
        {!('instagramInfo' in customer.sites) && (
          <Row>
            <Col className="fa-stack mt-2 mb-2 text-center">
              <i
                className={`fa ${bySource("INSTAGRAM").icon} fa-stack-1x text-dark`}
              ></i>
              <i
                className="fa fa-ban fa-stack-2x text-dark"
                style={{ opacity: 0.5 }}
              ></i>
            </Col>
          </Row>
        )}
      </td> */}
          <td className="align-middle">
            {"gmbInfo" in customer.sites && (
              <ToggleSwitch
                className="mb-0 mt-2 text-center"
                icon={bySource("GOOGLE").icon}
                labelWidth={0}
                inputWidth={12}
                onLabel=""
                offLabel=""
                name={`GOOGLE:${customer.id}:`}
                checked={customer.sites["gmbInfo"]}
                onChange={onChange}
                value={bySource("GOOGLE").customerField}
              />
            )}
            {!("gmbInfo" in customer.sites) && (
              <Row>
                <Col className="fa-stack mt-2 mb-2 text-center">
                  <i className={`fa ${bySource("GOOGLE").icon} fa-stack-1x text-dark`}></i>
                  <i className="fa fa-ban fa-stack-2x text-dark" style={{ opacity: 0.5 }}></i>
                </Col>
              </Row>
            )}
          </td>
          {showLinkedIn && (
            <td className="align-middle">
              {"linkedInInfo" in customer.sites && (
                <ToggleSwitch
                  className="mb-0 mt-2 text-center"
                  icon={bySource("LINKEDIN").icon}
                  labelWidth={0}
                  inputWidth={12}
                  onLabel=""
                  offLabel=""
                  name={`LINKEDIN:${customer.id}`}
                  checked={customer.sites["linkedInInfo"]}
                  onChange={onChange}
                  value={bySource("LINKEDIN").customerField}
                />
              )}
              {!("linkedInInfo" in customer.sites) && (
                <Row>
                  <Col className="fa-stack mt-2 mb-2 text-center">
                    <i className={`fa ${bySource("LINKEDIN").icon} fa-stack-1x text-dark`}></i>
                    <i className="fa fa-ban fa-stack-2x text-dark" style={{ opacity: 0.5 }}></i>
                  </Col>
                </Row>
              )}
            </td>
          )}
        </>
      )}
      {isCurrentUserInGroup(permissions.SHARE_DIRECT) && (
        <td className="align-middle">
          {"directMessageInfo" in customer.sites && (
            <ToggleSwitch
              className="mb-0 mt-2 text-center"
              icon={bySource("DIRECT_MESSAGE").icon}
              labelWidth={0}
              inputWidth={12}
              onLabel=""
              offLabel=""
              name={`DIRECT_MESSAGE:${customer.id}:`}
              checked={customer.sites["directMessageInfo"]}
              onChange={onChange}
              value={bySource("DIRECT_MESSAGE").customerField}
            />
          )}
          {!("directMessageInfo" in customer.sites) && (
            <Row>
              <Col className="fa-stack mt-2 mb-2 text-center">
                <i className={`fa ${bySource("DIRECT_MESSAGE").icon} fa-stack-1x text-dark`}></i>
                <i className="fa fa-ban fa-stack-2x text-dark" style={{ opacity: 0.5 }}></i>
              </Col>
            </Row>
          )}
        </td>
      )}
    </tr>
  );
};

export default CustomerTableRow;
