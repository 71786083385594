import { omitBy, isUndefined } from "lodash";
import qs from "qs";

export const getDefaultApproveFilters = () => ({
  status: "APPROVAL",
  sort: "reviewDate,desc"
});

export const getApprovalSearch = params =>
  qs.stringify({
    ...getDefaultApproveFilters(),
    ...omitBy(params, isUndefined)
  });
