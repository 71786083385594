// uncomment this line if you have something to import from your scss module
// import {} from "./TollFree.module.scss"

import { useCallback } from "react";
import { useCustomerSmsOrderSubmit } from "../../../../hooks/customerHooks";
import { ConfirmationButton } from "../../../Form/withConfirmation";

export default function TollFree({ customerId }) {
  const submit = useCustomerSmsOrderSubmit(customerId);
  const submitHandler = useCallback(() => {
    submit({ customerId, desiredAreaCode: "8**" });
  });
  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <span className="text-muted mb-2 text-center">
        Please fill out the{" "}
        <a href="https://www.bandwidth.com/forms/toll-free-campaign-brief/" target="_blank" rel="noopener noreferrer">
          Toll Free Campaign Brief
        </a>
      </span>
      <ConfirmationButton onClick={submitHandler}>Request Toll Free Phone Number</ConfirmationButton>
    </div>
  );
}
