import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import qs from "querystring";
import { Row, Col, Button } from "reactstrap";
import ReviewThread from "components/Reviews/ReviewThread";
import LoadingPage from "components/Misc/LoadingPage";
import MessageThread from "components/Messages/MessageThread";
import withAuthorization from "components/Auth/Authorization";
import { permissions } from "components/Auth/permissions";
import ContentGroup from "components/Layout/ContentGroup";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import { updateViewState } from "../../redux/actions/viewStateActions";
import { reportClearCustomer } from "../../redux/actions/reportActions";
import { beginAjaxCall, endAjaxCall, ajaxCallError } from "../../redux/actions/ajaxStatusActions";
import { topicsForAgency } from "data/topics";
import { filterNotSmsMessages } from "util/messageFilters";
import ReportDateRangePicker from "./ReportPickers/ReportDateRangePicker";
import { reportMapStateToProps } from "./util";
import * as api from "api/reportApi";

import styles from "scss/style.scss";

const DEFAULT_FILTER = { threshold: { label: "Negative", value: 3.0 } };
const THRESHOLD_OPTIONS = [
  { label: "All", value: 5.0 },
  { label: "Negative", value: 3.0 }
];

class ReviewThreadList extends Component {
  constructor(props) {
    super(props);

    this.emptyLabel = this.props.emptyLabel || "No reviews to show.";

    this.state = {
      reviews: null
    };

    this.loadReviews = this.loadReviews.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
  }

  componentDidMount() {
    this.query = qs.parse(this.props.location.search.substr(1));
    if (this.query && Object.keys(this.query).length > 0) {
      const filter = Object.assign({}, this.props.filter);
      Object.keys(this.query).forEach(k => (filter[k] = this.query[k]));
      if (filter.threshold) {
        if (filter.threshold === "5") {
          filter.threshold = THRESHOLD_OPTIONS[0];
        } else {
          filter.threshold = THRESHOLD_OPTIONS[1];
        }
      }
      if (JSON.stringify(filter) !== JSON.stringify(this.props.filter)) {
        this.props.dispatch(updateViewState("reportReviewList", { persistent: { filter } }));
      }
    } else {
      this.loadReviews();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate ||
      JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)
    ) {
      this.props.dispatch(
        updateViewState("reportReviewList", {
          persistent: { filter: this.props.filter }
        })
      );
      this.loadReviews();
    }
  }

  loadReviews() {
    this.props.dispatch(beginAjaxCall());
    this.setState({ reviews: undefined });
    const { startDate, endDate, customerId, contactId, filter } = this.props;

    api
      .loadReviewThreads(customerId, contactId, startDate, endDate, filter.threshold.value, filter.tag)
      .then(res => {
        this.setState({
          reviews: _.get(res.data, "_embedded.fullReviewDtoes", [])
        });
      })
      .then(() => this.props.dispatch(endAjaxCall()))
      .catch(err => this.props.dispatch(ajaxCallError()));
  }

  updateFilter(evt) {
    const filter = Object.assign({}, this.props.filter, {
      [evt.target.name]: evt.target.value
    });
    this.props.dispatch(updateViewState("reportReviewList", { persistent: { filter } }));
  }

  render() {
    const { reviews } = this.state;
    const { startDate, endDate, customerId, contactId, filter } = this.props;
    return (
      <div className="ps-4 pt-4 pe-4">
        {(!_.isEmpty(customerId) || !_.isEmpty(contactId)) && (
          <Row>
            <Col>
              <Button color="link" onClick={() => this.props.dispatch(reportClearCustomer())}>
                &lt; Back to my account
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="text-end">
            <div>
              <ReportDateRangePicker />
            </div>
            <Button
              color="link"
              onClick={() =>
                api
                  .loadReviewThreads(
                    customerId,
                    contactId,
                    startDate,
                    endDate,
                    filter.threshold.value,
                    filter.tag,
                    true
                  )
                  .then(res => window.open(res.data, "_blank"))
              }
            >
              Download Report
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            xs={6}
            md={{ size: 3, offset: 3 }}
            // className="d-flex justify-content-center align-items-center"
          >
            <div className="d-inline-block" style={{ width: "100%" }}>
              <HorizontalSelectField
                name="threshold"
                label="Rating"
                options={THRESHOLD_OPTIONS}
                inline={false}
                value={filter.threshold}
                onChange={this.updateFilter}
              />
            </div>
          </Col>
          <Col
            xs={6}
            md={3}
            // className="d-flex justify-content-center align-items-center"
          >
            <div className="d-inline-block" style={{ width: "100%" }}>
              <HorizontalSelectField
                name="tag"
                style={{ width: "25%" }}
                label="Tag"
                simpleValue={true}
                isClearable={true}
                options={topicsForAgency(this.props.currentAgency)}
                inline={false}
                value={filter.tag}
                onChange={this.updateFilter}
              />
            </div>
          </Col>
        </Row>
        <Row>
          {_.isUndefined(this.state.reviews) ? (
            <LoadingPage />
          ) : (
            <Col>
              {_.size(reviews) === 0 ? (
                <h2 style={{ textAlign: "center" }}>{this.emptyLabel}</h2>
              ) : (
                <div>
                  {reviews.map((r, i) => {
                    let wrapperStyles = {};
                    if (r.history && r.history.length > 0) wrapperStyles.backgroundColor = styles.light;
                    return (
                      <Row key={r.id} className="pb-3">
                        <Col xs={12} md={{ size: 6, offset: 3 }}>
                          <ContentGroup>
                            <MessageThread className="pt-5 px-4" messages={filterNotSmsMessages(r.messages || [])} />
                            <ReviewThread review={r} readOnly />
                            <div style={{ marginLeft: "50px" }}>
                              {r.history &&
                                r.history.map((h, i) => <ReviewThread key={i} review={h} readOnly={true} />)}
                            </div>
                          </ContentGroup>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

ReviewThreadList.propTypes = {};

function mapStateToProps(state, ownProps) {
  return {
    currentAgency: state.currentAgency,
    filter: _.get(state.viewState, "reportReviewList.persistent.filter", DEFAULT_FILTER),
    ...reportMapStateToProps(state, ownProps)
  };
}

export default withAuthorization(permissions.REPORT_READ)(connect(mapStateToProps)(ReviewThreadList));
