// uncomment this line if you have something to import from your scss module
import classNames from "classnames";
import WWButton from "components/Buttons/WWButton";
import { useCallback, useMemo } from "react";
import styles from "./TableSortToggle.module.scss";

export default function TableSortToggle({ name, sortSetter, toggleOptions = [,], value }) {
  const [sortUp, sortDown] = useMemo(() => {
    const sortUp = value === toggleOptions[0];
    const sortDown = value === toggleOptions[1];
    return [sortUp, sortDown];
  }, [value, toggleOptions]);

  const handleClick = useCallback(() => {
    sortSetter(sortUp ? toggleOptions[1] : toggleOptions[0]);
  }, [sortUp, toggleOptions, sortSetter]);

  return (
    <WWButton className="ps-0 border-0" color="" onClick={handleClick}>
      <div className="d-flex flex-nowrap justify-center align-items-center">
        <span className="text-capitalize pe-2 text-muted">{name}</span>
        <div className="d-flex flex-column pe-2">
          <i
            data-testid="up-toggle-icon"
            className={classNames(
              "fa fa-sort-up",
              styles.iconTop,
              { "text-secondary": sortUp },
              { "text-muted": !sortUp }
            )}
          />
          <i
            className={classNames(
              "fa fa-sort-down",
              styles.iconBottom,
              { "text-secondary": sortDown },
              { "text-muted": !sortDown }
            )}
          />
        </div>
      </div>
    </WWButton>
  );
}
