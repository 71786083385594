import React from "react";

const Ring = ({ text, size = "100px", color = "muted" }) => {
  let style = {
    width: size,
    height: size
  };
  return (
    <div className={`ring bg-${color} text-body`} style={style}>
      <div className="ringInner">
        <div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: "100%" }}>
          <span className="fw-bold" style={{ fontSize: "2em" }}>
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Ring;
