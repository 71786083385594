import { combineReducers } from "redux";
import ajaxCallsInProgress from "./ajaxStatusReducer";
import cognito from "./authReducer";
import report from "./reportReducer";
import currentContact from "./currentContactReducer";
import currentAgency from "./agencyReducer";
import app from "./appReducer";
import facebook from "./facebookReducer";
import google from "./googleReducer";
import viewState from "./viewStateReducer";
import userDashboard from "./userDashboardReducer";
import customersCacheReducer, { CACHE_STATE_KEY } from "./customersCacheReducer";

// Utility function allowing reuse of a reducer for different slices of state
// based on data in the action.
// https://redux.js.org/recipes/structuring-reducers/reusing-reducer-logic#customizing-behavior-with-higher-order-reducers
// function createFilteredReducer(reducerFunction, reducerPredicate, initialState) {
//   return (state, action) => {
//     const isInitializationCall = state === undefined;
//     const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall;
//     return shouldRunWrappedReducer ? reducerFunction(state || initialState, action) : state;
//   };
// }

const rootReducer = combineReducers({
  ajaxCallsInProgress,
  cognito,
  report,
  currentContact,
  currentAgency,
  app,
  facebook,
  google,
  viewState,
  userDashboard,
  [CACHE_STATE_KEY]: customersCacheReducer
});

export default rootReducer;
