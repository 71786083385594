import { useState, useEffect, useMemo, memo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { TableCard, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import FilterTableSort from "components/FacetedSearch/Sort/FilterTableSort/FilterTableSort";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import useFilterQuery from "hooks/data/useFilterQuery";
import { showSkeleton } from "util/showSkeleton";

import styles from "./AccountWhitespaceTable.module.scss";

const sortedProductTypesLabels = {
  INVITE: "Invite",
  INVITE_VIDEO: "Invite Video",
  ENGAGE: "Engage",
  ENGAGE_PLUS: "Engage Plus",
  LISTINGS: "Listings",
  CREATE: "Create",
  API: "API"
};

const sortedProductKeys = Object.keys(sortedProductTypesLabels);

const { checkIconClass, crossIconClass, filterInputClass } = styles;

const getFilters = productTypes => [
  {
    name: "q",
    label: "Location",
    type: "text",
    icon: "fa fa-building",
    placeholder: "Search Locations...",
    primaryBar: {
      position: "LEFT",
      className: filterInputClass
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "productFilter",
    label: "Products",
    type: "multiSelect",
    options: productTypes.map(key => ({
      label: sortedProductTypesLabels[key],
      value: `${key},TRUE`
    })),
    primaryBar: {
      position: "LEFT",
      className: "d-md-flex d-none"
    },
    secondaryBar: {
      className: "d-flex d-md-none"
    },
    chip: {
      position: "NONE"
    }
  },

  {
    name: "page",
    defaultValue: 0,
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "sort",
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  }
];

const normalizeAccountResponse = data => ({
  data: {
    productCounts: data?.productCounts,
    customers: data?._embedded?.customerInWhiteSpaceReportDtoes
  },
  pageInfo: data?.page
});

export const AccountWhitespaceTable = memo(
  ({ data, isLoading, isFetched, productTypes = sortedProductKeys, children }) => {
    const reportData = data?.data;
    return (
      <>
        {children}
        <TableCard>
          <WWTable loaded={!isLoading}>
            <WWTHead>
              <WWTR>
                <WWTH>
                  <FilterTableSort label="Location" options={["companyName,asc", "companyName,desc"]} />
                </WWTH>
                {productTypes.map(productType => (
                  <WWTH key={productType} className="text-center p-2">
                    {sortedProductTypesLabels[productType]}
                  </WWTH>
                ))}
              </WWTR>
            </WWTHead>
            {showSkeleton({ isLoading, isFetched }) ? (
              <TableBodySkeleton rows={8} cols={8} />
            ) : (
              <WWTBody>
                {reportData?.productCounts && reportData?.customers ? (
                  <>
                    <WWTR>
                      <WWTD>Total</WWTD>
                      {productTypes.map(productType => (
                        <WWTD className="text-center p-2">{reportData?.productCounts[productType] || 0}</WWTD>
                      ))}
                    </WWTR>
                    {reportData.customers.map(({ companyName, id, products }) => (
                      <WWTR>
                        <WWTD className="pe-2">
                          <Link to={`/customers/${id}`}>{companyName}</Link>
                        </WWTD>
                        {productTypes.map(productType => (
                          <WWTD className="text-center p-2">
                            <i
                              className={classNames({
                                "fa fa-check-circle": !!products[productType],
                                "fa fa-times-circle": !products[productType],
                                [checkIconClass]: !!products[productType],
                                [crossIconClass]: !products[productType]
                              })}
                            />
                          </WWTD>
                        ))}
                      </WWTR>
                    ))}
                  </>
                ) : (
                  <WWTR>
                    <WWTD colspan={productTypes.length + 1} className="text-muted text-center">
                      No data
                    </WWTD>
                  </WWTR>
                )}
              </WWTBody>
            )}
          </WWTable>
        </TableCard>
        <FilterPagination
          isLoading={showSkeleton({ isLoading, isFetched })}
          pageInfo={data?.pageInfo}
          totalPages={data?.pageInfo?.totalPages}
          elementsLabel="Locations"
        />
      </>
    );
  }
);

const ConnectedAccountWhitespaceTable = ({ accountId, enabled = true }) => {
  const [filters, setFilters] = useState(getFilters(sortedProductKeys));

  const query = useFilterQuery({
    filters,
    url: ["/accounts", accountId, "report", "whitespace"],
    normalizeResponse: normalizeAccountResponse,
    enabled
  });

  const reportData = query.data?.data;
  const productTypes = useMemo(
    () =>
      reportData
        ? sortedProductKeys.filter(key => Object.keys(reportData.productCounts).includes(key))
        : sortedProductKeys,
    [reportData]
  );

  useEffect(() => {
    setFilters(getFilters(productTypes));
  }, [productTypes]);

  return (
    <AccountWhitespaceTable {...query} productTypes={productTypes}>
      <FacetedSearch showFiltersButton={false} filters={filters} />
    </AccountWhitespaceTable>
  );
};

export default ConnectedAccountWhitespaceTable;
