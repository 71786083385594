import * as client from "./apiClient";

const path = "preview";

export function urlPreview(url) {
  return client.post([path, "url"], url, {
    headers: {
      "Content-type": "text/plain"
    }
  });
}
