import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { noop, size, map, isFunction, debounce } from "lodash";
import { not } from "ramda";
import { Badge, ButtonGroup, Modal, UncontrolledTooltip as Tooltip } from "reactstrap";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import AgeBadge from "../DateTime/AgeBadge";
import Reactions from "./Reactions";
import Attachments from "./Attachments";
import Paragraph from "../Layout/Paragraph";
import TagLabel from "./TagLabel";
import ReviewHeader from "./ReviewHeader";
import { Card } from "components/Card";
import HelpIcon from "components/Misc/HelpIcon";
import WWButton from "components/Buttons/WWButton";
import { permissions } from "components/Auth/permissions";
import { getReviewReportAsOptionLabel } from "data/reportReasons";
import { bySource } from "data/sites";
import useTranslate from "hooks/data/useTranslate";
import * as siteUtils from "util/siteUtils";

import "./Reviews.scss";
import styles from "./Review.module.scss";

import { isCurrentUserInGroup } from "util/userUtils";

const { edittextarea, reviewparagraph, representativesIconClass, iconWidth } = styles;

function getEditLabel(allowContentEditing, allowTranslationEditing) {
  if (allowContentEditing && allowTranslationEditing) {
    return "Edit review content or translation";
  } else if (allowContentEditing) {
    return "Edit review content";
  } else if (allowTranslationEditing) {
    return "Edit translation";
  } else {
    return "";
  }
}

function Review({
  review,
  withAge = true,
  showReviewTopics = true,
  actions,
  onChange = noop,
  allowContentEditing = false,
  allowTranslationEditing = false,
  showTagLabel = false,
  showType = false,
  showReportedAsBadge = true
}) {
  const reviewTypeClass = (() => {
    switch (review.type) {
      case "QUESTION":
        return "question";
      case "POST":
        return "post";
      default:
        return "review";
    }
  })();

  const [source, setSource] = useState(bySource(review.source));
  const [corrected, setCorrected] = useState(review.content);
  const [correctedTranslation, setCorrectedTranslation] = useState(review.translatedContent);
  const [translationLock, setTranslationLock] = useState(false);
  const inputRef = useRef();
  const translationRef = useRef();

  const editLabel = getEditLabel(allowContentEditing, allowTranslationEditing);

  const translate = useTranslate();

  const translateText = useCallback((text, sourceLanguage) => {
    translate.mutate(
      { text, sourceLanguage },
      {
        onSuccess: data => {
          setCorrectedTranslation(data);
        }
      }
    );
  }, []);

  const debouncedTranslate = useMemo(() => debounce(translateText, 1000), []);

  const updateReviewContent = useCallback(
    targetValue => {
      setCorrected(targetValue);
      if (targetValue && (review.language === "es" || review.language === "fr") && !translationLock) {
        debouncedTranslate(targetValue, review.language);
      }
    },
    [translationLock, review]
  );

  const onSubmit = useCallback(() => {
    setEditing(false);
    onChange(corrected, correctedTranslation);
  }, [onChange, corrected, correctedTranslation]);

  const [editing, setEditing] = useState(false);
  useEffect(() => {
    setSource(bySource(review.source));
  }, [review]);

  return (
    <div className="review-container">
      {showReviewTopics && (
        <div className="review-type-group">
          <div className={`review-type ${reviewTypeClass}`}>
            {review.source === "FACEBOOK" && review.type === "REVIEW" ? "Recommendation" : review.type.toLowerCase()}
          </div>
          {map(
            review.reviewEntities?.filter(entity => entity.type === "TOPIC"),
            topic => (
              <div className="review-type topic" key={topic.value}>
                {topic.value}
              </div>
            )
          )}
        </div>
      )}
      <ReviewHeader review={review} showType={showType} spaced />
      <Paragraph className={`${reviewparagraph} review-content mt-3 mb-0`} content={review.content} />
      <Modal
        isOpen={editing}
        onOpened={() => inputRef?.current.focus()}
        backdrop={true}
        fade
        centered={true}
        toggle={() => setEditing(false)}
      >
        {editing && (
          <div className="p-4">
            <h4>{editLabel}</h4>
            <small className="text-muted fs-2 fw-bold">Review content</small>
            <TextareaAutosize
              value={corrected}
              ref={inputRef}
              onChange={e => updateReviewContent(e.target.value)}
              className={`${edittextarea} form-control m-0 p-3`}
              autoFocus={allowContentEditing}
              onFocus={e => e.target.select()}
              disabled={!allowContentEditing}
            />
            {review.translatedContent && (
              <>
                <div className="d-flex justify-content-between w-100 mt-3">
                  <small className="text-muted fs-2 fw-bold align-self-end">
                    Widewail Translation <HelpIcon icon="info">Widewail Translation for internal use only </HelpIcon>
                  </small>
                  <WWButton
                    className="my-1"
                    size="sm"
                    iconClass={`fa fa-${translationLock ? "lock" : "unlock-alt"}`}
                    color={translationLock ? "light" : "secondary"}
                    onClick={() => setTranslationLock(not)}
                  />
                </div>
                <TextareaAutosize
                  value={correctedTranslation}
                  ref={translationRef}
                  disabled={translationLock}
                  onChange={e => setCorrectedTranslation(e.target.value)}
                  className={`${edittextarea} form-control m-0 p-3`}
                  autoFocus={!allowContentEditing}
                  onFocus={e => e.target.select()}
                />
              </>
            )}
          </div>
        )}
        <div className="ms-auto mx-3 pb-4">
          <WWButton
            color="light"
            onClick={() => {
              setCorrected(review.content);
              setCorrectedTranslation(review.translatedContent);
              setEditing(false);
            }}
          >
            <i className="fa fa-remove" /> Discard changes
          </WWButton>
          {editing ? (
            <WWButton
              className="ms-2"
              color="secondary"
              onClick={onSubmit}
              disabled={corrected === review.content && correctedTranslation === review.translatedContent}
            >
              <i className="fa fa-check" /> Submit changes
            </WWButton>
          ) : null}
        </div>
      </Modal>
      <ButtonGroup className="ms-auto mx-3">
        {!editing && editLabel && (
          <WWButton className="my-2" size="sm" color="light" onClick={() => setEditing(true)} title={editLabel}>
            <i className="fa fa-edit" /> {editLabel}
          </WWButton>
        )}
      </ButtonGroup>

      {review.attachments && <Attachments attachments={review.attachments} />}
      {review.translatedContent && (
        <Card color="secondary" className="text-light px-3 border-0 rounded-1">
          <h4 className="mb-2">
            Widewail Translation
            <HelpIcon className="ww-font-md ms-2" icon="info">
              Widewail translation for internal use only
            </HelpIcon>
          </h4>
          <Paragraph content={review.translatedContent} />
        </Card>
      )}
      {review.reactions && (
        <div className="review-reactions">
          <Reactions id={review.id} reactions={review.reactions} />
        </div>
      )}
      {isFunction(actions) && (
        <div className="review-actions d-flex flex-row justify-content-around">{actions(review)}</div>
      )}
      <div className="review-info">
        {review.customer && (
          <>
            <span id={"company-name-" + review.id} className="info--company-name d-inline-block text-truncate">
              <i className={`fa fa-map-marker me-2`} />
              {`${review.customer.companyName}`}
              {/* destinations for posts */}
              {size(review.destinations) > 0 && ` for ${review.destinations[0].companyName}`}
            </span>
            <Tooltip target={"company-name-" + review.id}>{review.customer.companyName}</Tooltip>
          </>
        )}
        {review?.interaction?.representatives && (
          <span className="d-inline-block text-truncate">
            <i className={classNames("bg-image-logo-invite", representativesIconClass)} />
            {isCurrentUserInGroup([permissions.REVIEW_INVITE_MANAGE]) ? (
              <Link to={`/invite/leads/${review?.interaction?.reviewLead?.id}`}>
                {review.interaction?.representatives?.[0]?.firstName}{" "}
                {review.interaction?.representatives?.[0]?.lastName}
              </Link>
            ) : (
              <>
                {review.interaction?.representatives?.[0]?.firstName}{" "}
                {review.interaction?.representatives?.[0]?.lastName}
              </>
            )}
          </span>
        )}
        {withAge && review.reviewDate && <AgeBadge from={review.reviewDate} />}
        {review.source && (
          <span className="d-inline-block text-truncate">
            <i className={`fa ${source.icon} me-2 ${iconWidth}`} />
            <a href={siteUtils.thirdpartyReviewUrl(review)} target="_blank" rel="noreferrer noopener">
              {`${source.label}`}
            </a>
          </span>
        )}
        {showTagLabel && <TagLabel tags={review.tags} />}
        {showReportedAsBadge && review.reportedAs && (
          <Badge className="p-2" color="dark">
            REPORTED AS {getReviewReportAsOptionLabel(review.reportedAs)}
          </Badge>
        )}
      </div>
    </div>
  );
}

Review.propTypes = {
  review: PropTypes.object.isRequired,
  withAge: PropTypes.bool,
  actions: PropTypes.func
};

export default Review;
