import React, { useState, useEffect, useCallback } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from "reactstrap";
import Input from "components/Form/Input";
import WWButton from "components/Buttons/WWButton";
import AjaxLoader, { AjaxLoaderSizes } from "components/Misc/AjaxLoader";
import colors from "constants/colors";
import { DEBOUNCE_DELAY_500_MS } from "constants/delays";

import styles from "./SendMessageForm.module.scss";
import reactStringReplace from "react-string-replace";

const {
  dropdownItemClass,
  dropdownSearchContainerClass,
  dropdownSearchClass,
  dropdownMenuClass,
  ajaxLoaderClass
} = styles;

const UsersDropdown = ({ users, onSelect, onSearch, onClose }) => {
  const [searchValue, setSearchValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaderCanBeShown, setLoaderCanBeShown] = useState(false);

  const onChange = useCallback(e => setSearchValue(e.target.value), [setSearchValue]);

  const onToggle = useCallback(() => {
    return setIsOpen(prevState => !prevState);
  }, [setSearchValue]);

  useEffect(() => {
    let doSearch = async v => await onSearch(v);

    if (isOpen) {
      setLoading(true);
      setLoaderCanBeShown(false);
      setTimeout(() => setLoaderCanBeShown(true), DEBOUNCE_DELAY_500_MS);
      doSearch(searchValue);
      setLoading(false);
    }
  }, [searchValue, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSearchValue("");
      onClose();
    }
  }, [isOpen]);

  return (
    <Dropdown isOpen={isOpen} toggle={onToggle}>
      <DropdownToggle tag="div" className="flex-grow-1 d-flex">
        <WWButton contentClass="text-capitalize" className="mr-2" color="link">
          @ Mention Someone
        </WWButton>
      </DropdownToggle>
      <DropdownMenu className={dropdownMenuClass}>
        <div className={dropdownSearchContainerClass}>
          <Input className={dropdownSearchClass} onChange={onChange} value={searchValue} placeholder="Search" />
        </div>
        {loading && loaderCanBeShown && (
          <div className={ajaxLoaderClass}>
            <AjaxLoader size={AjaxLoaderSizes.SM} color={colors.darkMuted} />
          </div>
        )}
        {users?.map(user => (
          <DropdownItem key={user.id} className={dropdownItemClass} onClick={() => onSelect(user)}>
            {searchValue
              ? reactStringReplace(user.display, searchValue, match => <strong>{match}</strong>)
              : user.display}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default UsersDropdown;
