// uncomment this line if you have something to import from your scss module
// import {} from "./index.module.scss"

import { DayPickerRangeController } from "react-dates";

/**
 * @param {import("react-dates").DayPickerRangeControllerShape} param0
 * @returns
 */
export default function Calendar({ ...props }) {
  return <DayPickerRangeController {...props} />;
}
