/*
  Shim to adapt the create-react-app style environment variables to 
  the style we were using before
*/
const config = {
  cognito: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPool: process.env.REACT_APP_COGNITO_USERPOOL,
    clientId: process.env.REACT_APP_COGNITO_CLIENTID,
    loginDomain: process.env.REACT_APP_COGNITO_LOGINDOMAIN,
    redirectUri: process.env.REACT_APP_COGNITO_REDIRECTURI
  },
  facebook: {
    appId: process.env.REACT_APP_FACEBOOK_APPID
  },
  google: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENTID
  },
  intercom: {
    appId: process.env.REACT_APP_INTERCOM_APPID
  },
  firebase: {
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY
  },
  api: process.env.REACT_APP_API
};

export default config;
