import classNames from "classnames";
import Checkbox from "../Checkbox";
import { noop } from "lodash";

import styles from "./IconCheckbox.module.scss";

export default function IconCheckbox({
  checked,
  onToggle = noop,
  className,
  iconChecked = "fa fa-check",
  iconUnchecked = "fa fa-times",
  ...props
}) {
  return (
    <label className={classNames(styles.checkbox, className)}>
      <Checkbox className="d-none" checked={checked} onToggle={onToggle} {...props} />
      <i data-testid="checkbox-icon" className={classNames({ [iconChecked]: checked, [iconUnchecked]: !checked })} />
    </label>
  );
}
