import * as types from "../actions/actionTypes";
import { Auth } from "aws-amplify";
import { datadogRum } from "@datadog/browser-rum";

export default function cognito(state, action) {
  if (state === undefined) {
    let initialState = {};
    return initialState;
  } else {
    switch (action.type) {
      case types.LOGIN_USER:
        let payload = action.session.idToken.decodePayload();
        datadogRum.setUser({
          email: payload?.email
        });
        return Object.assign({}, state, { user: payload, authState: action.authState });

      case types.LOGOUT_USER:
        localStorage.clear();
        Auth.signOut().then(() => (window.location = process.env.REACT_APP_COGNITO_REDIRECTURI));
        return { signingOut: true };

      default:
        return state;
    }
  }
}
