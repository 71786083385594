import * as types from "./actionTypes";

export function reportSetStartDate(date) {
  return { type: types.REPORT_SET_START_DATE, date };
}

export function reportSetEndDate(date) {
  return { type: types.REPORT_SET_END_DATE, date };
}

export function reportClearCustomer() {
  return { type: types.REPORT_CLEAR_CUSTOMER };
}
