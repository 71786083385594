import React, { useCallback, useMemo, useState } from "react";
import { updateTekionInfo } from "../../../api/customerApi";
import InputField from "../../../components/Form/InputField";
import { Collapse } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import WWButton from "../../../components/Buttons/WWButton";

export default class Tekion {
  field() {
    return "tekionInfo";
  }

  add() {
    return Add;
  }

  edit() {
    return Edit;
  }
}

const Edit = ({ info, customer }) => {
  const [initialDealerId, setInitialDealerId] = useState(info.dealerId);
  const [dealerId, setDealerId] = useState(initialDealerId);

  const patchReady = useMemo(() => initialDealerId !== dealerId, [initialDealerId, dealerId]);
  const saveToggles = useCallback(() => {
    updateTekionInfo(customer.id, info.id, {
      dealerId
    }).then(() => {
      setInitialDealerId(dealerId);
    });
  }, [info, dealerId, customer]);

  return (
    <>
      <InputField
        type="text"
        name="dealerId"
        label="Tekion Dealer Id"
        placeholder="Id"
        onChange={e => setInitialDealerId(e.target.value)}
        value={dealerId}
      />
      <Collapse isOpen={patchReady}>
        <FormField label=" ">
          <WWButton color="primary" onClick={saveToggles} disabled={!patchReady}>
            Update
          </WWButton>
        </FormField>
      </Collapse>
    </>
  );
};

const Add = ({ onSave }) => {
  const [dealerId, setDealerId] = useState();

  const onLink = useCallback(
    () =>
      onSave({
        dealerId,
        source: "TEKION"
      }),
    [dealerId, onSave]
  );

  return (
    <>
      <InputField
        type="text"
        name="dealerId"
        label="Tekion Dealer Id"
        placeholder="Id"
        onChange={e => setDealerId(e.target.value)}
        value={dealerId}
      />
      <WWButton disabled={!dealerId} color="primary" onClick={onLink}>
        Link
      </WWButton>
    </>
  );
};
