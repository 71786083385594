import Axios from "axios";
import config from "../config";
import * as apiClient from "./apiClient";

const TEMPLATES = "responseTemplates";
const TEMPLATE_SETS = "responseTemplateSets";

export function loadTemplateSets(customerId, auto) {
  return apiClient.get(TEMPLATE_SETS, { params: { customerId, auto } });
}

export const loadTemplates = setId =>
  Axios.get(`${config.api}/${TEMPLATE_SETS}/${setId}/templates`).then(({ data }) => ({
    data: data._embedded?.responseTemplates || []
  }));

export const createTemplateSet = ({ strategy: responseStrategy, auto: autoResponse, customer: customerId, ...rest }) =>
  Axios.post(
    `${config.api}/${TEMPLATE_SETS}`,
    {
      ...rest,
      responseStrategy,
      autoResponse
    },
    {
      params: {
        customerId
      }
    }
  );

export const createTemplate = ({ setId, content: text }) =>
  Axios.post(`${config.api}/${TEMPLATE_SETS}/${setId}/responseTemplates`, {
    text
  });

export const updateTemplate = ({ id, setId: templateSetId, content: text }) =>
  Axios.patch(`${config.api}/${TEMPLATES}/${id}`, { text, templateSetId });

export const removeTemplate = id => Axios.delete(`${config.api}/${TEMPLATES}/${id}`);

export function render(templateId, reviewId) {
  return apiClient.get([TEMPLATES, templateId, "render"], {
    params: { reviewId }
  });
}
