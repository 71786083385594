import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
  UncontrolledTooltip
} from "reactstrap";
import map from "lodash/map";
import moment from "moment";
import { useCampaignUpdate, useCampaignClone } from "hooks/data/campaignHooks";
import { useLocationQueryParamState, useStateThroughRedux } from "hooks/stateHooks";
import { CardBody, CardHeaderAction, CardHeaderActions, CardTitle } from "components/Card";
import Time from "components/DateTime/Time";
import { TableCard, TableCardHeader, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import WWButton from "components/Buttons/WWButton";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import Conditional from "components/Misc/Conditional/Conditional";
import SelectLocationPrompt from "components/Misc/SelectLocationPrompt";
import CampaignEdit from "./CampaignEdit/CampaignEdit";
import { INVITE_LANDING_PAGE_PREFIX } from "util/constants";
import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import useFilterQuery from "hooks/data/useFilterQuery";
import FilterPagination from "components/FacetedSearch/Pagination/FilterPagination";
import { useFilterState } from "hooks/filteringHooks";

import styles from "./CampaignsList.module.scss";

const { buttonGroup } = styles;

const NEW_CAMPAIGN = "new";

const filterConfig = [
  {
    name: "customerId",
    label: "Location",
    type: "locationsWithInvite",
    minLength: 0,
    required: true,
    primaryBar: {
      position: "LEFT",
      className: "d-md-flex auto-width"
    },

    chip: {
      position: "NONE"
    }
  },
  {
    name: "status",
    label: "Status",
    type: "buttonDropdown",
    defaultValue: "LIVE",
    required: true,
    clearable: false,
    options: [
      { value: "LIVE", label: "Live" },
      { value: "ARCHIVED", label: "Archived" }
    ],
    primaryBar: {
      position: "RIGHT",
      className: "d-none d-md-flex"
    },
    secondaryBar: {
      className: "d-flex d-md-none"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "page",
    defaultValue: 0,
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  }
];

export const CampaignsList = () => {
  const [editingCampaignId, setEditingCampaignId] = useLocationQueryParamState("modalCampaignId");
  const cloneCampaign = useCampaignClone();
  const updateCampaign = useCampaignUpdate();
  const [filterValues] = useFilterState();
  const campaignQuery = useFilterQuery({
    filters: filterConfig,
    url: `/campaigns`
  });

  const clone = useCallback(
    (id, e) => {
      e.stopPropagation();
      cloneCampaign.mutateAsync(id).then(data => edit(data.id));
    },
    [cloneCampaign]
  );
  const archive = useCallback(
    (id, e) => {
      e.stopPropagation();
      updateCampaign.mutate({ id, status: "ARCHIVED" });
    },
    [updateCampaign]
  );
  const send = useCallback(
    (id, e) => {
      e.stopPropagation();
      setSettingsOpened(true);
      setEditingCampaignId(id);
      setSettingsSection("send");
    },
    [setSettingsOpened, setEditingCampaignId, setSettingsSection]
  );

  const [, setSettingsOpened] = useStateThroughRedux("campaignModal/opened", false);
  const [, setSettingsSection] = useStateThroughRedux("campaignModal/section", undefined);

  useEffect(() => {
    if (editingCampaignId === NEW_CAMPAIGN) {
      setSettingsSection("typeSelector");
    } else if (editingCampaignId) {
      setSettingsSection("config");
    }
  }, []);

  useEffect(() => {
    setSettingsOpened(!!editingCampaignId);
  }, [editingCampaignId]);

  const edit = useCallback(
    (id, e) => {
      e.stopPropagation();
      setSettingsOpened(true);
      setEditingCampaignId(id);
      setSettingsSection("config");
    },
    [setEditingCampaignId, setSettingsSection]
  );

  const newCampaign = useCallback(() => {
    if (editingCampaignId === NEW_CAMPAIGN) {
      setEditingCampaignId("");
    }
    setEditingCampaignId(NEW_CAMPAIGN);
    setSettingsSection("typeSelector");
  }, [setEditingCampaignId, setSettingsSection]);

  return (
    <div className="mt-4 container-fluid">
      <FacetedSearch showFiltersButton={false} filters={filterConfig} />
      {!filterValues?.customerId?.id ? (
        <SelectLocationPrompt />
      ) : (
        <Row>
          <Col>
            <TableCard>
              <TableCardHeader className="flex-wrap justify-content-between">
                <CardTitle>Campaigns</CardTitle>
                <Row className="d-flex align-items-center justify-content-between flex-wrap">
                  <Col xs="auto" className="d-flex align-items-center ms-auto">
                    {filterValues?.customerId?.id && (
                      <CardHeaderActions>
                        <Link
                          className="btn btn-secondary text-uppercase"
                          to={`/invite/automatedCampaigns?customerId[id]=${filterValues?.customerId?.id}&customerId[companyName]=${filterValues?.customerId?.companyName}`}
                        >
                          View Automated Campaigns
                        </Link>
                        <CardHeaderAction className="d-md-inline mx-1" color="primary" onClick={() => newCampaign()}>
                          Create New Campaign
                        </CardHeaderAction>
                      </CardHeaderActions>
                    )}
                  </Col>
                </Row>
              </TableCardHeader>
              <CardBody>
                <WWTable>
                  <WWTHead>
                    <WWTR>
                      <WWTH>Type</WWTH>
                      <WWTH>Name</WWTH>
                      <WWTH>Updated Date</WWTH>
                      <WWTH>Start/End Date</WWTH>
                      <WWTH>
                        <div className="d-flex justify-content-end">
                          {/* <Pager pageInfo={pageInfo} onPageChange={v => setPageNumber(v)} /> */}
                        </div>
                      </WWTH>
                    </WWTR>
                  </WWTHead>
                  {campaignQuery.isLoading && <TableBodySkeleton cols={5} rows={3} />}
                  {campaignQuery.data?.data?.campaignListDtoes && (
                    <WWTBody>
                      {map(
                        campaignQuery.data.data?.campaignListDtoes,
                        ({
                          id,
                          name,
                          type,
                          lastModifiedOn,
                          startDate,
                          endDate,
                          shortCode,
                          status,
                          automatedCampaigns
                        }) => (
                          <WWTR key={id} onClick={e => edit(id, e)}>
                            <WWTD>{typeIcon(type)}</WWTD>
                            <WWTD>
                              <span id={"name" + id}>{name}</span>
                              <UncontrolledTooltip target={"name" + id}>{name}</UncontrolledTooltip>
                            </WWTD>
                            <WWTD>
                              <div>
                                <Time withIcon={false} format="dateSlash" date={lastModifiedOn} />
                              </div>
                              <Time className="text-muted small" withIcon={false} format="time" date={lastModifiedOn} />
                            </WWTD>
                            <WWTD>
                              <CampaignDates startDate={startDate} endDate={endDate} />
                            </WWTD>
                            <WWTD>
                              <div className={buttonGroup}>
                                <WWButton
                                  className="d-none d-sm-block"
                                  color="primary"
                                  disabled={status === "ARCHIVED"}
                                  iconClass="fa fa-upload"
                                  onClick={e => send(id, e)}
                                  tooltip="Upload CSV"
                                  tooltipPlacement="top"
                                />
                                <UncontrolledButtonDropdown>
                                  <DropdownToggle
                                    onClick={e => e.stopPropagation()}
                                    className="m-0"
                                    color="secondary"
                                    caret
                                  >
                                    More
                                  </DropdownToggle>
                                  <DropdownMenu container="body" positionFixed={true}>
                                    <DropdownItem onClick={e => edit(id, e)}>
                                      <i className="fa fa-edit" /> Edit
                                    </DropdownItem>
                                    <DropdownItem onClick={e => clone(id, e)}>
                                      <i className="fa fa-clone" /> Clone
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={e => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(`${INVITE_LANDING_PAGE_PREFIX}${shortCode}`);
                                      }}
                                    >
                                      <i className="fa fa-copy" /> Copy Link
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={e => {
                                        e.stopPropagation();
                                        window.open(`${INVITE_LANDING_PAGE_PREFIX}${shortCode}`, "_blank");
                                      }}
                                    >
                                      <i className="fa fa-eye" /> Preview
                                    </DropdownItem>
                                    <DropdownItem onClick={e => send(id, e)} className="m-0 d-sm-none" color="primary">
                                      <i className="fa fa-upload" />
                                      Upload CSV
                                    </DropdownItem>
                                    {status !== "ARCHIVED" && (
                                      <DropdownItem className="text-danger" onClick={e => archive(id, e)}>
                                        <i className="fa fa-trash" /> Archive
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledButtonDropdown>
                              </div>
                            </WWTD>
                          </WWTR>
                        )
                      )}
                    </WWTBody>
                  )}
                </WWTable>
              </CardBody>
            </TableCard>
            <div className="d-flex justify-content-center">
              <FilterPagination
                isLoading={campaignQuery.isLoading}
                pageInfo={campaignQuery.data?.pageInfo}
                totalPages={campaignQuery.data?.pageInfo?.totalPages}
                elementsLabel="Campaigns"
              />
            </div>
          </Col>
        </Row>
      )}
      <CampaignEdit
        key={editingCampaignId}
        createNew={editingCampaignId === NEW_CAMPAIGN}
        campaignId={editingCampaignId}
        setCampaignId={setEditingCampaignId}
      />
    </div>
  );
};

const typeIcon = type => {
  switch (type) {
    case "REVIEW":
      return <i className="bg-image-logo-invite-color p-1 p-sm-2" />;
    case "VIDEO":
      return <i className="bg-image-logo--video-color p-1 p-sm-2" />;
    case "VIDEO_FOLLOW_UP":
      return <i className="bg-image-logo-invite-fallow-up-color p-1 p-sm-2" />;
    default:
      return null;
  }
};

const CampaignDates = ({ startDate, endDate }) => {
  if (!startDate && !endDate) {
    return <span>Ongoing</span>;
  }

  if (startDate && endDate) {
    return (
      <>
        <Time withIcon={false} format="dateSlash" date={startDate} />
        {" - "}
        <Time withIcon={false} format="dateSlash" date={endDate} />
        <Expired endDate={endDate} />
      </>
    );
  }

  if (startDate) {
    return (
      <>
        {"Starts "}
        <Time withIcon={false} format="dateSlash" date={startDate} />
      </>
    );
  }

  if (endDate) {
    return (
      <>
        {"Ends "}
        <Time withIcon={false} format="dateSlash" date={endDate} />
        <Expired endDate={endDate} />
      </>
    );
  }
};

const Expired = ({ endDate }) => (
  <Conditional test={moment().isAfter(endDate)}>
    <br />
    <small className="text-danger">Expired</small>
  </Conditional>
);
