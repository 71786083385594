import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import ToggleAccordion from "components/WWAccordion/ToggleAccordion/ToggleAccordion";
import { isEqual, values } from "lodash";
import { assocPath, flatten, identity, pathOr, prop } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Row } from "reactstrap";
import ToggleSwitch from "components/Form/Switch";
import useContact from "hooks/data/useContact";
import useContactUpdater from "hooks/data/useContactUpdater";
import usePureEffect from "hooks/usePureEffect/usePureEffect";
import { CONTACT_TYPE_WIDEWAIL_USER } from "util/constants";
import { permissions } from "components/Auth/permissions";
import { AuthorizationRequiredToRender } from "components/Auth/Authorization";
import ActiveProductsRequireToRender from "components/Auth/ActiveProductsRequireToRender";
import StatusBar from "../../../StatusBar/StatusBar";
import ListingsNotificationsSettingsForm from "./ListingsNotificationsSettingsForm";
import DigestNotificationsSettingsForm from "./DigestNotificationsSettings";

const targetsValue = pathOr(undefined, ["target", "checked"]);
const targetsName = pathOr(undefined, ["target", "name"]);

{
  /*
TODO: Next time we touch this, refactor to remove duplication between this component and EditContact
*/
}
export default function NotificationsSettings({ id }) {
  const currentContact = useSelector(prop("currentContact"));
  const { data: initialContact } = useContact(currentContact.id);
  const [save] = useContactUpdater();
  const [contact, setContact] = useState();
  usePureEffect(setContact, initialContact);

  const setter = useCallback((key, picker) => v => setContact(assocPath(key, picker(v))), []);

  const setNotification = useCallback(
    (value, path = null, picker = identity) =>
      setter(flatten(["notificationPreferences", path || targetsName(value)]), picker)(value),
    []
  );

  const defaultSetNotification = useCallback(value => setNotification(value, null, targetsValue), []);

  // Status bar integration...
  const [status, setStatus] = useState("clean");
  useEffect(() => {
    if (!!contact && !!initialContact) {
      if (status === "clean" && !isEqual(contact, initialContact)) {
        setStatus("dirty");
      } else if (status === "savingRequested") {
        setStatus("saving");
        save(contact.id, contact)
          .then(() => setStatus("success"))
          .catch(() => setStatus("failure"));
      } else if (status === "cleanRequested") {
        setContact(initialContact);
        setStatus("clean");
      } else if (status === "success") {
        const timeoutid = setTimeout(() => setStatus("clean"), 1000);
        return () => clearTimeout(timeoutid);
      }
    }
  }, [status, initialContact, contact]);
  // ...Status bar integration

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="p-4">
            <h4>Review Response Notifications</h4>
            <Form className="form-horizontal">
              <ToggleSwitch
                label="Receive SMS Notifications"
                labelWidth={6}
                inputWidth={6}
                onLabel=""
                offLabel=""
                name="receiveSmsNotifications"
                onChange={defaultSetNotification}
                checked={contact?.notificationPreferences.receiveSmsNotifications}
                value="contact.notificationPreferences.receiveSmsNotifications"
              />
              <ToggleSwitch
                label="Receive Spam Notifications"
                labelWidth={6}
                inputWidth={6}
                onLabel=""
                offLabel=""
                name="receiveSpamNotifications"
                onChange={defaultSetNotification}
                checked={contact?.notificationPreferences.receiveSpamNotifications}
                value="contact.notificationPreferences.receiveSpamNotifications"
              />
              <ToggleAccordion
                onChange={value => {
                  ["oneStar", "twoStar", "threeStar", "fourStar", "fiveStar"].map(star =>
                    setNotification(value, ["receiveNewReviewNotifications", star])
                  );
                }}
                isOpen={
                  !values(contact?.notificationPreferences.receiveNewReviewNotifications).every(
                    value => value === false
                  )
                }
                label={"Receive New Review Notifications"}
                name="receiveNewReviewNotifications"
              >
                <Labeled
                  checked={contact?.notificationPreferences.receiveNewReviewNotifications.oneStar}
                  onToggle={value => setNotification(value, ["receiveNewReviewNotifications", "oneStar"])}
                  name="receiveNewReviewNotifications.oneStar"
                  label={"One Star"}
                />
                <Labeled
                  checked={contact?.notificationPreferences.receiveNewReviewNotifications.twoStar}
                  onToggle={value => setNotification(value, ["receiveNewReviewNotifications", "twoStar"])}
                  name="receiveNewReviewNotifications.twoStar"
                  label={"Two Star"}
                />
                <Labeled
                  checked={contact?.notificationPreferences.receiveNewReviewNotifications.threeStar}
                  onToggle={value => setNotification(value, ["receiveNewReviewNotifications", "threeStar"])}
                  name="receiveNewReviewNotifications.threeStar"
                  label={"Three Star"}
                />
                <Labeled
                  checked={contact?.notificationPreferences.receiveNewReviewNotifications.fourStar}
                  onToggle={value => setNotification(value, ["receiveNewReviewNotifications", "fourStar"])}
                  name="receiveNewReviewNotifications.fourStar"
                  label={"Four Star"}
                />
                <Labeled
                  checked={contact?.notificationPreferences.receiveNewReviewNotifications.fiveStar}
                  onToggle={value => setNotification(value, ["receiveNewReviewNotifications", "fiveStar"])}
                  name="receiveNewReviewNotifications.fiveStar"
                  label={"Five Star"}
                />
              </ToggleAccordion>
            </Form>
          </div>
          {contact?.type === CONTACT_TYPE_WIDEWAIL_USER && (
            <div className="p-4">
              <h4>Digest Notifications</h4>
              <DigestNotificationsSettingsForm contact={contact} onChange={defaultSetNotification} />
            </div>
          )}
          <div className="p-4">
            <AuthorizationRequiredToRender roles={[permissions.LISTINGS_MANAGE]}>
              <ActiveProductsRequireToRender products={["LISTINGS_MANAGEMENT"]}>
                <h4>Listings Notifications</h4>
                <ListingsNotificationsSettingsForm contact={contact} onChange={defaultSetNotification} />
              </ActiveProductsRequireToRender>
            </AuthorizationRequiredToRender>
          </div>
        </Col>
      </Row>
      <StatusBar status={status} onStatusChange={setStatus} />
    </>
  );
}
