import React, { useMemo, useState, useEffect, useCallback, useLayoutEffect } from "react";
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Alert } from "reactstrap";
import { debounce, delay, set, filter, isEmpty } from "lodash";
import { not } from "ramda";
import "firebase/database";
import copy from "copy-to-clipboard";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import * as customerApi from "api/customerApi";
import * as templateApi from "api/responseTemplateApi";
import ResponseTemplateList from "../ResponseTemplate/ResponseTemplateList";
import ContactsWidget from "../Contacts/ContactsWidget";
import StatusMessages from "../Misc/StatusMessages";
import { AgencyAdminFragment } from "../Auth/AuthorizedComponents";
import { EmojiPicker } from "components/Misc/EmojiPicker";
import { useConfirmationDialog } from "../Modals/confirmation.modal";
import { autoclose } from "../Modals";
import InputField from "components/Form/InputField";
import HelpIcon from "components/Misc/HelpIcon";
import WWButton from "components/Buttons/WWButton";
import HipaaReminder from "./Hipaa/HipaaReminder";
import Conditional from "components/Misc/Conditional/Conditional";
import { useErrorNotification } from "components/Notifications/notification";
import useTranslate from "hooks/data/useTranslate";
import { useSelection } from "hooks/utilHooks";
import Keywords from "data/autodata";
import { preferredCompanyName } from "util/customerUtils";
import { isCurrentUserInGroup, currentContactName } from "util/userUtils";
import { SIGNATORY_TAG, ACTIVE_CONTACT } from "data/options";
import { useResponseBotReviewReply } from "hooks/data/responseBotHooks";
import classNames from "classnames";
import AjaxLoader from "components/Misc/AjaxLoader";
import Block from "components/Layout/Block";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import { useResponderNotesByReviewId } from "hooks/data/responderNotesHooks";

import styles from "./ReplyEditor.module.scss";

const { translateTextArea, replyButtonGroup, aiButton } = styles;

export const ReplyEditor = ({ review, onUpdateStatus, onSave, ...props }) => {
  const errorNotification = useErrorNotification();
  const author = useMemo(() => currentContactName(), []);
  const [reply, setReply] = useState();
  const [textarea, setTextarea] = useState();
  const [binder, selection, setSelection] = useSelection();
  const [responsePoupup, setResponsePoupup] = useState(false);
  const [errors, setErrors] = useState([]);
  const [publishingDisabled, setPublishingDisabled] = useState(false);
  const [hipaaAcknowledged, setHipaaAcknowledged] = useState(!review.customer.hipaaCompliant);
  const [notesAcknowledged, setNotesAcknowledged] = useState(false);
  const confirm = useConfirmationDialog();
  const [enableGenerateResponse, setEnableGenerateResponse] = useState(false);

  const [translationLock, setTranslationLock] = useState(true);

  const AIPrompt = useResponseBotReviewReply(
    review?.id,
    props.reply?.parentId !== review?.id && props.reply?.parentId,
    {
      enabled: enableGenerateResponse,
      onSuccess: data => {
        insert(data);
        setEnableGenerateResponse(false);
      }
    }
  );

  useEffect(() => {
    if (props.reply?.content === "") {
      setTranslationLock(false);
    } else {
      setTranslationLock(true);
    }
  }, [props.reply?.content]);

  const translate = useTranslate();
  const responderNoteQuery = useResponderNotesByReviewId(review.id);
  const noteAcknowledgmentRequired = !isEmpty(responderNoteQuery.data?.filter(note => note.requireConfirmation));

  const translateText = useCallback((text, sourceLanguage) => {
    translate.mutate(
      { text, sourceLanguage },
      {
        onSuccess: data => {
          setReply(prev => ({ ...prev, translatedContent: data }));
        }
      }
    );
  }, []);

  const debouncedTranslate = useMemo(() => debounce(translateText, 1000), []);

  const onAboutToPublish = useCallback(() => {
    new Promise(resolve =>
      /* Confirm before publishing to sites which don't support deleting or edit responses */
      review.rating <= 3 && (review.source === "FACEBOOK" || review.source === "CARS_DOT_COM")
        ? autoclose(confirm()).then(v => v && resolve())
        : resolve()
    )
      .then(() => {
        setPublishingDisabled(true);
        setTranslationLock(true);
        copy(reply?.content);
        return onSave(reply, true);
      })
      .finally(() => setPublishingDisabled(false));
  }, [onSave, reply, review]);

  useLayoutEffect(() => {
    if (!!textarea) {
      const { onBlur, onFocus } = binder();
      textarea.addEventListener("focus", onFocus);
      textarea.addEventListener("blur", onBlur);
      return () => {
        textarea.removeEventListener("focus", onFocus);
        textarea.removeEventListener("blur", onBlur);
      };
    }
  }, [textarea, binder]);

  useEffect(() => {
    setReply(
      props.reply && props.reply?.createdOn
        ? { ...props.reply, author }
        : {
            author,
            createdOn: new Date(),
            content: "",
            status: "DRAFT",
            translatedContent: ""
          }
    );
  }, [author, props.reply]);

  const insert = useCallback(
    insertion => {
      setReply(prev => ({
        ...prev,
        content: `${prev.content.slice(0, selection[0])}${insertion}${prev.content.slice(selection[1])}`
      }));
      if (textarea) {
        textarea.focus();
        const newCursorPosition = selection[1] + insertion.length;
        delay(() => setSelection([newCursorPosition, newCursorPosition]), 0);
      }
    },
    [selection, textarea]
  );
  const selectTemplate = useCallback(
    ({ id }) => {
      templateApi
        .render(id, review.id, review.customer.id)
        .then(res => {
          insert(res.data.content.text);
          setResponsePoupup(false);
        })
        .catch(err => {
          setErrors(err.response.data.errors);
        });
    },
    [insert, review]
  );

  const selectAiTemplate = useCallback(() => {
    if (AIPrompt?.data) {
      insert(AIPrompt?.data);
    } else if (AIPrompt?.data === "") {
      errorNotification("No AI Response Available");
    } else {
      setEnableGenerateResponse(true);
    }
  }, [AIPrompt?.data, insert]);

  const [contacts, setContacts] = useState([]);

  const triggerRTAConfig = useMemo(() => {
    if (isCurrentUserInGroup("AGENCY_ADMIN")) {
      return {
        "#": {
          dataProvider: () => [review.author?.split(" ", 2)[0]],
          component: ({ entity }) => <div>{entity}</div>,
          output: item => ({ text: item, caretPosition: "end" })
        },
        ":": {
          dataProvider: token => [
            {
              lvl: 1,
              key: review.customer.id,
              name: preferredCompanyName(review.customer, review.source, review.siteInfoId),
              icon: "fa fa-building",
              text: preferredCompanyName(review.customer, review.source, review.siteInfoId)
            }
          ],
          component: ({ entity: { icon, name, lvl } }) => (
            <div>
              <i style={{ marginLeft: lvl + "px" }} className={icon} />
              {" ".repeat(lvl) + name}
            </div>
          ),
          output: item => item.text
        },
        "@": {
          dataProvider: token =>
            token?.length
              ? filter(Keywords, m => m.toLowerCase().startsWith(token.toLowerCase()))
              : ["Type to search..."],
          component: ({ entity }) => <div>{entity}</div>,
          output: item => item
        }
      };
    } else {
      return {};
    }
  }, [review, contacts]);

  /// TODO : contacts is also used in CustomerMessageComposer so this call can be moved to the parent component and contacts then used here and there
  useEffect(() => {
    customerApi
      .loadCustomerContacts(review.customer.id)
      .then(res => {
        const contacts = res.data._embedded.contacts;
        const filtered = contacts.filter(
          c => c.tags && c.tags.indexOf(SIGNATORY_TAG) >= 0 && c.status == ACTIVE_CONTACT
        );
        setContacts(filtered);
      })
      .catch(errorNotification);
  }, []);

  const updateReplyState = useCallback(
    ({ target: { name, value } }) => {
      setReply(reply =>
        name === "customer"
          ? { ...reply, customer: { ...reply?.customer, id: value.id } }
          : set({ ...reply }, name, value)
      );
      if (value && (review.language === "es" || review.language === "fr") && !translationLock) {
        debouncedTranslate(value, review.language);
      }
    },
    [translationLock, review]
  );

  const saveDisabled = !hipaaAcknowledged || (noteAcknowledgmentRequired && !notesAcknowledged);
  const saveAndPublishDisabled =
    publishingDisabled || !hipaaAcknowledged || (noteAcknowledgmentRequired && !notesAcknowledged);

  return (
    <div>
      <Row>
        <Col>
          <small className="text-muted fs-2">
            Reply by&nbsp;
            <small className="fw-bold fs-2">{reply?.author}</small>
            &nbsp;on&nbsp;
            <small className="fw-bold fs-2">{new Date(reply?.createdOn).toLocaleDateString()}</small>
          </small>
          <div className="position-relative">
            <ReactTextareaAutocomplete
              id="content"
              name="content"
              value={reply?.content || ""}
              onChange={updateReplyState}
              loadingComponent={() => <span>Loading...</span>}
              style={{
                fontSize: "14px",
                minHeight: "10em",
                height: "auto"
              }}
              itemStyle={{
                overflow: "hidden",
                whiteSpace: "nowrap"
              }}
              dropdownStyle={{
                maxWidth: "50%",
                zIndex: 1
              }}
              innerRef={v => setTextarea(v)}
              minChar={0}
              trigger={triggerRTAConfig}
            />
          </div>
        </Col>
      </Row>
      {(review.language === "es" || review.language === "fr") && (
        <Row>
          <Col>
            <div className="d-flex justify-content-between w-100">
              <small className="text-muted fs-2 fw-bold align-self-end">
                Widewail Translation <HelpIcon icon="info">Widewail Translation for internal use only </HelpIcon>
              </small>
              <WWButton
                className="my-1"
                size="sm"
                iconClass={`fa fa-${translationLock ? "lock" : "unlock-alt"}`}
                color={translationLock ? "light" : "secondary"}
                onClick={() => setTranslationLock(not)}
              />
            </div>
            <InputField
              key={reply?.createdOn}
              name="Widewail Translation"
              inputClassName={translateTextArea}
              type="textarea"
              disabled={translationLock}
              value={reply?.translatedContent || ""}
              onChange={e => setReply(prev => ({ ...prev, translatedContent: e.target.value }))}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <div className="d-flex flex-nowrap justify-content-between">
            <small className="fs-2">
              <code>#</code> Author's First Name &nbsp; &nbsp;
              <code>:</code> Location Name &nbsp; &nbsp;
              <code>@</code> Make/Model Data
            </small>
            <EmojiPicker onPick={({ native }) => insert(native)} />
          </div>
        </Col>
      </Row>
      {noteAcknowledgmentRequired && (
        <Block>
          <Alert className="py-1 mb-1" color="primary">
            <Labeled
              className="mb-0"
              value={notesAcknowledged}
              onToggle={setNotesAcknowledged}
              label="This response follows Responder instruction guidelines"
            />
          </Alert>
        </Block>
      )}
      {review.customer.hipaaCompliant && (
        <HipaaReminder hipaaAcknowledged={hipaaAcknowledged} setHipaaAcknowledged={setHipaaAcknowledged} />
      )}
      <Row>
        <Col
          className={classNames("justify-content-between align-items-center d-flex flex-wrap mt-1", replyButtonGroup)}
        >
          <div
            className={classNames(
              "d-flex align-items-center justify-content-stretch flex-wrap w-100",
              replyButtonGroup
            )}
          >
            {!props.reply?.replyToReply && (
              <WWButton iconClass="fa fa-magic" outline disabled={AIPrompt.isLoading} onClick={selectAiTemplate}>
                {AIPrompt.isLoading ? <AjaxLoader size={4} /> : "AI Response"}
              </WWButton>
            )}
            <AgencyAdminFragment>
              <WWButton color="light" className="flex-grow-1" onClick={() => setResponsePoupup(true)}>
                Response Templates
              </WWButton>
              {review.status === "REVIEW" ? (
                <WWButton color="primary" className="flex-grow-1" onClick={() => onUpdateStatus("UNREPLIED")}>
                  Mark Unreplied
                </WWButton>
              ) : (
                <WWButton
                  color="light"
                  className="flex-grow-1"
                  onClick={() => {
                    setTranslationLock(true);
                    setReply(prev => {
                      const content = { ...prev, status: "REVIEW" };
                      onSave(content, false);
                      return content;
                    });
                  }}
                >
                  Review
                </WWButton>
              )}
            </AgencyAdminFragment>
          </div>
          <div
            className={classNames(
              "d-flex flex-grow-1 align-items-center justify-content-stretch flex-wrap",
              replyButtonGroup
            )}
          >
            <WWButton
              color="primary"
              className="flex-grow-1"
              disabled={saveAndPublishDisabled}
              onClick={onAboutToPublish}
            >
              Save &amp; Publish
            </WWButton>
            {reply?.status !== "PUBLISHED" && (
              <WWButton
                color="primary"
                className="flex-grow-1"
                disabled={saveDisabled}
                onClick={() => {
                  setTranslationLock(true);
                  onSave(reply, false);
                }}
              >
                Save
              </WWButton>
            )}
          </div>
        </Col>
      </Row>
      <Conditional test={() => contacts && contacts.length}>
        <Row className="mt-4">
          <Col>
            <h5>Insert Info</h5>
            <ContactsWidget
              mode="parts"
              contacts={contacts}
              customerId={review.customer.id}
              onClick={c => insert(c)}
              tag={SIGNATORY_TAG}
            />
          </Col>
        </Row>
      </Conditional>
      <Modal fade={false} size="lg" isOpen={responsePoupup}>
        <ModalHeader>Templates</ModalHeader>
        <ModalBody>
          <StatusMessages errors={errors} />
          <ResponseTemplateList onPickTemplate={selectTemplate} customerId={review.customer.id} />
        </ModalBody>
        <ModalFooter>
          <WWButton onClick={() => setResponsePoupup(false)}>Cancel</WWButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const SaveAndPublishButton = ({ onClick, disabled = false }) => (
  <Button color="primary" disabled={disabled} onClick={onClick}>
    {"Save & Publish"}
  </Button>
);
