// uncomment this line if you have something to import from your scss module
// import {} from "./Form.module.scss"

import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Form as _Form } from "reactstrap";
import { get, omit } from "lodash";
import {
  eventTargetChecked,
  eventTargetSelectValue,
  eventTargetSelectValueWithLabel,
  useFormEventSetter,
  eventTargetSelectMultiValue
} from "hooks/stateHooks";
import { parseFormFieldName } from "util/stringUtils";
import { assocPath, dissocPath } from "ramda";

export const FormContext = React.createContext();

/**
 * HTML form which supports 2-way binding of object values to form fields.
 * Both text type fields and checkboxes are supported.
 *
 * Input fields contained within the form should have their name prop
 * set to the name of a field in the state object. You may use dot notation to
 * access nested fields.
 *
 * Input fields within the form may also explicitly specify their own value/onChange
 * props to detach themselves from the managed form state.
 *
 * <code>
 * const [myObj, setMyObj] = useState({name: "foo"});
 *
 * return (
 *    <Form state={myObj} setState={setMyObj}>
 *      <Input name="name"/>
 *      <Input name="someOtherField"/>
 *      <Input value={myObj.someManagedValue} onChange={myCustomerHandler}
 *    </Form>
 * )
 * </code>
 *
 * @param {*} param0
 * @returns
 */
export default function Form({ state, setState, initialValues, children, ...props }) {
  const setTextField = useFormEventSetter(setState);
  const setRawValue = useFormEventSetter(setState);
  const setCheckboxField = useFormEventSetter(setState, eventTargetChecked);
  const setSelectValue = useFormEventSetter(setState, eventTargetSelectValue);
  const setSelectValueWithLabel = useFormEventSetter(setState, eventTargetSelectValueWithLabel);
  const setSelectMultiValue = useFormEventSetter(setState, eventTargetSelectMultiValue);
  const setRadioGroupField = useFormEventSetter(setState);

  const [hasNestedFields, setHasNestedFields] = useState(false);
  const [fieldsMap, setFieldsMap] = useState([]);

  const registerField = useCallback(
    name => {
      if (name && typeof name !== "string") {
        throw new Error(`attempt to try register non string form field value "${name}"`);
      }
      if (name && (name.includes(".") || name.includes("["))) {
        setHasNestedFields(true);
      }
      setFieldsMap(prev => ({ ...prev, [name]: { path: name ? parseFormFieldName(name) : name } }));
    },
    [fieldsMap]
  );

  const omitAllNestedFields = (prevMap, fieldName) => {
    return Object.fromEntries(
      Object.entries(prevMap).filter(([key]) => {
        return !(key.indexOf(fieldName) === 0 && (key[fieldName.length] === "." || key[fieldName.length] === "["));
      })
    );
  };

  const removeField = useCallback(
    name => {
      const path = fieldsMap[name]?.path || parseFormFieldName(name);
      // if field is the item of array it not removed but replacing with null, it prevents rendering issues
      if (path.length > 1 && Array.isArray(get(state, path.slice(0, -1)))) {
        setState(assocPath(path, null));
      } else if (path) {
        setState(dissocPath(path));
      }
      setFieldsMap(prevMap => omitAllNestedFields(prevMap, name));
    },
    [fieldsMap, state]
  );

  useEffect(() => {
    if (typeof initialValues === "object") {
      setState(initialValues);
    }
  }, [initialValues]);

  const innerStateForFields = useMemo(() => {
    return Object.keys(fieldsMap).reduce((acc, field) => {
      const value = field.includes(".") || field.includes("[") ? get(state, parseFormFieldName(field)) : state[field];
      return { ...acc, [field]: value };
    }, {});
  }, [state, fieldsMap]);

  const parseFieldName = useCallback(name => (hasNestedFields ? name : parseFormFieldName(name)), [hasNestedFields]);

  return (
    <FormContext.Provider
      value={{
        state: hasNestedFields ? innerStateForFields : state,
        setState,
        setTextField,
        setCheckboxField,
        setRawValue,
        setSelectValue,
        setSelectValueWithLabel,
        setSelectMultiValue,
        setRadioGroupField,
        registerField,
        removeField,
        parseFieldName
      }}
    >
      <_Form {...props}>{children}</_Form>
    </FormContext.Provider>
  );
}
