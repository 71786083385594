import React from "react";
import logo from "../../img/logo-light.svg";

export default function Logo({ maxWidth = "150px", ...props }) {
  return (
    <img
      style={{
        maxWidth,
        ...props
      }}
      src={logo}
      alt="Widewail"
    />
  );
}
