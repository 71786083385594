import styles from "./Checkbox.module.scss";

import classNames from "classnames";
import { noop } from "lodash";
import { useCallback } from "react";

export default function Checkbox({ checked, onToggle = noop, className, ...props }) {
  const changed = useCallback(e => onToggle(e.target.checked), [onToggle]);
  return (
    <input
      className={classNames(className, styles.checkbox)}
      type="checkbox"
      checked={checked}
      onChange={changed}
      {...props}
    />
  );
}
