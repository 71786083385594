import { isArray, toPairs } from "lodash";
import get from "lodash/get";
import React, { useCallback, useState } from "react";
import { Alert, Col, Row, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "reactstrap";
import { AgencyAdminFragment } from "../../components/Auth/AuthorizedComponents";
import { permissions } from "../../components/Auth/permissions";
import { CardHeaderAction } from "../../components/Card";
import CloseButton from "../../components/Buttons/CloseButton";
import MemberList from "../../components/Form/ListField";
import { useGetGroups, useGetPermissions, useGroupAdd, useGroupDelete } from "hooks/data/useContact";

const preCannedPermissionsSets = {
  "Location Admin": [permissions.CONTACT_MANAGE, permissions.LOCATION_MANAGE],
  "Review Responder": [
    permissions.TEMPLATE_MANAGE,
    permissions.REVIEW_INVITE_MANAGE,
    permissions.REVIEW_APPROVE,
    permissions.REVIEW_REPLY,
    permissions.AGENCY_ADMIN,
    permissions.LOCATION_MANAGE,
    permissions.REVIEW_READ,
    permissions.CONTACT_MESSAGE,
    permissions.DASHBOARD_READ,
    permissions.CREATE_POST,
    permissions.CONTACT_MANAGE,
    permissions.REPORT_READ
  ]
};

export default function GroupMembership({ contact, readOnly }) {
  const [open, setOpen] = useState(false);
  const groupsQuery = useGetGroups(contact.id, { enabled: open });
  const groups = get(groupsQuery?.data, "_embedded.permissions") || [];
  const permissionsQuery = useGetPermissions({ enabled: open });
  const availablePermissions = permissionsQuery?.data || [];

  const groupAddMutation = useGroupAdd(contact.id);
  const groupDeleteMutation = useGroupDelete(contact.id);

  const groupAdd = useCallback(v => groupAddMutation.mutate(isArray(v) ? v : [v]), []);
  const groupRemove = useCallback(v => groupDeleteMutation.mutate(v), []);

  return (
    <AgencyAdminFragment>
      <CardHeaderAction onClick={() => setOpen(!open)}>Groups</CardHeaderAction>
      <Modal fade={false} isOpen={open} toggle={() => setOpen(!open)}>
        <ModalHeader>
          <strong className="fw-bold">User Groups</strong>
        </ModalHeader>
        <ModalBody>
          {!readOnly && (
            <Row className="mt-4">
              <Col className="d-flex flex-column">
                <h5>Pre-canned permissions</h5>
                <small className="text-muted">
                  These buttons allows you to set multiple permissions with one click action.
                </small>
                <div className="d-flex flex-row justify-content-stretch flex-wrap">
                  {toPairs(preCannedPermissionsSets).map(([key, value]) => (
                    <Button key={key} className="flex-grow-1 mx-1" color="info" onClick={() => groupAdd(value)}>
                      {key}
                    </Button>
                  ))}
                </div>
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col>
              <h5>Permissions list</h5>
              <MemberList
                readOnly={readOnly}
                placeholder="Groups..."
                members={groups}
                onAdd={groupAdd}
                onRemove={groupRemove}
                async={false}
                options={availablePermissions.map(x => [x])}
                getOptionLabel={v => v}
                getMemberLabel={v => <strong>{v}</strong>}
                getMemberValue={v => v}
              />
            </Col>
          </Row>
          {!readOnly && <Alert color="warning">Users must log out and back in for group changes to take effect</Alert>}
        </ModalBody>
        <ModalFooter>
          <CloseButton onClick={() => setOpen(!open)} />
        </ModalFooter>
      </Modal>
    </AgencyAdminFragment>
  );
}
