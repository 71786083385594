import React, { useCallback, useEffect, useState } from "react";
import { Badge, ListGroup, ListGroupItem, UncontrolledCollapse } from "reactstrap";
import { loadTemplateSets } from "../../api/responseTemplateApi";
import uniqueId from "lodash/uniqueId";
import noop from "lodash/noop";

export default function ResponseTemplateList({ customerId, auto = false, onPickTemplate = noop }) {
  const [sets, setSets] = useState([]);

  const load = useCallback(
    () =>
      loadTemplateSets(customerId, auto).then(res =>
        setSets((res.data || []).map(s => Object.assign({}, s, { htmlId: uniqueId("responseSet") })))
      ),
    [setSets, customerId]
  );

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <ListGroup>
        {sets.map(set => (
          <React.Fragment key={set.id}>
            <ListGroupItem id={set.htmlId} action>
              <strong>{set.name}</strong>
              <Badge className="ms-2" pill>
                {set.templates.length}
              </Badge>
            </ListGroupItem>
            <UncontrolledCollapse toggler={set.htmlId}>
              {set.templates.map(tmpl => (
                <ListGroupItem key={tmpl.id} onClick={() => onPickTemplate(tmpl)}>
                  {tmpl.text}
                </ListGroupItem>
              ))}
            </UncontrolledCollapse>
          </React.Fragment>
        ))}
      </ListGroup>
    </div>
  );
}
