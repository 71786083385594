import { evolve, join, pipe } from "ramda";
import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { cleanUpRequestParams } from "../../util/functionUtils";
import { usePatch } from "../dataHooks";

/**
 *
 * @param {string} id
 * @returns {[(data: any) => Promise<any>, import("react-query").UseMutationResult]}
 */
export const useContactUpdater = id => {
  const post = usePatch();
  const client = useQueryClient();
  const mutation = useMutation(
    ({ data, id }) =>
      post(`/contacts/${id}`, {
        data: pipe(
          cleanUpRequestParams,
          evolve({
            audience: join(","),
            tags: join(",")
          })
        )(data)
      }),
    {
      onSuccess: (_, { id }) => {
        client.invalidateQueries(`/contacts/${id}`);
      }
    }
  );

  return useMemo(() => [(id, data) => mutation.mutateAsync({ id, data }), mutation], [mutation]);
};

export default useContactUpdater;
