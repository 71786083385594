import { Card } from "reactstrap";
import styles from "./StatusBarContainer.module.scss";

const { statusbar } = styles;

export default function StatusBarContainer({ children, className }) {
  return (
    <Card color="light" className={`py-0 ${statusbar} ${className}`}>
      {children}
    </Card>
  );
}
