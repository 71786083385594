import { DashboardWidget } from "components/Widgets/DashboardWidget";
import VolumeChart from "components/Charts/VolumeChart";
import { withReportFilterQuery } from "components/HOCs/ReportWithFilters";
import { DEFAULT_REQUEST_FIELDS, getPrepareFilters } from "util/reportUtils/videoReportUtils";

const VideoInvitesSent = ({ data }) => (
  <DashboardWidget title="Video Requests Sent">
    <VolumeChart data={data} />
  </DashboardWidget>
);

export default withReportFilterQuery({
  url: "/inviteVideoReport/invites-sent",
  prepareFilters: getPrepareFilters([...DEFAULT_REQUEST_FIELDS, "compareMode"])
})(VideoInvitesSent);
