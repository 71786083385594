import classNames from "classnames";
import DeleteButton from "components/Buttons/DeleteButton";
import Time from "components/DateTime/Time";
import StatusInfo from "components/Table/StatusInfo/StatusInfo";
import { WWTD, WWTR } from "components/Table/WWTable";
import { startsWith } from "lodash";
import styles from "../SiteRowRenderers.module.scss";

const HubspotSiteRowRenderer = ({ index, customer, site, onRemove }) => {
  return (
    <>
      <WWTR noHover>
        <WWTD className="ps-0 pt-0">
          <div className={classNames(" d-flex flex-nowrap align-items-center mb-2", { "mt-5": index !== 0 })}>
            <img className={styles.logo} alt={`Hubspot Logo`} src={`/img/logos/primary/HUBSPOT.png`} />
            <h4 className={"m-0 ms-2 " + styles.siteLabel}>Hubspot</h4>
          </div>
        </WWTD>
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
        <WWTD className="pt-0" />
      </WWTR>
      {site.siteInfo?.map(info =>
        info.pipelineConfigurations?.map(pipeline => (
          <WWTR key={pipeline.id}>
            <WWTD>
              <span className="d-flex align-items-center">
                {customer.companyName}
                {(info.url || info.reviewUrl) && (
                  <a
                    onClick={e => e.stopPropagation()}
                    className="ms-2 d-flex align-items-center"
                    href={info.url || info.reviewurl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-external-link" />
                  </a>
                )}
              </span>
            </WWTD>
            <WWTD>
              <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                <span className="font-xs">PIPELINE</span> {pipeline.name}
              </div>
            </WWTD>
            <WWTD>
              <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                <span className="font-xs">STAGE</span> {pipeline.importStageLabel}
              </div>
            </WWTD>
            <WWTD>
              {pipeline.defaultInteractionTag && (
                <div className={classNames("d-flex flex-column align-items-start", styles.tableCell)}>
                  <span className="font-xs">TAG</span> {pipeline.defaultInteractionTag}
                </div>
              )}
            </WWTD>
            <WWTD>
              {info?.importStatus && (
                <>
                  <StatusInfo
                    status={startsWith(info.importStatus, "ERROR")}
                    statusColorMapper={{ true: "danger", false: "success" }}
                  >
                    {info.importStatus}
                  </StatusInfo>
                  <Time
                    className="font-xs text-uppercase"
                    withIcon={false}
                    format="ago"
                    date={info.lastImportSuccess}
                  />
                  <span className="font-xs"> AGO</span>
                </>
              )}
            </WWTD>
            <WWTD>
              <DeleteButton
                confirmationPrompt="Widewail will no longer import data from this location. Existing reviews will be marked read-only."
                type="button"
                size="sm"
                tooltip="Remove Hubspot"
                squareButton
                iconClass="fa fa-chain-broken"
                stopPropagation
                onClick={e => {
                  e.stopPropagation();
                  onRemove(site.customerField, info.id);
                }}
              >
                {null}
              </DeleteButton>
            </WWTD>
          </WWTR>
        ))
      )}
    </>
  );
};

export default HubspotSiteRowRenderer;
