import React, { useContext, useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormContext } from "../Form/Form";
import noop from "lodash/noop";
import get from "lodash/get";
import { assocPath } from "ramda";
import "./RichEditor.scss";

const modules = {
  toolbar: [["link"], ["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]]
};

export default function RichEditor({ name, value, placeholder, readOnly, onChange, className }) {
  const context = useContext(FormContext);
  useEffect(() => {
    context && context.registerField(name);
  }, [name]);

  const changeHandler = useMemo(() => {
    if (onChange) {
      return onChange;
    } else if (context) {
      return val => context.setState(assocPath(name.split("."), val));
    }
    return noop;
  });

  const editorValue = useMemo(() => {
    // is the value explicitly provided or is a context unavailable?
    if (value || !name || !context) {
      return value;
    } else {
      return get(context.state, name);
    }
  }, [value, context?.state, name]);

  return (
      <ReactQuill
        className={className}
        theme="snow"
        value={editorValue}
        onChange={changeHandler}
        modules={modules}
        placeholder={placeholder}
        readOnly={readOnly}
      />
  );
}
