import React, { useCallback, useState } from "react";
import { InputGroup, Button } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import { updateDealervaultGroupId } from "../../../api/customerApi";
import InputField from "../../../components/Form/InputField";
import CopyButton from "../../../components/Buttons/CopyButton";
import Input from "../../../components/Form/Input";

export default class Dealervault {
  field() {
    return "dealervaultInfo";
  }

  add() {
    const AddDealervault = ({ onSave }) => {
      const [groupId, setGroupId] = useState();

      return (
        <InputGroup>
          <Input
            type="text"
            placeholder="Group ID (Optional)"
            onChange={e => setGroupId(e.target.value)}
            value={groupId}
          />
          <Button
            color="primary"
            onClick={() =>
              onSave({
                source: "DEALERVAULT",
                dealerId: groupId
              })
            }
          >
            Link
          </Button>
        </InputGroup>
      );
    };

    return AddDealervault;
  }

  edit() {
    const EditDealervault = ({ info, customer }) => {
      const saveGroupId = useCallback(
        groupId => updateDealervaultGroupId(customer.id, "dealervaultInfo", info.id, groupId),
        [info.id, customer.id]
      );

      return (
        <>
          <InputField
            name="groupId"
            label="Group ID"
            placeholder="Group ID (Optional)"
            value={info.dealerId}
            onChange={evt => saveGroupId(evt.target.value)}
          />
        </>
      );
    };
    return EditDealervault;
  }
}
