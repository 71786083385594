import { findRouteName, useCurrentRoutePath } from "hooks/routeHooks";
import React, { useMemo } from "react";
import { Route, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useFilterSearch } from "hooks/filteringHooks";
import { useReduxPathFromUrlPath } from "hooks/urlHooks";

const BreadcrumbsItem = ({ match }) => {
  const url = useMemo(() => match.url || "", [match]);
  const reduxPath = useReduxPathFromUrlPath(url);
  const reduxSearch = useFilterSearch(reduxPath);
  const routeName = findRouteName(match.url);
  if (routeName) {
    return match.isExact ? (
      <BreadcrumbItem className="text-dark" active>
        {routeName}
      </BreadcrumbItem>
    ) : (
      <BreadcrumbItem>
        <Link to={{ pathname: match.url || "", search: reduxSearch }}>{routeName}</Link>
      </BreadcrumbItem>
    );
  }
  return null;
};

const Breadcrumbs = ({ location: { pathname } }) => {
  const paths = useCurrentRoutePath();
  const items = useMemo(() => paths.map((path, i) => <Route key={i++} path={path} component={BreadcrumbsItem} />), [
    paths
  ]);
  return (
    <Breadcrumb listClassName="rounded-0 mb-0 py-2 align-items-center">
      <Link to={""}>
        <i className="fa fa-home fa-lg text-link pe-2" />
      </Link>
      {items}
    </Breadcrumb>
  );
};

export default props => (
  <div className="app-breadcrumbs">
    <Route path="/:path" component={Breadcrumbs} {...props} />
  </div>
);
