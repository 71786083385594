import React, { useCallback, useContext, useEffect } from "react";
import { InputGroup, Input } from "reactstrap";
import classnames from "classnames";
import { FormContext } from "./Form";

const RadioGroup = ({ options, name, className }) => {
  const context = useContext(FormContext);
  useEffect(() => {
    context && context.registerField(name);
  }, [name]);
  const getChangeHandler = useCallback(
    optionName => ({ target }) =>
      target.checked && context.setRadioGroupField({ target: { name, type: "radio", value: optionName } }),
    [name]
  );

  return (
    <InputGroup className={classnames("d-flex flex-column", className)}>
      {options.map(({ label, optionName }) => (
        <label key={optionName}>
          <Input
            name={name}
            type="radio"
            onChange={getChangeHandler(optionName)}
            checked={context.state[name] === optionName}
          />
          {label}
        </label>
      ))}
    </InputGroup>
  );
};

export default RadioGroup;
