import { CAMPAIGNS } from "data/featureFlags";
import { INVITE_LANDING_PAGE_PREFIX } from "util/constants";
import { currentUserHasFeature, isCurrentUserInGroup } from "util/userUtils";
import { getDefaultApproveFilters } from "util/getApprovalSearch";
import { permissions } from "components/Auth/permissions";

const navConfig = {
  items: [
    {
      title: true,
      name: "Sections",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "" // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Admin Dashboard",
      url: "/admindash",
      icon: "fa fa-dashboard",
      groups: [permissions.AGENCY_ADMIN]
    },
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "fa fa-line-chart"
    },
    {
      name: "Approvals",
      url: "/reviews/feed",
      populateURLQuery: getDefaultApproveFilters,
      icon: "fa fa-bell",
      // there are more than one nav items for one path, noNavHighlightWhenOpened prevents highlighting all of them when the page is opened
      noNavHighlightWhenOpened: true,
      groups: [permissions.REVIEW_APPROVE]
    },
    {
      name: "Report",
      url: "/report",
      icon: "fa fa-pie-chart",
      groups: [permissions.REPORT_READ, permissions.VIDEO_MANAGE],
      children: [
        {
          name: "Review Performance",
          url: "/report/customer",
          icon: "fa fa-pie-chart",
          groups: [permissions.REPORT_READ]
          // not working correctly due to keeping unrelated query
          // should be replaced in favor of populateURLQuery
          // keepQuery: true
        },
        // TODO enable after internal review in prod
        {
          name: "Enterprise",
          url: "/report/enterprise",
          icon: "fa fa-table",
          groups: [permissions.REPORT_READ]
        },
        {
          name: "Group Comparison",
          url: "/report/group",
          icon: "fa fa-table",
          groups: [permissions.REPORT_READ]
          // not working correctly due to keeping unrelated query
          // should be replaced in favor of populateURLQuery
          // keepQuery: true
        },
        {
          name: "Engagement",
          url: "/report/engage",
          icon: "fa fa-table",
          groups: [permissions.REPORT_READ]
          // not working correctly due to keeping unrelated query
          // should be replaced in favor of populateURLQuery
          // keepQuery: true
        },
        {
          name: "Reviews",
          url: "/report/reviews",
          icon: "fa fa-star",
          groups: [permissions.LEGACY_REVIEW_REPORT]
          // not working correctly due to keeping unrelated query
          // should be replaced in favor of populateURLQuery
          // keepQuery: true
        },
        {
          name: "Invitations",
          url: "/report/invite",
          icon: "bg-image-logo-invite",
          groups: [permissions.REPORT_READ]
          // not working correctly due to keeping unrelated query
          // should be replaced in favor of populateURLQuery
          // keepQuery: true
        },
        {
          name: "Video Invitations",
          url: "/report/video",
          icon: "bg-image-logo-video-testimonials",
          groups: [permissions.VIDEO_MANAGE]
        },
        {
          name: "Tags",
          url: "/report/tags",
          icon: "fa fa-at",
          groups: [permissions.REPORT_READ]
        }
      ]
    },
    {
      name: "Create & Share",
      url: "/posts",
      icon: "fa fa-pencil",
      groups: [permissions.CREATE_POST, permissions.CREATE_SHARE, permissions.SHARE_DIRECT],
      children: [
        {
          name: "Create",
          url: "/posts/new",
          icon: "fa fa-edit"
        },
        {
          name: "Posts",
          url: "/posts/posts",
          icon: "fa fa-file-o"
        }
      ]
    },
    {
      name: () => (isCurrentUserInGroup(permissions.LOCATION_MANAGE) ? "Locations & Contacts" : "Contacts"),
      url: "/customers",
      icon: "fa fa-building",
      groups: [permissions.LOCATION_MANAGE, permissions.CONTACT_MANAGE],
      children: [
        {
          name: "Location List",
          url: "/customers",
          icon: "fa fa-building",
          groups: [permissions.LOCATION_MANAGE]
        },
        {
          name: "Contact List",
          url: "/customers/contacts",
          icon: "fa fa-user",
          groups: [permissions.CONTACT_MANAGE]
        },
        {
          name: "Shared Credentials",
          url: "/credentials",
          icon: "fa fa-id-card-o",
          groups: [permissions.AGENCY_ADMIN]
        },
        {
          name: "Accounts",
          url: "/accounts",
          icon: "fa fa-sitemap",
          groups: [permissions.AGENCY_ADMIN]
        },
        {
          name: "Agency Edit",
          url: "/agency",
          icon: "fa fa-university",
          groups: [permissions.AGENCY_ADMIN]
        }
      ]
    },
    {
      name: "Reviews",
      url: "/reviews",
      icon: "fa fa-star",
      groups: [permissions.REVIEW_READ, permissions.REVIEW_APPROVE],
      children: [
        {
          name: "Responder Queue",
          url: "/reviews/list",
          icon: "fa fa-list",
          groups: [permissions.AGENCY_ADMIN]
        },
        {
          name: "Review Feed",
          url: "/reviews/feed",
          icon: "fa fa-comments"
        },
        {
          name: "Response Templates",
          url: "/reviews/templates",
          icon: "fa fa-comment",
          groups: [permissions.TEMPLATE_MANAGE]
        },
        {
          name: "Responder Report",
          url: "/reviews/responders",
          icon: "fa fa-pie-chart",
          groups: [permissions.AGENCY_ADMIN]
        },
        {
          name: "Review Creator",
          url: "/reviews/creator",
          icon: "fa fa-id-card",
          groups: [permissions.WIDEWAIL_ADMIN]
        }
      ]
    },
    {
      name: "Invite",
      url: "/invite",
      icon: "bg-image-logo-invite",
      groups: [permissions.REVIEW_INVITE_MANAGE, permissions.VIDEO_MANAGE],
      children: [
        {
          name: "Invite List",
          url: "/invite/leads",
          icon: "fa fa-list",
          groups: [permissions.REVIEW_INVITE_MANAGE, permissions.VIDEO_MANAGE]
        },
        {
          name: "Video Testimonials",
          url: "/invite/video",
          icon: "bg-image-logo-video-testimonials",
          groups: [permissions.VIDEO_MANAGE]
        },
        {
          name: "Invite configuration",
          url: "/invite/configure",
          icon: "fa fa-gear",
          groups: [permissions.REVIEW_INVITE_MANAGE, permissions.VIDEO_MANAGE],
          isVisible: () => !currentUserHasFeature(CAMPAIGNS) || isCurrentUserInGroup("WIDEWAIL_ADMIN")
        },
        // {
        //   name: "Automated Campaigns",
        //   url: "/invite/automatedCampaigns",
        //   icon: "fa fa-gear",
        //   groups: [permissions.REVIEW_INVITE_MANAGE, permissions.VIDEO_MANAGE],
        //   isVisible: () => currentUserHasFeature(CAMPAIGNS)
        // },
        {
          name: "Automated Campaigns",
          url: "/invite/automatedCampaigns",
          icon: "fa fa-refresh",
          groups: [permissions.REVIEW_INVITE_MANAGE, permissions.VIDEO_MANAGE],
          isVisible: () => currentUserHasFeature(CAMPAIGNS)
        },
        {
          name: "Campaigns",
          url: "/invite/campaigns",
          icon: "fa fa-bullhorn",
          groups: [permissions.REVIEW_INVITE_MANAGE, permissions.VIDEO_MANAGE],
          isVisible: () => currentUserHasFeature(CAMPAIGNS)
        }
      ]
    },
    {
      name: "Listings",
      url: "/listings",
      icon: "bg-image-logo-listings",
      groups: [permissions.LISTINGS_MANAGE, permissions.LISTINGS_READ]
    },
    {
      name: "Logs",
      url: "/log",
      icon: "fa fa-list",
      groups: [permissions.WIDEWAIL_ADMIN],
      children: [
        {
          name: "Import Log",
          url: "/log/import",
          icon: "fa fa-list"
        },
        {
          name: "Inbound Email",
          url: "/log/inbound",
          icon: "fa fa-list"
        },
        {
          name: "CSV Links",
          url: "/log/csvLinks",
          icon: "fa fa-list"
        }
      ]
    },
    {
      name: "Learning Center",
      url: "/learning",
      icon: "fa fa-graduation-cap"
    },
    {
      title: true,
      name: ""
    },
    {
      name: "Leave us a Review!",
      icon: "fa fa-star",
      url: INVITE_LANDING_PAGE_PREFIX + "Widewail"
    }
    // {
    //   name: "Tools",
    //   url: "/tools",
    //   icon: "icon-wrench",
    //   groups: [permissions.AGENCY_ADMIN],
    //   children: [
    //     {
    //       name: "Facebook Linking Tool",
    //       url: "/tools/facebook",
    //       icon: "fa fa-facebook-official"
    //     }
    //   ]
    // }
  ]
};

export default navConfig;
