import React, { useCallback, useEffect, useState } from "react";
import FormField from "../../../components/Form/FormField";
import CopyButton from "../../../components/Buttons/CopyButton";
import { updateMindbodyMode } from "../../../api/customerApi";
import HorizontalSelectField from "../../../components/Form/HorizontalSelectField";
import map from "lodash/map";
import find from "lodash/find";
import property from "lodash/property";
import { errorCaughtNotifier, useLocalNotifications } from "../../../components/Notifications/notification";

export default class MindbodyAdminLinking {
  field() {
    return "mindbodyInfo";
  }

  add() {
    const AddLink = () => {
      return <></>;
    };

    return AddLink;
  }

  edit() {
    const EditMindBody = ({ info, customer }) => {
      const [mode, setMode] = useState([]);

      useEffect(() => {
        setMode(info.mode);
      }, [info.mode]);
      const notify = useLocalNotifications();

      const onModeChanged = useCallback(
        ({ target }) => {
          return updateMindbodyMode(
            customer.id,
            info.id,
            map(target.value, v => v.value)
          )
            .then(property("mode"))
            .then(setMode)
            .catch(errorCaughtNotifier(notify));
        },
        [info, customer]
      );

      return (
        <>
          <FormField label="StudioID" inline={false}>
            <div className="text-nowrap">
              {info.clientId}
              <span className="ps-1">
                <CopyButton text={info.clientId} />
              </span>
            </div>
          </FormField>
          <HorizontalSelectField
            name="mode"
            value={mode}
            options={triggerValues}
            label="Triggers"
            isMulti={true}
            simpleValue={false}
            onChange={onModeChanged}
            placeholder="Triggers"
            value={map(mode, it => find(triggerValues, v => v.value === it))}
          />
        </>
      );
    };
    return EditMindBody;
  }
}

const triggerValues = [
  { label: "New Client", value: "NEW_CLIENT" },
  { label: "Appointment Complete", value: "APPOINTMENT_COMPLETE" },
  { label: "Sale Complete", value: "SALE_COMPLETE" }
];
