import { noop } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { Button, ButtonGroup, Collapse, Spinner } from "reactstrap";
import { useReduxAt, useStateThroughRedux } from "../../../hooks/stateHooks";
import StatusBarContainer from "../../../components/Modals/NavigableModal/StatusBarContainer/StatusBarContainer";

/**
 * @type {<T extends "clean" | "dirty" | "saving" | "success" | "failure" | "savingRequested" | "cleanRequested">() => [T, (v: T | (prev: T) => T) => void]}
 */
export const useStatusBarState = () => {
  const activePage = useReduxAt("settings/section", undefined);
  const statusKey = useMemo(() => ((activePage ? `settings/status/${activePage}` : undefined)), [activePage]);
  const retval = useStateThroughRedux(statusKey, "clean");
  return retval;
};

/**
 * @param {{
 *  status: "clean" | "dirty" | "saving" | "success" | "failure" | "savingRequested" | "cleanRequested";
 *  onStatusChange: (v: "clean" | "dirty" | "saving" | "success" | "failure" | "savingRequested" | "cleanRequested") => void;
 * }} param0
 */
export default function StatusBar({
  className = "",
  cancelButtonText = "Discard",
  saveButtonText = "Save",
  status = "clean",
  onStatusChange = noop
}) {
  const onDiscard = useCallback(() => onStatusChange("cleanRequested"), [onStatusChange]);
  const onSave = useCallback(() => onStatusChange("savingRequested"), [onStatusChange]);
  return (
    <StatusBarContainer>
      <Collapse isOpen={status === "dirty" || status === "cleanRequested" || status === "savingRequested"}>
        <div className="py-3 d-flex align-items-center justify-content-between">
          <small className="text-muted">You have unsaved changes</small>
          <ButtonGroup>
            <Button size="sm" color="light" onClick={onDiscard}>
              {cancelButtonText}
            </Button>
            <Button size="sm" color="primary" onClick={onSave}>
              {saveButtonText}
            </Button>
          </ButtonGroup>
        </div>
      </Collapse>
      <Collapse isOpen={status === "saving"}>
        <div className="py-3 d-flex align-items-center">
          <Spinner size="sm" color="muted" />
          <small className="text-muted ms-3">Saving changes...</small>
        </div>
      </Collapse>
      <Collapse isOpen={status === "success"}>
        <div className="py-3 d-flex align-items-center">
          <i className="fa fa-check text-success" />
          <small className="text-muted ms-3">Your changes have been saved</small>
        </div>
      </Collapse>
      <Collapse isOpen={status === "failure"}>
        <div className="py-3 d-flex align-items-center justify-content-between">
          <div>
            <i className="fa fa-remove text-danger" />
            <small className="text-muted ms-3">Something went wrong...</small>
          </div>
          <Button size="sm" color="light" onClick={onDiscard}>
            {cancelButtonText}
          </Button>
        </div>
      </Collapse>
    </StatusBarContainer>
  );
}
