import WWButton from "components/Buttons/WWButton";
import { Card, CardBody, CardHeader, CardHeaderActions } from "components/Card";
import AddIntegrationWizard from "components/Customer/SelfService/AddIntegrationWizard";
import { WWTable, WWTBody } from "components/Table/WWTable";
import WWOffcanvas from "components/WWOffCanvas/WWOffcanvas";
import { SITES } from "data/sites";
import { isEmpty } from "lodash";
import { not } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import DefaultSiteRowRenderer from "../SiteRowRenderers/DefaultSiteRowRenderer/DefaultSiteRowRenderer";
import HubspotSiteRowRenderer from "../SiteRowRenderers/HubspotSiteRowRenderer/HubspotSiteRowRenderer";
import { useLocationQueryParamState } from "hooks/stateHooks";
import SquareSiteRowRenderer from "../SiteRowRenderers/SquareSiteRowRenderer/SquareSiteRowRenderer";
import FacebookSiteRowRenderer from "../SiteRowRenderers/FacebookSiteRowRenderer/FacebookSiteRowRenderer";

export default function LinkedSitesTable({
  onRemove,
  updateCustomerField,
  customer,
  currentAgency,
  linkedSites = [],
  reload
}) {
  const [openOffcanvas, setOpenOffcanvas] = useState(false);

  const [urlSite, setUrlSite] = useLocationQueryParamState("site");
  const openSiteEditor = useCallback(site => {
    setUrlSite(site);
    setOpenOffcanvas(true);
  }, []);

  useEffect(() => {
    if (urlSite) {
      setOpenOffcanvas(true);
    }
  }, [urlSite]);

  const sitesTableInfo = useMemo(
    () =>
      SITES.map(site => {
        return { ...site, siteInfo: linkedSites.filter(linkedSite => site.source === linkedSite.source) };
      }).filter(site => !isEmpty(site.siteInfo)),
    [linkedSites]
  );

  return (
    <Row className="d-flex justify-content-center">
      <Col>
        <Card className="px-0 pb-0">
          <CardHeader className="px-2 px-md-4 flex-wrap">
            <h3 className="ms-2">Linked Site Integrations</h3>
            <CardHeaderActions>
              <WWButton onClick={() => setOpenOffcanvas(true)} color="primary">
                Add site integration
              </WWButton>
            </CardHeaderActions>
          </CardHeader>
          <CardBody>
            <WWTable>
              <WWTBody>
                {sitesTableInfo?.map((site, index) => (
                  <LinkedSitesTables
                    index={index}
                    key={site.source}
                    customer={customer}
                    site={site}
                    rowClicked={openSiteEditor}
                    onRemove={onRemove}
                  />
                ))}
              </WWTBody>
            </WWTable>
          </CardBody>
        </Card>
      </Col>
      <WWOffcanvas
        isOpen={openOffcanvas}
        toggle={() => {
          setOpenOffcanvas(not);
          setUrlSite(null);
          reload && reload();
        }}
        direction="end"
        wide
      >
        <AddIntegrationWizard
          site={urlSite}
          currentAgency={currentAgency}
          customer={customer}
          updateCustomerField={updateCustomerField}
          onFinish={() => {
            setOpenOffcanvas(false);
            setUrlSite(null);
            reload && reload();
          }}
        />
      </WWOffcanvas>
    </Row>
  );
}

const LinkedSitesTables = ({ rowClicked, index, customer, site, onRemove }) => {
  switch (site.source) {
    case "HUBSPOT":
      return <HubspotSiteRowRenderer index={index} customer={customer} onRemove={onRemove} site={site} />;
    case "SQUARE":
      return (
        <SquareSiteRowRenderer
          index={index}
          customer={customer}
          rowClicked={rowClicked}
          onRemove={onRemove}
          site={site}
        />
      );
    case "FACEBOOK":
      return (
        <FacebookSiteRowRenderer
          index={index}
          customer={customer}
          onRemove={onRemove}
          rowClicked={rowClicked}
          site={site}
        />
      );
    default:
      return (
        <DefaultSiteRowRenderer index={index} onRemove={onRemove} rowClicked={rowClicked} key={site.id} site={site} />
      );
  }
};
