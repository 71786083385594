import classNames from "classnames";
import { useMemo } from "react";
import styles from "./TableSortDropdown.module.scss";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { getLabelFromValue } from "data/options";
import { find } from "lodash";

export default function TableSortDropdown({ name, sortSetter, options, value }) {
  const [sortUp, sortDown] = useMemo(() => {
    if (!!find(options, { value })) {
      const sortUp = value?.endsWith(",asc");
      const sortDown = value?.endsWith(",desc");
      return [sortUp, sortDown];
    } else {
      return [false, false];
    }
  }, [value, options]);

  return (
    <UncontrolledDropdown className="d-inline-flex">
      <DropdownToggle color="" className="ps-0 border-0 d-flex text-nowrap flex-nowrap">
        <div className="d-flex flex-nowrap justify-center align-items-center">
          <span className="text-capitalize pe-2 text-muted">
            {!value ? options?.[0].label : getLabelFromValue(value, options) || options?.[0].label}
          </span>
          <div className="d-flex flex-column pe-2">
            <i
              data-testid="up-toggle-icon"
              className={classNames(
                "fa fa-sort-up",
                styles.iconTop,
                { "text-secondary": sortUp },
                { "text-muted": !sortUp }
              )}
            />
            <i
              className={classNames(
                "fa fa-sort-down",
                styles.iconBottom,
                { "text-secondary": sortDown },
                { "text-muted": !sortDown }
              )}
            />
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu container="body">
        {options.map(v => (
          <DropdownItem toggle={true} key={v.value} onClick={() => sortSetter(v.value)}>
            <span
              className={classNames("text-capitalize d-flex justify-center align-items-center", {
                "text-muted": v.value !== value
              })}
            >
              {v.label}{" "}
              <i
                className={classNames(
                  { "fa fa-caret-up": v.value?.endsWith(",asc") },
                  { "fa fa-caret-down": v.value?.endsWith(",desc") },
                  "text-secondary"
                )}
              />
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
