export const LOCAL_STORAGE_KEYS = Object.freeze({
  AUTOMATED_CAMPAIGN_CUSTOMER: "campaignCustomer",
  AWAITED_REDIRECT_CUSTOMER: "awaitedRedirectCustomer",
  SQUARE_STATE: "squareState",
  CAMPAIGN_CUSTOMER: "campaignCustomer",
  RELATION_ID: "relationId",
  FILTER_STATE: "filterState"
});

export const VIEW_STATE_STORAGE_KEYS = Object.freeze({
  SHOW_IE11_BANNER: "showIe11Banner",
  SIDEBAR_MINIMIZED: "sidebar:minimized",
  SIDEBAR_EXPANDED_FOR_MOBILE: "sidebar:expandedForMobile",
  MODAL_PREVENT_CUSTOM_SITE_PUBLISH: "modal:prevent:customSitePublish",
  PRODUCT_CUSTOMERS: "ProductCustomers"
});
