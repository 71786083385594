import noop from "lodash/noop";
import size from "lodash/size";
import React, { useCallback } from "react";
import Skeleton, { SkeletonTheme } from "components/Skeleton";
import { Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import { Slab, defaultRenderer } from "./Slab";
import colors from "../../constants/colors";

const SKELETON_WIDTH = 77;
const SKELETON_HEIGHT = 108;
const SKELETON_COUNT = 3;

/**
 * @param {{
 *   data?: React.ComponentProps<typeof InfoSlab>["data"][];
 *   renderer?: React.ComponentProps<typeof InfoSlab>["renderer"];
 *   selected?: number;
 *   onChange?: (v: number) => void;
 *   withLoader?: boolean
 * }} param0
 */
export const SelectableSlabsRow = ({
  data = [],
  selected = 0,
  onChange = noop,
  renderer = defaultRenderer(),
  withLoader = true
}) => {
  const onSlabClick = useCallback(index => () => onChange(index), [onChange]);
  return (
    <SimpleBar>
      <div className="mb-3 flex-nowrap d-flex flex-row">
        {data
          ?.sort((a, b) => b.total - a.total)
          ?.map((v, i) => (
            <Button className="m-1 px-4" color="light" key={v.id} onClick={onSlabClick(i)} active={selected === i}>
              <Slab {...v} renderer={renderer} />
            </Button>
          ))}
        {withLoader && size(data) <= 0 && (
          <SkeletonTheme color={colors.chartLightGray}>
            <Skeleton className="mx-1" width={SKELETON_WIDTH} height={SKELETON_HEIGHT} count={SKELETON_COUNT} />
          </SkeletonTheme>
        )}
      </div>
    </SimpleBar>
  );
};
