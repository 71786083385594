import React from "react";
import find from "lodash/find";
import indexOf from "lodash/indexOf";
import isEmpty from "lodash/isEmpty";

export default function LinkedInPostExample({
  postText = "Create a post",
  attachments = [],
  posterName = "Your Business",
  likes = 200,
  comments = 20,
  ...props
}) {
  const img = find(attachments, a => a.type === "PHOTO" && (isEmpty(a.sources) || indexOf(a.sources, "LINKEDIN") >= 0));
  return (
    <div className="linkedin-example-post">
      <div className="linkedin-example-header">
        <div className="linkedin-image-circle text-center">
          <i className="fa fa-building" aria-hidden="true"></i>
        </div>
        <div>
          <div className="name-title">{posterName}</div>
          <div className="follower-subtitle">150,672 followers</div>
          <div className="datetime-subtitle">Now</div>
        </div>
      </div>
      {img && <img className="linkedin-example-image" alt="Post attachment" src={img.target}></img>}
      <div className="linkedin-example-body">{postText}</div>
    </div>
  );
}
