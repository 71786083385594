import React, { useEffect, useState } from "react";
import FormField from "../../../components/Form/FormField";
import { Button } from "reactstrap";
import CopyButton from "../../../components/Buttons/CopyButton";
import SelectField from "../../../components/Form/SelectField";
import { bySource } from "../../../data/sites";
import { listConnectedCustomers } from "../../../api/cccApi";
import { errorCaughtNotifier } from "../../../components/Notifications/notification";

const useCCCShops = customer => {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    listConnectedCustomers()
      .then(data => setCustomers(data))
      .catch(errorCaughtNotifier);
  }, [customer]);

  return customers;
};

export default class CCCAdminLinking {
  field() {
    return bySource("CCC").customerField;
  }

  add() {
    const AddCCCInfo = ({ customer, onSave }) => {
      const shops = useCCCShops(customer);
      const [selectedShop, setSelectedShop] = useState();

      return (
        <>
          <SelectField
            className="w-100"
            name="shop"
            label="Search Shops"
            placeholder="Search..."
            options={shops}
            value={selectedShop}
            onChange={evt => setSelectedShop(evt.target.value)}
            getOptionLabel={({ name }) => name}
            getOptionValue={v => v}
          />

          <Button
            color="primary"
            className="mt-2"
            disabled={!selectedShop}
            onClick={() =>
              onSave({
                shopId: selectedShop.id,
                shopName: selectedShop.name,
                source: "CCC",
                defaultReviewTag: "Collision"
              })
            }
          >
            Link
          </Button>
        </>
      );
    };

    return AddCCCInfo;
  }

  edit() {
    const EditCCCInfo = ({ info }) => (
      <>
        <FormField label="Shop ID">
          <div className="text-nowrap">
            {info.shopId} <CopyButton className="ms-1" text={info.shopId} />
          </div>
        </FormField>
        <FormField label="Shop Name">
          <div className="text-nowrap">
            {info.shopName} <CopyButton className="ms-1" text={info.shopName} />
          </div>
        </FormField>
      </>
    );
    return EditCCCInfo;
  }
}
