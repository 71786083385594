import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { updateCarfaxLink } from "../../../api/customerApi";
import FormField from "../../../components/Form/FormField";
import { CarfaxUrlInputField, parseCarfax } from "../CarfaxLinking";
import { useCarfaxLocations } from "hooks/data/carfaxHooks";
import SelectField from "components/Form/SelectField";
import { FIVE_MINUTES_MILLISECONDS } from "constants/time";

export default class Carfax {
  field() {
    return "carfaxInfo";
  }

  add() {
    return AddCarfax;
  }

  edit() {
    return EditCarfax;
  }
}

const AddCarfax = ({ customer, onSave }) => {
  const [selectedLocation, setSelectedLocation] = useState();
  const [carfaxValid, setCarfaxValid] = useState(undefined);
  const [carfaxUrl, setCarfaxUrl] = useState();

  const siteInfo = useMemo(() => {
    const retval = { carfaxUrl, source: "CARFAX" };
    if (!!selectedLocation) {
      retval.dealerId = selectedLocation.dealerId;
      retval.companyName = selectedLocation.companyName;
      retval.url = selectedLocation.url;
      retval.address = selectedLocation.address;
    }
    return retval;
  }, [selectedLocation, carfaxUrl]);

  return (
    <>
      <FormField label="Location">
        <CarFaxLocationSelector onChange={setSelectedLocation} />
      </FormField>
      <CarfaxUrlInputField
        companyName={customer.companyName}
        onChange={evt => {
          setCarfaxUrl(evt.target.value);
          setCarfaxValid(parseCarfax(evt) && true);
        }}
        valid={carfaxValid}
        value={carfaxUrl}
      />
      <FormField label=" ">
        <Button color="primary" onClick={() => onSave(siteInfo)} disabled={!carfaxValid && !selectedLocation}>
          Link
        </Button>
      </FormField>
    </>
  );
};

const EditCarfax = ({ info, customer }) => {
  const [carfaxUrl, setCarfaxUrl] = useState(info.carfaxUrl);
  const [selectedLocation, setSelectedLocation] = useState();
  const [carfaxValid, setCarfaxValid] = useState(parseCarfax({ target: { value: info.carfaxUrl } }) && true);
  const [siteInfo, setSiteInfo] = useState({ ...info });
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const newInfo = { ...info, carfaxUrl };
    if (!!selectedLocation) {
      newInfo.dealerId = selectedLocation.dealerId;
      newInfo.companyName = selectedLocation.companyName;
      newInfo.url = selectedLocation.url;
    }
    setSiteInfo(newInfo);
    setIsReady(carfaxValid && (newInfo.carfaxUrl !== info.carfaxUrl || newInfo.dealerId !== info.dealerId));
  }, [selectedLocation, carfaxUrl, info, carfaxValid]);

  const updateCarfax = useCallback(() => {
    updateCarfaxLink(customer.id, info.id, siteInfo).then(() => setIsReady(false));
  }, [customer, info, siteInfo, setIsReady]);

  return (
    <>
      <FormField label="Location">
        {info.url ? (
          <a href={info.url} target="_blank" rel="noopener noreferrer">
            {info.companyName + "  "} <i className="fa fa-external-link" />
            <br />
            {info.address}
          </a>
        ) : (
          <CarFaxLocationSelector onChange={setSelectedLocation} />
        )}
      </FormField>
      <CarfaxUrlInputField
        companyName={customer.companyName}
        onChange={evt => {
          setCarfaxUrl(evt.target.value);
          setCarfaxValid(parseCarfax(evt) && true);
        }}
        valid={carfaxValid}
        value={carfaxUrl}
      />

      <FormField label=" ">
        <Button color="primary" onClick={updateCarfax} disabled={!isReady}>
          Update
        </Button>
      </FormField>
    </>
  );
};

const CarFaxLocationSelector = ({ onChange, ...props }) => {
  const [selectWasOpened, setSelectWasOpened] = useState(false);
  const { data, isFetching } = useCarfaxLocations({
    refetchOnMount: false,
    staleTime: FIVE_MINUTES_MILLISECONDS,
    enabled: selectWasOpened
  });
  return (
    <SelectField
      {...props}
      onFocus={() => setSelectWasOpened(true)}
      name="carfaxLocation"
      className="w-100"
      isLoading={isFetching}
      options={data || []}
      getOptionLabel={option => `${option.companyName} - ${option.address}`}
      onChange={evt => onChange(evt.target.value)}
    />
  );
};
