import WWPagination from "components/Paging/WWPagination/WWPagination";
import { useFilterState, useFilterValueState } from "hooks/filteringHooks";
import { isEmpty, isEqual, omit } from "lodash";
import { useState, useEffect } from "react";

const FilterPagination = ({
  isLoading,
  totalPages,
  pageName = "page",
  pageInfo = {},
  elementsLabel = "Total Elements"
}) => {
  const [pageValue, pageSetter] = useFilterValueState(pageName, "number");
  const [filterValues, setFilterValues] = useFilterState();
  const [previousFilterValue, setPreviousFilterValue] = useState(filterValues);
  const { totalElements = 0 } = pageInfo;

  useEffect(() => {
    if (
      !isEqual(omit(previousFilterValue, pageName), omit(filterValues, pageName)) &&
      !isLoading &&
      pageInfo &&
      (!isEmpty(omit(filterValues, [pageName, "urlLoaded"])) || pageValue !== 0)
    ) {
      pageSetter(0);
      setPreviousFilterValue(filterValues);
    }
  }, [filterValues, previousFilterValue, pageName, pageInfo, isLoading, pageSetter]);

  return (
    <span className="d-flex flex-column justify-content-center align-content-center flex-wrap">
      {totalElements >= 0 && (
        <span className="fw-semibold text-muted text-center">
          {elementsLabel}: {totalElements || 0}
        </span>
      )}
      <WWPagination loading={isLoading} pageNumber={pageValue + 1 || 1} pages={totalPages} setPageNumber={pageSetter} />
    </span>
  );
};

export default FilterPagination;
