import React, { useCallback, useEffect, useState, useMemo } from "react";
import { cloneDeep, get, noop } from "lodash";
import { Collapse, FormGroup } from "reactstrap";
import { getPropertywareBuildings, updatePropertywareLink } from "api/customerApi";
import WWButton from "components/Buttons/WWButton";
import Labeled from "components/Checkbox/Labeled/CheckboxLabeled";
import HorizontalSelectField from "components/Form/HorizontalSelectField";
import SharedCredentialsSelector from "components/SharedCredentials/SharedCredentialsSelector";
import FormField from "components/Form/FormField";
import InputField from "components/Form/InputField";
import { useValidatePropertySitesCheckboxes } from "hooks/validationHooks";

export default class Propertyware {
  field() {
    return "propertywareInfo";
  }

  add() {
    const AddPropertyware = ({ onSave }) => {
      const [sharedCredentials, setSharedCredentials] = useState();
      const [clientSecret, setClientSecret] = useState("");
      const [clientId, setClientId] = useState("");
      const [organizationId, setOrganizationId] = useState("");
      const [building, setBuilding] = useState("");
      const [importMoveIn, setImportMoveIn] = useState(false);
      const [importMoveOut, setImportMoveOut] = useState(false);
      const [importRenewal, setImportRenewal] = useState(false);
      const [importMaintenance, setImportMaintenance] = useState(false);
      const [buildingOptions, setBuildingOptions] = useState([]);
      const [loading, setLoading] = useState(false);

      const importOptions = useMemo(
        () => [
          { name: "importMoveIn", label: "Move in", value: importMoveIn, setter: setImportMoveIn },
          { name: "importMoveOut", label: "Move out", value: importMoveOut, setter: setImportMoveOut },
          { name: "importRenewal", label: "Renewal", value: importRenewal, setter: setImportRenewal },
          { name: "importMaintenance", label: "Maintenance", value: importMaintenance, setter: setImportMaintenance }
        ],
        [importMoveIn, importMoveOut, importRenewal, importMaintenance]
      );

      const vaidateActions = useValidatePropertySitesCheckboxes(importOptions);

      useEffect(() => {
        if ((clientSecret && clientId && organizationId) || sharedCredentials) {
          setLoading(true);
          getPropertywareBuildings({
            clientSecret,
            clientId,
            organizationId,
            sharedCredentials: sharedCredentials ? { id: sharedCredentials.id } : null
          })
            .then(res => setBuildingOptions(res))
            .finally(() => setLoading(false));
        } else {
          setBuildingOptions([]);
        }
      }, [clientSecret, clientId, organizationId, sharedCredentials]);

      const onSubmit = useCallback(() => {
        vaidateActions().then(validationResult => {
          if (validationResult) {
            onSave({
              sharedCredentials,
              clientSecret,
              clientId,
              organizationId,
              buildingId: building.value,
              propertyName: building.label,
              importMoveIn,
              importMoveOut,
              importRenewal,
              importMaintenance,
              source: "PROPERTYWARE"
            });
          }
        });
      }, [
        sharedCredentials,
        clientSecret,
        clientId,
        organizationId,
        building,
        importMoveIn,
        importMoveOut,
        importRenewal,
        importMaintenance
      ]);

      return (
        <>
          <SharedCredentialsSelector label="Shared Credentials" source="PROPERTYWARE" onChange={setSharedCredentials} />
          {!sharedCredentials && (
            <>
              <InputField
                type="text"
                label="Client Secret"
                placeholder="Client Secret"
                onChange={e => setClientSecret(e.target.value)}
                value={clientSecret}
              />
              <InputField
                type="text"
                label="Client ID"
                placeholder="Client ID"
                onChange={e => setClientId(e.target.value)}
                value={clientId}
              />
              <InputField
                type="text"
                label="Organization ID"
                placeholder="Organization ID"
                onChange={e => setOrganizationId(e.target.value)}
                value={organizationId}
              />
            </>
          )}
          <HorizontalSelectField
            name="BuildingID"
            label="Building ID"
            isLoading={loading}
            isClearable
            isDisabled={(!clientSecret || !clientId || !organizationId) && !sharedCredentials}
            options={buildingOptions.map(option => {
              return { label: option.name, value: option.id };
            })}
            onChange={event => setBuilding(event.target.value)}
            inline={false}
            tip="Leaving this blank will import all buildings."
          />
          {importOptions.map(({ name, label, value, setter }) => (
            <Labeled key={name} name={name} checked={value} onToggle={setter} label={label} />
          ))}
          <FormField label=" ">
            <WWButton
              color="primary"
              disabled={(!clientSecret || !clientId || !organizationId) && !sharedCredentials}
              onClick={onSubmit}
            >
              Link
            </WWButton>
          </FormField>
        </>
      );
    };
    return AddPropertyware;
  }

  edit() {
    const EditPropertyware = ({ info, customer }) => {
      const [sharedCredentials, setSharedCredentials] = useState(info.sharedCredentials);
      const [clientId, setClientId] = useState(info.clientId);
      const [organizationId, setOrganizationId] = useState("");
      const [buildingId, setBuildingId] = useState(info.buildingId);
      const [importMoveIn, setImportMoveIn] = useState(info.importMoveIn);
      const [importMoveOut, setImportMoveOut] = useState(info.importMoveOut);
      const [importRenewal, setImportRenewal] = useState(info.importRenewal);
      const [importMaintenance, setImportMaintenance] = useState(info.importMaintenance);
      const [initialInfo, setInitialInfo] = useState([]);
      const [dirty, setDirty] = useState(false);

      const importOptions = useMemo(
        () => [
          { name: "importMoveIn", label: "Move in", value: importMoveIn, setter: setImportMoveIn },
          { name: "importMoveOut", label: "Move out", value: importMoveOut, setter: setImportMoveOut },
          { name: "importRenewal", label: "Renewal", value: importRenewal, setter: setImportRenewal },
          { name: "importMaintenance", label: "Maintenance", value: importMaintenance, setter: setImportMaintenance }
        ],
        [importMoveIn, importMoveOut, importRenewal, importMaintenance]
      );

      const vaidateActions = useValidatePropertySitesCheckboxes(importOptions);

      const updatePropertyware = useCallback(() => {
        vaidateActions().then(validationResult => {
          if (validationResult) {
            return updatePropertywareLink(customer.id, info.id, {
              clientId,
              organizationId,
              buildingId,
              importMoveIn,
              importMoveOut,
              importMaintenance,
              importRenewal
            }).then(data => {
              setInitialInfo(data);
            });
          }
        });
      }, [
        clientId,
        organizationId,
        buildingId,
        importMoveIn,
        importMoveOut,
        importRenewal,
        importMaintenance,
        customer,
        info
      ]);

      useEffect(() => {
        setInitialInfo(cloneDeep(info));
      }, [info]);

      useEffect(() => {
        setDirty(
          buildingId !== initialInfo.buildingId ||
            clientId !== initialInfo.clientId ||
            organizationId !== initialInfo.organizationId ||
            importOptions.some(opt => get(initialInfo, opt.name) !== opt.value)
        );
      }, [
        initialInfo,
        importMoveIn,
        importMoveOut,
        importRenewal,
        importMaintenance,
        buildingId,
        organizationId,
        clientId
      ]);

      return (
        <>
          {sharedCredentials && (
            <SharedCredentialsSelector
              label="Shared Credentials"
              source="PROPERTYWARE"
              id={sharedCredentials?.id}
              isDisabled={true}
              onChange={setSharedCredentials}
            />
          )}
          {info.propertyName && (
            <InputField name="propertyName" label="Property Name" disabled value={info.propertyName} onChange={noop} />
          )}
          {!sharedCredentials && (
            <>
              <InputField name="clientId" label="Client ID" disabled value={info.clientId} onChange={setClientId} />
              <InputField
                disabled
                name="organizationId"
                label="Organization ID"
                value={info.organizationId}
                onChange={setOrganizationId}
              />
              <InputField
                disabled
                name="buildingId"
                label="Building ID"
                value={info.buildingId}
                onChange={setBuildingId}
              />
            </>
          )}
          {importOptions.map(({ name, label, value, setter }) => (
            <FormGroup check key={name} style={{ flexBasis: "165px" }} className="d-flex m-2">
              <Labeled name={name} checked={value} onToggle={setter} label={label} />
            </FormGroup>
          ))}
          <Collapse isOpen={dirty}>
            <FormField label=" ">
              <WWButton color="primary" onClick={updatePropertyware} disabled={!dirty}>
                Update
              </WWButton>
            </FormField>
          </Collapse>
        </>
      );
    };
    return EditPropertyware;
  }
}
