import moment from "moment";

import FacetedSearch from "components/FacetedSearch/FacetedSearch";
import { isAgencyAdmin } from "util/userUtils";
import { useHasLocationIdParameter } from "hooks/customerHooks";
import { useFilterState, useFilterValueState } from "hooks/filteringHooks";
import {
  frequencyUnit2frequencyBackendValue,
  getAvailableFrequencyOptions
} from "components/DateTime/FrequencySelector.helpers";
import SelectLocationPrompt from "components/Misc/SelectLocationPrompt";
import useFilterQuery from "hooks/data/useFilterQuery";
import { sortDatasetsForPropertyManagement } from "util/arrayUtils";

import { createContext, useContext, useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { isNil } from "lodash";

const FilterContext = createContext({});

export const COMPARISON_OPTIONS = {
  RELATIVE: "RELATIVE",
  PREVIOUS_MONTH: "PREVIOUS_MONTH",
  PREVIOUS_YEAR: "PREVIOUS_YEAR"
};

export const ExploreDropDown = ({
  value,
  setValue,
  renderer = (k, o) => o[k],
  buttonRenderer = renderer,
  options = {},
  className,
  clearable = false
}) => {
  return (
    <UncontrolledButtonDropdown className={className}>
      <DropdownToggle caret>{buttonRenderer(value, options)}</DropdownToggle>
      <DropdownMenu>
        {clearable && <DropdownItem onClick={() => setValue(undefined)}>Clear Filter</DropdownItem>}
        {Object.keys(options).map(k => (
          <DropdownItem key={k} onClick={() => setValue(k)}>
            {renderer(k, options)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export const getFreqOptions = ({ startDate = new Date(), endDate = new Date() } = {}, filter = () => true) => {
  let daysBetween = moment(endDate).diff(startDate, "day");

  return getAvailableFrequencyOptions([daysBetween, "day"])
    .reverse()
    .reduce((ret, f) => {
      const unit = frequencyUnit2frequencyBackendValue(f);

      if (filter(unit)) {
        ret[unit] = `${f}`;
      }
      return ret;
    }, {});
};

export const getRelativeDateRangeDiffLabel = ({ startDate = new Date(), endDate = new Date() } = {}) => {
  return `${moment(endDate).diff(moment(startDate), "days") + 1} days`;
};

export const getComparisonsOptions = (dateRange = {}) => {
  const { startDate, endDate } = dateRange;
  const spansMonths = moment(startDate).month() !== moment(endDate).month();
  const optionKeyFilter = k => k !== COMPARISON_OPTIONS.PREVIOUS_MONTH || !spansMonths;

  return Object.keys(COMPARISON_OPTIONS).reduce((ret, k) => {
    if (!optionKeyFilter(k)) return ret;

    return [
      ...ret,
      {
        value: k,
        label: `Prev ${
          COMPARISON_OPTIONS[k] === COMPARISON_OPTIONS.RELATIVE
            ? getRelativeDateRangeDiffLabel(dateRange)
            : COMPARISON_OPTIONS[k].split("_")[1].toLowerCase()
        }`
      }
    ];
  }, []);
};

// ReportWithFilters is a wrapper component intended to provide a unified set of
// features to reporting views using the FilterBar 3.0 system. It is supposed to
// be generally plug and play but can also support a few special use cases when
// the need may arise.
//
//    <ReportWithFilters
//      facetsFn={args => <T FilterBarConfigObject>}
//      children={...your report widgets} />
//
// The children can be anything, but are intended to be used with FilterContext
// aware components. Components become aware via either the
// `useReportFilterQuery` custom hook or the `withFilterQuery` higher order
// component. These will set up all the filter query stuff need for the report
// to render itself, the idea being that widget should be only concerned with
// how to render a set of generic data to the screen.
//
// The hook and the HOC will interface directly with the FilterContext to get
// filter information, however overrides can be provided via args to the hook or
// props to the HOC.
//
// Usage as an HOC:
//
//    export default withReportFilterQuery({
//      url: `/report/sentimentWidget`,
//      normalizeResponse: (summary = []) => ({ summary: summary?.map(summaryDataMapper).sort(summaryDataSorter) })
//    })(SummaryWidget);
//
// or with custom hook:
//
//    export default function GMBReportWidgetWithQuery() {
//      const [selectedBusiness, setSelectedBusiness] = useState(),
//        filterQuery = useReportFilterQuery({
//          url: "/report/gmb-performance",
//          additionalParams: { siteId: get(selectedBusiness, "[0].siteId") },
//          freqFilter: f => f.value !== "HOURLY"
//        });
//
//      return (
//        <GMBReportWidget {...filterQuery} selectedBusiness={selectedBusiness} setSelectedBusiness={setSelectedBusiness} />
//      );
//    }
//
// The use of Context here allows for report widgets to be filter-aware while at
// the same time being filter agnostic. This means that widgets don't need to
// have or know about a filtersFn and can be plugged into reports with different
// filter sets without change.

// TODO by default this will send query params to the backend that may be
// ignored by the backend for a given query (ie, frequency in cases where there
// are no buckets. It would be nice to trim/include those based on need

export const useReportFilterQuery = ({
  url, // url to get data from
  sources = {}, // explorable sources in the dashboard widget
  sourceClearable = false, // allow the source to be cleared
  actions = () => {}, // custom actions passed to the dashboard widget
  normalizeResponse = resp => resp, // you can shape the raw data here based on the response
  prepareFilters, // custom transform of filter values
  additionalParams = {}, // additional params to the filterQuery
  freqFilter = () => true, // filter out incompatible freqs from the available if needed
  filtersFn, // if provided, overrides the context filters, REQUIRED when used outside off ReportWithFilters
  enabled: isEnabled = () => true, // function to disable the query based on filter value state
  forceResults, //by default the query is disabled if admin and no location selected, this skips that
  pageSize, // defining a page size adds all other paging params to the query
  sortParamName = "sort",
  debounceDelay // overrides the default useFilterQuery debounceDelay if needed
} = {}) => {
  const [sort, setSort] = useFilterValueState(sortParamName);
  const [pageNumber, setPageNumber] = useState(0);
  const sourceKeys = Object.keys(sources);
  const { filters: contextFilters } = useContext(FilterContext);
  const [filterValues = {}, setFilterValues] = useFilterState();
  const hasLocationSelected = useHasLocationIdParameter();
  const isAdmin = isAgencyAdmin();
  const showResults = !isAdmin || hasLocationSelected || forceResults;
  const availableFreqs = getFreqOptions(filterValues?.dateRange, freqFilter);
  const freqKeys = Object.keys(availableFreqs);
  const [source, setSource] = useState(sourceClearable ? null : sourceKeys[0]);
  const [frequency, setFrequency] = useState(Object.keys(availableFreqs)[0]);
  const filters =
    typeof filtersFn === "function"
      ? filtersFn({ filterValues, isAdmin, availableFreqs, showResults, hasLocationSelected })
      : contextFilters;

  // passing in pageSize enables pagination on the query
  const pageParams = [sort, pageSize].every(isNil)
      ? {}
      : {
          page: pageNumber,
          size: pageSize,
          sort
        },
    filterQuery = useFilterQuery({
      filters,
      url,
      additionalParams: { ...pageParams, ...(source ? { source } : {}), frequency, ...additionalParams },
      normalizeResponse,
      prepareFilters,
      debounceDelay,
      enabled: !!showResults && !!url && isEnabled({ filters, filterValues, hasLocationSelected, isAdmin })
    }),
    { data } = filterQuery;

  const ret = {
    filters,
    filterValues,
    setFilterValues,
    availableFreqs,
    url,
    source,
    setSource,
    frequency,
    setFrequency,
    showResults,
    hasLocationSelected,
    ...filterQuery,
    isLoading: filterQuery.isLoading || filterQuery.isFetching,
    rawData: data,
    pageNumber,
    setPageNumber,
    pageSize,
    sort,
    setSort,
    totalPages: data?.page?.totalPages,
    data: {
      ...data,
      datasets: data?.datasets ? sortDatasetsForPropertyManagement(data?.datasets) : null
    }
  };

  useEffect(() => {
    // when the date range changes and the selected freq is not an option,
    // change to the first one
    if (!freqKeys.includes(frequency)) setFrequency(freqKeys[0]);
  }, [frequency, setFrequency, freqKeys, filterValues?.dateRange]);

  useEffect(() => {
    setPageNumber(0);
  }, [JSON.stringify(filterValues), sort]);

  ret.actions = (
    <div className="d-flex flex-row flex-nowrap align-items-baseline" style={{ gap: "0.25em" }}>
      {sourceKeys.length > 0 && (
        <ExploreDropDown
          value={source}
          setValue={setSource}
          buttonRenderer={source => (isNil(source) ? "Sites" : sources[source])}
          renderer={source => `${sources[source]}`}
          options={sources}
          className="source-selector"
          clearable={sourceClearable}
        />
      )}
      {Object.keys(availableFreqs).length > 0 && (
        <ExploreDropDown
          value={frequency}
          setValue={setFrequency}
          renderer={f => availableFreqs[f]}
          options={availableFreqs}
          className="frequency-selector"
        />
      )}
      {actions(ret) /* allow for additional custom actions */}
    </div>
  );

  return ret;
};

export const withReportFilterQuery = args => Component => props => (
  <Component {...props} {...useReportFilterQuery({ ...args, ...props })} />
);

export const getFallbackFilterVals = ({ filters, filterValues }) => {
  return Object.keys(filterValues).reduce((ret, key) => {
    let filter = filters.find(f => f.name === key) || {},
      { options = [] } = filter,
      allowedOpts = options?.map(o => o.value) || [];

    // skip for checkboxen
    if (filter.type === "checkbox") return ret;

    if (options.length && !allowedOpts.includes(filterValues[key])) {
      ret[key] = allowedOpts[0];
    }

    return ret;
  }, {});
};

export function ReportWithFilters(props) {
  const { children, filterBarChildren, showFiltersButton = true } = props,
    { filters = [], showResults, filterValues, setFilterValues, data } = useReportFilterQuery(props);

  useEffect(() => {
    // if the filter values changed in a way that made a previously selected
    // option no longer valid, rest that value to the first available option
    let fallbacks = getFallbackFilterVals({ filters, filterValues });

    if (Object.keys(fallbacks).length) setFilterValues({ ...filterValues, ...fallbacks });
  }, [filters, filterValues, setFilterValues]);

  return (
    <FilterContext.Provider value={{ filters, data, showResults }}>
      <FacetedSearch showFiltersButton={showFiltersButton} filters={filters}>
        {showResults && filterBarChildren}
      </FacetedSearch>
      {showResults ? children : <SelectLocationPrompt />}
    </FilterContext.Provider>
  );
}

export default ReportWithFilters;
