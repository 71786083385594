import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { errorCaughtNotifier } from "components/Notifications/notification";
import { CardBody, CardHeaderActions } from "components/Card";
import { DashboardWidget } from "components/Widgets/DashboardWidget";
import TagsChart from "components/Charts/TagsChart";
import { TableCard, TableCardHeader, WWTable, WWTBody, WWTD, WWTH, WWTHead, WWTR } from "components/Table/WWTable";
import WWButton from "components/Buttons/WWButton";
import TableBodySkeleton from "components/Skeleton/TableBodySkeleton";
import PrintScreenButton from "components/Misc/PrintScreenButton";
import ReportWithFilters, { ExploreDropDown, useReportFilterQuery } from "components/HOCs/ReportWithFilters";
import FilterTableSort from "components/FacetedSearch/Sort/FilterTableSort/FilterTableSort";
import { useFrontendPagination, DEFAULT_PAGE_SIZE } from "hooks/data/useFrontendPagination";
import WWPagination from "components/Paging/WWPagination/WWPagination";
import Skeleton from "react-loading-skeleton";
import TagsReportTableRow from "./TagsReportTableRow";
import { exportTagsReport } from "api/reportApi";
import { tagsChartDataMapper } from "util/reportUtils/tagReportUtils";
import { showSkeleton } from "util/showSkeleton";
import { datetime2iso, endOfTheDay, startOfTheDay, now } from "util/dateUtils";
import { TAG_TYPES } from "data/options";
import { useNotification } from "components/Notifications/notification";

import styles from "./TagsReport.module.scss";
import { getReviewSourceFilterConfig } from "components/FacetedSearch/FilterRecipes";
import { getAccountFilterConfig } from "components/FacetedSearch/Filters/TypeAheadFilter/TypeAheadFilter";

const { containerClass } = styles;

const printButton = <PrintScreenButton color="warning" className="mb-0" />;
const filtersFn = ({ isAdmin, filterValues }) => [
  getAccountFilterConfig(),
  {
    name: "location",
    label: "Location",
    type: "customerOrBrand",
    required: isAdmin && !filterValues?.account?.id,
    minLength: 0,
    primaryBar: {
      position: "LEFT",
      className: "d-md-flex auto-width d-none"
    },
    secondaryBar: {
      className: "d-flex d-md-none"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "dateRange",
    label: "Date range",
    type: "dates",
    queryParams: ["startDate", "endDate"],
    required: true,
    defaultValue: {
      startDate: datetime2iso(startOfTheDay(now().startOf("month"))),
      endDate: datetime2iso(endOfTheDay(now()))
    },
    primaryBar: {
      position: "LEFT",
      className: "d-md-flex auto-width d-none"
    },
    chip: {
      position: "NONE"
    }
  },
  getReviewSourceFilterConfig(),
  {
    name: "sort",
    defaultValue: "count,desc",
    required: true,
    nonFilter: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  },
  {
    name: "tagType",
    nonFilter: true,
    defaultValue: "TOPIC",
    required: true,
    offCanvas: {
      position: "NONE"
    },
    chip: {
      position: "NONE"
    }
  }
];

const TagsReportChart = props => {
  const { tagType, hiddenValues, onDotClick, showAll, isLoading, chartData, totalCount, taggedCount, actions } = props;

  return (
    <DashboardWidget
      className="mb-0 border-bottom-0"
      titleClassName="mb-2"
      actionsClassName="mb-0 ms-auto "
      actions={actions}
      title={
        <div className="d-flex align-items-center mb-4 mb-sm-0">
          Review Tags
          {isLoading ? (
            <Skeleton count={1} width={200} height={24} />
          ) : (
            <>
              <div className="ps-3 font-lg mt-1">
                <strong>{taggedCount}</strong> of <strong>{totalCount}</strong> Reviews have Tags
              </div>
            </>
          )}
        </div>
      }
    >
      <div className="pb-2 d-none d-md-block">
        <div className="text-muted mb-3">
          <span>{hiddenValues.length} tags are hidden</span>
          <WWButton color="link" onClick={showAll}>
            Show all
          </WWButton>
        </div>
        <TagsChart onDotClick={onDotClick} data={chartData} tagType={tagType} />
      </div>
    </DashboardWidget>
  );
};

const TagsReportTable = ({
  tagType,
  tagsData,
  isLoading,
  isFetched,
  params,
  onTagToggle,
  onAllTagsToggle,
  actions = [],
  isTagToggled = () => false
}) => {
  const [tagsPage, setTagsPage] = useFrontendPagination(tagsData);
  const pages = tagsData?.length ? Math.ceil(tagsData.length / DEFAULT_PAGE_SIZE) : 0;
  const headers = [
      <WWTH>Name</WWTH>,
      <WWTH className="text-center">Sentiment</WWTH>,
      <WWTH className="text-center">
        <FilterTableSort label="Total Tags" options={["count,desc", "count,asc"]} />
      </WWTH>,
      <WWTH className="text-center">
        <FilterTableSort label="Average Rating" options={["averageRating,desc", "averageRating,asc"]} />
      </WWTH>,
      <WWTH className="text-center d-none d-md-block">
        <WWButton
          color="link"
          contentClass="text-capitalize"
          onClick={() => onAllTagsToggle(tagsData?.map(dataPoint => dataPoint.value))}
        >
          Toggle All
        </WWButton>
      </WWTH>
    ],
    pagination =
      pages > 1 ? (
        <WWPagination
          loading={showSkeleton({ isLoading, isFetched })}
          pageNumber={tagsPage + 1}
          pages={pages}
          setPageNumber={setTagsPage}
        />
      ) : null;

  return (
    <TableCardWrapper actions={actions} headers={headers} pagination={pagination}>
      {showSkeleton({ isLoading, isFetched }) ? (
        <TableBodySkeleton rows={DEFAULT_PAGE_SIZE} cols={headers.length} />
      ) : (
        <WWTBody>
          {!!tagsData?.length ? (
            tagsData
              .slice(tagsPage * DEFAULT_PAGE_SIZE, (tagsPage + 1) * DEFAULT_PAGE_SIZE)
              .map(tag => (
                <TagsReportTableRow
                  {...tag}
                  key={tag.value}
                  tagType={tagType}
                  params={params}
                  isChecked={isTagToggled(tag.value)}
                  onTagToggle={onTagToggle}
                />
              ))
          ) : (
            <WWTR>
              <WWTD className="text-muted text-center p-3" colSpan={5}>
                No data
              </WWTD>
            </WWTR>
          )}
        </WWTBody>
      )}
    </TableCardWrapper>
  );
};

const TableCardWrapper = ({ pagination, children, actions, headers }) => {
  return (
    <TableCard data-cy="tags-report-table" className="border-top-0 pt-0 pb-4">
      <TableCardHeader>
        <CardHeaderActions>
          {actions.map((a, i) => (
            <React.Fragment key={i}>{a}</React.Fragment>
          ))}
        </CardHeaderActions>
      </TableCardHeader>
      <CardBody>
        <WWTable>
          <WWTHead>
            <WWTR>
              {headers.map((h, i) => (
                <React.Fragment key={i}>{h}</React.Fragment>
              ))}
            </WWTR>
          </WWTHead>
          {children}
        </WWTable>
      </CardBody>
      {pagination}
    </TableCard>
  );
};

const TagsReport = () => {
  const [hiddenValues, setHiddenValues] = useState([]);
  const [tagType, setTagType] = useState();
  const enabled = ({ filterValues: { tagType } }) => !!tagType;
  const notifySuccess = useNotification();

  // setup the needed queries for the charts and table
  const {
    rawData: tagsData,
    isLoading,
    isFetched,
    filterValues,
    setFilterValues
  } = useReportFilterQuery({
    url: "/reviews/*/entityCounts",
    additionalParams: { type: tagType, size: 200 },
    filtersFn,
    enabled
  });
  const taggedReviews = useReportFilterQuery({
    url: "/reviews",
    additionalParams: {
      [`entity_${tagType}[mode]`]: "ANY",
      size: 1
    },
    filtersFn,
    enabled
  });
  const totalReviews = useReportFilterQuery({
    url: "/reviews",
    additionalParams: {
      type: "REVIEW",
      size: 1
    },
    filtersFn,
    enabled
  });

  // tagType creates a sortof chicken v egg situation, so we track an internal
  // state version so that we can track the changes to this value from outside the
  // filter query hook
  useEffect(() => {
    filterValues.tagType && setTagType(filterValues.tagType);
  }, [filterValues.tagType]);

  // when the location filter value changes, reset the hidden tags since they be
  // irrelevant
  useEffect(() => {
    setHiddenValues([]);
  }, [filterValues.location]);

  // prep the props for the chart
  const chartProps = {
    tagType,
    hiddenValues,
    isLoading: taggedReviews.isLoading || totalReviews.isLoading,
    taggedCount: taggedReviews?.data?.page?.totalElements || 0,
    totalCount: totalReviews?.data?.page?.totalElements || 0,
    chartData: tagsData?.length ? tagsChartDataMapper(tagsData, hiddenValues) : tagsData,
    onDotClick: meta => setHiddenValues(prev => [...prev, ...meta.map(({ value }) => value)]),
    showAll: () => setHiddenValues([]),
    actions: (
      <div className="d-flex flex-row flex-nowrap align-items-baseline">
        <span className="d-none d-sm-block text-muted me-2">Explore by:</span>
        <ExploreDropDown
          value={tagType || TAG_TYPES.TOPIC}
          setValue={tagType => setFilterValues({ ...filterValues, tagType })}
          options={TAG_TYPES}
          className="explore-selector"
        />
      </div>
    )
  };

  //prep props for table
  const tableProps = {
    isLoading,
    isFetched,
    tagsData,
    tagType,
    params: filterValues,
    onAllTagsToggle: values => (isEmpty(hiddenValues) ? setHiddenValues(values) : setHiddenValues([])),
    isTagToggled: tag => hiddenValues.includes(tag),
    onTagToggle: (value, toggle) =>
      setHiddenValues(prev => (toggle ? [...prev, value] : prev.filter(value2 => value2 !== value))),
    actions: [
      <WWButton
        className="btn-outline-secondary"
        disabled={isLoading}
        onClick={() => {
          exportTagsReport(
            {
              ...filterValues.dateRange,
              brand: filterValues.location?.brand,
              customerId: filterValues.location?.id,
              accountId: filterValues.account?.id,
              type: tagType
            },
            tagType
          )
            .then(() => {
              notifySuccess({
                title: "CSV is being generated",
                body: "CSV export is being generated. You will receive an email with the report attached."
              });
            })
            .catch(errorCaughtNotifier(filterValues));
        }}
      >
        Export CSV
      </WWButton>
    ]
  };

  return (
    <div className={classnames("py-4 px-xs-0 px-lg-4 container-fluid", containerClass)}>
      <ReportWithFilters filtersFn={filtersFn} filterBarChildren={printButton}>
        <Row className="d-flex align-items-stretch no-pagebreak-inside">
          <TagsReportChart {...chartProps} />
        </Row>
        <Row className="d-flex align-items-stretch no-pagebreak-inside">
          <TagsReportTable {...tableProps} />
        </Row>
      </ReportWithFilters>
    </div>
  );
};

export default TagsReport;
