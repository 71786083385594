import React from "react";
import WWAccordionSection from "./WWAccordionSection";

const WWAccordion = ({ items = [], borderless }) =>
  items
    ? items.map(({ children, caption, body, hide = false, openOnInit = false, nonAccordion = false }, index) => {
        if (hide) {
          return null;
        } else if (nonAccordion) {
          return body;
        } else {
          return (
            <WWAccordionSection borderless={borderless} caption={caption} openOnInit={openOnInit} key={index}>
              {body}
            </WWAccordionSection>
          );
        }
      })
    : null;

export default WWAccordion;
