/**
 * Put specified customers info into cache, merging it with existing ones
 * @param {{
 *   id: string
 * }[]} payload
 */
export const actionMergeCustomersIn = payload => ({
  type: "CACHE_CUSTOMERS_MERGE",
  payload
});

/**
 * Remove specified customers from cache
 * @param {string[]} payload
 */
export const actionInvalidateCachedCustomers = payload => ({
  type: "CACHE_CUSTOMERS_INVALIDATE",
  payload
});
