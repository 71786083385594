import React, { useState, useCallback } from "react";
import { Button, InputGroup } from "reactstrap";
import FormField from "../../../components/Form/FormField";
import * as linkedInApi from "../../../api/linkedInApi";
import Select from "react-select";
import logger from "../../../util/logger";
import debounce from "lodash/debounce";
import get from "lodash/get";

export default class LinkedIn {
  field() {
    return "linkedinInfo";
  }

  add() {
    const AddLinkedIn = ({ customer, onSave }) => {
      const [selectedLocation, setSelectedLocation] = useState();
      const [locations, setLocations] = useState();

      useCallback(() =>
        linkedInApi
          .pages()
          .then(res =>
            setLocations(
              res.data.map(p => {
                let details = get(p, "organization~");
                return {
                  label: details.localizedName,
                  value: {
                    organizationName: details.localizedName,
                    organizationId: details.id,
                    source: "LINKEDIN"
                  }
                };
              })
            )
          )
          .catch(err => logger.error(err))
      );

      return (
        <>
          <InputGroup>
            <Select
              className="w-75"
              enabled={locations != null}
              options={locations}
              onChange={l => setSelectedLocation(l)}
              value={selectedLocation}
              placeholder="Location Name"
            />
            <Button color="primary" onClick={() => onSave(selectedLocation.value)}>
              Link
            </Button>
          </InputGroup>
        </>
      );
    };
    return AddLinkedIn;
  }

  edit() {
    const EditLinkedIn = ({ info, customer, refreshSite }) => {
      return (
        <>
          {info.companyName && (
            <FormField label="Location">
              <a href={info.url} target="_blank" rel="noopener noreferrer">
                {info.companyName + "  "} <i className="fa fa-external-link" />
              </a>
            </FormField>
          )}
        </>
      );
    };
    return EditLinkedIn;
  }
}
